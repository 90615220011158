import React from 'react';
import Template from 'base/template';
import { columns } from 'views/sync-jobs/table-columns';
import IndexContentInfinite from 'base/index-content-infinite';
import SyncJob from 'api/sync-job';
import Filters from 'views/sync-jobs/filters';

const cards = [
  {
    name: 'total',
    label: 'Total'
  }
];

const SyncJobs = (props) => {
  return (
    <Template {...props}>
      <IndexContentInfinite
        title="Sync Jobs"
        id="sync-jobs"
        filters={Filters}
        cards={cards}
        query={SyncJob.searchReport}
        columns={columns}
        {...props}
        selectable
        //isSidebarOpen={true}
        //  sidebar={Sidebar}
      ></IndexContentInfinite>
    </Template>
  );
};

export default SyncJobs;
