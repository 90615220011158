import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
/** @jsxImportSource @emotion/react */
import * as Collapsible from '@radix-ui/react-collapsible';
import { GraphUp } from 'react-bootstrap-icons';
import { useMap } from 'react-leaflet';
import { IconButton } from 'components/buttons';
import OnlineChart from 'components/charts/online-chart';
const Filters = styled('section')`
  background: #ffffff;

  box-shadow: 0px 16px 40px -6px rgba(29, 32, 35, 0.4);

  svg {
    padding: 2px;
    transition: all 0.2s;
  }
  > div {
    &[data-state='open'] {
      svg.dropdown {
        transform: rotate(180deg);
      }
    }
  }
  h3 {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    text-transform: uppercase;
    width: 100%;
    color: #7f8795;
  }
  h3 {
    display: flex;
    width: 100%;
    padding: 1rem;
    border-bottom: 1px solid #eceff3;
    > svg {
      margin-left: auto;
    }
  }
`;

export default ({ filterOpen, setFilterOpen, selectedSite }) => {
  const elmRef = React.useRef();

  React.useEffect(
    () => elmRef.current && L.DomEvent.disableClickPropagation(elmRef.current),
    [elmRef]
  );

  const map = useMap();

  return (
    <div ref={elmRef} className="leaflet-top leaflet-left">
      <div
        className="leaflet-control leaflet-bar"
        css={css`
          display: flex;
        `}
      >
        <Filters>
          <aside
            data-show={filterOpen ? 'true' : 'false'}
            css={css`
              &[data-show='false'] {
                width: 0px !important;
                transition: height 300ms ease-out;
                border-bottom: none;

                opacity: 1;
                > * {
                  display: none;
                }
              }

              &[data-show='true'] {
                width: 220px !important;
                height: 220px !important;
                transition: width 300ms ease-in;
                opacity: 1;
              }
              position: relative;

              background: var(--neutral-09);
              border-bottom: 1px solid var(--neutral-12);
            `}
          >
            <div
              css={css`
                height: 100px;
              `}
            >
              <OnlineChart
                config={{ title: {} }}
                chartData={
                  selectedSite.device_count > 0
                    ? [
                        {
                          value: Number(selectedSite.online_count),
                          name: 'online'
                        },
                        {
                          value: Number(selectedSite.offline_count),
                          name: 'offline'
                        }
                      ]
                    : []
                }
              />
            </div>
          </aside>
        </Filters>
        <div
          css={css`
            padding: 0.5rem;
            width: fit-content;
            height: fit-content;
            background: #ffffff;
          `}
        >
          <IconButton onClick={() => setFilterOpen((prev) => !prev)}>
            <GraphUp />
          </IconButton>
        </div>
      </div>
    </div>
  );
};
