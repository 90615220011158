import React from 'react';
import { components } from 'react-select';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Badge } from 'components/badge';
import AsyncSelect from 'components/headless-select/async';
import Department from 'api/department';
import { Person as PersonIcon } from 'react-bootstrap-icons';
import { IconUser, IconUsersGroup } from '@tabler/icons-react';

export const DepartmentItem = ({ department }) => {
  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: auto 1fr auto;
        gap: 0.8rem;
        align-items: center;
        width: 100%;
        padding: 0.5rem 0;

        > * {
          flex: 1;
        }
      `}
    >
      <IconUsersGroup size={16} />
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 0.2rem;
          span:last-of-type {
            color: var(--neutral-01);
            font-weight: 500;
            font-size: 12px;
          }
        `}
      >
        <span>{department.Name}</span>
        <span>{department.Description}</span>
      </div>
      <span
        title={`${department.person_count} person(s) in dep.`}
        css={css`
          font-size: 11px;
          display: flex;
          align-items: center;
        `}
      >
        <IconUser size={16} />
        {department.person_count}
      </span>
    </div>
  );
};
export const Option = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <DepartmentItem department={props.data} />
    </components.Option>
  );
};
const DepartmentSelect = React.forwardRef((props, ref) => {
  return (
    <AsyncSelect
      fetchFn={Department.search}
      itemComponents={{ Option }}
      {...props}
    />
  );
});

export default DepartmentSelect;
