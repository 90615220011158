import React from 'react';
import { Field } from 'formik';
import { TextField } from 'components/form';

import AsyncSelect from 'components/headless-select/async';
import GenericDoor from 'api/generic_door';

/** @jsxImportSource @emotion/react */
const IntercomForm = ({ intercom }) => {
  return (
    <section>
      <Field name="name" as={TextField}></Field>
      <Field
        title="Door Association"
        name="generic_door_id"
        component={AsyncSelect}
        fetchFn={GenericDoor.search}
      />
    </section>
  );
};

export default IntercomForm;
