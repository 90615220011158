import React from "react";
import { SecondaryButton } from "./buttons";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
const styles = css`
  input {
    display: none;
  }
`;

const InputFile = (props, ref) => {
  return (
    <label css={styles}>
      <SecondaryButton onClick={() => ref.current.click()}>
        Upload
      </SecondaryButton>
      <input ref={ref} type="file" name={props.name} {...props} />
    </label>
  );
};

export default React.forwardRef(InputFile);
