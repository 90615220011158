import React from 'react';
import { css } from '@emotion/react';
import ReactECharts from 'echarts-for-react';
import { createDatesArray, createEachDayOfInterval } from 'utils/arrays';
import { ZoomIn, ZoomOut } from 'react-bootstrap-icons';
/** @jsxImportSource @emotion/react */
export default ({ dataZoom, setDataZoom }) => {
  function handleZoomIn() {
    setDataZoom((prevDataZoom) => {
      if (prevDataZoom.start == 50 && prevDataZoom.end == 50)
        return { start: 50, end: 50 };

      let start = prevDataZoom.start + 10;
      let end = prevDataZoom.end - 10;

      return { start, end };
    });
  }

  function handleZoomOut() {
    setDataZoom((prevDataZoom) => {
      if (prevDataZoom.start == 0 && prevDataZoom.end == 100)
        return { start: 0, end: 100 };
      let start = prevDataZoom.start - 10;
      let end = prevDataZoom.end + 10;

      return { start, end };
    });
  }

  return (
    <div
      className="data-zoom"
      css={css`
        padding: 0.2rem;
        margin-top: -20px;
        margin-right: 4px;
        color: var(--secondary-font-color);
      `}
    >
      <button onClick={handleZoomIn}>
        <ZoomIn size={20} />
      </button>
      <button onClick={handleZoomOut}>
        <ZoomOut size={20} />
      </button>
    </div>
  );
};
