import React from "react";
import { ThemeProvider as EmotionThemeProvider } from "@emotion/react";

const themeLight = {
  // contentBackground: "var(--neutral-07)",
  // tableRowBottomBorder: "var(--neutral-05)",
  // iconBackground: "var(--secundary-v-02)",
  // iconFill: "white",
  // hoverBackground: "var(--menu-hover-background-color)",
};

const themeDark = {
  // contentBackground: "#0d1117",
  // tableRowBottomBorder: "#2c2f3a",
  // iconBackground: "black",
  // iconFill: "black",
  // hoverBackground: "var(--neutral-08)",
};

const getInitialTheme = () => {
  if (typeof window !== "undefined" && window.localStorage) {
    const storedPrefs = window.localStorage.getItem("color-theme");
    if (typeof storedPrefs === "string") {
      return storedPrefs;
    }

    const userMedia = window.matchMedia("(prefers-color-scheme: dark)");
    if (userMedia.matches) {
      return "dark";
    }
  }

  // If you want to use dark theme as the default, return 'dark' instead
  return "dark";
};

export const ThemeContext = React.createContext();

export default function ThemeProvider({ initialTheme, children }) {
  const [theme, setTheme] = React.useState(getInitialTheme);

  const rawSetTheme = (rawTheme) => {
    const root = window.document.documentElement;
    const isDark = rawTheme === "dark";

   // root.classList.remove(isDark ? "light" : "dark");
    //root.classList.add(rawTheme);

    localStorage.setItem("color-theme", rawTheme);
  };

  if (initialTheme) {
    rawSetTheme(initialTheme);
  }

  React.useEffect(() => {
    rawSetTheme(theme);
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <EmotionThemeProvider theme={theme == "dark" ? themeDark : themeLight}>
        {children}
      </EmotionThemeProvider>
    </ThemeContext.Provider>
  );
}

export const useTheme = () => {
  return React.useContext(ThemeContext);
};
