import "css/sidebar.css";
import React from "react";
import { useHeadlessTable } from "providers/headless-table-provider";
import { AddButton } from "./buttons";
export const withActionButton = (WrappedButton) => (props) => {
  if (!WrappedButton) return !null;
  const { selectedFlatRows } = useHeadlessTable();

  const [isDisabled, setIsDisabled] = React.useState(true);

  React.useEffect(() => {
    setIsDisabled(selectedFlatRows.length != 1);
  }, [selectedFlatRows]);

  return (
    <WrappedButton
      disabled={isDisabled}
      data={selectedFlatRows?.[0]?.original}
      {...props}
    />
  );
};
