import { callAPI } from 'utils/ajax';

const CloudRecording = {
  search(input = {}) {
    return callAPI('GET', '/cloud_recording', input);
  },

  signedUrl(key) {
    return callAPI('GET', '/cloud_recording/signedUrl/' + btoa(key));
  },
  getRecordingList(input) {
    return callAPI('GET', '/cloud_recording/getRecordingList', input);
  },

  getBucketSize(cameraId) {
    return callAPI('GET', '/cloud_recording/getBucketSize/' + cameraId);
  }
};

export default CloudRecording;
