import React from 'react';
import { useEventList } from 'hooks/use-event-list';
import EventList from 'components/event-list';

const CameraEventList = ({ camera }) => {
  const [filters, setFilters] = React.useState();
  const [selection, setSelection] = React.useState('');

  React.useEffect(() => {
    setFilters((prev) => ({ ...prev, category: selection }));
  }, [selection]);

  React.useEffect(() => {
    setFilters({
      camera_id: camera?.id,
      category: null
    });
  }, [camera]);

  const query = useEventList({
    filters
  });
  return (
    <>
      {/* <EventListFilters
        filters={FILTERS}
        selection={selection}
        setSelection={setSelection}
      /> */}
      <EventList query={query} />
    </>
  );
};

export default CameraEventList;
