import React from 'react';
import { css } from '@emotion/react';
import { Dash, Plus } from 'react-bootstrap-icons';
import { AuxiliaryButton } from 'components/buttons';
/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';

export const Container = styled('div')`
  margin-inline: 4px;
  display: flex;
  align-items: center;
  width: fit-content;
  grid-template-columns: 1fr 1fr;
  column-gap: 0.3rem;

  label {
    grid-row: 1;
    grid-column: 1 / span 2;
    padding: 0.2rem;

    min-width: 20px;
    text-align: center;
    color: var(--secondary-font-color);
  }
  > button {
    height: 36px;
    width: 36px;
    padding: 0;
  }

  button:first-of-type {
    //   border-right: 1px solid var(--neutral-04);
  }
  button:last-of-type {
    //   border-left: 1px solid var(--neutral-04);
  }
`;

export default ({ selectedView, setSelectedView, index }) => {
  return (
    <article
      css={css`
        display: flex;
        gap: 0.2rem;
        margin-left: auto;
      `}
    >
      <Container>
        <AuxiliaryButton
          compact
          type="button"
          onClick={() => {
            const newLayout = [...selectedView.layout];
            let currentRows = newLayout[index].rows ?? 1;
            if (currentRows == 1) return;
            newLayout[index].rows = currentRows - 1;
            setSelectedView((prev) => ({
              ...prev,
              layout: newLayout
            }));
          }}
        >
          <Dash />
        </AuxiliaryButton>

        <label>
          Rows
          <span> ({selectedView.layout[index].rows ?? 1})</span>
        </label>
        <AuxiliaryButton
          compact
          type="button"
          onClick={() => {
            const newLayout = [...selectedView.layout];
            let currentRows = newLayout[index].rows ?? 1;
            if (currentRows == 5) return;
            newLayout[index].rows = currentRows + 1;
            setSelectedView((prev) => ({
              ...prev,
              layout: newLayout
            }));
          }}
        >
          <Plus size={24} />
        </AuxiliaryButton>
      </Container>
      <Container>
        <AuxiliaryButton
          compact
          type="button"
          onClick={() => {
            const newLayout = [...selectedView.layout];
            let currentCols = newLayout[index].cols ?? 1;
            if (currentCols == 1) return;
            newLayout[index].cols = currentCols - 1;
            setSelectedView((prev) => ({
              ...prev,
              layout: newLayout
            }));
          }}
        >
          <Dash />
        </AuxiliaryButton>
        <label>
          Columns
          <span> ({selectedView.layout[index].cols ?? 1})</span>
        </label>

        <AuxiliaryButton
          compact
          type="button"
          onClick={() => {
            const newLayout = [...selectedView.layout];
            let currentCols = newLayout[index].cols ?? 1;
            if (currentCols == 5) return;

            newLayout[index].cols = currentCols + 1;
            setSelectedView((prev) => ({
              ...prev,
              layout: newLayout
            }));
          }}
        >
          <Plus size={24} />
        </AuxiliaryButton>
      </Container>
    </article>
  );
};
