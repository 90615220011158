import { AuxiliaryButton, Button, CancelButton } from 'components/buttons';
import { Dialog, DialogContent, DialogTrigger } from 'components/dialog.js';
import React from 'react';
import { css } from '@emotion/react';
import { Form, Field } from 'formik';
import { InputLabel, TextField } from 'components/form';
/** @jsxImportSource @emotion/react */
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { Formik } from 'formik';
import { useMutationWrapper } from 'utils/ajax';
import * as Yup from 'yup';
import { useQueryClient } from 'react-query';
import MonitoringView from 'api/monitoring_view';
import { QueryClient } from 'react-query';
import { DEFAULT_VIEW } from 'routes/monitoring';

export default ({
  setSelectedItem,
  setSelectedView,
  selectedView,
  setIsEdit,
  setViews,
  views,
  children
}) => {
  const [viewType, setViewType] = React.useState();

  const [open, setOpen] = React.useState();

  const currentView = selectedView ?? DEFAULT_VIEW;

  const { mutate } = useMutationWrapper(
    ['monitoring', 'save'],
    (values) =>
      MonitoringView.save(
        { layout: currentView.layout, name: values.name },
        selectedView?.id
      ),
    {
      onSuccess: ({ view_id }, { name }) => {
        setSelectedView({ ...currentView, id: view_id, name });
        let index = views.findIndex((v) => v.id == selectedView?.id);
        if (index > -1) {
          let newViews = [...views];
          newViews[index] = { ...currentView, id: view_id, name };
          setViews(newViews);
        } else
          setViews((prev) => [...prev, { ...currentView, id: view_id, name }]);
        setOpen(false);
        !selectedView && setIsEdit(true);
      }
    }
  );

  const SCHEMA = Yup.object().shape({});
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent
        title={selectedView ? 'Edit Name' : 'Add View'}
        onCloseAutoFocus={(e) => e.preventDefault()}
        onOpenAutoFocus={(e) => e.preventDefault()}
        css={css`
          width: 400px;
          height: auto;
          > form {
            margin: 0px;
          }
        `}
      >
        <div>
          <Formik
            enableReinitialize={true}
            validationSchema={SCHEMA}
            initialValues={{ name: selectedView?.name }}
            onSubmit={(values) => {
              mutate(values);
            }}
          >
            {({ values }) => (
              <Form>
                <Field as={TextField} name="name" compcat autoComplete="off" />

                <footer
                  css={css`
                    margin-top: 16px;
                    display: flex;
                    justify-content: space-between;
                  `}
                >
                  <Button
                    compact
                    type="submit"
                    disabled={selectedView?.name == values.name}
                  >
                    Submit
                  </Button>
                  <DialogPrimitive.Close aria-label="Close">
                    <CancelButton compact />
                  </DialogPrimitive.Close>
                </footer>
              </Form>
            )}
          </Formik>
        </div>
      </DialogContent>
    </Dialog>
  );
};
