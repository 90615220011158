import { dataFetchQuery } from 'utils/ajax';
import { API_HOST_URL } from './api-url';
const API_URL = API_HOST_URL + '/camera_api';

const Camera = {
  save(input = {}, id) {
    return dataFetchQuery(`${API_URL}/save/` + id, input);
  },
  get(id = '') {
    return dataFetchQuery(`${API_URL}/get/` + id);
  },
  savePreferences(input = {}, id) {
    return dataFetchQuery(`${API_URL}/savePreferences/` + id, input);
  },
  search(input = {}) {
    return dataFetchQuery(`${API_URL}/search`, input);
  },

  searchLPR(input = {}) {
    return dataFetchQuery(`${API_URL}/search`, { ...input, lpr: true });
  },

  getStats(input = {}) {
    return dataFetchQuery(`${API_URL}/getStats`, input);
  },

  add(input = {}) {
    return dataFetchQuery(`${API_URL}/add`, input);
  },
  edit(input = {}, id) {
    return dataFetchQuery(`${API_URL}/edit/` + id, input);
  },

  getStreamUrl(id) {
    return dataFetchQuery(`${API_URL}/getStreamURL/` + id);
  },
  delete(id) {
    return dataFetchQuery(`${API_URL}/delete/` + id);
  },
  snap(id) {
    return dataFetchQuery(`${API_URL}/snap/` + id);
  },
  getRTSPSessionToken(id) {
    return dataFetchQuery(`${API_URL}/getRTSPSessionToken/` + id);
  },

  getFeedViewCount(id) {
    return dataFetchQuery(`${API_URL}/getFeedViewCount/` + id);
  },
  getAll(input) {
    return dataFetchQuery(`${API_URL}/getAll`, input);
  },

  lprActivity(input = {}, id) {
    return dataFetchQuery(`${API_URL}/lprActivity/${id}`, input);
  }
};

export default Camera;
