import styled from '@emotion/styled';
import React from 'react';
import Breadcrumbs from 'components/breadcrumbs.js';

const ContentWrapper = styled('div')`
  display: flex;
  user-select: none;
  flex-direction: column;
  padding: 24px 48px 24px 48px;
  background: var(--content-background-color);
  overflow: auto;
  box-sizing: border-box;
  grid-area: content;
`;

const Content = ({ children, ...props }) => {
  return (
    <ContentWrapper id="content" {...props} title="">
      {children}
    </ContentWrapper>
  );
};

export default Content;
