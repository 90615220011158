import { callAPI } from 'utils/ajax';

const RecordingExports = {
  search(input = {}) {
    return callAPI('GET', '/recording-export', input);
  },
  signedUrl(key) {
    return callAPI('GET', '/recording-export/signedUrl/' + key);
  },

  add(input = {}) {
    return callAPI('POST', '/recording-export', input);
  }
};

export default RecordingExports;
