/** @jsxImportSource @emotion/react */

import Device from 'api/device';
import React from 'react';
import { useQuery } from 'react-query';
import { useAuth } from 'providers/auth';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { HddNetworkFill } from 'react-bootstrap-icons';
import { Badge } from './badge';
import { IconAlertTriangleFilled } from '@tabler/icons-react';

const StatsWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const SystemStatus = styled('span')`
  font-weight: bold;
  white-space: nowrap;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: right;
  text-transform: uppercase;
  color: var(--neutral-00);
  letter-spacing: 0.75;
  &:before {
    content: 'System Status';
  }
`;

const StatusLabel = styled('span')`
  margin-left: 5px;
  display: flex;
  align-items: center;

  font-size: 12px;
  display: flex;
  align-items: center;
  text-align: right;
  text-transform: uppercase;

  letter-spacing: 0.75;
  border-radius: 50px;

  padding: 4px 12px;
  background-color: ${(props) => props.color};
  color: black;
`;

const ControllerStats = (props) => {
  const { isDetails } = props;

  const { focusedSite } = useAuth();

  // let filter = React.useCallback((data) => {
  //   if (["offline", "online"].includes(data?.type)) return true;
  //   return false;
  // }, []);

  // const { lastMessage } = useWebSocket({ filter });

  const { data } = useQuery(
    ['devices', 'stats', focusedSite],
    () => Device.stats(),
    {
      //refetch only once
      refetchInterval: 5e6,
      staleTime: 60000,
      cacheTime: 60000
    }
  );

  return (
    <StatsWrapper>
      <SystemStatus />
      {((data?.offline == 0 && data.unsynced == 0) || data?.total == 0) &&
        data?.alerts == 0 && <Badge color="success"> OK</Badge>}

      {data?.offline > 0 && (
        <span
          css={css`
            display: flex;
            align-items: center;
            gap: 10px;
            color: var(--neutral-00);
          `}
        >
          <Badge color="danger">offline</Badge>
          <HddNetworkFill color="red" />
          {data?.offline}
        </span>
      )}

      {data?.unsynced > 0 && (
        <span
          css={css`
            display: flex;
            font-weight: 500;
            align-items: center;
            gap: 10px;
            color: var(--neutral-00);
          `}
        >
          <Badge color="warning">Unsynced</Badge>
          <HddNetworkFill color="orange" />
          {data?.unsynced}
        </span>
      )}
      {data?.alerts > 0 && (
        <span
          css={css`
            display: flex;
            font-weight: 500;
            align-items: center;
            gap: 10px;
            color: var(--neutral-00);
            svg {
              color: red;
              fill: red;
              height: 16px;
              width: 16px;
            }
          `}
        >
          <Badge color="danger">Alerts</Badge>
          <IconAlertTriangleFilled />
          {data?.alerts}
        </span>
      )}

      {isDetails && data && (
        <>
          <span>
            {data?.online}
            {data?.offline > 0 && '/' + data?.offline}
            {data?.unsynced > 0 && '/' + data?.unsynced}
          </span>
        </>
      )}
    </StatsWrapper>
  );
};

export default ControllerStats;
