import React from 'react';
/** @jsxImportSource @emotion/react */
import { ReactComponent as NoRecordings } from 'assets/images/no-recordings.svg';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Layer } from 'components/video';
import { OverlayHolder } from 'components/video/overlay';
import { CameraVideo, CameraVideoOff } from 'react-bootstrap-icons';
export default ({ children, err }) => {
  return (
    <Layer>
      <OverlayHolder
        className="error-overlay"
        css={css`
          svg > * {
            stroke: var(--red-base);
          }
          text-align: center;
        `}
      >
        <label>Error</label>
        <label>{err?.message}</label>
        {children}
      </OverlayHolder>
    </Layer>
  );
};
