import React, { Fragment } from "react";
import { Field } from "formik";
import { TextField } from "components/form";
import { css } from "@emotion/react";
/** @jsxImportSource @emotion/react */
import { useFormikContext } from "formik";
import { RadioGroup } from "@headlessui/react";

import { HexColorPicker } from "react-colorful";
const colors = [
  "#6b5b95",
  "#feb236",
  "#d64161",
  "#ff7b25",
  "#a2b9bc",
  "#ABDB66",
  "#e9a88a",
];

const boxStyles = (props) => css`
  width: 48px;
  height: 48px;
  background: ${props.color};
  border-radius: 5px;
  cursor: pointer;
  &[aria-checked="true"] {
    border: 2px solid black;
  }
`;

const groupStyles = css`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 16px 0px;

  &[role="radiogroup"] {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
`;

const ColorBox = (props) => {
  return (
    <RadioGroup.Option as={Fragment} value={props.color}>
      {({ checked }) => <div css={boxStyles(props)}></div>}
    </RadioGroup.Option>
  );
};

const styles = css`
  width: 100%;
  padding-bottom: 20px;
  > div.colors {
    display: flex;
    gap: 8px;
  }
  > div.react-colorful {
    width: auto;
  }
`;

const GroupForm = (props) => {
  const { values, setFieldValue } = useFormikContext();

  let [currentColor, setCurrentcolor] = React.useState(values.color);

  React.useEffect(() => setFieldValue("color", currentColor), [currentColor]);

  return (
    <article css={styles}>
      <Field name="name" as={TextField} />

      <Field name="color" as={TextField} />
      <div className="colors">
        <RadioGroup
          css={groupStyles}
          value={values.color}
          onChange={setCurrentcolor}
        >
          {colors.map((i) => (
            <ColorBox key={i} color={i} />
          ))}
        </RadioGroup>
      </div>
      <HexColorPicker color={currentColor} onChange={setCurrentcolor} />
    </article>
  );
};

export default GroupForm;
