import React from 'react';
import Site from 'api/site';
import {
  CameraVideo,
  ChevronDown,
  CircleFill,
  DoorClosed,
  DoorOpen,
  Search
} from 'react-bootstrap-icons';
import AsyncSelect from 'components/headless-select/async';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useQueryWrapper } from 'utils/ajax';
/** @jsxImportSource @emotion/react */
import SelectWrapper from 'components/headless-select';
import { IconButton } from 'components/buttons';
import PopoverContent, {
  Popover,
  PopoverAnchor,
  PopoverPortal,
  PopoverTrigger
} from 'components/popover/popover-content';
import { FeatureGroup, useMap } from 'react-leaflet';

export default ({ cameras, editLayerRef: ref, mapRef }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [value, setValue] = React.useState(null);

  function getCameraIcon(camera) {
    //let color = camera.online == '1' ? '#00A96C' : '#ea3737';
    let color = '#7f8795';
    return L.divIcon({
      className: 'custom-icon camera',
      html: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-device-cctv">
  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
  <path d="M3 3m0 1a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-16a1 1 0 0 1 -1 -1z" />
  <path d="M12 14m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" />
  <path d="M19 7v7a7 7 0 0 1 -14 0v-7" />
  <path d="M12 14l.01 0" />
</svg>`
    });
  }
  const selectStyles = {
    control: (provided) => ({
      ...provided,

      width: 'auto',
      margin: 8,
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
    }),
    menu: () => ({
      border: 'none !important'
    }),
    menuList: (base) => ({
      ...base,
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 0px inset'
    })
  };
  return (
    <Popover>
      <PopoverTrigger>
        <IconButton
          title="Add A Camera Marker"
          onClick={() => setIsOpen((prev) => !prev)}
        >
          <CameraVideo />
        </IconButton>
      </PopoverTrigger>

      <PopoverContent
        align="start"
        css={css`
          width: 250px;
        `}
      >
        <SelectWrapper
          idx="value"
          label="name"
          styles={selectStyles}
          noValueContainer
          isClearable={false}
          placeholder="Search cameras..."
          name="cameras"
          checkboxes={false}
          data-compact
          icon={Search}
          itemComponents={{ DropdownIndicator: null }}
          menuIsOpen={true}
          //onChange={(e) => setGlobalAccessLevel(e)}
          options={
            cameras?.map((d) => ({
              value: d,
              name: d.name
            })) || []
          }
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          closeMenuOnSelect={true}
          value={value}
          isOptionDisabled={(e) => {
            let allLayers = ref.current.getLayers();

            let layerExists = allLayers
              ?.filter((l) => l instanceof L.Marker)
              .find(
                (l) =>
                  (l?.properties?.id == e.value.id &&
                    l?.properties?.type == 'camera') ||
                  (l?.options?.properties?.id == e.value.id &&
                    l?.options?.properties?.type == 'camera')
              );

            return layerExists;
          }}
          onChange={(e) => {
            let { id, type, name } = e.value;

            let allLayers = ref.current.getLayers();

            let layerExists = allLayers
              ?.filter((l) => l instanceof L.Marker)
              .find(
                (l) =>
                  (l?.properties?.id == id &&
                    l?.properties?.type == 'camera') ||
                  (l?.options?.properties?.id == id &&
                    l?.options?.properties?.type == 'camera')
              );

            if (layerExists) {
              layerExists.setIcon(
                getCameraIcon({ name })
                //L.icon({
                //iconUrl: `https://chart.googleapis.com/chart?chst=d_bubble_text_small&chld=bb|${name}|FFFF00|000000`
                //})
              );
            }
            if (!layerExists) {
              let marker = new L.Marker(mapRef.current.getCenter(), {
                pmIgnore: false,
                icon: getCameraIcon(e.value)
              }).bindTooltip(name, {
                permanent: true,
                direction: 'right'
              });
              marker.properties = { ...e.value, hidden: null, type: 'camera' };
              marker.addTo(ref.current);
              // TOTO why cant i just ad marker ?
              // TODO check if there is another way to put properties
              {
                /* mapRef.current.pm.enableDraw('Marker', {
              markerEditable: true,
              markerStyle: {
                icon: getDoorIcon({ name }),
                properties: { ...e.value.properties, hidden: null }
              },
              tooltips: false,
              continueDrawing: false
            }); */
              }
              marker.pm.enableLayerDrag();
              marker.on('click', (data) => {
                const isEdit =
                  mapRef.current.pm.globalDragModeEnabled() ||
                  mapRef.current.pm.globalEditModeEnabled();
                if (isEdit) return;
                marker.options.rotation = (marker.options.rotation ?? 0) + 20;
                marker.update();
              });
            }
            setValue(e);
          }}
        />
      </PopoverContent>
    </Popover>
  );
};
