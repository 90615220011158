import React from 'react';
import { useQueryWrapper } from 'utils/ajax';
import Camera from 'api/camera';
import { Truck } from 'react-bootstrap-icons';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */

export default (props) => {
  const { camera, filter } = props;

  const { data, isLoading } = useQueryWrapper(
    ['camera', camera.id, 'lprActivity', filter],
    () =>
      Camera.lprActivity(
        {
          camera_id: camera.id,
          lpr: true,
          from: filter?.from,
          to: filter?.to,
          groupBy: '$date'
        },
        camera.id
      ),
    {
      staleTime: 30000,
      cacheTime: 60000
    }
  );

  return (
    <ul>
      <li>
        <div
          css={css`
            filter: ${isLoading ? 'grayscale(1)' : 'grayscale(0)'};
            background: var(--orange-v-01);
            color: var(--orange-base);
          `}
        >
          <Truck />
        </div>
        <span className="name">Unfamiliar Plates</span>
        <span className="value">
          {data
            ?.map((d) => d.licensePlateUnfamiliar)
            .reduce((a, b) => a + b, 0)}
        </span>
      </li>
      <li>
        <div
          css={css`
            filter: ${isLoading ? 'grayscale(1)' : 'grayscale(0)'};
            background: var(--primary-v-02);
            color: var(--primary-base);
          `}
        >
          <Truck />
        </div>
        <span className="name">Granted Plates</span>
        <span className="value">
          {data?.map((d) => d.licensePlateGranted).reduce((a, b) => a + b, 0)}
        </span>
      </li>
      <li>
        <div
          css={css`
            filter: ${isLoading ? 'grayscale(1)' : 'grayscale(0)'};
            background: var(--red-v-02);
            color: var(--red-base);
          `}
        >
          <Truck />
        </div>
        <span className="name">Denied Plates</span>
        <span className="value">
          {data?.map((d) => d.licensePlateDenied).reduce((a, b) => a + b, 0)}
        </span>
      </li>
    </ul>
  );
};
