import React from "react";
import { Dialog } from "@radix-ui/react-dialog";
import { DialogTrigger, DialogContent } from "components/dialog.js";
import EventStreamChart from "components/charts/event-stream-chart";
import { css } from "@emotion/react";
/** @jsxImportSource @emotion/react */

const DeviceEventStream = ({ device }) => {
  if (!device) return null;

  const [filters, setFilters] = React.useState({ interval: 5, timeframe: 60 });

  return (
    <Dialog>
      <DialogTrigger
        css={css`
          &:hover {
            cursor: pointer !important;
            outline: 3px solid var(--secundary-v-01);
            .echarts-for-react {
              pointer-event: none;
            }
          }
        `}
      >
        <EventStreamChart device={device} slim />
      </DialogTrigger>
      <DialogContent>
        <header
          css={css`
            display: flex;
            align-items: center;
            gap: 0.5rem;
            justify-content: flex-end;
            margin-right: 1rem;
          `}
        >
          <label>Time Frame: </label>
          <select
            value={filters.timeframe}
            onChange={(e) =>
              setFilters((prev) => ({
                ...prev,
                timeframe: e.target.value,
              }))
            }
          >
            <option value={15}>Last 15 minutes</option>
            <option value={60}>Last 60 minutes</option>
            <option value={120}>Last 2 hours</option>
            <option value={600}>Last 10 hours</option>
          </select>
          <label>Interval: </label>
          <select
            value={filters.interval}
            onChange={(e) =>
              setFilters((prev) => ({
                ...prev,
                interval: e.target.value,
              }))
            }
          >
            <option value={1}>Every 1 minute</option>
            <option value={5}>Every 5 minutes</option>
            <option value={15}>Every 15 Minutes</option>
            <option value={30}>Every 30 Minutes</option>
          </select>
        </header>
        <EventStreamChart height="90%" device={device} filters={filters} />
      </DialogContent>
    </Dialog>
  );
};

export default DeviceEventStream;
