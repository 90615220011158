import React from "react";
import { Field } from "formik";
import { css } from "@emotion/react";
import UserFields from "views/users/user-fields";
import { TextField, Toggle } from "components/form";
import InstallerFeatures from "./installer-features";
/** @jsxImportSource @emotion/react */

const styles = css`
  color: var(--neutral-00);
`;

const InstallerForm = (props) => {
  return (
    <>
      <section css={styles}>
        <Field name="enabled" as={Toggle} />
        <br />
        <Field name="name" as={TextField} />
        <Field name="phone" as={TextField} />
        <Field name="contact_email" as={TextField} />
        <Field name="address" as={TextField} />
        <InstallerFeatures {...props} />
      </section>
      {!props.installer && (
        <>
          <h3
            css={css`
              color: var(--neutral-00);
            `}
          >
            Initial User
          </h3>
          <section>
            <UserFields prefix="user." />
          </section>
        </>
      )}
    </>
  );
};

export default InstallerForm;
