import React from 'react';
import { IndeterminateCheckBox } from 'components/form';
import { useFormikContext } from 'formik';

import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import { useAuth } from 'providers/auth';

let cf = {
  overview_enabled: 'Overview',
  monitoring: 'Monitoring',
  notifications_enabled: 'Notifications',
  reports_enabled: 'Reports',
  reports_auto: 'Automatic Reports',
  logs_enabled: 'Logs',
  tc_enabled: 'Time Clock',
  intercoms: 'Intercoms',
  cameras_enabled: 'Cameras',
  elevators: 'Elevators',
  badging: 'Badging',
  license_plates: 'License Plates',
  sensors: 'Sensors'
};

const isObject = (object) => object != null && typeof object === 'object';

const styles = css`
  color: var(--neutral-00);
  display: grid;
  gap: 16px;
  grid-template-columns: var(--form-column-width) var(--form-column-width);
  padding-top: 24px;
  min-width: 650px;

  > label {
    margin-bottom: 8px;
  }
  ul {
    display: grid;
    grid-template-columns: var(--form-column-width) var(--form-column-width);
    gap: 24px;
    width: fit-content;
  }
`;

const CompanyFeatures = (props) => {
  const { values, setFieldValue } = useFormikContext();

  const [group, setGroup] = React.useState();

  const { user } = useAuth();

  const installerFeatures = props.company
    ? props.company?.installer_features
    : user?.installer_features;

  React.useEffect(() => {
    if (group == 0) setFieldValue('features', {});
    else if (group == 1) {
      const allFeature = {};
      Object.keys(cf).forEach((f) => {
        if (installerFeatures[f] == true || installerFeatures == 'on')
          allFeature[f] = true;
      });
      setFieldValue('features', allFeature);
    } else if (group == -1) return;
  }, [group]);

  React.useEffect(() => {
    if (
      Object.values(values?.features).filter((p) => p == true).length ==
      Object.values(installerFeatures).filter((p) => p == true).length
    )
      setGroup(1);
    else if (
      Object.values(values.features).filter((p) => p == true).length <
      Object.values(installerFeatures).filter((p) => p == true).length
    )
      setGroup(-1);
    else setGroup(0);
  }, [values.features]);

  if (isObject(installerFeatures) && Object.keys(installerFeatures).length > 0)
    return (
      <article css={styles}>
        <h4
          css={css`
            grid-column: 1/-1;
            color: var(--neutral-00);
          `}
        >
          Features
        </h4>
        {Object.keys(installerFeatures).length > 2 && (
          <>
            <IndeterminateCheckBox
              label="All"
              value={group}
              onChange={(e) => setGroup(e.target.checked ? 1 : 0)}
            />
            <br />
          </>
        )}

        {Object.entries(cf).map((i) => {
          if (installerFeatures?.[i[0]])
            return (
              <IndeterminateCheckBox
                name={i[0]}
                label={i[1]}
                value={values?.features?.[i[0]] == true ? 1 : 0}
                onChange={(e) =>
                  setFieldValue('features', {
                    ...values?.features,
                    [i[0]]: e.target.checked
                  })
                }
              />
            );
        })}
        <br />
      </article>
    );
  else return null;
};

export default CompanyFeatures;
