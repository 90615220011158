import React from 'react';
import Recover from 'routes/recover';
import {
  Redirect,
  BrowserRouter,
  Link,
  Route,
  Switch,
  useHistory
} from 'react-router-dom';
import { useAuth, UserContext } from './providers/auth.js';
import ErrorHandler from './providers/error-handler.js';

import Login from 'routes/login';
import { useLocation } from 'react-router-dom';
import ROUTES from 'base/routes';

import { useTheme } from 'providers/theme.js';
import { useModal } from 'providers/modal-provider.js';
import { useLayout } from 'providers/layout-provider.js';
import Devices from 'routes/devices/index.js';
import { ErrorBoundary } from 'react-error-boundary';
import Template from 'base/template.js';
import Alert from 'components/alert.js';
const AuthRoute = ({ component: Component, ...rest }) => {
  let { user, setUser } = React.useContext(UserContext);

  const location = useLocation();
  return (
    <Route
      {...rest}
      render={(props) => {
        if (user) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: '/',
                state: {
                  from: location
                }
              }}
            />
          );
        }
      }}
    />
  );
};

function getCookie(cName) {
  const name = cName + '=';
  const cDecoded = decodeURIComponent(document.cookie); //to be careful
  const cArr = cDecoded.split('; ');
  let res;
  cArr.forEach((val) => {
    if (val.indexOf(name) === 0) res = val.substring(name.length);
  });
  return res;
}

function MyFallbackComponent({ error, resetErrorBoundary }) {
  return (
    <Template>
      <div style={{ padding: 20 }}>
        <Alert>
          <span>
            Somethig went wrong and the current view can not be loaded. <br />
            Please contact support.
            <code style={{ display: 'block', paddingTop: 10 }}>
              Error code: 1001 display
            </code>
          </span>
        </Alert>
      </div>
    </Template>
  );
}

function SSOAuth() {
  const { validateSession } = useAuth();

  React.useEffect(() => {
    let params = new URLSearchParams(document.location.search);

    let session = params.get('session'); // is the string "Jonathan"
    if (!session) return m.route.set('/login');
    validateSession.mutate(session);
  }, []);

  return <div>Validating you</div>;
}

function PrivateRoute({ component: Component, ...rest }) {
  let { user, setUser } = React.useContext(UserContext);
  let { logout } = useAuth();
  const history = useHistory();
  const previousPath = rest.location.pathname;

  React.useEffect(() => {
    // TODO maybe show alert
    if (!user) return;
    let sess = localStorage.getItem('user');
    if (!sess) return;

    let sessExp = JSON.parse(sess).expiration;
    if (!sessExp) return;

    if (Date.now() > sessExp * 1000)
      history.replace(history.location.pathname, {
        errorStatusCode: 401
      });
  }, [user]);

  if (
    user &&
    Array.isArray(rest.role) &&
    !('A' + rest.role).includes(user?.role)
  )
    return (
      <Redirect
        to={{
          pathname: '/',
          state: { errorStatusCode: 404 }
        }}
      />
    );

  if (typeof rest.valid == 'function' && user)
    if (!rest.valid(user))
      return (
        <Redirect
          to={{
            pathname: '/',
            state: { errorStatusCode: 404 }
          }}
        />
      );

  return (
    <Route
      {...rest}
      render={(props) => {
        props.crumbs = ROUTES.filter(({ path }) =>
          props.match.path.includes(path)
        ).map(({ path, ...rest }) => ({
          path: Object.keys(props.match.params).length
            ? Object.keys(props.match.params).reduce(
                (path, param) =>
                  path.replace(`:${param}`, props.match.params[param]),
                path
              )
            : path,
          ...rest
        }));

        return user ? (
          <ErrorBoundary FallbackComponent={MyFallbackComponent}>
            <Component {...rest} {...props} />
          </ErrorBoundary>
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: previousPath } }}
          />
        );
      }}
    />
  );
}

const Navigation = ({ children }) => {
  const location = useLocation();
  const { dialog } = useModal();
  const { user } = useAuth();

  React.useEffect(() => {
    // close dialog if open
    dialog && dialog.hide();
  }, [location]);

  return (
    <Switch>
      {ROUTES.map(({ path, Component, ...rest }, key) => (
        <PrivateRoute
          exact
          path={path}
          key={key}
          component={Component}
          {...rest}
        />
      ))}
      <Route path="/recover/:hash" component={Recover} />
      <Route path="/sso" component={SSOAuth} />
      <Route path="/" component={Login} />

      {children}
    </Switch>
  );
};

export default Navigation;
