import { callAPI } from 'utils/ajax';

const Speaker = {
  search(input = {}) {
    return callAPI('GET', `/speaker`, input);
  },
  get(id = '') {
    return callAPI('GET', `/speaker/${id}`);
  },
  stats(input = {}) {
    return callAPI('GET', `/speaker/stats`, input);
  },
  save(input = {}, id) {
    return callAPI('PUT', `/speaker/${id}`, input);
  }
};

export default Speaker;
