/** @jsxImportSource @emotion/react */
import React from 'react';
import '../css/login.css';
import TextField from '../components/form/text-field';
import PasswordField from '../components/form/password-field';
import { useAuth } from 'providers/auth.js';
import { Redirect } from 'react-router-dom';
import { Button } from 'components/buttons';
import { Input } from 'components/form';
import { Formik } from 'formik';
import { css, Global } from '@emotion/react';
import Background from 'assets/images/header-background.png';

import styled from '@emotion/styled';
import * as Yup from 'yup';
import { Facebook, Linkedin } from 'react-bootstrap-icons';
import { useMutationWrapper } from 'utils/ajax';
import RenderLogo from 'utils/use-logo';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Account from 'api/account';

const Footer = styled('footer')`
  position: fixed;
  bottom: 40px;
  padding: 24px 96px;

  width: 100%;
  display: flex;

  align-items: center;
  justify-content: space-between;
  gap: 20px;
  height: 12px;
  a {
    color: #868686 !important;
    font-size: 12px;
  }
`;

const footerIconStyles = css`
  width: 20px;
  height: 20px;
  color: #27ba62;
`;
const Login = () => {
  const { user, login } = useAuth();
  const history = useHistory();

  const SCHEMA = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
    password: Yup.string().required('Required'),
    sms_code: Yup.string()
  });

  const INITIAL_VALUES = {
    email: '',
    password: '',
    sms_code: '',
    tz_offset: new Date().getTimezoneOffset()
  };

  const recover = useMutationWrapper(
    ['user', 'recover'],
    (email) => Account.recover(email),
    {
      successMessage: `An email was sent to you with instructions on how to reset your password`
    }
  );
  if (user?.home_path && user?.home_path !== 'default')
    return <Redirect to={user.home_path} />;

  if (user?.role == 'I') return <Redirect to="/dashboard" />;
  else if (user?.role == 'V') return <Redirect to="/installers" />;
  else if (user?.role == 'A') return <Redirect to="/manage-users" />;
  else if (user?.role == 'C') return <Redirect to="/doors" />;

  return (
    <div
      css={css`
        //  background-image: url(${Background});
        background-color: #f8f9fc;
      `}
    >
      <Global
        styles={{
          button: {
            width: '100%'
          },

          body: {
            background: `linear-gradient(254.73deg, #0E0E0E 9.13%, #303030 93.13%)`
          }
        }}
      />
      <div
        css={css`
          height: 100vh;
          width: 100vw;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        `}
      >
        <div
          css={css`
            width: 450px;
          `}
        >
          <div
            css={css`
              text-align: center;
            `}
          >
            <RenderLogo
              login
              css={css`
                margin-bottom: 40px;
                width: 400px;
                height: 100px;
              `}
            />
          </div>

          <Formik
            validationSchema={SCHEMA}
            initialValues={INITIAL_VALUES}
            onSubmit={(values) => {
              values[user?.missing2fa ? 'sms_code' : 'mfa_code'] =
                values.d1 +
                values.d2 +
                values.d3 +
                values.d4 +
                values.d5 +
                values.d6;
              login.mutate(values);
            }}
          >
            {({
              setFieldValue,
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              errors
            }) => (
              <form
                css={css`
                  display: flex;
                  flex-direction: column;
                  gap: 10px;
                  div {
                    letter-spacing: 0.75;
                  }
                  button:first-of-type {
                    margin-top: 24px;
                  }
                `}
                onSubmit={handleSubmit}
              >
                {user?.missing2fa || user?.missingMfa ? (
                  <>
                    <p
                      css={css`
                        font-weight: 400;
                        color: var(--neutral-00);
                        font-size: 14px;
                        text-align: center;

                        div {
                          font-size: 20px;
                        }
                      `}
                    >
                      {user?.missing2fa && (
                        <div>
                          We have sent a code via SMS to xxxxxxx
                          <b>{user.missing2fa}</b>
                        </div>
                      )}
                      {user?.missingMfa && (
                        <div>Please enter your MFA code</div>
                      )}
                    </p>

                    <section
                      css={css`
                        display: flex;
                        gap: 12px;
                        justify-content: center;
                        input {
                          width: 65px;
                          height: 64px;
                          font-size: 24px;
                          font-weight: 700;
                          text-align: center;
                          border-radius: 5px;
                          color: var(--neutral-00);
                        }
                      `}
                    >
                      {[1, 2, 3, 4, 5, 6].map((n) => (
                        <Input
                          name={'d' + n}
                          value={values['d' + n]}
                          onChange={(ev) => {
                            // Handle pasting in the first input
                            const v = ev.target.value.replace(/\D/g, '');
                            if (n == 1 && v.length > 5) {
                              v.slice(-6)
                                .split('')
                                .forEach((d, idx) =>
                                  setFieldValue('d' + String(idx + 1), d)
                                );
                              return;
                            }

                            // Clips to 1 character
                            setFieldValue('d' + n, v[0]);

                            // Focus next element after typing
                            v[0] &&
                              ev.target.nextElementSibling &&
                              ev.target.nextElementSibling.focus();
                          }}
                          onKeyUp={(ev) => {
                            // Focus prev element on Backspace
                            ev.key == 'Backspace' &&
                              ev.target.previousElementSibling &&
                              ev.target.previousElementSibling.focus();
                          }}
                        />
                      ))}
                    </section>

                    <Button loading={login.isLoading} type="submit">
                      Verify
                    </Button>
                  </>
                ) : (
                  <>
                    <TextField
                      css={css`
                        width: 450px;
                      `}
                      name="email"
                      placeholder="joe@gmail.com"
                      value={values.email}
                    />
                    <PasswordField
                      css={css`
                        width: 450px;
                      `}
                      placeholder="********"
                      name="password"
                      value={values.password}
                    />

                    <Button
                      loading={login.isLoading ? true : undefined}
                      type="submit"
                    >
                      Login
                    </Button>
                  </>
                )}
                {!user?.missing2fa && !user?.missingMfa && (
                  <button
                    type="button"
                    onClick={() => recover.mutate({ email: values.email })}
                    css={css`
                      justify-content: center;
                      font-weight: bold;
                      font-size: 16px;
                      line-height: 24px;
                      display: flex;
                      align-items: center;
                      text-align: center;
                      text-decoration-line: underline;
                      margin-top: 16px;
                      color: #868686;
                    `}
                  >
                    Lost Account
                  </button>
                )}
                <div
                  css={css`
                    line-height: 16px;
                    height: 16px;
                    color: var(--red-base);
                    font-weight: bold;
                  `}
                >
                  {login?.error?.message}
                </div>
                {/* {JSON.stringify(values)} */}
              </form>
            )}
          </Formik>
        </div>
      </div>
      <Footer>
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 20px;
          `}
        >
          <RenderLogo
            css={css`
              height: 50px;
              width: 100px;
            `}
          />
          <span
            css={css`
              color: #868686 !important;
              font-size: 12px;
            `}
          >
            All Rights Reserved
          </span>
        </div>
        <a href="#">Terms And Conditions</a>
        <a href="#">Privacy Policy </a>
      </Footer>
    </div>
  );
};

export default Login;
