import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'providers/auth';

import {
  MenuButton,
  MenuItem,
  MenuItemDelete,
  MenuWrapper
} from 'components/action-menu';
import { useDeleteMutate } from './actions';
import IsUserAllowed from 'components/is-user-allowed';
import useAlertDialog from 'providers/alert-dialog-provider';
export default function ActionMenu({ data: schedule, ...otherProps }) {
  const { user } = useAuth();

  const history = useHistory();
  const deleteMutate = useDeleteMutate(schedule);
  const [isEnabled, setIsEnabled] = React.useState();

  const confirm = useAlertDialog();

  React.useEffect(() => {
    if (!schedule) return;
    let sc = schedule.sites;
    let isEnabled =
      !user.original_sites ||
      (sc && sc.filter((a) => !user.original_sites.includes(a)).length == 0);

    setIsEnabled(otherProps.disabled || isEnabled);
  }, [otherProps.disabled]);

  let handleEdit = (e) =>
    history.push('/schedules/save/' + schedule?.id, {
      schedule,
      desc: schedule?.name
    });

  const handleDelete = async (e) => {
    if (
      await confirm({
        description: `Are you sure you'd like to delete Schedule ${schedule.Name} ?`
      })
    )
      deleteMutate.mutate(schedule?.id, {});
  };

  return (
    <>
      <IsUserAllowed condition="scheduleEdit" restrict="C">
        <MenuWrapper disabled={!isEnabled}>
          <MenuItem label="Edit" onClick={handleEdit} />
          <MenuItemDelete onClick={handleDelete} />
        </MenuWrapper>
      </IsUserAllowed>
    </>
  );
}
