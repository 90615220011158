import { dataFetchQuery, useMutation } from "utils/ajax";

import { API_HOST_URL } from "./api-url";
const API_URL = API_HOST_URL + "/group_api";

const GenericDoor = {
  search(input = {}) {
    return dataFetchQuery(`${API_URL}/search`, input);
  },

  getStats(input) {
    return dataFetchQuery(`${API_URL}/getStats`, input);
  },

  save(input = {}, id = "") {
    return dataFetchQuery(`${API_URL}/save/` + id, input);
  },
  delete(id = "") {
    return dataFetchQuery(`${API_URL}/delete/` + id);
  },
};

export default GenericDoor;
