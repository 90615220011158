import React from 'react';

/** @jsxImportSource @emotion/react */
import {
  TabItem,
  TabGroup,
  TabPanel,
  TabPanels,
  TabList
} from 'components/tabs';
import { css } from '@emotion/react';
import {
  CameraVideo,
  Calendar,
  Record2,
  Sliders,
  CameraVideoOff,
  PersonPlus,
  Stopwatch,
  ShieldShaded,
  Search
} from 'react-bootstrap-icons';
import EventList from 'components/event-list';
import UsageChartHolder from 'components/charts/usage-chart-holder';
import LiveEvents from 'components/live-events';
import AuxiliaryList from 'views/auxiliaries/auxiliary-list';
import LastPerson from './last-person';
import CameraFeed from 'views/cameras/camera-feed';
import SidebarEmptyHolder from 'components/sidebar-empty-holder';
import DoorCameras from './door-cameras';
import WhoseInList, { WhoseinTooltip } from 'components/whosein-list';
import DoorEventList from './door-event-list';
import DoorSchedules from './door-schedules';
import LastAccessFormatter from 'components/last-access-formatter';
import DoorWhoseinList from './door-whosein-list';
import AccessLevelDoor from 'api/access_level_door';
import AsyncSelect from 'components/headless-select/async';
import { components } from 'react-select';
import TableProvider from 'providers/headless-table-provider';
import { useHeadlessTable } from 'providers/headless-table-provider';
import TablePagination from 'components/headless-pagination';
import TableFilter from 'components/headless-table-filter';
import SearchInput from 'components/search-input';
import ActivityChartFormatter from 'components/activity-chart-formatter';

const DoorTabs = ({ door = {} }) => {
  let filter = React.useCallback(
    (data) => {
      if (
        data?.door_token == door.token &&
        ['doorState', 'alarm', 'access'].includes(data.category)
      )
        return true;
      return false;
    },
    [door]
  );

  const [selectedIndex, setSelectedIndex] = React.useState(0);

  React.useEffect(() => setSelectedIndex(0), [door]);

  return (
    <TabGroup selectedIndex={selectedIndex} onChange={setSelectedIndex}>
      <TabList
        css={css`
          width: 100%;
          box-shadow: unset;

          padding: 0px 16px;
          gap: 2rem;
          border-top: 1px solid var(--neutral-12);
          border-bottom: 1px solid var(--neutral-12);

          [role='tab'] {
            min-width: unset;
          }
        `}
      >
        <TabItem title="Events">
          <Calendar size={24} />
        </TabItem>
        <TabItem title="Live Events">
          <Record2 size={32} />
        </TabItem>

        <TabItem disabled={!door.door_token} title="Whose In ?">
          <PersonPlus size={24} />
        </TabItem>

        {door.camera_count != 0 && (
          <TabItem title="Cameras">
            <CameraVideo size={24} />
          </TabItem>
        )}
        {door.auxiliary_count != 0 && (
          <TabItem title="Auxiliaries">
            <Sliders size={24} />
          </TabItem>
        )}
        {door.open_schedule_token &&
          JSON.parse(door.open_schedule_token).length > 0 && (
            <TabItem title="Open Times">
              <Stopwatch size={24} />
            </TabItem>
          )}

        {/* {door.door_token && (
          <TabItem title="Access Levels">
            <ShieldShaded size={22} />
          </TabItem>
        )} */}
      </TabList>
      <TabPanels>
        <TabPanel
          as="div"
          css={css`
            overflow: hidden;
            height: 100%;
            display: grid;
            grid-template-rows: auto 250px;
            gap: 1rem;
            padding: 16px;
            .activity-chart-formatter {
              padding-bottom: 0.5rem;
              height: 100%;
            }
          `}
        >
          {/* {door.token && <LastPerson door={door} />} */}
          <div
            css={css`
              overflow: auto;
            `}
          >
            <DoorEventList door={door} />
          </div>
          <ActivityChartFormatter
            full
            activity={door.activity}
            style={{ height: '100%', width: '100%' }}
            config={{}}
          />
        </TabPanel>
        <TabPanel
          css={css`
            position: relative;
            height: 100%;
            overflow: hidden;
          `}
        >
          <LiveEvents filter={filter} />
        </TabPanel>

        <TabPanel
          as="div"
          id="person"
          css={css`
            overflow: hidden;
            height: 100%;
            display: flex;
            flex-direction: column;
            padding: 16px;
            gap: 1rem;
            h4 {
              line-height: 22px;
            }
          `}
        >
          {door.door_token ? (
            <>
              <h4>Latest Entry</h4>
              <LastPerson data={door} />
              <h4>
                Whose In <WhoseinTooltip />
              </h4>

              <DoorWhoseinList door={door} />
            </>
          ) : (
            <div>Door does not support logged entries</div>
          )}
        </TabPanel>

        {door.camera_count != 0 && (
          <TabPanel
            css={css`
              padding: 1rem;
              height: 100%;
              display: flex;
              flex-direction: column;
            `}
          >
            {door.camera_count == 0 ? (
              <SidebarEmptyHolder
                icon={CameraVideoOff}
                title="No Cameras associated with this door"
                nosubtitle
              />
            ) : (
              <DoorCameras door={door} />
            )}
          </TabPanel>
        )}

        {door.auxiliary_count != 0 && (
          <TabPanel>
            <AuxiliaryList initialFilter={{ door_id: door.id }} />
          </TabPanel>
        )}
        {door.open_schedule_token &&
          JSON.parse(door.open_schedule_token).length > 0 && (
            <TabPanel
              css={css`
                padding: 1rem;
                height: 100%;
                display: flex;
                flex-direction: column;
                dl {
                  display: grid;
                  grid-template-rows: 14px 1fr;
                  row-gap: 0.3rem;
                }

                dt {
                  font-size: 12px;
                  line-height: 100%;
                  color: var(--neutral-01);
                }
                dd {
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 17px;
                  color: var(--neutral-15);
                  margin-bottom: 3rem;
                }
              `}
            >
              <dl>
                <dt>Open Schedules</dt>
                <dd>
                  <DoorSchedules door={door} />
                </dd>
                {door.last_snowday_time && (
                  <>
                    <dt>Last Snow Day Access</dt>
                    <dd>
                      <LastAccessFormatter door={door} snowday />
                    </dd>
                  </>
                )}
              </dl>
            </TabPanel>
          )}
        {/* <TabPanel>
          <UsageChart name={door.name} filters={{ door_id: door?.id }} />
        </TabPanel> */}
        {/* {door.door_token && (
          <TabPanel
            as="div"
            css={css`
              padding: 1rem;
              height: 100%;
              display: flex;
              flex-direction: column;
              overflow: hidden;
            `}
          >
            <div
              css={css`
              display: flex;
              align-items:center;
              span {
                margin-left: auto;
                font-size: 12px;
                color: var(--neutral-01);
              }
              margin-bottom:1rem;l
            `}
            >
              <h3>Access Levels</h3>
              <span>Total: {door.access_level_count}</span>
            </div>
            <TableProvider
              query={AccessLevelDoor.search}
              initialFilter={{
                door_token: door.door_token,
                excludeNoAccess: 1
              }}
              columns={[
                {
                  Header: 'name',
                  Cell: ({ row: { original } }) => (
                    <div
                      css={css`
                        padding: 0.5rem;
                      `}
                    >
                      <span>{original.access_level_name}</span>
                      <span>{original.schedule_name}</span>
                    </div>
                  )
                }
              ]}
            >
              {({ state, data, isLoading, tableState }) => {
                return (
                  <>
                    <TablePagination />
                    <List />
                  </>
                );
              }}
            </TableProvider>
            {/* <AsyncSelect
              fetchFilters={{
                door_token: door.door_token,
                excludeNoAccess: 1
              }}
              fetchFn={AccessLevelDoor.search}
              data-compact
              data-inline
              css={css`
                .select__menu {
                  box-shadow: none;
                  border: none;
                }
                .select__menu-list {
                  max-height: 100%;
                  overflow: auto;
                }
                input {
                  opacity: 1 !important ;
                }
              `}
              icon={Search}
              itemComponents={{ DropdownIndicator: null, MenuList, Option }}
              simple
              isSearchable={true}
              getOptionValue={(e) => e.door_token}
              getOptionLabel={(e) => e.access_level_name}
              menuIsOpen
              controlShouldRenderValue={false}
              value={null}
            /> 
          </TabPanel>/*}
        )} */}
      </TabPanels>
    </TabGroup>
  );
};

export default DoorTabs;
