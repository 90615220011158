import React from 'react';
import Template from 'base/template';
import {
  TabItem,
  TabGroup,
  TabPanel,
  TabPanels,
  TabList
} from 'components/tabs';
import Content from 'base/content';
import { css } from '@emotion/react';
import PageTitle from 'components/page-title';
/** @jsxImportSource @emotion/react */
import EventTable from 'routes/events/event-table';
import { styles } from './details-styles';
import { AvatarCircle } from 'components/avatar';
import { useQueryWrapper } from 'utils/ajax';
import { useParams } from 'react-router-dom';
import Person from 'api/person';
import { useNotFound } from 'hooks/not-found-hook';
import PersonTrendChart from 'components/charts/person-trend-chart';
import Breadcrumbs from 'components/breadcrumbs';
import GenericDoor from 'api/generic_door';

export const FILTERS = [
  {
    open: true,
    id: 'recent',
    label: 'Recent Searches...',
    options: []
  },
  {
    open: true,
    id: 'filterBy',
    label: 'Filter By...',
    options: [
      {
        label: 'Door',
        name: 'Door',
        value: 'door',
        id: 'door',
        fetchFn: GenericDoor.search,
        field: 'door_id'
      }
    ]
  }
];

const PeopleDetails = (props) => {

  const { id } = useParams();

  const [person, setPerson] = React.useState();

  const query = useQueryWrapper(
    ['person', 'get', id],
    () => Person.get(id),
    {
      initialData: {
        data: [props?.location?.state?.person]
      }
    }
  );

  useNotFound(id, query, props);

  React.useEffect(() => {
    if (!query.data) return;
    setPerson(query.data);
  }, [query.data]);

  return (
    <Template {...props}>
      <Content
        {...props}
        css={css`
          padding: 24px 48px;
        `}
      >
        <PageTitle>{person?.name}</PageTitle>
        <Breadcrumbs crumbs={props.crumbs} />

        {person && (
          <article css={styles}>
            <section
              className="avatar"
              css={css`
                display: flex;
                align-items: center;
                justify-content: center;
              `}
            >
              <AvatarCircle
                fontSize={64}
                size={128}
                name={person.name}
                url={person.avatar_url}
              />
            </section>
            <section className="trend">
              <PersonTrendChart person_id={person?.id} />
            </section>

            <section className="diag">
              <TabGroup>
                <TabList
                  css={css`
                    min-width: 40vw;
                    width: auto;
                    padding: 0 24px;
                  `}
                >
                  <TabItem>Events</TabItem>
                </TabList>
                <TabPanels>
                  <TabPanel
                    as="div"
                    css={css`
                      overflow: hidden;
                      height: 100%;
                    `}
                  >
                    <EventTable
                      filterOptions={FILTERS}
                      selectedColumns={[
                        'createdAt',
                        'type',
                        'level',
                        'site_name',
                        'door_name',
                        'p_name',
                        'device_name'
                      ]}
                      id={['events-person', person?.id]}
                      initialFilter={{ person_id: person?.id }}
                      eventSelectTypes={[
                        'accessGranted',
                        'accessDenied',
                        'accessNotTaken',
                        'reason',
                        'level'
                      ]}
                    />
                  </TabPanel>
                </TabPanels>
              </TabGroup>
            </section>
          </article>
        )}
      </Content>
    </Template>
  );
};

export default PeopleDetails;
