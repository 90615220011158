import React from "react";
import Template from "base/template";
import ActionMenu from "views/installers/action-menu";
import { columns } from "views/installers/table-columns";
import IndexContent from "base/index-content";
import Installer from "api/installer";
import { useAuth } from "providers/auth";
import { XLg } from "react-bootstrap-icons";

const cards = [
  {
    name: "total",
    label: "Total",
  },
  {
    name: "issues",
    label: "Issues",
    icon: XLg,
    color: "var(--red-base)",
    background: "var(--stats-background-red)",

    filter: { hasIssues: "1" },
  },
];

const Installers = (props) => {
  const { enforce, user } = useAuth();

  return (
    <Template {...props}>
      <IndexContent
        statsQuery={Installer.stats}
        title="installers"
        id="installers"
        cards={cards}
        query={Installer.search}
        columns={columns}
        hiddenColumns={[
          user.role != 'A' && 'vendor_name'
        ]}
        addButton={ enforce({ restrict: [ 'writeInstallers' ], reqVendor: true })
            ? "Add an Installer"
            : false }
        selectable
        sorting={{
          newestFirst: 'Newest First',
          oldestFirst: 'Oldest First',
          mostDevices: 'Most Devices',
          leastDevices: 'Least Devices',
          mostUsers: 'Most Users',
          leastUsers: 'Least Users',
          mostCompanies: 'Most Companies',
          leastCompanies: 'Least Companies'
        }}
        actionButton={ActionMenu}
        {...props}
      ></IndexContent>
    </Template>
  );
};

export default Installers;
