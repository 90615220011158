import React from 'react';
import { css } from '@emotion/react';
import ReactECharts from 'echarts-for-react';
import { createDatesArray, createEachDayOfInterval } from 'utils/arrays';
import {
  BarChart,
  BarChartLine,
  GraphUp,
  ThreeDots,
  ThreeDotsVertical,
  ZoomIn,
  ZoomOut
} from 'react-bootstrap-icons';

import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

/** @jsxImportSource @emotion/react */

const menuStyles = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4px 0px;
  position: absolute;
  margin-top: 5px;
  background: var(--neutral-09);
  z-index: 20;

  border: 1px solid var(--neutral-12);
  box-sizing: border-box;
  /* LittleShadow */

  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.35);
  border-radius: 5px;
  width: fit-content;
  [role='menuitem'] {
    width: 100%;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    padding-right: 1rem;
    padding-left: 0.2rem;
    cursor: pointer;
    font-size: 12px;
    transition: background ease 300ms;
    &:hover {
      background: var(--secundary-v-02);
    }
  }
`;
export default ({ type, setType }) => {
  return (
    <div
      className="chart-type"
      css={css`
        padding: 0.2rem;
        margin-top: -20px;
        margin-right: 4px;
        color: var(--secondary-font-color);
      `}
    >
      <DropdownMenu.Root>
        <DropdownMenu.Trigger
          css={css`
            cursor: pointer;
          `}
        >
          {type == 'bar' ? <BarChartLine size={20} /> : <GraphUp size={20} />}
        </DropdownMenu.Trigger>

        <DropdownMenu.Content css={menuStyles} align="end">
          <DropdownMenu.Item onClick={() => setType('line')}>
            Line
          </DropdownMenu.Item>
          <DropdownMenu.Item onClick={() => setType('bar')}>
            Bar
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    </div>
  );
};
