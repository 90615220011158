import React from "react";
import styled from "@emotion/styled";

const Div = styled("div")`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  text-transform: capitalize;
  display: block;
  color: var(--neutral-00);
  grid-column: 1/-1;
  margin-bottom: 8px;
`;

const Heading = (props) => {
  return <Div className="heading">{props.label || props.name}</Div>;
};

export default Heading;
