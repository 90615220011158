import ActionMenu from 'views/intercoms/action-menu';
import { css } from '@emotion/react';
import { getSelectionColumn } from 'components/table-selection-column';
import { Badge } from 'components/badge';
import { TelephoneInboundFill, TelephonePlusFill } from 'react-bootstrap-icons';
import { dateTimeShort } from 'utils/time';
import DeviceAlertsTableIndicator from 'components/device-alerts-table-indicator';
/** @jsxImportSource @emotion/react */

export const columns = [
  getSelectionColumn(),
  {
    Header: 'Name',
    accessor: 'name',
    pickerDisabled: true,
    newSorter: true,
    asc: 'atoz',
    desc: 'ztoa',
    Cell: ({ row: { original } }) => {
      return (
        <div
          css={css`
            display: grid;
            grid-template-columns: auto 1fr;
            column-gap: 0.4rem;
            svg {
              grid-row: span 2;
              place-self: center;
              color: var(--light--others--red-400-base);
            }
            > small {
              grid-column: 1;
            }
          `}
        >
          <DeviceAlertsTableIndicator device={original} />
          <div
            css={css`
              display: flex;
              flex-direction: column;
            `}
          >
            <strong
              css={css`
                display: inline-block;
                margin-bottom: 6px;
              `}
            >
              {original.name}
            </strong>
            <small>{original.site_name}</small>
          </div>
        </div>
      );
    }
  },
  {
    disableSortBy: true,
    Header: 'Status',
    accessor: 'online',
    label: 'Status',
    Cell: ({ row, cell }) => {
      if (!cell.value || cell.value == 0)
        return <Badge color="danger">offline</Badge>;
      else if (cell.value == 1) return <Badge color="green">Online</Badge>;
    }
  }
];
