import React from 'react';

import Template from 'base/template';
import {
  AuxiliaryButton,
  Button,
  CancelButton,
  IconButton
} from 'components/buttons';
import { Redirect } from 'react-router-dom';
import FormProvider from 'providers/form-provider';
import { useMutationWrapper } from 'utils/ajax';
import { useRedirect } from 'hooks/redirect-hook';
/** @jsxImportSource @emotion/react */

import { Formik } from 'formik';
import * as Yup from 'yup';
import Content from 'base/content';
import { useParams } from 'react-router-dom';
import { useQueryWrapper } from 'utils/ajax';
import { useNotFound } from 'hooks/not-found-hook';
import PageTitle from 'components/page-title';
import Breadcrumbs from 'components/breadcrumbs';
import Sensor from 'api/sensor';
import { Field } from 'formik';
import { FieldArray } from 'formik';
import { Heading, NumberField, TextField } from 'components/form';
import SelectWrapper from 'components/headless-select';
import { Trash } from 'react-bootstrap-icons';
import { css } from '@emotion/react';

export const SENSOR_METADATA_UNITS = [
  { id: 'voltage', name: 'Voltage', label: 'V' },
  { id: 'percentage', name: 'Percentage', label: '%' },
  { id: 'watts', name: 'Watts', label: 'Wh' },
  { id: 'wattshour', name: 'Watts/Hour', label: 'W/Hr' }
];
const MetaData = (props) => {
  const { id } = useParams();

  const [sensor, setSensor] = React.useState(props?.location?.state?.sensor);

  const query = useQueryWrapper(['sensor', 'get', id], () => Sensor.get(id), {
    enabled: !!id
  });

  const { data: topics, isLoading: isLoadingTopics } = useQueryWrapper(
    ['sensor', sensor.id, 'getTopicList'],
    () => Sensor.getTopicList(sensor.id),
    { enabled: !!sensor }
  );

  useNotFound(id, query, props);

  React.useEffect(() => {
    if (query.data) setSensor(query.data);
  }, [query.data]);

  const SCHEMA = Yup.object().shape({
    metadata: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required('Name required'),
        topic: Yup.string().required('Topic required'),
        min: Yup.number(),
        max: Yup.number().test(
          'superior',
          'max must be greater than min',
          function (f) {
            const ref = Yup.ref('min');
            return this.resolve(ref) > 0 ? f > this.resolve(ref) : true;
          }
        )
      })
    )
  });

  const INITIAL_VALUES = {
    metadata: sensor?.metadata
      ? Object.entries(sensor?.metadata).map(([key, value]) => ({ ...value }))
      : topics?.map((t) => ({ topic: t, name: '' }))
  };

  const { isSuccess, isLoading, mutate, data } = useMutationWrapper(
    ['sensor', 'saveMetadata'],
    (input) => Sensor.saveMetadata(input, sensor?.id),
    {}
  );

  const { redirect, setRedirect } = useRedirect(isSuccess);

  return (
    <Template {...props}>
      <Content {...props}>
        <PageTitle>{sensor?.name}</PageTitle>
        <Breadcrumbs crumbs={props.crumbs} />
        {redirect && <Redirect to={`/sensors`} />}

        <Formik
          enableReinitialize={true}
          validationSchema={SCHEMA}
          initialValues={INITIAL_VALUES}
          onSubmit={(values) => {
            const newMetadata = values.metadata.reduce((acc, obj) => {
              const keyValue = obj['topic'];
              acc[keyValue] = obj;
              return acc;
            }, {});

            mutate({ ...values, metadata: newMetadata });
          }}
        >
          {({ handleSubmit, handleReset, values, setFieldValue }) => (
            <FormProvider mutateFunc={handleSubmit} disabled={isLoading}>
              <section
                css={css`
                  grid-template-columns:
                    var(--form-column-width) var(--form-column-width)
                    var(--form-column-width) !important;
                `}
              >
                <FieldArray
                  name="metadata"
                  render={(arrayHelpers) => (
                    <div
                      css={css`
                        grid-column: 1/-1;
                        display: flex;
                        flex-direction: column;
                        gap: 1rem;
                      `}
                    >
                      <section
                        css={css`
                          display: grid;
                          flex-direction: column;
                          gap: 2rem;
                          div.error {
                            opacity: 50%;
                          }
                        `}
                      >
                        <Heading label="Metadata configuration" />
                        {values.metadata?.length == 0 && (
                          <div className="error">
                            No topics populated from sensor. Sensor might be
                            offline or not emitting events.
                          </div>
                        )}
                        {values.metadata &&
                          values.metadata.length > 0 &&
                          values.metadata.map((metadata, index) => (
                            <div
                              key={index + 1}
                              css={css`
                                padding: 1rem;
                                border: 1px solid var(--neutral-12);
                                border-radius: 4px;
                                width: 100%;
                                display: grid;
                                align-items: start;
                                grid-template-columns: auto 1fr auto;
                                gap: 2rem;
                                > label {
                                  display: flex;
                                  flex-direction: row;
                                  justify-content: center;
                                  align-items: center;

                                  width: 48px;
                                  height: 100%;

                                  /* light/neutrals/200 */
                                  background: var(--neutral-05);
                                  border-radius: 8px;
                                }
                                > * {
                                  justify-content: flex-start;
                                }
                                > button {
                                  place-self: center;
                                }
                              `}
                            >
                              <label>{index + 1}</label>
                              <section
                                css={css`
                                  display: flex;
                                  flex-direction: column;
                                `}
                              >
                                <div
                                  css={css`
                                    width: 100%;
                                    display: grid;
                                    align-items: start;
                                    grid-template-columns: 2fr 1fr 1fr;
                                    gap: 1rem;
                                  `}
                                >
                                  <Field
                                    disabled
                                    label="Entity / Topic"
                                    as={TextField}
                                    compact
                                    name={`metadata.${index}.topic`}
                                  />
                                  <Field
                                    label="Nice Name"
                                    as={TextField}
                                    compact
                                    name={`metadata.${index}.name`}
                                  />
                                  <Field
                                    placeholder=""
                                    component={SelectWrapper}
                                    title="unit"
                                    simple
                                    value={SENSOR_METADATA_UNITS.find(
                                      (u) =>
                                        u.id == values?.metadata?.[index]?.unit
                                    )}
                                    onChange={(e) =>
                                      setFieldValue(
                                        `metadata.${index}.unit`,
                                        e.id
                                      )
                                    }
                                    name={`metadata.${index}.unit`}
                                    data-compact
                                    options={SENSOR_METADATA_UNITS}
                                  />
                                </div>
                                <div
                                  css={css`
                                    width: 100%;
                                    display: grid;
                                    align-items: start;
                                    grid-template-columns: 100px 100px 100px;
                                    gap: 1rem;
                                    h4 {
                                      grid-column: 1/-1;
                                    }
                                  `}
                                >
                                  <h4>Alert values</h4>
                                  <Field
                                    label="Min Value"
                                    as={NumberField}
                                    data-compact
                                    name={`metadata.${index}.min`}
                                  />
                                  <Field
                                    label="Max Value"
                                    as={NumberField}
                                    data-compact
                                    name={`metadata.${index}.max`}
                                  />
                                  <Field
                                    label="Exact Value"
                                    as={NumberField}
                                    data-compact
                                    name={`metadata.${index}.exact`}
                                  />
                                </div>
                              </section>
                              {/* <IconButton
                                css={css`
                                  padding: 8px;
                                `}
                                delete
                                compact
                                onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                              >
                                <Trash />
                              </IconButton> */}
                            </div>
                          ))}
                      </section>
                      {/* <AuxiliaryButton
                        disabled={values.metadata?.length > 14}
                        compact
                        css={css`
                          margin-top: 1rem;
                          margin-bottom: 1rem; ;
                        `}
                        onClick={() =>
                          arrayHelpers.push({
                            topic: '',
                            name: '',
                            unit: ''
                          })
                        }
                      >
                        Add +
                      </AuxiliaryButton> */}
                    </div>
                  )}
                />
              </section>
              <footer>
                <Button type="submit">Submit</Button>
                <CancelButton onClick={() => setRedirect(true)} />
              </footer>
            </FormProvider>
          )}
        </Formik>
        {/* <Network /> */}
      </Content>
    </Template>
  );
};

export default MetaData;
