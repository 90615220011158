/** @jsxImportSource @emotion/react */
import { Badge } from 'components/badge';
import { css } from '@emotion/react';
export const columns = [
  {
    Header: 'Device',
    accessor: 'deviceId',
    sortDisable: true,
    disableSortBy: true,
    Cell: ({ row }) => {
      return (
        <div>
          <strong>{row.original.deviceName}</strong>
          <span> (id: {row.original.deviceId})</span>
        </div>
      );
    }
  },
  {
    Header: 'Batch Id',
    accessor: 'batchId',
    sortDisable: true,
    disableSortBy: true
  },
  {
    Header: 'Duration',
    accessor: 'duration',
    sortDisable: true,
    disableSortBy: true,
    Cell: ({ cell: { value } }) => {
      if (value) return <time>{value} milliseconds</time>;
      else return null;
    }
  },
  {
    Header: 'Batch Count',
    accessor: 'batchCount',
    sortDisable: true,
    disableSortBy: true
  },
  {
    Header: 'Status',
    accessor: 'status',
    sortDisable: true,
    disableSortBy: true,
    Cell: ({ cell: { value }, row: { original } }) => {
      let STATUS = {
        done: 'green',
        new: 'orange',
        skipped: 'gray'
      };

      return (
        <Badge color={STATUS[value]}>
          {value} - ({original.retryCount})
        </Badge>
      );
    }
  },

  {
    Header: 'Created At',
    accessor: 'createdAt',
    sortDisable: true,
    disableSortBy: true
  },
  {
    Header: 'Retry After',
    accessor: 'retryAfter',
    sortDisable: true,
    disableSortBy: true
  },
  {
    Header: 'Ran At',
    accessor: 'ranAt',
    sortDisable: true,
    disableSortBy: true,
    Cell: ({ cell: { value }, row: { original } }) => {
      return (
        <div
          css={css`
            > span {
              display: block;
              margin-top: 0.3rem;
            }
          `}
        >
          <time>{value}</time>
          <span
            css={css`
              font-size: 12px;
              opacity: 50%; ;
            `}
          >
            node: {original.node}
          </span>
        </div>
      );
    }
  },
  {
    Header: 'Type',
    accessor: 'type',
    sortDisable: true,
    disableSortBy: true
  },
  {
    Header: 'Token',
    accessor: 'token',
    sortDisable: true,
    disableSortBy: true
  },
  {
    Header: 'Action',
    accessor: 'action',
    sortDisable: true,
    disableSortBy: true
  }
];
