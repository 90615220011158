import React from "react";
import Template from "base/template";
import InstallerForm from "views/installers/form";
import { Button, CancelButton } from "components/buttons";
import { Redirect } from "react-router-dom";
import FormProvider from "providers/form-provider";
import { useMutationWrapper } from "utils/ajax";
import { useRedirect } from "hooks/redirect-hook";
import Installer from "api/installer";
import { Formik } from "formik";
import * as Yup from "yup";
import PageTitle from "components/page-title";
import Content from "base/content";
import Breadcrumbs from "components/breadcrumbs";

const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

const Save = (props) => {
  let installer = props?.location?.state?.installer || null;

  let { isSuccess, isLoading, mutate } = useMutationWrapper(
    ["installer", "save"],
    (input) => Installer.save(input, installer?.id),
    {}
  );

  const SCHEMA = Yup.object().shape({
    name: Yup.string().required("Required"),
    phone: Yup.string()
      .matches(phoneRegex, "Invalid Phone")
      .required("Required"),
    address: Yup.string().required("Required"),
    contact_email: Yup.string().required("Required").email("Invalid email"),
    ...(installer?.id
      ? {}
      : {
          user: Yup.object().required().shape({
            name: Yup.string().required("Required"),
            email: Yup.string().email("Invalid email").required("Required"),
            password: Yup.string().when("user_active", {
              is: true,
              then: (schema) => schema.required("Required"),
            }),
            phone: Yup.string()
              .matches(phoneRegex, "Invalid Phone")
              .required("Required"),
          })
        }),
  });

  const INITIAL_VALUES = {
    name: installer?.name,
    phone: installer?.phone,
    address: installer?.address,
    custom_domain: installer?.custom_domain,
    enabled: installer?.enabled ?? true,
    contact_email: installer?.contact_email,
    features: installer?.features ?? {},


  };

  if(!installer?.id)
    INITIAL_VALUES.user = {};

  const { redirect, setRedirect } = useRedirect(isSuccess);

  return (
    <Template {...props}>
      {redirect && <Redirect to="/installers" />}
      <Content {...props}>
        <PageTitle>{installer ? "Edit" : "Add"} Installer</PageTitle>
        <Breadcrumbs crumbs={props.crumbs} />
        <Formik
          validationSchema={SCHEMA}
          initialValues={INITIAL_VALUES}
          onSubmit={(values) => mutate(values)}
        >
          {({ handleSubmit }) => (
            <FormProvider
              name="installer"
              mutateFunc={handleSubmit}
              disabled={isLoading}
            >
              <InstallerForm installer={installer} />

              <footer>
                <Button type="submit">Submit</Button>
                <CancelButton onClick={() => setRedirect(true)} />
              </footer>
            </FormProvider>
          )}
        </Formik>
      </Content>
    </Template>
  );
};

export default Save;
