import React from 'react';

import { css } from '@emotion/react';
import Layer from './layer';
import RecordingIndicator from './recording-indicator';
import LiveIndicator from './live-indicator';

import TimeIndicator from './time-indicator';
import StopIndicator from './stop-indicator';

import VideoControls from './video-controls';
/** @jsxImportSource @emotion/react */
import { useQueryWrapper } from 'utils/ajax';
import Device from 'api/device';
import BandwidthIndicator from './bandwidth-indicator';
const VideoElement = React.forwardRef(
  (
    {
      currentRecording,
      pipeline,
      currentTime,
      videoProperties,
      play,
      host,
      onVapix,
      setRefresh,
      seek,
      ...props
    },
    ref
  ) => {
    const [controlsOpen, setControlsOpen] = React.useState();

    //auto enable audio if its is intercoms
    //Check the audio source to be 0/1 in socket also when sending rtsp params
    const [isMuted, setIsMuted] = React.useState(true);

    const [currentVideoTime, setCurrentVideoTime] = React.useState();

    React.useEffect(() => {
      // make video time null if no pipeline
      if (!pipeline) if (ref.current) ref.current.videoTime = null;

      if (!ref.current || !pipeline) return;
      const updateProgress = () => {
        let videoTime =
          new Date(currentTime).getTime() +
          pipeline.timeToFirstByte +
          pipeline.currentTime * 1000;
        if (ref.current) ref.current.videoTime = videoTime;

        setCurrentVideoTime(videoTime);
      };
      ref.current.addEventListener('progress', updateProgress);

      ref.current.addEventListener('timeupdate', updateProgress);
      ref.current.addEventListener('ended', updateProgress);

      return () => {
        ref.current &&
          ref.current.removeEventListener('progress', updateProgress);

        ref.current &&
          ref.current.removeEventListener('timeupdate', updateProgress);
        ref.current && ref.current.removeEventListener('ended', updateProgress);
      };
    }, [ref, pipeline, seek]);

    // const updateValues = React.useCallback(() => {
    //   const quality = ref.current.getVideoPlaybackQuality();
    //
    // }, [pipeline]);

    // React.useEffect(() => {
    //   const interval = setInterval(() => {
    //     updateValues();
    //   }, 1000);
    //   return () => clearInterval(interval);
    // }, []);

    //  const volume = useVolumeMeter(videoRef, isMuted);
    const { data, isLoading } = useQueryWrapper(
      ['device', 'capabilities', host.device_id],
      () => Device.capabilities(host.device_id, ['resolution', 'focus']),
      {
        staleTime: 60000,
        cacheTime: 120000,
        enabled: host.online == 1,
        noToast: true
      }
    );

    return (
      <>
        <Layer className="video-element">
          <video
            muted={isMuted}
            css={css`
              object-fit: contain;
              width: 100%;
              height: 100%;
            `}
            ref={ref}
            controls={props.controls}
          />
        </Layer>
        <Layer
          css={css`
            position: absolute;
            background: transparent;
            &:hover {
              .controls,
              .timestamp {
                visibility: visible;
              }
            }
          `}
        >
          <div
            className="timestamp"
            css={css`
              padding: 1rem;
              position: absolute;
              top: 0;
              left: 0;
              visibility: hidden;
            `}
          >
            {pipeline && <TimeIndicator time={currentVideoTime} />}
          </div>
          <div
            css={css`
              padding: 1rem;
              position: absolute;
              top: 0;
              right: 0;
              color: red;
              gap: 0.5rem;
              display: flex;
              flex-direction: column;
              align-items: end;
            `}
          >
            {currentRecording && <RecordingIndicator />}
            {pipeline && play && !currentRecording && <LiveIndicator />}
            {pipeline && (
              <BandwidthIndicator
                videoProperties={videoProperties}
                currentTime={currentTime}
              />
            )}
          </div>
          <div
            css={css`
              padding: 1rem;
              position: absolute;
              bottom: 0;
              color: red;
              display: flex;

              width: 100%;
              align-items: center;
              justify-content: center;
            `}
          >
            {pipeline && !play && <StopIndicator />}
          </div>
          <div
            css={css`
              position: absolute;
              inset: 0;
              color: red;
              display: flex;
              width: 100%;
              height: 100%;
              background: transparent;

              align-items: ;
              justify-content: center;
              &:hover {
                .video-controls {
                  height: 48px;
                }
              }
            `}
          >
            <VideoControls
              setRefresh={setRefresh}
              focus={data?.focus == 'yes'}
              resolution={data?.resolution}
              controlsOpen={controlsOpen}
              setControlsOpen={setControlsOpen}
              pipeline={pipeline}
              host={host}
              videoProperties={videoProperties}
              videoRef={ref}
              onVapix={onVapix}
            />
          </div>
        </Layer>
      </>
    );
  }
);

export default VideoElement;
