import React from 'react';

import { CheckBox, NumberField, TextField } from 'components/form';
import Site from 'api/site';
import { useFormikContext, Field } from 'formik';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import ScheduleSelect from 'components/form/schedule-select';
import DoorSelect from 'components/form/door-select';
import ControllerSelect from 'components/form/controller-select';
import TimezoneSelect from 'components/form/timezone-select';
import EventSelect from 'components/event-select';
import IoEventSelect from 'components/io-event-select';
import H4 from 'components/form/h4';
import AsyncSelect from 'components/headless-select/async';
import Select from 'components/headless-select';
import GenericDoor from 'api/generic_door';
import Device from 'api/device';
import Credential from 'api/credential';
import Schedule from 'api/schedule';
import Sensor from 'api/sensor';
import IOPort from 'api/ioport';
import SelectWrapper from 'components/headless-select';
import Alert from 'components/alert';
import { useAuth } from 'providers/auth.js';

const styles = css`
  color: var(--neutral-00);
  h4 {
    color: var(--neutral-00);
  }
  display: grid;
  grid-template-columns: var(--form-column-width) var(--form-column-width);
  gap: 24px;
  > label:first-of-type,
  hr {
    grid-column: 1/-1;
  }
  > .name,
  .events {
    grid-column: 1/-1;
    max-width: 100%;
  }
  input[type='checkbox'] {
    border: solid 1px var(--checkbox-border-color);
  }
`;

// private $ioEvents = [
//   'ioPort_0_0' => 'IoPort 1 Open',
//   'ioPort_0_1' => 'IoPort 1 Closed',
//   'ioPort_1_0' => 'IoPort 2 Open',
//   'ioPort_1_1' => 'IoPort 2 Closed',
//   'ioPort_2_0' => 'IoPort 3 Open',
//   'ioPort_2_1' => 'IoPort 3 Closed',
//   'ioPort_3_0' => 'IoPort 4 Open',
//   'ioPort_3_1' => 'IoPort 4 Closed',
//   'ioPort_13_0' => 'IoPort 13 Open',
//   'ioPort_13_1' => 'IoPort 13 Closed',
//   'ioPort_14_0' => 'IoPort 14 Open',
//   'ioPort_14_1' => 'IoPort 14 Closed'
// ];

const IO_PORTS = [
  { value: 'ioPort_0_0', name: 'IO Port 1 Open' },
  { value: 'ioPort_0_1', name: 'IO Port 1 Closed' },

  { value: 'ioPort_1_0', name: 'IO Port 2 Open' },
  { value: 'ioPort_1_1', name: 'IO Port 2 Closed' },

  { value: 'ioPort_2_0', name: 'IO Port 3 Open' },
  { value: 'ioPort_2_1', name: 'IO Port 3 Closed' },

  { value: 'ioPort_3_0', name: 'IO Port 4 Open' },
  { value: 'ioPort_3_1', name: 'IO Port 4 Closed' },

  { value: 'ioPort_4_0', name: 'IO Port 5 Open' },
  { value: 'ioPort_4_1', name: 'IO Port 5 Closed' },

  { value: 'ioPort_5_0', name: 'IO Port 6 Open' },
  { value: 'ioPort_5_1', name: 'IO Port 6 Closed' },

  { value: 'ioPort_6_0', name: 'IO Port 7 Open' },
  { value: 'ioPort_6_1', name: 'IO Port 7 Closed' },

  { value: 'ioPort_7_0', name: 'IO Port 8 Open' },
  { value: 'ioPort_7_1', name: 'IO Port 8 Closed' },

  { value: 'ioPort_8_0', name: 'IO Port 9 Open' },
  { value: 'ioPort_8_1', name: 'IO Port 9 Closed' },

  { value: 'ioPort_9_0', name: 'IO Port 10 Open' },
  { value: 'ioPort_9_1', name: 'IO Port 10 Closed' },

  { value: 'ioPort_10_1', name: 'IO Port 11 Open' },
  { value: 'ioPort_10_1', name: 'IO Port 11 Closed' },

  { value: 'ioPort_11_1', name: 'IO Port 12 Open' },
  { value: 'ioPort_11_1', name: 'IO Port 12 Closed' },

  { value: 'ioPort_12_0', name: 'IO Port 13 Open' },
  { value: 'ioPort_12_1', name: 'IO Port 13 Closed' },

  { value: 'ioPort_13_0', name: 'IO Port 14 Open' },
  { value: 'ioPort_13_1', name: 'IO Port 14 Closed' },

  { value: 'ioPort_14_0', name: 'IO Port 15 Open' },
  { value: 'ioPort_14_1', name: 'IO Port 15 Closed' },

  { value: 'ioPort_15_0', name: 'IO Port 16 Open' },
  { value: 'ioPort_15_1', name: 'IO Port 16 Closed' }
];

const INTERVAL_OPTIONS = [
  { value: '0', name: 'Always' },
  { value: '900', name: '15 minutes' },
  { value: '1800', name: '30 minutes' },
  { value: '3600', name: '1 hour' },
  { value: '14400', name: '4 hours' },
  { value: '28800', name: '8 hours' },
  { value: '86400', name: '24 hours' }
];

const DELAY_OPTIONS = [
  { value: '0', name: 'No Delay' },
  { value: '60', name: '1 minute' },
  { value: '300', name: '5 minutes' },
  { value: '900', name: '15 minutes' },
  { value: '1800', name: '30 minutes' },
  { value: '3600', name: '1 hour' },
  { value: '14400', name: '4 hours' }
];

const NotificationForm = (props) => {
  const { values, setFieldValue } = useFormikContext();

  const { user } = useAuth();

  return (
    <article css={styles}>
      <CheckBox
        name="enabled"
        label="Enabled"
        checked={values.enabled}
        onChange={(e) => setFieldValue('enabled', e.target.checked ? 'on' : '')}
      />

      <TextField className="name" name="name" value={values.name} />

      <Field
        helper="Time zone when this trigger is active"
        name="time_zone"
        component={TimezoneSelect}
      />
      <Field
        name="schedule"
        helper="Only trigger action during this schedule"
        idx="token"
        label="Name"
        fetchFn={Schedule.search}
        component={AsyncSelect}
      />

      <Field
        title="Trigger Interval"
        name="interval"
        helper="only trigger once in..."
        idx="value"
        label="name"
        simple
        component={SelectWrapper}
        options={INTERVAL_OPTIONS}
        value={INTERVAL_OPTIONS.find((int) => int.value == values.interval)}
        onChange={(e) => setFieldValue('interval', e.value)}
      />
      <H4
        css={css`
          grid-column: 1/-1;
        `}
      >
        Filters
      </H4>
      <div
        css={css`
          grid-column: 1/-1;
        `}
      >
        <Field
          name="events"
          title="Events"
          placeholder="Search events..."
          closeMenuOnSelect={false}
          helper="select up to 4 events to trigger by..."
          component={EventSelect}
        />
      </div>
      <div
        css={css`
          grid-column: 1/-1;
        `}
      >
        <Alert>
          Select filter categories according to your event type. <br />
          Leave blank to include all entities in the category.
        </Alert>
      </div>
      <Field
        name="doors"
        isMulti={true}
        component={AsyncSelect}
        fetchFn={GenericDoor.search}
      />
      <Field
        name="devices"
        isMulti={true}
        component={AsyncSelect}
        fetchFn={Device.search}
      />
      <Field
        title="Io Ports "
        component={Select}
        isMulti={true}
        idx="value"
        label="name"
        options={IO_PORTS}
        onChange={(e) =>
          setFieldValue(
            'io_events',
            e?.map((i) => i.value)
          )
        }
        defaultValue={IO_PORTS.filter((i) =>
          values.io_events?.includes(i.value)
        )}
      />
      <Field
        name="credentials"
        label="Description"
        isMulti={true}
        idx="token"
        component={AsyncSelect}
        fetchFn={Credential.search}
      />
      {user?.features?.sensors && (
        <Field
          name="sensors"
          isMulti={true}
          component={AsyncSelect}
          fetchFn={Sensor.search}
        />
      )}
    </article>
  );
};

export default NotificationForm;
