import React from "react";

import { css } from "@emotion/react";
/** @jsxImportSource @emotion/react **/
import { dateTime, dateTimeShort } from "utils/time";
import styled from "@emotion/styled";
import EVENTS_CONSTANTS from "constants/events";
import { AvatarCircle } from "./avatar";

const eventItemStyles = css`
  box-shadow: inset 0px -1px 0px var(--neutral-12);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px;

  gap: 16px;
  position: static;

  .avatar {
    flex: 0 0 32px;
  }
  span.type {
    display: flex;
    flex-direction: column;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: var(--neutral-00);
  }

  span.door {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;

    color: var(--neutral-01);
  }
  span.time {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-left: auto;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 100%;
    color: var(--secondary-font-color);
    margin-left: auto;
  }
`;

const EntryCard = ({ data: event }) => {
  if (!event) return null;

  return (
    <article css={eventItemStyles}>
      <AvatarCircle
        name={event.personName}
        url={event.p_avatar_url}
        size="32px"
        fontSize="12px"
      />

      <span className="type">
        <span>{EVENTS_CONSTANTS.readableNames[event?.type]}</span>

        <span>{event.personName || event.credentialDescription}</span>
        <span className="door">{event?.door_name}</span>
      </span>
      <span className="time">
        <span>
          {dateTimeShort(
            new Date(Number(event?.recentEntry.$date.$numberLong))
          )}
        </span>
      </span>
    </article>
  );
};

export default EntryCard;
