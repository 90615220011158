import React from 'react';
import { CheckBox, Heading, TextField } from 'components/form';
import { useFormikContext } from 'formik';
import styled from '@emotion/styled';

const Section = styled('section')`
  display: grid;
  gap: 16px;
  grid-template-columns: var(--form-column-width) var(--form-column-width);
  padding-top: 24px;
  min-width: 650px;
`;

const CompanyAD = ({ company }) => {
  const { values, setFieldValue, touched } = useFormikContext();

  const isTouched =
    values.ad_root || values.ad_secret || values.ad_id || values.ad_tenant;

  return (
    <>
      <CheckBox
        name="ad_enabled"
        label="Enable"
        checked={values.ad_enabled}
        onChange={(e) => {
          setFieldValue('ad_enabled', e.target.checked);
        }}
      />
      <br />
      <fieldset disabled={!values.ad_enabled}>
        <Section>
          <TextField
            name="ad_tenant"
            label="Directory (tenant) ID"
            //value={values.ad_tenant}
            placeholder={!isTouched && company?.ad_tenant}
            value={values?.ad_tenant}
          />
          <TextField
            name="ad_id"
            label="Application (Client) ID"
            placeholder={!isTouched && company?.ad_id}
            value={values.ad_id}
          />
          <TextField
            name="ad_secret"
            label="Client Secret Value"
            value={values.ad_secret}
            placeholder={!isTouched && company?.ad_secret}
          />
          <TextField
            name="ad_root"
            label="Object ID (Master Group)"
            value={values.ad_root}
            placeholder={!isTouched && company?.ad_root}
          />

          <TextField
            name="ad_card_field"
            label="Card Field"
            value={values.ad_card_field}
          />
          <TextField
            name="ad_card_nr_field"
            label="Card Number Field"
            value={values.ad_card_nr_field}
          />
          <TextField
            name="ad_facility_code_field"
            label="Facility Code Field"
            value={values.ad_facility_code_field}
          />
          <TextField
            name="ad_pin_field"
            label="Pin Field"
            value={values.ad_pin_field}
          />
        </Section>
      </fieldset>
    </>
  );
};

export default CompanyAD;
