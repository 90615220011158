import React from "react";

import CheckBox from "components/form/checkbox";
//import Select from "components/form/select";

import { css } from "@emotion/react";
import { useFormikContext } from "formik";
/** @jsxImportSource @emotion/react */
import { Disclosure } from "@headlessui/react";
import { ChevronUp, ChevronDown } from "react-bootstrap-icons";
import SelectWrapper from "components/headless-select";
const styles = css`
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  > .pin-selection {
    display: flex;
    gap: 48px;
  }
  p {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: var(--neutral-00);
    padding: 16px 0px;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    width: fit-content;
  }
`;

const ENDING_CHAR = [
  {
    value: "#",
    label: "#",
  },
  {
    value: "*",
    label: "*",
  },
];

const PinConfiguration = ({ configuration, index, ac }) => {
  const { values, setFieldValue } = useFormikContext();

  return (
    <article className="pin-configuration" css={styles}>
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button as="p">
              Set Up Pin Configuration
              {open ? <ChevronUp /> : <ChevronDown />}
            </Disclosure.Button>
            <Disclosure.Panel className="pin-selection" css="">
              <SelectWrapper
                idx="value"
                label="label"
                title="Min Digits excepted"
                simple
                placeholder=""
                isMulti={false}
                value={
                  values.doors[index]?.pinConfiguration
                    ? {
                        value:
                          values.doors[index]?.pinConfiguration?.MinPINSize,
                        label:
                          values.doors[index]?.pinConfiguration?.MinPINSize,
                      }
                    : null
                }
                onChange={(e) => {
                  setFieldValue(
                    `doors[${index}].pinConfiguration.MinPINSize`,
                    e?.value ?? null
                  );
                }}
                options={[...new Array(31)].map((i, idx) => ({
                  value: idx + 1,
                  label: idx + 1,
                }))}
                closeMenuOnSelect={true}
              />
              <SelectWrapper
                idx="value"
                label="label"
                simple
                placeholder=""
                title="Max Digits excepted"
                isMulti={false}
                value={
                  values.doors[index]?.pinConfiguration
                    ? {
                        value:
                          values.doors[index]?.pinConfiguration?.MaxPINSize,
                        label:
                          values.doors[index]?.pinConfiguration?.MaxPINSize,
                      }
                    : null
                }
                options={[...new Array(32)].map((i, idx) => ({
                  value: idx + 1,
                  label: idx + 1,
                }))}
                onChange={(e) => {
                  setFieldValue(
                    `doors[${index}].pinConfiguration.MaxPINSize`,
                    e?.value ?? null
                  );
                }}
              />
              <SelectWrapper
                idx="value"
                label="label"
                simple
                placeholder=""
                title="Ending Character"
                isMulti={false}
                options={ENDING_CHAR}
                closeMenuOnSelect={true}
                value={
                  values.doors[index]?.pinConfiguration
                    ? {
                        value: values.doors[index]?.pinConfiguration?.EndOfPIN,
                        label: values.doors[index]?.pinConfiguration?.EndOfPIN,
                      }
                    : null
                }
                onChange={(e) => {
                  setFieldValue(
                    `doors[${index}].pinConfiguration.EndOfPIN`,
                    e?.value ?? null
                  );
                }}
              />
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </article>
  );
};

export default PinConfiguration;
