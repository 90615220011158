import { dataFetchQuery } from 'utils/ajax';
import { API_HOST_URL } from './api-url';
const API_URL = API_HOST_URL + '/access_point_api';

const AccessPoint = {
  search(input = {}) {
    let id = input.id ?? null;
    return dataFetchQuery(`${API_URL}/search/${id ? id : ''}`, input);
  },
  searchWithSchedules(id = '', input = {}) {
    return dataFetchQuery(`${API_URL}/searchWithSchedules/` + id, input);
  }
};

export default AccessPoint;
