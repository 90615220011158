import "css/sidebar.css";
import React from "react";
/** @jsxImportSource @emotion/react **/
import { css, keyframes } from "@emotion/react";
import * as Collapsible from "@radix-ui/react-collapsible";
import { BarChartLineFill, ChevronDown } from "react-bootstrap-icons";
import { useHeadlessTable } from "providers/headless-table-provider";
const open = keyframes`
  from { height: 0 }
  to { height: 'var(--radix-collapsible-content-height)' }
`;

const close = keyframes`
from { height: 'var(--radix-collapsible-content-height)' }
  to { height: 0 }  
`;

const styles = css`
  display: flex;

  flex-direction: row;
  align-items: center;
  padding: 16px 24px;
  gap: 1rem;

  height: 56px;
  font-size: 14px;
  font-weight: 600;

  /* light/neutrals/000 */

  background: var(--neutral-09);
  /* light/large-shadow */

  box-shadow: 0px 5px 48px -5px rgba(0, 0, 0, 0.05);
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  border-bottom: 1px solid var(--neutral-12);

  svg:last-of-type {
    margin-left: austo;
  }
  &[data-state="open"] {
    border-bottom: 1px solid var(--neutral-12);
    svg:last-of-type {
      transform: rotate(180deg);
    }
  }
`;

export const withChartArea = (mainProps) => (ChartArea) => (props) => {
  const { tableState, dispatch } = useHeadlessTable();

  return (
    tableState.chart && (
      <section
        className="chart-area"
        css={css`
          margin-bottom: 4px;
          margin-top: 4px;
          height: ${mainProps.height || "250px"};
          background: var(--neutral-09);
          border-bottom: 1px solid var(--neutral-12);
        `}
      >
        <ChartArea />
      </section>
    )
  );
};
