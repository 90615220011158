import React from 'react';
import Template from 'base/template';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import IndexContent from 'base/index-content';

import { useParams } from 'react-router-dom';
import { useQueryWrapper } from 'utils/ajax';
import { useNotFound } from 'hooks/not-found-hook';
import useAsyncSelection from 'components/headless-select/use-async-selection';

import { getSelectionColumn } from 'components/table-selection-column';
import { Dialog, DialogTrigger, DialogContent } from 'components/dialog.js';
import { Button, CancelButton } from 'components/buttons';

import Select from 'components/headless-select';
import DepartmentAccessLevels from 'views/departments/departments-access-levels';
import { IconX } from '@tabler/icons-react';

import Department from 'api/department';

import DepartmentActionMenu from './department-action-menu';
import { useAddDepartmensMutate } from 'views/people/actions';
import Person from 'api/person';
import { DepartmentItem } from 'components/form/department-select';
const columns = [
  getSelectionColumn(),
  {
    Header: 'Department',
    accessor: 'Name',
    newSorter: true,
    asc: 'atoz',
    desc: 'ztoa',
    Cell: ({ row }) => (
      // Use Cell to render an expander for each row.
      // We can use the getToggleRowExpandedProps prop-getter
      // to build the expander.
      <article
        css={css`
          > span {
            display: block;
            line-height: 1.6;

            > span {
              margin-left: 8px;
            }
          }
          .desc {
            color: #7f8795;
          }
        `}
      >
        <span className="name">
          {row.original.Name}
          {row.original.ad_uuid && <Badge color="purple">AD</Badge>}
        </span>
        <span className="desc">{row.original.Description}</span>
      </article>
    )
  },

  {
    Header: 'People',
    accessor: 'person_count',
    id: 'person_count',
    asc: 'leastPeople',
    desc: 'mostPeople',
    newSorter: true
  },
  {
    Header: 'Access Levels',
    accessor: 'access_level_names',
    disableSortBy: true,
    Cell: ({ row: { original: dep } }) => <DepartmentAccessLevels dep={dep} />
  }
];

const cards = [
  {
    name: 'total',
    label: 'Total'
  }
];

const AddDepartment = ({
  dialogOpenProp,
  setDialogOpenProp,
  person,
  ...otherProps
}) => {
  const [deps, setDeps] = React.useState([]);
  const addDepartmentsMutation = useAddDepartmensMutate(person);

  //const addPeopleMutation = useAddPeopleMutate(department);
  const [options, setOptions] = React.useState([]);

  const selectRef = React.useRef();

  const handleRemoveValue = React.useCallback(
    (id) => {
      const removedValue = deps.find((val) => val.id === id);
      if (!removedValue) return;
      selectRef.current.onChange(
        deps.filter((val) => val.id !== id),
        { id, action: 'remove-value', removedValue }
      );
    },
    [deps]
  );

  const { input, isLoading, setInput, data } = useAsyncSelection({
    ...otherProps,
    fetchFn: Department.search,
    fetchFilters: { exceptPerson: person.id },
    queryKey: `person-deps-${person.id}`
  });

  React.useEffect(() => {
    if (data) setOptions(data?.data);
  }, [data]);

  return (
    <Dialog open={dialogOpenProp} onOpenChange={setDialogOpenProp}>
      <DialogTrigger asChild>
        <Button compact>Add Department</Button>
      </DialogTrigger>

      <DialogContent title="Add Department">
        <div
          css={css`
            display: flex;
            flex-direction: column;
            height: 100%;

            > section {
              height: 100%;

              flex: 1;
              display: grid;
              grid-template-columns: 1fr 1fr;
              overflow: hidden;
            }
            > footer {
              display: flex;
              width: 100%;
              align-items: center;
              justify-content: space-between;
              margin-top: 8px;
            }
          `}
        >
          <section>
            <Select
              data-compact
              ref={selectRef}
              isMulti={true}
              title=""
              data-inline
              inputValue={input ?? ''}
              label="Name"
              setInput={setInput}
              itemComponents={{ DropdownIndicator: null }}
              isClearable={true}
              options={options}
              //filterOption={null}
              closeMenuOnSelect={false}
              menuIsOpen
              // isDisabled={isLoading}

              onChange={(e) => setDeps(e)}
              //isOptionDisabled={() => als.length >= 10}
              hideSelectedOptions={true}
              controlShouldRenderValue={false}
              isLoading={isLoading}
              value={deps}
              onInputChange={(e) => {
                setInput(e);
              }}
            />
            <div
              css={css`
                background: var(--neutral-08);
                overflow: hidden;
                display: flex;
                flex-direction: column;
                flex: 1;
              `}
            >
              <div
                css={css`
                  margin: 1rem;
                  overflow: auto;
                  height: 100%;
                  padding-left: 1rem;
                  padding-right: 1rem;
                  display: flex;
                  flex-direction: column;
                  gap: 0.5rem;
                  flex: 1;
                `}
              >
                <label
                  css={css`
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 125%;
                  `}
                >
                  Selected Departments
                </label>
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                  `}
                >
                  {deps.map((val, idx) => (
                    <div
                      key={idx}
                      css={css`
                        display: grid;
                        grid-template-columns: 1fr auto;
                        align-items: center;
                        gap: 2rem;
                      `}
                    >
                      <DepartmentItem department={val} />
                      <IconX onClick={() => handleRemoveValue(val.id)} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
          <footer>
            <Button
              compact
              loading={addDepartmentsMutation.isLoading}
              disabled={deps.length == 0 || addDepartmentsMutation.isLoading}
              onClick={() =>
                addDepartmentsMutation.mutate(
                  deps.map((p) => p.id),
                  {
                    onSuccess: () => {
                      setOptions((options) =>
                        options.filter(
                          (opt) => !deps.map((p) => p.id).includes(opt.id)
                        )
                      );
                      setDeps([]);
                    }
                  }
                )
              }
            >
              Add {deps.length ? deps.length : ''} Departments
            </Button>
            <CancelButton compact onClick={() => setDialogOpenProp(false)}>
              Close
            </CancelButton>
          </footer>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const PersonDepartments = (props) => {
  const { id } = useParams();

  const [person, setPerson] = React.useState(props?.location?.state?.person);

  const query = useQueryWrapper(['person', 'get', id], () => Person.get(id));

  useNotFound(id, query, props);

  React.useEffect(() => {
    if (!query?.data) return;
    setPerson(query?.data);
  }, [query?.data]);

  const [dialogOpenProp, setDialogOpenProp] = React.useState(
    props?.location?.state?.dialogOpen ?? false
  );

  return (
    <Template {...props}>
      {person && (
        <IndexContent
          newSorter
          fields={['counts']}
          person={person}
          setDialogOpenProp={setDialogOpenProp}
          dialogOpenProp={dialogOpenProp}
          pageTitle={person.name}
          initialFilter={{
            person: person.id
          }}
          id={`person-dep-${person.id}`}
          statsQuery={Department.stats}
          cards={cards}
          query={Department.search}
          columns={columns}
          isSidebarOpen={true}
          dialogOpen={true}
          emptyMessage="No Access Levels found"
          actionButton={DepartmentActionMenu}
          //filterOptions={FILTERS}
          getRowProps={(row) =>
            (row.disableTableSelection = row.original.type == 'dep')
          }
          multi
          selectable={true}
          buttons={AddDepartment}
          {...props}
        ></IndexContent>
      )}
    </Template>
  );
};

export default PersonDepartments;
