import React from 'react';
import MenuItem from 'components/menu-item';
import {
  Building,
  People,
  Grid1x2,
  Cpu,
  HddNetwork,
  CardList
} from 'react-bootstrap-icons';
import { useAuth } from 'providers/auth';
import { ReactComponent as SensorIcon } from 'assets/sensor-icon.svg';

function InstallerMenu() {
  const { hasAllPermissions, user } = useAuth();

  return (
    <ul>
      <MenuItem to="/dashboard" title="Dashboard">
        <Grid1x2 />
      </MenuItem>
      {hasAllPermissions('writeUsers') && (
        <MenuItem to="/accounts" title="Peer Accounts">
          <People />
        </MenuItem>
      )}
      <MenuItem to="/companies" title="Companies">
        <Building />
      </MenuItem>
      <MenuItem to="/devices" title="Devices">
        <HddNetwork />
      </MenuItem>
      {user?.installer_features?.sensors && (
        <MenuItem to="/sensors" title="Sensors">
          <SensorIcon />
        </MenuItem>
      )}
      <MenuItem to="/audit-logs" title="Audit Logs">
        <CardList />
      </MenuItem>
    </ul>
  );
}

export default InstallerMenu;
