import { css } from '@emotion/react';
import { getPeriodSinceDate } from 'utils/time';
import { getSelectionColumn } from 'components/table-selection-column';
import { WEEK_DAYS } from './iteration-select';
import {
  IconCalendarEvent,
  IconCircleCheck,
  IconCircleX,
  IconHourglassLow
} from '@tabler/icons-react';
import { useMutationWrapper } from 'utils/ajax';
import Report from 'api/report';
import { AuxiliaryButton } from 'components/buttons';
import { IconCloudDown } from '@tabler/icons-react';
/** @jsxImportSource @emotion/react */
import { dateTimeDif } from 'utils/time';
import Label from 'components/label';
import * as Tooltip from '@radix-ui/react-tooltip';
import { IconHelpCircle } from '@tabler/icons-react';
import EVENTS_CONSTANTS from 'constants/events';
import SmallBadge from 'components/small-badge';
function ExpireAtHeader() {
  return (
    <Tooltip.Provider>
      <Tooltip.Root>
        <Tooltip.Trigger asChild={true}>
          <span
            css={css`
              display: inline-flex;
              gap: 0.4rem;
              align-items: center;
            `}
          >
            <label>Expires At</label>
            <IconHelpCircle size={22} />
          </span>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content asChild={true}>
            <div
              css={css`
                z-index: 200;
                display: flex;

                padding: 0.5rem;

                background: var(--neutral-09);
                box-shadow: 0px 2px 6px 4px rgba(29, 32, 35, 0.4);
                border-radius: 4px;
              `}
            >
              Indicates when report will be removed from history
              <Tooltip.Arrow
                css={css`
                  fill: var(--neutral-09);
                `}
              />
            </div>
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}

function StatsInfo({ children, row }) {
  return (
    <Tooltip.Provider>
      <Tooltip.Root delayDuration={0}>
        <Tooltip.Trigger asChild={true}>
          <span
            css={css`
              display: inline-flex;
              gap: 0.4rem;
              align-items: center;
            `}
          >
            {children}
          </span>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content asChild={true}>
            <div
              css={css`
                z-index: 200;
                width: 150px;
                padding: 0.5rem;

                background: var(--neutral-09);
                box-shadow: 0px 2px 6px 4px rgba(29, 32, 35, 0.4);
                border-radius: 4px;
              `}
            >
              <h4>Statistics </h4>

              <dl
                className="info"
                css={css`
                  h4 {
                    margin-bottom: 0.4rem;
                  }
                  dd {
                    margin-bottom: 0.5rem !important;
                  }
                `}
              >
                <dt>File Size:</dt>
                <dd>{row.fileSize}</dd>

                <dt>Result count:</dt>
                <dd>{row.resultCount}</dd>
              </dl>

              <Tooltip.Arrow
                css={css`
                  fill: var(--neutral-09);
                `}
              />
            </div>
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}
export const columns = [
  getSelectionColumn(),
  {
    Header: 'ID',
    accessor: '_id',
    pickerDisabled: true,
    role: ['A'],
    disableSortBy: true
  },
  {
    Header: 'Download',
    accessor: 'key',
    pickerDisabled: true,
    disableSortBy: true,

    Cell: ({ row: { original }, cell: { value } }) => {
      const { mutate, isLoading } = useMutationWrapper(
        ['report', value],
        () => Report.signedUrl(btoa(value)),
        {
          onSuccess: (url) => {
            const a = document.createElement('a');
            a.href = url;
            a.target = '_blank';
            a.rel = 'noreferrer noopener';
            a.download = 'filename.txt'; // Replace with the desired filename
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          },
          loadingMessage: 'Downloading File...',
          successMessage: 'Download success!'
        }
      );
      if (value)
        return (
          <AuxiliaryButton
            css={css`
              height: 24px;
            `}
            onClick={() => mutate()}
            disabled={isLoading}
          >
            <IconCloudDown />
            Download
          </AuxiliaryButton>
        );
      else return null;
    }
  },

  {
    Header: 'Description',
    accessor: 'description',
    pickerDisabled: true,
    disableSortBy: true
  },

  {
    Header: 'Type',
    accessor: 'output',
    disableSortBy: true
  },

  {
    Header: 'Recurrence',
    accessor: 'recurrence',
    disableSortBy: true,
    pickerDisabled: true,
    Cell: ({ cell: { value }, row: { original } }) => {
      if (value == '1')
        return (
          <time>
            <Label>Run Once</Label>
          </time>
        );
      else if (value == 'd') {
        return (
          <time>
            <Label>Daily</Label>
          </time>
        );
      } else if (value == 'm') {
        return (
          <time>
            <Label>
              <span>Monthly on the</span>
              <span> {original.dayOfMonth}</span>
            </Label>
          </time>
        );
      } else if (value == 'w') {
        return (
          <time>
            <Label>
              <span>Weekly on </span>
              <span>
                {WEEK_DAYS.find((d) => d.value == original.dayOfWeek)?.label}
              </span>
            </Label>
          </time>
        );
      }
    }
  },

  {
    Header: 'Period',
    disableSortBy: true,
    id: 'period',
    label: 'Period',

    Cell: ({ cell: { value }, row: { original } }) => {
      return (
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <time>
            <span>{new Date(original.start).toLocaleString()} </span> -
            <span> {new Date(original.end).toLocaleString()}</span>
          </time>
        </div>
      );
    }
  },

  {
    Header: 'Status',
    accessor: 'status',
    pickerDisabled: true,
    disableSortBy: true,

    Cell: ({ row: { original }, cell: { value } }) => {
      if (value == 'new')
        return (
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 0.2rem;
            `}
          >
            <IconCalendarEvent />
            scheduled
          </div>
        );
      else if (value == 'done')
        return (
          <StatsInfo row={original}>
            <div
              css={css`
                display: flex;
                align-items: center;
                gap: 0.2rem;
              `}
            >
              <IconCircleCheck color={'var(--light--primary--400-base)'} />
              done
            </div>
          </StatsInfo>
        );
      else if (value == 'failed')
        return (
          <div
            title={original.error}
            css={css`
              display: flex;
              align-items: center;
              gap: 0.2rem;
            `}
          >
            <IconCircleX color={'var(--light--others--red-400-base)'} />
            failed
          </div>
        );

      return <div>{value}</div>;
    }
  },
  {
    Header: 'Run At',
    accessor: 'runAt',
    disableSortBy: true,
    Cell: ({ cell: { value } }) => {
      {
        return (
          <time title={new Date(value).toLocaleString()}>
            {dateTimeDif(value)}
          </time>
        );
      }
    }
  },
  {
    disableSortBy: true,
    Header: 'Recipients',
    accessor: 'recipients',
    Cell: ({ cell: { value }, row: { original } }) => {
      {
        return (
          <div title={value?.join(', ')}>{value?.slice(0, 2)?.join(', ')}</div>
        );
      }
    }
  },
  {
    disableSortBy: true,
    Header: 'Events',
    accessor: 'types',
    Cell: ({ cell: { value } }) => {
      const eventsAmount = value.length;

      return (
        <div
          title={value.map((e) => EVENTS_CONSTANTS.readableNames[e]).join(', ')}
          css={css`
            display: flex;
            gap: 0.4rem;
          `}
        >
          {value.slice(0, 3).map((i) => (
            <SmallBadge>{EVENTS_CONSTANTS.readableNames[i]}</SmallBadge>
          ))}
          {eventsAmount > 3 && <SmallBadge>+ {eventsAmount - 3}</SmallBadge>}
        </div>
      );
    }
  },
  {
    Header: ExpireAtHeader,
    label: 'Expire At',
    accessor: 'expireAt',
    disableSortBy: true,
    Cell: ({ cell: { value } }) => {
      {
        if (value) return <time>{new Date(value).toLocaleString()}</time>;
        else return null;
      }
    }
  }
];
