import React from 'react';

import IoPorts from 'views/devices/io-ports';
import {
  TabItem,
  TabGroup,
  TabPanel,
  TabPanels,
  TabList
} from 'components/tabs';

import { css } from '@emotion/react';


import DeviceStats from 'views/devices/device-stats';
import EventTable from 'routes/events/event-table';
import SetupReports from './setup-reports';
import { RestoreReports } from './restore-reports';
import { useHistory, useParams } from 'react-router-dom';
/** @jsxImportSource @emotion/react */

const styles = css`
  color: var(--neutral-00);
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 16px;
  //align-items: stretch;
  flex: 1;
  height: 100%;
  width: 100%;
  overflow: hidden;

  > section {
    background: var(--neutral-09);
    box-shadow: 0px 5px 20px -5px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
  }

  > section.side {
    align-self: start;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 24px;
    overflow: hidden;

    article:first-of-type {
      margin-bottom: 12px;
      width: 100%;
      .progress {
        margin-top: 16px;
      }
    }
  }

  > section.diag {
    position: relative;
    overflow: hidden;

    height: 100%;
    display: flex;
    flex-direction: column;

    div.actions {
      position: absolute;
      right: 0;
      padding: 16px;
      .action-menu-wrapper {
        margin-left: auto;
        z-index: 12;
      }
    }
  }

  @media (max-width: 1600px) {
    display: flex;
    flex-direction: column;
  }
`;

const TABS = {
  events: 0,
  io: 1,
  config: 2,
  restore: 3,
  0: 'events',
  1: 'io',
  2: 'config',
  3: 'restore'
};
const Details = ({ device }) => {
  if (!device) return null;

  const { tab } = useParams();
  const history = useHistory();

  const [selectedIndex, setSelectedIndex] = React.useState(TABS?.[tab] ?? 0);

  return (
    <article css={styles}>
      <section
        className="stats"
        css={css`
          padding: 1rem;
        `}
      >
        <DeviceStats device={device} />
      </section>

      <section
        className="diag"
        css={css`
          grid-column: 2 /2;
        `}
      >
        <TabGroup
          selectedIndex={selectedIndex}
          onChange={(e) => {
            if (!device) return;
            setSelectedIndex(e);
            history.replace(`/devices/details/${device.id}/${TABS[e]}`);
          }}
        >
          <TabList
            css={css`
              min-width: 40vw;
              width: auto;
              padding: 0 24px;
            `}
          >
            <TabItem>Events</TabItem>
            <TabItem>Input/Output</TabItem>
            {['A1601', 'A1001', 'A1601'].includes(device.product) && (
              <TabItem>Configuration History</TabItem>
            )}
            <TabItem>Restore History</TabItem>
          </TabList>
          <TabPanels>
            <TabPanel
              as="div"
              css={css`
                overflow: hidden;
                height: 100%;
              `}
            >
              <EventTable
                selectedColumns={['createdAt', 'type', 'level', 'device_name']}
                id={['events', device?.device_id]}
                initialFilter={{
                  device_id: Number(device?.device_id),
                  category: 'device'
                }}
                eventSelectTypes={[
                  'scheduleOn',
                  'scheduleOff',
                  'restoreMissingEvents',
                  'restoreStart',
                  'restoreFinish',
                  'restoreError',
                  'online',
                  'offline',
                  'systemReady',
                  'level'
                ]}
              />
            </TabPanel>
            <TabPanel
              as="div"
              css={css`
                overflow: auto;
              `}
            >
              <IoPorts deviceId={device.device_id} />
            </TabPanel>
            {['A1601', 'A1001', 'A1610'].includes(device.product) && (
              <TabPanel
                as="div"
                css={css`
                  overflow: auto;
                `}
              >
                <SetupReports controllerId={device.controller_id} />
              </TabPanel>
            )}
            <TabPanel
              as="div"
              css={css`
                overflow: auto;
              `}
            >
              <RestoreReports deviceId={device.device_id} />
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </section>
    </article>
  );
};

export default Details;
