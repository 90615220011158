import React from 'react';

import { TextField } from 'components/form';
import { useFormikContext } from 'formik';
import InvertLabel from 'components/invert-label';
import { Field } from 'formik';
import AdUUID from './ad-uuid';
import { css } from '@emotion/react';
import { useAuth } from 'providers/auth';
import AsyncSelect from 'components/headless-select/async';
import AccessLevel from 'api/access_level';
import Alert from 'components/alert';
import Site from 'api/site';

/** @jsxImportSource @emotion/react */

const styles = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 720px;

  section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }
`;
const DepartmentForm = (props) => {
  const { values, setFieldValue } = useFormikContext();
  const { user, hasAllPermissions } = useAuth();

  return (
    <article css={styles}>
      {user.is_ad_sync && props?.dep && values.ad_uuid && (
        <>
          <InvertLabel color="purple">AD</InvertLabel>
          <span>{values.ad_uuid}</span>
        </>
      )}

      <AdUUID />

      <section>
        <TextField
          name="Name"
          disabled={values.ad_uuid}
          label="Name"
          value={values.Name}
        />

        <TextField
          name="Description"
          disabled={values.ad_uuid}
          label="Description"
          value={values.Description}
        />
      </section>

      <Field
        helper="Sites that can access this department"
        selectAll
        name="sites"
        isMulti={true}
        component={AsyncSelect}
        fetchFn={Site.search}
        onSelectAll={() => {
          setFieldValue('sites', null);
          setFieldValue('allSites', true);
        }}
        onDeselectAll={() => {
          setFieldValue('sites', []);
          setFieldValue('allSites', null);
        }}
      />
    </article>
  );
};

export default DepartmentForm;
