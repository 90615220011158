import React from "react";
import AsyncSelect from "components/form/async-select";
import Door from "api/door";
import { withField } from "./with-field";
import { useFormikContext } from "formik";

const DoorSelect = (props) => {
  return (
    <AsyncSelect
      title="doors"
      idx="token"
      label="Name"
      fetchFn={Door.search.bind()}
      {...props}
    />
  );
};

export default withField(DoorSelect);
