import React from 'react';
import { Popup, Tooltip, useMap } from 'react-leaflet';
import { Marker } from 'react-leaflet';
import { css } from '@emotion/react';
import DoorStateAvatar from 'views/doors/door-state-avatar';
import LastPerson from 'views/doors/last-person';
/** @jsxImportSource @emotion/react */
import ActionMenu from 'views/doors/action-menu';
import dataMarker from './data-marker';
import LastAccessFormatter from 'components/last-access-formatter';

const LeafIcon = L.DivIcon.extend({
  options: {}
  // createIcon: function (oldIcon) {
  //   var newIcon = L.Icon.prototype.createIcon.call(this, oldIcon);
  //   if (this.options.data) {
  //     for (var key in this.options.data) {
  //       newIcon.dataset[key] = this.options.data[key];
  //     }
  //   }
  //   return newIcon;
  // }
});

// L.DomMarkers.Icon = L.DivIcon.extend({
//   options: {
//     element: null // a initialized DOM element
//     //same options as divIcon except for html
//   },

//   createIcon: function () {
//     if (!this.options.element) return;

//     var div = this.options.element;
//     this._setIconStyles(div, 'icon');
//     return div;
//   }
// });

// L.DomMarkers.icon = function (options) {
//   return new L.DomMarkers.Icon(options);
// };

function createDoorIcon(door) {
  //TODO add alarms

  const alarm = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-door-closed" viewBox="0 0 16 16">
                <path d="M3 2a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v13h1.5a.5.5 0 0 1 0 1h-13a.5.5 0 0 1 0-1H3V2zm1 13h8V2H4v13z"/>
                <path d="M9 9a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"/>
              </svg>
  <span class='alarm' title=${door.alarm_state}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info" viewBox="0 0 16 16">
  <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
</svg></span>`;

  const unlock = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-door-closed" viewBox="0 0 16 16">
  <path d="M3 2a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v13h1.5a.5.5 0 0 1 0 1h-13a.5.5 0 0 1 0-1H3V2zm1 13h8V2H4v13z"/>
  <path d="M9 9a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"/>
</svg>
<span class='indicator unlock'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-unlock" viewBox="0 0 16 16">
<path d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2zM3 8a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1H3z"/>
</svg></span>`;

  const lock = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-door-closed" viewBox="0 0 16 16">
  <path d="M3 2a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v13h1.5a.5.5 0 0 1 0 1h-13a.5.5 0 0 1 0-1H3V2zm1 13h8V2H4v13z"/>
  <path d="M9 9a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"/>
</svg>
<span class='indicator lock'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-lock" viewBox="0 0 16 16">
<path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2zM5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1z"/>
</svg></span>`;

  const offline = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-door-closed" viewBox="0 0 16 16">
  <path d="M3 2a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v13h1.5a.5.5 0 0 1 0 1h-13a.5.5 0 0 1 0-1H3V2zm1 13h8V2H4v13z"/>
  <path d="M9 9a1 1 0 1 0 2 0 1 1 0 0 0-2 0z"/>
</svg>`;

  return dataMarker({
    className: `pin-square door`,
    iconSize: [24, 24],
    iconAnchor: [5, 5],
    data: {
      online: door.online == 1,
      state:
        door.state == '0' || door.state == 'Locked' ? 'Locked' : 'Unlocked',
      alarm: door.alarm_state
    },
    html:
      door.online == '0'
        ? offline
        : door.alarm_state != 'normal'
        ? alarm
        : door.state == '0' || door.state == 'Locked'
        ? lock
        : unlock
  });
}

const MarkerPopUp = ({ door }) => {
  //autoPan={false} closeOnEscapeKey={true} offset={L.point(200, 300)}

  return (
    <Popup autoPan={true}>
      <div
        css={css`
          min-width: 300px;
          width: 300px;

          header {
            padding: 1rem;
            border-bottom: 1px solid var(--neutral-05);
            display: grid;
            grid-template-columns: 50px 1fr auto;
            h3 {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }
          .actions {
            margin-left: auto;
          }
          section {
            h4 {
              margin-bottom: 0.5rem;
            }
            padding: 1rem;
          }
        `}
      >
        <header
          css={css`
            display: flex;
            align-items: center;
            gap: 1rem;
          `}
        >
          <DoorStateAvatar door={door} />
          <h3>{door.name}</h3>
          <div className="actions">
            <ActionMenu noEdit={true} data={door} simple />
          </div>
        </header>
        <section>
          {door.last_access_time && (
            <>
              <h4>Latest Entry</h4>
              <LastAccessFormatter door={door} />
            </>
          )}
        </section>
      </div>
    </Popup>
  );
};
const DoorLayer = ({ doors, isEdit, geojson, filters }) => {
  const [doorMarkers, setDoorMarkers] = React.useState([]);

  React.useEffect(() => {
    if (!doors) return;
    let json = { ...geojson };
    doors?.forEach((dbDoor, idx) => {
      // function getCoords(idx) {
      //   const SQUARE = 5;
      //   const DISTANCE = 0.000003;
      //   let lat, lng;
      //   if (idx % SQUARE == 0) {
      //     lng = parseFloat(currentSite.lng) + DISTANCE * idx;
      //   } else {
      //     lng =
      //       parseFloat(currentSite.lng) +
      //       DISTANCE * Math.round(idx / SQUARE) * SQUARE;
      //   }

      //   lat = parseFloat(currentSite.lat) - DISTANCE * (idx % SQUARE);

      //   return [lng, lat];
      // }

      let index = json.features.findIndex(
        (d) => d.properties.id == dbDoor.id && d.properties.type == 'door'
      );

      if (index !== -1) {
        //door found
        json.features[index] = {
          ...json.features[index],
          properties: {
            ...json.features[index]['properties'],
            shape: 'marker',
            type: 'door',
            ...dbDoor
          }
        };
      }
    });

    // remove doors that are not in db
    // let newFeatures = json.features.filter((f) => {
    //   if (f.geometry.type == 'Point' && f.properties.type == 'door')
    //     if (!doors?.find((dbDoor) => dbDoor.id == f.properties.id)) return null;
    //     else return f;
    //   return f;
    // });

    // json.features = newFeatures;

    setDoorMarkers(
      json.features.filter(
        (f) => f.properties.type == 'door' && f.geometry.type == 'Point'
      )
    );
  }, [doors, geojson]);

  return doorMarkers?.map((dm, key) => {
    let show = false;
    if (!filters.doors) return;
    if (filters.online && dm.properties.online == '1') show = true;
    if (filters.offline && dm.properties.online == '0') show = true;

    if (show)
      return (
        <Marker
          key={key}
          icon={createDoorIcon(dm.properties)}
          position={dm.geometry.coordinates.slice().reverse()}
          properties={dm.properties}
        >
          {filters.tooltip && (
            <Tooltip permanent={true} className="fade-in">
              {dm.properties.name}
            </Tooltip>
          )}

          {!filters.tooltip && (
            <Tooltip className="fade-in">{dm.properties.name}</Tooltip>
          )}
          <MarkerPopUp door={dm.properties} />
        </Marker>
      );
  });
};

export default DoorLayer;
