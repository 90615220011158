import React from 'react';
import { useQuery } from 'react-query';
import Event from 'api/event';
import EVENTS_CONSTANTS from 'constants/events';
import { dateTime, dateTimeDif, dateTimeShort } from 'utils/time';
import {
  BellFill,
  BellSlashFill,
  ExclamationCircleFill,
  CircleFill,
  ThreeDotsVertical
} from 'react-bootstrap-icons';
import styled from '@emotion/styled';
import { usePopoverState, Popover, PopoverDisclosure } from 'reakit/Popover';
import { css } from '@emotion/react';
import { useQueryWrapper } from 'utils/ajax';
import QuickNotification from 'api/quick_notification';
/** @jsxImportSource @emotion/react */
import { IconButton } from 'components/buttons';
import { Badge } from './badge';
import { useModal } from 'providers/modal-provider';
import EventModal from 'components/event-modal';
import { DialogDisclosure } from 'reakit/Dialog';
import { ThreeDots } from 'react-bootstrap-icons';
import { useEventSource } from 'react-use-websocket';
import SidebarEmptyHolder from './sidebar-empty-holder';
import { SSE_HOST_URL } from 'api/api-url';
import Label from './label';
import { ModalTitle } from './modal';
import ReactJson from 'react-json-view';
import TableEmptyHolder from './table-empty-holder';
import { ReactComponent as NoIssues } from 'assets/images/no-issues-controllers.svg';
import DashboardEmptyHolder from 'views/dashboard/dashboard-empty-holder';
import { XCircle } from 'react-bootstrap-icons';
import { ReactComponent as ChartEmptySvg } from 'assets/images/chart-empty-holder.svg';
import SyncCheckModal from './quick-notification/sync-check-modal';
import SyncCheckItem from './quick-notification/sync-check-item';
import { useLayout } from 'providers/layout-provider';
import { useAuth } from 'providers/auth';
const COMPONENTS = {
  syncCheckDone: SyncCheckItem
};

const NOTIFICATIONS_CONSTANTS = {
  readableNames: { syncCheckDone: 'Sync Check Report' }
};

const NotificationsBody = styled('aside')`
  /* &:before {
    content: "M";
    border-bottom: 1px solid var(--neutral-12);

    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.7rem;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.9;

    color: var(--neutral-00);
  } */
  color: var(--neutral-00);
  width: 360px;
  background: var(--neutral-09);
  border: 1px solid var(--neutral-12);
  box-sizing: border-box;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  z-index: 30;
  padding: 10px;
  max-height: 400px;
  overflow-y: auto;
  padding: 0;

  article.notifications {
    position: relative;
    padding: 1rem;
    display: grid;
    align-items: center;
    grid-column-gap: 1rem;
    grid-template-columns: 3fr 1fr 1fr;
    justify-content: center;
    box-sizing: border-box;
    border-bottom: 1px solid var(--neutral-12);
    &:hover {
      background: var(--neutral-07);
      transition: all 0.5 ease;
    }
  }

  article > label {
    font-size: 14px;
    font-weight: 700;
  }

  article > time {
    font-size: 12px;
    font-weight: 500;
    color: var(--secondary-font-color);
    grid-column: 1;
  }
  article > button {
    grid-column: 3/3;
    grid-row: 1 / 3;
    place-self: end;
  }

  article > span[role='badge'] {
    grid-column: 2/2;
    grid-row: 1 / 3;
  }

  a {
    height: 40px;
    display: inline-flex;
    color: var(--primary-base);
    border-radius: 5px;
    background: var(--primary-v-02);
    font-size: 14px;
    font-weight: 700;
    margin: 24px;
    box-sizing: border-box;
    align-items: center;
    padding: 0 16px;
  }
`;
const Notifications = () => {
  const { data, refetch } = useQueryWrapper(
    ['Quick_Notification'],
    QuickNotification.search
  );

  const user = useAuth();

  const popover = usePopoverState({ placement: 'bottom-start' });
  const { dialog, setModalState } = useModal();

  const [newNotif, setNewNotif] = React.useState(false);

  const { getSocketUrl } = useLayout();

  const { lastEvent } = useEventSource(
    getSocketUrl,
    {
      share: true,
      filter: (e) => {
        if (JSON.parse(e.data).isQuickNotification) return true;
        return false;
      },
      withCredentials: true,
      retryOnError: true
    },
    Boolean(user && !user.missing2fa && !user.missingMfa)
  );

  React.useEffect(() => {
    if (!lastEvent) return;
    refetch();
    setNewNotif(true);
  }, [lastEvent]);

  return (
    <>
      <PopoverDisclosure
        {...popover}
        onClick={() => {
          setNewNotif(false);
          refetch();
        }}
        css={css`
          position: relative;
        `}
      >
        {newNotif && (
          <div
            css={css`
              svg {
                width: 12px;
                height: 12px;
                fill: red;
                position: absolute;
                right: -8px;
                top: -8px;
              }
            `}
          >
            <CircleFill />
          </div>
        )}
        <BellFill
          size={20}
          // TODO Move this css to the parent so it applies to all buttons
          css={css`
            color: var(--primary-base);
            &:hover {
              color: var(--bell-hover-color);
            }
          `}
        />
      </PopoverDisclosure>
      <Popover {...popover} aria-label="notifications">
        <NotificationsBody>
          {data?.data.length > 0 ? (
            data?.data?.map((e, index) => {
              const Component = COMPONENTS?.[e.type];
              return (
                <article
                  className="notification"
                  key={index}
                  data-notif={e.type}
                >
                  <Component event={e} />
                </article>
              );
            })
          ) : (
            <SidebarEmptyHolder
              icon={BellSlashFill}
              title="You have no notifications"
              subtitle="Listening to new notifications..."
            />
          )}
          {/* {data?.data && <a href="">See all Notifications</a>} */}
        </NotificationsBody>
      </Popover>
    </>
  );
};

export default Notifications;
