import React from 'react';
import { CSVReader } from 'react-papaparse';
import Credential from 'api/credential';
import { useMutationWrapper } from 'utils/ajax';
import Template from 'base/template';

import {
  AuxiliaryButton,
  Button,
  IconButton,
} from 'components/buttons';
import { css } from '@emotion/react';
import Content from 'base/content';
import PageTitle from 'components/page-title';
import {
  Download,
} from 'react-bootstrap-icons';
import TableProvider from 'providers/headless-table-provider';
import Table from 'components/headless-table';
import TablePagination from 'components/headless-pagination';
import { Trash } from 'react-bootstrap-icons';
import { getRowNumberColumn } from 'components/table-rownumber-column';
import AsyncSelect from 'components/headless-select/async';
/** @jsxImportSource @emotion/react */
import AccessLevel from 'api/access_level';
import { toast } from 'react-toastify';
import Breadcrumbs from 'components/breadcrumbs';
import Department from 'api/department';
import AccessLevelPopover from './access-level-popover';
import DepartmentPopover from './department-popover';
import SitePopover from './site-popover';
import { CSVLink } from 'react-csv';

const mainStyles = css`
  display: flex;
  user-select: none;

  background-color: var(--content-background-color);

  flex-direction: column;
  height: 100%;
  overflow: hidden;
  > .table-holder {
    flex: 1;
    overflow: inherit;
  }
`;

const toolsCSS = css`
  display: flex;
  padding: 1rem;
  gap: 0.5rem;
  align-items: center;
  button {
    white-space: nowrap;
  }

  background: var(--neutral-09);
  margin: 0.5rem 0 0.5rem;
  align-items: center;

  .credential-total {
    margin-left: auto;
  }
  > div {
    padding: 0px !important;
    height: 40px !important;
  }

  > input + div {
    background: var(--primary-v-02) !important;
    border-radius: 8px !important;

    > div {
      width: 100% !important;
      display: flex;
      flex-direction: column;
      height: 40px;
      opacity: 1;
      padding: 8px;
      background: none !important;
    }

    > b {
      font-size: 14;
      font-weight: 700;
      color: var(--neutral-00);
    }
  }
`;

const Bulk = (props) => {
  const [stagedRows, setStagedRows] = React.useState([]);

  // TODO this are not being read anywhere??
  const [deletedRows, setDeletedRows] = React.useState([]);
  const [changedRows, setChangedRows] = React.useState([]);
  const [viewRows, setViewRows] = React.useState('staged');

  const handleOnDrop = (data) => {
    const rows = data
      .map((d) => d.data)
      .reduce((arr, row) => {
        if (!arr.some((x) => x.CardNr === row.CardNr)) {
          arr.push(row);
        }
        return arr;
      }, []);

    setStagedRows((prev) => [...prev, ...rows]);
  };

  const handleAddRow = React.useCallback(() => {
    const emptyRowIndex = stagedRows.findIndex(
      (r) => !r.Description && !r.Card && !r.CardNr && !r.FacilityCode && !r.PIN
    );

    //prevent adding multiple empty rows
    if (emptyRowIndex > -1) {
      const newArr = [...stagedRows];
      newArr.splice(emptyRowIndex, 1);
      return setStagedRows([
        {
          Description: '',
          Card: '',
          CardNr: '',
          FacilityCode: '',
          PIN: '',
          Person: '',
          department: '',
          level: ''
        },
        ...newArr
      ]);
    }

    setStagedRows((state) => [
      {
        PIN: '',
        Description: '',
        Card: '',
        CardNr: '',
        FacilityCode: '',
        Person: '',
        department: '',
        level: ''
      },
      ...state
    ]);
  }, [stagedRows]);

  const handleOnError = (err) => {
    console.log(err);
  };

  const handleOnRemoveFile = () => {
    setStagedRows([]);
    setDeletedRows([]);
    setChangedRows([]);
    setViewRows('staged');
  };

  const toastRef = React.useRef();
  const loading = () =>
    (toastRef.current = toast.info(
      'Uploading Credentials, this might take a while...',
      {
        autoClose: false,
        closeOnClick: false,
        closeButton: false
      }
    ));

  const error = (data) =>
    toast.update(toastRef.current, {
      type: toast.TYPE.ERROR,
      closeButton: true,
      closeOnClick: true,
      render: <>{data.message}</>
    });

  //pasre all errors here
  const settled = (data) =>
    toast.update(toastRef.current, {
      className: 'toast-default',
      type: '',
      closeButton: true,
      closeOnClick: false,
      render: (
        <div
          css={css`
            dt {
              font-weight: bold;
            }

            dl,
            dd {
              font-size: 0.95rem;
            }

            dd {
              margin-bottom: 1em;
            }
          `}
        >
          <p>Credentials Summary:</p>
          <dl>
            <dt>Added</dt>
            <dd>{Number(data?.data?.done?.length ?? 0)}</dd>

            <dt>Conflicts</dt>
            <dd>{Number(data?.data?.conflicts ?? 0)}</dd>

            <dt>Errors ({Number(data?.data?.errors.length ?? 0)})</dt>
            {/* <dd>
              {data?.data?.errors?.map((err, idx) => (
                <span
                  css={css`
                    display: block;
                  `}
                >
                  row {idx + 1} : {err}
                </span>
              ))}
            </dd> */}
          </dl>
          <CSVLink
            css={css`
              color: white;
              &:hover {
                text-decoration: underline;
              }
            `}
            data={data?.data?.errors ?? []}
          >
            Download Error Report
          </CSVLink>
        </div>
      )
    });

  const bulkMutation = useMutationWrapper(
    ['credential', 'save'],
    (input) => Credential.bulkAdd(input),
    {
      noToast: true,
      onMutate: () => loading(),
      onError: (data) => error(data),
      onSuccess: (data) => settled(data)
    }
  );

  const EditableCell = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateMyData, // This is a custom function that we supplied to our table instance
    style = {}
  }) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue);

    const onChange = (e) => {
      setValue(e.target.value);
    };

    const onBlur = () => {
      updateMyData(index, id, value);
    };

    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    return (
      <input
        style={style}
        css={css`
          font-size: 1rem;

          margin: 0;
          border: 0;
          &:focus {
            border: 4px solid black;
            border-radius: 5px;
          }
        `}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    );
  };

  const [skipPageReset, setSkipPageReset] = React.useState(false);

  const columns = [
    getRowNumberColumn(),
    {
      Header: () => {
        return (
          <IconButton delete compact onClick={() => setStagedRows([])}>
            <Trash />
            ALL
          </IconButton>
        );
      },
      id: 'actions',
      Cell: (tableProps) => {
        return (
          <span>
            <IconButton
              delete
              compact
              onClick={() => {
                // ES6 Syntax use the rvalue if your data is an array.
                const dataCopy = [...tableProps.rows.map((i) => i.original)];
                // It should not matter what you name tableProps. It made the most sense to me.
                dataCopy.splice(tableProps.row.index, 1);
                setStagedRows(dataCopy);
              }}
            >
              <Trash />
            </IconButton>
          </span>
        );
      }
    },
    {
      Header: 'Description',
      accessor: 'Description',

      Cell: (props) => <EditableCell {...props} style={{ width: '250px' }} />
    },
    {
      Header: 'Card',
      accessor: 'Card',

      Cell: (props) => <EditableCell {...props} style={{ width: '150px' }} />
    },
    {
      Header: 'PIN',
      accessor: 'PIN',

      Cell: (props) => <EditableCell {...props} style={{ width: '75px' }} />
    },
    {
      Header: 'Card Nr.',
      accessor: 'CardNr',

      Cell: (props) => <EditableCell {...props} style={{ width: '100px' }} />
    },
    {
      Header: 'Facility',
      accessor: 'FacilityCode',

      Cell: (props) => <EditableCell {...props} style={{ width: '75px' }} />
    },
    {
      Header: (props) => {
        return (
          <span
            css={css`
              display: inline-flex;
              align-items: center;
              gap: 0.2rem;
            `}
          >
            <SitePopover setStagedRows={props.setStagedRows} />
            Person
          </span>
        );
      },
      accessor: 'Person',

      Cell: (props) => (
        <div
          css={css`
            display: flex;
            flex-direction: column;
            span {
              font-size: 11px;
            }
          `}
        >
          <EditableCell {...props} style={{ width: '200px' }} />
          <span>
            {props.stagedRows[props.row.index]?.site?.name || 'All Sites'}
          </span>
        </div>
      )
    },
    {
      Header: (props) => {
        return (
          <span
            css={css`
              display: inline-flex;
              align-items: center;
              gap: 0.2rem;
            `}
          >
            <AccessLevelPopover setStagedRows={props.setStagedRows} />
            Access Level
          </span>
        );
      },

      accessor: 'level',
      style: { width: '300px' },
      Cell: (props) => {
        return (
          <AsyncSelect
            queryKey="ap-access-level-bulk"
            css={css`
              .select__menu-portal {
                z-index: 5;
              }
            `}
            data-compact
            fetchFn={AccessLevel.search}
            value={props.stagedRows?.[props?.row?.index]?.level}
            menuPosition="fixed"
            menuShouldBlockScroll={true}
            onChange={(e) => {
              let rows = [...props.stagedRows];
              rows[props.row.index].level = e;
              setStagedRows(rows);
            }}
            closeMenuOnSelect={true}
          />
        );
      }
    },
    {
      Header: (props) => {
        return (
          <span
            css={css`
              display: inline-flex;
              align-items: center;
              gap: 0.2rem;
            `}
          >
            <DepartmentPopover setStagedRows={props.setStagedRows} />
            Department
          </span>
        );
      },
      id: 'department',
      style: { width: '300px' },
      Cell: (props) => {
        return (
          <AsyncSelect
            queryKey="department-bulk"
            css={css`
              .select__menu-portal {
                z-index: 5;
              }
            `}
            label="Name"
            data-compact
            fetchFn={Department.search}
            value={props.stagedRows?.[props?.row?.index]?.department}
            menuPosition="fixed"
            menuShouldBlockScroll={true}
            onChange={(e) => {
              let rows = [...props.stagedRows];
              rows[props.row.index].department = e;
              setStagedRows(rows);
            }}
            closeMenuOnSelect={true}
          />
        );
      }
    }
  ];

  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    // setSkipPageReset(true)
    setSkipPageReset(true);
    setStagedRows((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  React.useEffect(() => {
    setSkipPageReset(false);
  }, [stagedRows]);

  return (
    <Template {...props}>
      <Content {...props}>
        <PageTitle>Import Credentials</PageTitle>
        <Breadcrumbs crumbs={props.crumbs} />
        <main css={mainStyles}>
          <section className="tools" css={toolsCSS}>
            <AuxiliaryButton compact onClick={downloadTemplate}>
              <Download />
              Download Template
            </AuxiliaryButton>
            <CSVReader
              onDrop={handleOnDrop}
              onError={handleOnError}
              config={{ header: true }}
              addRemoveButton
              onRemoveFile={handleOnRemoveFile}
            >
              <AuxiliaryButton compact>
                <b>Drop file or click to upload.</b>
              </AuxiliaryButton>
            </CSVReader>

            <div
              css={css`
                margin-left: auto;
                display: flex;
                align-items: center;

                gap: 0.5rem;
              `}
            >
              <AuxiliaryButton compact onClick={() => handleAddRow()}>
                Add Empty Credential
              </AuxiliaryButton>
              <Button
                compact
                disabled={bulkMutation.isLoading || stagedRows.length == 0}
                onClick={() => {
                  let creds = stagedRows.map((item) => ({ ...item }));
                  for (let i = 0; i < creds.length; i++) {
                    creds[i].personName = creds[i]['Person'];
                    delete creds[i].Person;
                    creds[i].level = creds[i]?.['level']?.id;
                    creds[i].department = creds[i]?.['department']?.id;
                    creds[i].sites = creds[i]?.['site']?.id
                      ? [creds[i]?.['site']?.id]
                      : false;
                  }

                  bulkMutation.mutate(creds);
                }}
              >
                Save Credentials ({stagedRows.length})
              </Button>
            </div>
          </section>

          <TableProvider
            columns={columns}
            updateMyData={updateMyData}
            tableData={stagedRows}
            setStagedRows={setStagedRows}
            autoResetPage={!skipPageReset}
            pageIndex={0}
            stagedRows={stagedRows}
            defaultColumn
            manualPagination={false}
            manualSortBy={false}
            manualGlobalFilter={false}
            initialState={{ pageSize: 30 }}
            getRowId={(row, relativeIndex, parent) =>
              parent ? [parent.id, relativeIndex].join('.') : relativeIndex
            }
          >
            {({}) => {
              return (
                <>
                  <Table>
                    <TablePagination />
                  </Table>
                </>
              );
            }}
          </TableProvider>
        </main>
      </Content>
    </Template>
  );
};

export default Bulk;

function downloadTemplate() {
  const rows = [
    ['Description', 'Card', 'PIN', 'CardNr', 'FacilityCode', 'Person']
  ];

  const csvContent =
    'data:text/csv;charset=utf-8,' + rows.map((e) => e.join(',')).join('\n');
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', 'Bulk Credential Import Template.csv');
  document.body.appendChild(link); // Required for FF
  link.click();
}
