import React from "react";

import { TextField } from "components/form";
import { useFormikContext, FieldArray } from "formik";
import SiteSelect from "components/form/site-select";
import { Field } from "formik";
import { css } from "@emotion/react";
/** @jsxImportSource @emotion/react */
import AsyncSelect from "components/headless-select/async";
import Site from "api/site";

const styles = css`
  display: grid;
  grid-template-columns: var(--form-column-width) var(--form-column-width);

  grid-column-gap: 24px;
  border-bottom: 2px solid var(--neutral-12);
  padding-bottom: 8px;

  .text-field:last-child {
    grid-column: 2 /2;
    grid-row: span 2;
  }
`;

const ScheduleForm = (props) => {
  const { values, setFieldValue } = useFormikContext();

  return (
    <article css={styles}>
      <TextField label="name" value={values.name} />
      <TextField name="description" value={values.description} />
      <Field
        selectAll
        name="sites"
        title="Sites With Access to This Schedule"
        isMulti={true}
        component={AsyncSelect}
        fetchFn={Site.search}
        onSelectAll={() => {
          setFieldValue("allSites", "on");
          setFieldValue("sites", null);
        }}
        onDeselectAll={() => {
          setFieldValue("sites", []);
          setFieldValue("allSites", null);
        }}
      />
    </article>
  );
};

export default ScheduleForm;
