import React from 'react';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */

const inputStyles = css`
  height: 24px;
  width: 24px;
`;

export const CHECKED = 1;
export const UNCHECKED = 0;
export const INDETERMINATE = -1;

const Toggle = ({ children, ...props }) => {
  const { value, ...otherProps } = props;

  const checkRef = React.useRef();

  React.useEffect(() => {
    checkRef.current.checked = Number(value) == CHECKED;
    checkRef.current.indeterminate = value === INDETERMINATE;
  }, [value]);

  return (
    <label
      role="slider"
      css={css`
        display: flex !important;
        align-items: center;
        gap: 5px;
        width: fit-content;
        position: relative;
        cursor: ${!props.disabled ? 'pointer' : 'auto'};
        input {
          display: none;
        }
        input:checked + .slider {
          background: var(--primary-base);
          border: none;
        }
        input:checked + .slider:before {
          background: var(--neutral-09);
        }

        input:disabled + .slider {
          opacity: 10%;
        }

        input:checked + .slider:before {
          -webkit-transform: translateX(26px);
          -ms-transform: translateX(14px);
          transform: translateX(14px);
        }
        .label {
          text-transform: capitalize;
          margin-left: 8px;
        }
        .slider {
          width: 36px;
          height: 24px;
          border-radius: 34px;
          background: var(--neutral-09);
          -webkit-transition: 0.4s;
          transition: 0.4s;

          border: 2px solid #c9cfda;
          box-sizing: border-box;
          border-radius: 50px;
          &:before {
            position: absolute;
            content: '';
            border-radius: 50%;
            height: 12px;
            width: 12px;
            left: 5px;
            bottom: 0;
            margin: auto;
            top: 0;
            background: #c9cfda;
            -webkit-transition: 0.4s;
            transition: 0.4s;
          }
        }
      `}
    >
      <input
        value={value}
        ref={checkRef}
        css={inputStyles}
        type="checkbox"
        {...otherProps}
      />
      <span className="slider"></span>
      {(props.label || props.name) && (
        <span className="label">{props.label ?? props.name}</span>
      )}
    </label>
  );
};

export default Toggle;
