import React from "react";
import { css } from "@emotion/react";
/** @jsxImportSource @emotion/react */
const styles = css`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;
const DepartmentAccessLevels = ({ dep }) => {
  if (!dep || !dep?.access_levels) return null;

  const als = dep.access_levels.map(al => al.name);

  return (
    <section className="access-levels" css={styles} title={als.join(", ")}>
      {als.slice(0, 2).join(", ")}
      {als.length > 2 && <span>(+{als.length - 2})</span>}
    </section>
  );
};

export default DepartmentAccessLevels;
