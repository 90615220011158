import React from 'react';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

//import * as Select from '@radix-ui/react-select';
import Select from 'react-select';

const INTERVALS = [
  { value: 1, label: '1 min' },
  { value: 5, label: '5 min' },
  { value: 15, label: '15 min' },
  { value: 30, label: '30 min' }
];
export default ({ filter, setFilter }) => {
  return (
    <article className="chart-interval">
      <Select
        unstyled
        css={css`
          .select__control {
            font-size: 14px;
            min-width: 120px;
            height: 100%;
            //min-height: 20px;

            color: white;
            //  border: none;
            cursor: pointer;
          }

          .select__value-container {
            overflow: unset !important;
            //padding: 0px;
            color: white;
            height: 100%;
          }
          .select__single-value {
          }
          .select__menu-list {
          }
          .select__option {
          }
        `}
        onChange={(e) =>
          setFilter((props) => ({ ...props, interval: e?.value }))
        }
        value={INTERVALS.find((i) => i.value == filter?.interval)}
        placeholder="Interval"
        isSearchable={false}
        classNamePrefix="select"
        components={{ IndicatorSeparator: () => null }}
        styles={{
          control: (base) => ({
            ...base,
            boxShadow: 'none !important',
            pointerEvent: 'all !important'
          }),

          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          menuList: (base) => ({
            ...base
            // kill the white space on first and last option
          }),
          option: (base, state) => ({
            ...base,
            backgroundColor: state.isSelected ? 'var(--primary-v-02)' : null,
            fontSize: '12px',
            color: 'black !important',

            '&:hover': {
              background: 'var(--neutral-05)'
            },
            '&:focus': {
              background: 'vaR(--neutral-05)'
            }
          })
        }}
        // menuPortalTarget={document.body}
        options={INTERVALS}
      />
    </article>
  );
};
