import React from 'react';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Camera, VolumeOff, VolumeUp } from 'react-bootstrap-icons';

export default ({ videoEl, videoProperties = {} }) => {
  if (!videoEl) return null;
  const handleSnapPicture = () => {
    videoEl.pause();
    var canvas = document.createElement('canvas');
    canvas.width = videoProperties?.width || 840;
    canvas.height = videoProperties?.height || 360;
    var ctx = canvas.getContext('2d');
    ctx.drawImage(videoEl, 0, 0, canvas.width, canvas.height);
    videoEl.play();
    // Ascii to binary conversion of canvas image
    var image = canvas.toDataURL('image/jpeg');
    var data = atob(image.substring('data:image/jpeg;base64,'.length));
    var asArray = new Uint8Array(data.length);
    for (var i = 0, len = data.length; i < len; ++i) {
      asArray[i] = data.charCodeAt(i);
    }

    // Create a blob from binary array
    var f = new Blob([asArray.buffer], { type: 'application/octet-stream' });
    var a = document.createElement('a');
    window.URL = window.URL || window.webkitURL;
    a.href = window.URL.createObjectURL(f);
    a.download = 'screenshot.png';
    document.body.appendChild(a);
    a.click();
    // Remove the temporary link
    document.body.removeChild(document.body.lastElementChild);
  };
  return (
    <article className="snap-control">
      <button onClick={handleSnapPicture}>
        <Camera />
      </button>
    </article>
  );
};
