import React from 'react';
import Template from 'base/template';
import Content from 'base/content';

import { useLocation } from 'react-router-dom';
import Error404 from 'base/error-404';
import SessionExpiredDialog from 'base/session-expired-dialog';

const ErrorHandler = ({ children, ...props }) => {
  const location = useLocation();
  const title = location?.state?.title;

  let status = location?.state?.errorStatusCode || {};

  switch (status) {
    case 401:
      return (
        <>
          {children}
          <SessionExpiredDialog />
        </>
      );

    case 404:
      return (
        <Template name={title}>
          <Content>
            <Error404 {...location?.state} />
          </Content>
        </Template>
      );

    default:
      return children;
  }
};

export default ErrorHandler;
