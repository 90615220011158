import { Link, Check, XLg } from 'react-bootstrap-icons';
import { css } from '@emotion/react';
import { dateTime, dateTimeShort } from 'utils/time';
import { Badge } from 'components/badge';
import { getSelectionColumn } from 'components/table-selection-column';
/** @jsxImportSource @emotion/react */
import TimeFormatter from 'components/time-formatter';
import { API_HOST_URL } from 'api/api-url';
import { IconButton } from 'components/buttons';
import { BookmarkCheckFill } from 'react-bootstrap-icons';

export const columns = [
  getSelectionColumn(),
  {
    Header: 'Name',
    accessor: 'name',
    pickerDisabled: true,
    asc: 'atoz',
    desc: 'ztoa',
    newSorter: true,
    Cell: ({ cell: { value }, row: { original } }) => (
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 0.5rem;
        `}
      >
        <span>{value}</span>
        <IconButton
          title="Open Guest Pass"
          onClick={() =>
            window.open(`${API_HOST_URL}/../guests/access/${original.hash}`)
          }
        >
          <Link size={24} color="#7f8795" />
        </IconButton>
      </div>
    )
  },
  {
    Header: 'In Today',
    id: 'in_today',
    disableSortBy: true,
    accessor: 'in_today',
    Cell: ({ row: { original } }) =>
      new Date(original.last_access_time).toDateString() ==
        new Date().toDateString() && <BookmarkCheckFill size={24} color="#008E5B" />
  },
  {
    Header: 'Status',
    accessor: 'ValidTo',
    disableSortBy: true,
    Cell: ({ cell: { value } }) => {
      let [Icon, klass, label] =
        new Date().toISOString() > new Date(value).toISOString()
          ? [XLg, 'warning', 'Expired']
          : [Check, 'success', 'Valid'];
      return (
        <Badge color={klass}>
          <Icon />
          {label}
        </Badge>
      );
    }
  },
  {
    Header: 'Enabled',
    disableSortBy: true,
    accessor: 'Enabled',
    Cell: ({
      cell: { value }
    }) => {
      return (
        <span>
          {value
            ? <Badge color="success">Active</Badge>
            : <Badge color="danger">Disabled</Badge>}
        </span>
      );
    }
  },
  {
    Header: 'Last Access',
    id: 'last_access_time',
    accessor: 'last_access_time',
    asc: 'oldestAccessTime',
    desc: 'newestAccessTime',
    newSorter: true,
    Cell: ({ cell: { value } }) => value ? <TimeFormatter dt={value} /> : null
  },
  {
    Header: 'Email Receipients',
    disableSortBy: true,
    accessor: 'email',
    Cell: ({ cell: { value } }) => {
      if (!value) return null;
      return (
        <span>
          {Array.isArray(value) ? value.join(', ') : ''}
        </span>
      );
    }
  },
  {
    Header: 'SMS Receipients',
    disableSortBy: true,
    accessor: 'sms',
    Cell: ({ cell: { value } }) => {
      if (!value) return null;
      return (
        <span>
          {Array.isArray(value) ? value.join(', ') : ''}
        </span>
      );
    }
  },
  {
    Header: 'Access Level',
    accessor: 'access_level_name',
    asc: 'atozAccessLevel',
    desc: 'ztoaAccessLevel',
    newSorter: true,
    Cell: ({ cell: { value } }) => {
      return <span>{value}</span>;
    }
  },

  {
    Header: 'Date Created',
    accessor: 'insert_time',
    asc: 'oldestInsertTime',
    desc: 'newestInsertTime',
    newSorter: true,
    Cell: ({ cell: { value } }) => {
      return <time title={new Date(value).toString()}>{dateTimeShort(new Date(value))}</time>;
    }
  },
  {
    Header: 'Expires At',
    asc: 'oldestValidTo',
    desc: 'newestValidTo',
    newSorter: true,
    Cell: ({ row: { original } }) => {
      return <time>{dateTime(original.ValidTo)}</time>;
    }
  }
];
