import styled from "@emotion/styled";
import { css } from "@emotion/react";
/** @jsxImportSource @emotion/react */
import { useFormikContext, getIn } from "formik";
import React from "react";

const InputWrapper = styled("input")`
  /* Auto Layout */
  color: var(--neutral-00);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  width: 100%;
  height: 48px;
  background: var(--neutral-09);
  border: 1px solid var(--neutral-12);
  box-sizing: border-box;
  border-radius: 5px;
  outline: none;
  cursor: text;

  /**fieldset & {
    width: ${(props) => (props.half ? "200px" : "400px")};
  }**/

  svg + & {
    padding-left: 42px;
  }

  &:focus {
    outline: 2px solid var(--neutral-12);
  }
  &:disabled {
    background: var(--neutral-07);
    cursor: default;
    border: 1px solid var(--neutral-12) !important;
  }
  &[data-error="true"] {
    outline: 2px solid #ff2f2f;
  }
`;

const iconStyle = css`
  position: absolute;
  top: 40px;
  left: 16px;
  opacity: 0.4;

  [data-compact] & {
    top: 36px;
  }

  &:first-child {
    top: 16px;
  }
`;

const Input = React.forwardRef((props, ref) => {
  const context = useFormikContext();
  const formikProps = {};
  const { name, icon: Icon } = props;

  const { errors, touched } = context || {};

  if (context) {
    formikProps.onChange = context.handleChange;
    formikProps.onBlur = context.handleBlur;
  }

  const dataError = !name
    ? false
    : (errors &&
        typeof errors[name] === "string" &&
        errors[name] &&
        touched[name]) ||
      (getIn(errors, name) && getIn(touched, name));

  return (
    <>
      {Icon && <Icon css={iconStyle} />}
      <InputWrapper
        ref={ref}
        data-error={dataError}
        {...formikProps}
        {...props}
      ></InputWrapper>
    </>
  );
});

export default Input;
