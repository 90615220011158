import React from 'react';
import MetricEvent from 'api/metric';
import { useQuery } from 'react-query';

import { css } from '@emotion/react';
import ChartLegend from './chart-legend';
/** @jsxImportSource @emotion/react */
import ActiveChartCore from 'components/charts/active-chart-core';
import Site from 'api/site';
import { useQueryWrapper } from 'utils/ajax';

const ActiveSiteChart = (props) => {
  const { filter = '-7 day', type = 'pie' } = props;
  const [chartData, setChartData] = React.useState([]);

  // const { isLoading, data } = useQuery(
  //   ['site-usage', filter],
  //   () =>
  //     MetricEvent.count({
  //       since: filter,
  //       type: 'accessGranted',
  //       groupBy: '$site_id',
  //       limit: 5
  //     }),
  //   {
  //     staleTime: 4 * (60 * 1000),
  //     cacheTime: 6 * (60 * 1000)
  //   }
  // );

  const { data, isLoading } = useQueryWrapper(
    ['site', 'most-active'],
    () => Site.search({ sort: 'mostActivity', limit: 5 }),
    {
      select: (data) => ({
        ...data,
        data: data?.data?.slice(0, 5) // only get 5 itesm till limit is implementes
      })
    }
  );

  React.useEffect(() => {
    if (!data?.data) return;

    setChartData(
      data?.data?.map((i) => ({ name: i.name, value: i.activity_total }))
    );
  }, [data]);

  return (
    <section
      css={css`
        height: 100%;
        width: 100%;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          height: 100%;
          width: 100%;
        `}
      >
        <ActiveChartCore
          chartData={chartData}
          isLoading={isLoading}
          type={type}
        />

        <ChartLegend data={chartData} />
      </div>
    </section>
  );
};

export default ActiveSiteChart;
