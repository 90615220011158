import React from "react";
import { css } from "@emotion/react";
/** @jsxImportSource @emotion/react */
import { ReactComponent as EmptySvg } from "assets/images/empty-holder.svg";

const articleStyles = css`
  position: relative;
  width: 100%;
  height: 100%;
  background: var(--neutral-09);
  box-shadow: 0px 5px 20px -5px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  margin-top: 8px;
  div.holder-container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0.5rem;
    padding: 2rem;
    span {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      background: var(--neutral-13);
      border-radius: 50%;
      width: 200px;
      height: 200px;
    }
    p {
      font-weight: bold;
      font-size: 20px;
      line-height: 100%;
      /* identical to box height, or 20px */

      display: flex;
      align-items: center;
      text-align: center;

      color: var(--neutral-00);
    }
    p:nth-child(3) {
      color: grey;
      font-size: 14px;
    }
  }
`;
const IndexEmptyHolder = (props) => {
  const { tag, children } = props;
  return (
    <article css={articleStyles}>
      <div className="holder-container">
        <span>
          <EmptySvg />
        </span>
        <p>{tag || "No results found for this search"}</p>

        {children}
      </div>
    </article>
  );
};

export default IndexEmptyHolder;
