import { useRef } from 'react';
import { GripVertical } from 'react-bootstrap-icons';
import { useDrag, useDrop } from 'react-dnd';
import { Toggle } from './form';

import { css } from '@emotion/react';
import { IconArrowsMove, IconGripVertical } from '@tabler/icons-react';
/** @jsxImportSource @emotion/react */

export const DraggableColumn = ({
  id,
  text,
  index,
  moveCard,
  column,
  onDragEnd
}) => {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: 'COL',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    }
  });
  const [{ isDragging }, drag] = useDrag({
    type: 'COL',
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    }),
    end(item, monitor) {
      if (typeof onDragEnd == 'function') onDragEnd();
    }
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <li
      ref={ref}
      data-handler-id={handlerId}
      className="draggable-column"
      css={css`
        display: flex;
        gap: 0.5rem;
        width: 100%;
        font-weight: 500;
        align-items: center;
        padding: 0.2rem 0.6rem;
        cursor: move;
        label {
          margin-left: auto;
        }
        &:hover {
          outline: 1px dashed;
        }
      `}
    >
      <IconGripVertical size={22} color="var(--light--neutrals--600)" />
      {column.label || column.Header}
      <Toggle
        disabled={column.pickerDisabled}
        {...column.getToggleHiddenProps({ title: undefined })}
      />
    </li>
  );
};
