/** @jsxImportSource @emotion/react */

import React from 'react';
import CompanyForm from 'views/companies/form';
import Company from 'api/company';
import Template from 'base/template';
import { css } from '@emotion/react';
import { Button, CancelButton, SecondaryButton } from 'components/buttons';
import { useHistory, Redirect } from 'react-router-dom';
import FormProvider from 'providers/form-provider';
import { useMutationWrapper } from 'utils/ajax';
import { useRedirect } from 'hooks/redirect-hook';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PageTitle from 'components/page-title';
import Content from 'base/content';
import Breadcrumbs from 'components/breadcrumbs';
const Save = (props) => {
  let company = props?.location?.state?.company;

  const SCHEMA = Yup.object().shape({
    display_name: Yup.string().required('Required'),
    phone: Yup.string().required('Required'),
    address: Yup.string().required('Required'),
    time_zone: Yup.string().required('Required').nullable(),
    event_ttl: Yup.number()
      .min(1, 'Must be between 1 and 365')
      .max(365, 'Must be between 1 and 365'),
    audit_log_ttl: Yup.number()
      .min(1, 'Must be between 1 and 60')
      .max(365, 'Must be between 1 and 365'),
    ad_id: Yup.string()
      .nullable()
      .when(['initially_enabled', 'ad_enabled'], {
        is: (initially_enabled, ad_enabled) => ad_enabled && !initially_enabled,
        then: Yup.string().required().nullable()
      }),
    ad_secret: Yup.string()
      .nullable()
      .when(['initially_enabled', 'ad_enabled'], {
        is: (initially_enabled, ad_enabled) => ad_enabled && !initially_enabled,
        then: Yup.string().required().nullable()
      }),
    ad_tenant: Yup.string()
      .nullable('')
      .when(['initially_enabled', 'ad_enabled'], {
        is: (initially_enabled, ad_enabled) => ad_enabled && !initially_enabled,
        then: Yup.string().required().nullable()
      }),
    ad_root: Yup.string()
      .nullable()
      .when(['initially_enabled', 'ad_enabled'], {
        is: (initially_enabled, ad_enabled) => ad_enabled && !initially_enabled,
        then: Yup.string().required().nullable()
      })
  });

  const INITIAL_VALUES = {
    event_ttl: company?.event_ttl,
    audit_log_ttl: company?.audit_log_ttl,
    mfa_enforce: company?.mfa_enforce,
    display_name: company?.display_name,
    phone: company?.phone,
    address: company?.address,
    time_zone: company?.time_zone,
    features: company?.permission ?? {},
    enabled: company?.enabled ?? true,
    mfa_enforce: company?.mfa_enforce == '1',
    ad_enabled: company?.ad_root ? true : false,
    ad_card_field: company?.ad_card_field,
    ad_card_nr_field: company?.ad_card_nr_field,
    ad_facility_code_field: company?.ad_facility_code_field,
    ad_pin_field: company?.ad_pin_field,
    ad_root: null,
    ad_secret: null,
    ad_tenant: null,
    ad_id: null,
    initially_enabled: company?.ad_root ? true : false
  };

  let { isSuccess, data, isLoading, mutate } = useMutationWrapper(
    ['company', 'save'],
    (input) => Company.save(input, company?.id),
    {}
  );

  const { redirect, setRedirect } = useRedirect(isSuccess);

  return (
    <Template {...props}>
      {redirect && <Redirect to="/companies" />}
      <Content {...props}>
        <PageTitle>{company ? company?.display_name : 'Company Add'}</PageTitle>
        <Breadcrumbs crumbs={props.crumbs} />
        <Formik
          validationSchema={SCHEMA}
          initialValues={INITIAL_VALUES}
          onSubmit={(values) => {
            const v = { ...values };
            if (!values.ad_enabled) {
              delete v.ad_root;
              delete v.ad_secret;
              delete v.ad_id;
              delete v.ad_tenant;
            } else {
              if (INITIAL_VALUES.ad_root == values.ad_root) delete v.ad_root;
              if (INITIAL_VALUES.ad_secret == values.ad_secret)
                delete v.ad_secret;
              if (INITIAL_VALUES.ad_tenant == values.ad_tenant)
                delete v.ad_tenant;
              if (INITIAL_VALUES.ad_id == values.ad_id) delete v.ad_id;
            }
            mutate(v);
          }}
        >
          {({ handleSubmit }) => (
            <FormProvider
              name="company"
              mutateFunc={handleSubmit}
              disabled={isLoading}
            >
              <CompanyForm company={company} />
              <footer>
                <Button type="submit">Submit</Button>
                <CancelButton onClick={() => setRedirect(true)} />
              </footer>
            </FormProvider>
          )}
        </Formik>
      </Content>
    </Template>
  );
};

export default Save;
