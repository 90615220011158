import React from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { XLg } from 'react-bootstrap-icons';

import { css, keyframes } from '@emotion/react';
/** @jsxImportSource @emotion/react */

const contentShow = keyframes(
  css`
    0% {
      opacity: 0;
      transform: translate(-50%, -48%) scale(0.96);
    }
    100% {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
  `
);

const overlayShow = keyframes(
  css`
    0% {
      opacity: 0;
    }
    100%: {
      opacity: 1;
    }
  `
);

export const DialogContent = React.forwardRef(
  ({ children, noCloseButton, ...props }, forwardedRef) => {
    const { title: TitleComponent } = props;
    return (
      <DialogPrimitive.Portal>
        <DialogPrimitive.Overlay
          css={css`
            inset: 0;
            background: ${props.portalOpacity
              ? 'rgba(0, 0, 0, 0.70)'
              : 'rgba(0, 0, 0, 0.44)'};
            opacity: ${props.portalOpacity ?? 0.3};
            position: fixed;
            @media (prefers-reduced-motion: no-preference) {
              animation: ${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1);
            }
            z-index: 4000;
          `}
        />
        <DialogPrimitive.Content
          ref={forwardedRef}
          css={css`
            background: white;
            border-radius: 6;
            box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
              hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 50vw;
            max-height: 85vh;
            height: 50vh;
            display: flex;
            flex-direction: column;

            z-index: 4001;
            @media (prefers-reduced-motion: no-preference) {
              animation: ${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1);
            }
            &:focus {
              outline: 'none';
            }
            > section.dialog-content {
              padding: 1.5rem;
              flex: 1;
              overflow: hidden;
              height: 100%;
            }
          `}
          {...props}
          title=""
        >
          {props.title &&
            (typeof props.title !== 'function' ? (
              <DialogPrimitive.Title
                css={css`
                  padding: 1rem;
                  background: #ffffff;
                  box-shadow: inset 0px -1px 0px #eceff3;
                  margin: 0;
                  font-weight: 700;
                  font-size: 16px;
                  line-height: 125%;
                  color: #7f8795;
                `}
              >
                {props.title}
              </DialogPrimitive.Title>
            ) : (
              <TitleComponent />
            ))}
          <section className="dialog-content">
            {children}
            {!noCloseButton && (
              <DialogPrimitive.Close
                aria-label="Close"
                css={css`
                  position: fixed;
                  top: 0px;
                  right: 0px;
                  margin: 0.5rem;
                `}
              >
                <XLg />
              </DialogPrimitive.Close>
            )}
          </section>
        </DialogPrimitive.Content>
      </DialogPrimitive.Portal>
    );
  }
);

export const Dialog = DialogPrimitive.Root;
export const DialogTrigger = DialogPrimitive.Trigger;
export const DialogClose = DialogPrimitive.Close;
