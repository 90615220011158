import React from 'react';

import Event from 'api/event';
import { useInfiniteQuery } from 'react-query';

import EventCard from 'components/event-card';

import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import EmptyHolder from './empty-holder';

import Spinner from './spinner';

import Heading from 'components/heading';
import LoadMoreButton from './load-more-button';

function groupBy(xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

const EventList = ({ query, ...props }) => {
  if (query.status === 'error') {
    return <div>error</div>;
  }

  const sectionStyles = css`
    width: 100%;
    display: inline-flex;
    position: relative;
    flex-direction: column;

    > div {
      overflow-y: auto;
      overflow-x: hidden;
    }

    .spinner {
      display: block;
      margin: 24px auto;
    }
  `;

  return (
    <section className="event-list" css={sectionStyles} {...props}>
      <div>
        {query.status == 'loading' && <Spinner />}
        {query.data && query.data.pages?.[0]?.data.length == 0 && (
          <EmptyHolder label="No recent events" />
        )}
        {query.data &&
          query.data.pages.map((group, i) => (
            <React.Fragment key={i}>
              {[...Object.values(groupBy(group.data, 'date'))].map((group) =>
                group.map((event, index) => (
                  <React.Fragment key={event.id}>
                    {index == 0 && <Heading label={event.date} />}

                    <EventCard key={event.id} data={event} />
                  </React.Fragment>
                ))
              )}
            </React.Fragment>
          ))}
        {query.hasNextPage && query.data.pages?.[0]?.data.length > 0 && (
          <LoadMoreButton
            hasNextPage={query.hasNextPage}
            fetchNextPage={query.fetchNextPage}
            isFetchingNextPage={query.isFetchingNextPage}
          />
        )}
      </div>
    </section>
  );
};

export default EventList;
