import React from "react";
import * as Yup from "yup";

const DOOR_DETAILS_CONST = {
  lock: "",
  double_lock: "",
  reader: "",
  reader_exit: "",
  hardware_id: "",
  name: "",
  accesstime: 7,
  extendedaccesstime: 30,
  opentoolongtime: 25,
  extendedopentoolongtime: 50,
  prealarmtime: 5,
  door_monitor: null,
  priorityconfiguration: null,
  rex: "None",
  door_monitor: "none",
  reader: "AADP",
};

function parseDoubleLock(dl, dl_type, dl_relay) {
  if (dl_type == "Relay") {
    if (dl_relay == "Closed") return "relay_locked";
    else return "relay_unlocked";
  } else if (dl_type == "Standard") {
    if (dl == "12V") return "fail_safe";
    else return "fail_secure";
  } else return "None";
}

function parseLock(lock, lock_type) {
  if (lock_type == "Standard") {
    if (lock == "12V") return "fail_safe";
    else return "fail_secure";
  } else if (lock == "Relay") {
    return "Relay";
  } else return "None";

  // TODO check double lock value
}

const parseReader = (readerType, readerLEDType) => {
  if (readerType == "Wiegand") {
    if (readerLEDType == "DualLED") return "wiegand_double";
    else if (readerLEDType == "SingleLED") return "wiegand_single";
  } else if (readerType == "RS-485HD") return "osdp";
  else return "none";
};

const parseDoorMonitor = (dm, dmType) => {
  if (dmType == "None") return "none";
  else if (dmType == "Standard") {
    if (dm == "Input Open") return "open_circuit";
    else if (dm == "Input Ground") return "closed_circuit";
  } else if (dmType == "RS485HD") {
    return "RS485HD";
  }
};

const useDoorDetails = (configuration, ac) => {
  const SCHEMA = Yup.object().shape({
    doors: Yup.array()
      .of(
        Yup.object().shape({
          // lock: Yup.string().required("Required").nullable(),
          //   /**  double_lock: Yup.string().when((""), {
          // 		is: Yup.array(),
          // 		then: Yup.string().nullable().required("Reuired"),
          // 	      }),**/
          //  reader: Yup.string().required("Required").nullable(),
          //   // reader_exit: Yup.string().required("Required").nullable(),
          // door_monitor: Yup.string().required("Required").nullable(),
          // rex: Yup.string().required("Required").nullable(),
          hardware_id: Yup.string().required("Required").nullable(),
        })
      )
      .required("Doors are required") // these constraints are shown if and only if inner constraints are satisfied
      .min(1, "Minimum of 1 doors")
      .nullable(),
  });

  const [initialValues, setInitialValues] = React.useState({ doors: [] });

  React.useEffect(() => {
    if (!configuration) return;
    setInitialValues({
      doors:
        configuration.length > 0
          ? configuration.map((door) => ({
              lock:
                ac.product == "A1001"
                  ? parseLock(door.lock, door?.lock_type)
                  : door?.lock_type,
              double_lock:
                ac.product == "A1001"
                  ? parseDoubleLock(
                      door?.double_lock,
                      door?.double_lock_type,
                      door?.double_lock_relay_type
                    )
                  : door?.double_lock_type,
              rex: door.rex_type,
              rex_token: door.rex_token,
              rex_open: door?.rex_action == "Access",
              door_monitor: parseDoorMonitor(
                door.door_monitor,
                door.door_monitor_type
              ),
              reader: "AADP",
              reader_exit: parseReader(
                door.reader_exit_type,
                door.reader_exit_led_type
              ),
              id: door?.id,
              token: door?.token,
              reader_token: door.reader_token,
              hardware_id: door.hardware_id,
              reader_exit_token: door.reader_exit_token,
              name: door?.Name,
              description: door?.Description,
              access_time: door?.AccessTime?.replace(/\D/g, "") || 7,
              extendedaccesstime:
                door?.ExtendedAccessTime?.replace(/\D/g, "") || 30,
              opentoolongtime: door?.OpenTooLongTime?.replace(/\D/g, "") || 25,
              extendedopentoolongtime:
                door?.ExtendedOpenTooLongTime?.replace(/\D/g, "") || 50,
              prealarmtime: door?.PreAlarmTime?.replace(/\D/g, "") || 5,
              priorityconfiguration: door?.PriorityConfiguration,
              reader_ap: door?.reader_ap,
              reader_exit_ap: door.reader_exit_ap,
              rex_ap: door.rex_ap,
              pinConfiguration: door.pinConfiguration,
            }))
          : [DOOR_DETAILS_CONST],
    });
  }, [configuration]);

  return { initialValues, SCHEMA, DOOR_DETAILS_CONST };
};

export default useDoorDetails;
