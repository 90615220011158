/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import Device from 'api/device';
import { useQueryWrapper, useMutationWrapper } from 'utils/ajax';
import * as Slider from '@radix-ui/react-slider';

import styled from '@emotion/styled';

import { Mic, VolumeUp, VolumeMute, MicMute } from 'react-bootstrap-icons';
import { IconButton } from 'components/buttons';
import { Button } from 'components/buttons';
import Spinner from 'components/spinner';
import { Toggle } from 'components/form';

const VerticalSliderConteiner = styled('aside')`
  .SliderRoot {
    position: relative;
    display: flex;
    align-items: center;
    &[aria-disabled='true'] {
      opacity: 50%;
      cursor: default;
    }
  }
  .SliderRoot[data-orientation='vertical'] {
    flex-direction: column;
    width: 20px;
    height: 200px;
  }

  .SliderTrack {
    position: relative;
    flex-grow: 1;
    background-color: grey;
  }
  .SliderTrack[data-orientation='vertical'] {
    width: 3px;
  }

  .SliderRange {
    position: absolute;
    background-color: var(--neutral-05);
  }
  .SliderRange[data-orientation='vertical'] {
    width: 100%;
  }

  .SliderThumb {
    cursor: pointer;
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: var(--primary-base);
  }
`;

export default ({ deviceId }) => {
  const { data, isLoading } = useQueryWrapper(
    ['device', 'capabilities', deviceId],
    () =>
      Device.capabilities(deviceId, [
        'lineOut',
        'input',
        'output',
        'audioSupport'
      ]),
    {
      cacheTime: 0
    }
  );

  const [inputGain, setInputGain] = React.useState(0);
  const [outputGain, setOutputGain] = React.useState(0);
  const [lineOutGain, setLineOutGain] = React.useState(0);
  const [enabled, setEnabled] = React.useState();

  const inputGainValues = data?.inputGain?.values
    .filter((item) => !isNaN(Number(item)) && Number(item))
    .map((i) => Number(i));
  const outputGainValues = data?.outputGain?.values
    .filter((item) => !isNaN(Number(item)) && Number(item))
    .map((i) => Number(i));
  const lineOutGainValues = data?.lineOutGain?.values
    .filter((item) => !isNaN(Number(item)) && Number(item))
    .map((i) => Number(i));

  const mutation = useMutationWrapper(['device', 'setAudio', deviceId], (cap) =>
    Device.setAudio(deviceId, cap)
  );

  React.useEffect(() => {
    // neeed to also make sure that current value is inside avaiable values. beacuse of old edge cases
    if (!data || data?.length == 0 || !data?.audioSupport) return;
    setEnabled(data?.audioSupport.current == 'yes');
    if (
      data?.inputGain &&
      data.inputGain.values.includes(data.inputGain.current)
    )
      setInputGain(data.inputGain.current);
    if (
      data?.outputGain &&
      data.outputGain.values.includes(data.outputGain.current)
    )
      setOutputGain(data.outputGain.current);
    if (
      data?.lineOutGain &&
      data.lineOutGain.values.includes(data.lineOutGain.current)
    )
      setLineOutGain(data.lineOutGain.current);
  }, [data]);

  const handleSave = React.useCallback(() => {
    let cap = {};

    if (data.inputGain?.current) cap.inputGain = inputGain;
    if (data?.outputGain?.current) cap.outputGain = outputGain;
    if (data?.lineOutGain?.current) cap.lineOutGain = lineOutGain;
    if (data?.audioSupport?.current) cap.enabled = enabled;
    mutation.mutate(cap);
  }, [data, enabled, inputGain, outputGain, lineOutGain]);

  if (data && data.length == 0) return <div>No audio support</div>;

  if (data)
    return (
      <article
        css={css`
          display: flex;
          flex-direction: column;
          gap: 1rem;
          section {
            display: flex;
            gap: 2rem;
            > * {
              h3 {
                font-size: 15px;
                color: var(--secondary-font-color);
                font-weight: 400;
              }
              border: 1px solid var(--neutral-05);
              padding: 2rem;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              gap: 1rem;
            }
          }
        `}
      >
        <Toggle
          value={enabled}
          label="Enable Audio"
          onChange={(e) => setEnabled(e.target.checked)}
        />
        <section>
          {data?.inputGain && (
            <VerticalSliderConteiner>
              <h3>Input Gain</h3>
              <label>{inputGain == 'mute' ? 'mute' : inputGain + 'db'}</label>
              <Slider.Root
                disabled={!enabled}
                orientation="vertical"
                className="SliderRoot"
                max={Math.max(...inputGainValues)}
                min={Math.min(...inputGainValues)}
                step={1}
                aria-label="Volume"
                value={[inputGain != 'mute' ? inputGain : 0]}
                onValueChange={(e) => setInputGain(e[0])}
              >
                <Slider.Track className="SliderTrack">
                  <Slider.Range className="SliderRange" />
                </Slider.Track>
                <Slider.Thumb className="SliderThumb" />
              </Slider.Root>
              {data.inputGain.values.includes('mute') && (
                <IconButton
                  onClick={() => setInputGain('mute')}
                  disabled={!enabled}
                >
                  {inputGain == 'mute' ? <MicMute /> : <Mic />}
                </IconButton>
              )}
            </VerticalSliderConteiner>
          )}

          {data?.outputGain && (
            <VerticalSliderConteiner>
              <h3>Output Gain</h3>
              <label>{outputGain == 'mute' ? 'mute' : outputGain + 'db'}</label>
              <Slider.Root
                disabled={!enabled}
                orientation="vertical"
                className="SliderRoot"
                max={Math.max(...outputGainValues)}
                min={Math.min(...outputGainValues)}
                step={1}
                aria-label="Volume"
                value={[outputGain != 'mute' ? outputGain : 0]}
                onValueChange={(e) => setOutputGain(e[0])}
              >
                <Slider.Track className="SliderTrack">
                  <Slider.Range className="SliderRange" />
                </Slider.Track>
                <Slider.Thumb className="SliderThumb" />
              </Slider.Root>
              {data.outputGain.values.includes('mute') && (
                <IconButton
                  onClick={() => setOutputGain('mute')}
                  disabled={!enabled}
                >
                  {outputGain == 'mute' ? <VolumeMute /> : <VolumeUp />}
                </IconButton>
              )}
            </VerticalSliderConteiner>
          )}
          {data?.lineOutGain && (
            <VerticalSliderConteiner>
              <h3>Line Out Gain</h3>

              <label>
                {lineOutGain == 'mute' ? 'mute' : lineOutGain + 'db'}
              </label>
              <Slider.Root
                disabled={!enabled}
                orientation="vertical"
                className="SliderRoot"
                max={Math.max(...lineOutGainValues)}
                min={Math.min(...lineOutGainValues)}
                step={1}
                aria-label="Volume"
                value={[lineOutGain != 'mute' ? lineOutGain : 0]}
                onValueChange={(e) => setLineOutGain(e[0])}
              >
                <Slider.Track className="SliderTrack">
                  <Slider.Range className="SliderRange" />
                </Slider.Track>
                <Slider.Thumb className="SliderThumb" />
              </Slider.Root>
              {data.lineOutGain.values.includes('mute') && (
                <IconButton
                  onClick={() => setLineOutGain('mute')}
                  disabled={!enabled}
                >
                  {lineOutGain == 'mute' ? <VolumeMute /> : <VolumeUp />}
                </IconButton>
              )}
            </VerticalSliderConteiner>
          )}
        </section>
        <footer>
          <Button loading={mutation.isLoading} onClick={handleSave}>
            Save
          </Button>
        </footer>
      </article>
    );
  return <Spinner />;
};
