import React from 'react';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import { useMutatePing } from './actions';
import { Badge } from 'components/badge';
import { ArrowClockwise, XLg } from 'react-bootstrap-icons';
import { IconButton } from 'components/buttons';
import IsRole from 'components/is-role';
import {
  IconApiApp,
  IconSettingsAutomation
} from '@tabler/icons-react';

const STATES = {
  UNSYNCED: 'unsynced',
  ONLINE: 'online',
  OFFLINE: 'offline',
  IN_SYNC: 'insync',
  PENDING: 'pending',
  FIRMWARE: 'firmware'
};

function reducer(state, action) {
  switch (action.type) {
    case STATES.OFFLINE:
      return ['red', 'offline'];
    case STATES.ONLINE:
      return ['success', 'online'];
    case STATES.UNSYNCED:
      return ['warning', 'unsynced', XLg];
    case STATES.IN_SYNC:
      return ['warning', 'in-sync', ArrowClockwise];
    case STATES.PENDING:
      return ['purple', 'Pending Setup', IconSettingsAutomation];
    case STATES.FIRMWARE:
      return ['purple', 'Upgrading Firmware', IconApiApp];
    default:
      throw new Error();
  }
}

const DeviceStatusFormatter = (props) => {
  const { device } = props;

  const pingMutate = useMutatePing(device);
  const handlePing = React.useCallback(() => {
    pingMutate.mutate(device.device_id);
  }, [device]);

  const [state, dispatch] = React.useReducer(reducer, []);

  React.useEffect(() => {
    if (device.synced == 2) dispatch({ type: STATES.IN_SYNC });
    else if (device.online == 0 && device.setup == 3)
      dispatch({ type: STATES.OFFLINE });
    else if (device.online == 0 && device.setup == 2)
      dispatch({ type: STATES.PENDING });
    else if (device.online == 1 && device.synced == 0)
      dispatch({ type: STATES.UNSYNCED });
    else if (device.setup == 4 || device.setup == 5)
      dispatch({ type: STATES.FIRMWARE });
    else
      dispatch({
        type: STATES.ONLINE
      });
  }, [device]);

  const Icon = state?.[2];

  return (
    <article
      css={css`
        display: flex;
        gap: 8px;
        align-items: center;

        > button {
          margin-left: auto;
        }
      `}
    >
      <Badge color={state?.[0]}>
        {Icon && <Icon />}
        <span>{state?.[1]}</span>
      </Badge>

      <IsRole restrict="C">
        <IconButton
          css={css`
            height: 24px;
            border: 1px solid var(--neutral-03);
          `}
          onClick={handlePing}
          title="Ping device to check online status"
          disabled={pingMutate.isLoading}
        >
          PING
        </IconButton>
      </IsRole>
    </article>
  );
};

export default DeviceStatusFormatter;
