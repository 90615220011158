import React from 'react';
import Template from 'base/template';
import ActionMenu from 'views/auxiliaries/action-menu';

import { columns } from 'views/cloud-recordings/table-columns';
import Sidebar from 'views/cloud-recordings/sidebar';
import IndexContent from 'base/index-content';
import { useAuth } from 'providers/auth';
import CloudRecording from 'api/cloud-recording';
import { IconCloudComputing, IconCloudOff } from '@tabler/icons-react';
import ActionRule from 'api/action_rule';
const cards = [
  {
    name: 'total',
    label: 'Total'
  },
  {
    name: 'active',
    label: 'Live Feeds',
    icon: IconCloudComputing,
    color: 'var(--light--primary--400-base)',
    background: 'var(--light--primary--200)'
  },
  {
    name: 'inactive',
    label: 'Inactive',
    icon: IconCloudOff,
    color: 'var(--light--others--red-400-base)',
    background: 'var(--light--others--red-100)'
  },
  {
    name: 'hadDrops24Hr',
    label: 'Issues in 24 Hr',
    icon: IconCloudOff,
    color: 'var(--light--others--red-400-base)',
    background: 'var(--light--others--red-100)',
    filter: { hadDrops24Hr: true }
  }
];

/* initialSorter={[
          {
            id: 'status',
            sortBy: 'newestStatus',
            desc: true
          }
        ]} **/
const CloudRec = (props) => {
  const { enforce, user } = useAuth();

  return (
    <Template {...props}>
      <IndexContent
        queryParams={{
          refetchInterval: 15000
          //refetchIntervalInBackground: true
        }}
        statsQuery={ActionRule.cloudRecordingStats}
        newSorter
        title="Cloud Recordings"
        id="Cloud Recordings"
        cards={cards}
        query={ActionRule.cloudRecordingSearch}
        columns={columns}
        sidebar={Sidebar}
        emptyIcon={IconCloudOff}
        // getRowId={(row, relativeIndex, parent) => row._id}
        selectable
        // actionButton={ActionMenu}
        //  emptyIcon={AuxiliaryEmptyIcon}
        {...props}
      ></IndexContent>
    </Template>
  );
};

export default CloudRec;
