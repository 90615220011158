import React from "react";
import { css } from "@emotion/react";
/** @jsxImportSource @emotion/react */
import { useAuth } from "providers/auth";
import { useHistory } from "react-router-dom";
import { SecondaryButton } from "components/buttons";
import { Button } from "components/buttons";

const styles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;

  button.stop-manage {
    height: 40px;
    padding: 0 16px;
    background: var(--red-v-02);
    color: var(--red-base);
    border-radius: 5px;
    font-weight: 700;
    font-size: 14px;
  }
`;

const Footer = () => {
  const history = useHistory();
  const { user, siteExitFocus, focusedSite } = useAuth();
  return (
    <footer css={styles}>
      {user.role == "I" && (
        <Button onClick={() => history.push("/sites/add")}>+ Add</Button>
      )}
      <SecondaryButton
        data-compact
        compact
        onClick={() => history.push("/sites")}
      >
        View All
      </SecondaryButton>
      {focusedSite && (
        <button className="stop-manage" onClick={() => siteExitFocus.mutate()}>
          Stop Focus
        </button>
      )}
    </footer>
  );
};

export default Footer;
