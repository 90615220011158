import React from 'react';
import { Trash } from 'react-bootstrap-icons';
import styled from '@emotion/styled';
import { Menu } from '@headlessui/react';
import MenuItemButton from './menu-item-button';
import { css } from '@emotion/react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

/** @jsxImportSource @emotion/react */

const MenuItem = React.forwardRef((props, ref) => {
  return (
    <DropdownMenu.Item disabled={props.disabled}>
      <MenuItemButton {...props} />
    </DropdownMenu.Item>
  );
});

const MenuItemDelete = (props) => {
  return (
    <DropdownMenu.Item disabled={props.disabled}>
      <MenuItemButton delete {...props}>
        <Trash />
        DELETE
      </MenuItemButton>
    </DropdownMenu.Item>
  );
};

const MenuSeperator = () => (
  <DropdownMenu.Separator
    css={css`
      height: 1px;
      background: var(--neutral-04);
      width: 100%;
      margin-top: 5px;
      margin-bottom: 5px;
    `}
  />
);

const MenuLabel = React.forwardRef(({ children, ...props }, forwardedRef) => {
  return (
    <DropdownMenu.Label
      css={css`
        font-size: 12px;
        padding: 4px 12px;
        font-size: 11px;
        color: #999;
        cursor: default;
        display: block;

        font-weight: 500;

        text-transform: uppercase;
        box-sizing: border-box;
      `}
      {...props}
      ref={forwardedRef}
    >
      {children}
    </DropdownMenu.Label>
  );
});

export { MenuItem, MenuItemDelete, MenuSeperator, MenuLabel };
