import React from 'react';
import { css } from '@emotion/react';
import Event from 'api/event';
/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import Player from 'components/video/player';
import { WidgetHeader } from './widget-header';
import { useQueryWrapper } from 'utils/ajax';

import usePlayerControls from 'components/use-player-controls';
import RemoveItemButton from './remove-item-button';
import Camera from 'api/camera';

import GenericDoor from 'api/generic_door';
import { TitleCards, IconCards } from './door-widget';
import DoorStateAvatar from 'views/doors/door-state-avatar';
import { AvatarCircle } from 'components/avatar';
import ActionMenu from 'views/doors/action-menu';

import WidgetGridControls from './widget-grid-controls';
import { WidgetControls } from './widget-controls';

import { FetchingWidgetHolder } from './fetching-widget-holder';
import {
  IconAlertCircle,
  IconArrowLeft,
  IconArrowRight,
  IconCaretLeft,
  IconCaretRight,
  IconChevronLeft,
  IconChevronRight,
  IconDoor,
  IconDoorEnter,
  IconMapPin,
  IconPlayerPause,
  IconPlayerPlay,
  IconVideo,
  IconWorld
} from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { SiteIcon } from 'icons';
import DeviceAlertsTableIndicator from 'components/device-alerts-table-indicator';
import { useLayout } from 'providers/layout-provider';
import { useEventSource } from 'react-use-websocket';
import { useAuth } from 'providers/auth';
import { useMotionEvent } from 'views/cameras/use-motion-event';
import CameraWidget from './camera-widget';

export default ({
  cameraId = [],
  isEdit,
  index,
  widgetIndex,
  selectedView,
  setSelectedView,
  ...otherProps
}) => {
  const CAROUSEL_TIMEOUT = 15000;
  const [currentIndex, setCurrentIndex] = React.useState(0);

  const interval1 = React.useRef();
  const interval2 = React.useRef();

  const [isRunning, setIsRunning] = React.useState(true);

  const [time, setTime] = React.useState(CAROUSEL_TIMEOUT / 1000);

  const carouselMoveForward = React.useCallback(() => {
    if (currentIndex == cameraId.length - 1) return setCurrentIndex(0);
    else return setCurrentIndex((prev) => prev + 1);
  }, [currentIndex]);

  const carouselMoveBackword = React.useCallback(() => {
    if (currentIndex == 0) return setCurrentIndex(cameraId.length - 1);
    else return setCurrentIndex((prev) => prev - 1);
  }, [currentIndex]);

  React.useEffect(() => {
    if (!isRunning) return;
    interval1.current = setInterval(() => {
      carouselMoveForward();
    }, CAROUSEL_TIMEOUT);

    return () => clearInterval(interval1.current);
  }, [currentIndex, isRunning]);

  React.useEffect(() => {
    if (!isRunning) return;
    interval2.current = setInterval(() => {
      setTime((time) => time > 0 && time - 1);
    }, 1000);

    return () => {
      clearInterval(interval2.current);
      setTime(CAROUSEL_TIMEOUT / 1000);
    };
  }, [currentIndex, isRunning]);

  React.useEffect(() => {
    if (!isRunning) {
      interval1.current && clearInterval(interval1.current);
      interval2.current && clearInterval(interval2.current);
    }
  }, [isRunning]);

  return (
    <article
      className="carousel-container"
      css={css`
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        height: 100%;
        .camera-widget {
          transition: 1s cubic-bezier(0.39, 0575 0565 1);
        }
        &:hover {
          .carousel-controls {
            opacity: 60%;
          }
        }
      `}
    >
      {time < 4 && (
        <section
          className="carousel-timeoput"
          css={css`
            opacity: 100%;
            position: absolute;
            height: 56px;
            width: fit-content;
            z-index: 1002;
            color: white;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: fit-content;
            position: absolute;
            border-radius: 8px;
            top: 30%;
            right: 50%;
            transform: translate(50%, -50%);
            background: black;
            color: white;
            align-items: center;
            opacity: 60%;
            > time {
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 48px;
              height: 56px;
              width: 56px;
            }
          `}
        >
          <time>{time}</time>
        </section>
      )}
      <section
        className="carousel-controls"
        css={css`
          opacity: 0%;
          position: absolute;
          height: 56px;
          width: fit-content;
          z-index: 1002;
          color: white;
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: fit-content;
          position: absolute;
          border-radius: 8px;
          top: 50%;
          right: 50%;
          transform: translate(50%, -50%);
          background: black;
          color: white;
          align-items: center;
        `}
      >
        <button onClick={carouselMoveBackword}>
          <IconChevronLeft size={56} />
        </button>
        <button onClick={() => setIsRunning((prev) => !prev)}>
          {isRunning ? (
            <IconPlayerPause size={48} strokeWidth={1} />
          ) : (
            <IconPlayerPlay size={48} />
          )}
        </button>

        <button onClick={carouselMoveForward}>
          <IconChevronRight size={56} />
        </button>
      </section>
      <CameraWidget
        carousel
        isEdit={isEdit}
        cameraId={cameraId[currentIndex]}
        setSelectedView={setSelectedView}
        selectedView={selectedView}
        index={index}
        widgetIndex={widgetIndex}
      />
    </article>
  );
};
