import React from "react";
import Template from "base/template";
import GroupForm from "views/groups/form";
import { Button, CancelButton } from "components/buttons";
import { Redirect } from "react-router-dom";
import FormProvider from "providers/form-provider";
import { useMutationWrapper } from "utils/ajax";
import { useRedirect } from "hooks/redirect-hook";
import { dateTimeLocalSQLNoSeconds } from "utils/time";
import Group from "api/group";
import { Formik } from "formik";
import * as Yup from "yup";
import PageTitle from "components/page-title";
import Content from "base/content";
import Breadcrumbs from "components/breadcrumbs";

const Save = (props) => {
  let group = props?.location?.state?.group || null;

  let { isSuccess, isLoading, mutate } = useMutationWrapper(
    ["group", "save"],
    (input) => Group.save(input, group?.id),
    {}
  );

  const SCHEMA = Yup.object().shape({
    name: Yup.string()
      .min(2, "Description is too short")
      .max(40, "40 characters max")
      .required(),
    color: Yup.string()
      .matches(/^#[0-9A-F]{6}$/i, "Is not in correct format")
      .required(),
  });

  const INITIAL_VALUES = {
    ...group,
    name: group?.name,
    color: group?.color,
  };

  const { redirect, setRedirect } = useRedirect(isSuccess);

  return (
    <Template {...props}>
      {redirect && <Redirect to="/groups" />}
      <Content {...props}>
        <PageTitle>Group {group ? " Save" : " Add"}</PageTitle>
        <Breadcrumbs crumbs={props.crumbs} />
        <Formik
          validationSchema={SCHEMA}
          initialValues={INITIAL_VALUES}
          onSubmit={(values) => {
            mutate(values);
          }}
        >
          {({ handleSubmit }) => (
            <FormProvider
              name="group"
              mutateFunc={handleSubmit}
              disabled={isLoading}
            >
              <GroupForm group={group} />
              <footer>
                <Button type="submit">Submit</Button>
                <CancelButton onClick={() => setRedirect(true)} />
              </footer>
            </FormProvider>
          )}
        </Formik>
      </Content>
    </Template>
  );
};

export default Save;
