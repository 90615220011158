import React from 'react';

import { useFormikContext, FieldArray } from 'formik';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import { DialogDisclosure } from 'reakit/Dialog';

import ActionModal from './action-modal';

import H4 from 'components/form/h4';
import { useModal } from 'providers/modal-provider';

import ACTIONS from './action-types';
import Label from 'components/label';
import { PlusLg } from 'react-bootstrap-icons';
import { AuxiliaryButton, IconButton } from 'components/buttons';

import { Trash, Pencil } from 'react-bootstrap-icons';

import { ErrorMessage } from 'components/form';
import { Field } from 'formik';
import SelectWrapper from 'components/headless-select';

const DELAY_OPTIONS = [
  { value: '0', name: 'No Delay (run immediately)' },
  { value: '60', name: '1 minute' },
  { value: '300', name: '5 minutes' },
  { value: '900', name: '15 minutes' },
  { value: '1800', name: '30 minutes' },
  { value: '3600', name: '1 hour' },
  { value: '14400', name: '4 hours' }
];
const ActionModalDisclosure = ({ children, index, as, action }) => {
  const mainForm = useFormikContext();
  const { dialog, setModalState } = useModal();

  return (
    <DialogDisclosure
      {...dialog}
      onClick={() =>
        setModalState({
          component: ActionModal,
          action,
          dialogProps: { hideOnClickOutside: false },

          onSubmit: (innerValues) => {
            if (!action)
              mainForm.setFieldValue('actions', [
                ...mainForm.values.actions,
                innerValues
              ]);

            if (action) {
              let newActions = mainForm.values.actions;
              newActions[index] = innerValues;
              mainForm.setFieldValue('actions', newActions);
            }
          }
        })
      }
      as={as}
      compact
    >
      {children}
    </DialogDisclosure>
  );
};

const ActionForm = ({ index = null }) => {
  const mainForm = useFormikContext();
  return (
    <article className="actions">
      <H4>Actions</H4>
      <section
        css={css`
          margin-bottom: 24px;
          color: var(--neutral-00);
          display: -ms-grid;
          display: grid;
          -ms-grid-columns: var(--form-column-width) var(--form-column-width);
          grid-template-columns: var(--form-column-width) var(
              --form-column-width
            );
          gap: 24px;
        `}
      >
        <Field
          title="Delay"
          placeholder="Select duration..."
          name="Delay"
          helper="Run action after a delay of... (approximate)"
          idx="value"
          label="name"
          simple
          component={SelectWrapper}
          options={DELAY_OPTIONS}
          value={DELAY_OPTIONS.find(
            (int) => int.value == mainForm.values.delay
          )}
          onChange={(e) => mainForm.setFieldValue('delay', e.value)}
        />
      </section>
      {/* <AddActionMenu /> */}
      <ActionModalDisclosure as={AuxiliaryButton}>
        <PlusLg />
        Add an Action
      </ActionModalDisclosure>
      <ErrorMessage name="actions" />

      {/* <SMSCards mainForm={mainForm} />
      <EmailCards mainForm={mainForm} />
      <DoorActionCards mainForm={mainForm} />
      <DoorScheduleCards mainForm={mainForm} /> */}

      <FieldArray
        name="actions"
        render={(arrayHelpers) => (
          <ul
            css={css`
              color: var(--neutral-00);
              margin-top: 2rem;

              /* border-bottom */
              /* lm/Neutrals/neutral-05 */

              border: 1px solid var(--neutral-12);
              box-sizing: border-box;
              border-radius: 4px;
              box-shadow: inset 0px -1px 0px var(--neutral-12);
              border-radius: 4px;
              .controls {
                display: flex;
                margin-left: auto;
              }
            `}
          >
            {mainForm.values.actions &&
              mainForm.values.actions.length > 0 &&
              mainForm.values.actions.map((action, index) => (
                <li
                  css={css`
                    display: flex;
                    align-items: center;
                    margin: 1rem;
                    gap: 0.5rem;
                  `}
                >
                  <Label>
                    {ACTIONS.find((i) => i.value == action.type)?.label}
                  </Label>
                  <section className="controls" key={index}>
                    <IconButton
                      delete
                      onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                    >
                      <Trash />
                    </IconButton>

                    <ActionModalDisclosure
                      as={IconButton}
                      index={index}
                      action={action}
                    >
                      <Pencil />
                    </ActionModalDisclosure>
                  </section>
                </li>
              ))}
          </ul>
        )}
      />
    </article>
  );
};

export default ActionForm;
