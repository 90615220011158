import React from 'react';
import Template from 'base/template';

import Intercom from 'api/intercom';
import { columns } from 'views/speakers/table-columns';
import Sidebar from 'views/intercoms/sidebar';
import IndexContent from 'base/index-content';
import ActionMenu from 'views/intercoms/action-menu';
import { Power } from 'react-bootstrap-icons';
import { useAuth } from 'providers/auth';
import { ReactComponent as IntercomEmptyIcon } from 'assets/empty-icons/empty-intercom.svg';

import Speaker from '../../api/speaker';

const cards = [
  {
    name: 'total',
    label: 'Total'
  }
];

const Speakers = (props) => {
  const { user } = useAuth();
  return (
    <Template {...props}>
      <IndexContent
        initialFilter={{ site: user.focused_site }}
        statsQuery={Speaker.stats}
        title="speakers"
        id="speakres"
        cards={cards}
        query={Speaker.search}
        columns={columns}
        // actionButton={ActionMenu}
        emptyIcon={IntercomEmptyIcon}
        {...props}
      ></IndexContent>
    </Template>
  );
};

export default Speakers;
