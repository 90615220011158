import React from "react";
import { css } from "@emotion/react";
/** @jsxImportSource @emotion/react */
import { useFormikContext } from "formik";
import { InputLabel } from "components/form";
import { Field } from "formik";

const styles = css`
  > div {
    display: flex;
    align-items: center;
    gap: 16px;
    input[type="checkbox"] {
      width: 24px;
      height: 24px;
      accent-color: var(--primary-base);
      border-radius: 5px;
      border-color: var(--checkbox-border-color);
    }
    label {
      display: flex;
      align-items: center;
      gap: 2px;
    }
    div {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      /* identical to box height */

      /* Neutrals/neutral-01 */

      color: var(--menu-item-color);
    }
  }
`;

const WeekCheckboxGroup = ({ eventIndex }) => {
  const { values, setFieldValue } = useFormikContext();

  return (
    <section className="week-checkbox-group" css={styles}>
      <InputLabel label="Week Days" />
      <div role="group" aria-labelledby="checkbox-group">
        <label>
          <Field
            type="checkbox"
            name="days"
            value="SU"
            checked={values?.days?.some((i) => i == "SU")}
          />
          Sunday
        </label>
        <label>
          <Field
            type="checkbox"
            name="days"
            value="MO"
            checked={values?.days?.some((i) => i == "MO")}
          />
          Monday
        </label>
        <label>
          <Field
            type="checkbox"
            name="days"
            value="TU"
            checked={values?.days?.some((i) => i == "TU")}
          />
          Tuesday
        </label>
        <label>
          <Field
            type="checkbox"
            name="days"
            value="WE"
            checked={values?.days?.some((i) => i == "WE")}
          />
          Wendnesday
        </label>
        <label>
          <Field
            type="checkbox"
            name="days"
            value="TH"
            checked={values?.days?.some((i) => i == "TH")}
          />
          Thursday
        </label>
        <label>
          <Field
            type="checkbox"
            name="days"
            value="FR"
            checked={values?.days?.some((i) => i == "FR")}
          />
          Friday
        </label>
        <label>
          <Field
            type="checkbox"
            name="days"
            value="SA"
            checked={values?.days?.some((i) => i == "SA")}
          />
          Saturday
        </label>
      </div>
    </section>
  );
};

export default WeekCheckboxGroup;
