import React from "react";
import { css } from "@emotion/react";
import TransmitAudio from "views/intercoms/transmit-audio";
import { Image, ArrowsFullscreen } from "react-bootstrap-icons";
import { Button, AuxiliaryButton, IconButton } from "./buttons";
/** @jsxImportSource @emotion/react */
import { useSnapMutate } from "views/cameras/actions";

const styles = css`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0.3rem;

  justify-content: center;

  svg {
    width: 20px;
    height: 20px;
    color: var(--natural-02);
  }

  button {
    color: var(--camera-actionpanel-bottons-background);
    &:hover {
      background: none !important;
      color: white !important;
    }
  }
  .transmit-audio {
    margin-left: auto;
  }
`;

const CameraActionsPanel = ({ camera, videoRef }) => {
  const mutatationSnap = useSnapMutate();

  const handleSnap = () =>
    mutatationSnap.mutate(camera?.id, {
      onSuccess: (data) => {
        var link = document.createElement("a");

        // Browsers that support HTML5 download attribute

        link.setAttribute("href", data.data.link);
        link.setAttribute("download", "snapdhot.jpeg");
        link.style = "visibility:hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
    });
  const handleFullScreen = () => {
    if (!document.fullscreenElement) {
      videoRef.current.requestFullscreen();
    }
  };

  return (
    <article css={styles}>
      <IconButton onClick={handleSnap} title="Snap Picture">
        <Image />
      </IconButton>

      {camera?.intercom_id && (
        <TransmitAudio intercom_id={camera.intercom_id} />
      )}
      <IconButton onClick={handleFullScreen} title="Full Screen">
        <ArrowsFullscreen />
      </IconButton>
    </article>
  );
};

export default CameraActionsPanel;
