/** @jsxImportSource @emotion/react */

import React from "react";
import { InputLabel, InputHelper, Input } from "components/form";
import { ErrorMessage } from "components/form";
import { css } from "@emotion/react";
import { useFormikContext } from "formik";

/** @jsxImportSource @emotion/react */
const getNameFromLabel = (label) =>
  label ? label.toLowerCase().replace(/\ /g, "_") : "";

const TextField = React.forwardRef((props, ref) => {
  const context = useFormikContext();
  const formikProps = {};
  const { name, icon: Icon } = props;

  const { errors, touched } = context || {};

  if (context) {
    formikProps.onChange = context.handleChange;
    formikProps.onBlur = context.handleBlur;
  }

  ref = ref || React.useRef();

  const tfProps = {
    "data-compact": props["data-compact"],
  };

  return (
    <div className={"field text-area " + props.className} {...tfProps}>
      <InputLabel {...props} />
      <InputHelper {...props} />
      <textarea
        css={css`
          resize: none;

          align-items: start;
          padding-top: 6px;
          overflow-y: auto;
          justify-content: center;
          align-items: center;
          padding: 8px 16px;
          width: 100%;
          height: 150px;

          background: var(--neutral-16);
          border: 1px solid var(--neutral-12);
          box-sizing: border-box;
          border-radius: 5px;
          outline: none;
          cursor: text;
        `}
        placeholder={props.placeholder || ""}
        ref={ref}
        name={name}
        {...props}
        {...formikProps}
      />

      <ErrorMessage name={name} {...props} />
    </div>
  );
});

export default TextField;
