import React from 'react';
import { Section } from './installer-dashboard';
import ActiveCompanyChart from './active-company-chart';
import * as RadioGroup from '@radix-ui/react-radio-group';
import { css } from '@emotion/react';
import { RadioStyles } from './installer-dashboard';
import ChartTypeSelect from 'components/charts/chart-type-select';
import ChartPeriodSelect from 'components/charts/chart-period-select';
import ActiveSiteChart from './active-site-chart';
/** @jsxImportSource @emotion/react **/
const ActiveSite = (props) => {
  const [filter, setFilter] = React.useState('-7 day');
  const [type, setType] = React.useState();
  return (
    <Section id="active-site" {...props}>
      <header>
        <div>
          <h4>Site Activity</h4>
          <h5>Top 5 Active Sites</h5>
        </div>
        {/* <ChartTypeSelect type={type} setType={setType} />
      <ChartPeriodSelect filter={filter} setFilter={setFilter} /> */}
      </header>

      <ActiveSiteChart filter={filter} type={type} />
    </Section>
  );
};

export default ActiveSite;
