import React from 'react';
import {
  pipelines,
  utils,
  components,
  RTSP_METHOD,
  isRtcpBye
} from 'media-stream-library/dist/browser-esm';
import { useEventState } from './event-hook';
import { MEDIA_HOST_URL } from 'api/api-url';

const useRtspPipeline = ({
  recordingTime,
  play,
  videoRef,
  recordingId,
  seek,
  camera,
  rtsp,
  onPlaying,
  onLoading,
  duration = null,
  webSocketURLParameters
}) => {
  const __onPlayingRef = React.useRef(onPlaying);
  __onPlayingRef.current = onPlaying;

  const __onLoadingRef = React.useRef(onLoading);
  __onLoadingRef.current = onLoading;
  const [pipeline, setPipeline] = React.useState();

  const [canplay, unsetCanplay] = useEventState(videoRef, 'canplay');
  const [playing, unsetPlaying] = useEventState(videoRef, 'playing');
  const [ended, unsetEnded] = useEventState(videoRef, 'ended');

  const [error, setError] = React.useState();

  const [fetching, setFetching] = React.useState(false);

  const [videoStats, setVideoStats] = React.useState();

  const whenPipelineReady = React.useCallback(
    (pipeline) => {
      //   pipeline.onSdp = (sdp) => {
      //     const videoMedia = sdp.media.find((m) => {
      //       return m.type === 'video';
      //     });
      //   };

      console.info('Pipeline is Ready', new Date());

      if (setError) setError(false);

      pipeline.rtsp.onEnded = (rtcp) => {};

      pipeline.rtsp.onRtcp = (rtcp) => {
        if (isRtcpBye(rtcp)) {
        }
      };

      pipeline.rtsp.onError = (err) => {
        setFetching(false);
        setError(err);
        console.warn('RTSP on Error', err);
      };

      pipeline.rtsp.incoming.on('end', (err) => {});

      pipeline.rtsp.incoming.on('error', (err) => {
        console.warn('Error from RTSP', err);
      });

      pipeline.rtsp.onPlay = (range) => {
        setFetching(false);
        // TODO might need to set up time here
        pipeline.timeToFirstByte =
          Date.now() - pipeline.connectionInitiated.getTime();
        console.info(
          'started playing feed at: ',
          new Date(),
          'ttfb',
          pipeline.timeToFirstByte
        );
      };
      // pipeline.rtsp.play();
      // pipeline.rtsp.send({ method: RTSP_METHOD.OPTIONS });
      // pipeline.rtsp.send({ method: RTSP_METHOD.DESCRIBE });

      // pipeline.rtsp.send({
      //   method: RTSP_METHOD.PLAY,
      //   headers: {
      //     Session: pipeline.rtsp._sessionId
      //   },
      //   uri: pipeline.rtsp._sessionControlURL
      // });

      let range = '';

      if (pipeline.recording) {
        let t, e;

        if (recordingTime) {
          t = new Date(recordingTime)
            .toISOString()
            .replaceAll('-', '')
            .replaceAll(':', '');

          range = 'clock=' + t + '-';
        }

        if (duration) {
          e = new Date(new Date(recordingTime).getTime() + duration * 1000)
            .toISOString()
            .replaceAll('-', '')
            .replaceAll(':', '');

          range = range + e;
        }
      }

      pipeline.onSdp = (sdp) => {
        const rtp = 1 * 2;
        const rtcp = rtp + 1;

        pipeline.rtsp._enqueue({
          method: RTSP_METHOD.PLAY,
          headers: {
            Session: pipeline.rtsp._sessionId,
            Range: range
          },
          uri: pipeline.rtsp._sessionControlURL
        });
        pipeline.rtsp._state = 'playing';
      };

      if (pipeline.rtsp._state === 'idle') {
        pipeline.rtsp._enqueue({ method: RTSP_METHOD.OPTIONS });
        pipeline.rtsp._enqueue({ method: RTSP_METHOD.DESCRIBE });
        pipeline.rtsp._dequeue();
      } else if (pipeline.rtsp._state === 'paused') {
        if (
          pipeline.rtsp._sessionId === null ||
          pipeline.rtsp._sessionId === undefined
        ) {
          throw new Error('rtsp: internal error');
        }
      }

      //   pipeline.rtsp._state = 'playing';
    },
    [recordingTime, seek]
  );

  React.useEffect(() => {
    if (play && pipeline && !fetching) {
      pipeline.ready.then(whenPipelineReady(pipeline)).catch((err) => {
        setFetching(false);
        setError(err);
      });

      setFetching(true);
    }
    if (!play && pipeline) pipeline.rtsp.stop();
  }, [play, pipeline]);

  React.useEffect(() => {
    const videoEl = videoRef.current;

    if (videoEl === null) {
      return;
    }

    if (
      pipeline &&
      pipeline.rtsp._state == 'playing' &&
      play &&
      canplay === true &&
      playing === false
    ) {
      videoEl.play().catch((err) => {
        console.error('VideoElement error: ', err.message);
      });
    }

    if (pipeline && play && canplay === false && playing === false) {
      if (__onLoadingRef.current !== undefined) {
        __onLoadingRef.current({ pipeline, videoEl: videoRef.current });
      }
    }

    if (!play && playing === true) {
      videoEl && videoEl.pause();
      unsetPlaying();
    } else if (play && playing === true) {
      if (__onPlayingRef.current !== undefined) {
        console.info('started playing video ', new Date());
        __onPlayingRef.current({ pipeline, videoEl: videoRef.current });
      }
    }
  }, [play, canplay, playing, unsetPlaying, pipeline, videoRef]);

  React.useEffect(() => {
    // /if (!play) return;
    if (
      !rtsp ||
      !camera ||
      camera.online == '0' ||
      (typeof rtsp === 'object' && !rtsp?.current)
    )
      return;
    if (!recordingId && seek) {
      setPipeline(null);
      if (setError) setError(false);
      setFetching(false);
      unsetCanplay();
      unsetPlaying();
      unsetEnded();
      return;
    }

    let qString = webSocketURLParameters ?? '';
    const wsUri = `${MEDIA_HOST_URL}/camera/${
      camera.id
    }/rtsp${qString}?timestamp=${Date.now()}`;

    const videoEl = videoRef.current;
    setError && setError(false);

    let Pipeline = new pipelines.Html5VideoPipeline({
      ws: { uri: wsUri, timeout: 10000 },
      //uri: `ws://localuser:localuser@192.168.1.151/rtsp-over-websocket`,
      rtsp: {
        uri: rtsp?.current ?? rtsp
      },
      auth: { username: 'localuser', password: 'localuser' },
      mediaElement: videoEl
    });

    Pipeline.connectionInitiated = new Date();

    Pipeline.onServerClose = () => {
      console.debug('server closed');
      setError(true);
      setFetching(false);
    };
    //   if (autoRetry) {
    //     utils.addRTSPRetry(newPipeline.rtsp);
    //   }

    // pipeline.rtsp.onRtcp = (rtcp) => {
    //   if (isRtcpBye(rtcp)) {
    //     setTimeout(() => play(host, encoding), 0);
    //   }
    // };

    const scheduler = new utils.Scheduler(Pipeline, (e) => {
      let motionData = e.data.buffer;
    });

    const runScheduler = components.Tube.fromHandlers((msg) =>
      scheduler.run(msg)
    );
    Pipeline.insertBefore(Pipeline.lastComponent, runScheduler);

    Pipeline.onSync = (ntpPresentationTime) => {
      scheduler.init(ntpPresentationTime);
    };

    if (recordingId) {
      Pipeline.recording = true;
    }

    setPipeline(Pipeline);

    return () => {
      try {
        Pipeline.close();
        if (videoEl instanceof HTMLElement && videoEl.src) videoEl.src = '';
        //scheduler?.reset();
        setPipeline(null);
        setFetching(false);
        unsetCanplay();
        unsetPlaying();
        unsetEnded();
      } catch {}
    };
  }, [rtsp, rtsp.current, camera]);

  return {
    pipeline,
    canplay,
    playing,
    ended,
    error,
    fetching,
    videoStats,
    setFetching
  };
};

export default useRtspPipeline;
