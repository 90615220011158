import React from 'react';

import { css } from '@emotion/react';
import { useQueryWrapper } from 'utils/ajax';
/** @jsxImportSource @emotion/react */
import CameraAction from 'api/camera_action';
import Camera from 'api/camera';
import { ArrowClockwise, Pause, PlayFill } from 'react-bootstrap-icons';
import Player from './player';

import Spinner from 'components/spinner';

function isHTMLMediaElement(el) {
  return el?.buffered !== undefined;
}

const FeedModal = ({ event, starttime, stoptime, autoPlay = true }) => {
  const recordingTime = new Date(
    new Date(event.utctime).setSeconds(new Date(event.utctime).getSeconds() - 5)
  ).getTime();
  const videoRef = React.useRef();
  const { camera_id } = event;

  const query = useQueryWrapper(
    ['camera', 'get', camera_id, event.utctime],
    () => Camera.search({ id: camera_id })
  );

  let camera = query.data?.data?.[0];

  const { data: recordings, isLoading } = useQueryWrapper(
    ['recording', camera_id, event.utctime],
    () =>
      CameraAction.listEdgeRecordings({
        id: camera_id,
        starttime: new Date(
          new Date(event.utctime).setSeconds(
            new Date(event.utctime).getSeconds() - 10
          )
        ).toISOString(),
        stoptime: new Date(
          new Date(event.utctime).setSeconds(
            new Date(event.utctime).getSeconds() + 10
          )
        ).toISOString()
      }),
    {
      enabled: camera?.online == 1
    }
  );

  let recording = recordings?.data[0];

  const [refresh, setRefresh] = React.useState(0);

  const [playing, setPlaying] = React.useState(true);

  const [progress, setProgress] = React.useState(0);
  const [play, setPlay] = React.useState(autoPlay);

  const videoHandler = (control) => {
    if (!videoRef.current) return;
    if (control === 'play') {
      videoRef.current.play();
      setPlaying(true);
    } else if (control === 'pause') {
      videoRef.current.pause();
      setPlaying(false);
    }
  };

  const onPlaying = React.useCallback((VideoProperties) => {
    const { videoEl: el, pipeline } = VideoProperties;

    videoRef.current = el;

    const onEnded = () => {};

    const updateProgress = () => {
      const played = recordingTime + pipeline.currentTime;
      // const buffered =
      //   isHTMLMediaElement(el) && el.buffered.length > 0
      //     ? recordingTime + el.buffered.end(el.buffered.length - 1)
      //     : played;
      // const total = 20;
      // const counter = `${new Date(played * 1000)
      //   .toISOString()
      //   .slice(11, 19)} / ${new Date(total * 1000)
      //   .toISOString()
      //   .slice(11, 19)}`;
      setProgress({
        currentTime: played,
        width: (el.currentTime / 20) * 100
      });
    };
    updateProgress();

    if (isHTMLMediaElement(el)) {
      el.addEventListener('timeupdate', updateProgress);
      el.addEventListener('ended', updateProgress);

      return () => {
        el.removeEventListener('timeupdate', updateProgress);
        el.removeEventListener('ended', updateProgress);
      };
    }
  }, []);

  if (isLoading) return <Spinner />;

  if (camera && camera?.online == 0)
    return (
      <span
        css={css`
          padding: 1rem;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          color: var(--secondary-font-color);
        `}
      >
        camera is currently offline
      </span>
    );

  if (recording)
    return (
      <article
        className="feed"
        css={css`
          padding: 1rem;
          position: relative;
          height: 100%;
          width: 100%;
          display: grid;
          grid-template-rows: 1fr auto;

          /* Controls */
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          {camera && recording && (
            <Player
              currentTime={recordingTime}
              currentRecording={recording?.recordingid}
              host={camera}
              duration={20}
              play={true}
              setPlay={setPlay}
              setRefresh={setRefresh}
              refresh={refresh}
              autoPlay={true}
              refreshOnVisibility={false}
              onPlaying={onPlaying}
              waiting={isLoading || query?.isLoading}
              vapixParameters={{ recordingid: recording?.recordingid }}
            />
          )}

          <div
            css={css`
              width: 100%;
              background: gray;
            `}
          >
            <div
              css={css`
                background: var(--primary-base);
                width: 100%;
                height: 8px;
              `}
              style={{
                width: `${progress ? Math.min(progress.width, 100) : 0}%`
              }}
            ></div>
          </div>
        </div>
        <section
          css={css`
            position: relative;
            width: 100%;
            padding: 1rem;
            aliogn-items: center;
            background: black;
            display: flex;
            gap: 1rem;
            color: white;
          `}
        >
          <button
            type="button"
            onClick={() => {
              setPlaying(true);
              setRefresh((prev) => prev + 1);
            }}
          >
            <ArrowClockwise size={24} />
          </button>

          {playing ? (
            <button onClick={() => videoHandler('pause')}>
              <Pause size={24} />
            </button>
          ) : (
            <button onClick={() => videoHandler('play')}>
              <PlayFill size={24} />
            </button>
          )}
        </section>
      </article>
    );
  else
    return (
      <span
        css={css`
          padding: 1rem;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          color: var(--secondary-font-color);
        `}
      >
        no recordings found
      </span>
    );
};

export default FeedModal;
