import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import { Field, useFormikContext } from 'formik';
import SiteActionSelect from 'components/site-action-select';
import AsyncSelect from 'components/headless-select/async';
import Site from 'api/site';
import Select from 'components/headless-select';

const styles = css`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const SITE_ACTIONS = [
  { value: 'lockDownRelease', name: 'Release', id: 'lockDownRelease' },
  { value: 'lockDown', name: 'Lock Down', id: 'lockDown' }
];

const SiteAction = ({ action }) => {
  const { setFieldValue } = useFormikContext();
  return (
    <article css={styles}>
      <Field
        name={`site_id`}
        isMulti={false}
        title="site"
        fetchFn={Site.search}
        component={AsyncSelect}
        closeMenuOnSelect={true}
      />

      <Field
        title="Action"
        name={`site_action`}
        onChange={(e) => setFieldValue('site_action', e?.value)}
        options={SITE_ACTIONS}
        defaultValue={SITE_ACTIONS.find((i) => i.value == action?.site_action)}
        component={Select}
      />
    </article>
  );
};

export default SiteAction;
