import React from "react";
import CreatableSelect from "react-select/creatable";
import { css } from "@emotion/react";
/** @jsxImportSource @emotion/react */
import { Field, useFormikContext } from "formik";
import AsyncSelect from "components/headless-select/async";
import Camera from "api/camera";
import Select from "components/headless-select";
import SMSCreatable from "components/form/sms-creatable";
import EmailCreatable from "components/form/email-creatable";
import { NumberField } from "components/form";

const styles = css`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .creatable__control {
    height: 80px;
  }
`;

export const SITE_ACTIONS = [
  { value: "release", name: "Release", id: "release" },
  { value: "lockDown", name: "Lock Down", id: "lockDown" },
];

const CameraAction = ({ action }) => {
  const { setFieldValue } = useFormikContext();
  return (
    <article css={styles}>
      <Field
        name={`camera_id`}
        isMulti={false}
        title="camera"
        fetchFn={Camera.search}
        component={AsyncSelect}
        closeMenuOnSelect={true}
      />

      <Field
        title="SMS"
        name={`camera_sms`}
        onChange={(e) => setFieldValue("camera_sms", e?.value)}
        defaultValue={SITE_ACTIONS.find((i) => i.value == action?.camera_sms)}
        component={SMSCreatable}
      />
      <Field
        title="EMAIL"
        name={`camera_email`}
        onChange={(e) => setFieldValue("camera_email", e?.value)}
        defaultValue={SITE_ACTIONS.find((i) => i.value == action?.camera_sms)}
        component={EmailCreatable}
      />
      <Field
        label="Delay in seconds before snap"
        css={css`
          width: 50%;
        `}
        min="0"
        max="30"
        name={`camera_delay`}
        as={NumberField}
      />
    </article>
  );
};

export default CameraAction;
