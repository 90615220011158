import { dataFetchQuery } from 'utils/ajax';
import { API_HOST_URL } from './api-url';
const API_URL = API_HOST_URL + '/access_level_api';

import { callAPI } from 'utils/ajax';

const AccessLevel = {

  get(id) {
    return callAPI('GET', `/access_level/${id}`);
  },

  search(input = {}) {
    return callAPI('GET', `/access_level`, input);
  },

  stats(input = {}) {
    return callAPI('GET', `/access_level/stats`, input);
  },

  // Used in Doors > Access Levels
  // TODO consider switch to using old api Access_Level_Door::stats
  oldStats(input = {}) {
    return dataFetchQuery(`${API_URL}/getStats/`, input);
  },

  lock(id) {
    return dataFetchQuery(`${API_URL}/lock/` + id, {});
  },

  delete(id) {
    return dataFetchQuery(`${API_URL}/delete/` + id, {});
  },

  add(input = null) {
    return dataFetchQuery(`${API_URL}/add`, input);
  },

  edit(input = null, id = '') {
    return dataFetchQuery(`${API_URL}/edit/` + id, input);
  },

  saveDoors(input = null, id = '') {
    return dataFetchQuery(`${API_URL}/saveDoors/` + id, input);
  },

  getTreeMap(input = {}) {
    return dataFetchQuery(`${API_URL}/getTreeMap`, input);
  },

  getControllerData(controllerId, accessLevelId) {
    return dataFetchQuery(
      `${API_URL}/getControllerData/${controllerId}/${accessLevelId}`
    );
  }
};

export default AccessLevel;
