import React from "react";
import { dateTime, dateTimeDif, dateTimeShort } from "utils/time";
import {
  BellFill,
  BellSlashFill,
  ExclamationCircleFill,
  CircleFill,
  ThreeDotsVertical,
} from "react-bootstrap-icons";
import { css } from "@emotion/react";
/** @jsxImportSource @emotion/react */
import { IconButton } from "components/buttons";
import { Badge } from "components/badge";
import { useModal } from "providers/modal-provider";
import SyncCheckModal from "./sync-check-modal";
import { DialogDisclosure } from "reakit/Dialog";
const styles = css`
  position: relative;
  padding: 1rem;
  display: grid;
  align-items: center;
  grid-column-gap: 1rem;
  grid-template-columns: 3fr 1fr 1fr;
  justify-content: center;
  box-sizing: border-box;
  border-bottom: 1px solid var(--neutral-12);
  &:hover {
    background: var(--neutral-07);
    transition: all 0.5 ease;
  }

  .companyName {
    color: var(--neutral-01);
  }

  label {
    font-size: 14px;
    font-weight: 700;
  }

  time {
    font-size: 12px;
    font-weight: 500;
    color: var(--secondary-font-color);
    grid-column: 1;
  }
  button {
    grid-column: 3/3;
    grid-row: 1 / 3;
    place-self: end;
  }

  span[role="badge"] {
    grid-column: 2/2;
    grid-row: 1 / 3;
  }

  a {
    height: 40px;
    display: inline-flex;
    color: var(--primary-base);
    border-radius: 5px;
    background: var(--primary-v-02);
    font-size: 14px;
    font-weight: 700;
    margin: 24px;
    box-sizing: border-box;
    align-items: center;
    padding: 0 16px;
  }
`;
const SyncCheckItem = ({ event, ...props }) => {
  const { dialog, setModalState } = useModal();

  return (
    <div css={styles}>
      <label>Sync Check Done</label>
      <div className="companyName">{event.company_name}</div>
      <time>
        {dateTimeDif(
          new Date(Number(event.createdAt.$date.$numberLong)),
          "auto",
          false
        )}
      </time>

      <Badge color={event.status ? "success" : "danger"}>
        {event.status ? "OK" : "Error"}
      </Badge>

      <DialogDisclosure
        {...dialog}
        as={IconButton}
        onClick={() =>
          setModalState({ component: SyncCheckModal, data: event })
        }
      >
        <ThreeDotsVertical />
      </DialogDisclosure>
    </div>
  );
};

export default SyncCheckItem;
