import React from 'react';
import Site from 'api/site';
import {
  ChevronDown,
  CircleFill,
  DoorClosed,
  DoorOpen,
  Search
} from 'react-bootstrap-icons';
import AsyncSelect from 'components/headless-select/async';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useQueryWrapper } from 'utils/ajax';
/** @jsxImportSource @emotion/react */
import SelectWrapper from 'components/headless-select';
import { IconButton } from 'components/buttons';
import PopoverContent, {
  Popover,
  PopoverAnchor,
  PopoverPortal,
  PopoverTrigger
} from 'components/popover/popover-content';

const Menu = (props) => {
  const shadow = 'hsla(218, 50%, 10%, 0.1)';
  return (
    <div
      css={{
        backgroundColor: 'white',
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        position: 'absolute',
        left: 0,
        zIndex: 2
      }}
      {...props}
    />
  );
};

export default ({ doors, editLayerRef: ref, mapRef }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [value, setValue] = React.useState(null);
  function getDoorIcon({ name }) {
    return L.divIcon({
      iconSize: [24, 24],
      iconAnchor: [5, 5],
      className: `pin-square`,
      html: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-lock" viewBox="0 0 16 16">
	  <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2zM5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1z"/></svg>`
    });
  }
  const selectStyles = {
    control: (provided) => ({
      ...provided,

      width: 'auto',
      margin: 8,
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
    }),
    menu: () => ({
      border: 'none !important'
    }),
    menuList: (base) => ({
      ...base,
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 0px inset'
    })
  };
  return (
    <Popover>
      <PopoverTrigger>
        <IconButton
          title=" Add A Door Marker "
          onClick={() => setIsOpen((prev) => !prev)}
        >
          <DoorOpen />
        </IconButton>
      </PopoverTrigger>

      <PopoverContent
        align="start"
        css={css`
          width: 250px;
        `}
      >
        <SelectWrapper
          idx="value"
          label="name"
          styles={selectStyles}
          noValueContainer
          isClearable={false}
          placeholder="Search doors..."
          name="doors"
          checkboxes={false}
          data-compact
          icon={Search}
          itemComponents={{ DropdownIndicator: null }}
          menuIsOpen={true}
          //onChange={(e) => setGlobalAccessLevel(e)}
          options={
            doors?.map((d) => ({
              value: d,
              name: d.name
            })) || []
          }
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          closeMenuOnSelect={true}
          value={value}
          onChange={(e) => {
            let { id, type, name } = e.value;

            let allLayers = ref.current.getLayers();

            let layerExists = allLayers
              ?.filter((l) => l instanceof L.Marker)
              .find(
                (l) =>
                  (l?.properties?.id == id && l?.properties?.type == 'door') ||
                  (l?.options?.properties?.id == id &&
                    l?.options?.properties?.type == 'door')
              );

            if (layerExists) {
              layerExists.setIcon(
                getDoorIcon({ name })
                //L.icon({
                //iconUrl: `https://chart.googleapis.com/chart?chst=d_bubble_text_small&chld=bb|${name}|FFFF00|000000`
                //})
              );
            }
            if (!layerExists) {
              let marker = new L.Marker(mapRef.current.getCenter(), {
                pmIgnore: false,
                icon: getDoorIcon({ name })
              }).bindTooltip(name, {
                permanent: true,
                direction: 'right'
              });
              marker.properties = { ...e.value, hidden: null, type: 'door' };
              marker.addTo(ref.current);
              // TOTO why cant i just ad marker ?
              // TODO check if there is another way to put properties
              {
                /* mapRef.current.pm.enableDraw('Marker', {
              markerEditable: true,
              markerStyle: {
                icon: getDoorIcon({ name }),
                properties: { ...e.value.properties, hidden: null }
              },
              tooltips: false,
              continueDrawing: false
            }); */
              }
              marker.pm.enableLayerDrag();
            }

            setValue(e);
          }}
        />
      </PopoverContent>
    </Popover>
  );
};
