import React from "react";
import "css/usage-chart.css";
import ReactECharts from "echarts-for-react";
import * as echarts from "echarts";

import { css } from "@emotion/react";
/** @jsxImportSource @emotion/react */
import subDays from "date-fns/subDays";

const HeatmapUsageChart = ({
  results = [],
  names = [],
  chartData = [],
  config = {},
  height,
  name,
  ...otherProps
}) => {
  const CHART_OPTIONS = {
    tooltip: {
      appendToBody: true,
      className: "heatmap-usage-tooltip",

      formatter: (params) =>
        `<div><time>${params.value?.[0]}</time><br><span><strong>${params.value?.[1]}</strong> Entrance(s)</span></div>`,
      position: "top",
      backgroundColor: "var(--neutral-09)",
      textStyle: {
        color: "var(--neutral-00)",
      },
    },

    visualMap: {
      label: {
        formatter: function (s) {
          return "ss";
        },
      },
      type: "piecewise",
      text: ["Daily Entrances", ""],
      showLabel: true,
      // pieces: [
      //   // Range of a piece can be specified by property min and max,
      //   // where min will be set as -Infinity if ignored,
      //   // and max will be set as Infinity if ignored.
      //   { min: 1500 },
      //   { min: 900, max: 1500 },
      //   { min: 310, max: 1000 },
      //   { min: 200, max: 300 },
      //   // Label of the piece can be specified.
      //   { min: 10, max: 200, label: "10 to 200 (custom label) " },
      //   // Color of the piece can be specified.
      //   { value: 123, label: "123 (custom special color) ", color: "grey" },
      //   { max: 5 },
      // ],
      splitNumber: 4,
      precision: 0,

      min: 0,
      max:
        //round to the nearest 100
        chartData?.length > 0
          ? Math.ceil(Math.max(...chartData?.map((i) => i[1])) / 10) * 10
          : 100,

      orient: "horizontal",

      inRange: {
        color: ["#b0e2d0", "#00a96c"], //From smaller to bigger value ->
      },
      left: "center",
      top: 0,
    },

    calendar: {
      top: 60,
      left: 60,
      right: 60,
      bottom: 30,

      splitLine: { lineStyle: { color: "#ccc" } },
      orient: "horizontal",

      // /cellSize: ["auto", 20],
      range: [subDays(new Date(), 180), new Date()],
      itemStyle: {
        color: "var(--neutral-09)",
        borderWidth: 0.2,
      },

      yearLabel: { show: false },
    },

    series: [
      {
        type: "heatmap",
        //smooth: true,
        coordinateSystem: "calendar",

        data: chartData || [],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },

        ...config?.series,
      },
    ],
  };

  return (
    <>
      <ReactECharts
        css={css`
          .heatmap-usage-tooltip {
            > div {
              display: flex;
              flex-direction: column;
              gap: 0.2rem;
              font-size: 12px;
              time {
                font-weight: bold;
              }
            }
          }
        `}
        opts={{ renderer: "svg" }}
        option={CHART_OPTIONS}
        style={{ padding: "0px", height: "100%" }}
      />
    </>
  );
};

export default HeatmapUsageChart;
