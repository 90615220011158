import React from 'react';
import {
  useDialogState,
  Dialog as BaseDialog,
  DialogDisclosure,
  DialogBackdrop
} from 'reakit/Dialog';

import { css } from '@emotion/react';
import { useModal } from 'providers/modal-provider';
/** @jsxImportSource @emotion/react */

const backdropStyles = css`
  overflow: hidden;
  padding: 10rem;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  inset: 0px;
  z-index: 2000;
  opacity: 0;
  display: flex;

  transform: translate3d(0, -100%, 0);
  &[data-enter] {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
`;

const dialogStyles = css`
  transition: opacity 200ms ease-in-out, transform 200ms ease-in-out;

  opacity: 0;

  // top: auto;
  position: relative;
  inset: 0;
  height: auto;

  margin: auto;

  background: var(--neutral-09);
  z-index: 1000;
  transition: opacity 400ms ease-in-out, transform 250ms ease-in-out;
  transform: translate3d(0, -50%, 0);

  min-width: 20vw;
  &[data-enter] {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  footer {
    margin-top: auto;
    display: flex;
    gap: 16px;
    margin-top: 16px;
  }
`;

const Dialog = ({ disclosure, ...props }) => {
  const { dialog } = useModal();
  return (
    <>
      {disclosure && (
        <DialogDisclosure
          {...dialog}
          ref={disclosure.ref}
          {...disclosure.props}
        >
          {(disclosureProps) => React.cloneElement(disclosure, disclosureProps)}
        </DialogDisclosure>
      )}
      <DialogBackdrop {...dialog} css={backdropStyles}>
        <BaseDialog
          {...dialog}
          aria-label="modal"
          css={dialogStyles}
          {...props}
        />
      </DialogBackdrop>
    </>
  );
};

export default Dialog;
