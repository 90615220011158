import React from "react";
import { getIn, useFormikContext } from "formik";
import { css } from "@emotion/react";
/** @jsxImportSource @emotion/react */
const styles = css`
  font-size: 14px;
  line-height: 19px;
  margin-top: 5px;
  font-weight: 500;
  color: #ff2f2f;
  //margin-bottom: -24px;
  max-width: 400px; ;
`;

const ErrorMessage = ({ name, ...props }) => {
  const context = useFormikContext();
  const { disabled } = props;
  if (!context) return null;

  const { errors, touched } = context;

  if (disabled) return null;

  if (!name) return null;

  return (typeof errors[name] === "string" && errors[name] && touched[name]) ||
    (getIn(errors, name) && getIn(touched, name)) ? (
    <div className="error-message" css={styles}>
      {errors?.[name] || getIn(errors, name)}
    </div>
  ) : null;
};

export default ErrorMessage;
