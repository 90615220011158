import React from 'react';
import styled from '@emotion/styled';
import SelectWrapper from 'components/headless-select';
import PopoverContent, {
  Popover,
  PopoverAnchor,
  PopoverPortal,
  PopoverTrigger
} from 'components/popover/popover-content';
import { css } from '@emotion/react';
import { ChevronRight, ChevronLeft, Pencil } from 'react-bootstrap-icons';
/** @jsxImportSource @emotion/react */
import { components } from 'react-select';
import SiteItem from 'components/site-focus/site-item';
import { IconButton } from 'components/buttons';
import { useHotkeys } from 'react-hotkeys-hook';
import { IconChevronDown } from '@tabler/icons-react';

const Navigator = styled.article`
  background: var(--neutral-09);
  border-bottom: 1px solid var(--neutral-12);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    svg {
      width: 20px;
      height: 20px;
    }
  }
`;
const Option = ({ children, ...props }) => {
  return (
    <components.Option
      {...props}
      css={css`
        padding: 0px;
      `}
    >
      <SiteItem site={props.data} />
    </components.Option>
  );
};
const MenuList = (props) => {
  return (
    <components.MenuList {...props}>
      {props.children.length > 0 && props.children.slice(0, 3)}
    </components.MenuList>
  );
};

const SiteNavigation = ({
  sites,
  selectedSite,
  setSelectedSite,
  setIsEdit,
  isEdit
}) => {
  const handleBack = () => {
    if (!sites || sites?.length == 0) return;

    if (!selectedSite) {
      setSelectedSite(sites[sites.length - 1]);
    } else {
      let index = sites.findIndex((s) => s.id == selectedSite.id);

      if (index == 0) {
        setSelectedSite(sites[sites.length - 1]);
      } else setSelectedSite(sites[index - 1]);
    }
  };
  const handleForward = () => {
    if (!sites || sites?.length == 0) return;
    if (!selectedSite) setSelectedSite(sites[0]);
    else {
      let index = sites.findIndex((s) => s.id == selectedSite.id);

      if (index == sites.length - 1) {
        setSelectedSite(sites[0]);
      } else setSelectedSite(sites[index + 1]);
    }
  };

  useHotkeys(
    'w',
    () => {
      handleForward();
    },
    { enabled: !isEdit },
    [selectedSite, sites]
  );

  useHotkeys(
    'q',
    () => {
      handleBack();
    },
    { enabled: !isEdit },
    [selectedSite, sites]
  );

  useHotkeys(
    's',
    () => {
      setSelectedSite(null);
    },
    { enabled: !isEdit },
    [selectedSite, sites]
  );

  return (
    <Navigator>
      <div
        css={css`
        pointer-events: ${isEdit ? 'none' : 'auto'};
        opacity: ${isEdit ? '50%' : '100%'};
          height: 20px;
          display: flex;
          align-items: center;
          gap: 2rem;
          height: 100%;
          >
        `}
      >
        <IconButton onClick={handleBack} disabled={sites?.length < 2}>
          <ChevronLeft />
          <kbd>q</kbd>
        </IconButton>
        <Popover>
          <PopoverTrigger
            css={css`
              height: 100%;
              width: 250px;
              max-width: 250px;
              justify-content: center;
              display: flex;
              gap: 5px;
              align-items: center;

              svg {
                transition: all 0.2s;

                color: var(--neutral-03);
              }
              &[aria-expanded='true'] {
                svg:first-of-type {
                  transform: rotate(180deg);
                }
              }
              span {
                font-weight: 500;
                font-size: 16px;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: var(--neutral-00);
                overflow: hidden;
              }
            `}
          >
            <span title={selectedSite && selectedSite?.name}>
              {selectedSite ? selectedSite?.name : 'All Sites'}
            </span>
            <IconChevronDown />
          </PopoverTrigger>
          <PopoverPortal>
            <PopoverContent sideOffset={0} align="center" asChild>
              <SelectWrapper
                itemComponents={{ DropdownIndicator: null, MenuList, Option }}
                css={css`
                  .select__control {
                    margin: 1rem 2.25rem !important;
                    width: auto !important;
                  }
                  .select__menu {
                    //box-shadow: none;
                    border: none;
                    position: relative;
                    box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.1);
                  }
                  .select__menu-list {
                    overflow-y: auto;
                    overflow-x: hidden;
                  }
                  input {
                    opacity: 1 !important ;
                  }
                `}
                isClearable={false}
                data-compact
                data-inline
                closeMenuOnSelect={true}
                controlShouldRenderValue={false}
                menuIsOpen="true"
                options={sites}
                onChange={(e) => e && setSelectedSite(e)}
              />
            </PopoverContent>
          </PopoverPortal>
        </Popover>
        <IconButton onClick={handleForward} disabled={sites?.length < 2}>
          <kbd>w</kbd>
          <ChevronRight />
        </IconButton>
        {selectedSite && (
          <IconButton onClick={() => setSelectedSite(null)}>
            All <kbd>s</kbd>
          </IconButton>
        )}
      </div>
    </Navigator>
  );
};

export default SiteNavigation;
