import React from 'react';
import styled from '@emotion/styled';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { CaretDownFill, CaretUpFill, CaretDown } from 'react-bootstrap-icons';

export const Table = styled('table')`
  table-layout: fixed;
`;

export const Th = styled('th')`
  position: relative;
  font-size: 14px;
  line-height: 16px;
  padding: 14px 0px;
  text-align: left;

  z-index: 100;
  box-sizing: border-box;
  height: 48px;
  color: var(--table-header-color);
  background: var(--neutral-09);
  cursor: pointer;
  border-top: 1px solid var(--neutral-12);
  border-bottom: 1px solid var(--neutral-12);

  //box-shadow: inset 0 -1px 0 var(--neutral-12), inset 0 -1px 0 var(--neutral-12);

  transition: all 0.4 ease;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  > div {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  td > div > span {
    display: inline-block;
    margin: 0;
  }

  &:hover {
    color: var(--table-header-hover-color);
  }
  &[data-sortable='false'] {
    cursor: default;
  }

  &[data-rownumber='false']&[data-selection='false']&:before {
    content: '';
    position: absolute;
    right: 0;
    bottom: 25%;
    height: 50%;
    border-left: 1px solid var(--neutral-12);
    box-sizing: border-box;
  }
`;

export const Tr = styled('tr')`
  cursor: ${(props) => (props.selectable ? 'pointer' : 'auto')};
  color: var(--neutral-00);
  background: var(--neutral-09);
  &[data-selected='true'] {
    background: var(--row-selected-color);
  }

  &[data-synced='false'] {
    background: var(--orange-v-01) !important;
  }
  &[data-failure='true'] {
    background: var(--neutral-06);
  }
`;

export const Thead = styled('thead')`
  position: sticky;
  top: 0;
  color: var(--neutral-00);
  background: var(--neutral-09);
`;

export const Td = styled('td')`
  position: relative;
  width: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  color: var(--neutral-00);
  background: inherit;
  border-bottom: 1px solid var(--neutral-12);

  &:last-of-type {
    border-right: 0;
  }
  &:first-of-type {
    &[data-selected='true']::before {
      content: '';
      position: absolute;
      top: -1px;
      left: 0;
      height: calc(100% + 2px);
      width: 4px;
      background: var(--primary-base);
      // border-left: 4px solid var(--primary-base);
    }
  }
`;

export const TableHolder = styled('section')`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: start;

  table {
    border-collapse: separate;
    background: var(--neutral-09);
    border-radius: 5px;
    border-spacing: 0;
    text-align: left;
    position: relative;
    width: 100%;
    border-color: var(--neutral-06);
    &[data-fetchin='true'] {
    }
  }

  thead {
    z-index: 3;
    width: 200px;
    min-height: 48px;
    height: 48px;
    background: var(--neutral-09);
  }

  tbody {
    background: var(--neutral-09);
  }

  tr {
    background: var(--neutral-09);
  }

  th,
  td {
    height: 3.5rem;
    vertical-align: center;
    padding: 0 12px;
    box-sizing: border-box;
    white-space: nowrap;
    color: var(--neutral-15);
    font-weight: 500;
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  td:not([data-selection='true']):not([data-rownumber='true']) span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  th {
    height: 48px;
    font-weight: 500;
    font-size: 12px;
    color: var(--neutral-01);
  }

  /* th:first-child,
  td:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
    background: inherit;
  } */

  /* th:last-child[data-action="true"],
  td:last-child[data-action="true"] {
    position: sticky;
    right: 0;
    z-index: 2;
    background: inherit;
  } */

  table[data-scrollable='true'] th[data-action='true']:last-child,
  table[data-scrollable='true'] td[data-action='true']:last-child {
    // box-shadow: inset -10px 0 8px 0px #00000026;
    /* position: absolute;
    top: 0;
    bottom: -1px;
    left: 0;
    width: 30px;
    transform: translate(-100%);
    transition: box-shadow 0.3s;
    content: ""; */
    border-left: 1px solid var(--neutral-12);
    box-shadow: 0px 5px 20px -5px rgb(0 0 0 / 5%);
  }

  th:first-child:not([data-selection='true']):not([data-rownumber='true']),
  td:first-child:not([data-selection='true']):not([data-rownumber='true']) {
    padding: 0px 20px 0px 40px;
  }
  th:first-child[data-rownumber='true'] + th,
  td:first-child[data-rownumber='true'] + td {
    padding-left: 12px !important;
  }

  [data-selection='true'] {
    max-width: 42px;
    width: 42px;
    min-width: 42px;
    padding-right: 0;
  }

  [data-rownumber='true'] {
    max-width: 28px;
    width: 28px;
    min-width: 28px;
    font-size: 0.65rem;
  }

  /* [data-selection="true"] + td,
  [data-selection="true"] + th,
  th:first-child[data-selection="false"],
  td:first-child[data-selection="false"] {
    border-right: 1px solid var(--neutral-12);
  } */

  /* [data-selection="true"] + td,
  [data-selection="true"] + th {
    position: sticky;
    left: 42px; // change this if the checkbox width changes
    z-index: 2;
    background: inherit;
  } */

  table[data-scrollable='false'] th:last-child,
  table[data-scrollable='false'] td:last-child {
    width: auto !important;
  }
`;

export const SorterIcon = ({ column }) => {
  if (!column.canSort) return null;
  return (
    <span
      css={css`
        position: absolute;
        right: 8px;
        svg {
          font-weight: bold;
          width: 16px;
          height: 16px;
        }
      `}
    >
      {column.isSorted ? (
        column.isSortedDesc ? (
          <CaretDownFill />
        ) : (
          <CaretUpFill />
        )
      ) : (
        <CaretDown />
      )}
    </span>
  );
};
