import React from 'react';
import { IndeterminateCheckBox } from 'components/form';
import { useFormikContext } from 'formik';

import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */

let cf = {
  overview_enabled: 'Overview',
  monitoring: 'Monitoring',
  notifications_enabled: 'Notifications',
  reports_enabled: 'Reports',
  reports_auto: 'Automatic Reports',
  logs_enabled: 'Logs',
  tc_enabled: 'Time Clock',
  intercoms: 'Intercoms',
  cameras_enabled: 'Cameras',
  elevators: 'Elevators',
  badging: 'Badging',
  license_plates: 'License Plates',
  sensors: 'Sensors'
};

const styles = css`
  color: var(--neutral-00);
  display: grid;
  gap: 16px;
  grid-template-columns: var(--form-column-width) var(--form-column-width);
  padding-top: 24px;
  min-width: 650px;

  > label {
    margin-bottom: 8px;
  }
  ul {
    display: grid;
    grid-template-columns: var(--form-column-width) var(--form-column-width);
    gap: 24px;
    width: fit-content;
  }
`;
const isObject = (object) => object != null && typeof object === 'object';

const InstallerFeatures = (props) => {
  const { values, setFieldValue } = useFormikContext();

  const [group, setGroup] = React.useState();

  React.useEffect(() => {
    if (group == 0) setFieldValue('features', {});
    else if (group == 1)
      setFieldValue('features', {
        intercoms: true,
        overview_enabled: true,
        monitoring: true,
        notifications_enabled: true,
        reports_enabled: true,
        reports_auto: true,
        logs_enabled: true,
        tc_enabled: true,
        cameras_enabled: true,
        elevators: true,
        badging: true,
        license_plates: true,
        sensors: true
      });
    else if (group == -1) return;
  }, [group]);

  React.useEffect(() => {
    if (
      Object.values(values?.features).filter((p) => p).length ==
      Object.values(cf).length
    )
      setGroup(1);
    else if (Object.values(values?.features).filter((p) => p).length)
      setGroup(-1);
    else setGroup(0);
  }, [values.features]);

  return (
    <article css={styles}>
      <h4
        css={css`
          grid-column: 1/-1;
          color: var(--neutral-00);
        `}
      >
        Features
      </h4>
      <IndeterminateCheckBox
        label="All"
        value={group}
        onChange={(e) => setGroup(e.target.checked ? 1 : 0)}
      />
      <br />

      {Object.entries(cf).map((i) => {
        return (
          <IndeterminateCheckBox
            name={i[0]}
            label={i[1]}
            value={values?.features?.[i[0]] ? 1 : 0}
            onChange={(e) =>
              setFieldValue('features', {
                ...values?.features,
                [i[0]]: e.target.checked ? true : false
              })
            }
          />
        );
      })}
      <br />
    </article>
  );
};

export default InstallerFeatures;
