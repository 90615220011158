import React from "react";
import { Tab } from "@headlessui/react";
import { css } from "@emotion/react";
/** @jsxImportSource @emotion/react */

const styles = css`
  display: flex;
  flex-direction: row;
  gap: 48px;
  align-items: center;
  padding: 0px;
  min-height: 72px;
  height: 72px;
  background: var(--neutral-09);
  box-shadow: inset 0px -1px 0px var(--neutral-12);
  > button {
    height: 72px;
  }
`;

const TabList = ({ children, ...otherProps }) => {
  return (
    <Tab.List css={styles} {...otherProps}>
      {children}
    </Tab.List>
  );
};

export default TabList;
