import * as AlertDialog from '@radix-ui/react-alert-dialog';
import React from 'react';
import { KeyboardFill } from 'react-bootstrap-icons';

import { XLg } from 'react-bootstrap-icons';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */

export default () => (
  <AlertDialog.Root>
    <AlertDialog.Trigger>
      <KeyboardFill />
    </AlertDialog.Trigger>
    <AlertDialog.Portal>
      <AlertDialog.Overlay
        css={css`
          inset: 0;
          pointer-events: none !important;
          background: black;
          opacity: 0.3;
          position: fixed;
          z-index: 10000;
        `}
      />
      <AlertDialog.Content
        css={css`
          background: white;
          border-radius: 6px;
          box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
            hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
          position: fixed;
          top: 25%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 90vw;
          max-width: 500px;
          max-height: 85vh;
          padding: 25;
          z-index: 100001;

          table {
            width: 100%;
          }

          thead {
            padding: 1rem;
          }
          td,
          th {
            border-bottom: 1px solid var(--neutral-05);
            text-align: left;
            padding: 1rem;
          }
        `}
      >
        <AlertDialog.Title
          css={css`
            margin: 0;
            margin-bottom: 0.5rem;
          `}
        >
          Hotkeys
        </AlertDialog.Title>
        <AlertDialog.Description>
          Keyboard Shortcuts and interactions
        </AlertDialog.Description>
        <AlertDialog.Cancel
          css={css`
            position: fixed;
            right: 0;
            top: 0;
            padding: 1rem;
          `}
        >
          <XLg />
        </AlertDialog.Cancel>
        <table>
          <thead>
            <tr>
              <th>
                <span>Key</span>
              </th>
              <th>
                <span>Description</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <kbd>Ctrl + A</kbd>
              </td>
              <td>
                <span>Toggle information sidebar</span>
              </td>
            </tr>
            <tr>
              <td>
                <kbd>/</kbd>
              </td>
              <td>
                <span>Search</span>
              </td>
            </tr>
            <tr>
              <td>
                <kbd>Ctrl + Q</kbd>
              </td>
              <td>
                <span>Live Events</span>
              </td>
            </tr>
            <tr>
              <td>
                <kbd>Ctrl + Z</kbd>
              </td>
              <td>
                <span>Overview</span>
              </td>
            </tr>
          </tbody>
        </table>
        <AlertDialog.Action />
      </AlertDialog.Content>
    </AlertDialog.Portal>
  </AlertDialog.Root>
);
