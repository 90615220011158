/** @jsxImportSource @emotion/react */
import React from 'react';
import { Menu } from '@headlessui/react';
import { css } from '@emotion/react';
import { ThreeDots } from 'react-bootstrap-icons';
import styled from '@emotion/styled';
import { MenuButton, MenuItems } from '.';

import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

const menuStyles = css`
  background: red;
  display: inline-block;
`;
const MenuWrapper = ({ children, ...otherProps }) => {
  return (
    <DropdownMenu.Root css={menuStyles}>
      <MenuButton {...otherProps} />
      <MenuItems {...otherProps}>{children}</MenuItems>
    </DropdownMenu.Root>
  );
};

export default MenuWrapper;
