import React from 'react';
import { css } from '@emotion/react';
import { ReactComponent as Rex } from 'assets/rex.svg';
/** @jsxImportSource @emotion/react */

import styled from '@emotion/styled';
import {
  AppIndicator,
  Camera,
  CameraVideo,
  CameraVideoFill,
  ClockHistory,
  Snow
} from 'react-bootstrap-icons';
import CameraFeed from 'views/cameras/camera-feed';

export const Indicator = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;

  width: 28px;
  height: 28px;
  svg {
    width: 16px;
    height: 16px;
  }
  /* light/neutrals/200 */

  background: #f3f5f9;
  /* light/neutrals/000 */

  border: 2px solid #ffffff;
  border-radius: 80px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;

  z-index: 2;
`;

export default ({ door }) => {
  return (
    <section
      css={css`
        display: flex;
        align-items: center;
      `}
    >
      {door.isNotification && (
        <Indicator>
          <AppIndicator title="A notification is attached to this door" />
        </Indicator>
      )}
      {door.camera_count > 0 && (
        <Indicator>
          <CameraVideo />
        </Indicator>
      )}
      {door.isRex && (
        <Indicator>
          <Rex width={16} height={16} />
        </Indicator>
      )}
      {door.io_port_id && (
        <Indicator>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-circuit-switch-open"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            fill="none"
            strokeLineCap="round"
            strokeLineJoin="round"
          >
            <title>I/O Door</title>
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M2 12h2"></path>
            <path d="M20 12h2"></path>
            <path d="M6 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
            <path d="M18 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
            <path d="M7.5 10.5l7.5 -5.5"></path>
          </svg>
        </Indicator>
      )}
      {(door.schedule_names || door.io_open_schedule_name) && (
        <Indicator>
          <ClockHistory
            title={door.schedule_names || door.io_open_schedule_name}
          />
        </Indicator>
      )}
      {door.snowday == '1' && (
        <Indicator>
          <Snow />
        </Indicator>
      )}
    </section>
  );
};
