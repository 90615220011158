import styled from '@emotion/styled';
import React from 'react';
import Breadcrumbs from 'components/breadcrumbs.js';
import PageTitle from 'components/page-title';
import TableStats from 'components/headless-table-stats';
import { AddButton, AuxiliaryButton } from 'components/buttons';
import TableFilter from 'components/headless-table-filter';
import TableFilters from 'components/headless-filters';
import LoadMoreButton from 'components/load-more-button';

import TableEmptyHolder from 'components/table-empty-holder';

import Table from 'components/headless-table-infinite';

/** @jsxImportSource @emotion/react **/
import { css } from '@emotion/react';
import { withActionButton } from 'components/with-action-button';
import useKeyboardShortcut from 'hooks/keyboard-shortcut-hook';

import {
  useDisclosureState,
  Disclosure,
  DisclosureContent
} from 'reakit/Disclosure';
import { Filter } from 'react-bootstrap-icons';
import InfiniteTableProvider from 'providers/headless-infinite-table-provider';
import EventsTrendChart from 'components/charts/events-trend-chart';
import IndexEmptyHolder from './index-empty-holder';

const ContentWrapper = styled('div')`
  grid-area: content;
  display: grid;
  grid-template-columns: 1fr auto;

  main {
    > header {
      display: flex;
      align-items: flex-start;
      margin-top: 2rem;
      margin-bottom: 0.5rem;

      gap: 8px;
      .table-filter-container {
        margin-left: auto;
      }
      div.table-search {
        width: 200px;
        padding-bottom: 0;
        transition: width ease 400ms;

        &:focus-within {
          width: 400px;
        }
      }

      > button {
        height: 48px;
      }
    }
    display: flex;
    user-select: none;

    padding: 24px 48px;
    background-color: var(--content-background-color);

    flex-direction: column;

    height: 100%;
    overflow: hidden;

    > .table-holder {
      overflow: hidden;
      height: 100%;
      div.table-body-container {
        height: 100%;
        overflow: auto;
      }
    }
  }
`;

const IndexContent = ({ children, ...props }) => {
  const { title, crumbs } = props;
  const filterRef = React.useRef();

  const Sidebar = props.sidebar;
  const ExtraButtons = props.buttons;
  const ActionButton = withActionButton(props.actionButton);
  const Filters = props.filters;
  const ExtraAction = props.extraAction;

  const [isSidebarOpen, setIsSidebarOpen] = React.useState(props.isSidebarOpen);
  return (
    <ContentWrapper id="content" {...props} title="">
      <InfiniteTableProvider {...props}>
        {({ state, isLoading, rows, data, status, isFetching, tableState }) => {
          return (
            <>
              <main>
                <PageTitle>{props.customTitle ?? title}</PageTitle>
                <Breadcrumbs crumbs={crumbs} />

                <header>
                  {props.addButton && (
                    <AddButton label={props.addButton} to={props.id} />
                  )}
                  {props.globalSearch && <TableFilter {...props} />}

                  {props.extraAction && <ExtraAction />}

                  {props.actionButton && <ActionButton {...props} />}
                </header>

                <TableStats {...props} />
                {props.filters && <Filters {...props} />}
                <TableFilters {...props} />
                {children && children(props)}

                <Table selectable {...props}></Table>
              </main>
              {props.sidebar && (
                <Sidebar
                  setIsSidebarOpen={setIsSidebarOpen}
                  isSidebarOpen={isSidebarOpen}
                />
              )}
            </>
          );
        }}
      </InfiniteTableProvider>
    </ContentWrapper>
  );
};

export default IndexContent;
