import React from "react";

import { useAuth } from "providers/auth";

const IsCompany = ({ children }) => {
  const { user } = useAuth();

  if (user.company_id) return <>{children}</>;
  else return null;
};

export default IsCompany;
