import React from 'react';
import { Portal } from 'reakit/Portal';
import { useHistory, useLocation } from 'react-router';
import { IconButton } from 'components/buttons';
import useSound from 'use-sound';
import incomingCallAudio from 'sounds/call-started.mp3';
import CameraFeed from 'views/cameras/camera-feed';
import { useAccessMutate } from 'views/doors/actions';
import LoadingBar from 'components/loading-bar';
import { Soundwave, VolumeMute, VolumeUp } from 'react-bootstrap-icons';
import TransmitAudio from 'views/intercoms/transmit-audio';
import SidePanel from 'components/side-panel';
import { useDialogState } from 'reakit/Dialog';
import Spinner from 'components/spinner';
import styled from '@emotion/styled';
import { useGlobalState } from './global-state-provider';
import Camera from 'api/camera';

import { SSE_HOST_URL } from 'api/api-url';

import { ReadyState, useEventSource } from 'react-use-websocket';
import { css } from '@emotion/react';
import { useQueryWrapper } from 'utils/ajax';
/** @jsxImportSource @emotion/react */
import Lottie from 'react-lottie';
import cubeLoader from 'assets/cube-loader';
import { useAuth } from './auth';
import { useHotkeys } from 'react-hotkeys-hook';
import useBuildVersion from 'hooks/build-version-hook';
import LivePlayer from 'components/video/live-player';
import IntercomPortal from 'components/intercom-portal';
import { useLocalStorage } from 'hooks/local-storage';
const PageLoader = styled('aside')`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
  width: 100vw;
  height: 100vh;
  opacity: 50%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  font-weight: 700;
  font-size: 16px;

  .animation-wrapper {
    width: 124px;
    height: 124px;
    overflow: hidden;
    border-radius: 100%;
    display: flex;
    flex-direction: column
    align-items: center;
    justify-content: center;

  }
   svg {
    transform: scale(1.2) !important;


  }

`;

const LayoutContext = React.createContext();
const LayoutProvider = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const { user } = useAuth();

  const { upBuildNo, localVersion } = useBuildVersion();

  const { pageLoader, setShowPageLoader } = useGlobalState();
  const [showSidePanel, setShowSidePanel] = React.useState(true);

  const [isPortalOpen, setIsPortalOpen] = React.useState(false);
  const [callEvent, setCallEvent] = React.useState([]);
  const [dnd, setDnd] = useLocalStorage('intercom-do-not-disturbe', true);
  const [sound, setSound] = useLocalStorage('intercom-sound', true);

  const [reloadLiveEvents, setReloadLiveEvents] = React.useState(1);

  const [showMenu, setShowMenu] = React.useState(true);
  const [collapsibleMenuState, setCollapsibleMenuState] =
    React.useState('access');

  const liveEventsDialog = useDialogState({
    animated: true
  });

  useHotkeys('ctrl+q', () => liveEventsDialog.toggle());
  useHotkeys(
    'ctrl+z',
    () =>
      !location.pathname.includes('overview') &&
      user.company_id &&
      history.push('/overview'),
    [location, user]
  );

  const getSocketUrl = React.useCallback(() => {
    return SSE_HOST_URL + '/events?reload=' + reloadLiveEvents;
  }, [reloadLiveEvents]);

  const { readyState } = useEventSource(
    getSocketUrl,
    {
      share: true,
      filter: (e) => {
        let data = JSON.parse(e.data);
        return ['callStarted', 'callEnded'].includes(data.type);
      },
      shouldReconnect: (e) => true,
      withCredentials: true,
      retryOnError: true
    },

    Boolean(user && !user.missing2fa && !user.missingMfa)
  );

  // let { data } = useQueryWrapper(
  //   ['camera', 'get', callEvent?.camera_id],
  //   () => Camera.search({ id: callEvent?.camera_id }),
  //   { enabled: callEvent?.camera_id ? true : false }
  // );

  return (
    <LayoutContext.Provider
      value={{
        dnd,
        sound,
        setDnd,
        setSound,
        isPortalOpen,
        setIsPortalOpen,
        callEvent,
        setCallEvent,
        upBuildNo,
        localVersion,
        collapsibleMenuState,
        setCollapsibleMenuState,
        setShowPageLoader,
        showSidePanel,
        setShowSidePanel,
        showMenu,
        setShowMenu,
        liveEventsDialog,
        setReloadLiveEvents,
        reloadLiveEvents,
        getSocketUrl,
        readyState,
        toggleSidePanel: () => {
          liveEventsDialog.visible
            ? liveEventsDialog.hide()
            : liveEventsDialog.show();
        }
      }}
    >
      {pageLoader && (
        <Portal>
          <PageLoader>
            <div className="animation-wrapper">
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: cubeLoader,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice'
                  }
                }}
                height={124}
                width={124}
              />
            </div>
            Loading...
          </PageLoader>
        </Portal>
      )}

      {children}
      <IntercomPortal />
    </LayoutContext.Provider>
  );
};

export const useLayout = () => {
  return React.useContext(LayoutContext);
};

export default LayoutProvider;
