import React from 'react';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import { ReactComponent as EmptySvg } from 'assets/images/empty-holder.svg';
import { AuxiliaryButton } from './buttons';
import { useHeadlessTable } from 'providers/headless-table-provider';
import { useAuth } from 'providers/auth';
const styles = css`
  min-height: 250px;
  height: 250px;
  tr {
    text-align: center;
    vertical-align: middle;
    height: 100%;
    align: center;
    padding: 1rem;
  }
  p {
    color: grey;
    font-size: 14px;
  }
  span {
    margin-top: 1rem;
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
    background: var(--neutral-07);
    border-radius: 50%;
    width: 150px !important;
    height: 150px !important;
  }
  h3 {
    margin-top: 2rem;
  }
  td {
    overflow: visible !important;
    padding: 0px !important;
    margin: 0px !important;
  }
`;
const TableEmptyHolder = (props) => {
  const { headerGroups, state, tableState, tableWidth } = useHeadlessTable();
  const Icon = props.emptyIcon || EmptySvg;
  const user = useAuth();

  const filterHeaders = (headers = []) => {
    return headers.filter((h) => {
      if (h.disableManaging && user.company_id) return null;

      if (h.role && !h.role?.includes(user.role)) return null;
      else return h;
    });
  };

  return (
    <tbody css={styles} className="empty-holder">
      <tr>
        <td colSpan={filterHeaders(headerGroups[0]?.headers)?.length || 1}>
          <div
            style={{
              width: tableWidth + 'px',
              position: 'sticky',
              left: 0,
              padding: '12px'
            }}
          >
            <span>
              <Icon size={72} color="#7F8795" stroke="1.5" />
            </span>
            <h3>{props.emptyMessage || 'No records found'}</h3>
            {tableState?.queryFilter?.all && (
              <>
                <p>'{tableState?.queryFilter?.all}'</p>
              </>
            )}
          </div>
        </td>
      </tr>
    </tbody>
  );
};

export default TableEmptyHolder;
