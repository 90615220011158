import React from 'react';
import Template from 'base/template';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import AccessLevel from 'api/access_level';
import { columns } from 'views/access-levels/table-columns';
import IndexContent from 'base/index-content';
import { XLg, ChevronDown } from 'react-bootstrap-icons';
import ActionMenu from 'views/access-levels/action-menu';
import { useAuth } from 'providers/auth';
import Sidebar from 'views/access-levels/sidebar';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { AuxiliaryButton, Button } from 'components/buttons';
import { useQueryWrapper } from 'utils/ajax';
import { MenuItems, MenuItem } from 'components/action-menu';
import { DialogContent, Dialog } from 'components/dialog.js';
import AsyncSelect from 'components/headless-select/async';
import Device from 'api/device';
import ReactJson from 'react-json-view';
const cards = [
  {
    name: 'total',
    label: 'Total'
  },
  {
    name: 'disabled',
    label: 'Disabled',
    color: '#EA3737',
    background: 'var(--red-v-02)',
    filter: { enabled: false },
    icon: XLg
  }
];

const ExtraAction = ({ setDialogOpen }) => {
  const menuStyles = css`
    display: inline-block;
    position: relative;
  `;

  return (
    <section
      css={css`
        display: flex;
        align-items: center;
        gap: 0.2rem;
      `}
    >
      <DropdownMenu.Root as="div" css={menuStyles}>
        <DropdownMenu.Trigger
          asChild={true}
          css={css`
            svg {
              transition: all 0.2s;
            }
            &[data-state='open'] {
              svg {
                transform: rotate(180deg);
              }
            }
          `}
        >
          <Button
            compact
            css={css`
              margin-left: -0.5rem;
              svg {
                width: 16px;
                height: 16px;
              }
            `}
          >
            <ChevronDown />
          </Button>
        </DropdownMenu.Trigger>

        <MenuItems>
          <MenuItem
            onClick={() => setDialogOpen((prev) => !prev)}
            label="Data sync..."
          ></MenuItem>
        </MenuItems>
      </DropdownMenu.Root>
    </section>
  );
};

const AccessLevels = (props) => {
  const { enforce, user } = useAuth();
  const [dialogOpen, setDialogOpen] = React.useState();

  const [al, setAl] = React.useState();
  const [controller, setController] = React.useState();

  const { isFetching, data, refetch, remove } = useQueryWrapper(
    ['match-access-level', controller, al],
    () => AccessLevel.getControllerData(controller, al),
    {
      refetchOnMount: false,
      cacheTime: 0,
      enabled: false
    }
  );

  React.useEffect(() => {
    setController(null);
    setAl(null);
  }, [dialogOpen]);

  return (
    <Template {...props}>
      <IndexContent
        newSorter
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        initialFilter={{ hasSite: user.focused_site }}
        statsQuery={AccessLevel.stats}
        title="Access Levels"
        fields={['counts']}
        id="access-levels"
        cards={cards}
        query={AccessLevel.search}
        columns={columns}
        addButton={
          enforce({ reqCompany: true, restrictIfCompanyUser: ['alEdit'] })
            ? 'Add An Access Level'
            : false
        }
        buttons={
          enforce({ managing: true, role: ['AV'] }) ? ExtraAction : false
        }
        sidebar={Sidebar}
        selectable
        actionButton={ActionMenu}
        {...props}
      >
        <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
          <DialogContent
            css={css`
              overflow: hidden;
              display: flex;
              flex-direction: column;
              height: 100%;
            `}
          >
            <h3
              css={css`
                margin-bottom: 1rem;
              `}
            >
              Match Access Level data
            </h3>
            <section
              css={css`
                display: flex;
                align-items: center;
                gap: 2rem;
              `}
            >
              <AsyncSelect
                data-compact
                name="devices"
                fetchFn={Device.search}
                closeMenuOnSelect={true}
                fetchFilters={{ type: 'controller' }}
                onChange={(e) => setController(e?.controller_id)}
              />
              <AsyncSelect
                data-compact
                closeMenuOnSelect={true}
                name="access-levels"
                fetchFn={AccessLevel.search}
                onChange={(e) => setAl(e?.id)}
              />
              <AuxiliaryButton
                data-loading={isFetching}
                disabled={isFetching || !controller || !al}
                compact
                onClick={() => {
                  remove();
                  refetch();
                }}
              >
                Match
              </AuxiliaryButton>
            </section>
            {/* <div>As Of: {new Date(Number(dataUpdatedAt)).toISOString()}</div> */}
            <section
              css={css`
                overflow: hidden;
                margin-top: 1rem;
                display: flex;
                gap: 2rem;
                > * {
                  flex: 1;
                  overflow: auto;
                }
                .react-json-view {
                  font-family: unset !important;
                  .data-type-label {
                    color: var(--neutral-03);
                  }
                }
              `}
            >
              <ReactJson iconStyle="circle" src={data?.apInAC} name={null} />
              <ReactJson iconStyle="circle" src={data?.apInDB} name={null} />
            </section>
          </DialogContent>
        </Dialog>
      </IndexContent>
    </Template>
  );
};

export default AccessLevels;
