import React from 'react';
import { useMutationWrapper } from 'utils/ajax';
import Door from 'api/door';
import Report from 'api/report';
import GenericDoor from 'api/generic_door';
import { useQueryClient } from 'react-query';
import { useHeadlessTable } from 'providers/headless-table-provider';
import { toast } from 'react-toastify';
import { css } from '@emotion/react';
import { dateTime } from 'utils/time';
/** @jsxImportSource @emotion/react */

export const useAccessMutate = (door) =>
  //thi can be changes generic api now supports openeing controller doors
  useMutationWrapper(
    ['door', door?.id],
    (dId = null) => GenericDoor.access(dId || door.id),
    {
      successMessage: `Door: ${door?.name} was Accessed sucessfully`
    }
  );

export const useLockMutate = (door) =>
  useMutationWrapper(['door', 'lock'], () => GenericDoor.lock(door.id), {
    successMessage: `Door: ${door?.name} was Locked sucessfully`
  });

export const useUnlockMutate = (door) =>
  useMutationWrapper(['door', 'unlock'], () => GenericDoor.unlock(door.id), {
    successMessage: `Door: ${door?.name} was Unlocked sucessfully`
  });

export const useLockDownMutate = (door) =>
  useMutationWrapper(['door', 'unlock'], (token) => Door.lockDown(token), {
    successMessage: `Door: ${door?.name} was Locked Down sucessfully`
  });

export const useReleaseMutate = (door) =>
  useMutationWrapper(
    ['door', 'unlock'],
    (token) => Door.lockDownRelease(token),
    {
      successMessage: `Door: ${door?.name} was Released sucessfully`
    }
  );

export const useDoubleLockMutate = (door) =>
  useMutationWrapper(['door', 'unlock'], (token) => Door.doubleLock(token), {
    successMessage: `Door: ${door?.name} was Double Locked`
  });

export const useGenericDeleteMutate = (door) => {
  const queryClient = useQueryClient();
  const { queryKey } = useHeadlessTable();
  return useMutationWrapper(
    ['door', 'delete'],
    (id) => GenericDoor.delete(id),
    {
      successMessage: `Door: ${door?.name} was Deleted sucessfully`,
      onMutate: (id) => {
        let data = queryClient.getQueryData(queryKey);

        const newData = {
          ...data,
          data: data.data.filter((row) => row.id != id)
        };

        queryClient.setQueryData(queryKey, newData);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['doors', 'stats']);
      }
    }
  );
};

export const useReportMutate = (door) => {
  const queryClient = useQueryClient();
  return useMutationWrapper(['door', 'Report'], (input) => Report.save(input), {
    successMessage: `A report was generated for ${door?.name}`,
    onSuccess: () => queryClient.invalidateQueries(['reports'])
  });
};

export const useGetStateMutate = (door, options = {}) => {
  const toastRef = React.useRef();
  const loading = () =>
    (toastRef.current = toast.info('Fetching door state...', {
      autoClose: false,
      closeOnClick: false,
      closeButton: false,
      pauseOnHover: true,
      draggable: false
    }));

  const success = (data) =>
    toast.update(toastRef.current, {
      className: 'custom-toast',
      autoClose: 4000,
      type: '',
      closeButton: true,
      closeOnClick: true,
      render: (
        <div>
          {data.data.DoorMode || data.data}
          <time
            css={css`
              display: block;
              font-size: 11px;
            `}
          >
            as of {dateTime(new Date())}
          </time>
        </div>
      )
    });

  const error = (data) =>
    toast.update(toastRef.current, {
      type: toast.TYPE.ERROR,
      closeButton: true,
      closeOnClick: true,
      render: <>Error Fetching State</>
    });
  return useMutationWrapper(
    ['door', 'state', door?.id],
    (id) => GenericDoor.getDoorState(id),
    {
      noToast: true,
      onMutate: () => loading(),

      onSuccess: (data) => success(data),
      onError: (data) => error(data),
      ...options
    }
  );
};

export const useAckAlert = (door) => {
  const queryClient = useQueryClient();

  return useMutationWrapper(
    ['door', 'ackAlert', door?.id],
    (input) => GenericDoor.acknowledgeAlert(input),
    {
      successMessage: `Alert acknowledged`,
      onSuccess: () => {
        queryClient.refetchQueries(['doors']);
      }
    }
  );
};
