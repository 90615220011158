import React from 'react';
import { useQueryWrapper } from 'utils/ajax';
import { css } from '@emotion/react';
import EmptyHolder from 'components/empty-holder';
/** @jsxImportSource @emotion/react */
import Spinner from 'components/spinner';
import { getIcon } from 'views/audit-logs/table-columns';
import AuditLog from 'api/audit_log';
const styles = css`
  overflow: auto;
  margin-top: 1rem;
  height: 100%;
  .spinner {
    display: block;
    margin: 24px auto;
  }
  .log-item {
    display: grid;
    grid-template-columns: 32px 1fr 100px;
    align-items: center;
    padding: 8px;
    gap: 16px;
    box-shadow: inset 0px -1px 0px #e3e6ec;

    font-size: 12px;

    time {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-left: auto;
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 100%;
      margin-right: 24px;
      color: var(--neutral-02);
    }
  }
  footer {
    margin-top: 1rem;
    font-size: 12px;
    color: var(--neutral-02);
  }
`;

function CredentialAudit({ title, credential, ...otherProps }) {
  const { data, status } = useQueryWrapper(
    ['credential', credential.token, 'audit'],
    () => AuditLog.search({ credential: credential?.token, limit: 5 })
  );

  return (
    <section className="audit-log-list" css={styles} {...otherProps}>
      {title && <h5>Recent Activity</h5>}
      {status == 'loading' && <Spinner />}
      {data?.data && data?.data.length == 0 && (
        <EmptyHolder label="No recent activity" />
      )}
      {data?.data?.length > 0 &&
        data.data.map((event, idx) => {
          const Icon = getIcon(event);
          return (
            <article className="log-item" key={idx}>
              <Icon size={24} />
              <span>
                {event.message} By {event.user_name}
              </span>
              <time>
                <span>
                  {new Date(event.createdAt).toLocaleString()}
                </span>
              </time>
            </article>
          );
        })}
      {data?.data?.length > 0 && <footer>Showing 5 most recent logs</footer>}
    </section>
  );
}

export default CredentialAudit;
