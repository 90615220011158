import { dataFetchQuery } from 'utils/ajax';
import { callAPI } from 'utils/ajax';
import { API_HOST_URL } from './api-url';
const API_URL = API_HOST_URL + '/device_api';

const Device = {
  get(id) {
    return callAPI('GET', `/device/${id}`);
  },

  search(input = {}) {
    return callAPI('GET', `/device`, input);
  },

  stats(input = {}) {
    return callAPI('GET', `/device/stats`, input);
  },
  getAll(input = {}) {
    return callAPI('GET', `/device/getAll`, input);
  },

  save(input = {}, id = null) {
    return dataFetchQuery(`${API_URL}/save` + (id ? `/${id}` : ''), input);
  },

  delete(id) {
    return dataFetchQuery(`${API_URL}/delete/` + id);
  },

  reset(id) {
    return dataFetchQuery(`${API_URL}/reset/` + id);
  },

  ping(id) {
    return dataFetchQuery(`${API_URL}/ping/` + id);
  },

  getHealth(id) {
    return dataFetchQuery(`${API_URL}/getHealth/` + id);
  },

  getTime(id) {
    return dataFetchQuery(`${API_URL}/getTime/` + id);
  },

  restore(id) {
    return dataFetchQuery(`${API_URL}/restore/` + id + '?serverReport=true');
  },

  setDateTime(id) {
    return dataFetchQuery(`${API_URL}/setDateTime/` + id);
  },

  setEventSubscription(id) {
    return dataFetchQuery(`${API_URL}/setEventSubscription/` + id);
  },

  renewEventSubscription(id) {
    return dataFetchQuery(`${API_URL}/renewEventSubscription/` + id);
  },

  testEventSubscription(id) {
    return dataFetchQuery(`${API_URL}/testEventSubscription/` + id);
  },

  unsetEventSubscription(id) {
    return dataFetchQuery(`${API_URL}/unsetEventSubscription/` + id);
  },

  getDeviceHealthProfile(id) {
    return dataFetchQuery(`${API_URL}/getDeviceHealthProfile/` + id);
  },

  createDebugSubscription(input = {}, id) {
    return dataFetchQuery(`${API_URL}/createDebugSubscription/` + id, input);
  },
  reboot(id) {
    return dataFetchQuery(`${API_URL}/reboot/` + id);
  },
  readEventStream(input = {}, id = '') {
    return dataFetchQuery(`${API_URL}/readEventStream/` + id, input);
  },

  getRestoreHistory(id) {
    return dataFetchQuery(`${API_URL}/restoreHistory/${id}`);
  },

  getDailyCount(input) {
    return dataFetchQuery(`${API_URL}/getDailyCount`, input);
  },

  capabilities(deviceId, input = {}) {
    return dataFetchQuery(`${API_URL}/capabilities/${deviceId}`, input);
  },

  setAudio(deviceId, input = {}) {
    return dataFetchQuery(`${API_URL}/setAudio/${deviceId}`, input);
  },

  acknowledgeAlert(deviceId, alert) {
    return dataFetchQuery(`${API_URL}/acknowledgeAlert/${deviceId}/${alert}`);
  },

  getServerReport(deviceId) {
    return dataFetchQuery(`${API_URL}/getServerReport/${deviceId}`);
  },

  getSystemLog(deviceId) {
    return callAPI('GET', `/device/getSystemLog/` + deviceId);
  },

  getDeviceMetricsLog(deviceId) {
    return callAPI('GET', `/device/getDeviceMetricsLog/` + deviceId);
  },

  getAppInfo(deviceId) {
    return callAPI('GET', `/device/getAppInfo/` + deviceId);
  },
  params(deviceId, input) {
    return callAPI('GET', `/device/params/` + deviceId, input);
  },
  upgradeFirmware(deviceId, input) {
    return callAPI('POST', `/device/upgradeFirmware/` + deviceId, input);
  },
  cancelFirmwareUpgrade(deviceId) {
    return callAPI('POST', `/device/cancelFirmwareUpgrade/` + deviceId);
  },
  toggleApp(deviceId, input = {}) {
    return callAPI('POST', `/device/toggleApp/` + deviceId, input);
  }
};

export default Device;
