import React from "react";
import MetricEvent from "api/metric";
import { useQuery } from "react-query";
import { useAuth } from "providers/auth";
import { css } from "@emotion/react";
import ChartLegend from "./chart-legend";
/** @jsxImportSource @emotion/react */
import ActiveChartCore from "components/charts/active-chart-core";

const ActiveCredentialChart = (props) => {
  const { filter = "-1 day", type = "bar" } = props;
  const { focusedSite } = useAuth();

  const [chartData, setChartData] = React.useState([]);

  const { isLoading, data } = useQuery(["credential-usage", focusedSite], () =>
    MetricEvent.count({
      since: "-7 day",
      type: "accessGranted",
      site_id: focusedSite,
      groupBy: "$credential_description",
      limit: 5,
    })
  );

  React.useEffect(() => {
    if (!data) return;

    setChartData(
      data?.map((i) => ({ name: i.credentialDescription, value: i.count }))
    );
  }, [data]);

  return (
    <section
      css={css`
        height: 100%;
        width: 100%;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          height: 100%;
        `}
      >
        <ActiveChartCore
          chartData={chartData}
          isLoading={isLoading}
          type={type}
        />
        <ChartLegend data={chartData} />
      </div>
    </section>
  );
};

export default ActiveCredentialChart;
