import React from 'react';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import { IconCircleCheck, IconAlertCircle } from '@tabler/icons-react';
export const columns = [
  {
    Header: 'Created At',
    accessor: 'createdAt',
    pickerDisabled: true,

    disableSortBy: true,

    Cell: ({ row: { original }, cell: { value } }) => {
      return <time>{new Date(value).toLocaleString()}</time>;
    }
  },
  {
    Header: 'Sent At',
    accessor: 'timestamp',
    pickerDisabled: true,

    disableSortBy: true,

    Cell: ({ row: { original }, cell: { value } }) => {
      return <time>{new Date(value * 1000).toLocaleString()}</time>;
    }
  },
  {
    Header: 'Status',
    accessor: 'event',
    pickerDisabled: true,
    disableSortBy: true,

    Cell: ({ row: { original }, cell: { value } }) => {
      if (value == 'deferred' || value == 'bounce')
        return (
          <div
            title={original.response}
            css={css`
              display: flex;
              align-items: center;
              gap: 0.2rem;
            `}
          >
            <IconAlertCircle color={'var(--light--others--orange-400-base'} />
            failed
          </div>
        );
      else if (value == 'delivered')
        return (
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 0.2rem;
            `}
          >
            <IconCircleCheck color={'var(--light--primary--400-base)'} />
            done
          </div>
        );
      return <div>{value}</div>;
    }
  },
  {
    Header: 'To',
    accessor: 'to',
    pickerDisabled: true,
    disableSortBy: true,

    Cell: ({ row: { original }, cell: { value } }) => {
      return value;
    }
  },
  {
    Header: 'Template',
    accessor: 'template',
    disableSortBy: true
  },
  {
    Header: 'IP',
    accessor: 'originating_ip',
    disableSortBy: true
  },
  {
    Header: 'Sent By',
    accessor: 'user_name',
    disableSortBy: true
  }
];
