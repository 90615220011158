import React from 'react';
import { Badge } from 'components/badge';
import { Telephone, Envelope } from 'react-bootstrap-icons';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import { AvatarCircle } from 'components/avatar';
import { getSelectionColumn } from 'components/table-selection-column';
import { dateTimeDif } from 'utils/time';
import PersonDepartments from './person-departments';
import { ActivityHeader, ActivityPercentage } from 'views/doors/table-columns';
import { useHeadlessTable } from 'providers/headless-table-provider';
import ActivityChartFormatter from 'components/activity-chart-formatter';

export const columns = [
  getSelectionColumn(true),
  {
    Header: 'Name',
    accessor: 'name',
    style: { width: '220px' },
    pickerDisabled: true,
    asc: 'atoz',
    desc: 'ztoa',
    newSorter: true,
    Cell: ({ row: { original } }) => {
      return (
        <span
          css={css`
            display: flex;
            gap: 0.8rem;
            align-items: center;
            text-transform: capitalize;
          `}
        >
          <AvatarCircle name={original.name} url={original.avatar_url} />
          {original.name}
        </span>
      );
    }
  },
  {
    Header: 'Status',
    accessor: 'enabled',
    columnId: 'enabled',
    disableSortBy: true,

    Cell: ({ row: { original: status } }) => {
      if (status.isLoading || status.isSuccess) return status.statusMessage;
      return (
        <>
          {status.enabled ? (
            <Badge color="success">Active</Badge>
          ) : (
            <Badge color="danger">Barred</Badge>
          )}
        </>
      );
    }
  },

  {
    Header: 'Employee ID',
    accessor: 'employee_id',
    disableSortBy: true
  },

  {
    Header: 'Info',
    accessor: 'email',
    style: { width: '300px' },
    disableSortBy: true,
    Cell: ({ row }) => (
      <article
        css={css`
          display: grid;
          gap: 8px;
          span {
            display: flex;
            gap: 8px;
            a {
              text-decoration: underline;
            }
            svg {
              height: 16px;
              width: 16px;
              color: #ccc;
            }
          }
        `}
      >
        {row.original.email && (
          <span>
            <Envelope />
            <a href={`mailto:${row.original.email}`}>{row.original.email}</a>
          </span>
        )}
        {row.original.phone && (
          <span>
            <Telephone />
            {row.original.phone}
          </span>
        )}
      </article>
    )
  },

  {
    Header: 'Credential Count',
    accessor: 'credential_count',
    asc: 'leastCredentials',
    desc: 'mostCredentials',
    newSorter: true
  },
  ,
  {
    Header: 'Update Time',
    accessor: 'update_time',
    asc: 'oldestUpdated',
    desc: 'newestUpdated',
    newSorter: true,
    Cell: ({ cell: { value } }) => <span>{dateTimeDif(value)}</span>
  },
  {
    Header: 'Departments',
    accessor: 'department_names',
    disableSortBy: true,
    Cell: ({ row: { original: person } }) => (
      <PersonDepartments person={person} />
    )
  },
  {
    Header: 'Date Inserted',
    accessor: 'insert_time',
    label: 'Date Inserted',
    id: 'insert_time',
    asc: 'oldestInserted',
    desc: 'newestInserted',
    newSorter: true,
    Cell: ({ cell: { value } }) => {
      if (value) return <span>{dateTimeDif(value)}</span>;
      else return null;
    }
  },
  {
    Header: () => <ActivityHeader />,
    disableSortBy: true,
    id: '7 Day Trend',
    label: '7 Day Trend',
    accessor: 'activity',

    Cell: ({ cell: { value } }) => <ActivityChartFormatter activity={value} />
  },
  {
    Header: () => <ActivityPercentage />,
    accessor: 'activity_total',
    label: 'Activity Percentage',
    newSorter: true,
    asc: 'mostActivity',
    desc: 'leastActivity',

    Cell: ({ cell: { value } }) => {
      if (value == 0) return null;
      const { tableState } = useHeadlessTable();

      let average =
        Number(tableState?.stats?.activity_total) / tableState?.stats?.total;

      const difference = value - average;

      // Calculate the percentage difference
      const ratio = (difference / average) * 100;

      return (
        //solve this
        <div>
          <eq
            data-positive={ratio > 0}
            css={css`
              display: flex;
              align-items: center;
              &[data-positive='true'] {
                color: var(--light--primary--400-base);
              }
              &[data-positive='false'] {
                color: var(--light--others--red-400-base);
              }

              font-size: 16px;
              font-weight: 600;
            `}
          >
            {ratio.toFixed(2)}%
          </eq>
        </div>
      );
    }
  }
];
