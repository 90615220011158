import React from 'react';

/** @jsxImportSource @emotion/react */
import {
  TabItem,
  TabGroup,
  TabPanel,
  TabPanels,
  TabList
} from 'components/tabs';
import { css } from '@emotion/react';
import { Calendar, Question, Record2 } from 'react-bootstrap-icons';
import EventList from 'components/event-list';
import UsageChartHolder from 'components/charts/usage-chart-holder';
import LiveEvents from 'components/live-events';
import { useEventList } from 'hooks/use-event-list';
import SidebarEmptyHolder from 'components/sidebar-empty-holder';

const AuxiliaryTabs = ({ aux }) => {
  let filter = React.useCallback(
    (data) => {
      if (
        ['ioPort', 'ipOpenTooLong', 'ioNormal', 'ioTriggerRelay'].includes(
          data?.type
        ) &&
        data.port_id == aux?.io_port_id
      )
        return true;
      return false;
    },
    [aux]
  );

  const query = useEventList({
    filters: {
      port_id: aux?.io_port_id
    }
  });

  if (!aux.io_port_id) return null;

  return (
    <TabGroup>
      <TabList
        css={css`
          width: 100%;
          box-shadow: unset;
          gap: 8px;
          padding: 0px 16px;
          gap: 48px;
          border: 1px solid var(--neutral-12);
          [role='tab'] {
            min-width: unset;
          }
        `}
      >
        <TabItem title="Events">
          <Calendar size={24} />
        </TabItem>
        <TabItem title="Live Events">
          <Record2 size={32} />
        </TabItem>
      </TabList>
      <TabPanels>
        <TabPanel
          css={css`
            overflow: hidden;
            height: 100%;
            display: flex;
            flex-direction: column;
            padding: 16px;
          `}
        >
          {/* {door.token && <LastPerson door={door} />} */}

          <EventList query={query} />
        </TabPanel>
        <TabPanel
          css={css`
            position: relative;
            height: 100%;
          `}
        >
          {!(aux.Direction == 'output' && aux.device_product == 'A1001') ? (
            <LiveEvents filter={filter} />
          ) : (
            <SidebarEmptyHolder
              icon={Question}
              title="Unsupported Prodcut"
              subtitle="A1001 does not support output port updates"
            />
          )}
        </TabPanel>
      </TabPanels>
    </TabGroup>
  );
};

export default AuxiliaryTabs;
