import React from 'react';

import SelectWrapper from 'components/headless-select';
import { useFormikContext } from 'formik';

export const WEEK_DAYS = [
  { value: '1', label: 'Sunday' },
  { value: '2', label: 'Monday' },
  { value: '3', label: 'Tuesday' },
  { value: '4', label: 'Wednesday' },
  { value: '5', label: 'Thursday' },
  { value: '6', label: 'Friday' },
  { value: '7', label: 'Saturday' }
];
const IterationSelect = (props) => {
  const { values, setFieldValue } = useFormikContext();

  return (
    <SelectWrapper
      simple
      placeholder="Select..."
      helper="Day of the week to run the report"
      title="Iteration Day"
      isMulti={false}
      idx="value"
      label="label"
      options={WEEK_DAYS}
      isSearchable={false}
      value={WEEK_DAYS.find((r) => r.value == values.dayOfWeek)}
      onChange={(e) => {
        setFieldValue('dayOfWeek', parseInt(e.value));
      }}
      {...props}
    />
  );
};

export default IterationSelect;
