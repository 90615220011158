import React from 'react';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { IconAlertTriangle } from '@tabler/icons-react';

export default ({ videoProperties, currentTime }) => {
  let diffBetweenFeeds = videoProperties?.videoEl?.videoTime
    ? ((currentTime - videoProperties?.videoEl?.videoTime) / 1000).toFixed(1)
    : 0;

  if (diffBetweenFeeds < 20) return null;

  return (
    <article
      css={css`
        background: rgba(29, 32, 35, 0.8);
        border-radius: 8px;
        display: flex;
        align-items: center;
        gap: 0.3rem;
        padding: 0.5rem;
        > label {
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #ffffff;
        }
      `}
    >
      <IconAlertTriangle />
      <label>LOW BANDWDITH</label>
    </article>
  );
};
