import React from 'react';
import { useQueryWrapper } from 'utils/ajax';
import CameraAction from 'api/camera_action';

import Toggle from 'components/form/toggle';
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useMutationWrapper } from 'utils/ajax';
import Spinner from 'components/spinner';
import { CircleFill } from 'react-bootstrap-icons';
import Device from 'api/device';

const App = ({ camera, app }) => {
  const supportedApps = ['vmd', 'device_metrics'];
  const appFields = {
    vmd: 'motion_app_state'
  };
  const [appState, setAppState] = React.useState(0);
  const [dbState, setDbState] = React.useState(
    camera?.[appFields[app.id]] ?? app.state == 'active'
  );

  // might be a bug here but still work if u go and change in the camera itslef

  const cameraAppMutate = useMutationWrapper(
    ['device', 'togggle-app', camera?.device_id],
    (input) => Device.toggleApp(camera?.device_id, input),
    {
      refetch: ['device', 'apps', camera?.device_id]
    }
  );

  React.useEffect(() => {
    setAppState(app.state == 'active');
  }, [app]);

  return (
    <article
      css={css`
        display: flex;
        justify-content: space-between;
        width: 100%;
        gap: 1rem;
        margin-bottom: 2rem;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 0.3rem;
        `}
      >
        <span
          css={css`
            opacity: ${!supportedApps.includes(app.id) ? '60%' : '100%'};
          `}
        >
          {app.Name || app.name || app.id}
        </span>
        <eq
          css={css`
            font-size: 12px;
          `}
        >
          version: {app.Version}
        </eq>
        <div>
          <CircleFill
            size={10}
            color={appState == 1 ? 'var(--primary-base' : 'var(--red-base'}
          />
          <span
            css={css`
              font-size: 12;
              margin-left: 4px;
            `}
          >
            Currently: {appState == '1' ? 'Running' : 'Paused'}
          </span>
        </div>
      </div>

      <Toggle
        disabled={!supportedApps.includes(app.id)}
        data-app={app.id}
        value={dbState}
        onChange={(e) => {
          setAppState(e.target.checked);

          cameraAppMutate.mutate({
            app: app.id,
            state: e.target.checked
          });
        }}
      />
    </article>
  );
};

const CameraApps = ({ camera }) => {
  if (!camera) throw new Error('no camera provided');

  const { data: apps, isLoading } = useQueryWrapper(
    ['device', 'apps', camera?.device_id],
    () => Device.getAppInfo(camera.device_id),
    {
      enabled: Boolean(camera) && camera.online == '1',
      cacheTime: 0
    }
  );

  return (
    <article
      css={css`
        width: 100%;
        .spinner {
          display: block;
          margin: 24px auto;
        }
        footer {
          font-size: 12px;
          color: var(--neutral-02);
        }
      `}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <section>
            {apps?.map((app) => (
              <App camera={camera} app={app} />
            ))}
          </section>
          <footer>* Apps will maintain state after device reset.</footer>
        </>
      )}
    </article>
  );
};

export default CameraApps;
