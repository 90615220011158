import { Badge } from 'components/badge';
import 'react-circular-progressbar/dist/styles.css';
import { getSelectionColumn } from 'components/table-selection-column';
import SiteHealthFormatter from 'components/site-health-formatter';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'providers/auth';
import { Binoculars } from 'react-bootstrap-icons';
import { IconButton } from 'components/buttons';
import { css } from '@emotion/react';
import LastAccessFormatter from 'components/last-access-formatter';
import { ActivityHeader, ActivityPercentage } from 'views/doors/table-columns';
import ActivityChartFormatter from 'components/activity-chart-formatter';
import { useHeadlessTable } from 'providers/headless-table-provider';
import { IconMapPinCheck, IconPin } from '@tabler/icons-react';

/** @jsxImportSource @emotion/react */
export const columns = [
  getSelectionColumn(),
  {
    style: { width: '400px' },
    Header: 'Name',
    accessor: 'name',
    pickerDisabled: true,
    newSorter: true,
    asc: 'atoz',
    desc: 'ztoa',
    Cell: ({ cell, row: { original } }) => {
      const { siteFocus, focusedSite } = useAuth();
      const history = useHistory();

      const handleFocus = (e) => {
        e.stopPropagation();
        siteFocus.mutate(original.id, {
          onSuccess: () => history.push('/dashboard')
        });
      };

      return (
        <article
          css={css`
            display: flex;
            gap: 8px;
            align-items: center;
          `}
        >
          <button
            type="button"
            disabled={focusedSite == original.id}
            css={css`
              font-weight: 500;
              font-size: 13px;
              display: flex;
              align-items: center;
              gap: 0.3rem;
              &:hover:enabled {
                text-decoration: underline !important;
              }
            `}
            onClick={handleFocus}
          >
            {focusedSite == original.id && (
              <IconMapPinCheck color="var(--light--primary--400-base)" />
            )}

            {original.name}
          </button>
        </article>
      );
    }
  },
  {
    Header: 'Status',
    accessor: 'locked',
    newSorter: true,
    asc: 'newestLocked',
    desc: 'oldestLocked',
    Cell: ({ cell, row: { original } }) => {
      return (
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 0.3rem;
          `}
        >
          {cell.value == '0' ? (
            <Badge color="success">Active</Badge>
          ) : (
            <>
              <Badge color="danger">Locked</Badge>
              {original.ac_door_count !== original.locked_down_doors && (
                <span title="Some doors are not Locked Down">
                  {original.ac_door_count} / {original.locked_down_doors}
                </span>
              )}
            </>
          )}
        </div>
      );
    }
  },

  {
    Header: 'Time Zone',
    accessor: 'time_zone',
    Header: 'Time Zone',
    newSorter: true,
    asc: 'atozTimeZone',
    desc: 'ztoaTimeZone'
  },

  {
    Header: 'Last Access',
    accessor: 'last_access_time',
    newSorter: true,
    asc: 'newestLastAccess',
    desc: 'oldestLastAccess',
    style: { width: '300px' },
    Cell: ({ row: { original }, cell: { value } }) => (
      <LastAccessFormatter door={original} />
    )
  },
  {
    Header: 'Devices',
    accessor: 'device_count',
    newSorter: true,
    desc: 'mostDevices',
    asc: 'leastDevices',
    Cell: ({ row: { original }, cell: { value } }) => (
      <>
        <strong>{value}</strong>
        {original.offline_count > 0 && (
          <>
            <span> / </span>
            <b
              title="Offline"
              css={css`
                color: var(--red-base);
                margin-left: 0.2rem;
              `}
            >
              {original.offline_count}
            </b>
          </>
        )}
      </>
    )
  },
  {
    newSorter: true,
    desc: 'mostDoors',
    asc: 'leastDoors',
    Header: 'Doors',
    accessor: 'door_count'
  },

  {
    newSorter: true,
    desc: 'mostOnline',
    asc: 'leastOnline',
    disableSortBy: true,
    Header: 'Health',
    accessor: 'online_count',
    Cell: ({ row: { original: site } }) => <SiteHealthFormatter site={site} />
  },
  {
    Header: () => <ActivityHeader />,
    disableSortBy: true,
    id: '7 Day Trend',
    label: '7 Day Trend',
    accessor: 'activity',

    Cell: ({ cell: { value } }) => <ActivityChartFormatter activity={value} />
  },
  {
    Header: () => <ActivityPercentage />,
    accessor: 'activity_total',
    label: 'Activity Percentage',
    newSorter: true,
    asc: 'mostActivity',
    desc: 'leastActivity',

    Cell: ({ cell: { value } }) => {
      if (value == 0) return null;
      const { tableState } = useHeadlessTable();

      let average =
        Number(tableState?.stats?.activity_total) / tableState?.stats?.total;

      const difference = value - average;

      // Calculate the percentage difference
      const ratio = (difference / average) * 100;

      return (
        //solve this
        <div>
          <eq
            data-positive={ratio > 0}
            css={css`
              display: flex;
              align-items: center;
              &[data-positive='true'] {
                color: var(--light--primary--400-base);
              }
              &[data-positive='false'] {
                color: var(--light--others--red-400-base);
              }

              font-size: 16px;
              font-weight: 600;
            `}
          >
            {ratio.toFixed(2)}%
          </eq>
        </div>
      );
    }
  }
];
