import React from 'react';
import Camera from 'api/camera';
import { useQueryWrapper } from 'utils/ajax';
import { useEventSource } from 'react-use-websocket';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useLayout } from 'providers/layout-provider';
import { Person, PersonFill } from 'react-bootstrap-icons';
import { IconUserCheck, IconUserX } from '@tabler/icons-react';

export default ({ camera }) => {
  const { data } = useQueryWrapper(
    ['camera', 'feed', 'count', camera.id],
    () => Camera.getFeedViewCount(camera?.id),
    {
      enabled: camera?.online == 1
    }
  );

  const [viewers, setViewers] = React.useState();

  let filter = React.useCallback(
    (data) => {
      if (data?.camera_id == camera.id && data.type == 'feedViewCount')
        return true;
      return false;
    },
    [camera]
  );

  React.useEffect(() => setViewers(data), [data]);

  const { getSocketUrl } = useLayout();

  const { lastEvent, readyState } = useEventSource(getSocketUrl, {
    share: true,
    filter: (e) => filter(JSON.parse(e.data)),
    withCredentials: true,
    retryOnError: true
  });

  React.useEffect(() => {
    if (!lastEvent) return;

    let data = JSON.parse(lastEvent.data);

    setViewers(data.count);
  }, [lastEvent]);

  return (
    <article
      className="feed-count-control"
      title="current viewers"
      css={css`
        display: flex;
        align-items: center;
        gap: 0.3rem;
        padding: 0.5rem;
        width: fit-content;
        opacity: ${readyState == 1 ? '100%' : '50%'};
        > label {
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #ffffff;
        }
      `}
    >
      {readyState == 1 && <IconUserCheck size={22} color="white" />}
      {readyState != 1 && <IconUserX size={22} color="white" />}

      <label> {viewers}</label>
    </article>
  );
};
