import React from 'react';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import { ThemeContext } from 'providers/theme';
import { divIcon } from 'leaflet';
import L from 'leaflet';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
const Map = ({ site, lat = 0, lng = 0, zoom = 10, iconHtml = null }) => {
  const { theme } = React.useContext(ThemeContext);
  let markerProps = {};
  if (iconHtml) markerProps.icon = divIcon({ html: iconHtml });

  function createSiteIcon(i = {}) {
    const LeafIcon = L.DivIcon.extend({
      options: {}
    });

    let issue = i?.offline_count > 0 || i?.unsynced_count > 0;
    return new LeafIcon({
      html: `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
      <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
    </svg>`,
      className: 'site-marker'
    });
  }

  const [map, setMap] = React.useState(null);

  React.useEffect(() => {
    if (!map) return;
    map.panTo(new L.LatLng(lat, lng));
  }, [lat, lng]);

  return (
    <div
      className="map-container"
      css={css`
        .leaflet-pane.leaflet-tile-pane {
          filter: grayscale(0.9);
        }
      `}
    >
      <MapContainer
        center={[Number(lat), Number(lng)]}
        zoom={zoom}
        whenCreated={setMap}
        dragging={false}
        zoomControl={false}
        scrollWheelZoom={false}
        doubleClickZoom={false}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker
          position={[Number(lat), Number(lng)]}
          icon={createSiteIcon(site)}
          {...markerProps}
        ></Marker>
      </MapContainer>
    </div>
  );
};

export default Map;
