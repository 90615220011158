import React from "react";
import AsyncSelect from "components/form/async-select";
import Schedule from "api/schedule";
import { withField } from "./with-field";

const ScheduleSelect = ({ children, ...props }) => {
  return (
    <AsyncSelect
      title="Schedule"
      idx="token"
      label="Name"
      isMulti={false}
      fetchFn={Schedule.search.bind()}
      {...props}
    />
  );
};

export default withField(ScheduleSelect);
