import React from 'react';
import Template from 'base/template';
import { Button, CancelButton, SecondaryButton } from 'components/buttons';
import Checkbox from 'components/form/checkbox';
import Door from 'api/door';
import FormProvider from 'providers/form-provider';
import { useMutationWrapper } from 'utils/ajax';
import { useRedirect } from 'hooks/redirect-hook';
import { Redirect } from 'react-router-dom';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import Content from 'base/content';
import PageTitle from 'components/page-title';
import { NumberField } from 'components/form';
import scheduleSelect from 'components/form/schedule-select';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Breadcrumbs from 'components/breadcrumbs';
import AsyncSelect from 'components/headless-select/async';
import Schedule from 'api/schedule';
/** @jsxImportSource @emotion/react */
import Alert from 'components/alert';
import DoorOpenSchedule from 'views/doors/door-open-schedule';
import { useParams } from 'react-router-dom';
import { useQueryWrapper } from 'utils/ajax';
import { useNotFound } from 'hooks/not-found-hook';
import GenericDoor from 'api/generic_door';
import IoOpenSchedule from 'views/devices/io-open-schedule';
import IOPort from 'api/ioport';
const DoorSchedule = (props) => {
  const { id } = useParams();

  const [door, setDoor] = React.useState();

  const query = useQueryWrapper(
    ['door', 'get', id],
    () => GenericDoor.search({ id }),
    {
      initialData: {
        data: [props?.location?.state?.door]
      },
      enabled: Boolean(!props?.location?.state?.door),
      cacheTime: 0
    }
  );

  useNotFound(id, query, props);

  React.useEffect(() => {
    if (!query.data.data?.[0]) return;
    setDoor(query.data.data?.[0]);
  }, [query.data.data?.[0]]);

  const SCHEMA = Yup.object().shape({});

  const INITIAL_VALUES = {
    schedule: door?.io_open_schedule_token,
    port_state: door?.io_open_schedule_port_state ?? '1'
  };

  const { isLoading, isSuccess, mutate } = useMutationWrapper(
    ['door', 'schedule'],
    (input) => IOPort.setOpenSchedule(input, door?.io_port_id)
  );

  const { redirect, setRedirect } = useRedirect(isSuccess);

  return (
    <Template {...props}>
      <Content {...props}>
        <PageTitle>{door?.name}</PageTitle>
        <Breadcrumbs crumbs={props.crumbs} />
        {door && (
          <>
            {door.door_token ? (
              <DoorOpenSchedule door={door} />
            ) : (
              <Formik
                validationSchema={SCHEMA}
                enableReinitialize={true}
                initialValues={INITIAL_VALUES}
                onSubmit={(values) => {
                  mutate(values);
                }}
              >
                {({ handleSubmit }) => (
                  <FormProvider mutateFunc={handleSubmit} disabled={isLoading}>
                    {redirect && <Redirect to="/doors" />}
                    <IoOpenSchedule deviceType={door.device_type} />

                    <footer>
                      <Button type="submit">Submit</Button>
                      <CancelButton onClick={() => setRedirect(true)} />
                    </footer>
                  </FormProvider>
                )}
              </Formik>
            )}
          </>
        )}
      </Content>
    </Template>
  );
};

export default DoorSchedule;
