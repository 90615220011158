import React from 'react';
import { css } from '@emotion/react';
import { TextArea } from 'components/form';
/** @jsxImportSource @emotion/react */
import { Field, useFormikContext } from 'formik';
import SelectWrapper from 'components/headless-select';
import { components } from 'react-select';
import { IconButton, SecondaryButton } from 'components/buttons';
import { Icon } from '@mui/material';
import { QuestionCircle, Search } from 'react-bootstrap-icons';

const MODIFIERS = [
  { name: 'Event', value: '%e', modifier: '%e', button: true },
  { name: 'Device name', value: '%D', modifier: '%D', button: true },
  { name: 'Door name', value: '%R', modifier: '%R', button: true },
  { name: 'Camera name', value: '%C', modifier: '%C' },
  { name: 'Person name', value: '%P', modifier: '%P' },
  { name: 'Credential Description', value: '%S', modifier: '%S' },
  { name: 'Timestamp', value: '%T', modifier: '%T', button: true },
  {
    name: 'License Plate Text',
    value: '%x',
    modifier: '%x',
    field: 'plateText'
  },
  {
    name: 'License Plate Confidence',
    value: '%d',
    modifier: '%d',
    field: 'plateConfidence'
  },
  { name: 'Sensor Name', value: '%S', modifier: '%S', field: 'sensor_name' },
  { name: 'Sensor Topic', value: '%o', modifier: '%o', field: 'topic' },
  { name: 'Site Name', value: '%v', modifier: '%v', field: 'site_name' }
];

export const Option = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 0.8rem;
          font-size: 12px;
        `}
      >
        <span
          css={css`
            background: var(--neutral-05);
            padding: 0.3rem;
            font-size: 11px;
          `}
        >
          {props.data.value}
        </span>
        <span>{props.data.name}</span>
      </div>
    </components.Option>
  );
};

export const Control = ({ children, ...props }) => {
  return (
    <components.Control {...props}>
      <Search
        css={css`
          margin-left: 5px;
        `}
      />
      {children}
    </components.Control>
  );
};

const styles = css`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  strong {
    font-size: 11px;
  }
`;
const TemplateBuilder = (props) => {
  const { values, setFieldValue } = useFormikContext();
  return (
    <article css={styles}>
      <section
        css={css`
          display: flex;
          gap: 0.5rem;
          align-items: baseline;

          button {
            padding: 0.3rem !important;
            font-size: 11px;
            height: auto;
          }
          .select__wrapper {
            margin-left: auto;
            flex: 0 0 40%;
          }
        `}
      >
        <strong>Most used:</strong>
        {MODIFIERS.map(
          (m, idx) =>
            m.button && (
              <SecondaryButton
                key={idx}
                onClick={() =>
                  setFieldValue('template', values?.template + m.value)
                }
              >
                {m.name}
              </SecondaryButton>
            )
        )}
        <SelectWrapper
          css={css`
            flex: 1 1 20%;
            margin-left: auto;
          `}
          simple
          data-compact
          components={{
            Option,
            IndicatorSeparator: null,
            DropdownIndicator: null,
            Control
          }}
          placeholder="Search modifiers to add.."
          isSearchable={true}
          onChange={(e) =>
            setFieldValue('template', values?.template + e.value)
          }
          value={null}
          options={MODIFIERS}
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
        />
      </section>

      <TextArea
        placeholder="Type message or add modifiers to create template"
        label="Message Template"
        name="template"
        value={values.template}
        maxLength="100"
        css={css`
          padding-bottom: 0px;
          grid-column: 1/-1;
          color: var(--neutral-00);
          textarea {
            height: 100px;
          }
        `}
      />
    </article>
  );
};

export default TemplateBuilder;
