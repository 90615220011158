import React from 'react';
import { css } from '@emotion/react';
import Company from 'api/company';
import { useQuery } from 'react-query';
import ReactECharts from 'echarts-for-react';

/** @jsxImportSource @emotion/react */
import AuditLog from 'api/audit_log';
import { useQueryWrapper } from 'utils/ajax';

const styles = css`
  height: 100%;
  width: 100%;
  .spinner {
    display: block;
    margin: 24px auto;
  }
`;

const UserActivity = ({ user, ...props }) => {
  const [chartSeries, setChartSeries] = React.useState([]);

  const { data, isLoading } = useQueryWrapper(
    ['user', 'activity', user.id],
    () => AuditLog.getDailyCount({ user: user.id })
  );

  const option = {
    animation: false,
    title: {
      subtext: 'Operations per day',
      left: 0,
      top: -10
    },

    tooltip: {
      appendToBody: true,
      trigger: 'axis',
      axisPointer: {
        z: 210
      },

      axisPointer: {
        type: 'shadow'
      },

      formatter: function (params) {
        return `${params[0].data[0]} <br> Operations: ${params[0].data[1]}`;
      },
      backgroundColor: 'var(--neutral-09)',
      textStyle: {
        color: 'var(--neutral-00)'
      }
    },

    xAxis: {
      // name: "offline/online Events ",
      // nameLocation: "middle",
      // nameTextStyle: {
      //   padding: [40, 0, 0, 0],
      // },
      type: 'time',
      //data: data?.map((d) => d._id) || "",
      axisLabel: {
        // boundaryGap: true,
        alignWithLabel: true,
        interval: 0,
        hideOverlap: true,
        formatter: {
          month: '{monthStyle|{MMM}}',
          day: '{M}/{d}' // 1, 2, ...
        },
        rich: {
          monthStyle: {
            fontWeight: 'bold'
          }
        }

        //show: false,
      },
      axisLine: {
        lineStyle: {
          width: 3
        }
      },
      axisTick: {
        show: false
      },
      minInterval: 3600 * 1000 * 24
    },

    yAxis: {
      splitLine: {
        show: false
      },

      axisTick: { show: false },
      axisLabel: { show: false }
    },

    series: chartSeries
  };

  React.useEffect(() => {
    if (!data) return;
    //set colors

    setChartSeries([
      {
        data: data
          .map?.((d) => [d._id, d.count])
          .sort((a, b) => new Date(a[0]) - new Date(b[0])),
        type: 'line',
        showSymbol: false,
        color: '#ea3737',
        areaStyle: {
          opacity: 0.1
        }
      }
    ]);
  }, [data]);

  return (
    <section className="company-health-profile" css={styles}>
      {data && data?.length > 0 && (
        <>
          <ReactECharts
            opts={{ renderer: 'svg' }}
            option={option}
            style={{ padding: '0px', height: '200px' }}
          />
        </>
      )}
    </section>
  );
};

export default UserActivity;
