import React from 'react';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { VolumeOff, VolumeUp } from 'react-bootstrap-icons';
import styled from 'styled-components';
import useVolumeMeter from './use-video-volume';
const VolumeMeter = styled('section')`
  /* paint background of level meter */
  padding: 0.5rem;

  .meter {
    width: 20px;
    height: 100px;
    //background-color: #f1f1f1; */
    position: relative;
    background: rgba(29, 32, 35, 0.8);
  }

  .bar {
    width: 100%;
    height: 15%;
    background-color: #4caf50;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: height 0.5s ease-in-out;
  }
`;
export default ({ videoEl }) => {
  //  const volume = useVolumeMeter(videoRef, isMuted);

  if (!videoEl) return null;

  const [isMuted, setIsMuted] = React.useState(true);

  return (
    <article className="volume-control">
      <button
        onClick={() => {
          videoEl.muted = !isMuted;
          setIsMuted((prev) => !prev);
        }}
      >
        {isMuted ? <VolumeOff size={24} /> : <VolumeUp size={24} />}
      </button>
    </article>
  );
};
