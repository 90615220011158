import React from 'react';

const useBuildVersion = () => {
  function generateBuildNo() {
    const stamp = String(Date.now() - 1674240669000);
    return (
      '0.' +
      stamp.slice(0, -8).padStart(2, '0') +
      '.' +
      stamp.slice(-8, -4).padStart(4, '0')
    );
  }
  const [upBuildNo, setUpBuildNo] = React.useState(null);

  const localVersion = process.env.REACT_APP_BUILD_NO ?? generateBuildNo();

  React.useEffect(() => {
    if (process.env.NODE_ENV == 'development') return;
    const getVersion = async () => {
      const res = await fetch('/version.txt');

      if (res.status == 200) setUpBuildNo(await res.text());
    };
    const interval = setInterval(getVersion, 60 * 1000);
    getVersion();

    return () => interval && clearInterval(interval);
  }, []);

  return { upBuildNo, generateBuildNo, localVersion };
};

export default useBuildVersion;
