import React, { Fragment } from 'react';
import { Tab } from '@headlessui/react';

const TabGroup = ({ children, ...otherProps }) => {
  return (
    <Tab.Group as={Fragment} {...otherProps}>
      {children}
    </Tab.Group>
  );
};

export default TabGroup;
