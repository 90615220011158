import { dataFetchQuery } from 'utils/ajax';

import { API_HOST_URL } from './api-url';
const API_URL = API_HOST_URL + '/ioport_api';

const IOPort = {
  save(input, id) {
    return dataFetchQuery(`${API_URL}/save/${id}`, input);
  },

  search(input) {
    return dataFetchQuery(`${API_URL}/search`, input);
  },
  getAllPortsDirection(id) {
    return dataFetchQuery(`${API_URL}/getAllPortsDirection/${id}`);
  },
  setPortDirection(input = {}, id) {
    return dataFetchQuery(`${API_URL}/setPortDirection/${id}`, input);
  },
  getAllPortsDirection(id) {
    return dataFetchQuery(`${API_URL}/getAllPortsDirection/${id}`);
  },

  testOutput(id, port) {
    return dataFetchQuery(`${API_URL}/testOutput/${id}/${port}`);
  },

  output(id) {
    return dataFetchQuery(`${API_URL}/output/${id}`);
  },

  toggle(device_id, port, state) {
    return dataFetchQuery(`${API_URL}/toggle/${device_id}/${port}/${state}`);
  },
  togglePort({ portId, state }) {
    return dataFetchQuery(
      `${API_URL}/togglePort/${portId}` + (state ? `/${state}` : '')
    );
  },
  setOpenSchedule(input = {}, portId) {
    console.log('MMM', input);
    return dataFetchQuery(
      `${API_URL}/setOpenSchedule/${portId}` +
        (input.schedule ? `/${input.schedule}` : ''),
      input
    );
  }
};

export default IOPort;
