import React from 'react';
import update from 'immutability-helper';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { css } from '@emotion/react';

import { ArrowRight, ArrowLeft, CaretLeft } from 'react-bootstrap-icons';

import DoorWidget from './door-widget';
import { IconButton } from 'components/buttons';

import AddItemDialog from './add-item-dialog';
import GridControls from './grid-controls';
import {
  IconCubePlus,
  IconSquareRoundedArrowRightFilled,
  IconSquareRoundedArrowLeftFilled,
  IconVideoPlus,
  IconSquareRoundedX
} from '@tabler/icons-react';
import CameraWidget from './camera-widget';
import AddWidgetDialog from './add-widget-dialog';
import { IconSquareRoundedXFilled } from '@tabler/icons-react';
import { DraggableItem } from './draggable-item';
import LastPersonWidget from './last-person-widget';
import EmptyHolder from './empty-holder';
import ClockWidget from './clock-widget';
import TopBar from './top-bar';
import WidgetsContainer from './widgets-container';
import CamerasContainer from './cameras-container';
/** @jsxImportSource @emotion/react */

const ViewLayout = ({
  selectedView,
  cols,
  rows,
  isEdit,
  setSelectedView,
  setIsEdit
}) => {
  return (
    <div
      className="main-container"
      css={css`
        overflow: hidden;
        display: flex;
        width: 100%;
        height: 100%;
        padding: 0.5rem;
        gap: 0.5rem;
      `}
    >
      {selectedView && (
        <section
          css={css`
            overflow: hidden;
            flex: 1;
            display: flex;
            gap: 1rem;
            flex-direction: column;

            /* .widget-section:not(:only-child) {
              min-height: 200px;
              height: auto;
              grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
              grid-template-rows: 1fr;
            }
            .widget-section:only-child {
              flex: 1;
              > div.view-container {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
                height: 100%;
                gap: 0.5rem;
              }
            } */
          `}
        >
          <TopBar
            selectedView={selectedView}
            isEdit={isEdit}
            setSelectedView={setSelectedView}
          />
          <WidgetsContainer
            selectedView={selectedView}
            isEdit={isEdit}
            setSelectedView={setSelectedView}
          />
          <CamerasContainer
            selectedView={selectedView}
            isEdit={isEdit}
            setSelectedView={setSelectedView}
          />
        </section>
      )}
    </div>
  );
};

export default ViewLayout;
