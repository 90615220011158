import React, { useRef, useEffect, forwardRef } from 'react';
import { useGesture } from '@use-gesture/react';

import { css } from '@emotion/react';
import { scale } from 'chroma-js';
import { canvas } from 'leaflet';

/** @jsxImportSource @emotion/react */
const GridCanvas = forwardRef(
  ({ gridSize, videoRef, isZoomMode, videoProperties }, ref) => {
    const canvasRef = useRef(null);
    const zoomCanvasRef = useRef(null);

    const animationFrameRef = React.useRef();

    // const [gridCoords, setGridCoords] = React.useState({});
    const grid = React.useRef({ x: 0, y: 0 });

    // const calculateCanvasSize = () => {
    //   const container = canvasRef.current.parentElement;
    //   const containerWidth = container.clientWidth;
    //   const containerHeight = container.clientHeight;

    //   const canvas = canvasRef.current;
    //   canvas.width = containerWidth;
    //   canvas.height = containerHeight;

    //   return { width: containerWidth, height: containerHeight };
    // };

    // const fillRectOnHover = (event) => {
    //   let canvas = canvasRef.current;
    //   const rect = canvas.getBoundingClientRect();
    //   const { width, height } = calculateCanvasSize();
    //   const context = canvas.getContext('2d');

    //   const rectSizeX = width / 4;
    //   const rectSizeY = height / 4;

    //   const mouseX = event.clientX - canvas.getBoundingClientRect().left;
    //   const mouseY = event.clientY - canvas.getBoundingClientRect().top;

    //   const gridX = Math.floor(mouseX / rectSizeX);
    //   const gridY = Math.floor(mouseY / rectSizeY);

    //   context.clearRect(0, 0, width, height);
    //   drawGrid(context, width, height);

    //   context.fillStyle = 'rgba(0, 0, 255, 0.3)';
    //   context.fillRect(
    //     gridX * rectSizeX,
    //     gridY * rectSizeY,
    //     rectSizeX,
    //     rectSizeY
    //   );
    // };

    // const drawGrid = (ctx, width, height) => {
    //   ctx.beginPath();
    //   for (let x = 0; x <= width; x += width / 4) {
    //     ctx.moveTo(x, 0);
    //     ctx.lineTo(x, height);
    //   }
    //   for (let y = 0; y <= height; y += height / 4) {
    //     ctx.moveTo(0, y);
    //     ctx.lineTo(width, y);
    //   }
    //   ctx.strokeStyle = '#999';
    //   ctx.stroke();
    // };

    // const getMouseCoordinates = (event) => {
    //   const rect = canvasRef.current.getBoundingClientRect();
    //   const mouseX = event.clientX - rect.left;
    //   const mouseY = event.clientY - rect.top;

    //   const gridX = Math.floor((mouseX / rect.width) * 4);
    //   const gridY = Math.floor((mouseY / rect.height) * 4);

    //   console.log(
    //     `Clicked at (x: ${mouseX}, y: ${mouseY}) in grid (${gridX}, ${gridY})`
    //   );
    //   const gridSectionWidth = rect.width / 4;
    //   const gridSectionHeight = rect.height / 4;

    //   const gridTopX = gridX * gridSectionWidth;
    //   const gridTopY = gridY * gridSectionHeight;

    //   console.log('Top-left coordinates of the clicked grid section:', {
    //     x: gridTopX,
    //     y: gridTopY
    //   });

    //   setGridCoords({ x: gridTopX, y: gridTopY });
    //   grid.current.x = gridTopX;
    //   grid.current.y = gridTopY;
    // };

    // useEffect(() => {
    //   const { width, height } = calculateCanvasSize();
    //   const canvas = canvasRef.current;
    //   const context = canvas.getContext('2d');
    //   drawGrid(context, width, height);

    //   // Add click event listener to the canvas
    //   canvas.addEventListener('click', getMouseCoordinates);
    //   canvas.addEventListener('mousemove', fillRectOnHover);

    //   function getTransformedPoint(x, y) {
    //     const originalPoint = new DOMPoint(x, y);
    //     return context.getTransform().invertSelf().transformPoint(originalPoint);
    //   }

    //   return () => {
    //     // Cleanup: remove event listener when the component unmounts
    //     canvas && canvas.removeEventListener('click', getMouseCoordinates);
    //   };
    // }, [gridSize]);

    // Forward the ref to the canvas element
    useEffect(() => {
      if (ref) {
        ref.current = canvasRef.current;
      }
    }, [ref]);

    const loopHandler = React.useCallback(
      function () {
        //var $this = this; //cache
        if (!canvasRef?.current) return;
        function loop() {
          if (!videoRef.paused && !videoRef.ended) {
            const context = canvasRef.current.getContext('2d');
            const { width, height } = videoRef.getBoundingClientRect();
            let relativeXPixel = videoRef.videoWidth / width;
            let relativeYPixel = videoRef.videoHeight / height;
            context.drawImage(
              videoRef,
              grid.current.x * relativeXPixel,
              grid.current.y * relativeYPixel,
              videoRef.videoWidth / 5,
              videoRef.videoHeight / 5,
              0,
              0,
              400,
              200
            );
            //context.fillText('ppp', 10, 20);
          }

          animationFrameRef.current = requestAnimationFrame(loop);
        }
        loop();
      },
      [canvasRef, videoRef, videoProperties]
    );

    useEffect(() => {
      if (!videoRef) return;

      //   videoRef.addEventListener('play', loopHandler);
      videoRef.style.filter = 'grayscale(100%)';
      loopHandler();

      return () => {
        // Cleanup: remove event listener when the component unmounts
        //  videoRef.removeEventListener('play', loopHandler);
        cancelAnimationFrame(animationFrameRef.current);
        videoRef.style.filter = 'grayscale(0%)';
      };
    }, [videoRef, videoProperties]);

    let [pos, setPos] = React.useState({ x: 0, y: 0, scale: 1 });

    const divRef = React.useRef();

    useGesture(
      {
        onDrag: ({ offset: [dx, dy] }) => {
          grid.current.x = dx > 0 ? dx : 0;
          grid.current.y = dy > 0 ? dy : 0;
          setPos({ x: dx > 0 ? dx : 0, y: dy > 0 ? dy : 0 });
        }
      },
      {
        target: canvasRef
      }
    );

    return (
      <div
        className="zoom-section"
        ref={divRef}
        css={css`
          display: ${isZoomMode ? 'block' : 'none'};
          border: 2px solid blue;
          overflow: hidden;
          position: absolute;
          inset: 0;
        `}
      >
        <canvas
          className="zoom-canvas"
          ref={canvasRef}
          style={{
            cursor: 'move',
            border: '4px solid red',
            position: 'absolute',
            left: pos.x,
            top: pos.y,
            width: '400px',
            height: '200px'
            //backgroundColor: 'white'
          }}
        />
      </div>
    );
  }
);

export default GridCanvas;
