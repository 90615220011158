import React from 'react';
import Template from 'base/template';
import { Button, CancelButton } from 'components/buttons';
import FormProvider from 'providers/form-provider';
import { Redirect } from 'react-router-dom';
import { useMutationWrapper } from 'utils/ajax';
import { useRedirect } from 'hooks/redirect-hook';

import AuxiliaryForm from 'views/auxiliaries/form';
import Auxiliary from 'api/auxiliary';

import { Formik } from 'formik';
import * as Yup from 'yup';
import PageTitle from 'components/page-title';
import Content from 'base/content';
import Breadcrumbs from 'components/breadcrumbs';

const Save = (props) => {
  let aux = props?.location?.state?.aux || null;

  const SCHEMA = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required')
  });

  const INITIAL_VALUES = {
    ...aux,
    name: aux?.name,
    category: aux?.category
  };

  let { isLoading, mutate, isSuccess } = useMutationWrapper(
    ['Auxiliary', 'save'],
    (input) => Auxiliary.save(input, aux?.id),
    {}
  );

  const { redirect, setRedirect } = useRedirect(isSuccess);

  return (
    <Template {...props}>
      <Content {...props}>
        {redirect && <Redirect to="/auxiliaries" />}
        <PageTitle>{aux ? aux.name : 'Auxiliary  Add'}</PageTitle>
        <Breadcrumbs crumbs={props.crumbs} />
        <Formik
          validationSchema={SCHEMA}
          initialValues={INITIAL_VALUES}
          onSubmit={(values) => {
            mutate(values);
          }}
        >
          {({ handleSubmit }) => (
            <FormProvider mutateFunc={handleSubmit} disabled={isLoading}>
              <AuxiliaryForm aux={aux} />
              <footer>
                <Button type="submit">Submit</Button>
                <CancelButton onClick={() => setRedirect(true)} />
              </footer>
            </FormProvider>
          )}
        </Formik>
      </Content>
    </Template>
  );
};

export default Save;
