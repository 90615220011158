import React from 'react';
import Template from 'base/template';
import Elevator from 'api/elevator';
import { columns } from 'views/elevator-floors/table-columns';
import IndexContent from 'base/index-content';
import ActionMenu from 'views/elevator-floors/action-menu';

import { useAuth } from 'providers/auth';
import ElevatorFloor from 'api/elevator_floor';
import { useQueryWrapper } from 'utils/ajax';
import { useNotFound } from 'hooks/not-found-hook';
import { useParams } from 'react-router-dom';

const cards = [
  {
    name: 'total',
    label: 'Total'
  }
];

const ElevatorFloors = (props) => {
  const { user } = useAuth();
  const { id } = useParams();

  const [elevator, setElevator] = React.useState();

  const query = useQueryWrapper(
    ['elevator', 'get', id],
    () => Elevator.search({ id }),
    {
      initialData: {
        data: [props?.location?.state?.elevator]
      }
    }
  );

  useNotFound(id, query, props);

  React.useEffect(() => {
    if (!query.data.data?.[0]) return;
    setElevator(query.data.data?.[0]);
  }, [query.data.data?.[0]]);

  return (
    <Template {...props}>
      {elevator && (
        <IndexContent
          newSorter
          initialFilter={{ elevator: elevator.id }}
          statsQuery={ElevatorFloor.stats}
          title="floors"
          pageTitle={elevator.name}
          id="floors"
          cards={cards}
          query={ElevatorFloor.search}
          columns={columns}
          isSidebarOpen={true}
          actionButton={ActionMenu}
          getRowId={(row, relativeIndex, parent) => {
            return row.door_token;
          }}
          //actionButton={ActionMenu}

          {...props}
        ></IndexContent>
      )}
    </Template>
  );
};

export default ElevatorFloors;
