import React from "react";
import Template from "base/template";

import Snapshot from "api/snapshot";
import { columns } from "views/snapshots/table-columns";
//import Sidebar from "views/doors/sidebar";
import IndexContent from "base/index-content";

const cards = [];

const Snapshots = (props) => {
  const cameraId = props.location.state?.camera?.id;

  return (
    <Template {...props}>
      <IndexContent
        initialFilter={{ camera: cameraId }}
        statsQuery={null}
        id="snapshots"
        cards={cards}
        query={Snapshot.search.bind()}
        columns={columns}
        //sidebar={Sidebar}

        {...props}
        title={props.location?.state?.camera?.name || "Snapshots"}
      ></IndexContent>
    </Template>
  );
};

export default Snapshots;
