import { CheckBox, PasswordField, TextField } from "components/form";
import { Field, useFormikContext, getIn } from "formik";
import React from "react";
import { css } from "@emotion/react";
import { useAuth } from "providers/auth";
/** @jsxImportSource @emotion/react */

const UserFields = (props) => {
  const { user, prefix = "" } = props;
  const { values, setFieldValue } = useFormikContext();
  const { user: userSession } = useAuth();

  let enforceMFA = userSession.mfa_enforce || user?.company_mfa_enforce;

  return (
    <>
      <Field as={TextField} name={prefix + "name"} autoComplete="off" label="Name" />
      <Field as={TextField} name={prefix + "email"} autoComplete="off" label="E-Mail" />
      <Field as={TextField} name={prefix + "phone"} autoComplete="off" label="Phone" />
      <br />
      <section
        css={css`
          display: grid;
          grid-column: 1 /-1;
          grid-template-columns: 1fr 1fr;
          align-items: start;
        `}
      >
        <CheckBox
          name={prefix + "enable_2fa"}
          label="Enable Two Factor Authentication"
          checked={getIn(values, prefix + "enable_2fa")}
          onChange={(e) =>
            setFieldValue(prefix + "enable_2fa", e.target.checked)
          }
          disabled={getIn(values, prefix + "enable_mfa")}
        />
        <div>
          <CheckBox
            name={prefix + "enable_mfa"}
            label="Enable MFA via authentication app"
            checked={enforceMFA || getIn(values, prefix + "enable_mfa")}
            disabled={enforceMFA || getIn(values, prefix + "enable_2fa")}
            onChange={(e) =>
              setFieldValue(prefix + "enable_mfa", e.target.checked)
            }
          />
          {enforceMFA && (
            <div
              css={css`
                margin-top: 8px;
                font-size: 11px;
              `}
            >
              MFA is enforced on company level.
            </div>
          )}

          {user &&
            Boolean(parseInt(user.enable_mfa)) !=
              getIn(values, prefix + "enable_mfa") && (
              <div
                css={css`
                  margin-top: 8px;
                  font-size: 11px;
                `}
              >
                Changing MFA status will de-activate the account and resend
                activation e-mail.
              </div>
            )}
        </div>
      </section>
      {!user ? (
        <>
          {!getIn(values, prefix + "enable_mfa") && (
            <CheckBox
              name={prefix + "active"}
              label="Activate user by default"
              checked={getIn(values, prefix + "active")}
              onChange={(e) =>
                setFieldValue(prefix + "active", e.target.checked)
              }
            />
          )}

          {getIn(values, prefix + "active") && !getIn(values, prefix + "enable_mfa") ? (
            <Field
              as={PasswordField}
              label="Password"
              autoComplete="new-password"
              name={prefix + "password"}
              placeholder="Type to add..."
            />
          ) : (
            <br />
          )}
        </>
      ) : (!getIn(values, prefix + "enable_mfa") &&
        <>
          <Field
            as={PasswordField}
            autoComplete="new-password"
            name={prefix + "password"}
            label="Password"
            placeholder="Type to change..."
          />
          <br />
        </>
      )}
    </>
  );
};

export default UserFields;
