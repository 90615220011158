import SMS from './sms-form';
import Email from './email-form';
import SiteAction from './site-action-form';

import DoorAction from './door-action-form';
import DoorSchedule from './door-schedule-form';
import WebhookForm from './webhook-form';
import IOPortTrigger from './io-port-trigger-form';
import {
  Envelope,
  ChatLeftDots,
  BoxArrowInLeft,
  Diagram3,
  LockFill,
  Clock,
  CodeSlash,
  Lock,
  Camera
} from 'react-bootstrap-icons';
import CameraAction from './camera-action-form';
import PlayAudioClip from './play-audio-clip-form';
import { IconDeviceSpeaker } from '@tabler/icons-react';

const ACTIONS = [
  {
    label: 'Send Email',
    value: 'email',
    description: 'Send email to recipients',
    icon: Envelope,
    component: Email
  },
  {
    label: 'Send Sms',
    value: 'sms',
    description: 'Send SMS to recipients',
    icon: ChatLeftDots,
    component: SMS
  },
  {
    label: 'Trigger IO Port',
    value: 'io_device_action',
    description: 'Triggers the IO Port',
    icon: BoxArrowInLeft,
    component: IOPortTrigger
  },
  {
    label: 'Activate Door Schedule',
    value: 'door_schedule_action',
    description: 'Activates door schedule',
    icon: Clock,
    component: DoorSchedule
  },
  {
    label: 'Webhook',
    value: 'webhook',
    description: 'Activates webhook form',
    icon: CodeSlash,
    component: WebhookForm
  },
  {
    label: 'Door Action',
    value: 'door_action',
    description: 'Activates door action',
    icon: Lock,
    component: DoorAction
  },
  {
    label: 'Site Action',
    value: 'site_action',
    description: 'Activates door action',
    icon: Diagram3,
    component: SiteAction
  },

  {
    label: 'Camera Action',
    value: 'camera_action',
    description: 'Activates Camera',
    icon: Camera,
    component: CameraAction
  },
  {
    label: 'Play Audio Clip',
    value: 'play_audio_clip',
    description: 'Plays Audio Clip',
    icon: IconDeviceSpeaker,
    component: PlayAudioClip
  }
];

export default ACTIONS;
