import { dataFetchQuery } from "utils/ajax";

import { API_HOST_URL } from "./api-url";
const API_URL = API_HOST_URL + "/guest_api";

import { callAPI } from 'utils/ajax';

const Guest = {

  get(id) {
    return callAPI('GET', `/guest/${id}`);
  },

  search(input = {}) {
    return callAPI('GET', `/guest`, input);
  },

  stats(input = {}) {
    return callAPI('GET', `/guest/stats`, input);
  },

  save(input, id = "") {
    return dataFetchQuery(`${API_URL}/save/` + id, input);
  },

  bar(id = "") {
    return dataFetchQuery(`${API_URL}/bar/` + id);
  },

  delete(id) {
    return dataFetchQuery(`${API_URL}/delete/` + id);
  },

  sendPass(id) {
    return dataFetchQuery(`${API_URL}/sendPass/` + id);
  },

  openDoor(hash, doorToken) {
    return dataFetchQuery(`${API_URL}/openDoor/` + doorToken + "/" + hash);
    //callAPIBlocking('api/guest_api/openDoor/' + doorToken + '/' + hash);
  },
};

export default Guest;
