import { callAPI } from 'utils/ajax';

const Sensor = {
  search(input = {}) {
    return callAPI('GET', `/sensor`, input);
  },
  get(id = '') {
    return callAPI('GET', `/sensor/${id}`);
  },
  stats(input = {}) {
    return callAPI('GET', `/sensor/stats`, input);
  },
  save(input = {}, id = '') {
    return callAPI(id ? 'PUT' : 'POST', `/sensor/${id}`, input);
  },
  delete(id) {
    return callAPI('DELETE', `/sensor/${id}`);
  },
  getTimeSeries(input = {}, id) {
    return callAPI('GET', `/sensor/getTimeSeries/${id}`, input);
  },
  generateToken(id) {
    return callAPI('POST', `/sensor/generateToken/${id}`);
  },
  getHealthProfile(id) {
    return callAPI('POST', `/sensor/getHealthProfile/${id}`);
  },
  saveMetadata(input = {}, id) {
    return callAPI('POST', `/sensor/saveMetadata/${id}`, input);
  },
  getTopics(id, input = null) {
    return callAPI('GET', `/sensor/getTopics/${id}`, input);
  },
  getTopicList(id) {
    return callAPI('GET', `/sensor/getTopicList/${id}`);
  }
};

export default Sensor;
