import 'css/sidebar.css';
import React from 'react';
import { withSidebar } from 'components/with-sidebar';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import ActiveDoorChart from 'views/dashboard/active-door-chart';
import { useHeadlessTable } from 'providers/headless-table-provider';
import SidebarEmptyHolder from 'components/sidebar-empty-holder';
import Tabs from './sidebar-tabs';
import ActionMenu from './action-menu';
import styled from '@emotion/styled';
import { ReactComponent as ElevatorIcon } from 'assets/elevator-icon.svg';

const Stats = () => {
  return <ActiveDoorChart />;
};

export const SidebarWrapper = styled('article')`
  display: flex;
  height: 100% !important;
  flex-direction: column;

  label {
    width: 100%;
  }

  > header {
    padding: 24px;
    display: flex;

    align-items: center;
    box-shadow: inset 0px -1px 0px var(--neutral-10);
    gap: 1rem;
    > span {
      width: 40px;
      height: 40px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px;
      background: ${(props) =>
        props.elevator.online == 0 ? 'var(--red-v-02)' : 'var(--primary-v-02)'};
      color: ${(props) =>
        props.elevator.online == 0 ? 'var(--red-v-01)' : 'var(--primary-base)'};

      border-radius: 4px;
    }

    h2 {
      margin: 0;

      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 100%;
      /* identical to box height, or 18px */

      /* Neutrals/neutral-00 */

      color: var(--neutral-00);
    }
    h3 {
      font-weight: 500;
      font-size: 12px;
      line-height: 100%;

      color: var(--menu-item-color);
    }
  }

  section.event-list {
    width: 100%;

    overflow-y: auto;
    margin-bottom: 8px;
  }
  section.usage-chart {
    width: 100%;
    flex: 1;
  }

  > section.groups {
    h4 {
      grid-column: 1/-1;
    }
    padding: 8px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 8px;
    width: 100%;

    margin-bottom: 8px;
  }
`;
const Sidebar = (props) => {
  const [elevator, setElevator] = React.useState();

  const { selectedFlatRows } = useHeadlessTable();

  React.useEffect(() => {
    setElevator(selectedFlatRows?.[0]?.original || props.data);
  }, [selectedFlatRows, props.data]);

  if (!elevator) return <SidebarEmptyHolder />;
  return (
    <SidebarWrapper elevator={elevator}>
      <header>
        <span>
          <ElevatorIcon />
        </span>

        <h2>{elevator?.name}</h2>
      </header>
      <Tabs elevator={elevator} />
    </SidebarWrapper>
  );
};

export default withSidebar({})(Sidebar, Stats);
