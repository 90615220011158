import { css } from "@emotion/react";
import { useCallback } from "react";
/** @jsxImportSource @emotion/react */

const debounce = function(){
    let timer;
    return (props, color) => {
        clearTimeout(timer);
        timer = setTimeout(() =>
        props.onChange({ target: { name: props.name, value: color } }), 200);
    }
};

export const ColorPicker = (props) => {

    const myCb = useCallback(debounce());

    return <span css={css`
        border-radius: 5px;
        border: 1px solid #0006;
        min-width: 24px;
        min-height: 24px;
        overflow: hidden;
        display: block;
        position: relative;

        > input[type=color] {
            cursor: pointer;
            position: absolute;
            width: calc(100% + 20px);
            height: calc(100% + 20px);
            top: 0;
            left: -12px;
            right: 0;
            bottom: 0;
            margin: auto;

            &[disabled] {
                opacity: .2;
                cursor: default;
            }
        }
    `} className="color">
        <input {...props} onChange={ev => myCb(props, ev.target.value)} type="color" />
    </span>;
};