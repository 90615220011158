/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  IconAlertCircle,
  IconAlertTriangle,
  IconCheck,
  IconCircleCheck
} from '@tabler/icons-react';
import { Badge } from 'components/badge';
import { Link } from 'react-router-dom/cjs/react-router-dom';
const {
  differenceInMilliseconds,
  differenceInSeconds,
  differenceInMinutes,
  differenceInHours,
  parseISO
} = require('date-fns');
export const columns = [
  {
    Header: 'Created At',
    accessor: 'createdAt',
    sortDisable: true,
    disableSortBy: true,
    Cell: ({ cell: { value } }) => {
      return <time>{new Date(value).toLocaleString()}</time>;
    }
  },
  {
    Header: 'Finished In',
    accessor: 'finishedAt',
    sortDisable: true,
    disableSortBy: true,
    Cell: ({ cell: { value }, row: { original } }) => {
      if (value)
        return (
          <>
            <time>
              {differenceInSeconds(
                new Date(original.finishedAt),
                new Date(original.createdAt)
              )}
            </time>
            <span> secs.</span>
          </>
        );
      else return null;
      // return <time>{new Date(value).toLocaleString()}</time>;
    }
  },

  {
    Header: 'Company',
    accessor: 'companyName',
    sortDisable: true,
    disableSortBy: true
  },
  {
    Header: 'Originator',
    accessor: 'userName',
    sortDisable: true,
    disableSortBy: true
  },
  {
    Header: 'Action',
    accessor: 'action',
    sortDisable: true,
    disableSortBy: true
  },
  {
    Header: 'Jobs',
    accessor: 'totalJobs',
    sortDisable: true,
    disableSortBy: true,
    Cell: ({ row: { original } }) => {
      return (
        <dl
          css={css`
            margin-bottom: 0px;
            display: flex;
            align-items: center;
            grid-template-columns: auto 1fr;
            row-gap: 0.5rem;
            column-gap: 0.5rem;
            dt {
              opacity: 50%;
            }
          `}
        >
          <dt>Total:</dt>
          <dd>
            <Badge color="gray">{original.totalJobs}</Badge>
          </dd>
          <dt>Processed:</dt>
          <dd>
            <Badge color="success">{original.processedJobs}</Badge>
          </dd>
        </dl>
      );
    }
  },
  {
    Header: 'Status',
    accessor: 'successes',
    sortDisable: true,
    disableSortBy: true,
    Cell: ({ cell: { value }, row: { original } }) => {
      return (
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 0.5rem;
          `}
        >
          {value?.length == original.totalJobs ? (
            <IconCircleCheck color="var(--light--primary--400-base)" />
          ) : (
            <IconAlertCircle color="var(--light--others--red-400-base)" />
          )}
          <eq>{value?.length}</eq> Jobs
        </div>
      );
    }
  },
  // {
  //   Header: 'Device',
  //   accessor: 'deviceId',
  //   sortDisable: true,
  //   disableSortBy: true,
  //   Cell: ({ row }) => {
  //     return (
  //       <div>
  //         <strong>{row.original.deviceName}</strong>
  //         <span> (id: {row.original.deviceId})</span>
  //       </div>
  //     );
  //   }
  // },
  {
    Header: 'Batch Id',
    accessor: 'batchId',
    sortDisable: true,
    disableSortBy: true,
    Cell: ({ cell: { value }, row: { original } }) => {
      return (
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 0.5rem;
          `}
        >
          {original?.failures?.length > 0 && (
            <IconAlertTriangle color="var(--light--others--red-400-base)" />
          )}
          <Link to={`/sync-jobs/${value}`}>
            <span>{value}</span>
          </Link>
        </div>
      );
    }
  }
  // {
  //   Header: 'Batch Count',
  //   accessor: 'batchCount',
  //   sortDisable: true,
  //   disableSortBy: true
  // },
  // {
  //   Header: 'Status',
  //   accessor: 'status',
  //   sortDisable: true,
  //   disableSortBy: true,
  //   Cell: ({ value }) => {
  //     let STATUS = {
  //       done: 'green',
  //       new: 'orange',
  //       skipped: 'gray'
  //     };

  //     return <Badge color={STATUS[value]}>{value}</Badge>;
  //   }
  // },

  // {
  //   Header: 'Created At',
  //   accessor: 'createdAt',
  //   sortDisable: true,
  //   disableSortBy: true,
  //   Cell: ({ row, value }) => (
  //     <span>{new Date(Number(value.$date.$numberLong)).toLocaleString()}</span>
  //   )
  // },
  // {
  //   Header: 'Ran At',
  //   accessor: 'ranAt',
  //   sortDisable: true,
  //   disableSortBy: true,
  //   Cell: ({ row, value }) => (
  //     <span>
  //       {value && new Date(Number(value.$date.$numberLong)).toLocaleString()}
  //     </span>
  //   )
  // },
  // {
  //   Header: 'Type',
  //   accessor: 'type',
  //   sortDisable: true,
  //   disableSortBy: true
  // },
  // {
  //   Header: 'Token',
  //   accessor: 'token',
  //   sortDisable: true,
  //   disableSortBy: true
  // },
  // {
  //   Header: 'Action',
  //   accessor: 'action',
  //   sortDisable: true,
  //   disableSortBy: true
  // }
];
