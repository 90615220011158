import React from 'react';
import { InputLabel, InputHelper, Input } from 'components/form';
import { ErrorMessage } from 'components/form';
import DatePicker from 'react-datepicker';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import getYear from 'date-fns/getYear';
import getMonth from 'date-fns/getMonth';
import 'css/date-time.css';
import {
  Calendar,
  Calendar2,
  Calendar3,
  CaretLeft,
  CaretRight,
  ChevronLeft,
  ChevronRight,
  Clock
} from 'react-bootstrap-icons';
const getNameFromLabel = (label) =>
  label && label.toLowerCase().replace(/\ /g, '_');

const styles = css``;

const ExampleCustomInput = React.forwardRef(
  ({ value, onClick, onChange, icon: Icon }, ref) => (
    <div
      css={css`
        cursor: pointer !important;
        position: relative;
        svg {
          position: absolute;
          top: 0;
          right: 8;
          height: 100%;
          pointer-events: none;
          fill: var(--primary-base);
        }
      `}
    >
      <input
        css={css`
          cursor: pointer !important;
        `}
        value={value}
        onChange={onChange}
        onClick={onClick}
        ref={ref}
        readOnly
      />
      {Icon ? <Icon size={18} /> : <Calendar3 size={18} />}
    </div>
  )
);

const ExampleCustomTimeInput = React.forwardRef(
  ({ value, onClick, onChange }, ref) => (
    <section
      css={css`
        display: flex;
        align-items: center;
        gap: 0.2rem;
        svg {
          color: var(--neutral-02);
          width: 20;
          height: 20;
        }
      `}
    >
      <input
        css={css`
          width: 100%;
          background: transparent;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          color: inherit;
          cursor: text;

          margin: 0;
          display: inline-block;
          font-size: inherit;
          font-family: inherit;
          font-weight: 300;
          line-height: inherit;
          height: auto;
          border: 0;
          border-radius: 0;
          vertical-align: initial;
        `}
        required
        type="time"
        value={value}
        onChange={(e) => {
          e.target.value && onChange(e.target.value);
        }}
      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-clock-hour-3"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
        <path d="M12 12h3.5"></path>
        <path d="M12 7v5"></path>
      </svg>
    </section>
  )
);
function generateYearsBetween(startYear = 2000, endYear) {
  const endDate = endYear || new Date().getFullYear();

  let years = [];

  for (var i = startYear; i <= endDate; i++) {
    years.push(startYear);

    startYear++;
  }

  return years;
}

const DateTimeField = React.forwardRef((props, ref) => {
  const name = props.field?.name || props.name || getNameFromLabel(props.label);
  const tfProps = {
    'data-compact': props['data-compact']
  };

  const [isMonthView, setIsMonthView] = React.useState();

  return (
    <div
      css={styles}
      className={!props.raw ? 'field text-field ' + props?.className : ''}
      {...tfProps}
    >
      <InputLabel {...props} />
      <InputHelper {...props} />
      <DatePicker
        renderCustomHeader={({
          monthDate,
          decreaseMonth,
          increaseMonth,
          decreaseYear,
          increaseYear,
          date
        }) =>
          !isMonthView ? (
            <div
              css={css`
                display: flex;
                align-items: center;
                justify-content: space-between;
              `}
            >
              <button
                type="button"
                aria-label="Previous Month"
                className={
                  'react-datepicker__navigation react-datepicker__navigation--previous'
                }
                onClick={decreaseMonth}
              >
                <ChevronLeft />
              </button>
              <button
                type="button"
                className="react-datepicker__current-month"
                onClick={() => setIsMonthView(true)}
              >
                {monthDate.toLocaleString('en-US', {
                  month: 'long',
                  year: 'numeric'
                })}
              </button>
              <button
                type="button"
                aria-label="Next Month"
                className={
                  'react-datepicker__navigation react-datepicker__navigation--next'
                }
                onClick={increaseMonth}
              >
                <ChevronRight />
              </button>
            </div>
          ) : (
            <div
              css={css`
                display: flex;
                align-items: center;
                justify-content: space-between;
              `}
            >
              <button
                type="button"
                aria-label="Previous Month"
                className={
                  'react-datepicker__navigation react-datepicker__navigation--previous'
                }
                onClick={decreaseYear}
              >
                <ChevronLeft />
              </button>
              <button
                onClick={() => setIsMonthView(false)}
                type="button"
                className="react-datepicker__current-month"
              >
                {date.getFullYear()}
              </button>
              <button
                type="button"
                aria-label="Next Month"
                className={
                  'react-datepicker__navigation react-datepicker__navigation--next'
                }
                onClick={increaseYear}
              >
                <ChevronRight />
              </button>
            </div>
          )
        }
        className="date-time"
        //   showYearDropdown
        dateFormatCalendar="MMMM"
        yearDropdownItemNumber={20}
        scrollableYearDropdown
        dateFormat="MMMM d, yyyy hh:mm a"
        selected={
          props.form?.values[props.field.name]
            ? new Date(props.form?.values[props.field.name])
            : new Date()
        }
        onChange={(date) => {
          isMonthView && setIsMonthView(false);
          date &&
            props?.form?.setFieldValue(props.field.name, date.toLocaleString());
        }}
        timeInputLabel=""
        showTimeInput={props.noTimeInput ? false : true}
        customTimeInput={<ExampleCustomTimeInput />}
        customInput={<ExampleCustomInput icon={Calendar2} />}
        showMonthYearPicker={isMonthView}
        shouldCloseOnSelect={!isMonthView}
        {...props}
      >
        {props.children}
      </DatePicker>

      {!props.raw && <ErrorMessage name={name} {...props} />}
    </div>
  );
});

export default DateTimeField;
