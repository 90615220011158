import React from "react";
import { css } from "@emotion/react";
/** @jsxImportSource @emotion/react */
import { Button, AuxiliaryButton, CancelButton } from "components/buttons";
import { useDialogState } from "reakit/Dialog";
import { ArrowRightShort, Check2Circle, XLg } from "react-bootstrap-icons";
import { Fragment } from "react";
import { useModal } from "providers/modal-provider";
import { useMutateCheckSync } from "./actions";

import { ModalTitle } from "components/modal";
import Spinner from "components/spinner";
import { currentDTMilli } from "utils/time";

const SyncModal = (props) => {
  const {
    dialog,
    modalState: { data: ac },
  } = useModal();
  const mutateRestore = useMutateCheckSync(ac, {
    noToast: true,
    onMutate: () => {
      setEvents((prev) => [
        ...prev,
        {
          message: `${currentDTMilli()}:  Please wait while running sync check`,
          success: true,
        },
      ]);
    },
    onSuccess: (res) => {
      setEvents((prev) => [
        ...prev,
        {
          message: `Credentials: AC: ${res?.data.credentialsInAC}, DB: ${res.data.credentialsInDB}`,
          success: res.data.credentialsMatch,
        },
        {
          message: `Schedules: AC: ${res?.data.schedulesInAC}, DB: ${res?.data.schedulesInDB}`,
          success: res.data.schedulesMatch,
        },
        {
          message: `Access Levels: AC: ${res?.data.APsInAC}, DB: ${res?.data.APsInDB}`,
          success: res.data.APsMatch,
        },
        {
          message: `${currentDTMilli()}: Operation complete: ${
            res.data.match ? "Success" : "Errors Found"
          }`,
          success: res.data.match,
        },
      ]);
    },
    onError: (err) => {
      setEvents((prev) => [
        ...prev,

        {
          message: `${currentDTMilli()}:
            Error Occured during process: ${err.message}`,

          success: false,
        },
      ]);
    },
  });

  if (!ac) return null;

  const progressRef = React.useRef();

  const [events, setEvents] = React.useState([]);

  React.useEffect(
    () =>
      progressRef.current.scrollTo({
        top: 1000,

        behavior: "smooth",
      }),
    [events]
  );

  return (
    <>
      <div
        css={css`
          position: relative;
          overflow: auto;
          height: 40vh;
          min-width: 40vw;
          display: flex;
          flex-direction: column;

          overflow-y: auto;
          ul {
            font-family: "Ubuntu Mono", monospace !important;
            height: 100%;
            overflow: auto;
            margin: 8px 24px;
            padding: 8px;
            display: flex;
            flex-direction: column;

            gap: 8px;
            background: rgb(14, 30, 37);

            color: white;

            border: 1px solid var(--neutral-12);
            box-sizing: border-box;
            border-radius: 4px;
          }

          > footer {
            padding: 24px;
            margin-top: auto;
            display: flex;
            gap: 8px;
            button:nth-child(2) {
              margin-left: auto;
            }
          }
          > p,
          span {
            padding: 0px 24px;

            &[data-socket="true"] {
              color: var(--primary-base);
            }
            &[data-socket="false"] {
              color: var(--red-v-01);
            }
          }
        `}
      >
        <ModalTitle>Sync Check: {ac.name}</ModalTitle>

        <ul ref={progressRef}>
          {events.map((i, idx) => (
            <li
              key={idx}
              css={css`
                line-height: 1.6;
                display: flex;
                align-items: center;
                gap: 24px;

                svg {
                  color: var(--primary-base);
                }
              `}
            >
              {i?.success ? (
                <Check2Circle size={22} color={"green"} />
              ) : (
                <XLg size={22} color={"red"} />
              )}
              {i.message}
            </li>
          ))}
        </ul>
        <footer>
          <Button
            disabled={mutateRestore.isLoading}
            onClick={() => mutateRestore.mutate(ac.id)}
          >
            {mutateRestore.isLoading ? <Spinner /> : "Sync Check..."}
          </Button>
          <CancelButton
            disabled={mutateRestore.isLoading}
            onClick={dialog.hide}
          >
            Close
          </CancelButton>
        </footer>
      </div>
    </>
  );
};

export default SyncModal;
