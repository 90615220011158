import React from "react";

import { CheckBox, InputLabel } from "components/form";
import { Field, useFormikContext } from "formik";
import { css } from "@emotion/react";
import { Heading } from "components/form";
/** @jsxImportSource @emotion/react */

const styles = css`
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;
  > h4,
  h3 {
    margin: 0;
    grid-column: 1/-1;
    font-weight: bold;
  }
  h4 {
    color: var(--neutral-01);
  }
  section.emergency {
    grid-column: 1/-1;
    position: static;
    padding: 24px;
    background: var(--red-v-02);
    border-radius: 4px;
    input[type="checkbox"] {
      accent-color: red;
    }
  }
`;
const DoorSecurity = (props) => {
  const { values, setFieldValue } = useFormikContext();
  const { index = 0 } = props;

  return (
    <section css={styles}>
      <h4>Enable Logging of following events </h4>

      <CheckBox
        name="denied_limit"
        label="Denied Limit"
        checked={values.doors[index].denied_limit}
        onChange={(e) =>
          setFieldValue(`doors[${index}].denied_limit`, e.target.checked)
        }
      />
      <CheckBox
        name="forced_open"
        label="Forced Open"
        checked={values.doors[index].forced_open}
        onChange={(e) =>
          setFieldValue(`doors[${index}].forced_open`, e.target.checked)
        }
      />
      <CheckBox
        name="open_too_long"
        label="Open Too Long"
        checked={values.doors[index].open_too_long}
        onChange={(e) =>
          setFieldValue(`doors[${index}].open_too_long`, e.target.checked)
        }
      />
      <CheckBox
        name="rex_open_door"
        label="REX Open Door"
        checked={values.doors[index].rex_open_door}
        onChange={(e) =>
          setFieldValue(`doors[${index}].rex_open_door`, e.target.checked)
        }
      />
      <section className="emergency">
        <Heading label="Emergency"></Heading>
        <CheckBox
          name="emergency_lockdown"
          label="Enable emergency lock down on this door"
          color="red"
          checked={values.doors[index].emergency_lockdown}
          onChange={(e) =>
            setFieldValue(
              `doors[${index}].emergency_lockdown`,
              e.target.checked
            )
          }
        />
      </section>
    </section>
  );
};

export default DoorSecurity;
