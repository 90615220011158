import 'css/sidebar.css';
import React from 'react';
import { withSidebar } from 'components/with-sidebar';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import Network from '../devices/network';
import { useHeadlessTable } from 'providers/headless-table-provider';
import SidebarEmptyHolder from 'components/sidebar-empty-holder';
import {
  TabItem,
  TabGroup,
  TabPanel,
  TabPanels,
  TabList
} from 'components/tabs';
import { Calendar, Diagram3, GraphUp, Hdd, Wifi } from 'react-bootstrap-icons';
import TreeView from 'components/tree-view';

import DeviceEventList from './device-event-list';
import HealthTimelineHolder from 'components/charts/health-timeline-holder';

import DeviceEventStream from './device-event-stream';
import SyncJobList from './sync-job-list';

import DeviceRestoreChart from './device-restore-chart';
import DeviceAlerts from 'components/device-alerts';
import Alert from 'components/alert';
import { IconApiApp } from '@tabler/icons-react';
import { dateTimeDif, dateTimeLocal, dateTimeShort } from 'utils/time';
import dateTime from './tools/date-time';
const articleStyles = (props) => css`
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 8px;
  .health-section {
    padding: 8px 16px;
  }

  label {
    width: 100%;
  }
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 100%;
    align-items: center;
    min-height: 32px;
    color: var(--neutral-00);
    overflow: hidden;
    text-overflow: ellipsis;
  }
  header {
    > time {
      font-weight: 700;
      grid-column: 1/-1;
    }
    > span {
      width: 40px;
      height: 40px;
      display: flex;

      justify-content: center;
      align-items: center;
      padding: 0px;
      background: ${props.device.online == 0
        ? 'var(--red-v-02)'
        : 'var(--primary-v-02)'};
      color: ${props.device.online == 0
        ? 'var(--red-v-01)'
        : 'var(--primary-base)'};

      border-radius: 4px;
    }
    h2 {
      margin: 0;

      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 100%;
      /* identical to box height, or 18px */
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      color: var(--neutral-00);
    }
    display: grid;
    width: 100%;
    grid-template-columns: 40px 1fr;
    align-items: center;
    padding: 24px;
    box-shadow: inset 0px -1px 0px var(--neutral-10);
    gap: 1rem;
    h4 {
      margin-bottom: 0.5rem;
      color: var(--neutral-02);
      font-weight: normal;
    }
  }
`;
const Sidebar = () => {
  const { selectedFlatRows } = useHeadlessTable();
  let device = selectedFlatRows?.[0]?.original;

  if (selectedFlatRows.length == 0) return <SidebarEmptyHolder />;
  else
    return (
      <article css={articleStyles({ device: selectedFlatRows?.[0]?.original })}>
        {device.firmware_upgrade_at && (
          <div
            css={css`
              grid-column: 1/-1;
              padding: 1rem;
              border-bottom: 1px solid var(--neutral-12);
            `}
          >
            <Alert icon={IconApiApp}>
              <div
                css={css`
                  time {
                    display: block;
                    margin-top: 0.5rem;
                  }
                `}
              >
                <span>Firmware upgrade is pending around:</span>
                <time>
                  {new Date(device.firmware_upgrade_at).toLocaleString()}
                </time>
              </div>
            </Alert>
          </div>
        )}
        <header>
          <span>
            <Hdd />
          </span>
          <h2>{selectedFlatRows[0].original?.name}</h2>
          {device.last_offline_at && !device.online && (
            <div
              css={css`
                display: flex;
                flex-direction: column;
                width: 100$;
                grid-column: 1/ -1;
                > label {
                  font-size: 12px;
                  color: var(--light--neutrals--800);
                }
              `}
            >
              <label>Offline since:</label>
              <time>
                {dateTimeShort(new Date(device.last_offline_at))} (approx:
                {dateTimeDif(device.last_offline_at)})
              </time>
            </div>
          )}
        </header>
        <DeviceAlerts device={selectedFlatRows[0].original} />
        <div className="health-section">
          <h4>3 Day Health Timeline</h4>
          <HealthTimelineHolder
            slim
            device_id={selectedFlatRows[0].original?.device_id}
          />
        </div>
        <TabGroup>
          <TabList
            css={css`
              width: 100%;
              box-shadow: unset;
              gap: 32px;
              padding: 0px 16px;
              border-top: 1px solid var(--neutral-12);
              border-bottom: 1px solid var(--neutral-12);

              [role='tab'] {
                min-width: unset;
              }
            `}
          >
            <TabItem title="Events">
              <Calendar size={24} />
            </TabItem>
            <TabItem title="Netowrk">
              <Wifi size={24} />
            </TabItem>
            <TabItem title="Statistics">
              <GraphUp size={24} />
            </TabItem>
            {selectedFlatRows[0].original?.type == 'controller' && (
              <TabItem title="Tree View">
                <Diagram3 size={24} />
              </TabItem>
            )}
            {selectedFlatRows[0].original?.type == 'controller' && (
              <TabItem title="Data">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-database-import"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="1"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <ellipse cx="12" cy="6" rx="8" ry="3"></ellipse>
                  <path d="M4 6v8m5.009 .783c.924 .14 1.933 .217 2.991 .217c4.418 0 8 -1.343 8 -3v-6"></path>
                  <path d="M11.252 20.987c.246 .009 .496 .013 .748 .013c4.418 0 8 -1.343 8 -3v-6m-18 7h7m-3 -3l3 3l-3 3"></path>
                </svg>
              </TabItem>
            )}
          </TabList>
          <TabPanels>
            <TabPanel
              as="div"
              css={css`
                padding: 8px 16px;

                display: flex;
                flex-direction: column;
                overflow: hidden;
                padding: 4px 16px;
              `}
            >
              <DeviceEventList
                title
                device={{ id: Number(selectedFlatRows[0].original?.device_id) }}
              />
            </TabPanel>
            <TabPanel
              as="div"
              css={css`
                padding: 8px 16px;

                display: flex;
                flex-direction: column;
              `}
            >
              <Network device={selectedFlatRows[0].original} />
            </TabPanel>
            <TabPanel
              as="div"
              css={css`
                padding: 8px 16px;
                display: flex;
                flex-direction: column;
                overflow-y: auto;
              `}
            >
              <DeviceEventStream device={selectedFlatRows[0].original} />
              <DeviceRestoreChart device={selectedFlatRows[0].original} />
            </TabPanel>
            {selectedFlatRows[0].original?.type == 'controller' && (
              <TabPanel
                as="div"
                css={css`
                  padding: 8px 16px;
                  display: flex;
                  flex-direction: column;
                  overflow: hidden;
                `}
              >
                <TreeView ac={selectedFlatRows[0].original} />
              </TabPanel>
            )}

            {selectedFlatRows[0].original?.type == 'controller' && (
              <TabPanel
                as="div"
                css={css`
                  padding: 8px 16px;
                  display: flex;
                  flex-direction: column;
                  overflow: hidden;
                `}
              >
                <SyncJobList
                  filters={{
                    deviceId: selectedFlatRows[0].original.device_id
                  }}
                />
              </TabPanel>
            )}
          </TabPanels>
        </TabGroup>
      </article>
    );
};

export default withSidebar({})(Sidebar);
