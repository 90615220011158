import { useMutationWrapper } from "utils/ajax";
import Guest from "api/guest";
import { useQueryClient } from "react-query";
import { useHeadlessTable } from "providers/headless-table-provider";

export const useBarMutate = (guest) =>
  useMutationWrapper(["guest", "bar"], (id) => Guest.bar(id), {
    successMessage: `Operation on guest: ${guest.name} completed`,
    invalidate: "guests",
    mainQueryKey: "guests",
  });

export const useDeleteMutate = (guest) => {
  const queryClient = useQueryClient();
  const { queryKey } = useHeadlessTable();
  return useMutationWrapper(
    ["guest", "delete"],
    (id) => Guest.delete(id),

    {
      successMessage: `Guest: ${guest?.name} deleted successfuly`,
      onMutate: (id) => {
        let data = queryClient.getQueryData(queryKey);

        const newData = {
          ...data,
          data: data.data.filter((row) => row.id != id),
        };

        queryClient.setQueryData(queryKey, newData);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["guests", "stats"]);
      },
    }
  );
};

export const useEnableMutate = (guest) => {
  const queryClient = useQueryClient();
  const { queryKey } = useHeadlessTable();
  return useMutationWrapper(
    ["guest", "enable"],
    (id) => Guest.bar(id),

    {
      successMessage: `Operation on Guest: ${guest?.name} was succesfull`,
      onMutate: (id) => {
        let data = queryClient.getQueryData(queryKey);

        let newData = data.data.map((i) =>
          i.id == id
            ? { ...i, Enabled: i.Enabled == 0 ? 1 : 0 }
            : { ...i }
        );

        queryClient.setQueryData(queryKey, { ...data, data: newData });
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["guests", "stats"]);
      },
    }
  );
};

export const useSendPassMutate = () => {
  return useMutationWrapper(["guest", "send"], (id) => Guest.sendPass(id));
};
