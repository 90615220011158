import React from 'react';
import { useMutationWrapper } from 'utils/ajax';
import Device from 'api/device';
import DeviceMetrics from 'api/device_metrics';
import { useQueryClient } from 'react-query';
// import { useHeadlessTable } from 'providers/headless-table-provider';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */

import { toast } from 'react-toastify';
export const usePing = () =>
  useMutationWrapper(['device', 'ping'], (id) => Device.ping(id), {
    noToast: true
  });

export const useMutatePing = (deviceOrController) => {
  const toastRef = React.useRef();
  const loading = () =>
    (toastRef.current = toast.info('Pinging...', {
      autoClose: false,
      closeOnClick: false,
      closeButton: false
    }));

  const success = (data) =>
    toast.update(toastRef.current, {
      type: data.message == 'online' ? toast.TYPE.SUCCESS : toast.TYPE.ERROR,
      closeButton: true,
      closeOnClick: true,
      render: (
        <div
          css={css`
            > span {
              display: block;
              font-size: 12px;
            }
          `}
        >
          {data.message == 'online' ? 'ONLINE' : 'OFFLINE'}
          {data?.data?.requestTime && (
            <span>
              Request time: {Math.trunc(data?.data?.requestTime * 1000)} MS
            </span>
          )}
        </div>
      ),
      autoClose: 4000
    });
  const mutation = useMutationWrapper(
    ['device', 'ping', deviceOrController?.id],
    (id) => Device.ping(id),
    {
      noToast: true,
      onMutate: () => loading(),

      onSuccess: (data) => success(data)
    }
  );
  return mutation;
};

export const useMutateReset = (device) => {
  const queryClient = useQueryClient();
  // const { queryKey } = useHeadlessTable();
  return useMutationWrapper(
    ['device', 'reset', device?.id],
    (id) => Device.reset(id),
    {
      invalidate: 'devices',
      successMessage: `Device started reset.`,
      onSuccess: () => queryClient.refetchQueries(['devices'])
    }
  );
};

export const useMutateDelete = (device) =>
  useMutationWrapper(
    ['device', 'delete', device?.id],
    (id) => Device.delete(id),
    {
      successMessage: `device: ${device?.name} was deleted.`,
      invalidate: 'devices'
    }
  );

export const useMutateRestore = (device, options) =>
  useMutationWrapper(
    ['device', 'restore'],
    (id) => Device.restore(id),
    options
  );

export const useMutateGetServerReport = () =>
  useMutationWrapper(
    ['device', 'restore'],
    (id) => Device.getServerReport(id),
    {
      loadingMessage: 'Generating Report...'
    }
  );

export const useMutateSetDateTime = () =>
  useMutationWrapper(
    ['device', 'setDateTime'],
    (id) => Device.setDateTime(id),
    {
      successMessage: `Device Date and Time set successfully.`
    }
  );

export const useMutateSetEventSubscription = () =>
  useMutationWrapper(['device', 'setEventSubscription'], (id) =>
    Device.setEventSubscription(id)
  );

export const useMutateRenewEventSubscription = () =>
  useMutationWrapper(['device', 'renewEventSubscription'], (id) =>
    Device.renewEventSubscription(id)
  );

export const useMutateUnsetEventSubscription = () =>
  useMutationWrapper(['device', 'unsetEventSubscription'], (id) =>
    Device.unsetEventSubscription(id)
  );

export const useMutateCreateDebugSubscription = (device) =>
  useMutationWrapper(['device', 'createDebugSubscription'], (input) =>
    Device.createDebugSubscription(input, device?.id)
  );

export const useMutateReboot = () =>
  useMutationWrapper(['device', 'reboot'], (id) => Device.reboot(id));

export const useAckAlert = (device) => {
  const queryClient = useQueryClient();

  return useMutationWrapper(
    ['device', 'ackAlert', device?.device_id],
    (alert) => Device.acknowledgeAlert(device.device_id, alert),
    {
      successMessage: `Alert acknowledged`,
      onSuccess: () => {
        queryClient.refetchQueries(['devices']);
        queryClient.refetchQueries(['cameras']);
        queryClient.refetchQueries(['intercoms']);
      }
    }
  );
};

export const useInstallMetricApp = (device) => {
  const queryClient = useQueryClient();

  return useMutationWrapper(
    ['device-metric', 'install', device?.id],
    () => DeviceMetrics.installMetricApp(device.id),
    {
      loadingMessage: 'Installing Application, this might take a while...',
      successMessage: `Application installed`,
      onSuccess: () => {
        queryClient.refetchQueries(['device', 'apps', device?.id]);
      }
    }
  );
};

export const useCancelFirmwareUpdate = (device) => {
  const queryClient = useQueryClient();

  return useMutationWrapper(
    ['device', 'cancel-upgrade-firmware', device?.id],
    () => Device.cancelFirmwareUpgrade(device?.id),
    {
      onSuccess: () => queryClient.resetQueries(['device', 'get', device.id])
    }
  );
};
