import React from 'react';
/** @jsxImportSource @emotion/react */
import { Formik, Form, Field } from 'formik';
import { css } from '@emotion/react';
import * as Yup from 'yup';
import { CheckBox, InputHelper, NumberField } from 'components/form';
import { Button } from 'components/buttons';
import { useMutationWrapper } from 'utils/ajax';
import Camera from 'api/camera';
import SelectWrapper from 'components/headless-select';
import OverlayControl from 'components/video/overlay-control';
import { InputLabel } from 'components/form';
const CameraPreferences = ({ camera }) => {
  if (!camera) throw new Error('no camera provided');

  const SCHEMA = Yup.object().shape({});

  const INITIAL_VALUES = {
    ...camera,
    lpr_throttle_interval: Number(camera.lpr_throttle_interval)
  };

  let { isLoading, mutate, isSuccess } = useMutationWrapper(
    ['camera', 'save', camera?.id],
    (input) => Camera.savePreferences(input, camera?.id),
    {}
  );

  const CLOUD_RETENTION_TIME = [
    { id: 7, name: '7 Days' },
    { id: 14, name: '14 Days' },
    { id: 30, name: '30 Days' }
  ];

  return (
    <article>
      <Formik
        enableReinitialize={true}
        validationSchema={SCHEMA}
        initialValues={INITIAL_VALUES}
        onSubmit={(values, { resetForm }) => {
          mutate(values);
        }}
      >
        {({ handleSubmit, values, setFieldValue }) => (
          <Form>
            <fieldset
              disabled={isLoading}
              css={css`
                padding: 0;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                > section {
                  margin-bottom: 1rem;
                }
              `}
            >
              <section>
                <InputLabel label="Toggle camera overlay" />
                <OverlayControl host={camera} />
              </section>
              <SelectWrapper
                title="Cloud retention interval"
                helper="Keep recording on cloud for..."
                value={CLOUD_RETENTION_TIME.find(
                  (i) => i.id == values.cloud_rec_expiration_time
                )}
                onChange={(e) => {
                  setFieldValue('cloud_rec_expiration_time', e.id);
                }}
                css={css`
                  width: 25%;
                `}
                simple
                options={CLOUD_RETENTION_TIME}
              />
              {camera.lpr && (
                <div>
                  <p>
                    Set License Plate throttle time. Same license plates will
                    only be logged once per interval
                  </p>
                  <Field
                    css={css`
                      width: 50%;
                    `}
                    name="lpr_throttle_interval"
                    label="LPR throttle Interval (seconds)"
                    as={NumberField}
                    max="360"
                    min="0"
                  />
                </div>
              )}
              <footer>
                <Button
                  compact
                  type="submit"
                  onSubmit={handleSubmit}
                  disabled={isLoading}
                >
                  Save
                </Button>
              </footer>
            </fieldset>
          </Form>
        )}
      </Formik>
    </article>
  );
};

export default CameraPreferences;
``;
