import React from 'react';
import { css } from '@emotion/react';
import { IndeterminateCheckBox } from './form';
/** @jsxImportSource @emotion/react */

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input
          type="checkbox"
          ref={resolvedRef}
          {...rest}
          className="selection"
        />
      </>
    );
  }
);

export const TableSelectionColumn = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input
          type="checkbox"
          ref={resolvedRef}
          {...rest}
          className="selection"
        />
      </>
    );
  }
);

export function getSelectionColumn(multi) {
  return {
    Header: ({ getToggleAllPageRowsSelectedProps, ...otherProps }) =>
      multi ? (
        <div>
          <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
        </div>
      ) : null,
    style: { width: '60px', maxWidth: '60px' },
    id: 'selection',
    pickerHide: true,
    disableExport: true,
    Cell: ({ row }) => {
      return (
        <TableSelectionColumn
          {...row.getToggleRowSelectedProps()}
          disabled={row.disableTableSelection}
        />
      );
    }
  };
}
