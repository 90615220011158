import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import { columns } from 'views/events/table-columns';
import InfiniteTableProvider from 'providers/headless-infinite-table-provider';
import Event from 'api/event';

import PeriodSelect from 'views/reports/period-select';
import Table from 'components/headless-table-infinite';

import TableFilters from 'components/headless-filters';
import TableReloadButton from 'components/table/table-reload-button';
import React from 'react';
import ColumnPicker from 'components/column-picker';
import EventSelect from 'components/event-select';
import TableFilter from 'components/headless-table-filter';

const EventTable = (props) => {
  const [eventFilter, setEventFilter] = React.useState();
  return (
    <InfiniteTableProvider
      columns={columns.filter((col) =>
        props.selectedColumns ? props.selectedColumns?.includes(col?.id) : true
      )}
      query={Event.search}
      {...props}
    >
      {({ setGlobalFilter, state }) => {
        return (
          <article
            css={css`
              height: 100%;
              overflow: hidden;
              display: flex;
              flex-direction: column;
              .select__menu-list {
                max-height: 300px !important;
              }
              .select__menu-portal {
                z-index: 5;
              }
              .table-holder {
                flex: 1;
                overflow: hidden;
                height: 100%;
                display: flex;
                flex-direction: column;
                div.table-body-container {
                  height: 100%;
                  overflow: auto;
                }
              }
            `}
          >
            <div
              css={css`
                background: var(--neutral-09);
                padding: 16px;
                display: flex;
                align-items: center;
                gap: 1rem;
                .period-select,
                .select__wrapper {
                  width: 20%;
                }
                > .table-controls {
                  margin-left: auto;
                  display: flex;
                  align-items: center;
                  gap: 0.3rem;
                }
              `}
            >
              <PeriodSelect
                globalFilter={state.globalFilter}
                data-compact
                setSelection={setGlobalFilter}
                selection={state.globalFilter}
              />
              <EventSelect
                css={css`
                  .select__menu-list {
                    max-height: 300px !important;
                  }
                `}
                data-compact
                isMulti={false}
                types={props.eventSelectTypes}
                value={
                  state.globalFilter.categoty ||
                  state.globalFilter.type ||
                  state.globalFilter.reason ||
                  state.globalFilter.level
                    ? eventFilter.event
                    : null
                }
                onChange={(e) => {
                  setEventFilter((prev) => ({ ...prev, event: e }));

                  setGlobalFilter((prev) => ({
                    ...prev,

                    category: e?.category ? e?.value : undefined,
                    type: e?.type ? e?.value : undefined,
                    level: e?.level ? e?.value : undefined,
                    reason: e?.reason ? e?.value : undefined
                  }));
                }}
              />
              <TableFilter
                {...props}
                selectProps={{ menuPosition: 'fixed', menuPlacement: 'auto' }}
              />
              <section className="table-controls">
                <TableReloadButton compact />
              </section>
            </div>
            <TableFilters {...props} />
            <Table selectable {...props}></Table>
          </article>
        );
      }}
    </InfiniteTableProvider>
  );
};

export default EventTable;
