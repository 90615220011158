import React from "react";

import { css } from "@emotion/react";
/** @jsxImportSource @emotion/react */
import { RadioGroup } from "@headlessui/react";
const filterStyles = css`
  margin-bottom: 8px;
  font-weight: 700;
  font-size: 11px;
  text-transform: uppercase;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  border: 1px solid var(--neutral-12);

  > div {
    flex: 1;
    line-height: 32px;
    padding: 0 20px;
    cursor: pointer;
    text-align: center;
    transition: all ease 300ms;
    background: var(--neutral-09);
    color: var(--neutral-00);

    &[aria-checked="true"] {
      background-color: var(--neutral-10) !important;
    }

    &:hover {
      background: var(--neutral-05);
    }

    &:first-of-type {
      border-right: 1px solid var(--neutral-12);
    }
  }
`;

const ChartFilterMenu = ({ selection, setSelection }) => {
  return (
    <RadioGroup value={selection} onChange={setSelection}>
      <div css={filterStyles}>
        <RadioGroup.Option value="-7 days">
          <span>7 Days</span>
        </RadioGroup.Option>
        <RadioGroup.Option value="-30 days">
          <span>30 Days</span>
        </RadioGroup.Option>
        <RadioGroup.Option value="-90 days">
          <span>90 Days</span>
        </RadioGroup.Option>
      </div>
    </RadioGroup>
  );
};

export default ChartFilterMenu;
