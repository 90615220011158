import React from 'react';

import { useSensorEventTotal } from './use-sensor-event-total';

import { Section } from 'views/dashboard/user-dashboard';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const LiveIndicator = styled('div')`
    position: absolute;
    right:0;
    height: 1rem;
    width: 1rem;
    margin:1rem;

    border-radius: 4rem;

    opacity: 0;
    background: var(--primary-base);
    -webkit-animation: pulsate 1000ms ease-out;
    -webkit-animation-iteration-count: infinite;

    -webkit-transition: background-color 300ms linear;
    -moz-transition: background-color 300ms linear;
    -o-transition: background-color 300ms linear;
    -ms-transition: background-color 300ms linear;
    transition: background-color 300ms linear;
  }

  @-webkit-keyframes pulsate {
    0% {
      opacity: 0.1;
    }
    40% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    100% {
      opacity: 0.1;
    }
  }
`;

/** @jsxImportSource @emotion/react */
export default ({ sensor }) => {
  if (!sensor) return;
  const { eventTotal, readyState } = useSensorEventTotal(sensor);
  return (
    <Section>
      {readyState == 1 && <LiveIndicator />}
      <div
        css={css`
          display: flex;
          align-items: center;
          flex-direction: column;
          gap: 1rem;
          width: 100%;
          height: 100%;
          h2 {
            color: var(--secondary-font-color);
          }
          > span {
            font-size: 36px;
            &:empty:before {
              content: 'Waiting...';
              font-size: 14px;
              opacity: 50%;
            }
          }
        `}
      >
        <h2>TOTAL EVENTS</h2>
        <span>{eventTotal}</span>
      </div>
    </Section>
  );
};
