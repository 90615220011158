import React from 'react';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';
import { useQueryWrapper } from 'utils/ajax';
import Event from 'api/event';
import { css } from '@emotion/react';
import { date } from 'yup';
import subDays from 'date-fns/subDays';
import addDays from 'date-fns/addDays';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import 'css/usage-chart.css';
import InfiniteTableProvider from 'providers/headless-infinite-table-provider';
import { useHeadlessTable } from 'providers/headless-table-provider';
import BarChartLoader from 'components/chart-loader';
import { GraphUp } from 'react-bootstrap-icons';
import { useLocalStorage } from 'hooks/local-storage';

/** @jsxImportSource @emotion/react */

const LOADING_OPTIONS = {
  text: 'Fetching Data...',
  color: '#c23531',
  textColor: 'gray',
  maskColor: 'transparent',
  zlevel: 0,

  // Font size. Available since `v4.8.0`.
  fontSize: '35px',
  // Show an animated "spinner" or not. Available since `v4.8.0`.
  showSpinner: false,
  // Radius of the "spinner". Available since `v4.8.0`.
  spinnerRadius: 10,
  // Line width of the "spinner". Available since `v4.8.0`.
  lineWidth: 5,
  // Font thick weight. Available since `v5.0.1`.
  fontWeight: 'normal',
  // Font style. Available since `v5.0.1`.
  fontStyle: 'normal',
  // Font family. Available since `v5.0.1`.
  fontFamily: 'inherit'
};

const EventsTrendChart = ({ filters = {} }) => {
  const { setGlobalFilter, tableState } = useHeadlessTable();
  const [chartData, setChartData] = React.useState([]);

  const [isVisible, setIsVisible] = React.useState();

  React.useEffect(() => {
    if (tableState?.chart !== null) setIsVisible(tableState.chart);
    else setIsVisible(true);
  }, [tableState?.chart]);

  const option = {
    animation: false,
    tooltip: {
      axisPointer: {
        type: 'cross',
        axis: 'x',
        label: {
          show: false,
          backgroundColor: '#6a7985'
        }
      },

      trigger: 'item',
      backgroundColor: 'var(--neutral-09)',
      textStyle: {
        color: 'var(--neutral-00)'
      },

      formatter: function (params) {
        if (params.data)
          return `<div class= "chart-tooltip events-trend-tooltip"><time>${
            params.data.date
          }</time>
                <span>
                <svg width="10" height="10" fill='#f59728'>
                <circle cx="5" cy="4" r="4"/>   
              </svg>
              Warning: ${params.data.warning || 0}</span>
              </span>
                <span>
                 <svg width="10" height="10" fill='#ea3737'>
      <circle cx="5" cy="4" r="4"/>
    </svg>
    Critical: ${params.data.critical || 0}</span>
    <span>
    <svg width="10" height="10" fill='#2480d6'>
<circle cx="5" cy="4" r="4"/>
</svg>
Info: ${params.data.info || 0}</span>
                </div>`;
        else return null;
      }
    },

    legend: {
      show: chartData.length > 0,
      bottom: '6%',
      icon: 'circle'
    },

    dataset: {
      source: chartData,
      dimensions: ['date', 'critical', 'warning', 'info']
    },

    grid: {
      left: '2%',
      right: '2%',
      bottom: '25%',
      top: '15%'
    },

    xAxis: {
      //       max: chartData?.length > 0 ? chartData[chartData.length - 1] : new Date(),
      //       min: chartData?.length > 0 ? chartData[0] : subDays(new Date(), 30),
      type: 'time',
      axisLine: {
        lineStyle: {
          color: '#D3D3D3'
        }
      },
      splitLine: {
        show: true,
        lineStyle: {
          type: 'dashed'
        }
      },
      triggerEvent: true
    },
    yAxis: [
      {
        type: 'value',
        show: false,
        splitLine: {
          show: false,
          lineStyle: {
            type: 'dashed'
          }
        }
      },
      {
        type: 'value',
        show: false,
        splitLine: {
          show: false,
          lineStyle: {
            type: 'dashed'
          }
        }
      }
    ],

    series: [
      {
        name: 'critical',
        type: 'bar',
        barWidth: 15,
        encode: {
          x: 'date',
          y: 'critical' // refer sensor 1 value
        },
        stack: '1',
        color: '#ea3737',
        lineStyle: {
          width: 5
        },
        yAxisIndex: 0
      },
      {
        name: 'warning',
        type: 'bar',
        barWidth: 15,
        encode: {
          x: 'date',
          y: 'warning' // refer sensor 1 value
        },
        stack: '1',
        color: '#f59728',
        lineStyle: {
          width: 5
        },
        yAxisIndex: 0
      },

      {
        animation: false,
        name: 'info',
        type: 'line',
        lineStyle: {
          width: 4,
          type: 'dotted'
        },
        encode: {
          x: 'date',
          y: 'info' // refer sensor 1 value
        },
        stack: '1',
        color: '#dbdbdb',
        yAxisIndex: 1
      }
    ]
  };

  const { data, isLoading } = useQueryWrapper(
    ['events', 'category', { ...filters }],
    () => Event.getTrends(filters),
    {
      staleTime: 5 * (60 * 1000), // 10 mins
      cacheTime: 6 * (60 * 1000), // 15 mins
      refetchOnMount: false
    }
  );

  React.useEffect(() => {
    if (!data) return;

    const index = {};

    for (const d of data.info) index[d._id] = { date: d._id, info: d.count };

    for (const d of data.critical) {
      index[d._id] = index[d._id] ?? { date: d._id };
      index[d._id].critical = d.count;
    }
    for (const d of data.warning) {
      index[d._id] = index[d._id] ?? { date: d._id };
      index[d._id].warning = d.count;
    }

    const final = Object.values(index);

    final.sort((a, b) => new Date(a.date) - new Date(b.date));
    setChartData(final);
  }, [data]);

  const onEvents = {
    //     click: (e) => {
    //       if (e.targetType == "axisLabel" && e.componentType == "xAxis")
    //         setGlobalFilter((prev) => ({
    //           ...prev,
    //           to: addDays(new Date(e.value.date), 1).toISOString(),
    //           from: subDays(new Date(e.value.date), 1).toISOString(),
    //         }));
    //       if (e.componentType == "series")
    //         setGlobalFilter((prev) => ({
    //           ...prev,
    //           to: addDays(new Date(e.value.date), 1).toISOString(),
    //           from: subDays(new Date(e.value.date), 1).toISOString(),
    //         }));
    //     },
  };

  return (
    <article
      className="events-trend-chart"
      data-show={isVisible ? 'true' : 'false'}
      css={css`
        &[data-show='false'] {
          height: 0px !important;
          transition: height 300ms ease-out;
          border-bottom: none;

          opacity: 1;
          > * {
            display: none;
          }
        }

        &[data-show='true'] {
          min-height: 220px !important;
          transition: height 300ms ease-in;
          opacity: 1;
        }
        position: relative;

        background: var(--neutral-09);
        border-bottom: 1px solid var(--neutral-12);
      `}
    >
      {chartData.length == 0 && (
        <div
          css={css`
            position: absolute;
            top: 0;
            color: var(--neutral-03);
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: var(--neutral-09);
          `}
        >
          {isLoading ? <BarChartLoader /> : 'No Data Found'}
        </div>
      )}

      {chartData.length > 0 && isVisible && (
        <ReactECharts
          opts={{ renderer: 'svg' }}
          option={option}
          style={{ height: '100%' }}
          loadingOption={LOADING_OPTIONS}
          onEvents={onEvents}
        />
      )}
    </article>
  );
};

export default EventsTrendChart;
