import React from 'react';
import Template from 'base/template';

import Intercom from 'api/intercom';
import { columns } from 'views/intercoms/table-columns';
import Sidebar from 'views/intercoms/sidebar';
import IndexContent from 'base/index-content';
import ActionMenu from 'views/intercoms/action-menu';
import { Power } from 'react-bootstrap-icons';
import { useAuth } from 'providers/auth';
import { ReactComponent as IntercomEmptyIcon } from 'assets/empty-icons/empty-intercom.svg';
import { IconAlertTriangle } from '@tabler/icons-react';

const cards = [
  {
    name: 'total',
    label: 'Total'
  },
  {
    name: 'online',
    label: 'Online',
    color: 'var(--primary-base)',
    background: 'var(--stats-background-green)',
    icon: Power
  },
  {
    name: 'offline',
    label: 'Offline',
    color: 'var(--red-base)',
    background: 'var(--stats-background-red)',
    filter: { online: '0' },
    icon: Power
  },
  {
    name: 'alerts',
    label: 'Alerts',
    color: 'var(--red-base)',
    background: 'var(--stats-background-red)',
    filter: { alerts: '1' },
    icon: IconAlertTriangle
  }
];

const Intercoms = (props) => {
  const { user } = useAuth();
  return (
    <Template {...props}>
      <IndexContent
        initialFilter={{ site: user.focused_site }}
        statsQuery={Intercom.stats}
        title="intercoms"
        id="intercoms"
        cards={cards}
        query={Intercom.search}
        columns={columns}
        sidebar={Sidebar}
        actionButton={ActionMenu}
        emptyIcon={IntercomEmptyIcon}
        {...props}
      ></IndexContent>
    </Template>
  );
};

export default Intercoms;
