import React from 'react';
import { useHistory } from 'react-router-dom';
// import { useMutateReset, useMutatePing } from './actions';
import { useMutateRestoreEvents } from 'views/controllers//actions';

import { MenuItem, MenuWrapper } from 'components/action-menu';
import SyncModal from 'views/controllers/sync-modal';
import { useModal } from 'providers/modal-provider';
import { DialogDisclosure } from 'reakit/Dialog';
import { getDeviceProxyUrl } from 'api/api-url';
import IsCompany from 'components/is-company';
import useAlertDialog from 'providers/alert-dialog-provider';
export default function ActionMenu({ data: device, ...otherProps }) {
  const history = useHistory();
  const { dialog, setModalState } = useModal();

  // const mutateReset = useMutateReset(device);
  // const mutatePing = useMutatePing(device);
  const mutateRestoreEvents = useMutateRestoreEvents(device);
  const confirm = useAlertDialog();

  // const handleReset = async () => {
  //   if (
  //     await confirm({
  //       description: `Are you sure you want to reset device: ${device?.name} ? \nAll data will be lost.`
  //     })
  //   )
  //     mutateReset.mutate(device?.id);
  // };

  const handleRestoreEvents = async () => {
    if (
      await confirm({
        description: `Are you sure you want to restore Events on: ${device?.name} ?\n This might take a while`
      })
    )
      mutateRestoreEvents.mutate(device?.controller_id);
  };

  let handleDetails = () =>
    history.push('devices/details/' + device?.id, {
      device,
      desc: device?.name
    });

  let handleTools = () =>
    history.push('devices/tools/' + device?.id, {
      device,
      desc: device?.name
    });

  const handleEdit = () => {
    if (device.type != 'controller')
      history.push('/devices/save/' + device.id, {
        device,
        desc: device?.name
      });
    else if (device.type == 'controller')
      history.push('controllers/save/' + device.controller_id, {
        ac: {
          ...device,
          id: device.controller_id
        },
        desc: device?.name
      });
  };

  const handleProxy = () => {
    window.open(
      getDeviceProxyUrl(device.mac),
      // location.protocol + "//" + device.mac + "." + CUBE_API_HOST,
      '_blank'
    );
  };

  const handleConfigure = () => {
    history.push('/devices/setup/' + device.controller_id, {
      ac: {
        ...device,
        id: device.controller_id
      },
      desc: device?.name
    });
  };

  let handleMonitoring = () =>
    history.push('/devices/monitoring/' + device?.id, {
      device,
      desc: device?.name
    });

  const handleSettings = () => {
    history.push('/devices/settings/' + device?.id, {
      device,
      desc: device?.name
    });
  };

  return (
    <MenuWrapper {...otherProps}>
      {/* <MenuItem label="Ping" onClick={() => mutatePing.mutate(device.id)} />
      <MenuItem label="Reset" onClick={handleReset} /> */}
      <IsCompany>
        <MenuItem label="Edit" onClick={handleEdit} />
      </IsCompany>
      <MenuItem label="Details" onClick={handleDetails} />
      <MenuItem label="Tools" onClick={handleTools} />
      {device?.type == 'controller' && (
        <>
          <IsCompany>
            <MenuItem label="Configure" onClick={handleConfigure} />
          </IsCompany>
          <DialogDisclosure
            {...dialog}
            disabled={otherProps.disabled}
            as={MenuItem}
            onClick={() => {
              setModalState({
                component: SyncModal,
                data: {
                  ...device,
                  id: device.controller_id
                },
                dialogProps: { hideOnClickOutside: false, hideOnEsc: false }
              });
            }}
          >
            Sync Check...
          </DialogDisclosure>
          <MenuItem label="Restore Events" onClick={handleRestoreEvents} />
        </>
      )}
      {['camera', 'intercom'].includes(device?.type) && (
        <MenuItem
          disabled={device?.online == 0}
          label="Settings..."
          onClick={handleSettings}
        />
      )}
      <MenuItem label="Open UI..." onClick={handleProxy} />
      <MenuItem
        disabled={device?.device_metrics_enabled == 0}
        label="Monitoring"
        onClick={handleMonitoring}
      />
    </MenuWrapper>
  );
}
