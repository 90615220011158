/** @jsxImportSource @emotion/react */
import React from 'react';
import { Menu } from '@headlessui/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ThreeDots } from 'react-bootstrap-icons';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { DropDown } from 'components/dropdown';

const menuStyles = (props) => css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4px 0px;
  position: absolute;
  margin-top: 5px;
  background: var(--neutral-09);
  z-index: 20;

  border: 1px solid var(--neutral-12);
  box-sizing: border-box;
  /* LittleShadow */

  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.35);
  border-radius: 5px;
  width: fit-content;

  > [role='menuitem'] > button {
    font-size: ${props.small && '12px !important'};
    line-height: ${props.small && '14px !important'};
    padding: ${props.small && '8px 12px !important'};
  }
`;

const MenuItems = ({ children, ...otherProps }) => {
  return (
    <DropdownMenu.Content
      css={menuStyles(otherProps)}
      {...otherProps}
      align="end"
    >
      {children}
    </DropdownMenu.Content>
  );
};

export default MenuItems;
