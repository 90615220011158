import styled from "@emotion/styled";

export const FormFooter = styled("footer")`
  margin: 24px auto 0 auto;
  display: flex;
  width: 100%;
  gap: 8px;
  // position: absolute;
  // bottom: 0;
  // transform: translateY(calc(100% + 32px));
`;
