const MAP_TOKEN =
  'pk.eyJ1IjoiY3ViZS1hY2Nlc3MiLCJhIjoiY2s1eXFnbzFxMHZidjNvbmVpNDk5eTR2ZSJ9.eXFkXLbzDuawBEPuxHdcAA';
import * as React from 'react';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import 'react-leaflet-markercluster/dist/styles.min.css';
import { FeatureGroup, Marker, Popup, Tooltip, useMap } from 'react-leaflet';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */

import * as RadioGroup from '@radix-ui/react-radio-group';
import L from 'leaflet';
import { AspectRatio } from 'react-bootstrap-icons';

import { IconButton } from 'components/buttons';
const LeafIcon = L.DivIcon.extend({
  options: {}
});

function MarkerContainer({ setSelectedSite, sites }) {
  const markerLayerRef = React.useRef();

  function createSiteIcon(i) {
    let issue = i.offline_count > 0 || i.unsynced_count > 0;
    let color = issue ? 'var(--red-base)' : 'var(--primary-base)';
    return new LeafIcon({
      html: `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="${color}" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
      <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
    </svg>`,
      className: 'site-marker'
    });
  }

  const map = useMap();

  React.useEffect(() => {
    if (!map) return;
    // map.setMinZoom(4);
    // map.setMaxZoom(20);

    if (!markerLayerRef.current) return;

    // map.dragging.enable();'
    // map.setMaxZoom(22);
    // map.setMinZoom(4);

    const bounds = markerLayerRef.current.getBounds();
    if (bounds._southWest && bounds._northEast)
      sites.length > 0 && map.fitBounds(bounds, { padding: [20, 20] });

    // add the layer
  }, [map, sites, markerLayerRef]);

  const [filter, setFilter] = React.useState('all');

  // React.useEffect(() => {
  //   if (filter == 'offline')
  //     markerLayerRef.current.eachLayer((layer) => {
  //       if (layer.options?.properties?.offline_count == '0')
  //         map.removeLayer(layer);
  //       else layer.addTo(map);
  //     });
  //   else if (filter == 'online') {
  //     markerLayerRef.current.eachLayer((layer) => {
  //       if (layer.options?.properties?.offline_count > '0')
  //         map.removeLayer(layer);
  //       else layer.addTo(map);
  //     });
  //   } else {
  //     markerLayerRef.current.eachLayer((layer) => {
  //       layer.addTo(map);
  //     });
  //   }
  // }, [filter]);

  if (sites.length > 0)
    return (
      <FeatureGroup ref={markerLayerRef} key={filter}>
        <div className="leaflet-top leaflet-right">
          <div
            className="leaflet-control leaflet-bar"
            css={css`
              padding: 0.5rem;
              background: white;
              margin: 2rem;
            `}
          >
            <IconButton
              onClick={() => {
                let currentBounds = markerLayerRef.current.getBounds();

                if (currentBounds._southWest && currentBounds._northEast) {
                  markerLayerRef.current &&
                    map.fitBounds(markerLayerRef.current.getBounds(), {
                      padding: [10, 10]
                    });
                }
              }}
            >
              <AspectRatio size={16} />
            </IconButton>
          </div>
        </div>
        <div className="leaflet-top leaflet-left">
          <div
            className="leaflet-control leaflet-bar"
            css={css`
              padding: 0.5rem;
              background: white;
              margin: 2rem;
            `}
          >
            <article>
              <RadioGroup.Root
                value={filter}
                onValueChange={setFilter}
                css={css`
                  display: flex;
                  width: 250px;

                  border: 1px solid var(--neutral-09);
                  & > * {
                    border-radius: 4px;
                    flex: 1;
                    padding: 0.2rem;
                    span:first-of-type {
                      font-size: 1.5rem;
                      font-weight: bold;
                    }
                    span.description {
                      opacity: 70%;
                    }
                  }
                `}
              >
                <RadioGroup.Item
                  value="all"
                  css={css`
                    &[data-state='checked'] {
                      background: var(--neutral-05);
                    }
                  `}
                >
                  <div
                    css={css`
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                    `}
                  >
                    <span>{sites.length}</span>
                    <span className="description">All</span>
                  </div>
                </RadioGroup.Item>
                <RadioGroup.Item
                  value="offline"
                  css={css`
                    &[data-state='checked'] {
                      background: var(--red-v-02);
                      color: var(--red-base);
                    }
                  `}
                >
                  <div
                    css={css`
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                    `}
                  >
                    <span>
                      {sites.filter((s) => s.hasIssues == '1')?.length}
                    </span>
                    <span className="description">Offline</span>
                  </div>
                </RadioGroup.Item>
                <RadioGroup.Item
                  value="online"
                  css={css`
                    &[data-state='checked'] {
                      background: var(--primary-v-03);
                      color: var(--primary-base);
                    }
                  `}
                >
                  <div
                    css={css`
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                    `}
                  >
                    <span>
                      {sites.filter((s) => s.hasIssues == '0')?.length}
                    </span>
                    <span className="description">Online</span>
                  </div>
                </RadioGroup.Item>
              </RadioGroup.Root>
            </article>
          </div>
        </div>

        {sites.map((i, key) => {
          let show = false;

          if (filter == 'offline' && i.offline_count > 0) show = true;
          else if (filter == 'online' && i.offline_count == 0) show = true;
          else if (filter == 'all') show = true;

          if (show)
            return (
              <Marker
                key={key}
                eventHandlers={{
                  mouseover: (e) => {},
                  mouseout: (e) => {},
                  click: (e) => setSelectedSite(e.target.options.data)
                }}
                properties={i}
                data={i}
                icon={createSiteIcon(i)}
                position={[i.lat, i.lng]}
              >
                <Tooltip
                  className="fade-in"
                  direction="top"
                  offset={[-5, -5]}
                  opacity={1}
                  css={css`
                    min-width: 200px;
                    max-width: 300px;
                    border-radius: 4px;
                    font-size: 12px !important;
                    width: 250px;
                    > * {
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    }
                  `}
                >
                  <h3>{i.name}</h3>
                  <h4>{i.address}</h4>
                </Tooltip>
              </Marker>
            );
        })}
      </FeatureGroup>
    );
  else return null;
}

function SiteCluster({
  setSelectedSite,

  sites
}) {
  const createClusterCustomIcon = function (cluster) {
    const count = cluster.getChildCount();

    // figure out how many digits long the number is
    const digits = (count + '').length;

    return L.divIcon({
      html: count,
      className: 'cluster digits-' + digits,
      iconSize: null
    });
  };

  return (
    <>
      {sites && (
        <MarkerClusterGroup iconCreateFunction={createClusterCustomIcon}>
          <MarkerContainer sites={sites} setSelectedSite={setSelectedSite} />
        </MarkerClusterGroup>
      )}
    </>
  );
}

export default SiteCluster;
