import React from 'react';
/** @jsxImportSource @emotion/react **/
import ReactECharts from 'echarts-for-react';
export default ({
  isLoading,
  chartData,
  type = 'pie',
  COLORS = ['#6F49C0', '#F5861F', '#49B7BE', '#FFCC47', '#2480D6'],
  ...props
}) => {
  const option = {
    title: {
      show: chartData.length == 0 && type != 'pie',
      textStyle: {
        color: '#bcbcbc'
      },
      text: 'no available data',
      left: 'center',
      top: 'center'
    },
    animation: false,

    grid: {
      left: '10%',
      right: '10%',
      bottom: '15%',
      top: '30%'
    },
    tooltip: {
      trigger: 'item'
    },

    legend: {
      orient: 'horizontal',
      left: 10,
      padding: 5,
      bottom: 0,
      show: false
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: false
      },
      axisLabel: {
        show: false
      },
      axisLine: {
        show: false,
        lineStyle: {
          color: '#D3D3D3'
        }
      },
      splitLine: {
        show: true,
        interval: 8,
        lineStyle: {
          type: 'dashed'
        }
      }
    },
    xAxis: {
      show: false,
      type: 'category',
      data: chartData?.map((d) => d.name),
      axisLabel: {
        show: false
      },
      axisLine: {
        lineStyle: {
          color: '#D3D3D3'
        }
      }
    },

    series: [
      {
        silent: true,
        barWidth: 15,
        data: chartData,
        radius: '90%',

        name: 'Active Companies',
        type: type,

        label: { show: false },
        itemStyle: {
          color: (params) => {
            return COLORS[params.dataIndex];
          },

          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          },

          barBorderRadius: [2, 2, 0, 0], // Specify the border radius
          borderType: 'solid'
        }
      }
    ],
    ...props.config
  };

  return (
    <ReactECharts
      opts={{ renderer: 'svg' }}
      option={option}
      showLoading={isLoading}
      loadingOption={{
        showSpinner: false,
        maskColor: 'var(--neutral-09)',
        fontWeight: 'normal',
        text: 'Fetching Data...',

        fontStyle: 'normal',

        fontFamily: 'inherit'
      }}
      style={{
        width: '100%',
        padding: 0,
        height: '200px',
        background: 'var(--neutral-09)'
      }}
    />
  );
};
