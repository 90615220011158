import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import * as Popover from '@radix-ui/react-popover';
import { ThreeDots } from 'react-bootstrap-icons';
import { Dash, Plus } from 'react-bootstrap-icons';
import { IconButton } from 'components/buttons';
import { Container } from './grid-controls';
import { AuxiliaryButton } from 'components/buttons';
export default ({
  isEdit,

  index,
  widgetIndex,
  selectedView,
  setSelectedView,
  limitRows = false,
  limitCols = false
}) => {
  if (!isEdit) return null;

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <IconButton>
          <ThreeDots />
        </IconButton>
      </Popover.Trigger>
      <Popover.Anchor />
      <Popover.Portal>
        <Popover.Content
          css={css`
            background: white;
            z-index: 5000;
          `}
        >
          <div
            css={css`
              padding: 1rem;
              display: flex;
              align-items: center;
              gap: 0.5rem;
            `}
          >
            {!limitRows && (
              <Container>
                <AuxiliaryButton
                  compact
                  onClick={() => {
                    const newLayout = [...selectedView.layout];
                    let rows = newLayout[index].items[widgetIndex].rows ?? 1;
                    rows--;
                    if (rows < 1) return;

                    newLayout[index].items[widgetIndex].rows = rows;

                    setSelectedView((prev) => ({
                      ...prev,
                      layout: newLayout
                    }));
                  }}
                >
                  <Dash />
                </AuxiliaryButton>
                <label>
                  ROWS
                  <span>
                    {selectedView.layout[index].items[widgetIndex].rows ?? 1}
                  </span>
                </label>

                <AuxiliaryButton
                  compact
                  onClick={() => {
                    const newLayout = [...selectedView.layout];

                    let rows = newLayout[index].items[widgetIndex].rows ?? 1;
                    rows++;
                    if (rows > newLayout[index].rows) return;

                    newLayout[index].items[widgetIndex].rows = rows;

                    setSelectedView((prev) => ({
                      ...prev,
                      layout: newLayout
                    }));
                  }}
                >
                  <Plus />
                </AuxiliaryButton>
              </Container>
            )}
            <Container>
              <AuxiliaryButton
                compact
                onClick={() => {
                  const newLayout = [...selectedView.layout];
                  let cols = newLayout[index].items[widgetIndex].cols ?? 1;
                  cols--;
                  if (cols < 1) return;

                  newLayout[index].items[widgetIndex].cols = cols;
                  setSelectedView((prev) => ({
                    ...prev,
                    layout: newLayout
                  }));
                }}
              >
                <Dash />
              </AuxiliaryButton>
              <label>
                COLS
                {selectedView.layout[index].items[widgetIndex].cols ?? 1}
              </label>

              <AuxiliaryButton
                compact
                onClick={() => {
                  const newLayout = [...selectedView.layout];
                  let cols = newLayout[index].items[widgetIndex].cols ?? 1;
                  cols++;
                  if (cols > newLayout[index].cols) return;

                  newLayout[index].items[widgetIndex].cols = cols;

                  setSelectedView((prev) => ({
                    ...prev,
                    layout: newLayout
                  }));
                }}
              >
                <Plus />
              </AuxiliaryButton>
            </Container>
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};
