import React from 'react';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import { useHeadlessTable } from 'providers/headless-table-provider';
import {
  ChevronLeft,
  ChevronRight,
  SortAlphaDown,
  SortDown,
  SortUpAlt,
  X
} from 'react-bootstrap-icons';
import { IconButton } from './buttons';
import Select, { components } from 'react-select';
const styles = css`
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  padding: 16px;
  display: flex;
  align-items: center;
  background: var(--neutral-09);
  z-index: 4;
  gap: 16px;
  border-radius: 0 0 6px 6px;
  //border-bottom: 1px solid var(--neutral-12);

  .controls {
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-areas: 'stack';
    & > * {
      grid-area: stack;
    }
    gap: 16px;
    > button {
      color: var(--neutral-00);
    }
    > button:disabled {
      opacity: 60%;
    }
  }
`;

export function pagination(current, total) {
  const center = [current - 2, current - 1, current, current + 1, current + 2],
    filteredCenter = center.filter((p) => p > 1 && p < total),
    includeThreeLeft = current === 5,
    includeThreeRight = current === total - 4,
    includeLeftDots = current > 5,
    includeRightDots = current < total - 4;

  if (total <= 1) return [1];

  if (includeThreeLeft) filteredCenter.unshift(2);
  if (includeThreeRight) filteredCenter.push(total - 1);

  if (includeLeftDots) filteredCenter.unshift('...');
  if (includeRightDots) filteredCenter.push('...');

  return [1, ...filteredCenter, total];
}

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SortDown title="Sort By..." size={20} color={'var(--secundary-base'} />
    </components.DropdownIndicator>
  );
};

const TablePagination = (props) => {
  const {
    gotoPage,
    previousPage,
    nextPage,
    state,
    data,
    page,
    tableDispatch,
    tableState,
    setGlobalFilter
  } = useHeadlessTable();

  const sortingOptions = Object.entries(props.sorting ?? {}).map(([k, v]) => ({
    value: k,
    label: v
  }));
  const [sorting, setSorting] = React.useState(sortingOptions[0]);

  return (
    <article css={styles}>
      <section className="controls">
        <section
          css={css`
            font-size: 12px;
            color: var(--neutral-02);
            justify: start;
            &:hover {
              color: var(--secudary-01);
            }
            > button {
              width: max-content;
              display: flex;
              align-items: center;
              gap: 0.1rem;
            }
          `}
        >
          {props.multi && Object.keys(state.selectedRowIds).length > 0 && (
            <button onClick={() => tableDispatch({ type: 'deselectAllRows' })}>
              <X size={18} />
              {Object.keys(state.selectedRowIds).length} selected
            </button>
          )}
        </section>
        {/* {props.sorting && (
          <article
            className="sorting"
            css={css`
              place-self: end;
            `}
          >
            <Select
              placeholder="Sort By..."
              isSearchable={false}
              value={sorting}
              className="select__container"
              classNamePrefix="select"
              css={css`
                .select__option--is-selected {
                  background: var(--secundary-v-02);
                  color: var(--neutral-00);
                }
                .select__option--is-focused {
                  background: var(--menu-hover-background-color);
                }

                .select__option:hover {
                  background: var(--menu-hover-background-color);
                }
                .select__control {
                  background: var(--neutral-09);
                  border: 1px solid var(--neutral-05);
                }
                width: 200px;
                display: inline-block;
              `}
              components={{ IndicatorSeparator: () => null, DropdownIndicator }}
              onChange={(item) => {
                setSorting(item);
                setGlobalFilter((prev) => ({ ...prev, sort: item.value }));
              }}
              options={sortingOptions}
            />
          </article>
        )} */}
        <article
          className="pagination"
          css={css`
            display: flex;
            gap: 1rem;
            place-self: center;

            align-items: center;
            margin: auto;
          `}
        >
          <IconButton
            data-compact
            onClick={() => previousPage()}
            disabled={state.pageIndex == 1}
          >
            <ChevronLeft />
          </IconButton>
          {tableState.queryLastPage > 0 &&
            pagination(state.pageIndex, tableState.queryLastPage).map((x) => (
              <button
                key={x}
                data-page={x}
                onClick={() => gotoPage(x)}
                css={css`
                  padding: 0.2rem;
                  &[data-page='${state.pageIndex}'] {
                    color: var(--primary-base) !important;

                    font-weight: bold;
                  }
                `}
              >
                {x}
              </button>
            ))}

          <IconButton
            data-compact
            onClick={() => nextPage()}
            disabled={state.pageIndex == data?.last_page}
          >
            <ChevronRight />
          </IconButton>
        </article>
      </section>
    </article>
  );
};

export default TablePagination;
