import React from 'react';

import SelectWrapper from 'components/headless-select';
const RECURRENCES = [
  { label: 'One-Time', value: '1' },
  { label: 'Daily', value: 'd' },
  { label: 'Weekly', value: 'w' },
  { label: 'Monthly', value: 'm' }
];
const RecurrenceSelect = ({ form, field, ...props }) => {
  const { values, setFieldValue } = form;

  return (
    <SelectWrapper
      simple
      title="Recurrence"
      idx="value"
      label="label"
      isMulti={false}
      options={RECURRENCES}
      isSearchable={false}
      isClearable={false}
      value={RECURRENCES.find((r) => r.value == values.recurrence)}
      onChange={(e) => {
        setFieldValue('recurrence', e.value);
      }}
      {...props}
    />
  );
};

export default RecurrenceSelect;
