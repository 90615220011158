import React from 'react';
import { css } from '@emotion/react';
import { CircleIcon } from 'components/circle-icon';
import { ClockHistory, Snow } from 'react-bootstrap-icons';

/** @jsxImportSource @emotion/react */

const DoorNameFormatter = (props) => {
  const { door } = props;
  if (!door) return null;
  return (
    <article
      className="door-name-formatter"
      css={css`
        display: flex;
        gap: 8px;
        align-items: center;

        padding: 8px 0px;
        span.desc {
          color: #7f8795;
          font-size: 12px;
        }
        span.site {
          color: #267dff;
        }
        span.name {
          font-weight: 500;

          font-size: 14px;
        }

        svg {
          width: 16px;
          height: 16px;
          margin: 0 0.2rem;
        }
      `}
      {...props}
    >
      <section
        css={css`
          display: flex;
          flex-direction: column;
        `}
      >
        <span className="name">{door.name}</span>
        <span className="desc">{door.description}</span>
      </section>
    </article>
  );
};

export default DoorNameFormatter;
