import React from 'react';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import { Field, Form, useFormikContext, FieldArray } from 'formik';

import AsyncSelect from 'components/headless-select/async';
import GenericDoor from 'api/generic_door';
import Select from 'components/headless-select';
import Speaker from 'api/speaker';
import AudioClip from 'api/audio-clip';

export const DOOR_ACTIONS = [
  { value: 'lockDoor', name: 'Lock', id: 'lockDoor' },
  { value: 'accessDoor', name: 'Access', id: 'accessDoor' },
  { value: 'unlockDoor', name: 'Unlock', id: 'unLockDoor' },
  { value: 'lockDownDoorRelease', name: 'Release', id: 'lockDownDoorRelease' },
  { value: 'lockDownDoor', name: 'Lock Down', id: 'lockDownDoor' }
];

const PlayAudioClip = ({ action }) => {
  const { setFieldValue } = useFormikContext();

  return (
    <article>
      <Field
        data-compact
        title="Speaker"
        name={`device_id`}
        idx={'device_id'}
        component={AsyncSelect}
        closeMenuOnSelect={true}
        fetchFn={Speaker.search}
      />
      <Field
        data-compact
        title="Audio Clip"
        name={`audio_clip_id`}
        component={AsyncSelect}
        closeMenuOnSelect={true}
        fetchFn={AudioClip.search}
      />
    </article>
  );
};

export default PlayAudioClip;
