import React from 'react';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { BarChart, XLg } from 'react-bootstrap-icons';
import { useLayout } from 'providers/layout-provider';
import { useEventSource } from 'react-use-websocket';
import { useAuth } from 'providers/auth';
import IsUserAllowed from 'components/is-user-allowed';
import { IconAlertTriangle, IconTriangle } from '@tabler/icons-react';
import { useHotkeys } from 'react-hotkeys-hook';
const VideoStats = ({ pipeline, videoEl, host }) => {
  const TTFB_TS_MS = 2000;
  const [videoStats, setVideoStats] = React.useState();
  const updateValues = React.useCallback(() => {
    if (!pipeline) return;
    const tracks = pipeline.tracks?.map((track, index) =>
      Object.assign({ index }, track)
    );
    const videoTrack = tracks?.find((track) => track.type === 'video');
    if (videoTrack !== undefined) {
      const quality = videoEl.getVideoPlaybackQuality();

      const dropPercent =
        (quality.droppedVideoFrames / quality.totalVideoFrames) * 100;
      const { coding, profile, level } = videoTrack?.codec;
      const framerate = Number(pipeline.framerate[videoTrack.index].toFixed(2));
      const bitrate = Math.round(pipeline.bitrate[videoTrack.index] / 1000);
      setVideoStats({
        bitrate,
        framerate,
        droppedVideoFrames: quality.droppedVideoFrames,
        totalVideoFrames: quality.totalVideoFrames,
        dropPercent
      });
    }
  }, [pipeline]);

  const [isVisible, setIsVisible] = React.useState();
  const [metrics, setMetrics] = React.useState({});

  let { getSocketUrl } = useLayout();
  const { user } = useAuth();
  let filterMetrics = React.useCallback((data) => {
    if (
      ['deviceMetrics'].includes(data?.type) &&
      data.device_id == host.device_id
    )
      return true;
    return false;
  }, []);
  const { lastEvent: lastEventMetrics } = useEventSource(
    getSocketUrl,
    {
      share: true,
      filter: (e) => filterMetrics(JSON.parse(e.data)),
      withCredentials: true,
      retryOnError: true
    },
    Boolean(user && !user.missing2fa && !user.missingMfa)
  );

  React.useEffect(() => {
    if (!lastEventMetrics) return;
    let data = JSON.parse(lastEventMetrics.data);
    setMetrics(data);
  }, [lastEventMetrics]);

  /** Feed Stats */
  const [feedStats, setFeedStats] = React.useState({});

  let cloud_action_rule =
    typeof host.cloud_action_rule == 'string'
      ? JSON.parse(host.cloud_action_rule)?.[0]
      : host.cloud_action_rule?.[0];

  let filterFeedStats = React.useCallback((data) => {
    if (
      ['feedUpdate'].includes(data?.type) &&
      data.action_rule_id == cloud_action_rule
    )
      return true;
    return false;
  }, []);
  const { lastEvent: lastEventFeedStats } = useEventSource(
    getSocketUrl,
    {
      share: true,
      filter: (e) => filterFeedStats(JSON.parse(e.data)),
      withCredentials: true,
      retryOnError: true
    },
    Boolean(user && !user.missing2fa && !user.missingMfa)
  );

  React.useEffect(() => {
    if (!lastEventFeedStats) return;
    let data = JSON.parse(lastEventFeedStats.data);
    setFeedStats(data);
  }, [lastEventFeedStats]);

  React.useEffect(() => {
    // in case nothing comes in from the SSE reset data

    let timer = setInterval(() => {}, 10000);
    setFeedStats({});

    return () => clearInterval(timer);
  }, []);

  React.useEffect(() => {
    setMetrics({});
    setFeedStats({});
    const interval = setInterval(() => {
      updateValues();
    }, 1000);
    return () => clearInterval(interval);
  }, [pipeline]);

  useHotkeys('alt+a', () => setIsVisible((prev) => !prev));

  return (
    <div
      css={css`
        position: relative;
        z-index: 1001;
        padding: 16px 24px 24px;
        color: white;
        margin: 1rem;
        padding: 0.3rem;
        border-radius: 4px;
        width: fit-content;

        background: rgb(41, 41, 41) no-repeat padding-box;
        opacity: 0.88;
        dl {
          display: grid;
          grid-template-columns: 50% 50%;
          grid-gap: 0.5rem 1.5rem;
          min-width: 250px;
        }

        dd {
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }

        dd,
        .desc {
          font-size: 12px;
          line-height: 17px;
          color: rgb(184, 184, 184);
        }

        dd[data-warning='true'],
        dt[data-warning='true'] {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          color: var(--dark--others--red-500);
          font-weight: 500;
        }
        h4 {
          font-weight: 400;
        }
      `}
    >
      <button onClick={() => setIsVisible((prev) => !prev)}>
        {isVisible ? <XLg size={18} /> : <BarChart size={20} />}
      </button>
      {isVisible && (
        <>
          <dl>
            <dd data-warning={`${pipeline?.timeToFirstByte > TTFB_TS_MS}`}>
              {pipeline?.timeToFirstByte > TTFB_TS_MS && <IconAlertTriangle />}
              TTFB:
            </dd>
            <dt data-warning={`${pipeline?.timeToFirstByte > TTFB_TS_MS}`}>
              {pipeline?.timeToFirstByte} <span className="desc">ms</span>
            </dt>
            <dd>Bitrate:</dd>
            <dt>
              {videoStats?.bitrate} <span className="desc">Kb /s</span>
            </dt>
            <dd>Framerate:</dd>
            <dt> {videoStats?.framerate}</dt>
            <dd>Dropped Frames:</dd>
            <dt> {videoStats?.droppedVideoFrames}</dt>
            <dd>Total Frames:</dd>
            <dt> {videoStats?.totalVideoFrames}</dt>
          </dl>
          {['A', 'V', 'I'].includes(user?.role) && (
            <>
              <hr />
              <dl>
                <dd>CPU Usage:</dd>
                <dt> {metrics?.cpu_usage}</dt>
                <dd>Active TCP:</dd>
                <dt> {metrics?.conns}</dt>
                <dd>Free Memory:</dd>
                <dt>
                  {metrics?.mem_free} <span className="desc">KB</span>
                </dt>
              </dl>

              {cloud_action_rule && (
                <>
                  <hr />
                  <h4>Cloud Feed</h4>
                  <dl>
                    <dd>B/s:</dd>
                    <dt>
                      {feedStats?.bytesReceived / 5}
                      <span className="desc"> bp /s</span>
                    </dt>
                    <dd>FPS:</dd>
                    <dt> {feedStats?.frames / 5}</dt>
                    <dd>Speed:</dd>
                    <dt> {feedStats?.speed}</dt>
                  </dl>
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default VideoStats;
