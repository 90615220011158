import styled from '@emotion/styled';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */

export const Container = styled('div')`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 0rem;
  overflow: auto;
  justify-content: start;
  border: ${({ isEdit }) =>
    isEdit ? '4px dashed var(--secundary-base)' : 'none'};
  max-height: ${({ bar }) => (bar ? '300px' : 'auto')};
  ${(props) =>
    props.isEdit &&
    css`
      max-height: fit-content;
      height: fit-content;
    `}
`;
