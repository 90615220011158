import React from 'react';
import { useHistory } from 'react-router-dom';

import { MenuItem, MenuItemDelete, MenuWrapper } from 'components/action-menu';
import {
  useDeleteMutate,
  useSendActivationMutate,
  useActivateMutate,
  useEndSessionMutate
} from './actions';
import { useAuth } from 'providers/auth';
import IsRole from 'components/is-role';
import useAlertDialog from 'providers/alert-dialog-provider';

export default function ActionMenu({ data: user, ...otherProps }) {
  const history = useHistory();
  const { impersonate, user: currentUser } = useAuth();

  const mutateDelete = useDeleteMutate(user);
  const mutateSendActivation = useSendActivationMutate(user);
  const mutateActivate = useActivateMutate(user);
  const mutateEndSession = useEndSessionMutate(user);

  const confirm = useAlertDialog();

  let handleEdit = () =>
    history.push('/accounts/edit/' + user?.id, {
      user,
      desc: user?.name
    });

  const handleDelete = async () => {
    if (
      await confirm({
        description: 'Are u sure you want to delete this user ?'
      })
    )
      mutateDelete.mutate(user?.id);
  };
  const handleSendActivation = () => mutateSendActivation.mutate(user?.id);
  const handleImpersonate = () => impersonate.mutate(user?.id);
  const handleActivate = () => mutateActivate.mutate(user.id);
  const handleEndSession = () => {
    if (
      confirm(
        'Ending user session will terminate any current user work, continue ?'
      )
    )
      mutateEndSession.mutate(user.id);
  };

  return (
    <MenuWrapper {...otherProps}>
      {currentUser.role == 'A' ||
        (currentUser.impersonator && (
          <MenuItem label="Impersonate" onClick={handleImpersonate} />
        ))}
      <MenuItem label="Edit" onClick={handleEdit} />
      <MenuItem
        disabled={user?.active == '1'}
        label="Re-send Activation Link"
        onClick={handleSendActivation}
      />
      <MenuItem
        disabled={user?.active == '1'}
        label="Activate User"
        onClick={handleActivate}
      />
      <MenuItem
        disabled={!user?.loggedIn}
        label="End Session"
        onClick={handleEndSession}
      />
      <MenuItemDelete onClick={handleDelete} />
    </MenuWrapper>
  );
}
