import React from 'react';
import MainMenu from './menu.js';
import TopBar from './topbar.js';
import styled from '@emotion/styled';
/** @jsxImportSource @emotion/react */

const Wrapper = styled(`div`)`
  display: grid;
  grid-template:
    'top-bar top-bar top-bar' 64px
    'menu content side' calc(100vh - 64px) / auto 1fr auto;
  max-height: 100vh;
  height: 100vh;

  @media screen and (max-width: 768px) {
    #sidebar {
      display: none; /* Hide the sidebar on small screens */
    }
  }
`;

const Template = ({ title, ...props }) => {
  document.title = props?.name ?? '';

  return (
    <Wrapper {...props}>
      <TopBar backButton={null} {...props} />
      <MainMenu {...props} />

      {props.children}
    </Wrapper>
  );
};

export default Template;
