import React from "react";

import { TextField, PasswordField, Heading } from "components/form";
import { useFormikContext } from "formik";
import { Field } from "formik";
import AsyncSelect from "components/headless-select/async";
import GenericDoor from "api/generic_door";

const CameraForm = (props) => {
  const { values } = useFormikContext();

  return (
    <section>
      <Field name="name" as={TextField}></Field>
      <Field
        title="Door Association"
        name="generic_door_id"
        component={AsyncSelect}
        fetchFn={GenericDoor.search}
      />
    </section>
  );
};

export default CameraForm;
