import React from 'react';

/** @jsxImportSource @emotion/react */
import {
  TabItem,
  TabGroup,
  TabPanel,
  TabPanels,
  TabList
} from 'components/tabs';
import { css } from '@emotion/react';
import { Calendar, CameraVideo } from 'react-bootstrap-icons';

import UsageChartHolder from 'components/charts/usage-chart-holder';
import LiveEvents from 'components/live-events';

import LivePlayer from 'components/video/live-player';
import IntercomEventList from './intercom-event-list';

const IntercomTabs = ({ intercom = {} }) => {
  let filter = React.useCallback(
    (data) => {
      if (data?.intercom_id == intercom.id && data.category == 'intercom')
        return true;
      return false;
    },
    [intercom]
  );

  return (
    <TabGroup>
      <TabList
        css={css`
          width: 100%;
          box-shadow: unset;
          padding: 0px 16px;
          gap: 2rem;
          border-top: 1px solid var(--neutral-12);
          border-bottom: 1px solid var(--neutral-12);
          [role='tab'] {
            min-width: unset;
          }
        `}
      >
        <TabItem title="Events">
          <Calendar size={24} />
        </TabItem>
        <TabItem title="Live Events">
          <CameraVideo size={30} />
        </TabItem>
      </TabList>
      <TabPanels>
        <TabPanel
          as="div"
          css={css`
            overflow: hidden;
            height: 100%;
            display: flex;
            flex-direction: column;
            padding: 16px;
          `}
        >
          <IntercomEventList intercom={intercom} />
        </TabPanel>
        <TabPanel
          css={css`
            width: 100%;
            display: grid;
            padding: 1rem;
            grid-template-rows: 300px auto;
            height: 100% !important;
            flex-direction: column;
            overflow-y: hidden;
            grid-gap: 0.8rem;
          `}
        >
          <LivePlayer
            host={{
              id: intercom.camera_id,
              ip: intercom.ip,
              intercom_id: intercom.id,
              online: intercom.online,
              device_id: intercom.device_id,
              generic_door_id: intercom.generic_door_id,
              intercom_relay_door_id: intercom.intercom_relay_door_id
            }}
          />
          <LiveEvents filter={filter} />
        </TabPanel>
      </TabPanels>
    </TabGroup>
  );
};

export default IntercomTabs;
