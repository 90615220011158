import React from 'react';
import { useQueryWrapper } from 'utils/ajax';
import Metric from 'api/metric';

import { css } from '@emotion/react';
import ReactECharts from 'echarts-for-react';

/** @jsxImportSource @emotion/react */
export default (props) => {
  let { camera, filter = {} } = props;

  const { data, isLoading } = useQueryWrapper(
    ['camera', camera.id, 'hour', filter],
    () =>
      Metric.count({
        camera_id: camera.id,
        lpr: true,
        from: filter?.from,
        to: filter?.to,
        groupBy: '$hour'
      })
  );

  const [chartData, setChartData] = React.useState([]);
  const COLORS = ['#6F49C0', 'F5861F', '#49B7BE', '#FFCC47', '#2480D6'];
  const HOURS = [
    '12AM',
    '1AM',
    '2AM',
    '3AM',
    '4AM',
    '5AM',
    '6AM',
    '7AM',
    '8AM',
    '9AM',
    '10AM',
    '11AM',
    '12PM',
    '1PM',
    '2PM',
    '3PM',
    '4PM',
    '5PM',
    '6PM',
    '7PM',
    '8PM',
    '9PM',
    '10PM',
    '11PM'
  ];

  React.useEffect(() => {
    if (!data) return;
    let d = [];

    for (let h = 0; h < 24; h++) {
      let m = data.find((d) => d._id == h);
      d.push({
        day: h,
        value: m?.count ?? 0
      });
    }

    setChartData(d);
  }, [data]);

  const option = {
    animation: false,
    grid: {
      left: '5%',
      right: '5%',
      bottom: '20%',
      top: '10%'
    },
    tooltip: {
      trigger: 'axis',
      confine: true,
      formatter:
        "<div class='lpr-tooltip'><span class='axis'>{b0}</span><br/><span class='data'>{c0}</span><label>Occurences</label>"
    },

    legend: {
      orient: 'horizontal',
      left: 10,
      padding: 5,
      bottom: 0,
      show: false
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: false
      },
      axisLabel: {
        show: false
      },
      axisLine: {
        show: false,
        lineStyle: {
          color: '#D3D3D3'
        }
      }
    },
    xAxis: {
      axisTick: {
        show: false
      },
      emphasis: {
        disabled: true
      },
      type: 'category',
      data: HOURS,
      axisLabel: {
        showMaxLabel: true,
        showMinLabel: true,
        show: true,
        color: 'var(--neutral-01)',
        fontSize: 10
      },
      axisLine: {
        show: false
      }
    },

    series: [
      {
        emphasis: {
          disabled: true
        },
        data: chartData,
        barWidth: 25,
        itemStyle: { emphasis: false, color: 'var(--secundary-base)' },
        type: 'bar',
        areaStyle: {},

        label: { show: false }
      }
    ],
    ...props.config
  };

  return (
    <ReactECharts
      css={css`
        .lpr-tooltip {
          display: flex;
          flex-direction: column;
          gap: 0.2rem;
          font-size: 12px;
          label {
            font-size: 11px;
          }
          .axis {
            font-weight: 500;
          }
          .data {
            font-size: 25px;
            font-weight: bold;
          }

          margin-top: 0.2rem;
          font-size: 12px;
        }
      `}
      opts={{ renderer: 'svg' }}
      loadingOption={{
        showSpinner: false,
        maskColor: 'var(--neutral-09)',
        fontWeight: 'normal',
        text: 'Fetching Data...',

        fontStyle: 'normal',

        fontFamily: 'inherit'
      }}
      showLoading={isLoading}
      option={option}
      style={{
        width: '100%',
        padding: 0,
        height: '100%',
        background: 'var(--neutral-09)'
      }}
    />
  );
};
