import * as Tooltip from '@radix-ui/react-tooltip';
import React from 'react';
import { css } from '@emotion/react';
import { dateTimeDif, dateTimeLocal } from 'utils/time';
import { Badge } from 'components/badge';
import { getSelectionColumn } from 'components/table-selection-column';
import StatusProgressBar from 'views/controllers/status-progress-bar';
import Clipboard from 'utils/clipboard';
import DeviceStatusFormatter from './device-status-formatter';

import IssuesTooltip from './issues-tooltip';
import { QuestionCircle } from 'react-bootstrap-icons';
/** @jsxImportSource @emotion/react */
import { useAuth } from 'providers/auth';
import { useHistory } from 'react-router-dom';
import DeviceAlertsTableIndicator from 'components/device-alerts-table-indicator';
import ActivityChartFormatter from 'components/activity-chart-formatter';
import { IconHelpCircle } from '@tabler/icons-react';
import CpuUsgaeChart from 'components/charts/cpu-usgae-chart';

function ActivityHeader() {
  return (
    <Tooltip.Provider>
      <Tooltip.Root>
        <Tooltip.Trigger asChild={true}>
          <span
            css={css`
              display: inline-flex;
              gap: 0.4rem;
              align-items: center;
            `}
          >
            <label>24 Hr Health</label>
            <IconHelpCircle size={22} />
          </span>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content asChild={true}>
            <div
              css={css`
                z-index: 200;
                display: flex;

                padding: 0.5rem;

                background: var(--neutral-09);
                box-shadow: 0px 2px 6px 4px rgba(29, 32, 35, 0.4);
                border-radius: 4px;
              `}
            >
              Last 24 hour device health.
              <br />
              Hours with offline events are marked in red.
              <Tooltip.Arrow
                css={css`
                  fill: var(--neutral-09);
                `}
              />
            </div>
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}

export function CpuUsageHeader() {
  return (
    <Tooltip.Provider>
      <Tooltip.Root>
        <Tooltip.Trigger asChild={true}>
          <span
            css={css`
              display: inline-flex;
              gap: 0.4rem;
              align-items: center;
            `}
          >
            <label>CPU Usage</label>
            <IconHelpCircle size={22} />
          </span>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content asChild={true}>
            <div
              css={css`
                z-index: 200;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                padding: 0.5rem;

                background: var(--neutral-09);
                box-shadow: 0px 2px 6px 4px rgba(29, 32, 35, 0.4);
                border-radius: 4px;
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                `}
              >
                Last 60 minutes of CPU usage
                <br />
                Number indicates average.
              </div>
              <Tooltip.Arrow
                css={css`
                  fill: var(--neutral-09);
                `}
              />
            </div>
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}

import DeviceFirmwareIndicator from 'components/device-firmware-indicator';
import Activity24HoursFormatter from './activity-24-hours-formatter';
import Skeleton from 'react-loading-skeleton';
import { getRowNumberColumn } from 'components/table-rownumber-column';

function LastOnlineTimeHeader() {
  return (
    <Tooltip.Provider>
      <Tooltip.Root>
        <Tooltip.Trigger asChild={true}>
          <span
            css={css`
              display: inline-flex;
              gap: 0.4rem;
            `}
          >
            <label>Online Time</label>
            <QuestionCircle size={16} />
          </span>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content asChild={true}>
            <div
              css={css`
                z-index: 200;
                display: flex;

                padding: 0.5rem;

                background: var(--neutral-09);
                box-shadow: 0px 2px 6px 4px rgba(29, 32, 35, 0.4);
                border-radius: 4px;
              `}
            >
              Online time since last restore
              <Tooltip.Arrow
                css={css`
                  fill: var(--neutral-09);
                `}
              />
            </div>
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}
export const columns = [
  getSelectionColumn(),
  {
    Header: 'ID',
    accessor: 'id',
    style: { width: '80px' },
    disableSortBy: true
  },

  {
    Header: 'Name',
    accessor: 'name',
    style: { width: '200px' },
    pickerDisabled: true,
    asc: 'atoz',
    desc: 'ztoa',
    newSorter: true,
    Cell: ({ row: { original } }) => {
      return (
        <div
          css={css`
            display: grid;
            grid-template-columns: auto auto 1fr;
            column-gap: 0.4rem;
            svg {
              grid-row: span 2;
              place-self: center;
              color: var(--light--others--red-400-base);
            }
            > small {
              grid-column: 1;
            }
          `}
        >
          <DeviceFirmwareIndicator device={original} />
          <DeviceAlertsTableIndicator device={original} />

          <div
            css={css`
              display: flex;
              flex-direction: column;
            `}
          >
            <strong
              css={css`
                display: inline-block;
                margin-bottom: 6px;
              `}
            >
              {original.name}
            </strong>
            <small>{original.site_name}</small>
          </div>
        </div>
      );
    }
  },

  {
    Header: 'Type',
    accessor: 'type',
    disableSortBy: true,
    Cell: ({ row: { original } }) => {
      function getControllerType() {
        if (original.doors == 1 || original.doors == 2)
          return <>{original.doors} doors</>;
        else if (original.doors == 3) return 'elevator';
        else if (original.doors == 0) return 'aperio';
      }
      if (original.type == 'controller') {
        return <strong>controller &bull; {getControllerType()}</strong>;
      }

      return <strong>{original.type}</strong>;
    }
  },
  {
    Header: 'Issues',
    role: ['A'],
    id: 'issues',
    disableSortBy: true,
    Cell: ({ row: { original } }) => {
      return <IssuesTooltip device={original} />;
    }
  },
  {
    Header: 'Status',
    accessor: 'online',
    pickerDisabled: true,
    style: { minWidth: '200px' },
    label: 'Status',
    disableSortBy: true,
    Cell: ({ row }) => <DeviceStatusFormatter device={row.original} />
  },
  {
    disableManaging: true,
    Header: 'Company',
    accessor: 'company_name',
    disableSortBy: true,
    style: { width: '250px' },
    Cell: ({ row: { original }, value }) => {
      const { companyManage, user } = useAuth();
      const history = useHistory();

      const handleManage = (e) => {
        e.stopPropagation();
        companyManage.mutate(original.company_id, {
          onSuccess: () => history.push('/devices')
        });
      };

      return (
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <Badge
            onClick={handleManage}
            css={css`
              text-transform: uppercase;
              &:hover {
                text-decoration: underline !important;
              }
              padding: 0;
              background: none;
            `}
            color="blue"
            className="company-name"
          >
            {value}
          </Badge>
          {user?.role != 'I' && original.installer_name && (
            <small>{original.installer_name}</small>
          )}
        </div>
      );
    }
  },
  {
    Header: 'Setup',
    accessor: 'setup',
    disableSortBy: true,
    style: { minWidth: '200px' },
    Cell: ({ row: { original } }) => <StatusProgressBar data={original} />
  },

  {
    Header: 'Model',
    accessor: 'product',
    style: { width: '100px' },
    disableSortBy: true,
    Cell: ({ row }) => {
      return (
        <span>
          <strong>{row.original.product}</strong> <br />
          <small>{row.original.firmware}</small>
        </span>
      );
    }
  },
  {
    Header: 'MAC',
    accessor: 'mac',
    style: { width: '180px' },
    disableSortBy: true,
    Cell: ({ cell: { value } }) => <Clipboard label={value} />
  },
  {
    Header: 'IP',
    accessor: 'ip',
    disableSortBy: true
  },
  {
    Header: 'LAN',
    accessor: 'lan',
    disableSortBy: true
  },
  {
    Header: 'Subscription',
    disableSortBy: true,
    accessor: 'last_subscribed_at',
    Cell: ({ row: { original } }) => {
      if (original.online == 0) return null;
      const subTime = original.last_subscribed_at
        ? original.last_subscribed_at.replace(' ', 'T') + 'Z'
        : false;
      const limit = new Date();
      limit.setHours(limit.getHours() - 2);
      limit.setMinutes(limit.getMinutes() - 30);
      return !subTime || new Date(subTime) < limit ? (
        <Badge color="red" title={subTime ? dateTimeLocal(subTime) : 'Never'}>
          Stale
        </Badge>
      ) : (
        <Badge color="green">Ok</Badge>
      );
    }
  },
  {
    Header: () => <LastOnlineTimeHeader />,
    accessor: 'online_time',
    label: 'Online Since',
    asc: 'oldestOnlineTime',
    desc: 'newestOnlineTime',
    newSorter: true,
    Cell: ({ cell: { value } }) => {
      if (value) return <span>{dateTimeDif(value)}</span>;
      else return null;
    }
  },

  {
    Header: 'Date Inserted',
    accessor: 'insert_time',
    label: 'Date Inserted',
    id: 'insert_time',
    asc: 'oldestInserted',
    desc: 'newestInserted',
    newSorter: true,
    Cell: ({ cell: { value } }) => {
      if (value) return <span>{dateTimeDif(value)}</span>;
      else return null;
    }
  },
  {
    Header: 'Metrics',
    accessor: 'last_device_metrics_at',
    disableSortBy: true,
    Cell: ({ cell: { value }, row: { original } }) => {
      var areDatesLessThan2MinutesApart =
        Date.now() - new Date(value).getTime() < 2 * 60 * 1000;

      if (original.device_metrics_enabled == 1)
        return (
          <div
            css={css`
              width: 16px;
              height: 16px;
              background-color: ${areDatesLessThan2MinutesApart
                ? 'var(--light--primary--400-base)'
                : 'var(--light--others--red-400-base)'};
              border-radius: 50%;
            `}
          ></div>
        );
      else return null;
    }
  },
  {
    Header: () => <CpuUsageHeader />,
    disableSortBy: true,
    id: 'cpu_usage',
    label: 'CPU Usage',
    accessor: 'cpu_usage',

    Cell: ({ cell: { value } }) => {
      const [loading, setLoading] = React.useState(true);
      React.useEffect(() => setTimeout(() => setLoading(false), 0), []);

      if (loading) return <Skeleton />;
      return <CpuUsgaeChart activity={value} />;
    }
  },

  {
    Header: () => <ActivityHeader />,
    disableSortBy: true,
    id: 'activity_24_hours',
    style: { width: '225px' },
    label: '24 Hr Health',
    accessor: 'activity_24_hours',
    fixed: true,
    Cell: ({ cell: { value }, row: { original } }) => {
      const [loading, setLoading] = React.useState(true);
      React.useEffect(() => setTimeout(() => setLoading(false), 0), []);

      if (loading) return <Skeleton />;
      return <Activity24HoursFormatter activity={value} device={original} />;
    }
  }
];
