import React from 'react';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */

export function getRowNumberColumn() {
  return {
    pickerHide: true,
    Header: () => null,
    id: 'rownumber',

    Cell: (tableProps) => {
      return (
        <span
          css={css`
            color: var(--neutral-02);
          `}
        >
          {tableProps.row.index + 1}
        </span>
      );
    }
  };
}
