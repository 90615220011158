import { InputLabel } from './form';
import { ErrorMessage } from './form';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
export const styles = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
  accent-color: var(--primary-base);

  > label {
    display: flex;
    gap: 4px;
    align-items: center;
  }

  > label[aria-selected='true'] {
    font-weight: 500;
  }

  input[type='radio'] {
    appearance: none;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    border: 2px solid var(--neutral-12);
    transition: 0.2s all linear;
  }

  input[type='radio']:disabled + label {
    opacity: 50%;
  }
  input[type='radio']:checked {
    background-color: var(--primary-base);
    border: 6px solid var(--neutral-09);
    outline: 2px solid var(--neutral-12);
  }
  input[type='radio'] + label {
    font-weight: 500;
  }
`;

const RadioGroup = (props) => {
  const { value, name, options, setFieldValue, title } = props;
  return (
    <>
      <InputLabel label={title} />
      <div role="group" css={styles}>
        {options.map((o, idx) => {
          return (
            <label aria-selected={value == o.value} key={idx}>
              <input
                onChange={(e) => {
                  setFieldValue(name, e.target.value);
                }}
                checked={value == o.value}
                type="radio"
                name={name}
                value={o.value}
              />
              {o.label}
            </label>
          );
        })}
        <ErrorMessage name={name} />
      </div>
    </>
  );
};

export default RadioGroup;
