import React from 'react';
import Template from 'base/template';

import GenericDoor from 'api/generic_door';
import { columns } from 'views/doors/table-columns';
import Sidebar from 'views/doors/sidebar';
import IndexContent from 'base/index-content';
import { DoorOpen, Power } from 'react-bootstrap-icons';
import ActionMenu from 'views/doors/action-menu';
import { useAuth } from 'providers/auth';
import Device from 'api/device';
import { ReactComponent as DoorEmptyIcon } from 'assets/empty-icons/empty-door.svg';
import parse from 'date-fns/parse';

import Site from 'api/site';
import Schedule from 'api/schedule';
import { IconAlertTriangle } from '@tabler/icons-react';

const cards = [
  {
    name: 'total',
    label: 'Total'
  },
  {
    name: 'alarms',
    label: 'Alarms',
    color: 'var(--red-base)',
    background: 'var(--stats-background-red)',
    columnId: 'alarm_state',
    filter: { alarm: '1' }
  },

  {
    name: 'online',
    label: 'Online',
    columnId: 'state',
    color: 'var(--primary-base)',
    background: 'var(--stats-background-green)',
    icon: Power,
    filter: { online: '1' }
  },
  {
    name: 'offline',
    label: 'Offline',
    color: 'var(--red-base)',
    background: 'var(--stats-background-red)',
    icon: Power,
    filter: { online: '0' }
  },
  {
    name: 'alerts',
    label: 'Alerts',
    color: 'var(--red-base)',
    background: 'var(--stats-background-red)',
    icon: IconAlertTriangle,
    filter: { alerts: '1' }
  }
];

// const ChartArea = (props) => {
//   return <DoorTrendChart />;
// };
const Doors = (props) => {
  const { enforce, user } = useAuth();

  let FILTERS = React.useMemo(
    () => [
      {
        open: true,
        id: 'recent',
        label: 'Recent Searches...',
        options: []
      },
      {
        id: 'filters',
        label: 'Filters',
        options: [
          {
            value: ['alarm', '1'],
            label: 'Alarms',
            name: 'Alarms',
            type: 'common'
          },
          {
            value: ['online', '0'],
            label: 'Offline',
            name: 'Offline',
            type: 'common'
          },
          {
            value: ['online', '1'],
            name: 'Online',
            label: 'Online',
            type: 'common'
          }
        ]
      },
      {
        open: true,
        id: 'filterBy',
        label: 'Filter By...',
        options: [
          {
            custom: true,
            label: 'Date Inserted ',
            field: 'insertDateFrom',
            name: 'Date Inserted',
            id: 'company',
            validate: (val) => {
              let d = parse(val, 'MM/dd/yyyy', new Date());

              return d instanceof Date && !isNaN(d);
            },
            helper: 'Insert a valid date above mm/dd/yy',
            options: [
              {
                value: 'yesterday',
                label: 'Past Day'
              },
              {
                value: '-7 days',
                label: 'Past Week'
              },
              {
                value: '-30 days',
                label: 'Past Month'
              }
            ]
          },
          {
            label: 'Site',
            value: 'site',
            fetchFn: Site.search,
            field: 'site_id',
            name: 'Site',

            id: 'site'
          },
          {
            visible: () => enforce({ role: 'IV' }),
            label: 'Device',
            value: 'device',
            fetchFn: Device.search,
            field: 'device_id',
            name: 'Device',
            id: 'device'
          },
          {
            visible: () => enforce({ restrictIfCompanyUser: ['scheduleView'] }),
            label: 'Open Time',
            value: 'open_schedule_token',
            fetchFn: Schedule.search,
            field: 'open_schedule_token',
            name: 'Open Time',
            id: 'open_schedule_token',
            idx: 'token'
          }
        ]
      }
    ],
    [user]
  );

  return (
    <Template {...props}>
      <IndexContent
        initialFilter={{ site: user.focused_site }}
        statsQuery={GenericDoor.getStats.bind()}
        title="doors"
        id="doors"
        cards={cards}
        query={GenericDoor.search.bind()}
        columns={columns}
        addButton={enforce({ managing: true }) ? 'Add a Door' : false}
        sidebar={Sidebar}
        isSidebarOpen={true}
        //filterKeys={filterKeys}
        actionButton={ActionMenu}
        filterOptions={FILTERS}
        // chartArea={ChartArea}
        emptyIcon={DoorEmptyIcon}
        live
        {...props}
      ></IndexContent>
    </Template>
  );
};

export default Doors;
