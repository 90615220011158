import React from 'react';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { useQueryWrapper } from 'utils/ajax';
import { useMutationWrapper } from 'utils/ajax';
//import * as Select from '@radix-ui/react-select';
import { ReactComponent as OverlayIcon } from 'assets/overlay-icon.svg';
import CameraAction from 'api/camera_action';
import { AuxiliaryButton } from 'components/buttons';

function isFirmwareGreaterThan(version1, version2) {
  const v1Parts = version1.split('.');
  const v2Parts = version2.split('.');

  for (let i = 0; i < Math.max(v1Parts.length, v2Parts.length); i++) {
    const v1Part = parseInt(v1Parts[i] || 0);
    const v2Part = parseInt(v2Parts[i] || 0);

    if (v1Part < v2Part) {
      return false; // version1 is older or equal
    } else if (v1Part > v2Part) {
      return true; // version1 is newer
    }
  }

  return false; // Versions are equal
}
export default ({
  videoRef,
  host,
  onVapix,
  pipeline,
  setControlsOpen,
  resolution = []
}) => {
  if (pipeline?.recording) return null;

  if (!isFirmwareGreaterThan(host.firmware, '7.10')) return null;

  const overlayMutation = useMutationWrapper(
    ['camera', host?.id, 'toggleOverlay'],
    () => CameraAction.toggleOverlay(host.id),
    { loadingMessage: 'Toggling Overlay...' }
  );

  const handleToggleOverlay = () => overlayMutation.mutate();

  return (
    <article
      className="overlay-control"
      css={css`
        display: flex;
      `}
    >
      <AuxiliaryButton
        compact
        type="button"
        disabled={overlayMutation.isLoading}
        onClick={handleToggleOverlay}
      >
        <OverlayIcon />
        Toggle Overlay
      </AuxiliaryButton>
    </article>
  );
};
