
import TextField from "components/form/text-field";
import { useFormikContext } from "formik";
import { useDebounce } from "hooks/debounce-hook";

export function DebouncedField(props){

    const { setFieldValue } = useFormikContext();

    const { input, setInput } = useDebounce(function(debouncedValue){
        props.name && setFieldValue(props.name, debouncedValue);
    }, props.time);

    const Comp = props.as ?? TextField;

    const otherProps = {
        [props.valueKey ?? 'value']: input
    };

    return <Comp {...props} {...otherProps} onChange={ev => setInput(ev.target.value)} />;
}