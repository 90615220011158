import React from 'react';
// then do somehting
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Clipboard as ClipboardIcon } from 'react-bootstrap-icons';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react **/

const styles = css`
  display: flex;
  align-items: center;
  gap: 10px;
  svg {
    color: gray;
    width: 16px;
    height: 16px;
    cursor: pointer;
    flex: 1 1 32px;
  }
  :hover {
    svg {
      color: black;
    }
  }
`;
const Clipboard = ({ ...props }) => {
  const { label, value, ...rest } = props;
  const [isCopied, setIsCopied] = React.useState(false);
  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  return (
    <CopyToClipboard
      className="copy-to-clipboard"
      text={value ?? label}
      onCopy={onCopyText}
    >
      <span css={styles}>
        <button
          {...rest}
          css={css`
            appearance: 'none';
            padding: 8;
            border: 0;
            outline: 0;
            cursor: 'pointer';
            background: var(--neutral-06);
            border-radius: 5px;
            :hover {
              background: var(--neutral-05);
            }
          `}
        >
          <div
            css={{
              position: 'relative',
              height: 16,
              width: 16
            }}
          >
            <Clippy
              css={{
                color: 'red',
                position: 'absolute',
                top: 0,
                left: 0,
                strokeDasharray: 50,
                strokeDashoffset: isCopied ? -50 : 0,
                transition: 'all 300ms ease-in-out'
              }}
            />
            <Check
              isVisible={isCopied}
              css={{
                color: 'green',
                position: 'absolute',
                top: 0,
                left: 0,
                strokeDasharray: 50,
                strokeDashoffset: isCopied ? 0 : -50,
                transition: 'all 300ms ease-in-out'
              }}
            />
          </div>
        </button>
        {label}
      </span>
    </CopyToClipboard>
  );
};

function Clippy(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      stroke="gray"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path d="M5.75 4.75H10.25V1.75H5.75V4.75Z" />
      <path d="M3.25 2.88379C2.9511 3.05669 2.75 3.37987 2.75 3.75001V13.25C2.75 13.8023 3.19772 14.25 3.75 14.25H12.25C12.8023 14.25 13.25 13.8023 13.25 13.25V3.75001C13.25 3.37987 13.0489 3.05669 12.75 2.88379" />
    </svg>
  );
}

function Check(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      stroke="green"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path d="M13.25 4.75L6 12L2.75 8.75" />
    </svg>
  );
}

export default Clipboard;
