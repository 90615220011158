import React from 'react';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import {
  IconAlertTriangle,
  IconAlertTriangleFilled
} from '@tabler/icons-react';
import { AuxiliaryButton } from './buttons';
import { useAckAlert } from 'views/devices/actions';
import { isWithinInterval, subDays, addDays, startOfToday } from 'date-fns';
import IsUserAllowed from './is-user-allowed';
import { dateTime } from 'utils/time';
import { useAuth } from 'providers/auth';
import IsRole from './is-role';
export function isWithinDays(date, days = 3) {
  const today = startOfToday();
  const pastDate = subDays(today, days);
  const futureDate = addDays(today, days);

  return isWithinInterval(date, { start: pastDate, end: futureDate });
}

export const ALERTS = [
  'storage_disruption',
  'tampering',
  'critical_temperature',
  'action_rule_failed'
];

export default ({ device }) => {
  const { user } = useAuth();
  let alerts = null;

  // if (
  //   device[i] &&
  //   isWithinDays(new Date(device[i]), user.role == 'C' ? 3 : 7)
  // )
  for (let i of ALERTS) {
    // if (
    //   device[i] &&
    //   isWithinDays(new Date(device[i]), user.role == 'C' ? 3 : 7)
    // )
    if (device[i]) alerts = true;
  }
  if (!alerts) return null;

  const ackMutation = useAckAlert(device);

  return (
    <IsRole role={['A', 'V', 'I']}>
      <article
        className="device-alerts"
        css={css`
          border-bottom: 1px solid var(--neutral-12);
          h3 {
            margin-bottom: 12px;
            padding-inline: 20px;
            padding-top: 16px;
          }
        `}
      >
        <h3>Alerts</h3>

        <ul
          css={css`
            // limit to 4
            display: flex;
            align-items: start;
            flex-direction: column;
            > li {
              height: 64px;
              box-shadow: 0px -1px 0px var(--neutral-12);
              padding-inline: 20px;
              width: 100%;
              display: grid;
              grid-template-columns: auto 1fr 1fr;
              gap: 1rem;
              align-content: center;
              align-items: center;

              h4 {
                font-size: 14px;
                font-weight: 600;
                color: var(--neutral-00);
              }
              time {
                margin-top: 4px;
                display: block;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                font-style: normal;
                font-size: 12px;
                color: var(--secondary-font-color);
              }
              svg {
                color: var(--light--others--red-400-base);
              }
              button {
                margin-left: auto;
                height: 36px;
              }
            }
          `}
        >
          {device.storage_disruption && (
            <li>
              <IconAlertTriangle />
              <div>
                <h4>Storage Disruption On</h4>
                <time>{dateTime(new Date(device.storage_disruption))}</time>
              </div>
              <IsUserAllowed restrict="C" condition="ackAlerts">
                <AuxiliaryButton
                  disabled={ackMutation.isLoading}
                  onClick={() => ackMutation.mutate('storage_disruption')}
                  compact
                >
                  Acknowledge
                </AuxiliaryButton>
              </IsUserAllowed>
            </li>
          )}
          {device.tampering && (
            <li>
              <IconAlertTriangle />
              <div>
                <h4>Device Tampering</h4>
                <time>{dateTime(new Date(device.tampering))}</time>
              </div>
              <IsUserAllowed restrict="C" condition="ackAlerts">
                <AuxiliaryButton
                  disabled={ackMutation.isLoading}
                  onClick={() => ackMutation.mutate('tampering')}
                  compact
                >
                  Acknowledge
                </AuxiliaryButton>
              </IsUserAllowed>
            </li>
          )}
          {device.critical_temperature && (
            <li>
              <IconAlertTriangle />
              <div>
                <h4>Critical Temperature</h4>
                <time>{dateTime(new Date(device.critical_temperature))}</time>
              </div>
              <IsUserAllowed restrict="C" condition="ackAlerts">
                <AuxiliaryButton
                  disabled={ackMutation.isLoading}
                  onClick={() => ackMutation.mutate('critical_temperature')}
                  compact
                >
                  Acknowledge
                </AuxiliaryButton>
              </IsUserAllowed>
            </li>
          )}
          {device.action_rule_failed && (
            <li>
              <IconAlertTriangle />
              <div>
                <h4>Action Rule Failed</h4>
                <time>{dateTime(new Date(device.action_rule_failed))}</time>
              </div>
              <IsUserAllowed restrict="C" condition="ackAlerts">
                <AuxiliaryButton
                  disabled={ackMutation.isLoading}
                  onClick={() => ackMutation.mutate('action_rule_failed')}
                  compact
                >
                  Acknowledge
                </AuxiliaryButton>
              </IsUserAllowed>
            </li>
          )}
        </ul>
      </article>
    </IsRole>
  );
};
