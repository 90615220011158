import React from 'react';
import Template from 'base/template';
import Company from 'api/company';
import { columns } from 'views/companies/table-columns';
import IndexContent from 'base/index-content';
import {
  Cpu,
  DoorOpen,
  XLg,
  ChevronDown,
  FiletypeCsv,
  Building,
  Receipt
} from 'react-bootstrap-icons';
import { NEW_API_HOST_URL } from 'api/api-url';
import ActionMenu from 'views/companies/action-menu';
import { useAuth } from 'providers/auth';
import Sidebar from 'views/companies/sidebar';
import { css } from '@emotion/react';
import { useExportUsers } from 'views/users/actions';
import { useExportDoors } from 'views/companies/actions';

/** @jsxImportSource @emotion/react */
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { AuxiliaryButton } from 'components/buttons';
import { MenuItem, MenuItems } from 'components/action-menu';

import { ReactComponent as CompanyEmptyIcon } from 'assets/empty-icons/empty-company.svg';
import { ReactComponent as TrendUpIcon } from 'assets/trend-up-icon.svg';
import IsRole from 'components/is-role';

const cards = [
  {
    name: 'total',
    label: 'Total'
  },
  {
    name: 'controller_count',
    label: 'Controllers',
    icon: Cpu
  },
  {
    name: 'door_count',
    label: 'Doors',
    icon: DoorOpen
  },
  {
    name: 'new_door_month_count',
    label: 'New Doors (month)',
    icon: TrendUpIcon,
    color: 'var(--primary-base)',
    background: 'var(--stats-background-green)'
  },
  {
    name: 'issues',
    label: 'Issues',
    icon: XLg,
    color: 'var(--red-base)',
    background: 'var(--stats-background-red)',

    filter: { hasACIssues: '1' }
  }
];

const handleExportBillingObjects = () =>
  (location.href = NEW_API_HOST_URL + `/company/exportBillingObjects`);

const ExtraAction = () => {
  const exportDoors = useExportDoors();

  const handleExportDoors = () => exportDoors.mutate();
  const handleExportBillingObject = () => exportBillingObject.mutate();
  const handleExportUsers = () =>
    (location.href = NEW_API_HOST_URL + `/user/export`);

  const menuStyles = css`
    display: inline-block;
    position: relative;
  `;
  const { user, enforce, hasAllPermissions } = useAuth();

  return (
    <>
      <DropdownMenu.Root as="div" css={menuStyles}>
        <DropdownMenu.Trigger
          asChild={true}
          css={css`
            &[data-state='open'] {
              svg {
                transform: rotate(180deg);
              }
            }
          `}
        >
          <AuxiliaryButton
            compact
            css={css`
              svg {
                width: 16px;
                height: 16px;
              }
            `}
          >
            <ChevronDown />
          </AuxiliaryButton>
        </DropdownMenu.Trigger>
        <MenuItems>
          {((enforce({ role: 'I' }) && hasAllPermissions('allCompanies')) ||
            (enforce({ role: 'V' }) && hasAllPermissions('allInstallers'))) && (
            <MenuItem onClick={handleExportUsers} label="Export Login Users...">
              <FiletypeCsv />
            </MenuItem>
          )}
          <MenuItem onClick={handleExportDoors} label="Export Doors...">
            <Building />
          </MenuItem>

          {((enforce({ role: 'I' }) && hasAllPermissions('allCompanies')) ||
            (enforce({ role: 'V' }) && hasAllPermissions('allInstallers'))) && (
            <a
              href={NEW_API_HOST_URL + `/company/exportBillingObjects`}
              download="_"
              target="_blank"
              rel="noreferrer noopener"
            >
              <MenuItem label="Export Billing Objects CSV...">
                <Receipt />
              </MenuItem>
            </a>
          )}
        </MenuItems>
      </DropdownMenu.Root>
    </>
  );
};

const Companies = (props) => {
  const { enforce, user } = useAuth();

  return (
    <Template {...props}>
      <IndexContent
        statsQuery={Company.stats}
        title="companies"
        id="companies"
        cards={cards}
        query={Company.search}
        columns={columns}
        hiddenColumns={[
          user.role != 'A' && 'vendor_name',
          user.role != 'A' && user.role != 'V' && 'installer_name'
        ]}
        addButton={
          enforce({
            reqInstaller: true,
            restrictIfInstallerUser: ['writeCompanies']
          })
            ? 'Add A Company'
            : false
        }
        getRowProps={(row) => ({
          style: {
            background:
              row.original.offline_count > 0 || row.original.unsynced_count > 0
                ? 'var(--red-v-02)  '
                : 'inherit'
          }
        })}
        selectable
        buttons={ExtraAction}
        actionButton={ActionMenu}
        sidebar={Sidebar}
        emptyIcon={CompanyEmptyIcon}
        {...props}
      ></IndexContent>
    </Template>
  );
};

export default Companies;
