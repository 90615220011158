import { callAPI } from 'utils/ajax';

const Installer = {
  search(input = {}) {
    return callAPI('GET', `/installer`, input);
  },
  // getAll(input = {}) {
  //   return callAPI('GET', `/installer`, input);
  // },
  save(input = {}, id = '') {
    return callAPI(id ? 'PUT' : 'POST', `/installer/${id}`, input);
  },
  delete(id) {
    return callAPI('DELETE', `/installer/${id}`);
  },
  stats() {
    return callAPI('GET', `/installer/stats`);
  },

  access(id) {
    return callAPI('POST', `/installer/access/${id}`);
  },

  stopAccessing() {
    return callAPI('POST', `/installer/stopAccessing`);
  }
};

export default Installer;
