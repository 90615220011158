import React from 'react';
import CredentialForm from 'views/credentials/form';
import Credential from 'api/credential';
import Template from 'base/template';
import { Button, CancelButton } from 'components/buttons';
import { Redirect } from 'react-router-dom';
import FormProvider from 'providers/form-provider';
import { useMutationWrapper, useQueryWrapper } from 'utils/ajax';
import { useRedirect } from 'hooks/redirect-hook';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PageTitle from 'components/page-title';
import Content from 'base/content';
import { useParams } from 'react-router-dom';
import Breadcrumbs from 'components/breadcrumbs';
import useAlertDialog from 'providers/alert-dialog-provider';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
const Save = (props) => {
  const { token } = useParams();
  const confirm = useAlertDialog();
  const credential = props?.location?.state?.credential;
  let credentialData = props?.location?.state?.credentialData ?? null;

  const [initialValues, setInitialValues] = React.useState({
    description: credential?.Description,
    pin: credential?.pin || credentialData?.pin,
    card: credential?.card || credentialData?.Card,
    person: credential?.person_id || credentialData?.person_id || null,
    cardNumber: credential?.cardNr || credentialData?.CardNr,
    facilityCode: credential?.facilityCode || credentialData?.FacilityCode,
    validFrom: credential
      ? credential.ValidFrom
      : new Date().toLocaleString('sv').replace(' ', 'T').slice(0, -3), // keep browser time-zone

    validTo: credential ? credential.ValidTo : '2036-06-01T11:30',

    enabled: credential ? credential?.Enabled == 1 : true,
    extendedTime: credential
      ? credential.Attribute?.find((i) => i.Name == 'AccessTime')?.[
          'Value'
        ]?.replace(/\D/g, '')
      : '',
    emergencyLockDown: credential
      ? credential.Attribute?.find((i) => i.Name == 'EmergencyLockDown')?.[
          'Value'
        ]
        ? true
        : false
      : false,
    cardOrCardNrOrPin: '',
    authenticationProfile: credential ? credential.AuthenticationProfile[0] : ''
  });

  const { data, isLoading: credentialLoading } = useQueryWrapper(
    ['credential', 'get', token],
    () => Credential.get(token),
    {
      enabled: Boolean(token && !props?.location?.state?.credential),
      cacheTime: 0,
      onNotFound() {
        setRedirect(true);
      }
    }
  );

  const { isSuccess, isLoading, mutate } = useMutationWrapper(
    ['credential', 'save'],
    (input) => Credential.save(input, credential?.token),
    {}
  );

  const { redirect, setRedirect } = useRedirect(isSuccess);

  React.useEffect(() => {
    if (!data || props?.location?.state?.credential) return;
    const credential = data;
    setInitialValues({
      description: credential?.Description,
      card: credential?.card || credentialData?.Card,
      person: credential?.person_id || credentialData?.person_id,
      cardNumber: credential?.cardNr || credentialData?.CardNr,
      facilityCode: credential?.facilityCode || credentialData?.FacilityCode,
      validFrom: credential
        ? credential.ValidFrom
        : new Date().toLocaleString('sv').replace(' ', 'T').slice(0, -3), // keep browser time-zone

      validTo: credential ? credential.ValidTo : '2036-06-01T11:30',

      enabled: credential ? (credential?.Enabled == 1 ? 'on' : '') : 'on',
      extendedTime: credential
        ? credential.Attribute?.find((i) => i.Name == 'AccessTime')?.[
            'Value'
          ]?.replace(/\D/g, '')
        : '',
      emergencyLockDown: credential
        ? credential.Attribute?.find((i) => i.Name == 'EmergencyLockDown')?.[
            'Value'
          ]
          ? true
          : false
        : false,
      cardOrCardNrOrPin: '',
      authenticationProfile: credential
        ? credential.AuthenticationProfile[0]
        : []
    });
  }, [data]);

  const SCHEMA = Yup.object().shape({
    person: Yup.number().required('person is a required field').nullable(),
    cardNumber: Yup.number()
      .nullable()
      .min(1, "card number can not be negative or '0'")
      .test('not-start-with-zero', 'Card number cannot be  0', (value) => {
        // Ensure the value is treated as a string
        const stringValue = String(value);

        // Check if it starts with '0'
        return !stringValue.startsWith('0');
      }),
    pin: Yup.number().nullable(),
    extendedTime: Yup.number().min(1, 'Min value 1.').max(60, 'Max value 60.'),
    description: Yup.string()
      .min(2, 'Description is too short')
      .max(40, '40 characters max')
      .required('Required'),
    card: Yup.string().when(['cardNumber', 'pin'], {
      is: (cardNumber, pin) => !cardNumber && !pin,
      then: Yup.string().required('Card or Card Nr or Pin required'),
      otherwise: Yup.string().matches(
        /^[0-9a-zA-Z]{1,22}$/,
        'card must be of 22 valid chars'
      )
    }),
    validTo: Yup.date()
      .max(new Date('2036-06-12T17:30'), 'Must be before 2036-06-12T17:30')
      .default(null)
      .when(
        'validFrom',
        (validFrom, Yup) =>
          validFrom &&
          Yup.min(
            validFrom,
            '"Valid To" cannot be before "Valid From"'
          ).nullable()
      )
  });
  const history = useHistory();

  return (
    <Template {...props}>
      {redirect && <Redirect to="/credentials" />}
      <Content {...props}>
        <PageTitle>
          {token ? initialValues.description : 'Credential Add'}
        </PageTitle>
        <Breadcrumbs crumbs={props.crumbs} />
        <Formik
          validationSchema={SCHEMA}
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={async (values) => {
            delete values.cardOrCardNrOrPin;
            const vf = new Date(values.validFrom).toISOString();
            const vt = new Date(values.validTo).toISOString();
            let trigger = true;
            if (new Date(values.validFrom) > new Date())
              trigger = await confirm({
                description: `Start date for this credential is in the future, the credential will be added but not work until ${new Date(
                  values.validFrom
                ).toLocaleString()}, continue ?`
              });

            trigger &&
              mutate(
                { ...values, validFrom: vf, validTo: vt },
                {
                  onSuccess: (res) => {
                    if (!credential && res.credentialToken)
                      history.push(
                        `/credentials/${res.credentialToken}/levels`,
                        {
                          desc: values.description,
                          dialogOpen: true
                        }
                      );
                  }
                }
              );
          }}
        >
          {({ handleSubmit, dirty }) => (
            <FormProvider
              mutateFunc={handleSubmit}
              disabled={isLoading || credentialLoading}
            >
              {(data || props?.location?.state?.credential || !token) && (
                <>
                  <CredentialForm
                    token={token}
                    credential={initialValues}
                    credentialData={credentialData}
                  />
                  <footer>
                    <Button type="submit" disabled={!dirty}>
                      Submit
                    </Button>
                    <CancelButton onClick={() => setRedirect(true)} />
                  </footer>
                </>
              )}
            </FormProvider>
          )}
        </Formik>
      </Content>
    </Template>
  );
};

export default Save;
