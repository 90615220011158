import React from "react";
import { css } from "@emotion/react";
import { useFormikContext } from "formik";
import { TextField } from "./form";
import { useIMask } from "react-imask";

/** @jsxImportSource @emotion/react */
const O3CRegistration = ({ device }) => {
  const { values, setFieldValue } = useFormikContext();
  const [mac, setMacOpts] = React.useState({ mask: "**:**:**:**:**:**" });
  const [oak, setOakOpts] = React.useState({ mask: "****-****-****-****" });
  const { ref: macRef, typedValue: macTypedValue } = useIMask(mac);
  const { ref: oakRef, typedValue: oakTypedValue } = useIMask(oak);

  if (device && values.ip == device?.ip) return null;
  else
    return (
      <article
        css={css`
          h4 {
            color: var(--neutral-00);
            grid-column: 1/-1;
            margin-bottom: 0.5rem;
          }
          grid-column: 1/-1;
          display: grid;
          grid-template-columns: 1fr 1.5fr;
          grid-column-gap: 24px;
          padding: 24px;

          background: var(--orange-v-01);
          border-radius: 4px;
          > div:last-child {
            flex: 1;
          }
          input[name="mac"],
          input[name="oak"] {
            text-transform: uppercase;
            font-weight: 500;
          }
        `}
      >
        <h4>Register Device on the O3C Network</h4>
        <TextField
          ref={macRef}
          name="mac"
          label="MAC"
          onBlur={(e) => setFieldValue("mac", macTypedValue)}
        />
        <TextField
          ref={oakRef}
          name="oak"
          label="OAK"
          onBlur={(e) => setFieldValue("oak", oakTypedValue)}
        />
      </article>
    );
};

export default O3CRegistration;
