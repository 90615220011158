import React from 'react';

/** @jsxImportSource @emotion/react */
import {
  TabItem,
  TabGroup,
  TabPanel,
  TabPanels,
  TabList
} from 'components/tabs';
import { css } from '@emotion/react';
import { GeoAlt, PersonPlus } from 'react-bootstrap-icons';

import Map from 'components/map';
import OnlineChart from 'components/charts/online-chart';
import UsageChartHolder from 'components/charts/usage-chart-holder';
import SiteWhoseinList from './site-whosein-list';
import { WhoseinTooltip } from 'components/whosein-list';
import LastPerson from 'views/doors/last-person';
import ActivityChartFormatter from 'components/activity-chart-formatter';

const SiteTabs = ({ site = {} }) => {
  return (
    <TabGroup>
      <TabList
        css={css`
          width: 100%;
          box-shadow: unset;

          padding: 0px 16px;
          gap: 2rem;
          border-top: 1px solid var(--neutral-12);
          border-bottom: 1px solid var(--neutral-12);

          [role='tab'] {
            min-width: unset;
          }
        `}
      >
        <TabItem title="">
          <GeoAlt size={24} />
        </TabItem>
        <TabItem title="Whose In ?">
          <PersonPlus size={24} />
        </TabItem>
      </TabList>
      <TabPanels>
        <TabPanel
          as="div"
          css={css`
            overflow: hidden;
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            padding: 16px;
          `}
        >
          <section
            className="content"
            css={css`
              display: grid;
              height: 100%;
              grid-auto-rows: 1fr 200px 225px;
              gap: 1rem;
            `}
          >
            <Map lat={site.lat} lng={site.lng} site={site} />
            <div>
              <h4>Site Health</h4>
              <OnlineChart
                chartData={
                  site.device_count > 0
                    ? [
                        {
                          value: Number(site.online_count),
                          name: 'online devices'
                        },
                        {
                          value: Number(site.offline_count),
                          name: 'offline devices'
                        }
                      ]
                    : []
                }
              />
            </div>
            {/* <UsageChartHolder
              title
              noExpand
              name={site.name}
              filters={{ site_id: site?.id }}
            /> */}
            <ActivityChartFormatter
              full
              activity={site.activity}
              style={{ height: '100%', width: '100%' }}
              config={{}}
            />
          </section>
        </TabPanel>
        <TabPanel
          css={css`
            overflow: hidden;
            height: 100%;
            display: flex;
            flex-direction: column;
            padding: 16px;
            gap: 1rem;
            h4 {
              line-height: 22px;
            }
          `}
        >
          <h4>Latest Entry</h4>
          <LastPerson data={site} />
          <h4>
            Whose In <WhoseinTooltip />
          </h4>
          <SiteWhoseinList site={site} />
        </TabPanel>
      </TabPanels>
    </TabGroup>
  );
};

export default SiteTabs;
