import React from 'react';
import { css } from '@emotion/react';
import Event from 'api/event';
/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import Player from 'components/video/player';
import { WidgetHeader } from './widget-header';
import { useQueryWrapper } from 'utils/ajax';

import usePlayerControls from 'components/use-player-controls';
import RemoveItemButton from './remove-item-button';
import Camera from 'api/camera';

import GenericDoor from 'api/generic_door';
import { TitleCards, IconCards } from './door-widget';
import DoorStateAvatar from 'views/doors/door-state-avatar';
import { AvatarCircle } from 'components/avatar';
import ActionMenu from 'views/doors/action-menu';

import WidgetGridControls from './widget-grid-controls';
import { WidgetControls } from './widget-controls';

import { FetchingWidgetHolder } from './fetching-widget-holder';
import {
  IconAlertCircle,
  IconCarouselHorizontal,
  IconDoor,
  IconDoorEnter,
  IconMapPin,
  IconVideo,
  IconWorld
} from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { SiteIcon } from 'icons';
import DeviceAlertsTableIndicator from 'components/device-alerts-table-indicator';
import { useLayout } from 'providers/layout-provider';
import { useEventSource } from 'react-use-websocket';
import { useAuth } from 'providers/auth';
import { useMotionEvent } from 'views/cameras/use-motion-event';
const CameraWidget = styled('article')`
  /* light/neutrals/000 */
  background: #ffffff;
  /* light/neutrals/300 */
  border: 1px solid #eceff3;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 225px;
  min-width: 175px;
  overflow: hidden;
`;

export default ({
  cameraId,
  isEdit,
  index,
  widgetIndex,
  selectedView,
  setSelectedView,
  carousel = false,
  ...otherProps
}) => {
  const {
    data: camera,
    isLoading,
    isError,
    refetch
  } = useQueryWrapper(
    ['camera', 'get', cameraId, selectedView?.id],
    () => Camera.get(cameraId),
    {
      cacheTime: 5 * 60 * 1000,
      staleTime: Infinity,
      noToast: true,
      noRetry: true
    }
  );

  let doorId = camera?.generic_door_id || camera?.intercom_relay_door_i;

  const { data: door } = useQueryWrapper(
    ['door', 'get', doorId],
    () => GenericDoor.get(doorId),
    {
      enabled: Boolean(doorId)
    }
  );
  const {
    waiting,
    setPlay,
    setRefresh,
    play,
    refresh,
    onPlaying,
    onLoading,
    videoProperties
  } = usePlayerControls({ camera });

  const { user } = useAuth();
  const [isMotion, setIsMotion] = React.useState();

  let filter = React.useCallback(
    (data) => {
      if (!camera) return false;
      if (
        data?.device_id == camera?.device_id &&
        ['online', 'offline'].includes(data.type)
      )
        return true;

      // if (
      //   data?.camera_id == camera?.id &&
      //   ['motionOn', 'motionOff'].includes(data.type)
      // )
      //   return true;
      return false;
    },
    [camera]
  );
  const { getSocketUrl } = useLayout();

  const { lastEvent, readyState } = useEventSource(
    getSocketUrl,
    {
      share: true,
      filter: (e) => filter(JSON.parse(e.data)),
      withCredentials: true,
      retryOnError: true
    },
    Boolean(user && !user.missing2fa && !user.missingMfa)
  );

  const { motionEvent } = useMotionEvent(camera);

  React.useEffect(() => {
    if (!motionEvent) return;
    setIsMotion(motionEvent.type == 'motionOn');
  }, [motionEvent]);

  // React.useEffect(() => {
  //   if (!lastEvent) return;

  //   let data = JSON.parse(lastEvent.data);

  //   if (['online', 'offline'].includes(data.type)) refetch();

  //   if (['motionOn', 'motionOff'].includes(data.type))
  //     setIsMotion(data.type == 'motionOn');
  // }, [lastEvent]);

  React.useEffect(() => {
    if (!lastEvent) return;

    refetch();
  }, [lastEvent]);

  React.useEffect(() => {
    setIsMotion(camera?.has_motion == '1');
  }, [camera]);

  if (isLoading)
    return <FetchingWidgetHolder>Fetching Camera...</FetchingWidgetHolder>;

  return (
    <CameraWidget
      css={css`
        &[data-motion='true'] {
          header {
            background: var(--light--others--purple-300);
          }
        }
      `}
      className="camera-widget"
      data-motion={isMotion && camera.online == '1' ? 'true' : 'false'}
    >
      <WidgetHeader>
        {carousel && <IconCarouselHorizontal />}
        <TitleCards>
          {camera && <DeviceAlertsTableIndicator device={camera} />}

          {camera && (
            <Link
              data-disabled={camera.online == '0'}
              to={`/cameras/view/${camera?.id}`}
            >
              <div className="name">{camera?.name}</div>
            </Link>
          )}
          {door?.name && (
            <article role="badge" className="door-name">
              <IconDoorEnter />
              <span>{door?.name}</span>
            </article>
          )}
          <article role="badge" className="site-name">
            <IconMapPin />
            <span>{camera?.site_name}</span>
          </article>
        </TitleCards>
        <WidgetControls>
          {door && (
            <IconCards>
              <DoorStateAvatar door={door} />

              <AvatarCircle
                name={door?.last_access_person_name}
                url={door?.last_access_person_avatar}
              />
              {!isEdit && <ActionMenu simple data={door} noEdit small />}
            </IconCards>
          )}
          <WidgetGridControls
            isEdit={isEdit}
            index={index}
            widgetIndex={widgetIndex}
            selectedView={selectedView}
            setSelectedView={setSelectedView}
          />
          {isEdit && (
            <RemoveItemButton
              onClick={() => {
                let newLayout = [...selectedView.layout];
                newLayout[index].items = newLayout[index]?.items?.filter(
                  (_, i) => i !== widgetIndex
                );
                setSelectedView((prev) => ({
                  ...prev,
                  layout: newLayout
                }));
              }}
            />
          )}
        </WidgetControls>
      </WidgetHeader>
      {isError && (
        <FetchingWidgetHolder>
          <IconAlertCircle color="var(--red-base)" size={36} /> Error fetching
          Widget
        </FetchingWidgetHolder>
      )}
      {camera && (
        <Player
          showCameraStats
          onLoading={onLoading}
          pipeline={videoProperties?.pipeline}
          videoEl={videoProperties?.videoEl}
          play={play}
          setPlay={setPlay}
          setRefresh={setRefresh}
          refresh={refresh}
          host={camera}
          live
          {...otherProps}
        />
      )}
    </CameraWidget>
  );
};
