import React from 'react';
import { Button, CancelButton, AuxiliaryButton } from 'components/buttons';
import Controller from 'api/controller';
import FormProvider, { useForm } from 'providers/form-provider';
import { useMutationWrapper } from 'utils/ajax';
import { Redirect } from 'react-router-dom';
import DoorDetails from 'views/doors/door-details';
import ReaderDetails from 'views/controllers/reader-details';
import { useRedirect } from 'hooks/redirect-hook';
import { Formik, FieldArray } from 'formik';
import * as Yup from 'yup';
import { FormFooter } from 'components/form/form-footer';
import useDoorDetails from 'hooks/use-door-details';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { CheckBox } from 'components/form';
import PinConfiguration from 'views/controllers/pin-configuration';
/** @jsxImportSource @emotion/react */
import { useEventSource } from 'react-use-websocket';
import { useLayout } from 'providers/layout-provider';
import { toast } from 'react-toastify';
import ProgressBar from 'components/progress-bar';
import useAlertDialog from 'providers/alert-dialog-provider';

const Heading = styled('h1')`
  background: var(--badge-background-purple);
  border-radius: 4px;

  /* Inside Auto Layout */
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  padding: 24px;
  /* identical to box height */

  /* Neutrals/neutral-00 */

  color: var(--neutral-00);
  display: flex;
  align-items: center;
  button {
    margin-left: auto;
    text-transform: uppercase;

    height: 24px;
  }
`;

const styles = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 48px;

  > button {
    margin-top: 24px;
  }
`;

const Msg = ({ closeToast, toastProps, ac }) => {
  const [eventCount, setEventCount] = React.useState(0);
  const stepCount = React.useRef(0);
  let filter = React.useCallback((data) => {
    if (data.type == 'setupProgress' && data.device_id == ac.device_id)
      return true;
    return false;
  }, []);
  const { getSocketUrl } = useLayout();

  const { lastEvent } = useEventSource(getSocketUrl, {
    share: true,
    filter: (e) => filter(JSON.parse(e.data)),
    withCredentials: true,
    retryOnError: true
  });

  React.useEffect(() => {
    if (!lastEvent) return;
    stepCount.current = JSON.parse(lastEvent.data).stepCount;
    setEventCount((prev) => ++prev);
  }, [lastEvent]);

  function computeBgColor(value) {
    return 'var(--primary-base)';
  }

  return (
    <div
      css={css`
        background: transparent !important ;
        width: 100%;
        display: flex;
        width: 100%;
        white-space: none;

        flex-direction: column;
        gap: 0.2rem;
      `}
    >
      <label
        css={css`
          display: flex;
          width: 100%
          span:last-of-type {
            margin-left: auto;
          }
        `}
      >
        <span>
          {lastEvent ? JSON.parse(lastEvent.data).message : 'Starting setup...'}
        </span>
        {lastEvent && stepCount.current > 0 && (
          <span
            css={css`
              margin-left: auto;
            `}
          >
            {Math.ceil(eventCount / (stepCount.current / 100))}%
          </span>
        )}
      </label>
      <ProgressBar
        height={14}
        completed={
          stepCount.current > 0
            ? Math.ceil(eventCount / (stepCount.current / 100))
            : 0
        }
        bgcolor={computeBgColor(
          Math.ceil(eventCount / (stepCount.current / 100))
        )}
      />
    </div>
  );
};

const Setup_Aperio = ({ ac, configuration }) => {
  const toastRef = React.useRef();
  const loading = () =>
    (toastRef.current = toast(<Msg ac={ac} />, {
      className: 'setup-toast',
      type: toast.TYPE.INFO,
      autoClose: false,
      closeOnClick: false,
      closeButton: false,
      pauseOnHover: true,
      draggable: false
    }));

  const error = (data) =>
    toast.update(toastRef.current, {
      className: '',
      type: toast.TYPE.ERROR,
      closeButton: true,
      closeOnClick: true,
      render: <div>{data?.message || 'General Error Occured '}</div>
    });

  const success = (data) =>
    toast.update(toastRef.current, {
      className: '',
      type: toast.TYPE.SUCCESS,
      closeButton: true,
      autoClose: 4000,
      closeOnClick: true,
      render: <>Done</>
    });

  const { isSuccess, isLoading, mutate } = useMutationWrapper(
    ['controller', 'setup', ac.id],
    (input) => Controller.setup(input, ac?.id),
    {
      loadingMessage: `Setting up Controller ${ac.name}...`,
      onMutate: () => loading(),
      noToast: true,
      onSuccess: (data) => success(data),
      onError: (data) => error(data)
    }
  );

  const { initialValues, SCHEMA, DOOR_DETAILS_CONST } = useDoorDetails(
    configuration,
    ac
  );

  const confirm = useAlertDialog();

  const { redirect, setRedirect } = useRedirect(isSuccess);
  return (
    <Formik
      enableReinitialize={true}
      validationSchema={SCHEMA}
      initialValues={initialValues}
      onSubmit={async (values) => {
        if (ac.setup == 3) {
          if (
            await confirm({
              description:
                'All open schedules on existing doors will be removed.'
            })
          )
            mutate(values);
        } else mutate(values);
      }}
    >
      {({ handleSubmit, setFieldValue, values }) => (
        <FormProvider mutateFunc={handleSubmit} disabled={isLoading}>
          {redirect && <Redirect to="/devices" />}
          <div css={styles}>
            <FieldArray
              name="doors"
              render={(arrayHelpers) => (
                <>
                  {values.doors && values.doors.length > 0
                    ? values.doors.map((e, index) => (
                        <article>
                          <Heading>
                            Door {index + 1}
                            {index > 0 && (
                              <AuxiliaryButton
                                compact
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                Remove
                              </AuxiliaryButton>
                            )}
                          </Heading>
                          <PinConfiguration index={index} />
                          <DoorDetails
                            ac={ac}
                            index={index}
                            security={false}
                            hardware
                          />
                          <CheckBox
                            name="rex"
                            label="REX"
                            checked={values.doors[index].rex == 'RS-485HD'}
                            onChange={(e) =>
                              setFieldValue(
                                `doors[${index}].rex`,
                                e.target.checked ? 'RS-485HD' : 'None'
                              )
                            }
                          />
                          <CheckBox
                            name="door_monitor"
                            label="Door Monitor"
                            checked={
                              values.doors[index].door_monitor == 'RS485HD'
                            }
                            onChange={(e) =>
                              setFieldValue(
                                `doors[${index}].door_monitor`,
                                e.target.checked ? 'RS485HD' : 'none'
                              )
                            }
                          />
                        </article>
                      ))
                    : null}
                  {values.doors.length < 8 && (
                    <AuxiliaryButton
                      compact
                      onClick={() => {
                        values.doors.length < 8 &&
                          arrayHelpers.push(DOOR_DETAILS_CONST);
                      }}
                    >
                      Add Door
                    </AuxiliaryButton>
                  )}
                </>
              )}
            />
          </div>

          <FormFooter>
            <Button type="submit">Submit</Button>
            <CancelButton onClick={() => setRedirect(true)} />
          </FormFooter>
        </FormProvider>
      )}
    </Formik>
  );
};

export default Setup_Aperio;
