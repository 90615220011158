import React from 'react';
import { Button, CancelButton, AuxiliaryButton } from 'components/buttons';
import Controller from 'api/controller';
import FormProvider, { useForm } from 'providers/form-provider';
import { useMutationWrapper } from 'utils/ajax';
import { Redirect } from 'react-router-dom';
import DoorDetails from 'views/doors/door-details';
import ReaderDetails from 'views/controllers/reader-details';
import { useRedirect } from 'hooks/redirect-hook';
import { Formik, FieldArray } from 'formik';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import 'css/controllers.css';
import { FormFooter } from 'components/form/form-footer';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import { toast } from 'react-toastify';
import { useEventSource } from 'react-use-websocket';
import { useLayout } from 'providers/layout-provider';
import ProgressBar from 'components/progress-bar';
import useAlertDialog from 'providers/alert-dialog-provider';
const Heading = styled('h1')`
  color: var(--neutral-00);
  background: var(--badge-background-purple);
  border-radius: 4px;

  /* Inside Auto Layout */
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  padding: 24px;
  /* identical to box height */

  /* Neutrals/neutral-00 */

  display: flex;
  align-items: center;
  button {
    margin-left: auto;
    text-transform: uppercase;

    height: 24px;
  }
`;

const DOOR_DETAILS_CONST = {
  lock: '',
  double_lock: '',
  reader: '',
  reader_exit: '',
  hardware_id: '',
  name: '',
  access_time: 7,
  extendedaccesstime: 30,
  opentoolongtime: 25,
  extendedopentoolongtime: 50,
  prealarmtime: 5,
  door_monitor: null,
  priorityconfiguration: null,
  rex: '',
  io: '1'
};

const styles = css`
  color: var(--neutral-00);
  display: flex;
  gap: 16px;
  flex-direction: column;
  > button {
    margin-top: 24px;
  }
`;

const Msg = ({ closeToast, toastProps, ac }) => {
  const [eventCount, setEventCount] = React.useState(0);
  const stepCount = React.useRef(0);
  let filter = React.useCallback((data) => {
    if (data.type == 'setupProgress' && data.device_id == ac.device_id)
      return true;
    return false;
  }, []);
  const { getSocketUrl } = useLayout();

  const { lastEvent, readyState } = useEventSource(getSocketUrl, {
    share: true,
    filter: (e) => filter(JSON.parse(e.data)),
    withCredentials: true,
    retryOnError: true
  });

  React.useEffect(() => {
    if (!lastEvent) return;
    stepCount.current = JSON.parse(lastEvent.data).stepCount || 15;
    setEventCount((prev) => ++prev);
  }, [lastEvent]);

  function computeBgColor(value) {
    return 'var(--primary-base)';
  }

  return (
    <div
      css={css`
        background: transparent !important ;
        width: 100%;
        display: flex;
        width: 100%;
        white-space: none;

        flex-direction: column;
        gap: 0.2rem;
      `}
    >
      <label
        css={css`
          display: flex;
          width: 100%
          span:last-of-type {
            margin-left: auto;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            width: 100%;
          `}
        >
          <span>
            {lastEvent
              ? JSON.parse(lastEvent.data).message
              : 'Starting setup...'}
          </span>
          <span
            css={css`
              margin-left: auto;
              font-size: 12px;
              color: var(--light--others--red-400-base);
            `}
          >
            {readyState != 1 && readyState != 0 && 'Live events are off'}
          </span>
        </div>
        {lastEvent && stepCount.current > 0 && (
          <span
            css={css`
              margin-left: auto;
            `}
          >
            {Math.ceil(eventCount / (stepCount.current / 100))}%
          </span>
        )}
      </label>
      <ProgressBar
        height={14}
        completed={
          stepCount.current > 0
            ? Math.ceil(eventCount / (stepCount.current / 100))
            : 0
        }
        bgcolor={computeBgColor(
          Math.ceil(eventCount / (stepCount.current / 100))
        )}
      />
    </div>
  );
};

const SetupDoors = ({ ac, configuration }) => {
  const toastRef = React.useRef();
  const loading = () =>
    (toastRef.current = toast(<Msg ac={ac} />, {
      className: 'setup-toast',
      type: toast.TYPE.INFO,
      autoClose: false,
      closeOnClick: false,
      closeButton: false,
      pauseOnHover: true,
      draggable: false
    }));

  const error = (data) =>
    toast.update(toastRef.current, {
      className: '',
      type: toast.TYPE.ERROR,
      closeButton: true,
      closeOnClick: true,
      render: <div>{data?.message || 'General Error Occured '}</div>
    });

  const success = (data) =>
    toast.update(toastRef.current, {
      className: '',
      type: toast.TYPE.SUCCESS,
      closeButton: true,
      autoClose: 4000,
      closeOnClick: true,
      render: (
        <div>
          <span>{ac.name} is done configuring</span>
          {data?.data?.executionTime && (
            <time
              css={css`
                display: block;
                font-size: 12px;
              `}
            >
              Execution Time: {data?.data?.executionTime} seconds
            </time>
          )}
        </div>
      )
    });

  const { isSuccess, isLoading, mutate } = useMutationWrapper(
    ['controller', 'setup', ac.id],
    (input) => Controller.setup(input, ac?.id),
    {
      loadingMessage: `Setting up Controller ${ac.name}...`,
      onMutate: () => loading(),
      noToast: true,
      onSuccess: (data) => success(data),
      onError: (data) => error(data)
    }
  );

  const SCHEMA = Yup.object().shape({
    doors: Yup.array()
      .of(
        Yup.object().shape({
          lock: Yup.string().required('Required').nullable(),
          /**  double_lock: Yup.string().when((""), {
            is: Yup.array(),
            then: Yup.string().nullable().required("Reuired"),
          }),**/
          reader: Yup.string().required('Required').nullable(),
          // reader_exit: Yup.string().required("Required").nullable(),
          door_monitor: Yup.string().required('Required').nullable(),
          rex: Yup.string().required('Required').nullable()
        })
      )
      .required('Doors are required') // these constraints are shown if and only if inner constraints are satisfied
      .min(1, 'Minimum of 1 doors')
      .nullable()
  });

  const [initialValues, setInitialValues] = React.useState({ doors: [] });

  function parseDoubleLock(dl, dl_type, dl_relay) {
    if (dl_type == 'Relay') {
      if (dl_relay == 'Closed') return 'relay_locked';
      else return 'relay_unlocked';
    } else if (dl_type == 'Standard') {
      if (dl == '12V') return 'fail_safe';
      else return 'fail_secure';
    } else return 'None';
  }

  function parseLock(lock, lock_type, lock_relay) {
    if (lock_type == 'Relay') {
      if (lock_relay == 'Closed') return 'relay_locked';
      else return 'relay_unlocked';
    } else if (lock_type == 'Standard') {
      if (lock == '12V') return 'fail_safe';
      else return 'fail_secure';
    } else return 'None';
  }

  const parseReader = (readerType, readerLEDType) => {
    if (readerType == 'Wiegand') {
      if (readerLEDType == 'DualLED') return 'wiegand_double';
      else if (readerLEDType == 'SingleLED') return 'wiegand_single';
    } else if (readerType == 'RS-485HD') return 'osdp';
    else return 'none';
  };

  const parseDoorMonitor = (dm, dmType) => {
    if (dmType == 'None') return 'none';
    else if (dmType == 'Standard') {
      if (dm == 'Input Open') return 'open_circuit';
      else if (dm == 'Input Ground') return 'closed_circuit';
    } else if (dmType == 'RS485HD') {
      return 'RS485HD';
    }
  };

  React.useEffect(() => {
    setInitialValues({
      doors:
        configuration.length > 0
          ? configuration.map((door, index) => ({
              io: door.io ?? index + 1,
              lock:
                ac.product == 'A1001'
                  ? parseLock(door.lock, door?.lock_type, door?.lock_relay_type)
                  : door?.lock_type,
              double_lock:
                ac.product == 'A1001'
                  ? parseDoubleLock(
                      door?.double_lock,
                      door?.double_lock_type,
                      door?.double_lock_relay_type
                    )
                  : door?.double_lock_type,
              rex: door.rex_type,
              rex_token: door.rex_token,
              rex_open: door?.rex_action == 'Access',
              door_monitor: parseDoorMonitor(
                door.door_monitor,
                door.door_monitor_type
              ),
              reader: parseReader(door.reader_type, door.reader_led_type),
              reader_exit: parseReader(
                door.reader_exit_type,
                door.reader_exit_led_type
              ),
              token: door?.token,
              id: door?.id,
              reader_token: door.reader_token,
              reader_exit_token: door.reader_exit_token,
              name: door?.Name,
              description: door?.Description,
              access_time: door?.AccessTime?.replace(/\D/g, '') || 7,
              extendedaccesstime:
                door?.ExtendedAccessTime?.replace(/\D/g, '') || 30,
              opentoolongtime: door?.OpenTooLongTime?.replace(/\D/g, '') || 25,
              extendedopentoolongtime:
                door?.ExtendedOpenTooLongTime?.replace(/\D/g, '') || 50,
              prealarmtime: door?.PreAlarmTime?.replace(/\D/g, '') || 5,
              priorityconfiguration: door?.PriorityConfiguration,
              reader_ap: door?.reader_ap,
              reader_exit_ap: door.reader_exit_ap,
              rex_ap: door.rex_ap,
              pinConfiguration: door.pinConfiguration
            }))
          : [DOOR_DETAILS_CONST]
    });
  }, [configuration]);

  const { redirect, setRedirect } = useRedirect(isSuccess);

  const confirm = useAlertDialog();

  return (
    <Formik
      validationSchema={SCHEMA}
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={async (values) => {
        //clean the data
        if (ac.product == 'A1210') {
          delete values.doors[0].reader_exit;
          delete values.doors[0].double_lock;
        }
        if (values.doors.length > 1) {
          values.doors[0].reader_exit = 'none';
          values.doors[0].double_lock = 'None';
          values.doors[1].reader_exit = 'none';
          values.doors[1].double_lock = 'None';
        }

        if (ac.setup == 3) {
          if (
            await confirm({
              description:
                'All open schedules on existing doors will be removed.'
            })
          )
            mutate(values);
        } else mutate(values);
      }}
    >
      {({ handleSubmit, values }) => (
        <FormProvider mutateFunc={handleSubmit} disabled={isLoading}>
          {redirect && <Redirect to="/devices" />}

          <FieldArray
            name="doors"
            render={(arrayHelpers) => (
              <article css={styles}>
                {values.doors && values.doors.length > 0
                  ? values.doors.map((e, index) => (
                      <>
                        <Heading>
                          Door {index + 1}
                          {index > 0 && (
                            <AuxiliaryButton
                              compact
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              Remove
                            </AuxiliaryButton>
                          )}
                        </Heading>
                        <input
                          type="hidden"
                          name={`doors[${index}].io`}
                          value={3}
                        />
                        <DoorDetails ac={ac} index={index} security={false} />
                        <ReaderDetails ac={ac} index={index} />
                      </>
                    ))
                  : null}
                {values.doors.length < 2 && ac.product != 'A1210' && (
                  <AuxiliaryButton
                    compact
                    onClick={() => {
                      values.doors.length < 2 &&
                        arrayHelpers.push({ ...DOOR_DETAILS_CONST, io: '2' });
                    }}
                  >
                    Add Door
                  </AuxiliaryButton>
                )}
              </article>
            )}
          />

          <FormFooter>
            <Button disabled={ac.online == 0 && ac.setup == 3} type="submit">
              Submit
            </Button>
            <CancelButton onClick={() => setRedirect(true)} />
          </FormFooter>
        </FormProvider>
      )}
    </Formik>
  );
};

export default SetupDoors;
