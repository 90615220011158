import React from 'react';
import Template from 'base/template';
import { Button, CancelButton } from 'components/buttons';
import FormProvider from 'providers/form-provider';
import { Redirect } from 'react-router-dom';
import { useMutationWrapper } from 'utils/ajax';
import { useRedirect } from 'hooks/redirect-hook';
import DepartmentForm from 'views/departments/form';
import Department from 'api/department';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PageTitle from 'components/page-title';
import Content from 'base/content';
import Breadcrumbs from 'components/breadcrumbs';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useQueryWrapper } from 'utils/ajax';
import { useNotFound } from 'hooks/not-found-hook';
const Save = (props) => {
  const [dep, setDep] = React.useState(props?.location?.state?.dep);

  const { id } = useParams();

  const SCHEMA = Yup.object().shape({
    Name: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    sites: Yup.array().min(1, 'Select at least one option').nullable()
  });

  const INITIAL_VALUES = {
    Name: dep?.Name,
    Description: dep?.Description,
    sites: dep
      ? dep?.sites === false
        ? [0]
        : dep.sites.map((i) => Number(i))
      : []
  };

  const query = useQueryWrapper(
    ['department', 'get', id],
    () => Department.get(id),
    {
      enabled: Boolean(!dep && id)
    }
  );

  React.useEffect(() => {
    if (!query?.data) return;
    setDep(query.data);
  }, [query?.data]);

  useNotFound(id, query, props);

  const queryClient = useQueryClient();

  let { isLoading, mutate, isSuccess } = useMutationWrapper(
    ['department', 'save'],
    (input) => Department.save(input, dep?.id),
    { onSuccess: () => queryClient.resetQueries(['departments']) }
  );

  const { redirect, setRedirect } = useRedirect(isSuccess);

  return (
    <Template {...props}>
      <Content {...props}>
        {redirect && <Redirect to="/departments" />}

        <PageTitle>{dep ? dep?.Name : !id ? 'Department Add' : ''}</PageTitle>

        <Breadcrumbs crumbs={props.crumbs} />
        {(dep || !id) && (
          <Formik
            validationSchema={SCHEMA}
            initialValues={INITIAL_VALUES}
            onSubmit={(values) => {
              mutate(values);
            }}
          >
            {({ handleSubmit }) => (
              <FormProvider mutateFunc={handleSubmit} disabled={isLoading}>
                <DepartmentForm dep={dep} />
                <footer>
                  <Button type="submit">Submit</Button>
                  <CancelButton onClick={() => setRedirect(true)} />
                </footer>
              </FormProvider>
            )}
          </Formik>
        )}
      </Content>
    </Template>
  );
};

export default Save;
