import React from "react";
import { Tooltip, TooltipReference, useTooltipState } from "reakit/Tooltip";
import { useModal } from "providers/modal-provider";
import { Badge } from "components/badge";
import Label from "components/label";
import {
  ChevronDown,
  ChevronUp,
  QuestionCircleFill,
  Record2,
  Broadcast,
  ArrowClockwise,
} from "react-bootstrap-icons";
import ActionMenu from "views/controllers/action-menu";
import { dateTimeDif } from "utils/time";
import { AuxiliaryButton } from "components/buttons";
import Clipboard from "utils/clipboard";
import { css } from "@emotion/react";
import { getSelectionColumn } from "components/table-selection-column";
import StatusProgressBar from "./status-progress-bar";
/** @jsxImportSource @emotion/react */
import RestoreModal from "./restore-modal";
import { toast } from "react-toastify";

import { DialogDisclosure } from "reakit/Dialog";
import { useMutatePing } from "views/devices/actions";
import DeviceStatusFormatter from "views/devices/device-status-formatter";
import DeviceSiteFormatter from "views/devices/device-site-formatter";

function LastOnlineTimeHeader() {
  const tooltip = useTooltipState({ placement: "bottom-end" });
  return (
    <>
      <TooltipReference
        css={css`
          display: flex;
          gap: 8px;
          svg {
            opacity: 50%;
          }
        `}
        {...tooltip}
        as="div"
      >
        Online Time <QuestionCircleFill />
      </TooltipReference>
      <Tooltip
        css={css`
          box-sizing: border-box;
          color: rgb(255, 255, 255);
          background-color: rgba(33, 33, 33, 0.9);
          font-size: 0.8em;
          padding: 0.5rem;
          border-radius: 0.25rem;
          z-index: 999;
        `}
        {...tooltip}
      >
        Last online time for device.
      </Tooltip>
    </>
  );
}

export const columns = [
  getSelectionColumn(),
  {
    Header: "ID",
    accessor: "id",
  },

  {
    Header: "Name",
    accessor: "name",
    pickerDisabled: true,
    Cell: ({ row: { original } }) => {
      return (
        <>
          <strong>{original.name}</strong>
          {original.synced == 2 && (
            <>
              <br />
              <Badge
                css={css`
                  margin-top: 0.4rem;
                  margin-bottom: 0.4rem;
                  font-size: 10px;
                `}
                color="gray"
              >
                Syncing
              </Badge>
            </>
          )}
        </>
      );
    },
  },
  {
    Header: "Site",
    accessor: "site_name",
    Cell: ({ row: { original } }) => <DeviceSiteFormatter device={original} />,
  },
  {
    Header: "Type",
    accessor: "doors",
    Cell: ({ cell: { value }, row: { original } }) => {
      const TYPE = { 0: "Aperio", 1: "Doors", 2: "Doors", 3: "Elevator" };

      return (
        <Label>
          {TYPE[value]} ({original.door_count})
        </Label>
      );
    },
  },

  {
    Header: "Status",
    id: "online",
    Cell: ({ row }) => <DeviceStatusFormatter device={row.original} />,
  },
  {
    Header: "Model",
    accessor: "product",
    Cell: ({ row, cell }) => {
      return (
        <>
          <strong>{row.original.product}</strong> <br />
          <small>{row.original.firmware}</small>
        </>
      );
    },
  },
  {
    Header: "MAC",
    accessor: "mac",
    Cell: ({ cell: { value } }) => <Clipboard label={value} />,
  },
  {
    Header: "IP",
    accessor: "ip",
  },
  {
    Header: "LAN",
    accessor: "lan",
  },
  {
    Header: "Setup",
    accessor: "setup",
    style: { minWidth: "200px" },
    Cell: ({ row: { original } }) => <StatusProgressBar data={original} />,
  },
  {
    Header: "Subscription",
    Cell: ({ row: { original } }) => {
      return (
        <span>
          {original.subscription_termination_time} / {original.subscription_id}
        </span>
      );
    },
  },

  {
    Header: () => <LastOnlineTimeHeader />,
    accessor: "online_time",
    label: "Online Since",
    Cell: ({ cell: { value } }) => <span>{dateTimeDif(value)}</span>,
  },

  {
    Header: "UUID",
    accessor: "uuid",
  },
];
