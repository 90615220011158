import React, { useEffect, useState } from 'react';

import { ReactComponent as Logo } from 'assets/images/logo_blackback.svg';
import { ReactComponent as YSLogoDarkMode } from 'assets/images/ys-logo-white.svg';
import { ReactComponent as YSLogoLightMode } from 'assets/images/ys-logo.svg';
import { ReactComponent as LogoLightMode } from 'assets/images/cube-logo-dark.svg';
import { ReactComponent as Arc34Logo } from 'assets/images/arc34-logo.svg';

import { useTheme } from 'providers/theme';

// change to DARK LOGO oonce fixed
const LOGOS = {
  'app.cube-access.com': {
    dark: LogoLightMode,
    light: LogoLightMode
  },
  'app.demo.cube-access.com': {
    dark: LogoLightMode,
    light: LogoLightMode
  },
  'access.yoursixsecurity.com': {
    dark: YSLogoLightMode,
    light: YSLogoLightMode
  },
  'dooraccess.yoursixsecurity.com': {
    dark: YSLogoLightMode,
    light: YSLogoLightMode
  },
  'sandbox.yoursixsecurity.com': {
    dark: YSLogoLightMode,
    light: YSLogoLightMode
  },
  'localhost:3001': {
    dark: LogoLightMode,
    light: LogoLightMode
  },
  'localhost:3000': {
    dark: LogoLightMode,
    light: LogoLightMode
  },
  'portal-staging.arc34.com': {
    dark: Arc34Logo,
    light: Arc34Logo
  },
  'portal.arc34.com': {
    dark: Arc34Logo,
    light: Arc34Logo
  }
};

function RenderLogo(props) {
  const { theme } = useTheme();

  let TheLogo = LOGOS[location.host][props.login ? 'light' : theme ?? 'dark'];

  return <TheLogo {...props} />;
}
export default RenderLogo;
