import { countColumn } from 'codemirror';
import { intervalToDuration } from 'date-fns';

function dateTimeDuration(start, end) {
  const { days, hours, seconds, minutes } = intervalToDuration({
    start: new Date(start),
    end: new Date(end)
  });

  let str = '';

  if (days > 0) str = `${days} days`;

  if (hours > 0) str += (str ? ', ' : '') + `${hours} hours`;

  if (minutes > 0) str += (str ? ', ' : '') + `${minutes} min`;

  if (seconds > 0) str += (str ? ', ' : '') + `${seconds} sec`;

  return str;
}

function dateTimeLocal(dateString, timeZone = false) {
  if (!dateString) return;

  let opts = {
    minute: 'numeric',
    hour: 'numeric',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    timeZoneName: 'short'
  };

  if (timeZone) opts.timeZone = timeZone;

  try {
    dateString =
      typeof dateString == 'string'
        ? new Date(dateString)
        : dateString || new Date();
    return new Intl.DateTimeFormat(undefined, opts).format(dateString);
  } catch (e) {
    console.error(e);
  }
}

function dateTime(dateString, timeZone = false) {
  if (!dateString) return null;
  let opts = {
    second: 'numeric',
    minute: 'numeric',
    hour: 'numeric',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    timeZoneName: 'short'
  };

  if (timeZone) opts.timeZone = timeZone;

  try {
    dateString =
      typeof dateString == 'string'
        ? new Date(dateString.length > 19 ? dateString : dateString + 'Z')
        : dateString || new Date();
    return new Intl.DateTimeFormat(undefined, opts).format(dateString);
  } catch (e) {
    console.error(e);
  }
}

function dateOnly(dateString, showYear = false) {
  let opts = { month: 'short', day: 'numeric' };
  showYear && (opts.year = 'numeric');

  try {
    dateString =
      typeof dateString == 'string'
        ? new Date(dateString + 'Z')
        : dateString || new Date();
    return new Intl.DateTimeFormat(undefined, opts).format(dateString);
  } catch (e) {
    console.error(e);
  }
}

function timeOnly(dateString) {
  let opts = { hour: 'numeric', minute: 'numeric' };

  try {
    dateString =
      typeof dateString == 'string'
        ? new Date(dateString + 'Z')
        : dateString
        ? new Date(dateString)
        : new Date();
    return new Intl.DateTimeFormat(undefined, opts).format(dateString);
  } catch (e) {
    console.error(e);
  }
}

function hourOnly(dateString) {
  let opts = { hour: 'numeric' };

  try {
    dateString =
      typeof dateString == 'string'
        ? new Date(dateString + 'Z')
        : dateString || new Date();
    return new Intl.DateTimeFormat(undefined, opts).format(dateString);
  } catch (e) {
    console.error(e);
  }
}

function dateTimeShort(dateString) {
  let opts = {
    minute: 'numeric',
    hour: 'numeric',
    month: 'short',
    day: 'numeric'
  };

  try {
    dateString =
      typeof dateString == 'string'
        ? new Date(dateString + 'Z')
        : dateString
        ? new Date(dateString)
        : new Date();
    return new Intl.DateTimeFormat(undefined, opts).format(dateString);
  } catch (e) {
    console.error(e);
  }
}

function dateTimePrecise(dateString) {
  let opts = {
    second: 'numeric',
    minute: 'numeric',
    hour: 'numeric',
    hour12: false
  };

  try {
    dateString =
      typeof dateString == 'string'
        ? new Date(dateString + 'Z')
        : dateString || new Date();
    return (
      new Intl.DateTimeFormat(undefined, opts).format(dateString) +
      ':' +
      dateString.getMilliseconds()
    );
  } catch (e) {
    console.error(e);
  }
}

function dateTimeTS(timeStamp) {
  let opts = {
    second: 'numeric',
    minute: 'numeric',
    hour: 'numeric',
    hour12: false
  };

  let dateString = new Date(timeStamp);

  try {
    return (
      new Intl.DateTimeFormat(undefined, opts).format(dateString) +
      ':' +
      dateString.getMilliseconds()
    );
  } catch (e) {
    console.error(e);
  }
}

function SQLDateTimeToComponent(dateString, UTC = true) {
  if (!dateString) return;
  UTC = UTC ? 'Z' : '';

  return new Date(dateString.replace(' ', 'T') + UTC)
    .toLocaleString('sv')
    .replace(' ', 'T')
    .slice(0, -3);
}

function dateTimeLocalSQL(dateString) {
  let opts = {
    minute: 'numeric',
    hour: 'numeric',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    second: 'numeric',
    hour12: false
  };

  try {
    dateString =
      typeof dateString == 'string'
        ? new Date(dateString + 'Z')
        : dateString || new Date();
    return new Intl.DateTimeFormat('sv-SE', opts).format(dateString);
  } catch (e) {
    console.error(e);
  }
}

function dateTimeLocalSQLNoSeconds(dateString) {
  let opts = {
    minute: 'numeric',
    hour: 'numeric',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour12: false
  };

  try {
    dateString =
      typeof dateString == 'string'
        ? new Date(dateString + 'Z')
        : dateString || new Date();
    return new Intl.DateTimeFormat('sv-SE', opts).format(dateString);
  } catch (e) {
    console.error(e);
  }
}

function dateTimeDif(dateString, numeric = 'auto', UTC = true) {
  if (!dateString) return;

  UTC = UTC && dateString.length <= 19 ? 'Z' : '';

  let ref;

  if (typeof dateString == 'number') var diff = dateString;
  else diff = (new Date() - new Date(dateString + UTC)) / 1000 / 60;

  try {
    let d = new Intl.RelativeTimeFormat('en', { numeric });
    if (diff < 60 && -diff < 60) ref = 'minute';
    else if (diff < 1440 && -diff < 1440) {
      ref = 'hour';
      diff = diff / 60;
    } else {
      ref = 'day';
      diff = diff / 60 / 24;
    }
    return d.format(Math.round(-diff), ref);
  } catch (e) {
    console.error(e);
  }
}

function getPeriodSinceDate(dt) {
  const diff = Math.floor((dt.getTime() - Date.now()) / 1000);
  const rtf1 = new Intl.RelativeTimeFormat(undefined, { numeric: 'auto' });
  if (Math.abs(diff) >= 60 * 60 * 24 * 365)
    return rtf1.format(Math.floor(diff / (60 * 60 * 24 * 365)), 'year');
  if (Math.abs(diff) >= 60 * 60 * 24 * 30)
    return rtf1.format(Math.floor(diff / (60 * 60 * 24 * 30)), 'month');
  if (Math.abs(diff) >= 60 * 60 * 24 * 7)
    return rtf1.format(Math.floor(diff / (60 * 60 * 24 * 7)), 'week');
  if (Math.abs(diff) >= 60 * 60 * 24)
    return rtf1.format(Math.floor(diff / (60 * 60 * 24)), 'day');
  if (Math.abs(diff) >= 60 * 60)
    return rtf1.format(Math.floor(diff / (60 * 60)), 'hour');
  if (Math.abs(diff) >= 60) return rtf1.format(Math.floor(diff / 60), 'minute');
  return rtf1.format(Math.floor(diff), 'seconds');
}

function currentDTMilli() {
  return new Date().toLocaleString('en', {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    fractionalSecondDigits: 3
  });
}

export {
  currentDTMilli,
  dateOnly,
  dateTime,
  dateTimeDif,
  dateTimeLocal,
  timeOnly,
  dateTimePrecise,
  dateTimeLocalSQL,
  dateTimeShort,
  dateTimeLocalSQLNoSeconds,
  getPeriodSinceDate,
  SQLDateTimeToComponent,
  dateTimeDuration
};
