import React from 'react';
import { useQueryWrapper } from 'utils/ajax';
import MetricEvent from 'api/metric';
import ReactECharts from 'echarts-for-react';
import Events from 'routes/events';
import Device from 'api/device';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { DialogTrigger, DialogContent } from 'components/dialog.js';
import { Dialog } from '@radix-ui/react-dialog';

import { setMinutes, addMinutes, getMinutes, isWithinInterval } from 'date-fns';

function intervalsGroup(
  startDate = new Date(Date.now() - 1000 * 60 * 60),
  endDate = new Date(),
  interval = 15
) {
  // Round to the nearest 15

  let current = setMinutes(
    startDate,
    Math.ceil(getMinutes(startDate) / interval) * interval
  );
  let group = [];
  while (endDate > current) {
    group.push(current.getTime());
    current = addMinutes(current, interval);
  }
  return group;
}

function addMissingIntervals() {
  const allIntervals = [];
  prices.forEach((item, index) => {
    if (index !== prices.length - 1) {
      // if this isn't the last loop item
      const start = _.parseISO(item.price_time); // the datetime of the current loop item
      const end = _.parseISO(prices[index + 1].price_time); // the datetime of the next loop item
      allIntervals.push(...intervalsGroup(start, end, item.price)); // generate the 15m intervals between these two days
    }
  });
  allIntervals.push(prices[prices.length - 1]);
  return allIntervals;
}

const EventStreamChart = ({ device, filters = {}, ...props }) => {
  const [chartData, setChartData] = React.useState([]);
  const { interval = 15, timeframe = 60 } = filters;

  const { data, isLoading } = useQueryWrapper(
    ['event-stream', device.id, filters],
    () =>
      Device.readEventStream(
        { timestamp: Date.now() - 60 * 1000 * timeframe },
        device.id
      )
  );

  React.useEffect(() => {
    if (!data) return setChartData([]);

    const times = intervalsGroup(
      new Date(Date.now() - 1000 * 60 * timeframe),
      new Date(),
      interval
    );
    const groups = [],
      d = [];

    times.forEach((t) => {
      groups[t] = data.filter(
        (e) => e[0] <= t + 60 * 1000 * interval && e[0] >= t
      ).length;
    });

    for (const [key, value] of Object.entries(groups)) {
      d.push([Number(key), value]);
    }
    setChartData(d.reverse());
  }, [data]);

  const option = {
    legend: {
      show: !props.slim,
      // Try 'horizontal'
      orient: 'vertical',
      icon: 'rect',
      bottom: 0,
      left: 0
    },
    tooltip: props.slim
      ? {}
      : {
          trigger: 'axis'
        },
    grid: {
      left: 40,
      top: 40,
      right: 20,
      bottom: 50
    },

    animation: false,
    title: {
      text: 'Events P/Minute'
    },
    xAxis: {
      axisLine: { show: !props.slim },
      axisTick: { show: !props.slim },
      axisLabel: { show: !props.slim },
      type: 'time',
      interval: 8,
      splitLine: {
        show: true
      }
    },
    yAxis: {
      max: !chartData ? 200 : null,
      show: true,
      type: 'value'
    },
    series: [
      {
        name: `${device.name}`,
        lineStyle: {
          color: '#5470C6',
          width: 3
        },
        symbol: props.slim ? 'none' : 'circle',
        type: 'line',
        data: chartData,
        symbolSize: 10
      }
    ]
  };

  return (
    <ReactECharts
      opts={{ renderer: 'svg' }}
      loadingOption={{
        showSpinner: false,
        maskColor: 'var(--neutral-09)',
        fontWeight: 'normal',
        text: 'Fetching Data...',
        fontStyle: 'normal',
        fontFamily: 'inherit'
      }}
      showLoading={isLoading}
      option={option}
      style={{ height: props.height || '220px', width: '100%', padding: '4px' }}
    />
  );
};

export default EventStreamChart;
