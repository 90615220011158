import React from "react";

import Select from "react-select";
import { InputHelper, InputLabel, ErrorMessage } from "components/form";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import useFormValues from "hooks/form-values-hook";
import { wrapperStyles, customStyles } from "components/form/styles";
import { components } from "react-select";
import {
  Option,
  MultiValueLabel,
  MultiValueContainer,
  LimitedChipsContainer,
  ValueContainer,
  MultiValue,
  SingleValue,
} from "components/form/custom-components";

const SimpleSelect = React.forwardRef((props, ref) => {
  const {
    formProps,
    idx = "value",
    label = "label",
    options,
    isMulti = true,
    title = null,
    checkboxes = true,
    form,

    field,

    isSearchable,
  } = props;

  const [value, setValue] = React.useState();

  const onChange = (e) => setValue(e);

  return (
    <div css={wrapperStyles} className={"select-wrapper " + props?.className}>
      <InputLabel>{title}</InputLabel>
      <InputHelper {...props} />
      <Select
        checkboxes={checkboxes}
        styles={customStyles(props)}
        defaultOptions
        ref={ref}
        hideSelectedOptions={false}
        isMulti={isMulti}
        isSearchable={isSearchable}
        isClearable={true}
        idx={idx}
        label={label}
        getOptionLabel={(e) => e[label]}
        getOptionValue={(e) => e[idx]}
        closeMenuOnSelect={false}
        placeholder="Search..."
        value={value}
        options={options}
        onChange={onChange}
        components={{
          MultiValueLabel,
          MultiValueContainer,
          MultiValue,
          SingleValue,
          Option,
          ...props.itemComponents,
        }}
        {...props}
        className="select__container"
        classNamePrefix="select"
      />
      <ErrorMessage {...formProps} />
    </div>
  );
});

export default SimpleSelect;
