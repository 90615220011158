import { AuxiliaryButton, IconButton } from 'components/buttons';
import React from 'react';

import { css } from '@emotion/react';
import AddView from './add-view';
import { Button } from 'components/buttons';

import { useMutationWrapper } from 'utils/ajax';
import MonitoringView from 'api/monitoring_view';
/** @jsxImportSource @emotion/react */
import { useQueryClient } from 'react-query';
import {
  IconVideoPlus,
  IconColumns3,
  IconGridDots,
  IconTrash,
  IconPencil,
  IconTimelineEventPlus,
  IconPlus
} from '@tabler/icons-react';
import useAlertDialog from 'providers/alert-dialog-provider';
import { Toggle } from 'components/form';

const ViewControls = ({
  isEdit,
  setIsEdit,
  selectedView,
  setSelectedView,

  setViews,
  views
}) => {
  if (!selectedView) return null;

  const confirm = useAlertDialog();

  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutationWrapper(
    ['monitoring-view', 'save'],
    () =>
      MonitoringView.save(
        { layout: selectedView.layout, name: selectedView.name },
        selectedView.id
      ),
    {
      onSuccess: async (res) => {
        let index = views.findIndex((v) => v.id == selectedView?.id);

        if (index > -1) {
          let newViews = [...views];
          newViews[index] = {
            layout: selectedView.layout,
            name: selectedView.name,
            id: selectedView.id
          };
          setViews(newViews);
        } else {
          setViews((prev) => [
            ...prev,
            {
              layout: selectedView.layout,
              name: selectedView.name,
              id: res.view_id
            }
          ]);

          setSelectedView({
            layout: selectedView.layout,
            name: selectedView.name,
            id: res?.view_id
          });
        }
      }
    }
  );

  const { mutate: deleteMutate } = useMutationWrapper(
    ['monitoring-view', 'save'],
    () => MonitoringView.delete(selectedView.id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['monitoring']);
      }
    }
  );

  const handleDelete = async () => {
    if (
      await confirm({
        description: 'Are you sure you want to delete this view ?'
      })
    )
      deleteMutate();
  };

  return (
    <section
      css={css`
        grid-area: stack;
        place-self: end;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        height: 100%;
        margin-right: 1rem;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 1rem;
        `}
      >
        {isEdit && (
          <>
            <AddView
              selectedView={selectedView}
              setSelectedView={setSelectedView}
              setViews={setViews}
              views={views}
            >
              <IconButton>
                <IconPencil />
              </IconButton>
            </AddView>
            <IconButton
              title="Add timleline"
              disabled={true}
              onClick={() => {
                if (
                  selectedView.layout?.find((l) =>
                    ['widgets', 'cameras'].includes(l.type)
                  )
                )
                  return;

                setSelectedView((prev) => ({
                  ...prev,
                  layout: [...prev.layout, { type: 'cameras' }]
                }));
              }}
            >
              <IconTimelineEventPlus />
            </IconButton>
            <IconButton
              title="Add topbar panel"
              disabled={selectedView.layout?.find((l) => l?.type == 'bar')}
              onClick={() => {
                if (selectedView.layout?.find((l) => l?.type == 'bar')) return;

                setSelectedView((prev) => ({
                  ...prev,
                  layout: [{ type: 'bar' }, ...prev.layout]
                }));
              }}
            >
              <IconColumns3 />
            </IconButton>
            <IconButton
              title="Add widget panel"
              disabled={selectedView.layout?.find((l) =>
                ['widgets', 'cameras'].includes(l.type)
              )}
              onClick={() => {
                if (
                  selectedView.layout?.find((l) =>
                    ['widgets', 'cameras'].includes(l.type)
                  )
                )
                  return;

                setSelectedView((prev) => ({
                  ...prev,
                  layout: [...prev.layout, { type: 'widgets' }]
                }));
              }}
            >
              <IconGridDots />
            </IconButton>
            <Button
              disabled={isLoading}
              css={css`
                height: 36px;
              `}
              compact
              onClick={() => mutate()}
            >
              Save
            </Button>
            {selectedView.id && (
              <IconButton
                delete
                css={css`
                  height: 36px;
                `}
                compact
                onClick={handleDelete}
              >
                <IconTrash />
              </IconButton>
            )}
          </>
        )}
        {!isEdit && (
          <AddView
            setSelectedView={setSelectedView}
            isEdit={isEdit}
            setViews={setViews}
            setIsEdit={setIsEdit}
            views={views}
          >
            <AuxiliaryButton
              compact
              css={css`
                height: 36px;
              `}
            >
              <IconPlus />
              Add View
            </AuxiliaryButton>
          </AddView>
        )}
        <div
          css={css`
            height: 36px;
            padding-top: 1rem;
            padding-bottom: 1rem;
            padding-inline: 0.75rem;
            display: flex;
            align-items: center;
            color: var(--secundary-v-01);
            border: 1px solid var(--secundary-v-03);
            border-radius: 4px;
            font-size: 14px;
            font-weight: 500;
          `}
        >
          <Toggle
            label="edit mode"
            value={isEdit}
            onChange={() => setIsEdit((prev) => !prev)}
          />
        </div>
      </div>
    </section>
  );
};

export default ViewControls;
