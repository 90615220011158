import React from 'react';
import { css } from '@emotion/react';

import CameraDisks from 'views/cameras/camera-disks';
import CameraApps from 'views/cameras/camera-apps';
import {
  TabItem,
  TabGroup,
  TabPanel,
  TabPanels,
  TabList
} from 'components/tabs';
import CameraRules from 'views/cameras/camera-rules';
import CameraAudio from 'views/cameras/camera-audio';
import CameraPreferences from 'views/cameras/camera-preferences';
/** @jsxImportSource @emotion/react */

const mainStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  [role='tabpanel'] {
    padding: 2rem;
  }
`;
const CameraSettings = ({ camera, ...props }) => {
  if (!camera) return null;

  return (
    <main css={mainStyles}>
      <div>
        <TabGroup>
          <TabList
            css={css`
              width: 50vw;
              max-width: 50vw;
              disaply: flex;
              box-shadow: unset;
              gap: 4rem;
              padding: 0px 16px;

              border-top: 1px solid var(--neutral-12);
              border-bottom: 1px solid var(--neutral-12);

              [role='tab'] {
                min-width: unset;
              }
            `}
          >
            <TabItem title="Rules">Rules</TabItem>
            <TabItem title="Apps">Apps</TabItem>
            <TabItem title="General">General</TabItem>
            <TabItem title="Rules">Storage</TabItem>
            <TabItem title="Audio">Audio</TabItem>
          </TabList>
          <TabPanels>
            <TabPanel
              as="div"
              css={css`
                overflow: hidden;
                height: 100%;
                display: flex;
                flex-direction: column;
                padding: 16px;
              `}
            >
              <CameraRules camera={camera} />
            </TabPanel>
            <TabPanel as="div" css={css``}>
              <CameraApps camera={camera} />
            </TabPanel>
            <TabPanel as="div" css={css``}>
              <CameraPreferences camera={camera} />
            </TabPanel>
            <TabPanel as="div" css={css``}>
              <CameraDisks camera={camera} />
            </TabPanel>
          </TabPanels>
          <TabPanel
            as="div"
            css={css`
              width: 100%;
            `}
          >
            <CameraAudio deviceId={camera.device_id} />
          </TabPanel>
        </TabGroup>
      </div>
    </main>
  );
};

export default CameraSettings;
