import React from 'react';
import Template from 'base/template';

import Notification from 'api/notification';
import { columns } from 'views/notifications/table-columns';
//import Sidebar from "views/doors/sidebar";
import IndexContent from 'base/index-content';
import { PlugFill } from 'react-bootstrap-icons';
import ActionMenu from 'views/notifications/action-menu';
import Sidebar from './sidebar';
import GenericDoor from 'api/generic_door';

const cards = [
  {
    name: 'total',
    label: 'Total'
  },
  {
    name: 'is_delay',
    label: 'Delayed',
    filter: { delayed: 1 },
    icon: () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon-tabler icon-tabler-clock-pause"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        strokeWidth="1"
        stroke="currentColor"
        fill="none"
        strokeLineCap="round"
        strokeLineJoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M13 20.94a8.916 8.916 0 0 1 -7.364 -2.576a9 9 0 1 1 15.306 -5.342"></path>
        <path d="M12 7v5l2 2"></path>
        <path d="M17 17v5"></path>
        <path d="M21 17v5"></path>
      </svg>
    )
  },
  {
    name: 'is_interval',
    label: 'Interval',
    filter: { interval: 1 },
    icon: () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-clock-hour-11"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        strokeWidth="1"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
        <path d="M12 12l-2 -3"></path>
        <path d="M12 7v5"></path>
      </svg>
    )
  }
];

const Notifications = (props) => {
  const FILTERS = React.useMemo(
    () => [
      {
        id: 'recent',
        label: 'Recent Searches...',
        options: []
      },

      {
        open: true,
        id: 'filterBy',
        label: 'Filter By...',
        options: [
          {
            label: 'door w/ action',
            value: 'door',
            fetchFn: GenericDoor.search,
            field: 'actionsIncludeDoor',
            name: 'Door with action',
            id: 'door'
          }
        ]
      }
    ],
    []
  );
  return (
    <Template {...props}>
      <IndexContent
        statsQuery={Notification.getStats}
        title="Notifications"
        id="notifications"
        cards={cards}
        query={Notification.search}
        columns={columns}
        addButton="Add A Notification"
        sidebar={Sidebar}
        filterOptions={FILTERS}
        selectable
        actionButton={ActionMenu}
        {...props}
      ></IndexContent>
    </Template>
  );
};

export default Notifications;
