import React from 'react';
import Template from 'base/template';
import { useMutationWrapper } from 'utils/ajax';
import FormProvider from 'providers/form-provider';
import AlForm from 'routes/access-levels/form';
import { FormFooter } from 'components/form/form-footer';

import AccessLevel from 'api/access_level';

import { Formik } from 'formik';
import * as Yup from 'yup';
import { Button, CancelButton } from 'components/buttons';
import Content from 'base/content';
import PageTitle from 'components/page-title';
import Breadcrumbs from 'components/breadcrumbs';
import { useHistory } from 'react-router-dom';

const Save = (props) => {
  let al = props?.location?.state?.al || null;
  const history = useHistory();

  const SCHEMA = Yup.object().shape({
    name: Yup.string()
      .min(4, 'Too Short!')
      .max(40, 'Too Long!')
      .required('Required'),
    description: Yup.string()
      .min(4, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required')
      .nullable(),
    authentication_profile: Yup.string().required('Required').nullable(),
    valid_to: Yup.date()
      .max(new Date('2036-06-12T17:30'), 'Must be before 2036-06-12T17:30')
      .default(null)
      .when(
        'valid_from',
        (valid_from, Yup) =>
          valid_from &&
          Yup.min(valid_from, 'End time cannot be before start time').nullable()
      )
  });

  const sites = al?.sites;
  const INITIAL_VALUES = {
    name: al?.name,
    description: al?.description,
    authentication_profile: al?.auth_profile_token,
    valid_from: al?.ValidFrom
      ? al.ValidFrom
      : new Date().toLocaleString('sv').replace(' ', 'T').slice(0, -3),

    valid_to: al?.ValidTo ? al.ValidTo : '2036-06-01T11:30',

    sites: al
      ? al?.sites === false
        ? [0]
        : sites?.map((id) => Number(id))
      : []
  };

  let { isLoading, mutate } = useMutationWrapper(
    ['accessLevel', 'add', 'edit'],
    (input) => AccessLevel[al ? 'edit' : 'add'](input, al?.id),
    {}
  );

  return (
    <Template {...props}>
      <Content {...props}>
        {/* {redirect && (
          <Redirect
            to={props.elevatorsOnly ? '/elevators/access' : '/access-levels'}
          />
        )} */}
        <PageTitle>{al ? al.name : 'Access Level Add'}</PageTitle>
        <Breadcrumbs crumbs={props.crumbs} />
        <Formik
          validationSchema={SCHEMA}
          initialValues={INITIAL_VALUES}
          onSubmit={(values) => {
            {
              /* let obj = {};
            Object.entries(values.schedules).forEach(
              ([k, v]) => (obj[k] = v[0])
            ); */
            }
            const vf = new Date(values.valid_from).toISOString();
            const vt = new Date(values.valid_to).toISOString();

            mutate(
              { ...values, valid_from: vf, valid_to: vt },
              {
                onSuccess: (res) => {
                  if (!al && res.data.accessLevelId)
                    history.push(
                      `/access-levels/${res.data.accessLevelId}/manage-doors`,
                      {
                        desc: res.data.accessLevelName,
                        dialogOpen: true
                      }
                    );
                  else
                    history.push(
                      props.elevatorsOnly
                        ? '/elevators/access'
                        : '/access-levels'
                    );
                }
              }
            );
          }}
        >
          {({ handleSubmit }) => (
            <FormProvider mutateFunc={handleSubmit} disabled={isLoading}>
              <AlForm al={al} />
              {/* <SiteSelector {...props} al={al} /> */}
              <FormFooter>
                <Button type="submit">Submit</Button>
                <CancelButton
                  onClick={() =>
                    history.push(
                      props.elevatorsOnly
                        ? '/elevators/access'
                        : '/access-levels'
                    )
                  }
                />
              </FormFooter>
            </FormProvider>
          )}
        </Formik>
      </Content>
    </Template>
  );
};

export default Save;
