import 'css/sidebar.css';
import React from 'react';
import { Portal } from 'reakit/Portal';
import styled from '@emotion/styled';
import { CaretLeftFill, Grid1x2Fill, XLg } from 'react-bootstrap-icons';
import { useHeadlessTable } from 'providers/headless-table-provider';
import { useLocalStorage } from 'hooks/local-storage';
import { useHotkeys } from 'react-hotkeys-hook';

const SidebarWrapper = styled('aside')`
  color: var(--neutral-00);
  height: calc(100vh - 64px);
  z-index: 5;
  //overflow-x: visible;
  /* @media (max-width: 1900px) {
    display: none;
  } */
  overflow-x: visible;
  overflow-y: visible;
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: var(--neutral-07);
  position: relative;

  width: 0px;
  //  box-shadow: 0px 5px 20px -5px red;
  //box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  &[data-show='true'] {
    width: ${(props) => props.width || '400px'};
    button.sidebar-toggle > svg {
      transform: rotate(180deg);
    }
  }
  > button {
    position: absolute;
    width: 23px;
    height: 48px;
    top: 12px;
    left: -23px;
    z-index: 20;
    color: var(--neutral-00);

    cursor: pointer;
    border-right: 1px solid $var(--neutral-12);
    //box-shadow: 0px 1px 4px rgb(0 0 0 / 30%);
    border-radius: 8px 0px 0px 8px;
    background: var(--neutral-09) 7px center/7px 10px no-repeat;
  }
`;

const SidebarContent = styled('div')`
  height: 100%;
  background-color: var(--neutral-09);
  box-shadow: 0px 5px 20px -5px rgb(0 0 0 / 5%);
`;

export const withSidebar =
  (props) =>
  (WrappedSideBar, IndexStats = null) =>
  (moreProps) => {
    const { isSidebarOpen, setIsSidebarOpen } = moreProps;

    const [sidebarVisible, setSidebarVisible] = useLocalStorage(
      'sidebarVisible',
      true,
      true
    );

    const handleDismiss = (e) => setSidebarVisible((prev) => !prev);
    const [sidebar, setSidebar] = React.useState(sidebarVisible);

    useHotkeys('ctrl+a', () => setSidebar((prev) => !prev));

    React.useEffect(() => {
      if (isSidebarOpen === false || isSidebarOpen === true)
        setSidebarVisible(isSidebarOpen);
      else setSidebarVisible(sidebar);
    }, [sidebar, isSidebarOpen]);

    return (
      <SidebarWrapper data-show={sidebarVisible} {...props}>
        {props.showToggleButton !== false && (
          <button className="sidebar-toggle" onClick={handleDismiss}>
            <CaretLeftFill />
          </button>
        )}
        {sidebarVisible && (
          <SidebarContent>
            <WrappedSideBar {...moreProps} />
          </SidebarContent>
        )}
      </SidebarWrapper>
    );
  };
