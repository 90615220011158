import React from 'react';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */

import { withFormik, Form } from 'formik';

import SelectWrapper from 'components/select-wrapper';

import SMS from './sms-form';
import Email from './email-form';
import SiteAction from './site-action-form';
import { CancelButton } from 'components/buttons';
import { FormFooter } from 'components/form/form-footer';
import * as Yup from 'yup';
import { Button } from 'components/buttons';
import Webhook from './webhook-form';
import Dialog from 'components/modal/modal';

import { ModalTitle } from 'components/modal';
import { Check2Circle } from 'react-bootstrap-icons';

import Select from 'components/headless-select';
import { Formik } from 'formik';
import { ErrorMessage } from 'components/form';
import { useModal } from 'providers/modal-provider';
import DoorAction from './door-action-form';
import DoorSchedule from './door-schedule-form';
import WebhookForm from './webhook-form';
import IOPortTrigger from './io-port-trigger-form';
import { components } from 'react-select';

import ACTIONS from './action-types';

const SCHEMA = Yup.object().shape({
  sms: Yup.array()

    .when('type', {
      is: 'sms',
      then: Yup.array().min(1, 'Insert at least 1 phone number').nullable()
    })
    .nullable(true),
  email: Yup.array()

    .when('type', {
      is: 'email',
      then: Yup.array().min(1, 'Insert at least one email address').nullable()
    })
    .nullable(true),

  url: Yup.string()

    .when('type', {
      is: 'webhook',
      then: Yup.string().required('Please enter a url')
    })
    .nullable(true),

  door_id: Yup.string()
    .nullable()

    .when('type', {
      is: 'door_action',
      then: Yup.string().required('Please select a door').nullable()
    }),

  door_action: Yup.string()
    .nullable()

    .when('type', {
      is: 'door_action',
      then: Yup.string().required('Please select an action').nullable()
    }),

  site_id: Yup.string()
    .nullable()

    .when('type', {
      is: 'site_action',
      then: Yup.string().required('Please select a site').nullable()
    }),

  site_action: Yup.string()
    .nullable()

    .when('type', {
      is: 'site_action',
      then: Yup.string().required('Please select an action').nullable()
    }),

  camera_delay: Yup.number()
    .nullable()
    .when('type', {
      is: 'camera_action',
      then: Yup.number()
        .min(0, 'Must be between 0 and 30')
        .max(30, 'Must be between 0 and 30')
    })
});

const Option = ({ children, ...props }) => {
  const Icon = props.data.icon;

  return (
    <section>
      <components.Option
        {...props}
        css={css`
          padding: 8px;
          display: grid;
          grid-template-columns: 1fr 3fr;
          align-items: center;
          svg {
            width: 1.5em;
            height: 1.5em;
            margin-left: 1rem;
          }
        `}
      >
        <div>
          <Icon />
        </div>
        <div>
          {children}

          <br />
          <span
            css={css`
              opacity: 0.7;
              font-size: 12px;
            `}
          >
            {props.data.description}
          </span>
        </div>
      </components.Option>
    </section>
  );
};
const ActionModal = ({ onSubmit, action }) => {
  const { dialog } = useModal();
  const [actionType, setActionType] = React.useState(
    ACTIONS.find((f) => f.value == action?.type)
  );

  const [initialValues, setInitialValues] = React.useState(action || {});

  let Component = actionType?.component;

  React.useEffect(() => {
    if (action) return;
    if (!actionType?.value) return;
    let val = {};

    if (actionType.value == 'sms') val.sms = [];
    if (actionType.value == 'email') val.email = [];
    if (actionType.value == 'door_action') {
      val.door_id = '';
      val.door_action = '';
    }

    if (actionType.value == 'site_action') {
      val.site_id = '';
      val.site_action = '';
    }

    setInitialValues((prev) => ({ type: actionType.value, ...val }));
  }, [actionType, action]);

  return (
    <>
      <ModalTitle>{action ? 'Edit' : 'Add '} Action</ModalTitle>
      <div
        css={css`
          color: var(--neutral-00);
          width: 30vw;
          > * {
            padding: 1.5rem;
          }
          footer {
            display: flex;
            justify-content: space-between;
            margin-top: auto;
          }
          article:first-of-type {
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
          }
          h2 {
            margin-top: 0px;
            margin-bottom: 1rem;
          }
        `}
      >
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={SCHEMA}
          onSubmit={(values) => {
            onSubmit(values);
            dialog.hide();
          }}
        >
          {({ setFieldValue, errors, values }) => (
            <Form
              css={css`
                min-height: 40vh;
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
              `}
            >
              {action ? (
                <h2>{ACTIONS.find((f) => f.value == action?.type).label}</h2>
              ) : (
                <>
                  <label>Action</label>

                  <Select
                    data-compact
                    simple
                    placeholder="Select Action..."
                    css={css`
                      .select__menu-list {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                      }
                      input {
                        height: 40px;
                      }
                      margin-bottom: 1.5rem;
                      margin-top: 0.5rem;
                      > div.select__control--is-focused {
                        box-shadow: none;
                      }
                    `}
                    idx="value"
                    label="label"
                    isDisabled={action}
                    isSearchable={false}
                    defaultValue={ACTIONS.find((f) => f.value == action?.type)}
                    components={{ IndicatorSeparator: null, Option }}
                    options={ACTIONS}
                    onChange={(e) => {
                      setFieldValue('type', e.value);
                      setActionType(e);
                    }}
                  />
                </>
              )}
              {Component && <Component action={action} />}

              <footer>
                <Button compact disabled={!values.type} type="submit">
                  {action ? 'Save' : 'Add'}
                </Button>
                <CancelButton compact onClick={() => dialog.hide()}>
                  Cancel
                </CancelButton>
              </footer>
              {process.env.NODE_ENV == 'development' && (
                <>
                  <div>{JSON.stringify(errors)}</div>
                  <div>{JSON.stringify(values)}</div>
                </>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};
export default ActionModal;
