import React from 'react';
import { useHistory } from 'react-router-dom';
import { MenuWrapper, MenuItem, MenuItemDelete } from 'components/action-menu';

import { useDeleteMutate } from './actions';
import useAlertDialog from 'providers/alert-dialog-provider';
export default function ActionMenu({ data: sensor = {}, ...otherProps }) {
  const history = useHistory();

  const deleteMutate = useDeleteMutate(sensor);

  const confirm = useAlertDialog();
  let handleEdit = (e) =>
    history.push('/sensors/save/' + sensor?.id, {
      sensor,
      desc: sensor?.name
    });

  let handleAuth = (e) => {
    history.push('/sensors/auth/' + sensor?.id, {
      sensor,
      desc: sensor?.name
    });
  };

  let handleMonitoring = (e) =>
    history.push('/sensors/monitoring/' + sensor?.id, {
      sensor,
      desc: sensor?.name
    });

  let handleMetadata = (e) =>
    history.push('/sensors/metadata/' + sensor?.id, {
      sensor,
      desc: sensor?.name
    });

  const handleDelete = async () => {
    if (
      await confirm({
        description: 'Are you sure you want to remove this Sensor?'
      })
    )
      deleteMutate.mutate(sensor?.id);
  };
  return (
    <MenuWrapper {...otherProps}>
      <MenuItem label="Edit" onClick={handleEdit} />
      <MenuItem label="Authentication" onClick={handleAuth} />
      <MenuItem label="Monitoring" onClick={handleMonitoring} />
      <MenuItem label="Metadata" onClick={handleMetadata} />
      <MenuItemDelete onClick={handleDelete} />
    </MenuWrapper>
  );
}
