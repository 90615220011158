import React from 'react';
import { useQueryWrapper } from 'utils/ajax';
import Event from 'api/event';
import EventCard from 'components/event-card';
import Spinner from 'components/spinner';
import styled from '@emotion/styled';
import EmptyHolder from 'components/empty-holder';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
const EventWrapper = styled('article')`
  height: 100%;
  width: 100%;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  gap: 0.35rem;
  .spinner {
    display: block;
    margin: 24px auto;
  }
  label {
    color: var(--neutral-02);
    margin-right: 0.2rem;
    font-size: 12px;
    margin-left: 0.2rem;
  }
  > .event-list {
    overflow: auto;
  }
`;

const MoreLink = ({ device }) => {
  if (!device?.id) return null;
  else
    return (
      <Link
        to={{
          pathname: `devices/details/${device?.id}/events`,
          state: device,
          desc: device?.name
        }}
      >
        view more...
      </Link>
    );
};

const DeviceEventList = ({ device, ...props }) => {
  // const [filters, setFilters] = React.useState();

  // React.useEffect(() => {
  //   setFilters({
  //     device_id: device?.id,
  //     category: 'device',
  //     level: 'critical',
  //     since: '-7 day',
  //     ...props.filters
  //   });
  // }, [device, props.filters]);

  const filters = {
    device_id: device?.id,
    category: 'device',
    level: 'critical',
    since: '-7 day',
    ...props.filters
  };

  const { data, isLoading } = useQueryWrapper(
    ['device', 'critical', device?.id, filters],
    () => Event.search(filters),
    {
      enabled: Boolean(filters)
    }
  );

  if (data?.data?.length == 0)
    return (
      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          width: 100%;
        `}
      >
        <EmptyHolder label="No recent events" />
        <MoreLink device={device} />
      </div>
    );

  return (
    <EventWrapper>
      {isLoading && <Spinner />}
      {data?.data?.length > 0 && (
        <>
          <div
            css={css`
              display: flex;
              align-items: center;
              font-size: 12px;
              > a {
                margin-left: auto;
              }
            `}
          >
            {props.title && <label>Last 7 days</label>}
            <MoreLink device={device} />
          </div>

          <div className="event-list">
            {data?.data?.map((event) => {
              return <EventCard status ntCard key={event.id} data={event} />;
            })}
          </div>
        </>
      )}
    </EventWrapper>
  );
};

export default DeviceEventList;
