import React from 'react';
import { useHistory } from 'react-router-dom';

export const useNotFound = (isEdit, fetchQuery, props) => {
  const [notFound, setNotFound] = React.useState(false);
  const history = useHistory();

  React.useEffect(() => {
    if (fetchQuery.isError || fetchQuery.data?.data?.length == 0)
      history.replace(history.location.pathname, {
        errorStatusCode: 404,
        title: props.name,
        error: fetchQuery?.error?.message
      });
  }, [fetchQuery.isError, isEdit, fetchQuery.data]);

  return { notFound, setNotFound };
};
