import React from 'react';
import OverlayNoRecording from './overlay-no-recording';
import useRtspPipeline from './use-rtsp-pipeline';

import VideoElement from './video-element';
import { Layer } from 'components/video';
import Spinner from 'components/spinner';
import OverlayFetching from './overlay-fetching';
import OverlayOffline from './overlay-offline';
import OverlayError from './overlay-error';
import OverlayEnded from './overlay-ended';
import VideoControls from './video-controls';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react **/

const RtspVideo = ({
  waiting,
  setWaiting,
  currentTime,
  currentRecording,
  seek,
  camera,
  rtsp,
  play,
  forwardedRef,
  onPlaying,
  duration,
  onLoading,
  setCamera,
  videoProperties,
  host,
  live = false,
  webSocketURLParameters,
  onVapix,
  setRefresh
}) => {
  let videoRef = React.useRef(null);

  // Forwarded refs can either be a callback or the result of useRef
  if (typeof forwardedRef === 'function') {
    forwardedRef(videoRef.current);
  } else if (forwardedRef) {
    videoRef = forwardedRef;
  }

  const { pipeline, canplay, playing, fetching, error, setFetching, ended } =
    useRtspPipeline({
      recordingTime: currentTime,
      recordingId: currentRecording,
      seek,
      videoRef,
      camera,
      rtsp,
      play,
      onPlaying,
      onLoading,
      duration,
      webSocketURLParameters
    });

  //waiting is an outside prop to wait for any other info.

  // there is a bug here for split secons pipeline doesnt exist... maybe start fetching when pipeline is just building ?

  return (
    <>
      {camera?.online == '0' && <OverlayOffline />}
      {error && <OverlayError err={error} />}
      {ended && pipeline && <OverlayEnded />}

      {camera?.online == '1' && (fetching || waiting) && !error && (
        <OverlayFetching />
      )}
      {camera?.online == '1' &&
        !pipeline &&
        !canplay &&
        !live &&
        !playing &&
        !fetching &&
        !waiting &&
        !currentRecording && <OverlayNoRecording />}

      <VideoElement
        seek={seek}
        setRefresh={setRefresh}
        videoProperties={videoProperties}
        currentTime={currentTime}
        ref={videoRef}
        currentRecording={currentRecording}
        pipeline={pipeline}
        play={play}
        onVapix={onVapix}
        host={host}
      />
    </>
  );
};

export default RtspVideo;
