import React from "react";

import { useFormikContext, useField, Form } from "formik";
import genericDoorSelect from "components/form/generic-door-select";
import { Field } from "formik";
import { css } from "@emotion/react";
import AsyncSelect from "components/headless-select/async";
import Person from "api/person";
import { TextField } from "components/form";
import SelectWrapper from "components/headless-select";
import { Toggle } from "components/form";
/** @jsxImportSource @emotion/react */
import { components } from "react-select";
import { RadioGroup } from "@headlessui/react";
import Camera from "api/camera";
import LicensePlate from "api/license_plate";
import { useQuery } from "react-query";
import Spinner from "components/spinner";
const styles = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  height: 48px;
  cursor: pointer;

  border: 1px solid var(--neutral-12);
  border-radius: 4px;
  [role="radiogroup"] {
    background: var(--neutral-09);
  }
  [role="radio"] {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    height: 100%;
  }
  [data-value="1"] {
    &[aria-checked="true"] {
      background-color: var(--primary-base);
      color: white;
    }
  }
  [data-value="0"] {
    &[aria-checked="true"] {
      background-color: var(--red-base);
      color: white;
    }
  }
`;

const Cameras = (props) => {
  let enabled = Boolean(props?.lp?.id);

  const { data } = useQuery(
    ["license-plate", "cameras", `${props?.lp?.id}`],
    () => LicensePlate.searchCameras(props?.lp.id),
    { enabled, cacheTime: 0 }
  );

  if (data || !enabled) {
    return (
      <Field
        title="Cameras"
        name="cameras"
        isMulti={true}
        component={AsyncSelect}
        defaultOption={data && data.map((i) => parseInt(i.camera_id))}
        fetchFn={Camera.searchLPR}
      />
    );
  } else return <Spinner />;
};

const LicensePlateForm = (props) => {
  const { values, setFieldValue } = useFormikContext();

  return (
    <section>
      <RadioGroup
        css={styles}
        value={values.enabled}
        onChange={(e) => setFieldValue("enabled", e)}
      >
        <RadioGroup.Option data-value="0" value="0">
          Disabled
        </RadioGroup.Option>

        <RadioGroup.Option data-value="1" value="1">
          Enabled
        </RadioGroup.Option>
      </RadioGroup>
      <br />

      <Field name="person" component={AsyncSelect} fetchFn={Person.search} />
      <br />
      <Field as={TextField} name="text" label="License Plate Number" />
      <Field as={TextField} name="description" />
      <div
        className="cameras"
        css={css`
          grid-column: 1/-1;
        `}
      >
        <Cameras {...props} />
      </div>
    </section>
  );
};

export default LicensePlateForm;
