import React from 'react';
import { getSelectionColumn } from 'components/table-selection-column';
import { Badge } from 'components/badge';
import DoorSchedules from 'views/doors/door-schedules';
export const columns = [
  getSelectionColumn(),

  {
    asc: 'atoz',
    desc: 'ztoa',
    newSorter: true,
    Header: 'Name',
    accessor: 'floor_name',
    pickerDisabled: true
  },
  {
    Header: 'Open Times',
    accessor: 'schedule_names',

    Cell: ({ row: { original: door } }) => <DoorSchedules door={door} />
  },

  {
    Header: 'State',
    accessor: 'state'
  },
  //only showing to installers
  {
    role: ['A', 'V'],
    Header: 'Remote Device',
    accessor: 'remote_device_name'
  }
];
