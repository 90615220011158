import React from "react";

const InvertLabel = ({ color, children }) => {
  let bgColor = `border-${color}-500`;
  let textColor = `text-${color}-500`;
  return (
    <span
      className={`text-xs p-[3px] font-medium  ${bgColor} 
    ${textColor} border-2 rounded-md align-middle`}
    >
      {children}
    </span>
  );
};

export default InvertLabel;
