import React from 'react';
import CameraAction from 'api/camera_action';
import { useInfiniteQuery } from 'react-query';
import { useQueryWrapper } from 'utils/ajax';
import { eachDayOfInterval } from 'date-fns';

const useListRecording = ({ camera, recordingZoom }) => {
  const [recordings, setRecordings] = React.useState([]);
  const fetchRecording = ({ pageParam = 1 }) =>
    CameraAction.listEdgeRecordings({
      page: pageParam,
      id: camera.id,
      // starttime: recordingZoom.start,
      stoptime: recordingZoom.stop,
      sortorder: 'descending',
      maxnumberofresults: 100
    });
  const {
    data: pastRecording,
    fetchNextPage,
    hasNextPage
  } = useInfiniteQuery([`edge`, `recording`, camera?.id], fetchRecording, {
    getNextPageParam: (lastPage, pages) => {
      return pages.length < lastPage.last_page ? pages.length + 1 : undefined;
    },

    refetchOnMount: 'always',
    refetchOnWindowFocus: true,
    // staleTime: 60 * (60 * 1000), // unliikely this need to be refetched unless actrually deleted on camera
    // cacheTime: 65 * (60 * 1000), //
    enabled: Boolean(recordingZoom) && camera.online == '1'
  });

  const { data: contRecording } = useQueryWrapper(
    [`edge`, `recording`, 'continues', camera?.id],
    () =>
      CameraAction.listEdgeRecordings({
        id: camera.id,

        sortorder: 'descending',
        starttime: new Date(Number(new Date().getTime() - 120000)).toISOString()
      }),
    {
      enabled: camera.online == '1',
      refetchInterval: 30000,
      refetchIntervalInBackground: true,
      noToast: true
    }
  );

  React.useEffect(() => {
    if (pastRecording);
    if (hasNextPage) fetchNextPage();
  }, [pastRecording]);

  let daysWithRecording = React.useMemo(() => {
    let arr = [];
    pastRecording?.pages.forEach((group) => {
      group.data.forEach((rec) => {
        // loop for all results and take into account continues
        const result = eachDayOfInterval({
          start: new Date(rec.starttime),
          end: rec.stoptime ? new Date(rec.stoptime) : new Date()
        });
        result?.forEach((r) => {
          arr[r.toLocaleDateString()] = 1;
        });
      });
    });
    return arr;
  }, [pastRecording]);

  React.useEffect(() => {
    let arr = [...recordings];
    pastRecording?.pages.forEach((group) => {
      group.data.forEach((rec) => {
        let index = arr.findIndex((r) => r.recordingid == rec.recordingid);
        if (index > -1) arr[index] = rec;
        else arr.push(rec);
      });
    });
    setRecordings(arr);
  }, [pastRecording]);

  React.useEffect(() => {
    if (contRecording?.data?.length == 0) return;

    // let start = new Date(rec.starttime).getTime();
    // let stop = rec.stoptime ? new Date(rec.stoptime).getTime() : null;
    // let cr = {
    //   rec: rec.recordingid,
    //   name: 'sd-card',
    //   value: [0, start, stop, stop - start],
    //   duration: Math.ceil((stop - start) / 1000),
    //   itemStyle: {
    //     normal: {
    //       color: '#00A96C'
    //     }
    //   }
    // };
    const arr = [...recordings];

    contRecording?.data.forEach((cr) => {
      let index = arr.findIndex((rec) => rec.recordingid == cr.recordingid);
      if (index == -1)
        //push only if not there
        arr.push(cr);
      else {
        //if same recording only change stop time
        arr[index] = {
          ...arr[index],

          stoptime: cr.stoptime || null
        };
      }
    });

    setRecordings(arr);
  }, [contRecording]);

  return { pastRecording, contRecording, daysWithRecording, recordings };
};

export default useListRecording;
