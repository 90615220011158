import { getSelectionColumn } from "components/table-selection-column";
import { dateTimeDif } from "utils/time";
import { css } from "@emotion/react";
/** @jsxImportSource @emotion/react */

export const columns = [
  getSelectionColumn(),
  {
    Header: "Description",
    accessor: "description",
  },
  {
    Header: "Badges Printed",
    accessor: "badge_count",
  },
  {
    Header: "Background Image",
    accessor: "background_image_url",
    Cell: ({ row }) =>
      row.values.background_image_url ? (
        <object
          css={css`
            height: 48px;
            border-radius: 8px;
            border: 1px solid #aaa;
          `}
          data={row.values.background_image_url}
        />
      ) : (
        "No image"
      ),
  },
  {
    Header: "Created At",
    accessor: "created_at",
    Cell: ({ row }) => dateTimeDif(row.values.created_at),
  },
];
