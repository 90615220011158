import React from "react";

import AsyncSelect from "react-select/async";
import { components } from "react-select";
import { ButtonLoader } from "components/buttons";
import { ThemeContext } from "providers/theme";
import debounce from "debounce-promise";
import { useField } from "formik";
import { InputHelper, InputLabel } from "./form";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { XLg } from "react-bootstrap-icons";
import { ErrorMessage } from "./form";

const controlStyles = {};

const SingleValue = ({ children, ...props }) => {
  return (
    <components.SingleValue {...props}>
      <div
        css={css`
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 16px;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 4px 12px;
          background: var(--singleValue-background-color);
          border-radius: 50px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        `}
      >
        {children}
      </div>
    </components.SingleValue>
  );
};

const MultiValueContainer = ({ children, ...props }) => {
  return (
    <components.MultiValueContainer {...props}>
      <div
        css={css`
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 16px;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 8px 12px;
          background: var(--multiValue-background-color);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        `}
      >
        {children}
      </div>
    </components.MultiValueContainer>
  );
};

const MultiValueLabel = ({ children, ...props }) => {
  return (
    <components.MultiValueLabel {...props}>
      <div
        css={css`
          font-weight: bold;
          font-size: 12px;
          line-height: 16px;

          color: var(--neutral-00);
        `}
      >
        {children}
      </div>
    </components.MultiValueLabel>
  );
};

const MultiValueRemove = ({ children, ...props }) => {
  return (
    <components.MultiValueRemove {...props}>
      <XLg />
    </components.MultiValueRemove>
  );
};

const ControlComponent = (props) => {
  return (
    <div style={controlStyles}>
      <InputLabel label={props.selectProps.title} />
      <InputHelper helper={props.selectProps.helper} />
      <components.Control {...props} />
    </div>
  );
};

const Option = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <div className="flex">
        {children}
        <span className="ml-auto">
          <Label icon="people">{props.data?.person_count}</Label>
        </span>
      </div>
    </components.Option>
  );
};

const wrapperStyles = css`
  > .error-message {
    margin-top: -18px;
  }
`;

const AsyncSelectWrapper = React.forwardRef(
  (
    {
      fetchFn,
      initialData,
      title,
      helper,
      searchTerm,
      label,
      idx,
      transformData,
      initialLoad,
      ...props
    },
    ref
  ) => {
    // Props
    // dataObject = put inside the data for provider
    const { theme } = React.useContext(ThemeContext);

    const customStyles = {
      container: (provided, state) => ({
        ...provided,
        minWidth: "250px",
        width: "100%",
        maxWidth: "348px",
        height: "fit-content",

        opacity: state.isDisabled ? 0.7 : 1,
      }),

      control: (styles, state) => ({
        ...styles,

        maxWidth: "400px",
        minHeight: props["data-compact"] ? "40px" : "48px",
        background: "var(--neutral-09)",
        border: "1px solid var(--neutral-12)",
        boxSizing: "border-box",
        borderRadius: "5px",
        cursor: state.selectProps.isSearchable ? "text" : "pointer",
      }),
      valueContainer: (base) => ({
        ...base,
        width: "100%",
        maxHeight: "200px",
        overflowY: "auto",
      }),
      singleValue: (base) => ({
        ...base,
        color: "var(--primary-base)",
        background: "var(--singleValue-background-color)",
        width: "100%",
      }),

      indicatorSeparator: (base) => ({
        ...base,
        margin: "0px",
      }),
      multiValue: (styles, { data }) => {
        return {
          ...styles,
          backgroundColor: "green",
          borderRadius: "50px",
        };
      },
      multiValueRemove: (styles, { data }) => {
        return {
          ...styles,
          "&:hover": {
            background: "none",
            color: "green",
          },
        };
      },

      input: (style) => ({
        border: "none",
        outline: "none",
        border: "none",
      }),
      menu: (styles) => ({
        ...styles,
        background: "var(--neutral-09)",

        maxWidth: "400px",
        width: "100%",
        zIndex: "12",
      }),
      menuList: (base) => ({
        ...base,
        background: "var(--neutral-09)",
        "::-webkit-scrollbar": {
          width: "14px",
        },
        "::-webkit-scrollbar-track": {
          background: "#ccc",
        },
        "::-webkit-scrollbar-thumb": {
          background: "#888",
          background: "#555",
          borderRadius: "12px",
        },
        "::-webkit-scrollbar-thumb:hover": {
          background: "#555",
        },
      }),

      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          color: "var(--neutral-00)",
          ...styles,
          padding: props["data-compact"] ? "8px" : "16px",
          color: "var(--neutral-00)",

          backgroundColor: isFocused
            ? "var(--neutral-10)"
            : "var(--neutral-09)"
            ? "AADAAAi"
            : "var(--neutral-09)",
        };
      },
    };

    //const [value, setValue] = React.useState();
    // TODO myab bug?
    const [defaultValue, setDefaultValue] = React.useState();

    const loadOptions = async (inputValue, callback) => {
      let searchParams = {};

      //usually searech by labelk but also accept searchTerm as param
      searchParams[searchTerm || label] = inputValue;
      console.log("calling async");
      //TODO pass iniital data as selection automaticall ??
      let i = await fetchFn(searchParams).then((e) => e.data || e);

      if (typeof transformData == "function") i = transformData(i);

      //put in array if multi select , single value otherwise
      //TODO find better way to decide not multi
      console.log("isMulti", props.isMulti);

      //this actually looks ata  string not array possible bug
      // now converting all JSON to objects in backed so wortks fine

      console.log("after async", fetchFn);
      let values = i.filter((o) => initialData.includes(o[idx].toString()));
      console.log("values", values);

      !defaultValue && setDefaultValue(values);
      ref.current.data = values.map((i) => i[idx]);
      ref.current.fullData = values.map((i) => ({ ...i }));
      console.log("data insidse ref", ref.current.data);
      return i;
    };

    const debouncedLoadOptions = debounce(loadOptions, 1000, {
      leading: false,
    });

    const [internalValue, setInternalValue] = React.useState();
    const [, , helpers] = useField(
      props.dataObject /* || props.name || "default"*/
    );
    const { setValue } = helpers;

    React.useEffect(() => {
      /**       if(defaultValue && props.dataObject) {
        //only put in form if dataObject id present
        let data = {}

        /// this is a bug make sure initialData above is not always set to array
        data[props.dataObject] = props.isMulti === false ? defaultValue?.map(i=> i[idx])[0] : defaultValue?.map(i => i[idx]);

        setFormData(prev => ({...prev,...data}));
      }**/

      setInternalValue(defaultValue);
      if (typeof props.setDefaultOption == "function")
        props.setDefaultOption(defaultValue);

      let data =
        props.isMulti === false
          ? defaultValue?.map((i) => i[idx])?.[0] ?? null
          : defaultValue?.map((i) => i[idx]);

      if (typeof setValue == "function" && props.dataObject) setValue(data);
    }, [defaultValue]);

    //let {setFormData} = useForm();//

    return (
      // accepts a ref and or a form ref
      <div css={wrapperStyles}>
        <AsyncSelect
          styles={customStyles}
          loadOptions={debouncedLoadOptions}
          ref={ref}
          defaultOptions
          className="async-select select__wrapper"
          classNamePrefix="select"
          isMulti
          isClearable
          getOptionLabel={(e) => e[label]}
          getOptionValue={(e) => e[idx]}
          closeMenuOnSelect={false}
          placeholder="Type to search..."
          components={{
            SingleValue,
            MultiValueLabel,
            MultiValueContainer,
            MultiValueRemove,
            Control: ControlComponent,
            ...props.itemComponents,
          }}
          onChange={(e) => {
            /**   if(props.dataObject) {
            let data = {}
            data[props.dataObject] = e ? Array.isArray(e) ? e.map(i => i[idx]) : e[idx] : null;
            setFormData(prev => ({...prev,...data}));
          }**/

            setInternalValue(e);

            //put data ionside ref to pull ouside of form
            ref.current.data = e
              ? Array.isArray(e)
                ? e.map((i) => i[idx])
                : e[idx]
              : null;
            ref.current.fullData = e;
            let data = e
              ? Array.isArray(e)
                ? e.map((i) => i[idx])
                : e[idx]
              : null;
            if (typeof setValue == "function") setValue(data);
            //handle outside funcitons
            if (typeof props.onChangeFunc == "function") props.onChangeFunc(e);
          }}
          //onBlur={setFormikTouched}
          value={internalValue}
          label={label}
          {...props}
          title={title}
          helper={helper}
        />
        {props.dataObject && (
          <ErrorMessage name={props.dataObject} {...props} />
        )}
      </div>
    );
  }
);

export default AsyncSelectWrapper;
