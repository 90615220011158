import { Card } from './card';
import {
  IconAlertTriangle,
  IconCalendarEvent
} from '@tabler/icons-react';
import { Button } from 'components/buttons';
import { css } from '@emotion/react';
import { useMutateRestoreEvents } from 'views/controllers/actions';
import useAlertDialog from 'providers/alert-dialog-provider';
/** @jsxImportSource @emotion/react */

export default ({ device }) => {
  const confirm = useAlertDialog();
  const mutateRestoreEvents = useMutateRestoreEvents(device);

  const handleRestoreEvents = async () => {
    if (
      await confirm({
        description: `Are you sure you want to restore Events on: ${device?.name} ?\n This might take a while`
      })
    )
      mutateRestoreEvents.mutate(device?.controller_id);
  };
  return (
    <Card classname="restore-events">
      <div className="icon">
        <IconCalendarEvent />
      </div>
      <div className="content">
        <h2>Events Restore</h2>
        <span className="description">
          Restore events. Access events that were lost durgin network traffic
          will be resotred and synced with database.
        </span>
      </div>
      <div className="action">
        <Button onClick={handleRestoreEvents} compact>
          Restore
        </Button>
      </div>
      <div
        className="data"
        css={css`
          span {
            display: flex;
            gap: 0.5rem;
            align-items: center;
            font-weight: 500;
            color: var(--light--others--red-400-base);
          }
        `}
      >
        {device?.lost_events && (
          <span>
            <IconAlertTriangle />
            Lost events in the past 7 days.
          </span>
        )}
      </div>
    </Card>
  );
};
