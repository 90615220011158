import React from 'react';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { VolumeOff, VolumeUp } from 'react-bootstrap-icons';
import TransmitAudio from 'views/intercoms/transmit-audio';
import { useQueryWrapper } from 'utils/ajax';
import Intercom from 'api/intercom';
import { useAccessMutate } from 'views/doors/actions';
import Camera from 'api/camera';

const DoorOpenIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-door-enter"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M13 12v.01"></path>
      <path d="M3 21h18"></path>
      <path d="M5 21v-16a2 2 0 0 1 2 -2h6m4 10.5v7.5"></path>
      <path d="M21 7h-7m3 -3l-3 3l3 3"></path>
    </svg>
  );
};

export default ({ camera }) => {
  const { mutate, isLoading } = useAccessMutate(camera);

  if (!camera?.generic_door_id && !camera?.intercom_relay_door_id) return null;

  return (
    <article className="door-control">
      <button
        disabled={isLoading}
        onClick={() =>
          mutate(camera.generic_door_id ?? camera.intercom_relay_door_id)
        }
      >
        <DoorOpenIcon />
      </button>
    </article>
  );
};
