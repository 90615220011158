import React from 'react';
import Template from 'base/template';

import Schedule from 'api/schedule';
import { columns } from 'views/schedules/table-columns';
import Sidebar from 'views/schedules/sidebar';
import IndexContent from 'base/index-content';
import { Check } from 'react-bootstrap-icons';
import ActionMenu from 'views/schedules/action-menu';
import { useAuth } from 'providers/auth';

const cards = [
  {
    name: 'total',
    label: 'Total'
  },

  {
    name: 'active',
    label: 'Active',
    icon: Check,
    color: 'var(--primary-base)',
    filter: { active: true },
    background: 'var(--stats-background-green)'
  }
];
const Schedules = (props) => {
  const { enforce, user } = useAuth();
  return (
    <Template {...props}>
      <IndexContent
        initialFilter={{
          hasSite: user.focused_site,

          // TODO remove this when this logic is moved to Event Server
          nextOccurrence: true
        }}
        statsQuery={Schedule.stats}
        title="Schedules"
        id="schedules"
        cards={cards}
        newSorter
        query={Schedule.search}
        columns={columns}
        addButton={
          enforce({ restrictIfCompanyUser: ['scheduleEdit'] })
            ? 'Add a Schedule'
            : false
        }
        sidebar={Sidebar}
        selectable
        actionButton={ActionMenu}
        {...props}
      ></IndexContent>
    </Template>
  );
};

export default Schedules;
