import {
  createContext,
  useContext,
  useState,
  useCallback,
  useRef
} from 'react';
import {
  Dialog,
  DialogAction,
  DialogCancel,
  DialogContent
} from 'components/alert-dialog';
import { CancelButton, Button } from 'components/buttons';

/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
const AlertDialog = createContext();

export function AlertDialogProvider({ children }) {
  //https://akashhamirwasia.com/blog/building-expressive-confirm-dialog-api-in-react/#conclusion
  const [state, setState] = useState({});
  const [open, setOpen] = useState();

  const fn = useRef();

  const confirm = useCallback(
    (data) => {
      return new Promise((resolve) => {
        setOpen(true);
        setState(data);

        fn.current = (choice) => {
          resolve(choice);
          setOpen(false);
        };
      });
    },
    [state]
  );
  return (
    <AlertDialog.Provider value={confirm}>
      {children}

      <Dialog type={state.type || 'confirm'} open={open} onOpenChange={setOpen}>
        <DialogContent>
          <div
            css={css`
              display: flex;
              align-items: start;
              flex-direction: column;
              gap: 0.5rem;
              width: 100%;
              font-weight: 500;
              min-width: 0;
              min-height: 0;

              word-break: break-word;
              max-width: 100%;
            `}
          >
            <p
              css={css`
                line-height: 22px;
                white-space: pre-line;
                text-overflow: ellipsis;
                overflow: hidden;
              `}
            >
              {state.description}
            </p>
            {state.info && (
              <small
                css={css`
                  display: block;
                  font-size: 12px;
                  color: #59606c;
                `}
              >
                {state.info}
              </small>
            )}
          </div>
          <footer id="footer">
            {!state.alert && (
              <DialogCancel compact asChild={true}>
                <CancelButton onClick={() => fn.current(false)} />
              </DialogCancel>
            )}
            <DialogAction asChild={true}>
              <Button compact onClick={() => fn.current(true)}>
                {state.actionLabel || 'Confirm'}
              </Button>
            </DialogAction>
          </footer>
        </DialogContent>
      </Dialog>
    </AlertDialog.Provider>
  );
}

export default function useAlertDialog() {
  return useContext(AlertDialog);
}
