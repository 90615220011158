import React from "react";
import Template from "base/template";
import VendorForm from "views/vendors/form";
import { Button, CancelButton } from "components/buttons";
import { Redirect } from "react-router-dom";
import FormProvider from "providers/form-provider";
import { useMutationWrapper } from "utils/ajax";
import { useRedirect } from "hooks/redirect-hook";
import Vendor from "api/vendor";
import { Formik } from "formik";
import * as Yup from "yup";
import PageTitle from "components/page-title";
import Content from "base/content";
import Breadcrumbs from "components/breadcrumbs";

const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

const Save = (props) => {
  let vendor = props?.location?.state?.vendor || null;

  let { isSuccess, isLoading, mutate } = useMutationWrapper(
    ["vendor", "save"],
    (input) => Vendor.save(input, vendor?.id),
    {}
  );

  const SCHEMA = Yup.object().shape({
    name: Yup.string().required("Required"),
    phone: Yup.string()
      .matches(phoneRegex, "Invalid Phone")
      .required("Required"),
    address: Yup.string().required("Required"),
    contact_email: Yup.string().required("Required").email("Invalid email"),

    ...(vendor?.id
      ? {}
      : {
          user: Yup.object().required().shape({
            name: Yup.string().required("Required"),
            email: Yup.string().email("Invalid email").required("Required"),
            password: Yup.string().when("user_active", {
              is: true,
              then: (schema) => schema.required("Required"),
            }),
            phone: Yup.string()
              .matches(phoneRegex, "Invalid Phone")
              .required("Required"),
          })
        }),
  });

  const INITIAL_VALUES = {
    name: vendor?.name,
    phone: vendor?.phone,
    address: vendor?.address,
    enabled: vendor?.enabled ?? true,
    contact_email: vendor?.contact_email
  };

  if(!vendor?.id)
    INITIAL_VALUES.user = {};


  const { redirect, setRedirect } = useRedirect(isSuccess);

  return (
    <Template {...props}>
      {redirect && <Redirect to="/vendors" />}
      <Content {...props}>
        <PageTitle>{vendor ? "Save" : "Add"} Vendor</PageTitle>
        <Breadcrumbs crumbs={props.crumbs} />
        <Formik
          validationSchema={SCHEMA}
          initialValues={INITIAL_VALUES}
          onSubmit={(values) => mutate(values)}
        >
          {({ handleSubmit }) => (
            <FormProvider
              name="vendor"
              mutateFunc={handleSubmit}
              disabled={isLoading}
            >
              <VendorForm vendor={vendor} />

              <footer>
                <Button type="submit">Submit</Button>
                <CancelButton onClick={() => setRedirect(true)} />
              </footer>
            </FormProvider>
          )}
        </Formik>
      </Content>
    </Template>
  );
};

export default Save;
