import React from 'react';
import Template from 'base/template';

import Department from 'api/department';
import { columns } from 'views/departments/table-columns';
import Sidebar from 'views/departments/sidebar';
import IndexContent from 'base/index-content';
import ActionMenu from 'views/departments/action-menu';
import { useAuth } from 'providers/auth';
import { ReactComponent as DepartmentEmptyIcon } from 'assets/empty-icons/empty-department.svg';
import { useQueryWrapper } from 'utils/ajax';
import Person from 'api/person';
/** @jsxImportSource @emotion/react */
const cards = [
  {
    name: 'total',
    label: 'Total'
  }
];
const Departments = (props) => {
  const { user } = useAuth();

  const { data: stats } = useQueryWrapper(['person', 'getStats'], () =>
    Person.stats({ hasSite: user.focused_site })
  );

  return (
    <Template {...props}>
      <IndexContent
        initialFilter={{
          hasSite: user.focused_site
        }}
        fields={['counts']}
        statsQuery={Department.stats}
        personStats={stats}
        title="departments"
        id="departments"
        cards={cards}
        query={Department.search}
        columns={columns}
        addButton="Add A Department"
        sidebar={Sidebar}
        actionButton={ActionMenu}
        emptyIcon={DepartmentEmptyIcon}
        {...props}
      ></IndexContent>
    </Template>
  );
};

export default Departments;
