import React from 'react';

import Template from 'base/template';
import { Button, CancelButton, SecondaryButton } from 'components/buttons';
import { Redirect } from 'react-router-dom';
import FormProvider from 'providers/form-provider';
import { useMutationWrapper } from 'utils/ajax';
import { useRedirect } from 'hooks/redirect-hook';
import SensorForm from 'views/sensors/form';

import { Formik } from 'formik';
import * as Yup from 'yup';
import Content from 'base/content';
import { useParams } from 'react-router-dom';
import { useQueryWrapper } from 'utils/ajax';
import { useNotFound } from 'hooks/not-found-hook';
import PageTitle from 'components/page-title';
import Breadcrumbs from 'components/breadcrumbs';
import Sensor from 'api/sensor';

const Save = (props) => {
  const { id } = useParams();

  const [sensor, setSensor] = React.useState(props?.location?.state?.sensor);

  const query = useQueryWrapper(['sensor', 'get', id], () => Sensor.get(id), {
    enabled: !!id
  });

  useNotFound(id, query, props);

  React.useEffect(() => {
    if (query.data) setSensor(query.data);
  }, [query.data]);

  const SCHEMA = Yup.object().shape({
    site_id: Yup.string().required('Required'),
    protocol: Yup.string().required('Required'),
    name: Yup.string()
      .min(2, 'Name is too short')
      .max(40, '40 characters max')
      .required('Required')
  });

  const INITIAL_VALUES = {
    name: sensor?.name,
    site_id: sensor?.site_id ?? '',
    protocol: sensor?.protocol ?? ''
  };

  const { isSuccess, isLoading, mutate, data } = useMutationWrapper(
    ['sensor', 'save'],
    (input) => Sensor.save(input, sensor?.id),
    {}
  );

  const { redirect, setRedirect } = useRedirect(isSuccess);

  return (
    <Template {...props}>
      <Content {...props}>
        <PageTitle>{sensor?.name}</PageTitle>
        <Breadcrumbs crumbs={props.crumbs} />
        {redirect &&
          (!sensor && data ? (
            <Redirect to={`/sensors/auth/${data?.sensor_id}`} />
          ) : (
            <Redirect to={`/sensors`} />
          ))}

        <Formik
          enableReinitialize={true}
          validationSchema={SCHEMA}
          initialValues={INITIAL_VALUES}
          onSubmit={(values) => {
            mutate(values);
          }}
        >
          {({ handleSubmit, handleReset }) => (
            <FormProvider mutateFunc={handleSubmit} disabled={isLoading}>
              <SensorForm sensor={sensor} />
              <footer>
                <Button type="submit">Submit</Button>
                <CancelButton onClick={() => setRedirect(true)} />
              </footer>
            </FormProvider>
          )}
        </Formik>
        {/* <Network /> */}
      </Content>
    </Template>
  );
};

export default Save;
