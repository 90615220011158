import React from 'react';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Select, { components } from 'react-select';
import Creatable, { useCreatable } from 'react-select/creatable';

import { useFormikContext, getIn } from 'formik';
import Spinner from 'components/spinner';
import { ErrorMessage, InputLabel, InputHelper } from 'components/form';
import { IconArrowRampLeft3, IconRefresh } from '@tabler/icons-react';

const styles = (props) => css`
  width: 100%;

  &[data-inline='true'] {
    .select__control {
      margin: 4px;
    }
    .select__menu {
      box-shadow: none;
      border: none;
    }
    .select__menu-list {
      max-height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
    }
    input {
      opacity: 1 !important ;
    }
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    > div {
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }
    .select__menu {
      overflow-y: auto;
      position: initial;
      height: 100%;
      border: none !important;
    }
  }

  &[data-error='true'] {
    .select__control {
      border: 1px solid var(--red-base);
      outline: 2px solid var(--red-base) !important;
    }
  }

  &[data-compact='true'] {
    .select__control {
      height: 40px;
      min-height: 40px;
    }
    .select__value-container {
      min-height: 40px;
      height: 40px;
    }

    .select__value-container + .select__indicators {
      min-height: 40px;
    }
  }

  .select__container.select--is-disabled {
    opacity: 50%;
  }

  .select__option--is-disabled {
    opacity: 50%;
  }

  .select__input {
    color: var(--neutral-00);
  }
  .select__control {
    background: var(--neutral-09);
    border-color: var(--neutral-12);
    display: flex;
    align-content: center;
    width: auto;
  }
  .select__value-container--has-value + .select__indicators {
    display: flex;
    align-items: center;
    min-height: 48px;
  }

  .select__menu {
    border: 1px solid var(--neutral-12);
    color: var(--neutral-00);
    z-index: 10;
  }

  .select__menu-list {
    background: var(--neutral-09);
    color: var(--neutral-0);
  }

  .select__control {
    min-height: 48px;
    border-radius: 5px;
    align-content: stretch;
  }

  .select__control:hover {
    // border: 1px solid var(--primary-base);
    // outline: 2px solid var(--primary-base) !important;
  }

  .select__multi-value__remove:hover {
    background: var(--primary-base);
    color: var(--neutral-00);
  }

  .select__control--is-focused {
    /* border-color: var(--primary-base);
    box-shadow: 0px 0px 0px 1px var(--primary-base); */
    box-shadow: none;
    box-sizing: border-box;
    overflow: hidden;
    outline: 2px solid var(--primary-base) !important;
    border: 1px solid var(--primary-base);
  }

  .select__control--is-focused:hover {
    border: 1px solid var(--primary-base);
    outline: 2px solid var(--primary-base) !important;
  }
  .select__multi-value,
  .select__single-value {
    background: ${props.simple || props.noValueContainer
      ? 'none'
      : 'var(--primary-v-02)'};
    border: ${props.simple || props.noValueContainer
      ? 'none'
      : '1px solid var(--primary-v-04)'};
    box-sizing: border-box;
    color: var(--neutral-00);
    border-radius: 4px;
    padding: 0.15rem;
    font-weight: 500;
    max-width: 90%;
  }

  .select__value-container {
    padding-left: 8px !important;
  }

  .select__multi-value__label {
    // background: var(--primary-v-02);
    color: var(--neutral-00);
  }

  .select__option--is-selected {
    background: var(--primary-v-02);
    color: var(--neutral-00);
  }
  .select__option--is-focused {
    background: var(--menu-hover-background-color);
  }

  .select__option:hover {
    background: var(--menu-hover-background-color);
  }
`;

export const Option = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <div
        data-value={props.value}
        css={css`
          display: flex;
          align-items: center;
          gap: 8px;
          padding: ${props.selectProps.smallOption ? '0.1rem' : '0.5rem'};

          font-size: 14px;
          cursor: ${props.data.value == 'select-all' ? 'pointer' : 'default'};
        `}
      >
        {props.selectProps.checkboxes && props.data.value != 'select-all' && (
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />
        )}
        <span
          css={css`
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          `}
        >
          {children}
        </span>
      </div>
    </components.Option>
  );
};

const LoadingMessage = ({ children, ...props }) => {
  return (
    <components.LoadingMessage {...props}>
      <div
        css={css`
          height: 80px;
        `}
      >
        <Spinner />
      </div>
    </components.LoadingMessage>
  );
};

export const ValueContainer = ({ children, ...props }) => {
  return (
    <components.ValueContainer
      {...props}
      innerProps={Object.assign({}, props.innerProps, {
        'data-value': props.getValue()?.[0]?.[props.idx || 'id']
      })}
    >
      {children}
    </components.ValueContainer>
  );
};

export const Control = ({ children, ...props }) => {
  let Icon = props.selectProps.icon;
  return (
    <components.Control
      {...props}
      innerProps={Object.assign({}, props.innerProps, {
        'data-value': props.getValue()?.[0]?.[props.idx || 'id']
      })}
      css={css`
        display: flex;
        align-items: center;
      `}
    >
      {props.selectProps.icon && (
        <Icon
          css={css`
            margin-left: 0.8rem;
          `}
        />
      )}
      {children}
    </components.Control>
  );
};
const NoOptionsMessage = (props) => {
  return (
    <div
      css={css`
        display: flex;
        width: 100%;
        flex: 1;
        padding: 1rem;
        color: var(--neutral-02);
        align-items: center;
        justify-content: center;
      `}
    >
      {props.selectProps.isError ? 'Error occured fetching data' : 'No Results'}
    </div>
  );
};

const MenuList = (props) => {
  return (
    <components.MenuList {...props}>
      {Array.isArray(props.children) && props.selectProps.maxOptions
        ? props?.children?.slice(0, props.selectProps.maxOptions)
        : props.children}
    </components.MenuList>
  );
};
const MoreSelectedBadge = ({ items }) => {
  const style = {
    marginLeft: 'auto',
    background: '#d4eefa',
    borderRadius: '4px',

    fontSize: '11px',
    padding: '3px',
    order: 99
  };

  const title = items.join(', ');
  const length = items.length;
  const label = `+ ${length}`;

  return (
    <div style={style} title={title}>
      {label}
    </div>
  );
};

export const MultiValue = ({ index, getValue, ...props }) => {
  const maxToShow = 4;
  const overflow = getValue()
    .slice(maxToShow)
    .map((x) => x?.[props?.selectProps?.label]);

  return index < maxToShow ? (
    <components.MultiValue {...props} />
  ) : index === maxToShow ? (
    <MoreSelectedBadge items={overflow} />
  ) : null;
};

const IndicatorsContainer = ({ children, ...props }) => (
  <components.IndicatorsContainer {...props}>
    {children}
    {/** Uncomment this if want to show async refresh button */}
    {/* {props.selectProps.isAsync && !props.selectProps.noAsyncRefresh && (
      <button
        disabled={props.selectProps.isLoading}
        css={css`
          height: 100%;
          border-left: 2px solid var(--neutral-12);
          padding: 8px;
          color: hsl(0, 0%, 80%);
          &:hover {
            color: hsl(0, 0%, 60%);
          }
          &:disabled {
            cursor: default;
            opacity: 50%;
          }
        `}
        type="button"
        onMouseDown={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        onTouchEnd={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        onClick={(e) => {
          if (typeof props.selectProps?.remove == 'function')
            props.selectProps?.remove();
          if (typeof props.selectProps?.refetch == 'function')
            props.selectProps?.refetch();
        }}
      >
        <IconRefresh size="20" />
      </button>
    )} */}
  </components.IndicatorsContainer>
);

const SelectWrapper = React.forwardRef((props, ref) => {
  const dataError = !props.field?.name
    ? false
    : (props.form.errors &&
        typeof props.form.errors[props.field.name] === 'string' &&
        props.form.errors[props.field.name] &&
        props.form.touched[props.field.name]) ||
      (getIn(props.form.errors, props.field.name) &&
        getIn(props.form.touched, props.field.name));

  const dataCompact = props['data-compact'] ? 'true' : 'false';

  const Component = props.creatable ? Creatable : Select;

  return (
    <div
      data-fetch-error={props.isError}
      data-inline={props['data-inline']}
      data-compact={dataCompact}
      data-error={dataError}
      data-simple={props.simple ? 'true' : 'false'}
      className="select__wrapper"
      css={styles(props)}
    >
      {props.title && <InputLabel label={props.title} />}
      {props.helper && <InputHelper {...props} />}

      <Component
        //   styles={customStyles(props)}
        checkboxes={!props.simple ? props.checkboxes ?? true : false}
        ref={ref}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        components={{
          Control,
          ValueContainer,
          NoOptionsMessage,
          Option,
          MultiValue,
          IndicatorSeparator: null,
          LoadingIndicator: null,
          LoadingMessage,
          MenuList,
          IndicatorsContainer,
          ...props.itemComponents
        }}
        //backspaceRemovesValue={false}
        isClearable={!props.simple}
        isSearchable={!props.simple}
        className="select__container"
        classNamePrefix="select"
        //controlShouldRenderValue={false}
        hideSelectedOptions={false}
        //menuIsOpen
        getOptionLabel={(e) => e[props.label || 'name']}
        getOptionValue={(e) => e[props.idx || 'id']}
        placeholder="Search..."
        // styles={selectStyles}
        tabSelectsValue={false}
        isLoading={props.isLoading}
        {...props}
        onBlurResetsInput={false}
        onSelectResetsInput={false}
        onCloseResetsInput={false}
      />
      {props.field?.name && <ErrorMessage name={props.field.name} />}
      {props.isError && (
        <div
          css={css`
            margin: 0.2rem;
            color: red;
          `}
        >
          Error occured fetching data
        </div>
      )}
    </div>
  );
});

export default SelectWrapper;
