import React from "react";
import { css } from "@emotion/react";
/** @jsxImportSource @emotion/react */
import { Field, Form, useFormikContext } from "formik";
import SMSCreatable from "components/form/sms-creatable";
import { useModal } from "providers/modal-provider";
import { ModalTitle } from "components/modal";
import { Formik } from "formik";
import { FormFooter } from "components/form/form-footer";
import { Button, CancelButton } from "components/buttons";
const SMS = ({ mainForm }) => {
  return (
    <article>
      <Field name="sms" component={SMSCreatable} />
    </article>
  );
};

export default SMS;
