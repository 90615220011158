import 'css/sidebar.css';
import React from 'react';
import { withSidebar } from 'components/with-sidebar';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import { useHeadlessTable } from 'providers/headless-infinite-table-provider';
import SidebarEmptyHolder from 'components/sidebar-empty-holder';
import styled from '@emotion/styled';
import { Badge } from 'components/badge';
import EVENTS_CONSTANTS from 'constants/events';
import TimeFormatter from 'components/time-formatter';
import Clipboard from 'utils/clipboard';
import { TextArea } from 'components/form';
import { AuxiliaryButton, Button } from 'components/buttons';
import { PencilSquare } from 'react-bootstrap-icons';
import Event from 'api/event';
import { useMutationWrapper } from 'utils/ajax';
import { QueryClient, useQueryClient } from 'react-query';
import IsRecording from './is-recording';
import AddDeniedCredential from './add-denied-credential';
import FeedModal from 'components/video/feed-modal';
import LicensePlateUri from 'views/license-plates/license-plate-uri';
import { useQueryWrapper } from 'utils/ajax';

const EventIcon = styled('span')`
  color: var(--neutral-00);
  width: 42px;
  height: 42px;

  background: ${(props) => props.background || 'var(--secundary-v-02)'};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    color: ${(props) => props.color || '#267DFF'};
    height: 18px;
    width: 18px;
  }
`;

const LEVELS = {
  info: 'blue',
  critical: 'red',
  warning: 'orange'
};

export const SidebarWrapper = styled('article')`
  overflow: auto;
  height: 100%;
  > header {
    display: grid;
    padding: 24px;
    border-bottom: 1px solid var(--neutral-12);
    grid-template-columns: auto 1fr;
    gap: 0 12px;

    > span {
      grid-row: 1 / span 2;
    }

    > h3 {
      + span {
        font-size: 12px;
        font-weight: 500;
        color: var(--neutral-01);
        grid-row: 2;
      }
    }
  }

  > section {
    padding: 0 24px 24px 24px;

    > strong {
      display: block;
      color: var(--neutral-01);
      font-weight: 500;
      font-size: 12px;

      + span {
        color: var(--neutral-00);
        font-size: 14px;
        font-weight: 700;
      }
    }
  }

  > section.time {
    display: grid;
    border-bottom: 1px solid var(--neutral-12);
    grid-template-columns: 1fr auto;
    padding: 24px;

    [role='badge'] {
      grid-column: 2;
      grid-row: span 2;
      align-self: center;
    }

    time:first-of-type {
      font-size: 14px;
    }

    + section {
      padding-top: 24px;
    }
  }

  > section.notes {
    p {
      font-size: 12px;
      cursor: pointer;
      margin-top: 14px;
      line-height: 1.6;
      text-align: justify;
      width: 100%;
    }

    textarea {
      color: var(--neutral-00);
      margin-top: 6px;
      margin-bottom: -8px;
    }

    button {
      display: inline-flex;
      margin-right: 12px;
    }
  }
  .event-actions {
    display: flex;
    width: 100%;
    gap: 0.5rem;
    align-items: center;
  }
`;

const EventNotes = ({ event, setEvent }) => {
  const [notesOpen, setNotesOpen] = React.useState(false);
  const [notesText, setNotesText] = React.useState(event?.notes);

  const { queryKey } = useHeadlessTable();
  const queryClient = useQueryClient();

  const { mutate } = useMutationWrapper(
    ['event', 'notes', event.id],
    (notesText) => Event.setNotes(event.id, notesText),
    {
      noToast: true,
      onSuccess: () => {
        const data = queryClient.getQueryData(queryKey);

        const newData = {
          pageParams: data.pageParams,
          pages: data.pages.map((p) => ({
            ...p,
            data: p?.data?.map((e) =>
              e.id == event.id ? { ...e, notes: notesText } : e
            )
          }))
        };

        queryClient.setQueryData(queryKey, newData);
      }
    }
  );
  return (
    <section className="notes">
      <strong>Notes</strong>
      {!notesOpen ? (
        <p
          onClick={() => {
            setTimeout(() =>
              document.querySelector('textarea.notes')?.focus?.()
            );
            setNotesOpen(true);
            setNotesText(event.notes);
          }}
        >
          <PencilSquare /> {event.notes || 'None'}
        </p>
      ) : (
        <>
          <TextArea
            onChange={(ev) => setNotesText(ev.target.value)}
            className="notes"
            value={notesText}
          />
          <Button
            onClick={() => {
              mutate(notesText);
              setEvent({ ...event, notes: notesText });
              setNotesOpen(false);
            }}
            compact
          >
            Save
          </Button>
          <AuxiliaryButton compact onClick={() => setNotesOpen(false)}>
            Cancel
          </AuxiliaryButton>
        </>
      )}
    </section>
  );
};
const Sidebar = (props) => {
  const [event, setEvent] = React.useState();

  const { selectedFlatRows } = useHeadlessTable();

  const query = useQueryWrapper(
    ['event', event?.id],
    () => Event.search({ _id: event.id }),

    {
      initialData: {
        data: event
      },
      enabled: [
        'licensePlateGranted',
        'licensePlateDenied',
        'licensePlateUnfamiliar'
      ].includes(event?.type)
    }
  );

  React.useEffect(() => {
    if (!query.data.data?.[0]) return;
    setEvent(query.data.data?.[0]);
  }, [query.data.data?.[0]]);

  React.useEffect(() => {
    setEvent(selectedFlatRows?.[0]?.original || props.data);
  }, [selectedFlatRows, props.data]);

  if (!event) return <SidebarEmptyHolder />;

  const deviceName = event.controller_name ?? event.device_name ?? event.name;

  return (
    <SidebarWrapper>
      <header>
        {EVENTS_CONSTANTS.colors[event.type] && (
          <EventIcon
            color={EVENTS_CONSTANTS.colors[event.type]}
            background={EVENTS_CONSTANTS.backgroundColors[event.type]}
          >
            {typeof EVENTS_CONSTANTS.icons[event.type] == 'function' &&
              EVENTS_CONSTANTS.icons[event.type]()}
          </EventIcon>
        )}
        <h3>
          {event.reason
            ? EVENTS_CONSTANTS.reasons[event.reason]
            : EVENTS_CONSTANTS.readableNames[event?.type]}
        </h3>

        <span>{event.site_name}</span>

        <Clipboard
          title="Copy JSON to clipboard"
          value={JSON.stringify(event)}
        />
        <span className="event-actions">
          <IsRecording event={event} />
          <AddDeniedCredential event={event} />
        </span>
      </header>

      <section className="time">
        <div>
          <TimeFormatter dt={event.utctime} />
          <time
            css={css`
              font-size: 0.75rem !important;
              color: var(--table-header-color);
              margin-top: 0.2rem;
              display: block;
              opacity: 80%;
            `}
          >
            {new Date(event.utctime).toLocaleString('en', {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
              fractionalSecondDigits: 3
            })}
          </time>
        </div>
        <Badge color={LEVELS[event.level]}>{event.level}</Badge>
      </section>

      {event.door_name && (
        <section>
          <strong>Door</strong>
          <span>{event.door_name}</span>
        </section>
      )}

      {event.p_name && (
        <section>
          <strong>Person</strong>
          <span>{event.p_name}</span>
        </section>
      )}

      {deviceName && (
        <section>
          <strong>Device</strong>
          <span>{deviceName}</span>
        </section>
      )}

      {event.camera_name && (
        <section>
          <strong>Camera</strong>
          <span>{event.camera_name}</span>
        </section>
      )}
      {event.notifications && (
        <section>
          <strong>Notifications Triggered:</strong>
          <span>{event.notifications?.map((n) => n.name).join(',')}</span>
        </section>
      )}
      {event.reason == 'CredentialNotFound' && (
        <section
          css={css`
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 0.4rem;
          `}
        >
          {Object.entries(event.data).map(
            ([key, value], idx) =>
              value && (
                <React.Fragment key={idx}>
                  <strong>{key}</strong>
                  <span>{value}</span>
                </React.Fragment>
              )
          )}
        </section>
      )}

      <EventNotes event={event} setEvent={setEvent} />

      {[
        'licensePlateGranted',
        'licensePlateDenied',
        'licensePlateUnfamiliar'
      ].includes(event.type) && (
        <>
          <section>
            <strong>Plate Image</strong>
            <LicensePlateUri event={event} />
          </section>
          {/* <section>
            <strong>Car State</strong>
            <span>{event.carState}</span>
          </section> */}
        </>
      )}

      {/* {(event.is_recording || event.category == 'camera') && (
        <div
          css={css`
            > .feed {
              height: 300px;
              max-height: 300px;
              position: relative;
            }
          `}
        >
          <FeedModal event={event} key={event.id} autoPlay={false} />
        </div>
      )} */}

      {event.snapshot_link && (
        <div
          css={css`
            padding: 1rem;
            > img {
              object-fit: cover;
            }
          `}
        >
          <img src={event.snapshot_link} width="100%" />
        </div>
      )}

      {/* TODO snapshot */}
      {/* TODO recording video */}
    </SidebarWrapper>
  );
};

export default withSidebar({})(Sidebar, null);
