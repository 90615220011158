import React from "react";
import { useAuth } from "providers/auth";
import UserDashboard from "views/dashboard/user-dashboard";
import InstallerDashboard from "views/dashboard/installer-dashboard";

const Dashboard = (props) => {
  const { user } = useAuth();

  if (user.company_id) return <UserDashboard {...props} />;
  if (user.installer_id) return <InstallerDashboard {...props} />;
  return null;
};

export default Dashboard;
