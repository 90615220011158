import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  useMutateDelete,
  useOutputMutate,
  useGetStateMutate,
  useTogglePortMutate
} from './actions';

import {
  MenuSeperator,
  MenuItem,
  MenuItemDelete,
  MenuWrapper,
  MenuLabel
} from 'components/action-menu';

import { ExclamationCircle } from 'react-bootstrap-icons';
import useAlertDialog from 'providers/alert-dialog-provider';

export default function ActionMenu({ data: aux, ...otherProps }) {
  const history = useHistory();
  const deleteMutate = useMutateDelete(aux);
  const outputMutate = useOutputMutate(aux);
  const toggleMutate = useTogglePortMutate(aux);
  const mutateGetState = useGetStateMutate(aux);

  const confirm = useAlertDialog();

  const handleDelete = async (e) => {
    if (aux.open_schedule_token)
      await confirm({
        alert: true,
        actionLabel: 'OK',
        description: `Can not delete Auxiliary with open schedule, please clear open schedule first.`
      });
    else if (
      await confirm({
        description: `Are you sure you want to delete Auxiliary: ${aux.name} ?`
      })
    )
      deleteMutate.mutate(aux?.id);
  };

  const handleTestOutput = (e) => {
    outputMutate.mutate(aux.io_port_id);
  };

  const handleToggle = (e) => {
    let state = e.target.getAttribute('data-state');
    toggleMutate.mutate({ portId: aux.io_port_id, state });
  };

  let handleEdit = (e) =>
    history.push('/auxiliaries/save/' + aux?.id, {
      aux,
      desc: aux?.name
    });

  let handleOpenTime = (e) =>
    history.push('/auxiliaries/schedule/' + aux?.id, {
      aux,
      desc: aux?.name
    });
  const handleGetState = (e) => mutateGetState.mutate(aux.id);

  return (
    <MenuWrapper {...otherProps}>
      <MenuItem label="Edit" onClick={handleEdit} />

      {aux?.Direction == 'output' && (
        <>
          <MenuSeperator />
          <MenuLabel>State control</MenuLabel>
          <MenuItem label="Test" onClick={handleTestOutput} />
          <MenuItem label="Toggle" onClick={handleToggle} />
          <MenuItem data-state="1" label="Activate" onClick={handleToggle} />
          <MenuItem data-state="0" label="Deactivate" onClick={handleToggle} />
          <MenuSeperator />

          <MenuItem label="Open Times" onClick={handleOpenTime} />
          <MenuSeperator />
        </>
      )}
      <MenuItem label="Get State" onClick={handleGetState}>
        <ExclamationCircle />
      </MenuItem>
      <MenuItemDelete onClick={handleDelete} />
    </MenuWrapper>
  );
}
