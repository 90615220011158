import 'css/sidebar.css';
import React from 'react';
import { withSidebar } from 'components/with-sidebar';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import ActiveDoorChart from 'views/dashboard/active-door-chart';
import { useHeadlessTable } from 'providers/headless-table-provider';
import SidebarEmptyHolder from 'components/sidebar-empty-holder';
import GroupLabel from 'components/group-label';
import DoorTabs from './door-tabs';
import DoorStateAvatar from './door-state-avatar';
import ActionMenu from 'views/doors/action-menu';
import styled from '@emotion/styled';
import OpenTimes from './open-times';
import DoorAlerts from 'components/door-alerts';
const Stats = (props) => {
  return <ActiveDoorChart />;
};

export const SidebarWrapper = styled('article')`
  display: flex;

  height: 100% !important;
  flex-direction: column;

  label {
    width: 100%;
  }

  > header {
    padding: 24px;
    display: grid;
    grid-template-columns: 50px 2fr auto;
    grid-column-gap: 16px;
    align-items: center;
    .door-avatar {
      grid-row: span 2;
    }

    /* .action-menu-wrapper {
      position: absolute;
      top: 0px;
      right: 0px;
      margin: 10px;
    } */
    h2 {
      margin: 0;

      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 100%;
      /* identical to box height, or 18px */

      color: var(--neutral-00);
    }
    h3 {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 100%;

      color: var(--neutral-00);
    }
  }

  > div > section.event-list {
    width: 100%;
    min-height: 60%;
    max-height: 60%;
    overflow-y: auto;
    margin-bottom: 8px;
  }
  article.whosein-list {
    width: 100%;
    //min-height: 60%;
    //max-height: 60%;
    overflow-y: auto;
    margin-bottom: 8px;
  }
  section.usage-chart {
    width: 100%;
    flex: 1;
  }

  > section.groups {
    h4 {
      grid-column: 1/-1;
    }
    padding: 8px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 8px;
    width: 100%;

    margin-bottom: 8px;
  }
`;
const Sidebar = (props) => {
  const [door, setDoor] = React.useState();

  const { selectedFlatRows } = useHeadlessTable();

  React.useEffect(() => {
    setDoor(selectedFlatRows?.[0]?.original || props.data);
  }, [selectedFlatRows, props.data]);

  if (!door) return <SidebarEmptyHolder />;
  return (
    <SidebarWrapper>
      <header>
        <DoorStateAvatar door={door} />
        <div>
          <h2>{door?.name}</h2>
          <h3>{door.description}</h3>
        </div>
        <ActionMenu simple data={door} />
      </header>
      <DoorAlerts door={door} />
      {/* <OpenTimes door={door} /> */}
      {door?.groups?.length > 0 && (
        <section className="groups">
          {door.groups?.map((i, idx) => (
            <GroupLabel color={i.color} label={i.name} key={idx} />
          ))}
        </section>
      )}
      <DoorTabs door={door} />
    </SidebarWrapper>
  );
};

export default withSidebar({})(Sidebar, Stats);
