import React from 'react';
import { DateTimeField, CheckBox, TextField, TextArea } from 'components/form';
import { useFormikContext } from 'formik';
import { Field } from 'formik';
import EmailCreatable from 'components/form/email-creatable';
import SMSCreatable from 'components/form/sms-creatable';
import AccessLevel from 'api/access_level';
import AsyncSelect from 'components/headless-select/async';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const GuestForm = (props) => {
  const { values, setFieldValue } = useFormikContext();

  return (
    <section>
      <CheckBox
        name="send"
        label="Send Guest Pass Now"
        checked={values.send}
        onChange={(e) => setFieldValue('send', e.target.checked ? 'on' : '')}
      />
      <br />
      <TextField name="name" value={values.name} />
      {/* <Field
        isMulti={false}
        name="access_level"
        defaultOptionValue={values.access_level_id}
        component={AccessLevelSelect}
        closeMenuOnSelect={true}
      /> */}
      <Field
        component={AsyncSelect}
        name="access_level"
        title="Access Level"
        isClearable={false}
        fetchFn={AccessLevel.search}
        {...props}
      />
      <Field
        component={DateTimeField}
        name="valid_from"
        utc
        label="Valid From"
      />
      <Field component={DateTimeField} name="valid_to" label="Valid To" />

      <Field name="email" component={EmailCreatable} />
      <Field name="sms" component={SMSCreatable} />
      <TextArea
        placeholder="Custom message"
        helper="Will be added to your text or email. 80 characters max"
        name="message"
        maxLength="80"
        css={css`
          grid-column: 1/-1;
          color: var(--neutral-00);
        `}
      />
    </section>
  );
};

export default GuestForm;
