import React from 'react';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import { Field, Form, useFormikContext, FieldArray } from 'formik';

import AsyncSelect from 'components/headless-select/async';
import Schedule from 'api/schedule';
import GenericDoor from 'api/generic_door';

const DoorSchedule = ({ action }) => {
  return (
    <article>
      <p>
        <i>
          The Door will be open during the selected schedule. Schedule will be
          removed after 1 cycle.
        </i>
      </p>
      <Field
        name={`door_id`}
        title="Door"
        component={AsyncSelect}
        closeMenuOnSelect={true}
        fetchFilters={{ onlyAC: true }}
        fetchFn={GenericDoor.search}
      />
      <Field
        title="Schedule"
        name={`door_schedule_token`}
        fetchFn={Schedule.search}
        label="Name"
        idx="token"
        component={AsyncSelect}
        closeMenuOnSelect={true}
      />
    </article>
  );
};

export default DoorSchedule;
