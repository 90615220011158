import React from "react";

import RadioGroup from "components/radio-group";

const READER = [
  { value: "wiegand_single", label: "Wiegand Single LED" },
  { value: "wiegand_double", label: "Wiegand Double LED" },
  { value: "osdp", label: "OSDP RS485" },
];

const READER_2 = [...READER, { value: "none", label: "None" }];

const ReaderTypeSelect = (props) => {
  const { exit, field, form } = props;
  const options = exit ? READER_2 : READER;

  return (
    <RadioGroup
      {...field}
      {...form}
      options={options}
      title={`${exit ? "Exit" : "Entrance "} Reader`}
    />
  );
};

export default ReaderTypeSelect;
