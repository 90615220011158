import { dataFetchQuery } from 'utils/ajax';
import { API_HOST_URL } from './api-url';
const API_URL = API_HOST_URL + '/action_rule_api';
import { callAPI } from 'utils/ajax';

const ActionRule = {
  search(input = {}) {
    return dataFetchQuery(`${API_URL}/search`, input);
  },
  save(input = {}, deviceId) {
    return dataFetchQuery(`${API_URL}/save/${deviceId}`, input);
  },
  delete(id) {
    return dataFetchQuery(`${API_URL}/delete/${id}`);
  },
  capabilities(id) {
    return dataFetchQuery(`${API_URL}/capabilities/${id}`);
  },
  cloudRecordingSearch(input = {}) {
    return callAPI('GET', '/action_rule/cloudRecordingSearch', input);
  },
  cloudRecordingStats(input = {}) {
    return callAPI('GET', '/action_rule/cloudRecordingStats', input);
  },
  getHealthProfile(id) {
    return callAPI('GET', `/action_rule/getHealthProfile/${id}`);
  },
  getFeedStats(id, input = {}) {
    return callAPI('GET', `/action_rule/getFeedStats/${id}`, input);
  }
};

export default ActionRule;
