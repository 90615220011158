import Doors from 'routes/doors';
import Elevators from 'routes/elevators';
import ElevatorAccessLevels from 'routes/elevators/access-levels';
import DoorSchedule from 'routes/doors/schedule';
import DoorDetails from 'routes/doors/details';
import DoorSave from 'routes/doors/save';
import GenericDoorSave from 'routes/generic-doors/save';
import DoorAccessLevels from 'routes/doors/access-levels';

import People from 'routes/people';
import PeopleDetails from 'routes/people/details';
import PersonSave from 'routes/people/save';
import PersonPrintBadge from 'routes/people/print';
import PersonDepartments from 'routes/people/departments';

import Credentials from 'routes/credentials';
import CredentialSave from 'routes/credentials/save';
import CredentialBulk from 'routes/credentials/bulk';
import CredentialAccessLevels from 'routes/credentials/access-levels';

import Controllers from 'routes/controllers';
import ControllerSave from 'routes/controllers/save';
import ControllerSetup from 'routes/controllers/setup';
import ControllerDetails from 'routes/controllers/details';

import Companies from 'routes/companies';
import CompanySave from 'routes/companies/save';

import Sites from 'routes/sites';
import SiteSave from 'routes/sites/save';

import Events from 'routes/events';

import AccessLevels from 'routes/access-levels';
import AccessLevelSave from 'routes/access-levels/save';

import Schedules from 'routes/schedules';
import ScheduleSave from 'routes/schedules/save';

import Departments from 'routes/departments';
import DepartmentSave from 'routes/departments/save';
import DepartmentManagePeople from 'routes/departments/manage-people';
import DepartmentAccessLevels from 'routes/departments/access-levels';

import Logs from 'routes/audit-logs';

import Users from 'routes/users';
import UserSave from 'routes/users/save';
import UserProfile from 'routes/users/profile';

import Dashboard from 'routes/dashboard';

import Guests from 'routes/guests';
import GuestSave from 'routes/guests/save';

import Cameras from 'routes/cameras';
import CameraSave from 'routes/cameras/save';
import CameraDetails from 'routes/cameras/details';
import CameraView from 'routes/cameras/view';
import CameraSettings from 'routes/cameras/settings';
import CameraMonitoring from 'routes/cameras/monitoring';
import CameraRecordings from 'routes/cameras/recordings';

import Notifications from 'routes/notifications';
import NotificationSave from 'routes/notifications/save';
import Overview from 'routes/overview';

import Devices from 'routes/devices';
import DeviceSave from 'routes/devices/save';
import DeviceDetails from 'routes/devices/details';
import DeviceTools from 'routes/devices/tools';
import DeviceSettings from 'routes/devices/settings';

import Aux from 'routes/auxiliaries';
import AuxiliarySave from 'routes/auxiliaries/save';
import AuxiliarySchedule from 'routes/auxiliaries/schedule';

import Intercoms from 'routes/intercoms';
import IntercomSave from 'routes/intercoms/save';
import IntercomSettings from 'routes/intercoms/settings';
//import IntercomDetails from 'routes/intercoms/details';
import IntercomView from 'routes/intercoms/view';

import Reports from 'routes/reports';
import ReportSave from 'routes/reports/save';

import Installers from 'routes/installers';
import InstallerSave from 'routes/installers/save';
import ManageUsers from 'routes/manage-users';

import Groups from 'routes/groups';
import GroupSave from 'routes/groups/save';
import Snapshots from 'routes/snapshots';

import Badges from 'routes/badging';
import BadgeSave from 'routes/badging/save';

import Vendors from 'routes/vendors';
import VendorSave from 'routes/vendors/save';

import LicensePlates from 'routes/license-plates';
import LicensePlateSave from 'routes/license-plates/save';

import AlManageDoors from 'routes/access-levels/manage-doors';

import SyncJobs from 'routes/sync-jobs';
import Elevator from 'api/elevator';
import ElevatorSave from 'routes/elevators/save';
import ElevatorFloors from 'routes/elevators/floors';
import FloorSave from 'routes/elevator-floors/save';
import CloudRec from 'routes/cloud-recordings';

import SensorSave from 'routes/sensors/save';
import Sensors from 'routes/sensors';
import SensorAuth from 'routes/sensors/auth';
import SensorMonitoring from 'routes/sensors/monitoring';
import SensorMetadata from 'routes/sensors/metadata';

import Monitoring from 'routes/monitoring';
import RecordingExports from 'routes/recording-exports';
import EmailQueue from 'routes/email-queue';

import DeviceMonitoring from 'routes/devices/monitoring';
import BatchSyncJobs from 'routes/sync-jobs/batch-index';

import Speakers from 'routes/speakers';
import AudioClips from 'routes/audio-clips';
import AudioClipSave from 'routes/audio-clips/save';
//import SpeakerSave from 'routes/spekares/save';

function hasAllPermissions(user, ...perms) {
  if (user.permission?.includes?.('fa')) return true;

  for (const p of perms) {
    if (!user.permission?.includes?.(p)) return false;
  }

  return true;
}
export default [
  {
    path: '/dashboard',
    name: 'Dashboard',
    title: 'Dashboard',
    Component: Dashboard
  },

  { path: '/doors', name: 'Doors', title: 'Doors', Component: Doors },
  {
    path: '/doors/schedule/:id',
    name: 'Door Open Times',
    title: 'Open Times',
    subTitle: 'Open Times',
    Component: DoorSchedule
  },
  {
    path: '/doors/edit/:token',
    name: 'Door Preferences',
    title: 'Edit',
    Component: DoorSave
  },

  {
    path: '/doors/:id/levels',
    name: 'Door Access Levels',
    title: 'Access Levels',
    Component: DoorAccessLevels
  },

  {
    path: '/doors/add',
    name: 'Door Preferences',
    title: 'Add',
    Component: GenericDoorSave
  },

  {
    path: '/doors/save/:id',
    name: 'Door Preferences',
    title: 'Save',
    Component: GenericDoorSave
  },
  {
    path: '/doors/details/:token',
    name: 'Door Tools',
    title: 'Tools',
    Component: DoorDetails
  },

  {
    path: '/controllers',
    name: 'Controllers',
    title: 'Controllers',
    Component: Controllers
  },
  {
    path: '/controllers/add',
    name: 'Add Controller',
    title: 'Add',
    Component: ControllerSave
  },
  {
    path: '/controllers/save/:id',
    name: 'Edit Controller',
    title: 'Edit',
    Component: ControllerSave
  },

  {
    path: '/controllers/details/:id',
    name: 'Controller Tools',
    title: 'Tools',
    Component: ControllerDetails
  },

  {
    path: '/people',
    name: 'People',
    title: 'People',
    Component: People
  },
  {
    path: '/people/add',
    name: 'Add Person',
    title: 'Add',
    Component: PersonSave
  },
  {
    path: '/people/details/:id',
    name: 'Person Details',
    title: 'Details',
    Component: PeopleDetails
  },
  {
    path: '/people/save/:id',
    name: 'Edit Person',
    title: 'Edit',
    Component: PersonSave
  },
  {
    path: '/people/badge/:id',
    name: 'Print Person Badge',
    title: 'Print Badge',
    Component: PersonPrintBadge
  },
  {
    path: '/people/:id/manage-departments',
    name: 'Person Departments',
    title: 'Departments',
    Component: PersonDepartments
  },
  { path: '/sites', name: 'Sites', title: 'Sites', Component: Sites },
  {
    path: '/sites/add',
    name: 'Add Site',
    title: 'Add',
    Component: SiteSave
  },
  {
    path: '/sites/save/:id',
    name: 'Edit Site',
    title: 'Edit',
    Component: SiteSave
  },
  {
    path: '/sites/doors/:id',
    name: 'Site Doors',
    title: 'Doors',
    Component: Doors
  },
  {
    path: '/sites/controllers/:id',
    name: 'Site Controllers',
    title: 'Controllers',
    Component: Controllers
  },

  {
    path: '/credentials',
    name: 'Credentials',
    title: 'Credentials',
    Component: Credentials,
    valid: (user) => user?.company_id
  },
  {
    path: '/credentials/add',
    name: 'Add Credentials',
    title: 'Add',
    Component: CredentialSave
  },
  {
    path: '/credentials/bulk',
    name: 'Import Credentials',
    title: 'Import',
    Component: CredentialBulk
  },
  {
    path: '/credentials/save/:token',
    name: 'Edit Crerdential',
    title: 'Edit',
    Component: CredentialSave
  },

  {
    path: '/credentials/:token/levels',
    name: 'Credential Access Levels',
    title: 'Access Levels',
    Component: CredentialAccessLevels
  },

  {
    path: '/companies',
    name: 'Companies',
    title: 'Companies',
    Component: Companies,
    role: ['A', 'V', 'I']
  },
  {
    path: '/companies/add',
    name: 'Add Company',
    title: 'Add',
    Component: CompanySave,
    role: ['A', 'V', 'I']
  },
  {
    path: '/companies/save/:id',
    name: 'Edit Company',
    title: 'Edit',
    Component: CompanySave,
    role: ['A', 'V', 'I']
  },

  { path: '/events', name: 'Events', title: 'Events', Component: Events },

  {
    path: '/access-levels',
    name: 'Access Levels',
    title: 'Access Levels',
    Component: AccessLevels
  },
  {
    path: '/access-levels/add',
    name: 'Add Access Level',
    title: 'Add',
    Component: AccessLevelSave
  },
  {
    path: '/access-levels/save/:id',
    name: 'Edit Access Level',
    title: 'Edit',
    Component: AccessLevelSave
  },

  {
    path: '/access-levels/:id/manage-doors',
    name: 'Manage Doors',
    title: 'Manage Doors',
    Component: AlManageDoors
  },

  {
    path: '/schedules',
    name: 'Schedules',
    title: 'Schedules',
    Component: Schedules
  },
  {
    path: '/schedules/add',
    name: 'Add Schedule',
    title: 'Add',
    Component: ScheduleSave
  },
  {
    path: '/schedules/save/:id',
    name: 'Edit Schedules',
    title: 'Edit',
    Component: ScheduleSave
  },

  {
    path: '/departments',
    name: 'Departments',
    title: 'Departments',
    Component: Departments,
    valid: (user) =>
      (user?.company_id && hasAllPermissions(user, 'alView', 'personView')) ||
      user?.role != 'C'
  },
  {
    path: '/departments/add',
    name: 'Add Department',
    title: 'Add',
    Component: DepartmentSave,
    valid: (user) =>
      (user?.company_id && hasAllPermissions(user, 'alView', 'personView')) ||
      user?.role != 'C'
  },
  {
    path: '/departments/save/:id',
    name: 'Edit Department',
    title: 'Edit',
    Component: DepartmentSave,
    valid: (user) =>
      (user?.company_id && hasAllPermissions(user, 'alView', 'personView')) ||
      user?.role != 'C'
  },

  {
    path: '/departments/:id/manage-people',
    name: 'Manage People',
    title: 'Manage People',
    Component: DepartmentManagePeople,
    valid: (user) =>
      (user?.company_id && hasAllPermissions(user, 'alView', 'personView')) ||
      user?.role != 'C'
  },

  {
    path: '/departments/:token/levels',
    name: 'Department Access Levels',
    title: 'Access Levels',
    Component: DepartmentAccessLevels,
    valid: (user) =>
      user?.company_id && hasAllPermissions(user, 'alView', 'personView')
  },

  {
    path: '/audit-logs',
    name: 'Audit Logs',
    title: 'Audit Logs',
    Component: Logs
  },

  { path: '/accounts', name: 'Accounts', title: 'Accounts', Component: Users },
  {
    path: '/accounts/add',
    name: 'Add Account',
    title: 'Add',
    Component: UserSave
  },
  {
    path: '/accounts/edit/:id',
    name: 'Edit Account',
    title: 'Edit',
    Component: UserSave
  },
  {
    path: '/profile/:id',
    name: 'My Profile',
    title: 'My Profile',
    Component: UserProfile
  },

  {
    path: '/guests',
    name: 'Guests',
    title: 'Guests',
    Component: Guests,
    valid: (user) =>
      (user?.company_id && hasAllPermissions(user, 'alView', 'guestView')) ||
      user?.role != 'C'
  },

  {
    path: '/guests/add',
    name: 'Add Guest',
    title: 'Add',
    Component: GuestSave,
    valid: (user) =>
      (user?.company_id && hasAllPermissions(user, 'alView', 'guestView')) ||
      user?.role != 'C'
  },
  {
    path: '/guests/save/:id',
    name: 'Edit Guest',
    title: 'Edit',
    Component: GuestSave,
    valid: (user) =>
      (user?.company_id && hasAllPermissions(user, 'alView', 'guestView')) ||
      user?.role != 'C'
  },

  {
    path: '/notifications',
    name: 'Notifications',
    title: 'Notifications',
    Component: Notifications
  },

  {
    path: '/notifications/add',
    name: 'Add Notification',
    title: 'Add',
    Component: NotificationSave
  },
  {
    path: '/notifications/save/:id',
    name: 'Edit Notification',
    title: 'Edit',
    Component: NotificationSave
  },
  {
    path: '/cameras',
    name: 'Cameras',
    title: 'Cameras',
    Component: Cameras,
    valid: (user) =>
      (user?.company_id && user?.features?.cameras_enabled) || user?.role != 'C'
  },
  {
    path: '/cameras/add',
    name: 'Add Camera',
    title: 'Add',
    Component: CameraSave
  },
  {
    path: '/cameras/save/:id',
    name: 'Edit Camera',
    title: 'Edit',
    Component: CameraSave
  },
  // {
  //   path: '/cameras/details/:id/:tab?',
  //   name: 'Camera Details',
  //   title: 'Details',
  //   Component: CameraDetails
  // },
  {
    path: '/cameras/view/:id',
    name: 'Camera View',
    title: 'View',
    Component: CameraView
  },

  {
    path: '/cameras/recordings/:id',
    name: 'Camera Recordings',
    title: 'View',
    Component: CameraRecordings
  },
  {
    path: '/cameras/settings/:id',
    name: 'Settings Details',
    title: 'Settings',
    Component: CameraSettings
  },
  {
    path: '/cameras/monitoring/:id',
    name: 'Camera Monitoring',
    title: 'Monitoring',
    Component: CameraMonitoring
  },

  {
    path: '/cameras/snapshots/:id',
    name: 'Camera Snapshots',
    title: 'Snapshots',
    Component: Snapshots
  },

  {
    path: '/overview',
    name: 'Overview',
    title: 'Overview',
    Component: Overview,
    valid: (user) => user?.company_id && user.features.overview_enabled
  },
  {
    path: '/devices',
    name: 'Devices',
    title: 'Devices',
    Component: Devices,
    valid: (user) => ['A', 'V', 'I'].includes(user?.role) || user?.devices_view
  },
  {
    path: '/devices/add',
    name: 'Add Device',
    title: 'Add',
    Component: DeviceSave,
    valid: (user) => ['A', 'V', 'I'].includes(user?.role)
  },

  {
    path: '/devices/settings/:id',
    name: 'Add Device',
    title: 'Add',
    Component: DeviceSettings,
    valid: (user) => ['A', 'V', 'I'].includes(user?.role)
  },

  {
    path: '/devices/save/:id',
    name: 'Edit Device',
    title: 'Edit',
    Component: DeviceSave,
    valid: (user) => ['A', 'V', 'I'].includes(user?.role)
  },
  {
    path: '/devices/setup/:id',
    name: 'Setup Device',
    title: 'Setup',
    Component: ControllerSetup,
    valid: (user) => ['A', 'V', 'I'].includes(user?.role)
  },
  {
    path: '/devices/details/:id/:tab?',
    name: 'Device Details',
    title: 'Details',
    Component: DeviceDetails,
    valid: (user) => ['A', 'V', 'I'].includes(user?.role)
  },

  {
    path: '/devices/tools/:id/',
    name: 'Device Tools',
    title: 'Tools',
    Component: DeviceTools,
    valid: (user) => ['A', 'V', 'I'].includes(user?.role) || user?.devices_view
  },
  {
    path: '/auxiliaries',
    name: 'Auxiliary',
    title: 'Auxiliary',
    Component: Aux
  },
  {
    path: '/auxiliaries/add',
    name: 'Add Auxiliary',
    title: 'Add',
    Component: AuxiliarySave
  },

  {
    path: '/auxiliaries/save/:id',
    name: 'Edit Auxiliary',
    title: 'Edit',
    Component: AuxiliarySave
  },
  {
    path: '/auxiliaries/schedule/:id',
    name: 'Edit Auxiliary',
    title: 'Open Times',
    Component: AuxiliarySchedule
  },
  {
    path: '/intercoms',
    name: 'Intercoms',
    title: 'Intercoms',
    Component: Intercoms
  },
  {
    path: '/intercoms/save/:id',
    name: 'Edit Intercom',
    title: 'Edit',
    Component: IntercomSave
  },
  {
    path: '/intercoms/settings/:id',
    name: 'Settings',
    title: 'Settings',
    Component: IntercomSettings
  },
  // {
  //   path: '/intercoms/details/:id/:tab?',
  //   name: 'Details',
  //   title: 'Details',
  //   Component: IntercomDetails
  // },
  {
    path: '/intercoms/view/:id/',
    name: 'View',
    title: 'View',
    Component: IntercomView
  },

  {
    path: '/reports',
    name: 'Reports',
    title: 'Reports',
    Component: Reports
  },

  {
    path: '/reports/add',
    name: 'Add Report',
    title: 'Add',
    Component: ReportSave
  },

  {
    path: '/reports/save/:id',
    name: 'Edit Report',
    title: 'Edit',
    Component: ReportSave
  },

  {
    path: '/installers',
    name: 'Installers',
    title: 'Installers',
    Component: Installers,
    role: ['A', 'V']
  },
  {
    path: '/installers/add',
    name: 'Add Installer',
    title: 'Add',
    Component: InstallerSave,
    role: ['A', 'V']
  },
  {
    path: '/installers/edit/:id',
    name: 'Edit Installer',
    title: 'Edit',
    Component: InstallerSave,
    role: ['A', 'V']
  },

  {
    path: '/manage-users',
    name: 'Manage Users',
    title: 'Manage Users',
    Component: ManageUsers,
    valid: (user) => user?.role == 'A' || user?.impersonator
  },
  {
    path: '/manage-users/edit/:uid',
    backTo: '/manage-users',
    name: 'Edit User',
    title: 'Edit User',
    Component: UserSave,
    valid: (user) => user?.role == 'A' || user?.impersonator
  },

  {
    path: '/groups',
    name: 'Groups',
    title: 'Groups',
    Component: Groups
  },

  {
    path: '/groups/add',
    name: 'Add Group',
    title: 'Add',
    Component: GroupSave
  },

  {
    path: '/groups/save/:id',
    name: 'Edit Group',
    title: 'Edit',
    Component: GroupSave
  },
  {
    path: '/snapshots',
    name: 'Snapshots',
    title: 'Snapshots',
    Component: Snapshots
  },
  {
    path: '/snapshots/:id',
    name: 'View Snapshot',
    title: 'View',
    Component: Snapshots
  },
  {
    path: '/elevators',
    name: 'Elevators',
    title: 'Elevators',
    Component: Elevators
  },
  {
    path: '/elevators/access',
    name: 'Elevator Access Levels',
    title: 'Access Levels',
    elevatorsOnly: true,
    Component: ElevatorAccessLevels
  },
  {
    path: '/elevators/access/add',
    name: 'Add Access Level',
    title: 'Add',
    elevatorsOnly: true,
    Component: AccessLevelSave
  },
  {
    path: '/elevators/save/:id',
    name: 'Edit Elevator',
    title: 'Edit',
    Component: ElevatorSave
  },

  {
    path: '/elevators/:id/floors',
    name: 'Floors',
    title: 'Floors',
    Component: ElevatorFloors
  },
  {
    path: '/elevators/:id/floors/save/:token',
    name: 'Edit Floor',

    Component: FloorSave
  },
  {
    path: '/elevators/access/save/:id',
    name: 'Edit Access Level',
    title: 'Edit',
    elevatorsOnly: true,
    Component: AccessLevelSave
  },

  {
    path: '/badges',
    name: 'Badge Templates',
    title: 'Badge Templates',
    Component: Badges
  },
  {
    path: '/badges/add',
    name: 'Add Badge Templates',
    title: 'Add',
    Component: BadgeSave
  },
  {
    path: '/badges/save/:id',
    name: 'Edit Badge Templates',
    title: 'Edit',
    Component: BadgeSave
  },

  {
    path: '/vendors',
    name: 'Vendors',
    title: 'Vendors',
    Component: Vendors,
    role: ['A']
  },
  {
    path: '/vendors/add',
    name: 'Add Vendor',
    title: 'Add',
    Component: VendorSave,
    role: ['A']
  },
  {
    path: '/vendors/save/:id',
    name: 'Edit Vendor',
    title: 'Edit',
    Component: VendorSave,
    role: ['A']
  },

  {
    path: '/license-plates',
    name: 'License Plates',
    title: 'License Plates',
    Component: LicensePlates
  },

  {
    path: '/license-plates/add',
    name: 'Add License Plate',
    title: 'Add',
    Component: LicensePlateSave
  },
  {
    path: '/license-plates/save/:id',
    name: 'Edit License Plate',
    title: 'Edit',
    Component: LicensePlateSave
  },
  {
    path: '/sync-jobs',
    name: 'Sync Jobs',
    title: 'Sync Jobs',
    Component: SyncJobs
  },
  {
    path: '/sync-jobs/:batchId',
    name: 'Batch Sync Jobs',
    title: 'Batch Sync Jobs',
    Component: BatchSyncJobs
  },
  {
    path: '/sensors',
    name: 'Sensors',
    title: 'Sensors',
    Component: Sensors
  },
  {
    path: '/sensors/save/:id',
    name: 'Edit Sensor',
    title: 'Edit',
    Component: SensorSave
  },
  {
    path: '/sensors/add',
    name: 'Add Sensor',
    title: 'Add',
    Component: SensorSave
  },
  {
    path: '/sensors/monitoring/:id',
    name: 'Sensor Monitoring',
    title: 'Monitoring',
    Component: SensorMonitoring
  },
  {
    path: '/sensors/auth/:id',
    name: 'Sensor Authentication',
    title: 'Authentication',
    Component: SensorAuth
  },
  {
    path: '/sensors/metadata/:id',
    name: 'Sensor metadata',
    title: 'Metadata',
    Component: SensorMetadata
  },

  {
    path: '/monitoring-views',
    name: 'Monitoring',
    title: 'Monitoring',
    Component: Monitoring,
    valid: (user) => user?.features?.monitoring
  },
  {
    path: '/cloud-recording',
    name: 'Cloud Recording',
    title: 'Cloud Recording',
    Component: CloudRec,
    role: ['AVI']
  },
  {
    path: '/recording-exports',
    name: 'Recording Exports',
    title: 'Recording Exports',
    Component: RecordingExports,
    valid: (user) =>
      user?.features?.cameras_enabled || user?.features?.intercoms
  },
  {
    path: '/email_queue',
    name: 'Email & SMS',
    title: 'Email/SMS delivery',
    Component: EmailQueue,
    role: ['A']
  },
  {
    path: '/devices/monitoring/:id',
    name: 'Device Monitoring',
    title: 'Monitoring',
    Component: DeviceMonitoring
  },

  {
    path: '/speakers',
    name: 'Speakers',
    title: 'Speakers',
    Component: Speakers
  },
  {
    path: '/audio-clips',
    name: 'Audio Clips',
    title: 'Audio Clips',
    Component: AudioClips
  },
  {
    path: '/audio-clips/add',
    name: 'Audio Clips',
    title: 'Audio Clips',
    Component: AudioClipSave
  }
];
