import { Badge } from 'components/badge';
import { css } from '@emotion/react';
import { dateTimeLocal } from 'utils/time';
import { getSelectionColumn } from 'components/table-selection-column';
import { ExclamationCircle, InfoCircleFill } from 'react-bootstrap-icons';
/** @jsxImportSource @emotion/react */

import { useAuth } from 'providers/auth';
import { useHistory } from 'react-router-dom';
import { dateTimeDif } from 'utils/time';
import TimeFormatter from 'components/time-formatter';
import React from 'react';
import { getRowNumberColumn } from 'components/table-rownumber-column';
export const columns = [
  getSelectionColumn(),
  {
    style: { width: '250px' },
    Header: 'Name',
    accessor: 'display_name',
    pickerDisabled: true,
    Cell: ({ row: { original } }) => {
      const { companyManage } = useAuth();
      const history = useHistory();

      const handleManage = (e) => {
        e.stopPropagation();
        companyManage.mutate(original.id, {
          onSuccess: () => history.push('/devices')
        });
      };
      return (
        <article
          css={css`
            display: flex;
            gap: 8px;
            align-items: center;
          `}
        >
          <span
            css={css`
              &:hover {
                text-decoration: underline !important;
              }
            `}
            onClick={handleManage}
          >
            {original.display_name}
          </span>
        </article>
      );
    },
    newSorter: true,
    asc: 'atoz',
    desc: 'ztoa'
  },
  {
    Header: 'Integrity',
    accessor: 'unsynced_count',
    style: { width: '100px' },
    Cell: ({ row, cell }) =>
      !cell.value || (cell.value == 0 && row.original.offline_count == 0) ? (
        <Badge color="success">OK</Badge>
      ) : (
        <Badge
          css={css`
            display: flex;
            align-items: center;
            color: var(--issues-badge-font-color);
            background: var(--red-base);
          `}
        >
          <ExclamationCircle />
          Issues
        </Badge>
      ),
    newSorter: true,
    asc: 'leastUnsynced',
    desc: 'mostUnsynced'
  },
  {
    Header: 'Vendor',
    newSorter: true,
    asc: 'atozVendor',
    desc: 'ztoaVendor',
    accessor: 'vendor_name',
    style: { width: '200px' },
    Cell: ({ row }) => (
      <span>
        <Badge
          css={css`
            padding: 0;
            background: none;
          `}
          color="green"
        >
          {row.original.vendor_name}
        </Badge>
      </span>
    )
  },
  {
    Header: 'Installer',
    newSorter: true,
    asc: 'atozInstaller',
    desc: 'ztoaInstaller',
    accessor: 'installer_name',
    style: { width: '200px' },
    Cell: ({ row }) => (
      <Badge
        css={css`
          padding: 0;
          background: none;
        `}
        color="blue"
      >
        {row.original.installer_name}
      </Badge>
    )
  },
  {
    Header: 'Devices',
    accessor: 'device_count',
    style: { width: '100px' },
    newSorter: true,
    asc: 'leastDevices',
    desc: 'mostDevices'
  },
  {
    Header: 'Doors',
    accessor: 'door_count',
    style: { width: '100px' },
    newSorter: true,
    asc: 'leastDoors',
    desc: 'mostDoors'
  },
  {
    Header: 'People',
    accessor: 'people_count',
    style: { width: '100px' },
    newSorter: true,
    asc: 'leastPeople',
    desc: 'mostPeople'
  },
  {
    Header: 'Credentials',
    accessor: 'credential_count',
    style: { width: '100px' },
    newSorter: true,
    asc: 'leastCredentials',
    desc: 'mostCredentials'
  },
  {
    Header: 'Latest Activity',
    accessor: 'last_audit_log_time',
    style: { width: '250px' },
    Cell: ({ cell: { value } }) =>
      value ? <TimeFormatter dt={value + 'Z'} /> : null,
    newSorter: true,
    asc: 'leastCredentials',
    desc: 'mostCredentials'
  },
  {
    Header: 'Date Created',
    accessor: 'insert_time',
    newSorter: true,
    asc: 'newestFirst',
    desc: 'oldestFirst',
    style: { width: '150px' },
    Cell: ({ cell: { value } }) => {
      return <span>{dateTimeDif(value)}</span>;
    }
  }
];
