import React from 'react';

import { TextField, NumberField } from 'components/form';

import { Field, useFormikContext } from 'formik';
import AsyncSelectWrapper from 'components/async-select-wrapper';
import PriorityConfig from 'api/priority_config';
import { css } from '@emotion/react';
import { useFormFields } from 'hooks/form-fields-hook';
import GroupSelect from 'components/form/group-select';
import DoorSecurity from './door-security';
/** @jsxImportSource @emotion/react */

const styles = css`
  display: grid;
  grid-template-columns: var(--form-column-width) var(--form-column-width);
  gap: 24px;
  .time {
    grid-column: 1/-1;
    display: grid;
    column-gap: 16px;
    row-gap: 8px;

    grid-template-columns: auto auto auto;
  }
`;
const DoorDetails = (props) => {
  const { index = 0, hardware, security } = props;
  const { values } = useFormikContext();

  return (
    <section css={styles}>
      {hardware && (
        <Field
          css={css`
            > input {
              background: #f1ffc4;
            }
          `}
          name={`doors[${index}].hardware_id`}
          value={values.doors[index].hardware_id}
          label={'Hardware ID'}
          as={TextField}
        ></Field>
      )}
      <Field
        name={`doors[${index}].name`}
        value={values.doors[index].name}
        label={'Name'}
        as={TextField}
      ></Field>

      <Field
        name={`doors[${index}].description`}
        label={'Description'}
        as={TextField}
      />

      <div className="time">
        <Field
          helper="1-60 (seconds)"
          name={`doors[${index}].access_time`}
          value={values.doors[index].access_time}
          label={'Access Time'}
          as={NumberField}
          half
        />

        <Field
          helper="2-90 (seconds)"
          name={`doors[${index}].extendedaccesstime`}
          label={'Extended Access Time'}
          as={NumberField}
          half
        />
        <Field
          helper="1-270 (seconds)"
          name={`doors[${index}].opentoolongtime`}
          label={'OTL'}
          as={NumberField}
          half
        />
        <Field
          helper="2-300 (seconds)"
          name={`doors[${index}].extendedopentoolongtime`}
          label={'Extended OTL'}
          as={NumberField}
          half
        />
        <Field
          helper="1-15 (seconds)"
          name={`doors[${index}].prealarmtime`}
          label={'Pre Alram Time'}
          as={NumberField}
          half
        />
      </div>
      {/**   <AsyncSelectWrapper
        fetchFn={(e) => PriorityConfig.search({ ...e })}
        initialData={[]}
        closeMenuOnSelect={true}
        dataObject={`doors[${index}].priorityconfiguration`}
        label="Name"
        idx="token"
        title="Priority Configuratrion"
        isMulti={false}
      />**/}
      {security && (
        <>
          <Field
            name={`doors[${index}].groups`}
            defaultOptionValue={values.doors[index].groups?.map((i) => i.id)}
            component={GroupSelect}
          />
          <DoorSecurity />
        </>
      )}
    </section>
  );
};

export default DoorDetails;
