import React from 'react';
import Schedule from 'api/schedule';
import AsyncSelect from 'components/headless-select/async';
import { css } from '@emotion/react';
import { Field, useFormikContext } from 'formik';
import { InputLabel } from 'components/form';
import Alert from 'components/alert';

/** @jsxImportSource @emotion/react */

export default ({ deviceType }) => {
  const { values, setFieldValue } = useFormikContext();
  return (
    <section>
      <article
        css={css`
          grid-column: 1/-1;
          display: flex;
          flex-direction: column;
          gap: 1rem;
        `}
      >
        <Alert>
          Set up schedule to activate a port state. <br />
          Removing a schedule requires to manually set the port state to its
          default position.
        </Alert>

        <Field
          fetchFilters={{
            noAdvanced: !['controller', 'relay'].includes(deviceType)
          }}
          name="schedule"
          label="Name"
          idx="token"
          fetchFn={Schedule.search}
          component={AsyncSelect}
        />
        <section
          css={css`
            margin-top: 1rem;

            label {
              display: flex;
              gap: 4px;
              align-items: center;
            }
            .radio {
              margin-top: 4px;
            }
            input[type='radio'] {
              appearance: none;
              border-radius: 50%;
              width: 24px;
              height: 24px;
              border: 2px solid var(--neutral-12);
              transition: 0.2s all linear;
            }
            input[type='radio']:checked {
              background-color: var(--primary-base);
              border: 6px solid var(--neutral-09);
              outline: 2px solid var(--neutral-12);
            }
            input[type='radio']:disabled {
              opacity: 20%;
            }
            input[type='radio'] + label {
              font-weight: 500;
            }
          `}
        >
          <InputLabel>Port State When schedule activates:</InputLabel>
          <div className="radio">
            <label>
              <input
                disabled={!values.schedule}
                type="radio"
                value="Active"
                name="state"
                checked={values.port_state == '1'}
                onChange={() => setFieldValue('port_state', '1')}
              />
              Active
            </label>
          </div>

          <div className="radio">
            <label>
              <input
                disabled={!values.schedule}
                type="radio"
                value="Inactive"
                name="state"
                checked={values.port_state == '0'}
                onChange={() => setFieldValue('port_state', '0')}
              />
              Inactive
            </label>
          </div>
        </section>
      </article>
    </section>
  );
};
