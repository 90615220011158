import React from 'react';
import Template from 'base/template';

import { columns } from 'views/audit-logs/table-columns';
import { css } from '@emotion/react';
import IndexContentInfinite from 'base/index-content-infinite';
/** @jsxImportSource @emotion/react */
import AuditLog from 'api/audit_log';
import Sidebar from 'views/audit-logs/sidebar';
import Filters from 'views/audit-logs/filters';

const cards = [
  {
    name: 'total',
    label: 'Total'
  }
];

const Logs = (props) => {
  return (
    <Template {...props}>
      <IndexContentInfinite
        title="Audit Logs"
        id="audit-logs"
        filters={Filters}
        //initialFilter={{ ddd: 'dsdas' }}
        initialFilter={{ browser: true }}
        cards={cards}
        query={AuditLog.search}
        columns={columns}
        {...props}
        selectable
        // actionButton={ActionMenu}
        isSidebarOpen={true}
        sidebar={Sidebar}
      ></IndexContentInfinite>
    </Template>
  );
};

export default Logs;
