import React from 'react';

import Template from 'base/template';

import 'css/dashboard.css';
import 'css/chart.css';
import EventList from 'components/event-list';

import { useQueryWrapper } from 'utils/ajax';

import Content from 'base/content';

import { HddNetwork, DoorClosed, GeoAlt } from 'react-bootstrap-icons';
/** @jsxImportSource @emotion/react **/
import { css } from '@emotion/react';
import PageTitle from 'components/page-title';

import { useEventList } from 'hooks/use-event-list';
import styled from '@emotion/styled';

import HealthProfile from 'views/companies/health-profile';

import DayOfWeekChart from './day-of-week-chart';

import WhoseInList from 'components/whosein-list';
import { useInfiniteQuery } from 'react-query';
import Person from 'api/person';
import { WhoseinTooltip } from 'components/whosein-list';
import Site from 'api/site';
import { SiteIcon } from 'icons';
import { useAuth } from 'providers/auth';
import ActiveSite from './active-site';
import ActiveDoor from './active-door';
import ActiveCredential from './active-credential';
import DeviceEventList from 'views/devices/device-event-list';
import { IconAlertTriangle, IconChartTreemap } from '@tabler/icons-react';
import Device from 'api/device';
import ReactECharts from 'echarts-for-react';
import { ConfigResolverMap } from '@use-gesture/react';
import IsRole from 'components/is-role';
var groupBy = function (xs, key) {
  if (!xs) return null;
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const RadioStyles = css`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  button {
    padding: 0.5rem;
    border-radius: 4px;
    &[aria-checked='true'] {
      font-weight: 500;
      background: var(--neutral-05);
    }
  }
`;

export const Section = styled('section')`
  &[data-shaded='true'] {
    filter: grayscale(1);
    opacity: 0.6;
    &:hover {
      filter: grayscale(0) !important;
      opacity: 1;
    }
    transition: all 0.2s linear;
  }
  [role='radiogroup'] {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem;
  }
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  background: var(--neutral-09);
  color: var(--neutral-00);
  border-radius: 4px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  header {
    > h4 {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 125%;
      padding-top: 1rem;
      padding-left: 1rem;
      margin-bottom: 0;
      color: var(--neutral-02);
    }
    > h5 {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 125%;
      padding: 0.4rem 1rem;
      color: var(--neutral-02);
    }
  }
`;

const viewStyle = ({ site }) => css`
  padding-top: 6px;
  overflow: auto;

  > main {
    .echarts-for-react {
    }
    display: grid;
    gap: 1rem;
    grid-template:
      'issues issues issues issues day-of-week' 200px
      'health health health health events' 300px
      'active-left active-left active-right active-right events' 250px
      'active-left active-left active-right active-right who' 150px
      'active-left active-left active-right active-right who' 150px
      'tree-map tree-map tree-map tree-map tree-map' auto
      / 1fr 1fr 1fr 1fr 1.5fr;

    margin-top: 1rem;
    #issues {
      grid-area: issues;
      > ul {
        align-items: center;
        display: flex;
        justify-content: flex-start;
        height: 100%;
        width: 100%;
        padding: 1rem;
        gap: 1rem;
        > li {
          border: 1px solid #eceff3;
          border-radius: 4px;
          height: fit-content;
          flex: 1;
          &:hover:enabled {
            filter: brightness(90%);
          }
          background: var(--neutral-09);
          border-radius: 5px;
          //box-shadow: 0px 5px 48px -5px rgba(0, 0, 0, 0.05);
          display: grid;
          grid-template-columns: auto 1fr;

          column-gap: 1.2rem;
          align-items: center;
          padding: 1.5rem 1.8rem;

          span.value {
            width: 100%;
            font-size: 24px;
            font-weight: 700;
            color: var(--neutral-00);
            grid-row: 2;
          }
          span.name {
            grid-row: 1;
            grid-column: 2;
            white-space: nowrap;
            font-size: 12px;
            text-transform: capitalize;
            color: var(--secondary-font-color);
          }
          > div {
            height: 48px;
            width: 48px;
            display: flex;
            border-radius: 4px;
            justify-content: center;
            align-items: center;

            padding: 0.4rem;
            grid-row: 1 / span 2;
            svg {
              width: 24px;
              height: 24px;
            }
          }
        }
      }
    }

    #health {
      grid-area: health;
      height: 100%;

      .company-health-profile {
        padding: 0.25rem;
      }
    }
    #events {
      grid-area: events;
      header {
        h4 {
          padding: 1rem;
        }
      }
    }
    #who {
      grid-area: who;
      header {
        h4 {
          padding: 1rem;
        }
      }
      .whosein-list {
        height: 100%;
        overflow: hidden;
      }
    }

    #active-site {
      grid-area: active-left;
    }
    #active-credential {
      grid-area: active-right;
    }
    #active-door {
      grid-area: ${site ? 'active-left' : 'active-right'};
    }
    #day-of-week {
      grid-area: day-of-week;
    }
    #tree-map {
      grid-area: tree-map;
      width: 100%;
      height: 625px;
      > div {
        width: 100%;
        height: 100%;
      }
    }
  }
`;

function UserDashboard(props) {
  const { user, focusedSite } = useAuth();
  // const query = useEventList({
  //   filters: {
  //     site: user?.focused_site,
  //     category: 'device',
  //     level: 'critical',
  //     level: ['critical', 'warning']
  //   }
  // });

  const fetchWhosein = ({ pageParam = 1 }) =>
    Person.getRecentEntries({ page: pageParam, site: user?.focused_site });
  const whoseInQuery = useInfiniteQuery([`whosein`], fetchWhosein, {
    getNextPageParam: (lastPage, pages) => {
      return pages.length < lastPage.last_page ? pages.length + 1 : undefined;
    }
  });

  const { data: stats, isLoading: statsLoading } = useQueryWrapper(
    [`Site`, `stats`, user?.focused_site],
    () => Site.stats({ site: user?.focused_site })
  );

  const { data: devicesTree, isLoading: devicesTreeLoading } = useQueryWrapper(
    ['device', 'getAll', user?.focused_site],
    () => Device.getAll({ hasSite: user?.focused_site }),
    {
      enabled: user.managing || user.devices_view
    }
  );

  let groupBySite = groupBy(devicesTree, 'site_id');
  let siteOnline = {};

  for (let site in groupBySite) {
    siteOnline[site] = !groupBySite[site].find((device) => !device.online);
  }

  let obj = {};

  const RED = '#E53935';
  const GREEN = '#4CAF50';
  const SITES_SPLIT = 1; // amount of sites to show another level

  for (let device of devicesTree || []) {
    obj[device.site_id] = obj[device.site_id] ?? {
      children: [],
      label: {
        color: 'white',
        fontSize: '16',
        position: 'insideBottomLeft'
      },
      itemStyle: {
        borderWidth: 0,
        borderRadius: 4,
        // color: device.online ? '#b0e2d0' : '#ffcaca',
        color: siteOnline[device.site_id] ? GREEN : RED
      },
      name: device.site_name,
      type: 'site',
      value: 0
    };
    obj[device.site_id].children.push({
      type: 'device',
      name: device.name,
      last_offline_at: device.last_offline_at,
      value: 1,
      online: device.online ? 1 : 0,
      label: {
        color: 'white',
        fontSize: '16',
        position: 'insideBottomLeft'
      },
      itemStyle: {
        borderWidth: 0,
        borderRadius: 4,
        // color: device.online ? '#b0e2d0' : '#ffcaca',
        color: device.online ? GREEN : RED // full color
      }
    });
    obj[device.site_id].value++;
  }

  obj = Object.values(obj);

  let option = {
    emphasis: {
      disabled: true
    },
    tooltip: {
      formatter: function (info) {
        if (!info.data) return '<div></div>';
        if (info.data?.type == 'site') return `<div>${info.data.name}</div>`;
        else if (info.data.type == 'device')
          return `<div>
          <div>${info.data.name}</div>
          <time style='font-size: 14px; margin-top:1rem;'>${
            info.data.online == 1
              ? ''
              : info.data.last_offline_at
              ? 'Went offline: ' +
                new Date(info.data.last_offline_at).toLocaleString()
              : ''
          }
          </div>
        `;
        else return '<div></div>';
      }
    },
    series: [
      {
        width: '95%',
        breadcrumb: {
          top: '95%',

          //  height: 20,
          show: Object.values(siteOnline).length > SITES_SPLIT,
          show: false
          // emphasis: {
          //   itemStyle: {
          //     testStyle: {
          //       backgroundColor: 'white'
          //     }
          //   }
          // },
          // itemStyle: {
          //   color: 'white',

          //   textStyle: {
          //     color: 'black',
          //     backgroundColor: 'white'
          //   }
          // }
        },
        levels: [
          {
            //color: ['#942e38', '#aaa', '#269f3c'],
            itemStyle: {
              // borderWidth: 6,
              // borderColor: '#333',
              gapWidth: 12
            }
          },
          {
            upperLabel: {
              show: true,
              height: 30,
              fontSize: 16,
              padding: 4
            },
            // color: ['#942e38', '#aaa', '#269f3c'],
            itemStyle: {
              borderColor: '#EEEEEE',
              borderWidth: 14,
              borderRadius: 4,
              gapWidth: 4
            }
          },
          {
            //color: ['#942e38', '#aaa', '#269f3c'],
            upperLabel: {
              show: true,
              height: 26,
              fontSize: 16
            },
            itemStyle: {
              borderColor: '#EEEEEE',
              borderWidth: 22,
              borderRadius: 4,
              gapWidth: 8
            }
          }
        ],
        drillDownIcon: '',
        name: 'Device Tree',
        roam: false,
        nodeClick: 'link',
        type: 'treemap',
        leafDepth: 2,
        data: obj
        // only show sites first if greater than 5
        // Object.values(siteOnline).length > SITES_SPLIT
        //   ? [{ name: 'Sites', children: obj }]
        //   : obj
      }
    ]
  };
  // there is a bug here when u are trying to pull tooltip before data load
  return (
    <Template {...props} title={false}>
      <Content css={viewStyle({ site: user?.focused_site })} {...props}>
        <PageTitle>Dashboard</PageTitle>
        <main>
          <Section id="issues">
            <header>
              <h4>Issues</h4>
            </header>
            <ul>
              <li>
                <div
                  css={css`
                    filter: ${statsLoading ? 'grayscale(1)' : 'grayscale(0)'};

                    background: ${stats?.with_issues != 0
                      ? 'var(--stats-background-red)'
                      : 'var(--stats-background-green)'};
                    color: ${stats?.with_issues != 0
                      ? 'var(--red-base)'
                      : 'var(--primary-base)'};
                  `}
                >
                  <GeoAlt />
                </div>
                <span className="name">Sites with issues</span>
                <span className="value">{stats?.with_issues}</span>
              </li>
              <li>
                <div
                  css={css`
                    filter: ${statsLoading ? 'grayscale(1)' : 'grayscale(0)'};

                    background: ${stats?.device_issues != 0
                      ? 'var(--stats-background-red)'
                      : 'var(--stats-background-green)'};
                    color: ${stats?.device_issues != 0
                      ? 'var(--red-base)'
                      : 'var(--primary-base)'};
                  `}
                >
                  <HddNetwork />
                </div>
                <span className="name">Device Issues</span>
                <span className="value">{stats?.device_issues}</span>
              </li>

              <li>
                <div
                  css={css`
                    filter: ${statsLoading ? 'grayscale(1)' : 'grayscale(0)'};

                    background: ${stats?.device_alerts != 0
                      ? 'var(--stats-background-red)'
                      : 'var(--stats-background-green)'};
                    color: ${stats?.device_alerts != 0
                      ? 'var(--red-base)'
                      : 'var(--primary-base)'};
                  `}
                >
                  <IconAlertTriangle />
                </div>
                <span className="name">Device Alerts</span>
                <span className="value">{stats?.device_alerts}</span>
              </li>

              <li>
                <div
                  css={css`
                    filter: ${statsLoading ? 'grayscale(1)' : 'grayscale(0)'};

                    background: ${stats?.door_issues != 0
                      ? 'var(--stats-background-red)'
                      : 'var(--stats-background-green)'};
                    color: ${stats?.door_issues != 0
                      ? 'var(--red-base)'
                      : 'var(--primary-base)'};
                  `}
                >
                  <DoorClosed />
                </div>
                <span className="name">Door Issues</span>
                <span className="value">{stats?.door_issues}</span>
              </li>
            </ul>
          </Section>

          {user.focused_site ? <ActiveCredential /> : <ActiveSite />}

          <ActiveDoor />

          <Section id="health">
            <header>
              <h4>Health</h4>
              <h5>Last 14 days offline/online count </h5>
            </header>

            <HealthProfile chartOptions={{ title: { show: false } }} />
          </Section>
          <Section id="day-of-week" data-shaded="true">
            <header>
              <h4>Daily Activity</h4>
              <h5>Past 7 days spread</h5>
            </header>
            <DayOfWeekChart />
          </Section>
          <Section id="events">
            <header>
              <h4>Recent Critical Events</h4>
            </header>
            <DeviceEventList filters={{ site_id: focusedSite }} />
          </Section>

          <Section id="who">
            <header>
              <h4>
                Who's In Today <WhoseinTooltip />
              </h4>
            </header>
            <WhoseInList query={whoseInQuery} />
          </Section>
          {(user.managing || user.devices_view) && (
            <Section id="tree-map">
              <header>
                <h4>Device Tree Map</h4>
                <h5>Current offline/online devices </h5>
              </header>
              {devicesTree && Array.isArray(obj) && obj?.length > 0 ? (
                <ReactECharts
                  option={option}
                  style={{ height: '600px', padding: 5 }}
                />
              ) : (
                <div
                  css={css`
                    > svg {
                      opacity: 20%;
                    }
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    label {
                      font-size: 12px;
                    }
                  `}
                >
                  <IconChartTreemap size={256} stroke={0.75} />
                  {!devicesTreeLoading && <label>no data found</label>}
                </div>
              )}
            </Section>
          )}
        </main>
      </Content>
    </Template>
  );
}

export default UserDashboard;
