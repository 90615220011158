import React from "react";
import { css } from "@emotion/react";
/** @jsxImportSource @emotion/react */
import { Form, useFormikContext } from "formik";
import { ErrorMessage, TextField } from "components/form";
import { FieldArray } from "formik";
import {
  DashCircle,
  Display,
  PlusCircle,
  ShieldMinus,
} from "react-bootstrap-icons";
import SelectWrapper from "components/select-wrapper";
import Select from "react-select";
import { SecondaryButton } from "components/buttons";

const METHODS = [
  { value: "get", label: "GET" },
  { value: "post", label: "POST" },
];

const styles = css`
  > div {
    padding-top: 10px;
  }
  .field {
    width: 100%;
    max-width: 100%;
  }
  svg {
  }
`;

const WebhookForm = ({ action }) => {
  return (
    <article>
      <p>
        <i>
          A POST Request will be sent to this url containing all events details.
        </i>
      </p>
      <TextField name="url" value={action?.url} />
    </article>
  );
};

export default WebhookForm;
