import React from 'react';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import PopoverContent, {
  Popover,
  PopoverTrigger
} from 'components/popover/popover-content';
import { HexColorPicker } from 'react-colorful';
import { IconButton } from 'components/buttons';
import { Square, SquareFill } from 'react-bootstrap-icons';
export default ({ setPathOptions, pathOptions, mapRef, selectedLayer }) => {
  React.useEffect(() => {
    if (!selectedLayer) return;

    const newPath = {
      color: selectedLayer.options?.color || '#A8A8A8',
      fillColor: selectedLayer.options?.fillColor || '#C8C8C8'
    };

    setPathOptions(newPath);
    mapRef.current.pm.setPathOptions(newPath);
  }, [selectedLayer]);

  return (
    <div
      css={css`
        display: flex;
        gap: 0.3rem;
        flex-wrap: wrap;
      `}
    >
      <Popover>
        <PopoverTrigger>
          <IconButton>
            <SquareFill color={pathOptions.fillColor} />
          </IconButton>
        </PopoverTrigger>
        <PopoverContent
          sideOffset={0}
          align="center"
          asChild
          css={css`
            width: auto;
          `}
        >
          <HexColorPicker
            color={pathOptions.fillColor}
            onChange={(e) => {
              if (selectedLayer) {
                selectedLayer?.setStyle({
                  ...pathOptions,
                  fillColor: e
                });
              }
              mapRef.current.pm.setPathOptions({
                ...pathOptions,
                fillColor: e
              });
              setPathOptions((prev) => ({
                ...prev,
                fillColor: e
              }));
            }}
          />
        </PopoverContent>
      </Popover>

      <Popover>
        <PopoverTrigger>
          <IconButton>
            <Square color={pathOptions.color} />
          </IconButton>
        </PopoverTrigger>
        <PopoverContent
          sideOffset={0}
          align="center"
          asChild
          css={css`
            width: auto;
          `}
        >
          <HexColorPicker
            color={pathOptions.color}
            onChange={(e) => {
              if (selectedLayer) {
                selectedLayer?.setStyle({
                  ...pathOptions,
                  color: e
                });
              }
              mapRef.current.pm.setPathOptions({
                ...pathOptions,
                color: e
              });
              setPathOptions((prev) => ({
                ...prev,
                color: e
              }));
            }}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
};
