import React from 'react';
import { XLg } from 'react-bootstrap-icons';
import AddWidgetDialog from './add-widget-dialog';
import { IconCubePlus } from '@tabler/icons-react';
import styled from '@emotion/styled';

const Holder = styled('div')`
  cursor: pointer;
  padding: 1rem;
  width: 100%;
  display: flex;
  opacity: 50%;
  flex-direction: column;
  flex: 1;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  border: 1px dashed black;
  flex: 1;
  height: 100%;

  transition: all 0.3s ease-in-out;

  svg {
    width: 56px;
    height: 56px;
  }
  &:hover {
    opacity: 100%;
  }
`;
export default ({ children }) => {
  return <Holder className="empty-holder">{children}</Holder>;
};
