import { useMutationWrapper } from "utils/ajax";
import Badge from "api/badge";
import { useHeadlessTable } from "providers/headless-table-provider";
import { useQueryClient } from "react-query";

export const useDeleteMutate = (badge) => {
  const queryClient = useQueryClient();
  const { queryKey } = useHeadlessTable();
  return useMutationWrapper(
    ["badge", "delete"],
    (id) => Badge.delete(id),

    {
      successMeisage: `Badge: ${badge?.name} deleted successfuly`,
      onMutate: (id) => {
        let data = queryClient.getQueryData(queryKey);

        const newData = {
          ...data,
          data: data.data.filter((row) => row.id != id),
        };

        queryClient.setQueryData(queryKey, newData);
      },
      onSuccess: () => {
        //queryClient.invalidateQueries(["badges", "stats"]);
      },
    }
  );
};
