import React from 'react';

import Template from 'base/template';
import {
  AuxiliaryButton,
  Button,
  CancelButton,
  SecondaryButton
} from 'components/buttons';
import { Redirect } from 'react-router-dom';
import FormProvider from 'providers/form-provider';
import { useMutationWrapper } from 'utils/ajax';
import { useRedirect } from 'hooks/redirect-hook';
import SensorForm from 'views/sensors/form';

import { Formik } from 'formik';
import * as Yup from 'yup';
import Content from 'base/content';
import { useParams } from 'react-router-dom';
import { useQueryWrapper } from 'utils/ajax';
import { useNotFound } from 'hooks/not-found-hook';
import PageTitle from 'components/page-title';
import Breadcrumbs from 'components/breadcrumbs';
import Sensor from 'api/sensor';
import { formStyles } from 'components/dom/form';
import { css } from '@emotion/react';
import { Heading, TextField } from 'components/form';
import Alert from 'components/alert';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Check, Check2All, Clipboard } from 'react-bootstrap-icons';
import useAlertDialog from 'providers/alert-dialog-provider';
/** @jsxImportSource @emotion/react */

const Auth = (props) => {
  const { id } = useParams();

  const [sensor, setSensor] = React.useState(props?.location?.state?.sensor);

  const query = useQueryWrapper(['sensor', 'get', id], () => Sensor.get(id), {
    enabled: !!id
  });

  useNotFound(id, query, props);

  React.useEffect(() => {
    if (query.data) setSensor(query.data);
  }, [query.data]);

  const { isSuccess, isLoading, mutate, data } = useMutationWrapper(
    ['sensor', 'generate'],
    () => Sensor.generateToken(sensor?.id)
  );

  const confirm = useAlertDialog();

  const handleGenerateToken = async () => {
    if (
      await confirm({
        description:
          'Are you sure u want to regenrate an authentication token ?'
      })
    )
      mutate();
  };

  const [isCopied, setIsCopied] = React.useState(false);
  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  return (
    <Template {...props}>
      <Content {...props}>
        <PageTitle>{sensor?.name}</PageTitle>
        <Breadcrumbs crumbs={props.crumbs} />

        <main
          css={css`
            margin-top: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            position: relative;
          `}
        >
          <div css={formStyles}>
            <Heading label="Authentication Token" />
            <section
              css={css`
                [role='alert'] {
                  grid-column: 1/-1;
                }
              `}
            >
              <Alert>
                Generating a new Authentication token to the sensor will
                invalidate the current token.
              </Alert>
              <div
                css={css`
                  grid-column: 1/-1;
                  display: grid;
                  width: 100%;
                  grid-template-columns: 1fr auto auto;
                  gap: 0.5rem;
                  > * {
                    flex: 1;
                  }
                `}
              >
                <TextField
                  title="Api key"
                  value={data?.token ?? sensor?.subscription_token}
                  disabled
                />

                <CopyToClipboard
                  text={data?.token || sensor?.subscription_token}
                  onCopy={onCopyText}
                >
                  <AuxiliaryButton>
                    {isCopied ? <Check /> : <Clipboard />}
                    Copy
                  </AuxiliaryButton>
                </CopyToClipboard>
                <AuxiliaryButton
                  loading={isLoading}
                  disabled={isLoading}
                  onClick={handleGenerateToken}
                >
                  Re-Generate
                </AuxiliaryButton>
              </div>
            </section>
          </div>
        </main>

        {/* <Network /> */}
      </Content>
    </Template>
  );
};

export default Auth;
