import React from 'react';
import Async from 'react-select/async';
import debounce from 'debounce-promise';
import { InputHelper, InputLabel, ErrorMessage } from 'components/form';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import useFormValues from 'hooks/form-values-hook';
import { wrapperStyles, customStyles } from 'components/form/styles';
import { components } from 'react-select';
import {
  Option,
  MultiValueLabel,
  MultiValueContainer,
  LimitedChipsContainer,
  ValueContainer,
  MultiValue,
  SingleValue
} from 'components/form/custom-components';

const AsyncSelect = React.forwardRef((props, ref) => {
  const {
    componentProps,
    selectAll,
    fetchFn,
    defaultSearchTerm = {},
    defaultOptionValue,
    idx = 'id',
    label = 'name',
    searchTerm,
    transformData,
    isMulti = true,
    checkboxes = true,
    title = null,
    setFieldValue,
    name // name of the filed if exists
  } = props;

  const [value, setValue] = React.useState();
  const [loadedOptions, setLoadedOptions] = React.useState([]);
  const [allSelected, setAllSelected] = React.useState(false);

  const selectAllValues = React.useCallback(() => {
    setValue([
      {
        [idx]: 0,
        [label]: 'Select All',
        value: 'select-all',
        label: 'select-all'
      }
    ]);
    setAllSelected(true);
    if (typeof props.onSelectAll == 'function') props.onSelectAll();
  }, [loadedOptions]);

  const deselectAllValues = React.useCallback(() => {
    setValue([]);
    setAllSelected(false);
    if (typeof props.onDeselectAll == 'function') props.onDeselectAll();
  }, []);

  const selectValue = React.useCallback(
    (val) => {
      setValue(val);
      setAllSelected(false);
    },
    [loadedOptions]
  );

  const isOptionDisabled = React.useCallback(
    (opt) => allSelected && opt.value !== 'select-all',
    [allSelected]
  );

  const [defaultValue, setDefaultValue] = React.useState();

  const loadOptions = async (searchKey) => {
    let searchParams = {};

    searchParams[searchTerm || label] = searchKey;

    let i = await fetchFn({
      ...searchParams,
      ...defaultSearchTerm,
      selection: Array.isArray(defaultOptionValue)
        ? defaultOptionValue
        : [defaultOptionValue]
    }).then((e) => e.data || e);

    if (selectAll)
      i = [
        {
          [idx]: 0,
          value: 'select-all',
          label: 'Select All',
          [label]: 'Select All'
        },
        ...i
      ];

    let values;

    if (isMulti)
      values = i.filter((o) =>
        Array.isArray(defaultOptionValue)
          ? defaultOptionValue?.includes(o[idx].toString())
          : defaultOptionValue == o[idx].toString()
      );
    else values = i.find((o) => o[idx] == defaultOptionValue) ?? null;

    !defaultValue && setDefaultValue(values);
    if (ref) {
      ref.current.data = values.map((i) => i[idx]);
      ref.current.fullData = values.map((i) => ({ ...i }));
    }

    if (typeof transformData == 'function') i = transformData(i);

    setLoadedOptions(i);

    return i;
  };
  const debouncedLoadOptions = debounce(loadOptions, 1000, {
    leading: false
  });
  const onChange = React.useCallback(
    (val, { removedValue, option }) => {
      option?.value === 'select-all'
        ? selectAllValues()
        : removedValue?.value === 'select-all'
        ? deselectAllValues()
        : selectValue(val);

      if (typeof props?.componentProps?.onChange == 'function')
        props.componentProps.onChange(val);
    },
    [selectAllValues, deselectAllValues, selectValue]
  );

  React.useEffect(() => {
    if (selectAll && defaultValue?.[0]?.value == 'select-all')
      selectAllValues();
    setValue(defaultValue);
  }, [defaultValue]);

  useFormValues(value, idx, name, setFieldValue, isMulti);

  return (
    <div className="async-wrapper" css={wrapperStyles}>
      <InputLabel>{title}</InputLabel>
      <InputHelper {...props} />
      <Async
        checkboxes={checkboxes}
        isOptionDisabled={isOptionDisabled}
        styles={customStyles(props)}
        loadOptions={debouncedLoadOptions}
        defaultOptions
        value={value}
        ref={ref}
        hideSelectedOptions={false}
        className="async-select field select__wrapper"
        classNamePrefix="select"
        isMulti={isMulti}
        isClearable
        idx={idx}
        label={label}
        getOptionLabel={(e) => e[label]}
        getOptionValue={(e) => e[idx]}
        closeMenuOnSelect={false}
        placeholder="Search..."
        components={{
          MultiValueLabel,
          MultiValueContainer,
          MultiValue,
          SingleValue,
          Option,
          ...props.components
        }}
        {...componentProps}
        onChange={onChange}
      />
      <ErrorMessage {...props} />
    </div>
  );
});

export default AsyncSelect;
