/** @jsxImportSource @emotion/react */
import React from 'react';
import Camera from 'api/camera';
import Template from 'base/template';
import { css } from '@emotion/react';

import PageTitle from 'components/page-title';
import Content from 'base/content';
import { useQueryWrapper } from 'utils/ajax';
import { useParams } from 'react-router-dom';
import { useNotFound } from 'hooks/not-found-hook';
import Breadcrumbs from 'components/breadcrumbs';
import CameraSettings from 'views/cameras/settings';
import Device from 'api/device';

const Settings = (props) => {
  const { id } = useParams();

  const [device, setDevice] = React.useState(props?.location?.state?.device);

  const query = useQueryWrapper(
    ['device', 'get', id],
    () => Device.search({ id, camera: true }),
    {
      enabled: !device
    }
  );

  React.useEffect(() => {
    if (!query?.data?.data?.[0]) return;
    setDevice(query.data.data?.[0]);
  }, [query?.data?.data?.[0]]);

  useNotFound(id, query, props);

  return (
    <Template {...props}>
      {device && (
        <Content {...props}>
          <PageTitle>{device?.name}</PageTitle>
          <Breadcrumbs crumbs={props.crumbs} />
          <CameraSettings camera={{ ...device, id: device.camera_id }} />
        </Content>
      )}
    </Template>
  );
};

export default Settings;
