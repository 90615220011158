import React from "react";
import { Tab } from "@headlessui/react";
import { css } from "@emotion/react";
/** @jsxImportSource @emotion/react */

const styles = css`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: var(--secondary-font-color);
  height: 100%;
  min-width: 92px;
  &:disabled {
    cursor: default;
    opacity: 20%;
  }
  &:hover:enabled {
    color: var(--table-header-hover-color);
  }
  &[aria-selected="true"] {
    color: var(--primary-base);
    border-bottom: 3px solid var(--primary-base);
    border-radius: 5px 5px 0px 0px;
    > svg {
      color: varvar(--primary-base);
    }
  }
`;
const TabItem = ({ children, ...otherProps }) => {
  return (
    <Tab css={styles} {...otherProps}>
      {children}
    </Tab>
  );
};

export default TabItem;
