import { Badge } from 'components/badge';
import {
  BookmarkCheckFill,
  ShieldExclamation,
  ShieldSlash
} from 'react-bootstrap-icons';
import { dateTimeDif } from 'utils/time';

import { css } from '@emotion/react';
import { AvatarCircle } from 'components/avatar';
import { getSelectionColumn } from 'components/table-selection-column';
import TimeFormatter from 'components/time-formatter';
/** @jsxImportSource @emotion/react */
import { ReactComponent as GuestIcon } from 'assets/guest-icon.svg';
import CredentialAccessLevels from './credential-access-levels';
import PersonDepartments from 'views/people/person-departments';
import { IconCalendarOff } from '@tabler/icons-react';
import { getRowNumberColumn } from 'components/table-rownumber-column';

export const columns = [
  getSelectionColumn(true),
  {
    Header: 'Description',
    accessor: 'Description',
    style: { width: '250px' },
    pickerDisabled: true,
    asc: 'atoz',
    desc: 'ztoa',
    newSorter: true,
    Cell: ({ row: { original } }) => {
      return (
        <div
          css={css`
            display: flex;
            gap: 0.4rem;
            align-items: center;
          `}
        >
          <span>{original.Description}</span>
          <span title={`Auth profile override: ${original.auth_profile_name}`}>
            {original.auth_profile_name && <ShieldExclamation size={18} />}
          </span>
          <span title={`No Access Level(s)`}>
            {original.access_level_count == 0 && <ShieldSlash size={18} />}
          </span>
          <span title={`Credential operation time is not yet operational`}>
            {new Date(original.ValidFrom) > new Date() && (
              <IconCalendarOff size={18} />
            )}
          </span>
        </div>
      );
    }
  },
  {
    Header: 'Status',
    id: 'enabled',
    accessor: 'Enabled',
    disableSortBy: true,
    style: { width: '120px' },
    Cell: ({ row: { original: status } }) => {
      if (status.isLoading) return status.statusMessage;
      return (
        <span
          css={css`
            display: flex;
            gap: 5px;
          `}
        >
          {status.Enabled == 1 && <Badge color="success">Active</Badge>}
          {status.Enabled == 0 && <Badge color="danger">Disabled</Badge>}
          {status.lost == 1 && <Badge color="warning">Lost</Badge>}
        </span>
      );
    }
  },
  {
    Header: 'In Today',
    disableSortBy: true,
    Cell: ({ row: { original } }) =>
      new Date().toDateString() ==
        new Date(original.last_access_time).toDateString() && (
        <BookmarkCheckFill size={24} color="#008E5B" />
      )
  },
  {
    Header: 'Last Access',
    id: 'last_access_time',
    accessor: 'last_access_time',
    asc: 'oldestLastAccess',
    desc: 'newestLastAccess',
    newSorter: true,
    Cell: ({ cell: { value } }) => value && <TimeFormatter dt={value} />
  },

  {
    Header: 'Card',
    accessor: 'card',
    asc: 'atozCard',
    desc: 'ztoaCard',
    newSorter: true
  },
  {
    Header: 'Card Nr',
    accessor: 'cardNr',
    asc: 'atozCardNr',
    desc: 'ztoaCardNr',
    newSorter: true
  },
  {
    Header: 'Facility Code',
    accessor: 'facilityCode',
    asc: 'atozFacilityCode',
    desc: 'ztoaFacilityCode',
    newSorter: true
  },
  {
    Header: 'Pin',
    accessor: 'pin',
    asc: 'atozPin',
    desc: 'ztoaPin',
    newSorter: true
  },
  {
    Header: 'Person',
    accessor: 'person_name',
    style: { width: '220px' },
    disableSortBy: true,
    Cell: ({ row: { original } }) => {
      return (
        <article
          css={css`
            display: flex;
            gap: 5px;
            align-items: center;

            svg {
              width: 32px;
              height: 32px;
              color: var(--neutral-02);
            }
          `}
        >
          {original.guest_id ? (
            <GuestIcon />
          ) : (
            <AvatarCircle
              name={original.person_name}
              url={original.person_avatar}
            />
          )}
          <span>{original.person_name}</span>
        </article>
      );
    }
  },
  {
    Header: 'Update Time',
    accessor: 'update_time',
    asc: 'oldestUpdated',
    desc: 'newestUpdated',
    newSorter: true,
    Cell: ({ cell: { value } }) => <span>{dateTimeDif(value)}</span>
  },
  {
    Header: 'Access Levels',
    accessor: 'access_level_names',
    style: { width: '250px' },
    disableSortBy: true,
    Cell: ({ row: { original: credential } }) => (
      <CredentialAccessLevels credential={credential} />
    )
  },
  {
    Header: 'Departments',
    accessor: 'department_names',
    style: { width: '250px' },
    disableSortBy: true,
    Cell: ({ row: { original: credential } }) => (
      <PersonDepartments person={credential} />
    )
  }
];
