import { useMutationWrapper } from 'utils/ajax';
import Company from 'api/company';
import { useQueryClient } from 'react-query';
import { useHeadlessTable } from 'providers/headless-table-provider';
import { jsonToCSV } from 'react-papaparse';
import { format } from 'date-fns';
import GenericDoor from 'api/generic_door';

export const useDeleteMutate = (company) => {
  const queryClient = useQueryClient();
  const { queryKey } = useHeadlessTable();
  return useMutationWrapper(
    ['company', 'delete'],
    (id) => Company.delete(id),

    {
      successMessage: `Company: ${company?.display_name} was deleted.`,
      onMutate: (id) => {
        let data = queryClient.getQueryData(queryKey);

        const newData = {
          ...data,
          data: data.data.filter((row) => row.id != id)
        };

        queryClient.setQueryData(queryKey, newData);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['companies']);
      }
    }
  );
};

export const useExportDoors = (data) => {
  return useMutationWrapper(
    ['generic_door', 'export'],
    (options) => GenericDoor.export(options),

    {
      onSuccess: (data) => {
        data = data.map((d) => ({
          door_name: d.name,
          insert_time: d.insert_time,
          days_active: d.days_active,
          company_name: d.company_name,
          installer_id: d.installer_id,
          installer_name: d.installer_name,
          site_id: d.site_id,
          site_name: d.site_name
        }));

        let csvFile = jsonToCSV(data);

        var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
        var link = document.createElement('a');

        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        const formattedDate = format(new Date(), 'MM-dd-yy');
        link.setAttribute('download', `doors-${formattedDate}.csv`);
        link.style = 'visibility:hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  );
};
