import React from "react";
import { useHistory } from "react-router-dom";
import {
  useMutateDelete,
  useMutateReset,
  useMutateRestoreEvents,
} from "./actions";

import {
  MenuItem,
  MenuItemButton,
  MenuItemDelete,
  MenuWrapper,
} from "components/action-menu";
import { useModal } from "providers/modal-provider";
import SyncModal from "./sync-modal";
import { Menu } from "@headlessui/react";
import { DialogDisclosure } from "reakit/Dialog";
import { ArrowClockwise } from "react-bootstrap-icons";
import { Button } from "components/buttons";
import IsUserAllowed from "components/is-user-allowed";
import IsCompany from "components/is-company";

export default function ActionMenu({ data: ac, ...otherProps }) {
  const history = useHistory();

  const mutateDelete = useMutateDelete(ac);
  const mutateReset = useMutateReset(ac);
  const mutateRestoreEvents = useMutateRestoreEvents(ac);

  /**const handleDelete = (e) =>
    confirm(`Are you sure you want to delete: ${ac?.name} ?`) &&
    mutateDelete.mutate(ac?.id);**/
  const { dialog, setModalState } = useModal();

  const handleReset = (e) =>
    confirm(`Are you sure you want to reset: ${ac?.name} ?`) &&
    mutateReset.mutate(ac?.id);

  const handleRestoreEvents = (e) =>
    confirm(
      `Are you sure you want to restore Events on: ${ac?.name} ?,\n This might take a while`
    ) && mutateRestoreEvents.mutate(ac?.id);

  let handleEdit = (e) =>
    history.push("controllers/save/" + ac?.id, {
      ac,
      desc: ac?.name,
    });

  let handleTools = (e) =>
    history.push("controllers/details/" + ac?.id, {
      ac,
      desc: ac?.name,
    });

  const handleConfigure = (e) => {
    history.push("/controllers/setup/" + ac.id, {
      ac,
      desc: ac.name,
    });
  };

  return (
    <MenuWrapper {...otherProps}>
      <IsCompany>
        <MenuItem label="Edit" onClick={handleEdit} />
      </IsCompany>
      <MenuItem label="Configure" onClick={handleConfigure} />
      <MenuItem label="Reset" onClick={handleReset} />
      <MenuItem label="Tools" onClick={handleTools} />

      <DialogDisclosure
        {...dialog}
        as={MenuItem}
        disabled={otherProps.disabled}
        onClick={() => {
          setModalState({
            component: SyncModal,
            data: ac,
            dialogProps: { hideOnClickOutside: false, hideOnEsc: false },
          });
        }}
      >
        Sync Check...
      </DialogDisclosure>
      <MenuItem label="Restore Events" onClick={handleRestoreEvents} />

      {/**<MenuItemDelete onClick={handleDelete} />**/}
    </MenuWrapper>
  );
}
