import React from "react";
import { Formik, Field, Form } from "formik";
import { css } from "@emotion/react";
/** @jsxImportSource @emotion/react */
import { useFormikContext } from "formik";
import { ErrorMessage, InputLabel } from "components/form";
import { set } from "js-cookie";

const styles = css`
  padding-bottom: 8px;
  > div {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  label {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  label[aria-selected="true"] {
    font-weight: 500;
  }

  input[type="radio"] {
    width: 20px;
    height: 20px;
    accent-color: var(--primary-base);
    border-radius: 5px;
    border-color: gray;
  }
`;

const FrequencyGroup = ({ localEvent = {}, setLocalEvent }) => {
  const { values, setFieldValue } = useFormikContext();

  return (
    <section className="frequency-group" css={styles}>
      <InputLabel label="frequency" />
      <div role="group" aria-labelledby="frequency-group">
        <label aria-selected={values.freq == "O"}>
          <input
            onChange={(e) => {
              setFieldValue("freq", e.target.value);
            }}
            checked={values.freq == "O"}
            type="radio"
            name="freq"
            value="O"
          />
          One Time
        </label>
        <label aria-selected={values.freq == "W"}>
          <input
            onChange={(e) => {
              setFieldValue("freq", e.target.value);
            }}
            checked={values.freq == "W"}
            type="radio"
            name="freq"
            value="W"
          />
          Weekly
        </label>
        <label aria-selected={values.freq == "Y"}>
          <input
            onChange={(e) => {
              setFieldValue("freq", e.target.value);
            }}
            checked={values.freq == "Y"}
            type="radio"
            name="freq"
            value="Y"
          />
          Yearly
        </label>
      </div>
      <ErrorMessage name="freq" />
    </section>
  );
};

export default FrequencyGroup;
