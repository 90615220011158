import React from 'react';
import { useHistory } from 'react-router-dom';
import { useMutateDelete, useMutateLock } from './actions';

import { MenuItem, MenuItemDelete, MenuWrapper } from 'components/action-menu';
import IsUserAllowed from 'components/is-user-allowed';
import { useAuth } from 'providers/auth';
import useAlertDialog from 'providers/alert-dialog-provider';

export default function ActionMenu({ data: al, elevatorsOnly, ...otherProps }) {
  const history = useHistory();
  const confirm = useAlertDialog();

  const mutateDelete = useMutateDelete(al);
  const mutateLock = useMutateLock(al);

  const [isEnabled, setIsEnabled] = React.useState();

  const { user } = useAuth();

  React.useEffect(() => {
    if (!al) return;
    let als = al.sites ?? [];
    let isEnabled =
      !user.original_sites ||
      (als && als.filter((a) => !user.original_sites.includes(a)).length == 0);

    setIsEnabled(otherProps.disabled || isEnabled);
  }, [otherProps.disabled]);

  let handleEdit = () =>
    history.push(
      elevatorsOnly
        ? '/elevators/access/save/' + al?.id
        : '/access-levels/save/' + al?.id,
      {
        al,
        desc: al?.name
      }
    );

  let handleManage = () =>
    history.push(`/access-levels/${al?.id}/manage-doors`, {
      al: al,
      desc: al?.name
    });

  const handleDelete = async () => {
    if (
      await confirm({
        description: `Are you sure you want to delete Access Level: ${al?.name} ?`
      })
    )
      mutateDelete.mutate(al?.id);
  };
  const handleLock = async () => {
    if (al.enabled == 1) {
      if (
        await confirm({
          description: `Locking this access level will disable access to all associated credentials!`
        })
      )
        mutateLock.mutate(al?.id);
    } else mutateLock.mutate(al?.id);
  };

  return (
    <>
      <MenuWrapper disabled={!isEnabled}>
        <IsUserAllowed condition="alEdit" restrict="C">
          <MenuItem label="Lock/Unlock" onClick={handleLock} />
        </IsUserAllowed>
        <IsUserAllowed condition="alEdit" restrict="C">
          <MenuItem label="Edit" onClick={handleEdit} />
          <MenuItem onClick={handleManage}>Manage Doors</MenuItem>
          <MenuItemDelete onClick={handleDelete} />
        </IsUserAllowed>
      </MenuWrapper>
    </>
  );
}
