import React from 'react';

import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import { Button, AuxiliaryButton, CancelButton } from 'components/buttons';
import { Check2Circle, PlusLg } from 'react-bootstrap-icons';
import { useFormikContext, FieldArray, Formik, Form } from 'formik';
import { TextField, DateTimeField, InputLabel } from 'components/form';
import FrequencyGroup from './frequency-radios';
import WeekCheckboxGroup from './week-checkbox-group';
import { dateTimeLocalSQLNoSeconds } from 'utils/time';
import * as Yup from 'yup';
import { useModal } from 'providers/modal-provider';
import { Modal, ModalTitle } from 'components/modal';
import Label from 'components/label';
import { Field } from 'formik';
import SelectWrapper from 'components/headless-select';
import { ErrorMessage } from 'components/form';

const EVENT_TYPES = {
  O: 'One Time',
  W: 'Weekly',
  Y: 'Yearly'
};

export const INTERVALS = [
  { id: 1, name: 'Every week' },
  { id: 2, name: 'Every 2 weeks' },
  { id: 3, name: 'Every 3 weeks' },
  { id: 4, name: 'Every 4 weeks' }
];

const SCHEMA = Yup.object().shape({
  days: Yup.array()
    .nullable()
    .when('freq', {
      is: (freq) => freq === 'W',
      then: Yup.array()
        .required()
        .nullable()
        .min(1, 'At least one item is required in the array')
    }),
  name: Yup.string()
    .min(2, 'Name is too short')
    .max(40, '40 characters max')
    .required('Required'),
  start: Yup.date()
    .max(new Date('2036-06-12T17:30'), 'Must be before 2036-06-12T17:30')
    .required('Required'),

  until: Yup.date()
    .max(new Date('2036-06-12T17:30'), 'Must be before 2036-06-12T17:30')
    .required('Required'),
  freq: Yup.string().required('Required'),

  end: Yup.date()

    .max(new Date('2036-06-12T17:30'), 'Must be before 2036-06-12T17:30')
    .required('Required')
    .when('start', (start, Yup) => {
      start &&
        Yup.min(start, 'End time cannot be before start time').nullable();
    })
});
const INITIAL_VALUES = {
  interval: 1,
  freq: '',
  name: '',
  start: dateTimeLocalSQLNoSeconds(new Date()),
  end: dateTimeLocalSQLNoSeconds(new Date()),
  until: '2036-06-12T00:00',
  days: []
};

const styles = css`
  color: var(--neutral-00);

  > form {
    display: flex;
    min-width: 40vw;
    min-height: 30vh;
    flex-direction: column;
    gap: 24px;
    footer {
      margin-top: auto;
      justify-content: space-between;
    }
  }
  padding: 24px;
`;
const EventModal = ({ eventType = 'include', eventIndex, mainForm }) => {
  const [initialValues, setInitialValues] = React.useState(INITIAL_VALUES);

  const { dialog } = useModal();

  React.useEffect(() => {
    if (eventIndex == null) setInitialValues(INITIAL_VALUES);
    else setInitialValues(mainForm.values.events[eventIndex]);
  }, [eventIndex, mainForm.values]);

  return (
    <>
      <ModalTitle>{eventType} Event</ModalTitle>
      <div css={styles}>
        <Formik
          enableReinitialize={true}
          validationSchema={SCHEMA}
          initialValues={initialValues}
          onSubmit={(values, { resetForm }) => {
            // alert(mainForm.values[type][values.freq]);
            // submit to main form
            values.type =
              eventIndex?.type ?? eventType == 'include' ? 'I' : 'E';
            if (values.freq != 'W') values.days = null;
            eventIndex != null
              ? mainForm.setFieldValue(`events[${eventIndex}]`, values)
              : mainForm.setFieldValue('events', [
                  ...mainForm.values['events'],
                  values
                ]);
            //reset state
            //alert(JSON.stringify(values));
            resetForm();

            dialog.hide();
          }}
        >
          {({ values, handleSubmit, errors, resetForm, setFieldValue }) => (
            <Form>
              <FrequencyGroup />

              <TextField
                className="event-description"
                name="name"
                value={values.name}
                label="Event Description"
              />
              <section
                className="dates"
                css={css`
                  width: 100%;
                  display: flex;
                  gap: 16px;
                  > div {
                    flex: 1;
                  }
                `}
              >
                <Field
                  name="start"
                  label="First Occurence Start"
                  component={DateTimeField}
                />

                <Field
                  name="end"
                  label="First Occurence Ends"
                  component={DateTimeField}
                />

                {(values?.freq == 'W' || values?.freq == 'Y') && (
                  <>
                    <Field
                      name="until"
                      label="Terminate Schedule on"
                      component={DateTimeField}
                    />
                  </>
                )}
              </section>

              {values?.freq == 'W' && (
                <div
                  css={css`
                    width: 30%;
                  `}
                >
                  <SelectWrapper
                    title="Interval"
                    helper="How often to run this event"
                    simple
                    options={INTERVALS}
                    value={INTERVALS.find(
                      (i) => parseInt(i.id) == parseInt(values.interval)
                    )}
                    onChange={(e) => {
                      setFieldValue('interval', e?.id);
                    }}
                  />
                </div>
              )}

              {values?.freq == 'W' && (
                <>
                  <WeekCheckboxGroup />
                  <ErrorMessage name="days" />
                </>
              )}

              <footer>
                <Button type="submit" onSubmit={handleSubmit}>
                  <Check2Circle size={24} />
                  Save
                </Button>

                <CancelButton
                  onClick={() => {
                    resetForm();
                    dialog.hide();
                  }}
                />
              </footer>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default EventModal;
