import React from 'react';
import styled from '@emotion/styled';
import RemoveItemButton from './remove-item-button';
import { WidgetHeader } from './widget-header';
const Container = styled('div')`
  display: flex;
  flex-direction: column;
`;
const Clock = styled('time')`
  font-size: 56px;
  font-family: mono;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
export default ({
  isEdit,
  selectedView,
  index,
  widgetIndex,
  setSelectedView
}) => {
  let time = new Date().toLocaleTimeString();

  const [ctime, setTime] = React.useState(time);
  const UpdateTime = () => {
    time = new Date().toLocaleTimeString();
    setTime(time);
  };
  setInterval(UpdateTime);
  return (
    <Container>
      {isEdit && (
        <WidgetHeader>
          <RemoveItemButton
            onClick={() => {
              let newLayout = [...selectedView.layout];
              newLayout[index].items = newLayout[index]?.items?.filter(
                (_, i) => i !== widgetIndex
              );
              setSelectedView((prev) => ({
                ...prev,
                layout: newLayout
              }));
            }}
          />
        </WidgetHeader>
      )}
      <Clock>{ctime}</Clock>
    </Container>
  );
};
