import React from 'react';

import {
  components,
  pipelines,
  isRtcpBye
} from 'media-stream-library/dist/browser-esm';

import { useQuery } from 'react-query';
import 'css/camera-feed.css';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import Heading from 'components/heading';
import TransmitAudio from 'views/intercoms/transmit-audio';
import CameraActionsPanel from 'components/camera-actions-panel';
import { MEDIA_HOST_URL } from '../../api/api-url';
import useWindowFocus from 'hooks/window-focus';
import Video from 'components/video';

function CameraFeed({ camera, title, recording_id, ...otherProps }) {
  const [feedUrl, setFeedUrl] = React.useState();
  const ref = React.createRef();

  const [isVideoLoaded, setIsVideoLoaded] = React.useState(false);

  // let { data, isError, isFetching } = useQuery(
  //   ["camera", "rtsp-session", camera?.id],
  //   () => CameraAction.getRTSPSessionToken(camera?.id),
  //   { cacheTime: 0 }
  // );

  // React.useEffect(() => {
  //   if (!data) return;

  //   setFeedUrl(data.url);
  // }, [data]);

  const focused = useWindowFocus();

  const [play, setPlay] = React.useState();
  const [fetching, setFetching] = React.useState();

  let pipeline;

  // Grab a reference to the video element
  let Pipeline;
  let mediaElement;

  React.useEffect(() => {
    if (!play) return;
    if (!focused) return;
    let uri;
    //  if (!data) return;
    if (recording_id)
      uri = `rtsp://localuser/axis-media/media.amp?audio=0&compression=100&recordingid=${recording_id}`;
    else uri = `rtsp://localuser/axis-media/media.amp?audio=0&compression=100`;

    Pipeline = pipelines.Html5VideoPipeline;
    mediaElement = ref.current;
    // hide the other output

    // Setup a new pipeline
    pipeline = new Pipeline({
      onServerClose: () => {},
      ws: {
        uri: `${MEDIA_HOST_URL}/camera/${camera.id}/rtsp`

        //uri: `ws://localhost:9200`,
        // uri: `ws://${camera.ip}/rtsp-over-websocket?rtspwssession=${data.data}`,
      },
      rtsp: {
        uri
        // feed by a recording id
        //uri: `rtsp://localuser/axis-media/media.amp?camera=1&overview=0&videoframeskipmode=empty&timestamp=1651573337542&Axis-Orig-Sw=true&recordingid=20220503_061540_6C31_B8A44F3D8463`,
      },
      auth: { username: 'localuser', password: 'localuser' },

      mediaElement
    });

    pipeline.rtsp.onRtcp = (rtcp) => {
      if (isRtcpBye(rtcp)) {
        setTimeout(() => pipeline.rtsp.play(), 0);
      }
    };

    // Restart stream on RTCP BYE (stream ended)

    pipeline.ready.then(() => {
      pipeline.rtsp.incoming.on('error', (err) => {
        console.log(err);
      });
      pipeline && pipeline.rtsp.play();
      console.log(pipeline);
    });

    return () => {
      // pipeline && pipeline.rtsp.stop();
      pipeline && pipeline.close();
    };
  }, [recording_id, focused, play]);

  React.useEffect(() => {
    console.log(focused);
    if (!focused) pipeline && pipeline.close();
  }, [focused]);

  return (
    <article className="camera-feed">
      {title && <Heading label="Live Feed" />}

      {focused && <Video ref={ref} play={play} setPlay={setPlay} />}
    </article>
  );
}

export default CameraFeed;
