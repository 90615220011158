import React from 'react';
import ReactECharts from 'echarts-for-react';
import { css } from '@emotion/react';
import { useHeadlessTable } from 'providers/headless-table-provider';
import { format } from 'date-fns';
import EmptyHolder from 'components/empty-holder';
import { isEmptyObject, getPast7Days } from 'utils/arrays';

/** @jsxImportSource @emotion/react */
export default ({ activity, ...props }) => {
  activity =
    activity && typeof activity !== 'object' ? JSON.parse(activity) : activity;

  // remove and keep only 7 days
  // hack to keep all data and not screen 7 days
  // TODO move cpu_usage wihch uses this chart to its own function
  if (!props.keepData) {
    let last7Days = getPast7Days();

    if (activity)
      for (const date in activity) {
        if (!last7Days.includes(date)) {
          delete activity[date];
        }
        if (isEmptyObject(activity)) activity = null;
      }
  }

  //sort the activity object
  const keyValueArray = Object.entries(activity ?? {});

  // Step 2: Sort the array by dates (keys)
  keyValueArray.sort((a, b) => {
    const dateA = new Date(a[0]);
    const dateB = new Date(b[0]);
    return dateA - dateB;
  });

  // Step 3: Convert the sorted array back into an object
  activity = Object.fromEntries(keyValueArray);

  const dates = Object.keys(activity ?? {});
  const values = Object.values(activity ?? {});

  const average =
    activity && !isEmptyObject(activity)
      ? values.reduce((acc, val) => acc + val, 0) / values.length
      : 0;

  const max = values ? Math.max(...values) : 0;

  const DEFUALT_STYLES = {
    graphic: {
      elements: [
        (!activity || isEmptyObject(activity)) && {
          type: 'text',
          left: 'center',
          top: 'center',
          style: {
            text: 'no data',
            fontSize: 12,
            fill: '#7f879',
            opacity: 0.3
          }
        }
      ]
    }
  };

  let option;
  if (props.full) {
    option = {
      ...DEFUALT_STYLES,
      title: {
        text: '7 Day Trend'
      },

      animation: false,

      tooltip: {
        trigger: 'axis',
        confine: true,
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        position: function (pos, params, el, elRect, size) {
          var obj = { top: 10 };
          obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
          return obj;
        }
      },

      grid: {
        top: 40,
        left: 40,
        right: 30,
        bottom: 55
      },

      visualMap: {
        orient: 'horizontal',
        show: true,
        pieces: [
          {
            gt: 0,
            lte: 1.25 * average,
            color: '#2480d6',
            label: 'in avg.'
          },
          {
            gt: 1.25 * average,
            //lte: 1.25 * average + 100,
            color: '#f5861f',
            label: 'above avg.'
          }
        ],
        outOfRange: {
          color: '#999'
        }
      },
      xAxis: {
        type: 'category',
        data: dates,
        axisTick: {
          show: false
        },
        // axisLine: {
        //   show: activity || !isEmptyObject(activity)
        // },

        show: true,
        axisLabel: {
          show: true,
          formatter: (data) => {
            return format(new Date(data), 'MM-dd');
          }
        }
      },
      yAxis: {
        type: 'value',
        show: true,
        axisLabel: {
          formatter: function (value) {
            // Check if the value is an integer (whole number)
            if (Number.isInteger(value)) {
              return value; // Display the whole number as is
            } else {
              return ''; // Empty string for non-whole numbers
            }
          }
        }
      },

      series: [
        {
          name: 'Entrances',
          smooth: 0.1,
          symbol: 'none',
          data: values,
          type: 'line',
          areaStyle: {
            opacity: 0.1
          }
        }
      ]
    };
  } else
    option = {
      ...DEFUALT_STYLES,
      animation: false,

      title: {
        show: max > 0 && props.showMaxTitle,
        text: max,
        left: 0,
        top: 0,
        textStyle: {
          fontSize: 13
        }
      },

      tooltip: {
        trigger: 'item'

        // backgroundColor: 'rgba(255, 255, 255, 0.8)',
        // position: function (pos, params, el, elRect, size) {
        //   var obj = { top: 10 };
        //   obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
        //   return obj;
        // },
        // extraCssText: 'width: 170px'
      },

      grid: {
        top: props.showMaxTitle ? 15 : 10,
        left: 10,
        right: 10,
        bottom: 10
      },
      visualMap: [
        {
          show: false,
          seriesIndex: 0,
          inRange: {
            color: ['#6D3CD5']
          }
        }
      ],
      visualMap: {
        show: false,
        pieces: [
          {
            gt: 0,
            lte: 1.25 * average,
            color: '#2480d6'
          },
          {
            gt: 1.25 * average,
            lte: 1.25 * average + 100,
            color: '#f5861f'
          }
        ],
        outOfRange: {
          color: '#999'
        }
      },
      xAxis: [
        {
          type: 'category',
          data: dates,
          axisTick: {
            show: false
          },
          min: Math.min(values),
          max: Math.max(values),
          show: true,
          ...(props.full ? { axisLabel: { show: true } } : {}),
          axisLine: {
            show: activity && !isEmptyObject(activity)
          }
        }
      ],
      yAxis: {
        type: 'value',
        show: false
      },
      /**series: [
          {
            type: 'bar',
            data: values,
            barWidth: 15,
            itemStyle: {
              color: ({ value }) => {
                return value > (125 / 100) * average ? '#f5861f' : '#2480d6';
              }
            }
          }
        ]**/
      series: [
        {
          smooth: 0.1,
          symbol: 'none',
          data: values,
          type: 'line',
          areaStyle: {
            opacity: 0.1
          }
        }
      ]
    };

  return (
    <div
      className="activity-chart-formatter"
      css={css`
        //  pointer-events: none !important;
      `}
    >
      <ReactECharts
        opts={{ renderer: 'svg' }}
        option={option}
        notMerge={true}
        style={props.style || { height: '50px', width: '150px' }}
      />
    </div>
  );
};
