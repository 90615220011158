import React from 'react';
import ReactEcharts from 'echarts-for-react';
import { startOfHour, endOfHour, isWithinInterval } from 'date-fns';
const DAY = 1000 * 60 * 60 * 24;
const MIN = 1000 * 60;
const HOUR = 1000 * 60 * 60;

function debounce(func, timeout = 1200) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

const Timeline = React.forwardRef(
  (
    {
      zoomLevelRef,
      setZoomLevel,
      isLoading,
      option,
      dataZoom,
      setCurrentTime,
      setCurrentRecordingTime,
      play,
      setPlay,
      setOption,
      running,
      updateMarkLine,
      currentTimeRef,
      setSeek,
      _seekRef,
      setVapixParams,
      recordingData,
      exportTime,
      setExportTime,
      videoEl
    },
    chartRef
  ) => {
    const throttleRef = React.useRef(null);
    React.useEffect(() => {
      if (!chartRef.current) return;

      const limitBrushSelection = (params) => {
        if (!params?.batch[0]?.areas[0]?.coordRange) return;
        const [start, end] = params?.batch[0]?.areas[0]?.coordRange;
        if (!start || !end) return;

        // if (end - start > 1800000) {
        //   chartRef.current.getEchartsInstance().dispatchAction({
        //     type: 'brush',
        //     areas: []
        //   });
        //   return;
        // }

        setExportTime({ start, end });
      };

      chartRef.current
        .getEchartsInstance()
        .on('brushSelected', limitBrushSelection);

      var zr = chartRef.current.getEchartsInstance().getZr();
      //click with throttle
      const handleClick = (params) => {
        running.current = false;

        var pointInPixel = [params.offsetX, params.offsetY];
        var pointInGrid = chartRef.current
          .getEchartsInstance()
          .convertFromPixel('grid', pointInPixel);

        if (!pointInGrid || !pointInPixel) return;
        if (Number(pointInGrid[0]) > Date.now()) return;

        // chartRef.current.getEchartsInstance().dispatchAction({
        //   type: 'dataZoom',
        //   start: Number(pointInGrid[0]) - MIN * 30,
        //   end: Number(pointInGrid[0]) + MIN * 30
        // });

        let clickedTime = Number(pointInGrid[0]);

        if (params.target && zoomLevelRef.current > 3) {
          // he clicked an hour
          //round to nearest hour to always get
          const MHOUR = 60 * 60 * 1000;
          let roundedTime = Math.round(clickedTime / MHOUR) * MHOUR;
          zoomLevelRef.current = 2;
          setZoomLevel(2);
          dataZoom.current = {
            start: roundedTime,
            end: roundedTime + 60000 * 59 // add 59 minutes
          };
          clickedTime = roundedTime + 60000;
        }

        // uncomment this if u want to set this straigh on or just use the timeline to fetch every 1 seccond

        // let rec;

        // if (recordingData?.current)
        //   rec = recordingData?.current.find((r) =>
        //     isWithinInterval(new Date(clickedTime), {
        //       start: new Date(r.value[1]),
        //       end: new Date(r.value[2])
        //     })
        //   );

        // console.log('CCC', clickedTime, new Date(clickedTime));
        _seekRef.current = clickedTime;
        if (videoEl) videoEl.videoTime = null;
        setSeek(clickedTime);

        debounce(updateMarkLine(clickedTime));
        //setVapixParams({ recordingid: rec?.rec, seek: clickedTime });

        setCurrentTime(clickedTime);

        running.current = true;
      };

      zr.on('click', handleClick);

      zr.on('mousedown', function (params) {
        running.current = false;
      });

      zr.on('mousemove', function (params) {
        //stop when hovering a series item and not in recording

        if (!_seekRef.current) running.current = !params.target;
      });

      zr.on('mouseout', function (params) {});

      zr.on('mouseup', function (params) {
        running.current = true;
      });

      chartRef.current.getEchartsInstance().on('datazoom', function (params) {
        let interval = chartRef?.current
          ?.getEchartsInstance()
          .getModel()
          .getComponent('xAxis', 0).axis.scale._interval;
        dataZoom.current = {
          start: chartRef.current.getEchartsInstance().getModel().option
            .dataZoom[0].startValue,
          end: chartRef.current.getEchartsInstance().getModel().option
            .dataZoom[0].endValue,
          interval
        };

        if (interval == 60000) {
          zoomLevelRef.current = 1;
          setZoomLevel(1);
        }
        if (interval == 103680000) {
          zoomLevelRef.current = 7;
          setZoomLevel(7);
        }
        if (interval == 21600000) {
          zoomLevelRef.current = 4;
          setZoomLevel(4);
        }
        if (interval == 3600000) {
          zoomLevelRef.current = 2;
          setZoomLevel(2);
        }
      });

      chartRef.current
        .getEchartsInstance()
        .on('datarangeselected', function (params) {
          dataZoom.current = {
            start: chartRef.current.getEchartsInstance().getModel().option
              .dataZoom[0].startValue,
            end: chartRef.current.getEchartsInstance().getModel().option
              .dataZoom[0].endValue
          };
        });

      //TOD REMOVE HANDERL
    }, [chartRef, videoEl]);

    return (
      <div
      //  onMouseEnter={() => setRunning(false)}
      //onMouseLeave={() => setRunning}
      >
        <ReactEcharts
          loadingOption={{
            showSpinner: false,

            fontWeight: 'normal',
            text: 'Fetching Data...',
            fontStyle: 'normal',
            fontFamily: 'inherit'
          }}
          showLoading={isLoading}
          ref={chartRef}
          option={option}
          style={{ height: '100px', width: '100%' }}
        />
      </div>
    );
  }
);

export default Timeline;
