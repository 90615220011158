import { Card } from './card';
import { IconAccessPoint } from '@tabler/icons-react';
import { useMutatePing } from '../actions';
import { Button } from 'components/buttons';

export default ({ device }) => {
  const pingMutate = useMutatePing(device);

  const handlePing = () => {
    pingMutate.mutate(device.id);
  };

  return (
    <Card className="ping">
      <div className="icon">
        <IconAccessPoint />
      </div>
      <div className="content">
        <h2>Ping</h2>
        <span className="description">Check if device is online.</span>
      </div>
      <div className="action">
        <Button disabled={pingMutate.isLoading} onClick={handlePing} compact>
          Ping
        </Button>
      </div>
    </Card>
  );
};
