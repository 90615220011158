import React from 'react';
import { DoorIcon } from 'icons';
import {
  ArrowRepeat,
  InfoCircleFill,
  Lock,
  LockFill,
  Question,
  Unlock,
  Power,
  ShieldLock,
  CameraVideoFill,
  CameraVideo
} from 'react-bootstrap-icons';
import { CircleIcon } from 'components/circle-icon';
import { css } from '@emotion/react';
import { Tooltip } from 'components/tooltip';
import { useGetStateMutate } from './actions';
import { SecondaryButton } from 'components/buttons';
import { dateTime } from 'utils/time';
import RefreshDoorStateButton from './refresh-door-state-button';
import useDoorStateSocket from './use-door-state-socket';
import Tip from './door-tooltip';
import DoorAction from 'views/notifications/door-action-form';
/** @jsxImportSource @emotion/react */
import { ReactComponent as DoubleLockIcon } from 'assets/double-lock.svg';
import IsDoorWithCamera from '../cameras/is-door-with-camera';

const DOOR_STATES = {
  Unlocked: {
    icon: Unlock
  },
  DoubleLocked: {
    icon: DoubleLockIcon
  },
  LockedDown: {
    icon: ShieldLock
  },
  Locked: {
    icon: Lock
  },
  Accessed: {
    icon: Unlock
  },
  1: {
    icon: Unlock
  },
  0: {
    icon: Lock
  }
};

const styles = (size) => css`
  svg {
    flex: 1;
    height: 80%;
    width: 80%;
  }
  &[data-alarm='true'] {
    //background: var(--red-v-02) !important;
  }
  &[data-online='0'] {
    filter: grayscale(1);
  }
  &[data-state='Locked'] {
    background: var(--door-avatar-locked-background);
    color: var(--door-avatar-locked-color);

    //color: var(--red-base);
  }

  &[data-state='Accessed'] {
    background: var(--door-avatar-accessed-background);
    color: var(--door-avatar-accessed-color);
    //color: var(--secundary-base);
  }
  &[data-state='DoubleLocked'] {
    background: var(--door-avatar-doublelocked-background);
    color: var(--door-avatar-doublelocked-color);
    //color: var(--secundary-base);
  }
  &[data-state='0'] {
    background: var(--door-avatar-locked-background);
    color: var(--door-avatar-locked-color);

    // color: var(--red-base);
  }

  &[data-static='true'] {
    filter: grayscale(1);
  }
  &[data-state='Unlocked'] {
    background: var(--door-avatar-unlocked-background);
    color: var(--door-avatar-unlocked-color);
  }
  &[data-state='LockedDown'] {
    background: var(--door-avatar-doublelocked-background);
    color: var(--door-avatar-doublelocked-color);
  }
  &[data-state='1'] {
    //door that is an auxiliary

    background: var(--door-avatar-unlocked-background);
    color: var(--door-avatar-unlocked-color);
  }
  &:not([data-state]) {
    background-color: var(--door-avater-state-unknown);
  }

  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  width: ${size};
  height: ${size};
  padding: 4px;

  border: 5px solid var(--door-icon-border-color);
  box-shadow: 0px 2px 10px -2px rgba(29, 32, 35, 0.8);
  border-radius: 80px;
`;

const DoorStateAvatar = ({ door, size = 40, showcamera, ...otherProps }) => {
  if (!door) return null;
  const isACDoor = Boolean(door.door_token);

  //TODO add get event for port

  let filter = React.useCallback(
    (data) => {
      if (!door) return;
      if (
        data?.door_id == door.id &&
        ['doorState', 'alarm'].includes(data.category)
      )
        return true;
      return false;
    },
    [door]
  );
  const [state, setState] = React.useState();
  const [alarmState, setAlarmState] = React.useState();

  React.useEffect(() => {
    if (!door) return;
    setState(door.state);
    setAlarmState(door.alarm_state);
  }, [door]);

  const { doorState, changeTime, doorAlarm } = useDoorStateSocket(door, filter);

  React.useEffect(() => {
    if (!doorState) return;

    setState(doorState);
  }, [doorState]);

  React.useEffect(() => {
    if (!doorAlarm) return;

    setAlarmState(doorAlarm);
  }, [doorAlarm]);

  const [accessTimer, setAccessTimer] = React.useState(door.access_time);

  const timer = React.useRef();

  const cancelTimer = React.useCallback(() => {
    timer.current && clearInterval(timer.current);
    setAccessTimer(door.access_time);
  }, []);

  React.useEffect(() => {
    //bug here running twice
    if (doorState !== 'Accessed') return cancelTimer();
    timer.current = setInterval(() => {
      setAccessTimer((time) => (time > 1 ? time - 1 : (time = 0)));
    }, 1000);

    return () => {
      timer.current && clearInterval(timer.current);
      setAccessTimer(door.access_time);
    };
  }, [doorState]);

  React.useEffect(() => {
    if (timer == 0) timer.current && clearInterval(timer.current);
  }, [timer]);

  const Icon =
    door.online == '0' ? Power : DOOR_STATES?.[state]?.icon || Question;

  return (
    <article
      css={styles(size)}
      className="door-avatar"
      data-state={state}
      data-static={
        door.device_product == 'A1001' && door.port_direction == 'output'
      }
      data-online={door.online}
      data-alarm={alarmState && alarmState != 'normal' ? 'true' : 'false'}
    >
      {door.device_product == 'A1001' && door.port_direction == 'output' ? (
        <Question title="A1001 doesn't offer Auxiliaty updates" />
      ) : (
        (doorState !== 'Accessed' || accessTimer == 0) && <Icon />
      )}

      {alarmState && alarmState != 'normal' && (
        <InfoCircleFill
          css={css`
            fill: ${alarmState == 'forcedOpen'
              ? 'var(--red-base)'
              : 'var(--orange-base)'};
            height: 20px !important;
            width: 20px !important;
            left: -8px;
            top: -8px;
            position: absolute;
          `}
        />
      )}

      {doorState == 'Accessed' && accessTimer != 0 && (
        <span
          css={css`
            text-align: center;
            width: 100%;
            /* color: black;
            border: 1px solid black;
          
            right: -12px;
            top: -12px;
            position: absolute; */
          `}
        >
          {accessTimer}
        </span>
      )}
    </article>
  );
};

export default DoorStateAvatar;
