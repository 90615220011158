import { Image, AppIndicator, JournalText } from 'react-bootstrap-icons';
import { css } from '@emotion/react';
import { dateTime } from 'utils/time';
import EVENTS_CONSTANTS from 'constants/events';
import { dateTimeShort, dateTimeDif } from 'utils/time';
import GroupLabel from 'components/group-label';
import { AvatarCircle } from 'components/avatar';
import { getSelectionColumn } from 'components/table-selection-column';

/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import { IconButton } from 'components/buttons';
import { useHistory } from 'react-router-dom';
import AddDeniedCredential from './add-denied-credential';
import IsRecording from './is-recording';
import TimeFormatter from 'components/time-formatter';
import IsEventResotred from './is-event-restored';
import SnapshotModal from 'components/snapshot-modal';

const EventIcon = styled('span')`
  color: var(--neutral-00);
  width: 32px;
  height: 32px;
  flex: 0 0 32px;
  background: ${(props) =>
    props.background || 'var(--event-icon-background-blue)'};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    color: ${(props) => props.color || '#267DFF'};
    width: 16px;
    height: 16px;
  }
`;
export const columns = [
  {
    Header: 'Time',
    id: 'createdAt',
    accessor: 'createdAt.$date.$numberLong',
    pickerDisabled: true,
    Cell: ({
      cell: { value },
      row: {
        original: { utctime }
      }
    }) => (
      <>
        {value && <TimeFormatter dt={Number(value)} />}
        <time
          css={css`
            font-size: 0.75rem;
            color: var(--table-header-color);
            margin-top: 0.2rem;
            display: block;
            opacity: 80%;
          `}
        >
          {new Date(utctime).toLocaleString('en', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            fractionalSecondDigits: 3
          })}
        </time>
      </>
    )
  },
  {
    Header: 'Event',
    id: 'type',
    accessor: 'event',
    pickerDisabled: true,
    sortDisable: true,
    disableSortBy: true,
    Cell: ({ row: { original: event } }) => {
      return (
        <section
          title={EVENTS_CONSTANTS.getTitle(event)}
          className="info"
          css={css`
            display: flex;
            align-items: center;
            gap: 0.6rem;
            span.details {
              width: 200px;
              color: var(--table-header-color);
              font-weight: 500;
              font-size: 12px;
            }
          `}
        >
          <EventIcon
            color={EVENTS_CONSTANTS.colors[event.type]}
            background={EVENTS_CONSTANTS.backgroundColors[event.type]}
          >
            {typeof EVENTS_CONSTANTS.icons[event.type] == 'function' &&
              EVENTS_CONSTANTS.icons[event.type]()}
          </EventIcon>

          <div
            css={css`
              display: flex;
              flex-direction: column;
              gap: 0.3rem;
            `}
          >
            <strong>
              {event.reason
                ? EVENTS_CONSTANTS.reasons[event.reason]
                : EVENTS_CONSTANTS.readableNames[event?.type]}
            </strong>
            <span
              className="details"
              title={EVENTS_CONSTANTS.getEventDetails(event)}
            >
              {EVENTS_CONSTANTS.getEventDetails(event)}
            </span>
          </div>
          <IsEventResotred event={event} />
        </section>
      );
    }
  },

  {
    Header: 'Level',
    id: 'level',
    accessor: 'level',
    sortDisable: true,
    disableSortBy: true,
    Cell: ({ cell: { value } }) => {
      return (
        <div
          title={value}
          data-level={value}
          css={css`
            height: 12px;
            width: 12px;
            &[data-level='info'] {
              background: var(--neutral-05);
            }
            &[data-level='warning'] {
              background: var(--orange-base);
            }
            &[data-level='critical'] {
              background: var(--red-base);
            }

            border-radius: 50%;
            display: inline-block;
          `}
        ></div>
      );
    }
  },

  {
    Header: 'Site',
    accessor: 'site_name',
    id: 'site_name',
    sortDisable: true,
    disableSortBy: true
  },
  {
    Header: 'Device',
    accessor: 'device_name',
    id: 'device_name',
    sortDisable: true,
    disableSortBy: true
  },
  {
    Header: 'Door',
    accessor: 'door_name',
    id: 'door_name',
    sortDisable: true,
    disableSortBy: true
  },
  {
    Header: 'Person',
    accessor: 'p_name',
    id: 'p_name',
    sortDisable: true,
    disableSortBy: true,
    Cell: ({ row: { original: event } }) => {
      return (
        <div
          css={css`
            display: grid;
            column-gap: 0.5rem;
            .avatar {
              grid-row: 1 / span 2;
            }
            grid-template-columns: auto 1fr;
            span:last-of-type {
              grid-column: 2;
              color: var(--neutral-01);
              font-size: 11px;
            }
          `}
        >
          {event.p_name ? (
            <AvatarCircle name={event.p_name} url={event.p_avatar_url} />
          ) : null}
          <span>{event.p_name}</span>
          <span>{event.c_description}</span>
        </div>
      );
    }
  },

  {
    Header: 'Access Level',
    accessor: 'access_level_name',
    id: 'access_level_name',
    sortDisable: true,
    disableSortBy: true
  },

  {
    Header: 'Department',
    accessor: 'department_names',
    id: 'department_names',
    sortDisable: true,
    disableSortBy: true
  },

  {
    Header: 'Camera',
    accessor: 'camera_name',
    sortDisable: true,
    disableSortBy: true,
    Cell: ({ row: { original: event } }) => {
      return <strong>{event.camera_name}</strong>;
    }
  },

  {
    Header: 'Snapshot',
    accessor: 'snapshot_link',
    sortDisable: true,
    disableSortBy: true,

    Cell: ({ cell: { value } }) => {
      return (
        <section>
          {value && (
            <SnapshotModal url={value}>
              <IconButton title="Snapshot available...">
                <Image size={24} />
              </IconButton>
            </SnapshotModal>
          )}
        </section>
      );
    }
  },

  {
    Header: 'License Plate',
    accessor: 'plateText',
    sortDisable: true,
    disableSortBy: true,
    Cell: ({ row: { original: event } }) => {
      return event.plateText ? (
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 0.2rem;
          `}
        >
          <span>{event.plateText}</span>
          <span
            css={css`
              font-size: 12px;
            `}
          >
            ({event.plateConfidence})
          </span>
        </div>
      ) : null;
    }
  },

  {
    Header: 'Indicators',
    id: 'extra',
    sortDisable: true,
    disableSortBy: true,

    Cell: ({ row: { original } }) => {
      return (
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 0.5rem;
            svg {
              width: 24px;
              height: 24px;
            }
          `}
        >
          {original.notes && (
            <span title="This Events has notes">
              <JournalText />
            </span>
          )}
          <IsRecording event={original} />
          <AddDeniedCredential event={original} />
          {original.notifications && (
            <span title="notification was triggered">
              <AppIndicator />
            </span>
          )}
        </div>
      );
    }
  }
];
