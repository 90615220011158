import React from "react";
import AsyncSelect from "components/form/async-select";
import Site from "api/site";
import { withField } from "./with-field";
import { components } from "react-select";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useFormikContext } from "formik";

const SiteSelect = ({ componentProps, ...props }) => {
  const { setFieldValue } = useFormikContext();
  return (
    <AsyncSelect
      title="sites"
      fetchFn={Site.search.bind()}
      selectAll={true}
      onSelectAll={() => {
        setFieldValue("allSites", "on");
        setFieldValue("sites", null);
      }}
      onDeselectAll={() => setFieldValue("allSites", null)}
      componentProps={componentProps}
      {...props}
    />
  );
};

export default withField(SiteSelect);
