import { callAPI, dataFetchQuery } from 'utils/ajax';

const MonitoringView = {
  search(input = {}) {
    return callAPI('GET', '/monitoring_view/', input);
  },

  save(input = {}, id = '') {
    return callAPI(id ? 'PUT' : 'POST', `/monitoring_view/${id}`, input);
  },

  delete(id = '') {
    return callAPI('DELETE', `/monitoring_view/${id}`);
  }
};

export default MonitoringView;
