import React from 'react';
import Template from 'base/template';
import { Button, CancelButton } from 'components/buttons';
import FormProvider from 'providers/form-provider';

import { TextField, PasswordField, Toggle } from 'components/form';
import { useMutationWrapper } from 'utils/ajax';
import { Redirect } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useMutateReset } from 'views/devices/actions';
import Device from 'api/device';
import PageTitle from 'components/page-title';
import { css } from '@emotion/react';

/** @jsxImportSource @emotion/react */
import Content from 'base/content';
import ProductSelect, { DEVICES } from 'routes/controllers/product-select';
import { Field } from 'formik';
import O3CRegistration from 'components/03c-registration';
import Site from 'api/site';
import AsyncSelect from 'components/headless-select/async';
import GenericDoor from 'api/generic_door';
import Breadcrumbs from 'components/breadcrumbs';
import useAlertDialog from 'providers/alert-dialog-provider';
import Tooltip from 'components/tooltip';
import { IconAlertCircle } from '@tabler/icons-react';
import { PRODUCTS } from 'routes/controllers/product-select';
const ControllerSave = (props) => {
  let generalRef = React.useRef(1);

  // TODO fix this in the api

  let device = props?.location?.state?.device || null;
  let type =
    props?.location?.state?.type || props?.location?.state?.device.type;

  const SCHEMA = Yup.object().shape({
    site: Yup.string().required('Required'),
    name: Yup.string().required('Required').nullable(),
    // doors: Yup.string().required("Required").nullable(),
    product: !device ? Yup.string().required('Required').nullable() : '',
    username: Yup.string().required('Required').nullable(),
    ip: Yup.string().required('Required').nullable()
  });

  const INITIAL_VALUES = {
    //...device,
    type: device?.type,
    site: device?.site_id,
    generic_door_id: device?.generic_door_id,
    name: device?.name,
    product: device?.product,
    username: device?.username || 'root',
    password: device ? '' : 'pass',
    ip: device?.ip,
    device_metrics_enabled: device?.device_metrics_enabled == 1,
    lpr_enabled: device ? device?.lpr_enabled : 0
  };

  const [redirect, setRedirect] = React.useState(false);

  const {
    isSuccess: isSuccessSave,
    isLoading,
    mutate,
    data
  } = useMutationWrapper(
    ['device', 'save'],
    (input) => Device.save(input, device?.id),
    {}
  );

  const { mutate: mutateReset } = useMutateReset(device);

  const confirm = useAlertDialog();

  React.useEffect(() => {
    const confirmReset = async () => {
      let ok = await confirm({
        description: `Newlly added Devices require reboot, Would You like to reboot the new Device ?`
      });
      ok && mutateReset(data.message);
      setRedirect(true);
    };
    if (!generalRef.current) return;
    if (!isSuccessSave) return;
    if (!device) {
      confirmReset();
    } else if (device) setRedirect(true);

    return () => (generalRef.current = null);
  }, [isSuccessSave]);

  return (
    <Template {...props}>
      {redirect && <Redirect to="/devices" />}
      <Content {...props}>
        <PageTitle>{device ? device.name : 'Device Add'}</PageTitle>
        <Breadcrumbs crumbs={props.crumbs} />
        <Formik
          validationSchema={SCHEMA}
          initialValues={INITIAL_VALUES}
          onSubmit={(values) => {
            if (values.mac)
              values.mac = values.mac.replace(/[^a-z0-9]/gi, '').toUpperCase();
            if (values.oak)
              values.oak = values.oak.replace(/[^a-z0-9]/gi, '').toUpperCase();
            mutate(values);
          }}
        >
          {({ handleSubmit, setFieldValue, values }) => (
            <FormProvider mutateFunc={handleSubmit} disabled={isLoading}>
              <section>
                <h2
                  css={css`
                    color: var(--neutral-00);
                    margin-top: 0;
                    margin-bottom: 0.2rem;
                    padding: 0rem;
                    text-transform: capitalize;
                  `}
                >
                  {type}
                </h2>
                <br />
                {!device && (
                  <Field
                    device
                    type={type}
                    name="product"
                    component={ProductSelect}
                  />
                )}

                <TextField name="name" value={values.name} />

                <div
                  className="ip-address"
                  css={css`
                    position: relative;
                    > div:first-of-type {
                      position: absolute;
                      right: 0;
                      top: 0;
                      z-index: 1;
                    }
                  `}
                >
                  <Tooltip
                    content={
                      <div>
                        O3c IP Address format is: [DEVICE-MAC].o3c.internal
                      </div>
                    }
                  >
                    <div>
                      <IconAlertCircle size={20} color={'var(--neutral-02)'} />
                    </div>
                  </Tooltip>
                  <TextField label="IP Address" name="ip" value={values.ip} />
                </div>

                <TextField name="username" value={values.username} />

                <PasswordField
                  autocomplete="new-passsord"
                  name="password"
                  value={values.password}
                  placeholder="Type to change..."
                />

                <TextField label="O3C ID" value={values.o3c_id} />

                <Field
                  name="site"
                  component={AsyncSelect}
                  fetchFn={Site.search}
                />
                {!device && (
                  <Field
                    name="generic_door_id"
                    title="Door Association"
                    component={AsyncSelect}
                    fetchFn={GenericDoor.search}
                  />
                )}

                <O3CRegistration device={device} ip={values.ip} />
              </section>
              <section>
                {type == 'camera' && (
                  <article
                    css={css`
                      grid-column: 1/-1;

                      padding: 24px;

                      background: var(--light--others--purple-200);
                      border-radius: 4px;
                      input:checked + .slider {
                        background: var(--light--others--purple-500) !important;
                      }
                    `}
                  >
                    <Toggle
                      label="Enable Monitoring via ACAP"
                      checked={values.device_metrics_enabled}
                      value={values.device_metrics_enabled}
                      onChange={(e) =>
                        setFieldValue(
                          'device_metrics_enabled',
                          e.target.checked
                        )
                      }
                    />
                  </article>
                )}

                {type == 'camera' &&
                  DEVICES.find((p) => p.value == values?.product)?.lpr && (
                    <article
                      css={css`
                        grid-column: 1/-1;
                        padding: 24px;

                        background: var(--light--others--purple-200);
                        border-radius: 4px;
                        input:checked + .slider {
                          background: var(
                            --light--others--purple-500
                          ) !important;
                        }
                      `}
                    >
                      <Toggle
                        label="Enable LPR (License Plate Detection)"
                        checked={values.lpr_enabled}
                        value={values.lpr_enabled}
                        onChange={(e) =>
                          setFieldValue('lpr_enabled', e.target.checked)
                        }
                      />
                    </article>
                  )}
              </section>

              <footer>
                <Button type="submit">Submit</Button>
                <CancelButton onClick={() => setRedirect(true)} />
              </footer>
            </FormProvider>
          )}
        </Formik>
      </Content>
    </Template>
  );
};

export default ControllerSave;
