import { useMutationWrapper } from 'utils/ajax';
import User from 'api/user';
import { useQueryClient } from 'react-query';
import { useHeadlessTable } from 'providers/headless-table-provider';

export const useDeleteMutate = (user) => {
  const queryClient = useQueryClient();
  const { queryKey } = useHeadlessTable();
  return useMutationWrapper(
    ['user', 'delete'],
    (id) => User.delete(id),

    {
      successMessage: `User: ${user?.name} deleted successfuly`,
      onMutate: (id) => {
        let data = queryClient.getQueryData(queryKey);

        const newData = {
          ...data,
          data: data.data.filter((row) => row.id != id)
        };

        queryClient.setQueryData(queryKey, newData);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['users']);
      }
    }
  );
};

export const useSendActivationMutate = () => {
  return useMutationWrapper(['user', 'send-activation-email'], (id) =>
    User.sendActivationEmail(id)
  );
};

export const useActivateMutate = (user) => {
  const queryClient = useQueryClient();
  const { queryKey } = useHeadlessTable();

  return useMutationWrapper(['user', 'activate'], (id) => User.activate(id), {
    successMessage: `"${user?.name}" was Activated!`,

    onMutate: (id) => {
      let data = queryClient.getQueryData(queryKey);
      const newData = {
        ...data,
        data: data.data.map((i) => {
          if (i.id == id) return { ...i, active: '1' };
          else return i;
        })
      };

      queryClient.setQueryData(queryKey, newData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['users', 'stats']);
    }
  });
};

export const useEndSessionMutate = () => {
  const queryClient = useQueryClient();

  return useMutationWrapper(
    ['user', 'end-session'],
    (id) => User.endSession(id),
    {
      onSuccess: (id) => {
        queryClient.invalidateQueries(['manage-users']);
      }
    }
  );
};
