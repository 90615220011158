import React from 'react';
import { useHistory } from 'react-router-dom';
import { useMutateRelease, useMutateDelete, useMutateLock } from './actions';
import { Menu } from '@headlessui/react';
import { useAuth } from 'providers/auth';

import {
  MenuButton,
  MenuItem,
  MenuItemDelete,
  MenuSeperator,
  MenuWrapper
} from 'components/action-menu';
import IsRole from 'components/is-role';
import useAlertDialog from 'providers/alert-dialog-provider';

export default function ActionMenu({ data: site, ...props }) {
  const history = useHistory();
  const confirm = useAlertDialog();
  const mutateDelete = useMutateDelete(site);
  const mutateRelease = useMutateRelease(site);
  const mutateLock = useMutateLock(site);

  const { user } = useAuth();

  let handleEdit = (e) =>
    history.push('/sites/save/' + site?.id, {
      site,
      desc: site?.name
    });

  const handleDelete = async () => {
    if (
      await confirm({
        description: `Are you sure you want to delete site: ${site.name}`
      })
    )
      mutateDelete.mutate(site?.id);
  };

  let handleMapping = (e) =>
    history.push('/overview', {
      site: site.id,
      desc: site?.name
    });
  const handleLock = () => mutateLock.mutate(site?.id);
  const handleRelease = () => mutateRelease.mutate(site?.id);

  return (
    <MenuWrapper {...props}>
      {(user.features?.overview_enabled == 'on' ||
        user.features?.overview_enabled == true) && (
        <>
          <MenuItem label="Mapping" onClick={handleMapping} />
          <MenuSeperator />
        </>
      )}

      <IsRole role={['I', 'A', 'V']}>
        <MenuItem label="Edit" onClick={handleEdit} />
      </IsRole>
      <MenuItem label="Lock Down" onClick={handleLock} />
      <MenuItem label="Release" onClick={handleRelease} />
      <IsRole role={['I', 'A', 'V']}>
        <MenuItemDelete
          onClick={handleDelete}
          disabled={site?.device_count != 0}
        />
      </IsRole>
    </MenuWrapper>
  );
}
