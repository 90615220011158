import React from 'react';
/** @jsxImportSource @emotion/react */
import incomingCallAudio from 'sounds/call-started.mp3';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import {
  CameraVideo,
  TelephoneFill,
  TelephoneMinusFill,
  XLg
} from 'react-bootstrap-icons';
import * as Popover from '@radix-ui/react-popover';
import { PopoverContent } from 'components/popover';
import { CheckBox, Toggle } from './form';
import { useEventSource } from 'react-use-websocket';
import { useLayout } from 'providers/layout-provider';
import { useAuth } from 'providers/auth';
import useSound from 'use-sound';
import { VolumeOff, VolumeUp } from 'react-bootstrap-icons';
import LivePlayer from './video/live-player';
import { useQueryWrapper } from 'utils/ajax';
import Camera from 'api/camera';
import TransmitAudio from '../views/intercoms/transmit-audio';
import { useLocalStorage } from '../hooks/local-storage';
import * as Portal from '@radix-ui/react-portal';
import Event from 'api/event';
import EventCard from './event-card';
import { dateTime } from 'utils/time';
const Call = ({ event, setCallEvent, callEvent, setIsPortalOpen }) => {
  let { data } = useQueryWrapper(
    ['camera', 'get', event?.camera_id],
    () => Camera.search({ id: event?.camera_id }),
    { enabled: Boolean(event?.camera_id) }
  );

  const [callState, setCallState] = React.useState();

  const handleAnswer = () => {
    const index = callEvent.findIndex(
      (e) => e.intercom_id == event.intercom_id
    );
    if (index > -1) {
      let arr = [...callEvent];
      arr[index] = { ...event, callStatus: 'answered' };
      setCallEvent(arr);
    }

    setCallState('answered');
  };

  const handleView = () => {
    setCallState('view');
  };

  const handleIgnore = () => {
    const arr = callEvent.filter((e) => e.intercom_id != event.intercom_id);
    arr.length == 0 && setIsPortalOpen(false);
    setCallEvent(arr);
  };

  return (
    <article>
      <div
        css={css`
          padding: 1rem;
          color: var(--secondary-font-color);
          display: flex;
          span.call-status {
            margin-left: auto;
          }
        `}
      >
        <div>
          <span>{event.camera_name}</span>
          <span
            css={css`
              display: block;
              font-size: 11px;
            `}
          >
            {dateTime(event.utctime)}
          </span>
        </div>
        {event.callStatus && (
          <span className="call-status">{event.callStatus}...</span>
        )}
      </div>
      {event.callStatus == 'picked' && <div>Picked By.. {event.user_name}</div>}
      {callState == 'answered' && data?.data?.[0] && (
        <div
          className="feed-area"
          css={css`
            height: 200px;
          `}
        >
          <LivePlayer
            host={data?.data?.[0]}
            autoPlay={true}
            webSocketURLParameters={`?intercom=${event.intercom_id}`}
          />
        </div>
      )}

      {callState == 'view' && data?.data?.[0] && (
        <div
          className="feed-area"
          css={css`
            height: 200px;
          `}
        >
          <LivePlayer host={data?.data?.[0]} autoPlay={true} />
        </div>
      )}

      <div
        css={css`
          display: flex;
          justify-content: space-between;
          padding: 1rem;
          background: var(--neutral-07);
          > button {
            display: flex;
            align-items: center;
            gap: 0.75rem;
            width: 100%;
            justify-content: center;
          }
          > * + * {
            border-right-width: 0px;
            border-left-width: 3px;
          }
        `}
      >
        {callState != 'answered' &&
          event.callStatus != 'picked' &&
          callState != 'view' && (
            <button
              onClick={
                event.callStatus == 'waiting' ? handleAnswer : handleView
              }
              css={css`
                color: var(--primary-base);
              `}
            >
              {event.callStatus == 'waiting' ? (
                <TelephoneFill size={20} />
              ) : (
                <CameraVideo size={20} />
              )}
              {event.callStatus == 'waiting' ? 'Answer' : 'View'}
            </button>
          )}

        {(event.callStatus == 'waiting' || callState == 'answered') && (
          <button
            onClick={handleIgnore}
            css={css`
              color: var(--red-base);
            `}
          >
            <TelephoneFill
              size={20}
              css={css`
                transform: rotate(135deg);
              `}
            />
            Hang up
          </button>
        )}
        {event.callStatus != 'waiting' && callState != 'answered' && (
          <button
            onClick={handleIgnore}
            css={css`
              color: var(--red-base);
            `}
          >
            <XLg size={20} />
            {event.callStatus == 'ended' || callState == 'answered'
              ? 'Close'
              : 'Ignore'}
          </button>
        )}
      </div>
    </article>
  );
};

export default () => {
  const {
    getSocketUrl,
    callEvent,
    setCallEvent,
    isPortalOpen,
    setIsPortalOpen,
    dnd,
    sound
  } = useLayout();
  const { user } = useAuth();

  const { lastEvent } = useEventSource(
    getSocketUrl,
    {
      share: true,
      filter: (e) => {
        let data = JSON.parse(e.data);
        return [
          'callStarted',
          'callEnded',
          'callMissed',
          'callAnswered'
        ].includes(data.type);
      },
      shouldReconnect: (e) => true,
      withCredentials: true,
      retryOnError: true
    },

    Boolean(user && !user.missing2fa && !user.missingMfa)
  );

  const handleCallEvent = React.useCallback(
    (event, action) => {
      let arr = [];

      if (action == 'update') {
        let item = callEvent.findIndex(
          (e) => e.intercom_id == event.intercom_id
        );

        if (item > -1) {
          arr = [...callEvent];

          arr[item] = event;
        } else {
          arr.push(event);
        }

        setCallEvent(arr);
      }
    },
    [callEvent]
  );

  const [play, { stop }] = useSound(incomingCallAudio);

  React.useEffect(
    () => callEvent.length == 0 && stop(),
    [sound, callEvent.length]
  );

  React.useEffect(() => {
    if (!lastEvent) return;
    if (dnd) return;

    let data = JSON.parse(lastEvent.data);

    if (data.type == 'callStarted') {
      handleCallEvent({ ...data, callStatus: 'waiting' }, 'update');
      setIsPortalOpen(true);
      sound && play();
    } else if (data.type == 'callMissed') {
      handleCallEvent({ ...data, callStatus: 'ended' }, 'update');
      stop();
    } else if (data.type == 'callEnded') {
      handleCallEvent({ ...data, callStatus: 'ended' }, 'update');
      stop();
    } else if (data.type == 'callAnswered' && data.user_id !== user.user_id) {
      stop();
      handleCallEvent({ ...data, callStatus: 'picked' }, 'update');
    } else if (data.type == 'callAnswered' && data.user_id == user.user_id) {
      stop();
    }
  }, [lastEvent]);

  if (!user?.company_id || !user?.features?.intercoms) return null;

  return (
    <>
      <>
        {isPortalOpen && (
          <Portal.Root
            className="call-dialog"
            css={css`
              position: relative;
              box-shadow: var(--popover-box-shadow);
              width: 360px;
              background: var(--neutral-09);
              border: 1px solid var(--neutral-12);
              box-sizing: border-box;
              border-radius: 5px;
              font-size: 14px;
              z-index: 1500 !important;
              position: fixed;
              top: 80 !important;
              right: 10 !important;
              left: unset !important;
              transition: opacity 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
            `}
          >
            {callEvent.map((event) => (
              <Call
                event={event}
                callEvent={callEvent}
                setCallEvent={setCallEvent}
                handleCallEvent={handleCallEvent}
                setIsPortalOpen={setIsPortalOpen}
              />
            ))}
          </Portal.Root>
        )}
      </>
    </>
  );
};
