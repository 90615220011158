import IOPort from 'api/ioport';
import React from 'react';
import { Formik, useFormikContext, Field } from 'formik';
import FormProvider from 'providers/form-provider';
import { useMutationWrapper, useQueryWrapper } from 'utils/ajax';
import { TextField, NumberField } from 'components/form';
import { Button, AuxiliaryButton } from 'components/buttons';
import Spinner from 'components/spinner';
import { css } from '@emotion/react';
import { Check2Circle } from 'react-bootstrap-icons';
/** @jsxImportSource @emotion/react **/
import {
  useOutputMutate,
  useTogglePortMutate
} from 'views/auxiliaries/actions';
import DirectionSelect from 'components/form/direction-select';

const itemStyles = css`
  cursor: pointer;
  transition: background ease 300ms, opacity ease 300ms, margin-top ease 100ms;

  &:hover {
    background: #f0f0f0;
  }
  display: grid;
  grid-template-columns: 0.5fr 1fr 2fr 1fr 2fr;
  align-items: center;

  justify-content: start;
  padding: 0.7rem 1.5rem;
  gap: 2rem;
  > * {
    padding: 0px;
  }

  background: var(--neutral-09);
  /* BorderBottom */

  box-shadow: inset 0px -1px 0px var(--neutral-12);
  > button:first-of-type {
    margin-left: auto;
  }

  p {
    font-size: 24px;
    color: #6428e4;
  }
`;

const IoPortItem = ({ port }) => {
  const { values } = useFormikContext();

  const outputMutate = useOutputMutate();
  const toggleMutate = useTogglePortMutate();

  return (
    <li css={itemStyles}>
      <p>IO{Number(port.port_number) + 1}</p>
      <TextField
        label="Name"
        name={`${port.IOPort}.name`}
        value={values?.[port.IOPort]?.name}
        data-compact
      />

      <Field
        isDisabled={port.relay}
        name={`${port.IOPort}.data`}
        port={port.IOPort}
        component={DirectionSelect}
      />
      <NumberField
        data-compact
        half
        label="OTL Time"
        name={`${port.IOPort}.otl_time`}
        value={values?.[port.IOPort]?.otl_time}
      />

      {port.Direction == 'output' && (
        <div
          css={css`
            display: flex;
            gap: 1rem;
            align-items: center;
            margin-left: auto;
          `}
        >
          <AuxiliaryButton
            compact
            data-loading={outputMutate.isLoading}
            disabled={outputMutate.isLoading || toggleMutate.isLoading}
            onClick={() => outputMutate.mutate(port.id)}
          >
            Test
          </AuxiliaryButton>
          <AuxiliaryButton
            compact
            disabled={outputMutate.isLoading || toggleMutate.isLoading}
            onClick={() => toggleMutate.mutate({ portId: port.id })}
          >
            Toggle
          </AuxiliaryButton>
        </div>
      )}
    </li>
  );
};

const containerStyles = css`
  form {
    all: unset;
    width: 100%;
    fieldset {
      all: unset;
      width: 100%;
      position: relative;
    }
  }
  ul {
    width: 100%;
  }
  footer {
    padding: 24px;
    > button {
      height: 48px;
    }
  }
  .spinner {
    display: block;
    margin: 24px auto;
  }
`;

function IoPorts({ deviceId }) {
  const { data, isLoading } = useQueryWrapper(
    ['controller', 'getIoPorts', deviceId],
    () => IOPort.search({ device_id: deviceId })
  );

  const { mutate, isLoading: mutateLoading } = useMutationWrapper(
    ['ioport', 'save', deviceId],
    (input) => IOPort.save(input, deviceId)
  );

  const [initialValues, setInitialValues] = React.useState({});

  React.useEffect(() => {
    if (!data) return;

    let obj = {};

    data.data.forEach((i) => {
      obj[i.IOPort] = {
        data: i.Direction + '_' + i.Trig,
        name: i.Name,
        otl_time: i.otl_time
      };
    }),
      setInitialValues(obj);
  }, [data]);

  return (
    <article className="io-ports" css={containerStyles}>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={(values) => {
          mutate(values);
        }}
      >
        {({ handleSubmit }) => (
          <FormProvider mutateFunc={handleSubmit} disabled={mutateLoading}>
            {data && (
              <>
                <ul className="ioports-container">
                  {data?.data?.map((p, idx) => (
                    <IoPortItem port={p} key={idx} deviceId={deviceId} />
                  ))}
                </ul>
                <footer>
                  <Button compact type="submit">
                    <Check2Circle />
                    Submit
                  </Button>
                </footer>
              </>
            )}
            {isLoading && <Spinner />}
          </FormProvider>
        )}
      </Formik>
    </article>
  );
}

export default IoPorts;
