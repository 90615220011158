import React from 'react';
import { css } from '@emotion/react';
import { Calendar2 } from 'react-bootstrap-icons';
import { DateTimeField } from 'components/form';
/** @jsxImportSource @emotion/react */

const ExampleCustomTimeInput = React.forwardRef(
  ({ value, onClick, onChange }, ref) => (
    <input
      css={css`
        width: 100%;
        background: transparent;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        color: inherit;
        cursor: text;

        margin: 0;
        display: inline-block;
        font-size: inherit;
        font-family: inherit;
        font-weight: 300;
        line-height: inherit;
        height: auto;
        border: 0;
        border-radius: 0;
        vertical-align: initial;
      `}
      required
      type="time"
      value={value}
      onChange={(e) => {
        e.target.value && onChange(e.target.value);
      }}
    />
  )
);
const ExampleCustomInput = React.forwardRef(
  ({ value, onClick, currentTime }, ref) => (
    <button
      css={css`
        width: fit-content;
        display: flex;
        justify-content: center;

        align-items: center;
        height: 100%;
        gap: 1.5rem;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        > div {
          width: 100px;
        }
      `}
      className="example-custom-input"
      onClick={onClick}
      ref={ref}
    >
      <Calendar2 size={18} color="var(--primary-base)" />
      <div>
        <time
          css={css`
            display: flex;
            width: max-content;
            font-weight: 600;
            font-size: 14px;
            line-height: 100%;
          `}
        >
          {new Date(currentTime).toLocaleTimeString()}
        </time>
        <time
          css={css`
            display: flex;
            width: max-content;
            font-weight: 600;
            font-size: 12px;
            opacity: 60%;
            line-height: 100%;
          `}
        >
          {new Date(currentTime).toLocaleDateString()}
        </time>
      </div>
    </button>
  )
);

export default ({
  dtDates,
  daysWithRecording,
  currentTime,
  handleSelectTime
}) => {
  return (
    <DateTimeField
      raw={true}
      highlightDates={Object.keys(daysWithRecording).map(
        (day) => new Date(day)
      )}
      customTimeInput={<ExampleCustomTimeInput />}
      selected={new Date(currentTime)}
      customInput={<ExampleCustomInput currentTime={currentTime} />}
      minDate={dtDates.min}
      maxDate={dtDates.max}
      onChange={(date) => {
        handleSelectTime(date.getTime());
      }}
    />
  );
};
