import React from 'react';
import styled from '@emotion/styled';
import { Container } from './container';
import ContainerHeader from './container-header';
import GridControls from './grid-controls';
import EmptyHolder from './empty-holder';
import AddWidgetDialog from './add-widget-dialog';
import { IconCubePlus, IconVideoPlus } from '@tabler/icons-react';
import doorWidget from './door-widget';
import clockWidget from './clock-widget';
import TimeSeriesGauge from 'views/sensors/time-series-gauge';
import currentTopics from 'views/sensors/current-topics';
import sensorWidget from './sensor-widget';
const TopBar = styled('aside')`
  display: flex;
  gap: 0.5rem;
  width: 100%;
  justify-content: center;
  flex: 1;

  overflow-x: auto;
  overflow-y: hidden;
  max-height: 300px;
  border: ${({ isEdit }) =>
    isEdit ? '4px dashed var(--secundary-base)' : 'none'};
  > * {
    flex: 1;
  }
`;

export const COMPONENTS = {
  door: doorWidget,
  clock: clockWidget,
  sensorGauge: sensorWidget
};
export default ({ selectedView, isEdit, setSelectedView }) => {
  let topBar = selectedView.layout.find((item) => item.type == 'bar');
  let index = selectedView.layout.findIndex((item) => item.type == 'bar');
  if (!topBar) return null;
  else
    return (
      <Container bar isEdit={isEdit}>
        <ContainerHeader
          selectedView={selectedView}
          isEdit={isEdit}
          index={index}
          setSelectedView={setSelectedView}
        ></ContainerHeader>
        <TopBar>
          {topBar?.items?.map((item, idx) => {
            let Component = COMPONENTS[item.type];
            if (!Component) return null;
            return (
              <Component
                slim
                isEdit={isEdit}
                doorId={item.id}
                sensor={item}
                setSelectedView={setSelectedView}
                selectedView={selectedView}
                index={index}
                widgetIndex={idx}
              />
            );
          })}
          {isEdit && (!topBar.items || topBar?.items?.length) < 5 && (
            <AddWidgetDialog
              selectedView={selectedView}
              setSelectedView={setSelectedView}
              index={index}
            >
              <EmptyHolder>
                <IconCubePlus />
                <span>Add widget</span>
              </EmptyHolder>
            </AddWidgetDialog>
          )}
        </TopBar>
      </Container>
    );
};
