import React from 'react';
import styled from '@emotion/styled';
import { useQueryWrapper } from 'utils/ajax';
import { useHeadlessTable } from 'providers/headless-table-provider';
/** @jsxImportSource @emotion/react */
import { InfoCircle, ArrowRepeat, Info } from 'react-bootstrap-icons';
import ColumnPicker from './column-picker';
import { useQueryClient } from 'react-query';
import { AuxiliaryButton, IconButton } from './buttons';
import { css } from '@emotion/react';
import { withActionButton } from 'components/with-action-button';
import TableFilter from './headless-table-filter';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
const Article = styled('article')`
  display: flex;
  // background: var(--neutral-09);
  box-sizing: border-box;
  border-radius: 6px 6px 0 0;
  position: relative;
  min-height: 72px;
  align-items: center;

  ul.table-stats {
    overflow-y: hidden;
    overflow-x: auto;
    input[type='checkbox'] {
      display: none;
    }
    input[type='checkbox']:not(:checked) ~ span {
      filter: grayscale(1);
      opacity: 0.5;
    }

    input[data-total='true'] ~ span {
      filter: unset !important;
      opacity: 1 !important;
    }
    display: flex;

    width: 100%;
    gap: 16px;
    > li {
    }
  }

  .table-setting {
    display: flex;
    margin-left: auto;
    align-items: center;
    gap: 16px;
  }
`;

const buttonStyles = (props) => css`
  // background: ${props.background};
  &:hover:enabled {
    filter: brightness(90%);
  }
  &:disabled {
    cursor: auto;
  }
  background: var(--neutral-09);
  border-radius: 5px;
  box-shadow: 0px 5px 48px -5px rgba(0, 0, 0, 0.05);
  display: grid;
  grid-template-columns: auto 1fr;
  margin-bottom: 10px;

  text-align: center;
  border-bottom: 4px solid;
  border-color: ${props.color ? props.color : 'var(--secundary-base)'};
  column-gap: 1.2rem;
  align-items: center;
  padding: 0.5rem 0.8rem;

  span.value {
    width: 100%;
    font-size: 22px;
    font-weight: 700;
    //color: ${props.background ? props.color : 'var(--neutral-00)'};
    color: var(--neutral-00);
    /* &:hover {
      color: var(--secundary-base);
    } */
    grid-row: 2;
  }
  span.name {
    grid-row: 1;
    grid-column: 2;
    white-space: nowrap;
    font-size: 12px;
    text-transform: capitalize;
    color: var(--secondary-font-color);
  }
  > div {
    height: 32px;
    width: 32px;
    display: flex;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    background: ${props.background
      ? props.background
      : 'var(--secundary-v-02)'};
    padding: 0.4rem;
    grid-row: 1 / span 2;
  }
`;

const TableStats = (props) => {
  const { id, setGlobalFilter, dispatch } = useHeadlessTable();
  const {
    statsQuery: query,
    cards,
    initialData,
    initialFilter,
    queryParams = {}
  } = props;

  if (typeof query !== 'function' && !initialData) return null;

  const { data, isLoading } = useQueryWrapper(
    [id, 'stats', initialData],
    ({ signal }) => initialData || query({ ...initialFilter }, { signal }),
    { ...queryParams }
  );

  const compatData = data?.data ? data.data : data;

  React.useEffect(() => {
    if (data?.data || data)
      dispatch({ type: 'TABLE_STATS', payload: data?.data ? data.data : data });
  }, [data]);

  const handleFilter = (card) => {
    if (!card.filter) return;
    setGlobalFilter?.((prev) => ({ ...prev, ...card.filter }));
  };

  return (
    <Article>
      <ul className="table-stats">
        {isLoading && (
          <li
            css={css`
              display: grid;
              grid-template-columns: 1fr 2fr;
              align-items: center;
              gap: 10px;
              width: 112px;
              height: 60px;
              background: var(--skeleton-loading-background-color);
              padding: 1rem;
              margin-bottom: 10px;
            `}
          >
            <Skeleton baseColor="var(--skeleton-loading-main-color)" />
            <Skeleton baseColor="var(--skeleton-loading-main-color)" />
          </li>
        )}
        {compatData &&
          cards?.map((card, idx) => {
            let Icon = card.icon || Info;
            if (
              card.name in compatData &&
              (card.alwaysShow || compatData[card.name] != 0)
            )
              if (
                (typeof card.visible == 'function' &&
                  card.visible(compatData)) ||
                !card.visible
              )
                return (
                  <li key={idx}>
                    <button
                      disabled={!card.filter ? true : false}
                      css={buttonStyles(card)}
                      onClick={handleFilter.bind(null, card)}
                    >
                      <div>
                        <Icon
                          size={24}
                          color={card.color ?? 'var(--secundary-base)'}
                        />
                      </div>
                      <span className="name">{card.label}</span>
                      <span className="value">{compatData[card.name]}</span>
                    </button>
                  </li>
                );
          })}
      </ul>
    </Article>
  );
};

export default TableStats;
