import React from 'react';
import { useHistory } from 'react-router-dom';
import { MenuItem, MenuItemDelete, MenuWrapper } from 'components/action-menu';
import { useDeleteMutate, useBarMutate } from './actions';
import IsUserAllowed from 'components/is-user-allowed';
import { useAuth } from 'providers/auth';
import { useHeadlessTable } from 'providers/headless-table-provider';
import useAlertDialog from 'providers/alert-dialog-provider';
import { IconShieldCheckeredFilled, IconUsersGroup } from '@tabler/icons-react';
export default function ActionMenu({ noEdit, data: person, ...otherProps }) {
  const history = useHistory();
  const barMutate = useBarMutate(person);
  const deleteMutate = useDeleteMutate(person);
  const { selectedFlatRows, state } = useHeadlessTable();

  const confirm = useAlertDialog();

  let handleEdit = () =>
    history.push('/people/save/' + person?.id, {
      person: person,
      desc: person?.name
    });

  const handlePrintBadge = () =>
    history.push('/people/badge/' + person?.id, {
      person: person,
      desc: person?.name
    });

  const handleAddCredential = () =>
    history.push('/credentials/add/', {
      credentialData: { person_id: person.id }
    });

  const handleBar = () => barMutate.mutate(person?.id, {});

  const handleDelete = async () => {
    // let msg = "";
    // if (person.sites == "false")
    //   msg = "This Person is accesible from all sites, ";
    // confirm(`${msg}Are you sure you want to delete ${person?.name}`) &&
    //   deleteMutate.mutate([person?.id], {});

    let ok = await confirm({
      description: `Are you sure you want to delete ${
        Object.keys(state.selectedRowIds).length
      } Persons(s)?`,
      info: `Associated credentials will be disabled.`
    });

    ok && deleteMutate.mutate(Object.keys(state.selectedRowIds));
  };
  const handleDetails = () =>
    history.push('people/details/' + person?.id, {
      person,
      desc: person?.name
    });
  const handleDepartments = () =>
    history.push(`/people/${person?.id}/manage-departments`, {
      person,
      desc: person?.name
    });
  const { user, hasAllPermissions } = useAuth();

  return (
    <MenuWrapper {...otherProps} disabled={selectedFlatRows?.length == 0}>
      <MenuItem
        disabled={selectedFlatRows?.length != 1}
        label="Bar / Release"
        onClick={handleBar}
      />
      {hasAllPermissions('personView', 'personEdit') && (
        <MenuItem
          disabled={selectedFlatRows?.length != 1}
          label="Add Credential..."
          onClick={handleAddCredential}
        />
      )}
      {!noEdit && (
        <MenuItem
          disabled={selectedFlatRows?.length != 1}
          label="Details"
          onClick={handleDetails}
        />
      )}
      <IsUserAllowed condition="personEdit" restrict="C">
        {(user.features?.badging == 'on' || user.features?.badging) && (
          <MenuItem
            disabled={selectedFlatRows?.length != 1}
            label="Print Badge"
            onClick={handlePrintBadge}
          />
        )}
        <MenuItem
          disabled={selectedFlatRows?.length != 1}
          label="Edit"
          onClick={handleEdit}
        />

        <MenuItem
          disabled={selectedFlatRows.length != 1}
          onClick={handleDepartments}
        >
          <IconUsersGroup />
          Departments...
        </MenuItem>
        <MenuItemDelete onClick={handleDelete} />
      </IsUserAllowed>
    </MenuWrapper>
  );
}
