/** @jsxImportSource @emotion/react */
import React, {  } from 'react';
import { css } from '@emotion/react';
// import SiteItem from './site-item';
// import { components } from 'react-select';
import { Check2Circle, SlashCircle, XLg } from 'react-bootstrap-icons';
import AsyncSelect from 'components/headless-select/async';
import { AuxiliaryButton, Button, CancelButton } from 'components/buttons';
import { DialogContent, Dialog } from 'components/dialog.js';
import { useQueryWrapper } from 'utils/ajax';
import Schedule from 'api/schedule';
import { useSaveDoorsMutate } from './actions';
import * as ScrollArea from '@radix-ui/react-scroll-area';
import SitePopover from './site-popover';
import IsRole from 'components/is-role';
import AccessPoint from 'api/access_point';
import { components } from 'react-select';
import SmallBadge from 'components/small-badge';
import { IconRefresh } from '@tabler/icons-react';
import { useQueryClient } from 'react-query';
import useAlertDialog from 'providers/alert-dialog-provider';
export const ScheduleNameOption = ({ ...props }) => {
  return (
    <components.Option {...props}>
      <label
        css={css`
          color: ${props.data.token == 'standard_always'
            ? 'var(--primary-base)'
            : props.data.token == '0'
            ? 'var(--red-base)'
            : 'black'};
        `}
      >
        {props.data.Name}
      </label>
    </components.Option>
  );
};

export const ScheduleSingleValue = ({ ...props }) => {
  return (
    <components.SingleValue {...props}>
      <label
        css={css`
          color: ${props.data.token == 'standard_always'
            ? 'var(--primary-base)'
            : props.data.token == '0'
            ? 'var(--red-base)'
            : 'black'};
        `}
      >
        {props.data.Name}
      </label>
    </components.SingleValue>
  );
};

const ReaderItem = ({
  schedules,
  setSchedules,
  reader: r,
  setChangedSchedules,
  setChangedSites
}) => {
  const handleSetAll = React.useCallback(
    () => {
      let tempSchedulesObj = { ...schedules };

      let scToken = schedules[r.door_token].token;

      let tempChangedSchedulesObj = {};

      for (const [key] of Object.entries(tempSchedulesObj)) {
        if (tempSchedulesObj[key]['site_id'] == r.site_id)
          tempSchedulesObj[key] = {
            ...schedules[r.door_token]
          };
      }

      for (const [key] of Object.entries(tempSchedulesObj))
        if (tempSchedulesObj[key]['site_id'] == r.site_id) {
          //only current site

          tempChangedSchedulesObj[key] = scToken;
        }

      setSchedules(tempSchedulesObj);

      setChangedSchedules((prev) => ({
        ...prev,
        ...tempChangedSchedulesObj
      }));

      setChangedSites((prevState) => new Set(prevState).add(r.site_id));
    },
    [r, schedules]
  );

  return (
    <article
      data-disabled={r.allow_al_update == '0'}
      className="reader"
      css={css`
        &[data-disabled='true'] {
          opacity: 80%;
          pointer-events: none;
        }
        display: grid;
        grid-template-columns: 50% 50%;
        padding: 0.4rem;
        .select__control {
          border: none;
          background: unset;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-items: center;
        `}
      >
        <b>{r.door_name} </b>
        <span
          css={css`
            color: var(--secondary-font-color);
            font-size: 12px;
          `}
        >
          {r.reader_direction == 'in' ? 'Entrance' : 'Exit'} Reader
        </span>

        <IsRole restrict="C">
          <span
            css={css`
              font-size: 12px;
              color: var(--neutral-02);
            `}
          >
            {r.device_name}
          </span>
        </IsRole>
        {r.allow_al_update == '0' && (
          <div>
            <SmallBadge>In setup - can not update</SmallBadge>
          </div>
        )}
      </div>

      <div
        css={css`
          display: ${r.allow_al_update == '0' ? 'none' : 'flex'};
          align-items: center;
          gap: 0.5rem;
          button:first-of-type {
            flex: 1 1 200px;
          }
        `}
      >
        <AsyncSelect
          css={css`
            width: 300px;
          `}
          queryParams={{ refetchOnMount: true, staleTime: 60000 }}
          menuShouldScrollIntoView={false}
          menuShouldBlockScroll={true}
          noValueContainer
          styles={{
            menu: (base) => ({ ...base, pointerEvents: 'all' }),
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            option: (styles, { isFocused, isSelected }) => {
              return {
                ...styles,
                background: isFocused
                  ? 'var(--menu-hover-background-color)'
                  : isSelected
                  ? 'var(--primary-v-02)'
                  : 'auto'
              };
            }
          }}
          menu
          isClearable={false}
          checkboxes={false}
          name="schedules"
          menuPortalTarget={document.body}
          menuPosition="fixed"
          menuPlacement="bottom"
          data-compact
          noAsyncRefresh
          query
          itemComponents={{
            Option: ScheduleNameOption,
            SingleValue: ScheduleSingleValue
          }}
          fetchFn={Schedule.search}
          label="Name"
          idx="token"
          value={schedules[r.door_token]}
          closeMenuOnSelect={true}
          onChange={(e) => {
            let tempSchedulesObj = { ...schedules };

            tempSchedulesObj[r.door_token] = {
              Name: e.Name,
              token: e.token,
              site_id: r.site_id
            };
            setSchedules(tempSchedulesObj);
            setChangedSchedules((prev) => ({
              ...prev,
              [r.door_token]: e.token || 0
            }));

            setChangedSites((prevState) => new Set(prevState).add(r.site_id));
          }}
        />
        <AuxiliaryButton
          css={css`
            margin-left: auto;
          `}
          compact
          onClick={handleSetAll}
        >
          Set Entire Site
        </AuxiliaryButton>
        <AuxiliaryButton
          css={css`
            flex: 0;
            svg {
              width: 18px;
              height: 18px;
            }
          `}
          title="No Access"
          compact
          onClick={() => {
            let tempSchedulesObj = { ...schedules };
            tempSchedulesObj[r.door_token] = {
              Name: 'No Access',
              token: '0',
              site_id: r.site_id
            };
            setSchedules(tempSchedulesObj);
            setChangedSchedules((prev) => ({
              ...prev,
              [r.door_token]: '0'
            }));

            setChangedSites((prevState) => new Set(prevState).add(r.site_id));
          }}
        >
          <SlashCircle />
        </AuxiliaryButton>
      </div>
    </article>
  );
};
export default function AddSite({
  accessLevel,
  dialogOpenProp = false,
  selectedSiteIdProp,
  setDialogOpenProp
}) {
  const queryClient = useQueryClient();
  const [selectedSite, setSelectedSite] = React.useState(false);
  const [schedules, setSchedules] = React.useState();
  const [changedSchedules, setChangedSchedules] = React.useState({});
  const [changedSites, setChangedSites] = React.useState(new Set());

  React.useState(() => {
    if (!dialogOpenProp) setChangedSchedules({});
    setSchedules(false);
  }, [dialogOpenProp]);

  const {
    data: siteReaders,
    isLoading,
    refetch
  } = useQueryWrapper(
    ['site-readers', selectedSite?.id],
    () =>
      AccessPoint.search({
        siteId: selectedSite?.id,
        page: 0,
        accessLevelId: accessLevel?.id
      }),
    {
      enabled:
        Boolean(selectedSite?.id) &&
        !changedSites.has(selectedSite?.id?.toString()),
      select: (res) => ({
        ...res,
        data: res.data.sort(function (a, b) {
          if (a.door_name.toLowerCase() < b.door_name.toLowerCase()) {
            return -1;
          }
          if (a.door_name.toLowerCase() > b.door_name.toLowerCase()) {
            return 1;
          }
          return 0;
        })
      })
    }
  );

  const saveDoorsMutate = useSaveDoorsMutate(accessLevel, {
    onError: () => {
      refetch();
    }
  });

  const confirm = useAlertDialog();

  React.useEffect(() => {
    let tempSchedulesObj = { ...schedules };
    // dont reload schedules if the site is changed
    //TODO change this to a better way, matbe to check in changedSchedules
    if (changedSites.has(selectedSite?.id?.toString())) return;

    if (!siteReaders?.data) return;

    let $data = [...siteReaders?.data];

    $data?.forEach(
      (r) =>
        (tempSchedulesObj[r.door_token] = {
          Name: r.schedule_name,
          token: r.schedule_token,
          site_id: r.site_id
        })
    );

    setSchedules(tempSchedulesObj);
  }, [siteReaders, dialogOpenProp]);

  const handleSaveSchedules = () => {
    saveDoorsMutate.mutate(
      {
        schedules: changedSchedules,

        id: accessLevel?.id
      },

      {
        onSuccess: () => {
          setChangedSchedules({});
          setChangedSites(new Set());
        }
      }
    );
  };

  React.useEffect(() => {
    if (!dialogOpenProp) {
      setChangedSchedules({});
      setChangedSites(new Set());
      setSelectedSite(null);
    }
  }, [dialogOpenProp]);

  const handleDialogClose = async () => {
    if (changedSites.size > 0) {
      if (
        await confirm({
          description: 'Are you sure you want to discrad changes ?'
        })
      ) {
        setDialogOpenProp(false);
      }
    } else setDialogOpenProp(false);
  };

  return (
    <>
      <Button onClick={() => setDialogOpenProp(true)} compact>
        Edit...
      </Button>

      <Dialog open={dialogOpenProp} onOpenChange={setDialogOpenProp}>
        <DialogContent
          noCloseButton
          id="site-dialog"
          onPointerDownOutside={(e) => e.preventDefault()}
          css={css`
            height: 70vh;
            width: 60vw;
          `}
        >
          <article
            css={css`
              .ScrollAreaRoot {
                flex: 1;
                width: 100%;

                border-radius: 4px;
                overflow: hidden;
                // box-shadow: 0 2px 10px hsl(0 0% 0% / 0.141);
                background-color: white;
                --scrollbar-size: 10px;
              }

              .ScrollAreaViewport {
                width: 100%;
                height: 100%;
                border-radius: inherit;
              }

              .ScrollAreaScrollbar {
                display: flex;
                /* ensures no selection */
                user-select: none;
                /* disable browser handling of all panning and zooming gestures on touch devices */
                touch-action: none;
                padding: 2px;
                background: hsl(0 0% 0% / 0.114);
                transition: background 160ms ease-out;
              }
              .ScrollAreaScrollbar:hover {
                //  background: black;
              }
              .ScrollAreaScrollbar[data-orientation='vertical'] {
                width: var(--scrollbar-size);
              }
              .ScrollAreaScrollbar[data-orientation='horizontal'] {
                flex-direction: column;
                height: var(--scrollbar-size);
              }

              .ScrollAreaThumb {
                flex: 1;
                background: gray;
                border-radius: var(--scrollbar-size);
                position: relative;
              }
              /* increase target size for touch devices https://www.w3.org/WAI/WCAG21/Understanding/target-size.html */
              .ScrollAreaThumb::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                height: 100%;
                min-width: 44px;
                min-height: 44px;
              }

              .ScrollAreaCorner {
                background: green;
              }

              display: flex;
              flex-direction: column;
              gap: 0.5rem;
              height: 100%;
              pointer-events: ${saveDoorsMutate.isLoading ? 'none' : 'all'};
              opacity: ${saveDoorsMutate.isLoading ? '50%' : '100%'};
            `}
          >
            <div
              css={css`
                display: flex;
                align-items: center;
                gap: 2rem;
              `}
            >
              <button
                onClick={handleDialogClose}
                aria-label="Close"
                css={css`
                  position: fixed;
                  top: 0px;
                  right: 0px;
                  margin: 0.5rem;
                `}
              >
                <XLg />
              </button>
              <SitePopover
                setSelectedSite={setSelectedSite}
                selectedSite={selectedSite}
                defaultSiteId={selectedSiteIdProp}
                changedSites={changedSites}
                accessLevelId={accessLevel?.id}
                accessLevel={accessLevel}
              />
              <AuxiliaryButton
                title="Refresh door list"
                compact
                onClick={() =>
                  queryClient.resetQueries(['site-readers', selectedSite?.id])
                }
              >
                <IconRefresh />
              </AuxiliaryButton>
            </div>

            {siteReaders?.data ? (
              <ScrollArea.Root className="ScrollAreaRoot">
                <ScrollArea.Viewport className="ScrollAreaViewport">
                  <div
                    css={css`
                      article:nth-child(even) {
                        background-color: #f2f2f2;
                      }
                      padding: 1rem;
                    `}
                  >
                    {siteReaders?.data?.map((r) => (
                      <ReaderItem
                        reader={r}
                        schedules={schedules}
                        setSchedules={setSchedules}
                        setChangedSchedules={setChangedSchedules}
                        setChangedSites={setChangedSites}
                      />
                    ))}
                  </div>
                </ScrollArea.Viewport>
                <ScrollArea.Scrollbar
                  className="ScrollAreaScrollbar"
                  orientation="vertical"
                >
                  <ScrollArea.Thumb className="ScrollAreaThumb" />
                </ScrollArea.Scrollbar>
                <ScrollArea.Scrollbar
                  className="ScrollAreaScrollbar"
                  orientation="horizontal"
                >
                  <ScrollArea.Thumb className="ScrollAreaThumb" />
                </ScrollArea.Scrollbar>
                <ScrollArea.Corner className="ScrollAreaCorner" />
              </ScrollArea.Root>
            ) : (
              <div
                css={css`
                  display: flex;
                  height: 100%;
                  width: 100%;
                  justify-content: center;
                  align-items: center;
                `}
              >
                <label
                  css={css`
                    opacity: 50%;
                    font-size: 12px;
                  `}
                >
                  {isLoading
                    ? 'Loading...'
                    : 'Pick a site from the above list to select doors'}
                </label>
              </div>
            )}

            <footer
              css={css`
                display: flex;
                justify-content: space-between;
              `}
            >
              <CancelButton compact onClick={handleDialogClose}>
                Close
              </CancelButton>
              <Button
                disabled={
                  Object.keys(changedSchedules)?.length == 0 ||
                  saveDoorsMutate.isLoading
                }
                compact
                loading={saveDoorsMutate.isLoading}
                onClick={handleSaveSchedules}
              >
                <Check2Circle size={20} />
                <span>
                  Submit {Object.keys(changedSchedules)?.length} change(s)
                  across {changedSites.size} Site(s)
                </span>
              </Button>
            </footer>
          </article>
          {process.env.NODE_ENV == 'development' && (
            <div
              css={css`
                //  font-size: 10px;
              `}
            >
              {selectedSite?.id}
              *********
              {JSON.stringify(changedSchedules)}
              **************
              {JSON.stringify(schedules)}
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
