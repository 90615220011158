import React from 'react';
import { css } from '@emotion/react';

/** @jsxImportSource @emotion/react */

const styles = (props) => css`
  height: 100%;
  width: ${props.completed}%;
  background: ${props.bgcolor};
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: width 0.4s ease-in-out;
`;
const ProgressBar = (props) => {
  const { completed, innerLabel } = props;

  return (
    <div
      className="progress-bar-container"
      css={css`
        height: ${props.height || 8}px;
        width: 100%;
        background: #e0e0de;
      `}
    >
      <div css={styles(props)}>
        {innerLabel && <span>{`${completed}%`}</span>}
      </div>
    </div>
  );
};

export default ProgressBar;
