import React from 'react';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react **/

const COLORS = {
  ABCDE: 'red',
  FGHIJ: 'purple',
  KLMN: 'blue',
  OPQRS: 'green',
  TUVWXYZ: 'pink',
  12345: 'yellow'
};

function decideColor(char) {
  for (let key in COLORS) {
    if (key.indexOf(char) > -1) return COLORS[key];
  }
  return 'default';
}
//TODO change color to not random

const initials = (nameString) => {
  if (!nameString) return 'N/A';

  let initials;
  const fullName = nameString.split(' ');

  if (fullName.length > 1)
    initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
  else initials = nameString;

  return initials.substring(0, 2).toUpperCase();
};

const Hex = ({ size, name }) => {
  const color = `bg-${decideColor(initials(name).substring(0, 1))}-400`;
  return (
    <div
      className={` ${color} ${size} flex font-bold items-center justify-center`}
      style={{
        clipPath:
          'polygon(50% 0%, 83% 12%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0% 43%, 17% 12%)'
      }}
    >
      {initials(name || 'N A')}
    </div>
  );
};

const circleStyles = (props) => css`
  //object-fit: cover;
  display: inline-flex;
  width: ${props.size || '32px'};
  height: ${props.size || '32px'};
  font-style: normal;
  font-weight: bold;
  font-size: ${props.fontSize || '12px'};
  line-height: 100%;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--neutral-00);
  background: ${!props.url
    ? 'var(--avatar-circle-background-color)'
    : 'transparent'};
`;

const containerStyles = (props) => css`
  //object-fit: cover;

  width: ${props.size || '32px'};
  height: ${props.size || '32px'};
`;
const Circle = (props) => {
  return <div css={circleStyles(props)}>{initials(props.name)}</div>;
};

// TODO deal better with erro coming from object
const AvatarCircle = (props) => {
  return (
    <div className="avatar" title={props.name} css={containerStyles(props)}>
      {props.url ? (
        <img
          css={circleStyles(props)}
          type="image/png"
          src={props.url} 
          width="100%"
          height="100%"
          loading="lazy"
        />
      ) : (
        <Circle {...props} />
      )}
    </div>
  );
};

const AvatarHex = (props) => {
  let size = props.size
    ? `h-${props.size} w-${props.size} text-${props.size / 6 + 'xl'}`
    : 'h-10 w-10';

  return <Hex size={size} name={props.name} />;
};

export { AvatarCircle, AvatarHex };
