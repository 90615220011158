import React from 'react';
import Template from 'base/template';

import Device from 'api/device';
import { columns } from 'views/devices/table-columns';
import Sidebar from 'views/devices/sidebar';
import IndexContent from 'base/index-content';
import ActionMenu from 'views/devices/action-menu';
import ActionMenuCompanyUser from 'views/devices/action-menu-company-user';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import { MenuItem } from 'components/action-menu';

import { MenuItems } from 'components/action-menu';
import { Button } from 'components/buttons';
import {
  ChevronDown,
  ExclamationCircle,
  PlugFill,
  Power
} from 'react-bootstrap-icons';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'providers/auth';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import Site from 'api/site';
import { useMutationWrapper } from 'utils/ajax';
import Company from 'api/company';

import { ReactComponent as DeviceEmptyIcon } from 'assets/empty-icons/empty-device.svg';
import parse from 'date-fns/parse';
import useAlertDialog from 'providers/alert-dialog-provider';
import {
  IconAlertTriangle,
  IconApiAppOff,
  IconNetworkOff
} from '@tabler/icons-react';

const ExtraAction = () => {
  const history = useHistory();
  const { user } = useAuth();

  const handleSave = (e) => {
    if (e.target.dataset.type != 'controller')
      history.push('devices/add', { type: e.target.dataset.type });
    else if (e.target.dataset.type == 'controller')
      history.push('controllers/add', { type: e.target.dataset.type });
  };

  const { mutate } = useMutationWrapper(
    ['company', 'checkSync', user.company_id],
    (companyId) => Company.checkSync(companyId),
    {
      successMessage:
        'When done, report will populate in top bar Notificaiton panel.',
      loadingMessage: 'Generating Report...'
    }
  );

  const confirm = useAlertDialog();
  const handleCompanySync = async () => {
    if (
      await confirm({
        description:
          'Running company sync might effect controllers performance, continue ?'
      })
    )
      mutate(user.company_id);
  };

  const menuStyles = css`
    display: inline-block;
    position: relative;
  `;

  return (
    <section
      css={css`
        display: flex;
        align-items: center;
        gap: 0.2rem;
      `}
    >
      <DropdownMenu.Root
        className="action-menu-wrapper"
        as="div"
        css={menuStyles}
      >
        <DropdownMenu.Trigger asChild={true}>
          <Button
            compact
            css={css`
              svg {
                transition: all 0.2s;
              }
              &[data-state='open'] {
                svg {
                  transform: rotate(180deg);
                }
              }
            `}
          >
            Add Device
            <ChevronDown />
          </Button>
        </DropdownMenu.Trigger>
        <MenuItems>
          <MenuItem onClick={handleSave} data-type="relay">
            Relay
          </MenuItem>
          <MenuItem onClick={handleSave} data-type="speaker">
            Speaker
          </MenuItem>
          {user?.features?.intercoms && (
            <MenuItem onClick={handleSave} data-type="intercom">
              Intercom
            </MenuItem>
          )}
          {user?.features?.cameras_enabled && (
            <MenuItem onClick={handleSave} data-type="camera">
              Camera
            </MenuItem>
          )}
          <MenuItem onClick={handleSave} data-type="controller">
            Controller
          </MenuItem>
        </MenuItems>
      </DropdownMenu.Root>
      <DropdownMenu.Root as="div" css={menuStyles}>
        <DropdownMenu.Trigger
          asChild={true}
          css={css`
            svg {
              transition: all 0.2s;
            }
            &[data-state='open'] {
              svg {
                transform: rotate(180deg);
              }
            }
          `}
        >
          <Button
            compact
            css={css`
              svg {
                width: 16px;
                height: 16px;
              }
            `}
          >
            <ChevronDown />
          </Button>
        </DropdownMenu.Trigger>
        <MenuItems>
          <MenuItem
            onClick={handleCompanySync}
            label="Run Company Sync..."
          ></MenuItem>
        </MenuItems>
      </DropdownMenu.Root>
    </section>
  );
};

const Devices = (props) => {
  const { enforce, user } = useAuth();

  const cards = React.useMemo(
    () => [
      {
        name: 'total',
        label: 'Total'
      },
      {
        name: 'alarms',
        label: 'Alarms'
      },
      {
        name: 'offline',
        label: 'Offline',
        color: 'var(--red-base)',
        background: 'var(--stats-background-red)',
        filter: { online: false },
        icon: Power
      },
      {
        name: 'restore_failure',
        label: 'Failures',
        color: 'var(--neutral-01)',
        background: 'var(--neutral-07)',
        filter: { restoreFailure: true },
        icon: Power
      },
      {
        name: 'unsynced',
        label: 'Unsynced',
        color: 'var(--orange-base)',
        id: 'online',
        background: 'var(--stats-background-orange)',
        filter: { synced: '0' },
        icon: Power
      },
      {
        name: 'stale_sub',
        label: 'Stale Subscriptions',
        color: 'var(--red-base)',
        background: 'var(--stats-background-red)',
        filter: { staleSub: true },
        icon: PlugFill,
        visible: () => enforce({ role: 'V' })
      },
      {
        name: 'issues',
        label: 'Global Issues',
        color: 'var(--red-base)',
        background: 'var(--stats-background-red)',
        filter: { issues: true },
        icon: ExclamationCircle,
        visible: () => enforce({ role: true })
      },
      {
        name: 'alerts',
        label: 'Alerts',
        color: 'var(--red-base)',
        background: 'var(--stats-background-red)',
        filter: { alerts: true },
        icon: IconAlertTriangle
      },
      {
        name: 'firmware_mismatch',
        label: 'Outdated Firmware',
        color: 'var(--red-base)',
        background: 'var(--stats-background-red)',
        filter: { firmwareMismatch: true },
        icon: IconApiAppOff,
        visible: () => enforce({ role: 'VI' })
      },
      {
        name: 'hadDrops24Hr',
        label: 'Issues 24Hr',
        icon: IconNetworkOff,
        color: 'var(--light--others--red-400-base)',
        background: 'var(--light--others--red-100)',
        filter: { hadDrops24Hr: true }
      }
    ],
    [user]
  );

  const FILTERS = React.useMemo(
    () => [
      {
        id: 'recent',
        label: 'Recent Searches...',
        options: []
      },
      {
        id: 'filters',
        label: 'Filters',
        options: [
          {
            value: ['type', 'controller'],
            label: 'Controllers',
            name: 'Controllers',
            type: 'common'
          },
          {
            value: ['type', 'relay'],
            label: 'Relays',
            name: 'Relays',
            type: 'common'
          },
          {
            value: ['type', 'camera'],
            label: 'Cameras',
            name: 'Cameras',
            type: 'common'
          }
        ]
      },

      {
        open: true,
        id: 'filterBy',
        label: 'Filter By...',
        options: [
          {
            label: 'Site',
            value: 'site',
            fetchFn: Site.search,
            field: 'site',
            name: 'Site',
            visible: () => enforce({ reqCompany: 'V' }),
            id: 'site'
          },
          {
            visible: () => enforce({ reqNoCompany: true }),
            label: 'Company',
            value: 'company',
            fetchFn: Company.search,
            field: 'company',
            name: 'Company',
            id: 'company'
          },
          {
            custom: true,
            label: 'Model Number',
            field: 'model',
            name: 'Model',
            id: 'model',

            helper: 'Insert a model number',
            options: [
              {
                value: 'A1001',
                label: 'A1001'
              },
              {
                value: 'A1601',
                label: 'A1601'
              },
              {
                value: 'A1610',
                label: 'A1610'
              },
              {
                value: 'A1210',
                label: 'A1210'
              }
            ]
          },
          {
            custom: true,
            label: 'Date Inserted ',
            field: 'insertDateFrom',
            name: 'Date Inserted',
            id: 'company',
            validate: (val) => {
              let d = parse(val, 'MM/dd/yyyy', new Date());

              return d instanceof Date && !isNaN(d);
            },
            helper: 'Insert a valid date above mm/dd/yy',
            options: [
              {
                value: 'yesterday',
                label: 'Past Day'
              },
              {
                value: '-7 days',
                label: 'Past Week'
              },
              {
                value: '-30 days',
                label: 'Past Month'
              }
            ]
          }
        ]
      }
    ],
    [user]
  );

  return (
    <Template {...props}>
      <IndexContent
        newSorter
        queryParams={{
          refetchInterval: 15000
          //refetchIntervalInBackground: true
        }}
        initialFilter={{ hasSite: user?.focused_site }}
        initialSorter={[
          {
            id: 'insert_time',
            sortBy: 'newestInserted',
            desc: true
          }
        ]}
        statsQuery={Device.stats}
        title="Devices"
        id="devices"
        cards={cards}
        query={Device.search}
        columns={columns}
        sidebar={Sidebar}
        selectable
        actionButton={user.role == 'C' ? ActionMenuCompanyUser : ActionMenu}
        buttons={enforce({ managing: true }) ? ExtraAction : false}
        filterOptions={FILTERS}
        emptyIcon={DeviceEmptyIcon}
        getRowProps={(row) => ({
          'data-synced': row.original.synced == 1,
          'data-failure': Boolean(row.original.restore_failure),
          'data-online': row.original.online == 1
        })}
        {...props}
      ></IndexContent>
    </Template>
  );
};

export default Devices;
