import React from "react";
import { css } from "@emotion/react";

import { useInView } from "react-intersection-observer";

/** @jsxImportSource @emotion/react */
const LoadMoreButton = (props) => {
  const {
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    tableRef = null,
  } = props;

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
    rootMargin: "750px 0px",
    root: tableRef?.current ?? null,
  });

  React.useEffect(() => {
    if (inView) hasNextPage && !isFetchingNextPage && fetchNextPage();
  }, [inView]);

  return (
    <button
      css={css`
        display: block;
        margin: auto;
        padding: 0.5rem;
        font-size: 12px;
        font-weight: 600;
        min-height: 20px;
        padding: 0.5rem;
        color: var(--neutral-05);
      `}
      ref={ref}
      onClick={() => fetchNextPage()}
      disabled={!hasNextPage || isFetchingNextPage}
    >
      {hasNextPage && <span>Loading More...</span>}
    </button>
  );
};

export default LoadMoreButton;
