import React from 'react';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import { IconTrash, IconTrashX } from '@tabler/icons-react';
import { AuxiliaryButton, IconButton } from 'components/buttons';

export default (props) => {
  return (
    <IconButton
      {...props}
      delete
      css={css`
        height: 36px;
        width: 36px;
        padding: 0;
      `}
    >
      <IconTrash />
    </IconButton>
  );
};
