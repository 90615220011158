import React from 'react';
import Template from 'base/template';
import { Button, CancelButton } from 'components/buttons';
import Controller from 'api/controller';
import FormProvider from 'providers/form-provider';
import { TextField, PasswordField } from 'components/form';
import { useMutationWrapper } from 'utils/ajax';
import { Redirect } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useMutateReset } from 'views/controllers/actions';
import PageTitle from 'components/page-title';
import Content from 'base/content';
import { Field } from 'formik';
import ProductSelect from './product-select';
import ConfigurationSelect from './configuration-select';
import O3CRegistration from 'components/03c-registration';
import AsyncSelect from 'components/headless-select/async';
import Site from 'api/site';
import Breadcrumbs from 'components/breadcrumbs';
import useAlertDialog from 'providers/alert-dialog-provider';
import { css } from '@emotion/react';
import Tooltip from 'components/tooltip';
import { IconAlertCircle } from '@tabler/icons-react';
/** @jsxImportSource @emotion/react */

const ControllerSave = (props) => {
  let generalRef = React.useRef(1);

  let ac = props?.location?.state?.ac || null;

  const SCHEMA = Yup.object().shape({
    site: Yup.string().required('Required').nullable(),
    name: Yup.string().required('Required').nullable(),
    doors: Yup.string().required('Required').nullable(),
    product: !ac && Yup.string().required('Required').nullable(),
    doors: !ac && Yup.string().required('Required').nullable(),
    username: Yup.string().required('Required').nullable(),
    ip: Yup.string().required('Required').nullable()
  });

  const INITIAL_VALUES = {
    site: ac?.site_id,
    name: ac?.name,
    doors: ac?.doors,
    product: ac?.prodcut || '',
    username: ac?.username || 'root',
    password: ac ? '' : 'pass',
    o3c_id: ac?.o3c_id,
    ip: ac?.ip
  };

  const [redirect, setRedirect] = React.useState(false);

  const {
    isSuccess: isSuccessSave,
    isLoading,
    mutate,
    data
  } = useMutationWrapper(
    ['controller', 'save'],
    (input) => Controller[ac ? 'edit' : 'add'](input, ac?.id),
    {}
  );

  const { mutate: mutateReset } = useMutateReset(ac);

  const confirm = useAlertDialog();

  React.useEffect(() => {
    const confirmReset = async () => {
      let ok = await confirm({
        description: `Newlly added controllers require reboot, Would You like to reboot the new Device ?`
      });
      ok && mutateReset(data.message);
      setRedirect(true);
    };
    if (!generalRef.current) return;
    if (!isSuccessSave) return;
    if (!ac) {
      confirmReset();
    } else if (ac) setRedirect(true);

    return () => (generalRef.current = null);
  }, [isSuccessSave]);

  return (
    <Template {...props}>
      {redirect && <Redirect to="/devices" />}
      <Content {...props}>
        <PageTitle>Controller {ac ? ' Save' : ' Add'}</PageTitle>
        <Breadcrumbs crumbs={props.crumbs} />
        <Formik
          validationSchema={SCHEMA}
          initialValues={INITIAL_VALUES}
          onSubmit={(values) => {
            if (values.mac)
              values.mac = values.mac.replace(/[^a-z0-9]/gi, '').toUpperCase();
            if (values.oak)
              values.oak = values.oak.replace(/[^a-z0-9]/gi, '').toUpperCase();
            mutate(values);
          }}
        >
          {({ handleSubmit, values }) => (
            <FormProvider mutateFunc={handleSubmit} disabled={isLoading}>
              <section>
                {!ac && (
                  <>
                    <Field name="product" component={ProductSelect} />
                    <Field name="doors" component={ConfigurationSelect} />
                  </>
                )}

                <TextField name="name" value={values.name} />

                <div
                  className="ip-address"
                  css={css`
                    position: relative;
                    > div:first-of-type {
                      position: absolute;
                      right: 0;
                      top: 0;
                      z-index: 1;
                    }
                  `}
                >
                  <Tooltip
                    content={
                      <div>
                        O3c IP Address format is: [DEVICE-MAC].o3c.internal
                      </div>
                    }
                  >
                    <div>
                      <IconAlertCircle size={20} color={'var(--neutral-02)'} />
                    </div>
                  </Tooltip>
                  <TextField label="IP Address" name="ip" value={values.ip} />
                </div>

                <TextField name="username" value={values.username} />
                <PasswordField
                  autocomplete="new-passsord"
                  name="password"
                  value={values.password}
                  placeholder="Type to change..."
                />

                <TextField label="O3C ID" value={values.o3c_id} />

                <Field
                  name="site"
                  component={AsyncSelect}
                  fetchFn={Site.search}
                  isMulti={false}
                />
              </section>
              <O3CRegistration device={ac} />

              <footer>
                <Button type="submit">Submit</Button>
                <CancelButton onClick={() => setRedirect(true)} />
              </footer>
            </FormProvider>
          )}
        </Formik>
      </Content>
    </Template>
  );
};

export default ControllerSave;
