import React from "react";
import Template from "base/template";

import Site from "api/site";
import { columns } from "views/sites/table-columns";
import Sidebar from "views/sites/sidebar";
import IndexContent from "base/index-content";
import { XLg } from "react-bootstrap-icons";
import ActionMenu from "views/sites/action-menu";
import { useAuth } from "providers/auth";

const cards = [
  {
    name: "total",
    label: "Total",
  },
  {
    name: "with_issues",
    label: "With Issues",
    color: "var(--red-base)",
    background: "var(--red-v-02)",
    icon: XLg,
    filter: { with_issues: true },
  },
];

const Sites = (props) => {
  const { enforce } = useAuth();
  return (
    <Template {...props}>
      <IndexContent
        statsQuery={Site.stats.bind()}
        title="Sites"
        id="sites"
        cards={cards}
        query={Site.search.bind()}
        columns={columns}
        addButton={enforce({ managing: true }) ? "Add a Site" : false}
        sidebar={Sidebar}
        selectable
        actionButton={ActionMenu}
        {...props}
      ></IndexContent>
    </Template>
  );
};

export default Sites;
