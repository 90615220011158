import { css } from '@emotion/react';
import { Badge } from 'components/badge';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'providers/auth';

import { getSelectionColumn } from 'components/table-selection-column';
import { dateTimeDif, dateTimeLocal } from 'utils/time';

/** @jsxImportSource @emotion/react */

export const columns = [
  getSelectionColumn(),

  {
    Header: 'Name',
    accessor: 'name',
    newSorter: true,
    asc: 'atoz',
    desc: 'ztoa',
    Cell: ({ row: { original } }) => {
      return (
        <>
          <strong
            css={css`
              display: inline-block;
              margin-bottom: 6px;
            `}
          >
            {original.name}
          </strong>
          <br />
          <small>{original.site_name}</small>
        </>
      );
    }
  },
  {
    newSorter: true,
    asc: 'leastInsertTime',
    desc: 'mostInsertTime',
    accessor: 'insert_time',
    label: 'Date Inserted',
    Header: 'Date Inserted',
    id: 'insert_time',
    Cell: ({ cell: { value } }) => {
      if (value) return <span>{dateTimeDif(value)}</span>;
      else return null;
    }
  },
  {
    newSorter: true,

    accessor: 'protocol',

    Header: 'Protocol',
    id: 'protocol',
    disableSortBy: true,
    Cell: ({ cell: { value } }) => {
      if (value == 'mqtt')
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1100 280"
            overflow="visible"
            fill="#2480d6"
            width="56px"
            height="48px"
          >
            <path d="M388 142.5c1.7 3.7 3.3 7.5 4.8 11.4a149.3 149.3 0 0 1 4.9-11.6c1.7-3.7 3.5-7.4 5.3-10.9L453.4 32c.9-1.7 1.8-3 2.8-4 .9-.9 2-1.6 3.2-2.1s2.5-.7 4-.7h40v196.6h-40.2V108.6c0-5.5.3-11.4.8-17.8l-52.1 101.1c-1.6 3.2-3.8 5.5-6.5 7.2-2.7 1.6-5.9 2.4-9.4 2.4h-6.2c-3.5 0-6.6-.8-9.4-2.4-2.7-1.6-4.9-4-6.5-7.2L321.4 90.7c.4 3.2.6 6.3.8 9.4s.3 6 .3 8.6v113.1h-40.2V25.2h40c1.5 0 2.8.2 4 .7s2.2 1.1 3.2 2.1c.9.9 1.9 2.3 2.8 4l50.6 99.9c1.7 3.4 3.4 6.9 5.1 10.6zm331.1-19.1c0 8.5-.9 16.6-2.6 24.4-1.8 7.8-4.3 15.1-7.6 21.9s-7.3 13.2-12.2 19c-4.8 5.9-10.3 11-16.4 15.5l49.8 54.4h-37.5c-5.4 0-10.3-.7-14.7-2s-8.3-3.9-11.7-7.9l-24.4-27.4c-4 .8-8 1.4-12.1 1.9-4.1.4-8.3.7-12.6.7-15.3 0-29.2-2.6-41.8-7.7S551.9 204 543 194.9c-9-9-15.9-19.7-20.8-31.9s-7.4-25.5-7.4-39.7 2.5-27.4 7.4-39.7c4.9-12.2 11.8-22.9 20.8-31.9s19.7-16.1 32.3-21.2 26.5-7.7 41.8-7.7 29.2 2.6 41.8 7.8 23.3 12.3 32.2 21.3 15.8 19.6 20.7 31.9c4.8 12.2 7.3 25.4 7.3 39.6zm-46.7 0c0-9.7-1.3-18.5-3.8-26.3s-6.1-14.4-10.9-19.8c-4.7-5.4-10.5-9.5-17.3-12.4s-14.6-4.3-23.4-4.3-16.7 1.4-23.6 4.3-12.7 7-17.4 12.4-8.3 12-10.9 19.8c-2.5 7.8-3.8 16.5-3.8 26.3s1.3 18.6 3.8 26.4 6.1 14.4 10.9 19.8c4.7 5.4 10.5 9.5 17.4 12.4 6.9 2.8 14.7 4.3 23.6 4.3 8.7 0 16.5-1.4 23.4-4.3 6.8-2.8 12.6-7 17.3-12.4s8.3-12 10.9-19.8c2.5-7.8 3.8-16.6 3.8-26.4zm193.2-98.3v36.2h-53.3v160.5h-45.6V61.3h-53.3V25.1h152.2zm174.5 0v36.2h-55.3v160.5h-45.6V61.3h-53.3V25.1h154.2zM34.9 136.4h-.6V214c0 5.6 4.6 10.2 10.2 10.2h79.9c-.7-48.5-40.6-87.8-89.5-87.8zm0-64h-.6v33c65.9.3 119.5 53.3 120.2 118.8h34.2c-.6-83.8-69.4-151.8-153.8-151.8zm202.3 141.7V144C214 87.2 167.3 42.3 109.1 21.4H44.5c-5.6 0-10.2 4.6-10.2 10.2v9.9c101.4.3 183.9 82 184.5 182.8h8.2c5.6-.1 10.2-4.6 10.2-10.2zM210.5 49.7c9.4 9.4 19 21.3 26.7 31.8v-50a10.07 10.07 0 0 0-10.1-10.1h-51.5c11.9 8.3 24.3 17.8 34.9 28.3z" />
          </svg>
        );
      else if (value == 'http')
        return (
          <svg
            width="36px"
            height="48px"
            focusable="false"
            aria-hidden="true"
            viewBox="0 0 24 24"
            fill="#2480d6"
          >
            <path d="M4.5 11h-2V9H1v6h1.5v-2.5h2V15H6V9H4.5v2zm2.5-.5h1.5V15H10v-4.5h1.5V9H7v1.5zm5.5 0H14V15h1.5v-4.5H17V9h-4.5v1.5zm9-1.5H18v6h1.5v-2h2c.8 0 1.5-.7 1.5-1.5v-1c0-.8-.7-1.5-1.5-1.5zm0 2.5h-2v-1h2v1z"></path>
          </svg>
        );
      else return null;
    }
  },
  {
    disableManaging: true,
    Header: 'Company',
    disableSortBy: true,
    accessor: 'company_name',
    Cell: ({ row: { original }, value }) => {
      const { companyManage } = useAuth();
      const history = useHistory();

      const handleManage = (e) => {
        e.stopPropagation();
        companyManage.mutate(original.company_id, {
          onSuccess: () => history.push('/devices')
        });
      };

      return (
        <Badge
          onClick={handleManage}
          css={css`
            text-transform: uppercase;
            &:hover {
              text-decoration: underline !important;
            }
            padding: 0;
            background: none;
          `}
          color="blue"
          className="company-name"
        >
          {value}
        </Badge>
      );
    }
  },
  {
    Header: 'Status',
    newSorter: true,
    id: 'online',
    asc: 'onlineFirst',
    desc: 'onlineLast',
    accessor: 'online',
    pickerDisabled: true,
    label: 'Status',
    Cell: ({ cell: { value } }) => {
      if (value == 0) return <Badge color="red">OFFLINE</Badge>;
      if (value == 1) return <Badge color="green">ONLINE</Badge>;
    }
  },
  {
    Header: 'Alert Status',
    disableSortBy: true,
    id: 'alert_state',
    accessor: 'alert_state'
  },
  {
    label: 'Last Data Recieved',
    Header: 'last Data At',
    id: 'last_data_at',
    disableSortBy: true,
    accessor: 'last_data_at',
    Cell: ({ cell: { value } }) => {
      if (value) return <span>{dateTimeDif(value)}</span>;
      else return null;
    }
  }
];
