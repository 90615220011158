import React from 'react';
import Template from 'base/template';
import { Button, CancelButton, SecondaryButton } from 'components/buttons';
import FormProvider from 'providers/form-provider';
import { Redirect } from 'react-router-dom';
import { useMutationWrapper } from 'utils/ajax';
import { useRedirect } from 'hooks/redirect-hook';
import FloorForm from 'views/elevator-floors/form';
import Elevator from 'api/elevator';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PageTitle from 'components/page-title';
import Content from 'base/content';
import { useQueryWrapper } from 'utils/ajax';
import { useParams } from 'react-router-dom';
/** @jsxImportSource @emotion/react */

import { useNotFound } from 'hooks/not-found-hook';
import Breadcrumbs from 'components/breadcrumbs';
import ElevatorFloor from 'api/elevator_floor';

const Save = (props) => {
  const { id } = useParams();

  const [floor, setFloor] = React.useState(props?.location?.state?.floor);

  const query = useQueryWrapper(
    ['floor', 'get', floor?.door_token],
    () => ElevatorFloor.search({ door_token: floor?.door_token }),
    {
      initialData: {
        data: [props?.location?.state?.floor]
      }
    }
  );

  useNotFound(id, query, props);

  React.useEffect(() => {
    if (!query.data.data?.[0]) return;
    setFloor(query.data.data?.[0]);
  }, [query.data.data?.[0]]);

  const SCHEMA = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required')
  });

  const INITIAL_VALUES = {
    name: floor?.floor_name,
    schedule: floor ? floor.open_schedule_token : []
  };

  let { isLoading, mutate, isSuccess } = useMutationWrapper(
    ['floor', 'save', floor?.door_token],
    (input) => ElevatorFloor.save(input, floor?.door_token),
    {}
  );

  const { redirect, setRedirect } = useRedirect(isSuccess);

  return (
    <Template {...props}>
      {redirect && (
        <Redirect to={`/elevators/${floor['elevator_id']}/floors`} />
      )}
      <Content {...props}>
        <PageTitle>{floor?.floor_name}</PageTitle>
        <Breadcrumbs crumbs={props.crumbs} />
        {floor && (
          <Formik
            validationSchema={SCHEMA}
            initialValues={INITIAL_VALUES}
            onSubmit={(values) => {
              mutate(values);
            }}
          >
            {({ handleSubmit }) => (
              <FormProvider mutateFunc={handleSubmit} disabled={isLoading}>
                <FloorForm floor={floor} />
                <footer>
                  <Button type="submit">Submit</Button>
                  <CancelButton onClick={() => setRedirect(true)} />
                </footer>
              </FormProvider>
            )}
          </Formik>
        )}
      </Content>
    </Template>
  );
};

export default Save;
