L.DataDivIcon = L.DivIcon.extend({
  createIcon: function (oldIcon) {
    var div = L.DivIcon.prototype.createIcon.call(this, oldIcon);
    if (this.options.data) {
      for (var key in this.options.data) {
        div.dataset[key] = this.options.data[key];
      }
    }
    return div;
  }
});

export default function (options) {
  return new L.DataDivIcon(options);
}
