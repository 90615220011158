import React from "react";

import { css } from "@emotion/react";
import DoorSchedules from "./door-schedules";
import { useQueryWrapper } from "utils/ajax";
import GenericDoor from "api/generic_door";
/** @jsxImportSource @emotion/react */
import { format, formatDistance, formatRelative, subDays } from "date-fns";
import { ClockHistory } from "react-bootstrap-icons";

const styles = css`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr 1fr 1fr;

  flex-direction: column;
  column-gap: 1rem;
  row-gap: 0.2rem;

  align-items: center;
  padding: 12px;

  margin: 0 1rem 1rem 1rem;

  background: var(--red-v-02);
  /* light/others/red-300 */

  border: 1px solid #ffcaca;
  border-radius: 4px;

  //color: var(--red-base);
  strong {
  }

  time {
    display: block;
  }
  .schedules {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: var(--neutral-02);
  }
  svg {
    grid-row: 1 / span 3;
  }
`;

const OpenTimes = ({ door }) => {
  if (
    JSON.parse(door.open_schedule_token)?.length == 0 ||
    !door.open_schedule_token
  )
    return null;

  const { data } = useQueryWrapper(["door", "open-times", door.id], () =>
    GenericDoor.getNextOpenTime(door.id)
  );

  return (
    <article css={styles} className="open-times">
      <ClockHistory size="42" />

      <strong>
        {`Door is set to ${
          door.snowday == "1" ? "unlock on first swipe" : "auto unlock"
        }`}
        :
      </strong>

      <DoorSchedules door={door} />

      {data?.data && (
        <time>
          {formatDistance(new Date(data.data), new Date(), {
            addSuffix: true,
          })}
        </time>
      )}
    </article>
  );
};

export default OpenTimes;
