import { Card } from './card';
import { IconRecycle } from '@tabler/icons-react';
import { Button } from 'components/buttons';
import { css } from '@emotion/react';
import { useMutateReset } from 'views/controllers/actions';
import useAlertDialog from 'providers/alert-dialog-provider';
/** @jsxImportSource @emotion/react */

export default ({ device }) => {
  const mutateReset = useMutateReset(device);
  const confirm = useAlertDialog();

  const handleReset = async () => {
    if (
      await confirm({
        description: `Are you sure you want to reset ${device?.name} ?`
      })
    )
      mutateReset.mutate(device.id, {
        onSuccess: () => {}
      });
  };

  return (
    <Card
      className="reset"
      css={css`
        .icon {
          background: var(--light--others--orange-300);
        }
        background: var(--light--others--orange-200);
        button {
          background: var(--light--others--orange-400-base);
          &:hover {
            background: var(--light--others--orange-400-base);
          }
        }
      `}
    >
      <div className="icon">
        <IconRecycle />
      </div>
      <div className="content">
        <h2>Reset</h2>
        <span className="description">Factory default.</span>
      </div>
      <div className="action">
        <Button onClick={handleReset} compact>
          Reset
        </Button>
      </div>
    </Card>
  );
};
