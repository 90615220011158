import React from 'react';
import '../../css/form/text-field.css';

import { InputLabel, InputHelper, Input } from 'components/form';
import { ErrorMessage } from 'components/form';
import { Eye, EyeSlash } from 'react-bootstrap-icons';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */

const getNameFromLabel = (label) => label.toLowerCase().replace(/\ /g, '_');

const PasswordField = React.forwardRef((props, ref) => {
  const name = props.name || getNameFromLabel(props.label);
  const { error } = props;

  const [isShow, setIsShow] = React.useState(false);

  return (
    <div className="field password-field">
      <InputLabel {...props} />
      <InputHelper {...props} />
      <div
        css={css`
          position: relative;
        `}
      >
        <Input
          css={css`
            padding-right: 36px;
          `}
          type={!isShow ? 'password' : 'text'}
          placeholder={props.placeholder || 'Type to change'}
          ref={ref}
          name={name}
          {...props}
          autoComplete="new-password"
        />
        <button
          onClick={() => setIsShow((prev) => !prev)}
          type="button"
          css={css`
            opacity: 50%;
            position: absolute;
            right: 8;
            top: 0;
            height: 100%;
            width: 24px !important;

            // width: fit-content !important;
            margin: 0px !important;
          `}
        >
          {isShow ? <EyeSlash size={18} /> : <Eye size={18} />}
        </button>
      </div>
      <ErrorMessage name={name} {...props} />
    </div>
  );
});

export default PasswordField;
