import React from 'react';
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { IconButton, AuxiliaryButton } from 'components/buttons';
import { Check2, Pencil, PlusLg, Trash, XLg } from 'react-bootstrap-icons';
import { DialogDisclosure } from 'reakit/Dialog';
import { useModal } from 'providers/modal-provider';
import CameraActionRuleModal from './camera-action-rule-modal';
import { useQueryWrapper } from 'utils/ajax';
import ActionRule from 'api/action_rule';
import { useMutationWrapper } from 'utils/ajax';
import { useQueryClient } from 'react-query';
import Spinner from 'components/spinner';
import Label from 'components/label';
import Aux from 'api/auxiliary';
import useAlertDialog from 'providers/alert-dialog-provider';
import { Badge } from 'components/badge';
const RULE_PRETTY_NAMES = {
  noDisruption: 'Always',
  motionOn: 'Motion Activated',
  systemReady: 'System Ready',
  onObjectAnalytics: 'Object Analytics'
};

const ACTION_PRETTY_NAMES = {
  recordVideo: 'Edge Recording',
  sendSystemNotification: 'Notify Server',
  sendHttpsFeed: 'Cloud Recording',
  sendMotionFrames: 'Send Snapshots'
};

const styles = css`
  table,
  tr {
    border: 1px solid var(--neutral-05);
    border-radius: 5px;
    border-collapse: collapse;
  }
  td,
  th {
    padding: 0.8rem;
  }
  .spinner {
    display: block;
    margin: 24px auto;
  }
`;

const CameraRules = ({ camera }) => {
  const queryClient = useQueryClient();

  const confirm = useAlertDialog();

  const { data: rules, isLoading } = useQueryWrapper(
    ['action-rules', camera.device_id],
    () => ActionRule.search({ device_id: camera.device_id }),
    {
      select: (data) => {
        const obj = { ...data };

        obj.data = obj.data.filter(
          (i) => i.action_type != 'sendSystemNotification'
        );

        obj.data = obj.data.map((rule) => {
          let ruleType;

          if (rule.rule_type.includes('motionOn')) ruleType = 'motionOn';
          else if (rule.rule_type.includes('systemReady'))
            ruleType = 'systemReady';
          else if (rule.rule_type.includes('objectAnalyticsOn'))
            ruleType = 'objectAnalyticsOn';

          return { ...rule, rule_type: ruleType };
        });

        return obj;
      }
    }
  );

  const arDeleteMutation = useMutationWrapper(['action-rule', 'delete'], (id) =>
    ActionRule.delete(id)
  );

  const handleDelete = async (id) => {
    if (
      await confirm({
        description: 'Are you sure you want to delete this rule?'
      })
    )
      arDeleteMutation.mutate(id, {
        onSettled: () => queryClient.invalidateQueries('action-rules')
      });
  };

  return (
    <section css={styles} className="camera-rules">
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <CameraActionRuleModal camera={camera}>
            <AuxiliaryButton compact>
              <PlusLg /> Add Rule
            </AuxiliaryButton>
          </CameraActionRuleModal>
          <table
            id="rules"
            css={css`
              margin-top: 2rem;
              text-align: left;
              width: 100%;

              border-collapse: collapse;

              padding: 1rem;
            `}
          >
            <tr>
              <th>Action</th>
              <th>Rule</th>
              <th>Details</th>
              <th>Active During</th>
            </tr>
            {rules?.data?.map((rule, idx) => (
              <tr key={idx}>
                <td>
                  <span>{ACTION_PRETTY_NAMES[rule.action_type]}</span>
                </td>
                <td
                  css={css`
                    font-size: 12px;
                    color: var(--secondary-font-color);
                  `}
                >
                  {RULE_PRETTY_NAMES[rule.rule_type]}
                </td>
                <td
                  css={css`
                    font-size: 12px;
                    display: flex;
                    gap: 0.2rem;
                    flex-direction: column;
                    color: var(--secondary-font-color);
                  `}
                >
                  <span>FPS: {rule.fps}</span>
                  <span>Res: {rule.resolution}</span>
                </td>
                <td
                  css={css`
                    font-size: 12px;
                    color: var(--secondary-font-color);
                  `}
                >
                  {rule.schedule_name}
                </td>
                <td
                  css={css`
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;
                  `}
                >
                  {rule.enabled == '1' ? (
                    <Badge color="green">Enabled</Badge>
                  ) : (
                    <Badge color="danger">Disabled</Badge>
                  )}
                  <IconButton
                    data-id={rule.id}
                    delete
                    onClick={() => handleDelete(rule.id)}
                  >
                    <Trash />
                  </IconButton>

                  <CameraActionRuleModal camera={camera} rule={rule}>
                    <IconButton>
                      <Pencil />
                    </IconButton>
                  </CameraActionRuleModal>
                </td>
              </tr>
            ))}
          </table>
        </>
      )}
    </section>
  );
};

export default CameraRules;
