import React from 'react';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { BarChart, XLg } from 'react-bootstrap-icons';
import LiveIndicator from './live-indicator';
import { IconActivity, IconTarget } from '@tabler/icons-react';
import { useMotionEvent } from 'views/cameras/use-motion-event';

const CameraStats = ({ pipeline, videoEl, host }) => {
  const [isVisible, setIsVisible] = React.useState();

  const { motionEvent, readyState } = useMotionEvent(host);

  const [event, setEvent] = React.useState(
    host.last_motion_at && new Date(host.last_motion_at + 'Z').toLocaleString()
  );

  React.useEffect(() => {
    if (motionEvent?.type == 'motionOn')
      setEvent(new Date(motionEvent.utctime).toLocaleString());
  }, [motionEvent]);

  return (
    <section
      css={css`
        position: relative;
        z-index: 1001;
        width: fit-content;

        > div {
          padding: 16px 24px 24px;
          color: white;
          margin: 1rem;
          padding: 0.3rem;
          border-radius: 4px;
          background: rgb(41, 41, 41) no-repeat padding-box;
          opacity: 0.88;
        }
        > div.last-person {
          margin-top: auto;
        }
      `}
    >
      <div
        css={css`
          width: fit-content;
          > div.content {
            display: ${isVisible ? 'grid' : 'none'};
          }
          dl {
            grid-template-columns: max-content 1fr;
            grid-gap: 0.5rem 1.5rem;
            min-width: 200px;
          }

          dd {
            font-size: 12px;
            line-height: 17px;
            color: rgb(184, 184, 184);
          }
        `}
      >
        <button onClick={() => setIsVisible((prev) => !prev)}>
          {isVisible ? <XLg size={18} /> : <IconActivity size={20} />}
        </button>
        <div className="content">
          <dl>
            <dd>Last Motion:</dd>
            <dt>{event}</dt>
            {host.lpr && (
              <>
                <dd>LPR:</dd>
                <dt> </dt>
              </>
            )}
          </dl>
        </div>
      </div>
      {/* <div
        className="last-person"
        css={css`
          width: 75px;
          height: 75px;
          border: 2px solid white;
          opacity: 30% !important;
        `}
      ></div> */}
    </section>
  );
};

export default CameraStats;
