import React from 'react';
import { Dialog } from '@radix-ui/react-dialog';
import { DialogTrigger, DialogContent } from 'components/dialog.js';

import { css } from '@emotion/react';
import { useQueryWrapper } from 'utils/ajax';
import Device from 'api/device';
import LiveEventsIndicator from 'components/live-events-indicator';

export const SystemLogIndicator = ({ device }) => {
  const [count, setCount] = React.useState(15);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setCount((prevCount) => {
        const newCount = prevCount - 1;

        if (newCount === 0) {
          return 15;
        }

        return newCount;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        width: 100%;
        gap: 1rem;
        > time {
          margin-left: auto;
          font-size: 12px;
          font-weight: 400;
        }
      `}
    >
      <LiveEventsIndicator active />

      <span>
        {device.name} / {device.mac}
      </span>
      <time>updating in: {count} sec</time>
    </div>
  );
};

const SystemLog = ({ device }) => {
  const { data } = useQueryWrapper(
    ['device', 'getSystemLog', device?.id],
    () => Device.getSystemLog(device.id),
    {
      refetchInterval: 15 * 1000
    }
  );

  return (
    <DialogContent
      css={css`
        height: 65vh !important;
      `}
      title={<SystemLogIndicator device={device} />}
    >
      <div
        css={css`
          height: 75vh;
          background: rgb(14, 30, 37);
          height: 100%;
          color: white;
          padding: 0.5rem;
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          overflow: auto;
          font-family: monospace;
        `}
      >
        {data &&
          typeof data == 'string' &&
          data
            ?.trim()
            .split('\n')
            .map((line, idx) => {
              const message = line.split(' ');
              return (
                <article
                  key={idx}
                  css={css`
                    display: grid;
                    grid-template-columns: auto 1fr;
                    word-wrap: none;
                    gap: 1rem;
                    user-select: all !important;
                  `}
                >
                  <time
                    css={css`
                      color: var(--dark--primary--500);
                    `}
                  >
                    [{new Date(message.shift()).toLocaleString()}]
                  </time>
                  <span key={idx}>- {message.join(' ')}</span>
                </article>
              );
            })}
      </div>
    </DialogContent>
  );
};

export default ({ device, children }) => {
  if (!device) return null;

  const [open, setOpen] = React.useState();

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      {open && <SystemLog device={device} />}
    </Dialog>
  );
};
