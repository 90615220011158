import React from 'react';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */

const styles = css`
  position: relative;
  width: 32px;
  height: 32px;

  .a-live-fixed,
  .a-live-animate {
    position: absolute;
    top: 50%;
    margin: -10px 0 0 -10px;
    left: 50%;
    width: 16px;
    height: 16px;
    background-color: var(--primary-base);
    border-radius: 100%;
  }
  .a-live-fixed[data-active='false'] {
    background: var(--red-base);
  }

  .a-live-animate {
    transform: scale(1);
    animation: pulse 1s infinite;
    transition: 1s all linear;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
`;

const LiveEventsIndicator = (props) => {
  const { active } = props;
  return (
    <div className="a-live-events" css={styles}>
      {active && <div className="a-live-animate"></div>}
      <div
        data-active={active ? 'true' : 'false'}
        className="a-live-fixed"
      ></div>
    </div>
  );
};

export default LiveEventsIndicator;
