import React from 'react';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import {
  IconAlertTriangle,
  IconAlertTriangleFilled
} from '@tabler/icons-react';
import { AuxiliaryButton } from './buttons';
import { useAckAlert } from 'views/doors/actions';
import { isWithinInterval, subDays, addDays, startOfToday } from 'date-fns';
import IsUserAllowed from './is-user-allowed';
import { dateTime } from 'utils/time';
import { useAuth } from 'providers/auth';
import IsRole from './is-role';
export function isWithinDays(date, days = 3) {
  const today = startOfToday();
  const pastDate = subDays(today, days);
  const futureDate = addDays(today, days);

  return isWithinInterval(date, { start: pastDate, end: futureDate });
}

export const ALERTS = ['reader_offline'];

export default ({ door }) => {
  const { user } = useAuth();
  let alerts = null;

  for (let i of ALERTS) if (door[i]) alerts = true;

  if (!alerts) return null;

  let reader_offline =
    typeof door.reader_offline == 'object'
      ? door.reader_offline
      : JSON.parse(door.reader_offline);

  const ackMutation = useAckAlert(door);

  return (
    <IsRole role={['A', 'V', 'I']}>
      <article
        className="door-alerts"
        css={css`
          border-bottom: 1px solid var(--neutral-12);
          h3 {
            margin-bottom: 12px;
            padding-inline: 20px;
            padding-top: 16px;
          }
        `}
      >
        <h3>Alerts</h3>

        <ul
          css={css`
            // limit to 4
            display: flex;
            align-items: start;
            flex-direction: column;
            > li {
              height: 64px;
              box-shadow: 0px -1px 0px var(--neutral-12);
              padding-inline: 20px;
              width: 100%;
              display: grid;
              grid-template-columns: auto 1fr 1fr;
              gap: 1rem;
              align-content: center;
              align-items: center;

              h4 {
                font-size: 14px;
                font-weight: 600;
                color: var(--neutral-00);
              }
              time {
                margin-top: 4px;
                display: block;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                font-style: normal;
                font-size: 12px;
                color: var(--secondary-font-color);
              }
              svg {
                color: var(--light--others--red-400-base);
              }
              button {
                margin-left: auto;
                height: 36px;
              }
            }
          `}
        >
          {reader_offline.map((ro) => (
            <li>
              <IconAlertTriangle />
              <div>
                <h4>
                  {ro.reader == 'in' ? 'Entrance' : 'Exit'} Reader Offline
                </h4>
                <time>
                  {dateTime(new Date(ro.datetime + 'Z')) ||
                    dateTime(new Date(ro.datetime))}
                </time>
              </div>

              <AuxiliaryButton
                disabled={ackMutation.isLoading}
                onClick={() =>
                  ackMutation.mutate({
                    reader: ro.reader,
                    door_token: door.door_token,
                    alert: 'reader_offline'
                  })
                }
                compact
              >
                Acknowledge
              </AuxiliaryButton>
            </li>
          ))}
        </ul>
      </article>
    </IsRole>
  );
};
