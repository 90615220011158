import React from 'react';
import Template from 'base/template';
import { useAuth } from 'providers/auth';
import { useQueryWrapper } from 'utils/ajax';
import { useNotFound } from 'hooks/not-found-hook';
import { useParams } from 'react-router-dom';
import Content from 'base/content';
import PageTitle from 'components/page-title';
import Breadcrumbs from 'components/breadcrumbs';
import styled from '@emotion/styled';

import { Section } from 'views/dashboard/user-dashboard';

import { css } from '@emotion/react';

/** @jsxImportSource @emotion/react */
import {
  ArrowClockwise,
  ArrowsFullscreen,
  Thermometer,
  ThermometerHalf
} from 'react-bootstrap-icons';
import { AuxiliaryButton, IconButton } from 'components/buttons';
import { queryCache, useQueryClient } from 'react-query';
import PeriodSelect from 'views/reports/period-select';
import Sensor from 'api/sensor';
import TimeSeriesChart from 'views/sensors/time-series-chart';
import { DialogTrigger, DialogContent, Dialog } from 'components/dialog.js';
import ChartZoom from 'components/chart-zoom';
import ChartInterval from 'components/chart-interval';
import ChartRefresh from 'components/chart-refresh';
import ChartTopics from 'components/chart-topics';
import Chip from 'components/chip';
import SidebarEmptyHolder from 'components/sidebar-empty-holder';
import Spinner from 'components/spinner';
import ChartType from 'components/chart-type';
import ChartSeriesSelector from 'components/chart-series-selector';
import {
  TabItem,
  TabGroup,
  TabPanel,
  TabPanels,
  TabList
} from 'components/tabs';

import CurrentTopics from 'views/sensors/current-topics';
import { IconAccessPointOff, IconAlertTriangle } from '@tabler/icons-react';

const EmptyTopicsHolder = (props) => {
  return (
    <div
      css={css`
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      `}
    >
      <SidebarEmptyHolder
        icon={IconAccessPointOff}
        title="No Data found"
        subtitle="not enough data was collected or sensor is not connected"
      />
    </div>
  );
};

const Main = styled('main')`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ChartDialog = (props) => {
  const [filter, setFilter] = React.useState({ interval: 30 });
  const [refresh, setRefresh] = React.useState();
  const [topics, setTopics] = React.useState([]);
  const [type, setType] = React.useState('line');

  const sensor = props.sensor;
  let snsTopics = props.topics;
  const sensorTopics = snsTopics.map((t) => {
    /// interploatyte the metada if array or object for old compatibility

    let meta;

    if (Array.isArray(sensor?.metadata))
      meta = sensor?.metadata?.find((m) => m.topic == t);
    //object
    else meta = sensor?.metadata?.[t];

    return { topic: t, name: meta?.name, unit: meta?.unit };
  });

  React.useEffect(
    () => setTopics([{ value: props.topic, label: props.topic }]),
    [props.topic]
  );

  const queryClient = useQueryClient();

  const queries = topics.map((topic) => ({
    topic: topic.value,
    sensor,
    filter
  }));

  const handleRemoveValue = (e) => {
    let arr = topics.filter((val) => val.value !== e);
    setTopics(arr);
  };

  React.useEffect(() => {
    if (queries.length > 1) setType('line');
  }, [queries.length]);

  return (
    <Dialog>
      <DialogTrigger
        css={css`
          padding: 0.2rem;
        `}
      >
        <ArrowsFullscreen size={18} />
      </DialogTrigger>
      <DialogContent
        //portalOpacity={1}
        css={css`
          height: 80vh;
          width: 80vw;
          display: flex;
          flex-direction: column;
          .echarts-for-react {
            pointer-events: auto;
          }
          > section.dialog-content {
            display: flex;
            flex-direction: column;
          }
        `}
      >
        <header
          css={css`
            display: flex;
            padding: 0.5rem;
            width: 100%;
            gap: 1rem;
            justify-content: end;
            margin-left: auto;
            align-items: center;
            .period-input {
              width: 300px;
            }
            > label {
              margin-right: auto;
            }
          `}
        >
          <ChartSeriesSelector
            seriesTypeList={['line', 'candlestick']}
            type={type}
            setType={setType}
            isDisabled={queries.length > 1}
          />
          <ChartTopics
            topicList={sensorTopics}
            topics={topics}
            setTopics={setTopics}
          />
          <ChartInterval filter={filter} setFilter={setFilter} />
          <PeriodSelect
            globalFilter={filter}
            data-compact
            selection={filter}
            setSelection={setFilter}
            showValue={true}
          />
          <AuxiliaryButton
            compact
            onClick={() => {
              const queryKeysToReset = topics.map((q) => [
                'sensor',
                props.sensor.id,
                q.value,
                filter
              ]);

              queryKeysToReset.forEach((i) => queryClient.resetQueries(i));
            }}
          >
            <ArrowClockwise size={24} />
          </AuxiliaryButton>
          <ChartRefresh refresh={refresh} setRefresh={setRefresh} />
        </header>
        <div
          css={css`
            margin-bottom: 1rem;
            display: flex;
            gap: 1rem;
            align-items: center;
            flex-wrap: wrap;
          `}
        >
          {topics.map((t) => (
            <Chip
              label={t.label}
              key={t.value}
              onClick={() => handleRemoveValue(t.value)}
            />
          ))}
        </div>
        <TimeSeriesChart
          type={type}
          queryParams={queries}
          refetchInterval={refresh}
        />
      </DialogContent>
    </Dialog>
  );
};

export default (props) => {
  const { user } = useAuth();
  const { id } = useParams();

  const [sensor, setSensor] = React.useState(props?.location?.state?.sensor);

  const query = useQueryWrapper(['sensor', id], () => Sensor.get(id), {
    enabled: !sensor
  });

  const { data: topics, isLoading } = useQueryWrapper(
    ['sensor', id, 'getTopicList'],
    () => Sensor.getTopicList(id),
    { enabled: !!sensor }
  );

  React.useEffect(() => {
    if (!query.data) return;
    setSensor(query.data);
  }, [query.data]);

  useNotFound(id, query, props);

  const [filter, setFilter] = React.useState({ interval: 30 });
  const queryClient = useQueryClient();

  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const sensorTopics = topics?.map((t) => {
    let meta = '';
    if (Array.isArray(sensor?.metadata))
      meta = sensor?.metadata?.find((m) => m.topic == t);
    //object
    else meta = sensor?.metadata?.[t];

    return { topic: t, name: meta?.name, unit: meta?.unit };
  });

  const handleInputChange = (inputValue) => {
    const filteredList = inputValue
      ? topics.filter((item) =>
          item?.toLowerCase()?.includes(inputValue?.toLowerCase())
        )
      : topics;
    setFilteredTopicList(filteredList);
    setInputValue(inputValue);
  };

  const [filteredTopicList, setFilteredTopicList] = React.useState();
  const [inputValue, setInputValue] = React.useState();

  React.useEffect(() => {
    if (!topics) return;

    setFilteredTopicList(topics);
  }, [topics]);

  return (
    <Template {...props}>
      <Content {...props}>
        <PageTitle>{sensor?.name}</PageTitle>
        <Breadcrumbs crumbs={props.crumbs} />
        {(query.isLoading || isLoading) && <Spinner />}

        {!query.isLoading &&
          sensor &&
          topics &&
          (topics?.length > 0 ? (
            <Main>
              <TabGroup
                selectedIndex={selectedIndex}
                onChange={setSelectedIndex}
              >
                <TabList
                  css={css`
                    width: 100%;
                    box-shadow: unset;
                    gap: 2rem;
                    padding: 0px 16px;
                    background: transparent;
                    box-shadow: inset 0px -1px 0px var(--neutral-10);
                    [role='tab'] {
                      min-width: unset;
                    }
                  `}
                >
                  <TabItem title="Current">Current</TabItem>
                  <TabItem title="History">History</TabItem>

                  <div
                    css={css`
                      display: flex;
                      margin-left: auto;
                      gap: 1rem;
                      > button:first-of-type {
                        order: 3;
                      }
                    `}
                  >
                    <AuxiliaryButton
                      compact
                      onClick={() => {
                        queryClient.resetQueries(['sensor', sensor?.id]);
                      }}
                    >
                      <ArrowClockwise size={24} />
                    </AuxiliaryButton>
                    <ChartTopics
                      menuIsOpen={false}
                      isMulti={false}
                      onKeyDown={(event) => {
                        if (event.key === 'Escape') handleInputChange('');
                      }}
                      itemComponents={{ DropdownIndicator: () => null }}
                      inputValue={inputValue}
                      isClearable={true}
                      onChange={() => {}}
                      onInputChange={handleInputChange}
                      topicList={sensorTopics}
                      placeholder="Type to search by topic..."
                    />

                    {selectedIndex == 1 && (
                      <div
                        css={css`
                          .period-input {
                            width: 400px;
                          }
                          margin-left: auto;
                          display: flex;
                          align-items: center;
                          gap: 0.5rem;
                        `}
                      >
                        <PeriodSelect
                          globalFilter={filter}
                          data-compact
                          selection={filter}
                          setSelection={setFilter}
                          showValue={true}
                        />
                      </div>
                    )}
                  </div>
                </TabList>
                <TabPanels>
                  <TabPanel
                    css={css`
                      flex: 1;
                      margin-top: 1rem;
                      overflow: auto;
                      background: transparent;
                    `}
                  >
                    <CurrentTopics sensor={sensor} />
                  </TabPanel>
                  <TabPanel
                    css={css`
                      margin-top: 1rem;
                      background: transparent;
                      display: grid;
                      flex: 1;
                      gap: 1rem;
                      grid-template-columns: repeat(5, 1fr);
                      grid-template-rows: 1fr 1fr 1fr 1fr;
                      overflow: auto;
                    `}
                  >
                    {filteredTopicList?.map((t) => (
                      <Section>
                        <header
                          data-alert={sensor?.alert_state?.includes(t)}
                          css={css`
                            &[data-alert='true'] {
                              background: var(--red-v-02) !important;
                              color: var(--red-v-01) !important;
                            }
                            background: #fafafa;
                            display: flex;
                            padding: 0.5rem;
                            gap: 1rem;
                            width: 100%;
                            align-items: center;
                            > button {
                              margin-left: auto;
                            }
                          `}
                        >
                          {sensor?.alert_state?.includes(t) && (
                            <span>
                              <IconAlertTriangle color="var(--red-v-01)" />
                            </span>
                          )}
                          <label>{t}</label>
                          <ChartDialog
                            topic={t}
                            sensor={sensor}
                            topics={topics}
                          />
                        </header>
                        <TimeSeriesChart
                          queryParams={[
                            {
                              topic: t,
                              sensor,

                              filter
                            }
                          ]}
                          slim
                        />
                      </Section>
                    ))}
                  </TabPanel>
                </TabPanels>
              </TabGroup>
            </Main>
          ) : (
            <EmptyTopicsHolder />
          ))}
      </Content>
    </Template>
  );
};
