import React from 'react';
import EventListFilters from 'components/event-list-filters';
import WhoseInList from 'components/whosein-list';
import { useInfiniteQuery } from 'react-query';
import Person from 'api/person';
const FILTERS = [
  {
    value: '',
    label: 'Today'
  },
  {
    value: '-7 day',
    label: 'Week'
  },
  {
    value: '-30 day',
    label: 'Month'
  }
];
const DoorWhoseinList = ({ door }) => {
  const [filters, setFilters] = React.useState();
  const [selection, setSelection] = React.useState('');

  React.useEffect(() => {
    setFilters((prev) => ({ ...prev, time: selection }));
  }, [selection]);

  React.useEffect(() => {
    setFilters({
      ...filters,
      door_token: door?.token
    });
  }, [door]);

  const fetchEvents = ({ pageParam = 1 }) =>
    Person.getRecentEntries({ ...filters, page: pageParam });
  const query = useInfiniteQuery([`whosein`, filters], fetchEvents, {
    enabled: Boolean(filters),
    getNextPageParam: (lastPage, pages) => {
      return pages.length < lastPage.last_page ? pages.length + 1 : undefined;
    }
  });

  return (
    <>
      <EventListFilters
        filters={FILTERS}
        selection={selection}
        setSelection={setSelection}
      />
      <WhoseInList query={query} />
    </>
  );
};

export default DoorWhoseinList;
