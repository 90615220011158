import React from 'react';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import { X } from 'react-bootstrap-icons';

const styles = (props) => css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 8px;
  gap: 8px;

  background: var(--badge-background-color);
  /* Secundary/secundary-v-03 */

  border: 1px solid var(--chip-border-color);
  box-sizing: border-box;
  border-radius: 4px;
  span.label {
    font-size: 14px;
    /* Neutrals/neutral-01 */
    font-weight: 500;
    color: ${props.color || 'var(--neutral-00'};
  }

  button {
    margin-left: auto;
    border: 1px solid var(--chip-border-color);
    color: ${props.color || '#267dff'};
  }
`;

const Chip = (props) => {
  return (
    <article css={styles(props)}>
      <span className="label">{props.label} </span>

      <button
        onClick={(e) => {
          if (typeof props.onClick == 'function') props.onClick();
        }}
      >
        <X size={24} />
      </button>
    </article>
  );
};

export default Chip;
