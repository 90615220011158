import React from 'react';
import Player from './player';
import usePlayerControls from 'components/use-player-controls';

const LivePlayer = ({ host, autoPlay = false, ...otherProps }) => {
  const { waiting, setPlay, setRefresh, play, refresh, onPlaying, onLoading } =
    usePlayerControls({ host, autoPlay: autoPlay });

  React.useEffect(() => {
    setPlay(autoPlay);

    return () => setPlay(false);
  }, [host]);

  return (
    <article id="camera-player">
      <Player
        live={true}
        play={play}
        setPlay={setPlay}
        setRefresh={setRefresh}
        refresh={refresh}
        host={host}
        autoPlay={false}
        {...otherProps}
      />
    </article>
  );
};

export default LivePlayer;
