import React from 'react';
import Template from 'base/template';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Credential from 'api/credential';
import { columns } from 'views/credentials/table-columns';
//import Sidebar from "views/doors/sidebar";
import IndexContent from 'base/index-content';
import { useHistory } from 'react-router';
import { Option as DepartmentOption } from 'components/form/department-select';
import AsyncSelect from 'components/headless-select/async';

import {
  BookmarkCheckFill,
  BookmarkX,
  CheckLg,
  PersonCheck,
  ShieldExclamation,
  ShieldSlash,
  XLg,
  ChevronDown
} from 'react-bootstrap-icons';
import { AuxiliaryButton, Button } from 'components/buttons';
import ActionMenu from 'views/credentials/action-menu';
import { useAuth } from 'providers/auth';
import AccessLevel from 'api/access_level';
import Sidebar from 'views/credentials/sidebar';
import Department from 'api/department';
import parse from 'date-fns/parse';
import { IconCalendarOff } from '@tabler/icons-react';
import Device from 'api/device';
import ReactJson from 'react-json-view';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useQueryWrapper } from 'utils/ajax';
import { DialogContent, Dialog } from 'components/dialog.js';
import { MenuItems, MenuItem } from 'components/action-menu';

const cards = [
  {
    name: 'total',
    label: 'Total'
  },
  {
    name: 'inToday',
    label: 'In Today',
    color: 'var(--primary-base) ',
    background: 'var(--stats-backgound-green)',
    filter: { inToday: true },
    icon: BookmarkCheckFill
  },

  {
    name: 'enabled',
    label: 'Enabled',
    color: 'var(--primary-base)',
    background: 'var(--stats-background-green)',
    filter: { enabled: true },
    icon: CheckLg
  },

  {
    name: 'disabled',
    label: 'Disabled',
    color: 'var(--red-base)',
    background: 'var(--stats-background-red)',
    filter: { enabled: false },
    icon: XLg
  },
  {
    name: 'guests',
    label: 'Guests',
    icon: PersonCheck,
    filter: { guest: true }
  },

  {
    name: 'hasNoAccessLevels',
    label: 'No Access Level',
    icon: ShieldSlash,
    filter: { hasNoAccessLevels: true }
  },
  {
    name: 'hasAuthProfileOverride',
    label: 'Overrides',
    icon: ShieldExclamation,
    filter: { hasAuthProfileOverride: true }
  },
  {
    name: 'idle30Days',
    label: 'No Activity (30d)',
    title: 'No entry in past 30 days',
    color: 'var(--red-base)',
    background: 'var(--stats-background-red)',
    icon: BookmarkX,
    filter: { idle30Days: true }
  },
  {
    name: 'notActive',
    label: 'Not Yet Active',
    color: 'var(--red-base)',
    background: 'var(--stats-background-red)',
    icon: IconCalendarOff,
    filter: { notActive: true }
  }
];

export const FILTERS = [
  {
    open: true,
    id: 'recent',
    label: 'Recent Searches...',
    options: []
  },
  {
    id: 'filters',
    label: 'Filters',
    options: [
      {
        value: ['hasNoAccessLevels', true],
        label: 'No Access Level',
        name: 'No Access Level',
        type: true
      },
      {
        value: ['hasAuthProfileOverride', true],
        label: 'Auth Profile Override',
        name: 'Auth Profile Override',
        type: true
      },
      {
        value: ['inToday', true],
        label: 'In Today',
        name: 'In Today',
        type: true
      },
      {
        value: ['idle30Days', true],
        label: 'No Activity (30 days)',
        name: 'No Activity (30 days)',
        type: true
      }
    ]
  },
  {
    open: true,
    id: 'filterBy',
    label: 'Filter By...',
    options: [
      {
        label: 'Include Access Level',
        value: 'accessLevel',
        fetchFn: AccessLevel.search,
        field: 'accessLevel',
        name: ' Include Access Level',
        id: 'accessLevel'
      },

      {
        label: 'Excelude Access Level',
        value: 'excludeAccessLevel',
        fetchFn: AccessLevel.search,
        field: 'excludeAccessLevel',
        name: 'Exclude Access Level',
        id: 'excludeAccessLevel'
      },
      {
        label: 'Department',
        value: 'department',
        fetchFn: Department.search,
        option: DepartmentOption,
        field: 'department',
        name: 'Department',
        id: 'department'
      },
      {
        custom: true,
        label: 'Update Time',
        field: 'updatedSince',
        name: 'Update At',
        validate: (val) => {
          let d = parse(val, 'MM/dd/yyyy', new Date());
          return d instanceof Date && !isNaN(d);
        },
        helper: 'Insert a valid date above mm/dd/yy',
        options: [
          {
            value: 'yesterday',
            label: 'Past Day'
          },
          {
            value: '-7 days',
            label: 'Past Week'
          },
          {
            value: '-30 days',
            label: 'Past Month'
          }
        ]
      }
    ]
  }
];

const ExtraButtons = ({ setDialogOpen }) => {
  const { enforce } = useAuth();

  const history = useHistory();
  const menuStyles = css`
    display: inline-block;
    position: relative;
  `;

  return (
    <section
      css={css`
        display: flex;
        align-items: center;
        gap: 1rem;
      `}
    >
      <AuxiliaryButton compact onClick={() => history.push('credentials/bulk')}>
        Bulk Add
      </AuxiliaryButton>
      {enforce({ managing: true, role: ['AV'] }) && (
        <DropdownMenu.Root as="div" css={menuStyles}>
          <DropdownMenu.Trigger
            asChild={true}
            css={css`
              svg {
                transition: all 0.2s;
              }
              &[data-state='open'] {
                svg {
                  transform: rotate(180deg);
                }
              }
            `}
          >
            <Button
              compact
              css={css`
                margin-left: -0.5rem;
                svg {
                  width: 16px;
                  height: 16px;
                }
              `}
            >
              <ChevronDown />
            </Button>
          </DropdownMenu.Trigger>

          <MenuItems>
            <MenuItem
              onClick={() => setDialogOpen((prev) => !prev)}
              label="Data sync..."
            ></MenuItem>
          </MenuItems>
        </DropdownMenu.Root>
      )}
    </section>
  );
};

const Credentials = (props) => {
  const { enforce, user } = useAuth();
  const [dialogOpen, setDialogOpen] = React.useState();

  const [cred, setCred] = React.useState();
  const [controller, setController] = React.useState();

  const { isFetching, data, refetch, remove } = useQueryWrapper(
    ['match-crdentials', controller, cred],
    () => Credential.controllerData({ id: controller }, cred.token),
    {
      refetchOnMount: false,
      cacheTime: 0,
      enabled: false
    }
  );

  React.useEffect(() => {
    setController(null);
    setCred(null);
  }, [dialogOpen]);

  return (
    <Template {...props}>
      <IndexContent
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        newSorter
        initialFilter={{ hasSite: user.focused_site }}
        initialSorter={[
          {
            id: 'last_access_time',
            sortBy: 'newestLastAccess',
            desc: true
          }
        ]}
        fields={['relations']}
        statsQuery={Credential.stats}
        title="Credentials"
        id="credentials"
        cards={cards}
        query={Credential.search}
        columns={columns}
        addButton={
          enforce({ restrictIfCompanyUser: ['personEdit'] })
            ? 'Add a Credential'
            : false
        }
        buttons={
          enforce({ restrictIfCompanyUser: ['personEdit'] })
            ? ExtraButtons
            : false
        }
        filterOptions={FILTERS}
        actionButton={ActionMenu}
        selectable
        sidebar={Sidebar}
        multi
        export
        {...props}
      >
        <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
          <DialogContent
            css={css`
              height: 80vh;
              .dialog-content {
                overflow: hidden;
                display: flex;
                flex-direction: column;
                height: 100%;
              }
            `}
          >
            <h3
              css={css`
                margin-bottom: 1rem;
              `}
            >
              Match Credential Data
            </h3>
            <section
              css={css`
                display: flex;
                align-items: center;
                gap: 2rem;
                align-items: end;
              `}
            >
              <AsyncSelect
                title="Choose Device"
                data-compact
                name="devices"
                fetchFn={Device.search}
                closeMenuOnSelect={true}
                fetchFilters={{ type: 'controller' }}
                onChange={(e) => setController(e?.id)}
              />
              <AsyncSelect
                title="Choose Credential"
                data-compact
                closeMenuOnSelect={true}
                name="credentials"
                idx="token"
                label="Description"
                fetchFn={Credential.search}
                onChange={(e) => setCred(e)}
              />
              <AuxiliaryButton
                data-loading={isFetching}
                disabled={isFetching || !controller || !cred}
                compact
                onClick={() => {
                  remove();
                  refetch();
                }}
              >
                Match
              </AuxiliaryButton>
            </section>
            {/* <div>As Of: {new Date(Number(dataUpdatedAt)).toISOString()}</div> */}
            <section
              css={css`
                overflow: hidden;
                margin-top: 1rem;
                display: flex;
                gap: 2rem;
                overflow: auto;
                height: 100%;

                > * {
                  flex: 1;
                  overflow: auto;
                }
                .react-json-view {
                  font-family: unset !important;
                  .data-type-label {
                    color: var(--neutral-03);
                  }
                }
              `}
            >
              <ReactJson
                iconStyle="circle"
                src={data?.Credential}
                name={null}
              />
              <ReactJson iconStyle="circle" src={cred || {}} name={null} />
            </section>
          </DialogContent>
        </Dialog>
      </IndexContent>
    </Template>
  );
};

export default Credentials;
