import { callAPI } from 'utils/ajax';

const Company = {
  search(input = {}) {
    return callAPI('GET', `/company`, input);
  },

  save(input = {}, id = '') {
    return callAPI(id ? 'PUT' : 'POST', `/company/${id}`, input);
  },

  delete(id) {
    return callAPI('DELETE', `/company/${id}`);
  },

  stats(input = {}) {
    return callAPI('GET', `/company/stats`, input, { keepData: true });
  },

  manage(id) {
    return callAPI('POST', `/company/manage/` + id);
  },

  stopManaging() {
    return callAPI('POST', `/company/stopManaging`);
  },

  checkSync(companyId) {
    return callAPI('POST', `/company/checkSync/` + companyId);
  },

  getDailyCount(input) {
    return callAPI('GET', `/company/dailyCount`, input);
  },

  exportBillingObject(input) {
    return callAPI('GET', `/company/exportBillingObjects`, input);
  }
};

export default Company;
