import React from "react";
import { css } from "@emotion/react";

import HealthTimelineHolder from "components/charts/health-timeline-holder";
const styles = css`
  grid-area: stats;
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0px 16px;
  gap: 24px;
`;

const DeviceStats = ({ device }) => {
  return (
    <section className="stats" css={styles}>

      <HealthTimelineHolder device_id={device.device_id} />

    </section>
  );
};

export default DeviceStats;
