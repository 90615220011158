import React from "react";
import Template from "base/template";

import LicensePlate from "api/license_plate";
import { columns } from "views/license-plates/table-columns";
import IndexContent from "base/index-content";
import ActionMenu from "views/license-plates/action-menu";
import { XLg, Check, Truck } from "react-bootstrap-icons";
import Sidebar from "views/license-plates/sidebar";
const cards = [
  {
    name: "total",
    label: "Total",
  },
  {
    name: "blocked",
    label: "Blocked",
    color: "var(--red-base)",
    background: "var(--stats-background-red)",
    filter: { enabled: "0" },
    icon: XLg,
  },
  {
    name: "allowed",
    label: "Allowed",
    color: "var(--primary-base)",
    background: "var(--stats-background-green)",
    filter: { enabled: "1" },
    icon: Check,
  },

  // {
  //   name: "offline",
  //   label: "Offline",
  //   color: "var(--red-base)",
  //   background: "var(--stats-background-red)",
  //   filter: { online: "0" },
  //   icon: Power,
  // },
];

const LicensePlates = (props) => {
  return (
    <Template {...props}>
      <IndexContent
        statsQuery={LicensePlate.getStats}
        title="license-plates"
        id="license-plates"
        cards={cards}
        query={LicensePlate.search}
        columns={columns}
        addButton="Add A License Plate"
        sidebar={Sidebar}
        actionButton={ActionMenu}
        {...props}
      ></IndexContent>
    </Template>
  );
};

export default LicensePlates;
