import React from 'react';
import Template from 'base/template';

import Vendor from 'api/vendor';
import { columns } from 'views/vendors/table-columns';
import IndexContent from 'base/index-content';
import ActionMenu from 'views/vendors/action-menu';

const cards = [
  {
    name: 'total',
    label: 'Total'
  }
];

const Vendors = (props) => {
  return (
    <Template {...props}>
      <IndexContent
        statsQuery={Vendor.stats}
        title="Vendors"
        id="vendors"
        cards={cards}
        query={Vendor.search}
        columns={columns}
        addButton="Add a Vendor"
        selectable
        actionButton={ActionMenu}
        sorting={{
          alpha: 'A-Z',
          newestFirst: 'Newest First',
          oldestFirst: 'Oldest First',
          mostDevices: 'Most Devices',
          mostInstallers: 'Most Installers',
          mostCompanies: 'Most Companies'
        }}
        {...props}
      ></IndexContent>
    </Template>
  );
};

export default Vendors;
