/** @jsxImportSource @emotion/react */

import React from "react";
import { TextField, CheckBox } from "components/form";
import { Heading } from "components/form";
import { Field, useFormikContext } from "formik";
import { css } from "@emotion/react";
import timezoneSelect from "components/form/timezone-select";
const styles = css`
  display: grid;
  gap: 16px;
  grid-template-columns: var(--form-column-width) var(--form-column-width);
  padding-top: 24px;
  min-width: 650px;

  .access-levels {
    position: static;
    padding: 24px;
    background: var(--orange-v-01);
    border-radius: 4px;
    input[type="checkbox"] {
      color: orange;
    }
    grid-column: 1/-1;
  }
`;
const CompanySecurity = ({ company }) => {
  const { values, setFieldValue } = useFormikContext();

  return (
    <section css={styles}>
      <CheckBox
        name="mfa_enforce"
        label="Enforce MFA on all new accounts "
        checked={values.mfa_enforce}
        onChange={(e) => setFieldValue("mfa_enforce", e.target.checked)}
      />
      <br />

      {company && Boolean(parseInt(company.mfa_enforce)) != values.mfa_enforce && (
        <div
          css={css`
            margin-top: 8px;
            font-size: 11px;
          `}
        >
          Changing MFA enforce level will only apply to newly created accounts.
        </div>
      )}
    </section>
  );
};

export default CompanySecurity;
