import React from 'react';
import Template from 'base/template';

import { columns } from 'views/audio-clips/table-columns';
import IndexContent from 'base/index-content';
import ActionMenu from 'views/audio-clips/action-menu';
import { useAuth } from 'providers/auth';

import AudioClip from '../../api/audio-clip';

const cards = [
  {
    name: 'total',
    label: 'Total'
  }
];

const AudioClips = (props) => {
  return (
    <Template {...props}>
      <IndexContent
        statsQuery={AudioClip.stats}
        title="audio-clips"
        id="audio-clips"
        cards={cards}
        query={AudioClip.search}
        columns={columns}
        addButton="Add an Audio Clip"
        actionButton={ActionMenu}
        {...props}
      ></IndexContent>
    </Template>
  );
};

export default AudioClips;
