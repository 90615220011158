import { Card } from './card';
import { IconArticle } from '@tabler/icons-react';
import { Button } from 'components/buttons';
import DeviceSystemLogModal from '../device-system-log-modal';

export default ({ device }) => {
  return (
    <Card classname="system-log">
      <div className="icon">
        <IconArticle />
      </div>
      <div className="content">
        <h2>System Log</h2>
        <span className="description">View Live Feed of system Log</span>
      </div>
      <div className="action">
        <DeviceSystemLogModal device={device}>
          <Button compact>View</Button>
        </DeviceSystemLogModal>
      </div>
    </Card>
  );
};
