import React from 'react';

import EntryCard from './entry-card';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import EmptyHolder from './empty-holder';
import Spinner from './spinner';
import LoadMoreButton from './load-more-button';
import * as Tooltip from '@radix-ui/react-tooltip';
import { QuestionCircle } from 'react-bootstrap-icons';
import { ReactComponent as EmptyPerson } from 'assets/empty-icons/empty-person.svg';

export const WhoseinTooltip = () => {
  return (
    <Tooltip.Provider>
      <Tooltip.Root>
        <Tooltip.Trigger asChild={true}>
          <QuestionCircle size={16} />
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content asChild={true}>
            <div
              css={css`
                z-index: 200;
                display: flex;

                padding: 0.5rem;

                background: var(--neutral-09);
                box-shadow: 0px 2px 6px 4px rgba(29, 32, 35, 0.4);
                border-radius: 4px;
              `}
            >
              List of recent entries. <br />
              Each person latest entry will be shown
              <Tooltip.Arrow
                css={css`
                  fill: var(--neutral-09);
                `}
              />
            </div>
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};

const styles = css`
  width: 100%;
  display: inline-flex;
  position: relative;
  flex-direction: column;
  gap: 24px;
  > div {
    overflow-y: auto;
  }

  .spinner {
    display: block;
    margin: 24px auto;
  }

  svg {
    width: 80px;
    height: 80px;
  }
`;

const WhoseInList = ({ query, ...props }) => {
  return (
    <article className="whosein-list" css={styles}>
      <div>
        {query.status == 'loading' && <Spinner />}
        {query.data && query.data.pages?.[0]?.data.length == 0 && (
          <EmptyHolder icon={EmptyPerson} label="No recent entries" />
        )}

        {query.data &&
          query.data.pages.map((page, i) =>
            page.data.map((entry, index) => (
              <EntryCard key={index} data={entry} {...props} />
            ))
          )}
        {query.hasNextPage && query.data.pages?.[0]?.data.length > 0 && (
          <LoadMoreButton
            hasNextPage={query.hasNextPage}
            fetchNextPage={query.fetchNextPage}
            isFetchingNextPage={query.isFetchingNextPage}
          />
        )}
      </div>
    </article>
  );
};

export default WhoseInList;
