import { eachDayOfInterval } from 'date-fns';
import { subDays } from 'date-fns';
export function createDatesArray(interval = 30) {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const today = new Date();
  const dates = [];

  for (let i = 0; i < interval; i++) {
    const date = new Date(today.getTime() - i * 24 * 60 * 60 * 1000);
    const options = {
      timeZone: timeZone,

      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    };
    const dateString = date
      .toLocaleDateString('en-US', options)
      .replace(/\//g, '-')
      .replace(/^(\d{2})-(\d{2})-(\d{4})$/, '$3-$1-$2');

    dates.push(dateString);
  }

  return dates.reverse();
}

export function createEachDayOfInterval(
  startDate = subDays(new Date(), 30),
  endDate = new Date()
) {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const datesArray = eachDayOfInterval({
    start: new Date(startDate),
    end: new Date(endDate)
  });
  const dates = [];

  for (let i of datesArray) {
    const date = i;
    const options = {
      timeZone: timeZone,

      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    };
    const dateString = date
      .toLocaleDateString('en-US', options)
      .replace(/\//g, '-')
      .replace(/^(\d{2})-(\d{2})-(\d{4})$/, '$3-$1-$2');

    dates.push(dateString);
  }

  return dates;
}

export function averageWithoutZero(arr) {
  const filteredArr = arr.filter((val) => val !== 0);
  const sum = filteredArr.reduce((acc, val) => acc + val, 0);
  const average = sum / filteredArr.length;

  return sum ? average : 0;
}

export function isEmptyObject(obj = {}) {
  if (obj)
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false; // If there's at least one property, the object is not empty
      }
    }
  return true; // If no properties are found, the object is empty
}

export function getPast7Days(timeZone = 'UTC') {
  const daysAgo = 7;
  const today = new Date();
  const past7Days = [];

  for (let i = 0; i < daysAgo; i++) {
    const date = new Date(today);
    date.setDate(today.getDate() - i);
    past7Days.push(date.toLocaleDateString('en-US', { timeZone }));
  }

  return past7Days;
}

export function getPast60Minutes() {
  const currentDate = new Date();
  const datesArray = [];

  for (let i = 1; i < 60; i++) {
    const pastDate = new Date(currentDate);
    pastDate.setMinutes(currentDate.getMinutes() - i);
    pastDate.setSeconds(0);
    pastDate.setMilliseconds(0);
    datesArray.push(pastDate.toISOString());
  }

  return datesArray.reverse();
}
