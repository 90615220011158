import React from 'react';
import Template from 'base/template';

import { columns } from 'views/sensors/table-columns';
//import Sidebar from 'views/sensors/sidebar';
import IndexContent from 'base/index-content';
import ActionMenu from 'views/sensors/action-menu';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import { MenuItem, MenuWrapper } from 'components/action-menu';
import { Menu } from '@headlessui/react';

import { MenuItems } from 'components/action-menu';
import { AuxiliaryButton, Button } from 'components/buttons';
import { ExclamationCircle, PlugFill, Power } from 'react-bootstrap-icons';
import { useAuth } from 'providers/auth';
import Site from 'api/site';
import Company from 'api/company';

import parse from 'date-fns/parse';
import Sensor from 'api/sensor';
import Sidebar from 'views/sensors/sidebar';
import { ReactComponent as SensorEmptyIcon } from 'assets/empty-icons/empty-sensor.svg';
import { IconAccessPoint } from '@tabler/icons-react';

const Sensors = (props) => {
  const { enforce, user } = useAuth();

  const cards = React.useMemo(
    () => [
      {
        name: 'total',
        label: 'Total'
      },
      {
        name: 'alerts',
        label: 'alerts',
        color: 'var(--red-base)',
        background: 'var(--stats-background-red)',
        filter: { alerts: 1 },
        icon: IconAccessPoint
      },
      {
        name: 'offline',
        label: 'Offline',
        color: 'var(--red-base)',
        background: 'var(--stats-background-red)',
        filter: { online: 0 },
        icon: Power
      }
    ],
    [user]
  );

  const FILTERS = React.useMemo(
    () => [
      {
        id: 'recent',
        label: 'Recent Searches...',
        options: []
      },

      {
        open: true,
        id: 'filterBy',
        label: 'Filter By...',
        options: [
          {
            label: 'Site',
            value: 'site',
            fetchFn: Site.search,
            field: 'siteId',
            name: 'Site',

            id: 'site'
          },
          {
            visible: () => enforce({ reqNoCompany: true }),
            label: 'Company',
            value: 'company',
            fetchFn: Company.search,
            field: 'company',
            name: 'Company',
            id: 'company'
          },
          {
            custom: true,
            label: 'Date Inserted ',
            field: 'insertDateFrom',
            name: 'Date Inserted',
            id: 'company',
            validate: (val) => {
              let d = parse(val, 'MM/dd/yyyy', new Date());

              return d instanceof Date && !isNaN(d);
            },
            helper: 'Insert a valid date above mm/dd/yy',
            options: [
              {
                value: 'yesterday',
                label: 'Past Day'
              },
              {
                value: '-7 days',
                label: 'Past Week'
              },
              {
                value: '-30 days',
                label: 'Past Month'
              }
            ]
          }
        ]
      }
    ],
    [user]
  );

  return (
    <Template {...props}>
      <IndexContent
        initialFilter={{ siteId: user.focused_site }}
        statsQuery={Sensor.stats}
        title="Sensor"
        id="sensors"
        cards={cards}
        query={Sensor.search}
        columns={columns}
        sidebar={Sidebar}
        filterOptions={FILTERS}
        selectable
        actionButton={ActionMenu}
        addButton={enforce({ managing: true }) ? 'Add A Sensor' : false}
        emptyIcon={SensorEmptyIcon}
        {...props}
      ></IndexContent>
    </Template>
  );
};

export default Sensors;
