import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
/** @jsxImportSource @emotion/react */
import * as Collapsible from '@radix-ui/react-collapsible';
import PopoverContent, {
  Popover,
  PopoverTrigger,
  PopoverPortal
} from 'components/popover/popover-content';
import {
  CameraVideo,
  ChevronDown,
  DoorClosed,
  Funnel,
  Sliders,
  Wifi,
  WifiOff
} from 'react-bootstrap-icons';
import { useMap } from 'react-leaflet';
import { IconButton } from 'components/buttons';
const Filters = styled('section')`
  background: #ffffff;
  width: 10vw;
  border-radius: 8px;

  svg {
    padding: 2px;
    transition: all 0.2s;
  }
  > div {
    &[data-state='open'] {
      svg.dropdown {
        transform: rotate(180deg);
      }
    }
  }
  h3 {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    text-transform: uppercase;
    width: 100%;
    color: #7f8795;
  }
  h3 {
    display: flex;
    width: 100%;
    padding: 1rem;
    border-bottom: 1px solid #eceff3;
    > svg {
      margin-left: auto;
    }
  }
`;

const FilterItem = styled('label')`
  display: flex;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  align-items: center;
  gap: 1rem;
  span {
    font-weight: 600;
    font-size: 12px;

    /* identical to box height */

    color: #1d2023;
  }
  svg {
    width: 24px;
    height: 24px;
  }
  input[type='checkbox'] {
    height: 16px;
    width: 16px;
    min-width: 16px;
    min-height: 16px;
    margin-left: auto;
  }
`;

export default ({
  filters,
  setFilters,
  filterOpen,
  setFilterOpen,
  markerLayerRef: ref,
  baseLayers,
  setBaseLayers
}) => {
  const map = useMap();

  React.useEffect(() => {
    if (baseLayers.length == 0) return;
    if (!filters.showBaseLayer) {
      baseLayers.forEach((l) => map.removeLayer(l));
    }
    if (filters.showBaseLayer) {
      map.addLayer(baseLayers[0]);
    }
  }, [filters.showBaseLayer, baseLayers]);

  return (
    <Popover>
      <PopoverTrigger>
        <IconButton>
          <Funnel />
        </IconButton>
      </PopoverTrigger>
      <PopoverPortal>
        <PopoverContent
          css={css`
            width: auto;
          `}
        >
          <Filters>
            <h3>Filters</h3>
            <FilterItem for="doors">
              <DoorClosed />
              <span>Doors</span>
              <input
                id="doors"
                checked={filters?.doors}
                type="checkbox"
                onChange={(e) => {
                  setFilters((prev) => ({
                    ...prev,
                    doors: e.target.checked
                  }));
                }}
              />
            </FilterItem>
            <FilterItem for="auxiliaries">
              <Sliders />
              <span>Auxiliaries</span>
              <input
                id="auxiliaries"
                checked={filters?.auxiliaries}
                type="checkbox"
                onChange={(e) => {
                  setFilters((prev) => ({
                    ...prev,
                    auxiliaries: e.target.checked
                  }));
                }}
              />
            </FilterItem>
            <FilterItem for="cameras">
              <CameraVideo />
              <span>Cameras</span>
              <input
                id="cameras"
                checked={filters?.cameras}
                type="checkbox"
                onChange={(e) => {
                  setFilters((prev) => ({
                    ...prev,
                    cameras: e.target.checked
                  }));
                }}
              />
            </FilterItem>
            <h3>Status</h3>
            <FilterItem for="online">
              <Wifi />
              <span>Online</span>
              <input
                id="online"
                checked={filters?.online}
                type="checkbox"
                onChange={(e) => {
                  setFilters((prev) => ({
                    ...prev,
                    online: e.target.checked
                  }));
                }}
              />
            </FilterItem>
            <FilterItem for="offline">
              <WifiOff />
              <span>Offline</span>
              <input
                id="offline"
                checked={filters?.offline}
                type="checkbox"
                onChange={(e) => {
                  setFilters((prev) => ({
                    ...prev,
                    offline: e.target.checked
                  }));
                }}
              />
            </FilterItem>
            <h3>View</h3>
            <FilterItem for="tooltip">
              <span>Show Tooltips ?</span>
              <input
                id="tooltip"
                checked={filters?.tooltip}
                type="checkbox"
                onChange={(e) => {
                  setFilters((prev) => ({
                    ...prev,
                    tooltip: e.target.checked
                  }));
                }}
              />
            </FilterItem>
            <FilterItem for="showBaseLayer">
              <span>Show Street layer</span>
              <input
                id="showBaseLayer"
                checked={filters?.showBaseLayer}
                type="checkbox"
                onChange={(e) => {
                  setFilters((prev) => ({
                    ...prev,
                    showBaseLayer: e.target.checked
                  }));
                }}
              />
            </FilterItem>
          </Filters>
        </PopoverContent>
      </PopoverPortal>
    </Popover>
  );
};
