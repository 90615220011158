import 'css/sidebar.css';
import React from 'react';
import { css } from '@emotion/react';

/** @jsxImportSource @emotion/react */
import { useQueryWrapper } from 'utils/ajax';
import { useHeadlessTable } from 'providers/headless-table-provider';

import EventSelect from 'components/event-select';
import PeriodSelect from 'views/reports/period-select';

import { CheckBox } from 'components/form';
import TableReloadButton from 'components/table/table-reload-button';
import ColumnPicker from 'components/column-picker';
import { AuxiliaryButton } from 'components/buttons';
import Event from 'api/event';
import IsEventResotred from './is-event-restored';
import TableFilter from 'components/headless-table-filter';

const articleStyles = css`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr auto;
  height: fit-content;
  align-items: center;
  > * {
    flex: 1;
  }
  column-gap: 3rem;
  gap: 0 16px;

  padding: 24px;

  background: var(--neutral-09);
  .table-filter-container {
    width: auto;
    min-width: auto;
  }

  header {
    h1 {
      padding: 24px;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 100%;
    }
  }
  section.filters {
    footer {
      display: flex;
      gap: 16px;
    }
  }
`;

const Filters = (props) => {
  const { setGlobalFilter, state, dispatch, tableState } = useHeadlessTable();
  const [filters, setFilters] = React.useState({});
  const { data } = useQueryWrapper(['events', 'stats'], () => Event.stats());

  return (
    <article css={articleStyles}>
      {/* <AsyncSelect
        simple
        placeholder="Site..."
        name="site"
        isSearchable={true}
        data-compact
        fetchFn={Site.search}
        closeMenuOnSelect={true}
        onChange={(e) => {
          setGlobalFilter((prev) => ({ ...prev, site_id: e?.id }));
          setFilters((prev) => ({ ...prev, site: e }));
        }}
        value={state.globalFilter.site_id ? filters.site : 'null'}
      /> */}

      <EventSelect
        css={css``}
        data-compact
        isMulti={false}
        category={true}
        level={true}
        reason={true}
        menuIsOpen={undefined}
        fixedMenu={false}
        closeMenuOnSelect={true}
        isClearable={true}
        value={
          state.globalFilter.categoty ||
          state.globalFilter.type ||
          state.globalFilter.reason ||
          state.globalFilter.level
            ? filters.event
            : null
        }
        onChange={(e) => {
          setFilters((prev) => ({ ...prev, event: e }));

          setGlobalFilter((prev) => ({
            ...prev,

            category: e?.category ? e?.value : undefined,
            type: e?.type ? e?.value : undefined,
            level: e?.level ? e?.value : undefined,
            reason: e?.reason ? e?.value : undefined
          }));
        }}
      />

      <PeriodSelect
        globalFilter={state.globalFilter}
        data-compact
        setSelection={setGlobalFilter}
        selection={state.globalFilter}
        to={state.globalFilter.to ? filters.to : ''}
        from={state.globalFilter.from ? filters.from : ''}
      />

      <TableFilter {...props} />

      <section
        css={css`
          display: flex;
          margin-left: auto;
          gap: 0.5rem;
        `}
      >
        <TableReloadButton compact />
        <ColumnPicker />
        <AuxiliaryButton
          compact
          data-active={tableState.chart}
          onClick={() =>
            dispatch({ type: 'CHART_TOGGLE', payload: !tableState.chart })
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-chart-arrows-vertical"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M18 21v-14"></path>
            <path d="M9 15l3 -3l3 3"></path>
            <path d="M15 10l3 -3l3 3"></path>
            <line x1="3" y1="21" x2="21" y2="21"></line>
            <line x1="12" y1="21" x2="12" y2="12"></line>
            <path d="M3 6l3 -3l3 3"></path>
            <path d="M6 21v-18"></path>
          </svg>
        </AuxiliaryButton>
      </section>

      <CheckBox
        css={css`
          grid-column: 1/ 3;
          justify-content: flex-end;
        `}
        label="Exclude Camera Events"
        checked={state.globalFilter.exceptCategory ? true : false}
        onChange={(e) =>
          setGlobalFilter((prev) => ({
            ...prev,
            exceptCategory: e.target.checked ? 'camera' : null
          }))
        }
      />
      {data?.restored && (
        <section
          css={css`
            display: flex;
            align-items: center;
            grid-column: 1/ -1;
            gap: 0.5rem;
            margin-top: 4px;
            strong {
              font-size: 12px;
            }
          `}
        >
          <IsEventResotred event={data} />
          <strong> Restored Events in past 14 days</strong>
        </section>
      )}
    </article>
  );
};

export default Filters;
