import 'css/sidebar.css';
import React from 'react';
import { withSidebar } from 'components/with-sidebar';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import { useHeadlessTable } from 'providers/headless-table-provider';
import SidebarEmptyHolder from 'components/sidebar-empty-holder';

import styled from '@emotion/styled';
import HealthProfile from './health-profile';
import VerticalBarHealthline from './vertical-bar-healthline';
import { Building } from 'react-bootstrap-icons';
import Map from 'components/map';
export const SidebarWrapper = styled('article')`
  display: flex;

  height: 100% !important;
  flex-direction: column;
  address > a {
    text-decoration: underline;
    font-size: 14px;
    color: var(--secundary-v-01);
  }
  > section {
    padding: 1rem;
  }

  > header {
    display: grid;
    grid-auto-columns: auto 1fr;
    > address {
      grid-column: span 2;
    }
    align-items: center;
    padding: 24px;
    box-shadow: inset 0px -1px 0px var(--neutral-10);
    gap: 1rem;
    > span {
      width: 40px;
      height: 40px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px;
      background: ${(props) =>
        props.company.offline_count > 0
          ? 'var(--red-v-02)'
          : 'var(--primary-v-02)'};
      color: ${(props) =>
        props.company.offline_count > 0
          ? 'var(--red-v-01)'
          : 'var(--primary-base)'};

      border-radius: 4px;
    }

    h2 {
      margin: 0;

      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 100%;
      /* identical to box height, or 18px */

      color: var(--neutral-00);
    }
    h3 {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 100%;

      color: var(--neutral-00);
    }
  }
  .map {
    height: 250px;
  }
`;
const Sidebar = (props) => {
  const [company, setCompany] = React.useState();

  const { selectedFlatRows } = useHeadlessTable();

  React.useEffect(() => {
    setCompany(selectedFlatRows?.[0]?.original || props.data);
  }, [selectedFlatRows, props.data]);

  if (!company) return <SidebarEmptyHolder />;
  return (
    <SidebarWrapper company={company}>
      <header>
        <span>
          <Building />
        </span>
        <h2>{company?.display_name}</h2>
        <address>
          <a
            target="_blank"
            href={`https://maps.google.com/?ll=${company?.lat},${company?.lng}`}
          >
            {company?.address}
          </a>
        </address>
      </header>
      {/* {company.lat && company.lng && (
        <section className="map">
          <Map lat={company.lat} lng={company.lng} />
        </section>
      )} */}
      <section className="health">
        <label
          css={css`
            font-size: 12px;
          `}
        >
          Last 7 days Device health history
        </label>
        <VerticalBarHealthline company={company} filters={{ days: '7' }} />
      </section>
    </SidebarWrapper>
  );
};

export default withSidebar({})(Sidebar, null);
