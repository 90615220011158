import LicensePlate from 'api/license_plate';
import React from 'react';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react **/
import { DialogDisclosure } from 'reakit/Dialog';
import { useModal } from 'providers/modal-provider';
import LicensePlateModal from 'views/cameras/license-plate-modal';
import { Dialog, DialogContent, DialogTrigger } from 'components/dialog.js';
const LicensePlateUri = ({ event, uri }) => {
  if (event?.ImageArray || uri)
    return (
      <Dialog>
        <DialogTrigger
          data-type={event.type || 'uri'}
          css={css`
            height: 100px;
            &[data-type='licensePlateUnfamiliar'] {
              filter: drop-shadow(0 0 0.15rem var(--orange-base));
            }
            &[data-type='licensePlateGranted'] {
              filter: drop-shadow(0 0 0.15rem var(--primary-base));
            }
            &[data-type='licensePlateDenied'] {
              filter: drop-shadow(0 0 0.15rem var(--red-base));
            }
          `}
        >
          <img
            css={css`
              width: 100%;
              max-height: 100%;
              box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
            `}
            src={`data:image/jpg;base64, ${
              uri || event.ImageArray[0].BinaryImage
            }`}
          />
        </DialogTrigger>
        <DialogContent noCloseButton>
          <img
            css={css`
              width: 100%;
              max-height: 100%;
              box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
            `}
            src={`data:image/jpg;base64, ${
              uri || event.ImageArray[0].BinaryImage
            }`}
          />
        </DialogContent>
      </Dialog>
    );
  else return null;
};

export default LicensePlateUri;
