import { useMutationWrapper } from 'utils/ajax';
import Report from 'api/report';
import { useQueryClient } from 'react-query';
import { useHeadlessTable } from 'providers/headless-table-provider';

export const useMutateDelete = (report) => {
  const queryClient = useQueryClient();
  const { queryKey } = useHeadlessTable();
  return useMutationWrapper(['report', 'delete'], (id) => Report.delete(id), {
    successMessage: `Report: ${report?.id} deleted sucessfuly`,
    onMutate: (id) => {
      let data = queryClient.getQueryData(queryKey);

      const newData = {
        ...data,
        data: data.data.filter((row) => row._id != id)
      };

      queryClient.setQueryData(queryKey, newData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['reports', 'stats']);
    }
  });
};

export const useMutateRun = (report) => {
  return useMutationWrapper(['report', 'delete'], (id) => Report.run(id), {});
};
