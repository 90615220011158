import React from 'react';
import { useDropzone } from 'react-dropzone';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */

import { Image, ImageFill, Plus, Trash3 } from 'react-bootstrap-icons';
import { AuxiliaryButton, IconButton } from 'components/buttons';
const FloorPlanUpload = ({ setImage, image }) => {
  const onDrop = React.useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        // Do whatever you want with the file contents

        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop
  });

  const acceptedFileItems = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));
  const removeAll = () => {
    acceptedFiles.length = 0;
    acceptedFiles.splice(0, acceptedFiles.length);
  };

  return (
    <section
      css={css`
        display: flex;
        gap: 1rem;
      `}
    >
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <IconButton>
          <Image />
        </IconButton>
      </div>
      {/* {acceptedFileItems} */}
      {image && (
        <IconButton
          onClick={() => {
            removeAll();

            setImage(null);
          }}
        >
          <Trash3 />
        </IconButton>
      )}
    </section>
  );
};

export default FloorPlanUpload;
