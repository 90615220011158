import { css } from "@emotion/react";
import { dateTimeShort } from "utils/time";
import React from "react";
import { IconButton } from "components/buttons";
import { Download, Trash } from "react-bootstrap-icons";
/** @jsxImportSource @emotion/react */
import { useMutateDelete } from "./actions";

export const columns = [
  {
    Header: "link",
    accessor: "link",
    pickerDisabled: true,
    Cell: ({ row: { original } }) => {
      const mutateDelete = useMutateDelete(original);

      const handleDelete = () => {
        if (confirm(`Are you sure you want to delete Snapshot: ${original.id}`))
          mutateDelete.mutate(original?.id);
      };
      return (
        <article
          css={css`
            img {
              filter: drop-shadow(0 -1mm 1mm rgba(0, 0, 0, 0.3));
            }
            .actions {
              font-size: 12px;
              display: flex;
              gap: 0.5rem;
              align-items: center;
              padding: 0.5rem;
              svg {
                width: 24px;
                height: 24px;
              }

              time {
                margin-right: auto;
                color: var(--neutral-01);
              }
            }
          `}
        >
          <img src={`${original.link}`} />

          <span className="actions">
            <time> {dateTimeShort(new Date(original.generated_at + "Z"))}</time>

            <a
              href={original.link}
              css={css`
                color: var(--secundary-base);
              `}
              downlaod
              target="_blank"
            >
              <Download />
            </a>
            <IconButton delete onClick={handleDelete}>
              <Trash />
            </IconButton>
          </span>
        </article>
      );
    },
  },
];
