import React from 'react';
import styled from '@emotion/styled';
import Sensor from 'api/sensor';
import { useQueryWrapper } from 'utils/ajax';
import TimeSeriesGauge from 'views/sensors/time-series-gauge';
import { WidgetHeader } from './widget-header';
import RemoveItemButton from './remove-item-button';
import WidgetGridControls from './widget-grid-controls';
import { IconButton } from 'components/buttons';
import {
  IconAlertCircle,
  IconAlertTriangle,
  IconReload,
  IconAccessPointOff,
  IconMapPin
} from '@tabler/icons-react';
import { useQueryClient } from 'react-query';
import { WidgetControls } from './widget-controls';
import SidebarEmptyHolder from 'components/sidebar-empty-holder';
import { FetchingWidgetHolder } from './fetching-widget-holder';
import { Link } from 'react-router-dom';
import { SENSOR_METADATA_UNITS } from 'routes/sensors/metadata';
import { TitleCards } from './door-widget';

const SensorWidget = styled('article')`
  box-sizing: border-box;
  /* Auto layout */
  place-self: center;
  display: flex;
  flex-direction: column;

  padding: 0px;
  min-width: 320px;

  height: 100%;
  background: #ffffff;
  border: 1px solid #eceff3;
  //min-height: 175px;
  max-height: 300px;
  height: 300px;

  &[data-alert='true'] {
    header {
      background: var(--red-v-02) !important;
      color: var(--red-v-01) !important;
    }
  }
  > section.topics {
    ::-webkit-scrollbar-thumb {
      background-color: #888;
    }

    ::-webkit-scrollbar-track {
      background-color: #f1f1f1;
    }
    padding: 1rem;
    scrollbar-color: #888 #f1f1f1;

    scrollbar-width: thin;
    flex: 1;
    display: grid;
    overflow-x: auto;
    overflow-y: hidden;

    grid-template-columns: repeat(3, minmax(125px, 1fr));
    gap: 1rem;
    align-items: center;
    > div {
      place-self: center;
      height: 100%;
      width: 100%;
      dl {
        margin: 0;
        place-self: center;
      }
      dt {
        font-size: 12px;
        color: var(--secondary-font-color);
      }
      dd {
        display: flex;
        align-items: center;
        gap: 1rem;
        > .current {
          font-size: 24px;
          em {
            font-size: 14px;
            color: var(--secondary-font-color);
          }
        }
      }
    }
  }
  > time {
    font-size: 12px;
    padding: 0.5rem;
    color: var(--secondary-font-color);
  }
`;

export default ({
  sensor,
  isEdit,
  widgetIndex,
  selectedView,
  index,
  setSelectedView,
  slim
}) => {
  const {
    data: sns,
    isLoading: isLoadingSensor,
    isError: isSensorError
  } = useQueryWrapper(['sensor', sensor.id], () => Sensor.get(sensor.id), {
    refetchInterval: 1200000
  });

  const {
    data: topics,
    isLoading: isLoadingTopics,
    dataUpdatedAt
  } = useQueryWrapper(
    ['sensor', sensor.id, 'getTopics'],
    () => Sensor.getTopics(sensor.id),
    {
      enabled: !!sns && Boolean(sns?.online),
      refetchInterval: 30000,
      select: (data) => {
        //..mix in metadaa
        return data.map((t) => ({
          ...t,
          name: sns?.metadata?.[t.topic]?.name,
          unit: sns?.metadata?.[t.topic]?.unit
        }));
      }
    }
  );
  if (isLoadingSensor || isLoadingTopics)
    return <FetchingWidgetHolder>Fetching Sensor...</FetchingWidgetHolder>;

  const queryClient = useQueryClient();
  return (
    <SensorWidget
      className="sensor-widget"
      data-widget="sensor"
      data-alert={sns && topics?.some((t) => t.alert)}
    >
      <WidgetHeader>
        {sns && topics?.some((t) => t.alert) && (
          <span>
            <IconAlertTriangle color="var(--red-v-01)" />
          </span>
        )}
        {sns && (
          <TitleCards>
            <Link to={`/sensors/monitoring/${sns?.id}`}>
              <div className="name">{sns?.name}</div>
            </Link>
            <span role="badge" className="site-name">
              <IconMapPin />
              <span>{sns?.site_name}</span>
            </span>
          </TitleCards>
        )}
        <WidgetControls>
          {!isEdit && (
            <IconButton
              title="reload sensor data"
              onClick={() => queryClient.resetQueries(['sensor', sensor.id])}
            >
              <IconReload />
            </IconButton>
          )}
          {!slim && (
            <WidgetGridControls
              isEdit={isEdit}
              index={index}
              widgetIndex={widgetIndex}
              selectedView={selectedView}
              setSelectedView={setSelectedView}
            />
          )}
          {isEdit && (
            <RemoveItemButton
              onClick={() => {
                let newLayout = [...selectedView.layout];
                newLayout[index].items = newLayout[index]?.items?.filter(
                  (_, i) => i !== widgetIndex
                );
                setSelectedView((prev) => ({
                  ...prev,
                  layout: newLayout
                }));
              }}
            />
          )}
        </WidgetControls>
      </WidgetHeader>
      {sns?.online == 0 && (
        <FetchingWidgetHolder>
          <IconAccessPointOff color="var(--red-base)" size={72} />
          Sensor is Offline
        </FetchingWidgetHolder>
      )}
      {topics?.length == 0 && sns?.online == 1 && (
        <FetchingWidgetHolder>no data found</FetchingWidgetHolder>
      )}

      {isSensorError && (
        <FetchingWidgetHolder>
          <IconAlertCircle color="var(--red-base)" size={36} /> Error fetching
          Widget
        </FetchingWidgetHolder>
      )}
      <section className="topics">
        {sns?.online == 1 &&
          topics?.map((topic, idx) => {
            return (
              <div data-alarm={topic.alert} key={idx}>
                <dl>
                  <dt>{topic.name || topic.topic}</dt>
                  <dd>
                    {topic.alert && (
                      <span>
                        <IconAlertTriangle color="var(--red-v-01)" />
                      </span>
                    )}
                    <div className="current">
                      <span>{topic.current}</span>
                      <em>
                        {
                          SENSOR_METADATA_UNITS.find(
                            (u) => u.id == topic.unit
                          )?.['label']
                        }
                      </em>
                    </div>
                  </dd>
                </dl>
              </div>
            );
          })}
      </section>
      {sns.last_data_at && (
        <time>
          Data updated at: {new Date(sns.last_data_at).toLocaleTimeString()}
        </time>
      )}
    </SensorWidget>
  );
};
