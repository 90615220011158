import React from 'react';

import ReactECharts from 'echarts-for-react';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */

import { useQueryWrapper } from 'utils/ajax';

import Event from 'api/event';
import { startOfDay, subDays } from 'date-fns';
import { createDatesArray, createEachDayOfInterval } from 'utils/arrays';

const styles = css`
  height: 100%;
  width: 100%;
  .spinner {
    display: block;
    margin: 24px auto;
  }
  header {
    display: block;
    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 100%;
      margin-bottom: 16px;
    }
  }
`;

const symbolSize = (data) => {
  if (data == 0) return 0;
  else if (data < 10) return 20;
  else if (data > 10 && data < 20) return 25;
  else if (data > 20 && data < 60) return 40;
  else return 60;
};

const RestoreChart = ({ device, filters = {}, slim = false, ...props }) => {
  //TODO change this to a Aggregate pipieline in fron end

  const { data, isLoading } = useQueryWrapper(
    ['restore', device?.device_id, filters],
    () =>
      Event.getCount({
        types: ['restoreStart', 'restoreFinish', 'restoreError'],
        device_id: device?.device_id,
        groupBy: '$date',
        from: startOfDay(subDays(new Date(), 14)),
        ...filters
      }),
    {
      ...props?.queryOptions,
      staleTime: 1000 * 60 * 10
    }
  );

  const [datesArray, setDatesArray] = React.useState([]);
  const [chartData, setChartData] = React.useState([]);

  React.useEffect(() => {
    setDatesArray(
      createEachDayOfInterval(
        filters?.from || startOfDay(subDays(new Date(), 14)),
        filters?.to
      )
    );
  }, [props.filter]);

  React.useEffect(() => {
    if (!data) return;

    const d = {
      start: [],
      finish: [],
      error: []
    };

    for (let i of datesArray) {
      let item = data.find((d) => d._id == i);
      d.start.push(item?.restoreStart || 0);
      d.finish.push(item?.restoreFinish || 0);
      d.error.push(item?.restoreError || 0);
    }
    setChartData(d);
  }, [data, datesArray]);

  const restoreOption = {
    animation: false,
    title: {
      text: !props.simple ? '7 Day Restore Activity' : ''
    },
    tooltip: {
      trigger: 'axis',
      confine: true,
      axisPointer: {
        type: 'shadow'
      }
    },

    grid: {
      left: 5,
      right: 5,
      bottom: 50,
      top: 25
    },
    xAxis: {
      type: 'category',
      data: datesArray,
      axisLabel: {
        interval: 0,
        showMaxLabel: true,
        show: true,
        color: 'var(--neutral-01)',
        fontSize: 11,
        formatter: (x) => {
          const dateStr = x;
          const parts = dateStr.split('-');
          const month = parseInt(parts[1]);
          const day = parseInt(parts[2]);
          return month + '/' + day;
        }
      },
      axisLine: {
        show: false,
        lineStyle: { color: 'grey' },
        symbolSize: [0, 0]
      },
      axisTick: { show: false }
    },
    yAxis: { show: false },
    legend: {
      selectedMode: slim ? false : 'multiple',
      data: ['start', 'finish', 'error'],
      icon: 'square',
      bottom: 0,
      left: 'center',
      bottom: 0,
      fontSize: 12,
      show: false
    },
    color: ['#FCD34D', '#3B82F6', '#900c3f'],
    series: [
      {
        silent: slim,
        symbolSize,
        // markPoint: {
        //   data: [{ type: "max", name: "Max" }],
        // },
        // itemStyle: {
        //   barBorderRadius: [10, 10, 0, 0] // Specify the border radius
        //   // borderType: "solid",

        //   // shadowBlur: 3,
        // },
        name: 'start',
        type: 'bar',
        barWidth: 10,

        data: chartData.start
      },

      {
        silent: slim,
        symbol: 'rectangle',
        symbolSize,
        // markPoint: {
        //   data: [{ type: "max", name: "Max" }],
        // },
        // itemStyle: {
        //   barBorderRadius: [10, 10, 0, 0] // Specify the border radius
        //   // borderType: "solid",

        //   // shadowBlur: 3,
        // },
        roundCap: true,
        symbolSize,
        name: 'finish',
        type: 'bar',
        barWidth: 10,

        data: chartData.finish
      },
      {
        silent: slim,
        //symbol: 'triangle',
        symbolSize: 10,
        // markPoint: {
        //   data: [{ type: "max", name: "Max" }],
        // },
        // itemStyle: {
        //   barBorderRadius: [10, 10, 0, 0] // Specify the border radius
        //   // borderType: "solid",

        //   // shadowBlur: 3,
        // },
        name: 'error',
        barWidth: 10,
        type: 'bar',
        // emphasis: {
        //   focus: 'series'
        // },
        data: chartData.error
      }
    ]
  };

  return (
    <article className="restore-chart" css={styles}>
      <ReactECharts
        loadingOption={{
          showSpinner: false,
          maskColor: 'var(--neutral-09)',
          fontWeight: 'normal',
          text: 'Fetching Data...',
          fontStyle: 'normal',
          fontFamily: 'inherit'
        }}
        showLoading={isLoading}
        opts={{ renderer: 'svg' }}
        option={restoreOption}
        style={{
          height: props.height || '220px',
          width: '100%',
          padding: '4px'
        }}
      />
    </article>
  );
};

export default RestoreChart;
