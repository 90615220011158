import React from 'react';
import { useLayout } from 'providers/layout-provider';
import { useAuth } from 'providers/auth';
import { useEventSource } from 'react-use-websocket';

export const useMotionEvent = (camera) => {
  const { getSocketUrl } = useLayout();
  const { user } = useAuth();

  let filter = React.useCallback(
    (data) => {
      if (!camera) return;
      if (
        data?.camera_id == camera.id &&
        (data.type == 'motionOn' || data.type == 'motionOff')
      )
        return true;
      return false;
    },
    [camera]
  );

  const { lastEvent, readyState } = useEventSource(
    getSocketUrl,
    {
      share: true,
      filter: (e) => filter(JSON.parse(e.data)),
      shouldReconnect: (e) => true,
      withCredentials: true,
      retryOnError: true
    },
    Boolean(user && !user.missing2fa && !user.missingMfa)
  );

  const [motionEvent, setMotionEvent] = React.useState();

  React.useEffect(() => {
    if (!lastEvent) return;

    let data = JSON.parse(lastEvent.data);
    setMotionEvent(data);
  }, [lastEvent]);
  return { motionEvent, readyState };
};
