import { useHistory } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useAuth } from 'providers/auth.js';
import { useHeadlessTable } from 'providers/headless-table-provider';
import { toast } from 'react-toastify';
import Spinner from 'components/spinner';
import 'css/toast.css';
import { NEW_API_HOST_URL } from 'api/api-url';
import { useLayout } from 'providers/layout-provider';

function setSessionExiration(res) {
  let expiration = res?.headers?.get('x-sess-expiration');
  let user = localStorage.getItem('user');
  if (user)
    localStorage.setItem(
      'user',
      JSON.stringify({ ...JSON.parse(user), expiration })
    );
}
export const callAPI = async (method, url, input, options = {}) => {
  function isAbortError(error) {
    if (error && error.name === 'AbortError') {
      return true;
    }
    return false;
  }
  const controller = new AbortController();
  // Get the abortController's signal
  const signal = controller.signal;

  url = NEW_API_HOST_URL + url;
  if (method == 'GET' && input) {
    Object.keys(input).forEach(
      (key) =>
        (input[key] === undefined || input[key] === null) && delete input[key]
    );

    // sanitize so params can be appended as an array for backend

    let params = new URLSearchParams();
    for (const property in input) {
      if (Array.isArray(input[property]))
        input[property].forEach((item) => params.append(`${property}[]`, item));
      else if (property == 'selection')
        params.append(`${property}[]`, input[property]);
      else params.append(`${property}`, input[property]);
    }

    url += '?' + params.toString();
    url = decodeURIComponent(url);
  }

  let res, body;

  if (!options.keepBody) body = JSON.stringify(input);

  try {
    res = await fetch(url, {
      method,
      credentials: 'include',
      body: method == 'GET' ? undefined : body ?? input,
      signal
    });
  } catch (ex) {
    if (isAbortError(ex)) {
      throw new Error('Request Timeout');
    } else throw new Error('General Error Fetching Data from server');
  }

  setSessionExiration(res);

  if (res.status == 401 && res.message == 'Not Logged In') throw new Error(401);
  if (res.status > 299) {
    let err = new Error(await res.text());
    err.status = res.status;
    throw err;
  }
  let data;

  const contentType = res.headers.get('content-type');
  if (contentType && contentType.indexOf('application/json') !== -1)
    data = await res.json();
  else data = await res.text();

  if (res.headers.get('x-found-count'))
    return {
      status: 'success',
      data: data,
      total: Number(res.headers.get('x-found-count')),
      last_page: Math.floor(res.headers.get('x-found-count') / 30) + 1,
      cancel: () => controller.abort()
    };
  else return data;
};

export const dataFetchQuery = async (url, input, params = {}) => {
  function isAbortError(error) {
    if (error && error.name === 'AbortError') {
      return true;
    }
    return false;
  }

  //make sure we have a session

  // Get the abortController's signal
  //const signal = controller.signal;
  let res;

  try {
    res = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(input),
      signal: params?.signal ?? null
    });
  } catch (ex) {
    if (isAbortError(ex)) {
      throw new Error('Request Timeout');
    } else throw new Error('General Error Fetching Data from server');
  }

  setSessionExiration(res);

  if (res.status == 401) throw new Error(401);

  let json = await res.json();
  //maybe throw in the fututre
  if (json.status == 'error') {
    throw new Error(json.message);
  }
  return json;
};
export const onMutateItem = async ({ id, queryClient, queryKey, filters }) => {
  //await this.queryClient.cancelQueries([this.queryKey, 'search', {filter: this.filters}])
  //await this.queryClient.cancelQueries([this.queryKey, 'stats', {filter: this.filters}])
  let data = queryClient.getQueryData([queryKey, 'search', { filters }]);
  //check if item or array of items is being updated
  queryClient.setQueryData([queryKey, 'search', { filters }], {
    ...data,
    data: data.data.map((i) => ({
      ...i,
      isLoading: i.id ? i.id == id || id.includes(i.id) : i.token == id
    }))
  });
};

export const useQueryWrapper = (key, func, opts = {}) => {
  // todo CHECK SESSION WITH COOKIE

  let { logout } = useAuth();

  const history = useHistory();

  const query = useQuery({
    queryKey: key,
    queryFn: func,
    retry: (failureCount, error) => {
      if (opts.noRetry) return false;
      if (error.status !== 404 && error.status !== 400 && failureCount < 3)
        return true;
      else return false;
    },
    onError: (res) => {
      if (res.message == 401) {
        //logout.mutate();
        //  toast.error('Session Expired due to inactivity');
        history.replace(history.location.pathname, {
          errorStatusCode: 401
        });
      } else {
        res.status == 404 && opts.onNotFound?.(res);
        if (!opts.noToast) toast.error(opts.errorMessage || res.message);
      }
    },
    //retry: 0, // do not retry
    ...opts
  });

  return query;
};

export const useMutationWrapper = (key, func, opts = {}) => {
  const { tableState, id, queryKey } = useHeadlessTable();
  let { logout } = useAuth();
  let queryClient = useQueryClient();

  const mutation = useMutation(func, {
    mutationKey: key,
    ...opts,
    onMutate: (id) => {
      typeof opts.onMutate == 'function' && opts.onMutate(id);

      if (!opts.noToast)
        mutation.toastId = toast.info(opts.loadingMessage || 'Loading...', {
          autoClose: false,
          closeOnClick: false,
          closeButton: false
        });

      if (opts.mainQueryKey) {
        console.log('main query key', opts.mainQueryKey);

        let newData;

        //this will be fixed as filters will be stored in the session storag
        let data = queryClient.getQueryData([
          opts.mainQueryKey,
          'search',
          tableState.queryFilters,
          tableState.queryFilter,
          tableState.querySorter,
          tableState.queryPageIndex
        ]);

        if (data) {
          console.log('found data', data);

          newData = {
            ...data,
            data: data.data.map((i) => ({
              ...i,
              isSuccess:
                i.id == id ||
                i.token == id ||
                (Array.isArray(id) && id.includes(i.token)) ||
                (Array.isArray(id) && id.includes(i.id))
                  ? false
                  : i.isSuccess,
              isLoading:
                i.id == id ||
                i.token == id ||
                (Array.isArray(id) && id.includes(i.token)) ||
                (Array.isArray(id) && id.includes(i.id))
                  ? true
                  : i.isLoading,
              statusMessage:
                i.id == id || i.token == id
                  ? opts.loadingMessage || 'Loading...'
                  : i.statusMessage
            }))
          };

          //check if item or array of items is being updated
          queryClient.setQueryData(
            [
              opts.mainQueryKey,
              'search',
              tableState.queryFilters,
              tableState.queryFilter,
              tableState.querySorter,
              tableState.queryPageIndex
            ],
            newData
          );
        }
      }
    },
    onError: (res) => {
      typeof opts.onError == 'function' && opts.onError(res);

      if (res.message == 401) {
        logout.mutate();
        toast.error('Session expired due to inactivity');
      } else if (mutation.toastId)
        toast.update(mutation.toastId, {
          type: toast.TYPE.ERROR,
          closeButton: true,
          closeOnClick: true,
          render: (
            <>
              Error!
              {(opts.errorMessage || typeof res?.message == 'string') && (
                <small>{opts.errorMessage || res?.message}</small>
              )}
            </>
          )
        });
      queryClient.invalidateQueries([id]);
    },

    onSuccess: (res, id) => {
      //TODO maybe fix this on slow conenction u mgiht edit somethig wrong

      typeof opts.onSuccess == 'function' && opts.onSuccess(res, id);

      if (mutation.toastId)
        toast.update(mutation.toastId, {
          type: toast.TYPE.SUCCESS,
          closeButton: true,
          closeOnClick: true,
          render: (
            <>
              Success!
              {(opts.successMessage ||
                typeof res?.message == 'string' ||
                typeof res == 'string') && (
                <small>{opts.successMessage || res?.message || res}</small>
              )}
            </>
          ),
          autoClose: 4000
        });

      if (opts.mainQueryKey) {
        console.log('main query key success', opts.mainQueryKey);
        let newData;

        //this will be fixed as filters will be stored in the session storag
        let data = queryClient.getQueryData([
          opts.mainQueryKey,
          'search',
          tableState.queryFilters,
          tableState.queryFilter,
          tableState.querySorter,
          tableState.queryPageIndex
        ]);

        console.log('Data in success', data);

        if (data) {
          newData = {
            ...data,
            data: data.data.map((i) => ({
              ...i,
              isLoading:
                i.id == id ||
                i.token == id ||
                (Array.isArray(id) && id.includes(i.token)) ||
                (Array.isArray(id) && id.includes(i.id))
                  ? false
                  : i.isLoading,
              isSuccess:
                i.id == id ||
                i.token == id ||
                (Array.isArray(id) && id.includes(i.token)) ||
                (Array.isArray(id) && id.includes(i.id))
                  ? true
                  : i.isSuccess,
              statusMessage:
                i.id == id || i.token == id
                  ? opts.loadingMessage || 'Loading...'
                  : i.statusMessage
            }))
          };

          //check if item or array of items is being updated
          queryClient.setQueryData(
            [
              opts.mainQueryKey,
              'search',
              tableState.queryFilters,
              tableState.queryFilter,
              tableState.querySorter,
              tableState.queryPageIndex
            ],
            newData
          );
        }
      }
    },

    onSettled: () => {
      // maybe put a timeout here
      opts.refetch && queryClient.refetchQueries(opts.refetch);
    }
  });

  return mutation;
};

export const useRunMutation = (mutate, data, queryClient, opts = {}) => {
  const history = useHistory();

  mutate(data, {
    onError: (res) => {
      res.status == 401 && history.replace('/login');
    },

    onSettled: () => {
      opts.invalidate && queryClient.invalidateQueries(opts.invalidate);
    }
  });

  return null;
};
