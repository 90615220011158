/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { red } from "@mui/material/colors";
export const wrapperStyles = css`
  > .error-message {
    margin-top: -18px;
    position: relative;
    z-index: 10;
  }
`;

export const customStyles = (props) => ({
  container: (provided, state) => ({
    ...provided,
    //width: "400px",
    //maxWidth: "400px",
    height: "fit-content",
    paddingBottom: state.selectProps.noPadding ? "0px" : "20px",

    opacity: state.isDisabled ? 0.7 : 1,
  }),

  control: (styles, state) => {
    return {
      ...styles,
      //    backgroundColor: theme == "dark" ? "white" : "white",
      // maxWidth: "400px",
      color: "var(--neutral-00)",
      minHeight: props["data-compact"] ? "40px" : "48px",
      background: "var(--neutral-09)",
      border: "1px solid var(--neutral-12)",
      boxSizing: "border-box",
      borderRadius: "5px",
      borderBottomLeftRadius: state.selectProps.menuIsOpen ? "0px" : "absolute",
      borderBottomRightRadius: state.selectProps.menuIsOpen
        ? "0px"
        : "absolute",
      cursor: state.selectProps.isSearchable ? "text" : "pointer",
      "&:focus-within": { borderColor: "var(--neutral-00)" }, // border style on hover
      "&:not([disabled]):hover": { borderColor: "var(--neutral-00)" }, // border style on hover
      border: "1px solid var(--neutral-12)", // default border color
      boxShadow: "none", // no box-shadow
      boxShadow: "none", // no box-shadow
    };
  },
  valueContainer: (base) => ({
    ...base,
    width: "100%",
    maxHeight: "200px",
    overflowY: "auto",
  }),
  singleValue: (base) => ({
    ...base,
    width: "fit-content",
    color: "var(--primary-base)",
  }),

  indicatorSeparator: (base) => ({
    ...base,
    backgroundColor: "var(--neutral-12)",
    margin: "0px",
  }),

  multiValueRemove: (styles, { data }) => {
    return {
      ...styles,
      "&:hover": {
        background: "none",
        color: "var(--neutral-00)",
      },
    };
  },

  input: (style) => ({
    border: "none",
    outline: "none",
    border: "none",
  }),
  menu: (styles, state) => {
    return {
      ...styles,
      backgroundColor: "var(--neutral-09)",
      color: "var(--neutral-00)",

      boxShadow: state.selectProps.fixedMenu ? "none" : styles.boxShadow,
      border: state.selectProps.fixedMenu
        ? "1px solid var(--neutral-12)"
        : "none",
      //padding: "2px",
      marginTop: state.selectProps.fixedMenu
        ? "0px"
        : state.selectProps.noPadding
        ? "0px"
        : "-16px",
      borderTopLeftRadius: state.selectProps.menuIsOpen ? "0px" : "5px",
      borderTopRightRadius: state.selectProps.menuIsOpen ? "0px" : "5px",
      // backgroundColor: theme == "dark" ? "white" : "white",
      //maxWidth: "400px",
      width: "100%",
      zIndex: state.selectProps.fixedMenu ? "11px" : "12",
      position: state.selectProps.fixedMenu ? "relative" : "absolute",
      paddingBottom: state.selectProps.fixedMenu ? "px" : "0px",
    };
  },
  menuList: (base, state) => ({
    ...base,

    maxHeight: state.selectProps.menuIsOpen ? "200px" : "inherit",

    "::-webkit-scrollbar": {
      width: "14px",
    },
    "::-webkit-scrollbar-track": {
      background: "#ccc",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#888",
      background: "#555",
      borderRadius: "12px",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }),

  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    if (data.value == "select-all")
      return {
        ...styles,
        color: "var(--secundary-base)",
        padding: props["data-compact"] ? "8px" : "16px",
        /* BorderBottom */
        boxShadow: "inset 0px -1px 0px var(--neutral-12)",
        background: "var(--allsiteselected-background-color)",
        borderRadius: "4px",
      };
    else
      return {
        ...styles,
        padding: props["data-compact"] ? "8px" : "16px",
        color: "var(--neutral-00)",
        opacity: isDisabled ? "0.5" : "1",
        fontWeight: isSelected ? 600 : 400,
        backgroundColor: isSelected
          ? "var(--menu-hover-background-color)"
          : isFocused
          ? "var(--menu-hover-background-color)"
          : "inherit",
      };
  },
});
