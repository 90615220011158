/** @jsxImportSource @emotion/react */

import React from "react";
import { InputLabel, InputHelper, Input } from "components/form";
import { ErrorMessage } from "components/form";

const getNameFromLabel = (label) =>
  label ? label.toLowerCase().replace(/\ /g, "_") : "";

const TextField = React.forwardRef((props, ref) => {
  const name = props.name || getNameFromLabel(props?.label);

  ref = ref || React.useRef();

  const tfProps = {
    "data-compact": props["data-compact"] ?? props["compact"],
  };

  return (
    <div className={"field text-field " + props.className} {...tfProps}>
      <InputLabel {...props} />
      <InputHelper {...props} />
      <Input
        type="text"
        placeholder={props.placeholder || ""}
        ref={ref}
        name={name}
        {...props}
      />

      <ErrorMessage name={name} {...props} />
    </div>
  );
});

export default TextField;
