import React from "react";
import { css } from "@emotion/react";
/** @jsxImportSource @emotion/react */
import { useAuth } from "providers/auth";
import { useHistory } from "react-router-dom";
import { SecondaryButton } from "components/buttons";

const styles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  .stop-access {
    height: 40px;
    padding: 0 16px;
    background: var(--red-v-02);
    color: var(--red-base);
    border-radius: 5px;
    font-weight: 700;
    &:hover {
      background: var(--stopManaging-hover-background-color);
    }
  }
`;

const Footer = (props) => {
  const { user, installerStopAccessing } = useAuth();
  const history = useHistory();
  return (
    <footer css={styles}>
      <button
        className="stop-access"
        onClick={() => installerStopAccessing.mutate(user?.installer_id)}
      >
        Stop Access
      </button>
      <SecondaryButton compact onClick={(e) => history.push("/installers")}>
        View All Installers
      </SecondaryButton>
    </footer>
  );
};

export default Footer;
