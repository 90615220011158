import { Card } from './card';
import { IconTrash } from '@tabler/icons-react';
import { Button } from 'components/buttons';
import { css } from '@emotion/react';
import useAlertDialog from 'providers/alert-dialog-provider';
/** @jsxImportSource @emotion/react */
import { useMutateDelete } from '../actions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default ({ device }) => {
  const mutateDelete = useMutateDelete(device);
  const confirm = useAlertDialog();
  const history = useHistory();
  const handleDelete = async () => {
    if (
      await confirm({
        description: `Are you sure you want to delete: ${device?.name} ?`
      })
    )
      mutateDelete.mutate(device.id, {
        onSuccess: () => {
          history.push('/devices');
        }
      });
  };

  return (
    <Card
      className="delete"
      css={css`
        .icon {
          background: var(--light--others--red-400-base);
        }
        background: var(--light--others--red-300);
        button {
          background: var(--light--others--red-400-base);
          &:hover {
            background: var(--light--others--red-400-base);
          }
        }
      `}
    >
      <div className="icon">
        <IconTrash />
      </div>
      <div className="content">
        <h2>DELETE</h2>
        <span className="description">
          Delete controller, all configuration, events and logs will be lost.
        </span>
      </div>
      <div className="action">
        <Button loading={mutateDelete.isLoading} onClick={handleDelete} compact>
          Delete
        </Button>
      </div>
    </Card>
  );
};
