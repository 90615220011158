import 'css/sidebar.css';
import React from 'react';
import { css, Global } from '@emotion/react';
import Select from 'components/headless-select';

/** @jsxImportSource @emotion/react */

import { useHeadlessTable } from 'providers/headless-table-provider';

import AsyncSelect from 'components/headless-select/async';

import TableFilter from 'components/headless-table-filter';
import User from 'api/user';
import { useAuth } from 'providers/auth';
import Company from 'api/company';
import TableReloadButton from 'components/table/table-reload-button';
import ColumnPicker from 'components/column-picker';

const articleStyles = css`
  display: flex;
  align-items: flex-end;
  height: fit-content;
  align-items: bottom;

  > * {
    flex: 1;
  }
  column-gap: 3rem;
  row-gap: 0.8rem;

  padding: 24px;

  background: var(--neutral-09);

  .select__wrapper {
    flex: 0 0 20%;
  }
  .table-filter-container {
    flex: 0 0 30%;
    margin-left: auto;
  }

  header {
    h1 {
      padding: 24px;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 100%;
    }
  }
  section.filters {
    footer {
      display: flex;
      gap: 16px;
    }
  }
`;
const Filters = (props) => {
  const { setGlobalFilter, state } = useHeadlessTable();
  const [filters, setFilters] = React.useState({});
  const { user, hasAllPermissions } = useAuth();

  return (
    <article css={articleStyles}>
      {hasAllPermissions('writeUsers') && (
        <AsyncSelect
          simple
          isSearchable={true}
          name="user"
          placeholder="User.."
          data-compact
          fetchFn={User.search}
          closeMenuOnSelect={true}
          onChange={(e) => {
            setGlobalFilter((prev) => ({ ...prev, user: e?.id }));
            setFilters((prev) => ({ ...prev, user: e }));
          }}
          value={state.globalFilter.user ? filters.user : 'null'}
        />
      )}
      {!user?.company_id && (
        <AsyncSelect
          simple
          isSearchable={true}
          name="company"
          placeholder="Company..."
          label="display_name"
          data-compact
          fetchFn={Company.search}
          closeMenuOnSelect={true}
          onChange={(e) => {
            setGlobalFilter((prev) => ({ ...prev, company: e?.id }));
            setFilters((prev) => ({ ...prev, company: e }));
          }}
          value={state.globalFilter.company ? filters.company : 'null'}
        />
      )}

      <section
        css={css`
          display: flex;
          margin-left: auto;
          gap: 0.5rem;
        `}
      >
        <TableFilter data-compact {...props} />
        <TableReloadButton compact />
        <ColumnPicker />
      </section>
    </article>
  );
};

export default Filters;
