import "css/sidebar.css";
import React from "react";
import { withSidebar } from "components/with-sidebar";
/** @jsxImportSource @emotion/react */

import { useHeadlessTable } from "providers/headless-table-provider";
import SidebarEmptyHolder from "components/sidebar-empty-holder";

import styled from "@emotion/styled";

import { ShieldCheck } from "react-bootstrap-icons";
import { Badge } from "components/badge";
import AccessLevelTabs from "./access-level-tabs";

export const SidebarWrapper = styled("article")`
  display: flex;

  height: 100% !important;
  flex-direction: column;

  > header {
    display: grid;
    padding: 24px;
    border-bottom: 1px solid var(--neutral-12);
    grid-template-columns: auto 1fr;
    gap: 0 24px;
    align-items: center;

    span.icon {
      grid-row: 1 / span 2;
      padding: 0.8rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      &[data-enabled="true"] {
        background: var(--primary-v-02);
        color: var(--primary-base);
      }
      &[data-enabled="false"] {
        background: var(--red-v-02);
        color: var(--red-base);
      }
    }
    h4 {
      font-size: 12px;
      font-weight: 500;
      color: var(--neutral-01);
      grid-column: 2;
    }
    [role="badge"] {
      grid-column: 3;
      grid-row: 1;
    }
  }
`;
const Sidebar = (props) => {
  const [al, setAl] = React.useState();

  const { selectedFlatRows } = useHeadlessTable();

  React.useEffect(() => {
    setAl(selectedFlatRows?.[0]?.original || props.data);
  }, [selectedFlatRows, props.data]);

  if (!al) return <SidebarEmptyHolder />;
  return (
    <SidebarWrapper>
      <header>
        <span className="icon" data-enabled={al.enabled == "1"}>
          <ShieldCheck />
        </span>
        <h3>{al.name}</h3>
        <h4>{al.description}</h4>
        <Badge color={al.enabled == "1" ? "green" : "red"}>
          {al.enabled == "1" ? "Active" : "Locked"}
        </Badge>
      </header>

      <AccessLevelTabs al={al} />
    </SidebarWrapper>
  );
};

export default withSidebar({ width: "375px" })(Sidebar, null);
