import React from "react";
import { useQueryWrapper } from "utils/ajax";
import Controller from "api/controller";
import { ReactComponent as NoIssues } from "assets/images/no-issues-doors.svg";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
const IconShadow = styled("span")`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: var(--dashboard-empty-holder-background-color);
  border-radius: 50%;
  width: 180px;
  height: 180px;
  margin-bottom: 8px;
`;

const styles = css`
  color: var(--dashboard-empty-holder-fill-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 8px;
  h4 {
    color: var(--neutral-00);
  }

  h5 {
    margin: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    /* identical to box height, or 14px */

    display: flex;
    align-items: center;
    text-align: center;

    /* Neutrals/neutral-02 */

    color: var(--neutral-02);
  }
`;

const DashboardEmptyHolder = (props) => {
  const { title, subtitle, children } = props;

  return (
    <article className="empty-holder" css={styles}>
      <IconShadow>{children}</IconShadow>
      {title && <h4>{title}</h4>}
      {subtitle && <h5>{subtitle}</h5>}
    </article>
  );
};

export default DashboardEmptyHolder;
