import { CheckLg, XLg } from 'react-bootstrap-icons';
import { css } from '@emotion/react';
import { dateTimeDif } from 'utils/time';
import { getSelectionColumn } from 'components/table-selection-column';
/** @jsxImportSource @emotion/react */
import { useAuth } from 'providers/auth';
import { PermissionFormatter } from 'views/manage-users/table-columns';

export const columns = [
  getSelectionColumn(),
  {
    Header: 'Name',
    accessor: 'name',
    newSorter: true,
    asc: 'atoz',
    desc: 'ztoa',
    Cell: ({ row }) => {
      const { user } = useAuth();
      return (
        <div
          css={css`
            > [role='badge'] {
              font-size: 8px;
              min-height: 12px;
              padding: 0 6px;
              margin: 0 4px;
            }
          `}
        >
          <strong>{row.original.name}</strong>
          {user.user_id == row.original.id && <span> (yourself)</span>}
          <br />
          <small>{row.original.email}</small>
        </div>
      );
    }
  },
  {
    Header: 'Last Login',
    accessor: 'last_login_at',
    newSorter: true,
    asc: 'oldestLogin',
    desc: 'newestLogin',
    Cell: ({ cell: { value } }) => <span>{dateTimeDif(value)}</span>
  },

  {
    Header: 'Active',
    accessor: 'active',
    newSorter: true,
    asc: 'activeFirst',
    desc: 'activeLast',
    Cell: ({ cell: { value } }) => {
      return (
        <span
          css={css`
            svg {
              width: 24px;
            }
          `}
        >
          {value ? <CheckLg color="green" /> : <XLg color="red" />}
        </span>
      );
    }
  },
  {
    Header: 'Enabled',
    accessor: 'enabled',
    newSorter: true,
    asc: 'enabledFirst',
    desc: 'enabledLast',

    Cell: ({ cell: { value } }) => {
      return (
        <span
          css={css`
            svg {
              width: 24px;
            }
          `}
        >
          {value ? <CheckLg color="green" /> : <XLg color="red" />}
        </span>
      );
    }
  },

  {
    Header: 'Date Inserted',
    accessor: 'created_at',
    label: 'Date Inserted',
    id: 'created_at',
    newSorter: true,
    asc: 'newest',
    desc: 'oldest',

    Cell: ({ cell: { value } }) => {
      if (value) return <span>{dateTimeDif(value)}</span>;
      else return null;
    }
  },
  {
    Header: 'Permissions',
    accessor: 'permission',
    disableSortBy: true,

    Cell: ({ cell: { value }, row: { original } }) => (
      <PermissionFormatter
        permission={value || []}
        devicesView={original.devices_view}
      />
    )
  },
  {
    Header: 'Security',
    accessor: 'enable_mfa',
    disableSortBy: true,
    Cell: ({ row: { original } }) => {
      if (original.enable_mfa) return <strong>MFA</strong>;
      else if (original.enable_2fa) return <strong>2FA</strong>;
      else return null;
    }
  }
];
