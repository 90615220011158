import React from 'react';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import { ReactComponent as EmptySvg } from 'assets/images/empty-holder.svg';
import Spinner from './spinner';
import { useHeadlessTable } from 'providers/headless-table-provider';
import { useAuth } from 'providers/auth';
const styles = css`
  height: 200px;
  pointer-events: none;
  tr {
    text-align: center;
    vertical-align: middle;
    height: 100%;
    align: center;
  }
  td {
    overflow: visible !important;
    padding: 0px !important;
    margin: 0px !important;
  }
`;
const TableLoader = (props) => {
  const { headerGroups, tableWidth } = useHeadlessTable();

  const { user } = useAuth();

  const filterHeaders = (headers = []) => {
    return headers.filter((h) => {
      if (h.disableManaging && user.company_id) return null;

      if (h.role && !h.role?.includes(user.role)) return null;
      else return h;
    });
  };

  return (
    <tbody css={styles}>
      <tr>
        <td colSpan={filterHeaders(headerGroups[0]?.headers)?.length ?? 1}>
          <div
            style={{ width: tableWidth + 'px', position: 'sticky', left: 0 }}
          >
            <Spinner />
          </div>
        </td>
      </tr>
    </tbody>
  );
};

export default TableLoader;
