import { dataFetchQuery } from "../utils/ajax";

import { API_HOST_URL } from "./api-url";
const API_URL = API_HOST_URL + "/metric_event_api";

const Metric = {
  search(input) {
    return dataFetchQuery(`${API_URL}/search`, input);
  },
  searchLatest(input) {
    return dataFetchQuery(`${API_URL}/searchLatest`, input);
  },

  searchLatestGroup(input = []) {
    return dataFetchQuery(`${API_URL}/searchLatestGroup`, input);
  },

  count(input) {
    return dataFetchQuery(`${API_URL}/count`, input);
  },
};

export default Metric;
