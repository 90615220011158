import React from 'react';
import { css } from '@emotion/react';
import DoorStateAvatar from 'views/doors/door-state-avatar';
/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import { AvatarCircle } from 'components/avatar';
import GenericDoor from 'api/generic_door';
import { useQueryWrapper } from 'utils/ajax';
import { WidgetHeader } from './widget-header';
import RemoveItemButton from './remove-item-button';
import LastPerson from 'views/doors/last-person';
const LastPersonWidget = styled('article')`
  box-sizing: border-box;
  /* Auto layout */
  width: stretch;
  display: flex;
  flex-direction: column;
  width: 320px;

  padding: 0px;
  background: #ffffff;
  border: 1px solid #eceff3;
  border-radius: 8px;
`;

const Content = styled(`div`)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
`;

const IconCards = styled('div')`
  margin-left: auto;
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const TitleCards = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  > .description {
    font-size: 12px;
    color: var(--secondary-font-color);
  }
`;

export default ({
  doorId,
  widgetIndex,
  selectedView,
  setSelectedView,
  index
}) => {
  const { data: door } = useQueryWrapper(['door', 'get', doorId], () =>
    GenericDoor.get(doorId)
  );

  //console.log(data);
  return (
    <LastPersonWidget>
      <WidgetHeader>
        <TitleCards>
          <div className="name">{door?.name}</div>
          <div>Last Person In</div>
        </TitleCards>
        <RemoveItemButton
          onClick={() => {
            let newLayout = [...selectedView.layout];
            newLayout[index].widgets = newLayout[index]?.widgets?.filter(
              (_, i) => i !== widgetIndex
            );
            setSelectedView((prev) => ({
              ...prev,
              layout: newLayout
            }));
          }}
        />
      </WidgetHeader>
      <Content> {door && <LastPerson data={door} />}</Content>
    </LastPersonWidget>
  );
};
