import React from 'react';
import GenericDoorForm from 'views/generic-doors/form';
import GenericDoor from 'api/generic_door';
import Template from 'base/template';
import { Button, CancelButton, SecondaryButton } from 'components/buttons';
import { Redirect } from 'react-router-dom';
import FormProvider from 'providers/form-provider';
import { useMutationWrapper } from 'utils/ajax';
import { useRedirect } from 'hooks/redirect-hook';
import PageTitle from 'components/page-title';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Content from 'base/content';
import GenericDoorDetails from 'views/generic-doors/generic-door-details';
import Breadcrumbs from 'components/breadcrumbs';

const Save = (props) => {
  let door = props?.location?.state?.door || null;

  const SCHEMA = Yup.object().shape({
    name: Yup.string()
      .min(4, 'Name is too short')
      .max(50, 'Max is 40 characters')
      .required('Required'),
    access_time: Yup.number()
      .min(1, 'Can no be less than 1')
      .max(30, 'Max value is 30')
      .required('Required')
  });

  const INITIAL_VALUES = {
    ...door,
    name: door?.name,
    access_time: door?.access_time || 7,
    description: door?.description
  };

  const { isSuccess, isLoading, mutate } = useMutationWrapper(
    ['generic-door', 'save'],
    (input) => GenericDoor.save(input, door?.id),
    {}
  );

  const { redirect, setRedirect } = useRedirect(isSuccess);

  return (
    <Template {...props}>
      <Content {...props}>
        {redirect && <Redirect to="/doors" />}
        <PageTitle>{door ? door.name : 'Door Add'}</PageTitle>
        <Breadcrumbs crumbs={props.crumbs} />

        <Formik
          validationSchema={SCHEMA}
          initialValues={INITIAL_VALUES}
          onSubmit={(values) => {
            mutate(values);
          }}
        >
          {({ handleSubmit }) => (
            <FormProvider mutateFunc={handleSubmit} disabled={isLoading}>
              <GenericDoorDetails door={door} />
              <footer>
                <Button type="submit">Submit</Button>
                <CancelButton onClick={() => setRedirect(true)} />
              </footer>
            </FormProvider>
          )}
        </Formik>
      </Content>
    </Template>
  );
};

export default Save;
