import { dataFetchQuery } from 'utils/ajax';

import { API_HOST_URL } from './api-url';
const API_URL = API_HOST_URL + '/credential_api';

import { callAPI } from 'utils/ajax';

const Credential = {
  get(token) {
    return callAPI('GET', `/credential/${token}`);
  },

  search(input = {}) {
    return callAPI('GET', `/credential`, input);
  },

  stats(input = {}) {
    return callAPI('GET', `/credential/stats`, input);
  },

  save(input = {}, token = '') {
    return callAPI(token ? 'PUT' : 'POST', `/credential/${token}`, input);
  },
  delete(tokens = null) {    
    return callAPI('DELETE', `/credential`, tokens);
  },
  removeAccessLevels(input = {}, token = '') {
    return callAPI('POST', `/credential/removeAccessLevels/${token}`, input);
  },
  addAccessLevels(input = {}, token = '') {
    return callAPI('POST', `/credential/addAccessLevels/${token}`, input);
  },
  controllerData(input = {}, token = '') {
    return callAPI('GET', `/credential/controllerData/${token}`, input);
  },

  enable(token = '') {
    return callAPI('PUT', `/credential/enable/${token}`);
  },
  disable(token = '') {
    return callAPI('PUT', `/credential/disable/${token}`);
  },

  // TODO move to use access_level_api when moved to new API
  searchAccessLevels(token) {
    return dataFetchQuery(`${API_URL}/searchAccessLevels/` + token, {});
  },

  add(input = null) {
    return dataFetchQuery(`${API_URL}/add`, input);
  },

  bulkAdd(input = null) {
    return dataFetchQuery(`${API_URL}/bulkAdd`, input, false);
  },

  edit(input = null, id = '') {
    return dataFetchQuery(`${API_URL}/edit/` + id, input);
  },

  bar(token) {
    return dataFetchQuery(`${API_URL}/bar/` + token, {});
  },

  lost(token) {
    return dataFetchQuery(`${API_URL}/lost/` + token, {});
  },

  retrieve(doorToken) {
    return dataFetchQuery(`${API_URL}/retrieve/` + doorToken, {});
  }
};

export default Credential;
