import React from 'react';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { VolumeOff, VolumeUp } from 'react-bootstrap-icons';
import TransmitAudio from 'views/intercoms/transmit-audio';

export default ({ intercom_id }) => {
  return (
    <article className="audio-control">
      <TransmitAudio intercom_id={intercom_id} />
    </article>
  );
};
