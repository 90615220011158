import React from 'react';
import { useHistory } from 'react-router-dom';

import { MenuItem, MenuItemDelete, MenuWrapper } from 'components/action-menu';
import {
  useDeleteMutate,
  useSendActivationMutate,
  useEndSessionMutate,
  useEnableMutate
} from './actions';
import { useAuth } from 'providers/auth';
import useAlertDialog from 'providers/alert-dialog-provider';

export default function ActionMenu({ data: user, ...otherProps }) {
  const { impersonate } = useAuth();
  const history = useHistory();

  const mutateDelete = useDeleteMutate(user);
  const mutateSendActivation = useSendActivationMutate(user);
  const mutateEndSession = useEndSessionMutate(user);
  const mutateEnable = useEnableMutate(user);

  const confirm = useAlertDialog();

  let handleEdit = () => {
    history.push('/manage-users/edit/' + user?.id, {
      user,
      desc: user?.name
    });
  };

  const handleImpersonate = () => impersonate.mutate(user?.id);
  const handleDelete = async () => {
    if (
      await confirm({
        description: 'Are u sure you want to delete this user?'
      })
    )
      mutateDelete.mutate(user?.id);
  };

  const handleEnable = async () => {
    if (
      await confirm({
        description: 'Are u sure you want to enable this user?'
      })
    )
      mutateEnable.mutate(user?.id);
  };
  const handleSendActivation = () => mutateSendActivation.mutate(user?.id);
  const handleEndSession = async () => {
    if (
      await confirm(
        'Ending user session will terminate any current user work, continue?'
      )
    )
      mutateEndSession.mutate(user.id);
  };

  return (
    <MenuWrapper {...otherProps}>
      <MenuItem
        label="Impersonate"
        disabled={!user?.enabled}
        onClick={handleImpersonate}
      />
      {/* <MenuItem label="Edit" onClick={handleEdit} /> */}
      <MenuItem
        disabled={user?.active}
        label="Re-send Activation Link"
        onClick={handleSendActivation}
      />
      <MenuItemDelete onClick={handleDelete} />
      <MenuItem
        disabled={!user?.loggedIn}
        label="End Session"
        onClick={handleEndSession}
      />
      {(!user?.enabled || user?.failed_login_attempts > 10) && (
        <MenuItem label="Enable User" onClick={handleEnable} />
      )}
    </MenuWrapper>
  );
}
