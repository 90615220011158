import React from 'react';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */

const inputStyles = css`
  height: 24px;
  width: 24px;
`;

export const CHECKED = 1;
export const UNCHECKED = 0;
export const INDETERMINATE = -1;

const CheckBox = ({ children, ...props }) => {
  const { value, indeterminate, ...otherProps } = props;

  const checkRef = React.useRef();

  return (
    <label
      name={props.name || ''}
      css={css`
        color: var(--neutral-00);
        display: inline-flex;
        align-items: center;
        gap: 5px;
        width: fit-content;

        cursor: pointer;

        > input {
          cursor: pointer;
          margin-right: 6px;
          appearance: none;
          &:checked {
            background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
            border-color: transparent;
            background-color: currentColor;
            background-size: 100% 100%;
            background-position: center;
            background-repeat: no-repeat;
            color: var(--primary-base);
          }
          width: 24px;
          height: 24px;
          border-radius: 5px;
          border: 1px solid var(--checkbox-border-color);
        }
      `}
    >
      <input ref={checkRef} css={inputStyles} type="checkbox" {...otherProps} />
      <span>{props.label || props.name}</span>
    </label>
  );
};

export default CheckBox;
