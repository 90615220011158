import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserProvider from './providers/auth.js';
import ThemeProvider from './providers/theme.js';
import ModalProvider from './providers/modal-provider';
import LayoutProvider from './providers/layout-provider';
import React from 'react';
import Navigation from './navigation';
import Spinner from 'components/spinner';
import './css/base.css';
import {
  QueryClient,
  QueryCache,
  MutationCache,
  QueryClientProvider
} from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import ErrorHandler from './providers/error-handler.js';
import { css } from '@emotion/react';
import {
  CheckCircleFill,
  ExclamationCircle,
  ExclamationCircleFill
} from 'react-bootstrap-icons';
import GlobalStateProvider from 'providers/global-state-provider.js';
import { useTheme } from '@emotion/react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { AlertDialogProvider } from 'providers/alert-dialog-provider.js';

const queryCache = new QueryCache({
  onError: () => {}
});
const mutationCache = new MutationCache({
  onError: () => {}
});

const queryClient = new QueryClient({
  queryCache,
  mutationCache,
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const HELMET = {
  'app.cube-access.com': {
    favicon: 'favicon.ico',
    title: 'Cube Access'
  },
  'app.demo.cube-access.com': {
    favicon: 'favicon.ico',
    title: 'Cube Access'
  },
  'access.yoursixsecurity.com': {
    favicon: 'ys-favicon.ico',
    title: 'YourSix'
  },
  'dooraccess.yoursixsecurity.com': {
    favicon: 'ys-favicon.ico',
    title: 'YourSix'
  },
  'sandbox.yoursixsecurity.com': {
    favicon: 'ys-favicon.ico',
    title: 'YourSix'
  },
  'localhost:3001': {
    favicon: 'favicon.ico',
    title: 'Cube Access'
  },
  'localhost:3000': {
    favicon: 'favicon.ico',
    title: 'Cube Access'
  },
  'portal-staging.arc34.com': {
    favicon: 'arc34-favicon.ico',
    title: 'Arc Securities staging -- Portal'
  },
  'portal.arc34.com': {
    favicon: 'arc34-favicon.ico',
    title: 'Arc Securities -- Portal'
  }
};

document.getElementById('favicon').href = HELMET[location.host]?.favicon;
document.title = HELMET[location.host].title;

export default () => {
  return (
    <GlobalStateProvider>
      <DndProvider backend={HTML5Backend}>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider>
              <UserProvider>
                {/* <SocketProvider> */}
                <AlertDialogProvider>
                  <ModalProvider>
                    <LayoutProvider>
                      <ToastContainer
                        hideProgressBar={true}
                        newestOnTop={true}
                        theme="colored"
                        draggable={false}
                        icon={({ type }) => {
                          if (type == 'success')
                            return <CheckCircleFill size="26" />;
                          if (type == 'error')
                            return <ExclamationCircleFill size="26" />;
                          if (type == 'info') return <Spinner />;
                        }}
                        className={css({ fontSize: '10px' })}
                      />
                      <ErrorHandler>
                        <Navigation />
                      </ErrorHandler>
                    </LayoutProvider>
                  </ModalProvider>
                </AlertDialogProvider>

                {/* </SocketProvider> */}
              </UserProvider>
            </ThemeProvider>
          </QueryClientProvider>
        </BrowserRouter>
      </DndProvider>
    </GlobalStateProvider>
  );
};
