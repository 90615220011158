import isWithinInterval from 'date-fns/isWithinInterval';
import {
  TabItem,
  TabGroup,
  TabPanel,
  TabPanels,
  TabList
} from 'components/tabs';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useQueryWrapper } from 'utils/ajax';
import subDays from 'date-fns/subDays';
import isSameHour from 'date-fns/isSameHour';
import styled from '@emotion/styled';

import Metric from 'api/metric';
import { keyframes } from '@emotion/react';
const bounce = keyframes`
  to {
    transform: rotate(360deg);
  }
`;
const Container = styled('div')`
  display: grid;
  grid-auto-rows: 1fr auto;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  column-gap: 1rem;
`;

const DAY = 1000 * 60 * 60 * 24;
const MIN = 1000 * 60;
const HOUR = 1000 * 60 * 60;

import * as Collapsible from '@radix-ui/react-collapsible';
import React from 'react';
import {
  ChevronDoubleLeft,
  ChevronDoubleRight,
  ChevronLeft,
  ChevronRight,
  Play,
  PlayCircle,
  Stop,
  ZoomIn,
  ChevronDown,
  ArrowClockwise,
  SquareFill,
  Activity,
  ZoomOut
} from 'react-bootstrap-icons';

import useChartOption from './use-chart-option';
import Timeline from './timeline';
import useListRecording from './use-list-recording';
import useListCloudRecording from './use-list-cloud-recording';
import Player from './player';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import startOfHour from 'date-fns/startOfHour';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import isToday from 'date-fns/isToday';
import { useQueries } from 'react-query';
import RecordingExport from './recording-export';
export function getBrowserVisibilityProp() {
  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    return 'visibilitychange';
  } else if (typeof document.msHidden !== 'undefined') {
    return 'msvisibilitychange';
  } else if (typeof document.webkitHidden !== 'undefined') {
    return 'webkitvisibilitychange';
  }
}

export function getBrowserDocumentHiddenProp() {
  if (typeof document.hidden !== 'undefined') {
    return 'hidden';
  } else if (typeof document.msHidden !== 'undefined') {
    return 'msHidden';
  } else if (typeof document.webkitHidden !== 'undefined') {
    return 'webkitHidden';
  }
}

export function getIsDocumentHidden() {
  return !document[getBrowserDocumentHiddenProp()];
}
export function usePageVisibility() {
  const [isVisible, setIsVisible] = React.useState(getIsDocumentHidden());
  const onVisibilityChange = () => setIsVisible(getIsDocumentHidden());

  React.useEffect(() => {
    const visibilityChange = getBrowserVisibilityProp();

    document.addEventListener(visibilityChange, onVisibilityChange, false);

    return () => {
      document.removeEventListener(visibilityChange, onVisibilityChange);
    };
  });

  return isVisible;
}

function removeEmpty(obj) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
}

const searchParams = (parameters = {}) => {
  return Object.entries(parameters)
    .map(([key, value]) => {
      if (value)
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join('&');
};

import usePlayerControls from 'components/use-player-controls';
import useCameraEvents from './use-camera-events';
import SeekDateTime from './seek-date-time';
import { defaultNewIndexGetter } from '@dnd-kit/sortable';
import { isSameDay } from 'date-fns';
import {
  IconDeviceSdCard,
  IconCloud,
  IconZodiacAquarius,
  IconPhotoSearch,
  IconRefresh
} from '@tabler/icons-react';

const validTimeStamp = (ts) => {
  return parseInt(ts) &&
    ts > 0 &&
    ts > Date.now() - DAY * 30 &&
    ts < Date.now() + 1 &&
    ts.toString().length == 13
    ? Number(ts)
    : null;
};

const PlayerWrapper = ({ camera: host, parameters = {} }) => {
  // const [dataZoomCoords, setDataZoomCoords] = React.useState({
  //   start: Date.now() - MIN * 15,
  //   end: Date.now()
  // });

  const [exportTime, setExportTime] = React.useState();
  const params = React.useMemo(() => new URLSearchParams(search), [search]);
  const history = useHistory();
  const [isZoomMode, setIsZoomMode] = React.useState();

  const dataZoom = React.useRef({
    start: validTimeStamp(params.get('timestamp'))
      ? Number(params.get('timestamp')) - MIN * 15
      : Date.now() - MIN * 15,
    end: validTimeStamp(params.get('timestamp'))
      ? Number(params.get('timestamp')) + MIN * 15
      : Date.now() + MIN * 15
  });

  const [fetchedZoom, setFetchedZoom] = React.useState(
    startOfHour(new Date(dataZoom.current.start)).getTime()
  );

  const intervalZoom = React.useRef();
  const [clusterDays, setClusterDays] = React.useState([]);

  const events = React.useRef([]);
  const clusterEvents = React.useRef([]);

  const [allEvents, setAllEvents] = React.useState({});
  const [allClusterEvents, setAllClusterEvents] = React.useState({});

  const [recordingZoom, setRecordingZoom] = React.useState();

  const [exportMode, setExportMode] = React.useState();

  const queryFirstHour = useQueryWrapper(
    ['camera', 'events', host?.id, fetchedZoom, fetchedZoom + HOUR],
    () =>
      Metric.search({
        first: true,
        camera: host.id,
        limit: 500,
        from: new Date(fetchedZoom).toISOString(),
        to: new Date(fetchedZoom + HOUR).toISOString(),
        slim: true
      }),
    {
      enabled: Boolean(fetchedZoom),
      staleTime: 10 * (60 * 1000), // 10 mins
      cacheTime: 15 * (60 * 1000), // 15 mins
      refetchOnMount: 'always',
      refetchOnReconnect: 'always',
      refetchIntervalInBackground: 'always'
    }
  );

  const querySecondHour = useQueryWrapper(
    [
      'camera',
      'events',
      host?.id,

      fetchedZoom + HOUR,
      // fetchedZoom + HOUR * 2 > Date.now()
      //   ? Math.round(Date.now() / 60000) * 60000
      //   : fetchedZoom + HOUR * 2 // TODO fix this fetching mechnism to macth recording
      fetchedZoom + HOUR * 2
    ],
    () =>
      Metric.search({
        second: true,
        camera: host.id,
        limit: 500,
        from: new Date(fetchedZoom + HOUR).toISOString(),
        to:
          fetchedZoom + HOUR * 2 > Date.now()
            ? new Date().toISOString()
            : new Date(fetchedZoom + HOUR * 2).toISOString(), //only up to current time
        slim: true
      }),
    {
      enabled: Boolean(fetchedZoom),
      staleTime: 10 * (60 * 1000), // 10 mins
      cacheTime: 15 * (60 * 1000), // 15 mins
      refetchOnMount: 'always',
      refetchOnReconnect: 'always',
      refetchIntervalInBackground: 'always'
    }
  );

  const queries = useQueries(
    clusterDays.map((date) => {
      // only reftech today
      let staleTime = isToday(new Date(date)) ? 0 : 10 * (60 * 1000); // 10 mins
      return {
        queryKey: ['camera', 'events', 'cluster', date.toISOString(), host?.id],
        queryFn: () =>
          Metric.count({
            groupBy: '$hour',
            camera: host.id,

            from: new Date(startOfDay(date)),
            to: new Date(endOfDay(date)),

            slim: true
          }),

        refetchInterval: isToday(new Date(date)) ? 30000 : false,
        staleTime,
        cacheTime: 15 * (60 * 1000), // 15 mins
        // refetchOnMount: 'always', // Be aware that the chart is actually remounted on scrolling so enabling this will actually overrdi refetc time
        refetchOnReconnect: 'always',
        refetchIntervalInBackground: 'always'
      };
    })
  );

  const allSuccess = queries.every((num) => num.isSuccess === true);

  // const queryClusterDay = useQueryWrapper(
  //   [
  //     'camera',
  //     'events',
  //     'cluster',
  //     intervalZoom,
  //     startOfDay(fetchedZoom),
  //     host?.id
  //   ],
  //   () =>
  //     Metric.count({
  //       groupBy: '$hour',
  //       camera: host.id,

  //       from: new Date(startOfDay(fetchedZoom)),
  //       to: new Date(endOfDay(fetchedZoom)),

  //       slim: true
  //     }),
  //   {
  //     enabled: Boolean(fetchedZoom),
  //     staleTime: 10 * (60 * 1000), // 10 mins
  //     cacheTime: 15 * (60 * 1000), // 15 mins
  //     refetchOnMount: 'always',
  //     refetchOnReconnect: 'always',
  //     refetchIntervalInBackground: 'always'
  //   }
  // );

  const queryFutureEvents = useQueryWrapper(
    ['camera', 'events', 'second', host?.id, 'future'],
    () =>
      Metric.search({
        camera: host.id,
        limit: 500,
        from: Date.now() - 30000,
        to: Date.now() + 2000,
        slim: true,
        future: true
      }),
    {
      refetchInterval: 30000,
      refetchIntervalInBackground: true
    }
  );

  React.useEffect(() => {
    events.current = events.current.concat(Object.values(allEvents));
  }, [allEvents]);

  React.useEffect(() => {
    clusterEvents.current = Object.values(allClusterEvents);
  }, [allClusterEvents, allSuccess]);

  React.useEffect(() => {
    if (!queryFirstHour?.data?.data) return;
    const data = queryFirstHour?.data?.data;
    let obj = {};

    if (!data) return;

    data.forEach((d) => (obj[d.id] = d));

    setAllEvents((prev) => ({ ...prev, ...obj }));
    //events.current = events.current.concat(Object.values(obj));
  }, [queryFirstHour.data]);

  React.useEffect(() => {
    if (!querySecondHour?.data?.data) return;

    const data = querySecondHour?.data?.data;
    if (!data) return;

    let obj = {};
    data.forEach((d) => (obj[d.id] = d));

    setAllEvents((prev) => ({ ...prev, ...obj }));

    //events.current = events.current.concat(Object.values(obj));
  }, [querySecondHour.data]);

  React.useEffect(() => {
    if (!queryFutureEvents?.data?.data) return;

    const data = queryFutureEvents?.data?.data;
    if (!data) return;

    let obj = {};
    data.forEach((d) => (obj[d.id] = d));

    setAllEvents((prev) => ({ ...prev, ...obj }));

    //events.current = events.current.concat(Object.values(obj));
  }, [queryFutureEvents.data]);

  React.useEffect(() => {
    // if (!queryClusterDay?.data) return;

    // const data = queryClusterDay?.data;
    // if (!data) return;

    // let obj = {};
    // data.forEach((d) => (obj[d.beginOfHour.$date.$numberLong] = d));

    // setAllClusterEvents((prev) => ({ ...prev, ...obj }));

    if (!allSuccess) return;

    const combinedData = queries.reduce(
      (acc, { data }) => acc.concat(data),
      []
    );

    let obj = {};
    combinedData.forEach((d) => (obj[d.beginOfHour.$date.$numberLong] = d));

    setAllClusterEvents((prev) => ({ ...prev, ...obj }));

    //events.current = events.current.concat(Object.values(obj));
    //this dependecny is needed to always get the total for the curetn day
  }, [allSuccess, queries?.[0]?.['data']]);

  const { setPlay, setRefresh, play, refresh, waiting, setWaiting } =
    usePlayerControls({ host });

  const { search } = useLocation();

  const { recordings, daysWithRecording } = useListRecording({
    camera: host,
    recordingZoom
  });

  const { cloudRecordings } = useListCloudRecording({
    camera: host,
    recordingZoom
  });

  const chartRef = React.useRef();
  const [currentTime, setCurrentTime] = React.useState(
    validTimeStamp(params.get('timestamp')) || Date.now()
  );

  const [currentRecordingTime, setCurrentRecordingTime] = React.useState(null);
  const currentTimeRef = React.useRef(
    validTimeStamp(params.get('timestamp') ?? Date.now())
  );
  const [currentRecording, setCurrentRecording] = React.useState(null);
  const [seek, setSeek] = React.useState(
    validTimeStamp(params.get('timestamp'))
  );

  React.useEffect(() => seek && setCurrentTime(seek), [seek]);

  const _seekRef = React.useRef(seek);
  const _recordingRef = React.useRef(currentRecording);

  const [videoProperties, setVideoProperties] = React.useState({
    width: 800,
    height: 600
  });

  const _timerRef = React.useRef();

  const { option, setOption, renderItem, data } = useChartOption({
    dataZoom: dataZoom.current
  });

  const running = React.useRef(true);
  const [isRunning, setIsRunning] = React.useState(true);

  const recordingData = React.useRef([]);
  const cloudRecordingData = React.useRef([]);

  const [zoomLevel, setZoomLevel] = React.useState(3);
  const zoomLevelRef = React.useRef();

  React.useEffect(() => {
    if (zoomLevelRef.current == zoomLevel) return;
    zoomLevelRef.current = zoomLevel;
    let start, end;

    let time = currentTime;
    if (zoomLevel == 1) {
      start = time - MIN * 1;
      end = time + MIN * 1;
    } else if (zoomLevel == 2) {
      start = time - MIN * 15;
      end = time + MIN * 15;
    } else if (zoomLevel == 3) {
      start = time - MIN * 30;
      end = time + MIN * 30;
    } else if (zoomLevel == 4) {
      start = time - HOUR * 1;
      end = time + HOUR * 1;
    }
    if (zoomLevel == 5) {
      start = time - HOUR * 12;
      end = time + HOUR * 12;
    }
    if (zoomLevel == 6) {
      start = time - DAY * 1;
      end = time + DAY * 1;
    }
    if (zoomLevel == 7) {
      start = time - DAY * 3;
      end = time + DAY * 3;
    }

    dataZoom.current = {
      start,
      end
    };

    updateMarkLine(currentTime);
  }, [zoomLevel]);

  // if u want live events via socket
  // events.current = events.current.concat(useLiveMotionevents(host));

  const updateMarkLine = React.useCallback(
    (time) => {
      if (!chartRef.current) return;
      const opt = { ...option };

      opt.toolbox = {
        show: true
      };

      opt.brush = {
        type: 'lineX',
        removeOnClick: false,
        xAxisIndex: [2],
        throttleType: 'debounce',
        throttleDelay: 1000,
        brushMode: 'single',
        brushStyle: {
          borderWidth: 1,
          color: 'rgba(120,140,180,0.3)',
          borderColor: 'rgba(120,140,180,0.8)'
        }
      };

      opt.dataZoom = [
        {
          // realtime: false,
          // yAxisIndex: [0],

          xAxisIndex: [0, 1, 2, 3],
          type: 'inside',
          //zoomLock: true,
          filterMode: 'weakFilter',
          minValueSpan: 60 * 1000 * 1,
          maxValueSpan: 60 * 1000 * 60 * 7 * 24,
          throttle: 200,
          startValue: dataZoom.current.start,
          endValue: dataZoom.current.end
        }
      ];
      opt.xAxis = [
        {
          gridIndex: 0,
          scale: true,
          axisPointer: { show: false },
          min: Date.now() - DAY * 30,
          max: Date.now() + DAY * 7,

          type: 'time',
          axisLabel: {
            show: false
          }
        },
        {
          gridIndex: 1,
          scale: true,

          min: Date.now() - DAY * 30,
          max: Date.now() + DAY * 7,

          type: 'time',
          axisLabel: {
            show: false
          }
        },
        {
          triggerEvent: true,
          min: Date.now() - DAY * 30,
          max: Date.now() + DAY * 7,
          type: 'time',
          minorTick: { show: false },
          axisLabel: {
            formatter: function (value, index) {
              return `{h}:{mm}:{ss}`;
            },
            textStyle: {
              color: '#000'
            },
            fontWeight: 400,
            showMaxLabel: true,
            inside: true,
            showMinLabel: false,
            fontSize: 12,
            fontFamily: 'monospace'
          },
          axisPointer: {
            triggerEvent: true,
            show: true,
            type: 'line',
            lineStyle: {
              color: 'red',
              type: 'solid',
              width: 3
            },
            label: {
              formatter: function (value, index) {
                return value && new Date(value.value).toLocaleTimeString();
              },
              backgroundColor: 'transparent',
              color: 'black',
              fontWeight: 600,
              fontSize: 12,
              padding: [0, 0, 5, -80],
              margin: 40
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'solid'
            }
          },

          gridIndex: 2

          //boundaryGap: false,
        },
        {
          gridIndex: 2,

          position: 'bottom',
          type: 'time',
          min: Date.now() - DAY * 30,
          max: Date.now() + DAY * 7,

          minorTick: { show: true },

          axisLine: {
            show: false
          },

          axisLine: {
            onZero: false
          },

          axisLabel: {
            formatter: function (value, index) {
              return index % 2 ? `{M}/{d}/{yy}` : ``;
            },
            showMaxLabel: true,
            showMinLabel: true,
            color: '#000',
            margin: 10,
            align: 'left',
            padding: [0, 10, 5, 6],
            fontFamily: 'monospace',
            fontWeight: 600,
            hideOverlap: false,
            fontSize: 12,
            fontWeight: 500,
            interval: 10
          }
        }
      ];
      opt.series = [
        {
          xAxisIndex: 0,
          yAxisIndex: 0,
          encode: {
            x: [1, 2],
            y: 0
          },
          triggerEvent: true,

          symbol: 'none',
          renderItem: renderItem,
          type: 'custom',
          data: recordingData.current ?? []
        },
        {
          xAxisIndex: 1,
          yAxisIndex: 1,
          encode: {
            x: [1, 2],
            y: 0
          },
          triggerEvent: true,

          symbol: 'none',
          renderItem: renderItem,
          type: 'custom',
          data: cloudRecordingData.current ?? []
        },
        {
          //LICENSE PLATES
          encode: {
            x: [0],
            y: [1, 2]
          },
          markLine: {
            silent: true,
            data: [{ name: 'currentTime', xAxis: time }],
            label: { show: false },
            lineStyle: {
              color: 'blue',
              type: 'solid',
              width: 3
            },
            symbol: 'none'
          },

          type: 'scatter',
          symbolSize: 0,
          color: '#fff5ea',
          // symbolKeepAspect: true,
          // symbol:
          //   'image://data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGNsYXNzPSJpY29uIGljb24tdGFibGVyIGljb24tdGFibGVyLXRydWNrIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3Ryb2tlLXdpZHRoPSIyIiBmaWxsPSJub25lIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZT0iI2Y1OTcyOCI+DQogIDxwYXRoIHN0cm9rZT0ibm9uZSIgZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPg0KICA8Y2lyY2xlIGN4PSI3IiBjeT0iMTciIHI9IjIiLz4NCiAgPGNpcmNsZSBjeD0iMTciIGN5PSIxNyIgcj0iMiIvPg0KICA8cGF0aCBkPSJNNSAxN2gtMnYtMTFhMSAxIDAgMCAxIDEgLTFoOXYxMm0tNCAwaDZtNCAwaDJ2LTZoLThtMCAtNWg1bDMgNSIvPg0KPC9zdmc+',
          //symbol: 'none',
          name: 'license',
          labelLayout: {
            hideOverlap: true
          },
          label: {
            show: true,
            position: 'inside',
            color: 'black',
            fontSize: 11,
            padding: 2,
            borderWidth: 1,
            borderRadius: 1,
            borderType: 'solid',
            borderColor: 'black',
            textBorderWidth: 1,

            formatter: ({ value }) => value?.[2] ?? 'PLATE'
          },

          data:
            zoomLevelRef.current <= 3
              ? events?.current?.map(
                  (e) =>
                    e.type.includes('licensePlate') && [
                      new Date(e.utctime),
                      '6',
                      e.plateText
                    ]
                )
              : [],
          xAxisIndex: 2,
          yAxisIndex: 2,
          itemStyle: {
            borderWidth: 1
          }
        },
        {
          cursor: 'auto',
          type: 'scatter',
          symbolSize: 20,
          color: '#6428e4',
          name: 'motion',
          symbol:
            'image://data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGNsYXNzPSJpY29uIGljb24tdGFibGVyIGljb24tdGFibGVyLWFjdGl2aXR5IiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZT0iIzY0MjhlNCIgZmlsbD0ibm9uZSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj4KICAgPGNpcmNsZSBjeD0iMTIiIGN5PSIxMiIgcj0iMTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiAvPgogICA8cGF0aCBzdHJva2U9Im5vbmUiIGQ9Ik0wIDBoMjR2MjRIMHoiIGZpbGw9Im5vbmUiPjwvcGF0aD4KICAgPHBhdGggZD0iTTMgMTJoNGwzIDhsNCAtMTZsMyA4aDQiPjwvcGF0aD4KPC9zdmc+',
          //'path://M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2Z',
          data:
            zoomLevelRef.current <= 3
              ? events?.current?.map(
                  (e) => e.type == 'motionOn' && [new Date(e.utctime), '4']
                )
              : [],

          xAxisIndex: 2,
          yAxisIndex: 2,
          itemStyle: {
            borderWidth: 1
          }
        },
        {
          // this is the cluster series

          encode: {
            x: [0],
            y: [1, 2]
          },
          cursor: 'auto',
          type: 'scatter',
          // symbolSize: function (data) {
          //   return Math.sqrt(data[2]) / 5; // adjust the size of the symbol based on the value
          // },
          symbolSize: 25,
          itemStyle: {
            color: '#808080' // Adjust the symbol size on focus,
          },

          label: {
            cursor: 'auto',
            show: true,
            position: 'inside', // set the position of the label to inside the circle
            formatter: function (params) {
              return params.data[2]; // display the value of the data property inside the symbol
            },
            color: '#fff' // set the color of the label to white
            // backgroundColor: '#ccc', // set the background color of the label to gray
            // // set the padding of the label to 4 pixels at the top and bottom and 8 pixels on the left and right
            // borderRadius: 10 // set the border radius of the label to 10 pixels to make it more rounded
          },

          color: '#808080',
          name: 'cluster',
          emphasis: {
            disabled: zoomLevelRef.current > 3 ? false : true,
            focus: 'series',
            itemStyle: {
              color: '#ea3737',
              borderColor: '#F8BDBD',
              border: 8,
              shadowColor: 'rgb(248, 189, 189, 0.5)',
              shadowBlur: 2
            }
          },

          data:
            zoomLevelRef.current > 3 ||
            !isSameHour(
              new Date(dataZoom.current.start),
              new Date(dataZoom.current.end)
            )
              ? clusterEvents?.current?.map((e) => [
                  new Date(Number(e.beginOfHour.$date.$numberLong)),

                  '4',
                  e.count
                ])
              : [], // keep showing cluster if not in hour

          xAxisIndex: 2,
          yAxisIndex: 2,
          itemStyle: {
            borderWidth: 1
          }
        }
      ];

      setOption(opt);
    },
    [recordings, seek, chartRef.current, zoomLevelRef.current]
  );

  const [vapixParams, setVapixParams] = React.useState();

  React.useEffect(() => {
    // this is needed - bring back seek if
    if (currentRecording) setSeek(currentTime);
  }, [currentRecording]);

  React.useEffect(() => {
    if (!isRunning) return;
    _timerRef.current = setInterval(function () {
      if (!running.current) return;

      //if (pipeline && !playing) return;
      // setCurrentTime((prev) =>
      //   pipeline?.recording || !pipeline
      //     ? new Date(prev).setSeconds(new Date(prev).getSeconds() + 1)
      //     : new Date().setSeconds(new Date().getSeconds() + 1)
      // );

      updateMarkLine(currentTimeRef.current);

      let rec, cloudRec;

      if (cloudRecordings) {
        let before60Sec = new Date(Number(Date.now() - 60000));

        cloudRecordingData.current = [];
        cloudRecordingData.current = cloudRecordings?.map((cloudRec) => {
          let start = new Date(cloudRec.start).getTime();
          //TODO this will always have end... need to pass contnuies
          // let stop = cloudRec.end
          //   ? new Date(cloudRec.end).getTime()
          //   : new Date().getTime();

          let stop =
            new Date(cloudRec.end) <= before60Sec
              ? new Date(cloudRec.end).getTime()
              : new Date().getTime();

          return {
            rec: cloudRec._id,
            name: 'cloud',
            value: [0, start, stop, stop - start],
            duration: Math.ceil((stop - start) / 1000),
            itemStyle: {
              normal: {
                color: '#135fa6'
              }
            }
          };
        });
      }

      if (recordings) {
        recordingData.current = [];
        recordingData.current = recordings?.map((rec) => {
          let start = new Date(rec.starttime).getTime();
          let stop = rec.stoptime
            ? new Date(rec.stoptime).getTime()
            : new Date().getTime();

          return {
            rec: rec.recordingid,
            resolution: rec?.resolution ?? '1920x1080',
            name: 'sd-card',
            value: [0, start, stop, stop - start],
            duration: Math.ceil((stop - start) / 1000),
            itemStyle: {
              normal: {
                color: '#00A96C'
              }
            }
          };
        });
      }

      if (recordingData?.current)
        rec = recordingData?.current.find((r) =>
          isWithinInterval(new Date(currentTimeRef.current), {
            start: new Date(r.value[1]),
            end: new Date(r.value[2])
          })
        );

      if (_seekRef.current) {
        setCurrentRecording(rec?.rec);
        setVapixParams({ recordingid: rec?.rec, seek: _seekRef.current });
        if (rec?.resolution) {
          var dimensionsArray = rec.resolution.split('x');
          // Convert the resulting parts to integers
          var width = parseInt(dimensionsArray[0]);
          var height = parseInt(dimensionsArray[1]);
          setVideoProperties((prev) => ({ ...prev, width, height }));
        }
      }

      // old way of updating timeline

      // if (_seekRef.current)
      //   setCurrentTime((prev) =>
      //     new Date(prev).setSeconds(new Date(prev).getSeconds() + 1)
      //   );
      // else setCurrentTime(new Date().setSeconds(new Date().getSeconds() + 1));

      // this way take into consideration slow feed and adjust the timeline accordingly...

      if (_seekRef.current) {
        if (videoProperties.videoEl?.videoTime)
          setCurrentTime(
            new Date(videoProperties.videoEl?.videoTime).getTime()
          );
        // case where timeline has no recordin in sick mode
        else
          setCurrentTime((prev) =>
            new Date(prev).setSeconds(new Date(prev).getSeconds() + 1)
          );
      } else setCurrentTime(new Date().setSeconds(new Date().getSeconds() + 1));

      if (
        chartRef.current &&
        chartRef.current.getEchartsInstance().getModel()?.option?.dataZoom[0]
      )
        dataZoom.current = {
          start:
            chartRef.current.getEchartsInstance().getModel().option.dataZoom[0]
              .startValue + 1000,
          end:
            chartRef.current.getEchartsInstance().getModel().option.dataZoom[0]
              .endValue + 1000,
          interval: chartRef?.current
            ?.getEchartsInstance()
            .getModel()
            .getComponent('xAxis', 0).axis.scale._interval
        };

      // calculate fetching of coords

      const timelineStart = dataZoom.current.start;

      const timelineEnd = dataZoom.current.end;
      // const timeLineZoom = dataZoom.current.interval;

      let ds = new Date(timelineStart);
      ds.setMinutes(0, 0, 0);
      ds.setHours(ds.getHours() - 1); //begning of hour

      let de = new Date(timelineEnd);
      de.setMinutes(0, 0, 0);
      de.setHours(de.getHours() + 1);

      //stop time can never be more than current time

      if (de > new Date()) de = new Date();

      setRecordingZoom({
        start: ds.toISOString(),
        stop: de.toISOString()
      });

      //if (timelineEnd - timelineStart > HOUR) return;

      let dzs = new Date(dataZoom.current.start);

      dzs.setMinutes(0, 0, 0);

      // calulate fetcihg of eventets via zoom

      if (timelineEnd - timelineStart < HOUR * 2) setFetchedZoom(dzs.getTime());

      //caluculate cluster

      let arr = [];
      const arrayOfDateInZoom = eachDayOfInterval({
        start: timelineStart,
        end: timelineEnd
      });
      arr = [...clusterDays, ...arrayOfDateInZoom];
      arr = arr.map((d) => startOfDay(d));

      const uniqueDates = [...new Set(arr.map((dt) => dt.getTime()))];

      setClusterDays(uniqueDates.map((d) => new Date(d)));
      intervalZoom.current = chartRef?.current
        ?.getEchartsInstance()
        .getModel()
        .getComponent('xAxis', 0).axis.scale._interval;

      //caluculate cluster
      // 43200000; // day

      // 103680000; // 12 hour
      // 21600000; //6 hour
      // 3600000;  // hour

      // 60000; // minute

      // settgin the cluster query
    }, 1000);

    return () => clearInterval(_timerRef.current);
  }, [
    chartRef.current,
    recordings,
    cloudRecordings,
    isRunning,
    videoProperties?.videoEl
  ]);

  //todo Add debounce for this function

  const handleSelectTime = React.useCallback(
    async (time) => {
      running.current = false;

      dataZoom.current = {
        start: time - HOUR * 12,
        end: time + MIN * 15
      };
      updateMarkLine(time);
      let currentRecordingTime = time;
      if (time < Date.now() + 5000 && time > Date.now() - 5000)
        currentRecordingTime = null;
      _seekRef.current = currentRecordingTime;
      setSeek(currentRecordingTime);
      setCurrentTime(time);

      running.current = true;
    },
    [option]
  );

  const handleZoomIn = React.useCallback(() => {
    if (zoomLevel == 1) return;

    setZoomLevel((prev) => prev - 1);
  }, [option, zoomLevel]);

  const handleZoomOut = React.useCallback(() => {
    if (zoomLevel == 7) return;

    setZoomLevel((prev) => prev + 1);
  }, [option, zoomLevel]);

  const onPlaying = React.useCallback(() => {
    running.current = true;
    setWaiting(false);
  }, []);

  const onLoading = React.useCallback((videoProperties) => {
    setWaiting(true);
    setVideoProperties((prev) => ({ ...prev, ...videoProperties }));
    //stop running only if fetching recording in thact case we want to syn the current time advancement to the recording tim
    if (_seekRef.current) running.current = false;
  }, []);

  const onStop = React.useCallback(() => {
    setIsRunning(false);
    setPlay(false);
  }, []);

  const onPlay = React.useCallback(() => {
    chartRef.current.getEchartsInstance().dispatchAction({
      type: 'brush',
      command: 'clear',
      areas: []
    });
    chartRef.current.getEchartsInstance().dispatchAction({
      type: 'takeGlobalCursor'
    });
    setExportMode(false);
    setWaiting(true);
    setIsRunning(true);
    running.current = true;
    setPlay(true);
    setRefresh((prev) => prev + 1);
  }, [chartRef]);

  const togglePlay = React.useCallback(() => {
    if (play) onStop();
    else onPlay();
  }, [play]);

  const [dtDates, setDtDates] = React.useState({
    min: subDays(new Date(), 30),
    max: new Date()
  });

  React.useEffect(() => {
    currentTimeRef.current = currentTime;
    setDtDates((prev) => ({
      min: prev.min,
      max: new Date()
    }));
    // history.push({ search: `?timestamp=${currentTime}` });
  }, [currentTime]);

  React.useEffect(() => {
    history.push({ search: !seek ? `` : `?timestamp=${Math.ceil(seek)}` });

    setVideoProperties((prev) => ({ ...prev }));
  }, [seek]);

  const [isTimelineOpen, setIsTimelineOpen] = React.useState();

  // see if everything is sycned.....

  let diffBetweenFeeds = videoProperties?.videoEl?.videoTime
    ? ((currentTime - videoProperties?.videoEl?.videoTime) / 1000).toFixed(1)
    : 0;

  return (
    <>
      <Container>
        <div
          className="camera-view"
          css={css`
            display: grid;
            height: 100%;
            width: 100%;
            gap: 1rem;
            grid-auto-rows: 1fr auto;
            align-items: center;
          `}
        >
          <Player
            isZoomMode={isZoomMode}
            setVideoProperties={setVideoProperties}
            videoProperties={videoProperties}
            pipeline={videoProperties?.pipeline}
            videoEl={videoProperties?.videoEl}
            showStats={true}
            currentTime={currentTime}
            currentRecording={currentRecording}
            seek={seek}
            //videoRef={videoRef}
            host={host}
            play={play}
            setPlay={setPlay}
            setRefresh={setRefresh}
            refresh={refresh}
            onPlaying={onPlaying}
            onLoading={onLoading}
            //u can add params here if need
            vapixParameters={vapixParams}
          />

          <Collapsible.Root
            asChild={true}
            defaultOpen={true}
            onOpenChange={setIsTimelineOpen}
            open={isTimelineOpen}
          >
            <div
              className="bottom-section"
              css={css`
                height: 100%;
                width: fit-content;
                display: grid;
                grid-template-rows: 1fr auto;
                align-items: center;
                width: 100%;
                gap: 0.5rem;
              `}
            >
              <section
                css={css`
                  padding-inline: 0.5rem;
                  display: flex;
                  width: fit-content;
                  align-items: center;
                  justify-content: end;
                  width: 100%;
                  height: 100%;
                  height: 40px;
                `}
              >
                <article
                  css={css`
                    display: flex;
                    align-items: center;

                    border: 1px solid var(--neutral-03);
                    border-radius: 4px;
                    margin-right: auto;
                    height: 100%;
                    > * {
                      height: 100%;
                      height: 100%;
                      flex: 1;
                      padding: 0.3rem;
                    }
                  `}
                >
                  {play ? (
                    <button
                      type="button"
                      onClick={onStop}
                      title="Stop Rolling mode"
                    >
                      <Stop size={24} color="var(--red-base)" />
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={onPlay}
                      title="Start Rolling mode"
                    >
                      <Play size={24} color="var(--prinmary-base)" />
                    </button>
                  )}
                  <button
                    type="button"
                    disabled={zoomLevel == 1}
                    title="Zoom in"
                    css={css`
                      &:disabled {
                        opacity: 50%;
                      }
                      border-left: 1px solid var(--neutral-03);
                    `}
                    onClick={async () => {
                      handleZoomIn(currentTime);
                    }}
                  >
                    <ZoomIn size={20} />
                  </button>

                  <button
                    type="button"
                    disabled={zoomLevel == 7}
                    title="Zoom out"
                    css={css`
                      &:disabled {
                        opacity: 50%;
                      }
                      border-left: 1px solid var(--neutral-03);
                    `}
                    onClick={async () => {
                      handleZoomOut(currentTime);
                    }}
                  >
                    <ZoomOut size={20} />
                  </button>
                  <button
                    type="button"
                    title="Refresh "
                    css={
                      waiting
                        ? css`
                            border-left: 1px solid var(--neutral-03);
                            svg {
                              animation: ${bounce} 1s ease infinite;
                            }
                          `
                        : css`
                            border-left: 1px solid var(--neutral-03);
                          `
                    }
                    onClick={() => setRefresh((prev) => prev + 1)}
                  >
                    <IconRefresh size={20} />
                  </button>
                  <button
                    type="button"
                    title="Zooming"
                    css={css`
                      border-left: 1px solid var(--neutral-03);
                      background: ${isZoomMode
                        ? 'var(--light--others--red-300)'
                        : 'white'};
                    `}
                    onClick={() => setIsZoomMode((prev) => !prev)}
                  >
                    <IconPhotoSearch
                      size={20}
                      color={
                        isZoomMode
                          ? 'var(--light--others--red-400-base)'
                          : 'currentColor'
                      }
                    />
                  </button>

                  {/* <button
                  title="Export"
                  css={css`
                    border-left: 1px solid var(--neutral-03);
                  `}
                  onClick={(e) => {
                    onStop(e);

                    const opt = chartRef.current
                      .getEchartsInstance()
                      .getOption();

                    const option = { ...opt, dataZoom: [] };

                    chartRef.current
                      .getEchartsInstance()
                      .setOption(option, true);

                    chartRef.current.getEchartsInstance().dispatchAction({
                      type: 'takeGlobalCursor',
                      key: 'brush',
                      brushOption: {
                        brushType: 'lineX',
                        brushMode: 'single',
                        removeOnClick: false,
                        brushStyle: {
                          borderWidth: 8,
                          color: 'rgba(120,140,180,0.3)',
                          borderColor: 'rgba(120,140,180,0.8)'
                        }
                      }
                    });
                  }}
                >
                  <EmojiExpressionless /> {exportMode ? '1' : '0'}
                </button> */}
                </article>
                <RecordingExport
                  host={host}
                  exportMode={exportMode}
                  setExportTime={setExportTime}
                  exportTime={exportTime}
                  onStop={onStop}
                  onPlay={onPlay}
                  chartRef={chartRef}
                  setExportMode={setExportMode}
                />

                <button
                  css={css`
                    border: 1px solid var(--neutral-03);
                    border-radius: 4px;
                    margin-right: 2rem;
                    height: 100%;
                    width: 130px;
                    > div {
                      display: flex;
                      align-items: center;
                      padding: 0.3rem;
                      width: 100%;
                      gap: 0.3rem;
                      justify-content: center;
                    }
                  `}
                  onClick={async () => {
                    setExportMode(false);
                    running.current = false;
                    dataZoom.current = {
                      start: Date.now() - MIN * 15,
                      end: Date.now() + MIN * 15
                    };
                    updateMarkLine(Date.now());
                    setCurrentTime(Date.now());
                    setCurrentRecording(null);
                    _seekRef.current = null;
                    setSeek(null);
                    setPlay(true);
                    setVapixParams({ recordingid: null, seek: null });
                    running.current = true;
                  }}
                >
                  {seek ? (
                    <div>
                      <PlayCircle size={24} />
                      LIVE
                    </div>
                  ) : (
                    <div>
                      <div
                        css={css`
                          background: var(--red-base);
                        `}
                        className="circle pulse"
                      ></div>
                      LIVE
                      <time
                        css={css`
                          font-size: 12px;
                          opacity: 80%;
                        `}
                      >
                        ( -{isNaN(diffBetweenFeeds) ? 0 : diffBetweenFeeds}
                        <span> sec</span>)
                      </time>
                    </div>
                  )}
                </button>
                {/* <div>CUR: {currentRecording}</div> */}

                <article
                  css={css`
                display: flex;
                align-items: center;

                height: 40px;

                border: 1px solid var(--neutral-03);
                border-radius: 4px;
                > * {
                  height: 100%;
                  flex: 1;
                  padding: 0.3rem;
              `}
                >
                  <button
                    css={css`
                      border-right: 1px solid var(--neutral-03);
                    `}
                    onClick={() => {
                      let minus = currentTime - 60 * 1000 * 60 * 5;
                      handleSelectTime(minus);
                    }}
                  >
                    <ChevronDoubleLeft />
                  </button>
                  <button
                    css={css`
                      border-right: 1px solid var(--neutral-03);
                    `}
                    onClick={() => {
                      let minus30 = currentTime - 60 * 1000 * 60;

                      handleSelectTime(minus30);
                      {
                        /* chartRef.current.getEchartsInstance().dispatchAction({
                    type: 'dataZoom',
                    dataZoomIndex: [0, 1],
                    startValue: minus30 - 60 * 1000 * 60,
                    endValue: minus30 + 60 * 1000 * 60
                  }); */
                      }
                    }}
                  >
                    <ChevronLeft />
                  </button>

                  <div>
                    <SeekDateTime
                      videoEl={videoProperties?.videoEl}
                      dtDates={dtDates}
                      daysWithRecording={daysWithRecording}
                      currentTime={currentTime}
                      handleSelectTime={handleSelectTime}
                    />
                  </div>
                  <button
                    css={css`
                      border-left: 1px solid var(--neutral-03);
                    `}
                    onClick={() => {
                      let plus30 = currentTime + 60 * 1000 * 60;
                      if (plus30 > Date.now()) return;
                      handleSelectTime(plus30);
                    }}
                  >
                    <ChevronRight />
                  </button>
                  <button
                    css={css`
                      border-left: 1px solid var(--neutral-03);
                    `}
                    onClick={() => {
                      let plus30 = currentTime + 60 * 1000 * 60 * 5;
                      if (plus30 > Date.now()) return;
                      handleSelectTime(plus30);
                    }}
                  >
                    <ChevronDoubleRight />
                  </button>
                  <Collapsible.Trigger asChild={true}>
                    <button
                      css={css`
                        border-left: 1px solid var(--neutral-03);
                        &[data-state='closed'] {
                          svg {
                            transform: rotate(180deg);
                          }
                        }
                      `}
                      onClick={() => null}
                    >
                      <ChevronDown />
                    </button>
                  </Collapsible.Trigger>
                </article>
              </section>
              <Collapsible.Content asChild={true}>
                <div
                  css={css`
                    background: transparent;
                  `}
                >
                  <Timeline
                    videoEl={videoProperties?.videoEl}
                    setExportTime={setExportTime}
                    exportTime={exportTime}
                    setVapixParams={setVapixParams}
                    //showLoading={pipeline && !canplay}
                    zoomLevelRef={zoomLevelRef}
                    setZoomLevel={setZoomLevel}
                    play={play}
                    setPlay={setPlay}
                    running={running}
                    ref={chartRef}
                    option={option}
                    dataZoom={dataZoom}
                    updateMarkLine={updateMarkLine}
                    setOption={setOption}
                    setCurrentTime={setCurrentTime}
                    setCurrentRecordingTime={setCurrentRecordingTime}
                    currentTimeRef={currentTimeRef}
                    setSeek={setSeek}
                    seek={seek}
                    _seekRef={_seekRef}
                    recordingData={recordingData}
                    renderItem={renderItem}
                    recordings={recordings}
                  ></Timeline>
                  <div
                    className="legend"
                    css={css`
                      margin-top: 0.5rem;
                      font-size: 12px;
                      display: flex;
                      gap: 1rem;
                      align-items: center;
                      span {
                        display: flex;
                        align-items: center;
                        gap: 0.3rem;
                      }
                    `}
                  >
                    <span>
                      <Activity color="#6428e4" size={12} />
                      Motion
                    </span>
                    <span>
                      <SquareFill color="var(--primary-base)" size={12} />
                      Edge
                    </span>
                    <span>
                      <SquareFill color="#135fa6" size={12} />
                      Cloud
                    </span>
                  </div>
                </div>
              </Collapsible.Content>
            </div>
          </Collapsible.Root>
        </div>
      </Container>
    </>
  );
};

export default PlayerWrapper;
