import React from 'react';
import { useQueryWrapper } from 'utils/ajax';
import { useDebounce } from 'hooks/debounce-hook';

const useAsyncSelection = (props) => {
  const { fetchFn, selectAll, queryParams = {} } = props;

  if (!fetchFn) return {};

  if (props.selectAll && !props.isMulti)
    throw Error('Must be multi when choosing slect all');

  const transformData = (data) => {
    if (!data.data) return data;
    const obj = { ...data };

    if (selectAll)
      obj.data = [
        {
          [props.idx || 'id']: 0,
          value: 'select-all',
          label: 'Select All',
          [props.label || 'name']: 'Select All'
        },
        ...obj.data
      ];
    // enable this if u want to slice options on backend
    // if (props.maxOptions) obj.data = obj.data.slice(0, props.maxOptions);

    if (typeof props.transformData == 'function')
      obj.data = props.transformData(obj.data);

    return obj;
  };

  const [filter, setFilter] = React.useState('');
  const [defaultValue, setDefaultValue] = React.useState();
  // needed to store first result length to decide if searches are Ajax based
  const [firstDataLength, setFirstDataLength] = React.useState();

  const { data, isLoading, isError, remove, refetch } = useQueryWrapper(
    [
      props?.queryKey,
      props.fetchFilters,
      props.form?.initialValues?.[props.field.name] || props.defaultOption,
      props.field?.name || props.name,
      filter
    ],
    () =>
      fetchFn({
        all: filter ?? '',
        selection:
          props.form?.initialValues?.[props.field.name] || props.defaultOption,
        ...props.fetchFilters
      }),
    {
      select: transformData,
      cacheTime: props.form ? 0 : 5 * 60 * 1000, // Do not save this in cache
      ...props?.queryParams,
      enabled: props.silent ? (filter ? true : false) : true,
      noToast: true
    }
  );
  const [allSelected, setAllSelected] = React.useState(false);
  const [value, setValue] = React.useState();
  const { setInput, input } = useDebounce(
    firstDataLength >= 30 ? setFilter : null
  );

  const selectAllValues = React.useCallback(() => {
    setValue([
      {
        [props.idx || 'id']: 0,
        [props.label || 'name']: 'Select All',
        value: 'select-all',
        label: 'select-all'
      }
    ]);
    setAllSelected(true);
    if (typeof props.onSelectAll == 'function') props.onSelectAll();
  }, []);

  const deselectAllValues = React.useCallback(() => {
    setValue([]);
    setAllSelected(false);
    if (typeof props.onDeselectAll == 'function') props.onDeselectAll();
  }, []);

  const selectValue = React.useCallback((val) => {
    setValue(val);
    setAllSelected(false);
  }, []);

  const isOptionDisabled = React.useCallback(
    (opt) => (allSelected && opt.value !== 'select-all') || opt.isFixed,
    [allSelected]
  );

  //not used
  // const orderOptions = (values) => {
  //   return values
  //     .filter((v) => v.isFixed)
  //     .concat(values.filter((v) => !v.isFixed));
  // };

  const onChange = React.useCallback(
    (val, { removedValue, option, action }) => {
      if (action == 'clear') {
        // if form clear allSites option -- since when u use the clear button the variable stays.
        props.form && props.form?.setFieldValue('allSites', undefined);

        // Is Fixed support
        if (defaultValue && Array.isArray(defaultValue)) {
          // only in multi
          val = defaultValue?.filter((v) => v.isFixed);
          selectValue(defaultValue?.filter((v) => v.isFixed));
        } else selectValue(null);
      } else {
        if (props.maxOptions && val?.length > props.maxOptions) return;

        option?.value === 'select-all'
          ? selectAllValues()
          : removedValue?.value === 'select-all'
          ? deselectAllValues()
          : selectValue(val);
      }

      //only apply form if not a select all option

      if (option?.value !== 'select-all')
        props.form?.setFieldValue(
          props.field.name,
          !props.isMulti
            ? val?.[props.idx || 'id'] ?? null
            : val.map((i) => (props.idx ? i[props.idx] : i.id))
        );
      if (props.onChange instanceof Function) props?.onChange(val);
      if (props.onChangeFunc instanceof Function) props?.onChangeFunc(val);
    },
    [selectAllValues, deselectAllValues, selectValue, defaultValue]
  );

  React.useEffect(() => {
    // u can transfer default option here to set the deafult

    if (!props.form) return;
    if (!data) return;

    let initialData = data?.data || data;

    const filteredData = props.isMulti
      ? initialData.filter((i) => {
          return (
            props.form.values?.[props.field.name]?.includes(
              i[props.idx] || parseInt(i.id) || i.id
            ) || props.defaultOption?.includes(i[props.idx] || parseInt(i.id))
          );
        })
      : initialData.find(
          (i) =>
            (i[props.idx] || parseInt(i.id) || i.id) ==
            props.form.values?.[props.field.name]
        ) || null;

    if (props.form && !defaultValue) {
      setDefaultValue(filteredData);
    }
  }, [data, props?.form?.values]);

  React.useEffect(() => {
    if (!defaultValue) return;

    if (selectAll && defaultValue?.[0]?.value == 'select-all')
      selectAllValues();
    setValue(defaultValue);

    props.form?.setFieldValue(
      props.field.name,
      !props.isMulti
        ? defaultValue?.[props.idx || 'id']
        : defaultValue.map((i) => (props.idx ? i[props.idx] : i.id))
    );
  }, [defaultValue]);

  React.useEffect(() => {
    if (!firstDataLength) setFirstDataLength(data?.data?.length);
  }, [data]);

  return {
    refetch,
    remove,
    isError,
    firstDataLength,
    input,
    setInput,
    value,
    setValue,
    data,
    isLoading,
    onChange,
    isOptionDisabled
  };
};

export default useAsyncSelection;
