import React from 'react';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */

export default ({
  data,
  COLORS = ['#6F49C0', '#F5861F', '#49B7BE', '#FFCC47', '#2480D6']
}) => {
  return (
    <article>
      <ul
        css={css`
          display: flex;
          flex-direction: column;
          gap: 0.8rem;
          padding: 2rem;
          width: 100%;
          li {
            display: grid;
            grid-template-columns: 4fr 1fr 1fr;
            justify-content: space-between;
            align-items: center;
            gap: 1rem;
            div {
              font-family: 'Inter';
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 15px;
              color: #7f8795;
            }
            div:last-of-type {
              text-align: end;
            }
          }
        `}
      >
        {data?.map((i, idx) => (
          <li key={idx}>
            <div
              css={css`
                display: flex;
                align-items: center;
                gap: 0.5rem;
                &:before {
                  content: '';
                  display: block;
                  height: 20px;
                  border-radius: 5px;
                  width: 20px;
                  max-width: 20px;
                  max-height: 20px;
                  min-width: 20px;
                  min-height: 20px;
                  background: ${COLORS[idx]};
                }
              `}
            >
              {i.name}
            </div>

            <div
              css={css`
                white-space: nowrap;
              `}
            >
              {i.value} Entrances
            </div>
            <div>
              {Math.trunc(
                Number(i.value) /
                  (data.map((i) => Number(i.value)).reduce((a, b) => a + b, 0) /
                    100)
              )}
              %
            </div>
          </li>
        ))}
      </ul>
    </article>
  );
};
