import React from "react";
import Template from "base/template";
import BadgeForm from "views/badging/form";
import { Button, CancelButton } from "components/buttons";
import { Redirect } from "react-router-dom";
import FormProvider from "providers/form-provider";
import { useMutationWrapper } from "utils/ajax";
import { useRedirect } from "hooks/redirect-hook";
import { dateTimeLocalSQLNoSeconds } from "utils/time";
import Badge from "api/badge";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import PageTitle from "components/page-title";
import Content from "base/content";
import { TextField } from "components/form";
import Breadcrumbs from "components/breadcrumbs";

export const DEFAULT_TEMPLATE = {
  v1: {
    avatar: { top: 0, left: 0, color: "#000000", active: true },
    name: { top: 120, left: 0, color: "#000000", active: true },
    department: { top: 140, left: 0, color: "#000000", active: true },
    email: { top: 160, left: 0, color: "#000000", active: true },
    phone: { top: 180, left: 0, color: "#000000", active: true },
    custom: { top: 200, left: 0, color: "#000000" },
  },
  h1: {
    avatar: {
      top: 0,
      left: 0,
      color: "#000000",
      active: true,
    },
    name: {
      top: 108,
      left: -1,
      color: "#000000",
    },
    department: {
      top: 130,
      left: -1,
      color: "#000000",
      active: true,
    },
    email: {
      top: 147,
      left: 0,
      color: "#000000",
      active: true,
    },
    phone: {
      top: 167,
      left: -1,
      color: "#000000",
      active: true,
    },
    custom: {
      top: 150,
      left: 150,
      color: "#000000",
    },
  },
};

const Save = (props) => {
  let badge = props?.location?.state?.badge || null;

  let { isSuccess, isLoading, mutate } = useMutationWrapper(
    ["badge", "save"],
    (input) => Badge.save(input, badge?.id),
    {}
  );

  const SCHEMA = Yup.object().shape({
    description: Yup.string().required(),
    layout: Yup.string().required(),
  });

  const DEFAULT_LAYOUT = "v1";

  const template = badge?.template
    ? JSON.parse(badge.template)
    : DEFAULT_TEMPLATE[DEFAULT_LAYOUT];

  const INITIAL_VALUES = {
    description: badge?.description ?? "",
    layout: badge?.layout ?? DEFAULT_LAYOUT,
    background_color: badge ? badge.background_color : "#ffffff",
    background: badge ? Boolean(badge.background_color) : true,
    background_image_url: badge?.background_image_url,
    background_image: badge ? Boolean(badge?.background_image_url) : false,
    ...template,
  };

  const { redirect, setRedirect } = useRedirect(isSuccess);

  return (
    <Template {...props}>
      {redirect && <Redirect to="/badges" />}
      <Content {...props}>
        <PageTitle>{badge ? "Save" : "Add"} Badge Template</PageTitle>
        <Breadcrumbs crumbs={props.crumbs} />
        <Formik
          validationSchema={SCHEMA}
          initialValues={INITIAL_VALUES}
          onSubmit={(values) => {
            values.template = {
              avatar: values.avatar,
              name: values.name,
              department: values.department,
              phone: values.phone,
              email: values.email,
              custom: values.custom,
            };

            mutate(values);
          }}
        >
          {({ handleSubmit }) => (
            <FormProvider
              name="badge"
              mutateFunc={handleSubmit}
              disabled={isLoading}
            >
              <Field name="description" as={TextField} />
              <BadgeForm badge={badge} />

              <footer>
                <Button type="submit">Save</Button>
                <CancelButton onClick={() => setRedirect(true)} />
              </footer>
            </FormProvider>
          )}
        </Formik>
      </Content>
    </Template>
  );
};

export default Save;
