import React from "react";

import Intercom from "api/intercom";
import Template from "base/template";
import { Button, CancelButton, SecondaryButton } from "components/buttons";
import { Redirect } from "react-router-dom";
import FormProvider from "providers/form-provider";
import { useMutationWrapper } from "utils/ajax";
import { useRedirect } from "hooks/redirect-hook";
import LicensePlate from "api/license_plate";

import { Formik } from "formik";
import * as Yup from "yup";
import Content from "base/content";
import LicensePlateForm from "views/license-plates/form";
import PageTitle from "components/page-title";
import Breadcrumbs from "components/breadcrumbs";

const Save = (props) => {
  let lp = props?.location?.state?.lp || null;

  const SCHEMA = Yup.object().shape({
    text: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    enabled: Yup.string().required("Required"),
  });

  const INITIAL_VALUES = {
    ...lp,
    enabled: lp ? lp.enabled : "1",
    text: lp?.text,
    description: lp?.description,
    person: lp?.person_id,
  };

  const { isSuccess, isLoading, mutate } = useMutationWrapper(
    ["license-plate", "save"],
    (input) => LicensePlate.save(input, lp?.id),
    {}
  );

  const { redirect, setRedirect } = useRedirect(isSuccess);

  return (
    <Template {...props}>
      <Content {...props}>
        <PageTitle>{lp ? lp.description : "License Plate Add"}</PageTitle>
        <Breadcrumbs crumbs={props.crumbs} />
        {redirect && <Redirect to="/license-plates" />}
        <Formik
          validationSchema={SCHEMA}
          initialValues={INITIAL_VALUES}
          onSubmit={(values) => {
            mutate(values);
          }}
        >
          {({ handleSubmit }) => (
            <FormProvider mutateFunc={handleSubmit} disabled={isLoading}>
              <LicensePlateForm lp={lp} />
              <footer>
                <Button type="submit">Submit</Button>
                <CancelButton onClick={() => setRedirect(true)} />
              </footer>
            </FormProvider>
          )}
        </Formik>
      </Content>
    </Template>
  );
};

export default Save;
