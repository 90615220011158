import React from 'react';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react **/
import ReactJson from 'react-json-view';
import Controller from 'api/controller';

import InfiniteTableProvider from 'providers/headless-infinite-table-provider';
import InfiniteList from 'components/headless-infinite-list';
import { getSelectionColumn } from 'components/table-selection-column';
import { dateTimeDif, dateTimeShort } from 'utils/time';
import { Badge } from 'components/badge';

const styles = css`
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: 24px;
  gap: 24px;
  height: 100%;

  overflow: auto;

  > .react-json-view {
    border: 1px solid #eee;
    border-radius: 8px;
    padding: 12px;
    align-self: stretch;
    overflow: auto;
  }

  > .list-holder li {
    cursor: pointer;
    padding: 12px 0;
    display: grid;
    grid-template-columns: 24px 70px 3fr;
    grid-template-rows: 1fr 1fr;
    gap: 8px 18px;
    align-items: center;
    background: var(--neutral-09);
    border-bottom: 1px solid var(--neutral-12);
    font-size: 13px;

    &:first-of-type {
      border-top: 1px solid var(--neutral-12);
    }

    input {
      grid-row: span 2;
    }

    [role='badge'] {
      grid-row: span 2;
    }

    > time {
      display: flex;
      gap: 16px;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
    }
  }
`;

function SetupReports({ controllerId, ...props }) {
  return (
    <article css={styles}>
      <InfiniteTableProvider
        initialSelection={{ 0: true }}
        columns={[
          getSelectionColumn(),
          {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ value }) =>
              value == 'ok' ? (
                <Badge color="green">OK</Badge>
              ) : (
                <Badge color="red">FAILED</Badge>
              )
          },
          {
            Header: 'Time',
            accessor: 'started',
            Cell: ({ value }) => (
              <time title={dateTimeShort(new Date(value))}>
                {dateTimeDif(new Date(value), 'auto', false)}
              </time>
            )
          },
          {
            Header: 'User',
            accessor: 'userName'
          }
        ]}
        id={['controller-config-history', controllerId]}
        query={Controller.getConfigHistory.bind(null, controllerId)}
        {...props}
      >
        {({ rows, prepareRow, selectedFlatRows }) => {
          return (
            <>
              <InfiniteList>
                <>
                  {rows.map((row, idx) => {
                    prepareRow(row);
                    return (
                      <>
                        <li
                          key={idx}
                          onClick={() => row.toggleRowSelected(true)}
                        >
                          {row.cells.map((cell, idx) => {
                            return (
                              <React.Fragment key={idx}>
                                {cell.render('Cell', {})}
                              </React.Fragment>
                            );
                          })}
                        </li>
                      </>
                    );
                  })}
                </>
              </InfiniteList>

              {selectedFlatRows?.[0] ? (
                <ReactJson
                  iconStyle="circle"
                  src={selectedFlatRows?.[0].original}
                  name={null}
                />
              ) : (
                <p>No Report selected</p>
              )}
            </>
          );
        }}
      </InfiniteTableProvider>
    </article>
  );
}

export default SetupReports;
