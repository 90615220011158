import { dataFetchQuery } from 'utils/ajax';
import { API_HOST_URL } from './api-url';
const API_URL = API_HOST_URL + '/site_api';
import { callAPI } from 'utils/ajax';

const Site = {
  search(input = {}) {
    return callAPI('GET', `/site`, input);
  },

  getAll(input) {
    return callAPI('GET', `/site/all`, input);
  },

  get(id) {
    return callAPI('GET', `/site/${id}`);
  },

  stats(input = {}) {
    return callAPI('GET', `/site/stats`, input);
  },

  add(input = {}) {
    return callAPI('POST', `/site`, input);
  },

  edit(input, id) {
    return callAPI('PUT', `/site/${id}`, input);
  },

  delete(id) {
    return callAPI('DELETE', `/site/${id}`);
  },

  lockDown(id) {
    return callAPI('POST', `/site/lockDown/${id}`);
  },

  lockDownRelease(id) {
    return callAPI('POST', `/site/lockDownRelease/${id}`);
  },

  focus(id) {
    return callAPI('POST', `/site/focus/${id}`);
  },

  exitFocus() {
    return callAPI('POST', `/site/exitFocus`);
  },

  saveMapping(id, input = {}) {
    return callAPI('POST', `/site/saveMapping/${id}`, input);
  },

  oldApiGet(input = {}) {
    return dataFetchQuery(`${API_URL}/get`, input);
  }
};

export default Site;
