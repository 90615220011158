import GenericDoor from 'api/generic_door';
import Camera from 'api/camera';
import { marker } from 'leaflet';
import React from 'react';
import { FeatureGroup, Marker, useMap, ImageOverlay } from 'react-leaflet';
import { useQueryWrapper } from 'utils/ajax';
import GeomanWrapper from './geoman-layer';
import MapLoader from './map-loader';
import SiteControls from './site-controls';
import ViewLayer from './view-layer';
import DoorLayer from './door-layer';
import CameraLayer from './camera-layer';
import Aux from 'api/auxiliary';
import AuxiliaryLayer from './auxiliary-layer';
import ViewLayerFilter from './view-layer-filter';
import SiteStats from './site-stats';
import { useAuth } from 'providers/auth';

const SiteLayer = ({
  editLayerRef,
  viewLayerRef,
  markerLayerRef,
  selectedSite,
  isEdit,
  setIsEdit,
  sites,
  searchVisible,
  image,
  setDoors,
  setCameras,
  setImage,
  setSelectedLayer,
  selectedLayer,
  pathOptions,
  setPathOptions,
  modes,
  setModes,

  setAuxiliaries,
  baseLayers,
  setBaseLayers
}) => {
  const [geojson, setGeojson] = React.useState();
  // const [image, setImage] = React.useState();

  const { hasAllPermissions } = useAuth();

  const { data: doors, isLoading } = useQueryWrapper(
    ['site', 'doors', selectedSite?.id],
    () => GenericDoor.getAll({ site: selectedSite.id }),
    {
      enabled: Boolean(selectedSite),
      cacheTime: 60000,
      staleTime: 60000
    }
  );
  React.useEffect(() => setDoors(doors), [doors]);

  const { data: cameras } = useQueryWrapper(
    ['site', 'cameras', selectedSite?.id],
    () => Camera.getAll({ site: selectedSite.id }),
    {
      enabled: Boolean(selectedSite) && hasAllPermissions('cameraView'),
      cacheTime: 60000,
      staleTime: 60000
    }
  );

  const { data: auxiliaries } = useQueryWrapper(
    ['site', 'auxiliaries', selectedSite?.id],
    () => Aux.getAll({ site: selectedSite.id }),
    {
      enabled: Boolean(selectedSite),
      cacheTime: 60000,
      staleTime: 60000
    }
  );
  React.useEffect(() => setDoors(doors), [doors]);
  React.useEffect(() => setCameras(cameras), [cameras]);
  React.useEffect(() => setAuxiliaries(auxiliaries), [auxiliaries]);

  const map = useMap();

  React.useEffect(() => {
    if (!selectedSite || !sites) return;

    const currentSite = sites.find((s) => s.id == selectedSite.id);

    let json = currentSite.geojson ?? {
      type: 'FeatureCollection',
      features: []
    };
    setImage(json.image ?? null);
    setGeojson(json);

    map.invalidateSize();
  }, [selectedSite, sites]);

  React.useEffect(() => {
    if (selectedSite.lat && selectedSite.lng)
      map.setView([selectedSite.lat, selectedSite.lng], 20);
  }, [selectedSite]);

  React.useEffect(() => {
    map.invalidateSize();
  }, [isEdit]);

  const [filters, setFilters] = React.useState({
    doors: true,
    auxiliaries: true,
    cameras: true,
    online: true,
    offline: true,
    tooltip: false,
    showBaseLayer: true
  });
  const [filterOpen, setFilterOpen] = React.useState(true);

  // React.useEffect(() => {
  //   if (!data?.data) return;

  //   // let doors = json.features.filter(
  //   //   (f) => f.geometry.type == 'Point' && f.properties.type == 'door'
  //   // );

  //   data.data.forEach((dbDoor, idx) => {
  //     function getCoords(idx) {
  //       const SQUARE = 5;
  //       const DISTANCE = 0.000003;
  //       let lat, lng;
  //       if (idx % SQUARE == 0) {
  //         lng = parseFloat(currentSite.lng) + DISTANCE * idx;
  //       } else {
  //         lng =
  //           parseFloat(currentSite.lng) +
  //           DISTANCE * Math.round(idx / SQUARE) * SQUARE;
  //       }

  //       lat = parseFloat(currentSite.lat) - DISTANCE * (idx % SQUARE);

  //       return [lng, lat];
  //     }

  //     let index = json.features.findIndex(
  //       (d) => d.properties.id == dbDoor.id && d.properties.type == 'door'
  //     );

  //     if (index !== -1) {
  //       //door found
  //       json.features[index] = {
  //         ...json.features[index],
  //         properties: {
  //           ...json.features[index]['properties'],
  //           shape: 'marker',
  //           type: 'door',
  //           ...dbDoor
  //         }
  //       };
  //     } else {
  //       // only push if its first time
  //       // if (!currentSite.geojson)
  //       //first time or new doors.....
  //       json.features.push({
  //         type: 'Feature',

  //         geometry: {
  //           type: 'Point',
  //           coordinates: getCoords(idx)
  //         },
  //         properties: {
  //           hidden: !currentSite.geojson ? false : true,
  //           shape: 'marker',
  //           type: 'door',
  //           ...dbDoor
  //         }
  //       });
  //     }
  //   });

  //   // remove doors that are not in db
  //   let newFeatures = json.features.filter((f) => {
  //     if (f.geometry.type == 'Point' && f.properties.type == 'door')
  //       if (!data.data.find((dbDoor) => dbDoor.id == f.properties.id))
  //         return null;
  //       else return f;
  //     return f;
  //   });

  //   json.features = newFeatures;

  //   setImage(json.image);

  //   setGeojson(json);

  //   setDoors(
  //     json.features.filter(
  //       (f) => f.properties.type == 'door' && f.geometry.type == 'Point'
  //     )
  //   );

  //   setDoorMarkers(
  //     json.features.filter(
  //       (f) => f.properties.type == 'door' && f.geometry.type == 'Point'
  //     )
  //   );

  //   // make sure its same doors that are in data
  // }, [data, sites]);

  return (
    <>
      {isLoading && <MapLoader />}
      {/* <SiteStats
        filterOpen={filterOpen}
        setFilterOpen={setFilterOpen}
        selectedSite={selectedSite}
      /> */}
      <SiteControls
        baseLayers={baseLayers}
        setBaseLayers={setBaseLayers}
        filters={filters}
        markerLayerRef={markerLayerRef}
        setFilters={setFilters}
        setImage={setImage}
        image={image}
        geojson={geojson}
        setIsEdit={setIsEdit}
        isEdit={isEdit}
        selectedSite={selectedSite}
        currentLayerRef={isEdit ? editLayerRef : viewLayerRef}
      />
      {!isEdit && (
        <FeatureGroup ref={markerLayerRef}>
          {geojson && (
            <DoorLayer doors={doors} geojson={geojson} filters={filters} />
          )}
          {geojson && (
            <CameraLayer
              cameras={cameras}
              geojson={geojson}
              filters={filters}
            />
          )}
          {geojson && (
            <AuxiliaryLayer
              auxiliaries={auxiliaries}
              geojson={geojson}
              filters={filters}
            />
          )}
        </FeatureGroup>
      )}
      <FeatureGroup key={JSON.stringify(geojson)}>
        {!isEdit && (
          <ViewLayer
            doors={doors}
            cameras={cameras}
            ref={viewLayerRef}
            geojson={geojson}
            selectedSite={selectedSite}
          />
        )}

        {isEdit && (
          <GeomanWrapper
            modes={modes}
            setModes={setModes}
            ref={editLayerRef}
            isEdit={isEdit}
            geojson={geojson}
            setGeojson={setGeojson}
            selectedSite={selectedSite}
            searchVisible={searchVisible}
            setSelectedLayer={setSelectedLayer}
            selectedLayer={selectedLayer}
            pathOptions={pathOptions}
            setPathOptions={setPathOptions}
          />
        )}

        {image && (
          <ImageOverlay
            url={decodeURIComponent(image)}
            bounds={map.getBounds()}
          />
        )}
      </FeatureGroup>
    </>
  );
};

export default SiteLayer;
