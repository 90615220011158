import React from 'react';
import AsyncSelect from 'components/headless-select/async';
import IOPort from 'api/ioport';
import { withField } from './with-field';
import { components } from 'react-select';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Badge } from 'components/badge';

const Option = (props) => {
  return (
    <>
      <components.Option {...props}>
        <span
          css={css`
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            color: var(--neutral-00);
            input[type='checkbox'] {
              width: 20px;
              height: 20px;
              accent-color: var(--primary-base);
              opacity: 0.7;
              cursor: pointer;
              grid-row: span 2;
            }
            > div {
              display: flex;
              flex-direction: column;
            }
            display: flex;
            gap: 8px;
            align-items: center;

            .device-name {
              color: var(--neutral-00);
              font-weight: 500;
            }
            .io-name {
              font-size: 12px;
            }
            div:last-of-type {
              margin-left: auto;
              > label {
                font-size: 10px;
                text-align: center;
              }
            }
          `}
        >
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />
          <div>
            <span className="device-name">{props.data.device_name}</span>
            <span className="io-name">{props.data.Name}</span>
          </div>
          <div>
            <Badge color={props.data.Direction == 'input' ? 'blue' : 'warning'}>
              {props.data.Direction}
            </Badge>
            {(props.data.generic_door_id || props.data.auxiliary_id) &&
              props.selectProps.selectedId != props.data.id && (
                <label>assigned</label>
              )}
          </div>
        </span>
      </components.Option>
    </>
  );
};

const IOPortSelect = (props) => {
  return (
    <AsyncSelect
      filterOption={(cand, input) => {
        // sepcial filtergin option
        if (input) {
          if (!cand?.label) return false;
          let dn = cand.data.device_name.toLowerCase();
          let label = cand.label.toLowerCase();
          let i = input.toLowerCase();
          return dn.includes(i) || label.includes(i);
        }

        return true;
      }}
      title="I/O Port"
      idx="id"
      label="Name"
      placeholder="Search device or io port..."
      searchTerm="device_name"
      isMulti={false}
      fetchFn={IOPort.search}
      itemComponents={{
        Option,
        IndicatorSeparator: null
      }}
      {...props}
    />
  );
};

export default IOPortSelect;
