import React from 'react';
import { useQueryWrapper } from 'utils/ajax';

import { css } from '@emotion/react';
import ReactECharts from 'echarts-for-react';
import Sensor from 'api/sensor';
import { useQueries } from 'react-query';
import GaugeLoader from './gauge-loader';
import * as echarts from 'echarts';

/** @jsxImportSource @emotion/react */
export default ({ min, max, current, topic }) => {
  function findClosestNumber(singleNumber, numberArray) {
    let closestNumber = numberArray[0]; // Assume the first number is the closest initially

    for (let i = 1; i < numberArray.length; i++) {
      const currentNumber = numberArray[i];

      if (
        Math.abs(currentNumber - singleNumber) <
        Math.abs(closestNumber - singleNumber)
      ) {
        closestNumber = currentNumber;
      }
    }

    return closestNumber;
  }

  let color =
    findClosestNumber(current, [min, max]) == min ? '#ea3737' : '#00a96c';

  // const option = {
  //   animation: false,
  //   series: [
  //     {
  //       name: topic,
  //       radius: '100%',
  //       type: 'gauge',
  //       center: ['50%', '60%'],
  //       startAngle: 180,
  //       endAngle: 0,
  //       min: min - 1,
  //       max: max + 1,
  //       splitNumber: 12,
  //       itemStyle: {
  //         color,
  //         lineStyle: {
  //           width: 25
  //         }
  //       },
  //       progress: {
  //         show: true,
  //         width: 25
  //       },

  //       pointer: {
  //         show: true,
  //         width: 5,
  //         icon: 'arrow',
  //         length: '50%'
  //       },
  //       axisLine: {
  //         lineStyle: {
  //           // color: [
  //           //   [0.2, '#00C853'],
  //           //   [0.4, '#FFD740'],
  //           //   [1, '#DAE0E6']
  //           // ],
  //           width: 15
  //         }
  //       },
  //       axisTick: {
  //         show: false,
  //         distance: -45,
  //         splitNumber: 5,
  //         lineStyle: {
  //           width: 2,
  //           color: '#999'
  //         }
  //       },
  //       splitLine: {
  //         show: false,
  //         distance: -80,
  //         length: 22,
  //         lineStyle: {
  //           width: 3,
  //           color: '#999'
  //         }
  //       },
  //       axisLabel: {
  //         formatter: (value) => {
  //           if (value === min - 1 || value === max + 1) {
  //             return value.toFixed(2);
  //           }
  //           return '';
  //         },
  //         show: true,
  //         distance: -8,
  //         color: '#999',
  //         fontSize: 11,
  //         interval: 'auto'
  //       },
  //       anchor: {
  //         show: false
  //       },
  //       title: {
  //         show: false
  //       },
  //       detail: {
  //         valueAnimation: true,
  //         width: '60%',
  //         lineHeight: 12,
  //         borderRadius: 8,
  //         offsetCenter: [0, '35%'],
  //         fontSize: 16,
  //         fontWeight: 'bolder',
  //         formatter: (v) => (!Number.isInteger(v) ? v.toFixed(2) : v),
  //         color: 'inherit'
  //       },
  //       data: [
  //         {
  //           value: current
  //         }
  //       ]
  //     }
  //   ]
  // };

  const option = {
    graphic: [
      {
        type: 'text',
        right: '20%',
        bottom: '15%',
        style: {
          fill: '#333',
          text: `${(max + 1).toFixed(2)}`,
          font: 'italic bolder 12px cursive',
          opacity: 0.5
        }
      },
      {
        type: 'text',
        left: '20%',
        bottom: '15%',
        style: {
          fill: '#333',
          text: `${(min - 1).toFixed(2)}`,
          font: 'italic bolder 12px cursive',
          opacity: 0.5
        },
        z: 100
      }
    ],
    series: [
      // {
      //   type: 'gauge',
      //   center: ['50%', '70%'],
      //   radius: '110%',
      //   min: min - 1,
      //   max: max + 1,
      //   splitNumber: 10,
      //   startAngle: 180,
      //   endAngle: 0,
      //   axisLine: {
      //     show: false,
      //     lineStyle: {
      //       width: 9,
      //       color: [[1, 'rgba(0,0,0,0)']]
      //     }
      //   },
      //   axisLabel: {
      //     // Valores (0, 10, 20, etc)
      //     show: false,
      //     color: '#4b695e',
      //     fontSize: 15,
      //     distance: -55,
      //     formatter: (value) => {
      //       if (value === min - 1 || value === max + 1) {
      //         return value.toFixed(2);
      //       }
      //       return '';
      //     }
      //   },
      //   axisTick: {
      //     // barras pequeñas entre los valores (0, 10, etc)
      //     show: true,
      //     splitNumber: 3,
      //     lineStyle: {
      //       color: '#263b35',
      //       width: 1
      //     },
      //     length: -20
      //   },
      //   splitLine: {
      //     show: true,
      //     length: -20,
      //     lineStyle: {
      //       color: '#4aca96',
      //       width: 2
      //     }
      //   }
      // },
      {
        type: 'gauge',
        min: min - 1,
        max: max + 1,

        radius: '100%',
        center: ['50%', '70%'],
        splitNumber: 0,
        startAngle: 180,
        endAngle: 0,
        axisLine: {
          show: true,
          lineStyle: {
            width: 10,
            color: [
              [
                1,
                new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  {
                    offset: 0,
                    color: '#ff0000'
                  },
                  {
                    offset: 0.5,
                    color: '#FFFF00'
                  },
                  {
                    offset: 1,
                    color: '#4ccb96'
                  }
                ])
              ]
            ]
          }
        },
        splitLine: {
          show: false
        },
        axisLabel: {
          show: false
        },
        axisTick: {
          show: false
        },
        pointer: {
          // Mostrar / ocultar aguja
          offsetCenter: [0, -25],
          show: true,
          length: '80%',
          width: '4%'
        },
        title: {
          show: true,
          offsetCenter: [0, '60%'],
          textStyle: {
            fontWeight: 'bold',
            color: '#0ab7ff',
            fontSize: 22
          }
        },
        detail: {
          // Posicion del valor
          show: true,
          offsetCenter: [0, '-10%'],
          color: '#ffffff',
          textStyle: {
            fontSize: 22,
            color: '#4aca96'
          }
        },
        data: [
          {
            value: current
          }
        ]
      }
    ]
  };

  return (
    <ReactECharts
      css={css`
        > div {
          width: 100% !important;
          height: 100% !important;
        }
      `}
      notMerge={true}
      opts={{ renderer: 'svg' }}
      option={option}
      style={{
        width: '225px',
        padding: 0,
        height: '120px',
        background: 'var(--neutral-09)'
      }}
    />
  );
};
