import { IconTrash, IconTrashX } from '@tabler/icons-react';
import React from 'react';
import styled from 'styled-components';
import { AuxiliaryButton, IconButton } from 'components/buttons';
import useAlertDialog from 'providers/alert-dialog-provider';

const ContainerHeader = styled('header')`
  display: flex;
  align-items: center;
  gap: 1rem;
  /* position: absolute;
  right: 0;
  top: 0; */
  width: fit-content;
  background: white;
  padding: 0.25rem;
  margin: 0.5rem;
  box-shadow: var(--popover-box-shadow);
  background: var(--neutral-09);
  margin-left: auto;
  > button {
    width: 36px;
    height: 36px;
    padding: 0;
  }
`;
export default ({
  children,
  isEdit,
  index,
  setSelectedView,
  selectedView,
  fixed = false
}) => {
  let confirm = useAlertDialog();
  if (!isEdit) return null;

  const handleRemoveContainer = async () => {
    if (selectedView.layout[index].items?.length > 0) {
      if (
        await confirm({
          description: 'Are you sure you want to remove the panel?'
        })
      )
        setSelectedView((prev) => ({
          ...prev,
          layout: prev.layout.filter((_, i) => i !== index)
        }));
    } else
      setSelectedView((prev) => ({
        ...prev,
        layout: prev.layout.filter((_, i) => i !== index)
      }));
  };
  return (
    <ContainerHeader>
      {children}
      {!fixed && (
        <IconButton onClick={handleRemoveContainer} delete>
          <IconTrash />
        </IconButton>
      )}
    </ContainerHeader>
  );
};
