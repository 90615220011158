import { dataFetchQuery } from 'utils/ajax';

import { API_HOST_URL } from './api-url';
const API_URL = API_HOST_URL + '/person_api';

import { callAPI } from 'utils/ajax';

const Person = {
  get(id) {
    return callAPI('GET', `/person/${id}`);
  },

  search(input = {}) {
    return callAPI('GET', `/person`, input);
  },
  save(input = {}, id = '') {
    return callAPI(id ? 'PUT' : 'POST', `/person/${id}`, input);
  },
  getTrends(input, id = '') {
    return dataFetchQuery(`${API_URL}/getTrends/${id}`, input);
  },

  stats(input = {}) {
    return callAPI('GET', `/person/stats`, input);
  },
  delete(ids = null) {
    return callAPI('DELETE', `/person`, ids);
  },
  removeDepartments(input = {}, id = '') {
    return callAPI('POST', `/person/removeDepartments/${id}`, input);
  },
  addDepartments(input = {}, id = '') {
    return callAPI('POST', `/person/addDepartments/${id}`, input);
  },

  add(input = null) {
    return dataFetchQuery(`${API_URL}/add`, input);
  },

  edit(input = null, id = '') {
    return dataFetchQuery(`${API_URL}/edit/` + id, input);
  },

  bar(id) {
    return dataFetchQuery(`${API_URL}/barPerson/${id}`);
  },

  getRecentEntries(input = {}) {
    return dataFetchQuery(`${API_URL}/getRecentEntries`, input);
  }
};

export default Person;
