import React from 'react';
import Person from 'api/person';
import { components } from 'react-select';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { AvatarCircle } from 'components/avatar';
import { Badge } from 'components/badge';
import { useMutationWrapper } from 'utils/ajax';
import useAsyncSelection from 'components/headless-select/use-async-selection';
import Select from 'components/headless-select';
import PersonDepartments from 'views/people/person-departments';

export const PersonItem = ({ person, slim }) => {
  return (
    <div
      css={css`
        width: fit-content;
        width: 100%;
        .departments {
          font-size: 12px;
        }
        color: var(--neutral-00);
        display: flex;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;

        align-items: center;
        gap: 1rem;
        font-size: 14px;

        [role='badge'] {
          margin-left: auto;
        }

        input[type='checkbox'] {
          width: 20px;
          height: 20px;
          accent-color: var(--primary-base);
          opacity: 0.7;
          cursor: pointer;
        }
        label {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis ellipsis;
        }
      `}
    >
      <AvatarCircle name={person.name} url={person.avatar_url} />
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 0.3rem;
          width: 100%;
          min-width: 0;
          .employee_id {
            font-size: 12px;
            color: var(--light--neutrals--800);
          }
        `}
      >
        <label title={person.name}>{person.name}</label>
        <PersonDepartments person={person} />
        {person.employee_id && (
          <span className="employee_id">Emp. ID#: {person.employee_id} </span>
        )}
      </div>
      {!slim && (
        <>
          {person.enabled == 1 && <Badge color="success">Active</Badge>}
          {person.enabled == 0 && <Badge color="danger">Barred</Badge>}
        </>
      )}
    </div>
  );
};
export const Option = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      {!props.data.__isNew__ ? (
        <PersonItem person={props.data} {...props} />
      ) : (
        <div>
          <b>{props.data.label}</b>
        </div>
      )}
    </components.Option>
  );
};

const PersonSelect = React.forwardRef((props, ref) => {
  let { isLoading: mutateLoading, mutate } = useMutationWrapper(
    ['person', 'save'],
    (input) => Person.add(input),
    {}
  );

  const { input, isLoading, setInput, data, value, onChange, remove, refetch } =
    useAsyncSelection({
      ...props,
      fetchFn: Person.search
    });

  const [options, setOptions] = React.useState();

  const handleCreate = React.useCallback((inputValue) => {
    mutate(
      { name: inputValue, allSites: 'on' },
      {
        onSuccess: (fullData) => {
          setOptions(() => [{ name: inputValue, id: fullData.data.id }]);
          onChange({ name: inputValue, id: fullData.data.id }, {});

          if (props.field.name && props.form)
            props.form.setFieldValue(props.field.name, fullData.data.id);
        }
      }
    );
  }, []);

  React.useEffect(() => {
    setOptions(data?.data);
  }, [data]);

  const isPersonDisabled = (option) => {
    return option.enabled == '0';
  };

  return (
    <Select
      ref={ref}
      title="person"
      creatable
      inputValue={input ?? ''}
      setInput={setInput}
      controlShouldRenderValue={true}
      itemComponents={{ Option }}
      isMulti={false}
      isClearable={true}
      isOptionDisabled={isPersonDisabled}
      options={options}
      //filterOption={null}
      closeMenuOnSelect={false}
      // isDisabled={isLoading}
      onChange={onChange}
      isLoading={isLoading}
      isDisabled={mutateLoading}
      formatCreateLabel={(input) => `Create Person "${input}"`}
      value={value}
      remove={remove}
      refetch={refetch}
      placeholder={mutateLoading ? 'Creating Person....' : 'Search People...'}
      onCreateOption={handleCreate}
      onInputChange={(e) => {
        setInput(e);
      }}
      {...props}
    />
  );
});

export default PersonSelect;
