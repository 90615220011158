import React from 'react';
import { useHistory } from 'react-router-dom';
import { MenuItem, MenuItemDelete, MenuWrapper } from 'components/action-menu';
import { useDeleteMutate } from './actions';
import useAlertDialog from 'providers/alert-dialog-provider';
import { NEW_API_HOST_URL } from 'api/api-url';
import { Receipt } from 'react-bootstrap-icons';
export default function ActionMenu({ data: vendor, ...otherProps }) {
  const history = useHistory();
  const deleteMutate = useDeleteMutate(vendor);

  const confirm = useAlertDialog();

  let handleEdit = () =>
    history.push('/vendors/save/' + vendor?.id, {
      vendor,
      desc: vendor?.name
    });

  const handleDelete = async () => {
    if (
      await confirm({
        description: 'Are you sure you want to remove this vendor?'
      })
    )
      deleteMutate.mutate(vendor?.id, {});
  };

  return (
    <MenuWrapper {...otherProps}>
      <MenuItem label="Edit" onClick={handleEdit} />
      <a
        href={NEW_API_HOST_URL + `/vendor/exportBillingObjects/${vendor?.id}`}
        download="_"
        target="_blank"
        rel="noreferrer noopener"
      >
        <MenuItem label="Export Billing Objects CSV...">
          <Receipt />
        </MenuItem>
      </a>

      <MenuItemDelete onClick={handleDelete} />
    </MenuWrapper>
  );
}
