import { callAPI } from 'utils/ajax';

const Report = {
  search(input = {}) {
    return callAPI('GET', '/report', input);
  },
  stats(input = {}) {
    return callAPI('GET', '/report/stats', input);
  },
  signedUrl(key) {
    return callAPI('GET', '/report/signedUrl/' + key);
  },

  save(input = {}, id = null) {
    return callAPI(
      id ? 'PUT' : 'POST',
      '/report' + (id ? `/${id}` : ''),
      input
    );
  },
  delete(id) {
    return callAPI('DELETE', '/report/' + id);
  },
  run(id) {
    return callAPI('POST', '/report/run/' + id);
  }
};

export default Report;
