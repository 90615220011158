import React from 'react';
export const UserContext = React.createContext({
  user: null,
  setUser: () => {},
  login: () => {},
  logout: () => {}
});
import User from 'api/user';
import Company from 'api/company';
import Installer from 'api/installer';
import Site from 'api/site';
import Account from 'api/account';
import { useMutationWrapper } from 'utils/ajax';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useGlobalState } from './global-state-provider';
export const useAuth = () => {
  return React.useContext(UserContext);
};

export default function UserProvider({ children }) {
  const { setUser: setGlobalUser, setShowPageLoader } = useGlobalState();

  const queryClient = useQueryClient();
  const queryCache = queryClient.getQueryCache();
  const history = useHistory();
  const location = useLocation();

  let userObject;
  let storageUser = localStorage.getItem('user');
  try {
    userObject = JSON.parse(storageUser);
  } catch (e) {
    userObject = null;
  }

  let [user, setUser] = React.useState(userObject);
  let [focusedSite, setFocusedSite] = React.useState(userObject?.focused_site);

  React.useEffect(() => {
    if (!userObject?.permission) {
      userObject = null;

      setUser(userObject);
      setFocusedSite(userObject?.focused_site);
    }
  }, []);

  const companyManage = useMutationWrapper(
    ['company', 'manage'],
    (id) => Company.manage(id),
    {
      noToast: true,
      onMutate() {
        setShowPageLoader(true);
      },

      onError() {
        setShowPageLoader(false);
      },

      onSuccess: (res) => {
        setShowPageLoader(false);

        queryClient.clear();
        queryCache.clear();

        localStorage.setItem('user', JSON.stringify(res));
        setUser(res);
        setGlobalUser(res);
        setFocusedSite(res?.focused_site);
      }
    }
  );

  const installerAccess = useMutationWrapper(
    ['installer', 'access'],
    (id) => Installer.access(id),
    {
      noToast: true,
      onMutate() {
        setShowPageLoader(true);
      },

      onError() {
        setShowPageLoader(false);
      },

      onSuccess: (res) => {
        // history.go(0);
        // setShowPageLoader(false);
        setShowPageLoader(false);

        queryClient.clear();
        queryCache.clear();
        localStorage.setItem('user', JSON.stringify(res));
        setUser(res);
        setGlobalUser(res);
      }
    }
  );

  const impersonate = useMutationWrapper(
    ['installer', 'impersonate'],
    (id) => User.impersonate(id),
    {
      noToast: true,
      onMutate() {
        setShowPageLoader(true);
      },

      onError() {
        setShowPageLoader(false);
      },

      onSuccess: (data) => {
        setShowPageLoader(false);

        queryClient.clear();
        queryCache.clear();

        localStorage.setItem('user', JSON.stringify(data));
        setUser(data);
        setGlobalUser(data);
        setFocusedSite(data?.focused_site);
        history.push('/');
      }
    }
  );

  const stopImpersonation = useMutationWrapper(
    ['installer', 'stopImpersonation'],
    () => User.stopImpersonation(),
    {
      noToast: true,
      onMutate() {
        setShowPageLoader(true);
      },

      onError() {
        setShowPageLoader(false);
      },

      onSuccess: (data) => {
        setShowPageLoader(false);

        queryClient.clear();
        queryCache.clear();

        localStorage.setItem('user', JSON.stringify(data));
        setUser(data);
        setGlobalUser(data);
        setFocusedSite(data?.focused_site);
        history.replace('/');
      }
    }
  );

  const siteFocus = useMutationWrapper(
    ['site', 'focus'],
    (id) => Site.focus(id),
    {
      noToast: true,
      onMutate: () => {
        setShowPageLoader(true);
        //optimistic update to not wait for api call
        //setUser(prev => ({...user, 'focused_site': id }))
      },

      onError() {
        setShowPageLoader(false);
      },

      onSuccess: (data) => {
        setShowPageLoader(false);

        queryClient.clear();
        queryCache.clear();

        localStorage.setItem('user', JSON.stringify(data));
        setUser(data);
        setGlobalUser(data);
        setFocusedSite(data?.focused_site);
      }
    }
  );

  const siteExitFocus = useMutationWrapper(
    ['site', 'exitFocus'],
    () => Site.exitFocus(),
    {
      noToast: true,
      onMutate: () => {
        setShowPageLoader(true);
      },
      onError() {
        setShowPageLoader(false);
      },
      onSuccess: (data) => {
        setShowPageLoader(false);

        queryClient.clear();
        queryCache.clear();

        localStorage.setItem('user', JSON.stringify(data));
        setUser(data);
        setGlobalUser(data);
        setFocusedSite(data?.focused_site);
      }
    }
  );

  const companyStopManaging = useMutationWrapper(
    ['company', 'stopManaging'],
    (id) => Company.stopManaging(id),
    {
      noToast: true,
      onMutate() {
        setShowPageLoader(true);
      },

      onError() {
        setShowPageLoader(false);
      },

      onSuccess: (res) => {
        setShowPageLoader(false);
        history.replace('/companies');
        queryClient.clear();
        queryCache.clear();

        localStorage.setItem('user', JSON.stringify(res));
        setUser(res);
        setGlobalUser(res);
        setFocusedSite(null);
      }
    }
  );

  const installerStopAccessing = useMutationWrapper(
    ['installer', 'stopAccessing'],
    (id) => Installer.stopAccessing(id),
    {
      noToast: true,
      onMutate() {
        setShowPageLoader(true);
      },

      onError() {
        setShowPageLoader(false);
      },

      onSuccess: (res) => {
        setShowPageLoader(false);
        history.replace('/installers');
        queryClient.clear();
        queryCache.clear();

        localStorage.setItem('user', JSON.stringify(res));
        setUser(res);
        setGlobalUser(res);
      }
    }
  );

  const login = useMutation(
    ['account', 'login'],
    (input) => Account.login(input),
    {
      onSuccess: (res) => {
        queryClient.clear();
        queryCache.clear();
        if (res == '2fa' || res == 'mfa_code_missing') {
          res == '2fa' && setUser((prev) => ({ ...prev, missing2fa: true }));
          res == 'mfa_code_missing' &&
            setUser((prev) => ({ ...prev, missingMfa: true }));
          return;
        }
        localStorage.setItem('user', JSON.stringify(res));
        setUser(res);
        setGlobalUser(res);
        setFocusedSite(res?.focused_site);
      }
    }
  );

  const validateSession = useMutation(
    ['account', 'validateSession'],
    (input) => Account.validateSession(input),
    {
      onSuccess: (res) => {
        queryClient.clear();
        queryCache.clear();
        localStorage.setItem('user', JSON.stringify(res));
        setUser(res);
        setGlobalUser(res);
        setFocusedSite(res?.focused_site);
        history.push('/', { state: { from: '' } });
      }
    }
  );

  const logout = useMutation(['account', 'logout'], () => Account.logout(), {
    onMutate: async () => {
      queryClient.clear();
      queryCache.clear();
      localStorage.removeItem('user');
      setUser(null);
      setGlobalUser(null);
      history.push('/login', { state: { from: '' } });
    }
  });

  function hasAllPermissions(...perms) {
    if (user.permission?.includes?.('fa')) return true;

    for (const p of perms) {
      if (!user.permission?.includes?.(p)) return false;
    }

    return true;
  }

  function enforce(opts = {}) {
    if (!user) return false;
    if (opts.role && !('A' + opts.role).includes(user.role)) return false;

    if (opts.managing && !user.managing) return false;

    if (opts.devicesView && !user.devices_view && user.role == 'C')
      return false;

    if (opts.reqCompany && !user.company_id) return false;

    if (opts.reqNoCompany && user.company_id) return false;

    if (opts.reqInstaller && (!user.installer_id || user.role == 'C'))
      return false;

    if (opts.reqVendor && (!user.vendor_id || user.role != 'V')) return false;

    if (opts.restrict && !hasAllPermissions(...opts.restrict)) return false;

    if (
      opts.restrictIfCompanyUser &&
      user.role == 'C' &&
      !hasAllPermissions(...opts.restrictIfCompanyUser)
    )
      return false;

    if (
      opts.restrictIfInstallerUser &&
      user.role == 'I' &&
      !hasAllPermissions(...opts.restrictIfInstallerUser)
    )
      return false;

    return true;
  }

  return (
    <UserContext.Provider
      value={{
        hasAllPermissions,
        user,
        setUser,
        setGlobalUser,
        focusedSite,
        setFocusedSite,
        login,
        logout,
        validateSession,
        siteFocus,
        siteExitFocus,
        companyManage,
        companyStopManaging,
        impersonate,
        stopImpersonation,
        installerAccess,
        installerStopAccessing,
        enforce
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
