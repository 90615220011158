import React from "react";
import { css } from "@emotion/react";
/** @jsxImportSource @emotion/react */

const styles = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px;

  /* Neutrals/neutral-09 */

  background: var(--neutral-09);
  /* BorderBottom */

  box-shadow: inset 0px -1px 0px var(--neutral-12);

  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  height: 72px;

  /* Neutrals/neutral-01 */

  color: var(--neutral-01);
`;

const ModalTitle = ({ title, subTitle, children }) => {
  return <header css={styles}>{title || children}</header>;
};

export default ModalTitle;
