/** @jsxImportSource @emotion/react */

import React from 'react';
import { NumberField } from 'components/form';
import { useFormikContext } from 'formik';
import { css } from '@emotion/react';

const styles = css`
  align-items: start;
  display: grid;
  gap: 16px;
  grid-template-columns: var(--form-column-width) var(--form-column-width);
  padding-top: 24px;
  min-width: 650px;
`;
const CompanyEvents = () => {
  const { values } = useFormikContext();

  return (
    <section css={styles}>
      <NumberField
        helper="Number of days"
        label="Event TTL"
        value={values.event_ttl}
      />
      <NumberField
        helper="Number of days"
        label="Audit Log TTL"
        value={values.audit_log_ttl}
      />
    </section>
  );
};

export default CompanyEvents;
