import React from "react";
import Select from "components/headless-select";
import Template from "base/template";
import { Button } from "components/buttons";
import FormProvider from "providers/form-provider";
import { Redirect } from "react-router-dom";
import { useRedirect } from "hooks/redirect-hook";
import { Formik } from "formik";
import { CancelButton } from "components/buttons";
import PageTitle from "components/page-title";
import Content from "base/content";
import { printBadge } from "views/badging/badge";
import BadgeForm from "views/badging/form";
import Badge from "api/badge";
import { useQueryWrapper } from "utils/ajax";
import { DebouncedField } from "components/debounced-field";
import Breadcrumbs from "components/breadcrumbs";
import { PrinterFill } from "react-bootstrap-icons";
/** @jsxImportSource @emotion/react */
import { DEFAULT_TEMPLATE } from "routes/badging/save";

const PersonPrintBadge = (props) => {
  const person = props?.location?.state?.person || null;

  const DEFAULT_LAYOUT = "v1";

  // const template = {
  //   v1: {
  //     avatar: { top: 0, left: 0, color: "#000000", active: true },
  //     name: { top: 120, left: 0, color: "#000000", active: true },
  //     department: { top: 140, left: 0, color: "#000000", active: true },
  //     email: { top: 160, left: 0, color: "#000000", active: true },
  //     phone: { top: 180, left: 0, color: "#000000", active: true },
  //     custom: { top: 200, left: 0, color: "#000000" },
  //   },
  //   h1: {
  //     avatar: {
  //       top: 0,
  //       left: 0,
  //       color: "#000000",
  //       active: true,
  //     },
  //     name: {
  //       top: 108,
  //       left: -1,
  //       color: "#000000",
  //     },
  //     department: {
  //       top: 130,
  //       left: -1,
  //       color: "#000000",
  //       active: true,
  //     },
  //     email: {
  //       top: 147,
  //       left: 0,
  //       color: "#000000",
  //       active: true,
  //     },
  //     phone: {
  //       top: 167,
  //       left: -1,
  //       color: "#000000",
  //       active: true,
  //     },
  //     custom: {
  //       top: 150,
  //       left: 150,
  //       color: "#000000",
  //     },
  //   },
  // };

  const INITIAL_VALUES = {
    ...DEFAULT_TEMPLATE[DEFAULT_LAYOUT],
    layout: DEFAULT_LAYOUT,
  };

  const { redirect, setRedirect } = useRedirect(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const [badgeFilter, setBadgeFilter] = React.useState();
  const { data } = useQueryWrapper([badgeFilter], () =>
    Badge.search({ all: badgeFilter })
  );

  return (
    <Template {...props}>
      {redirect && <Redirect to="/people" />}
      <Content {...props}>
        <PageTitle>Print Badge</PageTitle>
        <Breadcrumbs crumbs={props.crumbs} />
        <Formik initialValues={INITIAL_VALUES} disabled>
          {({ handleSubmit, values, setValues }) => (
            <FormProvider mutateFunc={handleSubmit} disabled={isLoading}>
              <section>
                <Select
                  title="Template"
                  idx="id"
                  label="description"
                  setState={setBadgeFilter}
                  controlShouldRenderValue={true}
                  isMulti={false}
                  isClearable={true}
                  options={data?.data}
                  checkboxes={false}
                  closeMenuOnSelect={true}
                  onChange={(template) => {
                    template &&
                      setValues({
                        ...values,
                        ...template,
                        background_image: Boolean(
                          template.background_image_url
                        ),
                        background: Boolean(template.background_color),
                        ...JSON.parse(template.template),
                      });
                  }}
                  isLoading={isLoading}
                />
              </section>

              <BadgeForm print person={person} />

              <section>
                <DebouncedField name="qrCode" label="QR Code" />
              </section>

              <footer>
                <Button
                  onClick={async function () {
                    const badge = document.getElementById("badge");
                    setIsLoading(true);
                    await printBadge(badge);
                    setIsLoading(false);
                  }}
                >
                  <PrinterFill />
                  Print...
                </Button>
                <CancelButton onClick={() => setRedirect(true)} />
              </footer>
            </FormProvider>
          )}
        </Formik>
      </Content>
    </Template>
  );
};

export default PersonPrintBadge;
