import { callAPI } from 'utils/ajax';

const AuditLog = {
  search(input = {}) {
    return callAPI('POST', '/audit_log/search', input);
  },

  getDailyCount(input) {
    return callAPI('GET', '/audit_log/dailyCount', input);
  },
};

export default AuditLog;
