import React from 'react';
import { useFormikContext } from 'formik';
import IOPortSelectOption from 'views/auxiliaries/ioport-select-option';
import { TextField, NumberField, Heading } from 'components/form';
import GroupSelect from 'components/form/group-select';
import { Field } from 'formik';
import ioportSelect from 'components/form/ioport-select';

const GenericDoorDetails = ({ door }) => {
  const { values } = useFormikContext();

  return (
    <section>
      <TextField label="name" value={values.name} />

      <TextField label="description" value={values.description} />
      <NumberField
        label="Access Time in seconds (output mode only)"
        name="access_time"
        value={values.access_time}
      />
      <Field
        name="io_port_id"
        component={ioportSelect}
        selectedId={door?.io_port_id}
        isOptionDisabled={(e) =>
          e.auxiliary_id || (e.generic_door_id && e.generic_door_id != door?.id)
        }
      />

      <Field
        name="groups"
        defaultOptionValue={values.groups?.map((i) => i.id)}
        component={GroupSelect}
      />
    </section>
  );
};

export default GenericDoorDetails;
