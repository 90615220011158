import { dataFetchQuery } from "utils/ajax";

import { API_HOST_URL } from "./api-url";
const API_URL = API_HOST_URL + "/controller_api";
const API_NAKED = API_HOST_URL;

const Controller = {
  search(input = {}) {
    return dataFetchQuery(`${API_URL}/search`, input);
  },

  getStats(input = {}) {
    return dataFetchQuery(`${API_URL}/getStats`, input);
  },

  stats() {
    // TODO change this name
    //get stats for showinf if offline
    return dataFetchQuery(`${API_URL}/stats`);
  },

  ping(id) {
    return dataFetchQuery(`${API_URL}/pingId/` + id);
  },

  getHealth(id) {
    return dataFetchQuery(`${API_URL}/getHealth/` + id);
  },

  edit(input = {}, id) {
    return dataFetchQuery(`${API_URL}/edit/` + id, input);
  },

  add(input = {}, id) {
    return dataFetchQuery(`${API_URL}/add`, input);
  },

  getConfiguration(id) {
    return dataFetchQuery(`${API_URL}/getConfiguration/` + id);
  },

  getConfigurationAperio(id) {
    return dataFetchQuery(`${API_URL}/getConfigurationAperio/` + id);
  },

  configureDoor1(input = {}, id) {
    return dataFetchQuery(
      `${API_NAKED}/controller_configure_api/door1/` + id,
      input
    );
  },
  configureDoor2(input = {}, id) {
    return dataFetchQuery(
      `${API_NAKED}/controller_configure_api/door2/` + id,
      input
    );
  },

  setup(input = {}, id) {
    return dataFetchQuery(`${API_URL}/setup/` + id, input);
  },

  configureAperio(input = {}, id) {
    return dataFetchQuery(
      `${API_NAKED}/controller_configure_api/aperio/` + id,
      input
    );
  },

  delete(id) {
    return dataFetchQuery(`${API_URL}/delete/` + id);
  },

  reset(id, table) {
    return dataFetchQuery(`${API_URL}/reset/` + id);
  },

  restore(id) {
    return dataFetchQuery(
      `${API_NAKED}/controller_connectivity_api/restoreController/` + id
    );
  },

  searchIssues(id) {},

  getIoPorts(id = null) {
    return dataFetchQuery(`${API_URL}/getIoPorts/` + id);
  },

  getSyncStats(id = null) {
    return dataFetchQuery(`${API_URL}/getSyncStats/` + id);
  },
  getServiceProviderList(id = null) {
    return dataFetchQuery(`${API_URL}/getServiceProviderList/` + id);
  },

  getConfig(id) {
    return dataFetchQuery(`${API_URL}/getConfig/` + id);
  },

  addRemoteDevice(controllerId, deviceId) {
    return dataFetchQuery(
      `${API_URL}/addRemoteDevice/${controllerId}/${deviceId}`
    );
  },

  removeRemoteDevice(controllerId, deviceToken = "") {
    return dataFetchQuery(
      `${API_URL}/removeRemoteDevice/${controllerId}/${deviceToken} `
    );
  },

  getRemoteDevices(controllerId) {
    return dataFetchQuery(`${API_URL}/getRemoteDevices/${controllerId}`);
  },

  getConfigHistory(controllerId) {
    return dataFetchQuery(`${API_URL}/configHistory/${controllerId}`);
  },

  checkSync(controllerId) {
    return dataFetchQuery(`${API_URL}/checkSync/${controllerId}`);
  },
  getScheduleReport(controllerId) {
    return dataFetchQuery(`${API_URL}/getScheduleReport/${controllerId}`);
  },

  testEventSubscription(id) {
    return dataFetchQuery(
      `${API_NAKED}/controller_connectivity_api/testEventSubscription/` + id
    );
  },

  restoreEvents(controllerId) {
    return dataFetchQuery(`${API_URL}/restoreEvents/${controllerId}`);
  },
};

export default Controller;
