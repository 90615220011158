import React from 'react';
import { css } from '@emotion/react';
import Company from 'api/company';
import { useQuery } from 'react-query';
import ReactECharts from 'echarts-for-react';
import Spinner from 'components/spinner';

/** @jsxImportSource @emotion/react */
import { dateTime } from 'utils/time';
import EmptyHolder from 'components/empty-holder';
import getDate from 'date-fns/getDate';
import * as echarts from 'echarts';
import styled from '@emotion/styled';
import Event from 'api/event';
import { useAuth } from 'providers/auth';

const Label = styled('label')`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;

  color: #59606c;
`;

const DeviceNumber = styled('span')`
  font-weight: 700;
  font-size: 28px;
  line-height: 100%;
  /* identical to box height, or 28px */

  color: #1d2023;
`;

const HealthProfile = ({ company, ...props }) => {
  const { user } = useAuth();
  const [chartSeries, setChartSeries] = React.useState([]);

  const { isLoading, data } = useQuery(
    ['events', 'device-health-events', company?.id, user?.focused_site],
    () =>
      Event.getDeviceHealthEvents({
        site: user?.focused_site,
        company: company?.id
      }),
    {
      staleTime: 10 * (60 * 1000), // 10 mins
      cacheTime: 15 * (60 * 1000), // 15 mins
      refetchOnMount: false
    }
  );

  const option = {
    animation: false,
    legend: {
      show: false,
      data: ['offline', 'online'],
      icon: 'square',
      top: 0,
      left: 'center'
    },
    grid: {
      show: false,
      left: 40,
      top: 30,
      right: 10,
      bottom: 40
    },

    tooltip: {
      trigger: 'axis',

      axisPointer: {
        type: 'shadow'
      },
      valueFormatter: (value) => (value < 0 ? -value : value)
    },
    xAxis: {
      type: 'category',
      data: data?.map((d) => d.time),

      axisLine: {
        show: false,
        lineStyle: {
          width: 2
        }
      },

      axisTick: {
        show: false
      }
    },

    yAxis: {
      axisLine: {
        show: false,
        lineStyle: {
          width: 2
        }
      },
      min: 0,
      splitLine: {
        show: false
      },

      axisTick: { show: false },
      axisLabel: {
        show: false,
        formatter: function (value, index) {
          if (!Number.isInteger(value)) return;
          if (value < 0) return -value;
          else return value;
        }
      }
    },

    series: chartSeries,
    ...props.chartOptions
  };

  React.useEffect(() => {
    if (!data) return;
    //set colors

    setChartSeries([
      {
        name: 'offline',
        // barWidth: 12,
        data: data.map((d) => d.offlines),
        type: 'bar',
        barWidth: '20%',

        color: '#ea3737',

        itemStyle: {
          //  barBorderRadius: [5, 5, 0, 0], // Specify the border radius
          borderType: 'solid'
        }
      },
      {
        name: 'online',

        data: data.map((d) => d.onlines),
        type: 'bar',
        barWidth: '20%',

        color: '#00a96c',
        itemStyle: {
          //barBorderRadius: [5, 5, 0, 0], // Specify the border radius
          borderType: 'solid'
        }
      }
    ]);
  }, [data]);

  return (
    <section
      className="company-health-profile"
      css={css`
        height: 100%;
        width: 100%;
        .offline {
          color: #ea3737;
        }
        .online {
          color: #00a96c;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;

          height: 100%;
          width: 100%;
          gap: 0.8rem;
        `}
      >
        <ReactECharts
          showLoading={isLoading}
          loadingOption={{
            showSpinner: false,
            maskColor: 'var(--neutral-09)',
            fontWeight: 'normal',
            text: 'Fetching Data...',
            fontStyle: 'normal',
            fontFamily: 'inherit'
          }}
          opts={{ renderer: 'svg' }}
          option={option}
          style={{ height: '100%', width: '100%' }}
        />
      </div>
    </section>
  );
};

export default HealthProfile;
