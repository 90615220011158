import React from "react";
import { useHistory } from "react-router-dom";
import { Menu } from "@headlessui/react";
import { MenuItem, MenuItemDelete, MenuWrapper } from "components/action-menu";
import { useDeleteMutate } from "./actions";
export default function ActionMenu({ data: group, ...otherProps }) {
  const history = useHistory();
  const deleteMutate = useDeleteMutate(group);

  let handleEdit = (e) =>
    history.push("/groups/save/" + group?.id, {
      group,
      desc: group?.name,
    });

  const handleDelete = (e) =>
    confirm("Are you sure you want to remove this group ?") &&
    deleteMutate.mutate(group?.id, {});

  return (
    <MenuWrapper {...otherProps}>
      <MenuItem label="Edit" onClick={handleEdit} />
      <MenuItemDelete onClick={handleDelete} />
    </MenuWrapper>
  );
}
