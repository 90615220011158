import React from 'react';

import { useQuery } from 'react-query';
import { useAuth } from 'providers/auth';
import { css } from '@emotion/react';
import Metric from 'api/metric';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { dateTimeShort } from 'utils/time';

/** @jsxImportSource @emotion/react */

export default (props) => {
  const { camera, filter } = props;
  const COLORS = ['#6F49C0', '#F5861F', '#49B7BE', '#FFCC47', '#2480D6'];

  const [chartData, setChartData] = React.useState([]);

  const { isLoading, data } = useQuery(
    ['camera', camera?.id, 'mostActivePlate', filter],
    () =>
      Metric.count({
        camera_id: camera.id,
        from: filter?.from,
        to: filter?.to,
        lpr: true,
        groupBy: '$plateText',
        //extraGroupFields: { 'confidence': { '$avg': '$confidence' } },
        limit: 5
      })
  );

  React.useEffect(() => {
    if (!data) return;

    //     setChartData(data?.map((i) => ({ name: i._id, value: i.count })));
    setChartData(data);
  }, [data]);

  const option = {
    animation: false,

    grid: {
      left: '10%',
      right: '10%',
      bottom: '15%',
      top: '30%'
    },
    tooltip: {
      trigger: 'item'
    },

    legend: {
      orient: 'horizontal',
      left: 10,
      padding: 5,
      bottom: 0,
      show: false
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: false
      },
      axisLabel: {
        show: false
      },
      axisLine: {
        show: false,
        lineStyle: {
          color: '#D3D3D3'
        }
      },
      splitLine: {
        show: true,
        interval: 8,
        lineStyle: {
          type: 'dashed'
        }
      }
    },
    xAxis: {
      show: false,
      type: 'category',
      data: chartData?.map((d) => d.name),
      axisLabel: {
        show: false
      },
      axisLine: {
        lineStyle: {
          color: '#D3D3D3'
        }
      }
    },

    series: [
      {
        barWidth: 15,
        data: chartData,
        radius: '90%',

        name: 'Active Companies',
        type: 'bar',

        label: { show: false },
        itemStyle: {
          color: (params) => {
            return COLORS[params.dataIndex];
          },

          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          },

          barBorderRadius: [2, 2, 0, 0], // Specify the border radius
          borderType: 'solid'
        }
      }
    ],
    ...props.config
  };

  return (
    <section
      css={css`
        padding: 1rem;
        width: 100%;
      `}
    >
      <table
        id="rules"
        css={css`
          td,
          th {
            padding: 0.5rem;
          }
          margin-top: 2rem;
          text-align: left;
          width: 100%;

          border-collapse: collapse;

          padding: 1rem;
        `}
      >
        <thead>
          <tr>
            <th>Plate</th>
            <th>Occurences</th>
            <th>Latest Occ.</th>
            <th>Confidence Avg.</th>
          </tr>
        </thead>
        <tbody>
          {isLoading && (
            <tr>
              <td>
                <Skeleton baseColor="var(--skeleton-loading-main-color)" />
              </td>
              <td>
                <Skeleton baseColor="var(--skeleton-loading-main-color)" />
              </td>
              <td>
                <Skeleton baseColor="var(--skeleton-loading-main-color)" />
              </td>
              <td>
                <Skeleton baseColor="var(--skeleton-loading-main-color)" />
              </td>
            </tr>
          )}
          {!isLoading &&
            chartData.map((d) => (
              <tr>
                <td>{d._id}</td>
                <td>{d.count}</td>
                <td>
                  {dateTimeShort(
                    new Date(Number(d.recentEntry.$date.$numberLong))
                  )}
                </td>
                <td>{d.avg_confidence.toFixed(2)}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </section>
  );
};
