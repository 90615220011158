import { Dialog, DialogContent, DialogTrigger } from 'components/dialog.js';
import React from 'react';
import { css } from '@emotion/react';
import Camera from 'api/camera';
import * as RadioGroup from '@radix-ui/react-radio-group';
/** @jsxImportSource @emotion/react */
import AsyncSelect from 'components/headless-select/async';

import styled from '@emotion/styled';

const Item = styled('button')`
  border: 1px solid black;
`;

export default ({ children, selectedView, setSelectedView, index }) => {
  const [selectedItem, setSelectedItem] = React.useState();

  React.useEffect(() => {
    if (!selectedItem) return;

    let newLayout = [...selectedView.layout];
    const oldWidgets = newLayout[index]?.items ?? [];
    let items = [...oldWidgets, selectedItem];
    newLayout[index] = {
      ...newLayout[index],
      items
    };
    setSelectedView((prev) => ({
      ...prev,
      layout: newLayout
    }));
  }, [selectedItem]);

  return (
    <Dialog>
      <DialogTrigger
        css={css`
          margin: 1rem;
        `}
      >
        {children}
      </DialogTrigger>
      <DialogContent
        title="Add Camera"
        onCloseAutoFocus={(e) => e.preventDefault()}
        onOpenAutoFocus={(e) => e.preventDefault()}
        css={css`
          width: 40vw;
          height: 80vh;
        `}
      >
        <section>
          <div>
            <AsyncSelect
              queryKey="cameras"
              noValueContainer
              isClearable={false}
              placeholder="Search Camera..."
              name="sites"
              data-inline
              fetchFilters={{}}
              checkboxes={false}
              data-compact
              fetchFn={Camera.search}
              itemComponents={{ DropdownIndicator: null }}
              menuIsOpen={true}
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              closeMenuOnSelect={true}
              value={null}
              isMulti={false}
              onChange={(e) => {
                if (typeof setSelectedItem == 'function') setSelectedItem(e);
              }}
            />
          </div>
        </section>
      </DialogContent>
    </Dialog>
  );
};
