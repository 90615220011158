import React from 'react';
import * as Tooltip from '@radix-ui/react-tooltip';

import { css, keyframes } from '@emotion/react';
import { getSelectionColumn } from 'components/table-selection-column';
import { dateTime } from 'utils/time';
/** @jsxImportSource @emotion/react */
import { formatDistanceToNow, formatDistance, parseISO } from 'date-fns';
import { Link } from 'react-router-dom';
import ActivityChart from './activity-chart';
import SmallBadge from 'components/small-badge';
import {
  IconHelp,
  IconHelpCircle,
  IconQuestionMark
} from '@tabler/icons-react';
import { useAuth } from 'providers/auth';
import { useMutatePing } from 'views/devices/actions';
import IsRole from 'components/is-role';
import { IconButton } from 'components/buttons';

function diff_hours(dt2, dt1) {
  var diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60 * 60;
  return Math.abs(Math.round(diff));
}

const fadeOutBackground = keyframes`
  0% {
    background-color: #3498db; /* Initial background color */
    }
   100% {
    background-color: rgba(52, 152, 219, 0); /* Faded background color */
  }
`;
function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = [
    'Bytes',
    'KiB',
    'MiB',
    'GiB',
    'TiB',
    'PiB',
    'EiB',
    'ZiB',
    'YiB'
  ];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

function ActivityHeader() {
  return (
    <Tooltip.Provider>
      <Tooltip.Root>
        <Tooltip.Trigger asChild={true}>
          <span
            css={css`
              display: inline-flex;
              gap: 0.4rem;
              align-items: center;
            `}
          >
            <label>24 Hr Activity</label>
            <IconHelpCircle size={22} />
          </span>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content asChild={true}>
            <div
              css={css`
                z-index: 200;
                display: flex;

                padding: 0.5rem;

                background: var(--neutral-09);
                box-shadow: 0px 2px 6px 4px rgba(29, 32, 35, 0.4);
                border-radius: 4px;
              `}
            >
              Last 24 hour feed health.
              <br />
              Hours with issues are marked in red.
              <Tooltip.Arrow
                css={css`
                  fill: var(--neutral-09);
                `}
              />
            </div>
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}

export const columns = [
  {
    disableSortBy: true,
    Header: 'Feed Status',
    accessor: 'activity_status',
    style: { width: '100px' },
    Cell: ({ cell: { value } }) => {
      return (
        <div
          css={css`
            width: 16px;
            height: 16px;
            background-color: ${value
              ? 'var(--light--primary--400-base)'
              : 'var(--light--others--red-400-base)'};
            border-radius: 50%;
          `}
        ></div>
      );
    }
  },

  {
    disableSortBy: true,
    Header: 'Trigger',
    accessor: 'trigger',
    style: { width: '100px' },
    Cell: ({ cell: { value } }) => <SmallBadge>{value}</SmallBadge>
  },
  {
    disableSortBy: true,
    Header: 'Camera',
    accessor: 'name',
    style: { minWidth: '275px', width: '275px' },

    Cell: ({ cell: { value }, row: { original } }) => {
      const { user } = useAuth();
      const pingMutate = useMutatePing(original);
      const handlePing = React.useCallback(() => {
        pingMutate.mutate(original.device_id);
      }, [original]);

      return (
        <div
          css={css`
            display: grid;
            grid-template-columns: 24px 1fr auto;
            align-items: center;
            justify-content: center;
            column-gap: 0.5rem;
          `}
        >
          <div
            css={css`
              width: 16px;
              height: 16px;
              background-color: ${original.device_online
                ? 'var(--light--primary--400-base)'
                : 'var(--light--others--red-400-base)'};
              border-radius: 50%;
            `}
          ></div>
          <div>
            <Link
              data-disabled={!Boolean(user.company_id)}
              to={`/cameras/view/${original.camera_id}`}
            >
              <strong>{value}</strong>
            </Link>

            <span
              css={css`
                font-size: 12px;
                display: block;
              `}
            >
              {original.site_name}
            </span>
          </div>
          <IsRole restrict="C">
            <IconButton
              css={css`
                height: 24px;
                border: 1px solid var(--neutral-03);
              `}
              onClick={handlePing}
              title="Ping device to check online status"
              disabled={pingMutate.isLoading}
            >
              PING
            </IconButton>
          </IsRole>
        </div>
      );
    }
  },
  {
    disableSortBy: true,
    Header: 'Attributes',
    accessor: 'resolution',
    Cell: ({ row: { original }, cell: { value } }) => (
      <div
        css={css`
          display: flex;
          flex-direction: column;
          font-size: 12px;
          gap: 0.2rem;
        `}
      >
        <span>Resolution: {original.resolution}</span>
        <span>FPS: {original.fps}</span>
      </div>
    )
  },

  {
    Header: () => <ActivityHeader />,
    disableSortBy: true,
    id: '24 hr health',
    label: '24 hr health',
    accessor: 'activity',
    fixed: true,
    style: { width: '350px' },
    Cell: ({ cell: { value }, row: { original } }) => (
      <ActivityChart activity={value} {...original} />
    )
  },

  {
    disableSortBy: true,
    Header: 'Activity Duration',
    accessor: 'last_activity_at',
    Cell: ({ row: { original }, cell: { value } }) => {
      if (original.activity_status)
        return (
          <div
            css={css`
              time {
                display: block;
              }
            `}
          >
            {original.last_started_at && original.last_activity_at && (
              <time>
                {formatDistance(
                  new Date(original.last_started_at).getTime(),
                  new Date(original.last_activity_at).getTime()
                )}
              </time>
            )}
          </div>
        );
      else return null;
    }
  },
  {
    disableSortBy: true,
    Header: 'Node',
    accessor: 'current_node',
    pickerHide: true,
    role: ['A'],
    Cell: ({ row: { original }, cell: { value } }) => {
      return (
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
          `}
        >
          <span>{value}</span>
          <span
            css={css`
              opacity: 80%;
            `}
          >
            proxy: {original.current_proxy}
          </span>
        </div>
      );
    }
  }
];

// belong to mongo FEE

// export const columns = [
//   {
//     disableSortBy: true,
//     Header: 'Status',

//     Cell: ({ cell: { value } }) => {
//       return (
//         <div
//           css={css`
//             width: 16px;
//             height: 16px;
//             background-color: var(--light--primary--400-base);
//             border-radius: 50%; /* This makes the square div appear as a circle */
//           `}
//         ></div>
//       );
//     }
//   },
//   {
//     disableSortBy: true,
//     Header: 'Camera Name',
//     accessor: 'camera_name',
//     Cell: ({ cell: { value }, row: { original } }) => {
//       return <strong>{value}</strong>;
//     }
//   },
//   {
//     Header: 'Company',
//     role: ['A'],
//     accessor: 'company_name'
//   },
//   {
//     disableSortBy: true,
//     Header: 'Feed UUID',
//     accessor: 'uuid'
//   },
//   {
//     disableSortBy: true,
//     Header: 'Node',
//     accessor: 'node'
//   },
//   {
//     disableSortBy: true,
//     Header: 'Feed Size',
//     accessor: 'bytesReceived',
//     Cell: ({ cell: { value } }) => {
//       const [fadeOut, setFadeOut] = React.useState(true);

//       React.useEffect(() => {
//         setFadeOut(true);
//         const timeout = setTimeout(() => {
//           setFadeOut(false);
//         }, 1000);

//         return () => clearTimeout(timeout);
//       }, [value]);
//       return (
//         <em
//           className={`${fadeOut ? 'fade' : ''}`}
//           css={css`
//             background: ${fadeOut ? 'lightgray' : ''};
//             padding: 0.2rem;
//             transition: background 1s ease;
//             font-size: 16px;
//             color: var(--light--secundary--400-base);
//           `}
//         >
//           {formatBytes(value)}
//         </em>
//       );
//     }
//   },
//   {
//     disableSortBy: true,
//     Header: 'Duration',
//     accessor: 'startTimeStamp',
//     Cell: ({ row: { original }, cell: { value } }) => {
//       return (
//         <div
//           css={css`
//             time {
//               display: block;
//             }
//           `}
//         >
//           <time>
//             {formatDistance(new Date(value).getTime(), new Date().getTime())}
//           </time>
//         </div>
//       );
//     }
//   }
// ];

// for cloud recordi search
// export const columns = [
//   {
//     disableSortBy: true,

//     Header: 'Node',
//     accessor: 'node'
//   },
//   {
//     newSorter: true,
//     accessor: 'status',
//     asc: 'oldestStatus',
//     desc: 'newestStatus',
//     Header: 'Status',
//     Cell: ({ row: { original } }) => {
//       let before60Sec = new Date(Number(Date.now() - 60000));
//       let [status, color] =
//         new Date(original.end) < before60Sec
//           ? ['done', 'red']
//           : ['streaming', 'green'];

//       return (
//         <div
//           css={css`
//             color: ${color};
//           `}
//         >
//           {status}
//         </div>
//       );
//     }
//   },
//   {
//     disableSortBy: true,

//     Header: 'Camera Name',
//     accessor: 'cameraName'
//   },
//   {
//     disableSortBy: true,

//     Header: 'files',
//     accessor: 'fileCount'
//   },
//   {
//     disableSortBy: true,

//     Header: 'Feed UUID',
//     accessor: '_id'
//   },
//   {
//     disableSortBy: true,

//     Header: 'Camera ID',
//     accessor: 'cameraId'
//   },
//   {
//     disableSortBy: true,
//     Header: 'start',
//     accessor: 'start',
//     Cell: ({ cell: { value } }) => dateTime(value)
//   },
//   {
//     disableSortBy: true,
//     Header: 'duration',
//     accessor: 'end',

//     Cell: ({ row: { original }, cell: { value } }) => {
//       return (
//         <div
//           css={css`
//             time {
//               display: block;
//             }
//           `}
//         >
//           <time>
//             {formatDistance(
//               new Date(original.start).getTime(),
//               new Date(original.end).getTime()
//             )}
//           </time>
//           <time
//             css={css`
//               font-size: 12px;
//               color: var(--light--neutrals--800);
//             `}
//           >
//             {dateTime(value)}
//           </time>
//         </div>
//       );
//     }
//   },
//   {
//     disableSortBy: true,
//     Header: 'Feed Size',
//     accessor: 'feedStorageSize',
//     Cell: ({ cell: { value } }) => {
//       return <em>{formatBytes(value)}</em>;
//     }
//   }
//   // {
//   //   disableSortBy: true,
//   //   Header: 'Feed Start',
//   //   accessor: 'feedStartedAt',
//   //   Cell: ({ cell: { value } }) => dateTime(value)
//   // },
//   // {
//   //   disableSortBy: true,

//   //   Header: 'Segment Start',
//   //   accessor: 'segmentStartedAt',
//   //   Cell: ({ cell: { value } }) => dateTime(value)
//   // },
//   // {
//   //   disableSortBy: true,

//   //   Header: 'Segment Start',
//   //   accessor: 'segmentEndedAt',
//   //   Cell: ({ cell: { value } }) => dateTime(value)
//   // },
//   // {
//   //   disableSortBy: true,

//   //   Header: 'Link',
//   //   accessor: 'link'
//   // },

//   // {
//   //   disableSortBy: true,

//   //   Header: 'Key',
//   //   accessor: 'key'
//   // },
//   // {
//   //   disableSortBy: true,

//   //   Header: 'File',
//   //   accessor: 'file'
//   // },
//   // {
//   //   disableSortBy: true,

//   //   Header: 'Camera ID',
//   //   accessor: 'cameraId'
//   // },
//   // {
//   //   disableSortBy: true,

//   //   Header: 'Device ID',
//   //   accessor: 'deviceId'
//   // }
// ];
