import React from 'react';
import 'css/usage-chart.css';

import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';

import { css } from '@emotion/react';
import { green } from '@mui/material/colors';
/** @jsxImportSource @emotion/react */

const SERIES_OPTIONS = {
  lineStyle: {
    width: 4
  }
};
const styles = css`
  .spinner {
    display: block;
    margin: 24px auto;
  }
  header {
    display: block;
    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 100%;
      margin-bottom: 16px;
    }
  }
  text {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 100%;
    color: #000000;
  }
`;

const UsageChart = ({
  results = [],
  names = [],
  chartData = [],
  config = {},
  height,
  chartOption,
  tooltiplabel = 'Entrances',
  isLoading,
  title = '',
  ...otherProps
}) => {
  let { extended, name } = otherProps;
  const option = chartOption || {
    animation: false,
    title: {
      text: `${title || 'Activity'}`,
      subtext: '7 days',
      subtextStyle: {
        textColor: 'green'
      }
    },
    tooltip: {
      appendToBody: true,
      trigger: 'axis',
      axisPointer: {
        z: 210
      },

      formatter: function (params) {
        return `${params[0].data[0]} - ${tooltiplabel}: ${params[0].data[1]}`;
      },
      backgroundColor: 'var(--neutral-09)',
      textStyle: {
        color: 'var(--neutral-00)'
      }
    },

    // legend: {
    //   oreint: "horizontal",
    //   bottom: 0,
    //   left: 10,
    // },
    silent: true,
    visualMap: [
      {
        show: false,

        seriesIndex: 0,
        width: 4,
        color: 'green',
        min: 0,
        max: Math.max(...chartData?.map((i) => i[1])),
        inRange: {
          color: ['#6D3CD5', '#6D3CD5', '#6D3CD5'],
          symbolSize: [4, 4]
        }
      }
    ],
    grid: {
      left: 20,
      top: 50,
      right: 20,
      bottom: 20
    },
    xAxis: {
      show: true,
      type: 'time',

      axisTick: { show: false },
      axisLine: { show: chartData?.length > 0 },
      axisLabel: {
        alignWithLabel: true,
        interval: 0,
        hideOverlap: true,
        formatter: {
          month: '{monthStyle|{MMM}}',
          day: '{M}/{d}', // 1, 2, ...
          hour: ''
        },
        rich: {
          monthStyle: {
            fontWeight: 'bold'
          }
        }
      }
    },
    yAxis: {
      show: false,
      type: 'value',

      splitLine: { show: false },
      axisTick: { show: false },
      axisLine: { show: true },
      axisLabel: { show: false }
    },

    // toolbox: {
    //   show: false,
    //   feature: {
    //     dataZoom: {
    //       yAxisIndex: "none",
    //     },
    //     dataView: { readOnly: false },
    //     magicType: { type: ["line", "bar"] },
    //   },
    // },

    series:
      chartData.length > 0
        ? [
            {
              smooth: 0.1,
              // areaStyle: {
              //   opacity: 0.1,
              //   normal: {
              //     color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              //       {
              //         offset: 0,
              //         color: "rgba(40, 182, 252, 0.85)",
              //       },
              //       {
              //         offset: 1,
              //         color: "rgba(28, 159, 255, 0)",
              //       },
              //     ]),
              //   },
              // },
              name: `${name || names?.[0]}`,
              type: 'line',
              areaStyle: {
                opacity: 0.1
                // normal: {
                //   color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                //     {
                //       offset: 0,
                //       color: "rgba(0, 0, 0, 0.85)",
                //     },
                //     {
                //       offset: 1,
                //       color: "rgba(0, 0, 0, 0)",
                //     },
                //   ]),
                // },
              },
              //smooth: true,
              // symbol: "none",
              // markPoint: {
              //   /**emphasis: {
              //     focus: "series",
              //     itemStyle: {
              //       color: "#6D3CD5",
              //     },
              //   },**/
              //   //symbolSize: 100,
              //   symbol: "circle",

              //   data: [
              //     {
              //       label: {
              //         fontSize: 18,
              //         fontWeight: "bold",
              //       },
              //       type: "max",
              //       name: "Max",
              //       itemStyle: {
              //         color: "#ea3737",
              //       },
              //     },

              //     {
              //       label: {
              //         fontSize: 18,
              //         fontWeight: "bold",
              //       },
              //       type: "min",
              //       name: "Min",
              //       itemStyle: {
              //         color: "#008E5B",
              //       },
              //     },
              //   ],
              // },
              // markLine: {
              //   data: [
              //     {
              //       label: {
              //         show: true,
              //         position: "middle",
              //         fontWeight: "bold",
              //         padding: 5,
              //         formatter: () =>
              //           `${Math.ceil(
              //             chartData
              //               .map((a) => a[1])
              //               .reduce((a, b) => a + b, 0) / chartData.length
              //           )}`,
              //       },
              //       symbol: "none",
              //       lineStyle: {
              //         color: "var(--secundary-v-01)",
              //         width: 3,
              //       },

              //       yAxis: Math.ceil(
              //         chartData.map((a) => a[1]).reduce((a, b) => a + b, 0) /
              //           chartData.length
              //       ),
              //     },
              //   ],
              // },

              data: chartData || [],
              ...SERIES_OPTIONS,
              ...config?.series
              // areaStyle: {
              //   color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              //     {
              //       offset: 0,
              //       color: "#ccc",
              //     },
              //     {
              //       offset: 1,
              //       color: "#ccc",
              //     },
              //   ]),
              // },
            }
          ]
        : [...results]?.map((r, idx) => ({
            name: `${names[idx]}`,
            type: 'line',
            xAxis: {
              show: !extended ? false : true,
              type: 'time',
              boundaryGap: ['0%', '0%']
            },
            yAxis: {
              show: false,
              type: 'value',
              boundaryGap: ['0%', '0%']
            },
            data: r?.data?.reverse().map((i) => {
              return [i._id, i.count];
            }),
            ...SERIES_OPTIONS,
            ...config?.series
          }))
  };

  const onEvents = {
    click: (e) => console.log(e)
  };

  return (
    <>
      <ReactECharts
        opts={{ renderer: 'svg' }}
        option={option}
        style={{ padding: '0px', height: '100%' }}
        onEvents={onEvents}
        showLoading={isLoading}
        loadingOption={{
          showSpinner: false,
          maskColor: 'var(--neutral-09)',
          fontWeight: 'normal',
          text: 'Fetching Data...',

          fontStyle: 'normal',

          fontFamily: 'inherit'
        }}
      />
    </>
  );
};

export default UsageChart;
