/** @jsxImportSource @emotion/react */

import React from 'react';
import { TextField, CheckBox } from 'components/form';
import { Heading } from 'components/form';
import { Field, useFormikContext } from 'formik';
import { css } from '@emotion/react';
import timezoneSelect from 'components/form/timezone-select';
const styles = css`
  display: grid;
  gap: 16px;
  grid-template-columns: var(--form-column-width) var(--form-column-width);
  padding-top: 24px;
  min-width: 650px;

  .access-levels {
    position: static;
    padding: 24px;
    background: var(--orange-v-01);
    border-radius: 4px;
    input[type='checkbox'] {
      color: orange;
    }
    grid-column: 1/-1;
  }
`;
const CompanyDetails = ({ company }) => {
  const { values, setFieldValue } = useFormikContext();

  return (
    <section css={styles}>
      {company && (
        <>
          <CheckBox
            name="enabled"
            label="Enabled"
            checked={values.enabled}
            onChange={(e) => setFieldValue('enabled', e.target.checked)}
          />
          <br />
        </>
      )}

      <TextField label="Display Name" value={values.display_name} />

      <TextField name="phone" value={values.phone} />

      <TextField
        name="address"
        value={values.address}
        css={css`
          grid-column: 1/-1;
        `}
      />

      <TextField label="O3C" name="o3c_owner_id" value={values.o3c_owner_id} />

      <Field name="time_zone" component={timezoneSelect} />

      {!company && (
        <section className="access-levels">
          <Heading label="Access Levels"></Heading>
          <CheckBox
            name="create_default_al"
            label="Create default Access Levels"
            onChange={(e) =>
              setFieldValue('create_default_al', e.target.checked)
            }
          />
        </section>
      )}
    </section>
  );
};

export default CompanyDetails;
