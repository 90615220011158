/** @jsxImportSource @emotion/react */
import React from 'react';
import { useAuth } from 'providers/auth.js';
import { Link } from 'react-router-dom';
import { usePopoverState, Popover, PopoverDisclosure } from 'reakit/Popover';
import { css } from '@emotion/react';
import { useTheme } from 'providers/theme';
import styled from '@emotion/styled';
import { KeyboardFill, MoonFill, PersonFill, Sun } from 'react-bootstrap-icons';
import KeyboardScModal from './keyboard-sc-modal';

import { useLayout } from 'providers/layout-provider';
import useAlertDialog from 'providers/alert-dialog-provider';

const PopoverContent = styled('aside')`
  width: 360px;
  background: var(--neutral-11);
  border: 1px solid var(--neutral-12);
  box-sizing: border-box;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  z-index: 30;

  > div {
    padding: 1rem;
    border-bottom: 1px solid var(--neutral-12);
  }

  a,
  button,
  span {
    display: block;
    font-weight: 500;
    font-size: 14px;
    color: var(--secondary-font-color);
    line-height: 42px;
    text-align: left;
    transition: color ease 400ms;
  }

  button:hover,
  a:hover {
    color: var(--neutral-00);
  }

  svg {
    cursor: pointer;
  }

  .email {
    font-weight: 700;
    font-size: 14px;
    color: var(--neutral-00);
    margin-bottom: -22px;
  }

  .name {
    font-size: 11px;
  }

  .icons {
    position: absolute;
    right: 26px;
    align-items: center;
    top: 30px;
    display: flex;
    gap: 28px;
    color: var(--primary-base);
  }
`;

const initials = (nameString) => {
  if (!nameString) return 'N/A';

  let initials;
  const fullName = nameString.split(' ');

  if (fullName.length > 1)
    initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
  else initials = nameString;

  return initials.substring(0, 2).toUpperCase();
};

const User = () => {
  let { user, logout, stopImpersonation } = useAuth();

  const { upBuildNo, localVersion } = useLayout();

  const confirm = useAlertDialog();

  async function logoutClick() {
    if (
      await confirm({
        description: `Are you sure you want to terminate your session ?`,
        actionLabel: 'Logout'
      })
    )
      logout.mutate();
  }

  const { setTheme, theme } = useTheme();

  const popover = usePopoverState({ placement: 'bottom-start' });
  return (
    <>
      <PopoverDisclosure {...popover}>
        {user.impersonator && (
          <span
            css={css`
              font-size: 11px;
              position: absolute;
              background: yellow;
              padding: 0 6px 0 12px;
              border: 1px solid var(--neutral-12);
              border-right: none;
              border-radius: 10px 0 0 10px;
              right: 0;
              top: 54px;
            `}
          >
            Impersonating
          </span>
        )}
        <div
          // TODO Move this css to the parent so it applies to all buttons
          css={css`
            display: inline-flex;
            width: 32px;
            height: 32px;
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 100%;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: var(--neutral-00);
            background: var(--avatar-circle-background-color);
            border: 2px solid transparent;
            transition: border 0.2s ease;
            &:hover {
              border-color: var(--primary-base);
            }
          `}
        >
          {initials(user.name)}
        </div>
      </PopoverDisclosure>
      <Popover {...popover} aria-label="User Profile">
        <PopoverContent>
          <div>
            <span className="email">{user.email}</span>
            <span className="name">{user.name}</span>
            <section className="icons">
              <KeyboardScModal />
              <button
                onClick={() => {
                  theme == 'light' &&
                    alert(
                      'Dark Mode will be temporarily discontinued for the next 60 to 90 days while we work to make the user experience better, we will keep your preferences and display it once available.'
                    );
                  setTheme((prev) => (prev == 'light' ? 'dark' : 'light'));
                }}
              >
                {theme == 'light' ? <Sun size="20" /> : <MoonFill size={20} />}
              </button>
            </section>
          </div>
          {/* <Separator /> */}
          <div>
            <Link
              to={{
                pathname: `/profile/${user.user_id}`,
                state: { user }
              }}
            >
              My Profile
            </Link>
          </div>

          {/**<button onClick={() => toggleSidePanel()}>Live Events</button>**/}

          {user.impersonator && (
            <>
              {/* <Separator /> */}
              <div>
                <button onClick={() => stopImpersonation.mutate()}>
                  Stop Impersonating
                </button>
              </div>
            </>
          )}

          {/* <Separator /> */}
          <button
            css={css`
              padding: 1rem;
            `}
            onClick={logoutClick}
          >
            Logout
          </button>
          {upBuildNo && (
            <p
              css={css`
                font-size: 10px;
                position: absolute;
                bottom: 6px;
                right: 12px;
              `}
            >
              v{localVersion}
              {upBuildNo == localVersion ? '' : ' - Update Avaiable'}
            </p>
          )}
        </PopoverContent>
      </Popover>
    </>
  );
};

export default User;
