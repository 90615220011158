import { Card } from './card';
import { IconCloudPlus } from '@tabler/icons-react';
import { Button, AuxiliaryButton } from 'components/buttons';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import {
  useMutateRenewEventSubscription,
  useMutateSetEventSubscription,
  useMutateUnsetEventSubscription,
  useMutateCreateDebugSubscription
} from 'views/devices/actions';

export default ({ device }) => {
  const mutateSetEventSubscription = useMutateSetEventSubscription(device);
  const mutateUnsetEventSubscription = useMutateUnsetEventSubscription(device);
  const mutateRenewEventSubscription = useMutateRenewEventSubscription(device);
  const mutateCreateDebugSubscription =
    useMutateCreateDebugSubscription(device);
  return (
    <Card
      classname="subscription"
      css={css`
        .action {
          display: flex;
          width: 100%;

          > button:first-child {
            border-radius: 6px 0px 0px 6px;
            margin-left: auto;
          }
          > button {
            border-radius: 0px;
          }
          > button:last-child {
            border-radius: 0px 6px 6px 0px;
          }
        }
      `}
    >
      <div className="icon">
        <IconCloudPlus />
      </div>
      <div className="content">
        <h2>Subscription</h2>
        <span className="description">Manage Subscriptions.</span>
      </div>
      <div className="action">
        <Button
          compact
          loading={mutateSetEventSubscription.isLoading}
          onClick={() => mutateSetEventSubscription.mutate(device.id)}
        >
          Set
        </Button>
        <AuxiliaryButton
          loading={mutateRenewEventSubscription.isLoading}
          onClick={() => mutateRenewEventSubscription.mutate(device.id)}
          compact
        >
          Renew
        </AuxiliaryButton>
        <AuxiliaryButton
          loading={mutateUnsetEventSubscription.isLoading}
          onClick={() => mutateUnsetEventSubscription.mutate(device.id)}
          compact
        >
          Unset
        </AuxiliaryButton>
        <Button
          compact
          loading={mutateCreateDebugSubscription.isLoading}
          onClick={() => {
            let url = prompt('Enter url to test?');
            url && mutateCreateDebugSubscription.mutate({ url });
          }}
        >
          Test
        </Button>
      </div>
      <div className="data">
        <div>Sub ID: {device.subscription_id}</div>
        <time>Sub UTC Exp. Time: {device.subscription_termination_time}</time>
      </div>
    </Card>
  );
};
