import React from 'react';
import Template from 'base/template';
import { useMutationWrapper } from 'utils/ajax';
import { useRedirect } from 'hooks/redirect-hook';
import FormProvider from 'providers/form-provider';
import SelectWrapper from 'components/headless-select';
import { useAuth } from 'providers/auth';
// import { dateTimeLocalSQLNoSeconds } from "utils/time";
import { FormFooter } from 'components/form/form-footer';
/** @jsxImportSource @emotion/react **/

import Report from 'api/report';

import { Redirect, useLocation } from 'react-router-dom';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { Button, CancelButton, SecondaryButton } from 'components/buttons';
import Content from 'base/content';
import PageTitle from 'components/page-title';
import {
  TimeField,
  DateTimeField,
  NumberField,
  TextField
} from 'components/form';

import CreatableSelect from 'react-select/creatable';
import { css } from '@emotion/react';

import Site from 'api/site';
import EVENTS_CONSTANTS from 'constants/events';
import { dateTimeLocalSQLNoSeconds } from 'utils/time';
import TimezoneSelect from 'components/form/timezone-select';
import SiteSelect from 'components/form/site-select';
import GenericDoorSelect from 'components/form/generic-door-select';
import PersonSelect from 'components/form/person-select';
import RecurrenceSelect from 'views/reports/recurrence-select';
import IterationSelect from 'views/reports/iteration-select';
import EmailCreatable from 'components/form/email-creatable';
import OutputSelector from 'views/reports/output-selector';
import EventSelect from 'components/event-select';
import { filterCategory } from 'components/event-select';
import AsyncSelect from 'components/headless-select/async';
import GenericDoor from 'api/generic_door';
import Person from 'api/person';
import { SQLDateTimeToComponent } from 'utils/time';
import Breadcrumbs from 'components/breadcrumbs';
import ReportType from 'views/reports/report-type';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Option as PersonItem } from 'components/form/person-select';
import PeriodSelect from 'views/reports/period-select';
import { InputLabel } from 'components/form';
import { ErrorMessage } from 'components/form';
import Alert from 'components/alert';

const Save = (props) => {
  const rpt = props?.location?.state?.report || null;
  const { id } = useParams();

  const SCHEMA = Yup.object().shape(
    {
      description: Yup.string().required('Required'),
      timezone: Yup.string().required('Required').nullable(),
      output: Yup.string().required('Required').nullable(),
      dayOfMonth: Yup.number().when('recurrence', {
        is: 'm',
        then: Yup.number().required('Required').nullable()
      }),
      dayOfWeek: Yup.number().when('recurrence', {
        is: 'w',
        then: Yup.number().required('Required').nullable()
      }),
      types: Yup.array().min(1, 'Select at least one event type').nullable(),
      sitesDoorsPeople: Yup.string()
        .nullable()
        .when(['doors', 'people', 'sites'], {
          is: (doors, people, sites) =>
            people?.length == 0 && doors?.length == 0 && !sites,
          then: Yup.string()
            .nullable()
            .required('Missing a site, people or doors')
        }),

      fromOrTo: Yup.string()
        .nullable()
        .when(['from', 'to', 'recurrence'], {
          is: (from, to, recurrence) => recurrence == '1' && (!from || !to),
          then: Yup.string().nullable().required('Missing date')
        })

      // sites: Yup.array().min(1, "Select at least one option").nullable(),
      // people: Yup.array().min(1, "Select at least one option").nullable(),
      // doors: Yup.array().min(1, "Select at least one option").nullable(),
    },
    [
      ['from', 'to', 'recurrence'],
      ['doors', 'people', 'sites']
    ]
  );

  // Get days threshold
  const s = new Date(rpt?.start);
  const e = new Date(rpt?.end);
  const delta = (e.getTime() - s.getTime()) / 1000;

  const { user } = useAuth();

  const people = rpt?.people;
  const doors = rpt?.doors;
  const sites = rpt?.sites;

  const INITIAL_VALUES = {
    fromOrTo: null,
    sitesDoorsPeople: null,
    dayOfMonth: rpt?.dayOfMonth ?? '1',
    dayOfWeek: rpt?.dayOfWeek ?? '1',
    description: rpt?.description,
    recurrence: rpt?.recurrence ?? '1',

    sites: sites ? sites.map((id) => Number(id)) : null,
    people: people ? people.map((id) => Number(id)) : null,
    doors: doors ? doors.map((id) => Number(id)) : null,

    types: rpt?.types,

    output: rpt?.output ?? 'html',
    //Times//

    start: rpt?.start
      ? SQLDateTimeToComponent(rpt?.start)
      : new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
          .toLocaleString('sv')
          .replace(' ', 'T')
          .slice(0, -3),

    end: rpt?.end
      ? SQLDateTimeToComponent(rpt?.end)
      : new Date().toLocaleString('sv').replace(' ', 'T').slice(0, -3),

    // End Times//
    recipients: rpt?.recipients ?? [user.email],
    timezone:
      rpt?.timezone ||
      new Intl.DateTimeFormat(undefined).resolvedOptions().timeZone
  };

  let { isLoading, mutate, isSuccess } = useMutationWrapper(
    ['report', 'save'],
    (input) => Report.save(input, id),
    {}
  );

  const { redirect, setRedirect } = useRedirect(isSuccess);

  let dayOfMonthOption = [
    {
      value: 1,
      label: '1st of the month'
    },
    {
      value: 10,
      label: '10th of the month'
    },
    {
      value: 15,
      label: '15th of the month'
    }
  ];

  return (
    <Template {...props}>
      {redirect && <Redirect to="/reports" />}
      <Content {...props}>
        {/* {redirect && <Redirect to="/access-levels" />} */}
        <PageTitle>{(rpt ? 'Save ' : 'Add ') + 'Report'}</PageTitle>
        <Breadcrumbs crumbs={props.crumbs} />
        <Formik
          validationSchema={SCHEMA}
          initialValues={INITIAL_VALUES}
          onSubmit={(values) =>
            //edge case wherethere is 0 in sites
            {
              // convert from period select
              delete values.allSites;
              delete values.fromOrTo;
              delete values.sitesDoorsPeople;
              {
                // convert from period to api time without utc to be consumed from backend
                values.start = values.from?.toLocaleString();
                values.end = values.to?.toLocaleString();

                delete values.from;
                delete values.to;

                delete values.period;
              }

              // house keeping
              if (values.recurrence != '1') {
                delete values.start;
                delete values.end;
              }

              if (values.recurrence == '1' || values.recurrence == 'd') {
                delete values.dayOfWeek;
                delete values.dayOfMonth;
              }

              if (values.recurrence == 'm') {
                delete values.dayOfWeek;
              }

              if (values.recurrence == 'w') {
                delete values.dayOfMonth;
              }

              mutate({
                ...values
              });
            }
          }
        >
          {({
            handleSubmit,
            values,
            setFieldValue,
            form,
            setValues,
            dirty
          }) => (
            <FormProvider mutateFunc={handleSubmit} disabled={isLoading}>
              {id && (
                <section
                  css={css`
                    > * {
                      grid-column: 1/-1;
                    }
                  `}
                >
                  <Alert>
                    This Report is a scheduled report. <br />
                    Editing this report will craete a new instance and will
                    cancel all pending recurrences
                  </Alert>
                </section>
              )}
              <section>
                <TextField label="Description" value={values.description} />
                <br />
                <Field name="timezone" component={TimezoneSelect} />
                <br />
                <Field name="recurrence" component={RecurrenceSelect} />
                <br />
                {values.recurrence == '1' && (
                  <>
                    <div
                      css={css`
                        > button:first-of-type {
                          width: 100%;
                        }
                      `}
                    >
                      <InputLabel label="Period" />
                      <PeriodSelect
                        disableAll
                        liveUpdate
                        title="Period"
                        setSelection={setValues}
                        showValue={true}
                        dateFormat="MMMM d, yyyy hh:mm a"
                      />
                      <ErrorMessage name="fromOrTo" />
                    </div>
                    <br />
                  </>
                )}

                {values.recurrence == 'm' && (
                  <>
                    <Field
                      name="dayOfMonth"
                      component={IterationSelect}
                      utc
                      options={dayOfMonthOption}
                      closeMenuOnSelect={true}
                      value={dayOfMonthOption.find(
                        (i) => i.value == values.dayOfMonth
                      )}
                      helper="Day of the month to run the report"
                      onChange={(e) => {
                        setFieldValue('dayOfMonth', e.value);
                      }}
                    />
                    <br />
                  </>
                )}
                {values.recurrence == 'w' && (
                  <>
                    <Field name="dayOfWeek" component={IterationSelect} />
                    <br />
                  </>
                )}
                <Field
                  name="recipients"
                  component={EmailCreatable}
                  helper="Email this report to the following recipients (optional) "
                  title="recipients"
                />
                <div
                  css={css`
                    color: var(--neutral-00);
                    > label {
                      margin: 16px 0;
                      display: flex;
                    }
                  `}
                >
                  <OutputSelector />
                </div>
              </section>

              <h3
                css={css`
                  color: var(--neutral-00);
                `}
              >
                Filter Events to be included in the Report
              </h3>

              <div
                css={css`
                  margin-top: 24px;
                  .error-message {
                    margin: 8px 0 0 0;
                  }
                `}
              >
                <Field
                  name="types"
                  helper="Choose Events to be included in the report. max 4 types"
                  component={EventSelect}
                  maxOption={4}
                  title="Event Types"
                  //category
                  closeMenuOnSelect={false}
                />
              </div>

              <section
                css={css`
                  width: 100% !important;
                `}
              >
                <div>
                  <Field
                    title="Filter Site"
                    name="sites"
                    isMulti={false}
                    component={AsyncSelect}
                    fetchFn={Site.search}
                  />
                  <ErrorMessage name="sitesDoorsPeople" />
                </div>

                <Field
                  name="doors"
                  title="Filter Doors"
                  isMulti={true}
                  component={AsyncSelect}
                  fetchFn={GenericDoor.search}
                />

                <Field
                  placeholder="Search by Name, Employee ID..."
                  name="people"
                  title="Filter People"
                  isMulti={true}
                  filterConfig={{
                    ignoreCase: true,
                    ignoreAccents: true,
                    trim: false,
                    matchFrom: false,
                    stringify: (option) => {
                      return `${option.label} ${option.data?.employee_id}`;
                    }
                  }}
                  menuPlacement="top"
                  component={AsyncSelect}
                  fetchFn={Person.search}
                  itemComponents={{ Option: PersonItem }}
                />
                <br />
              </section>

              <FormFooter>
                <Button type="submit" disabled={!dirty}>
                  Save
                </Button>
                <CancelButton onClick={() => setRedirect(true)} />
              </FormFooter>
            </FormProvider>
          )}
        </Formik>
      </Content>
    </Template>
  );
};

export default Save;
