/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, jsx } from '@emotion/react';
/** @jsxImportSource @emotion/react */
const styles = css`
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  font-style: normal;
  color: var(--secondary-font-color);

  margin-bottom: 2px;
`;

const InputHelper = (props) => {
  if (props.helper) return <div css={styles}>{props.helper}</div>;
  else return null;
};

export default InputHelper;
