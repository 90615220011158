import CloudRecording from 'api/cloud-recording';
import Spinner from 'components/spinner';
import React from 'react';
import { useQueries } from 'react-query';
import { useQueryWrapper } from 'utils/ajax';
/** @jsxImportSource @emotion/react */
import { useEventSource } from 'react-use-websocket';

import { css } from '@emotion/react';
import { IconCloudDownload } from '@tabler/icons-react';
import { useLayout } from 'providers/layout-provider';
import { useAuth } from 'providers/auth';
import { IconRotate } from '@tabler/icons-react';
export default ({ camera }) => {
  const { data, isLoading } = useQueryWrapper(
    ['camera', 'bucket', camera.id],
    () => CloudRecording.getBucketSize(camera.id),
    { staleTime: 120000 }
  );

  const [message, setMessage] = React.useState();
  const [bucket, setBucket] = React.useState();

  React.useEffect(() => {
    setBucket({});
  }, [camera]);

  // React.useEffect(() => {
  //   setMessage('Calculating Bucket Size...');
  //   let timer1 = setTimeout(() => {
  //     setMessage('Still processing...');
  //   }, 3000);

  //   let timer2 = setTimeout(() => {
  //     setMessage('Taking longer...');
  //   }, 6000);

  //   return () => {
  //     clearInterval(timer1);
  //     clearInterval(timer2);
  //   };
  // }, [camera]);

  const { getSocketUrl } = useLayout();
  const { user } = useAuth();

  let filter = React.useCallback(
    (data) => {
      if (data?.camera_id == camera.id && data.type == 'bucketSize')
        return true;
      return false;
    },
    [camera]
  );

  const { lastEvent, readyState } = useEventSource(
    getSocketUrl,
    {
      share: true,
      filter: (e) => filter(JSON.parse(e.data)),
      shouldReconnect: (e) => true,
      withCredentials: true,
      retryOnError: true
    },
    Boolean(user && !user.missing2fa && !user.missingMfa)
  );

  React.useEffect(() => {
    if (!lastEvent) return;

    let data = JSON.parse(lastEvent.data);
    setBucket(data);
  }, [lastEvent]);

  return (
    <dl
      css={css`
        dd {
          margin-bottom: 16px;
          font-size: 16px;
          font-weight: 500;
        }
        dt {
          position: relative;
          margin-bottom: 4px;
          font-size: 14px;
          opacity: 80%;
        }
      `}
    >
      <dt>Bucket retention period</dt>
      <dd>{camera.cloud_rec_expiration_time ?? 7} days</dd>
      <dt data-loading={isLoading}>Bucket Size</dt>
      <dd>{isLoading ? bucket?.size : data?.size}</dd>
      <dt data-loading={isLoading}>Total Objects</dt>
      <dd>{isLoading ? bucket?.count : data?.objects}</dd>
      {!isLoading && data?.objects != 0 && (
        <>
          <dt>First Object Date</dt>
          <dd>{new Date(data?.firstEntry).toLocaleString()}</dd>
          <dt>Last Object Date</dt>
          <dd>{new Date(data?.lastEntry).toLocaleString()}</dd>
        </>
      )}
    </dl>
  );
};
