import React from 'react';
import { useHistory } from 'react-router-dom';

import { MenuItem, MenuItemDelete } from 'components/action-menu';
import { useDeleteMutate } from './actions';
import { useAuth } from 'providers/auth';
import MenuWrapper from 'components/action-menu/menu-wrapper';

import { GearFill, PencilFill } from 'react-bootstrap-icons';
import useAlertDialog from 'providers/alert-dialog-provider';

export default function ActionMenu({ data: company, ...otherProps }) {
  const history = useHistory();
  const deleteMutate = useDeleteMutate(company);
  const { companyManage, hasAllPermissions } = useAuth();
  const confirm = useAlertDialog();

  let handleEdit = () =>
    history.push('/companies/save/' + company?.id, {
      company,
      desc: company?.name
    });

  const handleDelete = async () => {
    if (
      await confirm({
        description: `Are you sure you want to delete company: '${company['display_name']}' ?`
      })
    )
      deleteMutate.mutate(company?.id, {});
  };

  const handleManage = () =>
    companyManage.mutate(company?.id, {
      onSuccess: () => history.push('/devices')
    });

  const canWrite = hasAllPermissions('writeCompanies');

  return (
    <MenuWrapper {...otherProps}>
      <MenuItem disabled={!canWrite} label="Edit" onClick={handleEdit}>
        <PencilFill />
      </MenuItem>
      <MenuItem label="Manage" onClick={handleManage}>
        <GearFill />
      </MenuItem>
      <MenuItemDelete disabled={!canWrite} onClick={handleDelete} />
    </MenuWrapper>
  );
}
