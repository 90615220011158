import 'css/sidebar.css';
import React from 'react';
import { withSidebar } from 'components/with-sidebar';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import EventList from 'components/event-list';
import { AvatarCircle } from 'components/avatar';
import { useHeadlessTable } from 'providers/headless-table-provider';
import SidebarEmptyHolder from 'components/sidebar-empty-holder';
import { Badge } from 'components/badge';
import {
  Calendar,
  CardList,
  ShieldShaded,
  Search
} from 'react-bootstrap-icons';
import { useEventList } from 'hooks/use-event-list';
import CredentialAccessLevels from './credential-access-levels';
import {
  TabItem,
  TabGroup,
  TabPanel,
  TabPanels,
  TabList
} from 'components/tabs';
import CredentialAudit from './credential-audit';
import AccessLevel from 'api/access_level';
import AsyncSelect from 'components/headless-select/async';
import { components } from 'react-select';

const Stats = () => {
  return null;
};

const MenuList = (props) => {
  return (
    <components.MenuList {...props}>
      {Array.isArray(props.children)
        ? props?.children?.slice(0, 10)
        : props.children}
      {props.children.length > 10 && (
        <h6
          css={css`
            font-weight: 400;
          `}
        >
          Showing first 10 results
        </h6>
      )}
    </components.MenuList>
  );
};
const articleStyles = css`
  display: flex;
  height: 100% !important;
  flex-direction: column;
  gap: 0.2rem;

  label {
    width: 100%;
  }
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 100%;
    align-items: center;
    min-height: 32px;
    color: var(--neutral-00);
  }
  header {
    width: 100%;
    padding: 24px;
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    border-bottom: 1px solid var(--neutral-12);
  }
`;
const Sidebar = () => {
  const { selectedFlatRows } = useHeadlessTable();

  if (selectedFlatRows.length != 1) return <SidebarEmptyHolder />;

  const enabled = selectedFlatRows[0].original.Enabled == '1';

  const query = useEventList({
    filters: { credential_token: selectedFlatRows[0].original.token }
  });

  return (
    <article css={articleStyles}>
      <header>
        <h3>{selectedFlatRows[0].original.Description}</h3>
        {/* <AvatarCircle
          name={selectedFlatRows[0].original.name}
          url={selectedFlatRows[0].original.avatar_url}
          size="60px"
          fontSize="20px"
        /> */}

        <Badge color={enabled ? 'green' : 'red'}>
          {enabled ? 'Active' : 'Barred'}
        </Badge>
      </header>

      <TabGroup>
        <TabList
          css={css`
            width: 100%;
            box-shadow: unset;
            gap: 3rem;
            padding: 0px 16px;
            border-bottom: 1px solid var(--neutral-12);

            [role='tab'] {
              min-width: unset;
            }
          `}
        >
          <TabItem title="info">
            <Calendar size={22} />
          </TabItem>
          <TabItem title="Audit Logs">
            <CardList size={22} />
          </TabItem>
          {/* <TabItem title="Access Levels">
            <ShieldShaded size={22} />
          </TabItem> */}
        </TabList>
        <TabPanels>
          <TabPanel
            as="div"
            css={css`
              padding: 1rem;
              display: flex;
              flex-direction: column;
              overflow: hidden;
              dl {
                display: grid;
                grid-template-rows: 14px 1fr;
                row-gap: 0.2rem;
              }

              dt {
                font-size: 12px;
                line-height: 100%;
                color: var(--neutral-01);
              }
              dd {
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                color: var(--neutral-15);
                margin-bottom: 2rem;
              }

              h2 {
                color: #7f8795;
              }
              > section.usage-chart {
                flex: 1;
              }
              > section.event-list {
                display: inline-flex;
                position: relative;
                flex-direction: column;
                gap: 1rem;
                width: 100%;
                overflow-y: auto;
                height: 100%;
              }
            `}
          >
            <dl>
              <dt>Access Levels</dt>
              <dd>
                <CredentialAccessLevels
                  credential={selectedFlatRows[0].original}
                />
              </dd>
              <dt>Valid From</dt>
              <dd>{selectedFlatRows[0].original.ValidFrom}</dd>
              <dt>Valid To</dt>
              <dd>{selectedFlatRows[0].original.ValidTo}</dd>
              <dt
                css={css`
                  margin-bottom: 0.3rem;
                `}
              >
                Person
              </dt>
              <dd
                css={css`
                  display: flex;
                  align-items: center;
                  gap: 0.5rem;
                `}
              >
                <AvatarCircle
                  name={selectedFlatRows[0].original.name}
                  url={selectedFlatRows[0].original.avatar_url}
                  size="60px"
                  fontSize="20px"
                />
                <span>{selectedFlatRows[0].original.person_name}</span>
              </dd>
            </dl>
            <EventList query={query} />
          </TabPanel>
          <TabPanel
            as="div"
            css={css`
              padding: 1rem;
              display: flex;
              flex-direction: column;
              height: 100%;
              overflow: hidden;
            `}
          >
            <h5>Recent Logs</h5>
            <CredentialAudit credential={selectedFlatRows[0].original} />
          </TabPanel>
          {/* <TabPanel
            as="div"
            css={css`
              padding: 1rem;
              height: 100%;
              display: flex;
              flex-direction: column;
              overflow: hidden;
            `}
          >
            <div
              css={css`
              display: flex;
              align-items:center;
              span {
                margin-left: auto;
                font-size: 12px;
                color: var(--neutral-01);
              }
              margin-bottom:1rem;l
            `}
            >
              <h3>Access Levels</h3>
              <span>
                Total: {selectedFlatRows[0].original.access_level_count}
              </span>
            </div>
            <AsyncSelect
              fetchFilters={{
                credential: selectedFlatRows[0].original.token
              }}
              fetchFn={AccessLevel.search}
              data-compact
              data-inline
              css={css`
                .select__menu {
                  box-shadow: none;
                  border: none;
                }
                .select__menu-list {
                  max-height: 100%;
                  overflow: auto;
                }
                input {
                  opacity: 1 !important ;
                }
              `}
              icon={Search}
              itemComponents={{ DropdownIndicator: null, MenuList }}
              simple
              isSearchable={true}
              getOptionValue={(e) => e.id}
              getOptionLabel={(e) => e.name}
              menuIsOpen
              controlShouldRenderValue={false}
              value={null}
            />
          </TabPanel> */}
        </TabPanels>
      </TabGroup>
    </article>
  );
};

export default withSidebar({})(Sidebar, Stats);
