/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { useHeadlessTable } from 'providers/headless-infinite-table-provider';
import TableEmptyHolder from './table-empty-holder';
import TableLoader from './table-loader';
import Spinner from './spinner';
import {
  Th,
  Td,
  Tr,
  Thead,
  TableHolder,
  Table as TableBody,
  SorterIcon
} from 'components/table/table-components';
import LoadMoreButton from './load-more-button';
import TableTh from './table/table-th';
import TableError from './table-error';

export default function Table({
  children,
  selectable,
  live,
  getRowProps = () => {},
  ...otherProps
}) {
  const {
    getTableProps,
    isLoading,
    isFetching,
    headerGroups,
    getTableBodyProps,
    prepareRow,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    data,
    rows,
    tableState,
    tableRef,
    tableWidth,
    error
  } = useHeadlessTable();

  return (
    <>
      <TableHolder className="table-holder" id="table-holder">
        <div
          ref={tableRef}
          className="table-body-container"
          css={css`
            width: 100%;
            overflow-y: auto;
            height: 100%;
            color: var(--neutral-00);
            background: var(--neutral-09);
            position: relative;
          `}
        >
          <TableBody {...getTableProps()}>
            <Thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <TableTh key={column.id} column={column} />
                  ))}
                </tr>
              ))}
            </Thead>
            {rows?.length == 0 && !isLoading && !error && (
              <TableEmptyHolder {...otherProps} />
            )}
            {error && <TableError />}
            {isLoading && <TableLoader />}
            {!isLoading && !error && (
              <tbody {...getTableBodyProps()}>
                {rows.length > 0 /** Add is Fetchin gf using keepData */ &&
                  rows.map((row) => {
                    prepareRow(row);
                    return (
                      <Tr
                        data-selected={row.isSelected ? true : false}
                        selectable={selectable}
                        {...row.getRowProps(getRowProps(row))}
                        onClick={
                          selectable
                            ? (e) => {
                                row.toggleRowSelected(!row.isSelected);
                              }
                            : () => null
                        }
                      >
                        {row.cells.map((cell) => {
                          return (
                            <Td
                              data-selected={row.isSelected}
                              data-selection={
                                cell.column.id == 'selection' ? 'true' : 'false'
                              }
                              {...cell.getCellProps()}
                            >
                              {cell.render('Cell', { live, ...otherProps })}
                            </Td>
                          );
                        })}
                      </Tr>
                    );
                  })}
              </tbody>
            )}
            <tfoot>
              {hasNextPage && data.pages?.[0]?.data.length > 0 && (
                <tr>
                  <td colSpan={headerGroups[0]?.headers?.length ?? 1}>
                    <div
                      style={{
                        width: tableWidth + 'px',
                        position: 'sticky',
                        left: 0
                      }}
                    >
                      <LoadMoreButton
                        tableRef={tableRef}
                        hasNextPage={hasNextPage}
                        fetchNextPage={fetchNextPage}
                        isFetchingNextPage={isFetchingNextPage}
                      />
                    </div>
                  </td>
                </tr>
              )}
            </tfoot>
          </TableBody>
        </div>
        {children}
      </TableHolder>
    </>
  );

  // Table component logic and UI come here
}
