import React from 'react';
import styled from '@emotion/styled';

const Div = styled('h4')`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  /* Neutrals/neutral-00 */

  color: var(--neutral-00);
`;

const H4 = (props) => {
  return <Div {...props}>{props.label || props.name || props.children}</Div>;
};

export default H4;
