import 'css/sidebar.css';
import React from 'react';
import { withSidebar } from 'components/with-sidebar';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */

const articleStyles = css``;
const Sidebar = (props) => {
  return (
    <React.Fragment>
      <article css={articleStyles}></article>
    </React.Fragment>
  );
};

export default withSidebar({})(Sidebar, null);
