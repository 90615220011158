import { callAPI } from 'utils/ajax';

const Account = {
  login(input = {}) {
    return callAPI('POST', '/account/login', input);
  },
  logout() {
    return callAPI('POST', '/account/logout', {});
  },
  recover(input) {
    return callAPI('POST', '/account/recover', input);
  },
  reset(input) {
    return callAPI('POST', '/account/reset', input);
  },

  validateSession(input) {
    return callAPI('POST', '/account/validateSession?session=' + input);
  }
};

export default Account;
