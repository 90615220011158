import { dataFetchQuery } from "utils/ajax";

import { API_HOST_URL } from "./api-url";
const API_URL = API_HOST_URL + "/quick_notification_api";

const QuickNotification = {
  search(input, opts = {}) {
    return dataFetchQuery(`${API_URL}/search`, input);
  },
};

export default QuickNotification;
