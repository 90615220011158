import React from 'react';
import Label from 'components/label';
import { useFormikContext } from 'formik';

import { css } from '@emotion/react';
import { Pen, Pencil, Trash, PlusLg } from 'react-bootstrap-icons';
import { AuxiliaryButton } from 'components/buttons';
import { DialogDisclosure } from 'reakit/Dialog';
import { useModal } from 'providers/modal-provider';
import EventModal from './event-modal';
import { formatDistanceStrict, formatDistanceToNow } from 'date-fns';
/** @jsxImportSource @emotion/react */
import { INTERVALS } from './event-modal';
import useAlertDialog from 'providers/alert-dialog-provider';
const EVENT_TYPES = {
  O: 'One Time',
  W: 'Weekly',
  Y: 'Yearly'
};

const EVENT_COLOR = {
  O: 'yellow',
  W: 'pink',
  Y: 'purple'
};

const styles = css`
  background: var(--neutral-09);
  width: 100%;

  border: 1px solid var(--neutral-12);
  box-sizing: border-box;
  border-radius: 4px;

  header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    width: 100%;
    color: var(--neutral-00);
    background: var(--neutral-07);
    border-radius: 4px;
    [role='label'] {
      margin-left: auto;
    }
  }

  .event-details {
    display: flex;
    padding: 24px;
    gap: 24px;
    align-items: center;
    > .action-menu-wrapper {
      margin-left: auto;
      padding: 8px;
    }
  }

  dl {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 0.3rem;
  }
  dt {
    display: inline-block;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #7f8795;
  }

  dd {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;

    color: var(--neutral-00);
  }
  span.actions {
    display: flex;
    margin-left: auto;
    gap: 8px;
    button {
      padding: 12px;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
`;
const EventCard = ({ event, handleRemove, index }) => {
  const { setModalState, dialog } = useModal();

  const confirm = useAlertDialog();
  const mainForm = useFormikContext();
  return (
    <article css={styles}>
      <header>
        {event.name}
        <Label color={EVENT_COLOR[event.freq]}>{EVENT_TYPES[event.freq]}</Label>
      </header>

      <input type="hidden" name="freq" value={event?.freq} />

      <section className="event-details">
        <dl>
          <dt>First Occurence Starts</dt>
          <dd>
            <time>{new Date(event.start).toLocaleDateString()}</time>
            <span> - </span>
            <time>{new Date(event.start).toLocaleTimeString()}</time>
          </dd>

          <dt>First Occurence Ends</dt>
          <dd>
            <time>{new Date(event.end).toLocaleDateString()}</time>
            <span> - </span>
            <time>{new Date(event.end).toLocaleTimeString()}</time>
          </dd>

          {event?.freq == 'W' && (
            <>
              <dt>Week Days</dt>
              <dd>{`${event.days?.join(', ')}`}</dd>
            </>
          )}
          <dt>Duration</dt>
          <dd>
            <time>
              {formatDistanceStrict(new Date(event.start), new Date(event.end))}
            </time>
          </dd>

          {(event?.freq == 'W' || event?.freq == 'Y') && (
            <>
              <dt
                css={css`
                  margin-top: 1rem;
                `}
              >
                Schedule Terminates
              </dt>
              <dd
                css={css`
                  margin-top: 1rem;
                `}
              >
                <time>{new Date(event.until).toLocaleDateString()}</time>
                <span> - </span>
                <time>{new Date(event.until).toLocaleTimeString()}</time>
              </dd>
              <dt>Expiration:</dt>
              <dd>
                {formatDistanceToNow(new Date(event.until), {
                  addSuffix: true
                })}
              </dd>
              <dt>Interval:</dt>
              <dd>
                {
                  INTERVALS.find(
                    (i) => parseInt(i.id) == parseInt(event.interval)
                  )?.name
                }
              </dd>
            </>
          )}
        </dl>
        <span className="actions">
          <DialogDisclosure
            {...dialog}
            as={AuxiliaryButton}
            compact
            onClick={() => {
              setModalState({
                component: EventModal,
                eventIndex: index,
                eventType: event.type == 'I' ? 'include' : 'exclude',
                mainForm
              });
            }}
          >
            <Pencil />
          </DialogDisclosure>
          <AuxiliaryButton
            compact
            color="#ea3737"
            onClick={async () => {
              if (
                await confirm({
                  description: 'Are you sure you want to remove this event?'
                })
              )
                handleRemove(index);
            }}
          >
            <Trash />
          </AuxiliaryButton>
        </span>
      </section>
    </article>
  );
};

export default EventCard;
