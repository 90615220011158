import React from 'react';
import Template from 'base/template';
import { Button, CancelButton } from 'components/buttons';
import FormProvider from 'providers/form-provider';
import { Redirect } from 'react-router-dom';
import { useMutationWrapper } from 'utils/ajax';
import { useRedirect } from 'hooks/redirect-hook';

import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import PageTitle from 'components/page-title';
import Content from 'base/content';
import Breadcrumbs from 'components/breadcrumbs';
import AudioClip from 'api/audio-clip';
import TextField from 'components/form/text-field';
import { useDropzone } from 'react-dropzone';
import { css } from '@emotion/react';
import { Files } from 'react-bootstrap-icons';
/** @jsxImportSource @emotion/react */

const DropFiles = ({ setFieldValue }) => {
  const [file, setFile] = React.useState();

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFieldValue('file', acceptedFiles[0]);
      setFile(acceptedFiles[0]);
    }
  });

  return (
    <div
      {...getRootProps({ className: 'dropzone' })}
      css={css`
        grid-column: 1/-1;
        display: flex;
        padding: 1rem;
        justify-content: center;
        align-items: center;
        border: 1px dashed gray;

        * > {
          max-width: 100%;
          width: 100%;
        }
      `}
    >
      <div>
        <input {...getInputProps()} />
        {!file && (
          <>
            <p>Drag 'n' drop some files here, or click to select files</p>
            <em>(Only *.wav audio files are accepted)</em>
          </>
        )}
        {file && (
          <ul>
            <li key={file.path}>
              {file.path} - {file.size} bytes
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};
const Save = (props) => {
  let aux = props?.location?.state?.aux || null;

  const SCHEMA = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required')
  });

  const INITIAL_VALUES = {};

  let { isLoading, mutate, isSuccess } = useMutationWrapper(
    ['audio-clip', 'save'],
    ({ name, file }) => AudioClip.save(name, file),
    {}
  );

  const { redirect, setRedirect } = useRedirect(isSuccess);

  return (
    <Template {...props}>
      <Content {...props}>
        {redirect && <Redirect to="/audio-clips" />}
        <PageTitle>Audio Clip Add</PageTitle>
        <Breadcrumbs crumbs={props.crumbs} />
        <Formik
          validationSchema={SCHEMA}
          initialValues={{}}
          onSubmit={(values) => {
            console.log(values);
            const formData = new FormData();
            formData.append('file', values.file);
            mutate({ name: values.name, file: formData });
          }}
        >
          {({ handleSubmit, values, setFieldValue }) => (
            <FormProvider mutateFunc={handleSubmit} disabled={isLoading}>
              <section>
                <div
                  css={css`
                    grid-column: 1/-1;
                  `}
                >
                  <Field name="name" as={TextField}></Field>
                </div>
                <DropFiles setFieldValue={setFieldValue} />
              </section>

              <footer>
                <Button type="submit">Submit</Button>
                <CancelButton onClick={() => setRedirect(true)} />
              </footer>
            </FormProvider>
          )}
        </Formik>
      </Content>
    </Template>
  );
};

export default Save;
