import React from 'react';
import styled from '@emotion/styled';
import SnapPictureControl from './snap-picture-control';
import IntercomControl from './intercom-control';
import IntercomDoorControl from './intercom-door-control';
import VolumeControl from './volume-control';
import ResolutionControl from './resolution-control';
import FeedViewCount from './feed-view-count';
import { IconFocusAuto, IconRotateClockwise } from '@tabler/icons-react';
import { useMutationWrapper } from 'utils/ajax';
import CameraAction from 'api/camera_action';
import OverlayControl from './overlay-control';

const DelayedButton = ({ comp, children, ...props }) => {
  let Component = comp;
  // const [isDisabled, setIsDisabled] = React.useState(false);

  // const handleClick = () => {
  //   setIsDisabled(true);
  //   setTimeout(() => {
  //     setIsDisabled(false);
  //   }, delay);

  //   // Call the provided onClick handler, if available
  //   if (onClick && typeof onClick === 'function') {
  //     onClick();
  //   }
  // };
  //delay pressing 5 seconds

  const [isClickable, setIsClickable] = React.useState(false);

  const enableClick = () => {
    setIsClickable(true);
  };

  // Delay in milliseconds
  const delay = 4000;

  setTimeout(enableClick, delay);
  if (!isClickable) return null;

  return comp ? (
    <Component {...props}>{children}</Component>
  ) : (
    <button {...props}>{children}</button>
  );
};

const Controls = styled('article')`
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: ${(props) => (props.controlsOpen === true ? '48px' : '0px')};
  transition: all 0.3s ease;
  visibility: visible;
  background: rgba(29, 32, 35, 0.4);
  z-index: 1000;
  overflow: hidden;
  .controls-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    height: 100%;
    align-items: center;
    justify-content: start;
    padding: 0.5rem;
    .volume-control {
      margin-left: auto;
    }
    > article {
      button {
        width: fit-cnotent;
        svg {
          color: white;
          width: 24px;
          height: 24px;
        }
      }
    }
  }
`;
export default ({
  videoProperties,
  videoRef,
  host,
  onVapix,
  pipeline,
  controlsOpen,
  setControlsOpen,
  resolution = [],
  focus,

  setRefresh
}) => {
  if (host.online == 0) return null;
  const videoEl = videoRef.current;

  const afMutation = useMutationWrapper(
    ['camera', host?.id, 'autoFocus'],
    () => CameraAction.autoFocus(host.id),
    {
      loadingMessage: 'Focusing...'
    }
  );

  const handleAutoFocus = () => {
    afMutation.mutate();
  };

  return (
    <Controls className="video-controls" controlsOpen={controlsOpen}>
      <div className="controls-container">
        {host?.intercom_id && (
          <IntercomControl intercom_id={host?.intercom_id} />
        )}

        <IntercomDoorControl camera={host} />

        <VolumeControl videoEl={videoEl} />
        <SnapPictureControl
          videoProperties={videoProperties}
          videoEl={videoEl}
        />
        {focus && !pipeline?.recording && (
          <article>
            <button disabled={afMutation.isLoading} onClick={handleAutoFocus}>
              <IconFocusAuto />
            </button>
          </article>
        )}
        <ResolutionControl
          resolution={resolution}
          host={host}
          onVapix={onVapix}
          pipeline={pipeline}
          setControlsOpen={setControlsOpen}
        />
        <FeedViewCount camera={host} />
        {!pipeline?.recording && (
          <article>
            <DelayedButton onClick={() => setRefresh((prev) => prev + 1)}>
              <IconRotateClockwise />
            </DelayedButton>
          </article>
        )}
        {/* <OverlayControl host={host} onVapix={onVapix} pipeline={pipeline} /> */}
      </div>
    </Controls>
  );
};
