import React from 'react';
/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';
import { ArrowRepeat } from 'react-bootstrap-icons';
import { AuxiliaryButton, IconButton } from 'components/buttons';
import { useQueryClient } from 'react-query';
import { useHeadlessTable } from 'providers/headless-infinite-table-provider';
const bounce = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const TableReloadButton = (props) => {
  const queryClient = useQueryClient();
  const { id, isLoading } = useHeadlessTable();
  return (
    <AuxiliaryButton
      css={
        isLoading &&
        css`
          svg {
            animation: ${bounce} 1s ease infinite;
          }
        `
      }
      disabled={isLoading}
      onClick={() => queryClient.resetQueries([id])}
      compact
    >
      <ArrowRepeat />
    </AuxiliaryButton>
  );
};

export default TableReloadButton;
