import React from "react";
import { css } from "@emotion/react";
/** @jsxImportSource @emotion/react */
const styles = css`
  box-shadow: inset 0px -1px 0px var(--neutral-12);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px;
  width: 100%;
  background: var(--neutral-09);
  // border-radius: 4px;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 15px;
  height: 36px;
  // letter-spacing: 0.87;
  /* identical to box height */

  text-transform: uppercase;

  color: var(--neutral-01);
`;
const Heading = ({ label }) => {
  return <span css={styles}>{label}</span>;
};

export default Heading;
