import * as Popover from "@radix-ui/react-popover";
import React from "react";
import { css } from "@emotion/react";

import AsyncSelect from "components/headless-select/async";
import { Button } from "components/buttons";
import Site from "api/site";
/** @jsxImportSource @emotion/react */

const SitePopover = ({ setStagedRows }) => {
  const [globalSite, setGlobalSite] = React.useState();

  const [isOpen, setIsOpen] = React.useState();

  return (
    <Popover.Root open={isOpen} onOpenChange={setIsOpen}>
      <Popover.Trigger asChild>
        <Button
          css={css`
            height: 20px;
          `}
          compact
          onMouseDown={(e) => e.stopPropagation()}
          onClick={(e) => e.stopPropagation()}
        >
          Set Sites
        </Button>
      </Popover.Trigger>
      <Popover.Content asChild sideOffset={10}>
        <aside
          onMouseDown={(e) => e.stopPropagation()}
          onClick={(e) => e.stopPropagation()}
          css={css`
            position: relative;
            box-shadow: var(--popover-box-shadow);
            width: auto;
            background: var(--neutral-09);
            border: 1px solid var(--neutral-12);
            box-sizing: border-box;
            max-height: 400px;
            border-radius: 5px;
            width: 240;

            font-size: 14px;
            z-index: 300;
            footer {
              margin-bottom: 1rem;
              display: flex;
              justify-content: center;
              gap: 0.5rem;
              > button {
                height: 20px;
              }
            }
          `}
        >
          <AsyncSelect
            checkboxes={false}
            data-compact
            data-inline
            queryKey="site-select-all"
            fetchFn={Site.search}
            css={css`
              .select__control {
                width: unset !important;
                margin: 8;
              }
              .select__menu {
                //box-shadow: none;
                border: none;
                position: relative;
                box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.1);
              }
              .select__menu-list {
                max-height: 250px !important;
                overflow-y: auto;
                overflow-x: hidden;
              }
              input {
                opacity: 1 !important ;
              }
            `}
            //onChange={(e) => setGlobalAccessLevel(e)}
            menuIsOpen={true}
            closeMenuOnSelect={true}
            onChange={(e) => setGlobalSite(e)}
          />

          <Popover.Arrow />
          <footer>
            <Button
              data-compact
              compact
              disabled={!globalSite}
              onClick={() => {
                setStagedRows((prev) =>
                  prev.map((p) => ({
                    ...p,
                    site: globalSite,
                  }))
                );

                setIsOpen(false);
              }}
            >
              Set All Sites
            </Button>
            <Button
              data-compact
              compact
              css={css`
                height: 20px;
              `}
              onClick={() => {
                setStagedRows((prev) =>
                  prev.map((p) => ({
                    ...p,
                    site: null,
                  }))
                );

                setIsOpen(false);
              }}
            >
              Clear
            </Button>
          </footer>
        </aside>
      </Popover.Content>
    </Popover.Root>
  );
};

export default SitePopover;
