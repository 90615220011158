import React from "react";
import { useEventList } from "hooks/use-event-list";
import EventList from "components/event-list";
import EventListFilters from "components/event-list-filters";

const FILTERS = [
  {
    value: "",
    label: "All",
  },
  {
    value: "alarm",
    label: "Alarm",
  },
  {
    value: "access",
    label: "Access",
  },
];
const DoorEventList = ({ door }) => {
  const [filters, setFilters] = React.useState();
  const [selection, setSelection] = React.useState("");

  React.useEffect(() => {
    setFilters((prev) => ({ ...prev, category: selection }));
  }, [selection]);

  React.useEffect(() => {
    setFilters({
      door_id: door?.id,
      category: null,
    });
  }, [door]);

  const query = useEventList({
    filters,
  });
  return (
    <>
      <EventListFilters
        filters={FILTERS}
        selection={selection}
        setSelection={setSelection}
      />
      <EventList query={query} />
    </>
  );
};

export default DoorEventList;
