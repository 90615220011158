import React from 'react';
import SelectWrapper from 'components/headless-select';
/* @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { CancelButton, Button } from 'components/buttons';
import { Formik, Form } from 'formik';
import { Check2Circle } from 'react-bootstrap-icons';
import { useMutationWrapper, useQueryWrapper } from 'utils/ajax';
import ActionRule from 'api/action_rule';
import * as Yup from 'yup';
import { useQueryClient } from 'react-query';
import { Field } from 'formik';
import { Dialog, DialogClose } from '@radix-ui/react-dialog';
import { DialogTrigger, DialogContent } from 'components/dialog.js';
import AsyncSelect from 'components/headless-select/async';
import Schedule from 'api/schedule';
import { CheckBox } from 'components/form';
import Device from 'api/device';
const ACTIONS = [
  { id: 'recordVideo', name: 'Edge Recording' },
  { id: 'sendHttpsFeed', name: 'Cloud Recording' },
  { id: 'sendMotionFrames', name: 'Send Snapshots' }
];

const RULES = [
  { id: 'systemReady', name: 'Always Active' },
  { id: 'motionOn', name: 'On Motion' },
  { id: 'objectAnalyticsOn', name: 'On Object Analytics' }
];
const DESTINATIONS = [{ id: 'SD_DISK', name: 'SD CARD' }];
const FPS = ['5', '6', '7', '8', '9', '10', '15', '30'];

const CameraActionRuleModal = ({ children, camera, rule }) => {
  const mutation = useMutationWrapper(['action-rule', 'save'], (input) =>
    ActionRule.save(input, camera.device_id, rule?.id)
  );

  const queryClient = useQueryClient();

  const SCHEMA = Yup.object().shape({
    rule_type: Yup.string().required('Required'),
    schedule: Yup.string().required('Required'),
    action_type: Yup.string().required('Required'),
    // resolution: Yup.string().required('Required'),
    destination: Yup.string().when(['action_type'], {
      is: (action_type) => action_type === 'recordVideo',
      then: Yup.string().required()
    }),
    schedule: Yup.string()
      .nullable()
      .when(['rule_type'], {
        is: (rule_type) => rule_type === 'motionOn',
        then: Yup.string().nullable().required()
      }),
    resolution: Yup.string().when(['action_type'], {
      is: (action_type) => action_type === 'recordVideo',
      then: Yup.string().required()
    }),
    fps: Yup.string().when(['action_type'], {
      is: (action_type) => action_type === 'recordVideo',
      then: Yup.string().required()
    })
  });

  const [initialValues, setInitialValues] = React.useState({
    enabled: true,
    rule_type: '',
    action_type: '',
    destination: '',
    resolution: '',
    fps: '',
    rule_params: {},
    action_params: {},
    schedule: ''
  });

  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    if (!rule) return;
    let ruleType;

    const actionParams = JSON.parse(rule.action_params);

    if (rule.rule_type.includes('motionOn')) ruleType = 'motionOn';
    else if (rule.rule_type.includes('systemReady')) ruleType = 'systemReady';
    else if (rule.rule_type.includes('objectAnalyticsOn'))
      ruleType = 'objectAnalyticsOn';

    setInitialValues({
      enabled: rule.enabled == '1',
      destination: actionParams.storage_id,
      action_type: rule.action_type,
      rule_type: ruleType,
      schedule: rule.schedule_token,
      resolution: rule.resolution,
      fps: rule.fps
    });
  }, [rule]);

  // const { data: capabilities } = useQueryWrapper(
  //   ['capabilities', camera.device_id],
  //   () => ActionRule.capabilities(camera.device_id),
  //   {
  //     enabled: isOpen
  //   }
  // );

  const { data } = useQueryWrapper(
    ['device', 'capabilities', camera.device_id],
    () => Device.capabilities(camera.device_id, ['resolution']),
    {
      staleTime: 60000,
      cacheTime: 120000,
      enabled: camera.online == 1,
      noToast: true
    }
  );

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent
        css={css`
          max-width: 35vw;
          height: 80vh;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            height: 100%;

            h3 {
              margin: 4px 0 24px;
            }

            form {
              flex: 1;
              display: flex;
              flex-direction: column;
              margin: 0;
              .main {
                flex: 1;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                padding: 0.2rem;
              }
            }
            footer {
              flex: 0;
              display: flex;
              justify-content: space-between;
            }
          `}
        >
          <h3>Action Rule</h3>
          <Formik
            enableReinitialize={true}
            validationSchema={SCHEMA}
            initialValues={initialValues}
            onSubmit={(values, { resetForm }) => {
              mutation.mutate(
                {
                  enabled: values.enabled,
                  schedule:
                    values.rule_type == 'systemReady' ? null : values.schedule,
                  id: rule?.id,
                  rule_type: values.rule_type,
                  action_type: values.action_type,
                  action_params: {
                    storage_id: values.destination,
                    resolution: values.resolution,
                    fps: values.fps
                  },
                  rule_params: {}
                },
                {
                  onSuccess: () => {
                    setIsOpen(false);
                    queryClient.invalidateQueries('action-rules');
                  }
                }
              );
            }}
          >
            {({ values, handleSubmit, errors, resetForm, setFieldValue }) => (
              <Form>
                <div className="main">
                  <CheckBox
                    name="enabled"
                    label="Enabled"
                    checked={values.enabled}
                    onChange={(e) => setFieldValue('enabled', e.target.checked)}
                  />
                  <Field
                    component={SelectWrapper}
                    onChange={(e) => setFieldValue('action_type', e?.id)}
                    value={ACTIONS.find((t) => t.id == values.action_type)}
                    name="action_type"
                    placeholder="Select Action"
                    title="Action"
                    options={ACTIONS}
                    simple
                  />
                  <Field
                    component={SelectWrapper}
                    name="rule_type"
                    onChange={(e) => setFieldValue('rule_type', e?.id)}
                    value={RULES.find((t) => t.id == values.rule_type)}
                    placeholde="Select Trigger"
                    title="Trigger"
                    options={RULES}
                    simple
                  />
                  {values.rule_type != 'systemReady' && (
                    <Field
                      component={AsyncSelect}
                      name="schedule"
                      label="Name"
                      idx="token"
                      fetchFn={Schedule.search}
                      fetchFilters={{ noAdvanced: 1 }}
                      placeholde="Active During.."
                      title="Active During"
                      simple
                    />
                  )}
                  {values.action_type == 'recordVideo' && (
                    <Field
                      component={SelectWrapper}
                      name="destination"
                      onChange={(e) => setFieldValue('destination', e?.id)}
                      value={DESTINATIONS.find(
                        (t) => t.id == values.destination
                      )}
                      placeholde="Select Destination"
                      title="Destination"
                      options={DESTINATIONS}
                      simple
                    />
                  )}

                  <section
                    css={css`
                      display: flex;
                      align-items: center;
                      gap: 2rem;
                    `}
                  >
                    <Field
                      component={SelectWrapper}
                      name="resolution"
                      onChange={(e) => setFieldValue('resolution', e?.id)}
                      value={data?.resolution
                        ?.map((r) => ({
                          name: r,
                          id: r
                        }))
                        .find((t) => t.id == values.resolution)}
                      title="Resolution"
                      options={data?.resolution?.map((r) => ({
                        name: r,
                        id: r
                      }))}
                      simple
                    />
                    <Field
                      component={SelectWrapper}
                      name="fps"
                      onChange={(e) => setFieldValue('fps', e?.id)}
                      value={FPS?.map((r) => ({
                        name: r,
                        id: r
                      })).find((t) => t.id == values.fps)}
                      title="FPS"
                      options={FPS?.map((r) => ({
                        name: r,
                        id: r
                      }))}
                      simple
                    />
                  </section>
                </div>
                <footer>
                  <DialogClose>
                    <CancelButton compact label="Close" />
                  </DialogClose>
                  <Button
                    type="submit"
                    compact
                    onSubmit={handleSubmit}
                    disabled={mutation.isLoading}
                  >
                    <Check2Circle size={24} />
                    Save
                  </Button>
                </footer>
                {process.env.NODE_ENV == 'development' && (
                  <div>
                    {JSON.stringify(errors)} / {JSON.stringify(values)}
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CameraActionRuleModal;
