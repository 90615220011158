import React from 'react';

const usePlayerControls = ({ host, autoPlay = true }) => {
  const [seek, setSeek] = React.useState(null);
  const [error, setError] = React.useState();

  const [refresh, setRefresh] = React.useState(0);
  const [waiting, setWaiting] = React.useState(true);

  const [videoProperties, setVideoProperties] = React.useState({
    width: 1920,
    height: 1080
  });

  const [play, setPlay] = React.useState(autoPlay);

  const onLoading = React.useCallback((videoProperties) => {
    setWaiting(true);
    setVideoProperties((prev) => ({ ...prev, ...videoProperties }));
  }, []);

  const onPlaying = React.useCallback(() => {
    setWaiting(false);
  }, []);

  return {
    videoProperties,
    seek,
    error,
    refresh,
    setRefresh,
    waiting,
    setWaiting,
    setError,
    setPlay,
    play,
    host,
    onPlaying,
    onLoading
  };
};

export default usePlayerControls;
