import { dataFetchQuery } from 'utils/ajax';

import { API_HOST_URL } from './api-url';
const API_URL = API_HOST_URL + '/department_api';

import { callAPI } from 'utils/ajax';

const Department = {
  get(id) {
    return callAPI('GET', `/department/${id}`);
  },

  search(input = {}) {
    return callAPI('GET', `/department`, input);
  },
  save(input = {}, id = '') {
    return callAPI(id ? 'PUT' : 'POST', `/department/${id}`, input);
  },

  stats(input = {}) {
    return callAPI('GET', `/department/stats`, input);
  },
  removeAccessLevels(input = {}, token = '') {
    return callAPI('POST', `/department/removeAccessLevels/${token}`, input);
  },
  addAccessLevels(input = {}, token = '') {
    return callAPI('POST', `/department/addAccessLevels/${token}`, input);
  },
  addPeople(input = {}, depId) {
    return callAPI('PUT', `/department/addPeople/${depId}`, input);
  },

  removePeople(input = {}, depId) {
    return callAPI('PUT', `/department/removePeople/${depId}`, input);
  },

  add(input = {}) {
    return dataFetchQuery(`${API_URL}/add`, input);
  },

  edit(input = {}, id) {
    return dataFetchQuery(`${API_URL}/edit/` + id, input);
  },

  delete(id) {
    return callAPI('DELETE', `/department/` + id);
  },

  // TODO move this to use Access Level api when moving it to new api
  searchAccessLevels(id) {
    return dataFetchQuery(`${API_URL}/searchAccessLevels/` + id, {});
  },

  checkGroupInAd(uuid) {
    return dataFetchQuery(`${API_URL}/checkGroupInAd/` + uuid, {});
  },

  getTreeMap(input = {}) {
    return dataFetchQuery(`${API_URL}/getTreeMap`, input);
  }
};

export default Department;
