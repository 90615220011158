/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, cx, Global } from '@emotion/react';
import { ArrowRepeat } from 'react-bootstrap-icons';
import { Plus } from 'react-bootstrap-icons';
import { useHistory } from 'react-router';
import chroma from 'chroma-js';
import { useLocation } from 'react-router-dom';

const secondaryButtonStyles = (props) => css`
  display: inline-flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  padding: 4px 12px;
  gap: 8px;
  white-space: nowrap;

  height: ${props.compact ? '40px' : '48px'};
  background: ${props.alert ? 'var(--red-v-03)' : 'var(--secundary-v-02)'};
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  color: ${props.alert ? 'var(--red-base)' : 'var(--secundary-base)'};
  &:hover {
    background: var(--hover-background-color);
    transform: all ease 2s;
  }
  &:disabled {
    opacity: 50%;
    cursor: default;
    pointer-events: none;
  }
`;

const cancelButtonStyles = (props) => css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  height: 48px;
  padding: 8px 16px;

  border: 2px solid var(--neutral-12);
  box-sizing: border-box;
  border-radius: 4px;
  color: var(--neutral-01);
  height: ${props.compact ? '40px' : '48px'};

  &:hover {
    color: var(--neutral-00);
    transform: all ease 2s;
  }
  &:disabled {
    opacity: 50%;
    cursor: default;
  }
`;

const primaryButtonStyles = (props) => css`
  display: flex;
  gap: 0.5rem;
  position: relative;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;

  background: var(--secundary-base);
  border-radius: 4px;
  height: ${props.compact ? '40px' : '48px'};
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  font-size: 14px;
  color: var(--neutral-11);
  justify-content: center;
  &:hover {
    background-color: var(--secundary-v-01);
  }
  &:disabled {
    background: #ccc !important;
    cursor: default;
  }
  &:focus {
  }
`;

const auxiliaryButtonStyle = (props) => css`
  font-weight: 500;
  justify-content: center;
  position: relative;

  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  gap: 8px;
  height: ${props.compact ? '40px' : '48px'};
  align-items: center;
  padding: ${props.compact ? '0 8px' : '0 12px'};
  color: ${props.color || 'var(--secundary-v-01)'};
  border: 1px solid ${props.color || 'var(--secundary-v-03)'};
  box-sizing: border-box;
  border-radius: 4px;
  width: fit-content;
  transition: border 150ms linear;
  svg {
    width: 24px;
    height: 24px;
  }
  &:hover:enabled {
    color: ${chroma(props.color || '#2480d6')
      .darken()
      .saturate(2)
      .hex()};
    border: 1px solid
      ${chroma(props.color || '#2480d6')
        .darken()
        .saturate(0.1)
        .hex()};
  }
  &:disabled {
    opacity: 50%;
    cursor: default;
  }
  &:focus {
  }
`;

const iconButtonStyle = (props) => css`
  transition: color ease 300ms;
  padding: 0.4rem;
  &[data-compact='true'] {
    svg {
      width: 14px;
      height: 14px;
    }
  }

  position: relative;
  justify-content: center;
  font-style: normal;
  display: flex;
  gap: 8px;
  height: fit-content;
  align-items: center;

  color: ${props.delete
    ? 'var(--red-base)'
    : props.color || 'var(--neutral-03)'};
  //border: 1px solid ${props.color || 'var(--secundary-base)'};
  box-sizing: border-box;
  border-radius: 4px;
  width: fit-content;

  &[data-active='true'] {
    background: ${props.delete ? 'var(--red-v-02)' : 'var(--secundary-v-02)'};
    transform: all ease 2s;
    color: ${props.delete ? 'var(--red-base)' : 'var(--secundary-v-01)'};
  }

  &:hover:enabled {
    background: ${props.delete ? 'var(--red-v-02)' : 'var(--secundary-v-02)'};
    transform: all ease 2s;
    color: ${props.delete ? 'var(--red-base)' : 'var(--secundary-v-01)'};
  }
  &:disabled {
    opacity: 80%;
    cursor: default;
    background: none;
  }
  &:focus {
    background: ${props.delete ? 'var(--red-v-02)' : 'var(--secundary-v-02)'};
    transform: all ease 2s;
    color: ${props.delete ? 'var(--red-base)' : 'var(--secundary-v-01)'};
  }
  svg {
    height: 22px;
    width: 22px;
  }
`;

const InvertButton = (props) => {
  const $color = `text-${props.color || 'purple'}-500 border-${
    props.color || 'purple'
  }-600`;

  return (
    <button
      type="button"
      className={classNames(
        `flex  mt-2  border px-2 text-sm rounded font-medium ${$color}`,
        {
          'opacity-50 cursor-disabled': props.disabled
        }
      )}
      disabled={props.disabled || props.isLoading}
      {...props}
    >
      {props.disabled ? props.disabledMessage : props.children}
    </button>
  );
};
const SimpleButton = (props) => {
  if (props.disabled) return <ButtonLoader size={6} />;
  else
    return (
      <button
        className="uppercase simple-button font-bold"
        disabled={props.disabled || props.isLoading}
        {...props}
        type="button"
      >
        {props.children}
      </button>
    );
};
const IconButton = React.forwardRef((props, ref) => {
  return (
    <button
      data-loading={props.loading}
      disabled={props.disabled || props.loading}
      data-compact={props['data-compact'] ? true : false}
      type="button"
      css={iconButtonStyle(props)}
      ref={ref}
      {...props}
    ></button>
  );
});

const ActionButton = (props) => {
  if (props.disabled) return <ButtonLoader size={6} />;
  else
    return (
      <button
        disabled={props.disabled || props.isLoading}
        {...props}
        data-icon={props.icon}
        type="button"
      ></button>
    );
};

const AuxiliaryButton = React.forwardRef((props, ref) => {
  return (
    <button
      data-loading={props.loading}
      disabled={props.disabled || props.loading}
      type="button"
      css={auxiliaryButtonStyle(props)}
      ref={ref}
      {...props}
    ></button>
  );
});

const Button = React.forwardRef((props, ref) => {
  return (
    <button
      data-loading={props.loading}
      disabled={props.disabled || props.loading}
      type="button"
      ref={ref}
      css={primaryButtonStyles(props)}
      {...props}
    ></button>
  );
});

const AddButton = (props) => {
  const history = useHistory();
  const { label } = props;
  const location = useLocation();

  return (
    <Button
      compact
      onClick={() => history.push(`${location.pathname}/add`)}
      {...props}
    >
      {label}
    </Button>
  );
};

const CancelButton = (props) => {
  return (
    <button
      disabled={props.disabled || props.loading}
      type="button"
      css={cancelButtonStyles(props)}
      {...props}
    >
      {props.label || props.children || 'Cancel'}
    </button>
  );
};

const SecondaryButton = React.forwardRef((props, ref) => {
  return (
    <button
      data-loading={props.loading}
      disabled={props.disabled || props.loading}
      type="button"
      css={secondaryButtonStyles(props)}
      ref={ref}
      {...props}
    ></button>
  );
});

const ButtonLoader = ({ size = 3 }) => {
  return <ArrowRepeat className="animate-spin h-5 w-5" />;
};

const TableButton = (props) => {
  const { icon, buttonClasses } = props;

  return (
    <button
      data-icon={icon}
      disabled={props.disabled || props.isLoading}
      onClick={props.onClick}
      className={`bg-primary-500 focus:outline-none
                         hover:bg-primary-600 font-medium text-white capitalize  py-2 px-4 rounded inline-flex items-center disabled:opacity-50 disabled:bg-gray-300 ${buttonClasses}`}
    >
      {props.isLoading && <ButtonLoader />}
      {props.children}
    </button>
  );
};

const TableExpandButton = (props) => {
  const { icon, setIsExpand, isExpand } = props;

  return (
    <button
      type="button"
      onClick={() => setIsExpand((prev) => !prev)}
      data-icon={`${!isExpand ? 'expand_more' : 'expand_less'}`}
    ></button>
  );
};

export {
  TableButton,
  Button,
  ButtonLoader,
  IconButton,
  InvertButton,
  SimpleButton,
  SecondaryButton,
  TableExpandButton,
  CancelButton,
  AddButton,
  AuxiliaryButton
};
