import React from 'react';
import { withSidebar } from 'components/with-sidebar';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import ActiveDoorChart from 'views/dashboard/active-door-chart';
import { useHeadlessTable } from 'providers/headless-table-provider';
import SidebarEmptyHolder from 'components/sidebar-empty-holder';
import styled from '@emotion/styled';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';
import { useQueryWrapper } from 'utils/ajax';
import Schedule from 'api/schedule';
const { startOfWeek, format, startOfDay, addDays } = require('date-fns');
const Stats = (props) => {
  return <ActiveDoorChart />;
};

export const SidebarWrapper = styled('article')`
  display: flex;

  height: 100% !important;
  flex-direction: column;

  label {
    width: 100%;
  }

  > header {
    display: flex;
    padding: 24px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 8px;

    .action-menu-wrapper {
      position: absolute;
      top: 0px;
      right: 0px;
      margin: 10px;
    }

    h2 {
      margin: 0;

      font-size: 18px;
      line-height: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      color: var(--neutral-00);
    }
  }
`;
const Sidebar = (props) => {
  const { selectedFlatRows } = useHeadlessTable();

  let schedule = selectedFlatRows?.[0]?.original || props.data;

  if (!schedule) return <SidebarEmptyHolder />;

  var today = new Date();
  var startDate = new Date(today);
  var endDate = new Date(today);
  endDate.setDate(today.getDate() + 30); // Add 30 days to the current date

  // Format the start and end dates as 'YYYY-MM'
  var startDateFormatted = startDate.toISOString().substr(0, 10); // Format: 'YYYY-MM'
  var endDateFormatted = endDate.toISOString().substr(0, 10);

  const [chartData, setChartData] = React.useState([]);

  const { data: occurences, isLoading } = useQueryWrapper(
    ['schedule', 'occurences', schedule.id],
    () => Schedule.getOccurrencesBetween(schedule.id),

    {
      enabled: Boolean(schedule)
    }
  );

  // React.useEffect(() => {
  //   if (!occurences) return;
  //   setChartData(occurences.map((o) => [o.date, new Date(o.date)]));
  // }, [occurences]);

  const cellSize = [40, 40];

  const option = {
    animation: false,
    title: {
      text: 'Active Days',
      subtext: 'Next 30 days'
    },
    tooltip: {
      position: 'top',
      formatter: function (p) {
        const format = echarts.time.format(
          p.data[0],
          '{yyyy}-{MM}-{dd}',
          false
        );
        return (
          format +
          ': ' +
          '</br>' +
          `<span style="margin-top:0.5rem">Event time:</span> </br>` +
          `<time style="font-size:11px">${p.data[1].toLocaleTimeString()}</time>`
        );
      }
    },
    visualMap: {
      min: 0,
      max: 1000,
      show: false,
      inRange: {
        color: ['#00a96c'] // Set the color scale, from green to yellow
      }
    },
    grid: {
      show: false
    },
    calendar: [
      {
        splitLine: {
          show: false
        },
        cellSize,
        yearLabel: {
          show: true, // Display the year label
          margin: 40,
          textStyle: {
            color: 'black', // Set the year label text color
            fontSize: 22 // Set the year label font size
          }
        },
        top: 100,
        left: 40,
        right: 20,
        orient: 'vertical',
        range: [startDateFormatted, endDateFormatted] // Set the date range
      }
    ],
    series: [
      {
        id: 'label',
        type: 'scatter',
        coordinateSystem: 'calendar',
        symbolSize: 0,

        label: {
          show: true,
          fontSize: 11,

          borderWidth: 1,
          borderRadius: 1,
          position: [-18, -16],

          formatter: function (params) {
            return `${echarts.time.format(params.value[0], '{dd}', false)}`;
          }
        },
        data: chartData
      },
      {
        color: '#00a96c',
        name: 'Active Occurences',
        type: 'scatter',
        coordinateSystem: 'calendar',
        data: chartData
      }
    ],
    legend: {
      show: false,
      data: ['Active Occurences'],
      top: 'bottom',
      textStyle: {
        fontSize: 12
      }
    }
  };
  const events = occurences
    ? [...occurences.include, ...occurences.exclude]
    : [];

  const calendarRef = React.createRef();

  React.useEffect(
    () => calendarRef.current && calendarRef.current.calendar.today(),
    [schedule]
  );
  return (
    <SidebarWrapper>
      <header>
        {/* <GuestIcon
          css={css`
            color: var(--neutral-05);
          `}
        /> */}
        <h2>{schedule.Name}</h2>
      </header>
      <section
        css={css`
          flex: 1;
          padding: 1rem;

          .fc-toolbar-title {
            font-size: 16px;
          }
          .fc-scrollgrid {
            border: none;
          }
          .fc-view-harness.fc-view-harness-active
            div.fc-timeGridWeek-view.fc-view.fc-timegrid
            table.fc-scrollgrid.fc-scrollgrid-liquid
            thead
            tr.fc-scrollgrid-section.fc-scrollgrid-section-header
            th
            div.fc-scroller-harness
            div.fc-scroller {
            overflow: hidden !important;
          }
          .fc-scrollgrid-section-header > th:nth-child(1),
          .fc-scrollgrid-section-header > td:nth-child(1) {
            border: none !important;
          }
          //.fc-scrollgrid-section-body > td:nth-child(1) {}
          td[role='presentation'] {
            border: none !important;
          }
          .fc-theme-standard td {
            border: none !important;
          }
          table.fc-col-header thead tr th.fc-col-header-cell {
            border: none !important;
          }
          thead tr th.fc-timegrid-axis {
            border: none !important;
          }

          .fc-header-toolbar {
            button {
              background: none;
              color: black !important;
              border: none !important;
              &:hover {
                background: var(--light--neutrals--300);
              }
              &:focus {
                box-shadow: none !important;
              }
              &:disabled {
                background: none !important;

                opacity: 50%;
              }
            }
          }

          .fc-col-header-cell-cushion {
            font-size: 12px;
            opacity: 50%;
          }
          .fc-timegrid-slot-label-cushion {
            font-size: 12px;
            opacity: 50%;
          }
          .event-type-include {
            background: var(--light--primary--400-base) !important;
            border: none !important;
          }
          .event-type-exclude {
            background: var(--light--others--red-400-base) !important;
            border: none !important;
          }
        `}
      >
        <section
          className="legend"
          css={css`
            display: flex;
            gap: 1rem;
            font-size: 14px;
            align-items: center;
            .box {
              gap: 1rem;
              height: 10px;
              width: 30px;
            }
          `}
        >
          <div className="box event-type-include" />
          <span>Include Event</span>
          <div className="box event-type-exclude" />
          <span>Exclude Event</span>
        </section>
        <FullCalendar
          ref={calendarRef}
          plugins={[timeGridPlugin]}
          initialView="timeGridWeek"
          events={events}
          allDaySlot={false}
          //defaultView={'agendaWeek'}
          slotLabelInterval={{ hours: 1 }} // Set the interval for displaying hours on the left
          slotDuration="01:00:00" // Set the duration of each time slot
          slotLabelFormat={{
            hour: 'numeric',
            minute: '2-digit',
            omitZeroMinute: false,
            meridiem: 'short'
          }}
          eventClassNames={(arg) => {
            // Use the 'type' property to dynamically set the class name
            return ['event-type-' + arg.event.extendedProps.type];
          }}
          initialDate={format(startOfWeek(new Date()), 'yyyy-MM-dd')}
          validRange={{
            start: format(startOfWeek(new Date()), 'yyyy-MM-dd'),
            end: format(addDays(startOfDay(new Date()), 365), 'yyyy-MM-dd')
          }}
          visibleRange={{
            start: format(startOfWeek(new Date()), 'yyyy-MM-dd'),
            end: format(addDays(startOfDay(new Date()), 365), 'yyyy-MM-dd')
          }}
          headerToolbar={{}}

          //eventContent={renderEventContent}
        />
      </section>
    </SidebarWrapper>
  );
};

export default withSidebar({ width: '500px' })(Sidebar, Stats);
