import React from "react";
import { css } from "@emotion/react";
/** @jsxImportSource @emotion/react */

import * as RadioGroup from "@radix-ui/react-radio-group";

export const RadioStyles = css`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  button {
    padding: 0.5rem;
    border-radius: 4px;
    &[aria-checked="true"] {
      font-weight: 500;
      background: var(--neutral-05);
    }
  }
`;
const EventListFilters = ({ filters, selection, setSelection }) => {
  return (
    <RadioGroup.Root
      asChild={true}
      value={selection}
      onValueChange={setSelection}
    >
      <section css={RadioStyles}>
        {filters.map((f, idx) => (
          <RadioGroup.Item key={idx} value={f.value}>
            <span>{f.label}</span>
          </RadioGroup.Item>
        ))}
      </section>
    </RadioGroup.Root>
  );
};

export default EventListFilters;
