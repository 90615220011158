import React from "react";
import { css } from "@emotion/react";
/** @jsxImportSource @emotion/react */
import EmailCreatable from "components/form/email-creatable";
import { Formik, Field } from "formik";

const Email = ({ mainForm }) => {
  return (
    <article>
      <Field name="email" component={EmailCreatable} />
    </article>
  );
};

export default Email;
