import React from 'react';
import { FiletypeCsv } from 'react-bootstrap-icons';
import { AuxiliaryButton } from './buttons';
import { CSVLink } from 'react-csv';
import { useHeadlessTable } from 'providers/headless-table-provider';
import { useQueryClient } from 'react-query';
import { useTable } from 'react-table';

const TableExport = (props) => {
  const queryClient = useQueryClient();
  const { data, state, id } = useHeadlessTable();

  const [selectedData, setSelectedData] = React.useState([]);

  const COLS = React.useMemo(() => props.columns || [], [props.columns]);
  const DATA = React.useMemo(() => selectedData, [selectedData]);

  React.useEffect(() => {
    const queries = queryClient.getQueriesData([id, 'search']);
    if (!queries) return;

    const merged = [].concat.apply(
      [],
      queries?.map((q) => q?.[1])?.map((d) => d?.data)
    );

    let $arr = merged?.filter((r) =>
      Object.keys(state.selectedRowIds).includes(r?.id || r?.token)
    );

    // hack fix to work with credentials uniqu tokens

    let uniqueTokens = new Set();

    // Filter the array to keep only objects with unique tokens and simultaneously populate the Set
    let uniqueObjects = $arr.filter((obj) => {
      if (uniqueTokens.has(obj.token)) {
        return false; // Token already exists, so filter out this object
      } else {
        uniqueTokens.add(obj.token); // Add token to Set
        return true; // Keep this object in the filtered array
      }
    });

    // Convert Set to Array and log it

    setSelectedData(uniqueObjects);
  }, [state.selectedRowIds, data]);

  //reshape per the table cols and data
  const tableInstance = useTable({ columns: COLS, data: DATA });

  // reshape the rows
  const shapedRows = [];

  for (const row of tableInstance.rows) {
    const r = { ...row.values };
    for (const key in r) {
      if (
        tableInstance.columns.find((c) => c?.id == key || c?.Header == key)
          ?.disableExport
      )
        delete r[key];
    }
    shapedRows.push(r);
  }

  return (
    <CSVLink filename={`credentials-${Date.now()}.csv`} data={shapedRows}>
      <AuxiliaryButton compact disabled={selectedData.length == 0}>
        <FiletypeCsv />
      </AuxiliaryButton>
    </CSVLink>
  );
};

export default TableExport;
