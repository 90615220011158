import 'css/sidebar.css';
import React from 'react';
import { withSidebar } from 'components/with-sidebar';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */

import { useHeadlessTable } from 'providers/headless-table-provider';
import SidebarEmptyHolder from 'components/sidebar-empty-holder';

import { useQueryWrapper } from 'utils/ajax';

import { IconCloud, IconCloudOff } from '@tabler/icons-react';
import ActionRule from 'api/action_rule';
import HealthTimeline from 'components/charts/health-timeline';
import { useEventList } from 'hooks/use-event-list';
import EventList from 'components/event-list';
import ReactECharts from 'echarts-for-react';
import { Heading } from 'components/form';
import LiveFeedStats from './live-feed-stats';

const styles = (props) => css`
  display: flex;

  height: 100% !important;
  flex-direction: column;
  > * {
    padding: 4px 16px;
  }
  label {
    width: 100%;
  }
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 100%;
    align-items: center;
    min-height: 32px;
    color: var(--neutral-00);
    overflow: hidden;
    text-overflow: ellipsis;
  }
  header {
    h2 {
      margin: 0;

      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 100%;
      /* identical to box height, or 18px */
      overflow: hidden;
      text-overflow: ellipsis;
      color: var(--neutral-00);
    }
    display: flex;
    align-items: center;
    padding: 24px;
    box-shadow: inset 0px -1px 0px var(--neutral-10);
    gap: 1rem;
    > button:last-of-type {
      margin-left: auto;
    }
    h4 {
      margin-bottom: 0.5rem;
      color: var(--neutral-02);
      font-weight: normal;
    }
    > span {
      width: 40px;
      height: 40px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px;
      background: ${props.ar.activity_status == 0
        ? 'var(--red-v-02)'
        : 'var(--primary-v-02)'};
      color: ${props.ar.activity_status == 0
        ? 'var(--red-v-01)'
        : 'var(--primary-base)'};

      border-radius: 4px;
    }
  }
  section.event-list {
    width: 100%;
    overflow-y: auto;
    margin-bottom: 8px;
    height: 100%%;
    overflow: auto;
  }
`;

const Sidebar = (props) => {
  const { selectedFlatRows } = useHeadlessTable();
  const [filters, setFilters] = React.useState();

  let ar = selectedFlatRows?.[0]?.original;

  const { isLoading, data } = useQueryWrapper(
    ['action-rule', 'health', 'profile', ar?.id],
    () => ActionRule.getHealthProfile(ar?.id),
    {
      staleTime: 30000,
      refetchOnMount: 'always',
      enabled: Boolean(ar),
      select: (data) => {
        return data.map((d) => ({
          ...d,
          itemStyle: {
            normal: {
              color: d.type == 'cloudActionRuleStart' ? '#00a96c' : '#ea3737'
            }
          }
        }));
      }
    }
  );

  const { isLoading: feedIsLoading, data: feedData } = useQueryWrapper(
    ['action-rule', 'feed', 'stats', ar?.id],
    () => ActionRule.getFeedStats(ar?.id, { topic: 'bytesReceived' }),
    {
      staleTime: 30000,
      refetchOnMount: 'always',
      enabled: Boolean(ar)
    }
  );

  React.useEffect(() => {
    if (!ar) return;
    setFilters({
      action_rule_id: ar?.id,
      types: ['cloudActionRuleStart', 'cloudActionRuleEnd']
    });
  }, [ar]);

  const query = useEventList({
    filters
  });
  let totalUptime = 0;
  let totalDowntime = 0;

  if (data)
    for (let i = 0; i < data?.length; i++) {
      const event = data[i];
      const eventType = event.type;
      const eventValue = event.value;

      if (eventType === 'cloudActionRuleStart') {
        totalUptime += eventValue[2];
      } else if (eventType === 'cloudActionRuleEnd') {
        totalDowntime += eventValue[2];
      }
    }

  const totalTime = totalUptime + totalDowntime;
  const uptimePercentage = (totalUptime / totalTime) * 100;

  if (selectedFlatRows.length == 0) return <SidebarEmptyHolder />;

  let option = {
    grid: {
      left: '60'
    },
    tooltip: {
      confine: true,
      trigger: 'axis'
    },
    animation: false,

    dataZoom: [
      {
        type: 'inside',
        start: 90,
        end: 100
      }
    ],
    xAxis: {
      type: 'time',
      boundaryGap: ['5%', '5%'],
      axisLabel: {
        interval: 2,
        hideOverlap: true,
        formatter: {
          month: '{monthStyle|{MMM}}',
          day: '{M}/{d}' // 1, 2, ...
        },
        rich: {
          monthStyle: {
            fontWeight: 'bold'
          }
        }
      }
    },
    yAxis: [
      {
        type: 'value',
        name: 'MB/Sec'
      }
    ],
    series: [
      {
        name: 'Mbs',
        type: 'line',
        step: 'end',

        data: feedData?.data?.map((f) => {
          return [new Date(f[0]), (parseInt(f[1]) / 10 / 1e6).toFixed(4)]; // neeed to divide by 10 - backedn gets every 10 seconds
        }),
        showSymbol: false
      }
    ]
  };

  return (
    <article css={styles({ ar })}>
      <header>
        <span>{ar.activity_status ? <IconCloud /> : <IconCloudOff />}</span>
        <h2>{ar.name}</h2>
        <LiveFeedStats ar={ar} />
      </header>
      <div>
        <h4>3 Day Health Timeline</h4>
        <HealthTimeline chartData={data} isLoading={isLoading} />
      </div>
      <div>
        <ReactECharts
          opts={{ renderer: 'svg' }}
          option={option}
          style={{ padding: '10px', height: '300px' }}
          showLoading={feedIsLoading}
          loadingOption={{
            showSpinner: false,
            maskColor: 'var(--neutral-09)',
            fontWeight: 'normal',
            text: 'Fetching Data...',

            fontStyle: 'normal',

            fontFamily: 'inherit'
          }}
        />
      </div>
      <div>
        <Heading label="Uptime Percentage" />
        <time>{uptimePercentage.toFixed(2)}%</time>
      </div>
      <EventList query={query} />
    </article>
  );
};

export default withSidebar({})(Sidebar, null);
