import React from 'react';
import { useHistory } from 'react-router-dom';
import { useMutateReset, useMutatePing } from './actions';
import { useMutateRestoreEvents } from 'views/controllers//actions';

import { MenuItem, MenuWrapper, MenuItemButton } from 'components/action-menu';
import { Menu } from '@headlessui/react';
import SyncModal from 'views/controllers/sync-modal';
import { useModal } from 'providers/modal-provider';
import { DialogDisclosure } from 'reakit/Dialog';
import { CUBE_API_HOST, getDeviceProxyUrl } from 'api/api-url';
import IsUserAllowed from 'components/is-user-allowed';
import IsCompany from 'components/is-company';
import useAlertDialog from 'providers/alert-dialog-provider';
import { Info } from 'react-bootstrap-icons';
export default function ActionMenu({ data: device, ...otherProps }) {
  const history = useHistory();

  let handleTools = () =>
    history.push('devices/tools/' + device?.id, {
      device,
      desc: device?.name
    });

  return (
    <MenuWrapper {...otherProps}>
      <MenuItem label="Tools" onClick={handleTools} />
    </MenuWrapper>
  );
}
