import React from "react";

export const useRedirect = (isSuccess) => {
  const [redirect, setRedirect] = React.useState(false);

  React.useEffect(() => {
    isSuccess && setRedirect(true);
  }, [isSuccess]);

  return { redirect, setRedirect };
};
