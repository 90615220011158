import React from 'react';
/** @jsxImportSource @emotion/react */
import incomingCallAudio from 'sounds/call-started.mp3';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import {
  CameraVideo,
  TelephoneFill,
  TelephoneMinusFill,
  XLg
} from 'react-bootstrap-icons';
import * as Popover from '@radix-ui/react-popover';
import { PopoverContent } from 'components/popover';
import { CheckBox, Toggle } from './form';
import { useEventSource } from 'react-use-websocket';
import { useLayout } from 'providers/layout-provider';
import { useAuth } from 'providers/auth';
import useSound from 'use-sound';
import { VolumeOff, VolumeUp } from 'react-bootstrap-icons';
import LivePlayer from './video/live-player';
import { useQueryWrapper } from 'utils/ajax';
import Camera from 'api/camera';
import TransmitAudio from '../views/intercoms/transmit-audio';
import { useLocalStorage } from '../hooks/local-storage';
import * as Portal from '@radix-ui/react-portal';
import Event from 'api/event';
import EventCard from './event-card';

export default () => {
  const {
    callEvent,
    setCallEvent,
    isPortalOpen,
    setIsPortalOpen,
    dnd,
    setDnd,
    setSound,
    sound,
    getSocketUrl
  } = useLayout();

  const [isOpen, setIsOpen] = React.useState(false);

  const { user } = useAuth();

  const { data, refetch } = useQueryWrapper(
    ['intercom', 'all'],
    () => Event.search({ category: 'intercom', limit: 5 }),
    {
      staleTime: 60000,
      enabled:
        Boolean(user?.company_id) &&
        Boolean(user?.features?.intercoms) &&
        Boolean(user?.intercom_count != 0)
    }
  );

  const { lastEvent } = useEventSource(
    getSocketUrl,
    {
      share: true,
      filter: (e) => {
        let data = JSON.parse(e.data);
        return [
          'callStarted',
          'callEnded',
          'callMissed',
          'callAnswered'
        ].includes(data.type);
      },
      shouldReconnect: (e) => true,
      withCredentials: true,
      retryOnError: true
    },

    Boolean(user && !user.missing2fa && !user.missingMfa)
  );

  React.useEffect(() => {
    if (!lastEvent) return;
    refetch();
  }, [lastEvent]);

  if (
    !user?.company_id ||
    !user?.features?.intercoms ||
    user?.intercom_count == 0
  )
    return null;

  return (
    <>
      <>
        <Popover.Root open={isOpen && !isPortalOpen} onOpenChange={setIsOpen}>
          <Popover.Trigger asChild={true}>
            <button
              css={css`
                color: ${dnd
                  ? 'var(--bell-hover-color)'
                  : 'var(--primary-base)'};

                position: relative;
                svg.sleep:first-of-type {
                  position: absolute;
                  top: -5;
                  right: -5;
                }
              `}
            >
              {dnd && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="sleep"
                  width={16}
                  height={16}
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M4 12h6l-6 8h6"></path>
                  <path d="M14 4h6l-6 8h6"></path>
                </svg>
              )}
              <TelephoneFill size={20} />
            </button>
          </Popover.Trigger>
          <Popover.Portal>
            <PopoverContent
              css={css`
                min-height: auto;
              `}
            >
              {/* {allCallEvents.map((event) => (
                <>
                  <div>{event.intercom_id}</div>
                  <div>{event.callStatus}</div>
                </>
              ))} */}
              <header
                css={css`
                  padding: 0.5rem;
                  h3 {
                    color: var(--table-header-color);
                  }
                `}
              >
                <h3>Recent calls</h3>
              </header>
              {data?.data.map((event, idx) => {
                return (
                  <article
                    key={idx}
                    onClick={() => {
                      const index = callEvent.find(
                        (e) => e.intercom_id == event.intercom_id
                      );

                      !index && setCallEvent((prev) => [...prev, event]);
                      setIsPortalOpen(true);
                      setIsOpen(false);
                    }}
                  >
                    <EventCard data={event} collapsible={false} />
                  </article>
                );
              })}
              <section
                css={css`
                  display: flex;
                  align-items: center;
                  padding: 0.5rem;
                  gap: 0.2rem;
                  border-top: 1px solid var(--neutral-12);
                  > button {
                    margin-left: auto;
                  }
                  > label {
                    fonr-size: 12px;
                    color: var(--secondary-font-color);
                  }
                  input[type='checkbox'] {
                    min-width: 16px;
                    min-height: 16px;
                    height: 16px;
                    width: 16px;
                  }
                `}
              >
                <CheckBox
                  label="Do not distrube"
                  checked={dnd}
                  onChange={(e) => setDnd(e.target.checked)}
                />

                <button onClick={() => setSound((prev) => !prev)}>
                  {sound ? <VolumeUp size={28} /> : <VolumeOff size={28} />}
                </button>
              </section>
            </PopoverContent>
          </Popover.Portal>
        </Popover.Root>
      </>
    </>
  );
};
