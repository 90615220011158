import React from "react";
import { css } from "@emotion/react";
/** @jsxImportSource @emotion/react */
import { components } from "react-select";
import { Badge } from "components/badge";
const Option = (props) => {
  return (
    <>
      <components.Option {...props}>
        <span
          css={css`
            color: var(--neutral-00);
            input[type="checkbox"] {
              width: 20px;
              height: 20px;
              accent-color: var(--primary-base);
              opacity: 0.7;
              cursor: pointer;
              grid-row: span 2;
            }
            > div {
              display: flex;
              flex-direction: column;
            }
            display: flex;
            gap: 8px;
            align-items: center;

            .device-name {
              color: var(--neutral-00);
              font-weight: bold;
            }
            .io-name {
            }
            [role="badge"] {
              margin-left: auto;
            }
          `}
        >
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />
          <div>
            <span className="device-name">{props.data.device_name}</span>
            <span className="io-name">{props.data.Name}</span>
          </div>
          <Badge color={props.data.Direction == "input" ? "blue" : "warning"}>
            {props.data.Direction}
          </Badge>
        </span>
      </components.Option>
    </>
  );
};

const SingleValue = (props) => {
  return (
    <>
      <components.SingleValue {...props}>
        <span>
          <strong>Device: </strong>
          {props.data.device_name} -- <strong>Port: </strong> {props.data.Name}
        </span>
      </components.SingleValue>
    </>
  );
};

export { Option, SingleValue };
