import styled from 'styled-components';

export const FetchingWidgetHolder = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 12px;
  width: 100%;
  gap: 1rem;
  height: 100%;
`;
