import React, { Fragment } from "react";
import { useQueryWrapper } from "utils/ajax";
import Aux from "api/auxiliary";
import AuxiliaryStateAvatar from "views/auxiliaries/auxiliary-state-avatar";
import SearchInput from "components/search-input";
import { css } from "@emotion/react";
/** @jsxImportSource @emotion/react */
import { useDebounce } from "hooks/debounce-hook";
import Spinner from "components/spinner";
import EmptyHolder from "components/empty-holder";
import SidePanel from "components/side-panel";
import SidebarEmptyHolder from "components/sidebar-empty-holder";
import { Slider } from "@mui/material";
import { Sliders, ViewStacked } from "react-bootstrap-icons";

const styles = css`
  padding: 1rem;
  > .spinner {
    margin: 24px auto;
    display: block;
  }
  .search-filter-container {
    margin: 16px;
  }
`;

const AuxiliaryList = ({ initialFilter, initialData }) => {
  const [filter, setFilter] = React.useState("");

  const { setInput } = useDebounce(setFilter);

  const { data, isLoading } = useQueryWrapper(
    ["auxiliaries", initialFilter, filter],
    () => Aux.search({ ...initialFilter, all: filter }),
    { enabled: !initialData }
  );

  return (
    <article className="aux-list" css={styles}>
      {isLoading ? (
        <Spinner />
      ) : data?.data?.length == 0 ? (
        <SidebarEmptyHolder
          icon={Sliders}
          nosubtitle
          title="No Auxiliaries associated with this door"
        />
      ) : (
        <>
          <SearchInput onChange={(e) => setInput(e.target.value)} />
          <ul>
            {data &&
              data.data.map((aux) => (
                <li
                  key={aux.id}
                  css={css`
                    display: flex;
                    gap: 8px;
                    align-items: center;
                    padding: 16px;
                    transition: background ease 300ms;

                    &:hover {
                      background: var(
                        --marker-selector--hover-background-color
                      );
                    }
                  `}
                >
                  <AuxiliaryStateAvatar aux={aux} />
                  {aux.name}
                </li>
              ))}
          </ul>
        </>
      )}
    </article>
  );
};

export default AuxiliaryList;
