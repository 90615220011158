import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */

import { Field, useFormikContext } from 'formik';

import ioportSelect from 'components/form/ioport-select';
import { NumberField, TextField } from 'components/form';
import AsyncSelect from 'components/headless-select/async';
import IOPort from 'api/ioport';
import {
  Option,
  SingleValue
} from 'components/headless-select/components/io-option';
const IOPortTrigger = ({ action }) => {
  const { values } = useFormikContext();
  return (
    <article>
      <p>
        <i>
          Choose an I/O port to trigger output signal. PORT must be in OUTPUT
          mode. To change and output port status visit the Device's tools page.
        </i>
      </p>
      <Field
        name="io_port_id"
        title="IO Port"
        placeholder={'Search Device or I/O name...'}
        label="Name"
        data-compact
        itemComponents={{ Option, SingleValue }}
        isMulti={false}
        fetchFn={IOPort.search}
        fetchFilters={{ direction: 'output' }}
        component={AsyncSelect}
        closeMenuOnSelect={true}
      />
      <NumberField
        css={css`
          width: 35%;
        `}
        half
        value={values.output_string}
        name="output_string"
        label="Duration in seconds (1-60)"
        min="1"
        max="60"
      />
    </article>
  );
};

export default IOPortTrigger;
