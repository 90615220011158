import React from 'react';

import Device from 'api/device';
import { useQuery } from 'react-query';
import ReactECharts from 'echarts-for-react';
import Spinner from 'components/spinner';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import { dateTime } from 'utils/time';
import EmptyHolder from 'components/empty-holder';

import * as echarts from 'echarts';

import getTime from 'date-fns/getTime';
import subDays from 'date-fns/subDays';
import EventListFilters from 'components/event-list-filters';

const HealthTimeline = React.forwardRef(
  ({ device_id, chartData = [], isLoading, slim, xAxisMin, ...props }, ref) => {
    function renderItem(params, api) {
      // var categoryIndex = api.value(0);

      var start = api.coord([api.value(0), 1]);
      var end = api.coord([api.value(1), 1]);
      var height = api.size([0, 1])[1] * 1;
      var rectShape = echarts.graphic.clipRectByRect(
        {
          x: start[0],
          y: start[1] - height / 2,
          width: end[0] - start[0],
          height: height
        },
        {
          x: params.coordSys.x,
          y: params.coordSys.y,
          width: params.coordSys.width,
          height: params.coordSys.height
        }
      );
      return (
        rectShape && {
          type: 'rect',
          transition: ['shape'],
          shape: rectShape,
          style: api.style()
        }
      );
    }
    let option = {
      animation: false,
      tooltip: {
        position: ['40%', 55],
        className: 'health-timeline-tooltip',
        formatter: function (params) {
          return `<div>
           <span data-type='${params.data.type}'>${params.data.type}</span>
           <time>Start: ${dateTime(new Date(params.value[0]))}</time> 
           <time>End: ${dateTime(new Date(params.value[1]))}</time>
           <span>approx:  
           ${(params.value[2] / 1000 / 60 / 60).toFixed(0)} 
           Hours</span>
          </div>`;
        }
      },

      grid: {
        height: 25,
        top: 5,
        right: 5,
        left: 5,
        show: true,
        borderColor: '#59606c',
        borderWidth: 4
      },
      xAxis: {
        type: 'time',
        show: true,
        min: xAxisMin ?? chartData?.[0]?.value[0],

        scale: true,
        axisLabel: {
          hideOverlap: true
        }
      },
      yAxis: {
        show: false,

        data: ['health']
      },
      dataZoom: props.slim
        ? []
        : [
            {
              type: 'inside',
              filterMode: 'weakFilter'
            }
          ],
      series: [
        {
          type: 'custom',
          renderItem,
          itemStyle: {
            opacity: 0.8
            // borderWidth: 1,
            // borderColor: "gray",
          },
          encode: {
            x: [1, 2],
            y: 0
          },
          data: chartData ?? []
        }
      ]
    };

    return (
      <>
        <ReactECharts
          css={css`
            svg {
              cursor: grab !important;
            }
            .health-timeline-tooltip > div {
              display: flex;
              flex-direction: column;
              gap: 0.1rem;
              font-size: 12px !important;
              span {
                &[data-type='online'],
                &[data-type='sensorOnline'] {
                  text-transform: uppercase;
                  color: var(--primary-base);
                }
                &[data-type='offline'],
                &[data-type='sensorOffline'] {
                  text-transform: uppercase;
                  color: var(--red-base);
                }
              }
            }
          `}
          ref={ref}
          opts={{ renderer: 'svg' }}
          option={option}
          style={{ height: '50px', padding: '0px' }}
          showLoading={isLoading}
          loadingOption={{
            showSpinner: false,
            maskColor: 'var(--neutral-09)',
            fontWeight: 'normal',
            text: 'Fetching Data...',

            fontStyle: 'normal',

            fontFamily: 'inherit'
          }}
        />
      </>
    );
  }
);

export default HealthTimeline;
