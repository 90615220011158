import React from 'react';
import { DateTimeField, NumberField } from 'components/form';
import Credential from 'api/credential';
import { CheckBox } from 'components/form';
import { useQuery } from 'react-query';
import AuthProfile from 'api/auth_profile';
import { useFormikContext } from 'formik';
import { Heading } from 'components/form';
import { css } from '@emotion/react';
import { Field } from 'formik';
import AsyncSelect from 'components/headless-select/async';
import AccessLevel from 'api/access_level';
import Spinner from 'components/spinner';
import { useQueryWrapper } from 'utils/ajax';
/** @jsxImportSource @emotion/react */
import { useAuth } from 'providers/auth';
import AsyncSelectWrapper from 'components/async-select-wrapper';

const divStyles = css`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 720px;
  article {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }
  section.extended {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 24px;
    padding: 24px;

    background: var(--orange-v-01);
    border-radius: 4px;
    > div:last-child {
      flex: 1;
    }
  }

  section.emergency {
    position: static;
    padding: 24px;
    background: var(--red-v-02);
    border-radius: 4px;
    input[type='checkbox'] {
      color: red;
    }
  }
`;

const CredentialParams = (props) => {
  const { values, setFieldValue } = useFormikContext();

  const { hasAllPermissions } = useAuth();

  return (
    <div css={divStyles}>
      <article>
        <Field
          component={DateTimeField}
          name="validFrom"
          label="Valid From"
          utc
        />

        <Field component={DateTimeField} name="validTo" label="Valid To" utc />
      </article>
      {!props.token && hasAllPermissions('alView') && (
        <Field
          isMulti
          title="Access Levels"
          name="accessLevels"
          component={AsyncSelect}
          fetchFn={AccessLevel.search}
        />
      )}
      <section className="extended">
        <Heading label="Overrides"></Heading>
        <NumberField
          half
          label="Extended Time"
          value={values.extendedTime}
          name="extendedTime"
        />
        <Field
          placeholder="Select Authentication Profile"
          simple
          component={AsyncSelect}
          isSearchable={false}
          fetchFn={AuthProfile.search}
          name="authenticationProfile"
          title="Auth Profile"
          isMulti={false}
          label="Name"
          idx="token"
          closeMenuOnSelect={true}
        />
      </section>
      <section className="emergency">
        <Heading label="Emergency"></Heading>
        <CheckBox
          name="Emergency Lock Down"
          label="Enable Site Lock Down with 3 consecutive swipes"
          color="red"
          checked={values.emergencyLockDown}
          onChange={(e) => setFieldValue('emergencyLockDown', e.target.checked)}
        />
      </section>
    </div>
  );
};

export default CredentialParams;
