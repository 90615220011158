import React from "react";
import { css } from "@emotion/react";
/** @jsxImportSource @emotion/react */

const inputStyles = css`
  height: 24px;
  width: 24px;
`;

export const CHECKED = 1;
export const UNCHECKED = 0;
export const INDETERMINATE = -1;

const CheckBox = ({ children, ...props }) => {
  const { value, indeterminate, ...otherProps } = props;

  const checkRef = React.useRef();

  React.useEffect(() => {
    checkRef.current.checked = value === CHECKED;
    checkRef.current.indeterminate = value === INDETERMINATE || indeterminate;
  }, [value]);

  return (
    <label
      css={css`
        display: inline-flex;
        align-items: center;
        gap: 5px;
        width: fit-content;

        cursor: pointer;
      `}
    >
      <input
        value={value}
        ref={checkRef}
        css={inputStyles}
        type="checkbox"
        {...otherProps}
      />
      {props.label || props.name}
    </label>
  );
};

export default CheckBox;
