import styled from '@emotion/styled';
export const Card = styled('section')`
  border-radius: 8px;
  background: var(--light-neutrals-000, #fff);
  box-shadow: 0px 5px 56px -5px rgba(80, 87, 96, 0.04);
  display: grid;
  grid-template-columns: 48px 1fr auto;
  padding: 24px;
  align-items: center;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
  min-height: 175px;
  height: fit-content;

  .data {
    grid-column: span 3;
    font-size: 14px;
    color: var(--light--neutrals--700);
  }
  .icon {
    border-radius: 4px;

    background: var(--light-neutrals-200, #f3f5f9);

    display: flex;
    min-width: 48px;
    min-height: 48px;
    max-width: 48px;
    max-height: 48px;
    width: 48px;
    height: 48px;
    justify-content: center;
    align-items: center;
    svg {
      width: 32;
      height: 32;
    }
  }
  .description {
    color: var(--light-neutrals-800, #59606c);

    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
  }
  .action {
    margin-left: auto;
  }
  h2 {
    margin: 0;
    margin-bottom: 4px;
    color: var(--light-neutrals-900, #1d2023);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
  }
`;
