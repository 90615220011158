import React from 'react';
import Camera from 'api/camera';
import { useQueryWrapper } from 'utils/ajax';
import Spinner from 'components/spinner';
import { css } from '@emotion/react';

import FeedModal from 'components/video/feed-modal';
import Player from 'components/video/player';
import LivePlayer from 'components/video/live-player';
/** @jsxImportSource @emotion/react */

const styles = css`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 1rem;
  flex-direction: column;
  height: 100%;
  .spinner {
    margin: auto;
    display: block;
  }
`;

const CameraFeed = ({ camera }) => {
  return <LivePlayer host={camera} />;
};
const DoorCameras = ({ door }) => {
  const { data, isLoading } = useQueryWrapper(
    ['camera', 'search', door.id],
    () => Camera.search({ generic_door_id: door.id })
  );

  return (
    <article css={styles}>
      {isLoading && <Spinner />}

      {data &&
        data?.data?.slice(0, 3).map((camera, _) => {
          return <CameraFeed key={camera.id} camera={camera} />;
        })}
    </article>
  );
};

export default DoorCameras;
