import React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { PlayCircle } from "react-bootstrap-icons";
/** @jsxImportSource @emotion/react */

const Limiter = styled("div")`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Layer = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;
export const ControlArea = styled("div")`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
`;

const VideoNative = styled("video")`
  max-height: 100%;
  object-fit: contain;
  width: 100%;
  border-radius: 0.3rem;
`;

const Overlay = styled("div")`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: var(--neutral-00);
  border-radius: 0.3rem;
  svg {
    color: white;
    width: 35%;
    height: 35%;
  }
`;

const Video = React.forwardRef((props, videoRef) => {
  const naturalAspectRatio = React.useMemo(() => {
    if (props.videoProperties === undefined) {
      return undefined;
    }

    const { width, height } = props.videoProperties;

    return width / height;
  }, [props.videoProperties]);

  React.useLayoutEffect(() => {
    if (naturalAspectRatio === undefined || limiterRef.current === null) {
      return;
    }

    const observer = new window.ResizeObserver(([entry]) => {
      const element = entry.target;
      const maxWidth = element.clientHeight * naturalAspectRatio;
      element.style.maxWidth = `${maxWidth}px`;
    });
    observer.observe(limiterRef.current);

    return () => observer.disconnect();
  }, [naturalAspectRatio]);
  const limiterRef = React.useRef();

  return (
    <div
      css={css`
        position: relative;
        width: 100%;
        height: 100%;
      `}
    >
      <Limiter ref={limiterRef}>
        <Layer>
          <VideoNative autoPlay ref={videoRef} />
        </Layer>
        {!props.play && (
          <Layer onClick={() => props.setPlay(true)}>
            <Overlay>
              <PlayCircle />
            </Overlay>
          </Layer>
        )}
      </Limiter>
    </div>
  );
});

export default Video;
