import React from 'react';

import { Th, SorterIcon } from 'components/table/table-components';
import { useHeadlessTable } from 'providers/headless-infinite-table-provider';
import IsShowColumn from './is-show-column';
const TableTh = ({ column }) => {
  const { setLSColumnsWidth, LSColumnsWidth, dispatch, sorting } =
    useHeadlessTable();
  let x = 0;
  let w = 0;

  const ref = React.useRef();
  const resizerRef = React.useRef();

  const [width, setWidth] = React.useState(
    JSON.parse(LSColumnsWidth)?.[column.id] || column?.style?.width || '180px'
  );

  const mouseDownHandler = React.useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      // Get the current mouse position
      dispatch({ type: 'DISABLE_FETCHING' });
      x = e.clientX;

      // Calculate the current width of column
      const styles = window.getComputedStyle(ref.current);
      w = parseInt(styles.width, 10);

      // Attach listeners for document's events
      document.addEventListener('mousemove', mouseMoveHandler);
      document.addEventListener('mouseup', mouseUpHandler);
    },
    [ref.current]
  );

  const mouseMoveHandler = React.useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      // Determine how far the mouse has been moved
      const dx = e.clientX - x;

      // Update the width of column

      if (column.fixed) return;
      if (
        column.style?.minWidth &&
        parseInt(w + dx) < parseInt(column.style.minWidth)
      )
        return;
      if (parseInt(w + dx) > 60) setWidth(`${w + dx}px`);

      //setLSColumnsWidth((prev) => ({ ...prev, [column.id]: Number(w + dx) }));
    },
    [ref.current]
  );

  // When user releases the mouse, remove the existing event listeners
  const mouseUpHandler = React.useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      let cols = JSON.parse(LSColumnsWidth);
      //console.log(cols);
      cols[column.id] = ref.current.style.width;
      // console.log(cols);
      setLSColumnsWidth(JSON.stringify(cols));
      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', mouseUpHandler);
      dispatch({ type: 'ENABLE_FETCHING' });
    },
    [ref.current]
  );

  resizerRef.current &&
    resizerRef.current.addEventListener('mousedown', mouseDownHandler);

  // if(sorting)
  //   column.canSort = false;

  return (
    <IsShowColumn column={column}>
      <Th
        ref={ref}
        data-selection={column.id == 'selection' ? 'true' : 'false'}
        data-rownumber={column.id == 'rownumber' ? 'true' : 'false'}
        data-sortable={column.canSort ? 'true' : 'false'}
        selectable
        {...column.getHeaderProps(
          column.getSortByToggleProps({ title: undefined })
        )}
        style={{ width: width }}
      >
        <div>
          <span>{column.render('Header')}</span>
          {/* Add a sort direction indicator */}
          <SorterIcon column={column} />
        </div>
        {!column.fixed && (
          <div
            onClick={(e) => e.stopPropagation()}
            onMouseDown={(e) => e.stopPropagation()}
            ref={resizerRef}
            className="table-resizer"
          />
        )}
      </Th>
    </IsShowColumn>
  );
};

export default TableTh;
