import React from 'react';
import { useHistory } from 'react-router-dom';

import { MenuItem, MenuItemDelete } from 'components/action-menu';
import {
  useDeleteMutate,
  useEnableMutate,
  useDisableMutate,
  useLostMutate
} from './actions';
import IsUserAllowed from 'components/is-user-allowed';
import MenuWrapper from 'components/action-menu/menu-wrapper';
import { useHeadlessTable } from 'providers/headless-table-provider';
import useAlertDialog from 'providers/alert-dialog-provider';
import { IconShieldCheckeredFilled } from '@tabler/icons-react';

export default function ActionMenu({ data: credential = {} }) {
  const { selectedFlatRows, state } = useHeadlessTable();
  const history = useHistory();
  const deleteMutate = useDeleteMutate(credential);
  const enableMutate = useEnableMutate(credential);
  const disableMutate = useDisableMutate(credential);
  const lostMutate = useLostMutate(credential);

  const confirm = useAlertDialog();

  let handleEdit = () =>
    history.push('/credentials/save/' + credential?.token, {
      credential,
      desc: credential?.Description
    });

  const handleDelete = async () => {
    if (
      await confirm({
        description: `Are you sure you want to delete ${
          Object.keys(state.selectedRowIds).length
        } Credential(s) ?`
      })
    )
      deleteMutate.mutate(Object.keys(state.selectedRowIds));
  };

  const handleEnable = () => enableMutate.mutate(credential?.token);
  const handleDisable = () => disableMutate.mutate(credential?.token);
  const handleLost = () => lostMutate.mutate(credential?.token);
  const handleAccessLevels = () =>
    history.push(`/credentials/${credential?.token}/levels`, {
      credential,
      desc: credential?.Description
    });

  return (
    <MenuWrapper disabled={selectedFlatRows.length == 0}>
      <IsUserAllowed condition="personEdit" restrict="C">
        <MenuItem
          disabled={selectedFlatRows.length != 1}
          label="Edit"
          onClick={handleEdit}
        ></MenuItem>
      </IsUserAllowed>
      <MenuItem
        disabled={selectedFlatRows.length != 1}
        label={credential.Enabled == 1 ? 'Disable' : 'Enable'}
        onClick={credential.Enabled == 1 ? handleDisable : handleEnable}
      ></MenuItem>
      <MenuItem
        disabled={selectedFlatRows.length != 1}
        label="Lost/Found"
        onClick={handleLost}
      ></MenuItem>
      <IsUserAllowed condition="alView">
        <MenuItem
          disabled={selectedFlatRows.length != 1}
          onClick={handleAccessLevels}
        >
          <IconShieldCheckeredFilled />
          Access Levels...
        </MenuItem>
      </IsUserAllowed>

      <MenuItemDelete onClick={handleDelete} />
    </MenuWrapper>
  );
}
