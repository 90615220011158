import React from 'react';
import Template from 'base/template';
import { css } from '@emotion/react';
import Sidebar from 'views/monitoring/sidebar';
import styled from 'styled-components';
import ViewNavigation from 'views/monitoring/view-navigation';
import ViewLayout from 'views/monitoring/view-layout';
import { useQueryWrapper } from 'utils/ajax';
import MonitoringView from 'api/monitoring_view';
import { useLocalStorage } from 'hooks/local-storage';
import { useAuth } from 'providers/auth';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

/** @jsxImportSource @emotion/react */

const Main = styled('main')`
  display: grid;
  padding: 1rem;
  grid-template-rows: 50px 1fr 100px;
  height: 100%;
  width: 100%;
`;

export const DEFAULT_VIEW = {
  name: 'default',
  layout: [{ type: 'widgets', rows: 1, cols: 1, items: [] }]
};

const Monitoring = (props) => {
  let { user } = useAuth();

  if (!user) return null;

  const [currentView, setCurrentView] = useLocalStorage(
    `${user?.user_id}:monitoring-view`
  );

  React.useEffect(() => {
    if (params.get('viewId')) {
      setCurrentView(params.get('viewId'));
    }
  }, []);

  const { data } = useQueryWrapper(['monitoring', 'search'], () =>
    MonitoringView.search()
  );

  const [selectedView, setSelectedView] = React.useState({});
  const [views, setViews] = React.useState([]);

  React.useEffect(() => {
    if (!data) return;

    setViews(data?.data ?? [DEFAULT_VIEW]);
    const v = data?.data?.find((v) => v.id == currentView) ?? data?.data[0];
    setSelectedView(v ?? DEFAULT_VIEW);
  }, [data]);

  const [isEdit, setIsEdit] = React.useState();

  React.useEffect(() => {
    if (!selectedView?.id) return;
    setCurrentView(selectedView.id);
    history.push({ search: `?viewId=${selectedView.id}` });
  }, [selectedView]);

  const [cols, setCols] = React.useState(selectedView?.cols);
  const [rows, setRows] = React.useState(selectedView?.rows);
  const { search } = useLocation();

  const params = React.useMemo(() => new URLSearchParams(search), [search]);
  const history = useHistory();

  return (
    <Template {...props}>
      <div
        css={css`
          display: grid;
          height: 100%;
          width: 100%;
          grid-template-rows: 50px 1fr;
          gap: 0.5rem;
          position: relative;
          z-index: 1;
        `}
      >
        {selectedView && (
          <ViewNavigation
            views={views}
            setViews={setViews}
            cols={cols}
            setCols={setCols}
            setRows={setRows}
            rows={rows}
            selectedView={selectedView}
            setSelectedView={setSelectedView}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
          />
        )}

        {selectedView.layout && (
          <ViewLayout
            cols={cols}
            rows={rows}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            selectedView={selectedView}
            setSelectedView={setSelectedView}
          />
        )}

        {/* <div className="timeline">TIMELINE</div> */}
      </div>

      {/* <Sidebar /> */}
    </Template>
  );
};

export default Monitoring;
