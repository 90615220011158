import { useAuth } from 'providers/auth';
import { useLayout } from 'providers/layout-provider';
import React from 'react';
const DOOR_STATES = {
  doorAccessed: 'Accessed',
  doorUnlocked: 'Unlocked',
  doorLocked: 'Locked',
  0: 'Locked',
  1: 'Unlocked',
  doorDoubleLocked: 'DoubleLocked',
  doorLockedDown: 'LockedDown'
};

const DOOR_ALARMS = {
  forcedOpen: 1,
  openTooLong: 1,
  normal: 1
};

import { useEventSource } from 'react-use-websocket';

const useDoorStateSocket = (door, filter) => {
  //TODO maybe make this only work if row is focused
  // const { lastMessage } = useWebSocket({ filter });
  const [doorState, setDoorState] = React.useState();
  const [changeTime, setChangeTime] = React.useState();
  const [doorAlarm, setDoorAlarm] = React.useState();

  const { user } = useAuth();

  const { getSocketUrl } = useLayout();

  const { lastEvent } = useEventSource(
    getSocketUrl,
    {
      share: true,
      filter: (e) => filter(JSON.parse(e.data)),
      withCredentials: true,
      retryOnError: true
    },
    Boolean(user && !user.missing2fa && !user.missingMfa)
  );

  React.useEffect(() => {
    if (!lastEvent) return;

    let data = JSON.parse(lastEvent.data);

    setDoorState(DOOR_STATES[data.type]);

    if (data.type in DOOR_ALARMS) setDoorAlarm(data.type);
    setChangeTime(data.utctime);
  }, [lastEvent]);

  return { doorState, changeTime, doorAlarm };
};

export default useDoorStateSocket;
