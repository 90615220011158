import React from 'react';
import { AvatarCircle } from 'components/avatar';
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { dateTime } from 'utils/time';

const styles = css`
  > div {
    display: flex;
    flex-direction: column;
    //box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    justify-content: center;
    align-items: center;
    padding: 16px;
    box-sizing: border-box;
    gap: 16px;
    background: var(--neutral-09);
    /* Neutrals/neutral-05 */

    border: 1px solid #e3e6ec;
    box-sizing: border-box;
    border-radius: 4px;
    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 100%;
      /* identical to box height, or 18px */

      text-align: center;

      color: var(--neutral-00);
    }

    h4 {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      /* identical to box height, or 12px */
      color: var(--neutral-02);

      text-align: center;
    }
  }
`;
const LastPerson = ({ data, lastEntry }) => {
  const [entry, setEntry] = React.useState({});

  React.useEffect(() => {
    if (data)
      setEntry({
        avatar: data.last_access_person_avatar,
        name: data.last_access_person_name,
        time: data.last_access_time,
        door: data.last_access_door_name
      });
  }, [data]);

  return (
    <article css={styles} className="last-entry">
      <div>
        <AvatarCircle
          name={entry?.name}
          url={entry?.avatar}
          size="100px"
          fontSize="20px"
        />
        <h3>{entry.name}</h3>
        <h4>{entry.door && entry.door}</h4>

        <h4>{entry.time && dateTime(entry.time)}</h4>
      </div>
    </article>
  );
};

export default LastPerson;
