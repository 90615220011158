import React from 'react';
import Template from 'base/template';

import Report from 'api/report';
import { columns } from 'views/reports/table-columns';
//import Sidebar from "views/doors/sidebar";
import IndexContent from 'base/index-content';
import { CheckCircle, Stopwatch, XOctagon } from 'react-bootstrap-icons';
import ActionMenu from 'views/reports/action-menu';
import { IconCalendarEvent, IconCheck } from '@tabler/icons-react';

const cards = [
  {
    name: 'total',
    label: 'Total'
  },
  {
    name: 'done',
    filter: { status: 'done' },
    label: 'Done',
    color: 'var(--primary-v-01)',
    background: 'var(--primary-v-03)',
    icon: IconCheck
  },
  {
    name: 'new',
    label: 'Scheduled',
    filter: { status: 'new' },
    icon: IconCalendarEvent
  }
];

const Reports = (props) => {
  // const FILTERS = React.useMemo(
  //   () => [
  //     {
  //       id: 'recent',
  //       label: 'Recent Searches...',
  //       options: []
  //     },
  //     {
  //       id: 'filters',
  //       label: 'Filters',
  //       options: [
  //         {
  //           value: ['scheduled', '1'],
  //           label: 'Scheduled',
  //           name: 'Scheduled',
  //           type: 'common'
  //         }
  //       ]
  //     }
  //   ],
  //   []
  // );
  return (
    <Template {...props}>
      <IndexContent
        statsQuery={Report.stats}
        newSorter
        title="Reports"
        id="reports"
        cards={cards}
        query={Report.search}
        columns={columns}
        addButton="Add A Report"
        selectable
        actionButton={ActionMenu}
        // filterOptions={FILTERS}
        getRowId={(row) => row._id}
        //sidebar={Sidebar}
        {...props}
      ></IndexContent>
    </Template>
  );
};

export default Reports;
