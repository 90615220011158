import React from "react";
import { Section } from "./installer-dashboard";
import ActiveCompanyChart from "./active-company-chart";
import * as RadioGroup from "@radix-ui/react-radio-group";
import { css } from "@emotion/react";
import { RadioStyles } from "./installer-dashboard";
import ChartTypeSelect from "components/charts/chart-type-select";
import ChartPeriodSelect from "components/charts/chart-period-select";
import ActiveCredentialChart from "./active-credential-chart";
/** @jsxImportSource @emotion/react **/
const ActiveCredential = (props) => {
  const [filter, setFilter] = React.useState("-1 day");
  const [type, setType] = React.useState();
  return (
    <Section id="active-credential" {...props}>
      <header>
        <div>
          <h4>Credential Activity</h4>
          <h5>Top 5 Active Credentials</h5>
        </div>
      </header>

      <ActiveCredentialChart filter={filter} type={type} />
    </Section>
  );
};

export default ActiveCredential;
