import React from 'react';
import { Dialog } from '@radix-ui/react-dialog';
import { DialogTrigger, DialogContent } from 'components/dialog.js';
import { css } from '@emotion/react';
import RestoreChart from 'components/charts/restore-chart';
/** @jsxImportSource @emotion/react */
import { startOfDay, subDays } from 'date-fns';

const DeviceRestoreChart = ({ device }) => {
  if (!device) return null;

  const [filters] = React.useState();

  return (
    <Dialog>
      <DialogTrigger
        css={css`
          &:hover {
            cursor: pointer !important;
            outline: 3px solid var(--secundary-v-01);
            .echarts-for-react {
              pointer-event: none;
            }
          }
        `}
      >
        <RestoreChart
          device={device}
          slim
          filters={{ from: startOfDay(subDays(new Date(), 7)), ...filters }}
        />
      </DialogTrigger>
      <DialogContent>
        <RestoreChart
          height="90%"
          device={device}
          filters={{ from: startOfDay(subDays(new Date(), 7)), ...filters }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default DeviceRestoreChart;
