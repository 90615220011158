import React from 'react';
import Template from 'base/template';
import { Button, CancelButton, SecondaryButton } from 'components/buttons';
import Checkbox from 'components/form/checkbox';
import Door from 'api/door';
import FormProvider from 'providers/form-provider';
import { useMutationWrapper } from 'utils/ajax';
import { useRedirect } from 'hooks/redirect-hook';
import { Redirect } from 'react-router-dom';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import Content from 'base/content';
import PageTitle from 'components/page-title';
import { NumberField } from 'components/form';
import { css } from '@emotion/react';
import Breadcrumbs from 'components/breadcrumbs';
import AsyncSelect from 'components/headless-select/async';
import Schedule from 'api/schedule';
import IoOpenSchedule from 'views/devices/io-open-schedule';
import IOPort from 'api/ioport';
/** @jsxImportSource @emotion/react */
import { useQueryWrapper } from 'utils/ajax';
import { useNotFound } from 'hooks/not-found-hook';
import { useParams } from 'react-router-dom';
import Aux from 'api/auxiliary';
const AuxiliarySchedule = (props) => {
  const { id } = useParams();

  const [aux, setAux] = React.useState();

  const query = useQueryWrapper(['aux', 'get', id], () => Aux.search({ id }), {
    initialData: {
      data: [props?.location?.state?.aux]
    },
    enabled: Boolean(!props?.location?.state?.aux),
    cacheTime: 0
  });

  useNotFound(id, query, props);

  React.useEffect(() => {
    if (!query.data.data?.[0]) return;
    setAux(query.data.data?.[0]);
  }, [query.data.data?.[0]]);

  const SCHEMA = Yup.object().shape({});

  const INITIAL_VALUES = {
    schedule: aux?.open_schedule_token,
    port_state: aux?.open_schedule_port_state ?? '1'
  };

  const { isLoading, isSuccess, mutate } = useMutationWrapper(
    ['aux', 'schedule'],
    (input) => IOPort.setOpenSchedule(input, aux?.io_port_id)
  );

  const { redirect, setRedirect } = useRedirect(isSuccess);

  return (
    <Template {...props}>
      <Content {...props}>
        <PageTitle>{aux?.name}</PageTitle>
        <Breadcrumbs crumbs={props.crumbs} />
        {aux && (
          <Formik
            validationSchema={SCHEMA}
            enableReinitialize={true}
            initialValues={INITIAL_VALUES}
            onSubmit={(values) => {
              mutate(values);
            }}
          >
            {({ handleSubmit }) => (
              <FormProvider mutateFunc={handleSubmit} disabled={isLoading}>
                {redirect && <Redirect to="/auxiliaries" />}
                <IoOpenSchedule deviceType={aux.device_type} />

                <footer>
                  <Button type="submit">Submit</Button>
                  <CancelButton onClick={() => setRedirect(true)} />
                </footer>
              </FormProvider>
            )}
          </Formik>
        )}
      </Content>
    </Template>
  );
};

export default AuxiliarySchedule;
