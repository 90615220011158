import React from 'react';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import {
  PersonBadgeFill,
  DoorClosed,
  Cpu,
  HddFill,
  Hdd,
  HddNetwork
} from 'react-bootstrap-icons';

const styles = css`
  padding: 16px;
  border-top: 1px solid var(--neutral-12);
  cursor: pointer;
  transition: background ease 300ms;

  label {
    pointer-events: none;
    display: block;
    font-size: 14px;
    color: var(--primary-base);
    font-weight: 600;
    margin-bottom: 8px;
  }

  svg {
    display: inline-block;
    margin-right: 6px;
    vertical-align: middle;
  }

  b {
    vertical-align: middle;
    display: inline-block;
    margin-right: 12px;
    font-size: 14px;
    font-weight: 700;
  }
`;

const CompanyItem = ({ company, ...otherProps }) => {
  if (!company) return null;
  return (
    <article css={styles} {...otherProps}>
      <label>{company.display_name}</label>
      <HddNetwork
        color="#59606C"
        css={css`
          margin-left: 2px;
        `}
      />
      <b title="Controller count">{company.device_count}</b>
      <DoorClosed color="#59606C" />
      <b title="Door count">{company.door_count}</b>

      <PersonBadgeFill color="#59606C" />
      <b title="Credential count">{company.credential_count}</b>
    </article>
  );
};
export default CompanyItem;
