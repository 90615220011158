import SyncJob from 'api/sync-job';
import React from 'react';
import { useInfiniteQuery } from 'react-query';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */

import EmptyHolder from 'components/empty-holder';
import Spinner from 'components/spinner';
import { Badge } from 'components/badge';

import Heading from 'components/heading';
import LoadMoreButton from 'components/load-more-button';

function groupBy(xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

let STATUS = {
  done: 'green',
  new: 'orange',
  skipped: 'gray'
};

export default ({ filters = {}, ...props }) => {
  const fetchEvents = ({ pageParam = 1 }) =>
    SyncJob.search({ ...filters, page: pageParam });

  const query = useInfiniteQuery([`sync-job`, filters], fetchEvents, {
    getNextPageParam: (lastPage, pages) => {
      return pages.length < lastPage.last_page ? pages.length + 1 : undefined;
    },

    enabled: Boolean(filters)
  });

  const sectionStyles = css`
    width: 100%;
    display: inline-flex;
    position: relative;
    flex-direction: column;
    overflow: hidden;

    > div {
      overflow-y: auto;
    }

    .spinner {
      display: block;
      margin: 24px auto;
    }
  `;

  return (
    <section className="event-list" css={sectionStyles} {...props}>
      <div>
        {query.status == 'loading' && <Spinner />}
        {query.data && query.data.pages?.[0]?.data.length == 0 && (
          <EmptyHolder label="No recent events" />
        )}
        {query.data &&
          query.data.pages.map((group, i) => (
            <React.Fragment key={i}>
              {[...Object.values(groupBy(group.data, 'date'))].map((group) =>
                group.map((event, index) => {
                  return (
                    <React.Fragment key={event.id}>
                      {index == 0 && <Heading label={event.date} />}

                      <dl
                        css={css`
                          box-shadow: 0px -1px 0px var(--neutral-12);
                          padding: 4px;
                          display: grid;
                          grid-template-columns: 1fr 1fr;
                          align-items: center;
                          gap: 4px;
                          dt {
                            font-weight: 500;
                            font-size: 12px;
                            color: var(--secondary-font-color);
                          }
                          dd {
                            font-size: 13px;
                          }
                        `}
                      >
                        <dt>Type:</dt>
                        <dd>{event?.type}</dd>
                        <dt>Status:</dt>
                        <dd>
                          <Badge color={STATUS[event.status]}>
                            {event.status}
                          </Badge>
                        </dd>
                        <dt>Token:</dt>
                        <dd>{event?.token}</dd>
                        <dt>Created:</dt>
                        <dd>{new Date(event.createdAt).toLocaleString()}</dd>
                        <dt>Ran At:</dt>
                        <dd>
                          {event.ranAt &&
                            new Date(event.ranAt).toLocaleString()}
                        </dd>
                        <dt>Batch ID:</dt>
                        <dd>
                          <strong>{event.batchId}</strong>
                        </dd>
                        <dt>Batch Count:</dt>
                        <dd>{event.batchCount}</dd>
                        {event.duration && (
                          <>
                            <dt>Duration:</dt>
                            <dd>{event.duration} millisec.</dd>
                          </>
                        )}
                      </dl>
                    </React.Fragment>
                  );
                })
              )}
            </React.Fragment>
          ))}
        {query.hasNextPage && query.data.pages?.[0]?.data.length > 0 && (
          <LoadMoreButton
            hasNextPage={query.hasNextPage}
            fetchNextPage={query.fetchNextPage}
            isFetchingNextPage={query.isFetchingNextPage}
          />
        )}
      </div>
    </section>
  );
};
