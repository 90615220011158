import React from 'react';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import { PersonBadgeFill, DoorClosed, Cpu } from 'react-bootstrap-icons';
import SiteHealthFormatter from 'components/site-health-formatter';

const styles = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  min-height: 72px;
  padding: 16px;

  box-sizing: border-box;
  color: var(--primary-base);
  cursor: pointer;
  transition: all ease 400ms;
  font-size: 14px;
  font-weight: 600;
  box-shadow: 0px -1px 0px var(--neutral-12);

  [role='badge'] {
    margin-left: auto;
  }
  .time-zone {
    color: var(--neutral-02);
  }
`;

const SiteItem = ({ site, ...otherProps }) => {
  if (!site) return null;
  return (
    <article css={styles} {...otherProps}>
      {site.name}
      <SiteHealthFormatter site={site} />
      <i className="time-zone">{site.time_zone}</i>
    </article>
  );
};

export default SiteItem;
