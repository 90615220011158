import React from 'react';
import MenuItem from 'components/menu-item';
import { useAuth } from 'providers/auth';
import {
  Sliders,
  ShieldShaded,
  AppIndicator,
  CardList,
  PersonCircle,
  GraphUp,
  CameraVideo,
  Truck,
  Hdd,
  ChevronDown,
  Map,
  Person,
  Calendar2Event,
  Stopwatch,
  PersonBadge,
  Tag,
  People,
  Grid1x2,
  Cpu,
  HddNetwork,
  Speaker,
  DoorOpen,
  ShieldCheck,
  Gear,
  Building,
  Tv,
  Grid1x2Fill,
  ThermometerSun,
  Pin,
  GeoAlt
} from 'react-bootstrap-icons';
import { ReactComponent as GuestIcon } from 'assets/guest-icon.svg';
import { ReactComponent as ElevatorIcon } from 'assets/elevator-icon.svg';
import { ReactComponent as SensorIcon } from 'assets/sensor-icon.svg';
import { ReactComponent as DepartmentIcon } from 'assets/department-icon.svg';

import IsUserAllowed from 'components/is-user-allowed';
import * as Accordion from '@radix-ui/react-accordion';
/** @jsxImportSource @emotion/react */
import { Link, useRouteMatch } from 'react-router-dom';
import { css, keyframes } from '@emotion/react';
import { useLayout } from 'providers/layout-provider';
import IsRole from 'components/is-role';
import {
  IconCloudComputing,
  IconDashboard,
  IconDatabaseImport,
  IconDeviceSpeaker,
  IconIdBadge,
  IconIdBadge2,
  IconLayoutDashboard,
  IconMapPin,
  IconMapPinFilled,
  IconShieldCheckeredFilled,
  IconSpeakerphone,
  IconUser,
  IconUsersGroup
} from '@tabler/icons-react';
import { IconMovie } from '@tabler/icons-react';

const slideUp = keyframes`
  from {
    height: var(--radix-accordion-content-height);
  }

  to { height: 0}
`;

const slideDown = keyframes`
  from {
    height: 0;
  }

  to { height: var(--radix-accordion-content-height)}
`;

const accordionTriggerStyles = css`
  /* Auto layout */
  color: var(--menu-item-color);
  width: 100%;
  font-weight: 700;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  gap: 24px;
  isolation: isolate;
  font-size: var(--menu-item-font-size);

  height: var(--menu-item-height);

  padding-left: 24px;
  svg:first-child {
    width: var(--menu-item-svg-size);
    height: var(--menu-item-svg-size);
  }
  &[data-state='open'] {
    svg:last-child {
      transform: rotate(180deg);
    }
  }
  svg:last-child {
    margin-left: auto;
    margin-right: 16px;
    transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
    font-weigth: 700;
  }

  &:hover {
    background-color: var(--neutral-05) !important;
  }

  /* &::before {
    content: "";
    background: var(--neutral-08);
    border-radius: 8px;
    width: calc(100% - 24px);
    position: absolute;
    left: 12px;
    z-index: -1;
    height: 32px;
  } */
`;

const accordionContentStyles = css`
  overflow: hidden;
  a {
    padding-left: 40px;
  }
  &[data-state='open'] {
    animation: ${slideDown} 550ms cubic-bezier(0, 1, 0.5, 1) forwards;
  }

  &[data-state='closed'] {
    animation: ${slideUp} 550ms cubic-bezier(0, 1, 0.5, 1) forwards;
  }
`;

function UserMenu(props) {
  const { user } = useAuth();
  const { collapsibleMenuState, setCollapsibleMenuState } = useLayout();
  const match = useRouteMatch();

  React.useEffect(() => {
    // add more url based things here
    if (match.path == '/doors') setCollapsibleMenuState('system');
    if (match.path == '/reports') setCollapsibleMenuState('settings');
  }, []);

  return (
    <>
      <ul>
        <IsUserAllowed restrict="C" condition="dashboard">
          <MenuItem to="/dashboard" title="Dashboard">
            <Grid1x2 />
          </MenuItem>
        </IsUserAllowed>
        {user.features?.monitoring && (
          <IsUserAllowed restrict="C" condition="monitoring">
            <MenuItem to="/monitoring-views" title="Monitoring">
              <Tv />
            </MenuItem>
          </IsUserAllowed>
        )}
        {user.managing && (
          <MenuItem to="/sites" title="Sites">
            <GeoAlt />
          </MenuItem>
        )}
        {(user.managing || user.devices_view) && (
          <MenuItem to="/devices" title="Devices">
            <HddNetwork />
          </MenuItem>
        )}
        {user.managing && user.features?.sensors == true && (
          <MenuItem to="/sensors" title="Sensors">
            <SensorIcon />
          </MenuItem>
        )}
        {user.managing && (
          <MenuItem to="/doors" title="Doors">
            <DoorOpen />
          </MenuItem>
        )}
        {/* {user.features?.overview_enabled == 'on' && (
          <MenuItem to="/overview" title="Overview">
            <Map />
          </MenuItem>
        )} */}
        <Accordion.Root
          type="single"
          value={collapsibleMenuState}
          onValueChange={(e) => setCollapsibleMenuState(e)}
          collapsible
        >
          <Accordion.Item value="system">
            <Accordion.Header asChild={true}>
              <div>
                <Accordion.Trigger css={accordionTriggerStyles}>
                  <Hdd />
                  <span>Hardware</span>
                  <ChevronDown />
                </Accordion.Trigger>
              </div>
            </Accordion.Header>
            <Accordion.Content css={accordionContentStyles}>
              {!user.managing && (
                <MenuItem to="/doors" title="Doors">
                  <DoorOpen />
                </MenuItem>
              )}
              {(user.features?.intercoms == 'on' ||
                user.features?.intercoms == true) &&
                user?.intercom_count != 0 && (
                  <MenuItem to="/intercoms" title="Intercoms">
                    <Speaker />
                  </MenuItem>
                )}
              <MenuItem to="/speakers" title="Speakers">
                <IconDeviceSpeaker />
              </MenuItem>
              {(user.features?.cameras_enabled == 'on' ||
                user.features?.cameras_enabled == true) && (
                <IsUserAllowed condition="cameraView" restrict="C">
                  <MenuItem to="/cameras" title="Cameras">
                    <CameraVideo />
                  </MenuItem>
                </IsUserAllowed>
              )}
              {/** This will always show elevator for the company if not company user */}
              {(user.features?.elevators == 'on' ||
                user.features?.elevators == true) &&
                (user?.elevator_count != 0 || user.role != 'C') && (
                  <MenuItem to="/elevators" title="Elevators">
                    <ElevatorIcon />
                  </MenuItem>
                )}
              <MenuItem to="/auxiliaries" title="Auxiliaries">
                <Sliders />
              </MenuItem>
            </Accordion.Content>
          </Accordion.Item>

          <Accordion.Item value="organization">
            <Accordion.Header asChild={true}>
              <div>
                <Accordion.Trigger css={accordionTriggerStyles}>
                  <Building />
                  <span>Organization</span>
                  <ChevronDown />
                </Accordion.Trigger>
              </div>
            </Accordion.Header>
            <Accordion.Content css={accordionContentStyles}>
              <IsUserAllowed restrict="C" condition="personView">
                <MenuItem to="/people" title="People">
                  <IconUser />
                </MenuItem>
              </IsUserAllowed>
              <IsUserAllowed restrict="C" condition="personView">
                <MenuItem to="/credentials" title="Credentials">
                  <IconIdBadge />
                </MenuItem>
              </IsUserAllowed>
              <IsUserAllowed restrict="C" condition={['personView', 'alView']}>
                <MenuItem to="/departments" title="Departments">
                  <IconUsersGroup />
                </MenuItem>
              </IsUserAllowed>

              {(user.features?.badging == 'on' ||
                user.features?.badging == true) && (
                <MenuItem to="/badges" title="Badges">
                  <IconIdBadge2 />
                </MenuItem>
              )}
            </Accordion.Content>
          </Accordion.Item>

          <Accordion.Item value="access">
            <Accordion.Header asChild={true}>
              <div>
                <Accordion.Trigger css={accordionTriggerStyles}>
                  <ShieldCheck />
                  <span>Access Control</span>
                  <ChevronDown />
                </Accordion.Trigger>
              </div>
            </Accordion.Header>
            <Accordion.Content css={accordionContentStyles}>
              <IsUserAllowed restrict="C" condition="alView">
                <MenuItem to="/access-levels" title="Access Levels">
                  <IconShieldCheckeredFilled />
                </MenuItem>
              </IsUserAllowed>
              <IsUserAllowed restrict="C" condition="scheduleView">
                <MenuItem to="/schedules" title="Schedules">
                  <Stopwatch />
                </MenuItem>
              </IsUserAllowed>
              <MenuItem to="/events" title="Events">
                <Calendar2Event />
              </MenuItem>
              <IsUserAllowed restrict="C" condition={['guestView', 'alView']}>
                <MenuItem to="/guests" title="Guests">
                  <GuestIcon />
                </MenuItem>
              </IsUserAllowed>
              {(user.features?.license_plates == 'on' ||
                user.features?.license_plates == true) && (
                <IsUserAllowed restrict="C" condition="licensePlateView">
                  <MenuItem to="/license-plates" title="License Plates">
                    <Truck />
                  </MenuItem>
                </IsUserAllowed>
              )}
            </Accordion.Content>
          </Accordion.Item>

          <Accordion.Item value="settings">
            <Accordion.Header asChild={true}>
              <div>
                <Accordion.Trigger css={accordionTriggerStyles}>
                  <Gear />
                  <span>Settings</span>
                  <ChevronDown />
                </Accordion.Trigger>
              </div>
            </Accordion.Header>
            <Accordion.Content css={accordionContentStyles}>
              <IsUserAllowed condition="writeUsers" restrict="C">
                <MenuItem to="/accounts" title="Login Accounts">
                  <PersonCircle />
                </MenuItem>
              </IsUserAllowed>
              {(user.features?.intercoms == 'on' ||
                user.features?.intercoms == true ||
                user.features?.cameras_enabled == 'on' ||
                user.features?.cameras_enabled == true) && (
                <MenuItem to="/recording-exports" title="Recording Exports">
                  <IconMovie />
                </MenuItem>
              )}
              {(user.features?.reports_enabled == 'on' ||
                user.features?.reports_enabled == true) && (
                <MenuItem to="/reports" title="Reports">
                  <GraphUp />
                </MenuItem>
              )}
              {(user.features?.notifications_enabled == 'on' ||
                user.features?.notifications_enabled == true) && (
                <IsUserAllowed condition="fa" restrict="C">
                  <MenuItem to="/notifications" title="Notifications">
                    <AppIndicator />
                  </MenuItem>
                </IsUserAllowed>
              )}
              <MenuItem to="/audio-clips" title="Audio Clips">
                <IconSpeakerphone />
              </MenuItem>
              {((user.role == 'C' &&
                (user.features?.logs_enabled == 'on' ||
                  user.features?.logs_enabled == true)) ||
                user.role != 'C') && (
                <MenuItem to="/audit-logs" title="Audit Logs">
                  <CardList />
                </MenuItem>
              )}
              <MenuItem to="/groups" title="Groups">
                <Tag />
              </MenuItem>
            </Accordion.Content>
          </Accordion.Item>
        </Accordion.Root>
      </ul>
      <footer>
        <IsRole only={'A'}>
          <MenuItem to="/sync-jobs" title="Sync Jobs">
            <IconDatabaseImport />
          </MenuItem>
        </IsRole>
        {(user.features?.cameras_enabled == 'on' ||
          user.features?.cameras_enabled == true) && (
          <IsRole role={'AVI'}>
            <MenuItem to="/cloud-recording" title="Cloud Recording">
              <IconCloudComputing />
            </MenuItem>
          </IsRole>
        )}
      </footer>
    </>
  );
}

export default UserMenu;
