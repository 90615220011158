import { dataFetchQuery, useMutation } from 'utils/ajax';
import { callAPI } from 'utils/ajax';

import { API_HOST_URL } from './api-url';
const SyncJob = {
  search(input = {}) {
    return callAPI('GET', `/sync_job`, input);
  },
  stats(input = {}) {
    return callAPI('GET', `/sync_job/stats`, input);
  },
  searchReport(input = {}) {
    return callAPI('GET', `/sync_job/searchReport`, input);
  }
};

export default SyncJob;
