import GroupLabel from "components/group-label";
import ActionMenu from "views/groups/action-menu";
import { getSelectionColumn } from "components/table-selection-column";
/** @jsxImportSource @emotion/react */

export const columns = [
  getSelectionColumn(),
  {
    Header: "Label",
    accessor: "name",
    pickerDisabled: true,

    Cell: ({ row, cell: { value } }) => (
      <GroupLabel color={row.original.color} label={row.original.name} />
    ),
  },
];
