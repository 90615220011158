import React from "react";

import { useHistory } from "react-router-dom";
import { IconButton } from "components/buttons";
import { PersonPlus } from "react-bootstrap-icons";
import { useModal } from "providers/modal-provider";

/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useLayout } from "providers/layout-provider";
const style = css`
  align-items: center;
  gap: 0.4rem;
  display: flex;
`;
const AddDeniedCredential = ({ event, ...props }) => {
  const history = useHistory();
  let { liveEventsDialog } = useLayout();

  const handleAddNewCredential = React.useCallback(() => {
    liveEventsDialog.visible && liveEventsDialog.hide();
    history.push("/credentials/add", { credentialData: event.data });
  }, [event]);

  if (event.topic2 == "CredentialNotFound")
    return (
      <article
        title="Add Credential"
        className="credential-add"
        css={style}
        {...props}
      >
        <IconButton onClick={handleAddNewCredential}>
          <PersonPlus size={24} />
        </IconButton>
      </article>
    );
  else return null;
};

export default AddDeniedCredential;
