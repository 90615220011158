import 'css/sidebar.css';
import React from 'react';
import { css, Global } from '@emotion/react';
import Select from 'components/headless-select';

/** @jsxImportSource @emotion/react */

import { useHeadlessTable } from 'providers/headless-table-provider';

import AsyncSelect from 'components/headless-select/async';

import TableFilter from 'components/headless-table-filter';
import User from 'api/user';
import { useAuth } from 'providers/auth';
import Company from 'api/company';
import TableReloadButton from 'components/table/table-reload-button';
import ColumnPicker from 'components/column-picker';

const articleStyles = css`
  display: flex;
  align-items: center;
  height: fit-content;
  justify-content: end;

  > * {
    flex: 1;
  }
  column-gap: 3rem;
  row-gap: 0.8rem;

  padding: 24px;

  background: var(--neutral-09);
`;
const Filters = (props) => {
  return (
    <article css={articleStyles}>
      <section
        css={css`
          display: flex;
          margin-left: auto;
          gap: 0.5rem;
          margin-left: auto;
          justify-content: flex-end;
        `}
      >
        <TableReloadButton compact />
        <ColumnPicker />
      </section>
    </article>
  );
};

export default Filters;
