import React from "react";
import { TextField } from "components/form";
import { useFormikContext } from "formik";
import { useMutationWrapper } from "utils/ajax";
import Department from "api/department";
import { useAuth } from "providers/auth";
import { Button } from "components/buttons";
import { XOctagon, CheckCircle } from "react-bootstrap-icons";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Heading } from "components/form";

const styles = css`
  position: static;
  padding: 24px;
  background: var(--purple-v-01);
  border-radius: 4px;
`;

const AdUUID = () => {
  const { values, handleChange, handleBlur, setFieldValue } =
    useFormikContext();

  const { user } = useAuth();

  let { isLoading, isSuccess, mutate, data } = useMutationWrapper(
    ["department", "checkGroupInAd"],
    (uuid) => Department.checkGroupInAd(uuid),
    { noToast: true }
  );

  if (!user.is_ad_sync) return null;

  return (
    <article className="ad-uuid" css={styles}>
      <Heading label="Active Directory Setup"></Heading>
      <TextField
        label="AD UUID"
        value={values.ad_uuid}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <span>
        <Button
          compact
          onClick={() =>
            mutate(values.ad_uuid, {
              onSuccess: (res) => {
                setFieldValue("name", res.data.displayName);
              },
            })
          }
          loading={isLoading}
          type="button"
        >
          Scan UUID
          {isSuccess && (data?.data ? <CheckCircle /> : <XOctagon />)}
        </Button>
      </span>
    </article>
  );
};

export default AdUUID;
