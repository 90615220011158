import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import { AvatarCircle } from 'components/avatar';
import { ItemTypes } from './item-types.js';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import React from 'react';
// MUST be pure CSS beacuse of printing (e.g no nesting)
const badgeStyles = `
  
  p {
    margin: 0px;
  }
  > * {
    z-index: 6;
  }
  & {
    margin: auto;
    position: relative;
    box-sizing: border-box;
    border-radius: 5px;
    width: 100%;
    padding: 12px;
    overflow: hidden;
    box-shadow: 0px 2px 10px -2px #000b;
    text-shadow: 1px 1px 1px #0006;
  }
  div#avatar, #avatar {
    width: 100px;
    height: 100px;
    border: 4px solid rgba(200,200,200,0.4);
	  border-radius: 50%;	/* relative value */
	  box-shadow: 0px 0px 5px rgba(0,0,0,0.4);
	  transition: linear 0.25s;
    
  }

  
  

  // & > div > .avatar {
  //   margin-top: 8px;
  //   width: auto !important;
  //   height: auto !important;
  // }

  // & > div > .avatar > div, & > .avatar > object {
  //   box-shadow: 0px 0px 4px #000b;
  //   font-size: 10mm;
  //   width: 26mm !important;
  //   height: auto !important;
  //   aspect-ratio: 1;
  // }

  & > #bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }

  & > #bg ~ * {
    z-index: 9;
    position: relative;
  }

  & > h4 {
    margin: 0;
    font-size: 18px;
  }

  & .departments {
    font-size: 13px;
  }

  & > p {
    font-size: 10px;
    margin: 0 0 4px 0;
  }

  & #qr {
    position: absolute;
    padding: 4px;
    background: white;
    margin: auto;
    width: fit-content;
  }

  & #qr > img {
    width: 50px;
    z-index: 2;
    position: relative;
  }

  & #qr::before {
    content: "";
    background: var(--neutral-09);
    position: absolute;
    z-index: 1;
  }

  &[data-layout=v1], &[data-layout=v2] {
    width: 53.98mm;
    height: 85.8mm;
    
    transform-origin: top center;
    padding: 12px 24px 12px 24px;
  }

 


  &[data-layout=v1] .custom,
  &[data-layout=v2] .custom {
    padding-left: 64px;
  }

  &[data-layout=v1] #qr,
  &[data-layout=v2] #qr {
    left: 0;
    bottom: 0;
    border-top-right-radius: 6px;
  }

  &[data-layout=v1] #qr::before,
  &[data-layout=v2] #qr::before {
    width: 53.98mm;
    height: 8px;
    bottom: 0;
    left: 0;
  }

  &[data-layout=v2] h4,
  &[data-layout=v2] .departments,
  &[data-layout=v2] .email,
  &[data-layout=v2] .phone,
  &[data-layout=v2] .custom {
    text-align: center;
    margin-right: 0;
  }

 
  &[data-layout=v2] .custom {
    padding: 0;
  }

  &[data-layout=v2] #qr {
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    border-radius: 6px 6px 0 0;
  }

  &[data-layout=v2] #qr > img {
    width: 42px;
  }

  &[data-layout=h1],
  &[data-layout=h2] {
    height: 53.98mm;
    width: 85.8mm;
  }

  &[data-layout=h1] .avatar,
  &[data-layout=h2] .avatar {
    margin-top: 0;
  }




  &[data-layout=h1] #qr {
    bottom: 0;
    right: 0;
    margin: auto;
    border-top-left-radius: 6px;
  }

  &[data-layout=h1] #qr > img {
    width: 48px;
  }

  &[data-layout=h1] #qr::before {
    height: 6px;
    width: 85.8mm;
    bottom: 0;
    right: 0;
  }

  &[data-layout=h2] h4 {
    margin-top: 8px;
    font-size: 18px;
  }

  [data-layout=h2] .departments {
    font-size: 12px;
  }

  &[data-layout=h2] .avatar,
  &[data-layout=h2] h4,
  &[data-layout=h2] .departments {
    text-align: center;
  }

  &[data-layout=h2] .avatar > * {
    width: 72px !important;
  }
  &[data-layout=h2] .phone {
   & width: calc(30% - 4px);
  }

  &[data-layout=h2] .email {
    width: calc(70% - 4px);
  }

  &[data-layout=h2] .phone,
  &[data-layout=h2] .email {
    margin-top: 6px;
    display: inline-block;
    background: #6665;
    border-radius: 12px;
    margin: 8px 2px 0 2px;
  }

  &[data-layout=h2] .email,
  &[data-layout=h2] .phone,
  &[data-layout=h2] .custom {
    text-align: center;
  }

  &[data-layout=h2] .custom {
    margin-top: 6px;
  }

  &[data-layout=h2] #qr {
    top: 0;
    right: 0;
    border-radius: 0 0 0 6px;
  }

  &[data-layout=h2] #qr > img {
    width: 42px;
  }

  &[data-layout=h2] #qr::before {
    width: 6px;
    height: 53.98mm;
    top: 0;
    right: 0;
  }

`;

const styles = css(badgeStyles);

import update from 'immutability-helper';
import { useCallback, useState } from 'react';
import { useDrop } from 'react-dnd';
import { DraggableBox } from './draggable-box';
import { useFormikContext } from 'formik';
import { DragLayer } from './drag-layer.js';
export function PersonBadge({ data, person, boxes, setBoxes }) {
  const { values, setFieldValue, setValues } = useFormikContext();

  const deps = !Array.isArray(person.department_names)
    ? JSON.parse(person?.department_names)?.join(', ')
    : person?.department_names?.join(', ');

  const moveBox = useCallback(
    (id, left, top) => {
      setFieldValue(id, { ...values[id], left, top });
    },
    [values]
  );

  const [, drop] = useDrop(
    () => ({
      accept: ItemTypes.BOX,
      drop(item, monitor) {
        const delta = monitor.getDifferenceFromInitialOffset();
        const left = Math.round(item.left + delta.x);
        const top = Math.round(item.top + delta.y);

        moveBox(item.id, left, top);
        return undefined;
      }
    }),
    [moveBox]
  );

  const [snapToGridAfterDrop, setSnapToGridAfterDrop] = React.useState(false);
  const [snapToGridWhileDragging, setSnapToGridWhileDragging] =
    React.useState(false);
  const handleSnapToGridAfterDropChange = React.useCallback(() => {
    setSnapToGridAfterDrop(!snapToGridAfterDrop);
  }, [snapToGridAfterDrop]);
  const handleSnapToGridWhileDraggingChange = React.useCallback(() => {
    setSnapToGridWhileDragging(!snapToGridWhileDragging);
  }, [snapToGridWhileDragging]);

  return (
    <DndProvider backend={HTML5Backend}>
      <article
        ref={drop}
        id="badge"
        css={styles}
        style={{ background: data.background ? data.background_color : 'none' }}
        data-layout={data.layout}
      >
        <DragLayer snapToGrid={snapToGridWhileDragging} />
        {data.background_image &&
          (data.background_image_data || data.background_image_url) && (
            <img
              id="bg"
              src={data.background_image_data ?? data.background_image_url}
            />
          )}
        {values.avatar?.active && (
          <DraggableBox
            id="avatar"
            left={values.avatar.left}
            top={values.avatar.top}
          >
            <img id="avatar" src={person.avatar_url} />
          </DraggableBox>
        )}
        <DraggableBox id="name" left={values.name.left} top={values.name.top}>
          <p
            className="name"
            style={{
              color: values.name.color ?? 'black',
              fontSize: values.name.fontSize ?? 12
            }}
          >
            {person.name}
          </p>
        </DraggableBox>

        {deps && values.department.active && (
          <DraggableBox
            id="department"
            left={values.department.left}
            top={values.department.top}
          >
            <p
              className="departments"
              style={{
                color: values.department.color ?? 'black',
                fontSize: values.department.fontSize ?? 12
              }}
            >
              {deps}
            </p>
          </DraggableBox>
        )}

        {person.email && values.email.active && (
          <DraggableBox
            id="email"
            left={values.email.left}
            top={values.email.top}
          >
            <p
              className="email"
              style={{
                color: values.email.color ?? 'black',
                fontSize: values.email.fontSize ?? 12
              }}
            >
              {person.email}
            </p>
          </DraggableBox>
        )}

        {person.phone && values.phone.active && (
          <DraggableBox
            id="phone"
            left={values.phone.left}
            top={values.phone.top}
          >
            <p
              className="phone"
              style={{
                color: values.phone.color ?? 'black',
                fontSize: values.phone.fontSize ?? 12
              }}
            >
              {person.phone}
            </p>
          </DraggableBox>
        )}

        {values.custom?.text && (
          <DraggableBox
            id="custom"
            left={values.custom.left}
            top={values.custom.top}
          >
            <p
              className="custom"
              style={{
                color: values.custom.color ?? 'black',
                fontSize: values.custom.fontSize ?? 12
              }}
            >
              {values.custom.text}
            </p>
          </DraggableBox>
        )}

        {data.qrCode && (
          <div id="qr">
            <img
              src={`https://api.qrserver.com/v1/create-qr-code/?format=svg&data=${data.qrCode}`}
            />
          </div>
        )}
      </article>
    </DndProvider>
  );
}

export async function printBadge(badge) {
  const content =
    `
    <style>
      @media screen, print {

        @page {
          margin: 0;
          size: ${
            badge.getAttribute('data-layout').includes('h')
              ? '85.6mm 53.98mm'
              : '53.98mm 85.6mm'
          };
        }

        body, html { margin: 0 !important; padding: 0 !important; }

        .avatar {
          border-radius: 50%;
        }

        .avatar > :is(img, object) {
          display: inline-flex;
          font-style: normal;
          font-weight: bold;
          line-height: 100%;
          border-radius: 50%;
          font-size: 50px;
          align-items: center;
          justify-content: center;
          text-align: center;
          color: var(--neutral-00);
          background: var(--badge-background-purple);
        }

        ${badgeStyles.replaceAll('&', '#badge')}

        #badge {
          font-family: Inter;
          border-radius: 0 !important;
       
        }
      }
    </style>
  ` + badge.outerHTML;

  const iframe = document.createElement('iframe');
  iframe.style.display = 'none';
  document.body.appendChild(iframe);

  const pri = iframe.contentWindow;
  pri.document.write(content);
  pri.document.title = 'Badge - ' + Date.now();

  await Promise.all([
    // TODO add other fonts?
    new FontFace(
      'Inter',
      'url(https://fonts.gstatic.com/s/inter/v8/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2)',
      {
        /*weight: 300, style: 'normal'*/
      }
    ).load()
  ]).then(function (loadedFonts) {
    loadedFonts.forEach((font) => pri.document.fonts.add(font));
  });

  await new Promise((done) => {
    // TODO actually wait for load of Avatar and QRCode
    setTimeout(async function () {
      pri.document.close();
      pri.focus();
      pri.print();
      pri.onafterprint = () => {
        document.body.removeChild(iframe);
      };
      done();
    }, 5e3);
  });
}
