import React from "react";
import "css/loader.css";
function Loader({ size = 10 }) {
  let height = "h-" + size;
  let width = "w-" + size;

  return (
    <div className='w-full items-center flex justify-center'>
    <svg className={`spinner flex ${height} ${width} `} viewBox="0 0 50 50">
      <circle
        className="path"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        strokeWidth="5"
      ></circle>
    </svg>
    </div>
  );
}

export default Loader;
