import React from 'react';
/** @jsxImportSource @emotion/react */
import { ReactComponent as NoRecordings } from 'assets/images/no-recordings.svg';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Layer } from 'components/video';
import { OverlayHolder } from 'components/video/overlay';
import { CameraVideo, CameraVideoOff } from 'react-bootstrap-icons';
export default () => {
  return (
    <Layer>
      <OverlayHolder
        css={css`
          svg > * {
            stroke: var(--red-base);
          }
          text-align: center;
        `}
      >
        <CameraVideoOff size={56} />
        <label>Device Offline</label>
      </OverlayHolder>
    </Layer>
  );
};
