import React from 'react';
import Template from 'base/template';
import ActionMenu from 'views/auxiliaries/action-menu';

import Auxiliary from 'api/auxiliary';
import { columns } from 'views/auxiliaries/table-columns';
import Sidebar from 'views/auxiliaries/sidebar';
import IndexContent from 'base/index-content';
import { useAuth } from 'providers/auth';
import { ReactComponent as AuxiliaryEmptyIcon } from 'assets/empty-icons/empty-auxiliary.svg';

const cards = [
  {
    name: 'total',
    label: 'Total'
  }
];
const Aux = (props) => {
  const { enforce, user } = useAuth();

  return (
    <Template {...props}>
      <IndexContent
        initialFilter={{ site: user.focused_site }}
        statsQuery={Auxiliary.getStats}
        title="Auxiliaries"
        id="auxiliaries"
        cards={cards}
        query={Auxiliary.search}
        columns={columns}
        addButton={enforce({ reqInstaller: true }) ? 'Add an Auxiliary' : false}
        sidebar={Sidebar}
        selectable
        actionButton={ActionMenu}
        emptyIcon={AuxiliaryEmptyIcon}
        {...props}
      ></IndexContent>
    </Template>
  );
};

export default Aux;
