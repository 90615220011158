import { Badge } from 'components/badge';
import { ClockHistory } from 'react-bootstrap-icons';
import { css } from '@emotion/react';
import AlarmStateFormatter from 'components/alarm-state-formatter';
import { getSelectionColumn } from 'components/table-selection-column';
import AuxiliaryStateAvatar from './auxiliary-state-avatar';
import { dateTime } from 'utils/time';
/** @jsxImportSource @emotion/react */

export const columns = [
  getSelectionColumn(),
  {
    style: { width: '50px' },
    Header: 'State',
    accessor: 'state',
    Cell: ({ row: { original } }) => {
      return <AuxiliaryStateAvatar aux={original} />;
    }
  },

  {
    Header: 'Open Times',
    accessor: 'open_schedule_name'
  },
  {
    Header: 'Name',
    accessor: 'name',
    pickerDisabled: true,
    Cell: ({ row: { original } }) => {
      return (
        <div
          css={css`
            display: grid;
            grid-template-columns: auto auto;
            grid-template-rows: 1fr;
            grid-column-gap: 0px;
            grid-row-gap: 0px;
            span:nth-child(2) {
              color: var(--neutral-02);
              grid-column: 1/1;
            }
            svg {
              grid-row: 1/3;
              grid-column: 2;
              place-self: center;
            }
          `}
        >
          <span>{original.name}</span>
          <span>({original.state == '0' ? 'Idle' : 'Active'})</span>
          {original.open_schedule_token && (
            <ClockHistory
              title={`${original.open_schedule_name}`}
              size={22}
              color="var(--red-base)"
            />
          )}
        </div>
      );
    }
  },
  {
    Header: 'Device',
    accessor: 'device_name',
    Cell: ({ row: { original } }) => {
      return (
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <span>{original.device_name}</span>
          <span>{original.port_name}</span>
        </div>
      );
    }
  },
  {
    Header: 'Status',
    id: 'online',
    Cell: ({ row: { original } }) => {
      const [label, color] = !original.online
        ? ['unknown', 'gray']
        : original.online == '0'
        ? ['offline', 'red']
        : ['online', 'green'];

      return (
        <span>
          <Badge color={color}>{label}</Badge>
        </span>
      );
    }
  },
  {
    Header: 'Direction',
    accessor: 'Direction',
    Cell: ({ cell: { value = 'input' } }) => {
      return value;
    }
  },
  {
    Header: 'Last State Change',
    accessor: 'last_state_change_at',
    Cell: ({ row: { original }, cell: { value } }) => (
      <span>{dateTime(new Date(value + 'Z'))}</span>
    )
  },

  {
    Header: 'Alarm State',
    accessor: 'alarm_state',
    Cell: ({ cell: { value: state } }) => <AlarmStateFormatter state={state} />
  }
  /**   {
    // Make an expander cell
    Header: () => null, // No header
    id: "expander", // It needs an ID
    style: {
      width: "5%",
    },
    pickerHide: true,
    Cell: ({ row }) => (
      // Use Cell to render an expander for each row.
      // We can use the getToggleRowExpandedProps prop-getter
      // to build the expander.
      <span {...row.getToggleRowExpandedProps()}>
        {row.isExpanded ? (
          <ChevronUp color="var(--primary-base)" />
        ) : (
          <ChevronDown color="var(--primary-base)" />
        )}
      </span>
    ),
  }, **/
];
