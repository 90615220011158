import React from "react";

import { FlexCol } from "components/dom";
import DoorDetails from "views/doors/door-details";
import CreatableSelect from "react-select/creatable";
import { Heading } from "components/form";
import GenericDoorDetails from "views/generic-doors/generic-door-details";
import { useFormikContext, useField } from "formik";

const DoorForm = ({ door }) => {
  const { values, setFieldValue } = useFormikContext();

  return (
    <section>
      {door?.door_token ? <DoorDetails door={door} security /> : <GenericDoorDetails door={door} />}
    </section>
  );
};

export default DoorForm;
