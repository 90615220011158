import React from 'react';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react **/
import Heading from 'components/heading';
import Clipboard from 'utils/clipboard';

const styles = css`
  color: var(--neutral-00);
  width: 100%;
  dl {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 0.7rem;
  }
  dt {
    font-weight: 500;
    font-size: 12px;
    color: var(--secondary-font-color);
  }
  dd {
    font-size: 13px;
    span {
      font-size: 11px;
      margin-left: 4px;
      text-transform: uppercase;
      color: var(--secondary-font-color);
    }
  }
`;

const Network = ({ device }) => {
  return (
    <article css={styles}>
      <Heading label="network" />
      <dl>
        <dt>MAC</dt>

        <dd>
          <Clipboard label={device.mac} />
        </dd>

        <dt>LAN</dt>
        <dd>{device.lan}</dd>

        <dt>Product</dt>
        <dd>
          {device.product} / {device.type}
        </dd>
        <dt>Firmware</dt>
        <dd>{device.firmware}</dd>
        <dt>Subscribed at</dt>
        <dd>
          {device.last_subscribed_at} <span>(utc)</span>
        </dd>
        <dt>Subscription termination </dt>
        <dd>
          {device.subscription_termination_time} / {device.subscription_id}
          <span>(utc)</span>
        </dd>
        {device.uuid && (
          <>
            <dt>UUID</dt>
            <dd>
              <Clipboard label={device.uuid} />
            </dd>
          </>
        )}
        <dt>Online Since</dt>
        <dd>
          {device.online_time} <span>(utc)</span>
        </dd>
      </dl>
    </article>
  );
};

export default Network;
