import React from 'react';
import Template from 'base/template';

import Camera from 'api/camera';
import { css } from '@emotion/react';
import PageTitle from 'components/page-title';
import Content from 'base/content';
import { useQueryWrapper } from 'utils/ajax';
import { useParams } from 'react-router-dom';
import PlayerWrapper from 'components/video/player-wrapper';
/** @jsxImportSource @emotion/react */

import { useNotFound } from 'hooks/not-found-hook';
import Breadcrumbs from 'components/breadcrumbs';
import SmallBadge from 'components/small-badge';
import { IconMapPin } from '@tabler/icons-react';

const View = (props) => {
  const { id } = useParams();
  const [camera, setCamera] = React.useState(props?.location?.state?.camera);

  const query = useQueryWrapper(
    ['camera', 'get', id],
    () => Camera.search({ id }),
    {
      enabled: !camera
    }
  );

  useNotFound(id, query, props);

  React.useEffect(() => {
    if (!query?.data?.data?.[0]) return;
    setCamera(query.data.data?.[0]);
  }, [query?.data?.data?.[0]]);

  return (
    <Template {...props}>
      <Content {...props}>
        <PageTitle
          css={css`
            flex-wrap: wrap;
            gap: 0.5rem;
            [role='small-badge'] {
              margin-left: auto;
            }
          `}
        >
          <div>{camera?.name}</div>
          {camera?.site_name && (
            <SmallBadge>
              <IconMapPin />
              {camera?.site_name}
            </SmallBadge>
          )}
        </PageTitle>
        <Breadcrumbs crumbs={props.crumbs} />
        {camera && <PlayerWrapper camera={camera} />}
      </Content>
    </Template>
  );
};

export default View;
