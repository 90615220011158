import React from "react";
import styled from "@emotion/styled";

import { css } from "@emotion/react";
/** @jsxImportSource @emotion/react */

const COLOR = {
  default: "var(--neutral-00)",
  danger: "var(--red-base)",
  blue: "var(--secundary-base)",
  red: "var(--red-base)",

  green: "var(--primary-v-01)",
  success: "var(--primary-v-01)",

  orange: "var(--orange-base)",
  alert: "var(--orange-base)",
  warning: "var(--orange-base)",
  ad: "var(--purple-base)",
  purple: "var(--purple-base)",
  gray: "var(--neutral-02)",
  unknown: "var(--neutral-02)",
};

const BACKGROUND = {
  default: "--badge-background-grey",
  danger: "var(--badge-background-red)",
  blue: "var(--badge-background-blue)",
  red: "var(--badge-background-red)",
  green: "var(--badge-background-green)",
  success: "var(--badge-background-green)",
  orange: "var(--badge-background-orange)",
  alert: "FFF5EA",
  warning: "var(--badge-background-orange)",
  ad: "var(--badge-background-purple)",
  purple: "var(--badge-background-purple)",
  gray: "var(--badge-background-grey)",
  unknown: "var(--badge-background-grey)",
};

const BadgeContainer = styled("span")`
  /* Auto Layout */
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  width: fit-content;
  letter-spacing: 0.85;
  align-items: center;
  padding: 4px 8px;
  position: static;
  max-width: fit-content !important;
  min-height: 21px;
  max-height: 21px;
  height: fit-content;
  border-radius: 5px;
  font-weight: bold;
  font-size: 11px;
  //border: 1px solid;
  color: ${(props) => COLOR[props.color || "default"]};
  //border-color: ${(props) => COLOR[props.color || "default"]};
  background-color: ${(props) => BACKGROUND[props.color || "default"]};

  > svg {
    margin-right: 8px;
  }
`;

const Badge = React.forwardRef((props, ref) => {
  return <BadgeContainer ref={ref} role="badge" {...props} />;
});

export { Badge };
