import React from 'react';

import { useAuth } from 'providers/auth';

const IsShowColumn = ({ column, children }) => {
  const { user } = useAuth();

  if (!user) return null;

  if (column.disableManaging && user.company_id) return null;

  if (column.role && !column.role?.includes(user.role)) return null;
  else return <> {children}</>;
};

export default IsShowColumn;
