import React from 'react';
import { useHistory } from 'react-router-dom';
import { MenuWrapper, MenuItem, MenuItemDelete } from 'components/action-menu';

export default function ActionMenu({ data: intercom = {}, ...otherProps }) {
  const history = useHistory();
  let handleEdit = (e) =>
    history.push('/intercoms/save/' + intercom?.id, {
      intercom,
      desc: intercom?.name
    });

  let handleSetting = (e) =>
    history.push('/intercoms/settings/' + intercom?.id, {
      intercom,
      desc: intercom?.name
    });

  // let handleDetails = (e) =>
  //   history.push('/intercoms/details/' + intercom?.id, {
  //     intercom,
  //     desc: intercom?.name
  //   });

  let handleView = (e) =>
    history.push('/intercoms/view/' + intercom?.id, {
      desc: intercom?.name
    });
  return (
    <MenuWrapper {...otherProps}>
      <MenuItem label="Edit" onClick={handleEdit} />

      <MenuItem
        disabled={intercom?.online == 0}
        label="Setting"
        onClick={handleSetting}
      />
      <MenuItem
        disabled={intercom?.online == 0}
        label="View"
        onClick={handleView}
      />
      {/* <MenuItem
        disabled={intercom?.online == 0}
        label="Details"
        onClick={handleDetails}
      /> */}
    </MenuWrapper>
  );
}
