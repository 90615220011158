import React from 'react';
import SiteForm from 'views/sites/form';
import Site from 'api/site';
import Template from 'base/template';

import { CancelButton, Button } from 'components/buttons';
import { useRedirect } from 'hooks/redirect-hook';
import { useMutationWrapper } from 'utils/ajax';
import FormProvider from 'providers/form-provider';
import { Redirect } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PageTitle from 'components/page-title';
import Content from 'base/content';
import { Check2Circle } from 'react-bootstrap-icons';
import Breadcrumbs from 'components/breadcrumbs';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useQueryWrapper } from 'utils/ajax';
import { useNotFound } from 'hooks/not-found-hook';

const Save = (props) => {
  const { id } = useParams();

  const [site, setSite] = React.useState(props?.location?.state?.site);

  const SCHEMA = Yup.object().shape({
    name: Yup.string()
      .min(4, 'Too Short!')
      .max(30, 'Too Long!')
      .required('Required'),
    code: Yup.number().required('required'),
    address: Yup.string().required('required'),
    description: Yup.string().required('required'),
    time_zone: Yup.string().required('required').nullable()
  });

  const INITIAL_VALUES = {
    name: site?.name,
    description: site?.description,
    address: site?.address,
    code: site?.code,
    time_zone: site?.time_zone || ''
  };
  let { isLoading, isSuccess, mutate } = useMutationWrapper(
    ['site', 'add', 'edit'],
    (input) => Site[site ? 'edit' : 'add'](input, site?.id),
    {}
  );

  const query = useQueryWrapper(['SITE', 'get', id], () => Site.get(id), {
    enabled: Boolean(!site && id)
  });
  useNotFound(id, query, props);

  React.useEffect(() => {
    if (!query?.data) return;
    setSite(query.data);
  }, [query?.data]);

  const { redirect, setRedirect } = useRedirect(isSuccess);
  return (
    <Template {...props}>
      {redirect && <Redirect to="/sites" />}
      <Content {...props}>
        <PageTitle>{site ? site?.name : !id ? 'Site Add' : ''}</PageTitle>

        <Breadcrumbs crumbs={props.crumbs} />

        {(site || !id) && (
          <Formik
            validationSchema={SCHEMA}
            initialValues={INITIAL_VALUES}
            onSubmit={(values) => {
              mutate(values);
            }}
          >
            {({ handleSubmit, handleReset }) => (
              <FormProvider
                navigate="/sites"
                mutateFunc={handleSubmit}
                disabled={isLoading}
              >
                <SiteForm site={site} />

                <footer>
                  <Button type="submit">
                    <Check2Circle size={24} />
                    Submit
                  </Button>
                  <CancelButton onClick={() => setRedirect(true)} />
                </footer>
              </FormProvider>
            )}
          </Formik>
        )}
      </Content>
    </Template>
  );
};

export default Save;
