import React from 'react';
import Template from 'base/template';
import Elevator from 'api/elevator';
import { columns } from 'views/elevators/table-columns';
import IndexContent from 'base/index-content';
import ActionMenu from 'views/elevators/action-menu';
import { useHistory } from 'react-router-dom';
import { AuxiliaryButton } from 'components/buttons';
import Sidebar from 'views/elevators/sidebar';
import { useAuth } from 'providers/auth';
import { Power } from 'react-bootstrap-icons';
const cards = [
  {
    name: 'total',
    label: 'Total'
  },
  {
    name: 'offline',
    label: 'Offline',
    color: 'var(--red-base)',
    background: 'var(--stats-background-red)',
    filter: { online: false },
    icon: Power
  }
];

const ExtraButtons = () => {
  const history = useHistory();

  return (
    <AuxiliaryButton compact onClick={() => history.push('elevators/access')}>
      Access Levels
    </AuxiliaryButton>
  );
};

const Elevators = (props) => {
  const { user } = useAuth();
  return (
    <Template {...props}>
      <IndexContent
        newSorter
        initialFilter={{ site: user.focused_site }}
        statsQuery={Elevator.stats}
        title="elevators"
        id="elevators"
        cards={cards}
        query={Elevator.search}
        columns={columns}
        buttons={ExtraButtons}
        isSidebarOpen={true}
        actionButton={ActionMenu}
        sidebar={Sidebar}
        //actionButton={ActionMenu}
        live
        {...props}
      ></IndexContent>
    </Template>
  );
};

export default Elevators;
