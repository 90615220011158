import React from 'react';

import { TextField, DateTimeField } from 'components/form';

import { useFormikContext } from 'formik';
import AuthProfile from 'api/auth_profile';
import { css } from '@emotion/react';
import { Field } from 'formik';
import AsyncSelect from 'components/headless-select/async';
import Site from 'api/site';
/** @jsxImportSource @emotion/react */

const styles = css``;

const AlForm = ({ al }) => {
  const { values, setFieldValue } = useFormikContext();

  return (
    <section css={styles}>
      <TextField name="name" value={values.name} />
      <TextField name="description" value={values.description} />

      <Field
        component={DateTimeField}
        name="valid_from"
        label="Valid From"
        utc
      />
      <Field component={DateTimeField} name="valid_to" label="Valid To" utc />
      <Field
        placeholder="Select Authentication Profile"
        simple
        component={AsyncSelect}
        isSearchable={false}
        fetchFn={AuthProfile.search}
        name="authentication_profile"
        title="Auth Profile"
        isMulti={false}
        label="Name"
        idx="token"
        isClearable={false}
        closeMenuOnSelect={true}
      />
      <Field
        selectAll
        name="sites"
        transformData={(data) => {
          // here will put the fixed

          return data.map((i) => {
            let isFixed =  i.isInAccessLevel == '1' && al.sites != 'false';
            return { ...i, isFixed};

          })
        }}
        fetchFilters={{
          withReaderStats: true,
          isInAccessLevel: al?.id
        }}
        title="Sites with access to this Access Level"
        isMulti={true}
        component={AsyncSelect}
        fetchFn={Site.search}
        onSelectAll={() => {
          setFieldValue('allSites', 'on');
          setFieldValue('sites', null);
        }}
        onDeselectAll={() => {
          setFieldValue('sites', []);
          setFieldValue('allSites', null);
        }}
      />
    </section>
  );
};

export default AlForm;
