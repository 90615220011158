/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react';
import { useAuth } from 'providers/auth';
import Company from 'api/company';

import { useHistory } from 'react-router-dom';

import { css } from '@emotion/react';
import { components } from 'react-select';
import CompanyItem from './company-item';
import Footer from './footer';
import { Building, CaretDownFill, Search } from 'react-bootstrap-icons';
import AsyncSelect from 'components/headless-select/async';
import * as Popover from '@radix-ui/react-popover';
import { PopoverContent } from 'components/popover';

const Option = ({ children, ...props }) => {
  return (
    <components.Option
      {...props}
      css={css`
        padding: 0px;
      `}
    >
      <CompanyItem company={props.data} />
    </components.Option>
  );
};

const CompanyManage = () => {
  const { user, companyManage } = useAuth();
  const history = useHistory();

  const [isOpen, setIsOpen] = React.useState();

  const handleManage = (companyId) =>
    companyManage.mutate(companyId, {
      onSuccess: () => history.push('/devices')
    });

  return (
    <>
      <Popover.Root open={isOpen} onOpenChange={setIsOpen}>
        <Popover.Trigger
          css={css`
            display: flex;
            gap: 5px;
            align-items: center;

            svg {
              padding: 2px;
              transition: all 0.2s;
            }
            &[aria-expanded='true'] {
              svg:first-of-type {
                transform: rotate(180deg);
              }
            }
          `}
        >
          <CaretDownFill size={16} color={'var(--primary-base)'} />
          <Building size={24} />
          <div
            data-managed={user?.company_id ? true : false}
            css={css`
              display: flex;
              flex-direction: column;
              place-items: flex-start;
              font-size: 14px;
              &[data-managed='false'] {
                opacity: 0.4;
              }
            `}
          >
            {companyManage.isLoading && 'Managing...'}
            {!companyManage.isLoading &&
              (user?.company_name || 'Manage Company')}
          </div>
        </Popover.Trigger>
        <Popover.Anchor />
        <Popover.Portal>
          <PopoverContent
            css={css`
              height: ${user?.managing ? '400px' : 'auto'};
              min-height: ${!user?.managing ? '320px' : 'auto'};
              display: flex;
              flex-direction: column;
              > div {
                flex: 1;
              }
            `}
          >
            <AsyncSelect
              label="display_name"
              checkboxes={false}
              placeholder="Search Companies..."
              data-compact
              data-inline
              maxOptions={3}
              icon={Search}
              fetchFn={Company.search}
              queryKey="companies"
              queryParams={{ staleTime: 10e6, refetchOnMount: true }}
              fetchFilters={{
                sort: 'atoz',
                except: user?.company_id
              }}
              itemComponents={{ Option: Option, DropdownIndicator: null }}
              css={css`
                .select__control {
                  margin: 1rem 2.25rem !important;
                  width: auto !important;
                }
                .select__menu {
                  //box-shadow: none;
                  border: none;
                  position: relative;
                  box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.1);
                }
                .select__menu-list {
                  overflow-y: auto;
                  overflow-x: hidden;
                }
                input {
                  opacity: 1 !important ;
                }
              `}
              //onChange={(e) => setGlobalAccessLevel(e)}
              menuIsOpen={true}
              closeMenuOnSelect={true}
              controlShouldRenderValue={false}
              onChange={(e) => {
                setIsOpen(false);
                e && handleManage(e.id);
              }}
            />

            {user?.company_id && <Footer />}
          </PopoverContent>
        </Popover.Portal>
      </Popover.Root>
    </>
  );
};

export default CompanyManage;
