import React from 'react';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */

import { useModal } from 'providers/modal-provider';

import { ModalTitle } from 'components/modal';
import { Check2Circle } from 'react-bootstrap-icons';
import Person from 'api/person';
import { PersonItem } from 'components/form/person-select';
import { CancelButton, Button } from 'components/buttons';
import { useAddPeopleMutate } from 'views/departments/actions';
import styled from '@emotion/styled';
import useAsyncSelection from 'components/headless-select/use-async-selection';
import Select from 'components/headless-select';
import { Option } from 'components/form/person-select';
import { XLg } from 'react-bootstrap-icons';
const ValuesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const AddPeopleModal = (props) => {
  const {
    dialog,
    modalState: { data: department }
  } = useModal();

  const [people, setPeople] = React.useState([]);

  const addPeopleMutation = useAddPeopleMutate(department);

  const selectRef = React.useRef();

  const handleRemoveValue = React.useCallback(
    (id) => {
      const removedValue = people.find((val) => val.id === id);
      if (!removedValue) return;
      selectRef.current.onChange(
        people.filter((val) => val.id !== id),
        { id, action: 'remove-value', removedValue }
      );
    },
    [people]
  );

  const { input, isLoading, setInput, data } = useAsyncSelection({
    ...props,
    fetchFn: Person.search,
    fetchFilters: { exceptDepartment: department.id },
    queryKey: 'people'
  });

  const [options, setOptions] = React.useState();

  React.useEffect(() => {
    setOptions(data?.data);
  }, [data]);

  return (
    <>
      <div
        css={css`
          position: relative;
          overflow: auto;
          min-height: 60vh;
          max-height: 60vh;
          min-width: 50vw;
          width: 50vw;
          display: flex;
          flex-direction: column;
          > footer {
            padding: 1rem;
            display: flex;
            justify-content: space-between;
          }
          > section {
            height: 100%;

            flex: 1;
            display: grid;
            grid-template-columns: 1fr 1fr;
            overflow: hidden;

            box-shadow: inset 0px -1px 0px var(--neutral-12);

            .select__wrapper {
              height: 100%;
              flex: 1;
              padding-top: 1rem;
              padding-left: 1rem;
              padding-right: 1rem;
            }
            .select__menu-list {
              height: 100%;
              flex: 1;
            }
            .select__menu {
              display: flex;
              flex-direction: column;
              flex: 1;
            }

            > footer {
              display: flex;
              align-items: center;
              justify-content: space-between;
            }
          }
        `}
      >
        <ModalTitle>Add People (Max 10 per operation) </ModalTitle>

        <section>
          <Select
            data-compact
            ref={selectRef}
            isMulti={true}
            title=""
            data-inline
            inputValue={input ?? ''} // need to pass here empty string because we debounce null
            setInput={setInput}
            itemComponents={{ Option }}
            isClearable={true}
            options={options}
            //filterOption={null}
            closeMenuOnSelect={false}
            menuIsOpen
            // isDisabled={isLoading}

            onChange={(e) => setPeople(e)}
            isOptionDisabled={() => people.length >= 10}
            hideSelectedOptions={true}
            controlShouldRenderValue={false}
            isLoading={isLoading}
            value={people}
            onInputChange={(e) => {
              setInput(e);
            }}
          />
          <div
            css={css`
              background: var(--neutral-08);
              overflow: hidden;
              display: flex;
              flex-direction: column;
              flex: 1;
            `}
          >
            <div
              css={css`
                margin: 1rem;
                overflow: auto;
                height: 100%;
                padding-top: 1rem;
                padding-left: 1rem;
                padding-right: 1rem;
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                flex: 1;
              `}
            >
              <label
                css={css`
                  font-style: normal;
                  font-weight: 700;
                  font-size: 14px;
                  line-height: 125%;
                `}
              >
                {people.length} of 10 selected
              </label>
              <ValuesContainer>
                {people.map((val, idx) => (
                  <div
                    key={idx}
                    css={css`
                      display: grid;
                      grid-template-columns: 1fr auto;
                      align-items: center;
                      gap: 1rem;
                    `}
                  >
                    <PersonItem person={val} />
                    <XLg onClick={() => handleRemoveValue(val.id)} />
                  </div>
                ))}
              </ValuesContainer>
            </div>
          </div>
        </section>
        <footer>
          <Button
            loading={addPeopleMutation.isLoading}
            disabled={people.length == 0 || addPeopleMutation.isLoading}
            compact
            onClick={() =>
              addPeopleMutation.mutate(
                people.map((p) => p.id),
                {
                  onSuccess: () => {
                    setOptions((options) =>
                      options.filter(
                        (opt) => !people.map((p) => p.id).includes(opt.id)
                      )
                    );
                    setPeople([]);
                  }
                }
              )
            }
          >
            <Check2Circle size={22} />
            Add People
          </Button>
          <CancelButton compact onClick={dialog.hide}>
            Close
          </CancelButton>
        </footer>
      </div>
    </>
  );
};

export default AddPeopleModal;
