import { dataFetchQuery, useMutation } from 'utils/ajax';

import { API_HOST_URL } from './api-url';
const API_URL = API_HOST_URL + '/schedule_api';

import { callAPI } from 'utils/ajax';

const Schedule = {

  get(id) {
    return callAPI('GET', `/schedule/${id}`);
  },

  search(input = {}) {
    return callAPI('GET', `/schedule`, input);
  },

  stats(input = {}) {
    return callAPI('GET', `/schedule/stats`, input);
  },

  searchEvents(id) {
    return dataFetchQuery(`${API_URL}/searchEvents/` + id, {});
  },

  delete(id) {
    return dataFetchQuery(`${API_URL}/delete/` + id);
  },

  add(input = {}) {
    return dataFetchQuery(`${API_URL}/add`, input);
  },

  edit(input, id) {
    return dataFetchQuery(`${API_URL}/edit/` + id, input);
  },
  getOccurrencesBetween(id, input) {
    return dataFetchQuery(`${API_URL}/getOccurrencesBetween/` + id, input);
  }
};

export default Schedule;
