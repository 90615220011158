import React from 'react';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import {
  PersonBadgeFill,
  DoorClosed,
  Cpu,
  Building,
  Hdd,
  HddNetwork
} from 'react-bootstrap-icons';

const styles = css`
  padding: 0.75rem;
  border-top: 1px solid var(--neutral-12);
  cursor: pointer;
  transition: background ease 300ms;
  > div {
    display: flex;
    align-items: center;
    gap: 0.4rem;
  }
  label {
    grid-column: 1/-1;
    pointer-events: none;
    display: block;
    font-size: 14px;
    color: var(--primary-base);
    font-weight: 700;
    margin-bottom: 8px;
  }

  svg {
    display: inline-block;

    vertical-align: middle;
  }

  b {
    width: 22px;
    vertical-align: middle;
    display: inline-block;
    margin-right: 8px;
    margin-left: 12px;
    font-size: 14px;
    font-weight: 700;
  }
`;

const InstallerItem = ({ installer, ...otherProps }) => {
  if (!installer) return null;
  return (
    <article css={styles} {...otherProps}>
      <label>{installer.name}</label>

      <Building color="#59606C" />
      <b title="Company count">{installer.company_count}</b>

      <HddNetwork color="#59606C" />
      <b title="Device Count">{installer.device_count}</b>
    </article>
  );
};
export default InstallerItem;
