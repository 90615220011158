import React from 'react';
import Template from 'base/template';
import { Button } from 'components/buttons';

import IndexContent from 'base/index-content';
import { columns } from 'views/people/table-columns';
import Person from 'api/person';
import { XLg } from 'react-bootstrap-icons';
import { useModal } from 'providers/modal-provider';
import AddPeopleModal from './add-people-modal';
import { DialogDisclosure } from 'reakit/Dialog';
import DepartmentActionMenu from 'views/people/department-action-menu';
import { useParams } from 'react-router-dom';
import { useQueryWrapper } from 'utils/ajax';
import Department from 'api/department';
import { useNotFound } from 'hooks/not-found-hook';

const cards = [
  {
    name: 'total',
    label: 'Total'
  },

  {
    name: 'barred',
    label: 'Barred',
    color: 'var(--red-base)',
    background: 'var(--stats-background-red)',
    filter: { enabled: '0' },
    icon: XLg
  }
];

export const FILTERS = [
  {
    open: true,
    id: 'recent',
    label: 'Recent Searches...',
    options: []
  }
];

const AddPeople = (props) => {
  const { dialog, setModalState } = useModal();
  return (
    <DialogDisclosure
      compact
      {...dialog}
      as={Button}
      onClick={() => {
        setModalState({
          component: AddPeopleModal,
          data: props.department,
          dialogProps: { hideOnClickOutside: false, hideOnClickOutside: false }
        });
      }}
    >
      Add People...
    </DialogDisclosure>
  );
};

const DepartmentManagePeople = (props) => {
  const { id } = useParams();

  const [dep, setDep] = React.useState();

  const query = useQueryWrapper(['department', 'get', id], () =>
    Department.get(id)
  );

  useNotFound(id, query, props);

  React.useEffect(() => {
    if (!query?.data) return;
    setDep(query?.data);
  }, [query?.data]);

  return (
    <Template {...props}>
      {dep && (
        <IndexContent
          pageTitle={dep.Name}
          initialFilter={{ department: dep.id }}
          id={`department-people-${dep.id}`}
          statsQuery={Person.stats}
          cards={cards}
          query={Person.search}
          columns={columns}
          isSidebarOpen={true}
          //filterKeys={filterKeys}
          actionButton={DepartmentActionMenu}
          department={dep}
          filterOptions={FILTERS}
          // chartArea={ChartArea}
          multi
          buttons={AddPeople}
          {...props}
        ></IndexContent>
      )}
    </Template>
  );
};

export default DepartmentManagePeople;
