import HealthTimeline from './health-timeline';
import React from 'react';

import Device from 'api/device';
import { useQuery } from 'react-query';
import ReactECharts from 'echarts-for-react';
import Spinner from 'components/spinner';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import { dateTime } from 'utils/time';
import EmptyHolder from 'components/empty-holder';

import * as echarts from 'echarts';

import getTime from 'date-fns/getTime';
import subDays from 'date-fns/subDays';
import { startOfDay, endOfDay, isWithinInterval } from 'date-fns';
import EventListFilters from 'components/event-list-filters';

const styles = css`
  width: 100%;
  .spinner {
    display: block;
    margin: 24px auto;
  }
  header {
    display: block;
    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 100%;
      margin-bottom: 16px;
    }
  }
  text {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 100%;
    color: var(--neutarl-00);
  }
  [role='radiogroup'] {
    width: fit-content;
    flex: 0;
    margin-left: auto;
  }
`;

const HealthTimelineHolder = ({ device_id, ...props }) => {
  const [chartData, setChartData] = React.useState([]);
  const ref = React.useRef();

  const { isLoading, data } = useQuery(
    ['device', 'health', 'profile', device_id],
    () => Device.getDeviceHealthProfile(device_id),
    {
      staleTime: 60000
    }
  );

  React.useEffect(() => {
    if (!data) return;
    //set colors
    const newData = data.map((d) => ({
      ...d,
      itemStyle: {
        normal: { color: d.type == 'online' ? '#00a96c' : '#ea3737' }
      }
    }));

    setChartData(newData);
  }, [data, ref]);

  const [selection, setSelection] = React.useState('');

  const FILTERS = [
    {
      value: '',
      label: 'All'
    },
    {
      value: 'today',
      label: 'Today'
    }
  ];

  React.useEffect(() => {
    if (!ref.current) return;
    if (!selection)
      try {
        ref.current.getEchartsInstance().dispatchAction({
          type: 'dataZoom',
          startValue: chartData?.[0]?.value[0],
          endValue: chartData?.[chartData.length - 1]?.value[1]
        });
      } catch (e) {}
    else if (selection == 'today') {
      const todayStart = startOfDay(new Date());
      const todayEnd = endOfDay(new Date());

      let start = ref.current.getEchartsInstance().getModel().option
        .dataZoom[0].startValue;
      let end = ref.current.getEchartsInstance().getModel().option
        .dataZoom[0].endValue;

      const isIntervalWithinToday =
        isWithinInterval(start, {
          start: todayStart,
          end: todayEnd
        }) && isWithinInterval(end, { start: todayStart, end: todayEnd });

      if (isIntervalWithinToday) return;
      try {
        ref.current.getEchartsInstance().dispatchAction({
          type: 'dataZoom',
          startValue: getTime(subDays(new Date(), 1)),
          endValue: getTime(new Date())
        });
      } catch (e) {}
    }
  }, [selection, ref]);

  return (
    <article className="health-chart" css={styles}>
      {!props.slim && (
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <h4>Health Timeline</h4>
          <EventListFilters
            filters={FILTERS}
            selection={selection}
            setSelection={setSelection}
          />
        </div>
      )}
      <HealthTimeline
        ref={ref}
        chartData={chartData}
        isLoading={isLoading}
        xAxisMin={props.slim ? getTime(subDays(new Date(), 3)) : null}
      />
    </article>
  );
};

export default HealthTimelineHolder;
