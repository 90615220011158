import React from 'react';
import styled from '@emotion/styled';
import SelectWrapper from 'components/headless-select';
import PopoverContent, {
  Popover,
  PopoverPortal,
  PopoverTrigger
} from 'components/popover/popover-content';
import { css } from '@emotion/react';
import {
  ChevronRight,
  ChevronLeft,
  Pencil,
  PlusLg
} from 'react-bootstrap-icons';
/** @jsxImportSource @emotion/react */
import { components } from 'react-select';
import SiteItem from 'components/site-focus/site-item';
import { AuxiliaryButton, IconButton } from 'components/buttons';
import { useHotkeys } from 'react-hotkeys-hook';
import ViewControls from './view-controls';
import AddView from './add-view';
import { Toggle } from 'components/form';
import { IconCaretRight, IconChevronDown } from '@tabler/icons-react';

const Navigator = styled.article`
  background: var(--neutral-09);
  border-bottom: 1px solid var(--neutral-12);
  height: 100%;
  display: grid;
  grid-template-areas: 'stack';
  margin-top: 0.5rem;
  margin-inline: 0.5rem;
`;
const Option = ({ children, ...props }) => {
  return (
    <components.Option
      {...props}
      css={css`
        padding: 1em;
      `}
    >
      {props.data.name}
    </components.Option>
  );
};
const MenuList = (props) => {
  return (
    <components.MenuList {...props}>
      {/* {props.children.length > 0 && props.children.slice(0, 3)} */}
      {props.children}
    </components.MenuList>
  );
};

const ViewNavigation = (props) => {
  const {
    views = [],
    selectedView,
    setSelectedView,
    setIsEdit,
    setViews,
    isEdit
  } = props;

  function debounce(func, timeout = 500) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  const handleBack = () => {
    if (!views || views?.length == 0) return;

    if (!selectedView) {
      setSelectedView(views[views.length - 1]);
    } else {
      let index = views.findIndex((s) => s.id == selectedView.id);

      if (index == 0) {
        setSelectedView(views[views.length - 1]);
      } else setSelectedView(views[index - 1]);
    }
  };
  const handleForward = () => {
    if (!views || views?.length == 0) return;
    if (!selectedView) setSelectedView(views[0]);
    else {
      let index = views.findIndex((s) => s.id == selectedView.id);

      if (index == views.length - 1) {
        setSelectedView(views[0]);
      } else setSelectedView(views[index + 1]);
    }
  };

  const dbHandleBack = debounce(handleBack);
  const dbHandleForward = debounce(handleForward);

  useHotkeys(
    'w',
    () => {
      dbHandleForward();
    },
    { enabled: !isEdit },
    [selectedView, views]
  );

  useHotkeys(
    'q',
    () => {
      dbHandleBack();
    },
    { enabled: !isEdit },
    [selectedView, views]
  );

  const [ctime, setTime] = React.useState(new Date().toLocaleTimeString());

  React.useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date().toLocaleTimeString());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <Navigator>
      <div
        className="clock"
        css={css`
          grid-area: stack;
          place-self: start;
          align-items: center;
          vertical-align: middle;
          height: 100%;
          display: inline-flex;
          margin-left: 1rem;
          font-weight: 700;
          font-size: 24px;
        `}
      >
        {ctime}
      </div>
      <div
        className="view-navigator"
        css={css`
          grid-area: stack;
          place-self: center;
          align-items: center;
          height: 20px;
          display: flex;
          align-items: center;
          gap: 1rem;
          height: 100%;
        `}
      >
        <IconButton onClick={dbHandleBack}>
          <ChevronLeft />
          <kbd>q</kbd>
        </IconButton>
        <Popover
          css={css`
            z-index: 2000;
          `}
        >
          <PopoverTrigger
            css={css`
              height: 100%;
              min-width: 250px;
              justify-content: center;
              text-overflow: ellipsis;
              font-weight: 600;
              display: flex;
              gap: 5px;
              align-items: center;
              text-overflow: ellipsis;
              overflow: hidden;
              position: relative;
              svg {
                padding: 2px;
                transition: all 0.2s;
                position: absolute;
                right: 0;
                color: var(--neutral-03);
              }
              &[aria-expanded='true'] {
                svg:first-of-type {
                  transform: rotate(180deg);
                }
              }
              span {
                font-weight: 500;
                font-size: 16px;
                line-height: 100%;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: var(--neutral-00);
                overflow: hidden;
              }
            `}
          >
            <span>{selectedView ? selectedView?.name : ''}</span>
            <IconChevronDown />
          </PopoverTrigger>
          <PopoverPortal>
            <PopoverContent sideOffset={0} align="center" asChild>
              <SelectWrapper
                itemComponents={{ DropdownIndicator: null, MenuList, Option }}
                css={css`
                  .select__control {
                    margin: 1rem 2.25rem !important;
                    width: auto !important;
                  }
                  .select__menu {
                    //box-shadow: none;
                    border: none;
                    position: relative;
                    box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.1);
                  }
                  .select__menu-list {
                    overflow-y: auto;
                    overflow-x: hidden;
                  }
                  input {
                    opacity: 1 !important ;
                  }
                `}
                isClearable={false}
                placeholder="Search views...."
                data-compact
                data-inline
                closeMenuOnSelect={true}
                controlShouldRenderValue={false}
                menuIsOpen="true"
                isMulti={false}
                options={views}
                value={null}
                onChange={(e) => e && setSelectedView(e)}
              />
            </PopoverContent>
          </PopoverPortal>
        </Popover>
        <IconButton onClick={dbHandleForward}>
          <kbd>w</kbd>
          <ChevronRight />
        </IconButton>
      </div>

      <ViewControls {...props} />
    </Navigator>
  );
};

export default ViewNavigation;
