import "css/sidebar.css";
import React from "react";
import { withSidebar } from "components/with-sidebar";
import { css } from "@emotion/react";
/** @jsxImportSource @emotion/react */
import EventList from "components/event-list";
import Network from "../devices/network";
import { useHeadlessTable } from "providers/headless-table-provider";
import SidebarEmptyHolder from "components/sidebar-empty-holder";
import {
  TabItem,
  TabGroup,
  TabPanel,
  TabPanels,
  TabList,
} from "components/tabs";
import { Calendar, Diagram3 } from "react-bootstrap-icons";
import TreeView from "components/tree-view";
import HealthTimeline from "components/charts/health-timeline";
import DeviceEventList from "views/devices/device-event-list";
import HealthTimelineHolder from "components/charts/health-timeline-holder";
const articlStyles = css`
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 16px;
  > * {
    padding: 0px 16px;
  }

  label {
    width: 100%;
  }
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 100%;
    align-items: center;
    min-height: 32px;
    color: var(--neutral-00);
  }

  header {
    h4 {
      margin-bottom: 0.5rem;
      color: var(--neutral-02);
      font-weight: normal;
    }
  }

  div > section.event-list {
    width: 100%;
    overflow-y: auto;
    margin-bottom: 8px;
  }
`;
const Sidebar = (props) => {
  const { selectedFlatRows } = useHeadlessTable();

  if (selectedFlatRows.length == 0) return <SidebarEmptyHolder />;
  else
    return (
      <article css={articlStyles}>
        <header>
          <h1>{selectedFlatRows[0].original?.name}</h1>
          <h4>3 Day Health Timeline</h4>
          <HealthTimelineHolder
            slim
            device_id={selectedFlatRows[0].original?.device_id}
          />
        </header>
        <TabGroup>
          <TabList
            css={css`
              width: 100%;
              box-shadow: unset;
              gap: 32px;
              padding: 0px 16px;
              border-top: 1px solid var(--neutral-12);
              border-bottom: 1px solid var(--neutral-12);

              [role="tab"] {
                min-width: unset;
              }
            `}
          >
            <TabItem title="Events">
              <Calendar size={24} />
            </TabItem>
            <TabItem title="Tree View">
              <Diagram3 size={24} />
            </TabItem>
          </TabList>
          <TabPanels>
            <TabPanel
              as="div"
              css={css`
                display: flex;
                flex-direction: column;
                overflow: hidden;
              `}
            >
              <Network device={selectedFlatRows[0].original} />

              <DeviceEventList
                device={{ id: Number(selectedFlatRows[0].original?.device_id) }}
              />
            </TabPanel>
            <TabPanel
              as="div"
              css={css`
                display: flex;
                flex-direction: column;
                overflow: hidden;
              `}
            >
              <TreeView ac={selectedFlatRows[0].original} />
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </article>
    );
};

export default withSidebar({})(Sidebar);
