import ActionMenu from 'views/notifications/action-menu';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Badge } from 'components/badge';
import { getSelectionColumn } from 'components/table-selection-column';
import EVENTS_CONSTANTS from 'constants/events';
import Label from 'components/label';
import { dateTimeDif, dateTimeLocal } from 'utils/time';

export const columns = [
  getSelectionColumn(),
  {
    Header: 'Name',
    accessor: 'name',
    pickerDisabled: true,
    Cell: ({ cell: { value }, row: { original } }) => {
      //console.log(original);
      let actions = JSON.parse(original.actions);
      return (
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 0.5rem;
          `}
        >
          <span>{value}</span>
          {Number(actions.delay) > 0 && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-clock-pause"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="1"
              stroke="currentColor"
              fill="none"
              strokeLineCap="round"
              strokeLineJoin="round"
            >
              <title>Delayed Notification + {actions.delay / 60} minutes</title>
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M13 20.94a8.916 8.916 0 0 1 -7.364 -2.576a9 9 0 1 1 15.306 -5.342"></path>
              <path d="M12 7v5l2 2"></path>
              <path d="M17 17v5"></path>
              <path d="M21 17v5"></path>
            </svg>
          )}
          {Number(actions.interval) > 0 && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-clock-hour-11"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              strokeWidth="1"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <title>
                Interval - only run once in {actions.interval / 60} minute(s)
              </title>
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
              <path d="M12 12l-2 -3"></path>
              <path d="M12 7v5"></path>
            </svg>
          )}
        </div>
      );
    }
  },
  {
    Header: 'Status',
    accessor: 'enabled',
    Cell: ({ cell: { value } }) => {
      const [color, label] =
        value == '1' ? ['success', 'enabled'] : ['danger', 'disabled'];
      return (
        <span>
          <Badge color={color}>{label}</Badge>
        </span>
      );
    }
  },

  {
    Header: 'Schedule',
    accessor: 'schedule_name'
  },

  {
    Header: 'Count',
    accessor: 'count'
  },
  {
    Header: 'Latest run',
    accessor: 'latest_run_at',

    Cell: ({ row, cell }) => {
      if (!cell.value) return null;
      else
        return (
          <time>
            {`${dateTimeDif(cell.value)} (${dateTimeLocal(
              new Date(cell.value + 'Z')
            )})`}
          </time>
        );
    }
  },

  {
    Header: 'Events',
    accessor: 'events',
    Cell: ({ cell: { value } }) => {
      //ONLY showing 3 events
      const eventsAmount = JSON.parse(value).length;

      return (
        <div
          title={JSON.parse(value)
            .map((e) => EVENTS_CONSTANTS.readableNames[e])
            .join(', ')}
          css={css`
            display: flex;
            gap: 0.4rem;
          `}
        >
          {JSON.parse(value)
            .slice(0, 3)
            .map((i) => (
              <Label>{EVENTS_CONSTANTS.readableNames[i]}</Label>
            ))}
          {eventsAmount > 3 && <Label>+ {eventsAmount - 3}</Label>}
        </div>
      );
    }
  }
];
