import { useMutationWrapper } from 'utils/ajax';
import AccessLevel from 'api/access_level';
import { useQueryClient } from 'react-query';
import { useHeadlessTable } from 'providers/headless-table-provider';

export const useMutateDelete = (al) => {
  const queryClient = useQueryClient();
  const { queryKey } = useHeadlessTable();
  return useMutationWrapper(
    ['access-level', 'delete'],
    (id) => AccessLevel.delete(id),
    {
      successMessage: `Access Level: ${al?.name} deleted sucessfuly`,
      onMutate: (id) => {
        let data = queryClient.getQueryData(queryKey);

        const newData = {
          ...data,
          data: data.data.filter((row) => row.id != id)
        };

        queryClient.setQueryData(queryKey, newData);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['access-levels', 'stats']);
      }
    }
  );
};

export const useMutateLock = (al) => {
  const queryClient = useQueryClient();
  const { queryKey } = useHeadlessTable();
  return useMutationWrapper(
    ['access-level', 'lock'],
    (id) => AccessLevel.lock(id),
    {
      successMessage: `Access Level: ${al?.name} operation was sucessfull`,
      onMutate: (id) => {
        let data = queryClient.getQueryData(queryKey);

        data.data.forEach(
          (i) => i.id == id && (i.enabled = i.enabled == '0' ? '1' : '0')
        );

        queryClient.setQueryData(queryKey, data);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['access-levels', 'stats']);
      }
    }
  );
};

export const useSaveDoorsMutate = (al, opts) => {
  const queryClient = useQueryClient();
  const { tableDispatch } = useHeadlessTable();
  return useMutationWrapper(
    ['al', 'save', 'doors', al?.id],
    (input) => AccessLevel.saveDoors(input, al?.id),
    {
      onMutate: () => {
        tableDispatch({ type: 'deselectAllRows' });
      },
      onSuccess: () => {
        queryClient.invalidateQueries(`al-doors-${al.id}`);
        queryClient.invalidateQueries('sites-with-readers');
      },
      ...opts
    }
  );
};
