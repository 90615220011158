import User from 'api/user';
import React from 'react';
import Template from 'base/template';
import { columns } from 'views/manage-users/table-columns';
import IndexContent from 'base/index-content';
/** @jsxImportSource @emotion/react */
import ActionMenu from 'views/manage-users/action-menu';
import Sidebar from 'views/users/sidebar';

const cards = [
  {
    name: 'total',
    label: 'Total'
  },
  {
    name: 'failed',
    label: 'Locked',
    filter: { failed: '1' }
  }
];

export const FILTERS = [
  {
    open: true,
    id: 'recent',
    label: 'Recent Searches...',
    options: []
  },
  {
    id: 'filters',
    label: 'Filters',
    options: [
      {
        value: ['role', 'I'],
        label: 'Installer',
        name: 'Installer'
      },
      {
        value: ['role', 'C'],
        label: 'Company',
        name: 'Company'
      },
      {
        value: ['role', 'V'],
        name: 'Vendor',
        label: 'Vendor'
      }
    ]
  }
];

const AllUsers = (props) => {
  return (
    <Template {...props}>
      <IndexContent
        statsQuery={User.stats}
        title="Users"
        id="manage-users"
        cards={cards}
        query={User.search}
        columns={columns}
        {...props}
        selectable
        actionButton={ActionMenu}
        isSidebarOpen={true}
        sidebar={Sidebar}
        filterOptions={FILTERS}
      ></IndexContent>
    </Template>
  );
};

export default AllUsers;
