import React from 'react';

import Intercom from 'api/intercom';
import Template from 'base/template';
import { Button, CancelButton, SecondaryButton } from 'components/buttons';
import { Redirect } from 'react-router-dom';
import FormProvider from 'providers/form-provider';
import { useMutationWrapper } from 'utils/ajax';
import { useRedirect } from 'hooks/redirect-hook';
import IntercomForm from 'views/intercoms/form';

import { Formik } from 'formik';
import * as Yup from 'yup';
import Content from 'base/content';
import { useParams } from 'react-router-dom';
import { useQueryWrapper } from 'utils/ajax';
import { useNotFound } from 'hooks/not-found-hook';
import PageTitle from 'components/page-title';
import Breadcrumbs from 'components/breadcrumbs';

const Save = (props) => {
  const { id } = useParams();

  const [intercom, setIntercom] = React.useState(
    props?.location?.state?.intercom
  );

  const query = useQueryWrapper(['intercom', 'get', id], () =>
    Intercom.get(id)
  );

  useNotFound(id, query, props);

  React.useEffect(() => {
    if (!query.data) return;
    setIntercom(query.data);
  }, [query.data]);

  const SCHEMA = Yup.object().shape({});

  const INITIAL_VALUES = {
    name: intercom?.name,
    generic_door_id: intercom?.generic_door_id
  };

  const { isSuccess, isLoading, mutate } = useMutationWrapper(
    ['intercom', 'save'],
    (input) => Intercom.save(input, intercom?.id),
    {}
  );

  const { redirect, setRedirect } = useRedirect(isSuccess);

  return (
    <Template {...props}>
      <Content {...props}>
        <PageTitle>{intercom?.name}</PageTitle>
        <Breadcrumbs crumbs={props.crumbs} />
        {redirect && <Redirect to="/intercoms" />}
        {intercom && (
          <Formik
            validationSchema={SCHEMA}
            initialValues={INITIAL_VALUES}
            onSubmit={(values) => {
              mutate(values);
            }}
          >
            {({ handleSubmit, handleReset }) => (
              <FormProvider mutateFunc={handleSubmit} disabled={isLoading}>
                <IntercomForm intercom={intercom} />
                <footer>
                  <Button type="submit">Submit</Button>
                  <CancelButton onClick={() => setRedirect(true)} />
                </footer>
              </FormProvider>
            )}
          </Formik>
        )}
      </Content>
    </Template>
  );
};

export default Save;
