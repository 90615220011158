import { css } from "@emotion/react";
import { getSelectionColumn } from "components/table-selection-column";
import { Badge } from "components/badge";
import { AvatarCircle } from "components/avatar";
import TimeFormatter from "components/time-formatter";
/** @jsxImportSource @emotion/react */

export const columns = [
  getSelectionColumn(),
  {
    Header: "License Plate",
    accessor: "text",
    pickerDisabled: true,
    Cell: ({ cell: { value } }) => (
      <span
        css={css`
          font-weigth: 600;
          font-size: 18px;
          letter-spacing: 0.87;
        `}
      >
        {value}
      </span>
    ),
  },
  {
    Header: "Description",
    accessor: "description",
    pickerDisabled: true,
  },
  {
    Header: "Last Seen",
    id: "last_seen_at",
    accessor: "last_seen_at",
    Cell: ({ cell: { value } }) => value && <TimeFormatter dt={value + "Z"} />,
  },
  {
    Header: "Status",
    accessor: "enabled",
    Cell: ({ cell: { value } }) => {
      if (value == "1") return <Badge color="success">Allowed</Badge>;
      else if (value == "0") return <Badge color="danger">Blocked</Badge>;
      else return null;
    },
  },

  {
    Header: "Person",
    accessor: "person",
    disableSortBy: true,
    Cell: ({ row: { original } }) => {
      return (
        <span
          css={css`
            display: flex;
            gap: 5px;
            align-items: center;

            svg {
              width: 32px;
              height: 32px;
              color: var(--neutral-02);
            }
          `}
        >
          <AvatarCircle
            name={original.person_name}
            url={original.person_avatar}
          />

          {original.person_name}
        </span>
      );
    },
  },
];
