import React from "react";
import { useHistory } from "react-router-dom";
import { MenuWrapper, MenuItem, MenuItemDelete } from "components/action-menu";
import { useDeleteMutate } from "./actions";
export default function ActionMenu({ data: lp, ...otherProps }) {
  const deleteMutate = useDeleteMutate(lp);
  const handleDelete = (e) => {
    confirm(
      `Are you sure you want to delete ${lp?.description} - (${lp?.text})`
    ) && deleteMutate.mutate(lp?.id, {});
  };
  const history = useHistory();
  let handleEdit = (e) =>
    history.push("/license-plates/save/" + lp?.id, {
      lp,
      desc: lp?.description,
    });
  return (
    <MenuWrapper {...otherProps}>
      <MenuItem label="Edit" onClick={handleEdit} />
      <MenuItemDelete onClick={handleDelete} />
    </MenuWrapper>
  );
}
