/** @jsxImportSource @emotion/react */
import React from 'react';
import { Menu } from '@headlessui/react';
import { css } from '@emotion/react';
import { ThreeDots } from 'react-bootstrap-icons';
import { AuxiliaryButton, IconButton } from 'components/buttons';
import { Fragment } from 'react';
import chroma from 'chroma-js';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

export const menuButtonStyle = (props) => css`
  &[data-compact='true'] {
    height: 40px;
  }
  font-weight: 500;
  position: static;
  justify-content: center;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  height: 40px;
  display: flex;
  gap: 24px;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  width: fit-content;

  box-sizing: border-box;
  background: ${props.simple ? 'none' : 'var(--neutral-11)'};

  color: var(--secundary-base);

  border: ${props.simple ? 'none' : '1px solid var(--secundary-v-03)'};
  box-sizing: border-box;
  border-radius: 3px;
  transition: border 150ms linear;

  &:hover:enabled {
    color: ${chroma(props.color || '#2480d6')
      .darken()
      .saturate(2)
      .hex()};
    border: 1px solid
      ${chroma(props.color || '#2480d6')
        .darken()
        .saturate(0.1)
        .hex()};
  }

  &:disabled {
    opacity: 50%;
    cursor: default;
  }
  &[data-compact] {
    height: 40px;
  }
`;

const MenuButton = (props) => {
  const Icon = props.icon;

  if (props.simple)
    return (
      <DropdownMenu.Trigger
        className="action-menu-wrapper"
        data-compact={props.compact || props['data-compact']}
        disabled={props.disabled}
        data-simple={props.simple}
        type="button"
        asChild={true}
      >
        <IconButton>
          {props.icon ? <Icon size={20} /> : <ThreeDots size="20" />}
        </IconButton>
      </DropdownMenu.Trigger>
    );
  else
    return (
      <DropdownMenu.Trigger
        className="action-menu-wrapper"
        data-compact={props.compact || props['data-compact']}
        disabled={props.disabled}
        data-simple={props.simple}
        css={menuButtonStyle(props)}
        type="button"
      >
        {!props.simple && 'Actions'}
        {props.icon ? <Icon size={20} /> : <ThreeDots size="20" />}
      </DropdownMenu.Trigger>
    );
};

export default MenuButton;
