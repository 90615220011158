import React from 'react';
import { MenuItem, MenuWrapper } from 'components/action-menu';
import { useRemovePeopleMutate } from 'views/departments/actions';
import { useHeadlessTable } from 'providers/headless-table-provider';
import useAlertDialog from 'providers/alert-dialog-provider';

export default function DepartmentActionMenu({ department, ...otherProps }) {
  const { selectedFlatRows } = useHeadlessTable();

  const removePeopleMutate = useRemovePeopleMutate(department);

  const confirm = useAlertDialog();

  const handleRemove = async (e) => {
    if (
      await confirm({
        description: `Are you sure you want to remove ${selectedFlatRows.length} Person(s) from department '${department.Name}'?`
      })
    )
      removePeopleMutate.mutate(
        selectedFlatRows.map((i) => i.original).map((i) => i.id)
      );
  };

  return (
    <MenuWrapper {...otherProps} disabled={selectedFlatRows.length == 0}>
      <MenuItem label="Remove From Department" onClick={handleRemove} />
    </MenuWrapper>
  );
}
