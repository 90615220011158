import React from 'react';
import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog';
import { ExclamationTriangle, InfoCircle, XLg } from 'react-bootstrap-icons';

import { css, keyframes } from '@emotion/react';
/** @jsxImportSource @emotion/react */

const contentShow = keyframes(
  css`
    0% {
      opacity: 0;
      transform: translate(-50%, -48%) scale(0.96);
    }
    100% {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
  `
);

const overlayShow = keyframes(
  css`
    0% {
      opacity: 0;
    }
    100%: {
      opacity: 1;
    }
  `
);

export const DialogContent = React.forwardRef(
  ({ children, type = 'confirm', ...props }, forwardedRef) => (
    <AlertDialogPrimitive.Portal>
      <AlertDialogPrimitive.Overlay
        css={css`
          inset: 0;
          background: rgba(0, 0, 0, 0.44);
          opacity: 0.3;
          position: fixed;
          @media (prefers-reduced-motion: no-preference) {
            animation: ${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1);
          }
          z-index: 4000;
        `}
      />
      <AlertDialogPrimitive.Content
        data-type={type}
        ref={forwardedRef}
        css={css`
          height: 100%;
          width: 100%;
          display: grid;
          align-items: center;
          grid-template-columns: auto 1fr;
          grid-template-rows: 1fr auto;
          gap: 2rem;
          background: white;
          border-radius: 6;
          box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
            hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 20vw;
          max-height: 85vh;
          height: 220px;
          border-radius: 4px;
          padding: 16px;
          height: 220px;
          z-index: 4001;
          @media (prefers-reduced-motion: no-preference) {
            animation: ${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1);
          }
          &:focus {
            outline: 'none';
          }
          &[data-type='confirm'] {
            svg {
              fill: var(--red-base);
            }
            #icon {
              background: var(--red-v-02);
            }
          }

          &[data-type='info'] {
            svg {
              fill: var(--secundary-base);
            }
            #icon {
              background: var(--secundary-v-02);
            }
          }
          #icon {
            padding: 8px;
            place-self: center;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            width: fit-content;
            border-radius: 16px;
            width: 72px;
            height: 72px;
          }
          footer {
            display: flex;
            width: 100%;
            gap: 0.7rem;
            justify-content: end;
            grid-column: 1 /-1;
          }
        `}
        {...props}
      >
        <div id="icon">
          {type == 'confirm' && <ExclamationTriangle size={32} />}
          {type == 'info' && <InfoCircle size={32} />}
        </div>
        {children}
      </AlertDialogPrimitive.Content>
    </AlertDialogPrimitive.Portal>
  )
);

export const Dialog = AlertDialogPrimitive.Root;
export const DialogTrigger = AlertDialogPrimitive.Trigger;
export const DialogCancel = AlertDialogPrimitive.Cancel;
export const DialogAction = AlertDialogPrimitive.Action;
