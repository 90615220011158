import React from 'react';
import classNames from 'classnames';
import Loader from 'components/loader';
import { useFormikContext } from 'formik';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */

export const formStyles = css`
  box-shadow: var(--popover-box-shadow);
  gap: 16px;
  display: flex;
  flex-direction: column;
  width: fit-content;
  justify-content: center;

  background: var(--neutral-09);
  border-radius: 8px;
  padding: 24px;
  flex-wrap: wrap;
  position: relative;
  box-sizing: border-box;

  > h3 {
    font-weight: 700;
    font-size: 14px;
    margin-top: 32px;
  }

  &[disabled] {
    opacity: 0.4;
  }
  > section {
    display: grid;
    grid-template-columns: var(--form-column-width) var(--form-column-width);
    gap: 24px;
    grid-gap: 24px;
    width: fit-content;
  }
  footer {
    flex: 100%;
    display: flex;
    //justify-content: flex-end;
    gap: 16px;
    /**  button:last-child {
      margin-left: auto;
    }**/
  }
`;

const loaderStyles = css`
  // position: fixed;
  position: absolute;
  // top: 0;
  top: 200px;
  // bottom: 0;
  left: 0;
  right: 0;
  height: 24px;
  width: 24px;
  margin: auto;
  opacity: 1;
`;

import 'css/form.css';
import ReactJson from 'react-json-view';
const Form = React.forwardRef((props, ref) => {
  const formik = useFormikContext();
  return (
    <form
      css={css`
        margin-top: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        position: relative;
      `}
      onSubmit={props.onSubmit}
      /**noValidate **/ ref={ref}
      name={props.name}
    >
      {props.disabled && (
        <span css={loaderStyles}>
          <Loader size="24" />
        </span>
      )}
      <fieldset css={formStyles} disabled={props.disabled}>
        {props.children}
      </fieldset>
      <div
        css={css`
          width: 100%;
        `}
      >
        {process.env.NODE_ENV == 'development' && (
          <div
            css={css`
              margin-top: 48px;
              display: flex;
              gap: 24px;
              > div {
                flex: 1;
                height: 200px;
                overflow-y: auto;
              }
            `}
          >
            <ReactJson iconStyle="circle" src={formik?.values} name="values" />
            <ReactJson iconStyle="circle" src={formik?.errors} name="errors" />
          </div>
        )}
      </div>
    </form>
  );
});

export default Form;
