import { Card } from './card';
import {
  IconRotateRectangle,
  IconCheck,
  IconAlertCircle
} from '@tabler/icons-react';
import { Button } from 'components/buttons';
import { css } from '@emotion/react';
import { useMutateCheckSync } from 'views/controllers/actions';
/** @jsxImportSource @emotion/react */

export default ({ device }) => {
  const mutateCheckSync = useMutateCheckSync(device, {});

  return (
    <Card>
      <div className="icon">
        <IconRotateRectangle />
      </div>
      <div className="content">
        <h2>Sync Check</h2>
        <span className="description">
          Preform a shallow sync check for Credentials, Schedules and Access
          Levels.
        </span>
      </div>
      <div className="action">
        <Button
          compact
          disabled={mutateCheckSync.isLoading}
          onClick={() => mutateCheckSync.mutate(device.controller_id)}
        >
          Check
        </Button>
      </div>
      {mutateCheckSync.data?.data && (
        <div
          className="data"
          css={css`
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            span {
              display: flex;
              align-items: center;
              gap: 1rem;

              &[data-success='true'] {
                svg {
                  color: var(--light--primary--500);
                }
              }
              &[data-success='false'] {
                svg {
                  color: var(--light--others--red-500);
                }
              }
            }
          `}
        >
          <span
            data-type="credentials"
            data-success={mutateCheckSync.data?.data.credentialsMatch}
          >
            {mutateCheckSync.data?.data.credentialsMatch ? (
              <IconCheck />
            ) : (
              <IconAlertCircle />
            )}
            <label>Credentials:</label>
            {mutateCheckSync.data?.data.credentialsInAC} /
            {mutateCheckSync.data?.data.credentialsInDB}
          </span>
          <span
            data-type="schedules"
            data-success={mutateCheckSync.data?.data.schedulesMatch}
          >
            {mutateCheckSync.data?.data.schedulesMatch ? (
              <IconCheck />
            ) : (
              <IconAlertCircle />
            )}
            <label>Schedules:</label>
            {mutateCheckSync.data?.data.schedulesInAC} /
            {mutateCheckSync.data?.data.schedulesInDB}
          </span>

          <span
            data-type="levels"
            data-success={mutateCheckSync.data?.data.APsMatch}
          >
            {mutateCheckSync.data?.data.APsMatch ? (
              <IconCheck />
            ) : (
              <IconAlertCircle />
            )}
            <label>Levels:</label>
            {mutateCheckSync.data?.data.APsInAC} /
            {mutateCheckSync.data?.data.APsInDB}
          </span>
        </div>
      )}
    </Card>
  );
};
