import "css/sidebar.css";
import React from "react";
import { withSidebar } from "components/with-sidebar";
import { css } from "@emotion/react";
/** @jsxImportSource @emotion/react */
import EventList from "components/event-list";
import { useHeadlessTable } from "providers/headless-table-provider";
import SidebarEmptyHolder from "components/sidebar-empty-holder";
import UsageChartHolder from "components/charts/usage-chart-holder";

import { useEventList } from "hooks/use-event-list";

const articleStyles = css`
  display: flex;
  height: 100% !important;
  flex-direction: column;

  label {
    width: 100%;
  }
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 100%;
    align-items: center;
    min-height: 32px;
    color: var(--neutral-00);
  }
  header {
    width: 100%;
    padding: 24px;
    display: grid;
    grid-template-columns: 100px auto;
    align-items: center;
    column-gap: 24px;
    .avatar {
      grid-row: 1 / 3;
    }
  }
  h2 {
    color: #7f8795;
  }
  > section.usage-chart {
    flex: 1;
  }
  > section.event-list {
    display: inline-flex;
    position: relative;
    flex-direction: column;
    gap: 24px;

    width: 100%;
    overflow-y: auto;
  }
  > * {
    padding: 16px;
  }
`;
const Sidebar = (props) => {
  const { selectedFlatRows } = useHeadlessTable();

  if (selectedFlatRows.length == 0) return <SidebarEmptyHolder />;

  const query = useEventList({
    filters: { license_plate_id: selectedFlatRows[0].original.id },
  });

  return (
    <React.Fragment>
      <article css={articleStyles}>
        <header></header>
        <EventList query={query} />
      </article>
    </React.Fragment>
  );
};

export default withSidebar({})(Sidebar, null);
