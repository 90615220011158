import React from 'react';
import { MenuItem, MenuWrapper } from 'components/action-menu';
import { useRemoveAlMutate } from 'views/credentials/actions';
import { useHeadlessTable } from 'providers/headless-table-provider';
import useAlertDialog from 'providers/alert-dialog-provider';

export default function AccessLevelActionMenu({ credential, ...otherProps }) {
  const { selectedFlatRows } = useHeadlessTable();

  const removeAlMutate = useRemoveAlMutate(credential);

  const confirm = useAlertDialog();

  const handleRemove = async (e) => {
    if (
      await confirm({
        description: `Are you sure you want to remove ${selectedFlatRows.length} Access Levels(s) from credential '${credential.Description}'?`
      })
    )
      removeAlMutate.mutate(
        selectedFlatRows.map((i) => i.original).map((i) => i.access_level_id)
      );
  };

  return (
    <MenuWrapper {...otherProps} disabled={selectedFlatRows.length == 0}>
      <MenuItem label="Remove From Credential" onClick={handleRemove} />
    </MenuWrapper>
  );
}
