import React from 'react';
import { Popup, Tooltip, useMap } from 'react-leaflet';
import { Marker } from 'react-leaflet';
import { css } from '@emotion/react';
import DoorStateAvatar from 'views/doors/door-state-avatar';
import LastPerson from 'views/doors/last-person';
/** @jsxImportSource @emotion/react */
import ActionMenu from 'views/doors/action-menu';
import LivePlayer from 'components/video/live-player';
import { XLg } from 'react-bootstrap-icons';
import dataMarker from './data-marker';
import * as Dialog from '@radix-ui/react-dialog';
import Filters from 'views/events/filters';
import { marker } from 'leaflet';

const LeafIcon = L.DivIcon.extend({
  options: {}
  // createIcon: function (oldIcon) {
  //   var newIcon = L.Icon.prototype.createIcon.call(this, oldIcon);
  //   if (this.options.data) {
  //     for (var key in this.options.data) {
  //       newIcon.dataset[key] = this.options.data[key];
  //     }
  //   }
  //   return newIcon;
  // }
});

// L.DomMarkers.Icon = L.DivIcon.extend({
//   options: {
//     element: null // a initialized DOM element
//     //same options as divIcon except for html
//   },

//   createIcon: function () {
//     if (!this.options.element) return;

//     var div = this.options.element;
//     this._setIconStyles(div, 'icon');
//     return div;
//   }
// });

// L.DomMarkers.icon = function (options) {
//   return new L.DomMarkers.Icon(options);
// };

function createCameraIcon(camera) {
  let color = camera.online == '1' ? '#00A96C' : '#ea3737';

  return dataMarker({
    className: 'custom-icon camera',
    data: {
      online: camera.online == '1'
    },

    html: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill=${color} stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-device-cctv">
  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
  <path d="M3 3m0 1a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-16a1 1 0 0 1 -1 -1z" />
  <path d="M12 14m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" />
  <path d="M19 7v7a7 7 0 0 1 -14 0v-7" />
  <path d="M12 14l.01 0" />
</svg>`
  });
}

const CameraMarker = ({ camera: cm, filters }) => {
  const [popOpen, setPopOpen] = React.useState();
  const [popCoords, setPopCoords] = React.useState();

  const markerRef = React.useRef(null);

  React.useEffect(() => {
    if (!markerRef.current) return;

    markerRef.current.options.rotation = cm.options.rotation;
    markerRef.current.update();
  }, [markerRef]);

  return (
    <Marker
      ref={markerRef}
      icon={createCameraIcon(cm.properties)}
      position={cm.geometry.coordinates.slice().reverse()}
      properties={cm.properties}
      eventHandlers={{
        click: (e) => {
          setPopCoords({
            x: e.originalEvent.clientX,
            y: e.originalEvent.clientY
          });
          setPopOpen((prev) => !prev);
        }
      }}
    >
      {filters.tooltip && (
        <Tooltip permanent={true} className="fade-in">
          {cm.properties.name}
        </Tooltip>
      )}
      {!filters.tooltip && (
        <Tooltip className="fade-in">{cm.properties.name}</Tooltip>
      )}
      <Dialog.Root open={popOpen} onOpenChange={setPopOpen}>
        <Dialog.Portal>
          <Dialog.Content
            css={css`
              background-color: white;
              border-radius: 6px;
              box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
                hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
              position: fixed;
              transform: translate(-50%, -50%);

              top: 50%;
              left: 50%;
              z-index: 4000;

              width: 90vw;
              max-width: 450px;
              height: 350px;
              max-height: 85vh;
            `}
          >
            <article
              css={css`
                display: flex;
                flex-direction: column;
                height: 100%;
                header {
                  padding: 0.5rem;
                  border-bottom: 1px solid vaR(--neutral-05);
                  display: grid;
                  grid-template-columns: auto;
                  h3 {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                  }
                }
                #camera-player {
                  padding: 0.4rem;
                  flex: 1;
                }
              `}
            >
              <header>
                <h3>{cm.properties.name}</h3>
              </header>
              <LivePlayer host={cm.properties} autoPlay={true} />
            </article>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </Marker>
  );
};

const MarkerPopUp = ({ camera, ...props }) => {
  //autoPan={false} closeOnEscapeKey={true} offset={L.point(200, 300)}

  return (
    <Popup open={false} autoPan={false} offset={L.point(100, 200)} {...props}>
      <div
        css={css`
          min-width: 300px;
          width: 300px;
          height: 300px;

          header {
            padding: 1rem;
            border-bottom: 1px solid vaR(--neutral-05);
            display: grid;
            grid-template-columns: 50px 1fr auto;
            h3 {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }
          .actions {
            margin-left: auto;
          }
          section {
            h4 {
              margin-bottom: 0.5rem;
            }
            padding: 1rem;
          }
        `}
      >
        <header
          css={css`
            display: flex;
            align-items: center;
            gap: 1rem;
          `}
        >
          <h3>{camera.name}</h3>
        </header>
        <section
          css={css`
            height: 250px;
          `}
        >
          <LivePlayer host={camera} autoPlay={true} />
        </section>
      </div>
    </Popup>
  );
};
const CameraLayer = ({ cameras, geojson, filters }) => {
  const [cameraMarkers, setCameraMarkers] = React.useState([]);

  React.useEffect(() => {
    if (!cameras) return;
    let json = { ...geojson };
    cameras?.forEach((dbCamera, idx) => {
      let index = json.features.findIndex(
        (d) => d.properties.id == dbCamera.id && d.properties.type == 'camera'
      );

      if (index !== -1) {
        //door found
        json.features[index] = {
          ...json.features[index],
          properties: {
            ...json.features[index]['properties'],
            shape: 'marker',
            type: 'camera',
            ...dbCamera
          }
        };
      }
    });

    // todo remove cameras that re not in DB
    setCameraMarkers(
      json.features.filter(
        (f) => f.properties.type == 'camera' && f.geometry.type == 'Point'
      )
    );
  }, [cameras, geojson]);

  return cameraMarkers?.map((cm, key) => {
    let show = false;
    if (!filters.cameras) return;
    if (filters.online && cm.properties.online == '1') show = true;
    if (filters.offline && cm.properties.online == '0') show = true;
    if (show) return <CameraMarker camera={cm} key={key} filters={filters} />;
  });
};

export default CameraLayer;
