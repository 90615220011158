import React from 'react';
import Template from 'base/template';

import Camera from 'api/camera';

import { useAuth } from 'providers/auth';
import { useQueryWrapper } from 'utils/ajax';
import { useNotFound } from 'hooks/not-found-hook';
import { useParams } from 'react-router-dom';
import Content from 'base/content';
import PageTitle from 'components/page-title';
import Breadcrumbs from 'components/breadcrumbs';
import styled from '@emotion/styled';
import LprDayOfWeek from 'views/cameras/lpr-day-of-week';
import LprHour from 'views/cameras/lpr-hour';
import LprConfidence from 'views/cameras/lpr-confidence';
import LprActivity from 'views/cameras/lpr-activity';
import { Section } from 'views/dashboard/user-dashboard';
import { ArrowClockwise, Truck } from 'react-bootstrap-icons';
import { css } from '@emotion/react';
import LprStats from 'views/cameras/lpr-stats';
/** @jsxImportSource @emotion/react */
import * as RadioGroup from '@radix-ui/react-radio-group';
import LprMostActive from 'views/cameras/lpr-most-active';
import { AuxiliaryButton, IconButton } from 'components/buttons';
import { queryCache, useQueryClient } from 'react-query';
import PeriodSelect from 'views/reports/period-select';
import LprConfidenceLabel from 'views/cameras/lpr-confidence-label';
import { subDays } from 'date-fns';

export const RadioStyles = css`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  button {
    padding: 0.5rem;
    border-radius: 4px;
    &[aria-checked='true'] {
      font-weight: 500;
      background: var(--neutral-05);
    }
  }
`;

const Main = styled('main')`
  display: grid;

  gap: 1rem;

  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(4, 1fr);

  height: 100%;
  width: 100%;
  overflow: hidden;
  .dayOfWeek {
    grid-area: 1 / 1 / 2 / 4;
  }
  .hour {
    grid-area: 2 / 1 / 3 / 4;
  }
  .avgConfidence {
    grid-area: 3 / 1 / 4 / 4;
    display: flex;
    gap: 1rem;
    section:first-of-type {
      flex: 1 1 30%;
    }
  }

  .activity {
    grid-area: 4 / 1 / 4 / 6;
  }
  .most-active {
    grid-area: 2 / 4 / 4 / 6;
  }
  .stats {
    grid-area: 1 / 4 / 2 / 6;
    > ul {
      align-items: center;
      display: flex;
      justify-content: flex-start;
      height: 100%;
      width: 100%;
      padding: 1rem;
      gap: 1rem;
      > li {
        border: 1px solid #eceff3;
        border-radius: 4px;
        height: fit-content;
        flex: 0 0 30%;
        &:hover:enabled {
          filter: brightness(90%);
        }
        background: var(--neutral-09);
        border-radius: 5px;
        //box-shadow: 0px 5px 48px -5px rgba(0, 0, 0, 0.05);
        display: grid;
        grid-template-columns: auto 1fr;

        column-gap: 1.2rem;
        align-items: center;
        padding: 1.5rem 1.8rem;

        span.value {
          width: 100%;
          font-size: 24px;
          font-weight: 700;
          color: var(--neutral-00);
          grid-row: 2;
        }
        span.name {
          grid-row: 1;
          grid-column: 2;
          white-space: nowrap;
          font-size: 12px;
          text-transform: capitalize;
          color: var(--secondary-font-color);
        }
        > div {
          height: 48px;
          width: 48px;
          display: flex;
          border-radius: 4px;
          justify-content: center;
          align-items: center;

          padding: 0.4rem;
          grid-row: 1 / span 2;
          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
    .most-active {
    }
  }
`;

export default (props) => {
  const { user } = useAuth();
  const { id } = useParams();

  const [camera, setCamera] = React.useState();

  const query = useQueryWrapper(
    ['camera', 'details', id],
    () => Camera.search({ id }),
    {
      initialData: {
        data: [props?.location?.state?.camera]
      }
    }
  );

  React.useEffect(() => {
    if (!query.data.data?.[0]) return;
    setCamera(query.data.data?.[0]);
  }, [query.data.data?.[0]]);

  useNotFound(id, query, props);

  const [filter, setFilter] = React.useState({
    to: new Date(),
    from: subDays(new Date(), 7)
  }); // default 7 days
  const queryClient = useQueryClient();

  return (
    <Template {...props}>
      <Content {...props}>
        <div
          css={css`
            display: flex;
          `}
        >
          <PageTitle>{camera?.name}</PageTitle>
          <div
            css={css`
              .period-input {
                width: 400px;
              }
              margin-left: auto;
              display: flex;
              align-items: center;
              gap: 0.5rem;
            `}
          >
            <PeriodSelect
              globalFilter={filter}
              data-compact
              selection={filter}
              setSelection={setFilter}
              showValue={true}
            />
            <AuxiliaryButton
              compact
              onClick={() => {
                queryClient.resetQueries(['camera', camera?.id]);
              }}
            >
              <ArrowClockwise size={24} />
            </AuxiliaryButton>
          </div>
        </div>
        <Breadcrumbs crumbs={props.crumbs} />
        {camera && (
          <Main>
            <Section className="dayOfWeek">
              <header>
                <h4>Weekday spread</h4>
              </header>
              <LprDayOfWeek camera={camera} filter={filter} />
            </Section>
            <Section className="hour">
              <header>
                <h4>Hourly spread</h4>
              </header>
              <LprHour camera={camera} filter={filter} />
            </Section>
            <div className="avgConfidence">
              <Section>
                <header>
                  <h4>Confidence avg.</h4>
                  <h5>Period total</h5>
                </header>
                <LprConfidenceLabel camera={camera} filter={filter} />
              </Section>
              <Section>
                <header>
                  <h4>Confidence avg.</h4>
                </header>
                <LprConfidence camera={camera} filter={filter} />
              </Section>
            </div>
            <Section className="activity">
              <header>
                <h4>Activity</h4>
              </header>
              <LprActivity camera={camera} filter={filter} />
            </Section>
            <Section className="stats">
              <header>
                <h4>Plates total</h4>
              </header>
              <LprStats camera={camera} filter={filter} />
            </Section>
            <Section className="most-active">
              <header>
                <h4>Most Active Plate</h4>
              </header>
              <LprMostActive camera={camera} filter={filter} />
            </Section>
          </Main>
        )}
      </Content>
    </Template>
  );
};
