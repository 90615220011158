import React from 'react';
import ScheduleForm from 'views/schedules/form';
import Schedule from 'api/schedule';
import Template from 'base/template';
import { Button, CancelButton } from 'components/buttons';

import FormProvider from 'providers/form-provider';
import { useMutationWrapper } from 'utils/ajax';
import { useRedirect } from 'hooks/redirect-hook';
import { Redirect } from 'react-router-dom';
import EventForm from 'views/schedules/event-form';
import { Formik } from 'formik';
import Content from 'base/content';
import PageTitle from 'components/page-title';
import { ErrorMessage } from 'components/form';
import { useParams } from 'react-router-dom';
import { useNotFound } from 'hooks/not-found-hook';
import { useQueryWrapper } from 'utils/ajax';

import * as Yup from 'yup';
import Breadcrumbs from 'components/breadcrumbs';

const Save = (props) => {
  const { id } = useParams();

  const [schedule, setSchedule] = React.useState(
    props?.location?.state?.schedule
  );

  const query = useQueryWrapper(
    ['schedule', 'get', id],
    () => Schedule.get(id),
    {
      enabled: Boolean(id && !props?.location?.state?.schedule),
      cacheTime: 0,
      staleTime: Infinity,
      onNotFound() {
        setRedirect(true);
      }
    }
  );

  useNotFound(id, query, props);

  React.useEffect(() => {
    if (!query.data) return;
    setSchedule(query.data);
  }, [query.data]);

  const SCHEMA = Yup.object().shape({
    sites: Yup.array().min(1, 'Select at least one option').nullable(),

    name: Yup.string()
      .min(2, 'Name is too short')
      .max(40, '40 characters max')
      .required('Required'),
    events: Yup.array().required().min(1, 'Must contain 1 event')
  });

  const sites = schedule?.sites;
  const INITIAL_VALUES = {
    ...schedule,
    name: schedule?.Name,
    sites: schedule
      ? schedule?.sites === false
        ? [0]
        : sites.map((id) => Number(id))
      : [],
    description: schedule?.Description,
    events: []
  };

  let { isLoading, mutate, isSuccess } = useMutationWrapper(
    ['schedule', 'save'],
    (input) => Schedule[schedule ? 'edit' : 'add'](input, schedule?.id),
    {}
  );

  const { redirect, setRedirect } = useRedirect(isSuccess);

  return (
    <Template {...props}>
      <Content {...props}>
        <PageTitle>
          {id ? (schedule ? schedule.Name : '') : 'Schedule Add'}
        </PageTitle>
        {redirect && <Redirect to="/schedules" />}
        <Breadcrumbs crumbs={props.crumbs} />

        {(!id || (id && schedule)) && (
          <Formik
            validationSchema={SCHEMA}
            enableReinitialize={true}
            initialValues={INITIAL_VALUES}
            onSubmit={(values) => {
              let a = {
                ...values,
                include: values.events.filter((e) => e.type == 'I'),
                exclude: values.events.filter((e) => e.type == 'E')
              };
              mutate(a);
            }}
          >
            {({ handleSubmit }) => (
              <FormProvider
                mutateFunc={handleSubmit}
                disabled={isLoading}
                //autoFocus={false} // uncomment this if u do not need auto focus
              >
                <ScheduleForm schedule={schedule} {...props} />

                <EventForm schedule={schedule} {...props} />

                <footer>
                  <Button type="submit">Submit</Button>
                  <CancelButton onClick={() => setRedirect(true)} />
                </footer>
              </FormProvider>
            )}
          </Formik>
        )}
      </Content>
    </Template>
  );
};

export default Save;
