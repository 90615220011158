import 'css/sidebar.css';
import React from 'react';
import { withSidebar } from 'components/with-sidebar';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import ActiveDoorChart from 'views/dashboard/active-door-chart';
import { useHeadlessTable } from 'providers/headless-table-provider';
import SidebarEmptyHolder from 'components/sidebar-empty-holder';
import AuxiliaryStateAvatar from './auxiliary-state-avatar';
import AuxiliaryTabs from './auxiliary-tabs';
import ActionMenu from 'views/doors/action-menu';
import { Question } from 'react-bootstrap-icons';
const Stats = (props) => {
  return <ActiveDoorChart />;
};
const styles = css`
  display: flex;

  height: 100% !important;
  flex-direction: column;

  label {
    width: 100%;
  }

  > header {
    padding: 24px;
    display: grid;
    grid-template-columns: 50px auto;
    grid-column-gap: 16px;
    align-items: center;
    .door-avatar {
      grid-row: span 2;
    }
    button {
      padding: 8px;
      background: #f0f6ff;
      border-radius: 5px;
      svg {
        width: 24px;
        height: 24px;
        color: #2480d6;
      }
    }
    h2 {
      margin: 0;
      font-family: Red Hat Display;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 100%;
      /* identical to box height, or 18px */

      color: var(--neutral-00);
    }
    h3 {
      font-family: Red Hat Display;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 100%;

      color: var(--menu-item-color);
    }
  }

  > div > section.event-list {
    width: 100%;
    min-height: 100%;
    max-height: 100%;
    overflow-y: auto;

    margin-bottom: 8px;
  }
`;
const Sidebar = (props) => {
  const [auxiliary, setAuxiliary] = React.useState();
  const { selectedFlatRows } = useHeadlessTable();
  React.useEffect(() => {
    setAuxiliary(selectedFlatRows?.[0]?.original || props.data);
  }, [selectedFlatRows, props.data]);

  if (!auxiliary) return <SidebarEmptyHolder />;
  return (
    <article css={styles}>
      <header>
        <AuxiliaryStateAvatar aux={auxiliary} />
        <h2>{auxiliary.name}</h2>
      </header>

      {auxiliary?.io_port_id ? (
        <AuxiliaryTabs aux={auxiliary} />
      ) : (
        <SidebarEmptyHolder
          icon={Question}
          title="Port not found"
          subtitle="Associate this Auxiliary with a physical device port"
        />
      )}
    </article>
  );
};

export default withSidebar({})(Sidebar, Stats);
