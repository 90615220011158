import React, { Component } from 'react';

import CompanyDetails from 'views/companies/company-details';
import CompanyFeatures from 'views/companies/company-features';
import CompanyEvents from 'views/companies/company-events';
import CompanyAD from './company-ad';
import {
  TabItem,
  TabGroup,
  TabPanel,
  TabPanels,
  TabList
} from 'components/tabs';
import { css } from '@emotion/react';
import { useFormikContext } from 'formik';
import CompanySecurity from './company-security';
/** @jsxImportSource @emotion/react */

const dotStyles = css`
  height: 10px;
  width: 10px;
  background-color: var(--red-base);
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
`;

const IsError = ({ fields }) => {
  const { errors } = useFormikContext();

  if (fields.some((i) => errors[i])) return <span css={dotStyles}></span>;
  else return null;
};
const CompanyForm = (props) => {
  return (
    <TabGroup>
      <TabList>
        <TabItem>
          <IsError fields={['time_zone', 'name', 'address']} />
          Details
        </TabItem>

        <TabItem>
          <IsError fields={['audit_log_ttl', 'event_ttl']} />
          Events
        </TabItem>
        <TabItem>Active Directory</TabItem>
        <TabItem>Security</TabItem>
      </TabList>
      <TabPanels>
        <TabPanel>
          <CompanyDetails {...props} />

          <CompanyFeatures {...props} />
        </TabPanel>

        <TabPanel>
          <CompanyEvents {...props} />
        </TabPanel>
        <TabPanel>
          <CompanyAD {...props} />
        </TabPanel>
        <TabPanel>
          <CompanySecurity {...props} />
        </TabPanel>
      </TabPanels>
    </TabGroup>
  );
};

export default CompanyForm;
