import SelectWrapper from 'components/headless-select';
import React from 'react';
import { useFormikContext } from 'formik';
import { useAuth } from 'providers/auth';
import IsRole from 'components/is-role';

export default () => {
  const { user } = useAuth();

  const { values, setFieldValue } = useFormikContext();

  let OPTIONS = [
    // 'default', 'dashboard', 'doors'
    { name: 'Default home screen', id: 'default' },
    { name: 'Doors', id: 'doors' }
  ];
  if (
    user?.features?.cameras_enabled &&
    (values.permission.includes('fa') ||
      values.permission.includes('cameraView'))
  )
    OPTIONS.push({ name: 'Cameras', id: 'cameras' });

  if (
    user?.features?.cameras_enabled &&
    (values.permission.includes('fa') ||
      values.permission.includes('dashboard'))
  )
    OPTIONS.push({ name: 'Dashboard', id: 'dashboard' });
  if (
    user?.features?.monitoring &&
    (values.permission.includes('fa') ||
      values.permission.includes('monitoring'))
  )
    OPTIONS.push({ name: 'Monitoring', id: 'monitoring-views' });
  if (user?.features?.overview_enabled)
    OPTIONS.push({ name: 'Mapping Overview', id: 'overview' });

  return (
    <section>
      <SelectWrapper
        menuPlacement="top"
        title="Home Screen"
        simple
        options={OPTIONS}
        value={
          values.home_path && OPTIONS.find((o) => o.id == values.home_path)
        }
        onChange={(e) => e && setFieldValue('home_path', e.id)}
        placeholder="Select home screen..."
      ></SelectWrapper>
    </section>
  );
};
