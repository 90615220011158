import React from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */

export default ({
  children,
  content,
  open,
  defaultOpen,
  onOpenChange,
  arrow,
  ...props
}) => {
  return (
    <TooltipPrimitive.Provider>
      <TooltipPrimitive.Root
        open={open}
        defaultOpen={defaultOpen}
        onOpenChange={onOpenChange}
      >
        <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>

        <TooltipPrimitive.Content side="top" align="center" {...props} asChild>
          <div
            css={css`
              z-index: 2000;
              position: relative;
              padding: 0.5rem;
              background: var(--neutral-09);
              box-shadow: 0px 2px 6px 4px rgba(29, 32, 35, 0.4);
              border-radius: 4px;
            `}
          >
            {arrow && (
              <TooltipPrimitive.Arrow
                css={css`
                  fill: var(--neutral-09);
                `}
              />
            )}

            {content}
          </div>
        </TooltipPrimitive.Content>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  );
};
