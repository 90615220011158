import React from 'react';

import Template from 'base/template';

import Content from 'base/content';
import SetupDoors from './setup_doors';
import Setup_Aperio from './setup_aperio';
import SetupElevator from './setup_elevator';
import { useQueryWrapper } from 'utils/ajax';
import Controller from 'api/controller';
import PageTitle from 'components/page-title';
import Breadcrumbs from 'components/breadcrumbs';
import { useParams } from 'react-router-dom';
import { useNotFound } from 'hooks/not-found-hook';
import { Badge } from 'components/badge';
import { css } from '@emotion/react';

/** @jsxImportSource @emotion/react */
const ControllerSetup = (props) => {
  const { id } = useParams();

  const [ac, setAc] = React.useState();

  const query = useQueryWrapper(
    ['controller', 'get', id],
    () => Controller.search({ id }),
    {
      initialData: {
        data: [props?.location?.state?.ac]
      }
    }
  );

  useNotFound(id, query, props);

  React.useEffect(() => {
    if (!query.data.data?.[0]) return;
    setAc(query.data.data?.[0]);
  }, [query.data.data?.[0]]);

  const { data } = useQueryWrapper(
    ['controller'],
    () => Controller.getConfig(ac.id),
    {
      cacheTime: 0,
      enabled: Boolean(ac)
    }
  );

  //TODO add load ac from url

  return (
    <Template {...props}>
      <Content {...props}>
        {ac && (
          <div
            css={css`
              display: flex;
              gap: 0.5rem;
              align-items: center;
              line-height: 24px;
              h1 {
                margin: 0;
                font-size: 24px;
              }
            `}
          >
            <h1>{ac?.name}</h1>
            <span
              css={css`
                font-size: 12px;
              `}
            >
              ({ac?.mac})
            </span>
            {ac.setup == 3 && (
              <Badge color={ac.online == 1 ? 'success' : 'danger'}>
                {ac.online == 1 ? 'online' : 'offline'}
              </Badge>
            )}
          </div>
        )}
        <Breadcrumbs crumbs={props.crumbs} />

        {ac && (
          <article>
            {data &&
              ['A1601', 'A1001', 'A1610', 'A1210'].includes(ac.product) &&
              ['1', '2'].includes(ac.doors) && (
                <SetupDoors ac={ac} configuration={data} />
              )}

            {ac.doors == '0' && <Setup_Aperio ac={ac} configuration={data} />}
            {ac.doors == '3' && <SetupElevator ac={ac} configuration={data} />}
          </article>
        )}
      </Content>
    </Template>
  );
};

export default ControllerSetup;
