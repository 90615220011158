import 'css/sidebar.css';
import React from 'react';
import { withSidebar } from 'components/with-sidebar';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import { useHeadlessTable } from 'providers/headless-table-provider';
import SidebarEmptyHolder from 'components/sidebar-empty-holder';
import styled from '@emotion/styled';
import CameraTabs from './camera-tabs';
import { Camera, CameraVideo } from 'react-bootstrap-icons';
import {
  IconAlertTriangleFilled,
  IconCheck,
  IconDeviceSdCard,
  IconX
} from '@tabler/icons-react';
import { AuxiliaryButton } from 'components/buttons';
import { dateTimeShort } from 'utils/time';
import DeviceAlerts from 'components/device-alerts';
import IsRole from 'components/is-role';
import { useQueryWrapper } from 'utils/ajax';
import CameraAction from 'api/camera_action';
const Stats = (props) => {
  return null;
};
export const SidebarWrapper = styled('article')`
  display: flex;

  height: 100% !important;
  flex-direction: column;

  header {
    > span {
      width: 40px;
      height: 40px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px;
      background: ${(props) =>
        props.camera.online == 0 ? 'var(--red-v-02)' : 'var(--primary-v-02)'};
      color: ${(props) =>
        props.camera.online == 0 ? 'var(--red-v-01)' : 'var(--primary-base)'};

      border-radius: 4px;
    }
    h2 {
      margin: 0;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      /* identical to box height, or 18px */
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: var(--neutral-00);
    }
    display: flex;
    align-items: center;
    padding: 24px;
    box-shadow: inset 0px -1px 0px var(--neutral-10);
    gap: 1rem;
    h4 {
      margin-bottom: 0.5rem;
      color: var(--neutral-02);
      font-weight: normal;
    }
  }

  section.event-list {
    width: 100%;
    min-height: 60%;
    max-height: 60%;
    overflow-y: auto;
    margin-bottom: 8px;
  }

  section.usage-chart {
    width: 100%;
    flex: 1;
  }
`;
const Sidebar = (props) => {
  const { selectedFlatRows } = useHeadlessTable();
  const [camera, setCamera] = React.useState();

  const { data: disks, isLoading } = useQueryWrapper(
    ['camera', 'disks', camera?.id],
    () => CameraAction.listDisks(camera.id),
    {
      enabled: Boolean(camera) && Boolean(camera?.online == 1),
      staleTime: 120000
    }
  );

  let isEdge = disks?.find((i) => i.diskid == 'SD_DISK').status == 'OK';

  React.useEffect(
    () => setCamera(selectedFlatRows?.[0]?.original || props.data),
    [selectedFlatRows?.[0]?.original, props.data]
  );

  if (!camera) return <SidebarEmptyHolder />;
  return (
    <SidebarWrapper camera={camera}>
      <header>
        <span>
          <CameraVideo />
        </span>
        <h2>{camera.name}</h2>
        {camera.online == 1 && !isLoading && (
          <span
            title={isEdge ? 'SD CARD OK' : 'SD-CARD Missing'}
            css={css`
              color: ${!isEdge
                ? 'var(--red-v-01) !important'
                : 'var(--primary-base) !important'};
              background: ${!isEdge
                ? 'var(--red-v-02) !important'
                : 'var(--primary-v-02) !important'};
              position: absolute;
              right: 0;
              top: 0;
              margin: 16px;
            `}
          >
            {isEdge ? (
              <IconDeviceSdCard />
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-device-sd-card"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M7 21h10a2 2 0 0 0 2 -2v-14a2 2 0 0 0 -2 -2h-6.172a2 2 0 0 0 -1.414 .586l-3.828 3.828a2 2 0 0 0 -.586 1.414v10.172a2 2 0 0 0 2 2z"></path>
                <path d="M13 6v2"></path>
                <path d="M16 6v2"></path>
                <path d="M10 7v1"></path>

                <line
                  x1="0"
                  y1="0"
                  x2="24"
                  y2="24"
                  stroke="currentColor"
                  stroke-width="2"
                />
              </svg>
            )}
          </span>
        )}
      </header>
      <DeviceAlerts device={camera} />
      <CameraTabs camera={camera} />
    </SidebarWrapper>
  );
};

export default withSidebar({ width: '450px' })(Sidebar, Stats);
