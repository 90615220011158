import React from "react";
import ReactECharts from "echarts-for-react";
import * as echarts from "echarts";
import { useQueryWrapper } from "utils/ajax";
import Event from "api/event";
import { css } from "@emotion/react";
import { date } from "yup";
import subDays from "date-fns/subDays";
import addDays from "date-fns/addDays";
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";
import "css/usage-chart.css";
import InfiniteTableProvider from "providers/headless-infinite-table-provider";
import { useHeadlessTable } from "providers/headless-table-provider";
import BarChartLoader from "components/chart-loader";
import { GraphUp } from "react-bootstrap-icons";
import { useLocalStorage } from "hooks/local-storage";
import GenericDoor from "api/generic_door";
import Person from "api/person";

/** @jsxImportSource @emotion/react */

const LOADING_OPTIONS = {
  text: "Fetching Data...",
  color: "#c23531",
  textColor: "gray",
  maskColor: "transparent",
  zlevel: 0,

  // Font size. Available since `v4.8.0`.
  fontSize: "35px",
  // Show an animated "spinner" or not. Available since `v4.8.0`.
  showSpinner: false,
  // Radius of the "spinner". Available since `v4.8.0`.
  spinnerRadius: 10,
  // Line width of the "spinner". Available since `v4.8.0`.
  lineWidth: 5,
  // Font thick weight. Available since `v5.0.1`.
  fontWeight: "normal",
  // Font style. Available since `v5.0.1`.
  fontStyle: "normal",
  // Font family. Available since `v5.0.1`.
  fontFamily: "inherit",
};

const PersonTrendChart = ({ filters = {}, person_id }) => {
  const [chartData, setChartData] = React.useState([]);

  const option = {
    tooltip: {
      axisPointer: {
        type: "cross",
        axis: "x",
        label: {
          show: false,
          backgroundColor: "#6a7985",
        },
      },

      trigger: "item",
      backgroundColor: "var(--neutral-09)",
      textStyle: {
        color: "var(--neutral-00)",
      },

      formatter: function (params) {
        if (params.data)
          return `<div class= "chart-tooltip events-trend-tooltip"><time>${
            params.data.date
          }</time>
                <span>
                <svg width="10" height="10" fill='#f59728'>
                <circle cx="5" cy="4" r="4"/>   
              </svg>
              Access Not Taken: ${params.data.accessNotTaken || 0}</span>
              </span>
                <span>
                 <svg width="10" height="10" fill='#ea3737'>
      <circle cx="5" cy="4" r="4"/>
    </svg>
    Denied: ${params.data.accessDenied || 0}</span>
    <span>
    <svg width="10" height="10" fill='#00a96c'>
<circle cx="5" cy="4" r="4"/>
</svg>
Granted: ${params.data.accessGranted || 0}</span>
                </div>`;
        else return null;
      },
    },

    legend: {
      show: chartData.length > 0,
      top: "1%",

      data: [
        { name: "Access Not Taken", icon: "rect" },

        { name: "Access Denied", icon: "rect" },
        { name: "Access Granted", icon: "circle" },
      ],
    },

    dataset: {
      source: chartData,
      dimensions: ["date", "accessNotTaken", "accessDenied", "accessGranted"],
    },

    grid: {
      left: "2%",
      right: "2%",
      bottom: "15%",
      top: "20%",
    },

    xAxis: {
      //       max: chartData?.length > 0 ? chartData[chartData.length - 1] : new Date(),
      //       min: chartData?.length > 0 ? chartData[0] : subDays(new Date(), 30),
      type: "time",
      boundaryGap: true,
      axisLine: {
        lineStyle: {
          color: "#D3D3D3",
        },
      },
      axisLabel: {
        show: true,
        interval: 0,
      },
      splitLine: {
        show: true,
        lineStyle: {
          type: "dashed",
        },
      },
      triggerEvent: true,
    },
    yAxis: [
      {
        type: "value",
        show: false,
        splitLine: {
          show: false,
          lineStyle: {
            type: "dashed",
          },
        },
      },
      {
        type: "value",
        show: false,
        splitLine: {
          show: false,
          lineStyle: {
            type: "dashed",
          },
        },
      },
      {
        type: "value",
        show: false,
        splitLine: {
          show: false,
          lineStyle: {
            type: "dashed",
          },
        },
      },
    ],

    series: [
      {
        name: "Access Denied",
        type: "bar",
        roundCap: true,
        barWidth: 8,
        itemStyle: {
          // barBorderRadius: [10, 10, 0, 0],
          // shadowColor: "rgba(0, 0, 0, 0.5)",
          // shadowBlur: 4,
        },
        // showBackground: true,
        backgroundStyle: {
          color: "rgba(180, 180, 180, 0.2)",
        },
        encode: {
          x: "date",
          y: "accessDenied", // refer sensor 1 value
        },
        stack: "1",
        color: "#ea3737",
        lineStyle: {
          width: 15,
        },
        yAxisIndex: 0,
      },
      {
        name: "Access Not Taken",
        type: "bar",
        itemStyle: {
          // barBorderRadius: [10, 10, 0, 0],
          // shadowColor: "rgba(0, 0, 0, 0.5)",
          // shadowBlur: 4,
        },
        barWidth: 10,
        encode: {
          x: "date",
          y: "accessNotTaken", // refer sensor 1 value
        },
        stack: "0",
        color: "#f59728",
        lineStyle: {
          width: 5,
        },
        yAxisIndex: 1,
      },

      {
        animation: false,
        name: "Access Granted",
        type: "line",
        symbolSize: 15,
        symbol: "circle",

        lineStyle: {
          width: 2,
          type: "dotted",
        },

        color: "#00a96c",
        yAxisIndex: 2,
        encode: {
          x: "date",
          y: "accessGranted", // refer sensor 1 value
        },
        // data: chartData
        //   .filter((d) => d.accessGranted > 0)
        //   .map((d) => d.accessGranted && [d.date, d.accessGranted]),
      },
    ],
  };

  const { data, isLoading } = useQueryWrapper(
    ["person", "trend", person_id, { ...filters }],
    () => Person.getTrends(filters, person_id),
    {
      staleTime: 60000,
    }
  );

  React.useEffect(() => {
    if (!data) return;

    const index = {};

    for (const d of data.accessGranted)
      index[d._id] = { date: d._id, accessGranted: d.count };

    for (const d of data.accessDenied) {
      index[d._id] = index[d._id] ?? { date: d._id };
      index[d._id].accessDenied = d.count;
    }
    for (const d of data.accessNotTaken) {
      index[d._id] = index[d._id] ?? { date: d._id };
      index[d._id].accessNotTaken = d.count;
    }

    const final = Object.values(index);

    final.sort((a, b) => new Date(a.date) - new Date(b.date));

    setChartData(final);
  }, [data]);

  const onEvents = {
    //     click: (e) => {
    //       if (e.targetType == "axisLabel" && e.componentType == "xAxis")
    //         setGlobalFilter((prev) => ({
    //           ...prev,
    //           to: addDays(new Date(e.value.date), 1).toISOString(),
    //           from: subDays(new Date(e.value.date), 1).toISOString(),
    //         }));
    //       if (e.componentType == "series")
    //         setGlobalFilter((prev) => ({
    //           ...prev,
    //           to: addDays(new Date(e.value.date), 1).toISOString(),
    //           from: subDays(new Date(e.value.date), 1).toISOString(),
    //         }));
    //     },
  };

  // data-show={tableState.chart ? "true" : "false"}

  return (
    <article
      className="person-trend-chart"
      css={css`
        height: 100%;
        &[data-show="false"] {
          height: 0px !important;
          transition: height 300ms ease-out;

          opacity: 1;
          .chart-loader {
            display: none;
          }
        }

        &[data-show="true"] {
          min-height: 220px !important;
          transition: height 300ms ease-in;
          opacity: 1;
        }
        position: relative;

        background: var(--neutral-09);
      `}
    >
      {chartData.length == 0 && (
        <div
          css={css`
            position: absolute;
            top: 0;
            color: var(--neutral-03);
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: var(--neutral-09);
          `}
        >
          {isLoading ? <BarChartLoader /> : "No Data Found"}
        </div>
      )}

      {chartData.length > 0 && true && (
        <ReactECharts
          opts={{ renderer: "svg" }}
          option={option}
          style={{ height: "100%" }}
          loadingOption={LOADING_OPTIONS}
          onEvents={onEvents}
        />
      )}
    </article>
  );
};

export default PersonTrendChart;
