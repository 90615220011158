import { dataFetchQuery } from 'utils/ajax';
import { API_HOST_URL } from './api-url';
const API_URL = API_HOST_URL + '/camera_action_api';

const CameraAction = {
  getRTSPSessionToken(id) {
    return dataFetchQuery(`${API_URL}/getRTSPSessionToken/` + id);
  },
  listEdgeRecordings(options) {
    return dataFetchQuery(
      `${API_URL}/listEdgeRecordings/` + options.id,
      options
    );
  },
  edgeRecordingProperties(cameraId, options = {}) {
    return dataFetchQuery(
      `${API_URL}/edgeRecordingProperties/` + cameraId,
      options
    );
  },
  deleteEdgeRecording(cameraId, recordingId) {
    return dataFetchQuery(
      `${API_URL}/deleteEdgeRecording/` + cameraId + '/' + recordingId
    );
  },
  isCurrentlyRecording(cameraId) {
    return dataFetchQuery(`${API_URL}/isCurrentlyRecording/` + cameraId);
  },

  listDisks(cameraId) {
    return dataFetchQuery(`${API_URL}/listDisks/` + cameraId);
  },
  getAppInfo(cameraId) {
    return dataFetchQuery(`${API_URL}/getAppInfo/` + cameraId);
  },
  toggleCameraApp(cameraId, input = {}) {
    return dataFetchQuery(`${API_URL}/toggleCameraApp/` + cameraId, input);
  },

  autoFocus(cameraId) {
    return dataFetchQuery(`${API_URL}/autoFocus/` + cameraId);
  },

  toggleOverlay(cameraId) {
    return dataFetchQuery(`${API_URL}/toggleOverlay/` + cameraId);
  }
};

export default CameraAction;
