import React from 'react';
import Template from 'base/template';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import IndexContent from 'base/index-content';

import { useParams } from 'react-router-dom';
import { useQueryWrapper } from 'utils/ajax';
import { useNotFound } from 'hooks/not-found-hook';
import useAsyncSelection from 'components/headless-select/use-async-selection';

import CredentialAccessLevel from 'api/credential_access_level';
import { getSelectionColumn } from 'components/table-selection-column';
import { Dialog, DialogTrigger, DialogContent } from 'components/dialog.js';
import { Button, CancelButton } from 'components/buttons';
import AccessLevel from 'api/access_level';
import Select from 'components/headless-select';
import { DialogCancel } from 'components/alert-dialog';
import { IconX } from '@tabler/icons-react';
import DepartmentAccessLevel from 'api/department_access_level';
import AccessLevelActionMenu from './access-level-action-menu';
import { useAddAccessLevelsMutate } from 'views/departments/actions';
import { Badge } from 'components/badge';
import { AuthProfileFormatter } from 'views/access-levels/table-columns';
import Label from 'components/label';
import { components } from 'react-select';
import Department from 'api/department';
import Alert from 'components/alert';
import { Link } from 'react-router-dom';

const columns = [
  getSelectionColumn(),
  {
    Header: 'Access Level',
    accessor: 'access_level_name',
    newSorter: true,
    disableSortBy: true,
    Cell: ({ row: { original }, cell: { value } }) => {
      return (
        <Link to={`/access-levels/${original.access_level_id}/manage-doors`}>
          <strong>{value}</strong>
        </Link>
      );
    }
  },
  {
    Header: 'Status',
    accessor: 'enabled',
    style: { width: '200px' },
    disableSortBy: true,
    Cell: ({ row: { original } }) => {
      if (original.enabled == 0) return <Badge color="danger">Locked</Badge>;
      else if (
        !(
          new Date() < new Date(original.access_level_valid_to) &&
          new Date() > new Date(original.access_level_valid_from)
        )
      )
        return <Badge color="danger">Not Active</Badge>;
      else if (original.access_level_enabled)
        return <Badge color="success">Active</Badge>;
    }
  },

  {
    Header: 'Authentication',
    accessor: 'access_level_auth_profile_token',
    disableSortBy: true,
    style: { width: '50px' },
    Cell: AuthProfileFormatter
  }
];

const cards = [
  {
    name: 'total',
    label: 'Total'
  }
];

const AddAccessLevel = ({
  dialogOpenProp,
  setDialogOpenProp,
  department,
  ...otherProps
}) => {
  const [als, setAls] = React.useState([]);
  const addAccessLevelsMutation = useAddAccessLevelsMutate(department);

  //const addPeopleMutation = useAddPeopleMutate(department);
  const [options, setOptions] = React.useState([]);

  const selectRef = React.useRef();

  const handleRemoveValue = React.useCallback(
    (id) => {
      const removedValue = als.find((val) => val.id === id);
      if (!removedValue) return;
      selectRef.current.onChange(
        als.filter((val) => val.id !== id),
        { id, action: 'remove-value', removedValue }
      );
    },
    [als]
  );

  const { input, isLoading, setInput, data } = useAsyncSelection({
    ...otherProps,
    fetchFn: AccessLevel.search,
    fetchFilters: { exceptDepartment: department.id },
    queryKey: `department-als-${department.id}`
  });

  React.useEffect(() => {
    if (data) setOptions(data?.data);
  }, [data]);

  return (
    <Dialog open={dialogOpenProp} onOpenChange={setDialogOpenProp}>
      <DialogTrigger asChild>
        <Button compact>Add Access Level</Button>
      </DialogTrigger>

      <DialogContent title="Add Access Level">
        <div
          css={css`
            display: flex;
            flex-direction: column;
            height: 100%;

            > section {
              height: 100%;

              flex: 1;
              display: grid;
              grid-template-columns: 1fr 1fr;
              overflow: hidden;
            }
            > footer {
              display: flex;
              width: 100%;
              align-items: center;
              justify-content: space-between;
              margin-top: 8px;
            }
          `}
        >
          <section>
            <Select
              data-compact
              ref={selectRef}
              isMulti={true}
              title=""
              data-inline
              inputValue={input ?? ''}
              setInput={setInput}
              itemComponents={{ DropdownIndicator: null }}
              isClearable={true}
              options={options}
              //filterOption={null}
              closeMenuOnSelect={false}
              menuIsOpen
              // isDisabled={isLoading}

              onChange={(e) => setAls(e)}
              //isOptionDisabled={() => als.length >= 10}
              hideSelectedOptions={true}
              controlShouldRenderValue={false}
              isLoading={isLoading}
              value={als}
              onInputChange={(e) => {
                setInput(e);
              }}
            />
            <div
              css={css`
                background: var(--neutral-08);
                overflow: hidden;
                display: flex;
                flex-direction: column;
                flex: 1;
              `}
            >
              <div
                css={css`
                  margin: 1rem;
                  overflow: auto;
                  height: 100%;
                  padding-left: 1rem;
                  padding-right: 1rem;
                  display: flex;
                  flex-direction: column;
                  gap: 0.5rem;
                  flex: 1;
                `}
              >
                <label
                  css={css`
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 125%;
                  `}
                >
                  Selected Access Levels
                </label>
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                  `}
                >
                  {als.map((val, idx) => (
                    <div
                      key={idx}
                      css={css`
                        display: grid;
                        grid-template-columns: 1fr auto;
                        align-items: center;
                        gap: 2rem;
                      `}
                    >
                      {val.name}
                      <IconX onClick={() => handleRemoveValue(val.id)} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
          <footer>
            <Button
              compact
              loading={addAccessLevelsMutation.isLoading}
              disabled={als.length == 0 || addAccessLevelsMutation.isLoading}
              onClick={() =>
                addAccessLevelsMutation.mutate(
                  als.map((p) => p.id),
                  {
                    onSuccess: () => {
                      setOptions((options) =>
                        options.filter(
                          (opt) => !als.map((p) => p.id).includes(opt.id)
                        )
                      );
                      setAls([]);
                    }
                  }
                )
              }
            >
              Add {als.length ? als.length : ''} Access Levels
            </Button>
            <CancelButton compact onClick={() => setDialogOpenProp(false)}>
              Close
            </CancelButton>
          </footer>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const DepartmentManageAccessLevels = (props) => {
  const { token } = useParams();

  const [dep, setDep] = React.useState(props?.location?.state?.department);

  const query = useQueryWrapper(['department', 'get', token], () =>
    Department.get(token)
  );

  useNotFound(token, query, props);

  React.useEffect(() => {
    if (!query?.data) return;
    setDep(query?.data);
  }, [query?.data]);

  const [dialogOpenProp, setDialogOpenProp] = React.useState(
    props?.location?.state?.dialogOpen ?? false
  );

  return (
    <Template {...props}>
      {dep && (
        <IndexContent
          alert={() => (
            <Alert>
              <div
                css={css`
                  word-wrap: auto;
                  width: fit-content;
                `}
              >
                <h4>Access Level Association</h4>
                Access Levels assigned will be added to any person's credentials
                associated with this department
              </div>
            </Alert>
          )}
          newSorter
          department={dep}
          getRowId={(row, relativeIndex, parent) =>
            row.department_id + '_' + relativeIndex
          }
          setDialogOpenProp={setDialogOpenProp}
          dialogOpenProp={dialogOpenProp}
          pageTitle={dep.Name}
          initialFilter={{
            department: dep.id
          }}
          id={`department-al-${dep.id}`}
          statsQuery={DepartmentAccessLevel.stats}
          cards={cards}
          query={DepartmentAccessLevel.search}
          columns={columns}
          isSidebarOpen={true}
          dialogOpen={true}
          emptyMessage="No Access Levels found"
          actionButton={AccessLevelActionMenu}
          //filterOptions={FILTERS}
          getRowProps={(row) =>
            (row.disableTableSelection = row.original.type == 'dep')
          }
          multi
          selectable={true}
          buttons={AddAccessLevel}
          {...props}
        ></IndexContent>
      )}
    </Template>
  );
};

export default DepartmentManageAccessLevels;
