import React from 'react';
import Template from 'base/template';
import { Button, CancelButton, SecondaryButton } from 'components/buttons';
import FormProvider from 'providers/form-provider';
import { Redirect } from 'react-router-dom';
import { useMutationWrapper } from 'utils/ajax';
import { useRedirect } from 'hooks/redirect-hook';
import CameraForm from 'views/cameras/form';
import Camera from 'api/camera';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PageTitle from 'components/page-title';
import Content from 'base/content';
import { useQueryWrapper } from 'utils/ajax';
import { useParams } from 'react-router-dom';
/** @jsxImportSource @emotion/react */

import { useNotFound } from 'hooks/not-found-hook';
import Breadcrumbs from 'components/breadcrumbs';

const Save = (props) => {
  const { id } = useParams();
  const [camera, setCamera] = React.useState(props?.location?.state?.camera);

  const query = useQueryWrapper(
    ['camera', 'get', id],
    () => Camera.search({ id }),
    {
      enabled: !camera
    }
  );

  useNotFound(id, query, props);

  React.useEffect(() => {
    if (!query?.data?.data?.[0]) return;
    setCamera(query.data.data?.[0]);
  }, [query?.data?.data?.[0]]);

  const SCHEMA = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required')
  });

  const INITIAL_VALUES = {
    name: camera?.name,
    generic_door_id: camera?.generic_door_id
  };

  let { isLoading, mutate, isSuccess } = useMutationWrapper(
    ['camera', 'save', camera?.id],
    (input) => Camera.save(input, camera?.id),
    {}
  );

  const { redirect, setRedirect } = useRedirect(isSuccess);

  return (
    <Template {...props}>
      {redirect && <Redirect to="/cameras" />}
      <Content {...props}>
        <PageTitle>{camera?.name}</PageTitle>
        <Breadcrumbs crumbs={props.crumbs} />
        {camera && (
          <Formik
            validationSchema={SCHEMA}
            initialValues={INITIAL_VALUES}
            onSubmit={(values) => {
              mutate(values);
            }}
          >
            {({ handleSubmit }) => (
              <FormProvider mutateFunc={handleSubmit} disabled={isLoading}>
                <CameraForm camera={camera} />
                <footer>
                  <Button type="submit">Submit</Button>
                  <CancelButton onClick={() => setRedirect(true)} />
                </footer>
              </FormProvider>
            )}
          </Formik>
        )}
      </Content>
    </Template>
  );
};

export default Save;
