import React from 'react';
import Event from 'api/event';
import { useInfiniteQuery } from 'react-query';

export const useEventList = ({ filters = {} }) => {
  const fetchEvents = ({ pageParam = 1 }) =>
    Event.search({ ...filters, page: pageParam });
  const query = useInfiniteQuery([`eventsSearch`, filters], fetchEvents, {
    getNextPageParam: (lastPage, pages) => {
      return pages.length < lastPage.last_page ? pages.length + 1 : undefined;
    },

    enabled: Boolean(filters)
  });

  return query;
};
