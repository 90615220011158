import React from 'react';

import styled from '@emotion/styled';
import RemoveItemButton from './remove-item-button';
import { WidgetHeader } from './widget-header';
import WidgetGridControls from './widget-grid-controls';
import { WidgetControls } from './widget-controls';
import { TitleCards } from './door-widget';
import ReactECharts from 'echarts-for-react';
import { useAuth } from 'providers/auth';
import { useEventSource } from 'react-use-websocket';
import { useLayout } from 'providers/layout-provider';
import { css } from '@emotion/react';
import Site from 'api/site';
import { useQueryWrapper } from 'utils/ajax';
import SmallBadge from 'components/small-badge';
import { IconMapPin } from '@tabler/icons-react';
/** @jsxImportSource @emotion/react */
const Wrapper = styled('article')`
  box-sizing: border-box;
  /* Auto layout */
  place-self: center;
  display: flex;
  flex-direction: column;

  padding: 0px;
  width: 100%;
  min-width: 350px;
  height: 100%;
  background: #ffffff;
  border: 1px solid #eceff3;
  height: 200px;
  max-height: 200px;
  //max-height: 300px;
  > div {
    flex: 1;
  }
`;
export default ({
  siteId,
  widgetIndex,
  selectedView,
  setSelectedView,
  index,
  isEdit
}) => {
  const { user } = useAuth();
  let { getSocketUrl } = useLayout();

  const EVENTS = {
    accessGranted: { color: '#00a96c', value: 1 },
    accessDenied: { color: '#ea3737', value: 2 },
    manualAccessDoor: { color: '#2480d6', value: 3 },
    motionOn: { color: '#6f49c0', value: 4 },
    licensePlateGranted: { color: '#f5861f', value: 4 },
    licensePlateDenied: { color: '#f5861f', value: 4 },
    licensePlateUnfamiliar: { color: '#f5861f', value: 4 }
  };

  const [events, setEvents] = React.useState([]);
  const [average, setAverage] = React.useState();
  const eventsRef = React.useRef();

  eventsRef.current = events;

  const [isRunning, setIsRunning] = React.useState(true);

  let filter = React.useCallback((data) => {
    if (Object.keys(EVENTS).includes(data?.type) && data.site_id == siteId)
      return true;
    return false;
  }, []);

  React.useEffect(() => {
    //reset all event when swithcing companty
    setEvents([]);
  }, [user]);

  const seriesData = React.useRef([]);

  let DEFAULT_OPTION = {
    tooltip: {
      trigger: 'axis'
    },

    grid: {
      show: false,
      top: 20,
      left: 20,
      right: 20,
      bottom: 50
      //  backgroundColor: '#e3e6ec'
    },
    dataZoom: {
      show: false,
      start: 0,
      end: 100
    },

    xAxis: [
      {
        axisLabel: { interval: 0 },
        axisLine: { show: false },
        triggerEvent: true,
        min: Date.now() - 1000 * 60,
        max: Date.now() + 1000 * 60,
        type: 'time',
        //minorTick: { show: false },

        splitLine: {
          show: true
        }
      }
    ],
    yAxis: [
      {
        min: 0,
        max: 5,
        axisLabel: { show: false },
        axisLine: { show: false },
        splitLine: { show: false }
      }
    ],
    series: [
      {
        symbolSize: 10,
        // data: [
        //   { value: [new Date(), 8.04, 'david'], itemStyle: { color: 'red' } },
        //   { value: [new Date(), 4.04, 'david'], itemStyle: { color: 'green' } }
        // ],
        data: [],
        type: 'scatter'
      }
    ],
    animation: false
  };

  const { lastEvent, readyState } = useEventSource(
    getSocketUrl,
    {
      share: true,
      filter: (e) => filter(JSON.parse(e.data)),
      withCredentials: true,
      retryOnError: true
    },
    Boolean(user && !user.missing2fa && !user.missingMfa)
  );

  React.useEffect(() => {
    if (!lastEvent) return;
    if (!isRunning) return;

    let data = JSON.parse(lastEvent.data);

    setEvents((prev) => [...prev, data].reverse().slice(0, 20));
    //Series Data might get too big... // TODO cut data to save memeory

    seriesData.current.push({
      value: [new Date(), EVENTS[data.type].value || 1, data.type],
      itemStyle: { color: EVENTS[data.type].color || 'red' }
    });
  }, [lastEvent]);

  const [option, setOption] = React.useState(DEFAULT_OPTION);

  React.useEffect(() => {
    if (!isRunning) return;
    const timer = setInterval(() => {
      const opt = { ...option };
      opt.series = [
        {
          data: seriesData.current,
          type: 'scatter'
        }
      ];
      opt.xAxis = [
        {
          axisLabel: {
            interval: 0 // Display all labels
          },
          axisTick: { interval: 0 },
          triggerEvent: true,
          min: Date.now() - 1000 * 120,
          max: Date.now() + 1000 * 5,
          type: 'time',
          minorTick: { show: false },

          splitLine: {
            show: true,
            lineStyle: {
              type: 'solid'
            }
          }
        }
      ];
      // clean lis of events for old events

      const eventsInCurrentMinute = seriesData?.current?.filter((event) => {
        const eventTime = event.value[0].getTime();
        return eventTime > Date.now() - 1000 * 60;
      });

      setAverage(eventsInCurrentMinute.length);

      setOption(opt);
    }, 1000);

    return () => clearInterval(timer);
  }, [isRunning]);

  React.useEffect(() => {
    const cb = () => {
      if (document.visibilityState === 'visible') {
        setIsRunning(true);
      } else if (document.visibilityState === 'hidden') {
        setEvents([]);
        seriesData.current = [];
        setAverage(0);
        setIsRunning(false);
      }
    };

    document.addEventListener('visibilitychange', cb);

    return () => document.removeEventListener('visibilitychange', cb);
  }, []);

  const { data: site, isError } = useQueryWrapper(['site', 'get', siteId], () =>
    Site.get(siteId)
  );

  return (
    <Wrapper>
      <WidgetHeader>
        <TitleCards>
          <div className="name">
            <SmallBadge>
              <IconMapPin />
              {site?.name}
            </SmallBadge>
          </div>
        </TitleCards>
        <WidgetControls>
          <WidgetGridControls
            limitRows
            isEdit={isEdit}
            index={index}
            widgetIndex={widgetIndex}
            selectedView={selectedView}
            setSelectedView={setSelectedView}
          />
          {isEdit && (
            <RemoveItemButton
              onClick={() => {
                let newLayout = [...selectedView.layout];
                newLayout[index].items = newLayout[index]?.items?.filter(
                  (_, i) => i !== widgetIndex
                );
                setSelectedView((prev) => ({
                  ...prev,
                  layout: newLayout
                }));
              }}
            />
          )}
        </WidgetControls>
      </WidgetHeader>
      <div
        css={css`
          pointer-events: none;
          background-color: #f6f6f6;
        `}
      >
        <ReactECharts option={option} style={{ height: '100%' }} />
      </div>
    </Wrapper>
  );
};
