import React from 'react';
import { Card } from './card';
import { IconActivityHeartbeat, IconCheck } from '@tabler/icons-react';
import { Button } from 'components/buttons';
import { css } from '@emotion/react';
import { useMutateTestEventSubscription } from 'views/controllers/actions';
import { useEventSource } from 'react-use-websocket';
import { SSE_HOST_URL } from 'api/api-url';

export default ({ device }) => {
  const mutateTest = useMutateTestEventSubscription(device, {
    noToast: true,
    onMutate: () => {},
    onSuccess: () => {},
    onError: () => {}
  });

  const [events, setEvents] = React.useState(0);

  let filter = React.useCallback((data) => {
    if (
      data.type == 'testSubEvent' &&
      data.controller_id == device.controller_id
    )
      return true;
    return false;
  }, []);

  const { lastEvent } = useEventSource(SSE_HOST_URL + '/events', {
    share: true,
    filter: (e) => filter(JSON.parse(e.data)),
    withCredentials: true,
    retryOnError: true
  });

  React.useEffect(() => {
    if (!lastEvent) return;
    console.log('hhh');

    setEvents((prev) => prev + 1);
  }, [lastEvent]);

  return (
    <Card
      classname="thtoughput"
      css={css`
        .data {
          align-items: center;
          display: flex;
          gap: 0.2rem;
        }
      `}
    >
      <div className="icon">
        <IconActivityHeartbeat />
      </div>
      <div className="content">
        <h2>Throughput</h2>
        <span className="description">
          Test Trhoughtput of controller and basic connection to the cloud.
        </span>
      </div>
      <div className="action">
        <Button
          compact
          disabled={mutateTest.isLoading}
          onClick={() => {
            setEvents(0);
            mutateTest.mutate(device.controller_id);
          }}
          loading={mutateTest.isLoading}
        >
          Test
        </Button>
      </div>
      <div className="data">
        <span>Events Received: {events > 0 ? events : ''}</span>
        <span>{events == 0 && 'waiting...'}</span>
        <span>
          {events > 0 && <IconCheck color="var(--light--primary--500)" />}
        </span>
      </div>
    </Card>
  );
};
