import React from "react";
import Template from "base/template";

import Group from "api/group";
import { columns } from "views/groups/table-columns";
//import Sidebar from "views/doors/sidebar";
import IndexContent from "base/index-content";
import ActionMenu from "views/groups/action-menu";

const cards = [
  {
    name: "total",
    label: "Total",
  },
];

const Groups = (props) => {
  return (
    <Template {...props}>
      <IndexContent
        statsQuery={Group.getStats.bind()}
        title="Groups"
        id="groups"
        cards={cards}
        query={Group.search.bind()}
        columns={columns}
        addButton="Add A Group"
        //sidebar={Sidebar}
        selectable
        actionButton={ActionMenu}
        {...props}
      ></IndexContent>
    </Template>
  );
};

export default Groups;
