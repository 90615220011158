import React from 'react';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import { dateTime, dateTimeDif } from 'utils/time';
import { AvatarCircle } from './avatar';
const LastAccessFormatter = ({ door, snowday = false }) => {
  if (!door || !door.last_access_time) return null;

  const [person, setPerson] = React.useState({});

  React.useEffect(() => {
    if (snowday)
      setPerson({
        name: door.last_snowday_access_person_name,
        avatar: door.last_snowday_access_person_avatar,
        time: door.last_snowday_time,
        door: door.last_access_door_name
      });
    else {
      setPerson({
        name: door.last_access_person_name,
        avatar: door.last_access_person_avatar,
        time: door.last_access_time,
        door: door.last_access_door_name
      });
    }
  }, [door]);

  return (
    <article
      className="last-access-formatter"
      css={css`
        display: grid;
        grid-template-columns: 32px 1fr;
        align-items: center;
        row-gap: 0.3rem;
        column-gap: 0.5rem;

        time {
          color: var(--secondary-font-color);
          font-size: 12px;
          display: flex;
          flex-direction: column;
        }
        .door {
          font-size: 10px;
        }
        > section {
          display: flex;
          gap: 0.1rem;
          flex-direction: column;
        }
      `}
    >
      <AvatarCircle name={person?.name} url={person?.avatar} />
      <section>
        {person?.name && <span className="name">{person?.name}</span>}
        {person?.door && <span className="door">{person?.door}</span>}

        <time className="time">{person?.time && dateTime(person.time)}</time>
      </section>
    </article>
  );
};

export default LastAccessFormatter;
