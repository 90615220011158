import { Dialog, DialogContent, DialogTrigger } from 'components/dialog.js';
import React from 'react';
import { css } from '@emotion/react';
import Camera from 'api/camera';
import GenericDoor from 'api/generic_door';
import * as RadioGroup from '@radix-ui/react-radio-group';
/** @jsxImportSource @emotion/react */
import AsyncSelect from 'components/headless-select/async';
import styled from '@emotion/styled';
import Sensor from 'api/sensor';
import { IconAccessPoint, IconClock, IconDoor } from '@tabler/icons-react';
import SelectWrapper from 'components/headless-select';
import { useAuth } from 'providers/auth';
import { AuxiliaryButton } from 'components/buttons';
import Site from 'api/site';

const Container = styled('section')`
  display: flex;
  padding: 0.2rem;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .item-selector {
    height: 100%;
    overflow: hidden;
  }
`;

// const DialogContainer = styled('div')`
//   display: grid;
//   grid-template-columns: 1fr 1fr 1fr;
//   gap: 1rem;
// `;

export default ({ children, selectedView, setSelectedView, index }) => {
  const [itemType, setItemType] = React.useState('door');
  const [selectedItem, setSelectedItem] = React.useState();

  const { user } = useAuth();

  const OPTIONS = React.useMemo(() => {
    if (!user) return [];
    let opts = [
      { id: 'liveSiteChart', name: 'Site Live Events Chart' },
      { id: 'door', name: 'Door Widget' },
      { id: 'camera', name: 'Camera Widget' },
      { id: 'cameraCarousel', name: 'Camera Carousel' }
    ];
    if (user?.features?.sensors && user?.role != 'C')
      opts.push({ id: 'sensor', name: 'Sensor Widget' });

    return opts;
  }, [user]);

  const [cameraCarouselIds, setCameraCarouselIds] = React.useState([]);

  React.useEffect(() => {
    if (!selectedItem) return;

    let newLayout = [...selectedView.layout];
    const oldWidgets = newLayout[index]?.items ?? [];
    let items = [...oldWidgets, selectedItem];
    newLayout[index] = {
      ...newLayout[index],
      items
    };
    setSelectedView((prev) => ({
      ...prev,
      layout: newLayout
    }));
  }, [selectedItem]);

  return (
    <Dialog>
      <DialogTrigger
        css={css`
          margin: 1rem;
        `}
      >
        {children}
      </DialogTrigger>
      <DialogContent
        title="Add Widget"
        onCloseAutoFocus={(e) => e.preventDefault()}
        onOpenAutoFocus={(e) => e.preventDefault()}
        css={css`
          width: 30vw;
          height: 80vh;
          h3,
          h1 {
            text-align: left;
          }
        `}
      >
        <Container>
          <h3>Select Widget Type</h3>
          <SelectWrapper
            css={css`
              .select__menu {
                z-index: 11;
              }
            `}
            simple
            data-compact
            defaultValue={{ id: 'door', name: 'Door Widget' }}
            value={OPTIONS.find((o) => o.id == itemType)}
            onChange={(e) => setItemType(e.id)}
            options={OPTIONS}
          />

          {itemType == 'door' && (
            <div className="item-selector">
              <AsyncSelect
                name="doors"
                queryKey="doors"
                noValueContainer
                isClearable={false}
                placeholder="Search Door..."
                data-inline
                fetchFilters={{}}
                checkboxes={false}
                data-compact
                fetchFn={GenericDoor.search}
                itemComponents={{ DropdownIndicator: null }}
                menuIsOpen={true}
                controlShouldRenderValue={false}
                hideSelectedOptions={false}
                closeMenuOnSelect={true}
                value={null}
                isOptionDisabled={(option) =>
                  selectedView.layout[index].items
                    ?.filter((i) => i.type == 'door')
                    .find((i) => i.id == option.id)
                }
                onChange={(e) => {
                  setSelectedItem({ type: 'door', id: e.id });
                }}
              />
            </div>
          )}
          {itemType == 'sensor' && (
            <div className="item-selector">
              <AsyncSelect
                name="sensors"
                queryKey="sensors"
                noValueContainer
                isClearable={false}
                placeholder="Search sensor.."
                data-inline
                fetchFilters={{}}
                checkboxes={false}
                data-compact
                fetchFn={Sensor.search}
                itemComponents={{ DropdownIndicator: null }}
                menuIsOpen={true}
                controlShouldRenderValue={false}
                hideSelectedOptions={false}
                closeMenuOnSelect={true}
                isOptionDisabled={(option) =>
                  selectedView.layout[index].items
                    ?.filter((i) => i.type == 'sensor')
                    .find((i) => i.id == option.id)
                }
                onChange={(e) => {
                  setSelectedItem({ type: 'sensor', id: e.id });
                }}
              />
            </div>
          )}
          {itemType == 'camera' && (
            <div className="item-selector">
              <AsyncSelect
                noValueContainer
                isClearable={false}
                placeholder="Search Camera..."
                name="cameras"
                data-inline
                fetchFilters={{}}
                checkboxes={false}
                data-compact
                fetchFn={Camera.search}
                itemComponents={{ DropdownIndicator: null }}
                menuIsOpen={true}
                controlShouldRenderValue={false}
                hideSelectedOptions={true}
                closeMenuOnSelect={true}
                isMulti={false}
                isOptionDisabled={(option) =>
                  selectedView.layout[index].items
                    ?.filter((i) => i.type == 'camera')
                    .find((i) => i.id == option.id)
                }
                onChange={(e) => {
                  setSelectedItem({ type: 'camera', id: e.id });
                }}
              />
            </div>
          )}

          {itemType == 'cameraCarousel' && (
            <div
              className="item-selector"
              css={css`
                display: flex;
                flex-direction: column;
                gap: 1rem;
              `}
            >
              <AsyncSelect
                noValueContainer
                isClearable={false}
                placeholder="Search Camera..."
                name="cameras"
                data-inline
                fetchFilters={{}}
                data-compact
                fetchFn={Camera.search}
                itemComponents={{ DropdownIndicator: null }}
                menuIsOpen={true}
                controlShouldRenderValue={false}
                closeMenuOnSelect={true}
                isMulti={true}
                onChange={(e) =>
                  setCameraCarouselIds(e ? e.map((i) => i.id) : [])
                }
              />
              <AuxiliaryButton
                compact
                onClick={() =>
                  setSelectedItem({
                    type: 'cameraCarousel',
                    id: cameraCarouselIds
                  })
                }
              >
                Add Widget
              </AuxiliaryButton>
            </div>
          )}
          {itemType == 'liveSiteChart' && (
            <div className="item-selector">
              <AsyncSelect
                noValueContainer
                isClearable={false}
                placeholder="Search Site..."
                name="sites"
                data-inline
                fetchFilters={{}}
                checkboxes={false}
                data-compact
                fetchFn={Site.search}
                itemComponents={{ DropdownIndicator: null }}
                menuIsOpen={true}
                controlShouldRenderValue={false}
                hideSelectedOptions={true}
                closeMenuOnSelect={true}
                isMulti={false}
                isOptionDisabled={(option) =>
                  selectedView.layout[index].items
                    ?.filter((i) => i.type == 'liveSiteChart')
                    .find((i) => i.id == option.id)
                }
                onChange={(e) => {
                  setSelectedItem({ type: 'liveSiteChart', id: e.id });
                }}
              />
            </div>
          )}
        </Container>
      </DialogContent>
    </Dialog>
  );
};
