import React from 'react';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Device from 'api/device';

import { useQueryWrapper } from 'utils/ajax';
//import * as Select from '@radix-ui/react-select';
import Select from 'react-select';
export default ({
  videoRef,
  host,
  onVapix,
  pipeline,
  setControlsOpen,
  resolution = []
}) => {
  if (pipeline?.recording) return null;

  const [res, setRes] = React.useState(
    host.intercom_id ? '320x200' : '800x600'
  );

  return (
    <article className="resolution-control">
      <Select
        unstyled
        css={css`
          .select__control--is-disabled {
            opacity: 50%;
          }
          .select__control {
            pointer-events: all;
            font-size: 14px;
            min-width: 120px;
            height: 100%;
            min-height: 20px;
            background: transparent;
            color: white;
            border: none;
            cursor: pointer;
          }

          .select__value-container {
            overflow: unset !important;
            padding: 0px;
            color: white;
            height: 100%;
          }
          .select__single-value {
            color: white !important;
          }
          .select__menu-list {
            background: rgba(29, 32, 35, 0.8) !important;
          }
          .select__option {
            background: rgba(29, 32, 35, 0.8) !important;
          }
        `}
        onChange={(e) => {
          onVapix('resolution', e.value);
          setRes(e.value);
        }}
        onMenuOpen={(e) => setControlsOpen(true)}
        onMenuClose={(e) => setControlsOpen(false)}
        value={{ value: res, label: res }}
        placeholder="resolution"
        menuPlacement="top"
        menuShouldBlockScroll={true}
        captureMenuScroll={true}
        isSearchable={false}
        classNamePrefix="select"
        components={{ IndicatorsContainer: () => null }}
        styles={{
          control: (base) => ({
            ...base,
            boxShadow: 'none !important'
          }),

          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
            pointerEvents: 'all'
          }),
          menuList: (base) => ({
            ...base,
            // kill the white space on first and last option
            background: 'rgba(29, 32, 35, 0.8) !important',
            color: 'white'
          }),
          option: (base) => ({
            ...base,
            background: 'unset !important',
            fontSize: '12px',

            '&:hover': {
              background: 'rgba(29, 32, 35, 1) !important'
            },
            '&:focus': {
              background: 'rgba(29, 32, 35, 1) !important'
            },

            color: 'white'
          })
        }}
        isDisabled={resolution?.length == 0}
        menuPortalTarget={document.body}
        options={resolution?.map((opt) => ({ value: opt, label: opt }))}
      />
    </article>
  );
};
