import React from 'react';
import Template from 'base/template';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import IndexContent from 'base/index-content';

import { useParams } from 'react-router-dom';
import { useQueryWrapper } from 'utils/ajax';
import { useNotFound } from 'hooks/not-found-hook';
import useAsyncSelection from 'components/headless-select/use-async-selection';

import Credential from 'api/credential';
import CredentialAccessLevel from 'api/credential_access_level';
import { getSelectionColumn } from 'components/table-selection-column';
import { Dialog, DialogTrigger, DialogContent } from 'components/dialog.js';
import { Button, CancelButton } from 'components/buttons';
import AccessLevel from 'api/access_level';
import Select from 'components/headless-select';
import { DialogCancel } from 'components/alert-dialog';
import { IconAlertCircle, IconX } from '@tabler/icons-react';
import AccessLevelActionMenu from './access-level-action-menu';
import { useAddAccessLevelsMutate } from 'views/credentials/actions';
import { Badge } from 'components/badge';
import { AuthProfileFormatter } from 'views/access-levels/table-columns';
import Label from 'components/label';
import * as Tooltip from '@radix-ui/react-tooltip';
import { IconHelpCircle } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import Alert from 'components/alert';
import AccessLevelDoor from 'api/access_level_door';
import { columns as aldColumns } from 'views/access-levels/door-table-columns';
import * as RadioGroup from '@radix-ui/react-radio-group';

export function AssociationHeader() {
  return (
    <Tooltip.Provider>
      <Tooltip.Root>
        <Tooltip.Trigger asChild={true}>
          <span
            css={css`
              display: inline-flex;
              gap: 0.4rem;
              align-items: center;
            `}
          >
            <label>Association</label>
            <IconHelpCircle size={22} />
          </span>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content asChild={true}>
            <div
              css={css`
                z-index: 200;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                padding: 0.5rem;

                background: var(--neutral-09);
                box-shadow: 0px 2px 6px 4px rgba(29, 32, 35, 0.4);
                border-radius: 4px;
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  label {
                    display: flex;
                    gap: 0.5rem;
                    align-items: center;
                  }
                  .square::before {
                    content: '';
                    width: 10px; /* Adjust the width as needed */
                    height: 10px; /* Adjust the height as needed */
                    display: block; /* Make it a block-level element */
                  }
                  .in::before {
                    background: #2480d6;
                  }
                  .above::before {
                    background: #f5861f;
                  }
                `}
              >
                Access levels are associated directly to Credential or via
                Department
              </div>
              <Tooltip.Arrow
                css={css`
                  fill: var(--neutral-09);
                `}
              />
            </div>
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}

const columns = [
  getSelectionColumn(),
  {
    Header: 'Access Level',
    accessor: 'access_level_name',
    newSorter: true,
    disableSortBy: true,
    Cell: ({ row: { original }, cell: { value } }) => {
      return (
        <Link to={`/access-levels/${original.access_level_id}/manage-doors`}>
          <strong>{value}</strong>
        </Link>
      );
    }
  },
  {
    Header: 'Status',
    accessor: 'enabled',
    style: { width: '200px' },
    disableSortBy: true,
    Cell: ({ row: { original } }) => {
      if (original.enabled == 0) return <Badge color="danger">Locked</Badge>;
      else if (
        !(
          new Date() < new Date(original.access_level_valid_to) &&
          new Date() > new Date(original.access_level_valid_from)
        )
      )
        return <Badge color="danger">Not Active</Badge>;
      else if (original.access_level_enabled)
        return <Badge color="success">Active</Badge>;
    }
  },
  {
    Header: AssociationHeader,
    label: 'assocation',
    accessor: 'type',
    disableSortBy: true,
    Cell: ({ cell: { value }, row: { original } }) => {
      return (
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 0.5rem;
          `}
        >
          <Label>{value == 'dep' ? 'department' : 'credential'}</Label>
          {value == 'dep' && <span>{original.department_name}</span>}
        </div>
      );
    }
  },
  {
    Header: 'Authentication',
    accessor: 'access_level_auth_profile_token',
    disableSortBy: true,
    style: { width: '50px' },
    Cell: AuthProfileFormatter
  }
];

const cards = [
  {
    name: 'total',
    label: 'Total'
  },
  {
    name: 'extra_count',
    label: 'Ovelapping',
    icon: IconAlertCircle,
    color: 'var(--red-base)',
    background: 'var(--stats-background-red)',
    visible: (statsData) => statsData?.extra_count
  }
];

const AddAccessLevel = ({
  dialogOpenProp,
  setDialogOpenProp,
  credential,
  ...otherProps
}) => {
  const [als, setAls] = React.useState([]);
  const addAccessLevelsMutation = useAddAccessLevelsMutate(credential);

  //const addPeopleMutation = useAddPeopleMutate(department);
  const [options, setOptions] = React.useState([]);

  const selectRef = React.useRef();

  const handleRemoveValue = React.useCallback(
    (id) => {
      const removedValue = als.find((val) => val.id === id);
      if (!removedValue) return;
      selectRef.current.onChange(
        als.filter((val) => val.id !== id),
        { id, action: 'remove-value', removedValue }
      );
    },
    [als]
  );

  const { input, isLoading, setInput, data } = useAsyncSelection({
    ...otherProps,
    fetchFn: AccessLevel.search,
    fetchFilters: { exceptCredential: credential.token },
    queryKey: `credetial-als-${credential.token}`
  });

  React.useEffect(() => {
    if (data) setOptions(data?.data);
  }, [data]);

  return (
    <Dialog open={dialogOpenProp} onOpenChange={setDialogOpenProp}>
      <DialogTrigger asChild>
        <Button compact>Add Access Level</Button>
      </DialogTrigger>

      <DialogContent title="Add Access Level">
        <div
          css={css`
            display: flex;
            flex-direction: column;
            height: 100%;

            > section {
              height: 100%;

              flex: 1;
              display: grid;
              grid-template-columns: 1fr 1fr;
              overflow: hidden;
            }
            > footer {
              display: flex;
              width: 100%;
              align-items: center;
              justify-content: space-between;
              margin-top: 8px;
            }
          `}
        >
          <section>
            <Select
              data-compact
              ref={selectRef}
              isMulti={true}
              title=""
              data-inline
              inputValue={input ?? ''}
              setInput={setInput}
              itemComponents={{ DropdownIndicator: null }}
              isClearable={true}
              options={options}
              //filterOption={null}
              closeMenuOnSelect={false}
              menuIsOpen
              // isDisabled={isLoading}

              onChange={(e) => setAls(e)}
              //isOptionDisabled={() => als.length >= 10}
              hideSelectedOptions={true}
              controlShouldRenderValue={false}
              isLoading={isLoading}
              value={als}
              onInputChange={(e) => {
                setInput(e);
              }}
            />
            <div
              css={css`
                background: var(--neutral-08);
                overflow: hidden;
                display: flex;
                flex-direction: column;
                flex: 1;
              `}
            >
              <div
                css={css`
                  margin: 1rem;
                  overflow: auto;
                  height: 100%;
                  padding-left: 1rem;
                  padding-right: 1rem;
                  display: flex;
                  flex-direction: column;
                  gap: 0.5rem;
                  flex: 1;
                `}
              >
                <label
                  css={css`
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 125%;
                  `}
                >
                  Selected Access Levels
                </label>
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                  `}
                >
                  {als.map((val, idx) => (
                    <div
                      key={idx}
                      css={css`
                        display: grid;
                        grid-template-columns: 1fr auto;
                        align-items: center;
                        gap: 2rem;
                      `}
                    >
                      {val.name}
                      <IconX onClick={() => handleRemoveValue(val.id)} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
          <footer>
            <Button
              compact
              loading={addAccessLevelsMutation.isLoading}
              disabled={als.length == 0 || addAccessLevelsMutation.isLoading}
              onClick={() =>
                addAccessLevelsMutation.mutate(
                  als.map((p) => p.id),
                  {
                    onSuccess: () => {
                      setOptions((options) =>
                        options.filter(
                          (opt) => !als.map((p) => p.id).includes(opt.id)
                        )
                      );
                      setAls([]);
                    }
                  }
                )
              }
            >
              Add {als.length ? als.length : ''} Access Levels
            </Button>
            <CancelButton compact onClick={() => setDialogOpenProp(false)}>
              Close
            </CancelButton>
          </footer>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const ViewToggle = ({ setView, view }) => {
  const styles = css`
    order: -1;
    height: 36px;
    margin-right: auto;
    [role='radiogroup'] {
      background: var(--neutral-09);
      display: flex;
      align-items: center;
      height: 100%;
    }
    [role='radio'] {
      white-space: pre;
      padding: 0.5rem;
      border-radius: 4px;
      display: flex;
      align-items: center;
      font-weight: 500;

      width: 100%;
      justify-content: center;
      height: 100%;
      &[aria-checked='true'] {
        background-color: var(--light--neutrals--300);
      }
    }
  `;

  return (
    <section css={styles}>
      <RadioGroup.Root value={view} onValueChange={setView}>
        <RadioGroup.Item data-value="als" value="als">
          Access Levels
        </RadioGroup.Item>

        <RadioGroup.Item data-value="doors" value="doors">
          Doors
        </RadioGroup.Item>
      </RadioGroup.Root>
    </section>
  );
};

const CredManageAccessLevels = (props) => {
  const { token } = useParams();

  const [cred, setCred] = React.useState(props?.location?.state?.credential);

  const query = useQueryWrapper(['credential', 'get', token], () =>
    Credential.get(token)
  );

  useNotFound(token, query, props);
  const [dialogOpenProp, setDialogOpenProp] = React.useState(false);

  React.useEffect(() => {
    if (!query?.data) return;

    setCred(query?.data);
    setDialogOpenProp(query.data.CredentialAccessProfile.length == 0);
  }, [query?.data]);

  const [view, setView] = React.useState('als');
  return (
    <Template {...props}>
      {cred &&
        (view == 'als' ? (
          <IndexContent
            key="als"
            alert={({ tableState: { stats } }) => {
              if (stats?.extra_count)
                return (
                  <Alert>
                    <div
                      css={css`
                        line-height: 22px;
                        word-wrap: auto;
                        width: fit-content;
                      `}
                    >
                      Credential includes overlapping access levels.
                    </div>
                  </Alert>
                );
              else return null;
            }}
            newSorter
            credential={cred}
            getRowId={(row, relativeIndex, parent) =>
              row.token + '_' + relativeIndex
            }
            setDialogOpenProp={setDialogOpenProp}
            dialogOpenProp={dialogOpenProp}
            pageTitle={cred.Description}
            initialFilter={{
              credential: cred.token
            }}
            id={`credential-al-${cred.token}`}
            statsQuery={CredentialAccessLevel.stats}
            cards={cards}
            query={CredentialAccessLevel.search}
            columns={columns}
            isSidebarOpen={true}
            dialogOpen={true}
            emptyMessage="No Access Levels found"
            actionButton={AccessLevelActionMenu}
            //filterOptions={FILTERS}
            getRowProps={(row) =>
              (row.disableTableSelection = row.original.type == 'dep')
            }
            multi
            selectable={true}
            buttons={() => (
              <>
                <AddAccessLevel
                  credential={cred}
                  dialogOpenProp={dialogOpenProp}
                  setDialogOpenProp={setDialogOpenProp}
                />
                <ViewToggle setView={setView} view={view} />
              </>
            )}
            {...props}
          ></IndexContent>
        ) : (
          <IndexContent
            key={'doors'}
            alert={({ tableState: { stats } }) => {
              if (stats?.extra_count)
                return (
                  <Alert>
                    <div
                      css={css`
                        word-wrap: auto;
                        width: fit-content;
                        line-height: 22px;
                      `}
                    >
                      <b>Credential includes overlapping Doors. </b>
                      <br />
                      Overlapping doors might have different schedules applied
                      to the same doors and might override access.
                    </div>
                  </Alert>
                );
              else return null;
            }}
            newSorter
            credential={cred}
            getRowId={(row, relativeIndex, parent) =>
              row.token + '_' + relativeIndex
            }
            setDialogOpenProp={setDialogOpenProp}
            dialogOpenProp={dialogOpenProp}
            pageTitle={cred.Description}
            initialFilter={{
              credential: cred.token,
              excludeNoAccess: true
            }}
            id={`credential-doors-${cred.token}`}
            statsQuery={AccessLevelDoor.stats}
            cards={cards}
            query={AccessLevelDoor.search}
            columns={[
              ...aldColumns,
              {
                Header: 'Access Level',
                accessor: 'access_level_name',
                pickerDisabled: true,
                disableSortBy: true
              }
            ]}
            isSidebarOpen={true}
            dialogOpen={true}
            selectable={false}
            emptyMessage="No Doors found"
            //filterOptions={FILTERS}

            buttons={() => (
              <>
                <ViewToggle setView={setView} view={view} />
              </>
            )}
            {...props}
          ></IndexContent>
        ))}
    </Template>
  );
};

export default CredManageAccessLevels;
