import '@maplibre/maplibre-gl-leaflet';
const MAP_TOKEN =
  'pk.eyJ1IjoiY3ViZS1hY2Nlc3MiLCJhIjoiY2s1eXFnbzFxMHZidjNvbmVpNDk5eTR2ZSJ9.eXFkXLbzDuawBEPuxHdcAA';
import * as React from 'react';
import L from 'leaflet';
import { MapContainer, ZoomControl } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import Template from 'base/template';
import SiteCluster from 'views/overview/site-cluster';
import { css } from '@emotion/react';
import SiteLayer from 'views/overview/site-layer';
import SiteNavigator from 'views/overview/site-navigation';
/** @jsxImportSource @emotion/react */
import { useQueryWrapper } from 'utils/ajax';
import Site from 'api/site';
import MapSidePanel from 'views/overview/map-side-panel';
import MapLoader from 'views/overview/map-loader';
import { useAuth } from 'providers/auth';

const Overview = (props) => {
  const { focusedSite } = useAuth();
  let site = props?.location?.state?.site;

  const [selectedSite, setSelectedSite] = React.useState();
  const [sites, setSites] = React.useState();
  const [isEdit, setIsEdit] = React.useState();
  const [searchVisible, setSearchVisible] = React.useState(true);
  const [image, setImage] = React.useState(null);
  const [doors, setDoors] = React.useState();
  const [cameras, setCameras] = React.useState();
  const [auxiliaries, setAuxiliaries] = React.useState();
  const [selectedLayer, setSelectedLayer] = React.useState();
  const [pathOptions, setPathOptions] = React.useState({
    color: '#A8A8A8',
    fillColor: '#C8C8C8',
    fillOpacity: 0.4
  });

  React.useEffect(() => {
    if (site || focusedSite) setSelectedSite({ id: site || focusedSite });
    else setSelectedSite(null);
  }, [site, focusedSite]);

  const [modes, setModes] = React.useState({});

  const selectedLayerRef = React.useRef();

  const { data: siteData } = useQueryWrapper(
    ['site', 'get', site, focusedSite],
    () => Site.get(site || focusedSite),
    { enabled: Boolean(site) || Boolean(focusedSite) }
  );

  React.useEffect(() => {
    // TODO refactor here and do not gget json from main site call for lighter fetching
    if (!siteData) return;
    setSelectedSite(siteData);
  }, [siteData]);

  const { data, isFetching } = useQueryWrapper(
    ['sites', 'getAll'],
    () => Site.getAll(),
    { cacheTime: 0, staleTime: 0 }
  );

  React.useEffect(() => {
    selectedLayerRef.current = selectedLayer;
  }, [selectedLayer]);

  const mapRef = React.useRef();
  const viewLayerRef = React.useRef();
  const editLayerRef = React.useRef();
  const markerLayerRef = React.useRef();

  React.useEffect(() => {
    if (!mapRef.current) return;
  }, [mapRef.current]);

  React.useEffect(() => {
    if (!data) return;

    setSites(data);
  }, [data]);

  const whenMapReady = React.useCallback(() => {
    let fillPalette = ['orange', 'green', 'blue'];

    let gradientString = `<linearGradient id="stripes" x1="0%" y1="0%" x2="100%" y2="100%">
                          <stop offset=0 stop-color=${fillPalette[0]} />
                          <stop offset=33% stop-color=${fillPalette[0]} />
                          <stop offset=33% stop-color=${fillPalette[1]} />
                          <stop offset=66% stop-color=${fillPalette[1]} />
                          <stop offset=66% stop-color=${fillPalette[2]} />
                          <stop offset=100% stop-color=${fillPalette[2]} />
                          </linearGradient>`;

    let patternCube = ` <pattern id="cubes" x="0" y="126" patternUnits="userSpaceOnUse" width="126" height="200" viewBox="0 0 10 16">
                            <g id="cube"><path class="left-shade" d="M0 0l5 3v5l-5 -3z"></path>
                          <path class="right-shade" d="M10 0l-5 3v5l5 -3"></path>
                            </g>
                            <!-- Apply the cube shapes -->
                            <use x="5" y="8" xlink:href="#cube"></use>
                            <use x="-5" y="8" xlink:href="#cube"></use>

                          </pattern>
                          <!-- The canvas for our pattern -->
                          <rect x="0" y="0" width="100%" height="100%" fill="url(#cubes)"></rect>`;

    let patternCheckers = ` <pattern id="checkers" x="0" y="0" width="200" height="200" patternUnits="userSpaceOnUse">
                                <rect class="checker" x="0" width="100" height="100" y="0"></rect>
                                <rect class="checker" x="100" width="100" height="100" y="100"></rect>
                                </pattern>

                                <!-- Define the shape that will contain our pattern as the fill -->
                                <rect x="0" y="0" width="100%" height="100%" fill="url(#checkers)"></rect>`;

    let patternTrees = ` <pattern id="trees" patternUnits="userSpaceOnUse" width="25" height="25">
                        <image xlink:href="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-christmas-tree' width='24' height='24' viewBox='0 0 24 24' stroke-width='1.5' stroke='green' fill='green' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M12 3l4 4l-2 1l4 4l-3 1l4 4h-14l4 -4l-3 -1l4 -4l-2 -1z' /%3E%3Cpath d='M14 17v3a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-3' /%3E%3C/svg%3E"
                          x="0" y="0" width="25" height="25">
                        </image>
                        </pattern> `;

    const mapContainer = document.querySelectorAll('.leaflet-overlay-pane')[0];
    var svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    mapContainer.appendChild(svg);

    let svgDefs = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'defs'
    );

    svgDefs.insertAdjacentHTML('afterbegin', gradientString);
    svgDefs.insertAdjacentHTML('afterbegin', patternCube);
    svgDefs.insertAdjacentHTML('afterbegin', patternCheckers);
    svgDefs.insertAdjacentHTML('afterbegin', patternTrees);
    svg.appendChild(svgDefs);
  }, []);

  const [baseLayers, setBaseLayers] = React.useState([]);

  return (
    <Template {...props}>
      <main
        className="map-container"
        css={css`
          display: grid;
          grid-template-columns: 1fr auto;
          #main-map {
            &[data-edit='true'] {
              border: 6px solid rgba(36, 128, 214, 0.1);
            }
          }
        `}
      >
        <div
          css={css`
            position: relative;
            width: 100%;
            height: 100%;
            display: grid;
            grid-template-rows: 48px 1fr;
          `}
        >
          {/* <SiteStats
                setSelectedSite={setSelectedSite}
                selectedSite={selectedSite}
              /> */}

          <SiteNavigator
            sites={sites}
            selectedSite={selectedSite}
            setSelectedSite={setSelectedSite}
            setIsEdit={setIsEdit}
            isEdit={isEdit}
          />
          <section id="main-map" data-edit={isEdit ? true : false}>
            <MapContainer
              doubleClickZoom={false}
              whenReady={whenMapReady}
              center={[38.912753, -77.032194]}
              whenCreated={(e) => {
                mapRef.current = e;

                const gl = L.maplibreGL({
                  accessToken: MAP_TOKEN,
                  //https://api.maptiler.com/maps/topo/style.json?key=
                  style: 'mapbox://styles/mapbox/light-v11',

                  maxZoom: 28,
                  maxNativeZoom: 28
                });

                gl.addTo(e);
                setBaseLayers([gl]);

                //workaround to not remove markers

                e.on('pm:drawstart', () => {
                  setSearchVisible(false);
                });
                e.on('pm:drawend', () => {
                  setSearchVisible(true);
                });

                e.on('pm:globaleditmodetoggled', () => {
                  if (e.enabled) {
                    var markerLayers = e.pm
                      .getGeomanLayers()
                      .filter(
                        (layer) =>
                          layer instanceof L.Marker ||
                          layer instanceof L.CircleMarker
                      );
                    markerLayers.forEach((layer) => {
                      layer.off(
                        'contextmenu',
                        layer.pm._removeMarker,
                        layer.pm
                      );
                    });
                  }
                });
                // reet selected layer when clicking on non layer
                {
                  /* e.on('click', function (event) {
                  console.log('clicing on main map');
                  if (event.originalEvent?.target?.nodeName !== 'path') {
                    if (selectedLayerRef.current) {
                      selectedLayerRef.current.pm.disable();
                      setSelectedLayer(null);
                    }
                  }
                }); */
                }

                //prohibit marker rmoval

                {
                  /* var removeFnc = e.pm.removeLayer;
              e.pm.removeLayer = (event) => {
                const layer = event.target;
                const removeable =
                  !(layer instanceof L.Marker) &&
                  e.pm._isRelevantForRemoval(layer) &&
                  !layer.pm.dragging();

                if (removeable) removeFnc.call(e.pm, event);
              }; */
                }

                // add differet rooms
                {
                  {
                    /* const _actions = [
                  {
                    text: 'Custom message, with click event',
                    onClick(e) {
                      alert('click');
                    },
                    name: 'actionName'
                  }
                ]; */
                  }
                  e.pm.Toolbar.copyDrawControl('Polygon', {
                    name: 'OutdoorArea',
                    className: 'tree-pattern',
                    block: 'custom',
                    title: 'Outdoor space'
                  });
                }

                var maplibreMap = gl.getMaplibreMap();

                maplibreMap.on('load', () => {
                  // let's add some layer and fire events on it
                  maplibreMap.setPaintProperty('building', 'fill-color', [
                    'interpolate',
                    ['exponential', 0.5],
                    ['zoom'],
                    15,
                    '#e2714b',
                    22,
                    '#eee695'
                  ]);
                });
              }}
              //scrollWheelZoom={false}
              maxBoundsViscosity={1.0}
              zoom={3}
              maxZoom={28}
              zoomControl={false}
            >
              {/* <TileLayer
                  //maxZoom={14}
                  //noWrap={true}
                  attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
                /> */}
              {selectedSite ? (
                <SiteLayer
                  setBaseLayers={setBaseLayers}
                  baseLayers={baseLayers}
                  modes={modes}
                  setModes={setModes}
                  setSelectedLayer={setSelectedLayer}
                  selectedLayer={selectedLayer}
                  door={doors}
                  setDoors={setDoors}
                  cameras={cameras}
                  setCameras={setCameras}
                  auxiliaries={auxiliaries}
                  setAuxiliaries={setAuxiliaries}
                  sites={sites}
                  selectedSite={selectedSite}
                  isEdit={isEdit}
                  setIsEdit={setIsEdit}
                  viewLayerRef={viewLayerRef}
                  editLayerRef={editLayerRef}
                  markerLayerRef={markerLayerRef}
                  searchVisible={searchVisible}
                  image={image}
                  setImage={setImage}
                  pathOptions={pathOptions}
                  setPathOptions={setPathOptions}
                />
              ) : (
                <SiteCluster
                  sites={sites}
                  selectedSite={selectedSite}
                  setSelectedSite={setSelectedSite}
                />
              )}
              <ZoomControl position="bottomleft" />
              {isFetching && <MapLoader />}
            </MapContainer>
          </section>
        </div>
        {isEdit && (
          <MapSidePanel
            modes={modes}
            setModes={setModes}
            pathOptions={pathOptions}
            setPathOptions={setPathOptions}
            doors={doors}
            cameras={cameras}
            auxiliaries={auxiliaries}
            setImage={setImage}
            image={image}
            setIsEdit={setIsEdit}
            selectedSite={selectedSite}
            mapRef={mapRef}
            showToggleButton={false}
            isSidebarOpen={isEdit ? true : false}
            viewLayerRef={viewLayerRef}
            editLayerRef={editLayerRef}
            isEdit={isEdit}
            selectedLayer={selectedLayer}
            setSelectedLayer={setSelectedLayer}
          />
        )}
      </main>
    </Template>
  );
};

export default Overview;
