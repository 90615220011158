import { useMutationWrapper } from "utils/ajax";
import Group from "api/group";
import { useHeadlessTable } from "providers/headless-table-provider";
import { useQueryClient } from "react-query";

export const useDeleteMutate = (group) => {
  const queryClient = useQueryClient();
  const { queryKey } = useHeadlessTable();
  return useMutationWrapper(
    ["group", "delete"],
    (id) => Group.delete(id),

    {
      successMeisage: `Group: ${group?.name} deleted successfuly`,
      onMutate: (id) => {
        let data = queryClient.getQueryData(queryKey);

        const newData = {
          ...data,
          data: data.data.filter((row) => row.id != id),
        };

        queryClient.setQueryData(queryKey, newData);
      },
      onSuccess: () => {
        //queryClient.invalidateQueries(["groups", "stats"]);
      },
    }
  );
};
