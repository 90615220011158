import React from 'react';
import styled from '@emotion/styled';
import ColumnPicker from './column-picker';
import { css } from '@emotion/react';
import { ArrowRepeat, X } from 'react-bootstrap-icons';
import { useHeadlessTable } from 'providers/headless-table-provider';
import { Toggle } from './form';
import { useQueryClient } from 'react-query';
import { IconButton } from './buttons';

/** @jsxImportSource @emotion/react */
const Section = styled('section')`
  width: 100%;

  color: var(--neutral-00);
  height: 72px;
  max-height: 72px;
  min-height: 72px;
  padding: 14px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  align-items: center;
  background: var(--table-filters-background);
  border-top: 1px solid var(--neutral-12);
`;

const CleanFilters = styled('button')`
  margin-left: auto;
  &:before {
    content: 'CLEAN FILTERS';
    text-align: right;
    color: var(--clean-filter-color);
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    cursor: pointer;
    position: relative;
  }
`;

const ChipWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 8px;
  gap: 8px;

  background: var(--secundary-v-02);

  border: 1px solid var(--neutral-12);
  box-sizing: border-box;
  border-radius: 4px;
  span.key {
    font-size: 12px;
    line-height: 16px;
    color: var(--neutral-01);
    margin-top: 2px;
  }

  span.value {
    font-weight: 500;
    vertical-align: baseline;
    line-height: 16px;
    color: var(--neutral-00);
  }
  button {
    margin-left: auto;
    border-left: 1px solid var(--neutral-12);
  }
`;

const isNullUndefEmptyStr = (obj) =>
  Object.values(obj).every((value) => {
    // 👇️ check for multiple conditions
    if (value === null || value === undefined || value === '') {
      return true;
    }
    return false;
  });

const TableFilters = (props) => {
  const { id, isLoading, tableState, setGlobalFilter, data, refetch } =
    useHeadlessTable();

  const queryFilter = { ...tableState.queryFilter };
  delete queryFilter.sort;

  if (isNullUndefEmptyStr(queryFilter)) return null;

  return (
    <Section className="table-filters">
      {tableState.queryFilter &&
        Object.entries(tableState.queryFilter).map(
          ([key, value]) =>
            value !== undefined &&
            value !== null &&
            key != 'sort' && (
              <ChipWrapper key={key}>
                <span className="key">{key}: </span>
                <span className="value">{String(value)}</span>

                <button
                  onClick={(e) => {
                    let obj = { ...tableState.queryFilter };
                    delete obj[key];
                    setGlobalFilter(obj);
                  }}
                >
                  <X color="var(--clean-filter-color)" size={24} />
                </button>
              </ChipWrapper>
            )
        )}
      {!!(data?.total && data?.total != 0 && !isLoading) && (
        <div
          data-count={data.total}
          css={css`
            font-size: 12px;
            opacity: 70%;
          `}
        >
          {data?.total} results
        </div>
      )}
      {tableState.queryFilter &&
        Object.keys(tableState.queryFilter).length > 0 && (
          <CleanFilters
            onClick={() => {
              setGlobalFilter({});
            }}
          />
        )}
      <div
        css={css`
          z-index: 11;
          display: flex;
          align-items: center;
        `}
      >
        {/* {props.columnPicker !== false && <ColumnPicker />} */}

        {/**   {props.live && <Toggle onClick={(e) => setIsLive(e.target.checked)} />}**/}
        {props.children}
        {/* <IconButton onClick={() => queryClient.resetQueries([id])}>
          <ArrowRepeat size={24} />
        </IconButton> */}
      </div>
    </Section>
  );
};

export default TableFilters;
