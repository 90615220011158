import React from "react";
import { Badge } from "components/badge";
import { css } from "@emotion/react";
/** @jsxImportSource @emotion/react */
const styles = css`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  overflow: hidden;
`;
const CredentialAccessLevels = ({ credential }) => {
  if (!credential || !credential?.access_level_names) return null;

  const als = credential.access_level_names;

  return (
    <section className="access-levels" css={styles} title={als.join(", ")}>
      <span>
        {als.slice(0, 2).join(", ")}
        {als.length > 2 && <Badge>+{als.length - 2}</Badge>}
      </span>
    </section>
  );
};

export default CredentialAccessLevels;
