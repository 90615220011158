import styled from '@emotion/styled';
import React from 'react';
import Breadcrumbs from 'components/breadcrumbs.js';
import PageTitle from 'components/page-title';
import TableStats from 'components/headless-table-stats';
import TableProvider from 'providers/headless-table-provider';
import { AddButton, AuxiliaryButton } from 'components/buttons';
import TableFilter from 'components/headless-table-filter';
import TableFilters from 'components/headless-filters';

import TableEmptyHolder from 'components/table-empty-holder';

import Table from 'components/headless-table';

/** @jsxImportSource @emotion/react **/
import { css } from '@emotion/react';
import { withActionButton } from 'components/with-action-button';
import useKeyboardShortcut from 'hooks/keyboard-shortcut-hook';
import TablePagination from 'components/headless-pagination';

import {
  useDisclosureState,
  Disclosure,
  DisclosureContent
} from 'reakit/Disclosure';
import { Filter, Funnel } from 'react-bootstrap-icons';
import { useLocalStorage } from 'hooks/local-storage';
import IndexEmptyHolder from './index-empty-holder';
import { withChartArea } from 'components/with-chart-area';
import TableHeader from 'components/headless-table-header';

const ContentWrapper = styled('div')`
  grid-area: content;
  display: grid;
  grid-template-columns: 1fr auto;

  main {
    display: flex;
    user-select: none;

    padding: 24px 48px 24px 48px;
    background-color: var(--content-background-color);

    flex-direction: column;
    height: 100%;
    overflow: hidden;

    > header {
      flex-direction: column;
      display: flex;
      align-items: flex-start;
      padding-bottom: 24px;
      gap: 8px;
      .table-filter-container {
        margin-left: auto;
      }
      div.table-search {
        width: 200px;
        padding-bottom: 0;
        transition: width ease 400ms;

        &:focus-within {
          width: 400px;
        }
      }

      > button {
        height: 48px;
      }
    }

    > .table-holder {
      flex: 1;
      overflow: inherit;
    }
  }
`;

const inlineTableStyles = css`
  flex: 1;
  overflow-y: auto;
  position: relative;
`;
const IndexContent = ({ children, ...props }) => {
  const { title, crumbs } = props;

  const Sidebar = props.sidebar;
  const ActionButton = withActionButton(props.actionButton);
  const Filters = props.filters;
  const ChartArea = props.chartArea;
  const ExtraButtons = props.buttons;

  const [isSidebarOpen, setIsSidebarOpen] = React.useState(props.isSidebarOpen);

  return (
    <ContentWrapper id="content">
      <TableProvider {...props}>
        {({ state, data, isLoading, tableState }) => {
          const Alert = props.alert;
          return (
            <>
              <main>
                <header>
                  <PageTitle>{props.pageTitle || title}</PageTitle>
                  <Breadcrumbs crumbs={crumbs} />
                  <section
                    css={css`
                      gap: 1rem;
                      width: 100%;
                    `}
                  >
                    {props.alert && <Alert tableState={tableState} />}
                  </section>
                </header>

                <TableStats {...props} />
                <TableHeader {...props} actionButton={ActionButton} />
                <TablePagination {...props} />
                {props.chartArea && <ChartArea />}
                <TableFilters {...props} />
                <Table selectable {...props}></Table>
                {children}
              </main>
              {props.sidebar && (
                <Sidebar
                  setIsSidebarOpen={setIsSidebarOpen}
                  isSidebarOpen={isSidebarOpen}
                />
              )}
            </>
          );
        }}
      </TableProvider>
    </ContentWrapper>
  );
};

export default IndexContent;
