import React from "react";
import AsyncSelect from "components/form/async-select";
import Controller from "api/controller";
import { withField } from "./with-field";

const ControllerSelect = (props) => {
  return (
    <AsyncSelect
      title="Controllers"
      fetchFn={() => Controller.search()}
      {...props}
    />
  );
};

export default withField(ControllerSelect);
