import { css } from "@emotion/react";
import { dateTime, dateTimeDif } from "utils/time";
import EVENTS_CONSTANTS from "constants/events";
import { Badge } from "components/badge";
/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
const EventIcon = styled("span")`
  width: 32px;
  height: 32px;
  flex: 0 0 32px;
  background: ${(props) => props.background || "var(--secundary-v-02)"};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    color: ${(props) => props.color || "#267DFF"};
    width: 16px;
    height: 16px;
  }
`;
export const columns = [
  {
    Header: "Type",
    accessor: "type",
    pickerDisabled: true,
    Cell: ({ cell: { value } }) => {
      return (
        <article
          css={css`
            display: flex;
            align-items: center;
            gap: 8px;
          `}
        >
          <EventIcon
            color={EVENTS_CONSTANTS.colors[value]}
            background={EVENTS_CONSTANTS.backgroundColors[value]}
          >
            {typeof EVENTS_CONSTANTS.icons[value] == "function" &&
              EVENTS_CONSTANTS.icons[value]()}
          </EventIcon>
          <span>{EVENTS_CONSTANTS.readableNames[value]}</span>
        </article>
      );
    },
  },
  {
    Header: "Time",
    accessor: "createdAt.$date.$numberLong",
    pickerDisabled: true,
    Cell: ({ cell: { value } }) => {
      let td =
        Math.floor(new Date() - new Date(Number(value)).getTime()) / 1000;

      let color = td < 3600 ? "red" : "default";

      return (
        <div
          css={css`
            display: flex;
            flex-direction: row;
            gap: 8px;
            align-items: center;
            span:last-child {
              color: var(--neutral-01);
            }
          `}
        >
          <Badge color={color}>
            {dateTimeDif(new Date(Number(value)), "auto", false)}
          </Badge>
          <span>{dateTime(new Date(Number(value)))}</span>
        </div>
      );
    },
  },
];
