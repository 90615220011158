import React from 'react';
import { useHistory } from 'react-router-dom';
import { useMutateDelete, useMutateRun } from './actions';

import { MenuItem } from 'components/action-menu';
import MenuWrapper from 'components/action-menu/menu-wrapper';
import useAlertDialog from 'providers/alert-dialog-provider';
import IsUserAllowed from 'components/is-user-allowed';
import IsRole from 'components/is-role';

export default function ActionMenu({ data: report = {}, ...otherProps }) {
  const history = useHistory();

  let confirm = useAlertDialog();

  const mutateDelete = useMutateDelete(report);
  const mutateRun = useMutateRun(report);

  const handleEdit = () =>
    history.push('reports/save/' + report?._id, { report });

  const handleDelete = async () => {
    let msg = `Are you sure you want to delete report: \'${report?.description}\' ?`;

    if (report.recurrence != '1')
      msg += '\n\nDeleting this report will cancel all future runs.';
    let ok = await confirm({ description: msg });

    ok && mutateDelete.mutate(report?._id);
  };

  const handleRun = () => mutateRun.mutate(report?._id);

  return (
    <MenuWrapper {...otherProps}>
      <MenuItem
        disabled={report?.recurrence == 1}
        label="Edit"
        onClick={handleEdit}
      />

      <MenuItem label="Delete" onClick={handleDelete} />
      <IsRole only="A">
        <MenuItem label="Run" onClick={handleRun} />
      </IsRole>
    </MenuWrapper>
  );
}
