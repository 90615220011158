import React from 'react';
import { useHistory } from 'react-router-dom';
/** @jsxImportSource @emotion/react */
import { MenuItem, MenuItemDelete, MenuWrapper } from 'components/action-menu';
import { useDeleteMutate } from './actions';
import { Pencil, People } from 'react-bootstrap-icons';
import { useHeadlessTable } from 'providers/headless-table-provider';
import { IconUsersGroup } from '@tabler/icons-react';
import { IconShieldCheckeredFilled } from '@tabler/icons-react';
import IsUserAllowed from 'components/is-user-allowed';
import useAlertDialog from 'providers/alert-dialog-provider';

export default function ActionMenu({ data: department }) {
  const { selectedFlatRows } = useHeadlessTable();
  const history = useHistory();
  const deleteMutate = useDeleteMutate(department);
  let confirm = useAlertDialog();

  let handleEdit = () =>
    history.push('/departments/save/' + department?.id, {
      dep: department,
      desc: department?.Name
    });

  let handleManage = () =>
    history.push(`/departments/${department?.id}/manage-people`, {
      dep: department,
      desc: department?.Name
    });

  const handleDelete = async (e) => {
    if (
      await confirm({
        description: `Are you sure you want to delete Department: ${department['Name']} ?`
      })
    )
      deleteMutate.mutate(department?.id, {});

    e.preventDefault();
    e.stopPropagation();
  };

  const handleAccessLevels = () =>
    history.push(`/departments/${department?.id}/levels`, {
      department,
      desc: department?.Name
    });

  return (
    <MenuWrapper disabled={selectedFlatRows.length == 0}>
      <MenuItem
        disabled={selectedFlatRows.length != 1}
        label="Edit"
        onClick={handleEdit}
      >
        <Pencil />
      </MenuItem>
      <MenuItem onClick={handleManage}>
        <IconUsersGroup />
        Manage People
      </MenuItem>

      <IsUserAllowed condition="alEdit">
        <MenuItem
          disabled={selectedFlatRows.length != 1}
          onClick={handleAccessLevels}
        >
          <IconShieldCheckeredFilled />
          Access Levels...
        </MenuItem>
      </IsUserAllowed>

      <MenuItemDelete onClick={handleDelete} />
    </MenuWrapper>
  );
}
