import React from "react";
import MenuItem from "components/menu-item";
import {
  Building,
  Tools,
  People,
  Cpu,
  HddNetwork,
  CardList,
} from "react-bootstrap-icons";
import { useAuth } from "providers/auth";

function VendorMenu() {
  const { hasAllPermissions } = useAuth();

  return (
    <ul>
      {/* <MenuItem to="/dashboard" title="Dashboard">
        <PieChartFill />
      </MenuItem> */}
      {hasAllPermissions("writeUsers") && (
        <MenuItem to="/accounts" title="Peer Accounts">
          <People />
        </MenuItem>
      )}
      <MenuItem to="/installer" title="Installers">
        <Tools />
      </MenuItem>
      <MenuItem to="/companies" title="Companies">
        <Building />
      </MenuItem>
      <MenuItem to="/devices" title="Devices">
        <HddNetwork />
      </MenuItem>

      <MenuItem to="/audit-logs" title="Audit Logs">
        <CardList />
      </MenuItem>
    </ul>
  );
}

export default VendorMenu;
