import { useMutationWrapper } from 'utils/ajax';
import Site from 'api/site';
import { useQueryClient } from 'react-query';
import { useHeadlessTable } from 'providers/headless-table-provider';

export const useMutateDelete = (site) => {
  const queryClient = useQueryClient();
  const { queryKey } = useHeadlessTable();
  return useMutationWrapper(
    ['site', 'delete', site?.id],
    (id) => Site.delete(id),
    {
      successMessage: `Site: ${site?.name} deleted sucessfuly`,
      onMutate: (id) => {
        let data = queryClient.getQueryData(queryKey);

        const newData = {
          ...data,
          data: data.data.filter((row) => row.id != id)
        };

        queryClient.setQueryData(queryKey, newData);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['sites']);
      }
    }
  );
};

export const useMutateLock = (site) => {
  const queryClient = useQueryClient();
  const { queryKey } = useHeadlessTable();

  return useMutationWrapper(
    ['site', 'release', site?.id],
    (id) => Site.lockDown(id),
    {
      successMessage: `Site: ${site?.name} Locked Down sucessfuly`,
      // dont do optimisitic update since not all doors might work
      // onMutate: (id) => {
      //   let data = queryClient.getQueryData(queryKey);

      //   data.data.forEach((i) => i.id == id && (i.locked = '1'));

      //   queryClient.setQueryData(queryKey, data);
      // },
      onSuccess: () => {
        queryClient.invalidateQueries(['sites']);
      }
    }
  );
};

export const useMutateRelease = (site) => {
  const queryClient = useQueryClient();
  const { queryKey } = useHeadlessTable();
  return useMutationWrapper(
    ['site', 'release', site?.id],
    (id) => Site.lockDownRelease(id),
    {
      successMessage: `Site: ${site?.name} Released sucessfuly`,
      // onMutate: (id) => {
      //   let data = queryClient.getQueryData(queryKey);

      //   data.data.forEach((i) => i.id == id && (i.locked = '0'));

      //   queryClient.setQueryData(queryKey, data);
      // },
      onSuccess: () => {
        queryClient.invalidateQueries(['sites']);
      }
    }
  );
};
