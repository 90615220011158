import React from 'react';
import { useQueryWrapper } from 'utils/ajax';
import Metric from 'api/metric';

import { css } from '@emotion/react';
import ReactECharts from 'echarts-for-react';
import { averageWithoutZero, createEachDayOfInterval } from 'utils/arrays';
/** @jsxImportSource @emotion/react */
import ChartZoom from 'components/chart-zoom';
import ChartType from 'components/chart-type';
export default (props) => {
  let { camera } = props;

  const { data, isLoading } = useQueryWrapper(
    ['camera', camera.id, 'avgConfidence', props.filter],
    () =>
      Metric.count({
        camera_id: camera.id,
        lpr: true,
        from: props.filter?.from,
        to: props.filter?.to,
        groupBy: '$date',
        averageBy: '$confidence'
      })
  );

  const [datesArray, setDatesArray] = React.useState([]);

  const [chartData, setChartData] = React.useState([]);
  const [dataZoom, setDataZoom] = React.useState({
    start: 0,
    end: 100
  });

  React.useEffect(() => {
    setDatesArray(
      createEachDayOfInterval(props?.filter?.from, props?.filter?.to)
    );
    setDataZoom({ start: 0, end: 100 });
  }, [props.filter]);

  const [type, setType] = React.useState('line');

  React.useEffect(() => {
    if (!data) return;

    const d = [];

    for (let i of datesArray) {
      let item = data.find((d) => d._id == i);
      d.push(item ? item.avg.toFixed(2) * 100 : 0);
    }
    setChartData(d);
  }, [data, datesArray]);

  const option = {
    // title: {
    //   text: `Total avg: ${averageWithoutZero(chartData)}%`,
    //   padding: [8, 12, 12, 12],
    //   textStyle: {
    //     fontSize: 12
    //   }
    // },
    dataZoom: [
      {
        type: 'inside',
        xAxisIndex: 0,
        minSpan: 5,
        start: dataZoom.start,
        end: dataZoom.end
      }
    ],
    animation: false,
    grid: {
      left: '5%',
      right: '5%',
      bottom: '20%',
      top: '10%'
    },

    tooltip: {
      trigger: 'axis',
      formatter: '{b0}<br/>{a0}: <b>{c0}%</b>'
    },

    legend: {
      orient: 'horizontal',
      left: 10,
      padding: 5,
      bottom: 0,
      show: false
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: false
      },
      axisLabel: {
        show: false
      },
      axisLine: {
        show: false
        // lineStyle: {
        //   color: '#D3D3D3'
        // }
      }
    },
    xAxis: {
      dataZoom: [
        {
          type: 'inside',
          xAxisIndex: 0,
          minSpan: 5
        }
      ],
      boundaryGap: false,
      axisTick: {
        show: false
      },
      type: 'category',
      data: datesArray,

      axisLabel: {
        fontSize: 11,
        showMaxLabel: true,

        show: true,
        color: 'var(--neutral-01)',
        formatter: (x) => {
          const dateStr = x;
          const parts = dateStr.split('-');
          const month = parseInt(parts[1]);
          const day = parseInt(parts[2]);
          return month + '/' + day;
        }
      },
      axisLine: {
        show: false
      }
    },
    visualMap: [
      {
        show: false,
        type: 'continuous',
        seriesIndex: 0,
        min: 0,
        max: 100,
        inRange: {
          color: ['#ea3737', '#f59728', '#00a96c']
        }
      }
    ],

    series: [
      {
        // markLine: {
        //   show: true,
        //   data: [
        //     {
        //       label: {
        //         show: true,
        //         formatter: (x) => 'average period confidence ' + x.value + '%'
        //       },
        //       name: 'average period confidence',
        //       yAxis: averageWithoutZero(chartData)
        //     }
        //   ],
        //   lineStyle: {
        //     color: '#D3D3D3'
        //   },
        //   label: {
        //     show: true,
        //     position: 'middle',
        //     formatter: '{b}: {c}'
        //   },
        //   symbol: ''
        // },
        name: 'Avg. Plate Confidence',
        emphasis: {
          disabled: true
        },
        data: chartData,
        barWidth: 15,
        symbol: 'none',

        // itemStyle: {
        //   color: function (params) {
        //     // Change color based on value

        //     if (params.data >= 0.75) {
        //       return 'var(--primary-base)';
        //     } else if (params.data >= 0.5) {
        //       return 'var(--orange-base)';
        //     } else {
        //       return 'var(--red-base)';
        //     }
        //   },
        //   emphasis: false
        // },
        lineStyle: {
          width: 5
        },
        type: type
      }
    ],
    ...props.config
  };

  return (
    <section
      css={css`
        height: 100%;
        width: 100%;
        position: relative;
        .controls {
          position: absolute;
          top: 0;
          right: 0;
          z-index: 1000;
          display: flex;
          align-items: center;
        }
      `}
    >
      <div className="controls">
        <ChartZoom setDataZoom={setDataZoom} dataZoom={dataZoom} />
        <ChartType type={type} setType={setType} />
      </div>
      <ReactECharts
        opts={{ renderer: 'svg' }}
        loadingOption={{
          showSpinner: false,
          maskColor: 'var(--neutral-09)',
          fontWeight: 'normal',
          text: 'Fetching Data...',

          fontStyle: 'normal',

          fontFamily: 'inherit'
        }}
        showLoading={isLoading}
        option={option}
        style={{
          width: '100%',
          padding: 0,
          height: '100%',
          background: 'var(--neutral-09)'
        }}
      />
    </section>
  );
};
