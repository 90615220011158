import React from 'react';
import styled from '@emotion/styled';

const Div = styled('div')`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Limiter = ({ children, naturalAspectRatio, ...otherProps }) => {
  const limiterRef = React.useRef(null);

  React.useLayoutEffect(() => {
    if (naturalAspectRatio === undefined || limiterRef.current === null) {
      return;
    }

    const observer = new window.ResizeObserver(([entry]) => {
      const element = entry.target;
      const maxWidth = element.clientHeight * naturalAspectRatio;
      element.style.maxWidth = `${maxWidth}px`;
    });
    observer.observe(limiterRef.current);

    return () => observer.disconnect();
  }, [naturalAspectRatio]);

  return (
    <Div className="limiter" ref={limiterRef} {...otherProps}>
      {children}
    </Div>
  );
};

export default Limiter;
