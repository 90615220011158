import React from "react";
import Select from "components/form/select";
import { createFilter } from "react-select";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import SelectWrapper from "components/headless-select";

const TIME_ZONES = [
  { value: "America/New_York", label: "America/New_York" },
  { value: "America/Chicago", label: "America/Chicago" },
  { value: "America/Los_Angeles", label: "America/Los_Angeles" },
  { value: "America/Phoenix", label: "America/Phoenix" },
  { value: "America/Denver", label: "America/Denver" },
  { value: "Pacific/Honolulu", label: "Pacific/Honolulu" },
];
const TimezoneSelect = (props) => {
  //todo Optimize performace
  return (
    <SelectWrapper
      placeholder="Pick a time zone"
      simple
      idx="value"
      label="label"
      defaultValue={TIME_ZONES.filter(
        (i) => i.value == props.form.values[props.field.name]
      )}
      onChange={(e) => props.form?.setFieldValue(props.field?.name, e.value)}
      title="Time Zone"
      options={TIME_ZONES}
      {...props}
    />
  );
};

export default TimezoneSelect;
