import React from 'react';
import { Card } from './card';
import { useMutateRestore } from '../actions';
import { Button } from 'components/buttons';
import { useEventSource } from 'react-use-websocket';
import { SSE_HOST_URL } from 'api/api-url';
import { currentDTMilli } from 'utils/time';
import { IconReload, IconCheck, IconAlertTriangle } from '@tabler/icons-react';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */

export default ({ device }) => {
  const [events, setEvents] = React.useState([]);

  let filter = React.useCallback((data) => {
    if (data.type == 'restoreProgress' && data.device_id == device.id)
      return true;
    return false;
  }, []);
  const { lastEvent, readyState } = useEventSource(SSE_HOST_URL + '/events', {
    share: true,
    filter: (e) => filter(JSON.parse(e.data)),
    withCredentials: true,
    retryOnError: true
  });

  React.useEffect(() => {
    if (!lastEvent) return;

    let data = JSON.parse(lastEvent.data);
    setEvents((prev) => [...prev, `${currentDTMilli()} : ${data.message}`]);
  }, [lastEvent]);
  const mutateRestore = useMutateRestore(device, {
    loadingMessage: `Restoring Device ${device.name}`,
    successMessage: `Restoring ${device.name} Completed successfully`,
    onMutate: () => {
      setEvents((prev) => [
        ...prev,
        `${currentDTMilli()} : Starting restore process`
      ]);
    },
    onSuccess: () => {
      setEvents((prev) => [
        ...prev,
        `${currentDTMilli()} : Operation Complete`
      ]);
    },
    onError: (err) => {
      setEvents((prev) => [
        ...prev,
        `Error Occured during process: ${err.message}`
      ]);
    }
  });

  return (
    <Card classname="restore">
      <div className="icon">
        <IconReload />
      </div>
      <div className="content">
        <h2>Restore</h2>
        <span className="description">
          Restore your controller. Reload all data from Database and check
          integrity.
        </span>
      </div>
      <div className="action">
        <Button
          disabled={mutateRestore.isLoading}
          onClick={() => {
            setEvents([]);
            mutateRestore.mutate(device.id);
          }}
          compact
        >
          Restore
        </Button>
      </div>

      <div
        className="data"
        css={css`
          display: flex;
          align-items: center;
          gap: 0.5rem;
        `}
      >
        {mutateRestore.isSuccess && (
          <span>
            <IconCheck color="var(--light--primary--500)" />
          </span>
        )}
        {mutateRestore.isError && (
          <span>
            <IconAlertTriangle color="var(  --light--others--red-400-base)" />
          </span>
        )}
        {readyState != 3
          ? events.at(-1)
          : 'Server is offline, no live updates...'}
      </div>
    </Card>
  );
};
