import React from 'react';
import CameraAction from 'api/camera_action';
import { useInfiniteQuery } from 'react-query';
import { useQueryWrapper } from 'utils/ajax';
import CloudRecording from 'api/cloud-recording';

const useCloudListRecording = ({ camera, recordingZoom }) => {
  const [cloudRecordings, setCloudRecordings] = React.useState([]);
  const fetchRecording = ({ pageParam = 1 }) =>
    CloudRecording.getRecordingList({
      page: pageParam,
      cameraId: camera.id
      // starttime: recordingZoom.start,
    });
  const {
    data: pastCloudRecording,
    fetchNextPage,
    hasNextPage
  } = useInfiniteQuery([`cloud`, `recording`, camera?.id], fetchRecording, {
    getNextPageParam: (lastPage, pages) => {
      return pages.length < lastPage.last_page ? pages.length + 1 : undefined;
    },

    refetchOnMount: 'always',
    refetchOnWindowFocus: true,
    staleTime: 2 * (60 * 1000), // unliikely this need to be refetched unless actrually deleted on camera
    cacheTime: 3 * (60 * 1000), //
    enabled: Boolean(recordingZoom)
  });

  React.useEffect(() => {
    if (pastCloudRecording);
    if (hasNextPage) fetchNextPage();
  }, [pastCloudRecording]);

  const { data: contCloudRecording } = useQueryWrapper(
    [`cloud`, `recording`, 'continues', camera?.id],
    () =>
      CloudRecording.getRecordingList({
        cameraId: camera.id,
        from: new Date(Number(new Date().getTime() - 120000)).toISOString()
      }),

    {
      refetchInterval: 30000,
      refetchIntervalInBackground: true
    }
  );

  React.useEffect(() => {
    let arr = [...cloudRecordings];
    pastCloudRecording?.pages.forEach((group) => {
      group?.data?.forEach((rec) => {
        let index = arr.findIndex((r) => r._id == rec._id);
        if (index > -1) arr[index] = rec;
        else arr.push(rec);
      });
    });
    setCloudRecordings(arr);
  }, [pastCloudRecording]);

  React.useEffect(() => {
    if (!contCloudRecording?.data) return;
    if (contCloudRecording?.data?.length == 0) return;
    const arr = [...cloudRecordings];

    contCloudRecording?.data.forEach((cr) => {
      let index = arr.findIndex((rec) => rec._id == cr._id);

      if (index == -1)
        //push only if not there
        arr.push(cr);
      else {
        //if same recording only change stop time
        arr[index] = {
          ...arr[index],

          end: cr.end || null
        };
      }
    });

    setCloudRecordings(arr);
  }, [contCloudRecording]);

  return { pastCloudRecording, contCloudRecording, cloudRecordings };
};

export default useCloudListRecording;
