import * as Tooltip from "@radix-ui/react-tooltip";
import { css } from "@emotion/react";
import { Badge } from "components/badge";
import { Check, ExclamationCircle } from "react-bootstrap-icons";
import { dateTimeLocal } from "utils/time";
import styled from "@emotion/styled";

const Issue = styled.span`
  display: flex;
  justify-content: space-between;
  flex: 1;
  align-items: center;

  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  /* identical to box height, or 12px */
  /* light/neutrals/800 */
  color: #59606c;
  width: 100%;
  padding: 1rem;
  gap: 1rem;
`;

/** @jsxImportSource @emotion/react */
export default ({ device }) => {
  const subTime = device.last_subscribed_at
    ? device.last_subscribed_at.replace(" ", "T") + "Z"
    : false;
  const limit = new Date();
  limit.setHours(limit.getHours() - 2);
  limit.setMinutes(limit.getMinutes() - 30);

  const genralIssues =
    (!subTime ||
    new Date(subTime) < limit) ||
    device.lost_events ||
    device.synced == "0" ||
    !device.online;

  return (
    <Tooltip.Provider>
      <Tooltip.Root>
        <Tooltip.Trigger>
          <Badge color={genralIssues ? "red" : "green"}>
            {genralIssues ? "Issues" : "OK"}
          </Badge>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content asChild={true}>
            <div
              css={css`
                z-index: 200;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 0px;
                isolation: isolate;
                height: 100%;
                position: relative;

                background: var(--neutral-09);
                box-shadow: 0px 2px 6px 4px rgba(29, 32, 35, 0.4);
                border-radius: 4px;
              `}
            >
              <Issue>
                <label>Subscription</label>
                {!subTime || new Date(subTime) < limit ? (
                  <Badge
                    color="red"
                    title={subTime ? dateTimeLocal(subTime) : "Never"}
                  >
                    Stale
                  </Badge>
                ) : (
                  <Badge color="green">
                    <Check size={22} />
                    Ok
                  </Badge>
                )}
              </Issue>
              <Issue>
                Synced
                <Badge color={device.synced == "0" ? "orange" : "green"}>
                  {device.synced == "1" && <Check size={22} />}
                  {device.synced == "0" ? "Unsynced" : "OK"}
                </Badge>
              </Issue>
              <Issue>
                Lost Events
                <Badge color={device.lost_events ? "red" : "green"}>
                  {device.lost_events ? (
                    <ExclamationCircle size={18} />
                  ) : (
                    <Check size={22} />
                  )}
                  {device.lost_events ? device.lost_events : "OK"}
                </Badge>
              </Issue>
              <Issue>
                Status
                <Badge color={!device.online ? "red" : "green"}>
                  {!device.online ? "OFFLINE" : "ONLINE"}
                </Badge>
              </Issue>

              <Tooltip.Arrow
                css={css`
                  fill: var(--neutral-09);
                `}
              />
            </div>
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};
