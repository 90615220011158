import React from 'react';
import Template from 'base/template';

import Event from 'api/event';
import { css } from '@emotion/react';

import { columns } from 'views/events/table-columns';

import Filters from 'views/events/filters';
import Sidebar from 'views/events/sidebar';

import IndexContentInfinite from 'base/index-content-infinite';
import EventsTrendChart from 'components/charts/events-trend-chart';
import { useAuth } from 'providers/auth';
import Camera from 'api/camera';
import Device from 'api/device';
import LicensePlate from 'api/license_plate';
import GenericDoor from 'api/generic_door';
import Department from 'api/department';
import { Option as PersonOption } from 'components/form/person-select';
import { Option as DepartmentOption } from 'components/form/department-select';

import Person from 'api/person';
const contentStyles = css`
  .events-trend-chart {
    height: 220px;
  }
`;

export const FILTERS = [
  {
    open: true,
    id: 'recent',
    label: 'Recent Searches...',
    options: []
  },
  {
    open: true,
    id: 'filterBy',
    label: 'Filter By...',
    options: [
      {
        label: 'Door',
        name: 'Door',
        value: 'door',
        id: 'door',
        fetchFn: GenericDoor.search,
        field: 'door_id'
      },
      {
        label: 'person',
        name: 'Person',
        value: 'person',
        id: 'person',
        fetchFn: Person.search,
        field: 'person_id',
        option: PersonOption,
        slim: true
      },
      {
        label: 'department',
        name: 'Department',
        value: 'department',
        id: 'department',
        fetchFn: Department.search,
        option: DepartmentOption,
        field: 'department_id',
        label: 'Name'
      },
      {
        label: 'device',
        name: 'Device',
        value: 'device',
        id: 'device',
        fetchFn: Device.search,
        field: 'device_id'
      },
      {
        name: 'Camera',
        id: 'camera',
        value: 'camera',
        label: 'Camera',
        fetchFn: Camera.search,
        field: 'camera_id'
      },
      {
        name: 'License Plate',
        id: 'lp',
        label: 'License Plate',
        value: 'text',
        fetchFn: LicensePlate.search,
        field: 'license_plate_id',
        label: 'text'
      }
    ]
  }
];
const OPTIONS = [];

const Events = (props) => {
  const { user, focusedSite } = useAuth();

  return (
    <Template {...props} css={contentStyles}>
      <IndexContentInfinite
        initialFilter={{ site: user.focused_site }}
        reload
        filters={Filters}
        statsQuery={null}
        title="events"
        id="events"
        cards={[]}
        filterOptions={FILTERS}
        query={Event.search}
        columns={columns}
        {...props}
        selectable
        sidebar={Sidebar}
        isSidebarOpen={true}
      >
        {(props) => (
          <EventsTrendChart {...props} filters={{ site: focusedSite }} />
        )}
      </IndexContentInfinite>
    </Template>
  );
};

export default Events;
