import React from 'react';
import NotificationForm from 'views/notifications/form';
import Template from 'base/template';
import { Form, Flex } from 'components/dom';
import {
  AuxiliaryButton,
  Button,
  CancelButton,
  SecondaryButton
} from 'components/buttons';
import { useHistory, Redirect } from 'react-router-dom';
import FormProvider from 'providers/form-provider';
import { useMutationWrapper } from 'utils/ajax';
import { useRedirect } from 'hooks/redirect-hook';
import Notification from 'api/notification';
import ActionForm from 'views/notifications/action-form';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PageTitle from 'components/page-title';
import Content from 'base/content';
import { FormFooter } from 'components/form/form-footer';
import TemplateBuilder from 'views/notifications/template-builder';
import { Disclosure } from '@headlessui/react';
import * as Collapsible from '@radix-ui/react-collapsible';
import { css, keyframes } from '@emotion/react';
import { ArrowDown, CaretDown, ChevronDown } from 'react-bootstrap-icons';
import Breadcrumbs from 'components/breadcrumbs';
/** @jsxImportSource @emotion/react */

const open = keyframes`
   from: { height: 0 },

   to: { height: 'var(--radix-collapsible-content-height)' },

`;

const close = keyframes`
    from: { height: 'var(--radix-collapsible-content-height)' },
    to: { height: 0 }
`;

const Save = (props) => {
  let notification = props?.location?.state?.notification || null;

  let actions = notification ? JSON.parse(notification?.actions) : {};
  let triggers = notification ? JSON.parse(notification?.triggers) : {};
  let events = notification ? JSON.parse(notification?.events) : [];

  const SCHEMA = Yup.object().shape({
    name: Yup.string()
      .min(4, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    time_zone: Yup.string().required('Required').nullable(),
    schedule: Yup.string().required('Required').nullable(),

    events: Yup.array().min('1', 'Must contain 1 event').nullable(),
    actions: Yup.array().min('1', 'Must contain 1 action').nullable(),
    time_zone: Yup.string().required('Required').nullable()
  });

  const INITIAL_VALUES = {
    actions: notification?.actions_json
      ? JSON.parse(notification.actions_json)
      : [],
    sites_data: {},
    events: events,
    io_events: triggers.ioports,
    doors: triggers.doors,
    sensors: triggers.sensors,
    credentials: triggers.credentials,
    devices: triggers.devices,
    name: notification?.name || '',
    site_action: notification?.site_action || [],
    time_zone:
      notification?.time_zone ||
      new Intl.DateTimeFormat(undefined).resolvedOptions().timeZone,

    enabled: notification ? (triggers?.enabled ? 'on' : '') : 'on',
    schedule: notification?.schedule_token || '',
    interval: actions.interval,
    delay: actions.delay,
    template: actions.template ?? ''
  };

  let { isSuccess, data, isLoading, mutate } = useMutationWrapper(
    ['notification', 'save'],
    (input) => Notification.save(input, notification?.id),
    {}
  );

  const { redirect, setRedirect } = useRedirect(isSuccess);

  return (
    <Template {...props}>
      {redirect && <Redirect to="/notifications" />}
      <Content {...props}>
        <PageTitle>
          {notification ? notification.name : 'Notification Add'}
        </PageTitle>
        <Breadcrumbs crumbs={props.crumbs} />

        <Formik
          validationSchema={SCHEMA}
          initialValues={INITIAL_VALUES}
          // validate={() => document.querySelector('.error-message')?.scrollIntoView({
          //   behavior: 'smooth'
          // })}
          onSubmit={(values) => {
            mutate(values);
          }}
        >
          {({ handleSubmit, values }) => (
            <FormProvider
              name="notification"
              mutateFunc={handleSubmit}
              disabled={isLoading}
            >
              <NotificationForm notification={notification} />

              <Collapsible.Root defaultOpen={values.template}>
                <Collapsible.Trigger
                  asChild={true}
                  css={css`
                    cursor: pointer;
                    margin: 1.5rem 0;
                    display: flex;
                    align-items: center;
                    gap: 0.6rem;
                    &[data-state='open'] {
                      svg {
                        transform: rotate(180deg);
                      }
                    }
                  `}
                >
                  <AuxiliaryButton compact>
                    <ChevronDown />
                    Notification message template
                  </AuxiliaryButton>
                </Collapsible.Trigger>
                <Collapsible.Content asChild={true}>
                  <div
                    css={css`
                      margin-top: 1.2rem;
                    `}
                  >
                    <TemplateBuilder />
                  </div>
                </Collapsible.Content>
              </Collapsible.Root>

              <ActionForm notification={notification} />
              <FormFooter>
                <Button type="submit">Submit</Button>
                <CancelButton onClick={() => setRedirect(true)} />
              </FormFooter>
            </FormProvider>
          )}
        </Formik>
      </Content>
    </Template>
  );
};

export default Save;
