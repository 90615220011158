/** @jsxImportSource @emotion/react */
import update from 'immutability-helper';

import React from 'react';
import { usePopoverState, Popover, PopoverDisclosure } from 'reakit/Popover';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Gear } from 'react-bootstrap-icons';
import { AuxiliaryButton, CancelButton } from './buttons';
import { useHeadlessTable } from 'providers/headless-table-provider';
import { DraggableColumn } from './draggable-column';
import IsShowColumn from './table/is-show-column';
import { Dialog, DialogContent, DialogTrigger, DialogClose } from './dialog.js';
import { styles } from './radio-group';
import { color } from 'echarts';

const H1 = styled('h1')`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  margin-top: 20px;
  margin-bottom: 8px;
  //border-bottom: 1px solid var(--neutral-12);
  color: var(--neutral-14);
`;

const ColumnPicker = (props) => {
  const {
    allColumns,
    visibleColumns,
    setLSHiddenColumns,
    setColumnOrder,
    setLSColumnsOrder,
    LStableConfig,
    setLSTableConfig
  } = useHeadlessTable();
  let hasRowNumber = allColumns?.find((col) => col.id == 'rownumber');

  const [colOrder, setColOrder] = React.useState(allColumns);

  const moveCard = React.useCallback((dragIndex, hoverIndex) => {
    setColOrder((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]]
        ]
      })
    );
  }, []);

  const onDragEnd = React.useCallback(() => {
    setColumnOrder(colOrder.map((i) => i.id));
    setLSColumnsOrder(colOrder.map((i) => i.id));
  }, [colOrder]);

  React.useEffect(() => {
    setLSHiddenColumns(
      allColumns.filter((col) => !col.isVisible).map((obj) => obj.id)
    );
  }, [visibleColumns]);

  let rowNumCol = allColumns.find((col) => col.id == 'rownumber');

  if (allColumns.length == 0) return null;
  else
    return (
      <Dialog>
        <DialogTrigger compact asChild>
          <AuxiliaryButton>
            <Gear size={14} />
          </AuxiliaryButton>
        </DialogTrigger>

        <DialogContent
          css={css`
            height: auto;
            max-height: 85vh;
            width: 20vw;
            .dialog-content {
              display: flex;
              flex-direction: column;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              input[type='radio'],
              input[type='checkbox'] {
                accent-color: var(--primary-base);
                width: 20px;
                height: 20px;
                min-height: 20px;
                min-width: 20px;
                margin-right: 8px;
              }
              label {
                line-height: 20px;
                vertical-align: bottom;
              }
            `}
          >
            <div>
              <H1
                css={css`
                  margin-top: 4px;
                  margin-bottom: 16px;
                `}
              >
                Customize Columns
              </H1>

              <ul>
                {colOrder.map(
                  (column, index) =>
                    !column.pickerHide && (
                      <IsShowColumn column={column} key={column.id}>
                        <DraggableColumn
                          onDragEnd={onDragEnd}
                          index={index}
                          id={column.id}
                          text={column.text}
                          column={column}
                          moveCard={moveCard}
                        />
                      </IsShowColumn>
                    )
                )}
              </ul>

              {/* <div>Show Column Numbers</div> */}
            </div>
            {/* <div>
              <H1>Page Size</H1>
              <div
                css={css`
                  > label {
                    margin-right: 1rem;
                  }
                `}
              >
                <input type="radio" name="paglen" id="page10"></input>
                <label htmlFor="page10">10 items</label>
                <input type="radio" name="paglen" id="page30"></input>
                <label htmlFor="page30">30 items</label>
                <input type="radio" name="paglen" id="page50"></input>
                <label htmlFor="page50">50 items</label>
              </div>
            </div> */}
            {hasRowNumber && (
              <>
                <H1>Number Column</H1>
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                  `}
                >
                  <input
                    type="checkbox"
                    name="numcol"
                    id="numcol"
                    {...rowNumCol?.getToggleHiddenProps({ title: undefined })}
                  />
                  <label htmlFor="numcol">
                    <span>Show number of item in each row</span>
                  </label>
                </div>
              </>
            )}
          </div>
          <footer
            css={css`
              margin-top: 20px;
              display: flex;
              justify-content: flex-end;
            `}
          >
            <DialogClose asChild>
              <CancelButton compact>Close</CancelButton>
            </DialogClose>
          </footer>
        </DialogContent>
      </Dialog>
    );
};

export default ColumnPicker;
