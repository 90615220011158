import React from 'react';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import ReactECharts from 'echarts-for-react';
import { off } from 'codemirror';
import { format, addHours, startOfHour } from 'date-fns';

const ActivityChart = ({
  activity = {},
  last_activity_at,
  activity_status,
  last_started_at,
  last_offline_at,
  trigger
}) => {
  function past24Hours() {
    const currentDate = new Date();

    // Initialize an array to store the past 24 hours
    const past24Hours = [];

    // Loop through the past 24 hours
    for (let i = 0; i < 24; i++) {
      const hour = new Date(currentDate);
      past24Hours.push(hour);
      // Calculate the time for the current hour

      hour.setHours(currentDate.getHours() - i);

      // Add the formatted date to the array
    }

    // Print the list of hours in the past 24 hours

    let hours = past24Hours.map((now) => {
      now.setMinutes(0);
      now.setSeconds(0);
      now.setMilliseconds(0);
      return now.toISOString();
    });
    let obj = {};

    for (let h of hours) {
      obj[h] = 1;

      if (typeof activity == 'object' && activity)
        if (h in activity) obj[h] = 0;
      //if (new Date(h) > new Date(last_activity_at)) obj[h] = 0;
      if (!activity_status && trigger == 'system') {
        //if continues feed also calucalte by last_activity

        let lastOnline = startOfHour(new Date(last_activity_at));
        if (new Date(h) >= lastOnline) obj[h] = 0;
      } else if (activity_status && trigger == 'system') {
        let lastOffline = startOfHour(new Date(last_offline_at));
        let firstOnline = startOfHour(new Date(last_started_at));

        if (
          lastOffline &&
          new Date(h) <= firstOnline &&
          new Date(h) >= lastOffline
        )
          obj[h] = 0;
      }
    }

    return obj;
  }
  let allHours = past24Hours(last_activity_at);

  const categories = Object.keys(allHours).reverse();
  const values = Object.values(allHours).reverse();

  const series0 = values.map((value) => (value === 0 ? 1 : 0));
  const series1 = values.map((value) => (value === 1 ? 1 : 0));
  const color0 = '#ea3737'; // Color for 0 Values
  const color1 = '#00a96c'; // Color for 1 Values

  const option = {
    animation: false,
    tooltip: {
      position: [10, -90],
      extraCssText: 'z-index: 19999;', // Set the z-index of the tooltip
      appendToBody: true,
      trigger: 'axis',
      formatter: function (params) {
        const categoryHour1 = format(new Date(params[0].axisValue), 'HH:mm'); // X-axis category

        const categoryHour2 = format(
          addHours(new Date(params[0].axisValue), 1),
          'HH:mm'
        ); // X-axis category

        const categoryDate = format(
          addHours(new Date(params[0].axisValue), 1),
          'MM-dd'
        ); // X-axis category
        const series0Value = params[0].data; // Value for series 0
        const series1Value = params[1].data; // Value for series 1

        // Customize the tooltip content
        return `
          <time style='font-size: 18px; font-weight: 700;'>${categoryHour1} - ${categoryHour2}</time><br/>
          <time style='font-size:12px;'>${categoryDate}</time><br/>
              ${
                allHours?.[params[0].axisValue] === 0
                  ? `
                  
                    ${
                      activity?.[params[0].axisValue]
                        ? `<span style='font-size:22px; margin-top: 2px; color: #ea3737;'>
                    ${
                      activity?.[params[0].axisValue]
                    }</span><span> feed drop(s)</span>`
                        : `<span style='color: #ea3737;'>OFFLINE</span>`
                    }`
                  : `<span style='color:#00a96c;'>OK</span>`
              }
              
            `;
      }
      // formatter: '{b0}: {c0}<br />{b1}: {c1}',

      //       position: function (pos, params, dom, rect, size) {
      //         // tooltip will be fixed on the right if mouse hovering on the left,
      //         // and on the left if hovering on the right.
      //         var obj = { top: 60 };
      //         obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 5;
      //         return obj;
      //       }
    },
    grid: {
      left: 10,
      right: 10
    },
    xAxis: {
      type: 'category',
      data: categories,
      boundaryGap: false, // Remove space at both ends
      splitLine: {
        show: false
      },
      axisLabel: {
        show: false
      },
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      }
    },
    yAxis: {
      axisLabel: {
        show: false
      }
    },

    series: [
      {
        name: 'offline',
        type: 'bar',
        stack: 'values',
        data: series0,
        color: color0,
        barCategoryGap: '0%', // Set the gap between categories to zero
        barGap: '0%'
        // barWidth: 10,
        // barGap: 1
      },
      {
        name: 'online',
        type: 'bar',
        stack: 'values',
        data: series1,
        color: color1,
        // barWidth: 10,
        // barGap: 1
        barCategoryGap: '0%',
        barGap: '0%'
      }
    ]
  };

  return (
    <div
      css={css`
        svg {
          opacity: 80%;
        }
      `}
    >
      <ReactECharts
        opts={{ renderer: 'svg' }}
        option={option}
        style={{ padding: '0px', height: '20px', width: '300px' }}
        loadingOption={{
          showSpinner: false,
          maskColor: 'var(--neutral-09)',
          fontWeight: 'normal',
          text: 'Fetching Data...',

          fontStyle: 'normal',

          fontFamily: 'inherit'
        }}
      />
    </div>
  );
};

export default ActivityChart;
