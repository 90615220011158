import React from 'react';

import Credential from 'api/credential';
import { Heading, TextField, NumberField } from 'components/form';
import { useMutationWrapper } from 'utils/ajax';
import { useFormikContext } from 'formik';
import { ErrorMessage } from 'components/form';
import { CheckBox } from 'components/form';
import Door from 'api/door';
import { SecondaryButton } from 'components/buttons';
import { css } from '@emotion/react';
import AsyncSelect from 'components/headless-select/async';
/** @jsxImportSource @emotion/react */

const CredentialDetails = (props) => {
  const { values, handleChange, setFieldValue } = useFormikContext();

  const { credential } = props;

  const [retrievalDoor, setRetrievalDoor] = React.useState();
  const cardRef = React.useRef();
  const cardNrRef = React.useRef();
  const facilityCodeRef = React.useRef();

  let { isLoading, mutate, isSuccess } = useMutationWrapper(
    ['credential', 'retrieve'],
    (doorToken) => Credential.retrieve(doorToken)
  );

  let handleRetrieve = async () => {
    if (!retrievalDoor) return;
    mutate(retrievalDoor, {
      errorMessage: 'Failed to retrieve credential',
      onError: () => {
        setFieldValue('card', '');
        setFieldValue('cardNumber', '');
        setFieldValue('facilityCode', '');
      },
      onSuccess: (data) => {
        if (!data) return;
        let card = data.message.find((item) => item.Name == 'Card') || {};
        let cardNr = data.message.find((item) => item.Name == 'CardNr') || {};
        let facilityCode =
          data.message.find((item) => item.Name == 'FacilityCode') || {};
        if (data.message.length > 0) {
          setFieldValue('card', card.Value ?? '');
          setFieldValue('cardNumber', cardNr.Value ?? '');
          setFieldValue('facilityCode', facilityCode.Value ?? '');
        }
      }
    });
  };

  const divStyles = css`
    margin-top: 1rem;
    display: grid;
    grid-template-columns: var(--form-column-width) var(--form-column-width);
    gap: 24px;
    article {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 24px;
      align-items: center;
    }
    section.retrieve {
      grid-column: 1/-1;
      display: grid;
      grid-template-columns: 60% 1fr;
      grid-column-gap: 24px;
      padding: 24px;
      background: var(--primary-v-02);
      border-radius: 4px;
    }
    input {
      &[data-retrieved='true'] {
        background: var(--primary-v-02);
        font-weight: bold;
      }
    }
  `;

  return (
    <div css={divStyles}>
      <CheckBox
        disabled={credential?.ad_uuid}
        name="enabled"
        label="Enabled"
        checked={values.enabled}
        onChange={(e) => setFieldValue('enabled', e.target.checked)}
      />
      <br />
      {!values.ad_uuid && (
        <section className="retrieve">
          <Heading label="Retrieve Credential Tool"></Heading>
          <AsyncSelect
            onChange={(e) => setRetrievalDoor(e?.token)}
            placeholder="Search doors..."
            queryKey="doors"
            label="Name"
            idx="token"
            fetchFn={Door.search}
            closeMenuOnSelect={true}
          />

          <SecondaryButton
            onClick={handleRetrieve}
            loading={isLoading}
            disabled={isLoading || !retrievalDoor}
          >
            Retrieve last credential
          </SecondaryButton>
        </section>
      )}
      <TextField
        disabled={values.ad_uuid}
        label="Description"
        value={values.description}
      />
      <br />

      <TextField
        data-retrieved={isSuccess && values.card ? true : false}
        disabled={values.ad_uuid}
        ref={cardRef}
        label="Card"
        value={values.card}
      />

      <NumberField
        data-retrieved={isSuccess && values.cardNumber ? true : false}
        disabled={values.ad_uuid}
        ref={cardNrRef}
        label="Card Number"
        name="cardNumber"
        value={values.cardNumber}
      />
      <NumberField
        data-retrieved={isSuccess && values.facilityCode ? true : false}
        disabled={values.ad_uuid}
        ref={facilityCodeRef}
        label="Facility Code"
        name="facilityCode"
        value={values.facilityCode}
      />

      <NumberField
        disabled={values.ad_uuid}
        label="pin"
        value={values.pin}
        onChange={handleChange}
      />
      <ErrorMessage name="cardOrCardNrOrPin" />
    </div>
  );
};

export default CredentialDetails;
