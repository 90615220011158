import React from "react";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { dateTime, dateTimeDif } from "utils/time";
import { CircleIcon } from "components/circle-icon";
import { Clock } from "react-bootstrap-icons";
import { format, formatDistance, formatRelative, subDays } from "date-fns";
const styles = css`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0 16px;
  align-items: center;

  > article {
    grid-row: span 2;
  }

  > span:last-child {
    color: var(--table-header-color);
    font-weight: 500;
    font-size: 12px;
  }
`;
const TimeFormatter = ({ dt, slim = true }) => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 0.4rem;
        .dt {
          color: var(--neutral-01);
          font-size: 12px;
        }
        time:first-of-type {
          text-transform: capitalize;
          font-weight: 500;
        }
      `}
    >
      <time>
        {dt && formatDistance(new Date(dt), new Date(), { addSuffix: true })}
      </time>
      {!slim && <time className="dt">{dateTime(new Date(dt))}</time>}
    </div>
  );
};

export default TimeFormatter;
