import React from 'react';
import * as echarts from 'echarts';
import { dateTimeShort } from 'utils/time';

//var data = [];
var dataCount = 10;
// var startTime = +new Date();

// var types = [{ name: 'JS Heap', color: '#7b9ce1' }];
// Generate mock data

var categories = ['sd-card'];

// categories.forEach(function (category, index) {
//   var baseTime = startTime;
//   for (var i = 0; i < dataCount; i++) {
//     var typeItem = types[Math.round(Math.random() * (types.length - 1))];
//     var duration = Math.round(Math.random() * 10000);
//     data.push({
//       name: typeItem.name,
//       value: [baseTime, (baseTime += duration), duration],
//       itemStyle: {
//         normal: {
//           color: typeItem.color
//         }
//       }
//     });
//     baseTime += Math.round(Math.random() * 2000);
//   }
// });

function convertToHMS(value) {
  const sec = parseInt(value, 10); // convert value to number if it's string
  let hours = Math.floor(sec / 3600); // get hours
  let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
  let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
  // add 0 if value < 10; Example: 2 => 02
  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }
  return hours + ':' + minutes + ':' + seconds; // Return is HH : MM : SS
}

const MIN = 1000 * 60;
const HOUR = 1000 * 60 * 60;
const DAY = 1000 * 60 * 60 * 24;

function convertSecondsToDHM(seconds) {
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  const remainingMinutes = minutes % 60;
  const remainingHours = hours % 24;

  return {
    days: days,
    hours: remainingHours,
    minutes: remainingMinutes
  };
}
// console.log('DDD', data);
const useChartOption = ({ dataZoom }) => {
  function renderItem(params, api) {
    var categoryIndex = api.value(0);
    var start = api.coord([api.value(1), categoryIndex]);
    var end = api.coord([api.value(2), categoryIndex]);
    var height = api.size([0, 1])[1] * 1;

    var rectShape = echarts.graphic.clipRectByRect(
      {
        x: start[0],
        y: start[1] - height / 2,
        width: end[0] - start[0],
        height: height
      },
      {
        x: params.coordSys.x,
        y: params.coordSys.y,
        width: params.coordSys.width,
        height: params.coordSys.height
      }
    );

    return (
      rectShape && {
        type: 'rect',
        transition: ['shape'],
        shape: rectShape,
        style: api.style()
      }
    );
  }

  const [option, setOption] = React.useState({
    tooltip: {
      formatter: function (params) {
        if (params.value == null) {
          return ''; // Return an empty string to hide the tooltip
        }

        // if (params.seriesIndex == 0) {
        //   const { days, hours, minutes } = convertSecondsToDHM(
        //     params.data['duration']
        //   );
        //   return `<div>
        //       <span>Recording<span><br>

        //      ${days > 0 ? `<time>${days} days, </time>` : ''}
        //      ${hours > 0 ? `<time>${hours} hours, </time>` : ''}
        //       ${minutes > 0 ? `<time>${minutes} min</time>` : ''}

        //       </div>`;
        // }

        if (params.seriesIndex == 4) {
          let start = params.data[0];
          let end = new Date(new Date(params.data[0]).getTime() + HOUR);

          return `<div>
              
              <span>${params.data[2]}</span> 
              <span>events<span><br>
              
              <time>${dateTimeShort(start)}</time><br/>
              <time>${dateTimeShort(end)}</time>

              </div>`;
        }
        if (params.seriesIndex == 2) {
          let time = params.data[0];
          let lp = params.data[2];

          return `<div>
              
              <span style="text-transform:capitalize">${
                params.seriesName
              } Event</span><br>
              <div style="font-size:14px;font-weight:700; padding-top: 0.2rem; padding-bottom:0.2rem;" className='lp'>${lp}</div>    
              <time>${new Date(time).toLocaleTimeString()}</time><br/>   
              <time style="font-size:11px">${new Date(
                time
              ).toLocaleDateString()}</time><br/>    
                 
              </div>`;
        }
      },
      trigger: 'item',
      appendToBody: true,
      transitionDuration: 0,
      alwaysShowContent: true // Set the trigger to 'item' to display tooltip for each data point
    },
    animation: false,
    // dataZoom: [
    //   {
    //     type: 'inside',
    //     filterMode: 'weakFilter'
    //   }
    // ],
    // tooltip: {
    //   borderColor: 'transparent',
    //   formatter: function (params) {
    //     return `<div>
    //         <span>Recording Duration</span>
    //         <time>${convertToHMS(
    //           params?.data?.duration
    //         )} hours</time>
    //       </div>`;
    //   }
    // },

    dataZoom: [
      {
        // realtime: false,
        // yAxisIndex: [0],

        xAxisIndex: [0, 1, 2],
        type: 'inside',
        filterMode: 'weakFilter',
        minValueSpan: 60 * 1000 * 1,
        maxValueSpan: 60 * 1000 * 60 * 7 * 24,
        throttle: 0,
        startValue: dataZoom.start,
        endValue: dataZoom.end
      }
    ],

    grid: [
      {
        show: true,
        top: '20%',
        left: '0%',
        right: '0%',
        height: '15px'
        //backgroundColor: '#e3e6ec'
      },
      {
        show: true,
        top: '5%',
        left: '0%',
        right: '0%',
        height: '15px'
        //  backgroundColor: '#e3e6ec'
      },
      {
        show: true,
        left: '0%',
        right: '0%',
        top: '35px',
        height: '40%'
      }
    ],
    xAxis: [
      {
        axisLine: { show: false, onZero: false },

        min: Date.now() - DAY * 30,
        max: Date.now() + DAY * 7,
        axisLabel: {
          show: false
        },

        type: 'time',
        gridIndex: 0
      },
      {
        axisLine: { show: false, onZero: false },

        min: Date.now() - DAY * 30,
        max: Date.now() + DAY * 7,
        axisLabel: {
          show: false
        },

        type: 'time',
        gridIndex: 1
      },

      {
        triggerEvent: true,
        min: Date.now() - DAY * 30,
        max: Date.now() + DAY * 7,
        type: 'time',
        minorTick: { show: false },
        axisLabel: {
          formatter: function (value, index) {
            return `{h}:{mm}:{ss}`;
          },
          textStyle: {
            color: '#000'
          },
          fontWeight: 400,
          showMaxLabel: true,
          inside: true,
          showMinLabel: false,
          fontSize: 12,
          fontFamily: 'monospace'
        },
        axisPointer: {
          triggerEvent: true,
          show: true,
          type: 'line',
          lineStyle: {
            color: 'red',
            type: 'solid',
            width: 3
          },
          label: {
            formatter: function (value, index) {
              return value && new Date(value.value).toLocaleTimeString();
            },
            backgroundColor: 'white',
            color: 'black',
            fontWeight: 600,
            fontSize: 14,
            margin: 10
          }
        },
        splitLine: {
          show: true,
          lineStyle: {
            type: 'solid'
          }
        },

        gridIndex: 2

        //boundaryGap: false,
      }

      // {
      //   position: 'bottom',
      //   type: 'time',
      //   min: Date.now() - DAY * 30,
      //   max: Date.now() + DAY * 7,
      //   gridIndex: 1,
      //   minorTick: { show: true },

      //   axisLine: {
      //     show: true
      //   },

      //   axisLine: {
      //     onZero: false
      //   },

      //   axisLabel: {
      //     formatter: function (value, index) {
      //       return index % 2 ? `{M}/{d}/{yy}` : ``;
      //     },
      //     showMaxLabel: true,
      //     showMinLabel: true,
      //     color: '#000',
      //     margin: 5,
      //     align: 'left',
      //     padding: [0, 10, 5, 6],
      //     fontFamily: 'monospace',
      //     fontWeight: 600,
      //     hideOverlap: false,
      //     fontSize: 12,
      //     fontWeight: 500,
      //     interval: 10
      //   }
      // }
    ],
    yAxis: [
      {
        //cloud
        data: ['cloud'],
        show: false,
        gridIndex: 0
      },
      {
        data: ['sd-card'],
        show: false,
        gridIndex: 1
      },
      {
        min: 0,
        max: 8,
        gridIndex: 2,
        show: false,
        scale: false,
        splitArea: {
          show: true
        }
      }
    ],

    series: [
      {
        type: 'custom',
        renderItem,
        itemStyle: {
          opacity: 0.8
        },
        encode: {
          x: [1, 2],
          y: 0
        },
        data: [],
        xAxisIndex: 0,
        yAxisIndex: 0
      },
      {
        type: 'custom',
        renderItem,
        itemStyle: {
          opacity: 0.8
        },
        encode: {
          x: [1, 2],
          y: 0
        },
        data: [],
        xAxisIndex: 1,
        yAxisIndex: 1
      },

      {
        data: [],
        xAxisIndex: 2,
        yAxisIndex: 2
      }
    ]
  });

  return { option, setOption, renderItem };
};

export default useChartOption;
