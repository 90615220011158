import React from 'react';
import UserFields from './user-fields';
import { FieldArray, useFormikContext } from 'formik';
import { CheckBox } from 'components/form';
import Installer from 'api/installer';
import Company from 'api/company';
/** @jsxImportSource @emotion/react */
//import AsyncSelect from "components/form/async-select";
import AsyncSelect from 'components/headless-select/async';
import Site from 'api/site';
import { css } from '@emotion/react';
import { Badge } from 'components/badge';
import { useAuth } from 'providers/auth';
import { Field } from 'formik';
import Label from 'components/label';
import HomePath from './home-path';
import { Heading } from 'components/form';

const COMPANY_PERMISSIONS = {
  dashboard: 'View Dashboard',
  monitoring: 'Live Monitoring',
  personView: 'View People',
  personEdit: 'Edit People',
  alView: 'View Access Levels',
  alEdit: 'Edit Access Levels',
  scheduleView: 'View Schedules',
  scheduleEdit: 'Edit Schedules',
  guestView: 'View Guests',
  guestEdit: 'Edit Guests',
  cameraView: 'View Cameras'
};
//TODO there is a bug here when creating users as nested user u only can give your own
const UserForm = (props) => {
  const { values, setFieldValue } = useFormikContext();
  const { user: currentUser } = useAuth();
  const user = props.user;
  const role = user?.role ?? props.role;
  const [prevPermissions, setPrevPermissions] = React.useState([]);

  // TODO camera and live monitoring come from features and need to make sure if it has them...

  return (
    <>
      <section>
        <CheckBox
          name="enabled"
          label="Enabled"
          disabled={user ? user?.protected == 1 : false}
          checked={values.enabled}
          onChange={(e) => setFieldValue('enabled', e.target.checked)}
        />

        {role == 'V' && (
          <Badge title={user?.vendor_name} color="green">
            Vendor Account
          </Badge>
        )}
        {role == 'I' && (
          <Badge title={user?.installer_name} color="blue">
            Installer Account
          </Badge>
        )}
        {role == 'C' && (
          <Badge title={user?.company_name} color="red">
            Company Account
          </Badge>
        )}
        {user?.protected == 1 && (
          <Label
            title="Protected account is the primary account for this organization"
            css={css`
              position: absolute;
              right: 0.4rem;
              top: 0.4rem;
            `}
          >
            Protected
          </Label>
        )}

        <UserFields user={user} />
      </section>

      {(user?.id != currentUser?.user_id || !user) && (
        <>
          <h3
            css={css`
              color: var(--neutral-00);
            `}
          >
            Permissions
          </h3>
          <section>
            <FieldArray
              name="permission"
              render={(arrayHelpers) => (
                <>
                  {currentUser.role != role ||
                  currentUser.permission.includes('fa') ? (
                    <>
                      <CheckBox
                        label="Full Access"
                        disabled={user?.protected == 1}
                        checked={values.permission[0] == 'fa'}
                        onChange={(e) =>
                          e.target.checked
                            ? (setPrevPermissions(values.permission),
                              setFieldValue('permission', ['fa']))
                            : setFieldValue('permission', prevPermissions)
                        }
                      />
                      <br />
                    </>
                  ) : null}
                  <CheckBox
                    label="Manage Accounts"
                    disabled={values.permission[0] == 'fa'}
                    checked={
                      values.permission[0] == 'fa' ||
                      values.permission.includes('writeUsers')
                    }
                    onChange={(e) =>
                      e.target.checked
                        ? arrayHelpers.push('writeUsers')
                        : arrayHelpers.remove(
                            values.permission.indexOf('writeUsers')
                          )
                    }
                  />
                  <br />

                  {role == 'V' && (
                    <>
                      {currentUser.role != role ||
                      currentUser.permission.includes('writeInstallers') ||
                      currentUser.permission.includes('fa') ? (
                        <CheckBox
                          label="Edit & Remove Installers"
                          disabled={values.permission[0] == 'fa'}
                          checked={
                            values.permission[0] == 'fa' ||
                            values.permission.includes('writeInstallers')
                          }
                          onChange={(e) =>
                            e.target.checked
                              ? arrayHelpers.push('writeInstallers')
                              : arrayHelpers.remove(
                                  values.permission.indexOf('writeInstallers')
                                )
                          }
                        />
                      ) : (
                        <span></span>
                      )}
                      {currentUser.role != role ||
                      currentUser.permission.includes('allInstallers') ||
                      currentUser.permission.includes('fa') ? (
                        <CheckBox
                          label="Access to ALL Installers"
                          checked={
                            values.permission[0] == 'fa' ||
                            values.permission.includes('allInstallers')
                          }
                          disabled={values.permission[0] == 'fa'}
                          onChange={(e) =>
                            e.target.checked
                              ? arrayHelpers.push('allInstallers')
                              : arrayHelpers.remove(
                                  values.permission.indexOf('allInstallers')
                                )
                          }
                        />
                      ) : (
                        <span></span>
                      )}
                      {values.permission[0] != 'fa' &&
                        !values.permission.includes('allInstallers') && (
                          <>
                            <Field
                              title="Allowed Installers"
                              name="allowed_installers"
                              isMulti={true}
                              component={AsyncSelect}
                              fetchFn={Installer.search}
                              fetchFilters={{ vendor: currentUser.vendor_id }}
                            />

                            <br />
                          </>
                        )}
                    </>
                  )}

                  {role == 'I' && (
                    <>
                      {currentUser.role != role ||
                      currentUser.permission.includes('writeCompanies') ||
                      currentUser.permission.includes('fa') ? (
                        <CheckBox
                          label="Edit & Remove Companies"
                          disabled={values.permission[0] == 'fa'}
                          checked={
                            values.permission[0] == 'fa' ||
                            values.permission.includes('writeCompanies')
                          }
                          onChange={(e) =>
                            e.target.checked
                              ? arrayHelpers.push('writeCompanies')
                              : arrayHelpers.remove(
                                  values.permission.indexOf('writeCompanies')
                                )
                          }
                        />
                      ) : (
                        <span></span>
                      )}
                      {currentUser.role != role ||
                      currentUser.permission.includes('allCompanies') ||
                      currentUser.permission.includes('fa') ? (
                        <CheckBox
                          label="Access to ALL Companies"
                          checked={
                            values.permission[0] == 'fa' ||
                            values.permission.includes('allCompanies')
                          }
                          disabled={values.permission[0] == 'fa'}
                          onChange={(e) =>
                            e.target.checked
                              ? arrayHelpers.push('allCompanies')
                              : arrayHelpers.remove(
                                  values.permission.indexOf('allCompanies')
                                )
                          }
                        />
                      ) : (
                        <span></span>
                      )}
                      {values.permission[0] != 'fa' &&
                        !values.permission.includes('allCompanies') && (
                          <>
                            <Field
                              title="Allowed Companies"
                              name="allowed_companies"
                              label="display_name"
                              isMulti={true}
                              component={AsyncSelect}
                              fetchFn={Company.search}
                              fetchFilters={{
                                installer:
                                  user?.installer_id ?? currentUser.installer_id
                              }}
                            />
                            <br />
                          </>
                        )}
                    </>
                  )}

                  {role == 'C' && (
                    <>
                      {currentUser.role != 'C' ||
                      currentUser.permission.includes('allSites') ||
                      currentUser.permission.includes('fa') ? (
                        <>
                          <CheckBox
                            label="Access to ALL Sites"
                            disabled={values.permission[0] == 'fa'}
                            checked={
                              values.permission[0] == 'fa' ||
                              values.permission.includes('allSites')
                            }
                            onChange={(e) =>
                              e.target.checked
                                ? arrayHelpers.push('allSites')
                                : arrayHelpers.remove(
                                    values.permission.indexOf('allSites')
                                  )
                            }
                          />
                          <br />
                        </>
                      ) : null}
                      {values.permission[0] != 'fa' &&
                        !values.permission.includes('allSites') && (
                          <>
                            <Field
                              title="Allowed Sites"
                              name="allowed_sites"
                              isMulti={true}
                              component={AsyncSelect}
                              fetchFn={Site.search}
                              fetchFilters={{
                                company: currentUser?.company_id
                              }}
                            />

                            <br />
                          </>
                        )}

                      <div
                        css={css`
                          display: contents;
                        `}
                      >
                        {Object.entries(COMPANY_PERMISSIONS).map(([p, pn]) =>
                          currentUser.role != 'C' ||
                          currentUser.permission[0] == 'fa' ||
                          currentUser.permission.includes(p) ? (
                            <CheckBox
                              disabled={values.permission[0] == 'fa'}
                              checked={
                                values.permission[0] == 'fa' ||
                                values.permission.includes(p)
                              }
                              onChange={(e) => {
                                const set = new Set(values.permission);

                                function addPerm(p) {
                                  set.add(p);
                                  setFieldValue('permission', [...set]);
                                }

                                function removePerm(p) {
                                  set.delete(p);
                                  setFieldValue('permission', [...set]);
                                }

                                if (e.target.checked) {
                                  addPerm(p);
                                  p == 'personEdit' && addPerm('personView');
                                  p == 'alEdit' && addPerm('alView');
                                  p == 'scheduleEdit' &&
                                    addPerm('scheduleView');
                                  p == 'guestView' && addPerm('alView');
                                  if (p == 'guestEdit') {
                                    addPerm('guestView');
                                    addPerm('alView');
                                  }
                                } else {
                                  removePerm(p);
                                  p == 'personView' && removePerm('personEdit');
                                  p == 'alView' && removePerm('alEdit');
                                  p == 'scheduleView' &&
                                    removePerm('scheduleEdit');
                                  p == 'guestView' && removePerm('guestEdit');
                                }
                              }}
                              label={pn}
                            />
                          ) : null
                        )}
                      </div>
                    </>
                  )}
                </>
              )}
            />
          </section>
        </>
      )}
      <br />
      {role == 'C' && <HomePath role={role} />}
      {(currentUser.role != 'C' || currentUser.devices_view) && role == 'C' && (
        <div
          css={css`
            position: static;
            padding: 24px;
            background: var(--red-v-02);
            border-radius: 4px;
            input[type='checkbox'] {
              color: red;
            }
          `}
        >
          <Heading label="Device View" />
          <CheckBox
            label="Allow access to device view, ping and restore tools"
            checked={values.devices_view}
            onChange={(e) => setFieldValue('devices_view', e.target.checked)}
          />
        </div>
      )}
      <CheckBox
        label="Please confirm the above info is correct before submitting"
        checked={!values.formIsDisabled}
        onChange={(e) => setFieldValue('formIsDisabled', !e.target.checked)}
      />
    </>
  );
};

export default UserForm;
