import { dataFetchQuery, useMutation } from 'utils/ajax';
import { API_HOST_URL } from './api-url';
const API_URL = API_HOST_URL + '/elevator_api';
import { callAPI } from 'utils/ajax';
const Elevator = {
  search(input = {}) {
    return callAPI('GET', `/elevator`, input);
  },
  get(id = '') {
    return callAPI('GET', `/elevator/${id}`);
  },
  stats(input = {}) {
    return callAPI('GET', `/elevator/stats`, input);
  },

  save(input = {}, id) {
    return dataFetchQuery(`${API_URL}/save/${id}`, input);
  }
};

export default Elevator;
