import 'css/sidebar.css';
import React from 'react';
import { withSidebar } from 'components/with-sidebar';
import { useHeadlessTable } from 'providers/headless-table-provider';

import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import { AvatarCircle } from 'components/avatar';

import SidebarEmptyHolder from 'components/sidebar-empty-holder';
import { IconUsersGroup } from '@tabler/icons-react';
import ReactECharts from 'echarts-for-react';
import ActivityChartFormatter from 'components/activity-chart-formatter';

const articlStyles = css`
  display: flex;

  height: 100% !important;
  flex-direction: column;

  > header {
    display: grid;
    padding: 24px;
    border-bottom: 1px solid var(--neutral-12);
    grid-template-columns: auto 1fr;

    column-gap: 24px;
    align-items: center;

    span.icon {
      grid-row: 1 / span 2;
      padding: 0.8rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--primary-v-02);
      color: var(--primary-base);

      &[data-enabled='true'] {
        background: var(--primary-v-02);
        color: var(--primary-base);
      }
      &[data-enabled='false'] {
        background: var(--red-v-02);
        color: var(--red-base);
      }
    }
    h4 {
      font-size: 12px;
      font-weight: 500;
      color: var(--neutral-01);
      grid-column: 2;
    }
    [role='badge'] {
      grid-column: 3;
      grid-row: 1;
    }
  }
  > section {
    padding: 1rem;
    flex: 1;
  }

  .usage-chart {
    margin-top: auto;
    max-height: 250px;
  }

  .people {
    max-height: 70%;
    height: 70%;
  }
`;

const Stats = () => {
  return null;
};
const Sidebar = (props) => {
  const { selectedFlatRows, personStats } = useHeadlessTable();

  let person_total = personStats?.total;
  let pdt = personStats?.with_department;

  const [dep, setDep] = React.useState();

  React.useEffect(() => {
    setDep(selectedFlatRows?.[0]?.original || props.data);
  }, [selectedFlatRows, props.data]);

  if (!dep) return <SidebarEmptyHolder />;

  var percentage = (dep.person_count / pdt) * 100;
  const options = {
    color: ['#2480d6', '#c9cfda'],
    animation: false,
    grid: {
      left: '10%',
      right: '10%',
      bottom: '15%',
      top: '50%'
    },
    title: {
      text: 'Distribution',
      textStyle: {
        borderWidth: 2, // Set the border width
        borderColor: '#000', // Set the border color
        fontSize: 16
      }
    },

    // tooltip: {
    //   trigger: 'item',
    //   formatter: '{b}: {c} ({d}%)'
    // },
    xAxis: { axisLine: { show: false } },
    yAxis: { axisLine: { show: false } },
    series: [
      {
        emphasis: {
          label: {
            show: true // Disable label on hover
          },
          itemStyle: {
            opacity: 1 // Disable opacity change on hover
          }
        },
        selectedMode: 'none',
        radius: '70%',

        type: 'pie',
        startAngle: 225, // Set the start angle for the first sector
        clockwise: false,
        label: {
          show: true,
          formatter(param) {
            // correct the percentage
            return param.name + '\n (' + param.percent + '%)';
          }
        },
        center: ['50%', '50%'],
        data: [
          { value: percentage.toFixed(2), name: dep.Name },
          { value: (100 - percentage).toFixed(2), name: 'All Others' }
        ]
      }
    ]
  };

  return (
    <article css={articlStyles}>
      <header>
        <span className="icon">
          <IconUsersGroup />
        </span>
        <h3>{dep.Name}</h3>
        <h4>{dep.Description}</h4>
      </header>
      <section>
        <dl
          className="info"
          css={css`
            overflow: auto;
            margin-bottom: 0px;
          `}
        >
          <dt>Access Levels</dt>
          <dd
            css={css`
              > span {
                display: block;
                margin-top: 4px;
              }
            `}
          >
            {dep.access_levels?.map((al, idx) => (
              <span key={idx}>{al.name}</span>
            )) ?? 'N/A'}
          </dd>
          <dt
            css={css`
              grid-column: 1;
              grid-row: 5;
            `}
          >
            People Count ({dep.person_count} / {person_total})
          </dt>
          <dd
            css={css`
              grid-column: 1 / span 2;
              grid-row: 6;
              width: 100%;
              display: flex;
              align-items: center;
              gap: 1rem;
              margin-bottom: 1rem !important;
            `}
          >
            <meter
              min="0"
              max="100"
              value={dep.person_count / (person_total / 100)}
              low="40"
              high="90"
              css={css`
                -webkit-meter-bar {
                  background: none; /* Required to get rid of the default background property */
                  background-color: var(--purple-base);
                }
                ::-moz-meter-bar {
                  background: none; /* Required to get rid of the default background property */
                  background-color: var(--purple-base);
                }
                width: 100%;
                position: relative;
                height: 10px;
              `}
            ></meter>
            {parseInt(dep.person_count / (person_total / 100))}%
          </dd>
        </dl>
      </section>
      <section>
        <ReactECharts
          opts={{ renderer: 'svg' }}
          option={options}
          notMerge={true}
          lazyUpdate={true}
          style={{ height: '300px' }}
        />
      </section>
      <section>
        <ActivityChartFormatter
          full
          activity={dep.activity}
          style={{ height: '100%', width: '100%' }}
          config={{}}
        />
      </section>
    </article>
  );
};

export default withSidebar({})(Sidebar, Stats);
