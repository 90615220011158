import React from 'react';
import Template from 'base/template';
import { columns } from 'views/sync-jobs/batch-table-columns';
import IndexContentInfinite from 'base/index-content-infinite';
import SyncJob from 'api/sync-job';
import Filters from 'views/sync-jobs/filters';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { IconCheckbox, IconCircle0Filled, IconX } from '@tabler/icons-react';

const cards = [
  {
    name: 'total',
    label: 'Total'
  },
  {
    name: 'done',
    label: 'done',
    icon: IconCheckbox,
    color: 'var(--primary-base)',
    background: 'var(--stats-background-green)'
  },
  {
    name: 'failed',
    label: 'Failed',
    icon: IconX,
    color: 'var(--red-base)',
    background: 'var(--stats-background-red)'
  },
  {
    name: 'new',
    label: 'New'
  }
];

const BatchSyncJobs = (props) => {
  const { batchId } = useParams();

  return (
    <Template {...props}>
      <IndexContentInfinite
        id={batchId}
        customTitle={'Batch Sync Jobs: ' + batchId}
        filters={Filters}
        query={SyncJob.search}
        statsQuery={SyncJob.stats}
        initialFilter={{ batchId }}
        columns={columns}
        cards={cards}
        {...props}
        selectable
        //isSidebarOpen={true}
        //  sidebar={Sidebar}
      ></IndexContentInfinite>
    </Template>
  );
};

export default BatchSyncJobs;
