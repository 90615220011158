import React from 'react';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
const styles = css`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  width: fit-content;
`;
const PersonDepartments = ({ person }) => {
  if (!person || !person?.departments) return null;
  // some times department_names is just names
  const departments = person.departments.map((d) => d.name ?? d);
  return (
    <section
      className="departments"
      css={styles}
      title={departments.join(', ')}
    >
      {departments.slice(0, 2).join(', ')}

      {departments.length > 2 && <strong>(+{departments.length - 2})</strong>}
    </section>
  );
};

export default PersonDepartments;
