import React from 'react';
import styled from '@emotion/styled';
import { useHeadlessTable } from 'providers/headless-table-provider';
/** @jsxImportSource @emotion/react */
import { BarChart } from 'react-bootstrap-icons';
import ColumnPicker from './column-picker';
import { AuxiliaryButton } from './buttons';
import { withActionButton } from 'components/with-action-button';
import TableFilter from './headless-table-filter';
import { AddButton } from './buttons';
import TableExport from './headless-table-export';
import TableReloadButton from './table/table-reload-button';

const Article = styled('article')`
  padding: 12 24px;
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  background: var(--neutral-09);
  box-sizing: border-box;
  border-radius: 6px 6px 0 0;
  position: relative;
  min-height: 72px;
  align-items: center;
  z-index: 8;
  border-bottom: 1px solid var(--neutral-12);
  flex-wrap: wrap;
`;

const TableHeader = (props) => {
  const { dispatch, tableState, data } = useHeadlessTable();

  const ActionButton = withActionButton(props.actionButton);
  const ExtraAction = props.extraAction;
  const ExtraButtons = props.buttons;

  const isDisabled =
    data?.data?.length == 0 && Object.keys(tableState.queryFilter).length == 0;

  return (
    <Article>
      {!props.noGlobalSearch && (
        <TableFilter {...props} data-compact isDisabled={isDisabled} />
      )}
      {props.columnPicker !== false && <ColumnPicker />}
      {props.export && <TableExport {...props} />}

      <TableReloadButton {...props} />

      {props.chartArea && (
        <AuxiliaryButton
          compact
          onClick={() =>
            dispatch({ type: 'CHART_TOGGLE', payload: !tableState.chart })
          }
        >
          <BarChart />
        </AuxiliaryButton>
      )}
      {props.actionButton && <ActionButton {...props} />}
      {props.addButton && <AddButton label={props.addButton} to={props.id} />}
      {props.buttons && <ExtraButtons {...props} />}
      {props.extraAction && <ExtraAction />}
    </Article>
  );
};

export default TableHeader;
