import React from "react";

/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Door from "api/door";
import { useQueryWrapper } from "utils/ajax";
import { Cpu, DoorOpen } from "react-bootstrap-icons";
import EventList from "./event-list";
import DoorStateAvatar from "views/doors/door-state-avatar";

import { useEventList } from "hooks/use-event-list";

const TreeView = ({ ac }) => {
  const { data, isLoading } = useQueryWrapper(
    ["controller", "door-search", ac?.controller_id],
    () => Door.search({ controller: ac?.controller_id })
  );

  const [selectedDoor, setSelectedDoor] = React.useState(null);

  React.useEffect(() => {
    setSelectedDoor(null);
  }, [ac]);


  if (!ac) return null;
  const query = useEventList({
    filters: {
      door_id: selectedDoor?.id,
      category: null,
    },
  });

  return (
    <article
      css={css`
        display: flex;
        flex-direction: column;
        overflow: hidden;
        section.event-list {
          flex: 1;
          overflow: auto;
          margin-bottom: 1rem;
        }
        h3 {
          margin-bottom: 1rem;
        }
        ul {
          margin-left: 1rem;
          margin-bottom: 0.5rem;
          transition: all 0.3;
        }

        .wtree li {
          list-style-type: none;
          margin: 10px 0 10px 24px;
          position: relative;
        }
        .wtree li:before {
          content: "";
          position: absolute;
          top: 0px;
          left: -26px;
          border-left: 1px solid var(--secundary-base);
          border-bottom: 1px solid var(--secundary-base);
          width: 20px;
          height: 15px;
          opacity: 40%;
        }

        .wtree li > div {
          display: flex;
          align-items: center;
          gap: 0.4rem;
          &[data-selected="true"] {
            color: var(--neutral-00);
            font-weight: 500;
          }
          &[data-selected="false"] {
            opacity: 50%;
          }
        }
        .wtree li span {
          width: 36px;
          height: 36px;
          background: var(--secundary-v-02);
          border-radius: 80px;
          cursor: pointer;
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-align-items: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          -webkit-justify-content: center;
          justify-content: center;

          display: flex;
          align-items: center;
          gap: 1rem;
          padding: 10px;
          color: #888;
          text-decoration: none;
          svg {
            width: 24px;
            height: 24px;
            color: var(--secundary-base);
          }
        }

        .wtree li span:hover,
        .wtree li span:focus {
        }
        .wtree li span:hover + ul li span,
        .wtree li span:focus + ul li span {
        }
        .wtree li span:hover + ul li:after,
        .wtree li span:hover + ul li:before,
        .wtree li span:focus + ul li:after,
        .wtree li span:focus + ul li:before {
        }
      `}
    >
      <h3 class="title">{ac.site_name}</h3>
      <ul class="wtree">
        <li>
          <div>
            <span>
              <Cpu />
            </span>
            {ac.name}
          </div>
          <ul>
            {data?.data.map((door) => (
              <li>
                <div
                  data-selected={selectedDoor?.id == door.id ? "true" : "false"}
                  onClick={() => setSelectedDoor(door)}
                >
                  <DoorStateAvatar door={door} />
                  {door.Name}
                </div>
              </li>
            ))}
          </ul>
        </li>
      </ul>

      {selectedDoor && (
        <>
          <h3>{selectedDoor.Name} - Events</h3>
          <EventList query={query} />
        </>
      )}
    </article>
  );
};

export default TreeView;
