import styled from '@emotion/styled';

export const WidgetHeader = styled('header')`
  z-index: 1003;
  background: #fafafa;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  font-size: 12px;
  position: relative;
  padding-right: 0.5rem;
  gap: 1rem;
  > button:first-of-type {
    margin-left: auto;
  }
  .name {
    font-size: 16px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  a {
    &:hover {
      > div {
        text-decoration: underline;
      }
    }
  }
`;
