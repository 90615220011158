import React from "react";
import { components } from "react-select";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const MoreSelectedBadge = ({ items }) => {
  const style = {
    marginLeft: "auto",
    background: "#d4eefa",
    borderRadius: "4px",

    fontSize: "11px",
    padding: "3px",
    order: 99,
  };

  const title = items.join(", ");
  const length = items.length;
  const label = `+ ${length}`;

  return (
    <div style={style} title={title}>
      {label}
    </div>
  );
};

export const MultiValue = ({ index, getValue, ...props }) => {
  const maxToShow = 4;
  const overflow = getValue()
    .slice(maxToShow)
    .map((x) => x?.[props?.selectProps?.label]);

  return index < maxToShow ? (
    <components.MultiValue {...props} />
  ) : index === maxToShow ? (
    <MoreSelectedBadge items={overflow} />
  ) : null;
};

export const LimitedChipsContainer = ({ children, hasValue, ...props }) => {
  if (!hasValue) {
    return (
      <components.ValueContainer {...props}>
        {children}
      </components.ValueContainer>
    );
  }

  const CHIPS_LIMIT = 4;
  const [chips, otherChildren] = children;
  const overflowCounter = chips.slice(CHIPS_LIMIT).length;
  const displayChips = chips.slice(
    overflowCounter,
    overflowCounter + CHIPS_LIMIT
  );

  return (
    <components.ValueContainer {...props}>
      <div
        css={css`
          color: var(--neutral-00) !important;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 16px;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 8px 12px;
          background: ${props.data.value == "select-all"
            ? "#F0F6FF"
            : "var(--badge-background-green)"};
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        `}
      >
        {children}
      </div>
    </components.ValueContainer>
  );
};

export const SingleValue = ({ children, ...props }) => {
  return (
    <components.SingleValue {...props}>
      <div
        css={css`
          color: var(--neutral-00) !important;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 16px;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 4px 12px;
          background: var(--singleValue-background-color);
          border-radius: 50px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        `}
      >
        {children}
      </div>
    </components.SingleValue>
  );
};
export const MultiValueContainer = ({ children, ...props }) => {
  return (
    <components.MultiValueContainer {...props}>
      <div
        css={css`
          color: var(--neutral-00) !important;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 16px;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 8px 12px;
          background: ${props.data.value == "select-all"
            ? "var(--allsiteselected-background-color)"
            : "var(--multiValue-background-color)"};
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        `}
      >
        {children}
      </div>
    </components.MultiValueContainer>
  );
};

export const ValueContainer = ({ children, ...props }) => {
  return (
    <components.ValueContainer {...props}>
      <div>{children}</div>
    </components.ValueContainer>
  );
};

export const MenuList = ({ children, ...props }) => {
  return (
    <components.MenuList {...props}>
      <label>
        <button
          css={css`
            background: inherit;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 19px;
            padding: 16px;
          `}
          type="button"
        >
          Select All
        </button>
      </label>
      {children}
    </components.MenuList>
  );
};

export const MultiValueLabel = ({ children, ...props }) => {
  return (
    <components.MultiValueLabel {...props}>
      <div
        css={css`
          color: var(--neutral-00) !important;
          background: ${props.data.value == "select-all"
            ? "var(--allsiteselected-background-color)"
            : "var(--primary-v-02"};
        `}
      >
        {props.data.value == "select-all" ? "All Is Selected" : children}
      </div>
    </components.MultiValueLabel>
  );
};

export const Option = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 8px;
          color: var(--neutral-00);

          font-size: 14px;
          cursor: ${props.data.value == "select-all" ? "pointer" : "default"};
        `}
      >
        {props.selectProps.checkboxes && props.data.value != "select-all" && (
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />
        )}
        <span
          css={css`
            color: var(--neutral-00);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          `}
        >
          {children}
        </span>
      </div>
    </components.Option>
  );
};
