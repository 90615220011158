import React from 'react';
import Template from 'base/template';

import { columns } from 'views/recording-exports/table-columns';
import IndexContent from 'base/index-content';
import { useAuth } from 'providers/auth';
import RecordingExport from 'api/recording-export';

const cards = [];

const RecordingExp = (props) => {
  const { user } = useAuth();
  return (
    <Template {...props}>
      <IndexContent
        queryParams={{
          refetchInterval: 60000
        }}
        noGlobalSearch
        statsQuery={RecordingExport.getStats}
        title="Recording Exports"
        id="recording-exports"
        cards={cards}
        query={RecordingExport.search}
        columns={columns}
        isSidebarOpen={true}
        getRowId={(row, relativeIndex, parent) => row._id}
        {...props}
      ></IndexContent>
    </Template>
  );
};

export default RecordingExp;
