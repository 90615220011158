import React from 'react';
import { Section } from './installer-dashboard';

import { css } from '@emotion/react';
import ChartTypeSelect from 'components/charts/chart-type-select';
import ChartPeriodSelect from 'components/charts/chart-period-select';
import ActiveDoorChart from './active-door-chart';
/** @jsxImportSource @emotion/react **/
const ActiveDoor = (props) => {
  const [filter, setFilter] = React.useState('-1 day');
  const [type, setType] = React.useState();
  return (
    <Section id="active-door" {...props}>
      <header>
        <div>
          <h4>Door Activity</h4>
          <h5>Top 5 Active Doors</h5>
        </div>
        {/* <ChartTypeSelect type={type} setType={setType} />
        <ChartPeriodSelect filter={filter} setFilter={setFilter} /> */}
      </header>

      <ActiveDoorChart filter={filter} type={type} />
    </Section>
  );
};

export default ActiveDoor;
