import React from "react";
import RadioGroup from "components/radio-group";
const DOOR_MONITOR = [
  { value: "open_circuit", label: "Open Circuit", controller: "Input Open" },
  {
    value: "closed_circuit",
    label: "Closed Circuit",
    controller: "Input Ground",
  },
  { value: "none", label: "None" },
];

const DoorMonitorSelect = (props) => {
  const { field, form } = props;
  return (
    <RadioGroup
      {...field}
      {...form}
      options={DOOR_MONITOR}
      title="Door Monitor"
    />
  );
};

export default DoorMonitorSelect;
