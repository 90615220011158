import React from 'react';
import TableContext from '../providers/table-context';
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  useRowSelect,
  useRowState,
  useExpanded,
  //useGroupBy,
  useAsyncDebounce,
  useColumnOrder
} from 'react-table';
import { useResizeDetector } from 'react-resize-detector';

import Event from 'api/event';
import { toast } from 'react-toastify';
import { useLocalStorage } from 'hooks/local-storage';
import { useInfiniteQuery } from 'react-query';
import { useAuth } from './auth';
const InfiniteTableProvider = ({
  initialFilter = {},
  columns,
  query,
  children,
  id = '',
  ...otherProps
}) => {
  const { user } = useAuth();
  const [LStrendChart, setLStrendChart] = useLocalStorage(`${id}:chart`, true);

  const [LShiddenColumns, setLSHiddenColumns] = useLocalStorage(
    `${id}:hiddenColumns`
  );

  const [LScolumnOrder, setLSColumnsOrder] = useLocalStorage(
    `${id}:columnOrder`
  );

  const [recentFilters, setRecentFilters] = useLocalStorage(
    `${id}:recentFilters`,
    []
  );

  const [LSColumnsWidth, setLSColumnsWidth] = useLocalStorage(
    `${id}:columnsWidth`,
    '{}'
  );

  const PAGE_CHANGED = 'PAGE_CHANGED';
  const PAGE_SIZE_CHANGED = 'PAGE_SIZE_CHANGED';
  const FILTER_CHANGED = 'FILTER_CHANGED';
  const SORTER_CHANGED = 'SORTER_CHANGED';
  const GLOBAL_FILTER_CHANGED = 'GLOBAL_FILTER_CHANGED';
  const CHART_TOGGLE = 'CHART_TOGGLE';
  const DISABLE_FETCHING = 'DISABLE_FETCHING';
  const ENABLE_FETCHING = 'ENABLE_FETCHING';
  const TABLE_STATS = 'TABLE_STATS';

  const reducer = (state, { type, payload }) => {
    switch (type) {
      case PAGE_CHANGED: {
        return {
          ...state,
          queryPageIndex: payload
        };
      }

      case PAGE_SIZE_CHANGED: {
        return {
          ...state
          //  queryPageIndex: payload,
        };
      }

      case GLOBAL_FILTER_CHANGED: {
        return {
          ...state,
          queryFilter: payload
        };
      }

      case FILTER_CHANGED: {
        const f = {};
        payload.forEach((p) => (f[p.id] = p.value));

        return {
          ...state,
          queryFilters: f
        };
      }
      case SORTER_CHANGED: {
        return {
          ...state,
          querySorter: payload
        };
      }

      case CHART_TOGGLE: {
        setLStrendChart(payload);
        return {
          ...state,
          chart: payload
        };
      }
      case DISABLE_FETCHING: {
        return {
          ...state,
          disableFetching: true
        };
      }
      case ENABLE_FETCHING: {
        return {
          ...state,
          disableFetching: false
        };
      }
      case TABLE_STATS: {
        return {
          ...state,
          stats: payload
        };
      }

      default:
        throw new Error(`Unhandled action type: ${type}`);
    }
  };

  const [isLive, setIsLive] = React.useState(false);

  const initialState = {
    queryFilter: {},
    chart: LStrendChart
  };

  const [tableState, dispatch] = React.useReducer(reducer, initialState);

  // remove if wabt to debounce
  //const debouncedQuery = useAsyncDebounce(query, 500);
  //causes bug in states
  // const fetch = ({ pageParam = 1 }) =>
  //   debouncedQuery({
  //     ...initialFilter,
  //     ...tableState.queryFilter,
  //     sort: tableState.querySorter,
  //     page: pageParam,
  //   });

  const fetchEvents = ({ pageParam = 1 }) =>
    query({
      ...initialFilter,
      ...tableState.queryFilter,
      sort: tableState.querySorter,
      page: pageParam
    });

  const {
    data,
    remove,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
    isLoading,
    isFetching,
    isRefetching,
    error
  } = useInfiniteQuery(
    [
      id,
      'search',
      initialFilter,
      tableState.querySorter,
      tableState?.queryFilter
    ],
    fetchEvents,
    {
      retry: 2,
      onError: (res) => {
        toast.error(res.message);
      },
      getNextPageParam: (lastPage, pages) => {
        return pages.length < lastPage.last_page ? pages.length + 1 : undefined;
      }
    }
  );

  const COLS = React.useMemo(() => columns || [], [user]);
  const DATA = React.useMemo(() => {
    const newData = [];
    data?.pages
      .map((i) => i.data)
      .forEach((i) => {
        newData.push(...i);
      });

    return newData;
  }, [data?.pages]);

  const {
    width: tableWidth,
    height: tableHeight,
    ref: tableRef
  } = useResizeDetector();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    setFilter,
    setAllFilters,
    visibleColumns,
    allColumns,
    selectedFlatRows,
    toggleAllRowsSelected,
    setColumnOrder,

    state: {
      pageIndex,
      pageSize,
      sortBy,
      globalFilter,
      filters,
      selectedRowIds
    }
  } = useTable(
    {
      initialState: React.useMemo(
        () => ({
          selectedRowIds: otherProps?.initialSelection ?? {},
          hiddenColumns: [
            ...(otherProps.hiddenColumns ?? []),
            ...(LShiddenColumns ?? [])
          ],
          columnOrder: LScolumnOrder ?? [],

          globalFilter: {}
        }),
        [data]
      ),

      stateReducer: (newState, action) => {
        if (action.type === 'toggleRowSelected') {
          newState.selectedRowIds = {
            [action.id]: action.value
          };
          newState.selectedFlatRows = {};
        }

        return newState;
      },

      columns: COLS,
      data: DATA,

      manualGlobalFilter: true,
      manualSortBy: true,
      autoResetSelectedRows: false
    },

    useGlobalFilter,

    // useGroupBy,
    useSortBy,
    useExpanded,
    useRowSelect,
    useRowState,
    useColumnOrder
  );

  React.useEffect(() => {
    if (sortBy.length == 0) return;
    let col = columns.find((col) => col.accessor == sortBy?.[0].id);
    let payload;
    if (!col?.newSorting)
      payload = { [sortBy?.[0].id]: sortBy?.[0].desc ? -1 : 1 };
    else if (col?.newSorting) payload = col[sortBy?.[0].desc ? 'desc' : 'asc'];

    dispatch({ type: 'SORTER_CHANGED', payload });
  }, [sortBy]);

  React.useEffect(() => {
    dispatch({ type: 'GLOBAL_FILTER_CHANGED', payload: globalFilter });
  }, [globalFilter]);

  return (
    <TableContext.Provider
      value={{
        error,
        tableRef,
        tableWidth,
        tableHeight,
        LSColumnsWidth,
        setLSColumnsWidth,
        recentFilters,
        setRecentFilters,
        rows,
        queryKey: [
          id,
          'search',
          tableState.querySorter,
          tableState.queryFilter
        ],
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        status,
        remove,
        refetch,
        id,
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        data,
        isLoading,
        isFetching,
        isRefetching,
        allColumns,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        visibleColumns,
        previousPage,
        setPageSize,
        setGlobalFilter,
        setFilter,
        setAllFilters,
        selectedFlatRows,
        toggleAllRowsSelected,
        state: {
          pageIndex,
          pageSize,
          sortBy,
          globalFilter,
          filters,
          selectedRowIds,
          live: isLive
        },
        selectedFlatRows,
        tableState,
        initialState,
        dispatch,
        SORTER_CHANGED,
        FILTER_CHANGED,
        PAGE_CHANGED,
        LShiddenColumns,
        setLSHiddenColumns,
        setIsLive,
        isLive,
        setColumnOrder,
        setLSColumnsOrder,
        ...otherProps
      }}
    >
      {children({
        error,
        tableRef,
        tableWidth,
        tableHeight,
        LSColumnsWidth,
        setLSColumnsWidth,
        recentFilters,
        setRecentFilters,
        rows,
        queryKey: [
          id,
          'search',
          tableState.querySorter,
          tableState.queryFilter
        ],
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        status,
        refetch,
        remove,
        id,
        data,
        isFetching,
        isRefetching,
        isLoading,
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        allColumns,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        visibleColumns,
        previousPage,
        setPageSize,
        setGlobalFilter,
        setFilter,
        setAllFilters,
        selectedFlatRows,
        toggleAllRowsSelected,
        state: {
          pageIndex,
          pageSize,
          sortBy,
          filters,
          globalFilter,
          selectedRowIds,
          live: isLive
        },
        tableState,
        initialState,
        dispatch,
        SORTER_CHANGED,
        FILTER_CHANGED,
        PAGE_CHANGED,
        LShiddenColumns,
        setLSHiddenColumns,
        setIsLive,
        isLive,
        setColumnOrder,
        setLSColumnsOrder,
        ...otherProps
      })}
    </TableContext.Provider>
  );
};

export const useHeadlessTable = () => {
  return React.useContext(TableContext);
};

export default InfiniteTableProvider;
