import React from 'react';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { VolumeOff, VolumeUp } from 'react-bootstrap-icons';
import { dateOnly, timeOnly } from 'utils/time';
const { format } = require('date-fns');
export default ({ time }) => {
  if (!time) return null;
  return (
    <article
      css={css`
        background: rgba(29, 32, 35, 0.8);
        border-radius: 8px;
        display: flex;
        align-items: center;
        gap: 0.3rem;
        padding: 0.5rem;
        > time {
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #ffffff;
        }
      `}
    >
      <time> {time && new Date(time).toLocaleString()}</time>
    </article>
  );
};
