import 'css/sidebar.css';
import React from 'react';
import { withSidebar } from 'components/with-sidebar';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import { useHeadlessTable } from 'providers/headless-table-provider';
import SidebarEmptyHolder from 'components/sidebar-empty-holder';
import UserAudit from './user-audit';
import { Badge } from 'components/badge';
import Clipboard from 'utils/clipboard';
import UserActivity from './user-activity';
import { PERMISSION_NAMES } from 'constants/permissions';
import { AvatarCircle } from 'components/avatar';

const styles = css`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  > section {
    padding: 1rem;
  }

  header {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 1rem;

    border-bottom: 1px solid #eceff3;

    > h2 {
      margin: 0px;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      color: var(--neutral-00);
    }
    > h3 {
      margin: 0px;

      font-size: 12px;
      line-height: 100%; /* identical to box height, or 12px */
      font-weight: 500;
      color: var(--neutral-01);

      color: var(--menu-item-color);
    }
  }
`;

const Sidebar = () => {
  const { selectedFlatRows } = useHeadlessTable();

  if (selectedFlatRows.length == 0) return <SidebarEmptyHolder />;

  const user = selectedFlatRows[0].original;

  function getPerm(string) {
    let data = [];

    if (!user.permission.includes(string)) data = ['danger', 'no'];
    else if (
      user.permission.includes(string + 'View') &&
      user.permission.includes(string + 'Edit')
    )
      data = ['success', 'full'];
    else if (
      user.permission.includes(string + 'View') &&
      !user.permission.includes(string + 'Edit')
    )
      data = ['gray', 'View only'];
    else if (
      !user.permission.includes(string + 'View') &&
      user.permission.includes(string + 'Edit')
    )
      data = ['gray', 'Edit Only'];

    return data;
  }

  return (
    <article css={styles}>
      <header>
        <AvatarCircle name={user?.name} size="60px" fontSize="20px" />
        <h2>{user?.name}</h2>
        <h3>{user?.email}</h3>
      </header>
      <section
        css={css`
          dl {
            margin: 0px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            width: 100%;
            align-items: center;
            gap: 0.65rem;
            dt:not(:first-child) {
              font-size: 12px;
            }
          }
        `}
      >
        <dl>
          <dt>
            <h4>Permissions</h4>
          </dt>
          <dd>
            {user.permission == 'fa' ? (
              <Badge color="success">Full</Badge>
            ) : (
              <Badge color="gray">Limited</Badge>
            )}
          </dd>
          {user.devices_view && (
            <>
              <dt>Devices</dt>
              <dd>
                <Badge color="success">YES</Badge>
              </dd>
            </>
          )}

          {user.permission != 'fa' && user.role == 'C' && (
            <>
              <dt>Manage Users</dt>
              <dd>
                {user.permission.includes('writeUsers') ? (
                  <Badge color="success">YES</Badge>
                ) : (
                  <Badge color="danger">NO</Badge>
                )}
              </dd>
              <dt>Sites</dt>
              <dd>
                {user.permission.includes('allSites') ? (
                  <Badge color="success">ALL Sites</Badge>
                ) : (
                  <Badge color="gray">LIMITED</Badge>
                )}
              </dd>
              <dt>Dashboard</dt>
              <dd>
                {user.permission.includes('dashboard') ? (
                  <Badge color="success">Yes</Badge>
                ) : (
                  <Badge color="gray">No</Badge>
                )}
              </dd>
              <dt>People</dt>
              <dd>
                <Badge color={getPerm('person')[0]}>
                  {getPerm('person')[1]}
                </Badge>
              </dd>
              <dt>Access Levels</dt>
              <dd>
                <Badge color={getPerm('al')[0]}>{getPerm('al')[1]}</Badge>
              </dd>
              <dt>Schedules</dt>
              <dd>
                <Badge color={getPerm('schedule')[0]}>
                  {getPerm('schedule')[1]}
                </Badge>
              </dd>
            </>
          )}

          {/* {user.permission
            .split(',')
            .sort()
            .filter((p) => p != 'fa')
            .map((p) => (
              <>
                <dt
                  css={css`
                    font-size: 12px;
                  `}
                >
                  {PERMISSION_NAMES[p]}
                </dt>
                <dd>
                  <Badge color="gray">dd</Badge>
                </dd>
              </>
            ))} */}
        </dl>
      </section>
      <section>
        <UserAudit title user={user} />
      </section>
      <UserActivity user={user} />
    </article>
  );
};

export default withSidebar({})(Sidebar);
