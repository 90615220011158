import React from 'react';
import Template from 'base/template';

import IndexContent from 'base/index-content';
import { useParams } from 'react-router-dom';
import { useQueryWrapper } from 'utils/ajax';
import { useNotFound } from 'hooks/not-found-hook';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import GenericDoor from 'api/generic_door';
import AccessLevelDoor from 'api/access_level_door';
import AccessLevel from 'api/access_level';
import Schedule from 'api/schedule';
import { useAuth } from 'providers/auth';

const cards = [
  {
    name: 'total',
    label: 'Access Levels'
  }
];

const columns = [
  {
    style: { width: '200px' },
    Header: 'Access Level',
    accessor: 'access_level_name',
    asc: 'atozAccessLevel',
    desc: 'ztoaAccessLevel',
    newSorter: true
  },
  {
    Header: 'Reader',
    accessor: 'reader_direction',
    disableSortBy: true,
    Cell: ({ cell }) => {
      let dir = cell.value == 'in' ? 'Entrance' : 'Exit';
      return (
        <span
          css={css`
            color: var(--secondary-font-color);
          `}
        >
          {dir} Reader
        </span>
      );
    }
  },
  {
    Header: 'Schedule',
    accessor: 'schedule_name',
    asc: 'atozSchedule',
    desc: 'ztoaSchedule',
    newSorter: true,
    Cell: ({
      value,
      row: {
        original: { schedule_token: token }
      }
    }) => {
      if (token == 'standard_always')
        return (
          <span
            css={css`
              color: var(--primary-base);
            `}
          >
            {value}
          </span>
        );
      else return <span>{value}</span>;
    }
  }
];

const DoorAccessLevels = (props) => {
  const { id } = useParams();

  const [door, setDoor] = React.useState();

  const query = useQueryWrapper(
    ['door', 'get', id],
    () => GenericDoor.search({ id }),
    {
      initialData: {
        data: [props?.location?.state?.door]
      }
    }
  );

  useNotFound(id, query, props);

  React.useEffect(() => {
    if (!query.data.data?.[0]) return;
    setDoor(query.data.data?.[0]);
  }, [query.data.data?.[0]]);

  const FILTERS = React.useMemo(
    () => [
      {
        open: true,
        id: 'recent',
        label: 'Recent Searches...',
        options: []
      },
      {
        open: true,
        id: 'filterBy',
        label: 'Filter By...',
        options: [
          {
            label: 'Schedule',
            value: 'schedule',
            fetchFn: Schedule.search,
            field: 'schedule',
            name: 'Schedule',
            id: 'id',
            idx: 'id'
          },
          {
            label: 'Access Level',
            value: 'accessLevel',
            fetchFn: AccessLevel.search,
            field: 'accessLevel',
            name: 'Access Level',
            id: 'id',
            idx: 'id'
          }
        ]
      }
    ],
    [door]
  );

  const { user } = useAuth();

  return (
    <Template {...props}>
      {door && (
        <IndexContent
          pageTitle={door.name}
          initialFilter={{
            door: door.token,
            excludeNoAccess: true,
            hasSite: user.focused_site
          }}
          newSorter
          statsQuery={AccessLevel.stats} // this is needed so we wont count 2 readers as 2 access levels
          id={`access-level-door-${door.token}`}
          cards={cards}
          query={AccessLevelDoor.search}
          columns={columns}
          isSidebarOpen={true}
          filterOptions={FILTERS}
          getRowId={(row, relativeIndex, parent) =>
            parent ? [parent.id, relativeIndex].join('.') : relativeIndex
          }
          {...props}
        ></IndexContent>
      )}
    </Template>
  );
};

export default DoorAccessLevels;
