import React from 'react';
import GridControls from './grid-controls';

import styled from '@emotion/styled';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Container } from './container';
import ContainerHeader from './container-header';
import AddWidgetDialog from './add-widget-dialog';
import EmptyHolder from './empty-holder';
import { IconCubePlus } from '@tabler/icons-react';
import doorWidget from './door-widget';
import sensorWidget from './sensor-widget';
import cameraWidget from './camera-widget';
import SidebarEmptyHolder from 'components/sidebar-empty-holder';
import { ReactComponent as MonitoringViewEmptyIcon } from 'assets/empty-icons/empty-monitoring-view.svg';
import cameraCarouselWidget from './camera-carousel-widget';
import liveEventsChartWidget from './live-events-chart-widget';

export const COMPONENTS = {
  door: doorWidget,
  camera: cameraWidget,
  sensor: sensorWidget,
  cameraCarousel: cameraCarouselWidget,
  liveSiteChart: liveEventsChartWidget
};
export default ({ selectedView, isEdit, setSelectedView }) => {
  let widgetContainer = selectedView.layout.find(
    (item) => item.type == 'widgets'
  );

  let index = selectedView.layout.findIndex((item) => item.type == 'widgets');

  if (!widgetContainer) return null;
  else
    return (
      <Container isEdit={isEdit}>
        <ContainerHeader
          selectedView={selectedView}
          isEdit={isEdit}
          index={index}
          setSelectedView={setSelectedView}
          fixed
        >
          <GridControls
            selectedView={selectedView}
            setSelectedView={setSelectedView}
            index={index}
          />
        </ContainerHeader>
        <div
          css={css`
            overflow: auto;
            height: 100%;
            display: grid;

            gap: 0.5rem;
            grid-template-rows: ${`repeat(${
              widgetContainer?.items?.length == 0 && !isEdit
                ? 1
                : widgetContainer?.rows || 1
            }, minmax(300px, 1fr))`};
            grid-template-columns: ${`repeat(${
              widgetContainer?.items?.length == 0 && !isEdit
                ? 1
                : widgetContainer?.cols || 1
            }, minmax(300px, 1fr))`};
          `}
        >
          {!isEdit && widgetContainer?.items?.length == 0 && (
            <div
              className="empty-holder"
              css={css`
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                grid-column: 1/-1;
                grid-row: 1/-1;
              `}
            >
              <SidebarEmptyHolder
                icon={MonitoringViewEmptyIcon}
                title="Empty View"
                subtitle="Toggle edit mode to edit your view"
              />
            </div>
          )}
          {widgetContainer?.items?.length > 0 &&
            widgetContainer?.items?.map((item, idx) => {
              let Component = COMPONENTS[item.type];
              if (!Component) return null;
              return (
                <div
                  key={idx}
                  css={css`
                    grid-row: ${`span ${item.rows ?? 1}`};
                    grid-column: ${`span ${item.cols ?? 1}`};
                    min-width: 0;
                    min-height: 300px;

                    //   overflow: hidden;
                    /* place-self: center;
                  //border: 2px solid black;
                  height: 100%;
                  width: 100%;
                  justify-content: center;
                  align-items: center;
                  display: flex; */
                  `}
                >
                  <Component
                    isEdit={isEdit}
                    doorId={item.id}
                    cameraId={item.id}
                    siteId={item.id}
                    sensor={item}
                    setSelectedView={setSelectedView}
                    selectedView={selectedView}
                    index={index}
                    widgetIndex={idx}
                  />
                </div>
              );
            })}
          {isEdit && (
            <AddWidgetDialog
              selectedView={selectedView}
              setSelectedView={setSelectedView}
              index={index}
            >
              <EmptyHolder>
                <IconCubePlus />
                <span>Add Widget</span>
              </EmptyHolder>
            </AddWidgetDialog>
          )}
        </div>
      </Container>
    );
};
