/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const styles = css`
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr 5fr;

  gap: 16px;
  align-items: stretch;
  flex: 1;
  height: 100%;
  overflow: hidden;
  section.usage {
  }

  > section {
    background: var(--neutral-09);
    box-shadow: 0px 5px 20px -5px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
  }

  > section.diag {
    grid-column: span 2;
    position: relative;
    overflow: hidden;
    height: 100%;

    display: flex;
    flex-direction: column;

    div.actions {
      position: absolute;
      right: 0;
      padding: 16px;
      .action-menu-wrapper {
        margin-left: auto;
        z-index: 12;
      }
    }
  }

  .trend,
  .heatmap {
    &:hover {
      [role='radiogroup'] {
        opacity: 1;
        transition: all 0.5 ease;
      }
    }
    padding: 0.5rem;
    min-height: 250px;
    height: 250px;
    position: relative;
    [role='radiogroup'] {
      opacity: 0;
      position: absolute;
      top: 0px;
      right: 0px;
      margin: 0.5rem;
      z-index: 10;
      display: flex;

      border: 0.5px solid var(--neutral-05);
    }
  }
`;
