import React from 'react';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import ReactECharts from 'echarts-for-react';
import { format, addHours, startOfHour } from 'date-fns';

const ActivityChart = ({
  activity = {},

  hours
}) => {
  const categories = Object.keys(hours).reverse();
  const values = Object.values(hours).reverse();

  const series0 = values.map((value) => (value === 0 ? 1 : 0));
  const series1 = values.map((value) => (value === 1 ? 1 : 0));
  const color0 = '#ea3737'; // Color for 0 Values
  const color1 = '#00a96c'; // Color for 1 Values

  const option = {
    animation: false,
    tooltip: {
      position: [10, -90],
      extraCssText: 'z-index: 19999;', // Set the z-index of the tooltip
      appendToBody: true,
      trigger: 'axis',
      formatter: function (params) {
        const categoryHour1 = format(new Date(params[0].axisValue), 'HH:mm'); // X-axis category

        const categoryHour2 = format(
          addHours(new Date(params[0].axisValue), 1),
          'HH:mm'
        ); // X-axis category

        const categoryDate = format(
          addHours(new Date(params[0].axisValue), 1),
          'MM-dd'
        ); // X-axis category

        // Customize the tooltip content
        return `
          <time style='font-size: 18px; font-weight: 700;'>${categoryHour1} - ${categoryHour2}</time><br/>
          <time style='font-size:12px;'>${categoryDate}</time><br/>
              ${
                hours?.[params[0].axisValue] === 0
                  ? `
                  
                    ${
                      activity?.[params[0].axisValue]
                        ? `<span style='font-size:22px; margin-top: 2px; color: #ea3737;'>
                    ${
                      activity?.[params[0].axisValue]
                    }</span><span> offline event(s)</span>`
                        : `<span style='color: #ea3737;'>OFFLINE</span>`
                    }`
                  : `<span style='color:#00a96c;'>OK</span>`
              }
              
            `;
      }
    },
    grid: {
      left: 10,
      right: 10
    },
    xAxis: {
      type: 'category',
      data: categories,
      boundaryGap: false, // Remove space at both ends
      splitLine: {
        show: false
      },
      axisLabel: {
        show: false
      },
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      }
    },
    yAxis: {
      axisLabel: {
        show: false
      }
    },

    series: [
      {
        name: 'offline',
        type: 'bar',
        stack: 'values',
        data: series0,
        color: color0,
        barCategoryGap: '0%', // Set the gap between categories to zero
        barGap: '0%',
        barWidth: 10
        // barGap: 1
      },
      {
        name: 'online',
        type: 'bar',
        stack: 'values',
        data: series1,
        color: color1,
        barWidth: 10,
        // barGap: 1
        barCategoryGap: '0%',
        barGap: '0%'
      }
    ]
  };

  return (
    <div
      css={css`
        svg {
          opacity: 80%;
        }
      `}
    >
      <ReactECharts
        lazyUpdate={true}
        opts={{ renderer: 'svg' }}
        option={option}
        style={{ padding: '0px', height: '20px', width: '200px' }}
        loadingOption={{
          showSpinner: false,
          maskColor: 'var(--neutral-09)',
          fontWeight: 'normal',
          text: 'Fetching Data...',

          fontStyle: 'normal',

          fontFamily: 'inherit'
        }}
      />
    </div>
  );
};

export default ActivityChart;
