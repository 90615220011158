import React from 'react';
import { Dialog, DialogContent, DialogTrigger } from 'components/dialog.js';
import { IconActivity, IconNetwork } from '@tabler/icons-react';
import { AuxiliaryButton } from 'components/buttons';
import { useLayout } from 'providers/layout-provider';
import { useEventSource } from 'react-use-websocket';
import { useAuth } from 'providers/auth';
import ReactECharts from 'echarts-for-react';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */

const DialogWrapper = ({ ar, open }) => {
  let { getSocketUrl } = useLayout();

  const { user } = useAuth();
  const seriesData = React.useRef([]);

  const [isRunning, setIsRunning] = React.useState(true);

  React.useEffect(() => {
    const cb = () => {
      if (document.visibilityState === 'visible') {
        setIsRunning(true);
      } else if (document.visibilityState === 'hidden') {
        seriesData.current = [];
        setIsRunning(false);
      }
    };

    document.addEventListener('visibilitychange', cb);

    return () => document.removeEventListener('visibilitychange', cb);
  }, []);

  React.useEffect(() => {
    seriesData.current = [];
    setData({});
  }, [open]);

  let filter = React.useCallback(
    (data) => {
      if (['feedUpdate'].includes(data?.type) && data.action_rule_id == ar.id)
        return true;
      return false;
    },
    [ar]
  );

  const { lastEvent, readyState } = useEventSource(
    getSocketUrl,
    {
      share: true,
      filter: (e) => filter(JSON.parse(e.data)),
      withCredentials: true,
      retryOnError: true
    },
    Boolean(user && !user.missing2fa && !user.missingMfa)
  );

  const [data, setData] = React.useState();

  let DEFAULT_OPTION = {
    tooltip: {
      trigger: 'axis'
    },

    grid: {
      show: false,
      top: 20,
      left: 80,
      right: 20,
      bottom: 50
      //  backgroundColor: '#e3e6ec'
    },
    dataZoom: {
      show: false,
      start: 0,
      end: 100
    },

    xAxis: [
      {
        axisLabel: { interval: 5 },
        axisLine: { show: false },
        triggerEvent: true,
        min: Date.now() - 1000 * 60,
        max: Date.now() + 100 * 60,
        type: 'time',
        minorTick: { show: false },

        splitLine: {
          show: true
        }
      }
    ],
    yAxis: [
      {
        boundaryGap: ['5%', '5%'],
        type: 'value'
      }
    ],

    animation: false
  };

  const [option, setOption] = React.useState(DEFAULT_OPTION);

  React.useEffect(() => {
    // in case nothing comes in from the SSE reset data
    if (!isRunning) return;
    let timer = setInterval(() => {}, 8000);
    setData((prev) => ({}));

    return () => clearInterval(timer);
  }, [isRunning]);

  React.useEffect(() => {
    if (!lastEvent) return;
    let data = JSON.parse(lastEvent.data);
    setData(data);

    seriesData.current.push({
      value: [new Date(), data.bytesReceived / 5]
      //itemStyle: { color: 'red' }
    });
  }, [lastEvent]);

  React.useEffect(() => {
    if (!isRunning) return;

    const timer = setInterval(() => {
      const opt = { ...option };
      opt.series = [
        {
          showSymbol: false,
          symbolSize: 0,
          data: seriesData.current,
          type: 'line'
          //lineStyle: { color: '#d5ceeb' }
        }
      ];
      opt.xAxis = [
        {
          triggerEvent: true,
          min: Date.now() - 1000 * 60,
          max: Date.now() + 100 * 60,
          type: 'time',
          minorTick: { show: false },

          splitLine: {
            show: true,
            lineStyle: {
              type: 'solid'
            }
          }
        }
      ];
      // clean lis of events for old events

      setOption(opt);
    }, 1000);

    return () => clearInterval(timer);
  }, [ar, isRunning]);

  return (
    <DialogContent title={ar.name + ' - Feed Stats (' + ar?.fps + 'fps)'}>
      <div
        css={css`
          display: flex;
          gap: 2rem;

          padding-inline: 1rem;
          dt {
            &[data-success='false'] {
              color: var(--light--others--red-400-base);
            }
            font-size: 48px;
          }
          dd {
            font-size: 16px;
            color: var(--light--neutrals--600);
          }
        `}
      >
        <dl
          css={css`
            min-width: 200px;
          `}
        >
          <dt>{data?.bytesReceived ? data?.bytesReceived / 5 : 0}</dt>
          <dd>Bytes /Sec</dd>
        </dl>

        <dl>
          <dt data-success={Number(data?.frames / 5) >= Number(ar.fps)}>
            {data?.frames ? data?.frames / 5 : 0}
          </dt>
          <dd>FRAMES /Sec</dd>
        </dl>
        <dl>
          <dt data-success={Number(data?.speed?.replace('x', '')) >= 1}>
            {data?.speed ?? 0}
          </dt>
          <dd>SPEED</dd>
        </dl>
      </div>
      <ReactECharts option={option} />
    </DialogContent>
  );
};

const LiveFeedStats = ({ ar }) => {
  const [open, setOpen] = React.useState();

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <AuxiliaryButton compact>
          <IconActivity />
          Monitor
        </AuxiliaryButton>
      </DialogTrigger>
      <DialogWrapper ar={ar} open={open} />
    </Dialog>
  );
};

export default LiveFeedStats;
