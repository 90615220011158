import React from 'react';
import { useHistory } from 'react-router-dom';
import { MenuWrapper, MenuItems, MenuItem } from 'components/action-menu';
import { useDeleteMutate } from './actions';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { StyledMenuButton } from 'components/action-menu/menu-item-button';

export default function ActionMenu({ data: camera = {}, ...otherProps }) {
  const history = useHistory();

  let handleEdit = (e) =>
    history.push('/cameras/save/' + camera?.id, {
      camera: camera,
      desc: camera?.name
    });

  let handleSetting = (e) =>
    history.push('/cameras/settings/' + camera?.id, {
      camera: camera,
      desc: camera?.name
    });

  // let handleDetails = (e) =>
  //   history.push(`/cameras/details/${camera.id}`, {
  //     camera,
  //     desc: camera?.name
  //   });

  let handleView = (e) =>
    history.push(`/cameras/view/${camera.id}`, {
      camera,
      desc: camera?.name
    });

  let handleMonitoring = (e) =>
    history.push(`/cameras/monitoring/${camera.id}`, {
      camera,
      desc: camera?.name
    });

  let handleRecordings = (e) =>
    history.push(`/cameras/recordings/${camera.id}`, {
      camera,
      desc: camera?.name
    });

  return (
    <MenuWrapper {...otherProps}>
      <MenuItem label="Edit" onClick={handleEdit} />
      <MenuItem
        disabled={camera?.online == 0}
        label="Settings"
        onClick={handleSetting}
      />
      <MenuItem label="View" onClick={handleView} />
      {/* <DropdownMenu.Root as="div">
        <DropdownMenu.TriggerItem asChild={true}>
          <StyledMenuButton>Recordings</StyledMenuButton>
        </DropdownMenu.TriggerItem>
        <MenuItems>
          <MenuItem label="Cloud Recordings" onClick={handleRecordings} />
          <MenuItem label="Edge Recordings" onClick={handleRecordings} />
        </MenuItems>
      </DropdownMenu.Root> */}

      {/* <MenuItem label="Details" onClick={handleDetails} /> */}
      {camera?.lpr && camera.lpr_enabled == 1 && (
        <MenuItem label="LPR Monitoring" onClick={handleMonitoring} />
      )}
    </MenuWrapper>
  );
}
