import React from 'react';
import { Popup, Tooltip, useMap } from 'react-leaflet';
import { Marker } from 'react-leaflet';
import { css } from '@emotion/react';

/** @jsxImportSource @emotion/react */
import ActionMenu from 'views/auxiliaries/action-menu';
import dataMarker from './data-marker';
import AuxiliaryStateAvatar from 'views/auxiliaries/auxiliary-state-avatar';
import Filters from 'views/events/filters';

function createAuxIcon(door) {
  //TODO add alarms
  const unlock = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-sliders" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M11.5 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM9.05 3a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0V3h9.05zM4.5 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM2.05 8a2.5 2.5 0 0 1 4.9 0H16v1H6.95a2.5 2.5 0 0 1-4.9 0H0V8h2.05zm9.45 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-2.45 1a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0v-1h9.05z"/>
</svg>
  
    <span class='indicator'>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.5577 16.1406H9.06402C8.07623 16.1406 7.27255 15.3442 7.27255 14.3654C7.27255 13.3863 8.07614 12.5898 9.06402 12.5898H14.7949C16.4674 12.5898 17.8279 11.2414 17.8279 9.58389C17.8279 7.92641 16.4674 6.57787 14.7949 6.57787H4.42719C4.14089 5.6637 3.28076 5 2.26425 5C1.0135 5 0 6.00442 0 7.24396C0 8.48349 1.0136 9.48791 2.26434 9.48791C3.318 9.48791 4.20373 8.77467 4.45634 7.80852H14.795C15.7826 7.80852 16.5863 8.605 16.5863 9.58394C16.5863 10.5629 15.7827 11.3594 14.795 11.3594H9.06411C7.39164 11.3594 6.03091 12.7078 6.03091 14.3654C6.03091 16.0229 7.39155 17.3712 9.06411 17.3712H19.5576C19.8274 18.3116 20.7003 19 21.7358 19C22.9865 19 24 17.9956 24 16.756C24 15.5165 22.9865 14.5121 21.7358 14.5121C20.7003 14.5121 19.8275 15.2004 19.5577 16.1406Z" fill="currentColor"/>
      </svg>
      </span>`;

  const lock = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-sliders" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M11.5 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM9.05 3a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0V3h9.05zM4.5 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM2.05 8a2.5 2.5 0 0 1 4.9 0H16v1H6.95a2.5 2.5 0 0 1-4.9 0H0V8h2.05zm9.45 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-2.45 1a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0v-1h9.05z"/>
</svg>
  
<span class='indicator'><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M20.5 5.6213C20.7929 5.32841 20.7929 4.85354 20.5 4.56064C20.2071 4.26775 19.7322 4.26775 19.4393 4.56064L16.7295 7.27044C16.2041 6.8381 15.5296 6.57787 14.7949 6.57787H4.42719C4.14089 5.66369 3.28076 5 2.26425 5C1.0135 5 0 6.00442 0 7.24396C0 8.48349 1.0136 9.48791 2.26434 9.48791C3.318 9.48791 4.20373 8.77467 4.45634 7.80852H14.795C15.1891 7.80852 15.5539 7.93534 15.85 8.14997L12.6406 11.3594H9.06411C7.39164 11.3594 6.03091 12.7078 6.03091 14.3654C6.03091 15.3435 6.50471 16.2139 7.23681 16.7632L4.53032 19.4697C4.23743 19.7626 4.23743 20.2374 4.53032 20.5303C4.82322 20.8232 5.29809 20.8232 5.59098 20.5303L20.5 5.6213ZM8.12394 15.876L11.4102 12.5898H9.06402C8.07614 12.5898 7.27255 13.3862 7.27255 14.3654C7.27255 15.0028 7.61338 15.5629 8.12394 15.876ZM11.4572 17.3712L12.6878 16.1406H19.5577C19.8275 15.2004 20.7003 14.5121 21.7358 14.5121C22.9865 14.5121 24 15.5165 24 16.756C24 17.9956 22.9865 19 21.7358 19C20.7003 19 19.8274 18.3116 19.5576 17.3712H11.4572Z" fill="currentColor"/>
</svg></span>`;

  const offline = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-sliders" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M11.5 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM9.05 3a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0V3h9.05zM4.5 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM2.05 8a2.5 2.5 0 0 1 4.9 0H16v1H6.95a2.5 2.5 0 0 1-4.9 0H0V8h2.05zm9.45 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-2.45 1a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0v-1h9.05z"/>
</svg>
  `;

  return dataMarker({
    iconSize: [24, 24],
    iconAnchor: [5, 5],
    className: `pin-square aux`,
    data: {
      online: door.online == 1,
      state: door.state == '1' ? 'Active' : 'Idle'
    },
    html:
      door.online == '0'
        ? offline
        : door.state == '0' || door.state == 'Locked'
        ? lock
        : unlock
  });
}

const MarkerPopUp = ({ aux }) => {
  //autoPan={false} closeOnEscapeKey={true} offset={L.point(200, 300)}

  return (
    <Popup autoPan={true}>
      <div
        css={css`
          min-width: 300px;
          width: 300px;

          header {
            padding: 1rem;
            border-bottom: 1px solid var(--neutral-05);
            display: grid;
            grid-template-columns: 50px 1fr auto;
            h3 {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }
          .actions {
            margin-left: auto;
          }
          section {
            h4 {
              margin-bottom: 0.5rem;
            }
            padding: 1rem;
          }
        `}
      >
        <header
          css={css`
            display: flex;
            align-items: center;
            gap: 1rem;
          `}
        >
          <AuxiliaryStateAvatar aux={aux} />
          <h3>{aux.name}</h3>
          <div className="actions">
            <ActionMenu noEdit={true} data={aux} simple />
          </div>

          <div className="actions">
            {/* qqqqqqqq<ActionMenu noEdit={true} data={door} simple /> */}
          </div>
        </header>
        <section></section>
      </div>
    </Popup>
  );
};
const AuxiliaryLayer = ({ auxiliaries, isEdit, geojson, filters }) => {
  const [auxiliaryMarkers, setAuxiliaryMarkers] = React.useState([]);

  React.useEffect(() => {
    if (!auxiliaries) return;
    let json = { ...geojson };
    auxiliaries?.forEach((dbAux, idx) => {
      let index = json.features.findIndex(
        (d) => d.properties.id == dbAux.id && d.properties.type == 'auxiliary'
      );

      if (index !== -1) {
        //door found
        json.features[index] = {
          ...json.features[index],
          properties: {
            ...json.features[index]['properties'],
            shape: 'marker',
            type: 'auxiliary',
            ...dbAux
          }
        };
      }
    });

    setAuxiliaryMarkers(
      json.features.filter(
        (f) => f.properties.type == 'auxiliary' && f.geometry.type == 'Point'
      )
    );
  }, [auxiliaries, geojson]);

  return auxiliaryMarkers?.map((m, key) => {
    let show = false;

    if (!filters.auxiliaries) return;

    if (filters.online && m.properties.online == '1') show = true;
    if (filters.offline && m.properties.online == '0') show = true;

    if (show)
      return (
        <Marker
          key={key}
          icon={createAuxIcon(m.properties)}
          position={m.geometry.coordinates.slice().reverse()}
          properties={m.properties}
        >
          {filters.tooltip && (
            <Tooltip permanent={true} className="fade-in">
              {m.properties.name}
            </Tooltip>
          )}
          {!filters.tooltip && (
            <Tooltip className="fade-in">{m.properties.name}</Tooltip>
          )}
          <MarkerPopUp aux={m.properties} />
        </Marker>
      );
  });
};

export default AuxiliaryLayer;
