import React from 'react';
import { useQueryWrapper } from 'utils/ajax';
import CameraAction from 'api/camera_action';
import { SdCard } from 'react-bootstrap-icons';
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import Spinner from 'components/spinner';
const CameraDisks = ({ camera }) => {
  if (!camera) throw new Error('no camera provided');

  const [SDDisk, setSDDisk] = React.useState();

  const { data: disks, isLoading } = useQueryWrapper(
    ['camera', 'disks', camera?.id],
    () => CameraAction.listDisks(camera.id),
    {
      enabled: Boolean(camera),
      staleTime: 120000
    }
  );

  React.useEffect(() => {
    setSDDisk(disks?.find((i) => i.diskid == 'SD_DISK'));
  }, [disks]);

  if (isLoading) return <Spinner />;

  if (SDDisk?.totalsize == 0 || !SDDisk)
    return (
      <span
        css={css`
          display: flex;
          align-items: center;
          gap: 1rem;
          width: 100%;
          color: var(--neutral-03);
          justify-content: center;
        `}
      >
        <SdCard size={22} />
        No SD Card Found
      </span>
    );

  return (
    <article>
      <section
        css={css`
          display: flex;
          flex-direction: column;
          gap: 1rem;
        `}
      >
        <div>SD CARD ({SDDisk.totalsize / 1000000} GB)</div>
        <meter id="disk_d" value={SDDisk.freesize / SDDisk.totalsize}>
          60%
        </meter>
        <span
          css={css`
            color: var(--neutral-03);
          `}
        >
          ({SDDisk.freesize / 1000000} GB) Free of {SDDisk.totalsize / 1000000}{' '}
          GB
        </span>
      </section>
    </article>
  );
};

export default CameraDisks;
