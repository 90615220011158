import React from 'react';
import { useHistory } from 'react-router-dom';

import { MenuItem, MenuItemDelete } from 'components/action-menu';
import { useDeleteMutate } from './actions';
import MenuWrapper from 'components/action-menu/menu-wrapper';
import { NEW_API_HOST_URL } from 'api/api-url';

import { BoxArrowInRight, PencilFill } from 'react-bootstrap-icons';
import { useAuth } from 'providers/auth';
import useAlertDialog from 'providers/alert-dialog-provider';
import { Receipt } from 'react-bootstrap-icons';
export default function ActionMenu({ data: installer, ...otherProps }) {
  const { hasAllPermissions, installerAccess } = useAuth();

  const history = useHistory();
  const deleteMutate = useDeleteMutate(installer);

  const confirm = useAlertDialog();

  let handleEdit = () =>
    history.push('/installers/edit/' + installer?.id, {
      installer,
      desc: installer?.name
    });

  const handleDelete = async () => {
    if (
      await confirm({
        description: `Are you sure you want to delete installer: ${installer.name} ?`
      })
    )
      deleteMutate.mutate(installer?.id, {});
  };

  const handleAccess = () =>
    installerAccess.mutate(installer?.id, {
      onSuccess: () => history.push('/companies')
    });

  const canWrite = hasAllPermissions('writeInstallers');

  return (
    <MenuWrapper {...otherProps}>
      <MenuItem label="Access" onClick={handleAccess}>
        <BoxArrowInRight />
      </MenuItem>
      <MenuItem disabled={!canWrite} label="Edit" onClick={handleEdit}>
        <PencilFill />
      </MenuItem>

      <a
        href={
          NEW_API_HOST_URL + `/installer/exportBillingObjects/${installer?.id}`
        }
        download="_"
        target="_blank"
        rel="noreferrer noopener"
      >
        <MenuItem label="Export Billing Objects CSV...">
          <Receipt />
        </MenuItem>
      </a>
      <MenuItemDelete disabled={!canWrite} onClick={handleDelete} />
    </MenuWrapper>
  );
}
