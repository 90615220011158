import React from 'react';
/** @jsxImportSource @emotion/react */
import { ReactComponent as NoRecordings } from 'assets/images/no-recordings.svg';

import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Layer } from 'components/video';
import { OverlayHolder } from './overlay';

export default () => {
  return (
    <Layer>
      <OverlayHolder>
        <NoRecordings />
        <label>No recording found</label>
      </OverlayHolder>
    </Layer>
  );
};
