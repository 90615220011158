import { dataFetchQuery } from 'utils/ajax';

import { API_HOST_URL } from './api-url';
const API_URL = API_HOST_URL + '/generic_door_api';

const GenericDoor = {
  search(input = {}) {
    return dataFetchQuery(`${API_URL}/search`, input);
  },
  getTrends(input, id = '') {
    return dataFetchQuery(`${API_URL}/getTrends/${id}`, input);
  },
  get(id) {
    return dataFetchQuery(`${API_URL}/get/${id}`);
  },

  getStats(input = {}) {
    return dataFetchQuery(`${API_URL}/getStats`, input);
  },
  save(input = {}, id = '') {
    return dataFetchQuery(`${API_URL}/save/` + id, input);
  },
  delete(id = '') {
    return dataFetchQuery(`${API_URL}/delete/` + id);
  },

  access(id = '') {
    return dataFetchQuery(`${API_URL}/accessDoor/` + id);
  },
  unlock(id = '') {
    return dataFetchQuery(`${API_URL}/unLockDoor/` + id);
  },
  lock(id = '') {
    return dataFetchQuery(`${API_URL}/lockDoor/` + id);
  },
  getDoorState(id = '') {
    return dataFetchQuery(`${API_URL}/getDoorState/` + id);
  },
  export(input = {}) {
    return dataFetchQuery(`${API_URL}/export`, input);
  },

  getNextOpenTime(id = '') {
    return dataFetchQuery(`${API_URL}/getNextOpenTime/${id}`);
  },
  getAll(input) {
    return dataFetchQuery(`${API_URL}/getAll`, input);
  },

  getDailyCount(input) {
    return dataFetchQuery(`${API_URL}/getDailyCount`, input);
  },
  acknowledgeAlert(input) {
    return dataFetchQuery(`${API_URL}/acknowledgeAlert`, input);
  }
};

export default GenericDoor;
