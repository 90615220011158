import React from "react";
import { css } from "@emotion/react";
/** @jsxImportSource @emotion/react */

import chroma from "chroma-js";

const styles = (props) => css`
  display: flex;
  align-items: center;
  padding: 0 8;
  width: fit-content;
  height: 14px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 500;
  color: ${Math.round(chroma(props.color).luminance()) == 1
    ? "black"
    : "var(--neutral-09)"};
  background: ${props.color};
  border-radius: 50px;
  letter-spacing: 0.85;
`;
const GroupLabel = ({ color, label }) => {
  return (
    <div css={styles({ color })} role="group">
      {label}
    </div>
  );
};

export default GroupLabel;
