/** @jsxImportSource @emotion/react */
import React from 'react';
import Notifications from '../components/notifications';
import User from '../components/user';
import { useAuth } from 'providers/auth';
import { useLayout } from 'providers/layout-provider';
import KeyboardSCModal from 'components/keyboard-sc-modal';
import { List, Map, Record2 } from 'react-bootstrap-icons';
import { useHistory, useRouteMatch } from 'react-router-dom';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import ControllerStats from 'components/controller-stats';
//import SiteFocus from "components/site-focus";

import CompanyManage from 'components/company-manage';
import SiteFocus from 'components/site-focus';

import { DialogDisclosure } from 'reakit/Dialog';
import RenderLogo from 'utils/use-logo';
import InstallerAccess from 'components/installer-access';
import useBuildVersion from 'hooks/build-version-hook';
import BuildAlert from './build-alert';
import { Link } from 'react-router-dom';
import IntercomNotification from 'components/intercom-notification';
import { IconLayoutSidebar, IconMapSearch } from '@tabler/icons-react';
import { IconRotateRectangle } from '@tabler/icons-react';
import SidePanel from 'components/side-panel';
const Nav = styled('nav')`
  z-index: 2000;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: var(--neutral-09);
  grid-area: top-bar;
  position: static;
  box-shadow: inset 0px -1px 0px var(--neutral-10);
`;

const RightNavSection = styled('div')`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  align-items: center;
  padding: 0px 16px;

  height: 36px;
  gap: 32px;
`;

const ManagementSection = styled('section')`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  color: var(--neutral-00);

  //font-weight: bold;
  width: auto;
  top: 0%;
  bottom: 0%;

  border: 1px solid var(--neutral-12);
  box-sizing: border-box;
  border-radius: 5px;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  > button {
    /* /  font-size: 12px; */
    font-weight: bold;
    padding: 4px;
    height: 100%;
  }

  > button:not(:first-child) {
    border-left: 1px solid var(--neutral-12);
  }
`;

const LeftNavSection = styled('div')`
  display: flex;
  align-items: center;
  margin-left: 20px;

  > button {
    margin-right: 27px;
  }

  > svg {
    margin-right: 25px;
    padding-right: 25px;
    border-right: 1px solid var(--neutral-12);
    height: 24px;
  }
`;

const TopBar = (props) => {
  const { user } = useAuth();

  if (!user) return null;
  const { liveEventsDialog, setShowMenu, readyState, upBuildNo, localVersion } =
    useLayout();

  const match = useRouteMatch();

  const isOverview = match.path.includes('overview');

  return (
    <Nav>
      <LeftNavSection>
        <button
          title="Show sidebar"
          onClick={(e) => setShowMenu((prev) => !prev)}
        >
          <IconLayoutSidebar
            size="24"
            css={css`
              color: var(--primary-base);
            `}
          />
        </button>
        <RenderLogo
          css={css`
            // height: 50px;
            width: 150px;
          `}
        />

        <ControllerStats />
      </LeftNavSection>
      <RightNavSection>
        <ManagementSection>
          {(user.role == 'V' || user.role == 'A' || user.accessing) &&
            (!user.managing || user.accessing) && <InstallerAccess />}
          {(user.role == 'I' || user.role == 'V' || user.role == 'A') && (
            <CompanyManage />
          )}
          {user.company_id && <SiteFocus />}
          {(user.features?.overview_enabled == 'on' ||
            user.features?.overview_enabled == true) && (
            /** If Passing specific compoenent u need to aso use the navigation prop and pass it to an onclikc handler */
            <Link
              to="/overview"
              css={css`
                color: var(--primary-base);
                margin-right: 4px;
              `}
            >
              <IconMapSearch
                color={`${isOverview ? 'currentColor' : 'var(--neutral-03)'}
                    `}
              />
            </Link>
          )}
        </ManagementSection>

        {user?.company_id && <IntercomNotification />}
        {/* <DialogDisclosure {...liveEventsDialog} data-socket-state={readyState}>
          <Record2
            size={32}
            color={readyState == 1 ? 'var(--primary-base)' : 'red'}
          />
        </DialogDisclosure> */}
        <SidePanel />
        <Notifications />
        <User />
        {upBuildNo && upBuildNo !== localVersion && (
          <button
            onClick={() => {
              if ('serviceWorker' in navigator) {
                navigator.serviceWorker
                  .getRegistrations()
                  .then(function (registrations) {
                    for (let registration of registrations) {
                      registration.unregister();
                    }
                  });
              }
              window.location.reload(true);
            }}
          >
            <IconRotateRectangle />
          </button>
        )}
        {/* <BuildAlert /> */}
      </RightNavSection>
    </Nav>
  );
};

export default TopBar;
