import React from 'react';
/** @jsxImportSource @emotion/react */
import { ReactComponent as NoRecordings } from 'assets/images/no-recordings.svg';

import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Layer } from 'components/video';

export const OverlayHolder = styled('article')`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  object-fit: contain;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #59606c 8.33%,
    rgba(89, 96, 108, 0) 100%
  );
  label {
    color: white;
    text-align: center;
  }
  svg > * {
    fill: transparent;
    stroke: white;
  }
  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
