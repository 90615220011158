import React from 'react';
//import { Tabs, Tab } from "components/tabs";
import Template from 'base/template';
import { Redirect } from 'react-router-dom';
import {
  TabItem,
  TabGroup,
  TabPanel,
  TabPanels,
  TabList
} from 'components/tabs';
import Content from 'base/content';
import { css } from '@emotion/react';
import PageTitle from 'components/page-title';
import HealthChart from 'components/charts/health-chart';
import InfiniteTableProvider from 'providers/headless-infinite-table-provider';
import TableProvider from 'providers/headless-table-provider';
import { RadioGroup } from '@headlessui/react';
import Table from 'components/headless-table';
import Event from 'api/event';
import Report from 'api/report';
import SecurityIssue from 'api/security_issue';
import { columns } from 'views/events/table-columns';
import { columns as securityIssuesColumns } from 'views/security-issues/table-columns';
import { columns as reportsColumns } from 'views/reports/table-columns';
import WhoseInList from 'components/whosein-list';
import ActionMenu from 'views/doors/action-menu';
import { AuxiliaryButton, IconButton } from 'components/buttons';
import TableFilters from 'components/headless-filters';
import InfiniteList from 'components/headless-infinite-list';
/** @jsxImportSource @emotion/react */
import EventTable from 'routes/events/event-table';
import TablePagination from 'components/headless-pagination';
import { useQueryWrapper } from 'utils/ajax';
import { useParams } from 'react-router-dom';
/** @jsxImportSource @emotion/react */

import { useNotFound } from 'hooks/not-found-hook';
import GenericDoor from 'api/generic_door';
import UsageChartHolder from 'components/charts/usage-chart-holder';
import EventsTrendChart from 'components/charts/events-trend-chart';
import DoorTrendChart from 'components/charts/door-trend-chart';
import { Calendar2Range, GraphDown } from 'react-bootstrap-icons';
import Breadcrumbs from 'components/breadcrumbs';
import Person from 'api/person';
import Department from 'api/department';
import { Option as PersonOption } from 'components/form/person-select';
import { Option as DepartmentOption } from 'components/form/department-select';
const styles = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: stretch;
  flex: 1;
  height: 100%;
  overflow: hidden;
  section.usage {
  }

  > section {
    background: var(--neutral-09);
    box-shadow: 0px 5px 20px -5px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
  }

  > section.diag {
    position: relative;
    overflow: hidden;
    height: 100%;

    display: flex;
    flex-direction: column;

    div.actions {
      position: absolute;
      right: 0;
      padding: 16px;
      .action-menu-wrapper {
        margin-left: auto;
        z-index: 12;
      }
    }
  }

  .trend,
  .heatmap {
    &:hover {
      [role='radiogroup'] {
        opacity: 1;
        transition: all 200ms ease;
      }
    }
    padding: 0.5rem;
    min-height: 250px;
    height: 250px;
    position: relative;
    [role='radiogroup'] {
      transition: all 200ms ease;
      opacity: 0;
      position: absolute;
      top: 0px;
      right: 0px;
      margin: 0.5rem;
      z-index: 10;
      display: flex;

      border: 0.5px solid var(--neutral-05);
    }
  }
`;

export const FILTERS = [
  {
    open: true,
    id: 'recent',
    label: 'Recent Searches...',
    options: []
  },
  {
    open: true,
    id: 'filterBy',
    label: 'Filter By...',
    options: [
      {
        label: 'person',
        name: 'Person',
        value: 'person',
        id: 'person',
        fetchFn: Person.search,
        field: 'person_id',
        option: PersonOption,
        slim: true
      },
      {
        label: 'department',
        name: 'Department',
        value: 'department',
        id: 'department',
        fetchFn: Department.search,
        option: DepartmentOption,
        field: 'department_id',
        label: 'Name'
      }
    ]
  }
];

const DoorDetails = (props) => {
  // let door = props?.location?.state?.door || null;

  const { token } = useParams();

  const [door, setDoor] = React.useState();

  const query = useQueryWrapper(
    ['door', 'get', token],
    () => GenericDoor.search({ door_token: token }),
    {
      initialData: {
        data: [props?.location?.state?.door]
      }
    }
  );

  useNotFound(token, query, props);

  React.useEffect(() => {
    if (!query.data.data?.[0]) return;
    setDoor(query.data.data?.[0]);
  }, [query.data.data?.[0]]);

  const [chartType, setChartType] = React.useState('trend');
  return (
    <Template {...props}>
      <Content {...props}>
        <PageTitle>{door?.name}</PageTitle>
        <Breadcrumbs crumbs={props.crumbs} />

        {door && (
          <article css={styles}>
            <section className="trend">
              {/* <IconButton
                css={css`
                  position: absolute;
                  top: 0px;
                  right: 0px;
                  z-index: 100;
                  margin: 0.5rem;
                `}
                onClick={() =>
                  setChartType((prev) =>
                    prev == "trend" ? "heatmap" : "trend"
                  )
                }
              >
                {chartType == "trend" ? <Calendar2Range /> : <GraphDown />}
              </IconButton> */}
              <RadioGroup value={chartType} onChange={setChartType}>
                <RadioGroup.Option value="trend">
                  {({ checked }) => (
                    <IconButton data-active={checked}>
                      <GraphDown />
                    </IconButton>
                  )}
                </RadioGroup.Option>
                <RadioGroup.Option value="heatmap">
                  {({ checked }) => (
                    <IconButton data-active={checked}>
                      <Calendar2Range />
                    </IconButton>
                  )}
                </RadioGroup.Option>
              </RadioGroup>
              {chartType == 'trend' ? (
                <DoorTrendChart door_id={door?.id} />
              ) : (
                <UsageChartHolder
                  name={door.name}
                  filters={{ door_id: door?.id }}
                  type="heatmap"
                />
              )}
            </section>

            <section className="diag">
              <div className="actions">
                <ActionMenu noEdit={true} data={door} simple />
              </div>

              <TabGroup>
                <TabList
                  css={css`
                    min-width: 40vw;
                    width: auto;
                    padding: 0 24px;
                  `}
                >
                  <TabItem>Events</TabItem>
                </TabList>
                <TabPanels>
                  <TabPanel
                    as="div"
                    css={css`
                      overflow: hidden;
                      height: 100%;
                    `}
                  >
                    <EventTable
                      filterOptions={FILTERS}
                      selectedColumns={[
                        'createdAt',
                        'type',
                        'level',
                        'door_name',
                        'device_name',
                        'p_name'
                      ]}
                      eventSelectTypes={[
                        'accessGranted',
                        'accessDenied',
                        'reason',
                        'level'
                      ]}
                      id={['events-door', door?.id]}
                      initialFilter={{ door_id: Number(door?.id) }}
                    />
                  </TabPanel>
                </TabPanels>
              </TabGroup>
            </section>
          </article>
        )}
      </Content>
    </Template>
  );
};

export default DoorDetails;
