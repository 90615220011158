import React from 'react';
import Template from 'base/template';

import Person from 'api/person';
import { columns } from 'views/people/table-columns';
import Sidebar from 'views/people/sidebar';
import IndexContent from 'base/index-content';
import { PersonCheck, XLg } from 'react-bootstrap-icons';
import ActionMenu from 'views/people/action-menu';
import { useAuth } from 'providers/auth';
import Department from 'api/department';
import { Option as DepartmentOption } from 'components/form/department-select';
import { ReactComponent as EmptyPerson } from 'assets/empty-icons/empty-person.svg';
import parse from 'date-fns/parse';
/** @jsxImportSource @emotion/react */

const cards = [
  {
    name: 'total',
    label: 'Total'
  },
  {
    name: 'no_department',
    label: 'No department',
    color: 'var(--red-base)',
    background: 'var(--stats-background-red)',
    filter: { noDepartment: true },
    icon: () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon-tabler icon-tabler-users-group"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="#c60000"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <line x1="0" y1="0" x2="24" y2="24" stroke="#c60000" stroke-width="2" />
        <path d="M10 13a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
        <path d="M8 21v-1a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2v1" />
        <path d="M15 5a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
        <path d="M17 10h2a2 2 0 0 1 2 2v1" />
        <path d="M5 5a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
        <path d="M3 13v-1a2 2 0 0 1 2 -2h2" />
      </svg>
    )
  },

  {
    name: 'barred',
    label: 'Barred',
    color: 'var(--red-base)',
    background: 'var(--stats-background-red)',
    filter: { enabled: false },
    icon: XLg
  },
  {
    name: 'updated_recently',
    label: 'Recently Updated',
    color: 'var(--primary-base)',
    background: 'var(--stats-background-green)',
    filter: { updatedRecently: true },
    icon: PersonCheck
  }
];

export const FILTERS = [
  {
    id: 'recent',
    label: 'Recent Searches...',
    options: []
  },
  {
    id: 'filters',
    label: 'Filters',
    options: [
      {
        value: ['enabled', true],
        label: 'Active',
        type: 'common'
      },
      {
        value: ['enabled', false],
        label: 'Barred',
        type: 'common'
      }
    ]
  },
  {
    open: true,
    id: 'filterBy',
    label: 'Filter By...',
    options: [
      {
        label: 'Department',
        value: 'site',
        fetchFn: Department.search,
        field: 'department',
        option: DepartmentOption,
        name: 'Department',
        id: 'department'
      },
      {
        custom: true,
        label: 'Employee ID ',
        field: 'employeeId',
        name: 'Employee Id',

        helper: 'Search by Employee ID',
        options: []
      },
      {
        custom: true,
        label: 'Date Inserted ',
        field: 'insertedAfter',
        name: 'Date Inserted',
        validate: (val) => {
          let d = parse(val, 'MM/dd/yyyy', new Date());

          return d instanceof Date && !isNaN(d);
        },
        helper: 'Insert a valid date above mm/dd/yy',
        options: [
          {
            value: 'yesterday',
            label: 'Past Day'
          },
          {
            value: '-7 days',
            label: 'Past Week'
          },
          {
            value: '-30 days',
            label: 'Past Month'
          }
        ]
      }
    ]
  }
];

const People = (props) => {
  const { enforce, user } = useAuth();
  return (
    <Template {...props}>
      <IndexContent
        newSorter
        initialSorter={[
          {
            id: 'update_time',
            sortBy: 'newestUpdated',
            desc: true
          }
        ]}
        initialFilter={{
          hasSite: user.focused_site
        }}
        statsQuery={Person.stats}
        title="People"
        id="people"
        cards={cards}
        query={Person.search}
        columns={columns}
        addButton={
          enforce({ restrictIfCompanyUser: ['personEdit'] })
            ? 'Add a Person'
            : false
        }
        sidebar={Sidebar}
        selectable
        actionButton={ActionMenu}
        filterOptions={FILTERS}
        emptyIcon={EmptyPerson}
        multi
        export
        {...props}
      ></IndexContent>
    </Template>
  );
};

export default People;
