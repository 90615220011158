import React from "react";
import { css } from "@emotion/react";
/** @jsxImportSource @emotion/react */
import Label from "components/label";
import { ModalTitle } from "components/modal";
import { XCircle } from "react-bootstrap-icons";
import { Button } from "components/buttons";
import { Link, useHistory } from "react-router-dom";
import { useModal } from "providers/modal-provider";

import { ReactComponent as NoIssues } from "assets/images/no-issues-controllers.svg";
import DashboardEmptyHolder from "views/dashboard/dashboard-empty-holder";
const styles = css`
  .noissues {
    height: 60%;
  }
  width: 80vh;
  min-height: 40vh;
  height: 40vh;
  max-height: 70vh;
  min-width: 30vw;

  padding: 24px;
  overflow-y: auto;
  background: var(--sync-check-report-modal-backgorund);

  .summary {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;

    position: static;
    height: 56px;
    left: 24px;
    top: 24px;
    background: var(--red-v-02);
    border: 1px solid var(--neutral-12);
    border-radius: 4px;
    .description {
      font-size: 18px;
      display: flex;
      font-style: bold;
      font-weight: 700;
      color: var(--neutral-00);
      margin: 8px 0px;
    }
    .note {
      font-size: 18px;
      display: flex;
      color: var(--neutral-00);
      margin: 8px 8px;
    }
  }

  table {
    hight: 400px;
    margin: 30px 0px;
    width: 75.5vh;
    background: var(--neutral-09);
    border-radius: 5px;
    text-align: left;
    table-layout: fixed;
    border-spacing: 20px;
    border-bottom: 0px;
    position: relative;
    border: 1px solid var(--neutral-12);

    thead {
      color: var(--neutral-02);
    }

    tbody {
      color: var(--neutral-00);
    }
  }
  .footer {
    color: var(--neutral-00);
    padding-bottom: 8px;
    margin-right: 8px;
    margin-top: auto;
  }
`;

const SyncCheckModal = ({ data, ...props }) => {
  const handleJSONDownload = (data) => {
    var blob = new Blob([JSON.stringify(data, null, 2)], {
      type: "text/csv;charset=utf-8;",
    });
    var link = document.createElement("a");

    // Browsers that support HTML5 download attribute
    var url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "report.csv");
    link.style = "visibility:hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const { dialog, setModalState } = useModal();
  const history = useHistory();

  console.log("dodo", Object.values(data.errors), Object.keys(data.errors));
  console.log("dada", Object.keys(data.errors));
  if (!data) return null;
  ///<div className="note">
  // * Only connected controllers are shown
  // </div>////
  return (
    <>
      {/* company name */}
      <ModalTitle>
        Sync Report - {data.company_name} <Label>{data.type}</Label>
      </ModalTitle>

      {typeof data.errors == "object" ? (
        <div css={styles}>
          {Object.values(data.errors).length > 0 ? (
            <div
              css={css`
                display: flex;
                flex-direction: column;
                height: 100%;
              `}
            >
              <div className="summary">
                <XCircle
                  size={24}
                  fill="#ea3737"
                  css={css`
                    display: flex;
                    margin: 16px;
                  `}
                />
                <div>
                  <div className="description">
                    <span
                      css={css`
                        color: red;
                        margin: 0px 8px;
                      `}
                    >
                      {Object.values(data.errors).length}
                    </span>
                    out of {data?.controllersCount} Controllers with errors are
                    found.
                  </div>
                </div>
              </div>

              <table>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Controller Name</th>
                    <th>Company Name</th>
                  </tr>
                </thead>

                <tbody>
                  {Object.entries(data.errors)
                    .slice(0, 8)
                    .map(([id, cont], i) => {
                      console.log("sdsd", cont);
                      return (
                        <tr>
                          <td>{id}</td>
                          <td>{cont.controller_name}</td>
                          <td>{data?.company_name}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>

              <span
                css={css`
                  margin: 0px 6px;
                  display: flex;
                  align-items: center;
                  gap: 0.3rem;
                  color: var(--neutral-00);
                `}
              >
                This is a partial table. For more information please go to
                <Link to="/devices" onClick={dialog.hide}>
                  Device Page
                </Link>
              </span>

              <div className="footer">
                <Button
                  onClick={() => {
                    handleJSONDownload(data);
                  }}
                >
                  Download Full JSON Report
                </Button>
              </div>
            </div>
          ) : (
            <div>
              <DashboardEmptyHolder
                title="No Device Issues"
                subtitle="All Devices are normal"
              >
                <NoIssues />
              </DashboardEmptyHolder>
            </div>
          )}
          {/* <ReactJson iconStyle="circle" src={data} name={null} /> */}
        </div>
      ) : (
        <div css={styles}>
          <div
            css={css`
              padding-bottom: 12px;
            `}
          >
            The JSON Could not be Parsed
          </div>
          <Button
            onClick={() => {
              handleJSONDownload(data);
            }}
          >
            Download Full JSON Report
          </Button>
        </div>
      )}
    </>
  );
};

export default SyncCheckModal;
