import React from "react";
import { Tab } from "@headlessui/react";
import { Fragment } from "react";

const TabPanels = ({ children, ...otherProps }) => {
  return (
    <Tab.Panels as={Fragment} {...otherProps}>
      {children}
    </Tab.Panels>
  );
};

export default TabPanels;
