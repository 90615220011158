import React from 'react';
import {
  IconAlertTriangleFilled,
  IconAlertTriangle
} from '@tabler/icons-react';
import { ALERTS, isWithinDays } from './device-alerts';
import { useAuth } from 'providers/auth';
import IsRole from './is-role';

export default ({ device }) => {
  if (!device) return null;
  const { user } = useAuth();
  let alerts = null;

  // if (
  //   device[i] &&
  //   isWithinDays(new Date(device[i]), user.role == 'C' ? 3 : 7)
  // )
  for (let i of ALERTS) {
    // if (
    //   device[i] &&
    //   isWithinDays(new Date(device[i]), user.role == 'C' ? 3 : 7)
    // )
    if (device[i]) alerts = true;
  }
  if (!alerts) return null;

  return (
    <IsRole role={['A', 'V', 'I']}>
      <IconAlertTriangle
        className="alert-indicator"
        size="20"
        color="var(--red-v-01)"
      />
    </IsRole>
  );
};
