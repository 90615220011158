import { callAPI } from 'utils/ajax';

const AudioClip = {
  search(input = {}) {
    return callAPI('GET', `/audio-clip`, input);
  },

  save(name, file) {
    return callAPI('POST', `/audio-clip?name=${name}`, file, {
      keepBody: true
    });
  },
  delete(id) {
    return callAPI('DELETE', `/audio-clip/` + id);
  }
};

export default AudioClip;
