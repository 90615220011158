import React from 'react';

import CheckBox from 'components/form/checkbox';
import {
  parseDoorMonitor,
  parseLock,
  parseDoubleLock,
  parseReader,
  parseRex
} from 'utils/ac_config_parser';
import { Field } from 'formik';
import { useFormikContext } from 'formik';
import DoorMonitorSelect from './door-monitor-select';
import LockSelect from './lock-select';
import ReaderTypeSelect from './reader-type-select';
import RexSelect from './rex-select';

import { css } from '@emotion/react';
import PinConfiguration from './pin-configuration';
/** @jsxImportSource @emotion/react */

const styles = css`
  display: grid;
  grid-template-columns: var(--form-column-width) var(--form-column-width);
  gap: 24px;
  > section {
    display: flex;
    flex-direction: column;
    gap: 36px;
  }
`;

const ReaderDetails = ({ configuration, index, ac }) => {
  const { values, setFieldValue } = useFormikContext();

  return (
    <>
      <PinConfiguration index={index} />
      <article className="reader-details" css={styles}>
        <section>
          <Field
            name={`doors[${index}].lock`}
            component={LockSelect}
            product={ac.product}
            isRelay={
              (values.doors.length == 2 && index == 1) ||
              values.doors.length == 1
            }
          />

          {values.doors.length < 2 && ac.product != 'A1210' && (
            <Field
              isRelay={
                values.doors[index].lock !== 'relay_locked' &&
                values.doors[index].lock !== 'relay_unlocked'
              }
              double
              title="Lock #2 (Double Lock)"
              name={`doors[${index}].double_lock`}
              component={LockSelect}
              product={ac.product}
            />
          )}
          <Field name={`doors[${index}].reader`} component={ReaderTypeSelect} />

          {values.doors.length < 2 && ac.product != 'A1210' && (
            <Field
              exit
              name={`doors[${index}].reader_exit`}
              component={ReaderTypeSelect}
              title="Reader Exit"
            />
          )}
        </section>
        <section>
          <Field
            name={`doors[${index}].door_monitor`}
            component={DoorMonitorSelect}
          />

          <Field exit name={`doors[${index}].rex`} component={RexSelect} />

          <CheckBox
            name={`doors.[${index}].rex_open`}
            label="Rex Opens Door?"
            disabled={values.doors[index].rex == 'None'}
            checked={values.doors[index].rex_open}
            onChange={(e) =>
              setFieldValue(`doors[${index}].rex_open`, e.target.checked)
            }
          />
        </section>
      </article>
    </>
  );
};

export default ReaderDetails;
