import { dataFetchQuery } from 'utils/ajax';
import { API_HOST_URL } from './api-url';
const API_URL = API_HOST_URL + '/elevator_floor_api';
import { callAPI } from 'utils/ajax';

const ElevatorFloor = {
  search(input = {}) {
    return callAPI('GET', `/elevator_floor`, input);
  },

  stats(input = {}) {
    return callAPI('GET', `/elevator_floor/stats`, input);
  },

  save(input = {}, token) {
    return dataFetchQuery(`${API_URL}/save/${token}`, input);
  }
};

export default ElevatorFloor;
