import User from 'api/user';
import React from 'react';
import Template from 'base/template';
import { columns } from 'views/users/table-columns';
import IndexContent from 'base/index-content';
/** @jsxImportSource @emotion/react */
import { XLg, Check, Key } from 'react-bootstrap-icons';
import ActionMenu from 'views/users/action-menu';
import Sidebar from 'views/users/sidebar';
import { useAuth } from 'providers/auth';
import { ReactComponent as EmptyUser } from 'assets/empty-icons/empty-user.svg';

const cards = [
  {
    name: 'total',
    label: 'Total'
  },
  {
    name: 'disabled',
    label: 'Disabled',
    color: 'var(--red-base)',
    background: 'var(--stats-background-red)',
    icon: XLg,
    filter: { disabled: '1' }
  },
  {
    name: 'active',
    label: 'active',
    color: 'var(--primary-base)',
    background: 'var(--stats-background-green)',
    icon: Check,
    filter: { active: '1' }
  },

  {
    name: 'enable_mfa',
    label: 'MFA',
    color: 'var(--primary-base)',
    background: 'var(--stats-background-green)',
    icon: Key,
    filter: { enable_mfa: '1' }
  }
];

const Users = (props) => {
  const { user } = useAuth();
  let role;
  if (user.managing || user.company_id) role = 'C';
  else if (user.accessing) role = 'I';
  else role = user.role;

  return (
    <Template {...props}>
      <IndexContent
        {...props}
        initialFilter={{ role }}
        statsQuery={User.stats}
        title={role == 'C' ? 'Login Accounts' : 'Peer Accounts'}
        id="users"
        cards={cards}
        query={User.search}
        columns={columns}
        addButton="Add an Account"
        selectable
        actionButton={ActionMenu}
        sidebar={Sidebar}
        isSidebarOpen={true}
        emptyIcon={EmptyUser}
      ></IndexContent>
    </Template>
  );
};

export default Users;
