import React from 'react';

import InputFile from 'components/input-file';
import Canvas from 'components/canvas';

import { useField } from 'formik';
import { AuxiliaryButton, SecondaryButton } from './buttons';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  Trash,
  PersonFill,
  PersonPlus,
  CameraFill,
  XLg
} from 'react-bootstrap-icons';
import { WebcamCapture } from './webcam-capture';
import { useDropzone } from 'react-dropzone';

import { IconButton } from './buttons';
import { IconPhotoPlus, IconUserPlus } from '@tabler/icons-react';
const styles = css`
  position: relative;
  padding: 0px;
  border: 2px dashed #c9cfda;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;

  width: 100%;
  height: 100%;
  canvas {
    width: 160px;
    height: 160px;
    border-radius: 200px;
  }
  > div[role='button'] {
    height: auto;
  }

  p {
    color: var(--neutral-01);
    font-size: 13px;
  }

  .dropzone {
    gap: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  div.picture-footer {
    margin-top: auto;
  }

  section.controls {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  span.empty-holder {
    width: 160px;
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--neutral-07);
    border-radius: 50%;
    svg {
      width: 60%;
      height: 60%;
      color: var(--secondary-font-color);
    }
    &:hover {
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
    }
  }

  .webcam {
    display: grid;
    grid-template-rows: 80% 20%;
    flex-direction: column;
    position: absolute;
    z-index: 200;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
    video {
      padding: 0.5rem;
      height: 70%;
      width: 100%;
      object-fit: fill;
    }
    section.controls {
      position: absolute;
      align-items: center;
      display: flex;
      bottom: 0px;
      width: 100%;
      padding: 1rem;
      flex: 1;
      justify-content: center;
    }
  }
`;
const PictureInput = (props) => {
  const [isCanvasEmpty, setIsCanvasEmpty] = React.useState(true);
  const canvas = React.useRef();
  const fileInputRef = React.useRef();
  const hiddenValueRef = React.useRef();
  let reader = new FileReader();
  let img = new Image();

  const [, , helpers] = useField('photo');
  const { setValue } = helpers;
  const [isCamera, setIsCamera] = React.useState(false);

  React.useEffect(() => {
    img.crossOrigin = 'anonymous';
    if (props.src) img.src = props.src;

    img.onload = () => {
      setIsCanvasEmpty(false);

      if (!canvas.current) return;
      canvas.current.getContext('2d').drawImage(
        img,
        0,
        0,
        img.width,
        img.height, // source rectangle
        0,
        0,
        canvas.current.width,
        canvas.current.height
      ); // destination rectangle
      hiddenValueRef.current.value = canvas.current.toDataURL('image/png');
      setValue(canvas.current.toDataURL('image/png'));
    };
  }, [props.src, isCamera]);

  // // Handles file upload event and updates state
  // const handleUpload = (event) => {
  //   let file = event.target.files[0];
  //   if (typeof file?.name != "string") return;
  //   reader.readAsDataURL(file);
  //   reader.onloadend = () => {
  //     //console.log('image done on readre', reader.result)
  //     if (reader.result.indexOf("data:image/png") == -1)
  //       return alert("Invalid File Type");
  //     clearCanvas();
  //     img.src = reader.result;
  //   };
  // };

  const clearCanvas = (event) => {
    const { setValue } = helpers;
    if (!canvas.current) return;

    canvas.current
      .getContext('2d')
      .clearRect(0, 0, canvas.current.width, canvas.current.height);
    // fileInputRef.current.value = "";
    hiddenValueRef.current.value = '';
    setValue('');
    //setIsCanvasEmpty(true);
  };

  const onDrop = React.useCallback((uploadedFiles) => {
    // Read the files that have been dropped
    uploadedFiles.forEach((file) => {
      // Read the file as a data url
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        //console.log('image done on readre', reader.result)
        if (reader.result.indexOf('data:image/png') == -1)
          return alert('Invalid File Type, only *.png extension is accepted');

        clearCanvas();
        img.src = reader.result;
      };
    });
  }, []);
  const { getRootProps, getInputProps, acceptedFiles, rejectedFiles, open } =
    useDropzone({
      onDrop,
      accept: 'image/*',
      multiple: false
    });

  return (
    <article css={styles}>
      {isCamera && <WebcamCapture img={img} setIsCamera={setIsCamera} />}
      <p>Drag or click...</p>
      <div {...getRootProps()} className="dropzone">
        <input {...getInputProps()} />

        {!isCamera &&
          (isCanvasEmpty ? (
            <span className="empty-holder">
              <IconPhotoPlus />
            </span>
          ) : (
            <Canvas ref={canvas} />
          ))}
      </div>
      <input ref={hiddenValueRef} name="photo" type="hidden"></input>
      <div
        className="picture-footer"
        css={css`
          display: flex;
          justify-content: start;
          gap: 1rem;
        `}
      >
        {!isCamera && (
          <section className="controls">
            <AuxiliaryButton compact onClick={open}>
              Browse...
            </AuxiliaryButton>
            <AuxiliaryButton
              compact
              onClick={() => setIsCamera((prev) => !prev)}
            >
              <CameraFill size={22} />
              Camera
            </AuxiliaryButton>
          </section>
        )}
        {!isCanvasEmpty && (
          <AuxiliaryButton
            compact
            alert
            onClick={() => {
              clearCanvas();
              setIsCanvasEmpty(true);
            }}
          >
            <Trash size={22} />
          </AuxiliaryButton>
        )}
      </div>
    </article>
  );
};

export default PictureInput;
