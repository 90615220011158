import React from 'react';

import { TextField } from 'components/form';
import { useFormikContext, Field } from 'formik';
import GenericDoorSelectOption from 'views/generic-doors/generic-door-select-option';
import IOPortSelectOption from './ioport-select-option';
import genericDoorSelect from 'components/form/generic-door-select';
import ioportSelect from 'components/form/ioport-select';
import { ReactComponent as RelayOn } from 'assets/images/relay-on.svg';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import { Badge } from 'components/badge';

import Select from 'components/headless-select';
import {
  Fan,
  Lightbulb,
  Question,
  Toggles2,
  VolumeUp
} from 'react-bootstrap-icons';
import { components } from 'react-select';
import AsyncSelect from 'components/headless-select/async';
import GenericDoor from 'api/generic_door';

const Option = ({ children, ...props }) => {
  let Icon = props.data.icon ?? Question;
  return (
    <components.Option
      {...props}
      css={css`
        display: flex;
        gap: 1rem;
        color: var(--neutral-00);
      `}
    >
      <Icon size={22} />
      {props.data.label}
    </components.Option>
  );
};

const AUX_TYPE = [
  { value: 'sound', label: 'Horn', icon: VolumeUp },
  { value: 'light', label: 'Light', icon: Lightbulb },
  { value: 'switch', label: 'Switch', icon: Toggles2 },
  { value: 'fan', label: 'Fan', icon: Fan },
  { value: 'generic', label: 'generic', icon: RelayOn }
];

const AuxiliaryForm = ({ aux }) => {
  const { values, setFieldValue } = useFormikContext();

  return (
    <section>
      <TextField label="name" value={values.name} />

      <Field
        name="generic_door_id"
        title="Door Association"
        component={AsyncSelect}
        fetchFn={GenericDoor.search}
        closeMenuOnSelect={true}
      />

      <Field
        name="io_port_id"
        component={ioportSelect}
        selectedId={aux?.io_port_id}
        isOptionDisabled={(e) =>
          e.generic_door_id || (e.auxiliary_id && e.auxiliary_id != aux?.id)
        }
      />

      <Select
        simple
        placeholder="Select a category..."
        idx="value"
        label="label"
        isSearchable={false}
        isMulti={false}
        title="Category"
        itemComponents={{ Option, IndicatorSeparator: null }}
        defaultValue={AUX_TYPE.filter((ax) => ax.value == values.category)}
        options={AUX_TYPE}
        onChange={(e) => setFieldValue('category', e?.value || null)}
      />
    </section>
  );
};

export default AuxiliaryForm;
