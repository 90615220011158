import React from "react";
import { Camera, CameraVideo } from "react-bootstrap-icons";
/** @jsxImportSource @emotion/react */
// import { useTooltipState, Tooltip, TooltipReference } from "reakit/Tooltip";
import { css } from "@emotion/react";

const IsEventResotred = ({ event, ...otherProps }) => {
  if (!event?.restored) return null;

  return (
    <span
      title="Event is restored"
      css={css`
        height: 20px;
        font-size: 11px;
        display: flex;
        justify-content: center;
        width: 20px;
        align-items: center;
        border-radius: 50%;
        color: var(--purple-base);
        background: var(--purple-v-01);
      `}
      {...otherProps}
    >
      R
    </span>
  );
};

export default IsEventResotred;
