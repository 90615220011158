import React from 'react';
import { IconApiApp } from '@tabler/icons-react';
import { ALERTS, isWithinDays } from './device-alerts';
import { useAuth } from 'providers/auth';
import IsRole from './is-role';
import Tooltip from './tooltip';
import { css } from '@emotion/react';

/** @jsxImportSource @emotion/react */

export default ({ device }) => {
  if (!device || !device.firmware_upgrade_at) return null;
  const { user } = useAuth();

  return (
    <IsRole role={['A', 'V', 'I']}>
      <Tooltip
        content={
          <article
            css={css`
              display: flex;
              flex-direction: column;
              gap: 0.5rem;
              span {
                opacity: 80%;
              }
              time {
                font-size: 14px;
              }
            `}
          >
            {device.setup == 4 && (
              <b
                css={css`
                  color: var(--light--others--purple-500);
                `}
              >
                CURRENTLY UPGRADING...
              </b>
            )}
            <span>Firmware is scheduled around: </span>
            {device.firmware_upgrade_at && (
              <time>
                {new Date(device.firmware_upgrade_at).toLocaleString()}
              </time>
            )}
            <div>
              Upgrading to version: <eq>{device.expected_firmware}</eq>
            </div>
          </article>
        }
      >
        <IconApiApp
          title="Pending Firmware Upgrade..."
          className="firmware-indicator"
          size="20"
          color="var(--light--others--purple-500)"
        />
      </Tooltip>
    </IsRole>
  );
};
