import React from 'react';
import { useHistory } from 'react-router-dom';
import { MenuItem, MenuWrapper } from 'components/action-menu';

export default function ActionMenu({ data: elevator, ...props }) {
  const history = useHistory();

  let handleEdit = (e) =>
    history.push('/elevators/save/' + elevator.id, {
      elevator,
      desc: elevator?.Name
    });
  let handleFloors = (e) =>
    history.push('/elevators/' + elevator?.id + '/floors', {
      elevator,
      desc: elevator?.name
    });

  return (
    <MenuWrapper {...props}>
      <MenuItem label="Edit" onClick={handleEdit} />

      <MenuItem label="Manage Floors..." onClick={handleFloors} />
    </MenuWrapper>
  );
}
