import * as React from 'react';
import { FeatureGroup, useMap } from 'react-leaflet';

import * as L from 'leaflet';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import DoorLayer from './door-layer';
import CameraLayer from './camera-layer';

const ViewLayer = React.forwardRef(
  ({ geojson, doors, cameras, selectedSite }, ref) => {
    let geoJsonRef = React.useRef();
    const map = useMap();

    React.useEffect(() => {
      //TODO clear all layers and remove
      if (geojson) {
        geoJsonRef.current = L.geoJSON(geojson).eachLayer((layer) => {
          if (layer._latlng) {
            var latlng = layer.getLatLng();
          } else {
            var latlng = layer.getLatLngs();
          }
          switch (layer.feature.properties.shape) {
            case 'rectangle':
              new L.Rectangle(latlng, layer.feature.options).addTo(ref.current);
              break;
            case 'circle':
              new L.Circle(latlng, layer.feature.options).addTo(ref.current);
              break;
            case 'polygon':
              new L.Polygon(latlng, layer.feature.options).addTo(ref.current);
              break;
            case 'polyline':
              new L.Polyline(latlng, layer.feature.options).addTo(ref.current);
              break;
            case 'marker':
              // only show text markers
              //     if (layer.feature.properties.type == 'door') {
              //       let doorName = 'aaaa';
              //       let onlineColor = 'FF0000';
              //       let marker = new L.Marker(latlng, {
              //         icon: L.icon({
              //           iconUrl: `https://chart.googleapis.com/chart?chst=d_bubble_icon_text_small&chld=home|bb|${doorName}|${onlineColor}|000000`
              //         })
              //       });
              //       marker.properties = layer.feature.properties;
              //       marker.addTo(ref.current);
              //     }
              if (layer.feature?.options?.textMarker) {
                let marker = L.marker(latlng, {
                  textMarker: true,
                  text: layer.feature.options.text
                }).addTo(map);

                marker.properties = layer.feature.properties;
                marker.options = layer.feature.options;
                marker.addTo(ref.current);
              }
              break;
            case 'circlemarker':
              new L.CircleMarker(latlng, layer.feature.options).addTo(map);
              break;
          }
        });

        // console.group(geojson.bounds, [
        //   ([geojson.bounds._northEast.lat, geojson.bounds._northEast.lng],
        //   [geojson.bounds._southWest.lat, geojson.bounds._southWest.lng])
        // ]);

        // geojson.bounds &&
        //   map.fitBounds([
        //     ([geojson.bounds._northEast.lat, geojson.bounds._northEast.lng],
        //     [geojson.bounds._southWest.lat, geojson.bounds._southWest.lng])
        //   ]);
      }

      ref.current &&
        geojson?.features?.length > 0 &&
        map.fitBounds(geoJsonRef.current.getBounds(), {
          padding: [60, 60],
          animate: false
        });

      // map.dragging.disable();
    }, [geojson]);

    return (
      <FeatureGroup ref={ref} key={JSON.stringify(geojson)}></FeatureGroup>
    );
  }
);

export default ViewLayer;
/**
 * 
 * const [filter, setFilter] = React.useState('all');

  React.useEffect(() => {
    if (filter == 'offline')
      ref.current.eachLayer((layer) => {
        console.log(layer, 'layer');
        if (layer.options?.properties?.online == '1') map.removeLayer(layer);
        else layer.addTo(map);
      });
    else if (filter == 'online') {
      ref.current.eachLayer((layer) => {
        if (layer.options?.properties?.online == '0') map.removeLayer(layer);
        else layer.addTo(map);
      });
    } else {
      ref.current.eachLayer((layer) => {
        layer.addTo(map);
      });
    }
  }, [filter]);
 * 
 <article>
            <RadioGroup.Root
              value={filter}
              onValueChange={setFilter}
              css={css`
                display: flex;
                width: 250px;

                border: 1px solid var(--neutral-09);
                & > * {
                  border-radius: 4px;
                  flex: 1;
                  padding: 0.2rem;
                  span:first-of-type {
                    font-size: 1.5rem;
                    font-weight: bold;
                  }
                  span.description {
                    opacity: 70%;
                  }
                }
              `}
            >
              <RadioGroup.Item
                value="all"
                css={css`
                  &[data-state='checked'] {
                    background: var(--neutral-05);
                  }
                `}
              >
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                  `}
                >
                  <span>{doors?.length}</span>
                  <span className="description">All</span>
                </div>
              </RadioGroup.Item>
              <RadioGroup.Item
                value="offline"
                css={css`
                  &[data-state='checked'] {
                    background: var(--red-v-02);
                    color: var(--red-base);
                  }
                `}
              >
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                  `}
                >
                  <span>
                    {doors?.filter((s) => s.properties.online == '0')?.length}
                  </span>
                  <span className="description">Offline</span>
                </div>
              </RadioGroup.Item>
              <RadioGroup.Item
                value="online"
                css={css`
                  &[data-state='checked'] {
                    background: var(--primary-v-03);
                    color: var(--primary-base);
                  }
                `}
              >
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                  `}
                >
                  <span>
                    {doors?.filter((s) => s.properties.online == '1')?.length}
                  </span>
                  <span className="description">Online</span>
                </div>
              </RadioGroup.Item>
            </RadioGroup.Root>
            {/* <input
              name="type"
              type="radio"
              id="offline"
              onClick={() =>
                ref.current.eachLayer((layer) => {
                  if (layer.options?.properties?.online == '1')
                    map.removeLayer(layer);
                  else layer.addTo(map);
                })
              }
            />
            <label for="offline">Offline</label>
            <input
              name="type"
              type="radio"
              id="online"
              onClick={() =>
                ref.current.eachLayer((layer) => {
                  console.log(layer);
                  if (layer.options?.properties?.online == '0')
                    map.removeLayer(layer);
                  else layer.addTo(map);
                })
              }
            />
            <label for="online">Online</label>
            <input
              name="type"
              id="all"
              type="radio"
              onClick={() =>
                ref.current.eachLayer((layer) => {
                  layer.addTo(map);
                })
              }
            />
            <label for="all">All</label> 
            </article>

 */
