import 'css/sidebar.css';
import React from 'react';
import { withSidebar } from 'components/with-sidebar';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import { useHeadlessTable } from 'providers/headless-table-provider';
import SidebarEmptyHolder from 'components/sidebar-empty-holder';

import { ThermometerSun } from 'react-bootstrap-icons';
import Sensor from 'api/sensor';
import { useQueryWrapper } from 'utils/ajax';
import HealthTimeline from 'components/charts/health-timeline';
import { ReactComponent as SensorIcon } from 'assets/sensor-icon.svg';
import { MouseSensor } from '@dnd-kit/core';
import { useEventList } from 'hooks/use-event-list';
import EventList from 'components/event-list';
import { IconAccessPoint } from '@tabler/icons-react';

const articleStyles = (props) => css`
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 16px;
  > * {
    padding: 4px 16px;
  }

  label {
    width: 100%;
  }
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 100%;
    align-items: center;
    min-height: 32px;
    color: var(--neutral-00);
  }
  header {
    > span {
      width: 40px;
      height: 40px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px;
      background: ${props.sensor.online == 0
        ? 'var(--red-v-02)'
        : 'var(--primary-v-02)'};
      color: ${props.sensor.online == 0
        ? 'var(--red-v-01)'
        : 'var(--primary-base)'};

      border-radius: 4px;
    }
    h2 {
      margin: 0;

      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 100%;
      /* identical to box height, or 18px */
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: var(--neutral-00);
    }
    display: flex;
    align-items: center;
    padding: 24px;
    box-shadow: inset 0px -1px 0px var(--neutral-10);
    gap: 1rem;
    h4 {
      margin-bottom: 0.5rem;
      color: var(--neutral-02);
      font-weight: normal;
    }
  }

  section.event-list {
    width: 100%;
    overflow-y: auto;
    margin-bottom: 8px;
    height: 100%;
    overflow: hidden;
  }
`;
const Sidebar = (props) => {
  const { selectedFlatRows } = useHeadlessTable();

  const sensor = selectedFlatRows?.[0]?.original;

  const [filters, setFilters] = React.useState();

  React.useEffect(() => {
    if (!sensor) return;
    setFilters({
      sensor_id: sensor?.id
    });
  }, [sensor]);

  const query = useEventList({
    filters
  });

  const { isLoading, data } = useQueryWrapper(
    ['sensor', 'health', 'profile', sensor?.id],
    () => Sensor.getHealthProfile(sensor?.id),
    {
      staleTime: 30000,
      refetchOnMount: 'always',
      enabled: Boolean(sensor),
      select: (data) => {
        return data.map((d) => ({
          ...d,
          itemStyle: {
            normal: { color: d.type == 'sensorOnline' ? '#00a96c' : '#ea3737' }
          }
        }));
      }
    }
  );

  if (selectedFlatRows.length == 0) return <SidebarEmptyHolder />;
  else
    return (
      <article css={articleStyles({ sensor: selectedFlatRows?.[0]?.original })}>
        <header>
          <span>
            <IconAccessPoint />
          </span>
          <h2>{selectedFlatRows[0].original?.name}</h2>
        </header>
        <div>
          <h4>3 Day Health Timeline</h4>
          <HealthTimeline chartData={data} isLoading={isLoading} />
        </div>
        <EventList query={query} />
      </article>
    );
};

export default withSidebar({})(Sidebar);
