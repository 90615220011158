import { useMutationWrapper } from 'utils/ajax';
import Department from 'api/department';
import { useQueryClient } from 'react-query';
import { useHeadlessTable } from 'providers/headless-table-provider';

export const useDeleteMutate = (department) => {
  const queryClient = useQueryClient();
  const { queryKey } = useHeadlessTable();
  return useMutationWrapper(
    ['department', 'delete'],
    (id) => Department.delete(id),

    {
      successMessage: `Department: ${department?.Name} was deleted.`,

      onMutate: (id) => {
        let data = queryClient.getQueryData(queryKey);

        const newData = {
          ...data,
          data: data.data.filter((row) => row.id != id)
        };

        queryClient.setQueryData(queryKey, newData);
      },

      onSuccess: () => {
        queryClient.invalidateQueries(['people', 'stats']);
      },

      invalidate: 'departments',
      mainQueryKey: 'departments'
    }
  );
};

export const useRemovePeopleMutate = (department) => {
  const queryClient = useQueryClient();
  const { queryKey, tableDispatch } = useHeadlessTable();
  return useMutationWrapper(
    ['department', 'remove', 'person', department?.id],
    (ids) => Department.removePeople({ ids }, department?.id),
    {
      onMutate: (ids) => {
        let data = queryClient.getQueryData(queryKey);

        const newData = {
          ...data,
          data: data.data.filter((row) => !ids.includes(row.id))
        };

        queryClient.setQueryData(queryKey, newData);
        tableDispatch({ type: 'deselectRows', rows: ids });
      },
      onSuccess: () => {
        queryClient.invalidateQueries(`department-people-${department.id}`);
      }
    }
  );
};

export const useAddPeopleMutate = (department) => {
  const queryClient = useQueryClient();
  return useMutationWrapper(
    ['department', 'add', 'person', department?.id],
    (ids) => Department.addPeople({ ids }, department?.id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`department-people-${department.id}`);
        queryClient.invalidateQueries(['people']);
      }
    }
  );
};

export const useRemoveAlMutate = (department) => {
  const queryClient = useQueryClient();
  const { queryKey, tableDispatch } = useHeadlessTable();
  return useMutationWrapper(
    ['department', 'remove', 'al'],
    (ids) => Department.removeAccessLevels({ ids }, department.id),

    {
      onMutate: (ids) => {},
      successMessage: `Operation on Department: ${department?.Name} was succesfull`,

      onSuccess: () => {
        tableDispatch({ type: 'deselectAllRows' });
        queryClient.invalidateQueries([`department-al-${department.id}`]);
        queryClient.invalidateQueries([`department-al-${department.id}`]);
      }
    }
  );
};

export const useAddAccessLevelsMutate = (department) => {
  const queryClient = useQueryClient();
  const { queryKey, tableDispatch } = useHeadlessTable();
  return useMutationWrapper(
    ['department', 'add', 'al'],
    (ids) => Department.addAccessLevels({ ids }, department.id),

    {
      successMessage: `Operation on Department: ${department.Name} was succesfull`,
      onMutate: (ids) => {},
      onSuccess: () => {
        queryClient.invalidateQueries([`department-al-${department.id}`]);
        queryClient.invalidateQueries([`department-als-${department.id}`]);
      }
    }
  );
};
