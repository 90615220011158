import React from 'react';
import styled from '@emotion/styled';

const SmallBadge = styled('span')`
  padding-inline: 0.5rem;
  height: 24px;
  gap: 0.2rem;
  display: inline-flex;
  align-items: center;
  width: max-content;
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  background: var(--neutral-05);
  border-radius: 4px;
  svg {
    height: 16px;
    width: 16px;
  }
`;

export default ({ children }) => {
  if (!children) return null;
  return <SmallBadge role="small-badge">{children}</SmallBadge>;
};
