import React from 'react';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import { Field, Form, useFormikContext, FieldArray } from 'formik';

import AsyncSelect from 'components/headless-select/async';
import GenericDoor from 'api/generic_door';
import Select from 'components/headless-select';
import SelectWrapper from 'components/headless-select';
export const DOOR_ACTIONS = [
  { value: 'lockDoor', name: 'Lock', id: 'lockDoor' },
  { value: 'accessDoor', name: 'Access', id: 'accessDoor' },
  { value: 'unlockDoor', name: 'Unlock', id: 'unLockDoor' },
  { value: 'lockDownDoorRelease', name: 'Release', id: 'lockDownDoorRelease' },
  { value: 'lockDownDoor', name: 'Lock Down', id: 'lockDownDoor' }
];

const INTERVAL_OPTIONS = [
  { value: '0', name: 'Immediately' },
  { value: '60', name: '1 minute' },
  { value: '900', name: '15 minutes' },
  { value: '1800', name: '30 minutes' },
  { value: '3600', name: '1 hour' },
  { value: '14400', name: '4 hours' },
  { value: '28800', name: '8 hours' }
];

const DoorAction = ({ action }) => {
  const { setFieldValue } = useFormikContext();

  return (
    <article>
      <Field
        data-compact
        title="Door"
        name={`door_id`}
        transformData={(data) => {
          const obj = [...data];
          obj.unshift({ id: 'current', name: 'Current Event Door' });
          return obj;
        }}
        component={AsyncSelect}
        closeMenuOnSelect={true}
        fetchFn={GenericDoor.search}
      />
      <Field
        data-compact
        simple
        title="Action"
        name={`door_action`}
        onChange={(e) => setFieldValue('door_action', e?.value)}
        options={DOOR_ACTIONS}
        defaultValue={DOOR_ACTIONS.find((i) => i.value == action?.door_action)}
        component={Select}
      />
    </article>
  );
};

export default DoorAction;
