import React from 'react';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import { ReactComponent as EmptySvg } from 'assets/images/empty-holder.svg';
import Spinner from './spinner';
import { useHeadlessTable } from 'providers/headless-table-provider';
import { Eraser, ExclamationCircle } from 'react-bootstrap-icons';

const styles = css`
  height: 200px;
  pointer-events: none;
  tr {
    text-align: center;
    vertical-align: middle;
    height: 100%;
    align: center;
  }
  td {
    overflow: visible !important;
    padding: 0px !important;
    margin: 0px !important;
  }
`;
const TableError = (props) => {
  const { headerGroups, tableWidth } = useHeadlessTable();
  return (
    <tbody css={styles}>
      <tr>
        <td colSpan={headerGroups[0]?.headers?.length ?? 1}>
          <div
            style={{ width: tableWidth + 'px', position: 'sticky', left: 0 }}
          >
            <ExclamationCircle size={48} />
            <p>
              Error occured fetching data, try refreshing your browser, clearing
              your cookies or re-loging.
            </p>
          </div>
        </td>
      </tr>
    </tbody>
  );
};

export default TableError;
