import React from 'react';
import { ReactComponent as EmptySvg } from 'assets/images/empty-holder.svg';
import { ReactComponent as ChartEmptySvg } from 'assets/images/chart-empty-holder.svg';
import { ReactComponent as ChartBarEmptySvg } from 'assets/images/chart-bar-empty-holder.svg';

import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
const styles = css`
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  svg {
    fill: var(--neutral-09);
    width: 72px;
    height: 72px;
  }
  h2 {
    margin: 0px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    /* identical to box height, or 21px */

    display: flex;
    align-items: center;
    text-align: center;

    color: var(--secondary-font-color);
  }
`;

const EmptyHolder = (props) => {
  const { label, type, icon } = props;
  const Icon = icon;

  return (
    <article className="empty-holder" css={styles}>
      {type == 'chart' && <ChartEmptySvg />}
      {type == 'bar' && <ChartBarEmptySvg />}
      {icon && <Icon />}
      {!type && !icon && <EmptySvg />}
      <h2>{label || 'No records found'}</h2>
    </article>
  );
};

export default EmptyHolder;
