import React from 'react';
import { getSelectionColumn } from 'components/table-selection-column';
import { Badge } from 'components/badge';
import LastAccessFormatter from 'components/last-access-formatter';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import { ActivityHeader } from 'views/doors/table-columns';
import ActivityChartFormatter from 'components/activity-chart-formatter';

export const columns = [
  getSelectionColumn(),

  {
    asc: 'atoz',
    desc: 'ztoa',
    newSorter: true,
    Header: 'Name',
    accessor: 'name',
    style: { width: '200px' },
    pickerDisabled: true,
    Cell: ({ row: { original } }) => {
      return (
        <>
          <strong
            css={css`
              display: inline-block;
              margin-bottom: 6px;
            `}
          >
            {original.name}
          </strong>
          <br />
          <small>{original.site_name}</small>
        </>
      );
    }
  },
  {
    asc: 'newestOnline',
    desc: 'oldestOnline',
    newSorter: true,
    Header: 'Status',
    accessor: 'online',
    label: 'Status',
    Cell: ({ row, cell }) => {
      if (!cell.value || cell.value == 0)
        return <Badge color="danger">offline</Badge>;
      else if (cell.value == 1) return <Badge color="green">Online</Badge>;
    }
  },
  {
    Header: () => <ActivityHeader />,
    disableSortBy: true,
    id: '7 Day Trend',
    label: '7 Day Trend',
    accessor: 'activity',

    Cell: ({ cell: { value } }) => <ActivityChartFormatter activity={value} />
  },

  {
    disableSortBy: true,
    Header: 'Floors',
    accessor: 'floor_count'
  },
  {
    Header: 'Last Access',
    accessor: 'last_access_time',
    asc: 'oldestLastAccess',
    desc: 'newestLastAccess',
    newSorter: true,
    style: { width: '300px' },
    Cell: ({ row: { original } }) => <LastAccessFormatter door={original} />
  }
];
