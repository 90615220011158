import React from 'react';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import { AspectRatio, Pencil } from 'react-bootstrap-icons';
import { IconButton } from 'components/buttons';
import { useMap } from 'react-leaflet';
import ViewLayerFilter from './view-layer-filter';

export default ({
  setIsEdit,
  isEdit,
  currentLayerRef,
  baseLayers,
  setBaseLayers,
  filters,
  markerLayerRef,
  setFilters
}) => {
  const map = useMap();

  const elmRef = React.useRef();

  React.useEffect(
    () => elmRef.current && L.DomEvent.disableClickPropagation(elmRef.current),
    [elmRef]
  );

  return (
    <>
      <div className="leaflet-top leaflet-right" css={css``} ref={elmRef}>
        <div className="leaflet-control leaflet-bar">
          <div
            css={css`
              padding: 0.5rem;
              width: auto;
              background: var(--neutral-09);
              > section {
                margin-top: 1rem;
              }
            `}
          >
            <div
              css={css`
                display: flex;
              `}
            >
              {!isEdit && (
                <IconButton onClick={() => setIsEdit(true)} css={css``}>
                  <Pencil size={16} />
                </IconButton>
              )}
              <IconButton
                onClick={() => {
                  let currentBounds = currentLayerRef.current.getBounds();

                  if (currentBounds._southWest && currentBounds._northEast) {
                    currentLayerRef.current &&
                      map.fitBounds(currentLayerRef.current.getBounds(), {
                        padding: [10, 10]
                      });
                  }
                }}
              >
                <AspectRatio size={16} />
              </IconButton>
              {!isEdit && (
                <ViewLayerFilter
                  baseLayers={baseLayers}
                  setBaseLayers={setBaseLayers}
                  filters={filters}
                  markerLayerRef={markerLayerRef}
                  setFilters={setFilters}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
