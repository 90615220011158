import { useMutationWrapper } from "utils/ajax";
import Notification from "api/notification";
import { useQueryClient } from "react-query";
import { useHeadlessTable } from "providers/headless-table-provider";

export const useEnableMutate = (notification) => {
  const queryClient = useQueryClient();
  const { queryKey } = useHeadlessTable();
  return useMutationWrapper(
    ["notification", "enable"],
    (id) => Notification.enable(id),
    {
      successMessage: `Operation on notification: ${notification?.name} completed`,

      onMutate: (id) => {
        let data = queryClient.getQueryData(queryKey);

        let newData = data.data.map((i) =>
          i.id == id
            ? {
                ...i,
                enabled: i.enabled == "0" ? "1" : "0",
              }
            : { ...i }
        );

        queryClient.setQueryData(queryKey, { ...data, data: newData });
      },
      onSuccess: () => {
        //queryClient.invalidateQueries(["notifications", "stats"]);
      },
    }
  );
};

export const useDeleteMutate = (notification) => {
  const queryClient = useQueryClient();
  const { queryKey } = useHeadlessTable();
  return useMutationWrapper(
    ["notification", "delete"],
    (id) => Notification.delete(id),

    {
      successMessage: `Notification: ${notification?.name} deleted successfuly`,
      onMutate: (id) => {
        let data = queryClient.getQueryData(queryKey);

        const newData = {
          ...data,
          data: data.data.filter((row) => row.id != id),
        };

        queryClient.setQueryData(queryKey, newData);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["notifications", "stats"]);
      },
    }
  );
};
