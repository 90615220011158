import React from 'react';
import Template from 'base/template';

import Camera from 'api/camera';

import PageTitle from 'components/page-title';
import Content from 'base/content';
import { useQueryWrapper } from 'utils/ajax';
import { useParams } from 'react-router-dom';
import PlayerWrapper from 'components/video/player-wrapper';
/** @jsxImportSource @emotion/react */

import { useNotFound } from 'hooks/not-found-hook';
import Breadcrumbs from 'components/breadcrumbs';

const View = (props) => {
  const { id } = useParams();
  const [camera, setCamera] = React.useState();

  const query = useQueryWrapper(['camera', 'get', id], () =>
    Camera.search({ intercom_id: id })
  );

  useNotFound(id, query, props);

  React.useEffect(() => {
    if (!query?.data?.data?.[0]) return;
    setCamera(query.data.data?.[0]);
  }, [query?.data?.data?.[0]]);

  return (
    <Template {...props}>
      <Content {...props}>
        <PageTitle>{camera?.name}</PageTitle>
        <Breadcrumbs crumbs={props.crumbs} />
        {camera && <PlayerWrapper camera={camera} />}
      </Content>
    </Template>
  );
};

export default View;
