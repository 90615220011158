import React from "react";
import { Badge } from "components/badge";
import {
  CheckCircle,
  DoorOpen,
  InfoCircle,
  QuestionCircleFill,
  XLg,
} from "react-bootstrap-icons";

const DOOR_ALARMS = {
  forcedOpen: {
    color: "danger",
    icon: InfoCircle,
    label: "forced open",
  },
  normal: {
    color: "success",
    icon: CheckCircle,
    label: "normal",
  },
  openTooLong: {
    color: "warning",
    icon: DoorOpen,
    label: "Open Too Long",
  },
  ioOpenTooLong: {
    color: "warning",
    icon: DoorOpen,
    label: "Open Too Long",
  },
  unknown: {
    color: "unknown",
    icon: QuestionCircleFill,
    label: "Unknown",
  },
};

const AlarmStateFormatter = ({ state }) => {
  if (!(state in DOOR_ALARMS)) state = "unknown";
  const Icon = DOOR_ALARMS[state || "normal"]?.icon;
  return (
    <Badge color={DOOR_ALARMS[state || "normal"]?.color}>
      <Icon size="18" />
      {DOOR_ALARMS[state || "normal"]?.label}
    </Badge>
  );
};

export default AlarmStateFormatter;
