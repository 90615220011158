import React from 'react';
import { useHistory } from 'react-router-dom';
import { MenuItem, MenuItemDelete, MenuWrapper } from 'components/action-menu';
import { useDeleteMutate, useEnableMutate } from './actions';
import useAlertDialog from 'providers/alert-dialog-provider';
export default function ActionMenu({ data: notification, ...otherProps }) {
  const history = useHistory();
  const deleteMutate = useDeleteMutate(notification);
  const enableMutate = useEnableMutate(notification);

  const confirm = useAlertDialog();

  let handleEdit = (e) =>
    history.push('/notifications/save/' + notification?.id, {
      notification,
      desc: notification?.name
    });

  const handleDelete = async (e) => {
    let ok = await confirm({
      description: `Are you sure you want to delete Notification:  ${notification?.name}?`,
      info:
        notification.isDelayed == '1'
          ? 'All pending actions will be deleted'
          : ''
    });

    ok && deleteMutate.mutate(notification?.id, {});
  };

  const handleEnable = async (e) => {
    let msg = notification.enabled == '1' ? 'disable' : 'enable';
    let ok = await confirm({
      description: `Are you sure you want to ${msg} Notification:  ${notification?.name}?`,
      info:
        notification.isDelayed == '1' && notification.enabled == '1'
          ? 'All pending actions will be deleted'
          : ''
    });

    ok && enableMutate.mutate(notification?.id);
  };

  return (
    <MenuWrapper {...otherProps}>
      <MenuItem label="Edit" onClick={handleEdit} />
      <MenuItem label="Enable /Disable" onClick={handleEnable} />

      <MenuItemDelete onClick={handleDelete} />
    </MenuWrapper>
  );
}
