import { useMutationWrapper } from 'utils/ajax';
import Person from 'api/person';
import { useQueryClient } from 'react-query';
import { useHeadlessTable } from 'providers/headless-table-provider';

export const useBarMutate = (person) => {
  const queryClient = useQueryClient();
  const { queryKey } = useHeadlessTable();

  return useMutationWrapper(['person', 'bar'], (id) => Person.bar(id), {
    successMessage: `Operation on Person: ${person?.name} completed`,

    onMutate: (id) => {
      let data = queryClient.getQueryData(queryKey);

      data.data.forEach((i) => i.id == id && (i.enabled = !i.enabled));

      queryClient.setQueryData(queryKey, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['people']);
    }
  });
};

export const useDeleteMutate = () => {
  const queryClient = useQueryClient();
  const { queryKey, tableDispatch } = useHeadlessTable();
  return useMutationWrapper(
    ['person', 'delete'],
    (ids) => Person.delete({ ids }),

    {
      successMessage: `People deleted`,

      onMutate: (ids) => {
        let data = queryClient.getQueryData(queryKey);

        const newData = {
          ...data,
          data: data.data.filter((row) => !ids.includes(row.id))
        };

        queryClient.setQueryData(queryKey, newData);
        tableDispatch({ type: 'deselectRows', rows: ids });
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['people']);
      }
    }
  );
};

export const useRemoveDepartmentsMutate = (person) => {
  const queryClient = useQueryClient();
  const { queryKey, tableDispatch } = useHeadlessTable();
  return useMutationWrapper(
    ['person', 'remove', 'dep'],
    (ids) => Person.removeDepartments({ ids }, person.id),

    {
      onMutate: (ids) => {},
      successMessage: `Operation on Person: ${person.id} was succesfull`,

      onSuccess: () => {
        tableDispatch({ type: 'deselectAllRows' });
        queryClient.invalidateQueries([`person-dep-${person.id}`]);
        queryClient.invalidateQueries([`person-deps-${person.id}`]);
      }
    }
  );
};

export const useAddDepartmensMutate = (person) => {
  const queryClient = useQueryClient();
  return useMutationWrapper(
    ['person', 'add', 'dep'],
    (ids) => Person.addDepartments({ ids }, person.id),

    {
      successMessage: `Operation on Person: ${person.name} was succesfull`,
      onMutate: (ids) => {},
      onSuccess: () => {
        queryClient.invalidateQueries([`person-dep-${person.id}`]);
        queryClient.invalidateQueries([`person-deps-${person.id}`]);
      }
    }
  );
};
