import { callAPI } from 'utils/ajax';

const CredentialAccessLevel = {
  search(input = {}) {
    return callAPI('GET', `/credential_access_level`, input);
  },
  stats(input = {}) {
    return callAPI('GET', `/credential_access_level/stats`, input);
  }
};

export default CredentialAccessLevel;
