/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react';
import { useAuth } from 'providers/auth';
import Installer from 'api/installer';

import AsyncSelect from 'components/headless-select/async';

import { css } from '@emotion/react';
import { Tools, CaretDownFill, Search } from 'react-bootstrap-icons';
import { components } from 'react-select';

import InstallerItem from './installer-item';
import Footer from './footer';
import * as Popover from '@radix-ui/react-popover';
import { PopoverContent } from 'components/popover';
import { useHistory } from 'react-router-dom';

const Option = ({ children, ...props }) => {
  return (
    <components.Option
      css={css`
        padding: 0px;
      `}
      {...props}
    >
      <InstallerItem installer={props.data} />
    </components.Option>
  );
};

const InstallerAccess = () => {
  const { user, installerAccess } = useAuth();

  const [isOpen, setIsOpen] = React.useState();

  const history = useHistory();

  const handleAccess = (installerId) =>
    installerAccess.mutate(installerId, {
      onSuccess: () => history.push('/dashboard')
    });

  return (
    <>
      <Popover.Root open={isOpen} onOpenChange={setIsOpen}>
        <Popover.Trigger
          css={css`
            display: flex;
            gap: 5px;
            align-items: center;

            svg {
              padding: 2px;
              transition: all 0.2s;
            }
            &[aria-expanded='true'] {
              svg:first-of-type {
                transform: rotate(180deg);
              }
            }
          `}
        >
          <CaretDownFill size={16} color={'var(--primary-base)'} />
          <Tools size={22} />
          <div
            data-access={user?.installer_name ? true : false}
            css={css`
              display: flex;
              flex-direction: column;
              place-items: flex-start;
              font-size: 14px;
              &[data-access='false'] {
                opacity: 0.4;
              }
            `}
          >
            {installerAccess.isLoading && 'Accessing...'}
            {!installerAccess.isLoading &&
              (user?.installer_name || 'Access Installer')}
          </div>
        </Popover.Trigger>
        <Popover.Anchor />
        <Popover.Portal>
          <PopoverContent
            css={css`
              height: ${user?.accessing ? '370px' : 'auto'};
              min-height: ${!user?.accessing ? '300px' : 'auto'};
              display: flex;
              flex-direction: column;
              > div {
                flex: 1;
              `}
          >
            <AsyncSelect
              placeholder="Search Installers..."
              checkboxes={false}
              data-compact
              data-inline
              maxOptions={3}
              fetchFn={Installer.search}
              queryKey="installers-access"
              queryParams={{ staleTime: 10e6, refetchOnMount: false }}
              icon={Search}
              fetchFilters={{
                sort: 'atoz',
                except: user?.installer_id
              }}
              itemComponents={{ Option: Option, DropdownIndicator: null }}
              css={css`
                .select__control {
                  margin: 1rem 2.25rem !important;
                  width: auto !important;
                }
                .select__menu {
                  //box-shadow: none;
                  border: none;
                  position: relative;
                  box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.1);
                }
                .select__menu-list {
                  overflow-y: auto;
                  overflow-x: hidden;
                }
                input {
                  opacity: 1 !important ;
                }
              `}
              //onChange={(e) => setGlobalAccessLevel(e)}
              menuIsOpen={true}
              closeMenuOnSelect={true}
              controlShouldRenderValue={false}
              onChange={(e) => {
                setIsOpen(false);
                e && handleAccess(e.id);
              }}
            />

            {user?.installer_id && <Footer />}
          </PopoverContent>
        </Popover.Portal>
      </Popover.Root>
    </>
  );
};

export default InstallerAccess;
