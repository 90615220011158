const URLS = {
  'app.cube-access.com': 'api.cube-access.com',
  'app.demo.cube-access.com': 'api.demo.cube-access.com',
  'access.yoursixsecurity.com': 'api.yoursixsecurity.com',
  'dooraccess.yoursixsecurity.com': 'api.yoursixsecurity.com',
  'sandbox.yoursixsecurity.com': 'staging.yoursixsecurity.com',
  'localhost:3001': 'localhost',
  'localhost:3000': 'localhost',
  'portal-staging.arc34.com': 'api.portal-staging.arc34.com',
  'portal.arc34.com': 'api.portal.arc34.com'
};

const CUBE_ENV_HOSTS = {
  'app.cube-access.com': 'api.cube-access.com',
  'app.demo.cube-access.com': 'api.demo.cube-access.com',
  'access.yoursixsecurity.com': 'api.yoursixsecurity.com',
  'dooraccess.yoursixsecurity.com': 'api.yoursixsecurity.com',
  'sandbox.yoursixsecurity.com': 'api.demo.cube-access.com',
  'portal-staging.arc34.com': 'api.portal-staging.arc34.com',
  'portal.arc34.com': 'api.portal.arc34.com'
};

const CUBE_DEVICE_URLS = {
  'app.cube-access.com': 'device.cube-access.com',
  'app.demo.cube-access.com': 'device.demo.cube-access.com',
  'access.yoursixsecurity.com': 'device.yoursixsecurity.com',
  'dooraccess.yoursixsecurity.com': 'device.yoursixsecurity.com',
  'sandbox.yoursixsecurity.com': 'device.staging.yoursixsecurity.com',
  'portal-staging.arc34.com': 'device.portal-staging.arc34.com',
  'portal.arc34.com': 'device.portal.arc34.com'
  // need to add here Arc 34
};

export function getDeviceProxyUrl(mac) {
  return location.protocol + '//' + mac + '.' + CUBE_DEVICE_URLS[location.host];
}

export const API_HOST_URL =
  location.protocol + '//' + URLS[location.host] + '/api';

export const SSE_HOST_URL =
  process.env.REACT_APP_SSE_SERVER ?? '//' + URLS[location.host] + '/sse';

export const MEDIA_HOST_URL =
  process.env.REACT_APP_MEDIA_SERVER ??
  (location.protocol == 'https:' ? 'wss' : 'ws') +
    '://' +
    URLS[location.host] +
    '/media';

export const API_HOST = URLS[location.host];

export const CUBE_API_HOST = CUBE_ENV_HOSTS[location.host];

export const NEW_API_HOST_URL =
  process.env.REACT_APP_NEW_API_URL ??
  location.protocol + '//' + URLS[location.host] + '/api/v1';
