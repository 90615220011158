import React from 'react';
import 'css/usage-chart.css';
import MetricEvent from 'api/metric';
import { useQuery } from 'react-query';
import { useAuth } from 'providers/auth';
import LineChart from 'components/line-chart';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';
import Chart from 'components/chart';
import Spinner from 'components/spinner';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import { RadioGroup } from '@headlessui/react';
import EmptyHolder from 'components/empty-holder';
import { useModal } from 'providers/modal-provider';
import { DialogDisclosure } from 'reakit/Dialog';

import UsageChartModal from './usage-chart-modal';
import { ArrowsAngleExpand } from 'react-bootstrap-icons';
import { SecondaryButton } from 'components/buttons';
import ChartFilterMenu from './chart-filter-menu';
import ChartExpandButton from './chart-expand-button';
import UsageChart from './usage-chart';
import Door from 'api/door';
import HeatmapUsageChart from './heatmap-usage-chart';

const styles = css`
  overflow: hidden;
  background: var(--neutral-09);
  height: 100%;
  position: relative;
  .empty-holder {
    position: absolute;
    inset: 0;
  }

  header {
    display: block;
    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 100%;
      margin-bottom: 16px;
    }
  }
`;

const UsageChartHolder = (props) => {
  const [chartData, setChartData] = React.useState([]);

  const [filters, setFilters] = React.useState(props.filters);
  const [selection, setSelection] = React.useState('-7 days');

  React.useEffect(() => {
    setFilters((prev) => ({ ...prev, since: selection }));
  }, [selection]);

  React.useEffect(() => {
    setFilters(props.filters);
  }, [props.filters]);

  const { isLoading, data } = useQuery(
    ['usage', filters],
    () =>
      MetricEvent.searchLatest({
        since: selection,
        ...props?.filters
      }),
    {
      staleTime: 60000
    }
  );

  React.useEffect(() => {
    if (!data) return;

    let v = data;

    setChartData(
      v.reverse().map((i) => {
        return [i._id, i.count];
      })
    );
  }, [data]);

  return (
    <section className="usage-chart" css={styles} {...props}>
      {props.type == 'heatmap' ? (
        <HeatmapUsageChart chartData={chartData} {...props} />
      ) : (
        <UsageChart chartData={chartData} isLoading={isLoading} {...props} />
      )}

      {data && data.length == 0 && <EmptyHolder type="bar" />}
    </section>
  );
};

export default UsageChartHolder;
