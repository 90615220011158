import React, { forwardRef } from 'react';

import { useDebounce } from 'hooks/debounce-hook';

import { useHeadlessTable } from 'providers/headless-table-provider';
import { createFilter } from 'react-select';

import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react **/
import { useAuth } from 'providers/auth';
import {
  Search,
  ArrowRightShort,
  ArrowLeftShort,
  Trash2Fill
} from 'react-bootstrap-icons';

import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';

import { useQueryWrapper } from 'utils/ajax';

import { useHotkeys } from 'react-hotkeys-hook';

export const Control = ({ children, ...props }) => {
  return (
    <components.Control {...props}>
      <Search
        css={css`
          margin-left: 0.8rem;
        `}
      />
      {children}
      <span
        className="tooltip"
        title="Press hot key '/' to search"
        css={css`
          background: var(--secundary-v-02);
          opacity: 0.5;
          padding: 0.2rem 0.4rem;
          margin: 0.2rem;
        `}
      >
        /
      </span>
    </components.Control>
  );
};

const Option = ({ children, ...props }) => {
  const Option = props.selectProps.filter.option;

  if (props.selectProps.filter.option)
    return (
      <Option {...props} children={children} {...props.selectProps.filter} />
    );
  else
    return (
      <components.Option {...props}>
        <div
          data-value={props.value}
          css={css`
            font-weight: 500;
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 0.2rem;
            font-size: 14px;
            > svg:last-of-type {
              margin-left: auto;
            }
          `}
        >
          <span
            css={css`
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            `}
          >
            {children}
          </span>
          {(props.data.fetchFn || props.data.options) && (
            <ArrowRightShort size={20} />
          )}
        </div>
      </components.Option>
    );
};

const styles = (props) => css`
  min-width: 300px;
  max-width: 300px;
  height: 40px;
  &[data-error='true'] {
    .select__control {
      box-shadow: 0 0 0 1px var(--red-base);
      border-color: var(--red-base);
    }
  }

  .select__option--is-selected {
    background: var(--primary-v-02);
    color: var(--neutral-00);
  }

  &[data-compact='true'] {
    .select__control {
      height: 40px;
      min-height: 40px;
    }
  }

  .select__container.select--is-disabled {
    opacity: 50%;
  }

  .select__input {
    color: var(--neutral-00);
  }

  .select__control {
    background: var(--neutral-09);
    border: 1px solid var(--neutral-05);
    display: flex;
    align-content: center;
    width: 100%;
    font-size: 14px;
    height: 40px;
    box-sizing: border-box;
    > svg {
      opacity: 0.5;
    }
  }
  .select__value-container--has-value + .select__indicators {
    display: flex;
    align-items: center;
    height: 48px;
  }

  .select__menu {
    font-size: 14px;
    border: 1px solid var(--neutral-12);
    color: var(--neutral-00);
    z-index: 4;
  }

  .select__menu-list {
    background: var(--neutral-09);
    color: var(--neutral-0);
  }

  .select__multi-value__remove:hover {
    background: var(--primary-base);
    color: var(--neutral-00);
  }
  .select__value-container {
    cursor: text;
  }
  .select__control--menu-is-open,
  .select__control--is-focused {
    /* border-color: var(--primary-base);
    box-shadow: 0px 0px 0px 1px var(--primary-base); */
    box-shadow: none;
    box-sizing: border-box;

    // border: 2px solid black;
    outline-style: dotted;
    overflow: hidden;
    outline: 2px solid var(--secundary-base) !important;
    //box-shadow: 0em 0em 0.25em var(--primary-base);
    .tooltip {
      display: none;
    }
  }
  .select__multi-value,
  .select__single-value {
    background: ${props.simple ? 'none' : 'var(--primary-v-02)'};
    border: ${!props.simple ? '1px solid var(--primary-v-04)' : 'none'};
    box-sizing: border-box;
    color: var(--neutral-00);
    border-radius: 4px;
    padding: 0.3rem;
    font-weight: 500;
    max-width: 90%;
    width: fit-content;
  }

  .select__multi-value__label {
    // background: var(--primary-v-02);
    color: var(--neutral-00);
  }

  .select__option--is-selected {
    background: var(--primary-v-02);
    color: var(--neutral-00);
  }
  .select__option--is-focused {
    background: var(--menu-hover-background-color);
  }

  .select__option:hover {
    background: var(--menu-hover-background-color);
  }
  .select__menu {
    width: 100%;
    box-shadow: 0px 3px 6px 2px rgba(29, 32, 35, 0.4);
    boder-radius: none;
  }
`;

// const Group = (props) => {
//   const onClick = () => {
//     //setIsOpen((prev) => !prev);
//     const newOptions = [...props.selectProps.options];
//     newOptions.find((opt) => opt.id == props.data.id).open = !newOptions.find(
//       (opt) => opt.id == props.data.id
//     ).open;
//     props.selectProps.setOptions(newOptions);
//   };
//   return (
//     <components.Group
//       {...props}
//       headingProps={{ ...props.headingProps, onClick, isOpen: props.data.open }}
//       children={props.data.open ? props.children : null}
//     />
//   );
// };

const GroupHeading = ({ ...props }) => {
  const { setRecentFilters } = useHeadlessTable();

  return (
    <div
      css={css`
        position: relative;
        z-index: 2;
        display: flex;
        align-items: center;
        padding: 0.8rem;
        pointer-events: none;
        border-bottom: 1px solid var(--neutral-12);

        > button {
          position: relative;
          z-index: 3;
          pointer-events: all;
          margin-left: auto;
          color: var(--neutral-12);
        }
      `}
    >
      <components.GroupHeading
        {...props}
        css={css`
          padding-left: 0px;
          margin-bottom: 0px;
          color: #7f8795;
        `}
      />
      {props.data.id == 'recent' && (
        <button
          title="clear recent searches"
          onClick={() => setRecentFilters([])}
        >
          <Trash2Fill size={18} />
        </button>
      )}
    </div>
  );
};

const ValueContainer = ({ children, ...props }) => {
  return (
    <components.ValueContainer {...props}>
      {props.selectProps?.filter?.name && (
        <span
          css={css`
            padding-left: 8px;
            gap: 4px;
            box-sizing: border-box;
            border-radius: 4px;
            font-size: 12px;
            //font-weight: 600;
            line-height: 16px;
            color: var(--neutral-01);
            width: fit-content;
          `}
        >
          {props.selectProps.filter?.label}:
        </span>
      )}

      {children}
    </components.ValueContainer>
  );
};

const MenuList = (props) => {
  return (
    <components.MenuList
      css={css`
        display: flex;
        flex-direction: column;
        gap: 0.2rem;
        max-height: 500px;
      `}
      {...props}
    >
      {props.selectProps.filter.name && (
        <button
          onClick={() => props.selectProps.setFilter({})}
          css={css`
            padding: 0.5rem;

            display: flex;
            align-items: center;
            padding: 1rem;
            gap: 0.8rem;
            > label {
              font-weight: 500;
            }
            > span {
              font-size: 11px;
              color: #999;
              cursor: default;
              display: block;

              font-weight: 500;

              text-transform: uppercase;
              box-sizing: border-box;
            }

            border-bottom: 1px solid var(--neutral-12);
          `}
        >
          <ArrowLeftShort size={20} />
          <span>FILTER BY: </span>
          <label>{props.selectProps?.filter?.name}</label>
        </button>
      )}

      {props.children}
    </components.MenuList>
  );
};

const TableFilter = React.forwardRef(
  (
    {
      filterOptions = [
        { open: true, id: 'recent', label: 'Recent', options: [] }
      ],
      isDisabled,

      ...props
    },
    ref
  ) => {
    const { setGlobalFilter, tableState, setRecentFilters, recentFilters } =
      useHeadlessTable();

    const { user } = useAuth();

    const setFilters = (e) => {
      setGlobalFilter((prev) => ({
        ...prev,
        [filter.custom ? filter.field : 'all']: e.value
      }));

      if (!filter.field)
        setRecentFilters((prev) => [...prev, e.value].slice(-3));
    };

    filterOptions[0].options = recentFilters
      .map((r) => ({
        value: r,
        name: '"' + r + '"',
        label: '"' + r + '"',
        recent: true
      }))
      .reverse();

    const [options, setOptions] = React.useState([...filterOptions]);

    const [filter, setFilter] = React.useState({});
    const [allFilter, setAllFilter] = React.useState('');
    const { setInput, input } = useDebounce(setAllFilter);

    const { data, isLoading } = useQueryWrapper(
      ['table-global-filter', filter?.id, allFilter],
      () => filter?.fetchFn({ all: allFilter, ...filter?.initialFilter }),
      {
        enabled: typeof filter.fetchFn == 'function'
      }
    );

    React.useEffect(() => {
      if (isLoading) setOptions([]);
      if (!data?.data && !filter.fetchFn) setOptions([...filterOptions]);
      if (data?.data) setOptions(data?.data);
    }, [data, filter, recentFilters]);

    React.useEffect(() => {
      setOptions([...filterOptions]);
      setFilter({});
    }, [user]);

    const [menuOpen, setMenuOpen] = React.useState(undefined);

    const onMenuOpen = () => {
      if (menuOpen !== undefined) setMenuOpen(undefined);
    };

    React.useEffect(() => {
      setMenuOpen(false);
      setFilter({});
    }, [tableState.queryFilter]);

    const tableRef = React.useRef();

    useHotkeys('/', (e) => {
      e.preventDefault();
      tableRef.current.focus();
    });

    return (
      <article
        className="table-filter-container"
        data-compact={props['data-compact']}
        css={styles}
      >
        <CreatableSelect
          css={css`
            input {
              opacity: 1 !important ;
            }
          `}
          ref={ref || tableRef}
          onInputChange={(e) => {
            setInput(e);
          }}
          onKeyDown={(e) => {
            if (e.keyCode === 8) {
              // do stuff
              !input && setFilter({});
            }
          }}
          onMenuOpen={onMenuOpen}
          menuIsOpen={menuOpen}
          // isValidNewOption={() => props.isCreatable ?? true}
          closeMenuOnSelect={false}
          placeholder={!filter.field && 'Search...'}
          className="select__container"
          classNamePrefix="select"
          isValidNewOption={() => !filter?.fetchFn}
          formatCreateLabel={(userInput) => {
            if (!userInput) return null;
            if (typeof filter?.validate == 'function') {
              if (filter.validate(userInput))
                return `Search for "${userInput}"...`;
              else return null;
            } else if (filter.fetchFn) return null;
            else return `Search for "${userInput}"...`;
          }}
          noOptionsMessage={() =>
            filter ? filter?.helper : 'No Records found'
          }
          controlShouldRenderValue={false}
          getOptionLabel={(e) =>
            e.name ||
            e.Name ||
            e.token ||
            e.label ||
            e.text ||
            e.Description ||
            e.display_name ||
            e.door_name
          }
          filterOption={
            !filter.fetchFn
              ? (o, input) => {
                  // if (o?.data?.fetchFn) return null;

                  if (typeof o.data?.visible == 'function')
                    if (o.data.visible())
                      return o?.label?.toLowerCase().includes(input);
                    else return false;
                  else
                    return o?.label
                      ?.toLowerCase()
                      .includes(input.toLowerCase());
                }
              : null
          }
          getOptionValue={(e) => e.value}
          hideSelectedOptions={false}
          create
          createOptionPosition="first"
          options={filter.options ? filter.options : options}
          filter={filter}
          setFilter={setFilter}
          setOptions={setOptions}
          isLoading={isLoading}
          components={{
            //  Group,
            //GroupHeading,
            ValueContainer,
            Control,
            MenuList,
            Option,
            GroupHeading,
            IndicatorSeparator: null,
            DropdownIndicator: null,
            LoadingIndicator: null
          }}
          value={null}
          onChange={(e) => {
            e?.fetchFn || e?.custom
              ? setFilter(e || {})
              : e.__isNew__
              ? setFilters(e)
              : e.recent
              ? setGlobalFilter((prev) => ({ ...prev, all: e.value }))
              : Array.isArray(e.value)
              ? setGlobalFilter((prev) => ({
                  ...prev,
                  [e.value[0]]: e.value[1]
                }))
              : setGlobalFilter((prev) => ({
                  ...prev,
                  [filter.field]: filter.custom
                    ? e?.value
                    : e?.[filter.idx ?? 'id']
                }));
          }}
          isDisabled={isDisabled}
          {...props.selectProps}
        />
      </article>
    );
  }
);

export default TableFilter;
