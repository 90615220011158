import { css } from '@emotion/react';
import { dateTimeShort } from 'utils/time';
import React from 'react';
import styled from '@emotion/styled';
import lightFormat from 'date-fns/lightFormat';
import {
  ChevronDown,
  ChevronUp,
  CircleFill,
  Clock,
  Trash2Fill
} from 'react-bootstrap-icons';
import { useDeleteEdgeRecordingMutate } from 'views/cameras/actions';
import { Trash } from 'react-bootstrap-icons';
import { IconButton } from 'components/buttons';
/** @jsxImportSource @emotion/react */
import intervalToDuration from 'date-fns/intervalToDuration';

import * as Collapsible from '@radix-ui/react-collapsible';
import { useQueryWrapper } from 'utils/ajax';
import CameraAction from 'api/camera_action';

const Article = styled.article`
  padding: 1.2rem;
  box-shadow: 0px -1px 0px var(--neutral-12);
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 1rem;
  position: relative;
  transition: background linear 0.3s;

  background: ${(props) => (props.row.isSelected ? 'var(--primary-v-02)' : '')};
  time {
    font-size: 12px;
    .secondary {
      color: var(--secondary-font-color);
    }
  }
  &:hover {
    background: var(--neutral-08);
    cursor: pointer;
  }
`;

export const columns = [
  {
    Header: 'Start Time',
    accessor: 'starttime',
    Cell: ({ row, cell, camera }) => {
      let original = row.original;

      const [open, setOpen] = React.useState(false);

      const { days, hours, seconds, minutes } = intervalToDuration({
        start: new Date(original.starttime),
        end: original.stoptime ? new Date(original.stoptime) : new Date()
      });
      const mutateDeleteRecording = useDeleteEdgeRecordingMutate(camera);

      const { data: recordingProperties, isLoading } = useQueryWrapper(
        ['camera', 'recording', 'properties', camera?.id, original.recordingid],
        () =>
          CameraAction.edgeRecordingProperties(camera.id, {
            ...original,
            stoptime: original.stoptime || new Date()
          }),
        {
          enabled: open,
          staleTime: 10 * (60 * 1000), // 10 mins
          cacheTime: 15 * (60 * 1000), // 15 mins
          refetchOnMount: false
        }
      );

      return (
        <Collapsible.Root open={open} onOpenChange={setOpen}>
          <Collapsible.Trigger asChild={true}>
            <Article row={row}>
              <div>
                {original.recordingstatus == 'recording' && (
                  <div
                    css={css`
                      background: var(--red-base);
                    `}
                    className="circle pulse"
                  ></div>
                )}
              </div>
              <section>
                <div
                  css={css`
                    > time > span.date {
                      font-weight: 700;
                      font-size: 12px;

                      text-transform: uppercase;
                    }
                  `}
                >
                  <time>
                    <span className="date">{original.date} | </span>
                    <span>
                      {lightFormat(new Date(original.starttime), 'h:mm:ss aa')}
                    </span>
                  </time>

                  {original.stoptime ? (
                    <time>
                      <span> - </span>
                      <span className="date">{original.date} | </span>
                      <span>
                        {lightFormat(new Date(original.stoptime), 'h:mm:ss aa')}
                      </span>
                    </time>
                  ) : (
                    <span></span>
                  )}
                </div>

                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    gap: 0.2rem;
                    line-height: 2rem;
                    font-weight: 600;
                    font-size: 11px;
                    line-height: 20px;
                    color: #59606c;

                    time {
                      margin-right: 2px;
                    }
                  `}
                >
                  <Clock size={16} />
                  {days > 0 && <time>{days} days, </time>}
                  {hours > 0 && <time>{hours} hours, </time>}
                  {minutes > 0 && <time>{minutes} min, </time>}
                  {seconds > 0 && <time>{seconds} sec</time>}
                </div>
              </section>
              <div>{open ? <ChevronUp /> : <ChevronDown />}</div>
            </Article>
          </Collapsible.Trigger>
          <Collapsible.Content>
            <article
              css={css`
                padding: 1rem;
                position: relative;
                height: fit-content;
                position: relative;
                padding: 0.8rem;
                background: var(--table-filters-background);
                border-top: 1px solid var(--neutral-12);
                border-bottom: 1px solid var(--neutral-12);
                dl {
                  margin: 0;
                  display: grid;
                  grid-template-rows: 14px 1fr;
                  row-gap: 0.1rem;
                  column-gap: 0.4rem;
                }

                dt {
                  font-size: 12px;
                  line-height: 100%;
                  color: var(--neutral-01);
                }
                dd {
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 17px;
                  color: var(--neutral-15);
                  margin-bottom: 0.5rem;
                }
              `}
            >
              <IconButton
                css={css`
                  position: absolute;
                  top: 0px;
                  right: 0px;
                  margin: 1rem;
                `}
                onClick={() => {
                  if (
                    confirm('Are you sure you want to delete this recording ?')
                  )
                    mutateDeleteRecording.mutate({
                      cameraId: camera.id,
                      recordingId: original.recordingid
                    });
                }}
              >
                <Trash2Fill />
              </IconButton>
              <dl>
                <dt>Recording ID:</dt>
                <dd>{original.recordingid}</dd>

                <dt>FPS:</dt>
                <dd>{original.framerate}</dd>

                <dt>Resolution</dt>
                <dd>{original.resolution}</dd>

                <dt>File Size</dt>
                <dd>
                  {isLoading
                    ? 'Calculating...'
                    : `${recordingProperties?.[0]?.['EstimatedFileSize'] / 1000}
                  MB`}
                </dd>
              </dl>
            </article>
          </Collapsible.Content>
        </Collapsible.Root>
      );
    }
  }

  //},
];
