import React from 'react';
import { SSE_HOST_URL } from 'api/api-url';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import { getSelectionColumn } from 'components/table-selection-column';
import { Badge } from 'components/badge';
import { dateTimeDif, dateTimeLocal, dateTimeDuration } from 'utils/time';
import RecordingExport from 'api/recording-export';
import { useMutationWrapper } from 'utils/ajax';
import { AuxiliaryButton } from 'components/buttons';
import {
  IconCloudDown,
  IconCloudDataConnection,
  IconDeviceSdCard,
  IconHourglassLow,
  IconCircleCheck
} from '@tabler/icons-react';
import SmallBadge from 'components/small-badge';

function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = [
    'Bytes',
    'KiB',
    'MiB',
    'GiB',
    'TiB',
    'PiB',
    'EiB',
    'ZiB',
    'YiB'
  ];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export const columns = [
  {
    Header: 'Camera Name',
    accessor: 'cameraName',
    pickerDisabled: true,
    pickerHide: true,
    disableSortBy: true
  },
  {
    Header: 'Download',
    accessor: 'key',
    pickerDisabled: true,
    disableSortBy: true,

    Cell: ({ row: { original }, cell: { value } }) => {
      const { mutate, isLoading } = useMutationWrapper(
        ['recording-export', value],
        () => RecordingExport.signedUrl(btoa(value)),
        {
          onSuccess: (url) => {
            const a = document.createElement('a');
            a.href = url;
            a.target = '_blank';
            a.rel = 'noreferrer noopener';
            a.download = 'filename.txt'; // Replace with the desired filename
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }
        }
      );
      if (value)
        return (
          <AuxiliaryButton
            css={css`
              height: 24px;
            `}
            onClick={() => mutate()}
            disabled={isLoading}
          >
            <IconCloudDown />
            Download
          </AuxiliaryButton>
        );
      else return null;
    }
  },
  {
    Header: 'Source',
    accessor: 'type',
    pickerDisabled: true,
    pickerHide: true,
    disableSortBy: true,
    Cell: ({ row: { original }, cell: { value } }) => {
      let Icon = value == 'cloud' ? IconCloudDataConnection : IconDeviceSdCard;
      return (
        <SmallBadge>
          <Icon />
          {value}
        </SmallBadge>
      );
    }
  },
  {
    disableSortBy: true,
    Header: 'Exported By',
    accessor: 'userName',
    pickerDisabled: true,
    pickerHide: true
  },
  {
    Header: 'Start Time',
    accessor: 'startTime',
    pickerDisabled: true,
    pickerHide: true,
    disableSortBy: true,

    Cell: ({ row: { original }, cell }) => (
      <time>{new Date(cell.value).toLocaleString()}</time>
    )
  },
  {
    Header: 'Duration',
    pickerDisabled: true,
    disableSortBy: true,

    Cell: ({ row: { original }, cell }) => (
      <time>{dateTimeDuration(original.startTime, original.stopTime)}</time>
    )
  },
  {
    Header: 'Status',
    accessor: 'status',
    pickerDisabled: true,
    disableSortBy: true,

    Cell: ({ row: { original }, cell: { value } }) => {
      if (value == 'new' && original.pickedAt)
        return (
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 0.2rem;
            `}
          >
            <IconHourglassLow
              className="svg-spin"
              color={'var(--light--others--orange-400-base'}
            />
            processing
          </div>
        );
      else if (value == 'done')
        return (
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 0.2rem;
            `}
          >
            <IconCircleCheck color={'var(--light--primary--400-base)'} />
            done
          </div>
        );
      return <div>{value}</div>;
    }
  },
  {
    Header: 'Processing Time',
    accessor: 'processingDuration',
    role: ['A'],
    disableSortBy: true,
    Cell: ({ cell: { value } }) => {
      if (value) return <time>{value} sec</time>;
      else return null;
    }
  },
  {
    Header: 'Node',
    accessor: 'node',
    pickerHide: true,
    role: ['A'],
    disableSortBy: true
  },

  {
    Header: 'Expires At',
    accessor: 'expireAt',
    pickerDisabled: true,

    disableSortBy: true,

    Cell: ({ row: { original }, cell: { value } }) => {
      return <time>{new Date(value).toLocaleString()}</time>;
    }
  },

  {
    Header: 'Created At',
    accessor: 'createdAt',
    pickerDisabled: true,

    disableSortBy: true,

    Cell: ({ row: { original }, cell: { value } }) => {
      return <time>{new Date(value).toLocaleString()}</time>;
    }
  },

  {
    disableSortBy: true,

    Header: 'File Size',
    accessor: 'uploadedFileSize',
    pickerDisabled: true,
    pickerHide: true,
    Cell: ({ row: { original }, cell: { value } }) => {
      return <em>{formatBytes(value)}</em>;
    }
  }
];
