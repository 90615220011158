import React from "react";
import Dialog from "components/modal/modal";

import { ModalTitle } from "components/modal";
import Async from "components/form/headless-async-select";

import { css } from "@emotion/react";
/** @jsxImportSource @emotion/react */
import Label from "components/label";
import { useModal } from "providers/modal-provider";
import UsageChart from "./usage-chart";
import GenericDoorSelect from "components/form/generic-door-select";
import styled from "@emotion/styled";
import Chip from "components/chip";
import { useAsync } from "hooks/async-select-hook";
import GenericDoor from "api/generic_door";
import MetricEvent from "api/metric";
import { useQueryWrapper } from "utils/ajax";
import ChartFilterMenu from "./chart-filter-menu";
import { useQueries } from "react-query";
import Spinner from "components/spinner";

const COLORS = [
  "#5470c6",
  "#91cc75",
  "#fac858",
  "#ee6666",
  "#73c0de",
  "#3ba272",
  "#fc8452",
  "#9a60b4",
  "#ea7ccc",
];
const ValuesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;
`;

const UsageChartModal = ({ ...props }) => {
  const [doors, setDoors] = React.useState();

  const [doorsData, setDoorsData] = React.useState();

  const [chartData, setChartData] = React.useState();
  const [selection, setSelection] = React.useState(
    props.selection || "-90 days"
  );
  const [filters, setFilters] = React.useState(props.filters);

  React.useEffect(() => {
    setFilters((prev) => ({ ...prev, since: selection }));
  }, [selection]);

  const asyncProps = useAsync({
    selectAll: false,
    isMulti: true,
    maxOptions: 3,
    fetchFn: GenericDoor.search.bind(),
    defaultOptionValue: props.filters.door_id,
  });

  const handleRemoveValue = React.useCallback(
    (id) => {
      const removedValue = asyncProps.value?.find((val) => val.id === id);
      if (!removedValue) return;
      asyncProps.onChange(
        asyncProps.value.filter((val) => val.id !== id),
        { id, action: "remove-value", removedValue }
      );
    },
    [asyncProps?.value]
  );

  const results = useQueries(
    asyncProps?.value?.map((door) => ({
      queryKey: ["usage-modal", { ...filters, door_id: door.id }],
      queryFn: () =>
        MetricEvent.searchLatest({
          since: selection,
          type: "accessGranted",
          door_id: door.id,
        }),
    })) ?? []
  );

  // React.useEffect(() => {
  //   if (!data) return;

  //   let v = data;

  //   setChartData(
  //     v.reverse().map((i) => {
  //       return [i._id, i.count];
  //     })
  //   );
  // }, [data]);

  // React.useEffect(() => {
  //   if (!asyncProps.value) return;
  //   setDoors(asyncProps.value);
  // }, [asyncProps.value]);

  const isOptionSelected = (options, selectedValue) => {
    return false;
  };

  if (!filters) return null;
  else
    return (
      <>
        <ModalTitle>
          {asyncProps?.value?.map((i) => i.name).join(",  ")}
        </ModalTitle>

        <div
          css={css`
            position: relative;
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding: 16px;
            height: auto;
            min-height: 40vh;
            max-height: 40vh;
            width: 40vw;
            .spinner {
              margin: auto;
            }
            [role="radiogroup"] {
              width: 50%;
              margin-left: auto;
            }
          `}
        >
          <ChartFilterMenu selection={selection} setSelection={setSelection} />
          {results.some((r) => r.isLoading) ? (
            <Spinner />
          ) : (
            <UsageChart
              results={results}
              names={asyncProps?.value?.map((d) => d.name)}
              height="30vh"
            />
          )}

          <footer
            css={css`
              display: flex;
              margin-top: auto !important;
            `}
          >
            <ValuesContainer>
              {asyncProps?.value?.map((val, idx) => (
                <Chip
                  key={val.id}
                  onClick={() => handleRemoveValue(val.id)}
                  label={val.name}
                  color={COLORS[idx]}
                />
              ))}
            </ValuesContainer>
            <div
              css={css`
                width: 30%;
                margin-left: auto;
              `}
            >
              <Async
                title=""
                idx="id"
                label="name"
                isMulti={true}
                controlShouldRenderValue={false}
                {...asyncProps}
              />
            </div>
          </footer>
        </div>
      </>
    );
};

export default UsageChartModal;
