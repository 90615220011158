import { memo, useEffect, useState } from "react";
import { Box } from "./box.js";
const styles = {
  display: "inline-block",
  zIndex: 5,
};
export const BoxDragPreview = memo(function BoxDragPreview(props) {
  const [tickTock, setTickTock] = useState(false);
  useEffect(
    function subscribeToIntervalTick() {
      const interval = setInterval(() => setTickTock(!tickTock), 500);
      return () => clearInterval(interval);
    },
    [tickTock]
  );
  return (
    <div style={styles}>
      <Box preview {...props} />
    </div>
  );
});
