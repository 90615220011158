import React from 'react';
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Layer } from 'components/video';
import { OverlayHolder } from 'components/video/overlay';

export default () => {
  return (
    <Layer
      css={css`
        z-index: 1000;
      `}
    >
      <OverlayHolder
        css={css`
          svg > * {
            stroke: var(--red-base);
          }
          text-align: center;
        `}
      >
        <label>Feed Ended</label>
      </OverlayHolder>
    </Layer>
  );
};
