import React from 'react';
import 'css/usage-chart.css';

import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';

import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */

const OnlineChart = (props) => {
  const { chartData } = props;

  const option = {
    animation: false,

    grid: {
      left: 0,
      top: 0,
      right: 0,
      bottom: 0
    },

    tooltip: {
      trigger: 'item',
      formatter: '<b>{b}</b> : {c} ({d}%)',
      position: ['35%', '32%']
    },
    legend: {
      show: false
      // orient: "horizontal",
      // left: 10,
      // padding: 5,

      // bottom: 0,
    },
    series: [
      {
        color: ['#00b04f', '#ea3737'],
        name: 'Health',
        type: 'pie',
        //roseType: "radius",
        radius: ['40%', '70%'],

        data: chartData,
        label: { show: false },
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ],
    ...props.config
  };

  return (
    <>
      <ReactECharts
        opts={{ renderer: 'svg' }}
        option={option}
        style={{ height: '100%' }}
      />
    </>
  );
};

export default OnlineChart;
