import {
  Activity,
  AppIndicator,
  HandThumbsUp,
  HandThumbsUpFill,
  KeyFill,
  Lock,
  LockFill,
  PersonBadgeFill,
  Unlock,
  UnlockFill,
  XLg,
  Power,
  Snow,
  ArrowRepeat,
  Check2Circle,
  Stopwatch,
  ExclamationTriangle,
  Calendar2,
  Gear,
  BoxArrowInLeft,
  BootstrapReboot,
  CameraVideo,
  CameraVideoOff,
  Broadcast,
  Phone,
  TelephoneInboundFill,
  TelephoneMinusFill,
  Diagram3,
  ExclamationCircle,
  ShieldLock,
  Exclamation,
  Snapchat,
  CircleFill,
  Stop,
  Clock,
  ClockHistory,
  Truck,
  Question,
  BatteryHalf,
  ShieldSlash,
  Telephone,
  TelephoneFill,
  TelephonePlusFill,
  Thermometer
} from 'react-bootstrap-icons';
import { ReactComponent as MotionOff } from 'assets/motion-off.svg';
import { ReactComponent as DoubleLockIcon } from 'assets/double-lock.svg';
import { ReactComponent as Recording } from 'assets/recording.svg';
import { ReactComponent as REX } from 'assets/rex.svg';
import { ReactComponent as GuestIcon } from 'assets/guest-icon.svg';
import { css } from '@emotion/css';
import {
  IconAccessPoint,
  IconAlertTriangle,
  IconAlertTriangleFilled,
  IconApiApp,
  IconBarcode,
  IconBarcodeOff,
  IconCloud,
  IconCloudOff,
  IconDashboard,
  IconDashboardOff,
  IconDeviceCctv,
  IconDeviceCctvOff,
  IconDeviceSdCard,
  IconDeviceSpeaker,
  IconDeviceSpeakerOff,
  IconExclamationCircle,
  IconHandGrab,
  IconNetwork,
  IconNetworkOff,
  IconPolygon,
  IconPolygonOff,
  IconRepeatOff,
  IconTemperature,
  IconTemperatureCelsius,
  IconTriangleFilled,
  IconX
} from '@tabler/icons-react';
import { IconAccessPointOff } from '@tabler/icons-react';
/** @jsxImportSource @emotion/react */

const EVENTS_CONSTANTS = {
  getTitle: (e) => {
    const titles = {
      scheduleOn: 'Schedule ' + '"' + e.schedule_name + '"' + ' on',
      scheduleOff: 'Schedule ' + '"' + e.schedule_name + '"' + ' off'
    };

    return titles[e.type];
  },
  readableNames: {
    batteryAlarm: 'Aperio Battery Alarm',
    systemReady: 'System Ready',
    licensePlateGranted: 'Plate Granted',
    licensePlateDenied: 'Plate Denied',
    licensePlateUnfamiliar: 'Plate Unfamiliar',
    recordingOn: 'Recording Started',
    recordingOff: 'Recording Stopped',
    recordingCompleted: 'Recording Completed',
    accessBlocked: 'Access Blocked',
    accessUnauthorized: 'Access Unauthorized',
    motionOn: 'Motion Detected',
    motionOff: 'Motion Stopped',
    motionAppInitialized: 'Motion App Initialized',
    motionAppStopped: 'Motion App Stopped',
    syncCheckDone: 'Sync Check Report',
    doorDoubleLocked: 'Door Double Locked',
    doorUnknownState: 'Door State Unknown',
    doorUnlocked: 'Door Unlocked',
    doorLocked: 'Door Locked',
    doorAccessed: 'Door Accessed',
    doorLockedDown: 'Door Locked Down',
    systemEvent: 'System Event',
    doorAlarm: 'Door Alarm',
    accessNotTaken: 'No Access Taken on Swipe',
    accessGranted: 'Access Granted',
    rexOpenDoor: 'REX Open Door',
    deniedLimit: 'Access Denied Limit',
    accessDenied: 'Access Denied',
    openTooLong: 'Door Open Too Long',
    forcedOpen: 'Door Forced Open',
    normal: 'Door Status Restored',
    online: 'Device Online',
    sensorOnline: 'Sensor Online',
    offline: 'Device Offline',
    sensorOffline: 'Sensor Offline',
    trigger: 'System Event',
    issueDismissed: 'Security Issue Dismissed',
    scheduleOn: 'Schedule On',
    scheduleOff: 'Schedule Off',
    scheduleActivate: 'Schedule Activated',
    scheduleDeactivate: 'Schedule Deactivated',
    siteLocked: 'Site Locked Down',
    siteReleased: 'Site Released',
    ioPort: 'Io Port Trigger',
    timeClock: 'Time Clock',
    setupStart: 'Setup Process Start',
    setupFinish: 'Setup Process Finish',
    setupError: 'Setup Error',
    restoreStart: 'Restore Start',
    restoreFinish: 'Restore Finish',
    restoreError: 'Restore Error',
    syncError: 'Sync Error',
    resetStart: 'Reset Start',
    resetFinish: 'Reset Finish',

    rebootStart: 'Reboot Start',
    rebootFinish: 'Reboot Finish',

    snowDayRule: 'Snow Day Rule Activate',
    manualAccessDoor: 'Operator Access Door',
    manualLockDoor: 'Operator Lock Door',
    manualUnlockDoor: 'Operator Unlock Door',
    manualUnLockDoor: 'Operator Unlock Door',
    manualLockDownDoor: 'Operator Lock Down Door',
    manualLockDownReleaseDoor: 'Operator Release Door',
    notificationAccessDoor: 'Notification Access Door',
    notificationLockDoor: 'Notification Lock Door',
    notificationUnlockDoor: 'Notification Unlock Door',
    notificationUnLockDoor: 'Notification Unlock Door',

    notificationLockDownDoor: 'Notification Lock Down Door',
    notificationLockDownReleaseDoor: 'Notification Release Door',
    restoreMissingEvents: 'Event Restore',
    snapshotTaken: 'Snapshot Taken',
    callStarted: 'Call Satrted',
    callEnded: 'Call Ended',
    callAnswered: 'Call Answered',
    callMissed: 'Call Missed',
    ioOpenTooLong: 'Io Port Open Too Long',
    ioNormal: 'Io Normalized',
    ioTriggerRelay: 'Io Port Trigger',
    sensorAlertOn: 'Sensor Alert On',
    sensorAlertOff: 'Sensor Alert Off',
    storageDisruptionOff: 'Storage OK',
    storageDisruptionOn: 'Storage Disruption',
    tamperingOn: 'Device Tampering',
    criticalTemperature: 'Critical Temperature',
    actionRuleFailed: 'Action Rule Failed',
    networkConnectionOn: 'Network Connection On',
    networkConnectionOff: 'Network Connection Off',
    cloudActionRuleStart: 'Cloud Rule Start',
    cloudActionRuleEnd: 'Cloud Rule End',
    firmwareUpgradeStart: 'Firmware Upgrade Start',
    firmwareUpgradeFinish: 'Firmware Upgrade Finish',
    firmwareUpgradeFailed: 'Firmware upgrade Failed',
    readerOnline: 'Reader Online',
    readerOffline: 'Reader Offline',
    liveStreamStarted: 'Live Stream Started',
    liveStreamEnded: 'Live Stream Ended',
    audioClipStart: 'Audio Clip Start',
    audioClipStop: 'Audio Clip Stop',
    objectAnalyticsOn: 'Object Analytics On',
    objectAnalyticsOff: 'Object Analytics Off',
    objectAppInitialized: 'Object App Initialized',
    objectAppStopped: 'Object App Stopped'
  },

  tooltips: {
    Unauthorized:
      'Possible reasons: invalid Authentication profile on Access Level',
    InvalidAccessProfile: 'NO Access Level is available for this credential'
  },

  getInfo: (e) => {
    let data = {},
      source = {};

    let reason = e.data
      ? EVENTS_CONSTANTS.reasons[e?.data?.Reason || e.topic2]
      : 'Unfamiliar Reason';
    try {
      if (typeof e.data !== 'object') data = JSON.parse(e.data);
      else data = e.data;
      if (typeof e.source !== 'object') source = JSON.parse(e.source);
      else source = e.source;
    } catch (e) {
      (data = {}), (source = {});
    }

    let dn = e.site_name + (e.door_name ? ' \u2022 ' + e.door_name : '');
    let sn = e.site_name + ' \u2022 ' + e.controller_name;
    let rn =
      e.controller_name +
        ' \u2022 ' +
        e.company_name +
        ' \u2022 ' +
        'Duration: ' +
        Number(e?.data?.excecution).toFixed(2) +
        's' || 'N/A';
    // what do u want to return in info based on returned type

    let info = {
      licensePlateGranted:
        e.plateText + ' \u2022 ' + e.camera_name + ' \u2022 ' + e.site_name,
      licensePlateDenied:
        e.plateText + ' \u2022 ' + e.camera_name + ' \u2022 ' + e.site_name,
      licensePlateUnfamiliar:
        e.plateText + ' \u2022 ' + e.camera_name + ' \u2022 ' + e.site_name,
      motionOn: e.camera_name + ' \u2022 ' + e.site_name,
      motionOff: e.camera_name + ' \u2022 ' + e.site_name,
      recordingOn: e.camera_name + ' \u2022 ' + e.site_name,
      recordingOff: e.camera_name + ' \u2022 ' + e.site_name,
      online: e.controller_name + '\u2022 ' + e.company_name,
      sensorOnline: e.sensor_name + '\u2022 ' + e.company_name,
      offline: e.controller_name + '\u2022 ' + e.company_name,
      sensorOffline: e.sensor_name + '\u2022 ' + e.company_name,

      setupStart: e.controller_name + '\u2022 ' + e.company_name,
      setupFinish: e.controller_name + '\u2022 ' + e.company_name,
      setupError: e.controller_name + '\u2022 ' + e.company_name,

      restoreStart: e.controller_name + '\u2022 ' + e.company_name,
      restoreFinish: rn,
      restoreError: e.controller_name + '\u2022 ' + e.company_name,
      resetStart: e.controller_name + '\u2022 ' + e.company_name,
      resetFinish: e.controller_name + '\u2022 ' + e.company_name,
      syncError: e.controller_name + '\u2022 ' + e.company_name,
      accessDenied: reason + ' \u2022 ' + dn,
      accessGranted: (e.c_description || 'Unknown') + ' \u2022 ' + dn,
      accessBlocked: (e.c_description || 'Unknown') + ' \u2022 ' + dn,
      accessUnauthorized: (e.c_description || 'Unknown') + ' \u2022 ' + dn,
      timeClock: (e.c_description || 'Unknown') + ' \u2022 ' + dn,
      accessNotTaken: dn,
      doorUnlocked: dn,
      doorUnknownState: dn,
      doorLocked: dn,
      doorAccessed: dn,
      doorAlarm: dn,
      doorLockedDown: dn,
      deniedLimit: dn,
      issueDismissed: dn,
      normal: dn,
      forcedOpen: dn,
      openTooLong: dn,
      scheduleOn: sn,
      scheduleOff: sn,
      scheduleActivate: sn,
      siteLocked: (e.c_description || 'Operator') + ' \u2022 ' + dn,
      siteReleased: (e.c_description || 'Operator') + ' \u2022 ' + dn,
      ioPort: e.controller_name + ' \u2022 ' + 'Port: ' + e?.source?.port,
      ioTriggerRelay:
        e?.controller_name +
        ' \u2022 ' +
        'Port: ' +
        e?.source?.RelayToken +
        ' \u2022 ' +
        e?.data?.LogicalState,
      snowDayRule: (e.c_description || 'Unknown') + ' \u2022 ' + dn,
      restoreMissingEvents:
        e.controller_name + ' \u2022 ' + 'Execution: ' + e?.data?.duration
    };
    if (e.type in info) return info[e.type];
  },

  reasons: {
    InvalidAccessProfile: 'Invalid Access Level',
    CredentialNotFound: 'Unfamiliar Credential',
    CredentialNotActive: 'Credential Not Active',
    CredentialNotEnabled: 'Credential Disabled',
    CredentialExpired: 'Credential Expired',
    NotPermittedAtThisTime: 'Not Permitted At This Time',
    Unauthorized: 'Credential Not Authorized',
    InvalidPIN: 'Invalid PIN',
    Authentication: 'Can Not Authenticate'
  },

  icons: {
    storageDisruptionOff: () => <IconDeviceSdCard />,
    storageDisruptionOn: () => <IconDeviceSdCard />,
    tamperingOn: () => <IconExclamationCircle />,
    criticalTemperature: () => <IconTemperature />,
    systemReady: () => <Activity />,
    batteryAlarm: () => <BatteryHalf />,
    licensePlateGranted: () => <Truck />,
    licensePlateDenied: () => <XLg />,
    licensePlateUnfamiliar: () => <Truck />,
    recordingOn: () => <CircleFill />,
    recordingOff: () => <Stop />,
    recordingCompleted: () => <Recording />,

    motionOn: () => <Broadcast />,
    motionOff: () => <MotionOff />,
    doorUnknownState: () => <Question />,
    doorDoubleLocked: () => <DoubleLockIcon />,
    doorAccessed: () => <HandThumbsUpFill />,
    doorLocked: () => <LockFill />,
    doorUnlocked: () => <UnlockFill />,
    doorLockedDown: () => <ShieldLock />,
    systemEvent: 'memory',
    issueDismissed: 'done',
    doorAlarm: 'notification_important',

    normal: () => <Check2Circle />,

    accessUnauthorized: () => <Snapchat />,
    accessBlocked: () => <Exclamation />,
    accessNotTaken: () => <Exclamation />,
    accessGranted: (e) => (e?.guest_id ? <GuestIcon /> : <PersonBadgeFill />),
    rexOpenDoor: () => <REX />,
    accessDenied: () => <XLg />,
    deniedLimit: () => <ExclamationTriangle />,
    openTooLong: () => <ExclamationTriangle />,
    forcedOpen: () => <ExclamationTriangle />,
    online: () => <Power />,
    sensorOnline: () => <IconAccessPoint />,
    offline: () => <Power />,
    sensorOffline: () => <IconAccessPoint />,
    trigger: 'memory',
    scheduleOn: () => <Clock />,
    scheduleOff: () => <ClockHistory />,
    scheduleActivate: () => <Stopwatch />,
    scheduleDeactivate: () => <Stopwatch />,
    siteLocked: () => <Diagram3 />,
    siteReleased: () => <Diagram3 />,
    ioPort: () => <BoxArrowInLeft />,
    ioNormal: () => <BoxArrowInLeft />,
    ioTriggerRelay: () => <BoxArrowInLeft />,
    timeClock: 'schedule_send',
    setupStart: () => <Gear />,
    setupFinish: () => <Gear />,
    setupError: () => <XLg />,
    restoreStart: () => <ArrowRepeat />,
    restoreFinish: () => <Check2Circle />,
    restoreError: () => <XLg />,
    syncError: 'sync_problem',
    resetStart: () => <BootstrapReboot />,
    resetFinish: () => <Check2Circle />,
    rebootStart: () => <ArrowRepeat />,
    snowDayRule: () => <Snow />,
    manualAccessDoor: () => <HandThumbsUp />,
    manualUnLockDoor: () => <Unlock />,
    manualUnlockDoor: () => <Unlock />,
    manualLockDoor: () => <Lock />,
    manualLockDownDoor: () => <ShieldLock />,
    manualLockDownReleaseDoor: () => <ShieldSlash />,
    notificationAccessDoor: () => <AppIndicator />,
    notificationLockDoor: () => <AppIndicator />,
    notificationUnlockDoor: () => <AppIndicator />,
    notificationUnLockDoor: () => <AppIndicator />,
    notificationLockDownDoor: () => <ShieldLock />,
    notificationLockDownReleaseDoor: () => <ShieldLock />,
    restoreMissingEvents: () => <Calendar2 />,
    snapshotTaken: 'videocam',
    callStarted: () => <TelephoneInboundFill />,
    callEnded: () => <TelephoneFill className="transform-125" />,
    callAnswered: () => <TelephonePlusFill />,
    callMissed: () => <TelephoneInboundFill />,

    ioOpenTooLong: () => <ExclamationTriangle />,
    ioNormal: () => <Check2Circle />,
    sensorAlertOn: () => <IconAccessPoint />,
    sensorAlertOff: () => <Check2Circle />,
    actionRuleFailed: () => <IconRepeatOff />,
    networkConnectionOn: () => <IconNetwork />,
    networkConnectionOff: () => <IconNetworkOff />,
    cloudActionRuleStart: () => <IconCloud />,
    cloudActionRuleEnd: () => <IconCloudOff />,
    firmwareUpgradeStart: () => <IconApiApp />,
    firmwareUpgradeFinish: () => <IconApiApp />,
    firmwareUpgradeFailed: () => <IconX />,
    readerOnline: () => <IconBarcode />,
    readerOffline: () => <IconBarcodeOff />,
    liveStreamStarted: () => <IconDeviceCctv />,
    liveStreamEnded: () => <IconDeviceCctvOff />,
    audioClipStart: () => <IconDeviceSpeaker />,
    audioClipStop: () => <IconDeviceSpeakerOff />,
    objectAnalyticsOn: () => <IconPolygon />,
    objectAnalyticsOff: () => <IconPolygonOff />,
    objectAppInitialized: () => <IconDashboard />,
    objectAppStopped: () => <IconDashboardOff />,
    motionAppInitialized: () => <IconDashboard />,
    motionAppStopped: () => <IconDashboardOff />
  },

  backgroundColors: {
    storageDisruptionOff: 'var(--event-online-background-color)',
    storageDisruptionOn: 'var(--red-v-02)',
    tamperingOn: 'var(--red-v-02)',
    criticalTemperature: 'var(--red-v-02)',
    batteryAlarm: 'var(--red-v-02)',
    scheduleOn: 'var(--event-online-background-color)',
    scheduleActivate: 'var(--event-online-background-color)',
    scheduleOff: 'var(--event-offlne-background-color)',
    scheduleDeactivate: 'var(--event-offlne-background-color)',

    licensePlateGranted: 'var(--event-online-background-color)',
    licensePlateDenied: 'var(--event-offlne-background-color)',
    licensePlateUnfamiliar: 'var(--orange-v-01)',
    accessBlocked: 'var(--orange-v-01)',
    callStarted: 'var(--primary-v-02)',
    callEnded: 'var(--red-v-02)',
    callMissed: 'var(--red-v-02)',
    callAnswered: 'var(--secundary-v-02)',
    doorDoubleLocked: 'var(--orange-v-01)',

    motionOn: '#fff5ea',
    motionOff: '#fafbfd',
    online: '#EEFFF9',

    motionOn: 'var(--light--others--purple-200)',
    motionOff: 'var(--motionStop-stats-background-color)',
    online: 'var(--event-online-background-color)',
    sensorOnline: 'var(--event-online-background-color)',
    offline: 'var(--event-offlne-background-color)',
    sensorOffline: 'var(--event-offlne-background-color)',

    restoreFinish: 'var(--primary-v-02)',
    restoreStart: 'var(--orange-v-01)',
    setupStart: 'var(--neutral-07)',
    setupFinish: 'var(--primary-v-02)',
    setupError: 'var(--red-v-02)',
    accessGranted: 'var(--event-accessGranted-background-color)',
    rexOpenDoor: 'var(--event-accessGranted-background-color)',
    accessDenied: 'var(--event-accessDenied-background-color)',
    openTooLong: 'var(--event-openTooLong-background-color)',
    forcedOpen: 'var(--event-forcedOpen-background-color)',
    doorLocked: 'var(--event-doorLocked-background-color)',
    doorAccessed: 'var(--event-doorAccessed-background-color)',
    doorUnlocked: 'var(--event-doorUnlocked-background-color)',
    doorLockedDown: 'var(--orange-v-01)',
    deniedLimit: 'var(--red-v-02)',
    ioOpenTooLong: 'var(--red-v-02)',
    sensorAlertOn: 'var(--red-v-02)',
    sensorAlertOff: 'var(--event-online-background-color)',
    actionRuleFailed: 'var(--event-offlne-background-color)',
    networkConnectionOn: 'var(--event-online-background-color)',
    networkConnectionOff: 'var(--event-offlne-background-color)',
    cloudActionRuleStart: 'var(--event-online-background-color)',
    cloudActionRuleEnd: 'var(--event-offlne-background-color)',
    firmwareUpgradeStart: 'var(--orange-v-01)',
    firmwareUpgradeFinish: 'var(--event-online-background-color)',
    firmwareUpgradeFailed: 'var(--event-offlne-background-color)',
    readerOnline: 'var(--event-online-background-color)',
    readerOffline: 'var(--event-offlne-background-color)',
    liveStreamStarted: 'var(--event-online-background-color)',
    liveStreamEnded: 'var(--event-offlne-background-color)'
  },

  colors: {
    storageDisruptionOff: 'var(--primary-base)',
    storageDisruptionOn: 'var(--red-base)',
    tamperingOn: 'var(--red-base)',
    criticalTemperature: 'var(--red-base)',
    systemReady: 'event',
    batteryAlarm: 'var(--red-base)',
    scheduleOn: 'var(--primary-base)',
    scheduleActivate: 'var(--primary-base)',
    scheduleOff: 'var(--red-base)',
    scheduleDeactivate: 'var(--red-base)',

    licensePlateGranted: 'var(--primary-base)',
    licensePlateDenied: 'var(--red-base)',
    licensePlateUnfamiliar: 'var(--orange-base)',
    accessBlocked: 'var(--orange-base)',
    callStarted: 'var(--primary-base)',
    callEnded: 'var(--red-base)',
    callMissed: 'var(--red-base)',
    callAnswered: 'var(--secundary-base)',
    motionOn: 'var(--light--others--purple-400-base)',
    motionOff: '#7f8795',
    restoreStart: '#F59728',
    restoreFinish: 'var(--primary-base)',
    online: 'var(--primary-base)',
    sensorOnline: 'var(--primary-base)',
    offline: 'var(--red-base)',
    sensorOffline: 'var(--red-base)',
    doorUnknownState: 'error_outline',
    doorDoubleLocked: 'var(--orange-base)',
    doorAccessed: 'var(--primary-base)',
    doorLocked: 'var(--red-base)',
    doorUnlocked: 'var(--primary-base)',
    doorLockedDown: 'var(--orange-base)',
    systemEvent: 'gray-400',
    issueDismissed: 'blue-300',
    doorAlarm: 'purple-400',
    normal: 'black',
    accessNotTaken: 'no_meeting_room',
    accessGranted: 'var(--primary-base)',
    rexOpenDoor: 'var(--primary-base)',
    accessDenied: 'var(--red-base)',
    deniedLimit: 'var(--red-base)',
    openTooLong: 'var(--red-base)',
    forcedOpen: 'var(--red-base)',
    ioOpenTooLong: 'var(--red-base)',

    trigger: 'memory',

    siteLocked: 'vpn_lock',
    siteReleased: 'vpn_lock',
    ioPort: 'settings_input_composite',
    timeClock: 'schedule_send',
    setupStart: 'settings',
    setupFinish: 'var(--primary-base)',

    restoreError: 'error_outline',
    syncError: 'sync_problem',
    snowDayRule: 'lock_clock',
    manualAccessDoor: '#267DFF',
    manualLockDoor: '#267DFF',
    manualunLockDoor: '267DFF',
    manualUnlockDoor: '267DFF',
    manualLockDownDoor: 'block',
    manualLockDownReleaseDoor: 'check',
    restoreMissingEvents: 'event',
    setupError: 'var(--red-base)',
    snapshotTaken: 'blue-300',
    sensorAlertOff: 'var(--primary-base)',
    sensorAlertOn: 'var(--red-base)',
    actionRuleFailed: 'var(--red-base)',
    networkConnectionOn: 'var(--primary-base)',
    networkConnectionOff: 'var(--red-base)',
    cloudActionRuleStart: 'var(--primary-base)',
    cloudActionRuleEnd: 'var(--red-base)',
    firmwareUpgradeStart: 'var(--light--others--orange-500)',
    firmwareUpgradeFinish: 'var(--primary-base)',
    firmwareUpgradeFailed: 'var(--red-base)',
    readerOnline: 'var(--primary-base)',
    readerOffline: 'var(--red-base)',
    liveStreamStarted: 'var(--primary-base)',
    liveStreamEnded: 'var(--red-base)'
  },

  acEvents: [
    'online',
    'offline',
    'restoreError',
    'setupStart',
    'setupFinish',
    'syncError',
    'restoreFinish',
    'restoreStart',
    'restoreMissingEvents'
  ],

  getEventDetails: (e) => {
    // New function to retries additional event details
    let info = {
      restoreStart: `By: ${e.service} | ${e.user_name}`,
      restoreFinish: `${Number(e.execution).toFixed(2)} sec ${'\u2022'} By:  ${
        e.service
      } | ${e.user_name}`,
      setupFinish: e.runTime,

      restoreError: `${e.data?.errorMsg}`,
      scheduleOn: `${e.schedule_name}`,
      scheduleOff: `${e.schedule_name}`,
      scheduleActivate: `${e.schedule_name}`,
      scheduleDeactivate: `${e.schedule_name}`,

      notificationAccessDoor: `${e.notification_name}`,
      notificationLockDoor: `${e.notification_name}`,
      notificationUnlockDoor: `${e.notification_name}`,
      notificationUnLockDoor: `${e.notification_name}`,
      notificationLockDownDoor: `${e.notification_name}`,
      notificationLockDownReleaseDoor: `${e.notification_name}`,

      manualAccessDoor: `Operator: ${e.userName}`,
      manualLockDoor: `Operator: ${e.userName}`,
      manualunLockDoor: `Operator: ${e.userName}`,
      manualUnlockDoor: `Operator: ${e.userName}`,
      manualLockDownDoor: `Operator: ${e.userName}`,
      manualLockDownReleaseDoor: `Operator: ${e.userName}`,

      licensePlateGranted: `${e.plateText} * CAR STATE: ${e.carState}`,
      licensePlateDenied: `${e.plateText} * CAR STATE: ${e.carState}`,
      licensePlateUnfamiliar: `${e.plateText} * CAR STATE:  ${e.carState}`,
      ioPort: `${e.auxiliary_name || e.door_name} *  ${e.port_name}`,
      ioTriggerRelay: `${e.auxiliary_name || e.door_name} *  ${e.port_name}`,
      callAnswered: `By: ${e.user_name}`,
      sensorAlertOn: `topic: ${e.topic}`,
      sensorAlertOff: `topic: ${e.topic}`,
      restoreMissingEvents: `time: ${e.data?.duration}, pulled: ${e.data?.pulledEvents}, missing ${e.data?.missingEvents}`,
      actionRuleFailed: `${e.data?.description}`,
      cloudActionRuleEnd: `${e.feedEndReason}`
    };
    if (e.type in info) return info[e.type];
  }
};

export default EVENTS_CONSTANTS;
