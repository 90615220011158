import React from "react";
import Controller from "api/controller";
import Device from "api/device";
import { useQuery } from "react-query";
import ReactECharts from "echarts-for-react";
import Spinner from "components/spinner";
import { css } from "@emotion/react";
/** @jsxImportSource @emotion/react */
import { RadioGroup } from "@headlessui/react";
import Heading from "components/heading";
import EmptyHolder from "components/empty-holder";

const styles = css`
  width: 100%;
  .spinner {
    display: block;
    margin: 24px auto;
  }
  header {
    display: block;
    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 100%;
      margin-bottom: 16px;
    }
  }
  text {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 100%;
    color: var(--neutarl-00);
  }
`;

const colors = [];

const HealthChart = ({ device_id, ...props }) => {
  const [chartData, setChartData] = React.useState([]);
  const [chartColor, setChartColor] = React.useState();

  const { isLoading, data } = useQuery(["health", device_id], () =>
    Device.getHealth(device_id)
  );

  let option = {
    animation: false,
    title: {
      subtext: "7 day ping pattern",
    },
    series: [
      {
        silent: true,
        type: "gauge",
        center: ["50%", "80%"],
        radius: "100%",
        startAngle: 180,
        endAngle: 0,
        min: 1,
        max: 100,
        color: chartColor,
        splitLine: {
          show: false,
          // distance: -52,
          // length: 14,
          // lineStyle: {
          //   width: 3,
          //   color: '#999'
          // }
        },

        progress: {
          show: true,
          color: "auto",
        },
        pointer: {
          show: false,
          icon: "path://M12.8,0.7l12,40.1H0.7L12.8,0.7z",
          length: "12%",
          width: 20,
          offsetCenter: [0, "-60%"],
          color: "auto",
        },
        axisTick: {
          show: false,
        },
        axisLabel: { show: false },

        detail: {
          fontSize: 20,
          offsetCenter: [0, "0%"],
          valueAnimation: true,
          color: "black",
          formatter: function (value) {
            return value + "%";
          },
        },
        data: [
          {
            value: chartData,
          },
        ],
      },
    ],
  };

  React.useEffect(() => {
    if (!data) return;

    let d =
      Math.round(
        (data.filter((d) => d == "1").length / (data.length / 100)) * 10
      ) / 10;

    setChartData(d);
    setChartColor(d < 50 ? "var(--red-base)" : "var(--primary-v-01)");
  }, [data]);

  return (
    <article className="health-chart" css={styles}>
      {data && data?.length > 0 && (
        <>
          <ReactECharts
            opts={{ renderer: "svg" }}
            option={option}
            style={{ height: props.height || 200, padding: "0px" }}
          />
        </>
      )}
      {data && data.length == 0 && <EmptyHolder />}
      {isLoading && <Spinner />}
    </article>
  );
};

export default HealthChart;
