import { Search } from "react-bootstrap-icons";
import { TextField } from "./form";
import { css } from "@emotion/react";
/** @jsxImportSource @emotion/react **/
import { useDebounce } from "hooks/debounce-hook";

const iconStyle = css`
  position: absolute;
  top: 40px;
  left: 16px;
  opacity: 0.4;

  [data-compact] & {
    top: 36px;
  }

  &:first-child {
    top: 16px;
  }
`;

const SearchInput = (props) => {
  return (
    <article
      className="search-filter-container"
      css={css`
        color: var(--neutral-00);
        position: relative;
        display: flex;
        background: var(--neutral-09);
        /* Neutrals/neutral-04 */
        height: 48px;
        border: 1px solid var(--neutral-12);
        box-sizing: border-box;
        border-radius: 4px;
      `}
    >
      <Search css={iconStyle} />
      <input
        onKeyDown={(e) => e.keyCode === 27 && e.stopPropagation()}
        type="search"
        placeholder="Type to search..."
        css={css`
          color: var(--neutral-00);
          svg + & {
            padding-left: 42px;
          }
          &:focus {
            outline: 3px solid var(--primary-base);
          }
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 8px 16px;
          width: 100%;
          box-sizing: border-box;
          background: var(--neutral-09);
          border: none;
          width: 400px;
          box-sizing: border-box;
          border-radius: 4px;
          outline: none;
          cursor: text;
        `}
        {...props}
      />
    </article>
  );
};

export default SearchInput;
