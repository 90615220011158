import { callAPI } from 'utils/ajax';

const Vendor = {
  search(input = {}) {
    return callAPI('GET', `/vendor`, input);
  },

  stats(input) {
    return callAPI('GET', `/vendor/stats`, input);
  },

  save(input = {}, id = '') {
    return callAPI(id ? 'PUT' : 'POST', `/vendor/${id}`, input);
  },

  delete(id) {
    return callAPI('DELETE', `/vendor/${id}`);
  }
};

export default Vendor;
