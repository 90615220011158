import React from 'react';

/** @jsxImportSource @emotion/react */
import {
  TabItem,
  TabGroup,
  TabPanel,
  TabPanels,
  TabList
} from 'components/tabs';
import { css } from '@emotion/react';
import { Calendar, Record2 } from 'react-bootstrap-icons';
import EventList from 'components/event-list';
import UsageChartHolder from 'components/charts/usage-chart-holder';
import LiveEvents from 'components/live-events';
import { useEventList } from 'hooks/use-event-list';
import ActivityChartFormatter from 'components/activity-chart-formatter';

const Tabs = ({ elevator = {} }) => {
  let filter = React.useCallback(
    (data) => {
      if (
        [
          'accessGranted',
          'accessDenied',
          'manualAccessDoor',
          'doorAccessed',
          'doorLocked'
        ].includes(data?.type) &&
        data.elevator_id == elevator.id
      )
        return true;
      return false;
    },
    [elevator]
  );

  const query = useEventList({
    filters: { elevator_id: elevator?.id }
  });

  return (
    <TabGroup>
      <TabList
        css={css`
          width: 100%;
          box-shadow: unset;
          gap: 2rem;
          padding: 0px 16px;
          box-shadow: inset 0px -1px 0px var(--neutral-10);
          [role='tab'] {
            min-width: unset;
          }
        `}
      >
        {/* <TabItem title="Floors">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-stairs-up"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M4 20h4v-4h4v-4h4v-4h4"></path>
            <path d="M4 11l7 -7v4m-4 -4h4"></path>
          </svg>
        </TabItem> */}
        <TabItem title="Events">
          <Calendar size={24} />
        </TabItem>
        <TabItem title="Live Events">
          <Record2 size={32} />
        </TabItem>
      </TabList>
      <TabPanels>
        {/* <TabPanel
          css={css`
            overflow: hidden;
            height: 100%;
            display: flex;
            flex-direction: column;
            padding: 1rem;
          `}
        ></TabPanel> */}
        <TabPanel
          css={css`
            overflow: hidden;
            height: 100%;
            display: grid;
            grid-template-rows: auto 250px;
            gap: 1rem;
            padding: 1rem;
            > * {
              flex: 1;
            }
          `}
        >
          <EventList query={query} />
          {/* <UsageChartHolder
            name={elevator.name}
            filters={{ elevator_id: elevator?.id }}
          /> */}
          <ActivityChartFormatter
            full
            activity={elevator.activity}
            style={{ height: '100%', width: '100%' }}
            config={{}}
          />
        </TabPanel>
        <TabPanel
          css={css`
            position: relative;
            height: 100%;
            overflow: auto;
          `}
        >
          <LiveEvents filter={filter} />
        </TabPanel>
      </TabPanels>
    </TabGroup>
  );
};

export default Tabs;
