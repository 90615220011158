import React from 'react';
import { Dialog } from '@radix-ui/react-dialog';
import {
  DialogTrigger,
  DialogContent,
  DialogClose
} from 'components/dialog.js';
import { Formik, Form, Field, FieldArray } from 'formik';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import { DateTimeField } from 'components/form';
import { endOfDay } from 'date-fns';

import { Button, AuxiliaryButton } from 'components/buttons';
import { IconExclamationCircle, IconTimeDuration60 } from '@tabler/icons-react';
import Alert from 'components/alert';
import { useMutationWrapper } from 'utils/ajax';
import Device from 'api/device';
import { addMinutes, addDays } from 'date-fns';
import { QueryClient, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
const Content = ({ device, open, setOpen }) => {
  const { mutate, isLoading } = useMutationWrapper(
    ['device', 'upgrade-firmware', device.id],
    (start) => Device.upgradeFirmware(device.id, { start })
  );

  let queryClient = useQueryClient();
  const history = useHistory();

  return (
    <DialogContent
      css={css`
        height: auto !important;
        width: 25vw !important;
      `}
    >
      <Formik
        initialValues={{ start: endOfDay(new Date()) }}
        onSubmit={(values, actions) => {
          mutate(new Date(values.start).toISOString(), {
            onSettled: () => {
              setOpen(false);
              queryClient.resetQueries(['device', 'get', device.id]);
            }
          });
        }}
      >
        {({ setFieldValue, values }) => (
          <Form
            disabled={isLoading}
            css={css`
              .react-datepicker {
                box-shadow: none !important;
                border: none !important;
                min-height: 350px;
              }
              > footer {
                display: flex;
                gap: 1rem;
                justify-content: space-between;
              }
            `}
          >
            <Alert>
              Choose a date and time to preform a firmware upgrade.
              <br /> Device will be unavilable a window of 15-30 minutes
              depending on network latency and scheduleing.
            </Alert>
            <Field
              minDate={new Date()}
              maxDate={addDays(new Date(), 30)}
              component={DateTimeField}
              name="start"
              inline
            />
            <footer>
              <Button compact type="submit">
                <IconTimeDuration60 />
                Schedule Upgrade...
              </Button>

              <AuxiliaryButton
                compact
                onClick={() =>
                  setFieldValue('start', addMinutes(new Date(), 1))
                }
                type="submit"
              >
                <IconExclamationCircle />
                Upgrade Now...
              </AuxiliaryButton>
            </footer>
          </Form>
        )}
      </Formik>
    </DialogContent>
  );
};

export default ({ device, children }) => {
  if (!device) return null;

  const [open, setOpen] = React.useState();

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      {open && <Content device={device} setOpen={setOpen} />}
    </Dialog>
  );
};
