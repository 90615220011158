import React from 'react';
import { useAuth } from 'providers/auth.js';
import UserMenu from 'base/user-menu';
import VendorMenu from 'base/vendor-menu';
import InstallerMenu from 'base/installer-menu';
import { useLayout } from 'providers/layout-provider';
import styled from '@emotion/styled';
import AdminMenu from './admin-menu';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Badge } from 'components/badge';

import { CheckLg, ArrowClockwise } from 'react-bootstrap-icons';
import MenuItem from 'components/menu-item';
import useBuildVersion from 'hooks/build-version-hook';

const MenuWrapper = styled('aside')`
  color: var(--neutral-00) !important;
  background: var(--neutral-09);
  position: relative;
  display: flex;
  flex-direction: column;
  grid-area: menu;
  width: 0px;
  transition: all ease 200ms 200ms;
  opacity: 0;
  padding: 8px 0;
  overflow-x: hidden;
  &[data-show='true'] {
    width: 270px;
    opacity: 1;
  }

  > section {
    padding-bottom: 12px;
  }

  section.admin {
    font-size: 12px;
    font-weight: bold;
    border-top: 1px solid var(--neutral-12);
    border-bottom: 1px solid var(--neutral-12);
    padding-top: 12px;
    padding-bottom: 12px;

    > p {
      opacity: 0.4;
      padding: 0px 24px;
    }
  }
`;

const MainMenu = (props) => {
  const { user } = useAuth();
  const { showMenu } = useLayout();

  if (!user) return null;

  const levels = (
    <section
      css={css`
        font-size: 10px;
        margin: 4px 8px 0 auto;
        > [role='badge'] {
          font-size: 8px;
          min-height: 12px;
          padding: 0 6px;
          margin: 0 4px;
        }
      `}
    >
      {user.role == 'A' && <Badge color="orange">Admin</Badge>}
      {user.impersonator && (
        <Badge color="orange" title="Impersonating">
          Impers.
        </Badge>
      )}
      {user.impersonator && <>&gt;</>}
      {user.role == 'V' && (
        <Badge title={user.vendor_name} color="green">
          Vendor
        </Badge>
      )}
      {user.accessing && <>&gt;</>}
      {(user.role == 'I' || user.accessing) && (
        <Badge title={user.installer_name} color="blue">
          Installer
        </Badge>
      )}
      {user.managing && <>&gt;</>}
      {(user.role == 'C' || user.managing) && (
        <Badge title={user.company_name} color="red">
          Company
        </Badge>
      )}
    </section>
  );

  let menu = null;

  if (user.company_id) menu = <UserMenu {...props} />;
  else if (user.role == 'I' || user.accessing)
    menu = <InstallerMenu {...props} />;
  else if (user.role == 'V') menu = <VendorMenu {...props} />;
  else if (user.role == 'A') menu = <AdminMenu {...props} />;
  else menu = <UserMenu {...props} />;

  return (
    <MenuWrapper id="sidebar" data-show={showMenu}>
      {(user.impersonator || user.role == 'A') && levels}

      {(user.impersonator ||
        (user.role == 'A' && (user.managing || user.accessing))) && (
        <section className="admin">
          <p
            css={css`
              color: var(--neutral-00);
              margin-bottom: 8px !important;
            `}
          >
            Admin
          </p>
          <MenuItem to="/manage-users" title="Manage Users">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              className="bi bi-incognito"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="m4.736 1.968-.892 3.269-.014.058C2.113 5.568 1 6.006 1 6.5 1 7.328 4.134 8 8 8s7-.672 7-1.5c0-.494-1.113-.932-2.83-1.205a1.032 1.032 0 0 0-.014-.058l-.892-3.27c-.146-.533-.698-.849-1.239-.734C9.411 1.363 8.62 1.5 8 1.5c-.62 0-1.411-.136-2.025-.267-.541-.115-1.093.2-1.239.735Zm.015 3.867a.25.25 0 0 1 .274-.224c.9.092 1.91.143 2.975.143a29.58 29.58 0 0 0 2.975-.143.25.25 0 0 1 .05.498c-.918.093-1.944.145-3.025.145s-2.107-.052-3.025-.145a.25.25 0 0 1-.224-.274ZM3.5 10h2a.5.5 0 0 1 .5.5v1a1.5 1.5 0 0 1-3 0v-1a.5.5 0 0 1 .5-.5Zm-1.5.5c0-.175.03-.344.085-.5H2a.5.5 0 0 1 0-1h3.5a1.5 1.5 0 0 1 1.488 1.312 3.5 3.5 0 0 1 2.024 0A1.5 1.5 0 0 1 10.5 9H14a.5.5 0 0 1 0 1h-.085c.055.156.085.325.085.5v1a2.5 2.5 0 0 1-5 0v-.14l-.21-.07a2.5 2.5 0 0 0-1.58 0l-.21.07v.14a2.5 2.5 0 0 1-5 0v-1Zm8.5-.5h2a.5.5 0 0 1 .5.5v1a1.5 1.5 0 0 1-3 0v-1a.5.5 0 0 1 .5-.5Z"
              />
            </svg>
          </MenuItem>
        </section>
      )}

      {menu}

      {/* {process.env.NODE_ENV == "development" && <div css={css`
      margin-top: 12px;
      border-top: 1px solid var(--neutral-12);
      padding-top: 12px;
      > div { height: 400px; overflow: auto }
      .object-content {
        width: max-content;
      }`}>
      <ReactJson iconStyle="circle" src={user} name="user" />
    </div>} */}
    </MenuWrapper>
  );
};

export default MainMenu;
