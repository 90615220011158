import { dataFetchQuery } from 'utils/ajax';

import { API_HOST_URL } from './api-url';
const API_URL = API_HOST_URL + '/event_api';

const Event = {
  getLatestEvents(input, opts = {}) {
    return dataFetchQuery(`${API_URL}/getLatestEvents`, input);
  },
  getTrends(input) {
    return dataFetchQuery(`${API_URL}/getTrends`, input);
  },

  stats() {
    return dataFetchQuery(`${API_URL}/stats`);
  },
  search(input, opts = {}) {
    return dataFetchQuery(`${API_URL}/search`, input);
  },

  searchLast7DaysRestore(filters = {}) {
    return dataFetchQuery(`${API_URL}/getLatestEvents`, {
      types: ['restoreStart', 'restoreFinish', 'restoreError'],
      lastDays: '7 day',
      limit: 500,
      ...filters
    });
  },

  search7DaysHealth(filters = {}) {
    return dataFetchQuery(`${API_URL}/getLatestEvents`, {
      types: ['online', 'offline'],
      lastDays: '7 day',
      limit: 500,
      ...filters
    });
  },

  get(opts = {}) {
    opts = opts || {};
    return callAPISimple(`${SITE_URL}api/event_api/search`, opts);
  },

  notices(cb) {
    callAPISimple(`${SITE_URL}api/event_api/notices`, null, cb);
  },

  searchLatestForCompany(type, cb) {
    callAPISimple(`${SITE_URL}api/event_api/getLatestEvents`, { type }, cb);
  },

  searchLatestForSite(site, cb) {
    callAPISimple(
      `${SITE_URL}api/event_api/getLatestEvents`,
      { site_id: site, types: SITE_EVENTS },
      cb
    );
  },

  searchLatestForUUID(uuid, cb) {
    callAPISimple(
      `${SITE_URL}api/event_api/getLatestEvents`,
      { uuid, types: AC_EVENTS },
      cb
    );
  },

  searchLatestForDoor(token, cb) {
    //not used --- door uses EventStream
    callAPISimple(`${SITE_URL}api/event_api/getLatestEvents`, { token }, cb);
  },

  wasRestoredPastWeek() {
    return callAPISimple(`${SITE_URL}api/event_api/getLatestEvents`, {
      restored: true,
      lastDays: '-7 day',
      limit: 1
    });
  },

  searchLatestPersonEvents(person, cb) {
    callAPISimple(`${SITE_URL}api/event_api/getLatestEvents`, { person }, cb);
  },

  getCount(opts) {
    return dataFetchQuery(`${API_URL}/getCount`, opts);
  },

  getDailyCount(opts, cb) {
    callAPISimple(`${SITE_URL}api/event_api/getDailyCount`, { ...opts }, cb);
  },

  details(id) {
    return callAPISimple(`${SITE_URL}api/event_api/details/${id}`, {});
  },

  fake(id) {
    Form.submit(form, 'api/event_api/fake', (o) => {
      TextArea.set(sr, o.message);
      a(
        document.querySelector('span.log'),
        h$('p', `[${new Date()} ]--> ${o.message}`)
      );
    });
  },

  setNotes(id, notes) {
    dataFetchQuery(`${API_URL}/setNotes/${id}`, { notes });
  },

  getDeviceHealthEvents(input = {}) {
    return dataFetchQuery(`${API_URL}/getDeviceHealthEvents`, input);
  }
};

export default Event;
