import React from 'react';
import { Field } from 'formik';
/** @jsxImportSource @emotion/react **/

import { css } from '@emotion/react';
import { InputLabel } from 'components/form';
import { InputHelper } from 'components/form';
import { ErrorMessage } from 'components/form';
import { Info, InfoCircle } from 'react-bootstrap-icons';

const OutputSelector = (props) => {
  return (
    <div
      css={css`
        input[type='radio'] {
          appearance: none;
          border-radius: 50%;
          width: 24px;
          height: 24px;
          border: 2px solid var(--neutral-12);
          transition: 0.2s all linear;
        }

        input[type='radio']:disabled + label {
          opacity: 50%;
        }
        input[type='radio']:checked {
          background-color: var(--primary-base);
          border: 6px solid var(--neutral-09);
          outline: 2px solid var(--neutral-12);
        }
        input[type='radio'] + label {
          font-weight: 500;
        }
        height: 100%;
        display: flex;
        flex-direction: column;

        [role='group'] {
          display: flex;
          align-items: start;
          flex-direction: column;
          margin-top: 16px;
          gap: 0.5rem;
        }
        label {
          display: flex;
          align-items: center;
        }
        p {
          font-weight: bold;
          display: flex;
          align-items: center;
          gap: 8px;
        }
      `}
    >
      <InputLabel label="Output Formats" />

      {/* <InputHelper helper="PDF is limited to 2000 events" /> */}
      <div role="group" aria-labelledby="checkbox-group">
        {/* <label>
          <Field type="radio" name="output" value="pdf" />
          PDF
        </label> */}
        <label>
          <Field type="radio" name="output" value="csv" />
          CSV
        </label>
        <label>
          <Field type="radio" name="output" value="html" />
          HTML
        </label>
        <label>
          <Field type="radio" name="output" value="xlsx" />
          XLS (Excel)
        </label>
      </div>

      <ErrorMessage name="output" />
    </div>
  );
};

export default OutputSelector;
