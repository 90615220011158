/** @jsxImportSource @emotion/react */
import React from 'react';
import Camera from 'api/camera';
import Template from 'base/template';
import { css } from '@emotion/react';

import PageTitle from 'components/page-title';
import Content from 'base/content';
import { useQueryWrapper } from 'utils/ajax';
import { useParams } from 'react-router-dom';
import { useNotFound } from 'hooks/not-found-hook';
import Breadcrumbs from 'components/breadcrumbs';
import CameraSettings from 'views/cameras/settings';

const Settings = (props) => {
  const { id } = useParams();

  const [camera, setCamera] = React.useState(props?.location?.state?.camera);

  const query = useQueryWrapper(
    ['camera', 'details', id],
    () => Camera.search({ id }),
    {
      enabled: !camera
    }
  );

  React.useEffect(() => {
    if (!query?.data?.data?.[0]) return;
    setCamera(query.data.data?.[0]);
  }, [query?.data?.data?.[0]]);

  useNotFound(id, query, props);

  return (
    <Template {...props}>
      <Content {...props}>
        <PageTitle>{camera?.name}</PageTitle>
        <Breadcrumbs crumbs={props.crumbs} />
        <CameraSettings camera={camera} />
      </Content>
    </Template>
  );
};

export default Settings;
