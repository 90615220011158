import { Card } from './card';
import { IconPower } from '@tabler/icons-react';

import { Button } from 'components/buttons';
import { useMutateReboot } from '../actions';
import useAlertDialog from 'providers/alert-dialog-provider';

export default ({ device }) => {
  const mutateReboot = useMutateReboot(device);
  const confirm = useAlertDialog();
  const handleReboot = async () => {
    if (
      await confirm({
        description: `Are you sure you want to reboot ${device?.name}? reboot might take up to 2 minutes and will deem the device unreachable. `
      })
    )
      mutateReboot.mutate(device.id, {
        onSuccess: () => {}
      });
  };
  return (
    <Card className="reboot">
      <div className="icon">
        <IconPower />
      </div>
      <div className="content">
        <h2>Reboot</h2>
        <span className="description">
          Reboot Your device to clean memory and defrag space.
        </span>
      </div>
      <div className="action">
        <Button
          onClick={handleReboot}
          disabled={mutateReboot.isLoading}
          compact
        >
          Reboot
        </Button>
      </div>
    </Card>
  );
};
