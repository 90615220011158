import Dialog from "components/modal/modal";
import React from "react";

const ModalContext = React.createContext();
import { useDialogState, DialogDisclosure } from "reakit/Dialog";

const ModalProvider = ({ children }) => {
  const [modalState, setModalState] = React.useState({});

  const Content = modalState?.component;
  const dialog = useDialogState({ animated: true });

  React.useEffect(() => {
    if (!dialog.visible) setModalState(null);
  }, [dialog.visible]);

  return (
    <ModalContext.Provider
      value={{
        dialog,
        modalState,
        setModalState,
      }}
    >
      {children}
      <Dialog {...modalState?.dialogProps}>
        {modalState?.component && (
          <Content data={modalState?.data} {...modalState} />
        )}
      </Dialog>
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  return React.useContext(ModalContext);
};

export default ModalProvider;
