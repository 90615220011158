/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import TimeFormatter from 'components/time-formatter';
import {
  BoxArrowInRight,
  Building,
  DoorOpen,
  DoorOpenFill,
  HandThumbsUp,
  HandThumbsUpFill,
  Info,
  LockFill,
  Person,
  PersonBadge,
  PersonCircle,
  ShieldShaded,
  Stopwatch,
  UnlockFill,
  Cpu,
  HddNetwork,
  AppIndicator,
  Shop
} from 'react-bootstrap-icons';
import { getSelectionColumn } from 'components/table-selection-column';
import { Badge } from 'components/badge';

export function getIcon(entry) {
  // add to lower csse heer and re-test
  if (entry.message?.includes('notification')) return AppIndicator;
  if (entry.message?.includes('Logged')) return BoxArrowInRight;
  if (entry.message?.includes('user')) return PersonCircle;
  if (entry.message?.includes('credential')) return PersonBadge;
  if (entry.message?.includes('person')) return Person;
  if (entry.message?.includes('access level')) return ShieldShaded;
  if (entry.message?.includes('company')) return Building;
  if (entry.message?.includes('Unlocked')) return UnlockFill;
  if (entry.message?.includes('Locked')) return LockFill;
  if (entry.message?.includes('Granted access')) return HandThumbsUp;
  if (entry.message?.includes('Controller')) return Cpu;
  if (entry.message?.includes('Device')) return HddNetwork;
  if (entry.message?.includes('Door')) return DoorOpen;
  if (entry.message?.includes('Schedule')) return Stopwatch;
  if (entry.message?.includes('Vendor')) return Shop;
  else return Info;
}

const RoleBadge = ({ role }) => {
  if (role == 'V') return <Badge color="green">V</Badge>;
  else if (role == 'I') return <Badge color="blue">I</Badge>;
  else if (role == 'C') return <Badge color="red">C</Badge>;
  else if (role == 'A') return <Badge color="orange">A</Badge>;
  else return null;
};

export const columns = [
  {
    Header: 'Time',
    accessor: 'createdAt',
    newSorting: true,
    asc: 'newest',
    desc: 'oldest',
    Cell: ({ cell: { value } }) => (value ? <TimeFormatter dt={value} /> : null)
  },
  {
    Header: 'User',
    accessor: 'user_name',
    sortDisable: true,
    disableSortBy: true,
    Cell: ({ cell: { value }, row: { original } }) => {
      if (original.service != 'main-app') return <Badge>System</Badge>;

      return (
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 0.4rem;
          `}
        >
          <RoleBadge role={original.user_role} />
          <span>{original.user_role == 'A' ? 'Admin' : value}</span>
        </div>
      );
    }
  },

  {
    Header: 'Message',
    accessor: 'message',
    sortDisable: true,
    disableSortBy: true,
    pickerDisabled: true,
    Cell: ({ row: { original } }) => {
      const Icon = getIcon(original);
      return original.message ? (
        <>
          <span
            css={css`
              margin-right: 18px;
              display: inline-flex;
              background-color: var(--stats-background-blue);
              border-radius: 50%;
              width: 36px;
              height: 36px;
              align-items: center;
              justify-content: center;
              vertical-align: middle;
            `}
          >
            <Icon size={20} />
          </span>
          {original.message}
        </>
      ) : (
        ''
      );
    }
  },
  {
    Header: 'Company',
    accessor: 'company_name',
    sortDisable: true,
    disableSortBy: true
  },
  {
    Header: 'IP',
    accessor: 'ip',
    sortDisable: true,
    disableSortBy: true
  },

  {
    Header: 'GEO',
    accessor: 'country_code',
    sortDisable: true,
    disableSortBy: true,
    Cell: ({ row: { original } }) => {
      return (
        <div>
          {original.country_code && <span>{original.country_code} / </span>}
          {original.region_code && <span>{original.region_code} / </span>}
          {original.city && <span>{original.city}</span>}
        </div>
      );
    }
  }
];
