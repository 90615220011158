import React from "react";
import { Badge } from "./badge";

const SiteHealthFormatter = ({ site }) => {
  const { offline_count, unsynced_count } = site;

  if (offline_count > 0) return <Badge color="danger">Issues</Badge>;
  else if (unsynced_count > 0) return <Badge color="alert">Issues</Badge>;
  return <Badge color="success">OK</Badge>;
};

export default SiteHealthFormatter;
