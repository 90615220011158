import React from 'react';
import { Camera, CameraVideo } from 'react-bootstrap-icons';
/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import Tooltip from 'components/tooltip';

import { IconButton } from 'components/buttons';
import { DialogTrigger, DialogContent, Dialog } from 'components/dialog.js';

import FeedModal from 'components/video/feed-modal';

const IsRecording = ({ event }) => {
  if (!event.is_recording) return null;

  return (
    <Tooltip title="Video is attached...">
      <Dialog>
        <DialogTrigger asChild={true}>
          <IconButton onClick={(e) => e.stopPropagation()}>
            <CameraVideo color="var(--neutral-02)" />
          </IconButton>
        </DialogTrigger>
        <DialogContent
          onClick={(e) => e.stopPropagation()}
          css={css`
            height: 50vh;
          `}
        >
          <FeedModal event={event} />
        </DialogContent>
      </Dialog>
    </Tooltip>
  );
};

export default IsRecording;
