import 'css/sidebar.css';
import React from 'react';
import { withSidebar } from 'components/with-sidebar';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import { useHeadlessTable } from 'providers/headless-infinite-table-provider';
import SidebarEmptyHolder from 'components/sidebar-empty-holder';

import styled from '@emotion/styled';
import { Check2All, X } from 'react-bootstrap-icons';

export const SidebarWrapper = styled('article')`
  display: flex;

  height: 100% !important;
  flex-direction: column;

  h3 {
    padding: 24px;
  }

  section {
    overflow: auto;

    p {
      display: grid;
      margin: 0;
      grid-template-columns: 120px 1fr;
      padding: 2px 24px;
      font-size: 13px;
    }

    p:nth-of-type(odd) {
      background: #f8f8f8;
    }

    strong {
      line-height: 1.8;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    span {
      line-height: 1.8;
    }
  }

  > span {
    padding: 0 24px;
  }
`;
const Sidebar = () => {
  const { selectedFlatRows } = useHeadlessTable();

  if (!selectedFlatRows?.[0]?.original) return <SidebarEmptyHolder />;
  return (
    <SidebarWrapper>
      <h3 css={css``}>{selectedFlatRows[0].original.message}</h3>
      {/* {selectedFlatRows[0].original.data ? (
        <section>
          {Object.entries(selectedFlatRows[0].original.data).map(
            ([key, value]) => {
              if (Array.isArray(value) && value.length == 0) return "";
              else if (Array.isArray(value) && typeof value[0] == "string")
                value = value.join(", ");
              else if (!value) return "";
              else if (typeof value == "boolean")
                value = value ? <Check2All /> : <X />;
              else if (
                typeof value == "string" &&
                (value.length > 128 || value.includes("://"))
              )
                return "";
              else value = String(value);

              return (
                <p key={key}>
                  <strong>{key}</strong>
                  <span>{value}</span>
                </p>
              );
            }
          )}
        </section>
      ) : (
        <span>No additional data</span>
      )} */}
      <span>No additional data</span>
    </SidebarWrapper>
  );
};

export default withSidebar({})(Sidebar);
