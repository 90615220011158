import React from 'react';
import { useLayout } from 'providers/layout-provider';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import * as Dialog from '@radix-ui/react-dialog';
import EventCard from 'components/event-card';
import { useEventSource } from 'react-use-websocket';

import { useAuth } from 'providers/auth';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import {
  IconFocus,
  IconPlayerPause,
  IconPlayerPlay,
  IconReload,
  IconTrash
} from '@tabler/icons-react';
import ReactECharts from 'echarts-for-react';
import { IconButton } from './buttons';
import LiveEventsIndicator from './live-events-indicator';

const DialogWrapper = styled('div')`
  background-color: white;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 0;
  z-index: 10000;
  right: 0;
  width: max(500px, 20vw);
  height: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;

  header {
    display: flex;
    h4 {
      opacity: 50%;
      font-size: 12px;
    }
    .controls {
      display: flex;
      gap: 0.3rem;
      margin-left: auto;
    }
  }

  .stats {
    p {
      height: 64px;
      font-size: 12px;
      display: inline-grid;
      padding: 8px;
      border: 1px solid #0001;
      border-radius: 5px;
      margin-right: 12px;
      min-width: 64px;
      > b {
        font-size: 24px;
      }
    }
  }
`;

const DialogContent = React.forwardRef(
  ({ children, ...props }, forwardedRef) => {
    let {
      liveEventsDialog,
      getSocketUrl,
      setReloadLiveEvents,
      reloadLiveEvents
    } = useLayout();
    const { user } = useAuth();

    const EVENTS = {
      accessGranted: { color: '#00a96c', value: 1 },
      accessDenied: { color: '#ea3737', value: 2 },
      manualAccessDoor: { color: '#2480d6', value: 3 },
      motionOn: { color: '#6f49c0', value: 4 },
      licensePlateGranted: { color: '#f5861f', value: 4 },
      licensePlateDenied: { color: '#f5861f', value: 4 },
      licensePlateUnfamiliar: { color: '#f5861f', value: 4 }
    };

    const [events, setEvents] = React.useState([]);
    const [average, setAverage] = React.useState();
    const eventsRef = React.useRef();

    eventsRef.current = events;

    const [isRunning, setIsRunning] = React.useState(true);

    let filter = React.useCallback((data) => {
      if (Object.keys(EVENTS).includes(data?.type)) return true;
      return false;
    }, []);

    React.useEffect(() => {
      //reset all event when swithcing companty
      setEvents([]);
    }, [user]);

    const seriesData = React.useRef([]);

    let DEFAULT_OPTION = {
      tooltip: {
        trigger: 'axis'
      },

      grid: {
        show: false,
        top: 20,
        left: 20,
        right: 20,
        bottom: 50
        //  backgroundColor: '#e3e6ec'
      },
      dataZoom: {
        show: false,
        start: 0,
        end: 100
      },

      xAxis: [
        {
          axisLabel: { interval: 5 },
          axisLine: { show: false },
          triggerEvent: true,
          min: Date.now() - 1000 * 60,
          max: Date.now() + 100 * 60,
          type: 'time',
          minorTick: { show: false },
          // axisLabel: {
          //   formatter: function (value, index) {
          //     return `{h}:{mm}:{ss}`;
          //   },
          //   textStyle: {
          //     color: '#000'
          //   },
          //   fontWeight: 400,
          //   showMaxLabel: true,
          //   inside: true,
          //   showMinLabel: false,
          //   fontSize: 12,
          //   fontFamily: 'monospace'
          // },
          // axisPointer: {
          //   triggerEvent: true,
          //   show: false,
          //   type: 'line',
          //   lineStyle: {
          //     color: 'red',
          //     type: 'solid',
          //     width: 3
          //   },
          //   label: {
          //     formatter: function (value, index) {
          //       return value && new Date(value.value).toLocaleTimeString();
          //     },
          //     backgroundColor: 'white',
          //     color: 'black',
          //     fontWeight: 600,
          //     fontSize: 14,
          //     margin: 10
          //   }
          // },
          splitLine: {
            show: true
          }
        }
      ],
      yAxis: [
        {
          min: 0,
          max: 5,
          axisLabel: { show: false },
          axisLine: { show: false },
          splitLine: { show: false }
        }
      ],
      series: [
        {
          symbolSize: 10,
          // data: [
          //   { value: [new Date(), 8.04, 'david'], itemStyle: { color: 'red' } },
          //   { value: [new Date(), 4.04, 'david'], itemStyle: { color: 'green' } }
          // ],
          data: [],
          type: 'scatter'
        }
      ],
      animation: false
    };

    const { lastEvent, readyState } = useEventSource(
      getSocketUrl,
      {
        share: true,
        filter: (e) => filter(JSON.parse(e.data)),
        withCredentials: true,
        retryOnError: true
      },
      Boolean(user && !user.missing2fa && !user.missingMfa)
    );

    React.useEffect(() => {
      if (!lastEvent) return;
      if (!isRunning) return;

      let data = JSON.parse(lastEvent.data);

      setEvents((prev) => [...prev, data].reverse().slice(0, 20));
      //Series Data might get too big... // TODO cut data to save memeory

      seriesData.current.push({
        value: [new Date(), EVENTS[data.type].value || 1, data.type],
        itemStyle: { color: EVENTS[data.type].color || 'red' }
      });
    }, [lastEvent]);

    const [option, setOption] = React.useState(DEFAULT_OPTION);

    React.useEffect(() => {
      if (!isRunning) return;
      const timer = setInterval(() => {
        const opt = { ...option };
        opt.series = [
          {
            data: seriesData.current,
            type: 'scatter'
          }
        ];
        opt.xAxis = [
          {
            triggerEvent: true,
            min: Date.now() - 1000 * 60,
            max: Date.now() + 100 * 60,
            type: 'time',
            minorTick: { show: false },

            splitLine: {
              show: true,
              lineStyle: {
                type: 'solid'
              }
            }
          }
        ];
        // clean lis of events for old events

        const eventsInCurrentMinute = seriesData?.current?.filter((event) => {
          const eventTime = event.value[0].getTime();
          return eventTime > Date.now() - 1000 * 60;
        });

        setAverage(eventsInCurrentMinute.length);

        setOption(opt);
      }, 1000);

      return () => clearInterval(timer);
    }, [isRunning]);

    React.useEffect(() => {
      const cb = () => {
        if (document.visibilityState === 'visible') {
          setIsRunning(true);
        } else if (document.visibilityState === 'hidden') {
          setEvents([]);
          seriesData.current = [];
          setAverage(0);
          setIsRunning(false);
        }
      };

      document.addEventListener('visibilitychange', cb);

      return () => document.removeEventListener('visibilitychange', cb);
    }, []);
    return (
      <Dialog.Content asChild>
        <DialogWrapper>
          <header>
            <section
              css={css`
                width: 36px;
              `}
            >
              {readyState == 1 && <LiveEventsIndicator active={isRunning} />}
            </section>
            <section>
              <h3>Live Events</h3>
              {user?.managing && <h4>{user.company_name}</h4>}
              {user?.focused_site_name && <h4>{user.focused_site_name}</h4>}
            </section>
            <section className="controls">
              <IconButton
                disabled={readyState == 1 && user?.role != 'A'}
                onClick={() => setReloadLiveEvents((prev) => prev + 1)}
              >
                <IconReload />
              </IconButton>
              <IconButton
                title={isRunning ? 'stop feed' : 'start feed'}
                onClick={() => setIsRunning((prev) => !prev)}
              >
                {isRunning ? <IconPlayerPause /> : <IconPlayerPlay />}
              </IconButton>
              <IconButton
                title="clear feed"
                onClick={() => {
                  setEvents([]);
                  seriesData.current = [];
                  setAverage(0);
                }}
              >
                <IconTrash />
              </IconButton>
            </section>
          </header>
          <section
            css={css`
              display: flex;
              width: 100%;
              gap: 1rem;
              flex-direction: column;
              height: 100%;
              overflow: hidden;
              .events {
                &:empty:before {
                  display: flex;
                  height: 100%;
                  align-items: center;
                  justify-content: center;
                  opacity: 50%;
                  content: 'Waiting for events...';
                }
                flex: 1;
                overflow: auto;
              }
            `}
          >
            <div className="stats">
              <p>
                Total<b>{seriesData?.current.length ?? 0}</b>
              </p>
              <p>
                Events/min.<b>{average}</b>
              </p>
              <p>
                Events/sec.<b>{average ? (average / 60).toFixed(2) : 0}</b>
              </p>
            </div>
            <div
              className="timeline"
              css={css`
                background-color: #f6f6f6;

                pointer-events: none;
              `}
            >
              <ReactECharts option={option} style={{ height: 150 }} />
            </div>
            <div className="events"  onClick={()=> setIsRunning(false)}>
              {events.map((e, index) => (
                <EventCard key={index} data={e} />
              ))}
            </div>
          </section>
        </DialogWrapper>
      </Dialog.Content>
    );
  }
);

const overlayShow = keyframes(
  css`
    0% {
      opacity: 0;
    }
    100%: {
      opacity: 1;
    }
  `
);

export default function SidePanel() {
  let {
    liveEventsDialog,
    getSocketUrl,
    setReloadLiveEvents,
    reloadLiveEvents
  } = useLayout();

  const { user } = useAuth();

  const { readyState } = useEventSource(
    getSocketUrl,
    {
      share: true,
      filter: (e) => {
        return false;
      },
      withCredentials: true,
      retryOnError: true
    },
    Boolean(user && !user.missing2fa && !user.missingMfa)
  );

  return (
    <Dialog.Root>
      <Dialog.Trigger
        data-live={readyState == 1}
        css={css`
          &[data-live='true'] {
            color: var(--light--primary--500);
          }
          &[data-live='false'] {
            color: var(--light--others--red-400-base);
          }
        `}
      >
        <IconFocus />
      </Dialog.Trigger>
      <Dialog.Overlay
        css={css`
          inset: 0;
          background: rgba(0, 0, 0, 0.7);

          opacity: 0.3;
          position: fixed;
          @media (prefers-reduced-motion: no-preference) {
            animation: ${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1);
          }
          z-index: 4000;
        `}
      />
      <Dialog.Portal>
        <Dialog.Overlay />
        <DialogContent />
      </Dialog.Portal>
    </Dialog.Root>
  );
}
