import React from 'react';
import { css } from '@emotion/react';
import { useOverview } from 'providers/overview-provider';

/** @jsxImportSource @emotion/react */
import { withSidebar } from 'components/with-sidebar';

import { useQueryClient } from 'react-query';
import { useMutationWrapper } from 'utils/ajax';
import Site from 'api/site';
import FloorPlanUpload from './floor-plan-upload';
import ShapeEditor from './shape-editor';
import { Button, CancelButton } from 'components/buttons';
import LayerSearch from './layer-search';
import Select from 'react-select';
import DoorPopover from './door-popover';
import useAlertDialog from 'providers/alert-dialog-provider';
import CameraPopover from './camera-popover';
import { SliderPicker } from 'react-color';
import PatternEditor from './pattern-editor';
import AuxiliaryPopover from './auxiliary-popover';
import { map } from 'leaflet';
const Sidebar = ({
  editLayerRef: ref,
  mapRef,
  geojson,
  selectedSite,
  setIsEdit,
  setImage,
  image,
  doors,
  auxiliaries,
  cameras,
  selectedLayer,
  setSelectedLayer,
  pathOptions,
  setPathOptions,
  modes,
  setModes
}) => {
  const [layers, setLayers] = React.useState();

  const confirm = useAlertDialog();
  const queryClient = useQueryClient();
  const Mutation = useMutationWrapper(
    ['site', 'save'],
    ({ geojson, image }) =>
      Site.saveMapping(selectedSite.id, {
        geojson: {
          ...geojson,
          bounds: mapRef.current.getBounds(),
          image: image ? encodeURIComponent(image) : '',
          editMode: ''
        }
      }),

    {
      onMutate: () => {},
      onSuccess: () => {
        // const data = queryClient.getQueryData(['sites', 'getAll']);
        // const index = data.data.findIndex((s) => s.id == selectedSite.id);
        // if (index > -1) data.data[index]['geojson'] = JSON.stringify(geojson);
        // queryClient.setQueryData(['sites', 'getAll'], data);

        queryClient.invalidateQueries(['sites']);
      }
    }
  );

  const handleSave = () => {
    const newGeo = {
      type: 'FeatureCollection',
      features: [],
      editMode: true
    };
    const layers = ref.current?.getLayers();
    if (layers) {
      layers.forEach(function (layer) {
        //remove _state chnaged

        delete layer._state;
        var geoJson = JSON.parse(JSON.stringify(layer.toGeoJSON()));
        if (!geoJson.properties) {
          geoJson.properties = {};
        }

        geoJson.properties = layer.properties || {};
        geoJson.options = layer.options;

        // sometime the properties are inside options

        geoJson.properties = {
          ...geoJson.properties,
          ...layer.options.properties
        };

        if (layer instanceof L.Marker) {
          // Strip all values that were added during load
          let keepProps = {
            id: geoJson.properties.id,
            type: geoJson.properties.type,
            name: geoJson.properties.name
          };
          geoJson.properties = keepProps;
        }

        // geoJson.properties.options = JSON.parse(JSON.stringify(layer.options));

        if (layer.options.radius) {
          var radius = parseFloat(layer.options.radius);
          if (radius % 1 !== 0) {
            geoJson.options.radius = radius.toFixed(6);
          } else {
            geoJson.options.radius = radius.toFixed(0);
          }
        }

        if (layer instanceof L.Rectangle) {
          geoJson.properties.shape = 'rectangle';
        } else if (layer instanceof L.Circle) {
          geoJson.properties.shape = 'circle';
        } else if (layer instanceof L.CircleMarker) {
          geoJson.properties.shape = 'circlemarker';
        } else if (layer instanceof L.Polygon) {
          geoJson.properties.shape = 'polygon';
        } else if (layer instanceof L.Polyline) {
          geoJson.properties.shape = 'polyline';
        } else if (layer instanceof L.Marker) {
          geoJson.properties.shape = 'marker';
        }

        newGeo.features.push(geoJson);
      });
    }
    return newGeo;
  };

  React.useEffect(() => {
    setLayers(ref.current.getLayers());
  }, []);

  return (
    <article
      css={css`
        height: 100%;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
        > header {
          box-shadow: inset 1px 0px 0px #eceff3;
          > label {
            font-weight: 500;
            font-size: 12px;
            line-height: 125%;
            /* or 15px */

            display: flex;
            align-items: center;

            /* light/neutrals/800 */

            color: #59606c;
          }
        }
        label {
          font-weight: 500;
          font-size: 13px;
          line-height: 17px;

          color: #1d2023;
        }
      `}
    >
      <header>
        <h3>Edit Mode</h3>
        <label>{selectedSite?.name}</label>
      </header>

      <section
        className="controls"
        css={css`
          display: flex;
          flex-direction: column;
          gap: 1rem;
          margin-top: 1rem;
        `}
      >
        <label>Floor Plan</label>
        <FloorPlanUpload setImage={setImage} image={image} />
        {/* <LayerSearch
          layers={layers}
          onChange={(e) => {
            let { id, type, name } = e.value.feature.properties;

            let layerExists = ref.current
              .getLayers()
              ?.filter((l) => l instanceof L.Marker)
              .find(
                (l) => l?.properties?.id == id && l?.properties?.type == type
              );

            if (layerExists) {
              layerExists.setIcon(
                getDoorIcon({ name })
                //L.icon({
                //iconUrl: `https://chart.googleapis.com/chart?chst=d_bubble_text_small&chld=bb|${name}|FFFF00|000000`
                //})
              );
            }
            if (!layerExists) {
              // TODO check if there is another way to put properties
              mapRef.current.pm.enableDraw('Marker', {
                markerStyle: {
                  icon: getDoorIcon({ name }),
                  properties: { ...e.value.feature.properties, hidden: null }
                },
                tooltips: false,
                continueDrawing: false
              });
            }
          }}
        /> */}
        <label>Geometry</label>
        <ShapeEditor
          mapRef={mapRef}
          editLayerRef={ref}
          setSelectedLayer={setSelectedLayer}
          modes={modes}
          setModes={setModes}
        />
        <label>Colors And Shapes</label>
        <PatternEditor
          selectedLayer={selectedLayer}
          mapRef={mapRef}
          editLayerRef={ref}
          setPathOptions={setPathOptions}
          pathOptions={pathOptions}
        />
      </section>
      {/* <Select
        css={css`
          .select_control {
            position: relative;
          }
          .select__menu {
            box-shadow: none;
            border: none;
            position: relative;
          }
          .select__menu-list {
            max-height: 200px;
            overflow: auto;
          }
          input {
            opacity: 1 !important ;
          }
        `}
        placeholder="select a door..."
        components={{ DropdownIndicator: null }}
        controlShouldRenderValue={false}
        classNamePrefix="select"
        menuIsOpen={true}
        menuPosition="fixed"
        options={
          doors?.map((d) => ({
            value: d,
            label: d.properties.name
          })) || []
        }
        onChange={(e) => {
          let { id, type, name } = e.value.properties;

          let allLayers = ref.current.getLayers();

          console.log('click found, all layers', allLayers);

          let layerExists = allLayers
            ?.filter((l) => l instanceof L.Marker)
            .find(
              (l) =>
                (l?.properties?.id == id && l?.properties?.type == type) ||
                (l?.options?.properties?.id == id &&
                  l?.options?.properties?.type == type)
            );

          if (layerExists) {
            layerExists.setIcon(
              getDoorIcon({ name })
              //L.icon({
              //iconUrl: `https://chart.googleapis.com/chart?chst=d_bubble_text_small&chld=bb|${name}|FFFF00|000000`
              //})
            );
          }
          if (!layerExists) {
            // TODO check if there is another way to put properties
            mapRef.current.pm.enableDraw('Marker', {
              markerStyle: {
                icon: getDoorIcon({ name }),
                properties: { ...e.value.properties, hidden: null }
              },
              tooltips: false,
              continueDrawing: false
            });
          }
        }}
      /> */}
      <label>Markers</label>
      <section
        className="markers"
        css={css`
          display: flex;
          gap: 0.75rem;
        `}
      >
        <DoorPopover doors={doors} editLayerRef={ref} mapRef={mapRef} />
        <CameraPopover cameras={cameras} editLayerRef={ref} mapRef={mapRef} />
        <AuxiliaryPopover
          auxiliaries={auxiliaries}
          editLayerRef={ref}
          mapRef={mapRef}
        />
      </section>
      {/* {selectedLayer && (
        <section className="selected-layer">
          <input
            css={css`
              border: none;
              height: 24px;
            `}
            type="color"
            onChange={(e) =>
              selectedLayer &&
              selectedLayer.setStyle({ fillColor: e.target.value })
            }
          />
        </section>
      )} */}
      <div
        css={css`
          display: flex;
          gap: 0.5rem;

          margin-top: auto;
        `}
      >
        <Button
          loading={Mutation.isLoading}
          compact
          onClick={() => {
            let geojson = handleSave();
            Mutation.mutate({ geojson, image });
          }}
        >
          Save
        </Button>
        <CancelButton
          compact
          onClick={async () => {
            // check if a change was made
            if (ref.current?.getLayers().find((l) => l._state == 'changed')) {
              if (
                await confirm({ description: 'changes where made, discard?' })
              )
                //  queryClient.invalidateQueries(['sites']);
                setIsEdit(false);
            } else setIsEdit(false);
          }}
        >
          Close
        </CancelButton>
      </div>
    </article>
  );
};

export default withSidebar({ width: '15vw', showToggleButton: false })(
  Sidebar,
  null
);
