import React from 'react';
import CredentialDetails from 'views/credentials/credential-details';
import CredentialParams from 'views/credentials/credential-params';
import { useFormikContext, Field } from 'formik';
import InvertLabel from 'components/invert-label';
import PersonSelect from 'components/form/person-select';
/** @jsxImportSource @emotion/react */
const CredentialForm = (props) => {
  let isPerson = props?.credentialData?.person_id;
  const { values } = useFormikContext();

  return (
    <article>
      {values.ad_uuid && (
        <>
          <InvertLabel color="purple">AD</InvertLabel>
          <span>{values.ad_uuid}</span>
        </>
      )}

      <Field
        name="person"
        isDisabled={isPerson}
        isClearable={false}
        component={PersonSelect}
        closeMenuOnSelect={true}
      />

      <CredentialDetails {...props} />
      <CredentialParams {...props} />
    </article>
  );
};

export default CredentialForm;
