import React from 'react';
import { TextField, NumberField, Heading } from 'components/form';
import { Field, useFormikContext } from 'formik';
import TimezoneSelect from 'components/form/timezone-select';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const SiteForm = (props) => {
  const { values } = useFormikContext();

  return (
    <section>
      <Field
        name="time_zone"
        component={TimezoneSelect}
        css={css`
          color: var(--neutral-00);
        `}
      />
      <br />

      <TextField name="name" value={values.name} />

      <NumberField name="code" value={values.code} />

      <TextField name="description" value={values.description} />

      <TextField
        placeholder="eg. 8 Astor Pl, New York, NY 10003 OR 40.803415,-73.985813"
        helper="Full address OR Lat, Lng separated by comma"
        css={css`
          grid-column: 1/-1;
        `}
        name="address"
        value={values.address}
      />
    </section>
  );
};

export default SiteForm;
