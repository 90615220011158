import 'css/sidebar.css';
import React from 'react';
import { withSidebar } from 'components/with-sidebar';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import { useHeadlessTable } from 'providers/headless-table-provider';
import SidebarEmptyHolder from 'components/sidebar-empty-holder';
import LivePlayer from 'components/video/live-player';
import IntercomTabs from './intercom-tabs';
import styled from '@emotion/styled';
import DeviceAlerts from 'components/device-alerts';

const Stats = (props) => {
  return null;
};

export const SidebarWrapper = styled('article')`
  display: flex;

  height: 100% !important;
  flex-direction: column;

  > header {
    padding: 1rem;
  }

  section.event-list {
    width: 100%;
    min-height: 60%;
    max-height: 60%;
    overflow-y: auto;
    margin-bottom: 8px;
  }

  section.usage-chart {
    width: 100%;
    flex: 1;
  }
`;
const Sidebar = (props) => {
  const { selectedFlatRows } = useHeadlessTable();
  if (selectedFlatRows.length == 0) return <SidebarEmptyHolder />;

  return (
    <SidebarWrapper>
      <header>
        <h1>{selectedFlatRows[0].original?.name}</h1>
      </header>

      {/* <LivePlayer
          host={{
            id: selectedFlatRows[0].original?.camera_id,
            ip: selectedFlatRows[0].original?.ip,
            intercom_id: selectedFlatRows[0].original?.id,
            online: selectedFlatRows[0].original?.online
          }}
        /> */}
      <DeviceAlerts device={selectedFlatRows?.[0]?.original} />
      <IntercomTabs intercom={selectedFlatRows?.[0]?.original} />
    </SidebarWrapper>
  );
};

export default withSidebar({ width: '450px' })(Sidebar, Stats);
