import { Badge } from 'components/badge';
import { getSelectionColumn } from 'components/table-selection-column';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'providers/auth';
import { css } from '@emotion/react';
import { getRowNumberColumn } from 'components/table-rownumber-column';

/** @jsxImportSource @emotion/react */
export const columns = [
  getSelectionColumn(),
  {
    Header: 'Name',
    accessor: 'name',
    newSorter: true,
    asc: 'atoz',
    desc: 'ztoa',
    pickerDisabled: true,
    Cell: ({ row: { original } }) => {
      const { installerAccess } = useAuth();
      const history = useHistory();

      const handleAccess = (e) => {
        e.stopPropagation();
        installerAccess.mutate(original.id, {
          onSuccess: () => history.push('/dashboard')
        });
      };
      return (
        <article
          css={css`
            display: flex;
            gap: 8px;
            align-items: center;
          `}
        >
          <span
            css={css`
              &:hover {
                text-decoration: underline !important;
              }
            `}
            onClick={handleAccess}
          >
            {original.name}
          </span>
        </article>
      );
    }
  },
  {
    Header: 'Vendor',
    disableSortBy: true,
    accessor: 'vendor_name'
  },
  {
    Header: 'Devices',
    accessor: 'device_count',
    newSorter: true,
    asc: 'leastDevices',
    desc: 'mostDevices'
  },
  {
    Header: 'Health',
    Cell: ({ row: { original } }) => {
      const [color, label] =
        original.issue_count == 0 ? ['green', 'OK'] : ['red', 'issues'];
      return <Badge color={color}>{label}</Badge>;
    }
  },
  {
    newSorter: true,
    asc: 'leastUsers',
    desc: 'mostUsers',
    Header: 'Users',
    accessor: 'user_count'
  },

  {
    newSorter: true,
    asc: 'leastCompanies',
    desc: 'mostCompanies',
    Header: 'Companies',
    accessor: 'company_count'
  }
];
