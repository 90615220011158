import React from 'react';
import PersonForm from 'views/people/form';
import Person from 'api/person';
import Template from 'base/template';
import { AuxiliaryButton, Button } from 'components/buttons';
import FormProvider from 'providers/form-provider';
import { Redirect } from 'react-router-dom';
import { useMutationWrapper } from 'utils/ajax';
import { useRedirect } from 'hooks/redirect-hook';
import CredentialDetails from 'views/credentials/credential-details';
import CredentialParams from 'views/credentials/credential-params';
import { Formik } from 'formik';
import { dateTimeLocalSQLNoSeconds } from 'utils/time';
import * as Yup from 'yup';
import { CancelButton } from 'components/buttons';
import PageTitle from 'components/page-title';
import { css } from '@emotion/react';
import Content from 'base/content';
import Breadcrumbs from 'components/breadcrumbs';
import * as Collapsible from '@radix-ui/react-collapsible';
import { IconChevronDown } from '@tabler/icons-react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
/** @jsxImportSource @emotion/react */

const Save = (props) => {
  let person = props?.location?.state?.person || null;
  let credentialData = props?.location?.state?.credentialData || null;

  const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

  const SCHEMA = Yup.object().shape({
    phone: Yup.string().matches(phoneRegex, 'Invalid Phone').nullable(),
    sites: Yup.array().min(1, 'Select at least one option').nullable(),
    name: Yup.string()
      .min(4, 'Too Short!')
      .max(30, 'Too Long!')
      .required('Required'),
    email: Yup.string().email('Invalid email').nullable(),
    employee_id: Yup.string().max(16, 'Employee ID is too long').nullable(),
    description: Yup.string()
      .nullable()
      .when(['card', 'card_number'], {
        is: (card, card_number) => card || card_number,
        then: (schema) => schema.required('Required')
      })
  });

  const INITIAL_VALUES = {
    formIsDisabled: true,
    sites: person
      ? person?.sites == false
        ? [0]
        : person.sites.map((s) => Number(s))
      : [],

    // validFrom: dateTimeLocalSQLNoSeconds(new Date()),
    // validTo: '2036-06-12T11:30',
    // enabled: 'on',
    // card: credentialData?.Card,
    // cardNumber: credentialData?.CardNr,
    // facilityCode: credentialData?.FacilityCode,
    name: person?.name,
    email: person?.email,
    phone: person?.phone,
    employeeId: person?.employee_id
    // description: ''
  };

  let { isLoading, mutate, isSuccess } = useMutationWrapper(
    ['person', 'save'],
    (input) => Person.save(input, person?.id),
    {}
  );

  const { redirect, setRedirect } = useRedirect(isSuccess);
  const history = useHistory();

  return (
    <Template {...props}>
      {redirect && <Redirect to="/people" />}
      <Content {...props}>
        <PageTitle>{person ? person.name : 'Person Add'}</PageTitle>
        <Breadcrumbs crumbs={props.crumbs} />
        <Formik
          validationSchema={SCHEMA}
          initialValues={INITIAL_VALUES}
          onSubmit={(values) => {
            {
              {
                /* values.credential.validFrom = values.validFrom;
            values.credential.validTo = values.validTo;

            delete values.validFrom;
            delete values.validTo;  */
              }
            }

            delete values.formIsDisabled;

            mutate(values, {
              onSuccess: (res) => {
                {
                  /* if (res.data?.credentialToken)
                  history.push(
                    `/credentials/${res.data.credentialToken}/levels`,
                    {
                      desc: values.description,
                      dialogOpen: true
                    }
                  ); */
                }
                if (res.personId)
                  history.push('/credentials/add/', {
                    credentialData: { person_id: res.personId }
                  });
              }
            });
          }}
        >
          {({ handleSubmit, values }) => (
            <FormProvider mutateFunc={handleSubmit} disabled={isLoading}>
              <PersonForm person={person} />
              {/* {!person && (
                <Collapsible.Root>
                  <Collapsible.Trigger
                    asChild
                    css={css`
                      svg {
                        padding: 2px;
                        transition: all 0.2s;
                      }
                      &[data-state='open'] {
                        svg {
                          transform: rotate(180deg);
                        }
                      }
                    `}
                  >
                    <AuxiliaryButton>
                      Add Credential (Optional)
                      <IconChevronDown />
                    </AuxiliaryButton>
                  </Collapsible.Trigger>
                  <Collapsible.Content>
                    <CredentialDetails credentialData={credentialData} />
                    <CredentialParams credentialData={credentialData} />
                  </Collapsible.Content>
                </Collapsible.Root>
              )} */}

              <footer>
                <Button disabled={values.formIsDisabled} type="submit">
                  Submit
                </Button>
                <CancelButton onClick={() => setRedirect(true)} />
              </footer>
            </FormProvider>
          )}
        </Formik>
      </Content>
    </Template>
  );
};

export default Save;
