import React from 'react';
import { useHistory } from 'react-router-dom';
import { MenuItem, MenuItemDelete, MenuWrapper } from 'components/action-menu';
import { useDeleteMutate, useEnableMutate, useSendPassMutate } from './actions';
import IsUserAllowed from 'components/is-user-allowed';
import useAlertDialog from 'providers/alert-dialog-provider';
export default function ActionMenu({ data: guest, ...otherProps }) {
  const history = useHistory();
  const deleteMutate = useDeleteMutate(guest);
  const enableMutate = useEnableMutate(guest);
  const sendMutate = useSendPassMutate(guest);

  const confirm = useAlertDialog();

  let handleEdit = (e) =>
    history.push('/guests/save/' + guest?.id, {
      guest,
      desc: guest?.name
    });

  const handleDelete = async () => {
    if (
      await confirm({ description: 'Are you sure want to delete this guest ?' })
    )
      deleteMutate.mutate(guest?.id, {});
  };

  const handleBar = () => enableMutate.mutate(guest?.id);
  const handleSend = () => sendMutate.mutate(guest?.id);

  return (
    <IsUserAllowed condition="guestEdit">
      <MenuWrapper {...otherProps}>
        <MenuItem label="Edit" onClick={handleEdit} />
        <MenuItem label="Enable/Disable" onClick={handleBar} />
        <MenuItem label="Send Pass" onClick={handleSend} />
        <MenuItemDelete onClick={handleDelete} />
      </MenuWrapper>
    </IsUserAllowed>
  );
}
