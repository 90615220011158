import React from 'react';
import * as PopoverPrimitive from '@radix-ui/react-popover';

export const Popover = PopoverPrimitive.Root;
export const PopoverTrigger = PopoverPrimitive.Trigger;
export const PopoverAnchor = PopoverPrimitive.Anchor;
export const PopoverPortal = PopoverPrimitive.Portal;
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

const PopoverContent = React.forwardRef(
  ({ children, ...props }, forwardedRef) => (
    <PopoverPrimitive.Content
      sideOffset={20}
      align="end"
      asChild
      {...props}
      ref={forwardedRef}
    >
      <aside
        onMouseDown={(e) => e.stopPropagation()}
        onClick={(e) => e.stopPropagation()}
        css={css`
          position: relative;
          box-shadow: var(--popover-box-shadow);
          width: 360px;
          background: var(--neutral-09);
          border: 1px solid var(--neutral-12);
          box-sizing: border-box;
          border-radius: 5px;
          font-size: 14px;
          z-index: 2001;
        `}
      >
        {children}
      </aside>
    </PopoverPrimitive.Content>
  )
);

export default PopoverContent;
