import React from 'react';
import Select from 'components/headless-select';
import { createFilter } from 'react-select';
import useAsyncSelection from './use-async-selection';

const AsyncSelect = (props) => {
  const {
    isError,
    refetch,
    remove,
    input,
    setInput,
    data,
    isLoading,
    value,
    onChange,
    isOptionDisabled,
    firstDataLength
  } = useAsyncSelection(props);

  const [isLocal, setIsLocal] = React.useState(false); // sets lcaol fecthing if less than PAGLEN

  React.useEffect(() => {
    if (firstDataLength < 30) setIsLocal(true);
  }, [firstDataLength]);

  const filterConfig = props.filterConfig || {
    ignoreCase: true,
    ignoreAccents: true,
    trim: false,
    matchFrom: false
  };

  return (
    <Select
      isAsync
      styles={{
        multiValueRemove: (base, state) => {
          return state.data.isFixed ? { ...base, display: 'none' } : base;
        },

        multiValue: (base, state) => {
          return state.data.isFixed
            ? {
                ...base,
                backgroundColor: 'var(--neutral-05) !important;',
                border: 'none !important'
              }
            : base;
        }
      }}
      // not used ?

      onSelectResetsInput={isLocal ? true : false}
      title={props.title || props?.field?.name}
      idx={props.idx || 'id'}
      label={props.label || 'name'}
      inputValue={input}
      setInput={setInput}
      controlShouldRenderValue={true}
      isMulti={false}
      isClearable={Array.isArray(value) ? value?.some((v) => !v.isFixed) : true}
      options={data?.data ? data.data : data}
      filterOption={isLocal && input ? createFilter(filterConfig) : null}
      closeMenuOnSelect={props.isMulti ? false : true}
      // isDisabled={isLoading}
      onChange={onChange}
      isOptionDisabled={isOptionDisabled}
      isLoading={isLoading}
      isError={isError}
      refetch={refetch}
      remove={remove}
      value={value}
      onInputChange={(e, { action, prevInputValue }) => {
        if (action === 'input-blur') return setInput('');

        if (action === 'input-change') return setInput(e);

        !props.isMulti && setInput(e);

        return prevInputValue;
      }}
      {...props}
    />
  );
};

export default AsyncSelect;
