import {
  Dialog,
  DialogAction,
  DialogCancel,
  DialogContent,
  DialogTrigger
} from 'components/alert-dialog';
import { Button, CancelButton } from 'components/buttons';
import React from 'react';
import { useLayout } from 'providers/layout-provider';
import { useAuth } from 'providers/auth';

export default ({}) => {
  const { logout } = useAuth();

  return (
    <Dialog open={true}>
      <DialogContent type="info" onEscapeKeyDown={(e) => e.preventDefault()}>
        <span>
          Your session has expired, you will need to logout and login again.
        </span>
        <footer id="footer">
          <Button
            compact
            onClick={() => {
              logout.mutate();
            }}
          >
            Logout
          </Button>
        </footer>
      </DialogContent>
    </Dialog>
  );
};
