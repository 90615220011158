import React from 'react';
import { css } from '@emotion/react';
import { InputLabel } from 'components/form';
import { InputHelper } from 'components/form';
/** @jsxImportSource @emotion/react */
import CreatableSelect from 'react-select/creatable';
import { ErrorMessage } from 'components/form';
import { MultiValueContainer } from 'components/form/custom-components';

const SMSCreatable = (props) => {
  const {
    field: { name },
    form: { values, setFieldValue, errors },
    title
  } = props;

  const [inputValue, setInputValue] = React.useState();
  const [value, setValue] = React.useState();

  const onInputChange = (textInput, { action }) => {
    const regexExp =
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

    if (action === 'input-blur') {
      if (regexExp.test(inputValue))
        if (value)
          handleChange([...value, { label: inputValue, value: inputValue }], {
            action: 'create-option'
          });
        else
          handleChange([{ label: inputValue, value: inputValue }], {
            action: 'create-option'
          });
      else setInputValue('');
    }

    if (action === 'input-change') {
      if (textInput && textInput.endsWith(',')) {
        const label = textInput.slice(0, -1); // trim off comma
        const newValue = { label, value: label };

        if (regexExp.test(inputValue)) {
          setInputValue('');
          if (value)
            handleChange([...value, newValue], {
              action: 'create-option'
            });
          else
            handleChange([newValue], {
              action: 'create-option'
            });
        }
      } else setInputValue(textInput);
    }
  };

  const handleChange = (value, actionMeta) => {
    setValue(value);

    setFieldValue(
      name,
      value.map((i) => i.value)
    );
    setInputValue('');
  };
  return (
    <div>
      <InputLabel label={props.title} />
      <InputHelper helper="Separate SMS numbers pressing Enter" />
      <CreatableSelect
        inputValue={inputValue}
        onInputChange={onInputChange}
        backspaceRemovesValue
        isValidNewOption={(inputValue) => {
          const regexExp =
            /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
          return regexExp.test(inputValue);
        }}
        value={value}
        onChange={handleChange}
        menuIsOpen={true}
        isMulti
        isClearable={false}
        openMenuOnClick={false}
        components={{ MultiValueContainer, DropdownIndicator: null }}
        defaultValue={values?.[name]?.map((i) => ({
          label: i,
          value: i
        }))}
        css={css`
          .creatable__input {
            color: var(--neutral-00);
          }
          .creatable__menu {
            display: none;
          }
          .creatable__control--is-focused {
            box-shadow: none !important;
          }

          > div {
            height: 150px;
            background: var(--neutral-09);
            border-color: var(--neutral-12);

            align-items: start;
            padding-top: 6px;
            overflow-y: auto;
          }
        `}
        className="creatable__container"
        classNamePrefix="creatable"
        placeholder="Type phone numbers"
      />

      <ErrorMessage name={name} />
    </div>
  );
};

export default SMSCreatable;
