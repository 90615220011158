import React from 'react';
import ReactECharts from 'echarts-for-react';
import { css } from '@emotion/react';
import { useHeadlessTable } from 'providers/headless-table-provider';
import { format } from 'date-fns';
import EmptyHolder from 'components/empty-holder';
import { isEmptyObject, getPast7Days, getPast60Minutes } from 'utils/arrays';

/** @jsxImportSource @emotion/react */
export default ({ activity, ...props }) => {
  activity =
    activity && typeof activity !== 'object' ? JSON.parse(activity) : activity;

  let last60Mins = getPast60Minutes();
  let values = [];

  for (const date of last60Mins) {
    values.push(activity?.[date] ?? 0);
  }

  const average = values
    ? values.reduce((acc, val) => acc + val, 0) / values.length
    : 0;

  const max = values ? Math.max(...values) : 0;

  const DEFUALT_STYLES = {
    graphic: {
      elements: [
        (!activity || isEmptyObject(activity)) && {
          type: 'text',
          left: 'center',
          top: 'center',
          style: {
            text: 'no data',
            fontSize: 12,
            fill: '#7f879',
            opacity: 0.3
          }
        }
      ]
    }
  };

  let option = {
    ...DEFUALT_STYLES,
    animation: false,

    title: {
      show: average > 0,
      text: average.toFixed(2),
      left: 0,
      top: 0,
      textStyle: {
        fontSize: 13
      }
    },

    tooltip: {
      trigger: 'axis'
    },

    grid: {
      top: 15,
      left: 10,
      right: 10,
      bottom: 10
    },

    visualMap: {
      show: false,
      pieces: [
        {
          gt: 0,
          lte: 1.25 * average,
          color: '#2480d6'
        },
        {
          gt: 1.25 * average,
          lte: 1.25 * average + 100,
          color: '#f5861f'
        }
      ],
      outOfRange: {
        color: '#999'
      }
    },
    xAxis: [
      {
        type: 'category',
        data: getPast60Minutes(),
        axisTick: {
          show: false
        },
        min: Math.min(values),
        max: Math.max(values),
        show: true,
        ...(props.full ? { axisLabel: { show: true } } : {}),
        axisLine: {
          show: activity && !isEmptyObject(activity)
        }
      }
    ],
    yAxis: {
      type: 'value',
      show: false
    },

    series: [
      {
        smooth: 0.1,
        symbol: 'none',
        data: values.every((element) => element === 0) ? [] : values,
        type: 'line',
        areaStyle: {
          opacity: 0.1
        }
      }
    ]
  };

  return (
    <div
      className="cpu-usage-chart"
      css={css`
        //  pointer-events: none !important;
      `}
    >
      <ReactECharts
        lazyUpdate={true}
        opts={{ renderer: 'svg' }}
        option={option}
        notMerge={true}
        style={props.style || { height: '50px', width: '150px' }}
      />
    </div>
  );
};
