import { useMutationWrapper } from 'utils/ajax';
import Sensor from 'api/sensor';
import { useHeadlessTable } from 'providers/headless-table-provider';
import { useQueryClient } from 'react-query';

export const useDeleteMutate = (sensor) => {
  const queryClient = useQueryClient();
  const { queryKey } = useHeadlessTable();
  return useMutationWrapper(
    ['sensor', 'delete'],
    (id) => Sensor.delete(id),

    {
      successMeisage: `Sensor: ${Sensor?.name} deleted successfuly`,
      onMutate: (id) => {
        let data = queryClient.getQueryData(queryKey);

        const newData = {
          ...data,
          data: data.data.filter((row) => row.id != id)
        };

        queryClient.setQueryData(queryKey, newData);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['sensors']);
      }
    }
  );
};
