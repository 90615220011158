import React from 'react';

/** @jsxImportSource @emotion/react */
import {
  TabItem,
  TabGroup,
  TabPanel,
  TabPanels,
  TabList
} from 'components/tabs';
import { css } from '@emotion/react';
import { Calendar, CameraVideo } from 'react-bootstrap-icons';

import UsageChartHolder from 'components/charts/usage-chart-holder';
import LiveEvents from 'components/live-events';

import CameraEventList from './camera-event-list';

import LivePlayer from 'components/video/live-player';
import {
  IconCloud,
  IconCloudComputing,
  IconDeviceSdCard
} from '@tabler/icons-react';
import CameraBucket from './camera-bucket';
import IsRole from 'components/is-role';

const CameraTabs = ({ camera = {} }) => {
  let filter = React.useCallback(
    (data) => {
      if (data?.camera_id == camera.id && data.category == 'camera')
        return true;
      return false;
    },
    [camera]
  );

  const filters = camera.lpr
    ? { camera_id: camera?.id, lpr: true }
    : { camera_id: camera?.id, type: 'motionOn' };

  return (
    <TabGroup>
      <TabList
        css={css`
          width: 100%;
          box-shadow: unset;
          padding: 0px 16px;
          gap: 2rem;

          border-bottom: 1px solid var(--neutral-12);
          [role='tab'] {
            min-width: unset;
          }
        `}
      >
        <TabItem title="Events">
          <Calendar size={24} />
        </TabItem>
        <TabItem title="Live Events">
          <CameraVideo size={30} />
        </TabItem>
        <IsRole role={['A', 'V', 'I']}>
          <TabItem title="Live Events">
            <IconCloudComputing size={30} />
          </TabItem>
        </IsRole>
        {/* <TabItem
          disabled={camera.online == 0}
          title="SD Card"
          css={css`
            &[aria-selected='true'] {
              color: var(--primary-base);
              border-bottom: 3px solid var(--primary-base);
              border-radius: 5px 5px 0px 0px;
            }
          `}
        >
          <IconDeviceSdCard size={30} />
        </TabItem> */}
      </TabList>
      <TabPanels>
        <TabPanel
          as="div"
          css={css`
            overflow: hidden;
            height: 100%;
            display: flex;
            flex-direction: column;
            padding: 16px;
          `}
        >
          {/* {door.token && <LastPerson door={door} />} */}

          <CameraEventList camera={camera} />
          <UsageChartHolder
            tooltiplabel={camera?.lpr ? 'Plates' : 'Motion Events'}
            noExpand
            title={camera?.lpr ? 'LPR Events' : 'Motion events'}
            name={camera.name}
            filters={filters}
          />
        </TabPanel>
        <TabPanel
          css={css`
            width: 100%;
            display: grid;
            padding: 1rem;
            grid-template-rows: 300px auto;
            height: 100% !important;
            flex-direction: column;
            overflow-y: hidden;
            grid-gap: 0.8rem;
          `}
        >
          <LivePlayer host={camera} />
          <LiveEvents filter={filter} />
        </TabPanel>
        <IsRole role={['A', 'V', 'I']}>
          <TabPanel
            as="div"
            css={css`
              overflow: hidden;
              padding: 16px;
            `}
          >
            <CameraBucket camera={camera} />
          </TabPanel>
        </IsRole>
        {/* <TabPanel
          as="div"
          css={css`
            overflow: hidden;
            padding: 16px;
          `}
        ></TabPanel> */}
      </TabPanels>
    </TabGroup>
  );
};

export default CameraTabs;
