import React from 'react';
import { useEventList } from 'hooks/use-event-list';
import EventList from 'components/event-list';

export default ({ intercom }) => {
  const [filters, setFilters] = React.useState();
  const [selection, setSelection] = React.useState('');

  React.useEffect(() => {
    setFilters((prev) => ({ ...prev, category: selection }));
  }, [selection]);

  React.useEffect(() => {
    setFilters({
      category: 'intercom',
      intercom_id: intercom.id
    });
  }, [intercom]);

  const query = useEventList({
    filters
  });
  return (
    <>
      <EventList query={query} />
    </>
  );
};
