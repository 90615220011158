/** @jsxImportSource @emotion/react */
import React from "react";
import { css, jsx } from "@emotion/react";
/** @jsxImportSource @emotion/react */

const styles = css`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
  color: var(--neutral-01);
  margin-bottom: 5px;
`;

const InputLabel = (props) => {
  if (props.hideTitle) return null;
  if (!props.label && !props.name && !props.children) return null;
  return (
    <div css={styles}>
      {props.label ||
        (props.name ? props.name.replace(/_/g, " ") : props.children)}
    </div>
  );
};

export default InputLabel;
