import { Card } from './card';
import { IconClock2 } from '@tabler/icons-react';
import { Button } from 'components/buttons';
import { useMutateSetDateTime } from '../actions';
import { useQueryWrapper } from 'utils/ajax';
import Device from 'api/device';

export default ({ device }) => {
  if (!device) return;
  const mutateSetDateTime = useMutateSetDateTime(device);
  const { data: deviceDateTime, refetch } = useQueryWrapper(
    ['device', 'getTime', device.id],
    () => Device.getTime(device.id),
    {
      enabled: device.online,
      retry: false
    }
  );

  return (
    <Card classname="date-time">
      <div className="icon">
        <IconClock2 />
      </div>
      <div className="content">
        <h2>Date and Time</h2>
        <span className="description">Check and sync date and time.</span>
      </div>
      <div className="action">
        <Button
          loading={mutateSetDateTime.isLoading}
          onClick={() =>
            mutateSetDateTime.mutate(device.id, {
              onSuccess: () => refetch()
            })
          }
          compact
        >
          Set Date/Time
        </Button>
      </div>
      <div className="data">
        <time>Current time on device: {deviceDateTime?.data || 'N/A'}</time>
      </div>
    </Card>
  );
};
