import React from 'react';
import Template from 'base/template';

import Camera from 'api/camera';
import { columns } from 'views/cameras/table-columns';
import Sidebar from 'views/cameras/sidebar';
import IndexContent from 'base/index-content';
import { Power } from 'react-bootstrap-icons';
import ActionMenu from 'views/cameras/action-menu';
import { useAuth } from 'providers/auth';
import { IconAlertTriangle, IconTruck } from '@tabler/icons-react';

const Cameras = (props) => {
  const { user, enforce } = useAuth();

  const cards = React.useMemo(
    () => [
      {
        name: 'total',
        label: 'Total'
      },
      {
        name: 'offline',
        label: 'Offline',
        color: 'var(--red-base)',
        background: 'var(--stats-background-red)',
        filter: { online: '0' },
        icon: Power
      },
      {
        name: 'alerts',
        label: 'Alerts',
        color: 'var(--red-base)',
        background: 'var(--stats-background-red)',
        filter: { alerts: '1' },
        icon: IconAlertTriangle,
        visible: () => enforce({ role: 'VI' })
      },
      {
        name: 'lprEnabled',
        label: 'LPR',
        filter: { lprEnabled: '1' },
        icon: IconTruck
      }
    ],
    [user]
  );

  return (
    <Template {...props}>
      <IndexContent
        initialFilter={{ site: user.focused_site }}
        statsQuery={Camera.getStats}
        title="cameras"
        id="cameras"
        cards={cards}
        query={Camera.search}
        columns={columns}
        sidebar={Sidebar}
        selectable
        actionButton={ActionMenu}
        {...props}
      ></IndexContent>
    </Template>
  );
};

export default Cameras;
