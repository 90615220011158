import React from 'react';
import { css } from '@emotion/react';
import ProgressBar from 'components/progress-bar';
/** @jsxImportSource @emotion/react */

const styles = (props) => css`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  meter::-webkit-meter-bar {
    background: none; /* Required to get rid of the default background property */
    background-color: ${props.color};
  }
  meter::-moz-meter-bar {
    background: none; /* Required to get rid of the default background property */
    background-color: ${props.color};
  }
  meter {
    height: 8px;
    width: 100%;
    position: relative;
  }
  &:after {
    content: '${props.label}';

    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 15px;
    letter-spacing: 0.87;
    /* identical to box height */
    text-transform: uppercase;
    color: var(--menu-item-color);
  }
`;

const StatusProgressBar = ({ data: ac }) => {
  const STATUS = [
    {
      setup: '0',
      label: 'Pending Reset',
      color: '#F59728',

      value: 0
    },
    {
      setup: '1',
      label: 'In Reset...',
      color: '#FFCE22',

      value: 25
    },
    {
      setup: '2',
      label: 'Pending Setup...',
      color: '#6D3CD4',

      value: 75
    },
    {
      setup: '3',
      label: ac.online == '1' ? 'Configured' : 'Offline',
      color: ac.online == '1' ? '#00C9A5' : 'var(--red-base)',
      value: 100
    },
    {
      setup: '4',
      label: 'Upgrading Firmware',
      color: '#6D3CD4',
      value: 100
    },
    {
      setup: '5',
      label: 'Upgrading Firmware',
      color: '#6D3CD4',
      value: 100
    }
  ];

  let currentStatus = STATUS.find((key) => key.setup == ac.setup) || {};

  return (
    <span css={styles(currentStatus)}>
      <ProgressBar
        bgcolor={currentStatus.color}
        completed={currentStatus.value}
      />
    </span>
  );
};

export default StatusProgressBar;
