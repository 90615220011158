import React from 'react';
import Template from 'base/template';

import IndexContent from 'base/index-content';
import { columns } from 'views/access-levels/door-table-columns';

import { useParams } from 'react-router-dom';
import { useQueryWrapper } from 'utils/ajax';
import { useNotFound } from 'hooks/not-found-hook';
import AccessLevel from 'api/access_level';
import AccessLevelDoor from 'api/access_level_door';
import AddSite from 'views/access-levels/add-site';
/** @jsxImportSource @emotion/react */
import Site from 'api/site';
import Schedule from 'api/schedule';
import { ReactComponent as DoorEmptyIcon } from 'assets/empty-icons/empty-door.svg';
import { SiteIcon } from 'icons';
import { useAuth } from 'providers/auth';
const cards = [
  {
    name: 'total',
    label: 'Doors / Readers'
  },
  {
    name: 'effected_sites',
    label: 'Sites',
    icon: SiteIcon,
    color: 'var(--neutral-01)',
    background: 'var(--neutral-07)'
  }
];

const Buttons = (props) => {
  return <AddSite {...props} />;
};

const AlManageDoors = (props) => {
  const { id } = useParams();

  const [al, setAl] = React.useState();

  const FILTERS = React.useMemo(
    () => [
      {
        open: true,
        id: 'recent',
        label: 'Recent Searches...',
        options: []
      },
      {
        open: true,
        id: 'filterBy',
        label: 'Filter By...',
        options: [
          {
            label: 'Site',
            value: 'site',
            fetchFn: Site.search,
            field: 'site',
            name: 'Site',

            id: 'site'
          },
          {
            label: 'Schedule',
            value: 'schedule',
            fetchFn: Schedule.search,
            field: 'schedule',
            name: 'Schedule',
            id: 'id',
            idx: 'id'
          },
          {
            label: 'Door',
            value: 'door',
            fetchFn: AccessLevelDoor.search,
            field: 'door',
            name: 'Door',
            id: 'door_name',
            idx: 'entity',
            initialFilter: {
              accessLevel: al?.id
            }
          }
        ]
      }
    ],
    [al]
  );

  const query = useQueryWrapper(['access-level', 'get', id], () =>
    AccessLevel.get(id)
  );

  useNotFound(id, query, props);

  React.useEffect(() => {
    if (!query?.data) return;
    setAl(query?.data);
  }, [query?.data]);

  const [dialogOpenProp, setDialogOpenProp] = React.useState(
    props?.location?.state?.dialogOpen ?? false
  );
  const [selectedSiteIdProp, setSelectedSiteIdProp] = React.useState(null);
  const { user } = useAuth();

  return (
    <Template {...props}>
      {al && (
        <IndexContent
          idField="door_token"
          newSorter
          setDialogOpenProp={setDialogOpenProp}
          dialogOpenProp={dialogOpenProp}
          selectedSiteIdProp={selectedSiteIdProp}
          pageTitle={al.name}
          initialFilter={{
            accessLevel: al.id,
            excludeNoAccess: true,
            hasSite: user.focused_site
          }}
          id={`al-doors-${al.id}`}
          statsQuery={AccessLevelDoor.stats}
          cards={cards}
          query={AccessLevelDoor.search}
          columns={columns}
          isSidebarOpen={true}
          accessLevel={al}
          dialogOpen={true}
          al={al}
          emptyIcon={DoorEmptyIcon}
          emptyMessage="No accesible doors found"
          filterOptions={FILTERS}
          selectable={false}
          buttons={Buttons}
          getRowProps={(row) => ({
            style: {
              cursor: 'pointer'
            },
            onClick: () => {
              setDialogOpenProp(true);
              setSelectedSiteIdProp(row.original.site_id);
            }
          })}
          {...props}
        ></IndexContent>
      )}
    </Template>
  );
};

export default AlManageDoors;
