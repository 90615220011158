import { dataFetchQuery, useMutation } from "utils/ajax";

import { API_HOST_URL } from "./api-url";
const API_URL = API_HOST_URL + "/snapshot_api";
const Snapshot = {
  search(input = {}) {
    return dataFetchQuery(`${API_URL}/search`, input);
  },

  delete(id) {
    return dataFetchQuery(`${API_URL}/delete/` + id);
  },
};

export default Snapshot;
