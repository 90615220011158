import 'css/sidebar.css';
import React from 'react';
import { withSidebar } from 'components/with-sidebar';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import EventList from 'components/event-list';
import { AvatarCircle } from 'components/avatar';
import { useHeadlessTable } from 'providers/headless-table-provider';
import SidebarEmptyHolder from 'components/sidebar-empty-holder';
import { Badge } from 'components/badge';
import { useEventList } from 'hooks/use-event-list';
import ActivityChartFormatter from 'components/activity-chart-formatter';

const Stats = () => {
  return null;
};
const articleStyles = css`
  display: flex;
  height: 100% !important;
  flex-direction: column;
  gap: 0.2rem;
  > * {
    flex: 1;
  }

  label {
    width: 100%;
  }
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 100%;
    align-items: center;
    min-height: 32px;
    color: var(--neutral-00);
  }
  header {
    width: 100%;
    padding: 24px;
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    align-items: center;
    column-gap: 24px;
    border-bottom: 1px solid var(--neutral-12);

    .avatar {
      grid-row: 1 / 3;
    }
  }
  h2 {
    color: #7f8795;
  }
  > section.usage-chart {
    flex: 1;
  }
  > section.event-list {
    display: inline-flex;
    position: relative;
    flex-direction: column;
    gap: 24px;
    min-height: 50%;
    max-height: 50%;
    width: 100%;
    overflow-y: auto;
  }
  > * {
    padding: 16px;
  }
  .departments {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0.5rem;
    > span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-weight: 700;
    }
  }
`;
const Sidebar = () => {
  const { selectedFlatRows } = useHeadlessTable();

  if (selectedFlatRows.length != 1) return <SidebarEmptyHolder />;

  const query = useEventList({
    filters: { person_id: selectedFlatRows[0].original.id }
  });

  return (
    <article css={articleStyles}>
      <header>
        <AvatarCircle
          name={selectedFlatRows[0].original.name}
          url={selectedFlatRows[0].original.avatar_url}
          size="60px"
          fontSize="20px"
        />
        <h3>{selectedFlatRows[0].original.name}</h3>

        {!selectedFlatRows[0].original.enabled && (
          <Badge color="danger">Barred</Badge>
        )}
      </header>

      <EventList query={query} />
      <section>
        <ActivityChartFormatter
          full
          activity={selectedFlatRows[0].original.activity}
          style={{ height: '100%', width: '100%' }}
          config={{}}
        />
      </section>
      {/* <UsageChartHolder
        name={selectedFlatRows[0].original.name}
        filters={{ person_id: selectedFlatRows[0].original?.id }}
        noExpand
      /> */}
      {/* <UsageChart
          filters={{ person_id: selectedFlatRows[0].original.id }}
          name={selectedFlatRows[0].original.name}
        /> */}
    </article>
  );
};

export default withSidebar({})(Sidebar, Stats);
