import {
  Building,
  CreditCard,
  CreditCard2Front,
  Icon123,
  Plus
} from 'react-bootstrap-icons';
import { Badge } from 'components/badge';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */

import React from 'react';
import { dateTimeDif } from 'utils/time';
import { getSelectionColumn } from 'components/table-selection-column';
import SmallBadge from 'components/small-badge';
const SitesFormatter = ({ sites = [], site_names = [] }) => {
  const [color, label] =
    sites === false ? ['green', 'All'] : ['gray', 'limited'];

  return (
    <Badge title={site_names?.join(', ')} color={color}>
      {label}
    </Badge>
  );
};

export const AuthProfileFormatter = ({ cell: { value } }) => {
  return (
    <SmallBadge
      css={css`
        color: var(--neutral-00);
        svg {
          height: 18px;
          width: 18px;
          font-weight: 700;
          margin-right: 4px;
        }
      `}
      color="gray"
    >
      {value == 'CardNrOnly' && <CreditCard2Front />}
      {value == 'CardOnly' && <CreditCard />}
      {value == 'CardPlusPin' && (
        <>
          <CreditCard />
          <Plus />
          <Icon123 />
        </>
      )}
      {value == 'CardNrPlusPin' && (
        <>
          <CreditCard2Front />
          <Plus />
          <Icon123 />
        </>
      )}
      {value == 'FacilityPlusCardNr' && (
        <>
          <Building />
          <Plus />
          <Icon123 />
        </>
      )}
      {value == 'PINOnly' && <Icon123 />}
      <span>{value.replace(/([a-z0-9])([A-Z])/g, '$1 $2')}</span>
    </SmallBadge>
  );
};

export const columns = [
  getSelectionColumn(),
  {
    style: { width: '250px' },
    Header: 'Name',
    accessor: 'name',
    pickerDisabled: true,
    asc: 'atoz',
    desc: 'ztoa',
    newSorter: true,
    Cell: ({ row: { original } }) => {
      return (
        <section
          css={css`
            display: flex;
            flex-direction: column;
            span.desc {
              color: #7f8795;
              font-size: 12px;
            }

            span.name {
              font-weight: 500;

              font-size: 14px;
            }
          `}
        >
          <span className="name">{original.name}</span>
          <span className="desc">{original.description}</span>
        </section>
      );
    }
  },
  {
    Header: 'Status',
    accessor: 'enabled',
    style: { width: '200px' },
    disableSortBy: true,
    Cell: ({ row: { original } }) => {
      if (original.enabled == 0) return <Badge color="danger">Locked</Badge>;
      else if (
        !(
          new Date() < new Date(original.ValidTo) &&
          new Date() > new Date(original.ValidFrom)
        )
      )
        return <Badge color="danger">Not Active</Badge>;
      else if (original.enabled == 1)
        return <Badge color="success">Active</Badge>;
    }
  },
  {
    Header: 'Authentication',
    accessor: 'auth_profile_token',
    disableSortBy: true,
    style: { width: '50px' },
    Cell: AuthProfileFormatter
  },
  {
    style: { width: '150px' },
    Header: 'Update Time',
    accessor: 'update_time',
    asc: 'oldestUpdated',
    desc: 'newestUpdated',
    newSorter: true,
    Cell: ({ cell: { value } }) => <span>{dateTimeDif(value)}</span>
  },

  {
    style: { width: '25px' },
    asc: 'leastCredentials',
    desc: 'mostCredentials',
    newSorter: true,
    Header: 'Credentials',
    accessor: 'credential_count'
  },
  {
    style: { width: '25px' },
    asc: 'leastDoors',
    desc: 'mostDoors',
    newSorter: true,
    Header: 'Doors ',
    accessor: 'door_count'
  },

  {
    Header: 'Sites',
    accessor: 'sites',
    disableSortBy: true,
    Cell: ({ cell: { value }, row: { original } }) => (
      <SitesFormatter sites={value} />
    )
  }
];
