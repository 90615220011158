import React from "react";
import Template from "base/template";

import Badge from "api/badge";
import { columns } from "views/badging/table-columns";
import IndexContent from "base/index-content";
import ActionMenu from "views/badging/action-menu";

const cards = [
  {
    name: "total",
    label: "Total",
  }
];

const Badges = (props) => {
  return (
    <Template {...props}>
      <IndexContent
        statsQuery={Badge.getStats.bind()}
        title="Badges"
        id="badges"
        cards={cards}
        query={Badge.search.bind()}
        columns={columns}
        addButton="Add a Badge Template"
        selectable
        actionButton={ActionMenu}
        {...props}
      ></IndexContent>
    </Template>
  );
};

export default Badges;
