import React from "react";
import RadioGroup from "components/radio-group";

const REX = [
  { value: "ActiveLow", label: "Active Low" },
  { value: "ActiveHigh", label: "Active High" },
  { value: "None", label: "None" },
];

const RexSelect = (props) => {
  const { field, form } = props;
  return <RadioGroup {...field} {...form} options={REX} title="Rex Type" />;
};

export default RexSelect;
