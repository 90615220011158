import React from "react";
import AuditLog from "api/audit_log";
import { useQueryWrapper } from "utils/ajax";
import { css } from "@emotion/react";
import EmptyHolder from "components/empty-holder";
/** @jsxImportSource @emotion/react */
import Spinner from "components/spinner";
import { dateOnly, timeOnly } from "utils/time";
import { getIcon } from "views/audit-logs/table-columns";
const styles = css`
  .spinner {
    display: block;
    margin: 24px auto;
  }
  .log-item {
    display: grid;
    grid-template-columns: 32px 1fr 100px;
    align-items: center;
    padding: 8px;
    gap: 16px;
    box-shadow: inset 0px -1px 0px #e3e6ec;

    font-size: 12px;

    time {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-left: auto;
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 100%;
      margin-right: 24px;
      color: var(--neutral-02);
    }
  }
`;

function UserAudit({ title, user, ...otherProps }) {
  const { data, status } = useQueryWrapper(["userAudit", user.id], () =>
    AuditLog.search({ user: user?.id })
  );

  return (
    <section className="audit-log-list" css={styles} {...otherProps}>
      {title && <h5>Recent Activity</h5>}
      {status == "loading" && <Spinner />}
      {data && data?.data.length == 0 && <EmptyHolder label="No recent activity" />}
      {data &&
        data?.data.slice(0, 5).map?.((event, idx) => {
          const Icon = getIcon(event);
          return (
            <article className="log-item" key={idx}>
              <Icon size={24} />
              <span>{event.message}</span>
              <time>
                <span>
                  {dateOnly(
                    new Date(event.createdAt)
                  )}
                </span>
                <span>
                  {timeOnly(
                    new Date(event.createdAt)
                  )}
                </span>
              </time>
            </article>
          );
        })}
    </section>
  );
}

export default UserAudit;
