import React from 'react';
import { SSE_HOST_URL } from 'api/api-url';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import { getSelectionColumn } from 'components/table-selection-column';
import { Badge } from 'components/badge';
import { dateTimeDif, dateTimeLocal } from 'utils/time';
import MotionDetection from './motion-detection';
import RecordingIndicator from './recording-indicator';

import DeviceAlertsTableIndicator from 'components/device-alerts-table-indicator';

import {
  IconCloudDataConnection,
  IconDeviceCctv,
  IconDeviceSdCard
} from '@tabler/icons-react';

import SmallBadge from 'components/small-badge';
import { Link } from 'react-router-dom';
import { subMinutes } from 'date-fns';

export const columns = [
  getSelectionColumn(),
  {
    Header: 'Name',
    accessor: 'name',
    pickerDisabled: true,
    pickerHide: true,
    Cell: ({ cell, row: { original } }) => {
      const date15MinutesAgo = subMinutes(new Date(), 15);
      let door =
        original.generic_door_name || original.intercom_relay_door_name;
      return (
        <div
          css={css`
            display: grid;
            grid-template-columns: minmax(24px, auto) minmax(24px, auto) 1fr;
            column-gap: 0.75rem;

            > span {
              grid-row: span 2;
              place-self: center;
              color: var(--light--others--red-400-base);
            }
            > small {
              grid-column: 1;
            }
          `}
        >
          <span>
            <DeviceAlertsTableIndicator device={original} />
          </span>

          {new Date(original.live_stream_accessed_at) > date15MinutesAgo && (
            <span title="Recently viewed">
              <IconDeviceCctv color="var(--light--primary--400-base)" />
            </span>
          )}

          <div
            css={css`
              display: flex;
              flex-direction: column;
            `}
          >
            <strong
              css={css`
                display: inline-block;
                margin-bottom: 6px;
              `}
            >
              <Link to={`/cameras/view/${original.id}`}>
                <strong>{original.name}</strong>
              </Link>
            </strong>
            <small>{original.site_name}</small>
          </div>
        </div>
      );
    }
  },
  {
    Header: 'Rules',
    accessor: 'action_type',
    Cell: ({ row: { original }, cell }) => (
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 0.5rem;
        `}
      >
        {original.action_type?.split(',').map((i) => {
          let [color, icon, title] =
            i == 'sendHttpsFeed'
              ? [
                  '--light--others--orange-300',
                  IconCloudDataConnection,
                  'Cloud'
                ]
              : ['--light--others--purple-300', IconDeviceSdCard, 'Edge'];
          let Icon = icon;
          return (
            <SmallBadge>
              <Icon />
              {title}
            </SmallBadge>
          );
        })}
      </div>
    )
  },
  {
    disableSortBy: true,
    Header: 'Door Association',
    accessor: 'generic_door_name',
    Cell: ({ row, cell }) => {
      return (
        <div>
          {row.original.generic_door_name ||
            row.original.intercom_relay_door_name}
        </div>
      );
    }
  },

  {
    Header: 'Status',
    accessor: 'online',
    label: 'Status',
    pickerDisabled: true,
    Cell: ({ row, cell }) => {
      if (!cell.value || cell.value == 0)
        return <Badge color="danger">offline</Badge>;
      else if (cell.value == 1) return <Badge color="green">Online</Badge>;
    }
  },
  {
    Header: 'Model',
    accessor: 'product',
    Cell: ({ row, cell }) => {
      return (
        <>
          <strong>{row.original.product}</strong> <br />
          <small>{row.original.firmware}</small>
        </>
      );
    }
  },

  // {
  //   Header: 'Currently Recording',
  //   accessor: 'has_motion',
  //   sortDisable: true,
  //   disableSortBy: true,

  //   Cell: ({ row, cell }) => (
  //     <div
  //       css={css`
  //         display: flex;
  //         gap: 1rem;
  //       `}
  //     >
  //       <RecordingIndicator camera={row.original} />
  //       <MotionDetection camera={row.original} />,
  //     </div>
  //   )
  // },
  {
    Header: 'Last Motion Detected',
    accessor: 'last_motion_at',

    Cell: ({ row, cell }) => {
      return cell.value ? (
        <time>
          {`${dateTimeDif(cell.value)} (${dateTimeLocal(
            new Date(cell.value + 'Z')
          )})`}
        </time>
      ) : null;
    }
  }

  // {
  //   Header: "Door",
  //   accessor: "door_name",
  // },

  // {
  //   Header: "Intercom",
  //   accessor: "intercom_name",
  // },
];
