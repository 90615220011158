import React from "react";

import styled from "@emotion/styled";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const styles = (props) => css`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr 1fr;
  grid-column-gap: 8px;
  grid-template-areas:
    "icon sub"
    "icon title";
  align-items: center;
  > span {
    grid-area: icon;
    display: flex;
    width: ${props.size || "48px"};
    height: ${props.size || "48px"};
    background: ${props.background || "var(--circleIcon-backgound-color)"};
    border-radius: 50%;

    align-items: center;
    justify-content: center;
    position: relative;
    clip-path: ${props.triangle ? "polygon(50% 0, 100% 100%, 0 100%)" : "0"};

    > svg {
      grid-column: 1/-1;
      width: 24px;
      height: 24px;
      color: ${props.color || "gray"};
    }
  }
  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 100%;

    color: var(--neutral-00);
  }
  h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 100%;

    color: var(--secondary-font-color);
  }
`;

export const CircleIcon = React.forwardRef((props, ref) => {
  return (
    <article {...props} ref={ref} css={styles(props)}>
      <span className="icon-wrapper">{props.children}</span>
      {props.subtitle && <h4>{props.subtitle}</h4>}
      {props.title && <h3>{props.title}</h3>}
    </article>
  );
});

// export const CircleIcon = styled("span")`;
//   display: grid;
//   grid-template-columns: auto 1fr;

//   svg {
//     color: ${(props) => props.color || "1D2023"};
//     grid-column: 1/-1;
//     width: ${(props) => props.size || "48px"};
//     height: ${(props) => props.size || "48px"};
//     background: ${(props) => props.background || "F3F5F9"};
//     border-radius: 50%;
//     padding: 12px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     position: relative;
//     clip-path: ${(props) =>
//       props.triangle ? "polygon(50% 0, 100% 100%, 0 100%)" : "0"};
//   }
//   &:before {
//     content: "ss";
//     grid-column: 2 /1;
//     grid-row: 1/1;
//   }
//   &:after {
//     content: "00";
//     grid-column: 2 / 1;
//     grid-row: 2 / 1;
//   }
// `;
