import 'css/sidebar.css';
import React from 'react';
import { withSidebar } from 'components/with-sidebar';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import EventList from 'components/event-list';
import { useHeadlessTable } from 'providers/headless-table-provider';
import SidebarEmptyHolder from 'components/sidebar-empty-holder';
import UsageChartHolder from 'components/charts/usage-chart-holder';
import { useInfiniteQuery } from 'react-query';
import Metric from 'api/metric';
import Spinner from 'components/spinner';
import EmptyHolder from 'components/empty-holder';
import LoadMoreButton from 'components/load-more-button';
import Heading from 'components/heading';
import { Badge } from 'components/badge';
import { dateOnly, dateTime, dateTimeShort, timeOnly } from 'utils/time';
import { Check2Circle, XCircle, XCircleFill } from 'react-bootstrap-icons';
import Label from 'components/label';
import ACTIONS from 'views/notifications/action-types';
const Stats = (props) => {
  return null;
};
const articleStyles = css`
  position: relative;
  .spinner {
    display: block;
    margin: 24px auto;
  }
  display: flex;
  height: 100% !important;
  flex-direction: column;

  box-sizing: border-box;

  label {
    width: 100%;
  }
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 100%;
    align-items: center;
    min-height: 32px;
    color: var(--neutral-00);
  }
  header {
    [role='badge'] {
      position: absolute;
      right: 0;
      top: 0;
      margin: 1rem;
    }
    width: 100%;

    align-items: center;
    [role='badge'] {
      display: block;
      margin-top: 0.5rem;
    }
  }

  .notifications {
    overflow-x: hidden;
    overflow-y: auto;
    height: 60%;
    margin: 1rem;
    .notification-event {
      display: flex;
      align-items: center;
      gap: 3rem;
      padding: 0.8rem;
      box-shadow: inset 0px -1px 0px var(--neutral-12);
    }
    span.status {
      padding: 0.2rem;
      border-radius: 50%;
    }
    svg {
    }
  }

  h2 {
    color: #7f8795;
  }
  > section.usage-chart {
    flex: 1;
  }

  > * {
    padding: 16px;
  }
`;

function groupBy(xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

const NotificationActions = ({ notification }) => {
  if (!notification) return null;

  return (
    <article
      css={css`
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 0.2rem;
        padding-top: 0.3rem;
      `}
    >
      {Object.entries(JSON.parse(notification.actions)).map(
        ([key, value], idx) => {
          if (
            value &&
            ((Array.isArray(value) && value.length > 0) ||
              (typeof value === 'object' && Object.keys(value).length > 0))
          )
            return (
              <Label
                key={idx}
                title={Array.isArray(value) ? value?.join(', ') : ''}
              >
                {ACTIONS.find((act) => act.value == key)?.['label']}
              </Label>
            );
        }
      )}
    </article>
  );
};

const Sidebar = (props) => {
  const { selectedFlatRows } = useHeadlessTable();

  if (selectedFlatRows.length == 0) return <SidebarEmptyHolder />;

  const fetchNotifications = ({ pageParam = 1 }) =>
    Metric.search({
      notification_id: selectedFlatRows[0]?.original?.id,
      page: pageParam
    });

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
    refetch,
    remove
  } = useInfiniteQuery(
    [`notifications`, `search`, selectedFlatRows[0]?.original?.id],
    fetchNotifications,
    {
      getNextPageParam: (lastPage, pages) => {
        return pages.length < lastPage.last_page ? pages.length + 1 : undefined;
      }
    }
  );

  return (
    <React.Fragment>
      <article css={articleStyles}>
        <header>
          <h3>{selectedFlatRows[0]?.original?.name}</h3>
          {selectedFlatRows[0]?.original.enabled == '1' && (
            <Badge color="success">Enabled</Badge>
          )}
          {selectedFlatRows[0]?.original.enabled == '0' && (
            <Badge color="danger">Disbaled</Badge>
          )}
          <NotificationActions notification={selectedFlatRows[0]?.original} />
        </header>
        <div className="notifications">
          {status == 'loading' && <Spinner />}
          {data && data.pages?.[0]?.data.length == 0 && (
            <EmptyHolder label="No recent events" />
          )}
          {data &&
            data.pages.map((group, i) => (
              <React.Fragment key={i}>
                {[...Object.values(groupBy(group.data, 'date'))].map((group) =>
                  group.map((event, index) => (
                    <React.Fragment key={event.id}>
                      {index == 0 && <Heading label={event.date} />}
                      <div className="notification-event">
                        <span className="status" data-status={event.success}>
                          {event.success || !('success' in event) ? (
                            <Check2Circle
                              size={24}
                              color="var(--primary-base)"
                            />
                          ) : (
                            <XCircle size={24} color="var(--red-v-01)" />
                          )}
                        </span>
                        {dateTimeShort(
                          new Date(
                            new Date(
                              Number(event?.createdAt?.$date?.$numberLong)
                            ).toISOString()
                          )
                        )}
                      </div>
                    </React.Fragment>
                  ))
                )}
              </React.Fragment>
            ))}
          {hasNextPage && data.pages?.[0]?.data.length > 0 && (
            <LoadMoreButton
              hasNextPage={hasNextPage}
              fetchNextPage={fetchNextPage}
              isFetchingNextPage={isFetchingNextPage}
            />
          )}
        </div>
        <UsageChartHolder
          name={selectedFlatRows[0].original.name}
          tooltiplabel="Notifications"
          filters={{
            notification_id: selectedFlatRows[0].original?.id,
            type: 'notification'
          }}
          noExpand
        />
      </article>
    </React.Fragment>
  );
};

export default withSidebar({})(Sidebar, Stats);
