export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="56"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="lucide lucide-gauge"
    >
      <path d="m12 14 4-4">
        <animate
          attributeName="opacity"
          from="0"
          to="1"
          dur="1s"
          begin="0s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="opacity"
          from="1"
          to="0"
          dur="1s"
          begin="1s"
          repeatCount="indefinite"
        />
      </path>
      <path d="M3.34 19a10 10 0 1 1 17.32 0">
        <animate
          attributeName="opacity"
          from="0"
          to="1"
          dur="1s"
          begin="0s"
          repeatCount="indefinite"
        />
        <animate
          attributeName="opacity"
          from="1"
          to="0"
          dur="1s"
          begin="1s"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  );
};
