import React from 'react';
import { css } from '@emotion/react';
import Spinner from 'components/spinner';
/** @jsxImportSource @emotion/react */
export default () => {
  return (
    <div
      className="leaflet-top leaflet-right"
      css={css`
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        z-index: 2000;
        opacity: 50%;
        pointer-events: none;
        display: flex;
        background: rgba(0, 0, 0, 0.4);

        align-items: center;
        justify-content: center;
      `}
    >
      <div
        className="leaflet-control leaflet-bar"
        css={css`
          z-index: 3000;
          background: white;
          width: 96px;
          height: 96px;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          isolation: isolate;
          z-index: 3000;
          > * {
            color: black;
          }
        `}
      >
        <Spinner />
      </div>
    </div>
  );
};
