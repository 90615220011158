import React from 'react';
import Template from 'base/template';
import ActionMenu from 'views/auxiliaries/action-menu';

import { columns } from 'views/email-queue/table-columns';
import IndexContent from 'base/index-content';
import { useAuth } from 'providers/auth';
import EmailQueueApi from 'api/email_queue';

const cards = [
  {
    name: 'total',
    label: 'Total'
  }
];
const EmailQueue = (props) => {
  return (
    <Template {...props}>
      <IndexContent
        // statsQuery={CloudRecording.getStats}
        title="Email/SMS"
        id="email_queue"
        cards={cards}
        query={EmailQueueApi.search}
        columns={columns}
        //sidebar={Sidebar}
        getRowId={(row, relativeIndex, parent) => row._id}
        //selectable
        // actionButton={ActionMenu}
        //  emptyIcon={AuxiliaryEmptyIcon}
        {...props}
      ></IndexContent>
    </Template>
  );
};

export default EmailQueue;
