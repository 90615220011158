import React from 'react';
import { css, keyframes } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import { dateOnly, timeOnly } from 'utils/time';
import styled from '@emotion/styled';
import EVENTS_CONSTANTS from 'constants/events';
import { DialogDisclosure } from 'reakit/Dialog';
import { Image, ThreeDots } from 'react-bootstrap-icons';
import { AvatarCircle } from 'components/avatar';
import { useModal } from 'providers/modal-provider';
import EventModal from 'components/event-modal';
import { useAuth } from 'providers/auth';
import { useLayout } from 'providers/layout-provider';
import AddDeniedCredential from 'views/events/add-denied-credential';
import { useTheme } from 'providers/theme';
import { useHistory } from 'react-router-dom';
import IsRole from './is-role';
import { IconButton } from './buttons';
import IsRecording from 'views/events/is-recording';
import LicensePlateUri from 'views/license-plates/license-plate-uri';
import * as Collapsible from '@radix-ui/react-collapsible';
import { ChevronDown, Pencil } from 'react-bootstrap-icons';
import Clipboard from 'utils/clipboard';
import SnapshotModal from './snapshot-modal';

const open = keyframes`
  from { height: 0 }
  to { height: 'var(--radix-collapsible-content-height)' }
`;

const close = keyframes`
from { height: 'var(--radix-collapsible-content-height)' }
  to { height: 0 }  
`;

const EventIcon = styled('span')`
  position: relative;
  width: ${(props) => (props.small ? '20px' : '32px')};
  height: ${(props) => (props.small ? '20px' : '32px')};
  flex: 0 0 ${(props) => (props.small ? '20px' : '32px')};
  background: ${(props) =>
    props.background || 'var(--event-icon-background-blue)'};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    color: ${(props) => props.color || '#267DFF'};
    width: 16px;
    height: 16px;
  }
  svg.transform-125 {
    transform: rotate(130deg);
  }
`;
const eventItemStyles = css`
  width: 100%;
  box-shadow: 0px -1px 0px var(--neutral-12);
  display: grid;
  grid-template-columns: auto 5fr auto 2fr auto;
  align-items: center;
  padding: 0.8rem 0.5rem;
  gap: 6px;
  position: static;
  transition: background linear 0.3s;

  cursor: pointer;
  &:hover {
    background: var(--neutral-05);
  }

  span.type {
    align-items: flex-start;
    display: flex;
    gap: 0.3rem;
    flex-direction: column;
    font-style: normal;
    color: var(--secondary-font-color);

    > h4 {
      text-align: intial;
      font-size: 14px;
      font-weight: 600;
      color: var(--neutral-00);
    }
    > * {
      text-align: start;
    }
  }

  span.info {
    display: block;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    font-style: normal;
    font-size: 12px;
    color: var(--event-description-color);
  }

  span.company {
    font-style: normal;
    font-size: 12px;
  }
  span.person {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: var(--secondary-font-color);
  }

  span.event {
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 0.4rem;
    svg {
      width: 12px !important;
      height: 12px !important;
    }
  }
  span.time {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-left: auto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;

    color: var(--secondary-font-color);
  }
  span.license-plate {
    height: 30px;
  }

  &[data-state='open'] {
    svg.collapsible-trigger {
      transform: rotate(180deg);
    }
  }
`;

const EventCard = ({ data: event, collapsible = true }) => {
  const { user } = useAuth();

  const history = useHistory();

  const { toggleSidePanel } = useLayout();

  if (!event) return null;

  const { dialog, setModalState } = useModal();

  let handleClick = (e) => {
    if (event.c_token)
      history.push('/credentials/save/' + event.c_token, {
        desc: event?.c_description
      });
    else if (!event.c_token && event.type == 'accessDenied')
      history.push('/credentials/add/', { credentialData: event.data });
  };

  return (
    <Collapsible.Root className="event-card" disabled={!collapsible}>
      <Collapsible.Trigger asChild>
        <button
          //onClick={handleClick}
          data-type={event.type}
          css={eventItemStyles}
        >
          {event.c_token || event.license_plate_id ? (
            <AvatarCircle name={event.p_name} url={event.p_avatar_url} />
          ) : (
            <EventIcon
              color={EVENTS_CONSTANTS.colors[event?.type]}
              background={EVENTS_CONSTANTS.backgroundColors[event?.type]}
            >
              {typeof EVENTS_CONSTANTS.icons[event?.type] == 'function' &&
                EVENTS_CONSTANTS.icons[event?.type](event)}
            </EventIcon>
          )}
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 0.5rem;
              overflow: hidden;
              * > {
                text-align: start;
              }
              .type {
                overflow: hidden;
                .info {
                  width: 100%;
                }
              }
            `}
          >
            <span className="type">
              <h4>
                {event.reason
                  ? EVENTS_CONSTANTS.reasons[event.reason]
                  : EVENTS_CONSTANTS.readableNames[event?.type]}
              </h4>

              {event.category == 'access' && event.c_description && (
                <span className="person">{event.c_description}</span>
              )}
              <span className="info">
                {event.door_name || event.device_name}
              </span>
              {!user?.company_id && (
                <span className="company">{event.company_name}</span>
              )}
              {/* <IsRecording event={event} />
        {[
          "licensePlateGranted",
          "licensePlateDenied",
          "licensePlateUnfamiliar",
        ].includes(event.type) && (
          <span className="license-plate">
            <LicensePlateUri event={event} />
          </span>
        )} */}
            </span>
          </div>
          <div className="indicators">
            {/* <IsRecording event={event} /> */}
          </div>

          <span className="time">
            <span>{dateOnly(new Date(event?.utctime))}</span>
            <span>{timeOnly(new Date(event?.utctime))}</span>
          </span>
          {collapsible && (
            <ChevronDown
              css={css`
                margin-left: auto;
              `}
              size={12}
              className="collapsible-trigger"
            />
          )}
        </button>
      </Collapsible.Trigger>
      <Collapsible.Content
        asChild={true}
        css={css`
          overflow: 'hidden';
        `}
      >
        <section
          className="collasible-card-area"
          css={css`
            .event-actions {
              display: flex;
              width: 100%;
              gap: 0.5rem;
              align-items: center;
              justify-content: flex-end;
            }
            height: fit-content;
            position: relative;
            padding: 0.8rem;
            background: var(--table-filters-background);
            border-top: 1px solid var(--neutral-12);
            dl {
              margin: 0;
              display: grid;
              grid-template-rows: 14px 1fr;
              grid-template-columns: 1fr 1fr;
              row-gap: 0.1rem;
              column-gap: 0.4rem;
            }

            dt {
              font-size: 12px;
              line-height: 100%;
              color: var(--neutral-01);
              overflow: hidden;
              text-overflow: ellipsis;
            }
            dd {
              font-weight: 600;
              font-size: 14px;
              line-height: 17px;
              color: var(--neutral-15);
              margin-bottom: 0.5rem;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .event-details {
              overflow: auto;
              font-size: 14px;
            }
          `}
        >
          <dl>
            <dt>Device</dt>
            <dd>{event.device_name}</dd>

            <dt
              css={css`
                grid-column: 2;
                grid-row: 1;
              `}
            >
              Door
            </dt>
            <dd
              css={css`
                grid-column: 2;
                grid-row: 2;
              `}
            >
              {event.door_name}
            </dd>

            <dt
              css={css`
                grid-column: 2;
                grid-row: 3;
              `}
            >
              Person
            </dt>
            <dd
              css={css`
                grid-column: 2;
                grid-row: 4;
              `}
            >
              {event.p_name}
            </dd>
            <dt>Camera</dt>
            <dd>{event.camera_name}</dd>
            <dt
              css={css`
                grid-column: 2;
                grid-row: 5;
              `}
            >
              Department
            </dt>
            <dd
              css={css`
                grid-column: 2;
                grid-row: 6;
              `}
            >
              {event.department_names}
            </dd>
            <dt>Access Level</dt>
            <dd>{event.access_level_name}</dd>
          </dl>

          {event.reason == 'CredentialNotFound' && (
            <dl>
              {Object.entries(event.data).map(
                ([key, value], idx) =>
                  value && (
                    <React.Fragment key={idx}>
                      <dt>{key}</dt>
                      <dd>{value}</dd>
                    </React.Fragment>
                  )
              )}
            </dl>
          )}
          <div className="event-actions">
            <AddDeniedCredential event={event} />
            {event.c_token && (
              <IconButton onClick={handleClick}>
                <Pencil size={16} />
              </IconButton>
            )}
            {event.snapshot_link && (
              <SnapshotModal url={event.snapshot_link}>
                <IconButton onClick={handleClick}>
                  <Image size={16} />
                </IconButton>
              </SnapshotModal>
            )}
            <IsRole restrict="C">
              <Clipboard
                title="Copy JSON to clipboard"
                value={JSON.stringify(event)}
              />
            </IsRole>
          </div>
          <div
            className="event-details"
            title={EVENTS_CONSTANTS.getEventDetails(event)}
          >
            {EVENTS_CONSTANTS.getEventDetails(event)}
          </div>
        </section>
      </Collapsible.Content>
    </Collapsible.Root>
  );
};

export default EventCard;
