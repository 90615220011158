import React from 'react';
//import { Tabs, Tab } from "components/tabs";
import Template from 'base/template';
import { Redirect, useHistory } from 'react-router-dom';
import {
  TabItem,
  TabGroup,
  TabPanel,
  TabPanels,
  TabList
} from 'components/tabs';
import { dateOnly } from 'utils/time';

import Content from 'base/content';
import { css } from '@emotion/react';
import PageTitle from 'components/page-title';
import InfiniteTableProvider from 'providers/headless-infinite-table-provider';
import InfiniteList from 'components/headless-infinite-list';
import CameraAction from 'api/camera_action';
import Snapshot from 'api/snapshot';
import { columns } from 'views/recordings/table-columns';
import { columns as snapshotColumns } from 'views/snapshots/table-columns';

import TableFilters from 'components/headless-filters';
import CameraFeed from 'views/cameras/camera-feed';
import TableProvider from 'providers/headless-table-provider';
import Table from 'components/headless-table';
import TablePagination from 'components/headless-pagination';
import { Calendar2Date, Trash } from 'react-bootstrap-icons';
import DashboardEmptyHolder from 'views/dashboard/dashboard-empty-holder';
import { ReactComponent as NoRecordings } from 'assets/images/no-recordings.svg';
import { useQueryWrapper } from 'utils/ajax';
import { useParams } from 'react-router-dom';
/** @jsxImportSource @emotion/react */
import Camera from 'api/camera';
import { useNotFound } from 'hooks/not-found-hook';
import { useLocation } from 'react-router-dom';

import Recordings from 'views/cameras/recordings';
import Breadcrumbs from 'components/breadcrumbs';
import PlayerWrapper from 'components/video/player-wrapper';
import EventTable from 'routes/events/event-table';
const styles = css`
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

function groupBy(xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

const TABS = {
  live: 0,
  recordings: 1,
  snapshots: 2,
  events: 3,
  0: 'live',
  1: 'recordings',
  2: 'snapshots',
  3: 'events'
};

const CameraDetails = (props) => {
  const { id, tab } = useParams();
  const [camera, setCamera] = React.useState(props?.location?.state?.camera);

  const query = useQueryWrapper(
    ['camera', 'get', id],
    () => Camera.search({ id }),
    {
      enabled: !camera
    }
  );

  useNotFound(id, query, props);

  React.useEffect(() => {
    if (!query?.data?.data?.[0]) return;
    setCamera(query.data.data?.[0]);
  }, [query?.data?.data?.[0]]);

  const [selectedIndex, setSelectedIndex] = React.useState(TABS?.[tab] ?? 0);

  return (
    <Template {...props}>
      <Content {...props}>
        <PageTitle>{camera?.name}</PageTitle>
        <Breadcrumbs crumbs={props.crumbs} />

        <EventTable
          selectedColumns={['createdAt', 'type', 'level', 'device_name']}
          id={['events', camera.id]}
          initialFilter={{
            camera_id: Number(camera.id)
          }}
          eventSelectTypes={['motionOn', 'motionOff']}
        />
      </Content>
    </Template>
  );
};

export default CameraDetails;
