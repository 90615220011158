import React from 'react';
import { css } from '@emotion/react';
import DoorStateAvatar from 'views/doors/door-state-avatar';
/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import { AvatarCircle } from 'components/avatar';
import GenericDoor from 'api/generic_door';
import { useQueryWrapper } from 'utils/ajax';
import { WidgetHeader } from './widget-header';
import RemoveItemButton from './remove-item-button';
import Event from 'api/event';
import EventCard from 'components/event-card';
import ActionMenu from 'views/doors/action-menu';
import WidgetGridControls from './widget-grid-controls';
import { ErrorBoundary } from 'react-error-boundary';
import { FetchingWidgetHolder } from './fetching-widget-holder';
import { IconAlertCircle } from '@tabler/icons-react';
import { WidgetControls } from './widget-controls';
const DoorWidget = styled('article')`
  box-sizing: border-box;
  /* Auto layout */
  place-self: center;
  display: flex;
  flex-direction: column;

  padding: 0px;
  width: 100%;
  min-width: 350px;
  height: 100%;
  background: #ffffff;
  border: 1px solid #eceff3;

  min-height: 225px;
  //max-height: 300px;
`;

const Content = styled(`div`)`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  .event-card {
    max-height: 82px;

    button {
      height: min(auto, 50%);
      box-shadow: none;
      cursor: auto;
    }
  }
  &:empty:before {
    content: 'Waiting for events...';
    display: flex;
    grid-row: 1/-1;
    height: 100%;
    align-items: center;
    justify-content: center;
    opacity: 50%;
  }
`;

export const IconCards = styled('div')`
  margin-left: auto;
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export const TitleCards = styled('div')`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: start;
  gap: 0.3rem;
  flex: 1;
  overflow: hidden;
  a {
    max-width: 200px;
    white-space: nowrap;
  }
  a + .site-name {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: auto;
    white-space: nowrap;
    min-width: 0;
  }

  .site-name > span,
  .door-name > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  [role='badge'] {
    padding-inline: 0.5rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    min-height: 24px;
    gap: 0.2rem;
    display: inline-flex;
    align-items: center;

    background: var(--neutral-05);
    border-radius: 4px;
    svg {
      height: 16px;
      width: 16px;
    }
  }

  > .description {
    font-size: 12px;
    color: var(--secondary-font-color);
  }
`;

export default ({
  doorId,
  widgetIndex,
  selectedView,
  setSelectedView,
  index,
  isEdit
}) => {
  const parentRef = React.useRef();

  const [numberOfChildren, setNumberOfChildren] = React.useState();

  function calculateNumberOfChildren() {
    const parentDiv = parentRef.current;
    if (!parentDiv) return;
    const parentHeight = parentDiv.offsetHeight;
    const childHeight = 82; // Height of each child element in pixels

    return Math.floor(parentHeight / childHeight);
  }

  const { data: door, isError: isErrorDoor } = useQueryWrapper(
    ['door', 'get', doorId],
    () => GenericDoor.get(doorId)
  );

  const { data: events, isError: isErrorEvents } = useQueryWrapper(
    ['door', 'events', doorId],
    () => Event.search({ door_id: doorId, limit: 10 })
  );

  React.useEffect(() => {
    setNumberOfChildren(calculateNumberOfChildren());
  }, [
    parentRef.current,
    selectedView.layout[index].rows,
    selectedView.layout[index]?.items[widgetIndex]?.rows
  ]);
  let error = isErrorDoor || isErrorEvents;

  //console.log(data);
  return (
    <DoorWidget>
      <WidgetHeader>
        <TitleCards>
          <div className="name">{door?.name}</div>
          {/* <div className="description">{door?.description}</div> */}
        </TitleCards>
        <WidgetControls>
          {door && (
            <IconCards>
              <DoorStateAvatar door={door} />
              {door?.token && (
                <AvatarCircle
                  name={door?.last_access_person_name}
                  url={door?.last_access_person_avatar}
                />
              )}
              {!isEdit && <ActionMenu small simple data={door} noEdit />}
            </IconCards>
          )}
          <WidgetGridControls
            isEdit={isEdit}
            index={index}
            widgetIndex={widgetIndex}
            selectedView={selectedView}
            setSelectedView={setSelectedView}
          />
          {isEdit && (
            <RemoveItemButton
              onClick={() => {
                let newLayout = [...selectedView.layout];
                newLayout[index].items = newLayout[index]?.items?.filter(
                  (_, i) => i !== widgetIndex
                );
                setSelectedView((prev) => ({
                  ...prev,
                  layout: newLayout
                }));
              }}
            />
          )}
        </WidgetControls>
      </WidgetHeader>
      {error && (
        <FetchingWidgetHolder>
          <IconAlertCircle color="var(--red-base)" size={36} /> Error fetching
          Widget
        </FetchingWidgetHolder>
      )}

      {!error && (
        <Content ref={parentRef}>
          {events?.data?.slice(0, numberOfChildren).map((event, _) => (
            <EventCard
              key={event.id}
              data={event}
              collapsible={false}
              onClick={() => null}
            />
          ))}
        </Content>
      )}
    </DoorWidget>
  );
};
