import React, { Fragment } from "react";
import { Tab } from "@headlessui/react";
import { css } from "@emotion/react";
/** @jsxImportSource @emotion/react */

const styles = css`
  background: var(--neutral-09);
`;

const TabPanel = ({ children, ...otherProps }) => {
  return (
    <Tab.Panel css={styles} {...otherProps}>
      {children}
    </Tab.Panel>
  );
};

export default TabPanel;
