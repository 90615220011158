import { dataFetchQuery, useMutation } from "utils/ajax";
import { API_HOST_URL } from "./api-url";
const API_URL = API_HOST_URL + "/door_api";

const Door = {
  search(input = {}) {
    return dataFetchQuery(`${API_URL}/search`, input);
  },
  searchAllBySite(site, input = {}) {
    return dataFetchQuery(`${API_URL}/search`, { site, page: null, ...input });
  },
  getStats(input = {}) {
    return dataFetchQuery(`${API_URL}/getStats`, input);
  },
  access(token, cb = null) {
    return dataFetchQuery(`${API_URL}/accessDoor/` + token);
  },
  lock(token) {
    return dataFetchQuery(`${API_URL}/lockDoor/` + token);
  },
  unlock(token) {
    return dataFetchQuery(`${API_URL}/unlockDoor/` + token);
  },
  lockDown(token) {
    return dataFetchQuery(`${API_URL}/lockDownDoor/` + token);
  },
  release(token) {
    return dataFetchQuery(`${API_URL}/release/` + token);
  },
  doubleLock(token) {
    return dataFetchQuery(`${API_URL}/doubleLockDoor/` + token);
  },

  lockDownRelease(token) {
    return dataFetchQuery(`${API_URL}/lockDownDoorRelease/` + token);
  },

  getHealth(token) {
    return dataFetchQuery(`${API_URL}/getHealth/` + token);
  },
  setDoorSchedule(input, token) {
    return dataFetchQuery(`${API_URL}/setDoorSchedule/` + token, input);
  },

  edit(input = {}, token = "") {
    return dataFetchQuery(`${API_URL}/edit/` + token, input);
  },

  pin(token) {
    return dataFetchQuery(`${API_URL}/pinDoor/` + token);
  },
  getUsage(input) {
    return dataFetchQuery(`${API_URL}/getUsage`, input);
  },
  getDoorState(token) {
    return dataFetchQuery(`${API_URL}/getDoorState/${token}`);
  },
};

export default Door;
