import React from 'react';
import { TextField } from 'components/form';
import PictureInput from 'components/picture-input';
import { useFormikContext } from 'formik';
import { useQuery } from 'react-query';
import { css } from '@emotion/react';
import { Badge } from 'components/badge';
/** @jsxImportSource @emotion/react */
import { Field } from 'formik';
import DepartmentSelect from 'components/form/department-select';
import AsyncSelect from 'components/headless-select/async';
import Site from 'api/site';
import Spinner from 'components/spinner';
import Department from 'api/department';
import { CheckBox } from 'components/form';

const styles = css`
  display: grid;
  grid-template-columns: var(--form-column-width) var(--form-column-width);
  gap: 24px;
  align-items: start;

  > span {
    grid-column: 1/-1;
    display: flex;
    gap: 8px;
    align-items: center;
    font-size: 13px;
  }
`;
const Departments = (props) => {
  let enabled = props?.person?.id ? true : false;

  const { data } = useQuery(
    ['person', 'departments', `${props.person?.id}`],
    () => Department.search({ person: props?.person?.id }),
    { enabled, cacheTime: 0 }
  );

  if (data || !enabled) {
    return (
      <Field
        helper="Departments associated with this person"
        isMulti={true}
        label="Name"
        menuPlacement="top"
        name="departments"
        component={DepartmentSelect}
        defaultOption={data?.data && data.data?.map((i) => i.id)}
      />
    );
  } else return <Spinner />;
};
const PersonForm = (props) => {
  const { values, setFieldValue } = useFormikContext();

  return (
    <article css={styles}>
      {values.ad_uuid && (
        <span>
          <Badge color="purple">AD</Badge>
          {values.ad_uuid}
        </span>
      )}
      <PictureInput src={props.person?.avatar_url} />
      <div
        css={css`
          align-self: start;
        `}
      >
        <TextField disabled={values.adUuid} label="name" value={values.name} />
        <TextField
          disabled={values.adUuid}
          name="email"
          value={values.email || ''}
        />
        <TextField
          disabled={values.adUuid}
          name="phone"
          value={values.phone || ''}
        />
        <TextField
          helper="Optional unique employee identifier"
          disabled={values.adUuid}
          name="employeeId"
          value={values.employeeId}
        />
      </div>
      <Field
        helper="Sites that can access this person"
        selectAll
        name="sites"
        isMulti={true}
        component={AsyncSelect}
        fetchFn={Site.search}
        onSelectAll={() => {
          setFieldValue('sites', null);
          setFieldValue('allSites', true);
        }}
        onDeselectAll={() => {
          setFieldValue('sites', []);
          setFieldValue('allSites', null);
        }}
      />

      {!props.person && <Departments {...props} />}
      <section
        css={css`
          grid-column: 1/ -1;
        `}
      >
        <CheckBox
          label="Please confirm the above info is correct before submitting"
          checked={!values.formIsDisabled}
          onChange={(e) => setFieldValue('formIsDisabled', !e.target.checked)}
        />
      </section>
    </article>
  );
};

export default PersonForm;
