import React from 'react';

import { TextField } from 'components/form';
import Schedule from 'api/schedule';
import AsyncSelect from 'components/headless-select/async';
import { Field } from 'formik';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import Alert from 'components/alert';
const styles = css`
  display: flex;
  flex-direction: column;
  width: 30vw;
  gap: 16px;
  .number-field {
    width: 50%;
  }
`;

const ElevatorForm = (props) => {
  return (
    <article css={styles}>
      <Field label="Name" name="name" as={TextField}></Field>
      {/* <Field
        label="Floors access time (seconds)"
        max={60}
        min={0}
        name={`access_time`}
        as={NumberField}
      /> */}
      <br />
      <Alert severity="info">
        Set up the floor to stay accessible during the schedule below. <br />
        Multiple schedules can be selected
      </Alert>
      <Field
        title="Open Schedule"
        name="schedule"
        label="Name"
        idx="token"
        fetchFn={Schedule.search}
        component={AsyncSelect}
        isMulti={true}
      />
    </article>
  );
};

export default ElevatorForm;
