import React from 'react';
import RadioGroup from 'components/radio-group';

const LOCK = {
  A1601: [{ value: 'Relay', label: 'Relay' }],
  A1610: [{ value: 'Relay', label: 'Relay' }],
  A1210: [{ value: 'Relay', label: 'Relay' }],

  A1001: [
    { value: 'fail_safe', label: 'Fail Safe' },
    { value: 'fail_secure', label: 'Fail Secure' },
    { value: 'relay_locked', label: 'Relay Open - Locked', relay: true },
    { value: 'relay_unlocked', label: 'Relay Open - Unlocked', relay: true }
  ]
};

const DOUBLE_LOCK = {
  A1001: [
    { value: 'fail_safe', label: 'Fail Safe' },
    { value: 'fail_secure', label: 'Fail Secure' },
    { value: 'relay_locked', label: 'Relay Open - Locked', relay: true },
    { value: 'relay_unlocked', label: 'Relay Open - Unlocked', relay: true },
    { value: 'None', label: 'None' }
  ],
  A1601: [
    { value: 'Relay', label: 'Relay' },
    { value: 'None', label: 'None' }
  ],
  A1610: [
    { value: 'Relay', label: 'Relay' },
    { value: 'None', label: 'None' }
  ],
  A1210: [
    { value: 'Relay', label: 'Relay' },
    { value: 'None', label: 'None' }
  ]
};

const LockSelect = (props) => {
  const { double, field, form, product } = props;
  let options = double ? DOUBLE_LOCK[product] : LOCK[product];

  if (!props.isRelay && product == 'A1001')
    options = options.filter((o) => !o.relay);

  return (
    <RadioGroup
      {...field}
      {...form}
      options={options}
      title={double ? 'Double Lock' : 'Lock'}
    />
  );
};

export default LockSelect;
