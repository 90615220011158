/** @jsxImportSource @emotion/react */

import React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useHeadlessTable } from "providers/headless-table-provider";
import TableEmptyHolder from "./table-empty-holder";
import TableLoader from "./table-loader";
import LoadMoreButton from "./load-more-button";
import Spinner from "./spinner";
const ListHolder = styled("section")`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: start;
  color: var(--neutral-00);

  > div {
    overflow-y: auto;
    height: 100%;
    background: var(--neutral-09);
  }
`;

const List = styled("ul")`
  margin: 0px;
`;
export default function InfiniteList(props) {
  const {
    rows,
    getTableProps,
    isLoading,
    isFetching,
    data,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useHeadlessTable();

  return (
    <>
      {rows.length > 0 && (
        <ListHolder className="list-holder">
          <div>
            <List {...getTableProps()} {...props}>
              {props.children}
            </List>

            {hasNextPage && data.pages?.[0]?.data.length > 0 && (
              <LoadMoreButton
                hasNextPage={hasNextPage}
                fetchNextPage={fetchNextPage}
                isFetchingNextPage={isFetchingNextPage}
              />
            )}
          </div>
        </ListHolder>
      )}

      {rows.length == 0 && !isLoading && (
        <div
          css={css`
            width: 100%;
            display: flex;
            justify-content: center;
            padding-top: 4rem;
          `}
        >
          no data
        </div>
      )}
      {isLoading && <Spinner /> /****change to isFetching if using keepData */}
    </>
  );
}
