import React from 'react';
import Template from 'base/template';

import Controller from 'api/controller';
import { columns } from 'views/controllers/table-columns';
import Sidebar from 'views/controllers/sidebar';
import IndexContent from 'base/index-content';
import {
  ArrowDown,
  CaretDown,
  ChevronDown,
  Cpu,
  Gear,
  Power
} from 'react-bootstrap-icons';
import ActionMenu from 'views/controllers/action-menu';
import { AuxiliaryButton } from 'components/buttons';
import { Menu } from '@headlessui/react';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import { MenuItem, MenuItemDelete, MenuWrapper } from 'components/action-menu';

import { MenuItems } from 'components/action-menu';
import Company from 'api/company';
import { useAuth } from 'providers/auth';
import { useMutationWrapper } from 'utils/ajax';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import useAlertDialog from 'providers/alert-dialog-provider';

const cards = [
  {
    name: 'total',
    label: 'Total'
  },

  {
    name: 'online',
    label: 'Online',
    color: 'var(--primary-v-01)',
    background: 'var(--stats-background-green)',
    filter: { online: '1' },
    icon: Power
  },
  {
    name: 'offline',
    label: 'Offline',
    color: 'var(--red-base)',
    background: 'var(--stats-background-red)',
    filter: { online: '0' },
    icon: Power
  },
  {
    name: 'unsynced',
    label: 'Unsynced',
    color: 'var(--orange-base)',
    id: 'online',
    background: 'var(--stats-background-orange)',
    filter: { synced: '0' },
    icon: Power
  }
];

const menuStyle = css`
  display: inline-block;
  position: relative;
  [role='menu'] {
    left: 0px;
  }
`;

const ExtraAction = (props) => {
  const { user } = useAuth();

  const { mutate } = useMutationWrapper(
    ['company', 'checkSync', user.company_id],
    (companyId) => Company.checkSync(companyId),
    {
      successMessage:
        'When done, report will populate in top bar Notificaiton panel.',
      loadingMessage: 'Generating Report...'
    }
  );

  const confirm = useAlertDialog();
  const handleCompanySync = async (e) => {
    if (
      await confirm({
        description:
          'Running company sync might effect controllers performance, continue ?'
      })
    )
      mutate(user.company_id);
  };

  const menuStyles = css`
    display: inline-block;
    position: relative;
  `;

  return (
    <DropdownMenu.Root as="div" css={menuStyles}>
      <DropdownMenu.Trigger
        asChild={true}
        css={css`
          &[data-state='open'] {
            svg {
              transform: rotate(180deg);
            }
          }
        `}
      >
        <AuxiliaryButton
          compact
          css={css`
            svg {
              width: 16px;
              height: 16px;
            }
          `}
        >
          <ChevronDown />
        </AuxiliaryButton>
      </DropdownMenu.Trigger>
      <MenuItems>
        <MenuItem
          onClick={handleCompanySync}
          label="Run Company Sync..."
        ></MenuItem>
      </MenuItems>
    </DropdownMenu.Root>
  );
};

const Controllers = (props) => {
  const { user, enforce } = useAuth();
  return (
    <Template {...props}>
      <IndexContent
        initialFilter={{ site: user.focused_site }}
        statsQuery={Controller.getStats}
        title="controllers"
        id="controllers"
        cards={cards}
        query={Controller.search}
        columns={columns}
        addButton={enforce({ managing: true }) ? 'Add a Controller' : false}
        sidebar={Sidebar}
        isSidebarOpen={true}
        queryParams={{ refetchInterval: 30000 }}
        selectable
        extraAction={user.company_id ? ExtraAction : null}
        actionButton={ActionMenu}
        {...props}
      ></IndexContent>
    </Template>
  );
};

export default Controllers;
