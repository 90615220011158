import React from 'react';
import Site from 'api/site';
import {
  ChevronDown,
  CircleFill,
  DoorClosed,
  DoorOpen,
  Search
} from 'react-bootstrap-icons';
import AsyncSelect from 'components/headless-select/async';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useQueryWrapper } from 'utils/ajax';
/** @jsxImportSource @emotion/react */
import SelectWrapper from 'components/headless-select';
import { IconButton } from 'components/buttons';
import PopoverContent, {
  Popover,
  PopoverAnchor,
  PopoverPortal,
  PopoverTrigger
} from 'components/popover/popover-content';

import { ReactComponent as RelayOn } from 'assets/images/relay-on.svg';

export default ({ auxiliaries, editLayerRef: ref, mapRef }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [value, setValue] = React.useState(null);
  function getIcon({ name }) {
    return L.divIcon({
      iconSize: [24, 24],
      iconAnchor: [5, 5],
      className: `pin-square`,
      html: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-sliders" viewBox="0 0 16 16">
      <path fill-rule="evenodd" d="M11.5 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM9.05 3a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0V3h9.05zM4.5 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM2.05 8a2.5 2.5 0 0 1 4.9 0H16v1H6.95a2.5 2.5 0 0 1-4.9 0H0V8h2.05zm9.45 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-2.45 1a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0v-1h9.05z"/>
    </svg>
      `
    });
  }
  const selectStyles = {
    control: (provided) => ({
      ...provided,

      width: 'auto',
      margin: 8,
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
    }),
    menu: () => ({
      border: 'none !important'
    }),
    menuList: (base) => ({
      ...base,
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 0px inset'
    })
  };
  return (
    <Popover>
      <PopoverTrigger>
        <IconButton
          title=" Add An Auxiliary Marker "
          onClick={() => setIsOpen((prev) => !prev)}
        >
          <RelayOn />
        </IconButton>
      </PopoverTrigger>

      <PopoverContent
        align="start"
        css={css`
          width: 250px;
        `}
      >
        <SelectWrapper
          idx="value"
          label="name"
          styles={selectStyles}
          noValueContainer
          isClearable={false}
          placeholder="Search auxiliaries..."
          name="doors"
          checkboxes={false}
          data-compact
          icon={Search}
          itemComponents={{ DropdownIndicator: null }}
          menuIsOpen={true}
          //onChange={(e) => setGlobalAccessLevel(e)}
          options={
            auxiliaries?.map((d) => ({
              value: d,
              name: d.name
            })) || []
          }
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          closeMenuOnSelect={true}
          value={value}
          onChange={(e) => {
            let { id, type, name } = e.value;

            let allLayers = ref.current.getLayers();

            let layerExists = allLayers
              ?.filter((l) => l instanceof L.Marker)
              .find(
                (l) =>
                  (l?.properties?.id == id &&
                    l?.properties?.type == 'auxiliary') ||
                  (l?.options?.properties?.id == id &&
                    l?.options?.properties?.type == 'auxiliary')
              );

            if (layerExists) {
              layerExists.setIcon(
                getIcon({ name })
                //L.icon({
                //iconUrl: `https://chart.googleapis.com/chart?chst=d_bubble_text_small&chld=bb|${name}|FFFF00|000000`
                //})
              );
            }
            if (!layerExists) {
              let marker = new L.Marker(mapRef.current.getCenter(), {
                pmIgnore: false,
                icon: getIcon({ name })
              }).bindTooltip(name, {
                permanent: true,
                direction: 'right'
              });
              marker.properties = {
                ...e.value,
                hidden: null,
                type: 'auxiliary'
              };
              marker.addTo(ref.current);
              // TOTO why cant i just ad marker ?
              // TODO check if there is another way to put properties
              {
                /* mapRef.current.pm.enableDraw('Marker', {
              markerEditable: true,
              markerStyle: {
                icon: getDoorIcon({ name }),
                properties: { ...e.value.properties, hidden: null }
              },
              tooltips: false,
              continueDrawing: false
            }); */
              }
              marker.pm.enableLayerDrag();
            }
            setValue(e);
          }}
        />
      </PopoverContent>
    </Popover>
  );
};
