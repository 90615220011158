import React from 'react';
/** @jsxImportSource @emotion/react */
import { ReactComponent as NoRecordings } from 'assets/images/no-recordings.svg';

import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Layer } from 'components/video';
import { OverlayHolder } from 'components/video/overlay';
export default () => {
  return (
    <Layer>
      <OverlayHolder>
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <label>Fetching</label>
      </OverlayHolder>
    </Layer>
  );
};
