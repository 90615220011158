import { useMutationWrapper } from 'utils/ajax';
import Credential from 'api/credential';
import { useQueryClient } from 'react-query';
import { useHeadlessTable } from 'providers/headless-table-provider';

export const useDeleteMutate = () => {
  const queryClient = useQueryClient();
  const { queryKey, tableDispatch } = useHeadlessTable();
  return useMutationWrapper(
    ['credential', 'delete'],
    (tokens) => Credential.delete({ tokens }),

    {
      successMessage: 'Credentials deleted',

      onMutate: (tokens) => {
        let data = queryClient.getQueryData(queryKey);

        const newData = {
          ...data,
          data: data.data.filter((row) => !tokens.includes(row.token))
        };

        queryClient.setQueryData(queryKey, newData);
        tableDispatch({ type: 'deselectRows', rows: tokens });
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['credentials']);
      }
    }
  );
};

export const useEnableMutate = (credential) => {
  const queryClient = useQueryClient();
  const { queryKey } = useHeadlessTable();
  return useMutationWrapper(
    ['credential', 'enable'],
    (token) => Credential.enable(token),

    {
      successMessage: `Operation on Credential: ${credential?.Description} was succesfull`,
      onMutate: (token) => {
        let data = queryClient.getQueryData(queryKey);

        data.data.forEach((i) => i.token == token && (i.Enabled = 1));

        queryClient.setQueryData(queryKey, data);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['credentials', 'stats']);
        queryClient.invalidateQueries(['credential', credential.token]);
      }
    }
  );
};

export const useDisableMutate = (credential) => {
  const queryClient = useQueryClient();
  const { queryKey } = useHeadlessTable();
  return useMutationWrapper(
    ['credential', 'enable'],
    (token) => Credential.disable(token),

    {
      successMessage: `Operation on Credential: ${credential?.Description} was succesfull`,
      onMutate: (token) => {
        let data = queryClient.getQueryData(queryKey);

        data.data.forEach((i) => i.token == token && (i.Enabled = '0'));

        queryClient.setQueryData(queryKey, data);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['credentials', 'stats']);
        queryClient.invalidateQueries(['credential', credential.token]);
      }
    }
  );
};

export const useLostMutate = (credential) => {
  const queryClient = useQueryClient();
  const { queryKey } = useHeadlessTable();
  return useMutationWrapper(
    ['credential', 'lost'],
    (token) => Credential.lost(token),

    {
      successMessage: `Operation on Credential: ${credential?.Description} was succesfull`,
      onMutate: (token) => {
        let data = queryClient.getQueryData(queryKey);

        data.data.forEach((i) => {
          if (i.token == token) {
            i.lost = !i.lost;
            i.Enabled = i.Enabled == '0' ? '1' : '0';
          }
        });

        queryClient.setQueryData(queryKey, data);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['credentials', 'stats']);
      }
    }
  );
};

export const useRemoveAlMutate = (credential) => {
  const queryClient = useQueryClient();
  const { queryKey, tableDispatch } = useHeadlessTable();
  return useMutationWrapper(
    ['credential', 'remove', 'al'],
    (ids) => Credential.removeAccessLevels({ ids }, credential.token),

    {
      onMutate: (ids) => {},
      successMessage: `Operation on Credential: ${credential?.Description} was succesfull`,

      onSuccess: () => {
        tableDispatch({ type: 'deselectAllRows' });
        queryClient.invalidateQueries([`credential-al-${credential.token}`]);
        queryClient.invalidateQueries([`credetial-als-${credential.token}`]);
      }
    }
  );
};

export const useAddAccessLevelsMutate = (credential) => {
  const queryClient = useQueryClient();
  const { queryKey, tableDispatch } = useHeadlessTable();
  return useMutationWrapper(
    ['credential', 'add', 'al'],
    (ids) => Credential.addAccessLevels({ ids }, credential.token),

    {
      successMessage: `Operation on Credential: ${credential?.Description} was succesfull`,
      onMutate: (ids) => {},
      onSuccess: () => {
        queryClient.invalidateQueries([`credential-al-${credential.token}`]);
        queryClient.invalidateQueries([`credetial-als-${credential.token}`]);
      }
    }
  );
};
