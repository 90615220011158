import React from "react";
import GuestForm from "views/guests/form";
import Template from "base/template";
import { Button, CancelButton } from "components/buttons";
import { Redirect } from "react-router-dom";
import FormProvider from "providers/form-provider";
import { useMutationWrapper } from "utils/ajax";
import { useRedirect } from "hooks/redirect-hook";
import Guest from "api/guest";
import { Formik } from "formik";
import * as Yup from "yup";
import PageTitle from "components/page-title";
import Content from "base/content";
import Breadcrumbs from "components/breadcrumbs";

const Save = (props) => {
  let guest = props?.location?.state?.guest || null;

  let { isSuccess, isLoading, mutate } = useMutationWrapper(
    ["guest", "save"],
    (input) => Guest.save(input, guest?.id),
    {}
  );

  const SCHEMA = Yup.object().shape({
    access_level: Yup.string().required("Required").nullable(),
    name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    email: Yup.array().of(Yup.string().email("Invalid Emails")),
    valid_to: Yup.date()
      .max(new Date("2036-06-12T17:30"), "Must be before 2036-06-12T17:30")
      .default(null)
      .when(
        "valid_from",
        (valid_from, Yup) =>
          valid_from &&
          Yup.min(valid_from, "End time cannot be before start time").nullable()
      ),

    valid_to: Yup.date().max("2036-06-12T17:30").required("Required"),
  });

  const INITIAL_VALUES = {
    ...guest,
    sms: guest ? guest?.sms : [],
    email: guest ? guest?.email : [],
    name: guest?.name,
    valid_from: guest
      ? guest.ValidFrom
      // Need this to stay timezone-less
      : new Date().toLocaleString("sv").replace(" ", "T").slice(0, -3),

    valid_to: guest
      ? guest.ValidTo
      : new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000),
    access_level: guest?.access_level_id,
  };

  const { redirect, setRedirect } = useRedirect(isSuccess);

  return (
    <Template {...props}>
      {redirect && <Redirect to="/guests" />}
      <Content {...props}>
        <PageTitle>{guest ? guest.name : "Guest Add"}</PageTitle>
        <Breadcrumbs crumbs={props.crumbs} />
        <Formik
          validationSchema={SCHEMA}
          initialValues={INITIAL_VALUES}
          onSubmit={(values) => {
            const vf = new Date(values.valid_from).toISOString();
            const vt = new Date(values.valid_to).toISOString();

            mutate({ ...values, valid_from: vf, valid_to: vt });
          }}
        >
          {({ handleSubmit }) => (
            <FormProvider
              name="guest"
              mutateFunc={handleSubmit}
              disabled={isLoading}
            >
              <GuestForm guest={guest} />
              <footer>
                <Button type="submit">Submit</Button>
                <CancelButton onClick={() => setRedirect(true)} />
              </footer>
            </FormProvider>
          )}
        </Formik>
      </Content>
    </Template>
  );
};

export default Save;
