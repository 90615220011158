/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { CaretDown, CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import { useHeadlessTable } from 'providers/headless-table-provider';
import TableEmptyHolder from './table-empty-holder';
import TableLoader from './table-loader';
import {
  Th,
  Td,
  Tr,
  Thead,
  TableHolder,
  Table as TableBody,
  SorterIcon
} from 'components/table/table-components';

import { useResizeDetector } from 'react-resize-detector';
import TableTh from './table/table-th';
import IsShowColumn from './table/is-show-column';
import TableError from './table-error';

export default function Table({
  children,
  selectable,
  live,
  getRowProps = () => {},
  ...otherProps
}) {
  const {
    getTableProps,
    isLoading,
    isFetching,
    tableWidth,
    tableHeight,
    headerGroups,
    getTableBodyProps,
    page,
    prepareRow,
    tableRef,
    data,
    state,
    error,
    LStableConfig,
    setLSTableConfig
  } = useHeadlessTable();

  React.useEffect(() => {
    tableRef.current && tableRef.current.scrollTo(0, 0);
  }, [state.pageIndex]);

  const [scrollable, setScrollable] = React.useState();

  React.useEffect(() => {
    if (tableRef.current)
      setScrollable(
        tableRef.current.scrollWidth > tableRef.current.clientWidth
      );
  }, [tableWidth, tableHeight]);

  return (
    <>
      <TableHolder className="table-holder" id="table-holder">
        <div
          ref={tableRef}
          css={css`
            width: 100%;
            overflow-y: auto;
            height: 100%;
            color: var(--neutral-00);
            background: var(--neutral-09);
            position: relative;
          `}
        >
          <TableBody
            data-fetching={isFetching}
            data-scrollable={`${scrollable}`}
            {...getTableProps()}
          >
            <Thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <TableTh key={column.id} column={column} />
                  ))}
                </tr>
              ))}
            </Thead>
            {page.length == 0 && !isLoading && !error && (
              <TableEmptyHolder {...otherProps} />
            )}
            {error && <TableError />}
            {isLoading && <TableLoader />}
            {!isLoading && !error && (
              <tbody {...getTableBodyProps()}>
                {page.length > 0 /** Add is Fetchin gf using keepData */ &&
                  page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <Tr
                        key={i}
                        selectable={selectable}
                        data-selected={row.isSelected ? true : false}
                        onClick={
                          selectable
                            ? (e) => {
                                !row.disableTableSelection &&
                                  !row.isSelected &&
                                  row.toggleRowSelected(!row.isSelected);
                              }
                            : () => null
                        }
                        {...row.getRowProps(getRowProps(row))}
                      >
                        {row.cells.map((cell, i) => {
                          return (
                            <IsShowColumn key={i} column={cell.column}>
                              <Td
                                data-action={
                                  cell.column.id == 'action' ? 'true' : 'false'
                                }
                                data-selection={
                                  cell.column.id == 'selection'
                                    ? 'true'
                                    : 'false'
                                }
                                data-rownumber={
                                  cell.column.id == 'rownumber'
                                    ? 'true'
                                    : 'false'
                                }
                                {...cell.getCellProps()}
                              >
                                {cell.render('Cell', { live, ...otherProps })}
                              </Td>
                            </IsShowColumn>
                          );
                        })}
                      </Tr>
                    );
                  })}
              </tbody>
            )}
          </TableBody>
        </div>
        {children}
      </TableHolder>
    </>
  );

  // Table component logic and UI come here
}
