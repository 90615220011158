import React from 'react';
import { css } from '@emotion/react';

import {
  Check2,
  Exclamation,
  ExclamationCircle,
  XLg
} from 'react-bootstrap-icons';
import { IconExclamationCircle } from '@tabler/icons-react';
/** @jsxImportSource @emotion/react */
const ICONS = {
  warning: XLg,
  success: Check2,
  info: IconExclamationCircle
};

const styles = (type) => css`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 1rem;
  color: var(--neutral-00);

  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;

  padding: 1rem;

  &[data-type='info'] {
    background: var(--secundary-v-02);
    border: 1px solid var(--secundary-v-03);
    svg {
      color: var(--secundary-base);
    }
  }
`;

const Alert = (props) => {
  const { type = 'info' } = props;

  const Icon = props.icon || ICONS[type];

  return (
    <article data-type={type} role="alert" css={styles(type)}>
      <Icon size={24} />
      {props.children}
    </article>
  );
};

export default Alert;
