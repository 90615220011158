import { getSelectionColumn } from 'components/table-selection-column';
import { dateTimeDif } from 'utils/time';

export const columns = [
  getSelectionColumn(),
  {
    Header: 'Name',
    accessor: 'name',
    newSorter: true,
    asc: 'atoz',
    desc: 'ztoa'
  },
  {
    Header: 'Phone',
    accessor: 'phone',
    disableSortBy: true
  },
  {
    Header: 'Contact E-mail',
    accessor: 'contact_email',
    disableSortBy: true
  },
  {
    Header: 'Installer Count',
    accessor: 'installer_count',
    newSorter: true,
    asc: 'lessInstallers',
    desc: 'mostInstallers'
  },
  {
    Header: 'Company Count',
    accessor: 'company_count',
    newSorter: true,
    asc: 'lessCompanies',
    desc: 'mostCompanies'
  },
  {
    Header: 'Device Count',
    accessor: 'device_count',
    newSorter: true,
    asc: 'lessDevices',
    desc: 'mostDevices'
  },
  {
    Header: 'Created At',
    accessor: 'created_at',
    newSorter: true,
    asc: 'oldestFirst',
    desc: 'newestFirst',
    Cell: ({ row }) => dateTimeDif(row.values.created_at)
  }
];
