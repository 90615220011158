import 'css/sidebar.css';
import React from 'react';
import { withSidebar } from 'components/with-sidebar';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import Map from 'components/map';
import CameraFeed from 'views/cameras/camera-feed';
import { useHeadlessTable } from 'providers/headless-table-provider';
import SidebarEmptyHolder from 'components/sidebar-empty-holder';
import OnlineChart from 'components/charts/online-chart';
import UsageChartHolder from 'components/charts/usage-chart-holder';
import styled from '@emotion/styled';
import SiteTabs from 'views/sites/site-tabs';
const Stats = (props) => {
  return null;
};

export const SidebarWrapper = styled('article')`
  display: flex;

  height: 100% !important;
  flex-direction: column;

  label {
    width: 100%;
  }

  > header {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    address > a {
      text-decoration: underline;
      font-size: 14px;
      color: var(--secundary-v-01);
    }
    h1 {
      margin-bottom: 0px;
    }
    h4 {
      color: #7f8795;
    }
  }

  article.whosein-list {
    width: 100%;
    //min-height: 60%;
    //max-height: 60%;
    overflow-y: auto;
    margin-bottom: 8px;
  }
  section.usage-chart {
    width: 100%;
    flex: 1;
  }
`;
const Sidebar = (props) => {
  const [site, setSite] = React.useState();

  const { selectedFlatRows } = useHeadlessTable();

  React.useEffect(() => {
    setSite(selectedFlatRows?.[0]?.original || props.data);
  }, [selectedFlatRows, props.data]);

  if (!site) return <SidebarEmptyHolder />;

  return (
    <SidebarWrapper>
      <header>
        <h1>{site?.name}</h1>
        <h4>{site?.description}</h4>
        <address>
          <a
            target="_blank"
            href={`https://maps.google.com/?ll=${site?.lat},${site?.lng}`}
          >
            {site?.address}
          </a>
        </address>
      </header>
      <SiteTabs site={site} />
    </SidebarWrapper>
  );
};

export default withSidebar({})(Sidebar, Stats);

/**  <article css={articleStyles}>
      <header>
        <h1>{selectedFlatRows[0].original?.name}</h1>
        <h4>{selectedFlatRows[0].original?.description}</h4>
        <address>
          <a target="_blank" href={`https://maps.google.com/?ll=${lat},${lng}`}>
            {selectedFlatRows[0].original.address}
          </a>
        </address>
      </header>
      <section className="content">
        <Map lat={lat} lng={lng} site={selectedFlatRows[0].original} />
        <OnlineChart
          chartData={
            selectedFlatRows[0].original.device_count > 0
              ? [
                  {
                    value: Number(selectedFlatRows[0].original.online_count),
                    name: 'online devices'
                  },
                  {
                    value: Number(selectedFlatRows[0].original.offline_count),
                    name: 'offline devices'
                  }
                ]
              : []
          }
        />
        <UsageChartHolder
          title
          noExpand
          name={selectedFlatRows[0].original.name}
          filters={{ site_id: selectedFlatRows[0].original?.id }}
        />
      </section>
    </article> */
