import React from 'react';
import { useQueryWrapper } from 'utils/ajax';
import Camera from 'api/camera';

import { css } from '@emotion/react';
import ReactECharts from 'echarts-for-react';
import { createDatesArray, createEachDayOfInterval } from 'utils/arrays';
import ChartZoom from 'components/chart-zoom';
/** @jsxImportSource @emotion/react */

export default (props) => {
  let { camera } = props;

  const { data, isLoading } = useQueryWrapper(
    ['camera', camera.id, 'lprActivity', props.filter],
    () =>
      Camera.lprActivity(
        {
          camera_id: camera.id,
          lpr: true,
          from: props.filter?.from,
          to: props.filter?.to,
          groupBy: '$date'
        },
        camera.id
      ),
    {
      staleTime: 30000,
      cacheTime: 60000
    }
  );

  const [datesArray, setDatesArray] = React.useState([]);
  const [chartData, setChartData] = React.useState([]);

  const [dataZoom, setDataZoom] = React.useState({
    start: 0,
    end: 100
  });

  React.useEffect(() => {
    setDatesArray(
      createEachDayOfInterval(props?.filter?.from, props?.filter?.to)
    );
    setDataZoom({ start: 0, end: 100 });
  }, [props.filter]);

  React.useEffect(() => {
    if (!data) return;

    const d = {
      granted: [],
      denied: [],
      unfamiliar: []
    };

    for (let i of datesArray) {
      let item = data.find((d) => d._id == i);
      d.granted.push(item?.licensePlateGranted || 0);
      d.denied.push(item?.licensePlateDenied || 0);
      d.unfamiliar.push(item?.licensePlateUnfamiliar || 0);
    }
    setChartData(d);
  }, [data, datesArray]);

  const option = {
    dataZoom: [
      {
        type: 'inside',
        xAxisIndex: 0,
        minSpan: 5,
        start: dataZoom.start,
        end: dataZoom.end
      }
    ],
    animation: false,
    grid: {
      left: '1%',
      right: '1%',
      bottom: '20%',
      top: '10%'
    },

    tooltip: {
      trigger: 'axis',
      confine: true
    },

    legend: {
      orient: 'horizontal',
      left: 10,
      padding: 5,
      bottom: 0,
      show: false
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: false
      },
      axisLabel: {
        show: false
      },
      axisLine: {
        lineStyle: {
          color: '#D3D3D3'
        }
      }
    },
    xAxis: {
      axisTick: {
        show: false
      },
      type: 'category',
      data: datesArray,
      axisLabel: {
        showMaxLabel: true,
        show: true,
        color: 'var(--neutral-01)',
        fontSize: 11,
        formatter: (x) => {
          const dateStr = x;
          const parts = dateStr.split('-');
          const month = parseInt(parts[1]);
          const day = parseInt(parts[2]);
          return month + '/' + day;
        }
      },
      axisLine: {
        show: false
      }
    },

    series: [
      {
        name: 'Unfamiliar License',
        emphasis: {
          disabled: true
        },
        data: chartData.unfamiliar,
        barWidth: 8,

        type: 'bar',
        itemStyle: {
          color: 'var(--orange-base)'
        }
      },
      {
        name: 'Granted License',
        emphasis: {
          disabled: true
        },
        barWidth: 8,

        data: chartData.granted,
        itemStyle: {
          color: 'var(--primary-base)'
        },

        type: 'bar'
      },
      {
        barWidth: 8,

        name: 'Denied License',
        emphasis: {
          disabled: true
        },
        data: chartData.denied,
        itemStyle: {
          color: 'var(--red-base)'
        },

        type: 'bar'
      }
    ],
    ...props.config
  };

  return (
    <section
      css={css`
        height: 100%;
        width: 100%;
        position: relative;
        .data-zoom {
          position: absolute;
          top: 0;
          right: 0;
          z-index: 1000;
        }
      `}
    >
      <ChartZoom setDataZoom={setDataZoom} dataZoom={dataZoom} />
      <ReactECharts
        opts={{ renderer: 'svg' }}
        loadingOption={{
          showSpinner: false,
          maskColor: 'var(--neutral-09)',
          fontWeight: 'normal',
          text: 'Fetching Data...',

          fontStyle: 'normal',

          fontFamily: 'inherit'
        }}
        showLoading={isLoading}
        option={option}
        style={{
          width: '100%',
          padding: 0,
          height: '100%',
          background: 'var(--neutral-09)'
        }}
      />
    </section>
  );
};
