import React from 'react';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Broadcast, CircleFill } from 'react-bootstrap-icons';

export default () => {
  return (
    <article
      css={css`
        background: rgba(29, 32, 35, 0.8);
        border-radius: 8px;
        display: flex;
        align-items: center;
        gap: 0.3rem;
        padding: 0.5rem;
        width: fit-content;
        > label {
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #ffffff;
        }
      `}
    >
      <Broadcast color="var(--red-base)" size={20} />
      <label>LIVE</label>
    </article>
  );
};
