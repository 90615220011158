import React from 'react';
import { css } from '@emotion/react';
import { IconError404, IconTrafficCone } from '@tabler/icons-react';
/** @jsxImportSource @emotion/react */

const styles = css`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  h1 {
    font-size: 48px;
    line-height: 100%;
    /* identical to box height, or 48px */

    text-align: center;

    /* light/neutrals/900 */

    color: var(--neutral-00);

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 16px 0px;
  }
  h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
    text-align: center;
    color: var(--neutral-01);
  }
  h5 {
    font-weight: 500;
    font-size: 12px;
  }
`;

const Error404 = (props) => {
  document.title = '404';

  return (
    <div css={styles}>
      <IconError404
        size={144}
        strokeWidth={1}
        color={'var( --light--neutrals--700)'}
      />
      <h1>Oops! Page Not Found </h1>
      <h2>
        This page doesn’t exist or you don’t have permission to access it.
        <br />
        Please, check your URL or navigate via the side menu.
      </h2>
      {props.error && <h5>Error code: {props.error}</h5>}
    </div>
  );
};

export default Error404;
