import React from "react";
import Template from "base/template";

import AccessLevel from "api/access_level";
import { columns } from "views/access-levels/table-columns";
import IndexContent from "base/index-content";
import { XLg } from "react-bootstrap-icons";
import ActionMenu from "views/access-levels/action-menu";

const cards = [
  {
    name: "total",
    label: "Total",
  },
  {
    name: "disabled",
    label: "Disabled",
    color: "var(--red-base)",
    background: "var(--red-v-02)",
    filter: { enabled: "0" },
    icon: XLg,
  },
];

const ElevatorAccessLevels = (props) => {
  return (
    <Template {...props}>
      <IndexContent
        nowSorter
        fields={['counts']}
        initialFilter={{ hasFloors: true }}
        statsQuery={AccessLevel.stats}
        title="Elevator Access Levels"
        id="elevator-access-levels"
        cards={cards}
        query={AccessLevel.search}
        columns={columns}
        addButton="Add An Access Level"
        selectable
        actionButton={ActionMenu}
        {...props}
      ></IndexContent>
    </Template>
  );
};

export default ElevatorAccessLevels;
