import React from 'react';
import styled from '@emotion/styled';
import RtspVideo from './rtsp-video';
import Limiter from './limiter';
import { Layer } from 'components/video';
import { PlayCircle } from 'react-bootstrap-icons';
import { css } from '@emotion/react';
import VideoStats from './video-stats';
import CameraStats from './camera-stats';
/** @jsxImportSource @emotion/react */
import OverlayError from './overlay-error';
import ResolutionControl from './resolution-control';

const getHeightPct = (aspectRatio) => {
  if (aspectRatio === 0) {
    throw new Error('Cannot handle aspect ratio 0');
  }
  return 100 / aspectRatio;
};

const ContainerBody = styled('div')`
padding-top: ${(props) => getHeightPct(props.aspectRatio)}%};
width: 100%;
background: black;
position: relative;
display: flex ;
align-items:center;
`;

const Container = styled('div')`
  position: relative;
  height: 100%;
  width: 100%;
  padding: 2rem;
  background: black;
  z-index: 1000;
  overflow: hidden;
`;

import { ErrorBoundary } from 'react-error-boundary';
import VideoControls from './video-controls';
import * as Collapsible from '@radix-ui/react-collapsible';
import GridCanvas from './video-grid-canvas';
function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <OverlayError className="error-boundary">
      <pre>{error.message}</pre>
    </OverlayError>
  );
}

function removeEmpty(obj) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
}

const searchParams = (parameters = {}) => {
  return Object.entries(parameters)
    .map(([key, value]) => {
      if (value)
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join('&');
};

const Player = React.forwardRef(
  (
    {
      showCameraStats,
      pipeline,
      videoEl,
      showStats,
      currentTime,
      currentRecording,
      seek,
      host,
      play,
      setPlay,
      setRefresh,
      refresh,
      forwardedRef,
      onPlaying,
      onLoading,
      duration = null,
      live,
      waiting,
      setWaiting,
      refreshOnVisibility = true,
      autoPlay = true,
      videoProperties,
      vapixParameters,
      webSocketURLParameters = null,
      setVideoProperties,
      isZoomMode = false
    },
    ref
  ) => {
    // make sure we pass host

    // you can either pass entire RTSP or pass vapix Params

    const [parameters, setParameters] = React.useState(vapixParameters);

    React.useEffect(
      () => setParameters((prev) => ({ ...prev, ...vapixParameters })),
      [vapixParameters]
    );

    const onVapix = React.useCallback((key, value) => {
      setParameters((p) => {
        const newParams = { ...p, [key]: value };
        if (value === '') {
          delete newParams[key];
        }
        return newParams;
      });
      //  setRefresh((refreshCount) => refreshCount + 1);
    }, []);

    const rtspUri = React.useCallback(
      (param) => {
        let encodedParams = searchParams(removeEmpty(param));
        return `rtsp://${host?.ip}/axis-media/media.amp?${encodedParams}`;
      },
      [parameters, host, vapixParameters]
    );

    const naturalAspectRatio = React.useMemo(() => {
      if (videoProperties === undefined) {
        return undefined;
      }

      const { width, height } = videoProperties;

      return width / height;
    }, [videoProperties]);

    React.useEffect(() => {
      if (parameters?.resolution && typeof setVideoProperties == 'function') {
        var dimensionsArray = parameters.resolution.split('x');

        // Convert the resulting parts to integers
        var width = parseInt(dimensionsArray[0]);
        var height = parseInt(dimensionsArray[1]);
        setVideoProperties((prev) => ({ ...prev, width, height }));
      }
      //setVideoProperties();
    }, [parameters]);

    React.useEffect(() => {
      if (!refreshOnVisibility) return;

      const cb = () => {
        if (document.visibilityState === 'visible') {
          setPlay && autoPlay && setPlay(true);
          setRefresh && setRefresh((v) => v + 1);
        } else if (document.visibilityState === 'hidden') {
          setPlay && setPlay(false);
          if (videoEl instanceof HTMLElement) videoEl.src = '';
        }
      };

      document.addEventListener('visibilitychange', cb);

      return () => document.removeEventListener('visibilitychange', cb);
    }, [refreshOnVisibility, videoEl]);

    const stream = rtspUri({
      // pull: 1 // breaks the camera fronend,
      audio: 1,
      fps: host?.intercom_id ? 5 : 8,
      videocodec: 'h264',
      resolution: host?.intercom_id ? '320x200' : '800x600',
      ...parameters
    });

    return (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Container className="main-container">
          {showStats && (
            <Layer>
              <VideoStats pipeline={pipeline} videoEl={videoEl} host={host} />
            </Layer>
          )}
          <Layer>{showCameraStats && <CameraStats host={host} />}</Layer>

          {!play && !autoPlay ? (
            <Layer>
              <div
                className="play-button"
                css={css`
                  z-index: 1000;
                  height: 100%;
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background: black;
                  color: white;
                `}
              >
                <button onClick={() => setPlay(true)}>
                  <PlayCircle size={72} />
                </button>
              </div>
            </Layer>
          ) : (
            <Limiter
              className="limiter"
              naturalAspectRatio={naturalAspectRatio}
            >
              <ContainerBody aspectRatio={naturalAspectRatio}>
                <RtspVideo
                  onVapix={onVapix}
                  key={refresh}
                  forwardedRef={forwardedRef}
                  currentTime={currentTime}
                  currentRecording={currentRecording}
                  seek={seek}
                  onPlaying={onPlaying}
                  onLoading={onLoading}
                  //videoRef={videoRef}
                  camera={host}
                  rtsp={stream}
                  play={play}
                  setPlay={setPlay}
                  videoProperties={videoProperties}
                  naturalAspectRatio={naturalAspectRatio}
                  duration={duration}
                  live={live}
                  waiting={waiting}
                  setWaiting={setWaiting}
                  host={host}
                  webSocketURLParameters={webSocketURLParameters}
                  setRefresh={setRefresh}
                />
              </ContainerBody>
              {isZoomMode && (
                <GridCanvas
                  videoRef={videoEl}
                  isZoomMode={isZoomMode}
                  videoProperties={videoProperties}
                />
              )}
            </Limiter>
          )}
        </Container>
      </ErrorBoundary>
    );
  }
);

export default Player;
