import React from 'react';
import TableContext from '../providers/table-context';
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  useExpanded,
  usePagination,
  useFilters,
  useRowSelect,
  useRowState,
  useAsyncDebounce,
  useColumnOrder
} from 'react-table';
import useTableQuery from 'hooks/table-query';
import { useQueryWrapper } from 'utils/ajax';
import { useLocalStorage } from 'hooks/local-storage';
import { useQuery, useQueryClient, queryClient } from 'react-query';
import { useAuth } from './auth';

import { useResizeDetector } from 'react-resize-detector';

const TableProvider = ({
  tableData,
  initialFilter = {},
  fields = false,
  queryParams = {},
  columns,
  query = () => null,
  children,
  id = '',
  live,
  multi = false,
  newSorter = false,
  ...otherProps
}) => {
  const queryClient = useQueryClient();

  const { user } = useAuth();

  const [LStrendChart, setLStrendChart] = useLocalStorage(`${id}:chart`, true);

  const [LShiddenColumns, setLSHiddenColumns] = useLocalStorage(
    `${id}:hiddenColumns`
  );

  const [LSColumnsWidth, setLSColumnsWidth] = useLocalStorage(
    `${id}:columnsWidth`,
    '{}'
  );

  const [LScolumnOrder, setLSColumnsOrder] = useLocalStorage(
    `${id}:columnOrder`
  );

  const [recentFilters, setRecentFilters] = useLocalStorage(
    `${id}:recentFilters`,
    []
  );

  const [LStableConfig, setLSTableConfig] = useLocalStorage(
    `${id}:tableConfig`,
    []
  );

  const PAGE_CHANGED = 'PAGE_CHANGED';
  const PAGE_SIZE_CHANGED = 'PAGE_SIZE_CHANGED';
  const TOTAL_COUNT_CHANGED = 'TOTAL_COUNT_CHANGED';
  const FILTER_CHANGED = 'FILTER_CHANGED';
  const SORTER_CHANGED = 'SORTER_CHANGED';
  const NEW_SORTER_CHANGED = 'NEW_SORTER_CHANGED';
  const GLOBAL_FILTER_CHANGED = 'GLOBAL_FILTER_CHANGED';
  const CHART_TOGGLE = 'CHART_TOGGLE';
  const DATA_CHANGED = 'DATA_CHANGED';
  const DISABLE_FETCHING = 'DISABLE_FETCHING';
  const ENABLE_FETCHING = 'ENABLE_FETCHING';
  const TABLE_STATS = 'TABLE_STATS';

  const initialState = {
    queryPageIndex: 1,
    queryLastPage: 0,
    queryTotal: 0,
    queryFilter: {},
    querySorter: otherProps.initialSorter
      ? !newSorter
        ? {
            order:
              otherProps.initialSorter?.[0].id +
              (otherProps.initialSorter?.[0].desc ? ' DESC' : ' ASC')
          }
        : {
            sort: otherProps.initialSorter?.[0].sortBy
          }
      : {},
    chart: LStrendChart,
    disableFetching: false
  };

  const reducer = (state, { type, payload }) => {
    switch (type) {
      case PAGE_CHANGED: {
        return {
          ...state,
          queryPageIndex: payload
        };
      }

      case PAGE_SIZE_CHANGED: {
        return {
          ...state
          //  queryPageIndex: payload,
        };
      }

      case GLOBAL_FILTER_CHANGED: {
        return {
          ...state,
          queryFilter: payload
        };
      }

      case FILTER_CHANGED: {
        const f = {};
        payload.forEach((p) => (f[p.id] = p.value));

        return {
          ...state,
          queryFilters: f
        };
      }
      case SORTER_CHANGED: {
        return {
          ...state,
          querySorter: {
            order:
              payload.length > 0
                ? payload?.[0].id + (payload?.[0].desc ? ' DESC' : ' ASC')
                : undefined
          }
        };
      }

      case NEW_SORTER_CHANGED: {
        let { sortingDir, desc, asc } = payload;
        return {
          ...state,
          querySorter: {
            sort: sortingDir == 'desc' ? desc : asc
          }
        };
      }

      case DATA_CHANGED: {
        return {
          ...state,
          queryTotal: !payload ? state.queryTotal : payload?.total,
          queryLastPage: !payload ? state.queryLastPage : payload?.last_page
        };
      }

      case CHART_TOGGLE: {
        setLStrendChart(payload);
        return {
          ...state,
          chart: payload
        };
      }

      case DISABLE_FETCHING: {
        return {
          ...state,
          disableFetching: true
        };
      }
      case ENABLE_FETCHING: {
        return {
          ...state,
          disableFetching: false
        };
      }

      case TABLE_STATS: {
        return {
          ...state,
          stats: payload
        };
      }

      default:
        throw new Error(`Unhandled action type: ${type}`);
    }
  };

  const [isLive, setIsLive] = React.useState(false);
  const [isMuti, setIsMulti] = React.useState(multi);

  const [tableState, dispatch] = React.useReducer(reducer, initialState);

  const { remove, refetch, data, isLoading, isFetching, isRefetching, error } =
    useQueryWrapper(
      [
        id,
        'search',

        tableState.queryFilter,
        tableState.querySorter,
        tableState.queryPageIndex
      ],
      ({ signal }) =>
        query(
          {
            ...initialFilter,
            ...(fields ? { fields } : {}),
            ...tableState.queryFilter,
            ...tableState.querySorter,
            page: tableState.queryPageIndex
            //...tableState.queryFilters,
          },
          { signal }
        ),

      {
        // select: (data) => {
        //   if (typeof otherProps.transformData == 'function')
        //     return otherProps.transformData(data);
        //   else return data;
        // },
        enabled: !Array.isArray(tableData),
        ...queryParams
        //keepPreviousData: true,
        // staleTime: 30000, //set this to 30 seconds
      }
    );

  const COLS = React.useMemo(() => columns || [], [user, columns]);
  const DATA = React.useMemo(
    () => (tableData ? tableData : data ? data?.data : []),
    [data?.data, tableData]
  );

  function ensureColOrder(cols) {
    // ensure col number is also numbwer 0
    cols = cols?.filter((item) => item.id !== 'rownumber') ?? [];

    cols.splice(0, 0, 'rownumber');

    return cols;
  }

  const {
    width: tableWidth,
    height: tableHeight,
    ref: tableRef
  } = useResizeDetector();

  const {
    toggleRowSelected,
    dispatch: tableDispatch,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    setFilter,
    setAllFilters,
    visibleColumns,
    allColumns,
    selectedFlatRows,
    toggleAllRowsSelected,
    setColumnOrder,

    state: {
      pageIndex,
      pageSize,
      sortBy,
      globalFilter,
      filters,
      selectedRowIds
    }
  } = useTable(
    {
      initialState: otherProps.initialState ?? {
        selectedRowIds: otherProps?.initialSelection ?? {},
        hiddenColumns: [
          ...(otherProps.hiddenColumns ?? []),
          ...(LShiddenColumns ?? [])
        ],
        columnOrder: ensureColOrder(LScolumnOrder) ?? [],
        live: isLive,
        multi: multi,
        sortBy: otherProps?.initialSorter ?? [],
        globalFilter: React.useMemo((prev) => ({ ...prev }), [data]),
        pageIndex: otherProps.pageIndex ?? 1,
        pageSize: 30
      },
      stateReducer: (newState, action) => {
        if (!newState.multi)
          if (action.type === 'toggleRowSelected') {
            newState.selectedRowIds = {
              [action.id]: action.value
            };
            newState.selectedFlatRows = {};
          }

        if (action.type === 'setGlobalFilter') {
          // Reset selection if globa Filter changed
          return { ...newState, selectedRowIds: {} };
        }
        if (action.type === 'deselectAllRows') {
          return { ...newState, selectedRowIds: {} };
        }
        if (action.type === 'deselectRows') {
          const newSelectedRows = { ...newState.selectedRowIds };

          for (const key in newSelectedRows) {
            if (action.rows.includes(key)) delete newSelectedRows[key];
          }

          return {
            ...newState,
            selectedRowIds: newSelectedRows
          };
        }

        return newState;
      },
      useControlledState: (state) => {
        //always select the row if 1 result

        return React.useMemo(
          () => ({
            ...state,
            multi: multi
            //always check single coloumn
            // selectedRowIds:
            //   data?.data.length == 1 ? { 0: true } : state.selectedRowIds,
          }),
          [state]
        );
      },
      columns: COLS,
      data: DATA,
      manualSortBy: otherProps.manualSortBy ?? true,
      manualGlobalFilter: otherProps.manualGlobalFilter ?? true,
      manualFilters: true,
      manualPagination: otherProps.manualPagination ?? true,
      autoResetSelectedRows: false /** KEEP SELECION */,
      autoResetSortBy: false,
      getRowId: (row, relativeIndex, parent) => {
        return row.id || row.token;
      },

      // autoResetPage: false,
      //autoResetSortBy: false,
      pageCount: -1,
      ...otherProps
    },

    useFilters,

    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    useRowState,
    useColumnOrder
  );

  // This section automatically refetches next page in the pagination --- remove for performance.

  // React.useEffect(() => {
  //   if (data?.data?.length == 30)
  //     queryClient.fetchQuery(
  //       [
  //         id,
  //         'search',

  //         tableState.queryFilter,
  //         tableState.querySorter,
  //         tableState.queryPageIndex + 1
  //       ],
  //       ({ signal }) =>
  //         query(
  //           {
  //             ...initialFilter,
  //             ...tableState.queryFilter,
  //             ...tableState.querySorter,
  //             page: tableState.queryPageIndex + 1
  //             //...tableState.queryFilters,
  //           },
  //           { signal }
  //         )
  //     );
  // }, [tableState.queryPageIndex, data?.data]);

  React.useEffect(() => {
    dispatch({ type: 'PAGE_CHANGED', payload: pageIndex });
  }, [pageIndex]);

  React.useEffect(() => {
    dispatch({ type: 'PAGE_SIZE_CHANGED', payload: pageSize });
    gotoPage(1);
  }, [pageSize, gotoPage]);

  React.useEffect(() => {
    //maybe check global newSorter on table here
    const col = COLS.find((C) => C?.accessor && C.accessor == sortBy?.[0]?.id);
    if (col?.newSorter || newSorter)
      dispatch({
        type: 'NEW_SORTER_CHANGED',
        payload: { sortingDir: sortBy?.[0]?.desc ? 'desc' : 'asc', ...col }
      });
    else dispatch({ type: 'SORTER_CHANGED', payload: sortBy });
  }, [sortBy]);

  React.useEffect(() => {
    dispatch({ type: 'GLOBAL_FILTER_CHANGED', payload: globalFilter });
  }, [globalFilter]);

  React.useEffect(() => {
    dispatch({ type: 'FILTER_CHANGED', payload: filters });
  }, [filters]);

  React.useEffect(() => {
    dispatch({ type: 'DATA_CHANGED', payload: data });
  }, [data]);

  React.useEffect(() => {
    setGlobalFilter({});
  }, [user]);

  return (
    <TableContext.Provider
      value={{
        LStableConfig,
        setLSTableConfig,
        error,
        tableRef,
        tableWidth,
        tableHeight,
        LSColumnsWidth,
        setLSColumnsWidth,
        tableDispatch,
        toggleRowSelected,
        recentFilters,
        setRecentFilters,
        queryKey: [
          id,
          'search',
          tableState.queryFilter,
          tableState.querySorter,
          tableState.queryPageIndex
        ],
        remove,
        refetch,
        id,
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        data,
        isLoading,
        isFetching,
        isRefetching,
        allColumns,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        visibleColumns,
        previousPage,
        setPageSize,
        setGlobalFilter,
        setFilter,
        setAllFilters,
        selectedFlatRows,
        toggleAllRowsSelected,
        state: {
          pageIndex,
          pageSize,
          sortBy,
          globalFilter,
          filters,
          selectedRowIds,
          live: isLive,
          multi
        },
        selectedFlatRows,
        tableState,
        initialState,
        dispatch,
        SORTER_CHANGED,
        FILTER_CHANGED,
        PAGE_CHANGED,
        LShiddenColumns,
        setLSHiddenColumns,
        setIsLive,
        isLive,
        setColumnOrder,
        setLSColumnsOrder,
        ...otherProps
      }}
    >
      {children({
        LStableConfig,
        setLSTableConfig,
        error,
        tableRef,
        tableWidth,
        tableHeight,
        LSColumnsWidth,
        setLSColumnsWidth,
        tableDispatch,
        toggleRowSelected,
        recentFilters,
        setRecentFilters,
        queryKey: [
          id,
          'search',
          tableState.queryFilters,
          tableState.queryFilter,
          tableState.querySorter,
          tableState.queryPageIndex
        ],
        refetch,
        remove,
        id,
        data,
        isFetching,
        isRefetching,
        isLoading,
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        allColumns,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        visibleColumns,
        previousPage,
        setPageSize,
        setGlobalFilter,
        setFilter,
        setAllFilters,
        selectedFlatRows,
        toggleAllRowsSelected,
        state: {
          pageIndex,
          pageSize,
          sortBy,
          filters,
          globalFilter,
          selectedRowIds,
          live: isLive,
          multi
        },
        tableState,
        initialState,
        dispatch,
        SORTER_CHANGED,
        FILTER_CHANGED,
        PAGE_CHANGED,
        LShiddenColumns,
        setLSHiddenColumns,
        setIsLive,
        isLive,
        setColumnOrder,
        setLSColumnsOrder,
        ...otherProps
      })}
    </TableContext.Provider>
  );
};

export const useHeadlessTable = () => {
  //todo take this out
  return React.useContext(TableContext);
};

export default TableProvider;
