import React from "react";
import AsyncSelect from "components/form/async-select";
import Group from "api/group";
import { withField } from "./with-field";
import { components } from "react-select";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const MoreSelectedBadge = ({ items }) => {
  const style = {
    marginLeft: "auto",
    background: "#d4eefa",
    borderRadius: "4px",

    fontSize: "11px",
    padding: "3px",
    order: 99,
  };

  const title = items.join(", ");
  const length = items.length;
  const label = `+ ${length}`;

  return (
    <div style={style} title={title}>
      {label}
    </div>
  );
};

export const MultiValueLabel = ({ children, ...props }) => {
  return (
    <components.MultiValueLabel {...props}>
      <span
        css={css`
          background-color: ${props.data.color};
          color: var(--neutral-00);
          position: static;
          width: -webkit-fit-content;
          width: -moz-fit-content;
          width: fit-content;
          min-height: 21px;
          border-radius: 20px;
          font-weight: bold;
          font-size: 14px;
          padding: 4px 8px;
        `}
      >
        {props.data.name}
      </span>
    </components.MultiValueLabel>
  );
};

export const MultiValue = ({ index, getValue, ...props }) => {
  const maxToShow = 4;
  const overflow = getValue()
    .slice(maxToShow)
    .map((x) => x?.[props?.selectProps?.label]);

  return index < maxToShow ? (
    <components.MultiValue
      {...props}
      css={css`
        background: transparent;
      `}
    />
  ) : index === maxToShow ? (
    <MoreSelectedBadge items={overflow} />
  ) : null;
};

const Menu = (props) => {
  return (
    <>
      <components.Menu {...props}>
        <div>{props.children}</div>
      </components.Menu>
    </>
  );
};

const Option = (props) => {
  return (
    <>
      <components.Option {...props}>
        <span
          css={css`
            background-color: ${props.data.color};
            color: #f0f6ff;
            position: static;
            width: -webkit-fit-content;
            width: -moz-fit-content;
            width: fit-content;
            min-height: 21px;
            border-radius: 20px;
            font-weight: bold;
            font-size: 14px;
            padding: 4px 8px;
          `}
        >
          {props.data.name}
        </span>
      </components.Option>
    </>
  );
};

export const MultiValueContainer = ({ children, ...props }) => {
  return (
    <components.MultiValueContainer {...props}>
      <span
        css={css`
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 16px;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 4px 8px;
          background: ${props.data.color};
          border-radius: 20px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        `}
      >
        {children}
      </span>
    </components.MultiValueContainer>
  );
};

const GroupSelect = ({ componentProps, ...props }) => {
  const [isAdding, setIsAdding] = React.useState();
  return (
    <AsyncSelect
      title="groups"
      fetchFn={Group.search.bind()}
      componentProps={{
        ...componentProps,
        isAdding: isAdding,
        setIsAdding: setIsAdding,
        hideSelectedOptions: true,
      }}
      components={{
        Menu,
        Option,
        MultiValue,
        MultiValueLabel,
        MultiValueContainer,
      }}
      {...props}
    />
  );
};

export default withField(GroupSelect);
