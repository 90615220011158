import React from "react";
import { useFormikContext } from "formik";

const useFormValues = (value, idx, name, setFieldValue, isMulti) => {
  React.useEffect(() => {
    const isAllSelect = Array.isArray(value)
      ? value?.some((i) => i.value == "select-all")
      : false;

    let data = value
      ? Array.isArray(value)
        ? value.map((i) => i[idx])
        : value[idx] ?? ""
      : null;

    !isAllSelect && name && setFieldValue && setFieldValue(name, data);
  }, [value]);
};

export default useFormValues;
