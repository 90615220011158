import { useMutationWrapper } from 'utils/ajax';
import Installer from 'api/installer';
import { useQueryClient } from 'react-query';

export const useDeleteMutate = (installer) => {
  const queryClient = useQueryClient();
  return useMutationWrapper(
    ['installer', 'delete'],
    (id) => Installer.delete(id),

    {
      successMessage: `Installer '${installer?.name}' was deleted.`,
      onSuccess: () => {
        queryClient.invalidateQueries(['installers']);
      }
    }
  );
};
