import 'css/sidebar.css';
import React from 'react';
import { withSidebar } from 'components/with-sidebar';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import ActiveDoorChart from 'views/dashboard/active-door-chart';
import { useHeadlessTable } from 'providers/headless-table-provider';
import SidebarEmptyHolder from 'components/sidebar-empty-holder';
import styled from '@emotion/styled';
import { ReactComponent as GuestIcon } from 'assets/guest-icon.svg';
import EventList from 'components/event-list';
import { useEventList } from 'hooks/use-event-list';
const Stats = () => {
  return <ActiveDoorChart />;
};

export const SidebarWrapper = styled('article')`
  display: flex;

  height: 100% !important;
  flex-direction: column;

  label {
    width: 100%;
  }

  > header {
    display: flex;
    padding: 24px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 8px;

    .action-menu-wrapper {
      position: absolute;
      top: 0px;
      right: 0px;
      margin: 10px;
    }

    h2 {
      margin: 0;
      font-family: Red Hat Display;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 100%;
      /* identical to box height, or 18px */

      /* Neutrals/neutral-00 */

      color: var(--neutral-00);
    }
    h3 {
      font-family: Red Hat Display;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 100%;
      /* identical to box height, or 12px */

      /* Neutrals/neutral-01 */

      color: var(--menu-item-color);
    }
  }
  .event-list {
    width: 100%;
    min-height: 60%;
    max-height: 60%;
    overflow-y: auto;
    padding: 16px;
    margin-bottom: 8px;
  }
`;
const Sidebar = (props) => {
  const [guest, setGuest] = React.useState();

  const { selectedFlatRows } = useHeadlessTable();

  React.useEffect(() => {
    setGuest(selectedFlatRows?.[0]?.original || props.data);
  }, [selectedFlatRows, props.data]);

  const query = useEventList({
    filters: { credential_token: guest?.credential_token }
  });

  if (!guest) return <SidebarEmptyHolder />;
  return (
    <SidebarWrapper>
      <header>
        <GuestIcon
          css={css`
            color: var(--neutral-05);
          `}
        />
        <h2>{guest.name}</h2>
      </header>
      <EventList query={query} />
    </SidebarWrapper>
  );
};

export default withSidebar({})(Sidebar, Stats);
