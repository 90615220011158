import React from 'react';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

//import * as Select from '@radix-ui/react-select';
import Select, { components, OptionProps } from 'react-select';

const SingleValue = (props) => {
  return (
    <components.SingleValue {...props}>
      <div
        css={css`
          align-items: center;
          display: flex;
          gap: 0.5rem;
        `}
      >
        <span></span>
        <label
          css={css`
            text-transform: capitalize;
          `}
        >
          {props.data.label}
        </label>
        <label
          css={css`
            text-transform: capitalize;
          `}
        ></label>
      </div>
    </components.SingleValue>
  );
};

const Option = (props) => {
  return (
    <components.Option {...props}>
      <div
        css={css`
          display: grid;
          grid-template-columns: 32px 1fr;
          gap: 0.5rem;
          align-items: center;
          width: 100%;
          div {
            grid-row: 1 / span 2;
          }
        `}
      >
        <div className="unit">
          {!props.data.unit && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="question-mark"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              strokeWidth="1"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M8 8a3.5 3 0 0 1 3.5 -3h1a3.5 3 0 0 1 3.5 3a3 3 0 0 1 -2 3a3 4 0 0 0 -2 4"></path>
              <path d="M12 19l0 .01"></path>
            </svg>
          )}
          {props.data.unit == 'voltage' && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="circuit-voltmeter"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              strokeWidth={1}
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M12 12m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
              <path d="M5 12h-3"></path>
              <path d="M19 12h3"></path>
              <path d="M10 10l2 4l2 -4"></path>
            </svg>
          )}
        </div>
        <label>{props.data.label}</label>
        {props.data.name && (
          <label
            css={css`
              text-transform: capitalize;
              color: var(--secondary-font-color);
            `}
          >
            {props.data.name}
          </label>
        )}
      </div>
    </components.Option>
  );
};

const ClearIndicator = (props) => {
  return (
    <components.ClearIndicator {...props}>
      <div
        css={css`
          align-items: center;
          display: flex;
          gap: 0.5rem;
          background: var(--secundary-v-01);
        `}
      >
        All
      </div>
    </components.ClearIndicator>
  );
};

export default ({ topicList = [], topics, setTopics, ...otherProps }) => {
  const options = topicList.map(
    (item) =>
      item && {
        value: item.topic,
        label: item.topic,
        name: item.name,
        unit: item.unit
      }
  );

  const [value, setValue] = React.useState();

  return (
    <article className="chart-interval">
      <Select
        unstyled
        css={css`
          .select__control {
            font-size: 14px;
            min-width: 300px;
            height: 100%;
            //min-height: 20px;

            color: white;
            //  border: none;
            cursor: pointer;
          }

          .select__value-container {
            overflow: unset !important;
            //padding: 0px;
            color: white;
            height: 100%;
          }
          .select__single-value {
          }
          .select__menu-list {
          }
          .select__option {
          }
        `}
        isMulti
        placeholder="Add Topic"
        isClearable={false}
        isSearchable={true}
        classNamePrefix="select"
        controlShouldRenderValue={false}
        value={topics}
        onChange={(e) => {
          typeof setTopics == 'function' && setTopics(e ?? []);
        }}
        components={{
          IndicatorSeparator: () => null,
          Option,
          SingleValue,
          ...otherProps.itemComponents
        }}
        styles={{
          control: (base) => ({
            ...base,
            boxShadow: 'none !important',
            pointerEvent: 'all !important'
          }),

          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          menuList: (base) => ({
            ...base
            // kill the white space on first and last option
          }),
          option: (base, state) => ({
            ...base,
            backgroundColor: state.isSelected ? 'var(--primary-v-02)' : null,
            fontSize: '12px',
            color: 'black !important',

            '&:hover': {
              background: 'var(--neutral-05)'
            },
            '&:focus': {
              background: 'vaR(--neutral-05)'
            }
          })
        }}
        // menuPortalTarget={document.body}
        options={options}
        {...otherProps}
      />
    </article>
  );
};
