import React from 'react';
//import { Dialog, Transition } from "@headlessui/react";
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import { Record2, Trash } from 'react-bootstrap-icons';
import EventCard from 'components/event-card';
import { ReactComponent as NoLiveEvents } from 'assets/no-live-events.svg';
import SidebarEmptyHolder from './sidebar-empty-holder';
import styled from '@emotion/styled';
import { useEventSource } from 'react-use-websocket';
import { SSE_HOST_URL } from 'api/api-url';
import { IconButton } from './buttons';
import { useLayout } from 'providers/layout-provider';
import FeedViewCount from './video/feed-view-count';
import { useAuth } from 'providers/auth';
const styles = css`
  height: 100%;
  display: flex;
  flex-direction: column;

  overflow: hidden;
  .empty-holder {
    span {
      background: var(--red-v-02);
    }
  }
  header {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--neutral-12);
    color: var(--secondary-font-color);
    height: 72px;
    > h1 {
      padding: 24px;
    }

    button {
      display: flex;
      margin-right: 16px;
      margin-left: auto;
    }
  }
`;

const contentStyles = (isSocketConnected) => css`
  overflow-y: auto;
  height: 100%;
`;

const Waiting = styled('article')`
  span {
    background: var(--primary-v-02) !important;
    height: 120px;
    width: 120px;

    > svg {
      height: 72px;
      width: 72px;
      color: var(--primary-base);
    }
  }
`;

const LiveEvents = ({ filter }) => {
  const [events, setEvents] = React.useState([]);
  const user = useAuth();

  // const { lastMessage, isSocketConnected } = useWebSocket({ filter });

  // React.useEffect(() => {
  //   if (!lastMessage) return;
  //   setEvents((prev) => [...prev, lastMessage].reverse());
  // }, [lastMessage]);

  const { getSocketUrl } = useLayout();

  const { lastEvent, readyState } = useEventSource(
    getSocketUrl,
    {
      share: true,
      filter: (e) => filter(JSON.parse(e.data)),
      shouldReconnect: (e) => true,
      withCredentials: true,
      retryOnError: true
    },
    Boolean(user && !user.missing2fa && !user.missingMfa)
  );

  React.useEffect(() => {
    setEvents([]);
  }, [filter]);

  React.useEffect(() => {
    if (!lastEvent) return;

    let data = JSON.parse(lastEvent.data);
    setEvents((prev) => [data, ...prev]);
  }, [lastEvent]);

  return (
    <article css={styles}>
      <header>
        <h1>Live Events</h1>
        <IconButton
          disabled={events.length == 0}
          onClick={(e) => setEvents([])}
        >
          <Trash />
        </IconButton>
      </header>
      {readyState != 1 && readyState != 0 ? (
        <SidebarEmptyHolder
          icon={NoLiveEvents}
          title="Live Events are off"
          subtitle="Refresh your screen or try again later"
        />
      ) : (
        <div css={contentStyles(readyState == 1)}>
          {events.length == 0 && (
            <Waiting>
              <SidebarEmptyHolder
                icon={Record2}
                title="Waiting for events..."
                subtitle="Events will populate automatically"
              />
            </Waiting>
          )}
          {events.map((e, index) => (
            <EventCard key={index} data={e} />
          ))}
        </div>
      )}
    </article>
  );
};

export default LiveEvents;
