import React from 'react';

import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import { RadioGroup } from '@headlessui/react';
import { Calendar3 } from 'react-bootstrap-icons';
import { DateTimeField } from 'components/form';
import PopoverContent, {
  PopoverPortal,
  PopoverTrigger,
  Popover
} from 'components/popover/popover-content';

import styled from '@emotion/styled';
import { Button, CancelButton } from 'components/buttons';
import { dateTime, dateTimeShort } from 'utils/time';
import { startOfDay, endOfDay, subDays } from 'date-fns';

const DateTimeSection = styled('section')`
  display: flex;
  flex-direction: column;
  align-items: center;
  .react-datepicker__input-time-container {
    width: fit;
    margin-top: auto;
    height: auto;
    margin-bottom: 0.5rem;
  }

  .react-time-picker__wrapper {
    border: none;
  }
  .react-datepicker {
    height: 100%;
  }
  > div {
    height: 100%;
  }
  > div > div {
    height: 100%;
  }
`;

const filterStyles = css`
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  padding-top: 0.5rem;
  padding-bottom: 3rem;

  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  > div {
    line-height: 32px;
    padding-right: 20px;
    padding-left: 20px;
    cursor: pointer;
    transition: all ease 300ms;
    background: var(--neutral-09);
    color: var(--neutral-00);

    &[aria-checked='true'] {
      background-color: var(--primary-v-02) !important;
    }

    &:hover {
      background: var(--neutral-05);
    }
  }
`;

const PeriodFilters = ({ setStartDate, setEndDate, disableAll }) => {
  const handleChange = (e) => {
    if (e == 'today') {
      setStartDate(startOfDay(new Date()));
      setEndDate(endOfDay(new Date()));
    }
    if (e == 'yesterday') {
      setStartDate(startOfDay(subDays(new Date(), 1)));
      setEndDate(endOfDay(subDays(new Date(), 1)));
    }
    if (e == '-7 day') {
      setStartDate(startOfDay(subDays(new Date(), 6)));
      setEndDate(endOfDay(new Date()));
    }
    if (e == '-30 day') {
      setStartDate(startOfDay(subDays(new Date(), 30)));
      setEndDate(endOfDay(new Date()));
    }
    if (e == 'all') {
      setStartDate(null);
      setEndDate(null);
    }
  };
  return (
    <RadioGroup
      onChange={handleChange}
      value={null}
      css={css`
        background: #ffffff;
        border-right: 1px solid #eceff3;
      `}
    >
      <div css={filterStyles}>
        <RadioGroup.Option value="today">
          <span>Today</span>
        </RadioGroup.Option>
        <RadioGroup.Option value="yesterday">
          <span>Yesterday</span>
        </RadioGroup.Option>
        <RadioGroup.Option value="-7 day">
          <span>Past 7 Days</span>
        </RadioGroup.Option>
        <RadioGroup.Option value="-30 day">
          <span>Past 30 Days</span>
        </RadioGroup.Option>
        {!disableAll && (
          <RadioGroup.Option value="all">
            <span>All</span>
          </RadioGroup.Option>
        )}
      </div>
    </RadioGroup>
  );
};

const CustomInput = ({ showValue, startDate, endDate }) => {
  return (
    <div
      className="period-input"
      css={css`
        height: 100%;
        cursor: pointer !important;
        position: relative;
        > input[type='text'] {
          color: #1d2023;
          color: var(--neutral-00);
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 8px 12px;
          width: 100%;
          height: 100%;
          background: #fff;
          background: var(--neutral-09);
          box-sizing: border-box;
          border-radius: 4px;
          outline: none;
          cursor: text;
          border: 1px solid #e3e6ec;
          border-color: hsl(0, 0%, 80%);
        }
        svg {
          position: absolute;
          top: 0;
          right: 8;
          height: 100%;
          pointer-events: none;
          fill: var(--neutral-00);
        }
      `}
    >
      <input
        css={css`
          cursor: pointer !important;
          font-size: 16px;
        `}
        type="text"
        placeholder="Period..."
        value={
          showValue && (startDate || endDate)
            ? (startDate ? dateTimeShort(startDate) : '') +
              ' - ' +
              (endDate ? dateTimeShort(endDate) : '')
            : ''
        }
        readOnly
      />
      <Calendar3 size={18} />
    </div>
  );
};

const PeriodSelect = ({
  showValue = false,
  disableAll = false,
  globalFilter,
  selection = 'all',
  setSelection,
  ...otherProps
}) => {
  // const [current, setCurrent] = React.useState(null);

  // React.useEffect(() => {
  //   if (selection.period) setCurrent(selection.period);
  //   else if (selection.to || selection.from) setCurrent('custom');
  //   else setCurrent();
  // }, [selection]);

  // React.useEffect(() => {
  //   if (!current) return;
  //   if (typeof setSelection == 'function')
  //     current !== 'custom' &&
  //       setSelection((prev) => ({
  //         ...prev,
  //         period: current,

  //         from: null,
  //         to: null
  //       }));
  // }, [current]);

  const [isPopoverOpen, setIsPopoverOpen] = React.useState();

  const [startDate, setStartDate] = React.useState(globalFilter?.from);

  const [endDate, setEndDate] = React.useState(globalFilter?.to);

  const minEndDate = startDate;

  const handleCancel = () => {
    setStartDate(globalFilter?.from ? new Date(globalFilter.from) : null);
    setEndDate(globalFilter?.to ? new Date(globalFilter.to) : null);
    setIsPopoverOpen(false);
  };

  const handleSubmit = () => {
    if (typeof setSelection == 'function')
      setSelection((prev) => ({
        ...prev,
        from: startDate && startDate.toISOString(),
        to: endDate && endDate.toISOString(),
        period: null
      }));
    setIsPopoverOpen(false);
  };

  React.useEffect(() => {
    if (!globalFilter?.from) setStartDate(null);
    if (!globalFilter?.to) setEndDate(null);
  }, [globalFilter]);

  React.useEffect(() => {
    if (otherProps.liveUpdate)
      if (typeof setSelection == 'function')
        setSelection((prev) => ({
          ...prev,
          from: startDate,
          to: endDate
        }));
  }, [startDate, endDate]);

  return (
    <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
      <PopoverTrigger
        data-compact={otherProps['data-compact'] ? true : false}
        css={css`
          height: 48px;
          &[data-compact='true'] {
            height: 40px;
            min-height: 40px;
          }
        `}
      >
        <CustomInput
          showValue={showValue}
          startDate={startDate}
          endDate={endDate}
        />
      </PopoverTrigger>

      <PopoverContent
        sideOffset={0}
        css={css`
          display: grid;
          grid-template-rows: 1fr auto;

          z-index: 1000;
          width: auto;
          .react-datepicker {
            box-shadow: none;
            border: none;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            gap: 0.2rem;
          `}
        >
          <PeriodFilters
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            disableAll={disableAll}
          />
          <DateTimeSection className="date-time-selection-start">
            <DateTimeField
              selected={startDate}
              value={startDate}
              timeFormat="hh:mm"
              onChange={(date) => {
                setEndDate(null);
                setStartDate(date);
              }}
              inline
              hideTitle
              data-compact
              raw={true}
              selectsStart
              startDate={startDate}
              endDate={endDate}
            />
            {/* <TimePicker
                css={css`
                  width: fit;
                `}
                disableClock
                value={startTime}
                onChange={(time) => {
                  setStartTime(time);
                  {
                    if (!time) return;
                    const [hours, minutes] = time.split(':'); // Split the time string into hours and minutes
                    const d = startDate;
                    d && d.setHours(hours); // Set the hours
                    d && d.setMinutes(minutes); // Set the minutes
                  }
                }}
              /> */}
          </DateTimeSection>
          <DateTimeSection className="date-time-selection-end">
            <DateTimeField
              minDate={minEndDate}
              selected={endDate}
              value={endDate}
              timeFormat="h:mm aa"
              onChange={(date) => {
                setEndDate(date);
              }}
              inline
              hideTitle
              data-compact
              raw={true}
              selectsEnds
              startDate={startDate}
              endDate={endDate}
            />
            {/* <TimePicker
                disableClock
                value={endTime}
                onChange={(time) => {
                  setEndTime(time);
                  {
                    if (!time) return;
                    const [hours, minutes] = time.split(':'); // Split the time string into hours and minutes
                    const d = endDate;
                    d && d.setHours(hours); // Set the hours
                    d && d.setMinutes(minutes); // Set the minutes
                  }
                }}
              /> */}
          </DateTimeSection>
        </div>
        <div
          css={css`
            border-top: 1px solid #eceff3;
            padding: 0.5rem;
            display: flex;
            align-items: center;
            button:first-of-type {
              margin-left: auto;
              margin-right: 4px;
            }
          `}
        >
          <label
            css={css`
              font-size: 12px;
              color: var(--secondary-font-color);
              span {
                margin-left: 0.2rem;
                margin-right: 0.2rem;
              }
            `}
          >
            selected times: <time>{dateTime(startDate) ?? 'xxx'}</time>
            <span>-</span>
            <time>{dateTime(endDate) ?? 'xxx'}</time>
          </label>
          <Button compact onClick={handleSubmit}>
            OK
          </Button>
          <CancelButton compact onClick={handleCancel}>
            Cancel
          </CancelButton>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default PeriodSelect;
