import Controller from 'api/controller';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useQueryWrapper } from 'utils/ajax';
import { Button, CancelButton, AuxiliaryButton } from 'components/buttons';
import FormProvider from 'providers/form-provider';
import { useMutationWrapper } from 'utils/ajax';
import { Redirect } from 'react-router-dom';
import { useRedirect } from 'hooks/redirect-hook';
import { Formik, FieldArray, Field } from 'formik';
import { TextField, Toggle, NumberField } from 'components/form';
import * as Yup from 'yup';
import { FormFooter } from 'components/form/form-footer';
import { css } from '@emotion/react';
import Device from 'api/device';
import Select from 'components/headless-select';
import { ChevronDown, ChevronUp } from 'react-bootstrap-icons';
import Alert from 'components/alert';
/** @jsxImportSource @emotion/react */

const headerStyle = css`
  align-items: end;
  display: flex !important;
  width: 100% !important;
  padding: 12px 0;
  border-bottom: 1px solid #eee;
  gap: 12px !important;
  position: sticky;
  top: -48px;
  background: var(--neutral-09);
  z-index: none;

  > :first-child {
    flex: 1;
  }
`;

const confStyle = css`
  border-bottom: 1px solid var(--neutral-12);
  padding: 12px 0;
`;

const deviceStyle = css`
  display: block !important;
  width: 100% !important;
  box-sizing: border-box;
  padding: 24px 0 0 0;
  grid-column: span 2;

  .issue {
    font-size: 13px;
    background-color: var(--orange-v-01);
    color: #f5861f;
    font-weight: 500;
    padding: 2px 8px;
    border-radius: 24px;
    letter-spacing: 0.4;
  }

  > header {
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;

    > button {
      margin-left: auto;
    }
  }

  > article {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-top: 12px;
    padding: 0 16px 4px 5px;
    border-radius: 5px;
    cursor: pointer;
    transition: background ease 300ms, opacity ease 300ms, margin-top ease 100ms;

    &:hover {
      background: #f0f0f0;
    }

    > .text-field {
      padding-bottom: 0;
    }

    > .number-field {
      width: 72px;
      padding: 0;

      > div {
        font-size: 11px;
        margin-bottom: -2px;
      }

      > input {
        height: 30px;
      }
    }

    > strong {
      flex: 1;
      pointer-events: none;
    }
  }

  &[data-folded='true'] {
    > article {
      pointer-events: none;
      opacity: 0;
      margin-top: -54px;

      transition: background ease 300ms, opacity ease 100ms,
        margin-top ease 300ms;
    }
  }
`;

const parseReader = (door) => {
  if (door?.reader_type == 'Wiegand') {
    if (door?.reader_led_type == 'DualLED') return 'wiegand_double';
    else if (door?.reader_led_type == 'SingleLED') return 'wiegand_single';
  } else if (door?.reader_type == 'RS-485HD') return 'osdp';
  else return 'none';
};

const SetupElevator = ({ ac, configuration }) => {
  const history = useHistory();

  const { isSuccess, isLoading, mutate } = useMutationWrapper(
    ['controller', 'setup', ac.id],
    (input) => Controller.setup(input, ac?.id),
    {
      loadingMessage: `Setting up Controller ${ac.name}...`
    }
  );

  const { redirect, setRedirect } = useRedirect(isSuccess);

  const SCHEMA = Yup.object().shape({
    pinConfiguration: Yup.object().shape({
      MinPINSize: Yup.number().min(4, 'Min 4').max(32, 'Max 32'),
      MaxPINSize: Yup.number()
        .min(Yup.ref('MinPINSize'), 'Must be at least Min PIN Size')
        .max(32, 'Max 32'),
      EndOfPIN: Yup.string().required()
    }),
    readerType: Yup.string().required(),
    floors: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string()
            .nullable()
            .when('enabled', {
              is: true,
              then: Yup.string().required('Name is required')
            }),
          accesstime: Yup.number()
            .nullable()
            .when('enabled', {
              is: true,
              then: Yup.number().required('Required')
            }),
          enabled: Yup.bool().nullable()
        })
      )
      .required('Please add at least 1 floor')
      .min(1, 'Please add at least 1 floor')
  });

  const [initialValues, setInitialValues] = React.useState({
    floors: []
  });

  const [filter, setFilter] = React.useState();
  let exceptIds = [ac.device_id, devices?.map((d) => d.id)].filter((d) => d);

  const {
    remove,
    refetch,
    data,
    isLoading: isDevicesLoading
  } = useQueryWrapper([filter], () =>
    Device.search({
      all: filter,
      type: 'relay',
      exceptIds // remove nulls
    })
  );

  const [selectedDevice, setSelectedDevice] = React.useState(null);
  const [devices, setDevices] = React.useState([]);
  const [foldedDevices, setFoldedDevices] = React.useState({});

  const { mutate: mutateAddDev, isLoading: isDeviceLoading } =
    useMutationWrapper(
      ['controller', 'addRemoteDevice', ac?.id],
      (input) => Controller.addRemoteDevice(ac?.id, input.device.id),
      {
        onMutate() {
          setSelectedDevice(null);
        },
        onSuccess: (res, input) => {
          let len = input.floors.form.getFieldProps('floors').value.length;

          for (const io of res.ServiceList) {
            len++;
            input.floors.push({
              online: true,
              name: 'Floor ' + len,
              accesstime: 30,
              io_remote_id: io.Id,
              ioName: io.NiceName,
              device_id: input.device.id,
              enabled: true
            });
          }

          setDevices([
            ...devices,
            { ...selectedDevice, status: res.Status, token: res.Id }
          ]);
        }
      }
    );

  React.useEffect(
    function () {
      remove();
      refetch();
    },
    [devices]
  );

  const { mutate: mutateRemDev, isLoading: isDeviceRemLoading } =
    useMutationWrapper(
      ['controller', 'removeRemoteDevice', ac?.id],
      (input) => Controller.removeRemoteDevice(ac?.id, input.device.token),
      {
        onSuccess: (res, input) => {
          const floors = input.floors.form.getFieldProps('floors').value;
          input.floors.form.setFieldValue(
            'floors',
            floors.filter((f) => f.device_id != input.device.id)
          );
          setDevices(devices.filter((d) => d.id != input.device.id));
        }
      }
    );

  const { isLoading: isInitialDevicesLoading, data: deviceData } =
    useQueryWrapper([], () => Controller.getRemoteDevices(ac.id), {
      cacheTime: 0
    });

  const [initialDevices, setInitialDevices] = React.useState();

  React.useEffect(() => {
    if (deviceData) setInitialDevices(deviceData);
  }, [deviceData]);

  React.useEffect(
    function () {
      if (!initialDevices) return;

      const devices = [];
      const floors = [];
      const onlineFloorTokens = [];

      initialDevices.forEach((dv) => {
        devices.push(dv);
        for (const io of dv?.ios || []) {
          const floorConf = configuration?.find((c) => c.remote_io == io.Id);

          floorConf && onlineFloorTokens.push(floorConf.token);

          floors.push({
            online: true,
            reader_ap: floorConf?.reader_ap,
            token: floorConf?.token,
            accesstime: floorConf?.AccessTime
              ? Number(floorConf?.AccessTime.replace(/\D+/g, ''))
              : 30,
            name: floorConf?.Name ?? 'Floor ' + (floors.length + 1),
            io_remote_id: io.Id,
            ioName: io.NiceName,
            device_id: dv.id,
            enabled: Boolean(configuration?.length == 0 || floorConf)
          });
        }

        const offlineFloors = configuration?.filter(
          (c) => !onlineFloorTokens.includes(c.token)
        );
        for (const floor of offlineFloors) {
          floors.push({
            online: false,
            reader_ap: floor.reader_ap,
            token: floor.token,
            accesstime: floor.AccessTime
              ? Number(floor.AccessTime.replace(/\D+/g, ''))
              : 30,
            name: floor.Name,
            io_remote_id: floor.remote_io,
            ioName: floor.remote_io_name,
            device_id: dv.id,
            enabled: false
          });
        }
      });

      setDevices(devices);
      setInitialValues({
        ...initialValues,
        floors,
        // All doors point to the same Reader and PIN
        pinConfiguration: configuration?.[0]?.pinConfiguration ?? {
          MaxPINSize: 4,
          MinPINSize: 4,
          EndOfPIN: '#'
        },
        readerType: parseReader(configuration?.[0]),
        reader_token: configuration?.[0]?.reader_token
      });
    },
    [initialDevices, configuration]
  );

  const READER_TYPES = [
    { id: 'wiegand_single', name: 'Wiegand Single LED' },
    { id: 'wiegand_double', name: 'Wiegand Double LED' },
    { id: 'osdp', name: 'OSDP RS485' }
  ];

  const PIN_CHARS = [
    { id: '#', name: '# (hashtag)' },
    { id: '*', name: '* (asterik)' }
  ];

  const RELAY_ISSUES = {
    NotAdded: {
      name: 'Unsynced',
      hint: 'This Relay is not connected anymore. Please, remove it and add it again.'
    },
    AddedNotDiscovered: {
      name: 'Unreachable',
      hint: 'The Relay cannot be reached. Please, Check for network conditions.'
    },
    AddedInvalidCredentials: {
      name: 'Unauthorized',
      hint: 'The Relay is not setup with a local username and password.'
    },

    //custom state set by us when its in controller but not in DB
    NotInDB: {
      name: 'Not In Database',
      hint: 'The Relay is not in DB'
    }
  };

  return (
    <Formik
      validationSchema={SCHEMA}
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={(values) => mutate(values)}
    >
      {({ handleSubmit, setFieldValue, values }) => (
        <FormProvider
          mutateFunc={handleSubmit}
          disabled={
            isInitialDevicesLoading ||
            isLoading ||
            isDeviceLoading ||
            isDeviceRemLoading
          }
        >
          {redirect && <Redirect to="/devices" />}
          <section css={confStyle}>
            <div
              css={css`
                display: flex;
                gap: 12px;
                > .field {
                  flex: 1;
                }
                > .select__wrapper {
                  width: 120px;
                }
              `}
            >
              <Field
                name={`pinConfiguration.MinPINSize`}
                value={values.pinConfiguration?.MinPINSize}
                label={'Min PIN Size'}
                min={1}
                max={32}
                as={NumberField}
              />
              <Field
                name={`pinConfiguration.MaxPINSize`}
                value={values.pinConfiguration?.MaxPINSize}
                label={'Max PIN Size'}
                min={1}
                max={32}
                as={NumberField}
              />
              <Select
                simple
                checkboxes={false}
                placeholder=""
                title="End Character"
                isMulti={false}
                options={PIN_CHARS}
                closeMenuOnSelect={true}
                value={PIN_CHARS.find(
                  (pc) => pc.id == values.pinConfiguration?.EndOfPIN
                )}
                isClearable={false}
                setState={() => {}}
                onChange={(e) =>
                  setFieldValue('pinConfiguration.EndOfPIN', e?.id)
                }
              />
            </div>

            <Select
              checkboxes={false}
              title="Reader Type"
              placeholder=""
              isMulti={false}
              controlShouldRenderValue={true}
              options={READER_TYPES}
              closeMenuOnSelect={true}
              value={READER_TYPES.find((rt) => rt.id == values.readerType)}
              setState={() => {}}
              onChange={(e) => setFieldValue('readerType', e?.id)}
            />
          </section>

          <FieldArray
            name="floors"
            render={(array) => (
              <>
                <section css={headerStyle}>
                  <Select
                    title="remote relay"
                    helper="Only currently online relays can be added"
                    idx="id"
                    label="name"
                    setState={setFilter}
                    controlShouldRenderValue={true}
                    isMulti={false}
                    checkboxes={false}
                    isClearable={true}
                    options={data?.data}
                    isOptionDisabled={(e) => !e.online}
                    closeMenuOnSelect={true}
                    onChange={(e) => {
                      setSelectedDevice(e);
                    }}
                    isLoading={isDevicesLoading}
                    value={selectedDevice}
                  />

                  <AuxiliaryButton
                    disabled={!selectedDevice}
                    onClick={() =>
                      mutateAddDev({ floors: array, device: selectedDevice })
                    }
                  >
                    Add Remote Relay
                  </AuxiliaryButton>
                </section>

                <Alert severity="info">
                  Only I/Os in Output Mode can be used as Elevator Floors
                </Alert>

                {devices?.length > 0 ? (
                  devices.map((dv) => (
                    <section
                      data-folded={foldedDevices[dv.id]}
                      css={deviceStyle}
                    >
                      <header
                        onClick={() =>
                          setFoldedDevices({
                            ...foldedDevices,
                            [dv.id]: !foldedDevices[dv.id]
                          })
                        }
                      >
                        {foldedDevices[dv.id] ? <ChevronDown /> : <ChevronUp />}
                        <h4>{dv.name}</h4>
                        <span>
                          (
                          {
                            values.floors.filter(
                              (f) => f.enabled && f.device_id == dv.id
                            ).length
                          }{' '}
                          Floors)
                        </span>
                        {dv.status != 'Ok' && (
                          <span
                            title={RELAY_ISSUES[dv.status]?.hint}
                            className="issue"
                          >
                            {RELAY_ISSUES[dv.status]?.name ?? dv.status}
                          </span>
                        )}
                        <AuxiliaryButton
                          compact="true"
                          onClick={(ev) => {
                            console.log('remove device', dv);
                            mutateRemDev({ floors: array, device: dv });
                            ev.stopPropagation();
                          }}
                        >
                          Remove Relay
                        </AuxiliaryButton>
                      </header>

                      {values.floors.map((floor, fidx) =>
                        floor.device_id != dv.id ? null : (
                          <>
                            <article
                              onClick={function (ev) {
                                ev.target.tagName == 'ARTICLE' &&
                                  array.replace(fidx, {
                                    ...floor,
                                    enabled: !floor.enabled
                                  });
                              }}
                            >
                              <Field
                                disabled={!floor.enabled}
                                name={`floors[${fidx}].name`}
                                value={floor.name}
                                placeholder={'Floor Name'}
                                as={TextField}
                                label={' '}
                                data-compact
                              ></Field>

                              <strong>
                                {floor.ioName + '  '}
                                {!floor.online && (
                                  <span
                                    title="This I/O is currently unavailable. Check for it's I/O mode."
                                    className="issue"
                                  >
                                    Offline
                                  </span>
                                )}
                              </strong>

                              <Field
                                disabled={!floor.enabled}
                                name={`floors[${fidx}].accesstime`}
                                value={floor.accesstime}
                                label={'Access Time'}
                                min={1}
                                max={100}
                                as={NumberField}
                                data-compact
                              />

                              <Toggle
                                value={floor.enabled}
                                disabled={!floor.online}
                                onChange={(e) => {
                                  array.replace(fidx, {
                                    ...floor,
                                    enabled: e.target.checked
                                  });
                                }}
                              />
                            </article>
                          </>
                        )
                      )}
                    </section>
                  ))
                ) : (
                  <p
                    css={css`
                      margin: 24px 24px 0 24px;
                    `}
                  >
                    No Remote Relays added yet
                    {!data?.data?.length && (
                      <>
                        <br />
                        <br />
                        <Button
                          onClick={() => history.push('/devices/add')}
                          compact
                        >
                          Add a Relay
                        </Button>
                      </>
                    )}
                  </p>
                )}
              </>
            )}
          />

          <FormFooter
            css={css`
              position: sticky;
              bottom: -40px;
              background: white;
              padding: 24px;
              align-items: center;
              border-top: 1px solid #eee;

              strong {
                margin-left: auto;
                font-size: 18px;
              }
            `}
          >
            <Button
              disabled={values.floors.filter((f) => f.enabled).length < 1}
              type="submit"
            >
              Submit
            </Button>
            <CancelButton onClick={() => setRedirect(true)} />
            <strong>
              {values.floors.filter((f) => f.enabled).length} Floors
            </strong>
          </FormFooter>
        </FormProvider>
      )}
    </Formik>
  );
};

export default SetupElevator;
