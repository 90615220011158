import React from 'react';
//import { Tabs, Tab } from "components/tabs";
import Template from 'base/template';

import Content from 'base/content';
import PageTitle from 'components/page-title';
import Details from 'views/devices/details';
import { useQueryWrapper } from 'utils/ajax';
import Device from 'api/device';
import { useEventSource } from 'react-use-websocket';
import { SSE_HOST_URL } from 'api/api-url';
import { useParams } from 'react-router-dom';
/** @jsxImportSource @emotion/react */
import { useNotFound } from 'hooks/not-found-hook';
import Breadcrumbs from 'components/breadcrumbs';

const DeviceDetails = (props) => {
  const { id } = useParams();

  const [device, setDevice] = React.useState(props?.location?.state?.device);

  const query = useQueryWrapper(
    ['device', 'get', id],
    () => Device.get(id),
    {
      enabled: !device
    }
  );
  const { lastEvent } = useEventSource(SSE_HOST_URL + '/events', {
    share: true,
    filter: (e) => filter(JSON.parse(e.data)),
    withCredentials: true,
    retryOnError: true
  });

  let filter = React.useCallback((data) => {
    if (
      [
        'resetStart',
        'restoreStart',
        'online',
        'offline',
        'resetFinish'
      ].includes(data?.type)
    )
      return true;
    return false;
  }, []);

  React.useEffect(() => {
    if (!lastEvent) return;
    query.refetch();
  }, [lastEvent]);

  useNotFound(id, query, props);
  React.useEffect(() => {
    if (!query?.data) return;
    setDevice(query.data);
  }, [query?.data]);

  return (
    <Template {...props}>
      <Content {...props}>
        <PageTitle>{device?.name}</PageTitle>

        <Breadcrumbs crumbs={props.crumbs} />
        {device && <Details device={device} />}
      </Content>
    </Template>
  );
};

export default DeviceDetails;
