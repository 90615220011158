import React from 'react';
import { useQueryWrapper } from 'utils/ajax';

import { css } from '@emotion/react';
import ReactECharts from 'echarts-for-react';
import Sensor from 'api/sensor';
import { useQueries } from 'react-query';

/** @jsxImportSource @emotion/react */
export default ({ queryParams, slim = false, ...props }) => {
  const queries = useQueries(
    queryParams.map((q) => {
      let { sensor, topic, filter } = q;
      return {
        queryKey: ['sensor', sensor.id, topic, filter],
        queryFn: () =>
          Sensor.getTimeSeries(
            {
              topic: topic,
              from: q.filter?.from,
              to: q.filter?.to,
              interval: q.filter?.interval
            },
            sensor.id
          ),
        staleTime: 60000,

        refetchInterval:
          new Date(q.filter?.to) < new Date() ? false : props.refetchInterval,
        refetchIntervalInBackground: false,
        select: (data) => data.data
      };
    })
  );

  const isLoading = queries?.find((query) => query.isLoading === true);
  const isRefetching = queries?.find((query) => query.isRefetching == true);
  const noData = queries?.every((query) => query?.data?.length == 0);

  let seriesType = queryParams.length == 1 ? props.type ?? 'line' : 'line';
  const option = {
    title: {
      text: noData ? 'no data' : '',
      textStyle: {
        fontSize: 12,
        fontWeight: 'thin',
        align: 'center',
        verticalAlign: 'middle',
        lineHeight: 200
      },
      top: 'middle',
      left: 'center'
    },

    dataZoom: !slim
      ? [
          {
            xAxisIndex: [0],
            type: 'inside'
            // start: 98,
            // end: 100
          },
          {
            xAxisIndex: [0],
            // start: 98,
            // end: 100,
            borderColor: 'transparent',

            dataBackground: {
              areaStyle: {
                color: '#2480d6'
              }
            },
            showDetail: false,
            height: 35,
            selectedDataBackground: {
              areaStyle: {
                color: '#2480d6'
              }
            },
            fillerColor: 'rgba(255,255,255,0.50)'
          }
        ]
      : [],
    legend: { show: !slim, type: 'scroll' },
    animation: false,
    grid: {
      left: 50,
      top: 30,
      right: 10,
      bottom: slim ? 20 : 45
    },
    tooltip: {
      trigger: 'axis',
      confine: true,
      axisPointer: {
        type: 'cross'
      }
      //       formatter:
      //         "<div class='lpr-tooltip'><span class='axis'>{b0}</span><br/><span class='data'>{c0}</span><label>Occurences</label>"
    },

    yAxis: {
      // max: !chartData ? 200 : null,
      show: true,
      type: 'value'
    },
    xAxis: {
      axisLine: { show: !slim },
      axisTick: { show: !slim },
      axisLabel: { show: !slim, inside: true },
      type: 'time',
      interval: 8,
      splitLine: {
        show: true
      }
    },

    series:
      queries?.map((q, idx) => ({
        data:
          seriesType == 'line'
            ? q?.data?.map((i) => [i[0], i[3]]) // get the closing value not average
            : q?.data?.map((i) => [i[0], i[2], i[3], i[4], i[5]]),
        name: queryParams[idx]['topic'],
        itemStyle:
          seriesType == 'line'
            ? {}
            : {
                color0: '#d8fff1',
                color: '#fdebeb',
                borderColor: '#ea3737',
                borderColor0: '#00a96c'
              },
        showSymbol: false,
        emphasis: {
          disabled: true
        },
        type: seriesType,

        label: { show: false },
        markPoint: {
          data: [
            {
              name: 'max',
              symbol: 'pin',
              symbolSize: 20,
              label: {
                show: false
              },
              itemStyle: {
                color: 'green'
              },
              type: 'max'
            },
            {
              name: 'min',

              itemStyle: {
                color: 'red'
              },
              symbol: 'pin',
              symbolSize: 20,
              label: {
                show: false
              },

              type: 'min'
            }
          ]
        }
      })) ?? []
  };

  return (
    <ReactECharts
      css={css`
        .lpr-tooltip {
          display: flex;
          flex-direction: column;
          gap: 0.2rem;
          font-size: 12px;
          label {
            font-size: 11px;
          }
          .axis {
            font-weight: 500;
          }
          .data {
            font-size: 25px;
            font-weight: bold;
          }

          margin-top: 0.2rem;
          font-size: 12px;
        }
      `}
      notMerge={true}
      opts={{ renderer: 'svg' }}
      loadingOption={{
        showSpinner: true,
        textColor: '#000',
        maskColor: 'rgba(255, 255, 255, 0.8)',
        fontWeight: 'normal',
        text: '',
        spinnerRadius: 15,

        fontStyle: 'normal',
        lineWidth: 1,
        zlevel: 0,

        fontFamily: 'inherit'
      }}
      showLoading={isLoading || isRefetching}
      option={option}
      style={{
        width: '100%',
        padding: 0,
        height: '100%',
        background: 'var(--neutral-09)'
      }}
    />
  );
};
