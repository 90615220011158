import React from 'react';
import { useMutationWrapper } from 'utils/ajax';
import IOPort from 'api/ioport';
import Auxiliary from 'api/auxiliary';
import { useQueryClient } from 'react-query';
import { useHeadlessTable } from 'providers/headless-table-provider';
import { toast } from 'react-toastify';
import { css } from '@emotion/react';
import { dateTime } from 'utils/time';
/** @jsxImportSource @emotion/react */
export const useToggleMutate = (deviceId) => {
  return useMutationWrapper(
    ['ioport', 'toggle', deviceId],
    ({ deviceId, port }) => IOPort.toggle(deviceId, port)
  );
};

export const useTogglePortMutate = (aux) => {
  return useMutationWrapper(
    ['ioport', 'toggle', aux?.port_id],
    ({ portId, state }) => IOPort.togglePort({ portId, state })
  );
};

export const useTestMutate = (deviceId) => {
  return useMutationWrapper(['ioport', 'test', deviceId], (deviceId, port) =>
    IOPort.testOutput(deviceId, port)
  );
};

export const useOutputMutate = (aux) => {
  return useMutationWrapper(['ioport'], (port_id) => IOPort.output(port_id));
};

export const useMutateDelete = (aux) => {
  const queryClient = useQueryClient();
  const { queryKey } = useHeadlessTable();
  return useMutationWrapper(
    ['auxiliary', 'output'],
    (id) => Auxiliary.delete(id),
    {
      successMessage: `Auxiliary: ${aux?.name} was Deleted sucessfully`,
      onMutate: (id) => {
        let data = queryClient.getQueryData(queryKey);

        const newData = {
          ...data,
          data: data.data.filter((row) => row.id != id)
        };

        queryClient.setQueryData(queryKey, newData);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['auxiliaries', 'stats']);
      }
    }
  );
};

export const useGetStateMutate = (aux, options = {}) => {
  const toastRef = React.useRef();
  const loading = () =>
    (toastRef.current = toast.info('Fetching auxiliary state...', {
      autoClose: false,
      closeOnClick: false,
      closeButton: false,
      pauseOnHover: true,
      draggable: false
    }));

  const success = (data) =>
    toast.update(toastRef.current, {
      autoClose: 4000,
      className: 'custom-toast',
      type: '',
      closeButton: true,
      closeOnClick: true,
      render: (
        <div>
          {data.data}
          <time
            css={css`
              display: block;
              font-size: 11px;
            `}
          >
            as of {dateTime(new Date())}
          </time>
        </div>
      )
    });

  const error = (data) =>
    toast.update(toastRef.current, {
      type: toast.TYPE.ERROR,
      closeButton: true,
      closeOnClick: true,
      render: <>Error Fetching State</>
    });
  return useMutationWrapper(
    ['auxiliary', 'state', aux?.id],
    (id) => Auxiliary.getState(id),
    {
      noToast: true,
      onMutate: () => loading(),

      onSuccess: (data) => success(data),
      onError: (data) => error(data),
      ...options
    }
  );
};
