import { callAPI } from 'utils/ajax';

const DeviceMetrics = {
  search(input = {}) {
    return callAPI('GET', `/device_metrics`, input);
  },

  getTopics(input = null) {
    return callAPI('GET', `/device_metrics/getTopics`, input);
  },
  getTopicList(input = null) {
    return callAPI('GET', `/device_metrics/getTopicList`, input);
  },
  getAppCurrentVersion() {
    return callAPI('GET', `/device_metrics/getAppCurrentVersion`);
  },
  installMetricApp(deviceId) {
    return callAPI('POST', `/device_metrics/installMetricApp/` + deviceId);
  }
};

export default DeviceMetrics;
