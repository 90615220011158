import { useMutationWrapper } from "utils/ajax";

import { useQueryClient } from "react-query";
import { useHeadlessTable } from "providers/headless-table-provider";
import LicensePlate from "api/license_plate";

// export const useBarMutate = (person) => {
//   const queryClient = useQueryClient();
//   const { queryKey } = useHeadlessTable();

//   return useMutationWrapper(["person", "bar"], (id) => Person.bar(id), {
//     successMessage: `Operation on Person: ${person?.name} completed`,

//     onMutate: (id) => {
//       let data = queryClient.getQueryData(queryKey);

//       data.data.forEach(
//         (i) => i.id == id && (i.enabled = i.enabled == "0" ? "1" : "0")
//       );

//       queryClient.setQueryData(queryKey, data);
//     },
//     onSuccess: () => {
//       queryClient.invalidateQueries(["people", "stats"]);
//     },
//   });
// };

export const useDeleteMutate = (lp) => {
  const queryClient = useQueryClient();
  const { queryKey } = useHeadlessTable();
  return useMutationWrapper(
    ["lp", "delete"],
    (id) => LicensePlate.delete(id),

    {
      successMessage: `License Plate: ${lp?.name} deleted successfuly`,

      onMutate: (id) => {
        let data = queryClient.getQueryData(queryKey);

        const newData = {
          ...data,
          data: data.data.filter((row) => row.id != id),
        };

        queryClient.setQueryData(queryKey, newData);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["lp", "stats"]);
      },
    }
  );
};
