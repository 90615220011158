import React from 'react';
import {
  Fan,
  InfoCircleFill,
  Lightbulb,
  LightbulbOff,
  Question,
  VolumeMute,
  VolumeUp,
  Power,
  Info
} from 'react-bootstrap-icons';
import { CircleIcon } from 'components/circle-icon';
import { css } from '@emotion/react';
import { ReactComponent as RelayOn } from 'assets/images/relay-on.svg';
import { ReactComponent as RelayOff } from 'assets/images/relay-off.svg';
import { ReactComponent as SwitchOn } from 'assets/images/switch-on.svg';
import { ReactComponent as SwitchOff } from 'assets/images/switch-off.svg';
import { dateTime } from 'utils/time';
import { SecondaryButton } from 'components/buttons';
/** @jsxImportSource @emotion/react */
import { Tooltip } from 'components/tooltip';
import { useOutputMutate } from './actions';
import { useEventSource } from 'react-use-websocket';
import { SSE_HOST_URL } from 'api/api-url';
import { useLayout } from 'providers/layout-provider';
const AUX_ICONS = {
  generic: {
    1: RelayOn,
    0: RelayOff,
    active: RelayOn,
    inactive: RelayOff
  },
  light: {
    1: Lightbulb,
    0: LightbulbOff,
    active: Lightbulb,
    inactive: LightbulbOff
  },
  sound: {
    1: VolumeUp,
    0: VolumeMute,
    active: VolumeUp,
    inactive: VolumeMute
  },
  fan: {
    1: Fan,
    0: Fan, // todo fan off
    active: Fan,
    inactive: Fan // todo fan off
  },
  switch: {
    1: SwitchOn,
    0: SwitchOff,
    active: SwitchOn,
    inactive: SwitchOff
  }
};

const styles = (size) => css`
  svg {
    flex: 1;
    height: 80%;
    width: 80%;
  }
  &[data-online='0'] {
    filter: grayscale(1);
  }
  &[data-static='true'] {
    filter: grayscale(1);
  }

  &[data-alarm='true'] {
    // background: var(--red-v-02) !important;
  }
  &[data-state='active'] {
    background: var(--auxiliary-avatar-active-background);
    color: var(--auxiliary-avatar-active-color);
  }
  &[data-state='none'] {
    background: var(--neutral-05);
    color: var(--neutral-00);
  }
  &[data-state='idle'] {
    background: var(--auxiliary-avatar-idle-background);
    color: var(--auxiliary-avatar-idle-color);
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  width: ${size};
  height: ${size};
  padding: 4px;

  border: 5px solid var(--door-icon-border-color);
  box-shadow: 0px 2px 10px -2px rgba(29, 32, 35, 0.8);
  border-radius: 80px;
`;

const AuxiliaryStateAvatar = ({ aux, size = 40 }) => {
  const [state, setState] = React.useState(aux.state);
  const [alarmState, setAlarmState] = React.useState(aux.alarm_state);

  let filter = React.useCallback(
    (data) => {
      if (aux?.id == data.auxiliary_id) return true;
      return false;
    },
    [aux]
  );

  const { getSocketUrl } = useLayout();

  const { lastEvent } = useEventSource(getSocketUrl, {
    share: true,
    filter: (e) => filter(JSON.parse(e.data)),
    withCredentials: true,
    retryOnError: true
  });

  React.useEffect(() => {
    if (!lastEvent) return;

    let data = JSON.parse(lastEvent.data);

    if (data.type == 'ioPort') setState(data.data.state);
    if (data.type == 'ioTriggerRelay') setState(data.data.LogicalState);
    else if (data.type == 'ioOpenTooLong') setAlarmState('openTooLong');
    else if (data.type == 'ioNormal') setAlarmState('normal');
  }, [lastEvent]);

  const Icon =
    aux.online == '0'
      ? Power
      : AUX_ICONS?.[aux?.category ?? 'generic']?.[state] || Question;

  return (
    <article
      css={styles(size)}
      className="auxiliary-avatar"
      data-online={aux.online}
      data-static={aux.device_product == 'A1001' && aux.Direction == 'output'}
      data-state={
        !state ? 'none' : state == 0 || state == 'inactive' ? 'idle' : 'active'
      }
      data-alarm={alarmState == 'OpenTooLong' ? 'true' : 'false'}
    >
      {aux.device_product == 'A1001' && aux.Direction == 'output' ? (
        <Question title="A1001 doesn't offer Auxiliaty updates" />
      ) : (
        <Icon />
      )}

      {alarmState == 'openTooLong' && (
        <InfoCircleFill
          css={css`
            fill: var(--red-base);
            height: 20px !important;
            width: 20px !important;
            left: -8px;
            top: -8px;
            position: absolute;
          `}
        />
      )}
    </article>
  );
};

export default AuxiliaryStateAvatar;
