import React from 'react';
import { Mic, MicFill, MicMute } from 'react-bootstrap-icons';
import classNames from 'classnames';
var mediaRecorder, websocket;
import { css } from '@emotion/react';
import Aux from 'api/auxiliary';
import { AuxiliaryButton, IconButton } from 'components/buttons';
/** @jsxImportSource @emotion/react */
import useAudioRecorder from 'hooks/audio-recorder';
//https://www.webrtc-developers.com/how-to-know-if-my-microphone-works/
const micStyles = (isMediaActive) => css`
  color: ${isMediaActive ? 'blue' : 'black'};
`;
const TransmitAudio = ({ intercom_id }) => {
  const {
    startRecording,
    stopRecording,
    togglePauseResume,
    recordingBlob,
    isRecording,
    isPaused,
    recordingTime
  } = useAudioRecorder({ intercom_id });

  let stopTimeoutId = React.useRef();
  let startTimeoutId = React.useRef();

  const isInButton = React.useRef();

  const handleStartRecording = () => {
    clearTimeout(startTimeoutId.current);
    clearTimeout(stopTimeoutId.current);
    startRecording();
    startTimeoutId.current = setTimeout(() => {
      stopRecording();
    }, 15000);
  };

  const handleStopRecording = () => {
    isInButton.current = false;
    clearTimeout(stopTimeoutId.current);
    clearTimeout(startTimeoutId.current);
    stopTimeoutId.current = setTimeout(() => {
      stopRecording();
    }, 1000);
  };

  // React.useEffect(() => {
  //   document.addEventListener('pointerup', handleStopRecording);

  //   return () => document.removeEventListener('pointerup', handleStopRecording);
  // }, []);

  return (
    <article
      className="transmit-audio"
      css={css`
        color: white;
      `}
    >
      <button
        onClick={() => {
          if (!isRecording) handleStartRecording();
          else handleStopRecording();
        }}
      >
        {isRecording ? (
          <Mic title="Disable Microphone" />
        ) : (
          <MicMute title="Enable microphone" />
        )}
      </button>
    </article>
  );
};

export default TransmitAudio;
