import React from 'react';
import { useAuth } from 'providers/auth';

const IsUserAllowed = ({ children, role, condition, restrict }) => {
  const { user } = useAuth();
  const userPerm = user.permission;

  // add this if want to except array
  // (Array.isArray(condition) &&
  // condition?.every((item) => userPerm?.includes(item)));

  let ok =
    userPerm?.includes?.('fa') ||
    (restrict == 'C' && user.managing) ||
    (restrict == 'I' && user.accessing) ||
    userPerm?.includes?.(condition) ||
    (Array.isArray(condition) &&
      condition?.every((item) => userPerm?.includes(item)));

  return ok ? <>{children}</> : null;
};

export default IsUserAllowed;
