import React from "react";
import { css } from "@emotion/react";
/** @jsxImportSource @emotion/react */
import "css/bar-chart.css";

const BarChartLoader = () => {
  return (
    <div class="loader-container chart-loader">
      <div class="loader-chart">
        <div class="loader-horizontal"></div>
        <div class="loader-vertical"></div>

        <div class="loader-line1"></div>
        <div class="loader-line2"></div>
        <div class="loader-line3"></div>
        <div class="loader-line4"></div>
      </div>
    </div>
  );
};

export default BarChartLoader;
