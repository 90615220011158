import React from 'react';
import { Form } from 'components/dom';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
const FormContext = React.createContext();

export const getFieldErrorNames = (formikErrors) => {
  // not used
  const transformObjectToDotNotation = (obj, prefix = '', result = []) => {
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (!value) return;

      const nextKey = prefix ? `${prefix}.${key}` : key;
      if (typeof value === 'object') {
        transformObjectToDotNotation(value, nextKey, result);
      } else {
        result.push(nextKey);
      }
    });

    return result;
  };

  return transformObjectToDotNotation(formikErrors);
};

export const ScrollToFieldError = ({
  scrollBehavior = { behavior: 'smooth', block: 'center' },
  autoFocus
}) => {
  const { submitCount, isValid, errors } = useFormikContext();

  React.useEffect(() => {
    if (autoFocus === false) return;
    var firstInput = document.querySelector('input[type="text"]');
    firstInput && firstInput.focus();
  }, []);

  React.useEffect(() => {
    if (isValid) return;

    //  const fieldErrorNames = getFieldErrorNames(errors);
    //  if (fieldErrorNames.length <= 0) return;

    //const element = document.querySelector(
    // `input[name='${fieldErrorNames[0]}']`
    //);

    //serach for the first error-message class

    const element = document.querySelector('.error-message');

    if (!element) return;

    // Scroll to first known error into view
    element.scrollIntoView(scrollBehavior);

    // Formik doesn't (yet) provide a callback for a client-failed submission,
    // thus why this is implemented through a hook that listens to changes on
    // the submit count.
  }, [submitCount]);
  return null;
};

const FormProvider = ({ children, ...props }) => {
  let formRef = React.useRef();
  const [formData, setFormData] = React.useState({});
  let history = useHistory();

  const submitForm = (e) => {
    e.preventDefault();

    /** const elems = e.target.elements;
     for (var i = 0; i < elems.length; i++) {

   //   console.log(getValidationMessage(elems[i]), elems[i])


  }

     //custom validation
     let invalids = e.target.querySelectorAll( ":invalid" )

     let g = e.target.querySelectorAll("input[type=hidden");
     console.log(g)
     for ( var i = 0; i < invalids.length; i++ ) {
            console.log('VALIDATION', invalids[i], invalids[i].name, invalids[ i ].validationMessage);
        }
    // remove noValidat in form if not needed**/

    let data = Object.fromEntries(new FormData(e.target).entries());
    /**  EXPERIMET TO READ FORM DATA WITH HIDDENS ARRASY VLAUES **/
    let obj = {};
    /**  let  newData = new FormData(e.target);
     for (var key of newData.keys()) {
           obj[key] = newData.getAll(key)
           if(obj[key].length == 1 )
              obj[key] = obj[key][0]
     }

     console.log('OBJ1',obj) **/

    obj = {};

    for (let el of e.target.elements) {
      if (!el.name || el.disabled) continue;
      let v = el.value;

      if (typeof obj[el.name] == 'undefined' && el.dataset.type != 'array')
        obj[el.name] = v;
      //always keep array if dataset is array
      else if (typeof obj[el.name] == 'undefined' && el.dataset.type == 'array')
        obj[el.name] = [v];
      else if (typeof obj[el.name] == 'object') obj[el.name].push(v);
      else obj[el.name] = [obj[el.name], v];
    }

    //new Data now includes hidden values with the same name

    /** END **/

    let finalData = Object.assign({}, obj, formData);

    if (typeof props.transformData == 'function')
      finalData = props.transformData(finalData);

    //cdo not submit if transform data return falls

    if (!finalData) return;

    if (typeof props.mutateFunc == 'function')
      props.mutateFunc(finalData, {
        onSuccess: (data) => {
          if (typeof props.onSuccesssfunc == 'function') onSuccessFunc(data);
        }
      });
  };

  return (
    <FormContext.Provider value={{ formRef, setFormData, formData }}>
      <ScrollToFieldError autoFocus={props.autoFocus} />
      <Form
        ref={formRef}
        name={props.name}
        onSubmit={submitForm}
        disabled={props.disabled}
      >
        {children}
      </Form>
    </FormContext.Provider>
  );
};

export const useForm = () => {
  return React.useContext(FormContext);
};

export default FormProvider;

FormProvider.propTypes = {
  mutateFunc: PropTypes.func
};
