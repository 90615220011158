import React from 'react';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */

const styles = css`
  align-items: center;
  padding: 4px 8px;

  position: static;
  font-weight: 600;
  letter-spacing: 0.81;
  font-size: 12px;

  text-align: left;

  max-width: 200px;
  width: fit-content;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  color: var(neutral-00);
  text-transform: capitalize;

  background: var(--label-background-color);
  color: var(--label-color);
  border-radius: 4px;
`;
const Label = ({ children, ...props }) => {
  return (
    <span
      role="label"
      css={styles}
      {...props}
      title={typeof children !== 'string' && children}
    >
      {children}
    </span>
  );
};

export default Label;
