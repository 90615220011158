import React from 'react';
import { useQueryWrapper } from 'utils/ajax';
import Metric from 'api/metric';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { css } from '@emotion/react';
import ReactECharts from 'echarts-for-react';
import { averageWithoutZero } from 'utils/arrays';
import chroma from 'chroma-js';

/** @jsxImportSource @emotion/react */

export default (props) => {
  let { camera } = props;
  const { data, isLoading } = useQueryWrapper(
    ['camera', camera.id, 'avgConfidence', props.filter],
    () =>
      Metric.count({
        camera_id: camera.id,
        lpr: true,
        from: props.filter?.from,
        to: props.filter?.to,
        groupBy: '$date',
        averageBy: '$confidence'
      })
  );

  let avg = averageWithoutZero(data?.map((d) => d.avg) ?? []).toFixed(2) * 100;

  const colorScale = chroma
    .scale(['#ea3737', '#f59728', '#00a96c'])
    .domain([1, 50, 100])
    .mode('lab');
  const color = (avg) => {
    let c = colorScale(avg);
    return c.css();
  };

  return (
    <article
      css={css`
        height: 100%;
        width: 100%;
        display: block;
        margin-bottom: 0.5rem;
        align-items: center;
        justify-content: center;
        font-size: 64px;
        font-weight: 600;
        color: ${color(avg)};
        padding: 1rem;
        margin: auto;
        text-align: center;
      `}
    >
      {isLoading && <Skeleton baseColor="var(--skeleton-loading-main-color)" />}
      {data?.length > 0 && <span>{avg}%</span>}
    </article>
  );
};
