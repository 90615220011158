import React from 'react';

import SelectWrapper from 'components/headless-select';
/** @jsxImportSource @emotion/react **/
import useFormValues from 'hooks/form-values-hook';
import { css } from '@emotion/react';
import { setFluidGetter } from '@react-spring/shared';
import { components } from 'react-select';
import { ArrowDownCircle, BoxArrowInRight } from 'react-bootstrap-icons';

let IOPORTS = [
  {
    direction: 'input',
    trigger: 'open',
    value: 'input_open',
    label: 'Input; Trigger on Circuit open'
  },
  {
    direction: 'input',
    trigger: 'closed',
    value: 'input_closed',
    label: 'Input; Trigger on circuit closed'
  },
  {
    direction: 'output',
    trigger: 'open',
    value: 'output_open',
    label: 'Output; Activate on circuit open'
  },
  {
    direction: 'input',
    trigger: 'closed',
    value: 'output_closed',
    label: 'Output; Activate on circuit closed'
  }
];

const RELAYPORTS = [
  {
    direction: 'output',
    trigger: 'open',
    value: 'output_open',
    label: 'Output; Activate on circuit open'
  },
  {
    direction: 'input',
    trigger: 'closed',
    value: 'output_closed',
    label: 'Output; Activate on circuit closed'
  }
];

const DirectionSelect = ({
  field = {},
  form = {},

  ...props
}) => {
  let options = props.relay ? RELAYPORTS : IOPORTS;

  return (
    <SelectWrapper
      title="Direction"
      simple
      idx="value"
      label="label"
      menuPosition="fixed"
      menuShouldBlockScroll={true}
      menuPlacement="top"
      value={options.find((i) => i.value == form.values?.[props.port]?.data)}
      onChange={(e) => form.setFieldValue(`${field.name}`, e?.value)}
      closeMenuOnSelect={true}
      isSearchable={false}
      isClearable={false}
      options={options}
      isMulti={false}
      formProps={{ ...form, ...field }}
      data-compact
      {...props}
    />
  );
};

export default DirectionSelect;
