import { callAPI } from 'utils/ajax';

const User = {
  myself() {
    return callAPI('GET', `/user/myself`);
  },

  search(input = {}) {
    return callAPI('GET', `/user`, input);
  },
  get(id) {
    return callAPI('GET', `/user/${id}`);
  },

  stats(input) {
    return callAPI('GET', `/user/stats`, input);
  },

  save(input = {}, id = '') {
    return callAPI(id ? 'PUT' : 'POST', `/user/${id}`, input);
  },

  delete(id) {
    return callAPI('DELETE', `/user/${id}`);
  },

  impersonate(id) {
    return callAPI('POST', `/user/impersonate/` + id);
  },

  stopImpersonation() {
    return callAPI('POST', '/user/stopImpersonation');
  },

  sendActivationEmail(id) {
    return callAPI('POST', `/user/activationEmail/${id}`);
  },

  activate(id) {
    return callAPI('POST', `/user/activate/${id}`);
  },

  endSession(id) {
    return callAPI('POST', `/user/endSession/${id}`);
  },

  enable(id) {
    return callAPI('POST', `/user/enable/${id}`);
  }
};

export default User;
