import React from 'react';
import MetricEvent from 'api/metric';
import { useQuery } from 'react-query';
import ChartCard from 'components/chart';
import { useAuth } from 'providers/auth';
import Label from 'components/label';
import ReactECharts from 'echarts-for-react';
import { css } from '@emotion/react';
import Spinner from 'components/spinner';
/** @jsxImportSource @emotion/react */

const DayOfWeekChart = (props) => {
  const [chartData, setChartData] = React.useState([]);
  const COLORS = ['#6F49C0', 'F5861F', '#49B7BE', '#FFCC47', '#2480D6'];
  const DAYS = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FR', 'SAT'];

  const { focusedSite } = useAuth();

  const { isLoading, data } = useQuery(
    ['day-of-week', focusedSite],
    () =>
      MetricEvent.count({
        since: '-7 day',
        type: 'accessGranted',
        groupBy: '$dayOfWeek',
        site_id: focusedSite
      }),
    {
      staleTime: 4 * (60 * 1000),
      cacheTime: 6 * (60 * 1000)
    }
  );

  React.useEffect(() => {
    if (!data) return;

    setChartData(
      data
        ?.map((i) => ({
          day: i._id,
          value: i.count
        }))
        .sort((a, b) => a.day - b.day)
    );
  }, [data]);

  const option = {
    animation: false,
    grid: {
      left: '10%',
      right: '10%',
      bottom: '20%',
      top: '10%'
    },
    tooltip: {
      trigger: 'item'
    },

    legend: {
      orient: 'horizontal',
      left: 10,
      padding: 5,
      bottom: 0,
      show: false
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: false
      },
      axisLabel: {
        show: false
      },
      axisLine: {
        show: false,
        lineStyle: {
          color: '#D3D3D3'
        }
      },
      splitLine: {
        show: true,
        interval: 8,
        lineStyle: {
          type: 'dashed'
        }
      }
    },
    xAxis: {
      type: 'category',
      data: chartData?.map((d) => DAYS[d.day - 1]),
      axisLabel: {
        show: true
      },
      axisLine: {
        lineStyle: {
          color: '#D3D3D3'
        }
      }
    },

    series: [
      {
        data: chartData,
        smooth: 0.2,
        type: 'line',
        areaStyle: {},
        lineStyle: {
          color: '#5470C6',
          width: 5
        },

        label: { show: false }
      }
    ],
    ...props.config
  };

  return (
    <section
      css={css`
        height: 100%;
        width: 100%;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          height: 100%;
        `}
      >
        <ReactECharts
          opts={{ renderer: 'svg' }}
          loadingOption={{
            showSpinner: false,
            maskColor: 'var(--neutral-09)',
            fontWeight: 'normal',
            text: 'Fetching Data...',

            fontStyle: 'normal',

            fontFamily: 'inherit'
          }}
          showLoading={isLoading}
          option={option}
          style={{
            width: '100%',
            padding: 0,
            height: '100%',
            background: 'var(--neutral-09)'
          }}
        />
      </div>
    </section>
  );
};

export default DayOfWeekChart;
