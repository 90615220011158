import { IconCloudDown } from '@tabler/icons-react';
import { PopoverContent } from 'components/popover';

import {
  Popover,
  PopoverPortal,
  PopoverTrigger
} from 'components/popover/popover-content';
import React from 'react';
/** @jsxImportSource @emotion/react */
import { Button, CancelButton } from 'components/buttons';
import { css } from '@emotion/react';
import { styles } from 'components/radio-group';
import { PopoverClose } from '@radix-ui/react-popover';
import { Formik, Field, Form } from 'formik';
import { useMutationWrapper } from 'utils/ajax';
import RecordingExports from 'api/recording-export';
import { InputLabel } from 'components/form';
import CameraAction from 'api/camera_action';
import { useQueryWrapper } from 'utils/ajax';
{
  /* <button
  title="Export"
  css={css`
    border-left: 1px solid var(--neutral-03);
  `}
  onClick={(e) => {
    onStop(e);
    setExportMode(true);

    chartRef.current.getEchartsInstance().dispatchAction({
      type: 'takeGlobalCursor',
      key: 'brush',
      brushOption: {
        throttleType: 'debounce',
        throttleDelay: 1000,
        range: [2 * 3600 * 1000, 4 * 3600 * 1000],
        xAxisIndex: 'all',
        brushType: 'lineX',
        brushMode: 'single',
        removeOnClick: true,
        brushStyle: {
          borderWidth: 8,
          color: 'rgba(120,140,180,0.3)',
          borderColor: 'rgba(120,140,180,0.8)'
        }
      }
    });
  }}
>
  <IconCloud size={20} />
</button>; */
}

export default ({
  chartRef,
  onStop,
  setExportMode,
  exportTime,
  setExportTime,
  exportMode,
  onPlay,
  host
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    onStop(e);
    setExportMode(true);
    setIsOpen(true);

    chartRef.current.getEchartsInstance().dispatchAction({
      type: 'takeGlobalCursor',
      key: 'brush',
      brushOption: {
        throttleType: 'debounce',
        throttleDelay: 1000,
        range: [2 * 3600 * 1000, 4 * 3600 * 1000],
        xAxisIndex: 'all',
        brushType: 'lineX',
        brushMode: 'single',
        removeOnClick: true,
        brushStyle: {
          borderWidth: 8,
          color: 'rgba(120,140,180,0.3)',
          borderColor: 'rgba(120,140,180,0.8)'
        }
      }
    });
  };

  function formatDateToDateTimeLocalString(date) {
    if (!date) return '';
    const year = date.getFullYear().toString().padStart(4, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  const [initialValues, setInitialValues] = React.useState({});

  const { mutate, isSuccess, isLoading } = useMutationWrapper(
    ['export-recording'],
    (input) => RecordingExports.add(input),
    {
      onSuccess: () => onPlay()
    }
  );

  const { data: disks } = useQueryWrapper(
    ['camera', 'disks', host?.id],
    () => CameraAction.listDisks(host.id),
    {
      enabled: Boolean(host.online == 1),
      staleTime: 120000,
      noToast: true
    }
  );

  let isEdge = disks?.find((i) => i.diskid == 'SD_DISK').status == 'OK';

  React.useEffect(() => {
    if (exportTime)
      setInitialValues({
        start: exportTime.start,
        end: exportTime.end,
        type: isEdge ? 'edge' : 'cloud'
      });
  }, [exportTime]);

  return (
    <Popover open={isOpen && exportMode} onOpenChange={setIsOpen}>
      <PopoverTrigger
        title="Export Recording"
        onClick={handleClick}
        css={css`
          margin-inline: 0.5rem;
          border: 1px solid var(--neutral-03);
          border-radius: 4px;
          height: 100%;
          padding: 0.3rem;
        `}
      >
        <IconCloudDown />
      </PopoverTrigger>
      <PopoverContent
        onPointerDownOutside={(e) => e.preventDefault()}
        onEscapeKeyDown={(e) => e.preventDefault()}
        onInteractOutside={(e) => e.preventDefault()}
        side="top"
        css={css`
          width: 275px;
          padding: 1rem;
          [role='group'] {
            display: flex;
            flex-direction: row;
            margin-bottom: 4px;
          }
          button {
            margin-top: 4px;
          }
          button:first-of-type {
            margin-bottom: 4px;
          }
          form {
            margin-bottom: 0px !important;
          }
          input[type='datetime-local'] {
            appearance: none;
            border: 1px solid var(--light--neutrals--500);
          }
        `}
      >
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={(values) => {
            mutate({
              name: `${host.name}-${new Date(
                values.start
              ).toISOString()}-${new Date(values.end).toISOString()}`,
              type: values.type,
              startTime: new Date(values.start).toISOString(),
              stopTime: new Date(values.end).toISOString(),
              cameraId: host.id
            });
          }}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <article
                css={css`
                  display: flex;
                  flex-direction: column;

                  input {
                    height: 36px;
                    margin-bottom: 0.5rem;
                  }
                `}
              >
                <label
                  css={css`
                    font-size: 12px;
                    opacity: 50%;
                    margin-bottom: 4px;
                  `}
                >
                  Pick export interval below or use the timeline brush. <br />
                  (6 hours max)
                </label>
                <InputLabel>From</InputLabel>
                <input
                  type="datetime-local"
                  value={formatDateToDateTimeLocalString(
                    values?.start ? new Date(values?.start) : ''
                  )}
                  onChange={(e) => {
                    setFieldValue(
                      'start',
                      e.target.value
                        ? new Date(e.target.value).toISOString()
                        : ''
                    );
                    setExportTime((prev) => ({
                      ...prev,
                      start: new Date(e.target.value).getTime()
                    }));
                    chartRef.current.getEchartsInstance().dispatchAction({
                      type: 'brush',
                      command: 'clear',
                      areas: []
                    });
                  }}
                />
                <InputLabel>To</InputLabel>
                <input
                  type="datetime-local"
                  value={formatDateToDateTimeLocalString(
                    values?.end ? new Date(values?.end) : ''
                  )}
                  onChange={(e) => {
                    setFieldValue(
                      'end',
                      e.target.value
                        ? new Date(e.target.value).toISOString()
                        : ''
                    );
                    setExportTime((prev) => ({
                      ...prev,
                      end: new Date(e.target.value).getTime()
                    }));

                    chartRef.current.getEchartsInstance().dispatchAction({
                      type: 'brush',
                      command: 'clear',
                      areas: []
                    });
                    {
                      /* chartRef.current.getEchartsInstance().dispatchAction({
                  type: 'brush',
                  areas: [
                    {
                      brushType: 'rect', // Brush type is a rectangle
                      coordRange: [
                        exportTime.start,
                        new Date(e.target.value).getTime()
                      ], // Set the range of the brush
                      xAxisIndex: 0 // Set the index of the x-axis
                    }
                  ]
                }); */
                    }
                  }}
                />
                <div role="group" css={styles}>
                  <Field
                    type="radio"
                    name="type"
                    value="edge"
                    id="edge"
                    disabled={!isEdge}
                  />

                  <label for="edge">Edge</label>

                  <Field type="radio" name="type" value="cloud" id="cloud" />
                  <label for="cloud">Cloud</label>
                </div>
                <Button
                  loading={isLoading}
                  type="submit"
                  compact
                  disabled={!values.start || !values.end || isLoading}
                >
                  Export
                </Button>
                <PopoverClose asChild>
                  <CancelButton compact onClick={() => onPlay()}>
                    Close
                  </CancelButton>
                </PopoverClose>
              </article>
            </Form>
          )}
        </Formik>
      </PopoverContent>
    </Popover>
  );
};
