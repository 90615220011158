import React from 'react';
import GridControls from './grid-controls';

import styled from '@emotion/styled';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Container } from './container';
import ContainerHeader from './container-header';
import EmptyHolder from './empty-holder';
import AddItemDialog from './add-item-dialog';
import { IconVideoPlus } from '@tabler/icons-react';
import CameraWidget from './camera-widget';

export default ({ selectedView, isEdit, setSelectedView }) => {
  let widgetContainer = selectedView.layout.find(
    (item) => item.type == 'cameras'
  );

  let index = selectedView.layout.findIndex((item) => item.type == 'cameras');

  if (!widgetContainer) return null;
  else
    return (
      <Container isEdit={isEdit}>
        <ContainerHeader
          isEdit={isEdit}
          index={index}
          setSelectedView={setSelectedView}
          selectedView={selectedView}
        >
          <GridControls
            selectedView={selectedView}
            setSelectedView={setSelectedView}
            index={index}
          />
        </ContainerHeader>
        <div
          css={css`
            overflow: auto;
            height: 100%;
            display: grid;
            gap: 0.5rem;
            grid-template-rows: ${`repeat(${widgetContainer?.rows || 1}, 1fr)`};
            grid-template-columns: ${`repeat(${
              widgetContainer?.cols || 1
            }, 1fr)`};
          `}
        >
          {widgetContainer?.items?.map((item, idx) => (
            <div
              key={idx}
              css={css`
                grid-row: ${`span ${item.rows ?? 1}`};
                grid-column: ${`span ${item.cols ?? 1}`};
              `}
            >
              <CameraWidget
                cameraId={item.id}
                isEdit={isEdit}
                setSelectedView={setSelectedView}
                selectedView={selectedView}
                index={index}
                widgetIndex={idx}
              />
            </div>
          ))}

          {isEdit && (
            <AddItemDialog
              selectedView={selectedView}
              setSelectedView={setSelectedView}
              index={index}
            >
              <EmptyHolder>
                <IconVideoPlus />
                <span>Add Camera</span>
              </EmptyHolder>
            </AddItemDialog>
          )}
        </div>
      </Container>
    );
};
