import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import React from 'react';
import { Badge } from 'components/badge';
import { getSelectionColumn } from 'components/table-selection-column';
import { CheckLg, QuestionCircle } from 'react-bootstrap-icons';

import { QuestionCircleFill } from 'react-bootstrap-icons';
import TimeFormatter from 'components/time-formatter';
import * as Tooltip from '@radix-ui/react-tooltip';

function AdvancedColumnHeader() {
  return (
    <Tooltip.Provider>
      <Tooltip.Root>
        <Tooltip.Trigger asChild={true}>
          <div
            css={css`
              display: inline-flex;
              gap: 0.5rem;
            `}
          >
            Advanced
            <QuestionCircle size={16} />
          </div>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content asChild={true}>
            <div
              css={css`
                z-index: 200;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 0px;
                isolation: isolate;
                height: 100%;
                position: relative;
                padding: 0.5rem;
                background: var(--neutral-09);
                box-shadow: 0px 2px 6px 4px rgba(29, 32, 35, 0.4);
                border-radius: 4px;
              `}
            >
              Advanced Schedules only apply to controllers and relays
              <Tooltip.Arrow
                css={css`
                  fill: var(--neutral-09);
                `}
              />
            </div>
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}

function DoorUsageHeader() {
  return (
    <Tooltip.Provider>
      <Tooltip.Root>
        <Tooltip.Trigger asChild={true}>
          <div
            css={css`
              display: inline-flex;
              gap: 0.5rem;
            `}
          >
            Door Usage
            <QuestionCircle size={16} />
          </div>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content asChild={true}>
            <div
              css={css`
                z-index: 200;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 0px;
                isolation: isolate;
                height: 100%;
                position: relative;
                padding: 0.5rem;

                background: var(--neutral-09);
                box-shadow: 0px 2px 6px 4px rgba(29, 32, 35, 0.4);
                border-radius: 4px;
              `}
            >
              Doors using this schedule as an open time
              <Tooltip.Arrow
                css={css`
                  fill: var(--neutral-09);
                `}
              />
            </div>
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}

export const columns = [
  getSelectionColumn(),
  {
    Header: 'Name',
    accessor: 'Name',
    style: { width: '220px' },
    pickerDisabled: true,
    asc: 'atoz',
    desc: 'ztoa',
    newSorter: true,
    Cell: ({ row }) => (
      // Use Cell to render an expander for each row.
      // We can use the getToggleRowExpandedProps prop-getter
      // to build the expander.
      <div
        css={css`
          display: flex;
          align-items: end;
          gap: 0.5rem;
        `}
      >
        <span
          css={css`
            position: relative;
            display: flex;
            flex-direction: column;
            gap: 5px;
            span:last-child {
              color: #7f8795;
            }
          `}
        >
          <span>{row.original.Name}</span>
          <span>{row.original.Description}</span>
        </span>
        {row.original.system_default == 1 && (
          <Badge color="gray">DEFAULT</Badge>
        )}
      </div>
    )
  },
  {
    Header: 'State',
    accessor: 'state',
    disableSortBy: true,
    Cell: ({ cell: { value } }) => {
      const [color, label] =
        value ? ['success', 'active'] : ['danger', 'idle'];
      return <Badge color={color}>{label}</Badge>;
    }
  },
  {
    Header: () => <AdvancedColumnHeader />,
    label: 'Advanced',
    accessor: 'advanced',
    disableSortBy: true,
    Cell: ({ cell: { value } }) => {
      return <span>{value && <CheckLg size={24} color="green" />}</span>;
    }
  },
  {
    Header: 'Next Occurance',
    accessor: 'next_occurance',
    disableSortBy: true,
    id: 'next_occurance',
    Cell: ({ cell: { value } }) => {
      return value ? <TimeFormatter dt={new Date(value)} /> : null;
    }
  },
  {
    Header: () => <DoorUsageHeader />,
    label: 'Door Usage',
    accessor: 'door_schedule_count',
    disableSortBy: true,
    id: 'door_schedule_count'
  }
];
