import React from 'react';
import { InputLabel, InputHelper, Input } from 'components/form';
import { ErrorMessage } from 'components/form';

const getNameFromLabel = (label) =>
  label ? label.toLowerCase().replace(/\ /g, '_') : '';

const NumberField = React.forwardRef((props, ref) => {
  const name = props.name || getNameFromLabel(props.label);
  ref = ref || React.useRef();

  const tfProps = {
    'data-compact': props['data-compact']
  };

  return (
    <div className={'field number-field ' + props.className} {...tfProps}>
      <InputLabel {...props} />
      <InputHelper {...props} />
      <Input
        type="number"
        placeholder={props.placeholder || ''}
        ref={ref}
        name={name}
        {...props}
        onWheel={(event) => {
          event.target.blur();
        }}
      />
      <ErrorMessage name={name} {...props} />
    </div>
  );
});

export default NumberField;
