import React from 'react';
import Template from 'base/template';
import { Button, CancelButton, SecondaryButton } from 'components/buttons';
import Checkbox from 'components/form/checkbox';
import Door from 'api/door';
import FormProvider from 'providers/form-provider';
import { useMutationWrapper } from 'utils/ajax';
import { useRedirect } from 'hooks/redirect-hook';
import { Redirect } from 'react-router-dom';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';

import { NumberField } from 'components/form';

import { css } from '@emotion/react';

import AsyncSelect from 'components/headless-select/async';
import Schedule from 'api/schedule';
/** @jsxImportSource @emotion/react */
import Alert from 'components/alert';

const styles = css`
  display: flex;
  flex-direction: column;
  width: 30vw;
  gap: 16px;
  .number-field {
    width: 50%;
  }
`;
const DoorOpenSchedule = ({ door }) => {
  const SCHEMA = Yup.object().shape({
    snowday_early_minutes: Yup.number()
      .min(0, 'Must be between 0 and 60')
      .max(60, 'Must be between 0 and 60')
  });

  const INITIAL_VALUES = {
    ...door,
    schedule: door ? JSON.parse(door.open_schedule_token) : null,
    snowday_early_minutes: door.snowday_early_minutes || 0,
    snow_day_rule: door?.snowday == '1' ? 'on' : ''
  };

  const { isLoading, isSuccess, mutate } = useMutationWrapper(
    ['door', 'schedule'],
    (input) => Door.setDoorSchedule(input, door?.door_token)
  );

  const { redirect, setRedirect } = useRedirect(isSuccess);

  return (
    <Formik
      validationSchema={SCHEMA}
      initialValues={INITIAL_VALUES}
      onSubmit={(values) => {
        mutate(values);
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => (
        <FormProvider mutateFunc={handleSubmit} disabled={isLoading}>
          {redirect && <Redirect to="/doors" />}
          <article css={styles}>
            <p>
              Set up your door to stay open during the schedule below.
              <br /> Maximum of 5 schedules can be selected.
            </p>
            <Field
              isOptionDisabled={(e) => values.schedule?.length > 4}
              name="schedule"
              label="Name"
              idx="token"
              fetchFn={Schedule.search}
              component={AsyncSelect}
              isMulti={true}
            />

            <p>
              First person in (snow day rule will keep the door locked until the
              first swipe is presented at the reader).
              <b
                css={css`
                  margin-top: 4px;
                  display: block;
                `}
              >
                This feature is not available when the door have multiple open
                schedules.
              </b>
            </p>

            <Alert>
              Applying snow day rule if any of the schedules above is active
              will cause the door to stay open indefinetly. Please lock manually
              OR apply Snow day rule when schedule is not active.
            </Alert>

            <Checkbox
              name="snow_day_rule"
              label="First Person In (Snow Day Rule)"
              checked={values.snow_day_rule}
              onChange={(e) =>
                setFieldValue('snow_day_rule', e.target.checked ? 'on' : '')
              }
            />
            <NumberField
              value={values.snowday_early_minutes}
              name="snowday_early_minutes"
              label="Snow Day Early Minutes"
              disabled={!values.snow_day_rule}
              helper="Swiping within this time frame prior to the schedule will activate it at the designated time "
              min="0"
              max="60"
            />
          </article>

          <footer>
            <Button type="submit">Submit</Button>
            <CancelButton onClick={() => setRedirect(true)} />
          </footer>
        </FormProvider>
      )}
    </Formik>
  );
};

export default DoorOpenSchedule;
