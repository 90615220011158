import React from "react";

const GlobalStateContext = React.createContext();

const GlobalStateProvider = ({ children }) => {
  const [user, setUser] = React.useState();
  const [pageLoader, setShowPageLoader] = React.useState(false);
  const [appGlobalState, setAppGlobalState] = React.useState();

  return (
    <GlobalStateContext.Provider
      value={{
        user,
        setUser,
        pageLoader,
        setShowPageLoader,
        appGlobalState,
        setAppGlobalState,
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
};

export const useGlobalState = () => {
  return React.useContext(GlobalStateContext);
};

export default GlobalStateProvider;
