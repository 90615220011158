import React from 'react';
import MetricEvent from 'api/metric';
import { useQuery } from 'react-query';
import { useAuth } from 'providers/auth';
import { css } from '@emotion/react';
import ChartLegend from './chart-legend';
/** @jsxImportSource @emotion/react */
import ActiveChartCore from 'components/charts/active-chart-core';
import { useQueryWrapper } from 'utils/ajax';
import GenericDoor from 'api/generic_door';
const ActiveDoorChart = (props) => {
  const { focusedSite } = useAuth();

  const [chartData, setChartData] = React.useState([]);

  // const { isLoading, data } = useQuery(
  //   ['doorUsage', focusedSite],
  //   () =>
  //     MetricEvent.count({
  //       since: '-7 day',
  //       type: 'accessGranted',
  //       site_id: focusedSite,
  //       groupBy: '$door_token',
  //       limit: 5
  //     }),
  //   {
  //     staleTime: 4 * (60 * 1000),
  //     cacheTime: 6 * (60 * 1000)
  //   }
  // );

  const { data, isLoading } = useQueryWrapper(
    ['door', 'most-active', focusedSite],
    () =>
      GenericDoor.search({
        slim: true,
        order: 'activity_total DESC',
        limit: 5,
        site: focusedSite
      }),
    {
      select: (data) => ({
        ...data,
        data: data?.data?.slice(0, 5) // only get 5 itesm till limit is implementes
      })
    }
  );

  React.useEffect(() => {
    if (!data?.data) return;

    setChartData(
      data?.data.map((i) => ({ name: i.name, value: i.activity_total }))
    );
  }, [data]);

  return (
    <section
      css={css`
        height: 100%;
        width: 100%;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          height: 100%;
          width: 100%;
        `}
      >
        <ActiveChartCore chartData={chartData} isLoading={isLoading} />
        <ChartLegend data={chartData} />
      </div>
    </section>
  );
};

export default ActiveDoorChart;
