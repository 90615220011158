import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */

import React from 'react';
export const columns = [
  // getSelectionColumn(),

  {
    style: { width: '250px' },
    Header: 'Door',
    accessor: 'door_name',
    pickerDisabled: true,
    asc: 'atozDoor',
    desc: 'ztoaDoor',
    newSorter: true,
    Cell: ({ row: { original } }) => {
      return (
        <section
          css={css`
            display: flex;
            flex-direction: column;
            span.desc {
              color: #7f8795;
              font-size: 12px;
            }
            span.site {
              color: #267dff;
            }
            span.name {
              font-weight: 500;

              font-size: 14px;
            }
          `}
        >
          <span className="name">{original.door_name}</span>
          <span className="desc">{original.door_description}</span>
        </section>
      );
    }
  },
  {
    Header: 'Reader',
    accessor: 'reader_direction',
    disableSortBy: true,
    Cell: ({ cell }) => {
      let dir = cell.value == 'in' ? 'Entrance' : 'Exit';
      return (
        <span
          css={css`
            color: var(--secondary-font-color);
          `}
        >
          {dir} Reader
        </span>
      );
    }
  },
  {
    Header: 'Site',
    accessor: 'site_name',
    pickerDisabled: true,
    asc: 'atozSite',
    desc: 'ztoaSite',
    newSorter: true,
    Cell: ({ row: { original } }) => (
      <section
        css={css`
          span.site {
            color: #267dff;
          }
        `}
      >
        <span className="site">{original.site_name}</span>
      </section>
    )
  },

  {
    style: { width: '250px' },
    Header: 'Open Schedule',
    accessor: 'schedule_name',
    pickerDisabled: true,
    asc: 'atozSchedule',
    desc: 'ztoaSchedule',
    newSorter: true,
    Cell: ({
      value,
      row: {
        original: { schedule_token: token }
      }
    }) => {
      if (token == 'standard_always')
        return (
          <span
            css={css`
              color: var(--primary-base);
            `}
          >
            {value}
          </span>
        );
      else return <span>{value}</span>;
    }
  }
];
