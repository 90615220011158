import React from "react";
//import { Tabs, Tab } from "components/tabs";
import Template from "base/template";
import { Redirect, useParams } from "react-router-dom";

import Content from "base/content";
import PageTitle from "components/page-title";
import Details from "views/devices/details";
import { useNotFound } from "hooks/not-found-hook";
import { useQueryWrapper } from "utils/ajax";
import Controller from "api/controller";
import { useEventSource } from "react-use-websocket";
import { SSE_HOST_URL } from "api/api-url";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const ControllerDetails = (props) => {
  const { id } = useParams();
  //let ac =  || null;

  const query = useQueryWrapper(
    ["controller", "details", id],
    () => Controller.search({ id }),
    {
      initialData: {
        data: [props?.location?.state?.ac],
      },
    }
  );
  const { lastEvent, readyState } = useEventSource(SSE_HOST_URL + "/events", {
    share: true,
    filter: (e) => filter(JSON.parse(e.data)),
    withCredentials: true,
    retryOnError: true,
  });

  let filter = React.useCallback((data) => {
    if (
      [
        "resetStart",
        "restoreStart",
        "online",
        "offline",
        "resetFinish",
      ].includes(data?.type)
    )
      return true;
    return false;
  }, []);

  React.useEffect(() => {
    if (!lastEvent) return;
    query.refetch();
  }, [lastEvent]);

  useNotFound(id, query, props);

  return (
    <Template {...props}>
      <Content
        {...props}
        css={css`
          padding: 24px 48px;
        `}
      >
        <PageTitle>{query.data.data?.[0]?.name}</PageTitle>
        <Details device={query?.data?.data[0]} />
      </Content>
    </Template>
  );
};

export default ControllerDetails;
