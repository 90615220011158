import React from 'react';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

//import * as Select from '@radix-ui/react-select';
import Select, { components, OptionProps } from 'react-select';

const SingleValue = (props) => {
  return (
    <components.SingleValue {...props}>
      <div
        css={css`
          align-items: center;
          display: flex;
          gap: 0.5rem;
        `}
      >
        <span>
          {props.data.value == 'line' && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-chart-line"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              strokeWidth="1"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M4 19l16 0"></path>
              <path d="M4 15l4 -6l4 2l4 -5l4 4"></path>
            </svg>
          )}
          {props.data.value == 'candlestick' && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-chart-candle"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              strokeWidth={1}
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M4 6m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v3a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z"></path>
              <path d="M6 4l0 2"></path>
              <path d="M6 11l0 9"></path>
              <path d="M10 14m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v3a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z"></path>
              <path d="M12 4l0 10"></path>
              <path d="M12 19l0 1"></path>
              <path d="M16 5m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z"></path>
              <path d="M18 4l0 1"></path>
              <path d="M18 11l0 9"></path>
            </svg>
          )}
        </span>
        <label
          css={css`
            text-transform: capitalize;
          `}
        >
          {props.data.label}
        </label>
      </div>
    </components.SingleValue>
  );
};

const Option = (props) => {
  return (
    <components.Option {...props}>
      <div
        css={css`
          align-items: center;
          display: flex;
          gap: 0.5rem;
        `}
      >
        <span>
          {props.value == 'line' && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-chart-line"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              strokeWidth="1"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M4 19l16 0"></path>
              <path d="M4 15l4 -6l4 2l4 -5l4 4"></path>
            </svg>
          )}
          {props.value == 'candlestick' && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-chart-candle"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              strokeWidth={1}
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M4 6m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v3a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z"></path>
              <path d="M6 4l0 2"></path>
              <path d="M6 11l0 9"></path>
              <path d="M10 14m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v3a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z"></path>
              <path d="M12 4l0 10"></path>
              <path d="M12 19l0 1"></path>
              <path d="M16 5m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z"></path>
              <path d="M18 4l0 1"></path>
              <path d="M18 11l0 9"></path>
            </svg>
          )}
        </span>
        <label
          css={css`
            text-transform: capitalize;
          `}
        >
          {props.data.label}
        </label>
      </div>
    </components.Option>
  );
};

export default ({ seriesTypeList = [], type, setType, ...otherProps }) => {
  const options = seriesTypeList.map((t) => ({ value: t, label: t }));

  return (
    <article className="chart-series">
      <Select
        unstyled
        css={css`
          .select__control {
            font-size: 12px;
            min-width: 150px;
            height: 100%;
            //min-height: 20px;

            color: white;
            //  border: none;
            cursor: pointer;
          }

          .select__value-container {
            overflow: unset !important;
            //padding: 0px;
            color: white;
            height: 100%;
          }
          .select__single-value {
          }
          .select__menu-list {
          }
          .select__option {
          }
        `}
        placeholder="Chart Type"
        isClearable={false}
        isSearchable={false}
        classNamePrefix="select"
        value={options.find((i) => i.value == type)}
        onChange={(e) => setType(e.value)}
        components={{ IndicatorSeparator: () => null, Option, SingleValue }}
        styles={{
          control: (base) => ({
            ...base,
            boxShadow: 'none !important',
            pointerEvent: 'all !important'
          }),

          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          menuList: (base) => ({
            ...base
            // kill the white space on first and last option
          }),
          option: (base, state) => ({
            ...base,
            backgroundColor: state.isSelected ? 'var(--primary-v-02)' : null,
            fontSize: '12px',
            color: 'black !important',

            '&:hover': {
              background: 'var(--neutral-05)'
            },
            '&:focus': {
              background: 'vaR(--neutral-05)'
            }
          })
        }}
        // menuPortalTarget={document.body}
        options={options}
        {...otherProps}
      />
    </article>
  );
};
