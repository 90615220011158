import React from 'react';
import { css } from '@emotion/react';
import { Badge } from 'components/badge';
/** @jsxImportSource @emotion/react */
import { getSelectionColumn } from 'components/table-selection-column';
import DepartmentAccessLevels from './departments-access-levels';
import { ActivityHeader, ActivityPercentage } from 'views/doors/table-columns';
import { useHeadlessTable } from 'providers/headless-table-provider';
import ActivityChartFormatter from 'components/activity-chart-formatter';

const SitesFormatter = ({ sites = [], site_names = [] }) => {
  const [color, label] =
    sites === false ? ['green', 'All'] : ['gray', 'limited'];

  return (
    <Badge title={site_names?.join(', ')} color={color}>
      {label}
    </Badge>
  );
};

export const columns = [
  getSelectionColumn(),
  {
    Header: 'Name',
    accessor: 'Name',
    style: { width: '220px' },
    pickerDisabled: true,
    asc: 'atoz',
    desc: 'ztoa',
    newSorter: true,
    Cell: ({ row }) => (
      // Use Cell to render an expander for each row.
      // We can use the getToggleRowExpandedProps prop-getter
      // to build the expander.
      <article
        css={css`
          > span {
            display: block;
            line-height: 1.6;

            > span {
              margin-left: 8px;
            }
          }
          .desc {
            color: #7f8795;
          }
        `}
      >
        <span className="name">
          {row.original.Name}
          {row.original.ad_uuid && <Badge color="purple">AD</Badge>}
        </span>
        <span className="desc">{row.original.Description}</span>
      </article>
    )
  },

  {
    Header: 'People',
    accessor: 'person_count',
    id: 'person_count',
    asc: 'leastPeople',
    desc: 'mostPeople',
    newSorter: true
  },

  {
    Header: 'Access Levels',
    accessor: 'access_level_names',
    disableSortBy: true,
    Cell: ({ row: { original: dep } }) => <DepartmentAccessLevels dep={dep} />
  },
  {
    Header: 'Sites',
    accessor: 'sites',
    disableSortBy: true,
    Cell: ({ cell: { value }, row: { original } }) => (
      <SitesFormatter sites={value} site_names={original.site_names} />
    )
  },
  {
    Header: () => <ActivityHeader />,
    disableSortBy: true,
    id: '7 Day Trend',
    label: '7 Day Trend',
    accessor: 'activity',

    Cell: ({ cell: { value } }) => <ActivityChartFormatter activity={value} />
  },
  {
    Header: () => <ActivityPercentage />,
    accessor: 'activity_total',
    label: 'Activity Percentage',
    newSorter: true,
    asc: 'mostActivity',
    desc: 'leastActivity',

    Cell: ({ cell: { value } }) => {
      if (value == 0) return null;
      const { tableState } = useHeadlessTable();

      let average =
        Number(tableState?.stats?.activity_total) / tableState?.stats?.total;

      const difference = value - average;

      // Calculate the percentage difference
      const ratio = (difference / average) * 100;

      return (
        //solve this
        <div>
          <eq
            data-positive={ratio > 0}
            css={css`
              display: flex;
              align-items: center;
              &[data-positive='true'] {
                color: var(--light--primary--400-base);
              }
              &[data-positive='false'] {
                color: var(--light--others--red-400-base);
              }

              font-size: 16px;
              font-weight: 600;
            `}
          >
            {ratio.toFixed(2)}%
          </eq>
        </div>
      );
    }
  }
];
