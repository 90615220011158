import React from 'react';
import { Field, useFormikContext, FieldArray } from 'formik';
import { InputLabel, TextField } from 'components/form';
import AsyncSelect from 'components/headless-select/async';
import Site from 'api/site';
import SelectWrapper from 'components/headless-select';
import { Heading } from 'components/form';
import { AuxiliaryButton } from 'components/buttons';
import { css } from '@emotion/react';
import { PlusLg, Trash } from 'react-bootstrap-icons';
/** @jsxImportSource @emotion/react */

const PROTOCOLS = [
  { value: 'http', name: 'HTTP', isDisabled: true },
  { value: 'mqtt', name: 'MQTT' }
];

export default ({ sensor }) => {
  const { values, setFieldValue } = useFormikContext();
  return (
    <section>
      <Field name="name" as={TextField}></Field>
      <Field
        title="Site"
        name="site_id"
        component={AsyncSelect}
        fetchFn={Site.search}
      />
      <Field
        name="protocol"
        component={SelectWrapper}
        onChange={(e) => {
          setFieldValue('protocol', e.value);
        }}
        value={PROTOCOLS.find((i) => i.value == values.protocol)}
        title="Network Protocol"
        simple
        placeholder="Select protocol..."
        options={PROTOCOLS}
      ></Field>
    </section>
  );
};
