import React from 'react';
import Select from 'components/headless-select';
import { AuxiliaryButton, SecondaryButton } from 'components/buttons';
import { Field, useFormikContext } from 'formik';
import { Upload } from 'react-bootstrap-icons';
import { css } from '@emotion/react';
import { CheckBox, TextField } from 'components/form';
import { PersonBadge } from './badge';
import { ColorPicker } from 'components/form/color-picker';
/** @jsxImportSource @emotion/react */
import 'rc-input-number/assets/index.css';
import InputNumber from 'rc-input-number';
import { DEFAULT_TEMPLATE } from 'routes/badging/save';
import { IconPhoto } from '@tabler/icons-react';
const styles = css`
  display: flex;
  gap: 5rem;
  .right > :first-child {
    margin-bottom: 24px;
  }

  section.fields {
    align-content: start;
    display: grid;
    grid-template-columns: auto 150px 54px;
    gap: 24px;
    align-items: center;

    label[name='avatar.active'] {
      grid-column: 1/ -1;
    }

    > .orient {
      grid-column: span 2;
      display: flex;
      gap: 16px;
      width: 100%;

      > button {
        flex: 1;
        border: 1px solid #0003;
        background: transparent;
        color: black;

        > svg {
          width: 28px;
          height: 28px;
          margin-right: 8px;
          margin-left: -6px;
        }
      }

      > button[data-active='true'] {
        border: 1px solid #000;
        font-weight: 600;
      }
    }

    > .bg {
      grid-column: span 2;
      display: flex;
      gap: 24px;
      width: 100%;

      > .upload {
        flex: 1;
        justify-cotent: space-between;

        &[disabled] {
          pointer-events: none;
          opacity: 0.5;
        }

        > img {
          border: 1px solid #0006;
          border-radius: 5px;
          width: 100%;
          height: 32px;
          object-fit: cover;
          cursor: pointer;
        }

        > p {
          margin-left: auto;
        }

        > input[type='file'] {
          display: none;
        }

        > button {
          > svg {
            width: 20px;
            height: 20px;
            margin-right: 4px;
          }
        }
      }
    }
  }
`;

const BadgeForm = (props) => {
  const person = props.person ?? {
    name: 'John Doe',
    email: 'johndoe@example.com',
    phone: '1111111111',
    department_names: '["IT","Human Resources"]'
  };

  const print = props.print ?? false;

  // const { redirect, setRedirect } = useRedirect(false);

  const LAYOUTS = [
    { id: 'v1', name: 'Vertical 1' },
    { id: 'h1', name: 'Horizontal 1' }
  ];

  const { values, setValues, setFieldValue } = useFormikContext();

  React.useEffect(() => {
    // needs to change
    props.person &&
      !props.person.avatar_url &&
      setFieldValue('avatar.active', false);
  }, [props.person, values]);

  return (
    <>
      <article css={styles}>
        <section className="fields">
          <CheckBox label="Name" checked={true} disabled />
          <InputNumber
            min={1}
            max={26}
            precision={0}
            step={1}
            value={values.name.fontSize ?? 12}
            onChange={(e) => setFieldValue('name.fontSize', e)}
          />
          <Field name="name.color" as={ColorPicker} />

          <CheckBox
            name="avatar.active"
            label="Avatar"
            checked={values.avatar?.active}
            disabled={Boolean(props.person && !props.person.avatar_url)}
            onChange={(e) => {
              setFieldValue('avatar', {
                ...values.avatar,
                active: e.target.checked
              });
            }}
          />

          <CheckBox
            name="department.active"
            label="Department"
            checked={values.department.active}
            onChange={(e) => {
              setFieldValue('department', {
                ...values.department,
                active: e.target.checked,
                color: values.department.color
              });
            }}
          />
          <InputNumber
            min={1}
            max={26}
            precision={0}
            step={1}
            value={values.department.fontSize ?? 12}
            onChange={(e) => setFieldValue('department.fontSize', e)}
          />

          <Field
            name="department.color"
            as={ColorPicker}
            disabled={!values.department.active}
          />
          <CheckBox
            name="email.active"
            label="E-mail"
            checked={values.email.active}
            onChange={(e) =>
              setFieldValue('email', {
                ...values.email,
                active: e.target.checked,
                color: values.email.color
              })
            }
          />
          <InputNumber
            min={1}
            max={26}
            precision={0}
            step={1}
            value={values.email.fontSize ?? 12}
            onChange={(e) => setFieldValue('email.fontSize', e)}
          />
          <Field
            name="email.color"
            as={ColorPicker}
            disabled={!values.email.active}
          />
          <CheckBox
            name="phone.active"
            label="Phone"
            checked={values.phone.active}
            disabled={Boolean(props.person && !props.person.phone)}
            onChange={(e) =>
              setFieldValue('phone', {
                ...values.phone,
                active: e.target.checked,
                color: values.phone.color
              })
            }
          />
          <InputNumber
            min={1}
            max={26}
            precision={0}
            step={1}
            value={values.phone.fontSize ?? 12}
            onChange={(e) => setFieldValue('phone.fontSize', e)}
          />
          <Field
            name="phone.color"
            as={ColorPicker}
            disabled={!values.phone.active}
          />
          {print ? (
            <TextField label="Custom Line" name="custom.text" compact />
          ) : (
            <CheckBox
              name="custom"
              label="Custom Line"
              checked={true}
              disabled
            />
          )}
          <InputNumber
            min={1}
            max={26}
            precision={0}
            step={1}
            value={values.custom?.fontSize ?? 12}
            onChange={(e) => setFieldValue('custom.fontSize', e)}
          />
          <Field
            name="custom.color"
            as={ColorPicker}
            disabled={!values.custom?.text}
          />
          <CheckBox
            name="background"
            label="Background Color"
            checked={values.background}
            onChange={(e) => setFieldValue('background', e.target.checked)}
          />
          <br />
          <Field
            name="background_color"
            as={ColorPicker}
            disabled={!values.background}
          />
          <div className="bg">
            <CheckBox
              name="background_image"
              label="Background Image"
              checked={values.background_image}
              onChange={(e) =>
                setFieldValue('background_image', e.target.checked)
              }
            />

            <label className="upload" disabled={!values.background_image}>
              <input
                type="file"
                accept="image/*"
                disabled={!values.background_image}
                onChange={(ev) => {
                  const input = ev.target;
                  const reader = new FileReader();
                  reader.onload = (e) => {
                    const data = e.target.result;
                    setFieldValue('background_image_data', data);
                  };
                  input.files[0] && reader.readAsDataURL(input.files[0]);
                }}
              />
              {values.background_image_url || values.background_image_data ? (
                <img
                  src={
                    values.background_image_data ?? values.background_image_url
                  }
                />
              ) : (
                <AuxiliaryButton
                  compact
                  onClick={(ev) => ev.target.previousElementSibling.click()}
                >
                  <IconPhoto /> Upload Image
                </AuxiliaryButton>
              )}
            </label>
          </div>
        </section>

        <div className="right">
          <Select
            simple
            title="Layout"
            placeholder=""
            isMulti={false}
            controlShouldRenderValue={true}
            options={LAYOUTS}
            closeMenuOnSelect={true}
            value={LAYOUTS.find((t) => t.id == values.layout)}
            setState={() => {}}
            controlShouldRenderValue={true}
            isClearable={false}
            onChange={(e) => {
              setValues({
                ...values,
                ...DEFAULT_TEMPLATE[e.id],
                layout: e?.id
              });
            }}
          />

          <PersonBadge person={person} data={values} {...props} />
        </div>
      </article>
    </>
  );
};

export default BadgeForm;
