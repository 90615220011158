/** @jsxImportSource @emotion/react */
import React, { Fragment, useState } from 'react';
import Site from 'api/site';
import { useAuth } from 'providers/auth';
import { useHistory } from 'react-router-dom';
import { css } from '@emotion/react';
import Footer from './footer';
import SiteItem from './site-item';
import { components } from 'react-select';
import { CaretDownFill, Search } from 'react-bootstrap-icons';
import AsyncSelect from 'components/headless-select/async';
import * as Popover from '@radix-ui/react-popover';
import { PopoverContent } from 'components/popover';

export const Option = ({ children, ...props }) => {
  return (
    <components.Option
      {...props}
      css={css`
        padding: 0px;
      `}
    >
      <SiteItem site={props.data} />
    </components.Option>
  );
};

export default function SiteFocus() {
  const { focusedSite, siteFocus, user } = useAuth();
  const history = useHistory();

  const [isOpen, setIsOpen] = React.useState();

  const handleFocus = (siteId) =>
    siteFocus.mutate(siteId, {
      //onSuccess: () => history.push('/doors')
    });

  return (
    <>
      <Popover.Root open={isOpen} onOpenChange={setIsOpen}>
        <Popover.Trigger
          css={css`
            display: flex;
            gap: 5px;
            align-items: center;

            svg {
              padding: 2px;
              transition: all 0.2s;
            }
            &[aria-expanded='true'] {
              svg:first-of-type {
                transform: rotate(180deg);
              }
            }
          `}
        >
          <div
            data-focused={focusedSite ? true : false}
            css={css`
              display: flex;
              flex-direction: column;
              place-items: flex-start;
              font-size: 14px;
              &[data-focused='false'] {
                opacity: 0.4;
              }
            `}
          >
            {user.focused_site_name ?? 'Focus site...'}
          </div>
          <CaretDownFill size={16} color={'var(--primary-base)'} />
        </Popover.Trigger>
        <Popover.Anchor />
        <Popover.Portal>
          <PopoverContent>
            <AsyncSelect
              queryKey="site-focus"
              checkboxes={false}
              data-compact
              data-inline
              maxOptions={3}
              fetchFn={Site.search}
              fetchFilters={{
                except: focusedSite
              }}
              queryParams={{
                cacheTime: 60 * 1000,
                staleTime: 60 * 1000
              }}
              icon={Search}
              itemComponents={{ Option: Option, DropdownIndicator: null }}
              css={css`
                .select__control {
                  margin: 1rem 2.25rem !important;
                  width: auto !important;
                }
                .select__menu {
                  //box-shadow: none;
                  border: none;
                  position: relative;
                  box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.1);
                }
                .select__menu-list {
                  overflow-y: auto;
                  overflow-x: hidden;
                }
                input {
                  opacity: 1 !important ;
                }
              `}
              //onChange={(e) => setGlobalAccessLevel(e)}
              menuIsOpen={true}
              closeMenuOnSelect={true}
              controlShouldRenderValue={false}
              onChange={(e) => {
                setIsOpen(false);
                e && handleFocus(e.id);
              }}
            />

            <Popover.Arrow />
            <Footer />
          </PopoverContent>
        </Popover.Portal>
      </Popover.Root>
    </>
  );
}
