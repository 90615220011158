import { useMutationWrapper } from "utils/ajax";
import Snapshot from "api/snapshot";
import { useQueryClient } from "react-query";
import { useHeadlessTable } from "providers/headless-table-provider";

export const useMutateDelete = (snapshot) => {
  const queryClient = useQueryClient();
  const { queryKey } = useHeadlessTable();
  return useMutationWrapper(
    ["snaphsot", "delete", snapshot?.id],
    (id) => Snapshot.delete(id),
    {
      successMessage: `Snapshot: ${snapshot?.id} deleted sucessfuly`,
      onMutate: (id) => {
        let data = queryClient.getQueryData(queryKey);

        const newPagesArray =
          data?.pages.map((page) => ({
            ...page,
            data: page.data.filter((val) => val.id !== id),
          })) ?? [];

        queryClient.setQueryData(queryKey, (data) => ({
          pages: newPagesArray,
          pageParams: data.pageParams,
        }));
      },
      onSuccess: () => {},
    }
  );
};
