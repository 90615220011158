import React from 'react';
import { Badge } from 'components/badge';
import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
const styles = css`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  width: fit-content;
`;
const DoorSchedules = ({ door }) => {
  return (
    <section className="door-schedule">
      {door.schedule_names || door.io_open_schedule_name}
    </section>
  );
};

export default DoorSchedules;
