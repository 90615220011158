/** @jsxImportSource @emotion/react */

import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { css } from '@emotion/react';

const linkCSS = css`
  display: flex;
  gap: 24px;
  padding-left: 24px;
  color: inherit;
  font-weight: 400;
  height: var(--menu-item-height);

  align-items: center;
  transition: color ease 300ms;
  width: 200px;
  min-width: 200px;
  box-sizing: border-box;
  font-size: var(--menu-item-font-size);
  width: 100%;

  &:hover {
    background-color: var(--neutral-05) !important;
  }

  svg {
    width: var(--menu-item-svg-size);
    height: var(--menu-item-svg-size);
  }
`;

const regularCSS = css`
  color: var(--menu-item-color);
`;

const selectedCSS = css`
  color: var(--neutral-00);
  svg {
    color: var(--primary-base);
  }
  background: var(--primary-v-03);
`;

const MenuItem = (props) => {
  const match = useRouteMatch();

  const itemStyle = match.path.includes(props.to) ? selectedCSS : regularCSS;

  return (
    <li css={itemStyle}>
      <Link css={linkCSS} to={props.to}>
        {props.children}
        <span>{props.title}</span>
      </Link>
    </li>
  );
};

export default MenuItem;
