import React from "react";
import { ReactComponent as EmptySvg } from "assets/images/sidebar-empty-holder.svg";
import { css } from "@emotion/react";
import { useTheme } from "@emotion/react";
/** @jsxImportSource @emotion/react */

const styles = (theme) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 24px;
  span {
    background: var(--neutral-07);

    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;

    border-radius: 50%;
    width: 200px;
    height: 200px;
  }
  svg {
    width: 150px;
    height: 150px;
    color: var(--neutral-03);
  }

  > h1 {
    font-weight: bold;
    font-size: 18px;
    line-height: 100%;
    /* identical to box height, or 18px */

    display: flex;
    align-items: center;
    text-align: center;

    /* Neutrals/neutral-00 */

    color: var(--neutral-00);
  }
  > h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    /* identical to box height, or 21px */

    display: flex;
    align-items: center;
    text-align: center;

    color: var(--secondary-font-color);
  }
`;

const SidebarEmptyHolder = (props) => {
  const { title, subtitle, icon: Icon, nosubtitle, size } = props;
  return (
    <article className="empty-holder" css={styles}>
      <span>{Icon ? <Icon /> : <EmptySvg />}</span>
      <h1>{title || "Select a row"}</h1>
      {!nosubtitle && (
        <h2>{subtitle || "Select a row for more information"}</h2>
      )}
    </article>
  );
};

export default SidebarEmptyHolder;
