import React from 'react';
import { Badge } from 'components/badge';
import * as Tooltip from '@radix-ui/react-tooltip';
import { css } from '@emotion/react';

import { getSelectionColumn } from 'components/table-selection-column';
import { AvatarCircle } from 'components/avatar';
import { dateTime, dateTimeDif } from 'utils/time';

import { CircleIcon } from 'components/circle-icon';
import DoorStateAvatar from './door-state-avatar';
/** @jsxImportSource @emotion/react */
import AlarmStateFormatter from 'components/alarm-state-formatter';
import { IconButton } from 'components/buttons';
import { useAccessMutate } from './actions';
import DoorNameFormatter from './door-name-formatter';
import LastAccessFormatter from 'components/last-access-formatter';
import { Link } from 'react-router-dom';
import DoorSchedules from './door-schedules';
import { ReactComponent as Rex } from 'assets/rex.svg';
import Indicatiors from './indicatiors';
import ReactECharts from 'echarts-for-react';
import { IconHelpCircle, IconPercentage } from '@tabler/icons-react';
import ActivityChartFormatter from 'components/activity-chart-formatter';
import { useHeadlessTable } from 'providers/headless-table-provider';
import DoorAlertTableIndicator from 'components/door-alert-table-indicator';

export function ActivityHeader() {
  return (
    <Tooltip.Provider>
      <Tooltip.Root>
        <Tooltip.Trigger asChild={true}>
          <span
            css={css`
              display: inline-flex;
              gap: 0.4rem;
              align-items: center;
            `}
          >
            <label>7 Day Trend</label>
            <IconHelpCircle size={22} />
          </span>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content asChild={true}>
            <div
              css={css`
                z-index: 200;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                padding: 0.5rem;

                background: var(--neutral-09);
                box-shadow: 0px 2px 6px 4px rgba(29, 32, 35, 0.4);
                border-radius: 4px;
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  label {
                    display: flex;
                    gap: 0.5rem;
                    align-items: center;
                  }
                  .square::before {
                    content: '';
                    width: 10px; /* Adjust the width as needed */
                    height: 10px; /* Adjust the height as needed */
                    display: block; /* Make it a block-level element */
                  }
                  .in::before {
                    background: #2480d6;
                  }
                  .above::before {
                    background: #f5861f;
                  }
                `}
              >
                Entrances trend for past 7 days
                <label className="square in">around average</label>
                <label className="square above">25% above average</label>
              </div>
              <Tooltip.Arrow
                css={css`
                  fill: var(--neutral-09);
                `}
              />
            </div>
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}

export function ActivityPercentage() {
  return (
    <Tooltip.Provider>
      <Tooltip.Root>
        <Tooltip.Trigger asChild={true}>
          <span
            css={css`
              display: inline-flex;
              gap: 0.4rem;
              align-items: center;
            `}
          >
            <label>Activity Percentage </label>
            <IconHelpCircle size={22} />
          </span>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content asChild={true}>
            <div
              css={css`
                z-index: 200;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                padding: 0.5rem;

                background: var(--neutral-09);
                box-shadow: 0px 2px 6px 4px rgba(29, 32, 35, 0.4);
                border-radius: 4px;
              `}
            >
              <div>
                Above or below avreage activity in recent 7 days. <br />
                <span
                  css={css`
                    font-size: 12px;
                    opacity: 80%;
                  `}
                >
                  (Indicates most active door)
                </span>
              </div>

              <Tooltip.Arrow
                css={css`
                  fill: var(--neutral-09);
                `}
              />
            </div>
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
}

export const columns = [
  getSelectionColumn(),
  {
    style: { width: '80px' },
    Header: 'State',
    accessor: 'state',
    Cell: ({ row: { original: door } }) => {
      return (
        <>
          <DoorStateAvatar door={door} />
        </>
      );
    }
  },

  {
    Header: 'Name',
    accessor: 'name',
    style: { width: '220px' },
    pickerDisabled: true,
    Cell: ({ row, cell }) => (
      <div
        css={css`
          display: grid;
          grid-template-columns: auto auto 1fr;
          column-gap: 0.4rem;
          svg {
            grid-row: span 2;
            place-self: center;
          }
          > small {
            grid-column: 1;
          }
        `}
      >
        <DoorAlertTableIndicator door={row.original} />
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 1rem;
          `}
        >
          <DoorNameFormatter door={row.original} />

          <Indicatiors door={row.original} />
        </div>
      </div>
    )
  },
  {
    Header: 'Status',
    id: 'online',
    accessor: 'online',
    Cell: ({ row: { original } }) => {
      const [label, color] =
        original.online == '0' ? ['offline', 'red'] : ['online', 'green'];

      return (
        <span>
          <Badge color={color}>{label}</Badge>
        </span>
      );
    }
  },
  {
    Header: 'Device',
    role: 'IAV',
    accessor: 'device_name',
    Cell: ({ row, cell }) => (
      <span className="name">
        <Link
          to={{
            pathname: '/devices/details/' + row.original.device_id
          }}
          css={css`
            &:hover {
              text-decoration: underline !important;
            }
          `}
        >
          {row.original.device_name ?? row.original.controller_name}
        </Link>
      </span>
    )
  },

  {
    Header: 'Date Inserted',
    role: 'IAV',
    accessor: 'insert_time',
    label: 'Date Inserted',
    id: 'insert_time',
    Cell: ({ cell: { value } }) => {
      if (value) return <span>{dateTimeDif(value)}</span>;
      else return null;
    }
  },

  {
    Header: 'Site',
    accessor: 'site_name'
  },

  {
    Header: 'Alarm State',
    accessor: 'alarm_state',
    Cell: ({ cell: { value: state } }) => <AlarmStateFormatter state={state} />
  },

  {
    Header: 'Last Access',
    accessor: 'last_access_time',
    style: { width: '300px' },
    Cell: ({ row: { original }, cell: { value } }) => (
      <LastAccessFormatter door={original} />
    )
  },
  {
    Header: 'Last State Change',
    accessor: 'last_state_change_at',
    style: { width: '200px' },
    Cell: ({ row: { original }, cell: { value } }) => (
      <span>{value ? dateTime(new Date(value + 'Z')) : ''}</span>
    )
  },

  {
    Header: 'Open Times',
    accessor: 'schedule_names',

    Cell: ({ row: { original: door } }) => <DoorSchedules door={door} />
  },
  {
    Header: () => <ActivityHeader />,
    disableSortBy: true,
    id: '7 Day Trend',
    label: '7 Day Trend',
    accessor: 'activity',

    Cell: ({ cell: { value } }) => <ActivityChartFormatter activity={value} />
  },
  {
    Header: () => <ActivityPercentage />,
    accessor: 'activity_total',
    label: 'Activity Percentage',

    Cell: ({ cell: { value } }) => {
      if (value == 0) return null;
      const { tableState } = useHeadlessTable();

      let average =
        Number(tableState?.stats?.activity_total) / tableState?.stats?.total;

      const difference = value - average;

      // Calculate the percentage difference
      const ratio = (difference / average) * 100;

      return (
        //solve this
        <div>
          <eq
            data-positive={ratio > 0}
            css={css`
              display: flex;
              align-items: center;
              &[data-positive='true'] {
                color: var(--light--primary--400-base);
              }
              &[data-positive='false'] {
                color: var(--light--others--red-400-base);
              }

              font-size: 16px;
              font-weight: 600;
            `}
          >
            {ratio.toFixed(2)}%
          </eq>
        </div>
      );
    }
  }
];
