import React from 'react';

import { useAuth } from 'providers/auth';
const IsRole = ({ children, role, disableCompany, restrict, only }) => {
  const { user } = useAuth();

  if (!user) return null;

  if (user.role == 'A') return <> {children}</>;

  if (restrict == 'C') {
    if (user.role == 'C') return null;
    else return <>{children}</>;
  }

  if (only)
    if (user.role == only) return <> {children}</>;
    else return null;

  if (role?.includes(user.role)) return <> {children}</>;
  else return null;
};

export default IsRole;
