import React from 'react';
import { useQueryWrapper } from 'utils/ajax';
import Sensor from 'api/sensor';
import styled from '@emotion/styled';
import { Section } from 'views/dashboard/user-dashboard';
import { css } from '@emotion/react';
import TimeSeriesGauge from './time-series-gauge';
import EventTotalWidget from './event-total-widget';
import GaugeLoader from './gauge-loader';
import { IconAlertTriangle } from '@tabler/icons-react';
/** @jsxImportSource @emotion/react */
const Container = styled('article')`
  display: grid;
  flex: 1;
  gap: 1rem;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr 1fr 1fr 1fr;
  overflow: hidden;
  height: 100%;
`;

export default ({ sensor }) => {
  const { data: topics, isLoading } = useQueryWrapper(
    ['sensor', sensor.id, 'getTopics'],
    () => Sensor.getTopics(sensor.id),
    {
      enabled: Boolean(sensor),
      select: (data) => {
        //..mix in metadaa
        return data.map((t) => ({
          ...t,
          name: sensor?.metadata?.[t.topic]?.name,
          unit: sensor?.metadata?.[t.topic]?.unit
        }));
      }
    }
  );

  return (
    <Container className="topics-container">
      {/* <EventTotalWidget sensor={sensor} /> */}
      {isLoading &&
        sensor?.topics?.map((t, idx) => (
          <Section
            key={idx}
            css={css`
              width: 100%;
              height: 100%;
              > * {
                margin: auto;
              }
            `}
          >
            <GaugeLoader />
          </Section>
        ))}
      {topics?.map((topic, idx) => {
        return (
          <Section key={idx}>
            <header
              data-alert={topic.alert}
              css={css`
                &[data-alert='true'] {
                  background: var(--red-v-02) !important;
                  color: var(--red-v-01) !important;
                }
                background: #fafafa;
                padding: 0.5rem;
                display: flex;
                align-items: center;
                font-size: 14px;
                position: relative;
                padding-right: 0.5rem;
                gap: 1rem;
                width: 100%;
                > button {
                  margin-left: auto;
                }
              `}
            >
              {topic.alert && (
                <span>
                  <IconAlertTriangle color="var(--red-v-01)" />
                </span>
              )}
              {topic.name || topic.topic}
            </header>
            <div
              css={css`
                height: 100%;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
              `}
            >
              <TimeSeriesGauge {...topic} />
            </div>
          </Section>
        );
      })}
    </Container>
  );
};
