import React from 'react';
import Site from 'api/site';
import { ChevronDown, CircleFill, Search } from 'react-bootstrap-icons';
import AsyncSelect from 'components/headless-select/async';
import { css } from '@emotion/react';
import { useQueryWrapper } from 'utils/ajax';
/** @jsxImportSource @emotion/react */

const Menu = (props) => {
  const shadow = 'hsla(218, 50%, 10%, 0.1)';
  return (
    <div
      css={{
        backgroundColor: 'white',
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        position: 'absolute',
        zIndex: 2
      }}
      {...props}
    />
  );
};

const Dropdown = ({ children, isOpen, target, onClose }) => (
  <div css={{ position: 'relative' }}>
    {target}
    {isOpen ? (
      <Menu>
        {children}
        <div
          css={css`
            display: flex;
            align-items: center;
            padding: 0.4rem;
            font-size: 11px;
            line-height: 20px;
            gap: 0.5rem;
          `}
        >
          <CircleFill size={14} color="var(--secundary-v-01)" />
          <span>changed site</span>
          <CircleFill size={14} color="var(--orange-base)" />
          <span>in access level</span>
        </div>
      </Menu>
    ) : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);

const Blanket = (props) => (
  <div
    css={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: 'fixed',
      zIndex: 1
    }}
    {...props}
  />
);

export default ({
  setSelectedSite,
  selectedSite,
  defaultSiteId = 1,
  changedSites,
  accessLevelId,
  accessLevel
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [value, setValue] = React.useState(selectedSite);

  // TODO consider using Site.get. Make sure it brings readerStats by default
  const { data: site } = useQueryWrapper(
    ['site', 'get', defaultSiteId],
    () =>
      Site.search({
        id: defaultSiteId,
        withReaderStats: true
      }),
    { enabled: Boolean(defaultSiteId), refetchOnMount: false }
  );

  React.useEffect(() => {
    if (!site?.data?.[0]) return;
    setSelectedSite(site.data[0]);
    setValue(site.data[0]);
  }, [site]);

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      minWidth: 300,
      width: 'auto',
      margin: 8,
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
    }),
    menu: () => ({
      border: 'none !important'
    }),
    menuList: (base) => ({
      ...base,
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 0px inset'
    })
  };

  return (
    <Dropdown
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      target={
        <button
          css={css`
            align-items: center;
            gap: 0.8rem;
            -moz-box-align: baseline;

            border-width: 0px;
            border-radius: 3px;
            box-sizing: border-box;
            display: inline-flex;
            font-size: inherit;
            font-style: normal;
            font-family: inherit;
            font-weight: 500;
            max-width: 100%;
            position: relative;
            text-align: center;
            text-decoration: none;
            transition: background 0.1s ease-out 0s,
              box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
            white-space: nowrap;
            background: rgba(9, 30, 66, 0.04);
            color: rgb(66, 82, 110) !important;
            cursor: pointer;
            height: 2.28571em;
            line-height: 2.28571em;
            padding: 0px 10px;
            vertical-align: middle;
            width: auto;
            -moz-box-pack: center;
            justify-content: center;
          `}
          onClick={() => setIsOpen((prev) => !prev)}
          isSelected={isOpen}
        >
          {value
            ? `Site: ${value.name} (${value.reader_count})`
            : 'Select a Site'}
          <ChevronDown size={14} />
        </button>
      }
    >
      <AsyncSelect
        queryKey="sites-with-readers"
        queryParams={{ refetchOnMount: true, staleTime: 60000 }}
        styles={selectStyles}
        noValueContainer
        isClearable={false}
        placeholder="Search Site..."
        name="sites"
        fetchFilters={{
          withReaderStats: true,
          isInAccessLevel: accessLevelId,
          inIds:
            accessLevel.sites !== false && accessLevel
              ? accessLevel.sites
              : undefined
        }}
        checkboxes={false}
        data-compact
        fetchFn={Site.search}
        icon={Search}
        isOptionDisabled={(e) => e.reader_count == 0}
        itemComponents={{ DropdownIndicator: null }}
        menuIsOpen={true}
        //onChange={(e) => setGlobalAccessLevel(e)}
        getOptionLabel={(site) => (
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: 0.5rem;
            `}
          >
            {changedSites.has(site.id) && (
              <CircleFill size={12} color="var(--secundary-v-01)" />
            )}
            {site.isInAccessLevel == '1' && (
              <CircleFill size={12} color="var(--orange-base)" />
            )}
            <div
              css={css`
                display: flex;
                flex-direction: column;
              `}
            >
              <span>{site.name}</span>
              <span
                css={css`
                  opacity: 60%;
                  font-size: 11px;
                `}
              >
                {site.reader_count} reader(s)
              </span>
            </div>
          </div>
        )}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        closeMenuOnSelect={true}
        value={value}
        onChange={(e) => {
          setValue(e);
          setIsOpen(false);
          e && setSelectedSite(e);
        }}
      />
    </Dropdown>
  );
};
