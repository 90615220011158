import React from 'react';
import DoorForm from 'views/doors/form';
import GenericDoor from 'api/generic_door';
import Template from 'base/template';
import { Button, CancelButton, SecondaryButton } from 'components/buttons';
import { Redirect } from 'react-router-dom';
import FormProvider from 'providers/form-provider';
import { useMutationWrapper } from 'utils/ajax';
import { useRedirect } from 'hooks/redirect-hook';

import { Formik } from 'formik';
import * as Yup from 'yup';
import PageTitle from 'components/page-title';
import Content from 'base/content';
import Breadcrumbs from 'components/breadcrumbs';

const Save = (props) => {
  let door = props?.location?.state?.door || null;

  const SCHEMA = Yup.object().shape({
    doors: Yup.array().of(
      Yup.object().shape({
        name: Yup.string()
          .min(4, 'Name too short')
          .max(50, 'Name too long')
          .required('Required'),
        priorityconfiguration: Yup.string().required('Required').nullable(),
        access_time: Yup.number()
          .required('must be a number')
          .min(1, 'min value is 1')
          .max(60, 'max value is 60')
          .nullable(),
        extendedaccesstime: Yup.number()
          .required('must be a number')
          .min(2, 'min value is 2')
          .max(90, 'max value is 90')
          .nullable()
          .test('superior', 'must be more than Access Time', function (f) {
            const ref = Yup.ref('access_time');
            return f > this.resolve(ref);
          }),
        opentoolongtime: Yup.number()
          .required('must be a number')
          .min(1, 'min value is 1')
          .max(270, 'max value is 270')
          .nullable(),
        extendedopentoolongtime: Yup.number()
          .required('must be a number')
          .min(2, 'min value is 2')
          .max(300, 'max value is 300')
          .nullable()
          .test('superior', 'Must be more than OTL', function (f) {
            const ref = Yup.ref('opentoolongtime');
            return f > this.resolve(ref);
          }),
        prealarmtime: Yup.number()
          .required('must be a number')
          .min(1, 'min value is 1')
          .max(15, 'max value is 15')
          .nullable()
      })
    )
  });

  const INITIAL_VALUES = {
    doors: [
      {
        ...door,
        name: door?.name,
        description: door?.description,
        access_time: door?.AccessTime?.replace(/\D/g, '') || 7,
        extendedaccesstime: door?.ExtendedAccessTime?.replace(/\D/g, '') || 30,
        opentoolongtime: door?.OpenTooLongTime?.replace(/\D/g, '') || 25,
        extendedopentoolongtime:
          door?.ExtendedOpenTooLongTime?.replace(/\D/g, '') || 50,
        prealarmtime: door?.PreAlarmTime?.replace(/\D/g, '') || 5,
        priorityconfiguration: door?.PriorityConfiguration,
        labels: door ? JSON.parse(door.labels) : [],
        denied_limit: Boolean(Number(door?.denied_limit)),
        forced_open: Boolean(Number(door?.forced_open)),
        open_too_long: Boolean(Number(door?.open_too_long)),
        rex_open_door: Boolean(Number(door?.rex_open_door)),
        emergency_lockdown: Boolean(Number(door?.emergency_lockdown)),
        // this is unnecessary just to comply wiht old ui
        security: true
      }
    ]
  };

  const { isSuccess, isLoading, mutate } = useMutationWrapper(
    ['door', 'edit'],
    (input) => GenericDoor.save(input, door?.id),
    {}
  );

  const { redirect, setRedirect } = useRedirect(isSuccess);

  return (
    <Template {...props}>
      {redirect && <Redirect to="/doors" />}
      <Content {...props}>
        <PageTitle>{door ? door.name : 'Door Add'}</PageTitle>
        <Breadcrumbs crumbs={props.crumbs} />
        <Formik
          validationSchema={SCHEMA}
          initialValues={INITIAL_VALUES}
          onSubmit={(values) => {
            mutate({ ...values.doors[0] });
          }}
        >
          {({ handleSubmit, handleReset }) => (
            <FormProvider mutateFunc={handleSubmit} disabled={isLoading}>
              <DoorForm door={door} />
              <footer>
                <Button type="submit">Submit</Button>
                <CancelButton onClick={() => setRedirect(true)} />
              </footer>
            </FormProvider>
          )}
        </Formik>
      </Content>
    </Template>
  );
};

export default Save;
