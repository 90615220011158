import React from 'react';
import { useMutationWrapper } from 'utils/ajax';
import Controller from 'api/controller';
import Device from 'api/device';
import ControllerConnectivity from 'api/controller_connectivity';
import { useQueryClient } from 'react-query';
import { useHeadlessTable } from 'providers/headless-table-provider';
import { toast } from 'react-toastify';

export const useMutatePing = (controller, options) => {
  const toastRef = React.useRef();
  const loading = () =>
    (toastRef.current = toast.info('Pinging Controller...', {
      autoClose: false,
      closeOnClick: false,
      closeButton: false
    }));

  const success = (data) =>
    toast.update(toastRef.current, {
      type: data.message == 'online' ? toast.TYPE.SUCCESS : toast.TYPE.ERROR,
      closeButton: true,
      closeOnClick: true,
      render: <>{data.message == 'online' ? 'ONLINE' : 'OFFLINE'}</>,
      autoClose: 4000
    });

  const error = (data) =>
    toast.update(toastRef.current, {
      type: toast.TYPE.ERROR,
      closeButton: true,
      closeOnClick: true,
      render: <>Error Pinging...</>,
      autoClose: 4000
    });
  const mutation = useMutationWrapper(
    ['controller', 'ping', controller.id],
    (id) => Controller.ping(id),
    {
      noToast: true,
      onMutate: () => loading(),

      onSuccess: (data) => success(data),
      onError: (data) => error(data)
    }
  );
  return mutation;
};

export const useMutateDelete = (controller) => {
  const queryClient = useQueryClient();
  const { queryKey } = useHeadlessTable();
  return useMutationWrapper(
    ['controller', 'delete', controller?.id],
    (id) => Controller.delete(id),
    {
      successMessage: `Controller: ${controller?.name} was deleted.`,
      onMutate: (id) => {
        if (!queryKey) return;
        let data = queryClient.getQueryData(queryKey);
        if (!data) return;

        if (!data) return;
        const newData = {
          ...data,
          data: data.data.filter((row) => row.id != id)
        };

        queryClient.setQueryData(queryKey, newData);
      },
      onSuccess: () => {
        queryClient.removeQueries(['controllers']);
      }
    }
  );
};
export const useMutateReset = (controller) => {
  const queryClient = useQueryClient();
  return useMutationWrapper(
    ['controller', 'reset', controller?.id],
    (id) => Controller.reset(id),
    {
      invalidate: 'controllers',
      successMessage: `Controller started reset.`,
      onSuccess: () => queryClient.refetchQueries(['devices'])
    }
  );
};

export const useMutateSetDateTime = (controller) =>
  useMutationWrapper(
    ['controller', 'setDateTime'],
    (id) => ControllerConnectivity.setDateTime(id),
    {
      successMessage: `Controller Date and Time set successfully.`
    }
  );

export const useMutateRestore = (controller, options = {}) =>
  useMutationWrapper(
    ['controller', 'restore', controller.id],
    (id) => ControllerConnectivity.restore(id),
    options
  );

export const useMutateSetEventSubscription = (controller) =>
  useMutationWrapper(
    ['controller', 'setEventSubscription', controller.id],
    (id) => ControllerConnectivity.setEventSubscription(id)
  );

export const useMutateRenewEventSubscription = (controller) =>
  useMutationWrapper(
    ['controller', 'renewEventSubscription', controller.id],
    (id) => ControllerConnectivity.renewEventSubscription(id)
  );

export const useMutateUnsetEventSubscription = (controller) =>
  useMutationWrapper(
    ['controller', 'unsetEventSubscription', controller.id],
    (id) => ControllerConnectivity.unsetEventSubscription(id)
  );

export const useMutateCheckSync = (controller, options = {}) =>
  useMutationWrapper(
    ['controller', 'check-sync', controller.id],
    (id) => Controller.checkSync(id),
    options
  );

export const useMutateTestEventSubscription = (controller, options = {}) =>
  useMutationWrapper(
    ['controller', 'test-subscription', controller.id],
    (id) => Controller.testEventSubscription(id),
    options
  );

export const useMutateRestoreEvents = (controller) =>
  useMutationWrapper(
    ['controller', 'restore-events', controller?.id],
    (id) => Controller.restoreEvents(id),
    {}
  );

export const useMutateCreateDebugSubscription = (device) =>
  useMutationWrapper(['device', 'createDebugSubscription'], (input) =>
    Device.createDebugSubscription(input, device?.device_id)
  );

export const useMutateReboot = (device) =>
  useMutationWrapper(['device', 'reboot'], (id) => Device.reboot(id));
