import React from 'react';
import { useHistory } from 'react-router-dom';
import { MenuItem, MenuWrapper } from 'components/action-menu';

export default function ActionMenu({ data: floor, ...props }) {
  const history = useHistory();

  let handleEdit = (e) =>
    history.push(
      '/elevators/' + floor['elevator_id'] + '/floors/save/' + floor.door_token,
      {
        floor,
        desc: floor.door_name
      }
    );

  return (
    <MenuWrapper {...props}>
      <MenuItem label="Edit" onClick={handleEdit} />
    </MenuWrapper>
  );
}
