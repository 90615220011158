import styled from '@emotion/styled';

const Limiter = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
`;

export default Limiter;
