import React from 'react';

/** @jsxImportSource @emotion/react */
import {
  TabItem,
  TabGroup,
  TabPanel,
  TabPanels,
  TabList
} from 'components/tabs';
import { css } from '@emotion/react';
import {
  ExclamationCircle,
  PersonBadge,
  Search,
  CreditCard,
  CreditCard2Front,
  Plus
} from 'react-bootstrap-icons';
import { components } from 'react-select';
import Credential from 'api/credential';
import UsageChartHolder from 'components/charts/usage-chart-holder';
import AsyncSelect from 'components/headless-select/async';
import { useQueryWrapper } from 'utils/ajax';


const MenuList = (props) => {
  return (
    <components.MenuList {...props}>
      {Array.isArray(props.children)
        ? props?.children?.slice(0, 10)
        : props.children}
      {props.children.length > 10 && (
        <h6
          css={css`
            font-weight: 400;
          `}
        >
          Showing first 10 results
        </h6>
      )}
    </components.MenuList>
  );
};
const AccessLevelTabs = ({ al = {} }) => {

  // TODO test why this was being called and not used
  const credentialStats = useQueryWrapper(
    ['credential', 'stats'],
    () => Credential.stats(),
    {

    }
  );

  return (
    <TabGroup>
      <TabList
        css={css`
          width: 100%;
          box-shadow: unset;
          gap: 4rem;
          padding: 0px 16px;
          border-bottom: 1px solid var(--neutral-12);

          [role='tab'] {
            min-width: unset;
          }
        `}
      >
        <TabItem title="info">
          <ExclamationCircle size={24} />
        </TabItem>

        <TabItem title="credentials">
          <PersonBadge size={24} />
        </TabItem>
      </TabList>
      <TabPanels>
        <TabPanel
          as="div"
          css={css`
            .usage-chart {
              margin-top: auto;
              max-height: 200px;
            }
            padding: 1rem;

            display: flex;
            flex-direction: column;
            height: 100%;
            dl {
              display: grid;
              grid-template-rows: 14px 1fr;
              row-gap: 0.1rem;
            }

            dt {
              font-size: 12px;
              line-height: 100%;
              color: var(--neutral-01);
            }
            dd {
              font-weight: 600;
              font-size: 14px;
              line-height: 17px;
              color: var(--neutral-15);
              margin-bottom: 3rem;
            }
          `}
        >
          <dl>
            <dt css={css``}>Valid From</dt>
            <dd>{new Date(al.ValidFrom).toLocaleString()}</dd>
            <dt
              css={css`
                grid-column: 2;
                grid-row: 1;
              `}
            >
              Valid To
            </dt>
            <dd
              css={css`
                grid-column: 2;
                grid-row: 2;
              `}
            >
              {new Date(al.ValidTo).toLocaleString()}
            </dd>
            <dt>Auth Profile</dt>
            <dd
              css={css`
                grid-column: 1;
                grid-row: 4;
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  gap: 0.2rem;
                  svg {
                    width: 24px;
                    height: 24px;
                  }
                  span {
                    font-size: 12px;
                    color: var(--neutral-02);
                  }
                `}
              >
                {al.auth_profile_token == 'CardNrOnly' && <CreditCard2Front />}
                {al.auth_profile_token == 'CardOnly' && <CreditCard />}
                {al.auth_profile_token == 'CardPlusPin' && (
                  <div
                    css={css`
                      display: flex;
                      gap: 0.2rem;
                    `}
                  >
                    <CreditCard />
                    <Plus />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-123"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2.873 11.297V4.142H1.699L0 5.379v1.137l1.64-1.18h.06v5.961h1.174Zm3.213-5.09v-.063c0-.618.44-1.169 1.196-1.169.676 0 1.174.44 1.174 1.106 0 .624-.42 1.101-.807 1.526L4.99 10.553v.744h4.78v-.99H6.643v-.069L8.41 8.252c.65-.724 1.237-1.332 1.237-2.27C9.646 4.849 8.723 4 7.308 4c-1.573 0-2.36 1.064-2.36 2.15v.057h1.138Zm6.559 1.883h.786c.823 0 1.374.481 1.379 1.179.01.707-.55 1.216-1.421 1.21-.77-.005-1.326-.419-1.379-.953h-1.095c.042 1.053.938 1.918 2.464 1.918 1.478 0 2.642-.839 2.62-2.144-.02-1.143-.922-1.651-1.551-1.714v-.063c.535-.09 1.347-.66 1.326-1.678-.026-1.053-.933-1.855-2.359-1.845-1.5.005-2.317.88-2.348 1.898h1.116c.032-.498.498-.944 1.206-.944.703 0 1.206.435 1.206 1.07.005.64-.504 1.106-1.2 1.106h-.75v.96Z" />
                    </svg>
                  </div>
                )}
                {al.auth_profile_token == 'PINOnly' && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-123"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2.873 11.297V4.142H1.699L0 5.379v1.137l1.64-1.18h.06v5.961h1.174Zm3.213-5.09v-.063c0-.618.44-1.169 1.196-1.169.676 0 1.174.44 1.174 1.106 0 .624-.42 1.101-.807 1.526L4.99 10.553v.744h4.78v-.99H6.643v-.069L8.41 8.252c.65-.724 1.237-1.332 1.237-2.27C9.646 4.849 8.723 4 7.308 4c-1.573 0-2.36 1.064-2.36 2.15v.057h1.138Zm6.559 1.883h.786c.823 0 1.374.481 1.379 1.179.01.707-.55 1.216-1.421 1.21-.77-.005-1.326-.419-1.379-.953h-1.095c.042 1.053.938 1.918 2.464 1.918 1.478 0 2.642-.839 2.62-2.144-.02-1.143-.922-1.651-1.551-1.714v-.063c.535-.09 1.347-.66 1.326-1.678-.026-1.053-.933-1.855-2.359-1.845-1.5.005-2.317.88-2.348 1.898h1.116c.032-.498.498-.944 1.206-.944.703 0 1.206.435 1.206 1.07.005.64-.504 1.106-1.2 1.106h-.75v.96Z" />
                  </svg>
                )}
                <span>{al.auth_profile_token}</span>
              </div>
            </dd>
            {!credentialStats.isLoading && <>
              <dt
                css={css`
                  grid-column: 1;
                  grid-row: 5;
                `}
              >
                Credential Count ({al.credential_count} / {credentialStats.data?.total})
              </dt>
              <dd
                css={css`
                  grid-column: 1 / span 2;
                  grid-row: 6;
                  width: 100%;
                  display: flex;
                  align-items: center;
                  gap: 1rem;
                `}
              >
                <meter
                  min="0"
                  max="100"
                  value={al.credential_count / (credentialStats.data?.total / 100)}
                  low="40"
                  high="90"
                  css={css`
                    -webkit-meter-bar {
                      background: none; /* Required to get rid of the default background property */
                      background-color: var(--purple-base);
                    }
                    ::-moz-meter-bar {
                      background: none; /* Required to get rid of the default background property */
                      background-color: var(--purple-base);
                    }
                    width: 100%;
                    position: relative;
                    height: 10px;
                  `}
                ></meter>
                {(al.credential_count / (credentialStats.data?.total / 100)).toFixed(2)}%
              </dd>
            </>}
          </dl>
          <UsageChartHolder
            name={al.name}
            filters={{ access_level_id: al?.id }}
          />
        </TabPanel>

        <TabPanel
          as="div"
          css={css`
            padding: 1rem;
            height: 100%;
            display: flex;
            flex-direction: column;
          `}
        >
          <div
            css={css`
              display: flex;
              align-items:center;
              span {
                margin-left: auto;
                font-size: 12px;
                color: var(--neutral-01);
              }
              margin-bottom:1rem;l
            `}
          >
            <h3>Credentials</h3>
            <span>Total: {al?.credential_count}</span>
          </div>
          <AsyncSelect
            fetchFilters={{ accessLevel: al?.id }}
            fetchFn={Credential.search}
            data-compact
            data-inline
            css={css`
              .select__menu {
                box-shadow: none;
                border: none;
              }
              .select__menu-list {
                max-height: 100%;
                overflow: auto;
              }
              input {
                opacity: 1 !important ;
              }
            `}
            icon={Search}
            itemComponents={{ DropdownIndicator: null, MenuList }}
            simple
            isSearchable={true}
            getOptionValue={(e) => e.token}
            getOptionLabel={(e) => e.Description}
            menuIsOpen
            controlShouldRenderValue={false}
            value={null}
          />
        </TabPanel>
      </TabPanels>
    </TabGroup>
  );
};

export default AccessLevelTabs;
