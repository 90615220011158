import React from 'react';

import { css } from '@emotion/react';
import { Dialog, DialogContent, DialogTrigger } from './dialog.js';
/** @jsxImportSource @emotion/react */

const SnapshotModal = ({ url, children }) => {
  return (
    <Dialog>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent
        css={css`
          min-width: 30vw;
          width: fit-content;
          img {
            object-fit: contain;
            width: 100%;
            height: 100%;
          }
        `}
      >
        <img src={url}></img>
      </DialogContent>
    </Dialog>
  );
};

export default SnapshotModal;
