import React from 'react';
import { useQuery } from 'react-query';
import Schedule from 'api/schedule';
import EventCard from 'views/schedules/event-card';
import { useFormikContext, FieldArray } from 'formik';

import { css } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import EventModal from './event-modal';
import { DialogDisclosure } from 'reakit/Dialog';
import { useModal } from 'providers/modal-provider';

import { AuxiliaryButton } from 'components/buttons';
import { PlusLg } from 'react-bootstrap-icons';
import { ErrorMessage } from 'components/form';
import Spinner from 'components/spinner';

const styles = css`
  .error-message {
    margin-bottom: 8px;
  }
  > section {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 0px;
    border-bottom: 2px solid var(--neutral-12);
    > button {
      width: fit-content;
      text-transform: capitalize;
    }
  }
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;

    color: var(--neutral-00);
  }
`;

const EventForm = (props) => {
  let enabled = props?.schedule ? true : false;

  const { dialog, setModalState } = useModal();

  const { data, isSuccess } = useQuery(
    ['schedule', 'events', `${props.schedule?.id}`],
    () => Schedule.searchEvents(props?.schedule?.id),
    { enabled }
  );

  const mainForm = useFormikContext(null);

  React.useEffect(() => {
    if (!data) return;

    mainForm.setValues({
      ...mainForm.values,
      events: data.map((i) => ({
        ...i,
        days: JSON.parse(i.week_days),
        start: i.start,
        end: i.end,
        until: i.until ? i.until : '2036-06-01T11:30'
      }))
    });
  }, [data]);

  if (isSuccess || !enabled)
    return (
      <article css={styles}>
        <ErrorMessage name="events" />

        <section className="includes">
          <h1> Include Events</h1>

          <DialogDisclosure
            {...dialog}
            as={AuxiliaryButton}
            compact
            onClick={() =>
              setModalState({
                component: EventModal,
                eventIndex: null,
                eventType: 'include',
                mainForm,
                dialogProps: { hideOnClickOutside: false }
              })
            }
          >
            <PlusLg />
            Include Events
          </DialogDisclosure>

          <FieldArray
            name="events"
            render={({ remove }) =>
              mainForm.values.events.length > 0
                ? mainForm.values.events.map(
                    (event, index) =>
                      event.type == 'I' && (
                        <EventCard
                          event={event}
                          handleRemove={remove}
                          index={index}
                        />
                      )
                  )
                : null
            }
          />
        </section>

        <section className="excludes">
          <h1> Exclude Events</h1>
          <DialogDisclosure
            {...dialog}
            as={AuxiliaryButton}
            compact
            onClick={() =>
              setModalState({
                component: EventModal,
                eventIndex: null,
                eventType: 'exclude',
                mainForm,
                dialogProps: { hideOnClickOutside: false }
              })
            }
          >
            <PlusLg />
            Exclude Events
          </DialogDisclosure>
          <FieldArray
            name="events"
            render={({ remove }) =>
              mainForm.values.events.length > 0
                ? mainForm.values.events.map(
                    (event, index) =>
                      event.type == 'E' && (
                        <EventCard
                          event={event}
                          eventType="exclude"
                          handleRemove={remove}
                          index={index}
                        />
                      )
                  )
                : null
            }
          />
        </section>
      </article>
    );
  else return <Spinner />;
};

export default EventForm;
