/** @jsxImportSource @emotion/react */
import React from 'react';
import '../css/login.css';
import PasswordField from '../components/form/password-field';
import { useAuth } from 'providers/auth.js';
import { Redirect, useLocation } from 'react-router-dom';
import { Button } from 'components/buttons';
import { CheckBox, Input } from 'components/form';
import { Formik } from 'formik';
import { css, Global } from '@emotion/react';
import Background from 'assets/images/header-background.png';

import styled from '@emotion/styled';
import * as Yup from 'yup';
import { Facebook, Linkedin } from 'react-bootstrap-icons';
import { useMutationWrapper } from 'utils/ajax';
import RenderLogo from 'utils/use-logo';
import { useParams } from 'react-router-dom';
import QRCode from 'react-qr-code';
import Account from 'api/account';

const Footer = styled('footer')`
  position: fixed;
  bottom: 40px;
  padding: 24px 96px;

  width: 100%;
  display: flex;

  align-items: center;
  justify-content: space-between;
  gap: 20px;
  height: 12px;
  a {
    color: #868686 !important;
    font-size: 12px;
  }
`;

const footerIconStyles = css`
  width: 20px;
  height: 20px;
  color: #27ba62;
`;
const Login = () => {
  const { user } = useAuth();
  const { hash } = useParams();
  const { search } = useLocation();

  let $secret = new URLSearchParams(search).get('mfa');
  let $activation = new URLSearchParams(search).get('activation');

  const [formDisabled, setFormDisabled] = React.useState(
    $activation ? true : false
  );

  const SCHEMA = Yup.object().shape({
    password: Yup.string()
      .min(8, 'Password must be 8 charcters long')
      .required('Required')
  });

  const INITIAL_VALUES = {
    email: '',
    password: '',
    sms_code: '',
    tz_offset: new Date().getTimezoneOffset()
  };

  const recover = useMutationWrapper(['user', 'recover'], (input) =>
    Account.reset(input)
  );

  if (recover.isSuccess) return <Redirect to="/login" />;

  if (user?.role == 'I') return <Redirect to="/companies" />;
  else if (user?.role == 'A') return <Redirect to="/installers" />;
  else if (user?.role == 'W' || user?.role == 'R')
    return <Redirect to="/doors" />;
  return (
    <div
      css={css`
        background-image: url(${Background});
      `}
    >
      <Global
        styles={{
          button: {
            width: '100%'
          },
          body: {
            //   background: `linear-gradient(254.73deg, black 9.13%, #black 93.13%)`,
          }
        }}
      />
      <div
        css={css`
          height: 100vh;
          width: 100vw;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        `}
      >
        <div
          css={css`
            width: 450px;
          `}
        >
          <RenderLogo
            login
            css={css`
              margin-bottom: 20px;
              width: 450px;
              height: 100px;
            `}
          />

          <Formik
            validationSchema={SCHEMA}
            initialValues={INITIAL_VALUES}
            onSubmit={(values) => {
              values.mfa_code =
                values.d1 +
                values.d2 +
                values.d3 +
                values.d4 +
                values.d5 +
                values.d6;
              recover.mutate({
                password: values.password,
                key: hash,
                mfa_code: values.mfa_code
              });
            }}
          >
            {({ handleSubmit, setFieldValue, values }) => (
              <form
                css={css`
                  .field {
                    padding-bottom: 0px !important;
                  }
                  align-items: center;
                  display: flex;
                  flex-direction: column;

                  div {
                    letter-spacing: 0.75;
                  }
                  button {
                    margin-top: 12px;
                  }
                `}
                onSubmit={handleSubmit}
              >
                <input type="hidden" value={hash} />

                <PasswordField
                  css={css`
                    color: var(--neutral-00);
                    width: 450px;
                  `}
                  placeholder="Type your new password"
                  name="password"
                  label="New Password"
                  value={values.password}
                />

                {$secret && (
                  <section
                    css={css`
                      display: flex;
                      align-items: center;
                      flex-direction: column;
                    `}
                  >
                    <p
                      css={css`
                        font-size: 12px;
                        strong {
                          margin-left: 4px;
                        }
                      `}
                    >
                      Your account is enabled to use MFA, please scan the
                      following QR code into your authenticator app, or type
                      code:
                      <strong>{$secret}</strong>
                    </p>
                    <div>
                      <QRCode
                        size={140}
                        style={{
                          height: 'auto',
                          maxWidth: '100%',
                          width: '100%'
                        }}
                        value={`otpauth://totp/Cube%20Access?secret=${$secret}`}
                      />
                    </div>
                    <p
                      css={css`
                        font-size: 12px;
                        strong {
                          margin-left: 4px;
                        }
                      `}
                    >
                      After authenticating, please enter your 6-digits code
                      below:
                    </p>

                    <section
                      css={css`
                        display: flex;
                        gap: 12px;
                        justify-content: center;
                        input {
                          width: 65px;
                          height: 64px;
                          font-size: 24px;
                          font-weight: 700;
                          text-align: center;
                          border-radius: 5px;
                          color: var(--neutral-00);
                        }
                      `}
                    >
                      {[1, 2, 3, 4, 5, 6].map((n) => (
                        <Input
                          name={'d' + n}
                          value={values['d' + n]}
                          onChange={(ev) => {
                            // Handle pasting in the first input
                            const v = ev.target.value.replace(/\D/g, '');
                            if (n == 1 && v.length > 5) {
                              v.slice(-6)
                                .split('')
                                .forEach((d, idx) =>
                                  setFieldValue('d' + String(idx + 1), d)
                                );
                              return;
                            }

                            // Clips to 1 character
                            setFieldValue('d' + n, v[0]);

                            // Focus next element after typing
                            v[0] &&
                              ev.target.nextElementSibling &&
                              ev.target.nextElementSibling.focus();
                          }}
                          onKeyUp={(ev) => {
                            // Focus prev element on Backspace
                            ev.key == 'Backspace' &&
                              ev.target.previousElementSibling &&
                              ev.target.previousElementSibling.focus();
                          }}
                        />
                      ))}
                    </section>
                  </section>
                )}

                {new URLSearchParams(search).get('activation') && (
                  <div
                    css={css`
                      width: 100%;
                      display: flex;
                      flex-direction: column;
                      gap: 24px;
                    `}
                  >
                    <textarea
                      css={css`
                        resize: none;
                        margin-top: 12px;
                        width: 100%;
                        height: 200px;
                      `}
                    >
                      Privacy Policy Effective Date: July 18, 2024 Cube Access
                      Inc. ("we", "us", "our") is committed to protecting your
                      privacy. This Privacy Policy explains how we collect, use,
                      disclose, and safeguard your information when you use our
                      application and any related services (the "Service").
                      Please read this policy carefully to understand our
                      practices regarding your information and how we will treat
                      it. 1. Purpose for Collecting Personal Information We
                      collect personal information to provide you with our
                      Service, improve your user experience, and comply with
                      legal obligations. Your information helps us deliver a
                      more personalized and efficient service. 2. Types of
                      Personal Information Collected We may collect the
                      following types of information when you use our Service: *
                      Personal Information: This includes your name, email
                      address, telephone number, and credentials (e.g.,
                      username, password) necessary for the functionality of the
                      Service. * Usage Data: Information about how you interact
                      with our Service, including IP address, browser type,
                      pages visited, and other usage statistics. 3. Methods of
                      Collection We collect information in various ways,
                      including: * Directly from You: When you provide
                      information directly to us (e.g., during account creation
                      or customer support interactions). * Through Cookies and
                      Tracking Techniques: We may use cookies, web beacons, and
                      other tracking technologies to collect information about
                      your usage of the Service. You can control cookies through
                      your browser settings and other tools. 4. Use of Your
                      Information We may use the information we collect for
                      various purposes, including: * To provide, maintain, and
                      improve our Service; * To personalize your experience and
                      deliver content and product offerings relevant to your
                      interests; * To respond to inquiries, provide customer
                      support, and troubleshoot technical issues; * To send you
                      updates, newsletters, marketing communications, and other
                      information regarding our products and services. 5. Use,
                      Retention, and Disposal * Use: Your information is used
                      only for the purposes outlined in this policy. *
                      Retention: We retain your personal information for as long
                      as necessary to fulfill the purposes outlined in this
                      policy, comply with legal obligations, resolve disputes,
                      and enforce our agreements. * Disposal: Once your personal
                      information is no longer required, we will securely
                      dispose of it in accordance with our data retention and
                      deletion policies. 6. Choice and Consent By using our
                      Service, you consent to the collection, use, and
                      disclosure of your personal information as described in
                      this Privacy Policy. You may withdraw your consent at any
                      time by contacting us, but doing so may limit your ability
                      to use certain features of our Service. 7. Access You have
                      the right to access, update, and correct your personal
                      information. If you would like to review, modify, or
                      delete the information we have about you, please contact
                      us. 8. Disclosure to Third Parties We may disclose your
                      information to third parties only in the following
                      circumstances: * With your consent; * To comply with legal
                      obligations; * To protect and defend our rights and
                      property. 9. Security for Privacy We use industry-standard
                      security measures to protect your information from
                      unauthorized access or disclosure. 10. Quality We take
                      reasonable steps to ensure that the personal information
                      we collect is accurate, complete, and up-to-date. You are
                      responsible for ensuring the accuracy of the information
                      you provide to us. 11. Monitoring and Enforcement We
                      regularly review our practices to ensure compliance with
                      this Privacy Policy. If you have any concerns or
                      complaints about our privacy practices, please contact us.
                      We will address your concerns and take appropriate actions
                      to resolve any disputes. 12. Changes to This Privacy
                      Policy We may update this Privacy Policy from time to time
                      to reflect changes in our practices or for other
                      operational, legal, or regulatory reasons. We will notify
                      you of any changes by posting the updated policy on our
                      website or through other reasonable means. 13. Contact Us
                      If you have any questions about this Privacy Policy or our
                      practices, please contact us at or@cube-access.com. Terms
                      of Use Effective Date: July 18, 2024 These Terms of Use
                      ("Terms") govern your use of Cube Access ("we", "us",
                      "our") mobile application and related services (the
                      "Service"). By accessing or using our Service, you agree
                      to comply with and be bound by these Terms. 1. Acceptance
                      of Terms By using our Service, you agree to these Terms
                      and our Privacy Policy. If you do not agree, please do not
                      use our Service. 2. Eligibility You must be at least 18
                      years old to use our Service. By using our Service, you
                      represent and warrant that you meet this requirement. 3.
                      Account Registration To access certain features of our
                      Service, you may need to create an account. You agree to
                      provide accurate and complete information when
                      registering, and to keep your account information
                      up-to-date. 4. Use of the Service You agree to use the
                      Service only for lawful purposes and in accordance with
                      these Terms. You are responsible for all activities that
                      occur under your account. 5. Prohibited Conduct You agree
                      not to: * Use the Service for any illegal purpose or in
                      violation of any local, state, national, or international
                      law; * Infringe or violate the rights of any third party,
                      including intellectual property rights, privacy rights, or
                      contractual rights; * Interfere with or disrupt the
                      Service or servers or networks connected to the Service.
                      6. User Content You may submit, upload, or otherwise make
                      available content through the Service ("User Content").
                      You retain ownership of your User Content, but you grant
                      us a non-exclusive, royalty-free, worldwide, perpetual,
                      irrevocable, and sublicensable right to use, reproduce,
                      modify, adapt, publish, translate, create derivative works
                      from, distribute, perform, and display your User Content
                      in connection with the Service. 7. Privacy Your privacy is
                      important to us. Please review our Privacy Policy to
                      understand how we collect, use, and disclose information
                      that we receive from users. 8. Security We implement
                      industry-standard security measures to protect your
                      information. However, no method of transmission over the
                      internet or electronic storage is completely secure, and
                      we cannot guarantee absolute security. 9. Termination We
                      may terminate or suspend your account and access to the
                      Service, without prior notice or liability, for any
                      reason, including if you breach these Terms. 10.
                      Disclaimers The Service is provided on an "as is" and "as
                      available" basis. We disclaim all warranties, whether
                      express or implied, including the warranties of
                      merchantability, fitness for a particular purpose, and
                      non-infringement. 11. Limitation of Liability To the
                      fullest extent permitted by law, we shall not be liable
                      for any indirect, incidental, special, consequential, or
                      punitive damages, or any loss of profits or revenues,
                      whether incurred directly or indirectly, or any loss of
                      data, use, goodwill, or other intangible losses, resulting
                      from (a) your use of the Service; (b) any unauthorized
                      access to or use of our servers and/or any personal
                      information stored therein; and (c) any interruption or
                      cessation of transmission to or from the Service. 12.
                      Governing Law These Terms shall be governed by and
                      construed in accordance with the laws of United State
                      without regard to its conflict of law principles. 13.
                      Changes to Terms We may modify these Terms at any time. We
                      will notify you of any changes by posting the new Terms on
                      our website or through other reasonable means. Your
                      continued use of the Service after such changes
                      constitutes your acceptance of the new Terms. 14. Contact
                      Us If you have any questions about these Terms, please
                      contact us using our contact form on our website.
                    </textarea>
                    <CheckBox
                      label="I have read and accepted the Terms of use and Privacy
                      policy"
                      onChange={(e) => setFormDisabled(!e.target.checked)}
                    ></CheckBox>
                  </div>
                )}

                <Button
                  loading={recover.isLoading}
                  type="submit"
                  disabled={formDisabled}
                >
                  {new URLSearchParams(search).get('activation')
                    ? 'Activate'
                    : 'Recover'}
                </Button>

                <div
                  css={css`
                    height: 16px;
                    line-height: 16px;
                    font-weight: bold;
                    color: var(--red-base);
                  `}
                >
                  {recover?.error?.message}
                </div>
                {/* {JSON.stringify(values)} */}
              </form>
            )}
          </Formik>
        </div>
      </div>
      <Footer>
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 20px;
          `}
        >
          <RenderLogo
            css={css`
              height: 50px;
              width: 100px;
            `}
          />
          <span
            css={css`
              color: #868686 !important;
              font-size: 12px;
            `}
          >
            Cube Access - All Rights Reserved
          </span>
        </div>
        <a href="#">Terms And Conditions</a>
        <a href="#">Privacy Policy </a>
        <div
          css={css`
            display: flex;
            gap: 20px;
          `}
        >
          <Facebook css={footerIconStyles} />
          <Linkedin css={footerIconStyles} />
        </div>
      </Footer>
    </div>
  );
};

export default Login;
