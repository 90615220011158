import { useState, useCallback, useEffect } from 'react';
import { MEDIA_HOST_URL } from 'api/api-url';
// https://www.npmjs.com/package/react-audio-voice-recorder
const useAudioRecorder = ({ intercom_id }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [mediaRecorder, setMediaRecorder] = useState();
  const [timerInterval, setTimerInterval] = useState();
  const [recordingBlob, setRecordingBlob] = useState();
  const [socket, setSocket] = useState();

  useEffect(() => {
    if (socket) {
      return () => {
        socket && socket?.close();
        setSocket * null;
      };
    }
  }, [socket]);

  const _startTimer = () => {
    const interval = setInterval(() => {
      setRecordingTime((time) => time + 1);
    }, 1000);
    setTimerInterval(interval);
  };

  const _stopTimer = () => {
    timerInterval != null && clearInterval(timerInterval);
    setTimerInterval(undefined);
  };

  /**
   * Calling this method would result in the recording to start. Sets `isRecording` to true
   */
  const startRecording = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      var wsUri = `${MEDIA_HOST_URL}/intercom/${intercom_id}/audio`;
      let websocket = new WebSocket(wsUri);
      setSocket(websocket);

      websocket.onopen = function (evt) {
        setIsRecording(true);
        const recorder = new MediaRecorder(stream);
        setMediaRecorder(recorder);
        recorder.start(300);
        _startTimer();

        recorder.addEventListener('dataavailable', (event) => {
          //setRecordingBlob(event.data);

          const downloadBlob = new Blob([event.data], {
            type: `audio/wav`
          });

          if (websocket.readyState === 3)
            recorder.stream.getTracks().forEach((t) => t.stop());
          console.log(websocket.readyState);
          if (websocket.readyState !== 3) websocket.send(downloadBlob);
          // setMediaRecorder(null);
        });
      };

      websocket.onclose = function (evt) {
        console.error('socket closed: ', evt);
        stopRecording();
      };

      websocket.onerror = function (evt) {
        console.error('socket Error', evt);
        stopRecording();
      };
      if (timerInterval != null) return;
    } catch {}
  }, [timerInterval]);

  /**
   * Calling this method results in a recording in progress being stopped and the resulting audio being present in `recordingBlob`. Sets `isRecording` to false
   */
  const stopRecording = () => {
    console.log('stopping recorder...');
    mediaRecorder?.stop();
    setTimeout(() => {
      socket?.close();
    }, 500);
    _stopTimer();
    setRecordingTime(0);
    setIsRecording(false);
    setIsPaused(false);
    setSocket(null);
    setMediaRecorder(null);
  };

  /**
   * Calling this method would pause the recording if it is currently running or resume if it is paused. Toggles the value `isPaused`
   */
  const togglePauseResume = () => {
    if (isPaused) {
      setIsPaused(false);
      mediaRecorder?.resume();
      _startTimer();
    } else {
      setIsPaused(true);
      _stopTimer();
      mediaRecorder?.pause();
    }
  };

  return {
    startRecording,
    stopRecording,
    togglePauseResume,
    recordingBlob,
    isRecording,
    isPaused,
    recordingTime
  };
};

export default useAudioRecorder;
