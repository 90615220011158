import React from 'react';
import Template from 'base/template';
import Guest from 'api/guest';
import { columns } from 'views/guests/table-columns';
import Sidebar from 'views/guests/sidebar';
import IndexContent from 'base/index-content';
import ActionMenu from 'views/guests/action-menu';
import { useAuth } from 'providers/auth';
import { ReactComponent as GuestEmptyIcon } from 'assets/empty-icons/empty-guest.svg';
import { BookmarkCheckFill, XLg } from 'react-bootstrap-icons';

const cards = [
  {
    name: 'total',
    label: 'Total'
  },
  {
    name: 'in_today',
    label: 'In Today',
    color: 'var(--primary-base) ',
    background: 'var(--stats-backgound-green)',
    filter: { inToday: true },
    icon: BookmarkCheckFill
  },
  {
    name: 'disabled',
    label: 'Disabled',
    color: 'var(--red-base)',
    background: 'var(--stats-background-red)',
    filter: { enabled: false },
    icon: XLg
  }
];

const Guests = (props) => {
  const { enforce } = useAuth();
  return (
    <Template {...props}>
      <IndexContent
        newSorter
        statsQuery={Guest.stats}
        title="Guests"
        id="guests"
        cards={cards}
        query={Guest.search}
        columns={columns}
        addButton={
          enforce({ restrictIfCompanyUser: ['guestEdit'] })
            ? 'Add a Guest'
            : false
        }
        sidebar={Sidebar}
        selectable
        actionButton={ActionMenu}
        emptyIcon={GuestEmptyIcon}
        {...props}
      ></IndexContent>
    </Template>
  );
};

export default Guests;
