import React from 'react';
import User from 'api/user';
import Template from 'base/template';
import { Button, CancelButton } from 'components/buttons';
import FormProvider from 'providers/form-provider';
import { useMutationWrapper, useQueryWrapper } from 'utils/ajax';
import PageTitle from 'components/page-title';
import { Formik } from 'formik';
import UserAudit from 'views/users/user-audit';
import * as Yup from 'yup';
import {
  TabItem,
  TabGroup,
  TabPanel,
  TabPanels,
  TabList
} from 'components/tabs';
import { css } from '@emotion/react';
import { useHistory } from 'react-router';
import Content from 'base/content';
import UserFields from 'views/users/user-fields';
import { useAuth } from 'providers/auth';
import Breadcrumbs from 'components/breadcrumbs';
/** @jsxImportSource @emotion/react */

const UserProfile = (props) => {
  // load this form a query
  const { setUser, setGlobalUser } = useAuth();

  const user = props?.location?.state?.user || null;

  const history = useHistory();

  const SCHEMA = Yup.object().shape({});

  const { data, isLoading: isLoadingInitial } = useQueryWrapper(
    ['user', user.id],
    () => User.myself()
  );

  const [currentUser, setCurrentUser] = React.useState({});

  React.useEffect(
    () =>
      setCurrentUser({
        password: '',
        name: data?.name || '',
        email: data?.email || '',
        phone: data?.phone || '',
        enable_2fa: data ? (data?.enable_2fa == '1' ? true : false) : true,
        enable_mfa: data ? (data?.enable_mfa == '1' ? true : false) : true
      }),
    [data]
  );

  let { isLoading, mutate } = useMutationWrapper(
    ['user', 'save'],
    (input) => User.save(input, user?.user_id),
    {
      onSuccess(res) {
        setUser((prev) => ({ ...prev, ...res }));
        setGlobalUser((prev) => ({ ...prev, ...res }));
      }
    }
  );

  return (
    <Template {...props}>
      <Content {...props}>
        <PageTitle>Profile</PageTitle>
        <Breadcrumbs crumbs={props.crumbs} />
        <Formik
          enableReinitialize={true}
          validationSchema={SCHEMA}
          initialValues={currentUser}
          onSubmit={(values) => {
            mutate(values);
          }}
        >
          {({ handleSubmit }) => (
            <FormProvider
              mutateFunc={handleSubmit}
              disabled={isLoadingInitial || isLoading}
            >
              <input type="hidden" name="enabled" value="on"></input>

              <TabGroup>
                <TabList>
                  <TabItem>My Account</TabItem>
                  <TabItem>Audit Logs</TabItem>
                </TabList>
                <TabPanels
                  as="div"
                  css={css`
                    min-width: 40vw;
                    width: auto;
                    padding: 24px;
                  `}
                >
                  <TabPanel
                    as="div"
                    css={css`
                      display: grid !important;
                      grid-template-columns: 1fr 1fr !important;
                      gap: 24px;

                      footer {
                        display: flex;
                        padding: 24px 0px;
                      }
                    `}
                  >
                    <UserFields user={currentUser} />
                    <footer>
                      <Button type="submit">Submit</Button>
                      <CancelButton onClick={() => history.goBack()} />
                    </footer>
                  </TabPanel>
                  <TabPanel>
                    <UserAudit user={currentUser} />
                  </TabPanel>
                </TabPanels>
              </TabGroup>
            </FormProvider>
          )}
        </Formik>
      </Content>
    </Template>
  );
};

export default UserProfile;
