import { Card } from './card';
import {
  IconApiApp,
  IconApiAppOff,
  IconCheck,
  IconTrash,
  IconX
} from '@tabler/icons-react';
import { Button, CancelButton } from 'components/buttons';
import { useCancelFirmwareUpdate, useMutateSetDateTime } from '../actions';
import { useQueryWrapper } from 'utils/ajax';
import Device from 'api/device';
import { css } from '@emotion/react';
import { useMutateReset } from 'views/controllers/actions';
import useAlertDialog from 'providers/alert-dialog-provider';
/** @jsxImportSource @emotion/react */
import { useMutateDelete } from '../actions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import DeviceFirmwareUpgradeModal from '../device-firmware-upgrade-modal';
import { useAuth } from 'providers/auth';
import Alert from 'components/alert';
import { compareVersions } from 'compare-versions';

export default ({ device }) => {
  //const mutateDelete = useMutateUpgradeFirmware(device);
  if (!device) return null;
  const confirm = useAlertDialog();

  const { user } = useAuth();

  const { data, isLoading, isError } = useQueryWrapper(
    ['device', 'firmware', device.id],
    () => Device.params(device.id, { groups: 'firmware' }),
    { enabled: device.online == 1 },
    { noToast: true }
  );

  const cancelFirmwareUpgrade = useCancelFirmwareUpdate(device);

  let isNotSupported =
    compareVersions(
      device?.expected_firmware ?? '0.0.0',
      data?.firmware ?? '0.0.0'
    ) == -1;

  let isUpToDate =
    data?.firmware == device.expected_firmware &&
    data?.firmware == device.expected_firmware &&
    !isNotSupported;

  let isPendingUpgrade = new Date(device.firmware_upgrade_at) > new Date();

  const history = useHistory();

  return (
    <Card
      className="firmware"
      data-firmware={isUpToDate}
      css={css`
        &[data-firmware='true'] {
          --state-color: var(--light--primary--500);
        }
        &[data-firmware='false'] {
          --state-color: var(--light--others--red-400-base);
        }
      `}
    >
      {device.firmware_upgrade_at && (
        <div
          css={css`
            grid-column: 1/-1;
          `}
        >
          <Alert>
            <div>
              Upgrade is pending around:
              <time>
                {new Date(device.firmware_upgrade_at).toLocaleString()}
              </time>
            </div>
          </Alert>
        </div>
      )}
      <div className="icon">
        <IconApiApp color="var(--state-color)" />
      </div>
      <div className="content">
        <h2>Firmware</h2>
        <span className="description">Schedule A Firmware Upgrade</span>
        {data && (
          <div
            css={css`
              margin-top: 1rem;
              display: flex;
              flex-direction: column;
              gap: 0.2rem;
              span:nth-child(2) {
                font-size: 12px;
                opacity: 80%;
              }
            `}
          >
            <span>current version: {data?.firmware}</span>
            <span>expected version: {device?.expected_firmware}</span>
            <span>
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  gap: 0.5rem;
                `}
              >
                <span>
                  {isUpToDate
                    ? 'up to date'
                    : isNotSupported
                    ? 'not supported'
                    : 'outdated'}
                </span>
                {isUpToDate ? (
                  <IconCheck color="var(--light--primary--500)" />
                ) : (
                  <IconX color="var(--light--others--red-400-base)" />
                )}
              </div>
            </span>
          </div>
        )}
        {!data && !isLoading && <div>current version: N/A</div>}
      </div>
      <div className="action">
        {device.online == 1 &&
          !isNotSupported &&
          device.expected_firmware &&
          !isPendingUpgrade &&
          (!isUpToDate || user.role == 'A') && (
            <DeviceFirmwareUpgradeModal device={device}>
              <Button compact>Upgrade...</Button>
            </DeviceFirmwareUpgradeModal>
          )}

        {isPendingUpgrade && (
          <Button
            onClick={() => cancelFirmwareUpgrade.mutate()}
            loading={cancelFirmwareUpgrade.isLoading}
            css={css`
              background: var(--light--others--red-400-base);
              &:hover {
                background: var(--light--others--red-400-base);
              }
            `}
            compact
          >
            Cancel Upgrade
          </Button>
        )}
      </div>
    </Card>
  );
};
