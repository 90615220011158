import React from "react";
import { useHistory } from "react-router-dom";
import { MenuItem, MenuItemDelete, MenuWrapper } from "components/action-menu";
import { useDeleteMutate } from "./actions";
export default function ActionMenu({ data: badge, ...otherProps }) {
  const history = useHistory();
  const deleteMutate = useDeleteMutate(badge);

  let handleEdit = () =>
    history.push("/badges/save/" + badge?.id, {
      badge,
      desc: badge?.name,
    });

  const handleDelete = () =>
    confirm("Are you sure you want to remove this template?") &&
    deleteMutate.mutate(badge?.id, {});

  return (
    <MenuWrapper {...otherProps}>
      <MenuItem label="Edit" onClick={handleEdit} />
      <MenuItemDelete onClick={handleDelete} />
    </MenuWrapper>
  );
}
