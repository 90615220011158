import React from 'react';
import { css } from '@emotion/react';
import { IconButton } from 'components/buttons';
import { EraserFill, Fonts } from 'react-bootstrap-icons';
import useAlertDialog from 'providers/alert-dialog-provider';
/** @jsxImportSource @emotion/react */

export default ({
  mapRef,
  editLayerRef: ref,
  setSelectedLayer,
  modes,
  setModes
}) => {
  const confirm = useAlertDialog();

  return (
    <div
      css={css`
        display: flex;
        gap: 0.3rem;
        flex-wrap: wrap;
      `}
    >
      <IconButton
        title="Draw Rectangle"
        data-active={modes?.draw?.Rectangle?.enabled}
        onClick={() => {
          if (mapRef.current.pm.globalDrawModeEnabled())
            mapRef.current.pm.disableDraw();
          else mapRef.current.pm.enableDraw('Rectangle');
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-shape"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M5 5m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
          <path d="M19 5m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
          <path d="M5 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
          <path d="M19 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
          <path d="M5 7l0 10"></path>
          <path d="M7 5l10 0"></path>
          <path d="M7 19l10 0"></path>
          <path d="M19 7l0 10"></path>
        </svg>
      </IconButton>

      <IconButton
        title="Delate All Layers"
        onClick={async () => {
          if (
            await confirm({
              description: 'This action will delete all the layers, continue?'
            })
          )
            ref.current.clearLayers();
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-square-x"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z"></path>
          <path d="M10 10l4 4m0 -4l-4 4"></path>
        </svg>
      </IconButton>

      <IconButton
        title="Cut Mode"
        data-active={modes?.cut}
        onClick={() => {
          setSelectedLayer(null);
          if (mapRef.current.pm.globalCutModeEnabled())
            mapRef.current.pm.disableGlobalCutMode();
          else mapRef.current.pm.enableGlobalCutMode();
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-cut"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M7 17m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
          <path d="M17 17m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
          <path d="M9.15 14.85l8.85 -10.85"></path>
          <path d="M6 4l8.85 10.85"></path>
        </svg>
      </IconButton>

      <IconButton
        title="Draw Polygon"
        data-active={modes?.draw?.Polygon?.enabled}
        onClick={() => {
          if (mapRef.current.pm.globalDrawModeEnabled())
            mapRef.current.pm.disableDraw();
          else mapRef.current.pm.enableDraw('Polygon');
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-polygon"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          fill="none"
          strokeLineCap="round"
          strokeLineJoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M12 5m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
          <path d="M19 8m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
          <path d="M5 11m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
          <path d="M15 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
          <path d="M6.5 9.5l3.5 -3"></path>
          <path d="M14 5.5l3 1.5"></path>
          <path d="M18.5 10l-2.5 7"></path>
          <path d="M13.5 17.5l-7 -5"></path>
        </svg>
      </IconButton>

      <IconButton
        title="Draw Line"
        data-active={modes?.draw?.Line?.enabled}
        onClick={() => {
          if (mapRef.current.pm.globalDrawModeEnabled())
            mapRef.current.pm.disableDraw();
          else mapRef.current.pm.enableDraw('Line');
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <defs>
            <path
              id="line-a"
              d="M9.165 19.42l9.256-9.255a3.5 3.5 0 111.414 1.414l-9.256 9.256a3.5 3.5 0 11-1.414-1.414zM21.5 10a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm-14 14a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
            ></path>
          </defs>
          <g fill="none" fillRule="evenodd" transform="translate(-3 -3)">
            <mask id="line-b" fill="none">
              <use xlinkHref="#line-a"></use>
            </mask>
            <use
              fill="currentColor"
              fillRule="nonzero"
              xlinkHref="#line-a"
            ></use>
            <g fill="currentColor" mask="url(#line-b)">
              <path d="M0 0H30V30H0z"></path>
            </g>
          </g>
        </svg>
      </IconButton>

      <IconButton
        title="Erase Mode"
        data-active={modes?.removal}
        onClick={() => {
          if (mapRef.current.pm.globalRemovalModeEnabled())
            mapRef.current.pm.disableGlobalRemovalMode();
          else mapRef.current.pm.enableGlobalRemovalMode();
        }}
      >
        <EraserFill />
      </IconButton>

      <IconButton
        title="Global Edit Mode"
        data-active={modes?.edit}
        onClick={() => {
          if (mapRef.current.pm.globalEditModeEnabled())
            mapRef.current.pm.disableGlobalEditMode();
          else mapRef.current.pm.enableGlobalEditMode();
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-triangle-square-circle"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M12 3l-4 7h8z"></path>
          <path d="M17 17m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
          <path d="M4 14m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z"></path>
        </svg>
      </IconButton>

      <IconButton
        title="Global Drag Mode"
        data-active={modes?.drag}
        onClick={() => {
          if (mapRef.current.pm.globalDragModeEnabled())
            mapRef.current.pm.disableGlobalDragMode();
          else mapRef.current.pm.enableGlobalDragMode();
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-drag-drop"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M19 11v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2"></path>
          <path d="M13 13l9 3l-4 2l-2 4l-3 -9"></path>
          <path d="M3 3l0 .01"></path>
          <path d="M7 3l0 .01"></path>
          <path d="M11 3l0 .01"></path>
          <path d="M15 3l0 .01"></path>
          <path d="M3 7l0 .01"></path>
          <path d="M3 11l0 .01"></path>
          <path d="M3 15l0 .01"></path>
        </svg>
      </IconButton>

      <IconButton
        ttiel="Draw Text"
        onClick={() => {
          if (mapRef.current.pm.globalDrawModeEnabled())
            mapRef.current.pm.disableDraw();
          else mapRef.current.pm.enableDraw('Text');
        }}
      >
        <Fonts />
      </IconButton>
    </div>
  );
};
