import React from "react";
import { css } from "@emotion/react";
import { useAuth } from "providers/auth";
/** @jsxImportSource @emotion/react */
const styles = css`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  span {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
  svg {
    width: 24px;
    height: 24px;
  }
`;
const DeviceSiteFormatter = ({ device }) => {
  const { user } = useAuth();
  return (
    <article css={styles}>
      <span>
        <strong>{device.site_name}</strong>
      </span>
      {!user.company_id && <span>{device.company_name}</span>}
    </article>
  );
};

export default DeviceSiteFormatter;
