import React from 'react';

import Template from 'base/template';
import { ReactComponent as ElevatorIcon } from 'assets/elevator-icon.svg';
import 'css/dashboard.css';
import 'css/chart.css';
import EventList from 'components/event-list';

import { useQueryWrapper } from 'utils/ajax';

import Content from 'base/content';
import Company from 'api/company';

import {
  Building,
  CameraVideo,
  DoorClosed,
  HddNetwork
} from 'react-bootstrap-icons';
/** @jsxImportSource @emotion/react **/
import { css } from '@emotion/react';
import PageTitle from 'components/page-title';
import { useEventList } from 'hooks/use-event-list';
import styled from '@emotion/styled';
import HealthProfile from 'views/companies/health-profile';
import ActiveCompany from './active-company';
import CompanyCount from './company-count';
import DeviceCount from './device-count';
import Device from 'api/device';
import { Badge } from 'components/badge';
import GenericDoor from 'api/generic_door';
import DeviceEventList from 'views/devices/device-event-list';
import ReactECharts from 'echarts-for-react';
import { useAuth } from 'providers/auth';
import RestoreChart from 'components/charts/restore-chart';
import { IconAlertTriangle, IconChartTreemap } from '@tabler/icons-react';
export const RadioStyles = css`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  button {
    padding: 0.5rem;
    border-radius: 4px;
    &[aria-checked='true'] {
      font-weight: 500;
      background: var(--neutral-05);
    }
  }
`;

export const Section = styled('section')`
  [role='radiogroup'] {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem;
  }

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  background: var(--neutral-09);
  color: var(--neutral-00);
  border-radius: 4px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  header {
    padding: 0.75rem;
    width: 100%;
    /* display: flex;
    align-items: center; */
    gap: 0.5rem;
    .chart-type-select {
      margin-left: auto;
    }
    h4 {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 125%;

      margin-bottom: 0;
      color: var(--neutral-02);
    }
    h5 {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 125%;

      color: var(--neutral-02);
    }
  }
`;

const viewStyle = css`
  padding-top: 6px;
  overflow: auto;

  > main {
    [role='badge'] {
      font-size: 1rem;
      min-height: none;
      max-height: none;
      grid-row: 1 / span 2;
    }
    .echarts-for-react {
    }
    display: grid;

    gap: 1rem;
    grid-template:
      'issues issues issues issues events' 150px
      'stats stats stats stats events' 250px
      'health health health health events' 275px
      'restore restore restore restore events' 275px
      'tree-map tree-map tree-map tree-map tree-map' auto
      / 1fr 1fr 1fr 1fr 1.5fr;

    margin-top: 1rem;
    #stats {
      grid-area: stats;
      overflow-y: auto;
    }
    #issues {
      grid-area: issues;
      overflow-y: auto;
    }
    #tree-map {
      grid-area: tree-map;
      width: 100%;
      height: 625px;
      > div {
        width: 100%;
        height: 100%;
      }
    }
    #issues,
    #stats {
      > ul {
        display: flex;
        justify-content: flex-start;
        height: 100%;
        width: 100%;
        padding: 1rem;
        gap: 1rem;
        > li {
          .count-chart,
          .sparkline {
            height: 80px;
            grid-row: 3;
            grid-column: 1 / span 4;
          }
          flex: 1;
          border: 1px solid #eceff3;
          border-radius: 4px;
          height: fit-content;
          &:hover:enabled {
            filter: brightness(90%);
          }
          background: var(--neutral-09);
          border-radius: 5px;
          box-shadow: 0px 5px 48px -5px rgba(0, 0, 0, 0.05);
          display: grid;
          grid-template-columns: auto 1fr auto auto;
          column-gap: 1.2rem;
          align-items: center;
          padding: 0.7rem 0.75rem;

          span.value {
            width: 100%;
            font-size: 24px;
            font-weight: 700;
            color: var(--neutral-00);
            grid-row: 2;
          }
          span.name {
            grid-row: 1;
            grid-column: 2;
            white-space: nowrap;
            font-size: 12px;
            text-transform: capitalize;
            color: var(--secondary-font-color);
          }
          div.icon {
            height: 48px;
            width: 48px;
            display: flex;
            border-radius: 4px;
            justify-content: center;
            align-items: center;

            padding: 0.4rem;
            grid-row: 1 / span 2;
            svg {
              width: 24px;
              height: 24px;
            }
          }
          section {
            grid-row: 1 / span 2;
          }
        }
      }
    }

    #health {
      grid-area: health;
    }
    #restore {
      grid-area: restore;
    }
    #events {
      grid-area: events;
    }
    #active-company {
      grid-area: active-company;
    }
    #device-count {
      grid-area: device-count;
    }
    #company-count {
      grid-area: company-count;
    }
  }
`;

const StyledBadge = ({ count }) => {
  const color = count > 0 ? 'success' : count != 0 ? 'error' : 'gray';

  return (
    <Badge
      title="Last 30 days movement"
      color={color}
      css={css`
        font-size: 11px;
      `}
    >
      {count > 0 ? '+' : count != 0 ? '-' : ''}
      {count}%
    </Badge>
  );
};
var groupBy = function (xs, key) {
  if (!xs) return null;
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

function getPercent(arr) {
  if (!arr) return;

  arr = Object.values(arr);

  if (arr[0] == 0) return 0;
  return Math.ceil(((arr[0] - arr[arr.length - 1]) / arr[0]) * 100);
}

const Sparkline = ({ data, name = '' }) => {
  if (!data || Object.values(data).every((value) => value === 0))
    return (
      <div
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          font-size: 12px;
          color: var(--secondary-font-color);
        `}
      >
        no data
      </div>
    );

  let color = getPercent(data) == 0 ? '#7f8795' : '#008e5b';
  const option = {
    animation: false,
    padding: [0, 0, 0, 0],
    tooltip: {
      trigger: 'axis',
      confine: true,
      formatter:
        "<div class='tooltip'><span class='axis'>{b0}</span><br/><span class='data'>{c0}"
    },
    grid: {
      top: 25,
      left: 10,
      right: 10,
      bottom: 20
    },
    xAxis: {
      //show: false,
      type: 'category',
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        showMaxLabel: true,
        showMinLabel: true,
        fontSize: 11,
        interval: 7,
        formatter: (value) => value.split('-')[2]
      },

      data: Object.keys(data).reverse()
    },
    yAxis: {
      //  min: Math.min([...Object.values(data)]),
      show: false,
      type: 'value'
    },
    series: [
      {
        name: name,
        color,
        areaStyle: {
          normal: {
            opacity: 0.1,
            color
          }
        },
        data: Object.values(data).reverse(),
        type: 'line',

        symbolSize: '0'
      }
    ]
  };
  return (
    <ReactECharts
      css={css`
        .tooltip {
          padding: 0.5rem;
          display: flex;
          flex-direction: column;
          gap: 0.2rem;
          font-size: 12px;
          label {
            font-size: 11px;
          }
          .axis {
            font-weight: 500;
          }
          .data {
            font-size: 25px;
            font-weight: bold;
          }

          margin-top: 0.2rem;
          font-size: 12px;
        }
      `}
      className="count-chart"
      option={option}
      style={{ height: '80px', width: '100%' }}
    />
  );
};

function InstallerDashboard(props) {
  // const query = useEventList({
  //   filters: {
  //     category: 'device',
  //     level: 'critical',
  //     level: ['critical', 'warning']
  //   }
  // });

  const { data: devicesTree, isLoading: devicesTreeLoading } = useQueryWrapper(
    ['device', 'getAll'],
    () => Device.getAll(),
    {}
  );

  let groupByCompany = groupBy(devicesTree, 'company_id');
  let companyOnline = {};

  for (let c in groupByCompany) {
    companyOnline[c] = !groupByCompany[c].find((device) => !device.online);
  }

  let obj = {};

  const RED = '#E53935';
  const GREEN = '#4CAF50';
  const SITES_SPLIT = 1; // amount of sites to show another level

  for (let device of devicesTree || []) {
    obj[device.company_id] = obj[device.company_id] ?? {
      children: [],
      label: {
        color: 'white',
        fontSize: '16',
        position: 'insideBottomLeft'
      },
      itemStyle: {
        borderWidth: 0,
        borderRadius: 4,
        // color: device.online ? '#b0e2d0' : '#ffcaca',
        color: companyOnline[device.company_id] ? GREEN : RED
      },
      name: device.company_name,
      type: 'company',
      value: 0
    };
    obj[device.company_id].children.push({
      type: 'device',
      name: device.name,
      last_offline_at: device.last_offline_at,
      value: 1,
      online: device.online ? 1 : 0,
      label: {
        color: 'white',
        fontSize: '16',
        position: 'insideBottomLeft'
      },
      itemStyle: {
        borderWidth: 0,
        borderRadius: 4,
        // color: device.online ? '#b0e2d0' : '#ffcaca',
        color: device.online ? GREEN : RED // full color
      }
    });
    obj[device.company_id].value++;
  }

  obj = Object.values(obj);

  let option = {
    emphasis: {
      disabled: true
    },
    tooltip: {
      formatter: function (info) {
        if (!info.data) return '<div></div>';
        if (info.data?.type == 'company') return `<div>${info.data.name}</div>`;
        else if (info.data.type == 'device')
          return `<div>
          <div>${info.data.name}</div>
          <time style='font-size: 14px; margin-top:1rem;'>${
            info.data.online == 1
              ? ''
              : info.data.last_offline_at
              ? 'Went offline: ' +
                new Date(info.data.last_offline_at).toLocaleString()
              : ''
          }
          </div>
        `;
        else return '<div></div>';
      }
    },
    series: [
      {
        width: '95%',
        breadcrumb: {
          top: '95%',

          //  height: 20,
          show: Object.values(companyOnline).length > SITES_SPLIT,
          show: false
          // emphasis: {
          //   itemStyle: {
          //     testStyle: {
          //       backgroundColor: 'white'
          //     }
          //   }
          // },
          // itemStyle: {
          //   color: 'white',

          //   textStyle: {
          //     color: 'black',
          //     backgroundColor: 'white'
          //   }
          // }
        },
        levels: [
          {
            //color: ['#942e38', '#aaa', '#269f3c'],
            itemStyle: {
              // borderWidth: 6,
              // borderColor: '#333',
              gapWidth: 12
            }
          },
          {
            upperLabel: {
              show: true,
              height: 30,
              fontSize: 16,
              padding: 4
            },
            // color: ['#942e38', '#aaa', '#269f3c'],
            itemStyle: {
              borderColor: '#EEEEEE',
              borderWidth: 14,
              borderRadius: 4,
              gapWidth: 4
            }
          },
          {
            //color: ['#942e38', '#aaa', '#269f3c'],
            upperLabel: {
              show: true,
              height: 26,
              fontSize: 16
            },
            itemStyle: {
              borderColor: '#EEEEEE',
              borderWidth: 22,
              borderRadius: 4,
              gapWidth: 8
            }
          }
        ],
        drillDownIcon: '',
        name: 'Device Tree',
        roam: false,
        nodeClick: 'link',
        type: 'treemap',
        leafDepth: 2,
        data: obj
        // only show sites first if greater than 5
        // Object.values(siteOnline).length > SITES_SPLIT
        //   ? [{ name: 'Sites', children: obj }]
        //   : obj
      }
    ]
  };

  const { data: stats, isLoading: statsLoading } = useQueryWrapper(
    [`company`, `stats`],
    () => Company.stats()
  );

  const { data: companyCount } = useQueryWrapper(
    ['company', 'count'],
    () => Company.getDailyCount(),
    {
      staleTime: 4 * (60 * 1000),
      cacheTime: 6 * (60 * 1000)
      // select: (res) => getPercent(Object.values(res))
    }
  );

  const { data: deviceCount } = useQueryWrapper(
    ['device', 'count'],
    () => Device.getDailyCount(),
    {
      staleTime: 4 * (60 * 1000),
      cacheTime: 6 * (60 * 1000)
      //select: (res) => getPercent(Object.values(res))
    }
  );

  const { data: doorCount } = useQueryWrapper(
    ['door', 'count'],
    () => GenericDoor.getDailyCount(),
    {
      staleTime: 4 * (60 * 1000),
      cacheTime: 6 * (60 * 1000)
      // select: (res) => getPercent(Object.values(res))
    }
  );

  const {
    user: { installer_features: features }
  } = useAuth();

  return (
    <Template {...props} title={false}>
      <Content css={viewStyle} {...props}>
        <PageTitle>Dashboard</PageTitle>
        <main>
          <Section id="issues">
            <header>
              <h4>Issues</h4>
            </header>
            <ul>
              <li>
                <div
                  css={css`
                    filter: ${statsLoading ? 'grayscale(1)' : 'grayscale(0)'};
                    background: ${stats?.issues != 0
                      ? 'var(--stats-background-red)'
                      : 'var(--stats-background-green)'};
                    color: ${stats?.issues != 0
                      ? 'var(--red-base)'
                      : 'var(--primary-base)'};
                  `}
                  className="icon"
                >
                  <Building />
                </div>
                <span className="name">Companies with Issues</span>
                <span className="value">{stats?.issues}</span>
              </li>
              <li>
                <div
                  css={css`
                    filter: ${statsLoading ? 'grayscale(1)' : 'grayscale(0)'};

                    background: ${stats?.device_issues != 0
                      ? 'var(--stats-background-red)'
                      : 'var(--stats-background-green)'};
                    color: ${stats?.device_issues != 0
                      ? 'var(--red-base)'
                      : 'var(--primary-base)'};
                  `}
                  className="icon"
                >
                  <HddNetwork />
                </div>
                <span className="name">Device Issues</span>
                <span className="value">{stats?.device_issues}</span>
              </li>

              <li>
                <div
                  css={css`
                    filter: ${statsLoading ? 'grayscale(1)' : 'grayscale(0)'};
                    background: ${stats?.device_alerts != 0
                      ? 'var(--stats-background-red)'
                      : 'var(--stats-background-green)'};
                    color: ${stats?.device_alerts != 0
                      ? 'var(--red-base)'
                      : 'var(--primary-base)'};
                  `}
                  className="icon"
                >
                  <IconAlertTriangle />
                </div>
                <span className="name">Device Alerts</span>
                <span className="value">{stats?.device_alerts}</span>
              </li>

              <li>
                <div
                  className="icon"
                  css={css`
                    filter: ${statsLoading ? 'grayscale(1)' : 'grayscale(0)'};

                    background: ${stats?.door_issues != 0
                      ? 'var(--stats-background-red)'
                      : 'var(--stats-background-green)'};
                    color: ${stats?.door_issues != 0
                      ? 'var(--red-base)'
                      : 'var(--primary-base)'};
                  `}
                >
                  <DoorClosed />
                </div>
                <span className="name">Door Issues</span>
                <span className="value">{stats?.door_issues}</span>
              </li>
            </ul>
          </Section>
          <Section id="stats">
            <header>
              <h4>Statistics</h4>
            </header>
            <ul>
              <li>
                <div
                  className="icon"
                  css={css`
                    filter: ${statsLoading ? 'grayscale(1)' : 'grayscale(0)'};
                    background: #f3f5f9;
                  `}
                >
                  <Building />
                </div>
                <span className="name">Companies</span>
                <span className="value">{stats?.total}</span>
                {/* <Badge color={`${companyCount > 0 ? 'success' : 'warning'}`}>
                  {companyCount > 0 ? '+' : companyCount != 0 ? '-' : ''}
                  {companyCount}%
                </Badge> */}
                <StyledBadge count={getPercent(companyCount)} />
                <section className="sparkline">
                  <Sparkline data={companyCount} name="companies" />
                </section>
              </li>
              <li>
                <div
                  className="icon"
                  css={css`
                    filter: ${statsLoading ? 'grayscale(1)' : 'grayscale(0)'};
                    background: #f3f5f9;
                  `}
                >
                  <HddNetwork />
                </div>
                <span className="name">Devices</span>
                <span className="value">{stats?.device_count}</span>

                <StyledBadge count={getPercent(deviceCount)} />
                <section className="sparkline">
                  <Sparkline data={deviceCount} name="devices" />
                </section>
              </li>
              <li>
                <div
                  className="icon"
                  css={css`
                    filter: ${statsLoading ? 'grayscale(1)' : 'grayscale(0)'};
                    background: #f3f5f9;
                  `}
                >
                  <DoorClosed />
                </div>
                <span className="name">Doors</span>
                <span className="value">{stats?.door_count}</span>
                <StyledBadge count={getPercent(doorCount)} />
                <section className="sparkline">
                  <Sparkline data={doorCount} name="doors" />
                </section>
              </li>

              {features?.cameras_enabled && (
                <li>
                  <div
                    className="icon"
                    css={css`
                      filter: ${statsLoading ? 'grayscale(1)' : 'grayscale(0)'};
                      background: #f3f5f9;
                    `}
                  >
                    <CameraVideo />
                  </div>
                  <span className="name">Cameras</span>
                  <span className="value">{stats?.camera_count}</span>
                  <section className="sparkline">
                    {/* <StyledBadge count={getPercent(doorCount)} /> */}
                    <Sparkline data={{}} />
                  </section>
                </li>
              )}
              {features?.elevators && (
                <li>
                  <div
                    className="icon"
                    css={css`
                      filter: ${statsLoading ? 'grayscale(1)' : 'grayscale(0)'};
                      background: #f3f5f9;
                    `}
                  >
                    <ElevatorIcon />
                  </div>
                  <span className="name">Elevators</span>
                  <span className="value">{stats?.elevator_count}</span>
                  <section className="sparkline">
                    {/* <StyledBadge count={getPercent(doorCount)} /> */}
                    <Sparkline data={{}} />
                  </section>
                </li>
              )}
            </ul>
          </Section>
          {/* <ActiveCompany data-shaded="true" /> */}
          {/* <Section id="company-count" data-shaded="true">
            <header>
              <div>
                <h4>Company Count</h4>
                <h5>30 day company growth trend</h5>
              </div>
            </header>
            <CompanyCount />
          </Section>
          <Section id="device-count" data-shaded="true">
            <header>
              <div>
                <h4>Device Count</h4>
                <h5>30 day device growth trend</h5>
              </div>
            </header>

            <DeviceCount />
          </Section> */}

          <Section id="health" data-shaded="true">
            <header>
              <div>
                <h4>Health</h4>
                <h5>Last 14 days offline/online count </h5>
              </div>
            </header>

            <HealthProfile chartOptions={{ title: { show: false } }} />
          </Section>

          <Section id="restore" data-shaded="true">
            <header>
              <div>
                <h4>Restore</h4>
                <h5>Last 14 days of restores </h5>
              </div>
            </header>

            <RestoreChart
              simple
              filters={{ lastDays: '14 day' }}
              height="100%"
            />
          </Section>
          <Section id="events">
            <header>
              <h4>Recent Critical Events</h4>
            </header>
            <DeviceEventList />
          </Section>

          <Section id="tree-map">
            <header>
              <h4>Device Tree Map</h4>
              <h5>Current offline/online devices </h5>
            </header>
            {devicesTree && Array.isArray(obj) && obj?.length > 0 ? (
              <ReactECharts
                option={option}
                style={{ height: '600px', padding: 5 }}
              />
            ) : (
              <div
                css={css`
                  > svg {
                    opacity: 20%;
                  }
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-direction: column;
                  label {
                    font-size: 12px;
                  }
                `}
              >
                <IconChartTreemap size={256} stroke={0.75} />
                {!devicesTreeLoading && <label>no data found</label>}
              </div>
            )}
          </Section>
        </main>
      </Content>
    </Template>
  );
}

export default InstallerDashboard;
