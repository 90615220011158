import React from "react";
import styled from "@emotion/styled";

const PageTitle = styled("h1")`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 100%;
  display: flex;
  align-items: center;
  /* identical to box height, or 24px */
  text-transform: capitalize;

  color: var(--neutral-00);
  margin-bottom: 0;
`;

export default PageTitle;
