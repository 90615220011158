import React from 'react';
import { Link } from 'react-router-dom';

import { css } from '@emotion/react';
import { ChevronRight } from 'react-bootstrap-icons';
/** @jsxImportSource @emotion/react */

const styles = css`
  display: flex;
  align-items: center;
  color: var(--neutral-00);
  font-size: 11px;
  font-weight: 500;
  margin-top: 4px;
  margin-bottom: 1rem;

  span {
    font-weight: 700;
  }

  svg {
    height: 9px;
    margin: 0 4px;
    color: var(--secundary-base);
  }

  a {
    color: var(--neutral-00);
    text-decoration: underline;

    &:hover {
      color: var(--secundary-base);
    }
  }
`;

const Breadcrumbs = ({ crumbs }) => {
  if (!crumbs) return null;
  if (crumbs?.length == 1) return null;
  // Don't render a single breadcrumb.

  return (
    <nav css={styles}>
      {/* Link back to any previous steps of the breadcrumb. */}
      {crumbs.map(({ path, title }, key) => {
        return key + 1 === crumbs.length ? (
          <span key={key}>{title}</span>
        ) : (
          <React.Fragment key={key}>
            <Link key={key} to={path}>
              {title}
            </Link>
            <ChevronRight />
          </React.Fragment>
        );
      })}
    </nav>
  );
};

export default Breadcrumbs;
