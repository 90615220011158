import React from 'react';
import styled from '@emotion/styled';
import { Menu } from '@headlessui/react';

export const StyledMenuButton = styled('button')`
  white-space: nowrap;
  display: flex;
  gap: 0.7rem;
  flex-direction: row;
  align-items: center;
  padding: 14px 16px;
  /**margin-inline: 4px;**/
  border-radius: 4px;
  position: static;
  min-width: 228px;
  color: ${(props) =>
    props.delete ? 'var(--red-v-01)' : 'var(--secundary-base)'};
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  transition: background ease 300ms;
  &:hover {
    background: var(--secundary-v-02);
  }
  &[aria-disabled] {
    opacity: 50%;
    cursor: default;
  }
  &:disabled {
    opacity: 50%;
    cursor: default;
  }
  svg {
    width: 16px;
    height: 16px;
  }
`;

const MenuItemButton = React.forwardRef((props, ref) => {
  return (
    <StyledMenuButton ref={ref} {...props} active>
      {props.children}
      <span>{props.label}</span>
    </StyledMenuButton>
  );
});

export default MenuItemButton;
