import React from 'react';
import Template from 'base/template';
import { useQueryWrapper } from 'utils/ajax';
import { useNotFound } from 'hooks/not-found-hook';
import { useParams } from 'react-router-dom';
import Content from 'base/content';
import PageTitle from 'components/page-title';
import Breadcrumbs from 'components/breadcrumbs';
import styled from '@emotion/styled';
import { subMinutes } from 'date-fns';

import { Section } from 'views/dashboard/user-dashboard';

import { css } from '@emotion/react';

/** @jsxImportSource @emotion/react */
import { ArrowClockwise } from 'react-bootstrap-icons';
import { AuxiliaryButton } from 'components/buttons';
import { useQueryClient } from 'react-query';
import PeriodSelect from 'views/reports/period-select';

import SidebarEmptyHolder from 'components/sidebar-empty-holder';
import Spinner from 'components/spinner';
import {
  TabItem,
  TabGroup,
  TabPanel,
  TabPanels,
  TabList
} from 'components/tabs';

import {
  IconAccessPointOff,
  IconX
} from '@tabler/icons-react';
import Device from 'api/device';
import DeviceMetrics from 'api/device_metrics';
import ReactECharts from 'echarts-for-react';
import { useQueries } from 'react-query';
import SmallBadge from 'components/small-badge';
import { useInstallMetricApp } from 'views/devices/actions';
import { DialogTrigger, DialogContent, Dialog } from 'components/dialog.js';
import { ArrowsFullscreen } from 'react-bootstrap-icons';
import ChartInterval from 'components/chart-interval';
import AsyncSelect from 'components/headless-select/async';

const TS_COLORS = [
  '#0074D9', // Blue
  '#FF851B', // Orange
  '#001F3F', // Navy
  '#FF4136', // Red
  '#2ECC40', // Green
  '#7FDBFF', // Light Blue
  '#FFDC00', // Yellow
  '#F012BE', // Pink
  '#3D9970', // Teal
  '#B10DC9', // Purple
  '#85144b', // Maroon
  '#39CCCC', // Aqua
  '#01FF70', // Lime
  '#FF3860', // Salmon
  '#444', // Dark Gray
  '#999' // Gray
];

const TOPICS = {
  mem_free: 'Free Memory',
  conns: 'Active TCP connections',
  cpu_usage: 'CPU Usage',
  rx: 'Bytes Recieved',
  tx: 'Bytes Transfered'
};
const EmptyTopicsHolder = () => {
  return (
    <div
      css={css`
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      `}
    >
      <SidebarEmptyHolder
        icon={IconAccessPointOff}
        title="No Data found"
        subtitle="Not enough data was collected or monitoring plugin not present on device"
      />
    </div>
  );
};

const DeviceTimeSeriesChartHolder = ({
  devices,
  topic,
  interval = 1,
  from,
  to
}) => {
  const queries = useQueries(
    devices.map((device) => {
      return {
        queryKey: ['device-metrics', device?.id, topic, interval, from, to],
        queryFn: () =>
          DeviceMetrics.search({
            device: device.id,
            topic,
            interval,
            from: from ?? subMinutes(new Date(), 3600).toISOString(),
            to: to
          }),
        staleTime: 60000,

        select: (data) => data.data
      };
    })
  );

  const isLoading = queries?.find((query) => query.isLoading === true);
  const isRefetching = queries?.find((query) => query.isRefetching == true);
  const noData = queries?.every(
    (query) => query?.data?.length == 0 || !query.data
  );

  // const { data } = useQueryWrapper(
  //   ['device-metrics', device.id, topic, interval],
  //   () => DeviceMetrics.search({ device: device.id, topic, interval })
  // );

  const option = {
    animation: false,

    graphic: {
      elements: [
        noData && {
          type: 'text',
          left: 'center',
          top: 'center',
          style: {
            text: 'no data',
            fontSize: 12,
            fill: '#7f879',
            opacity: 0.3
          }
        }
      ]
    },
    dataZoom: !noData && [
      {
        xAxisIndex: [0],
        type: 'inside',
        start: 0, // change to 90 to focus
        end: 100
      },
      {
        xAxisIndex: [0],

        borderColor: 'transparent',
        dataBackground: {
          areaStyle: {
            color: '#2480d6'
          }
        },
        showDetail: false,
        height: 30,
        bottom: 20,
        selectedDataBackground: {
          areaStyle: {
            color: '#2480d6'
          }
        },
        fillerColor: 'rgba(255,255,255,0.50)'
      }
    ],

    grid: [
      {
        top: 30,
        bottom: 80,
        left: 60,
        right: 30
      }
    ],
    tooltip: {
      confine: true,
      trigger: 'axis',
      axisPointer: {
        animation: false
      }
    },
    xAxis: {
      type: 'time',
      splitLine: {
        show: false
      },
      axisLine: {
        show: !noData
      }
    },
    yAxis: {
      type: 'value',
      boundaryGap: [0, '100%'],
      splitLine: {
        show: false
      }
    },
    series: queries?.map((q, idx) => {
      return {
        color: TS_COLORS[idx],
        name: devices[idx].name,

        type: 'line',
        showSymbol: false,
        data: q.data
      };
    })
  };

  return (
    <ReactECharts
      css={css``}
      notMerge={true}
      opts={{ renderer: 'svg' }}
      loadingOption={{
        showSpinner: true,
        textColor: '#000',
        maskColor: 'rgba(255, 255, 255, 0.8)',
        fontWeight: 'normal',
        text: '',
        spinnerRadius: 15,

        fontStyle: 'normal',
        lineWidth: 1,
        zlevel: 0,

        fontFamily: 'inherit'
      }}
      showLoading={isLoading || isRefetching}
      option={option}
      style={{
        width: '100%',
        padding: 0,
        height: '100%',
        background: 'var(--neutral-09)'
      }}
    />
  );
};

const ChartDialog = ({ topic, device }) => {
  const [filter, setFilter] = React.useState({ interval: 1 });
  const [refresh, setRefresh] = React.useState();
  const [devices, setDevices] = React.useState([device]);

  const handleRemoveValue = (e) => {
    let arr = devices.filter((val) => val.id !== e);
    setDevices(arr);
  };

  return (
    <Dialog>
      <DialogTrigger
        css={css`
          padding: 0.2rem;
        `}
      >
        <ArrowsFullscreen size={18} />
      </DialogTrigger>
      <DialogContent
        //portalOpacity={1}
        css={css`
          height: 80vh;
          width: 80vw;
          display: flex;
          flex-direction: column;
          .echarts-for-react {
            pointer-events: auto;
          }
          > section.dialog-content {
            display: flex;
            flex-direction: column;
          }
        `}
      >
        <header
          css={css`
            display: flex;
            padding: 0.5rem;
            width: 100%;
            gap: 1rem;
            justify-content: end;
            margin-left: auto;
            align-items: center;
            .period-input {
              width: 300px;
            }
            .select__wrapper {
              width: 350px;
            }
            > label {
              margin-right: auto;
            }
            > h3 {
              margin-right: auto;
            }
          `}
        >
          <h3>{TOPICS?.[topic]}</h3>
          <ChartInterval filter={filter} setFilter={setFilter} />
          <PeriodSelect
            globalFilter={filter}
            data-compact
            selection={filter}
            setSelection={setFilter}
            showValue={true}
          />
          <AsyncSelect
            checkboxes={false}
            placeholder="Add Devices"
            noCheckboxes
            smallOption
            isClearable={false}
            fetchFn={Device.search}
            fetchFilters={{ metrics: true }}
            data-compact
            isMulti={true}
            value={devices}
            controlShouldRenderValue={false}
            hideSelectedOptions={true}
            onChange={(e) => setDevices(e ?? [])}
          />
        </header>
        <div
          css={css`
            margin-bottom: 1rem;
            display: flex;
            gap: 1rem;
            align-items: center;
            flex-wrap: wrap;
          `}
        >
          {devices?.map((d, idx) => (
            <div
              css={css`
                padding: 0.15rem;
                padding-inline: 0.25rem;
                //box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
                display: inline-flex;
                align-items: center;
                border: 1px solid ${TS_COLORS[idx]};
                gap: 1rem;
                .circle {
                  width: 16px; /* Set the width and height to be the same for a perfect circle */
                  height: 16px;
                  background-color: ${TS_COLORS[
                    idx
                  ]}; /* Set the background color */
                  border-radius: 50%; /* Use 50% border-radius to create a circle */
                }
              `}
            >
              <span className="circle"></span>
              {d.name}
              <button onClick={() => handleRemoveValue(d?.id)}>
                <IconX size={16} />
              </button>
            </div>
          ))}
        </div>
        <DeviceTimeSeriesChartHolder
          topic={topic}
          devices={devices}
          refetchInterval={refresh}
          interval={filter.interval}
          from={filter.from}
          to={filter.to}
        />
      </DialogContent>
    </Dialog>
  );
};

const TimeSeriesChartHolder = ({
  device,
  topics,
  interval = 1,
  config = {},
  ...props
}) => {
  const queries = useQueries(
    topics.map((topic) => {
      return {
        queryKey: ['device-metrics', device.id, topic, interval],
        queryFn: () =>
          DeviceMetrics.search({
            device: device.id,
            topic,
            interval,
            from: subMinutes(new Date(), 3600).toISOString()
          }),
        staleTime: 60000,

        select: (data) => data.data
      };
    })
  );

  const isLoading = queries?.find((query) => query.isLoading === true);
  const isRefetching = queries?.find((query) => query.isRefetching == true);
  const noData = queries?.every(
    (query) => query?.data?.length == 0 || !query.data
  );

  // const { data } = useQueryWrapper(
  //   ['device-metrics', device.id, topic, interval],
  //   () => DeviceMetrics.search({ device: device.id, topic, interval })
  // );

  const option = {
    animation: false,
    color: props.color,
    graphic: {
      elements: [
        noData && {
          type: 'text',
          left: 'center',
          top: 'center',
          style: {
            text: 'no data',
            fontSize: 12,
            fill: '#7f879',
            opacity: 0.3
          }
        }
      ]
    },

    grid: [
      {
        top: 30,
        bottom: 30,
        left: 60,
        right: 30
      }
    ],
    tooltip: {
      confine: true,
      trigger: 'axis',
      axisPointer: {
        animation: false
      }
    },
    xAxis: {
      type: 'time',
      splitLine: {
        show: false
      },
      axisLine: {
        show: !noData
      }
    },
    yAxis: {
      type: 'value',
      boundaryGap: [0, '100%'],
      splitLine: {
        show: false
      }
    },
    series: queries?.map((q, idx) => {
      return {
        name: topics[idx],

        type: 'line',
        showSymbol: false,
        data: q.data
      };
    })
  };

  return (
    <ReactECharts
      css={css``}
      notMerge={true}
      opts={{ renderer: 'svg' }}
      loadingOption={{
        showSpinner: true,
        textColor: '#000',
        maskColor: 'rgba(255, 255, 255, 0.8)',
        fontWeight: 'normal',
        text: '',
        spinnerRadius: 15,

        fontStyle: 'normal',
        lineWidth: 1,
        zlevel: 0,

        fontFamily: 'inherit'
      }}
      showLoading={isLoading || isRefetching}
      option={option}
      style={{
        width: '100%',
        padding: 0,
        height: '300px',
        background: 'var(--neutral-09)'
      }}
    />
  );
};

const Header = styled('header')`
  &[data-alert='true'] {
    background: var(--red-v-02) !important;
    color: var(--red-v-01) !important;
  }
  background: #fafafa;
  display: flex;
  padding: 0.5rem;
  gap: 1rem;
  width: 100%;
  align-items: center;
  > button {
    margin-left: auto;
  }
`;

const Main = styled('main')`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export default (props) => {
  const { id } = useParams();

  const [device, setDevice] = React.useState(
    props?.location?.state?.device || {}
  );

  const query = useQueryWrapper(['device', 'get', id], () => Device.get(id), {
    enabled: !device?.id
  });

  const { data: topics, isLoading } = useQueryWrapper(
    ['device', id, 'getTopicList'],
    () => DeviceMetrics.getTopicList({ device: id }),
    { enabled: !!device }
  );

  React.useEffect(() => {
    if (!query.data) return;
    setDevice(query.data);
  }, [query.data]);

  useNotFound(id, query, props);

  const [filter, setFilter] = React.useState({ interval: 30 });
  const queryClient = useQueryClient();

  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleInputChange = (inputValue) => {
    const filteredList = inputValue
      ? topics.filter((item) =>
          item?.toLowerCase()?.includes(inputValue?.toLowerCase())
        )
      : topics;
    setFilteredTopicList(filteredList);
    setInputValue(inputValue);
  };

  const [filteredTopicList, setFilteredTopicList] = React.useState();
  const [inputValue, setInputValue] = React.useState();

  React.useEffect(() => {
    if (!topics) return;

    setFilteredTopicList(topics);
  }, [topics]);

  const { data } = useQueryWrapper(
    ['device', 'getSystemLog', device?.id],
    () => Device.getDeviceMetricsLog(device?.id),
    { enabled: !!device && device.online == 1 }
  );

  const { data: s3AppVersion, isLoading: appVersionLoading } = useQueryWrapper(
    ['device-metrics', 'app-version', device?.id],
    () => DeviceMetrics.getAppCurrentVersion(),
    { enabled: !!device && device.online == 1 }
  );

  const { data: apps } = useQueryWrapper(
    ['device', 'apps', device?.id],
    () => Device.getAppInfo(device?.id),
    {
      enabled: !!device && device.online == 1
    }
  );

  let installedAppVersion = apps?.find(
    (app) => app.id == 'device_metrics'
  )?.Version;

  const installMetricApp = useInstallMetricApp(device);

  return (
    <Template {...props}>
      <Content {...props}>
        <PageTitle>{device?.name}</PageTitle>
        <Breadcrumbs crumbs={props.crumbs} />
        {(query.isLoading || isLoading) && <Spinner />}
        {device?.id && (
          <>
            {device && !(query.isLoading || isLoading) && (
              <div
                css={css`
                  flex: 1;
                  display: flex;
                  align-items: center;
                  gap: 0.3rem;
                `}
              >
                <SmallBadge>
                  app version: {installedAppVersion ?? 'N/A'}
                </SmallBadge>

                {device &&
                  !(query.isLoading || isLoading || appVersionLoading) &&
                  installedAppVersion !== s3AppVersion && (
                    <AuxiliaryButton
                      loading={installMetricApp.isLoading}
                      onClick={() => installMetricApp.mutate()}
                      css={css`
                        height: 24px;
                      `}
                    >
                      Update to {s3AppVersion}
                    </AuxiliaryButton>
                  )}
              </div>
            )}

            {!query.isLoading &&
              device &&
              topics &&
              (topics?.length > 0 ? (
                <Main>
                  <TabGroup
                    selectedIndex={selectedIndex}
                    onChange={setSelectedIndex}
                  >
                    <TabList
                      css={css`
                        width: 100%;
                        box-shadow: unset;
                        gap: 2rem;
                        padding: 0px 16px;
                        background: transparent;
                        box-shadow: inset 0px -1px 0px var(--neutral-10);
                        [role='tab'] {
                          min-width: unset;
                        }
                        > div:last-of-type {
                          margin-left: auto;
                        }
                      `}
                    >
                      <TabItem title="History">History</TabItem>
                      <TabItem title="Logs">Logs</TabItem>
                      <div>
                        <AuxiliaryButton
                          compact
                          onClick={() => {
                            queryClient.resetQueries([
                              'device-metrics',
                              device.id
                            ]);
                            queryClient.resetQueries([
                              'device',
                              'getSystemLog',
                              device.id
                            ]);
                          }}
                        >
                          <ArrowClockwise size={24} />
                        </AuxiliaryButton>
                      </div>
                    </TabList>
                    <TabPanels>
                      <TabPanel
                        css={css`
                          margin-top: 1rem;
                          background: transparent;
                          display: grid;
                          grid-template-columns: 1fr 1fr;
                          grid-template-rows: 300px;
                          gap: 2rem;
                          flex: 1;
                          width: 100%;
                          gap: 1rem;
                          flex-direction: column;
                          > * {
                            min-height: 300px;
                          }
                        `}
                      >
                        <Section>
                          <Header data-alert={device?.alert_state?.includes(t)}>
                            <label>Bytes transfered</label>
                            <ChartDialog topic="tx" device={device} />
                          </Header>
                          <TimeSeriesChartHolder
                            device={device}
                            topics={['tx']}
                            color={['#6f49c0']}
                          />
                        </Section>
                        <Section>
                          <Header data-alert={device?.alert_state?.includes(t)}>
                            <label>Bytes recieved</label>
                            <ChartDialog topic="rx" device={device} />
                          </Header>
                          <TimeSeriesChartHolder
                            device={device}
                            topics={['rx']}
                            color={['#2480d6']}
                          />
                        </Section>
                        <Section>
                          <Header data-alert={device?.alert_state?.includes(t)}>
                            <label>CPU Usage</label>
                            <ChartDialog topic="cpu_usage" device={device} />
                          </Header>
                          <TimeSeriesChartHolder
                            device={device}
                            topics={['cpu_usage']}
                            color={['#f5861f']}
                          />
                        </Section>
                        <Section>
                          <Header data-alert={device?.alert_state?.includes(t)}>
                            <label>Active TCP connections</label>
                            <ChartDialog topic="conns" device={device} />
                          </Header>
                          <TimeSeriesChartHolder
                            device={device}
                            topics={['conns']}
                            color={['#00c37d']}
                          />
                        </Section>
                        <Section>
                          <Header data-alert={device?.alert_state?.includes(t)}>
                            <label>Free memory</label>
                            <ChartDialog topic="mem_free" device={device} />
                          </Header>
                          <TimeSeriesChartHolder
                            device={device}
                            topics={['mem_free']}
                            color={['#FFC300']}
                          />
                        </Section>
                        <Section>
                          <Header data-alert={device?.alert_state?.includes(t)}>
                            <label>Disk Usage</label>
                            <ChartDialog topic="disk_use" device={device} />
                          </Header>
                          <TimeSeriesChartHolder
                            device={device}
                            topics={['disk_use']}
                            color={['#581845 ']}
                          />
                        </Section>
                      </TabPanel>

                      <TabPanel
                        css={css`
                          background: transparent;
                          display: flex;
                          flex: 1;
                          height: 500px;
                          max-height: 500px;
                          margin-top: 1rem;
                          width: 100%;
                          gap: 1rem;
                          flex-direction: column;
                          overflow: auto;
                        `}
                      >
                        {/* <TableProvider
                      columns={columns}
                      tableData={data ?? []}
                      //  query={Device.getDeviceMetricsLog(device.id)}
                    >
                      {({}) => {
                        return <Table />;
                      }}
                    </TableProvider> */}

                        <div
                          css={css`
                            background: rgb(14, 30, 37);
                            font-family: monospace;
                            color: white;
                            padding: 0.75rem;
                            display: flex;
                            flex: 1;
                            flex-direction: column;
                            gap: 0.5rem;
                            // overflow: auto;
                          `}
                        >
                          {data &&
                            Array.isArray(data) &&
                            data?.map((line, idx) => {
                              return (
                                <article
                                  key={idx}
                                  css={css`
                                    display: grid;
                                    grid-template-columns: auto auto 1fr;
                                    word-wrap: none;
                                    gap: 1rem;
                                    user-select: all !important;
                                  `}
                                >
                                  <time
                                    css={css`
                                      color: var(--dark--primary--500);
                                    `}
                                  >
                                    [{new Date(line.time).toLocaleString()}]
                                  </time>
                                  <div>{line.host}</div>
                                  <div>{line.message}</div>
                                </article>
                              );
                            })}
                        </div>
                      </TabPanel>
                    </TabPanels>
                  </TabGroup>
                </Main>
              ) : (
                <EmptyTopicsHolder />
              ))}
          </>
        )}
      </Content>
    </Template>
  );
};
