import { Card } from './card';
import { IconReport } from '@tabler/icons-react';
import { useMutateGetServerReport } from '../actions';
import { Button } from 'components/buttons';

export default ({ device }) => {
  const reportMutate = useMutateGetServerReport(device);

  const handleServerReport = () => {
    reportMutate.mutate(device.device_id, {
      onSuccess: (data) => {
        if (!data.data) return;
        const blob = new Blob([data.data], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${
          device.name
        }-server-report-${new Date().toLocaleDateString()}.txt`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });
  };

  return (
    <Card classname="ping">
      <div className="icon">
        <IconReport />
      </div>
      <div className="content">
        <h2>Server Report</h2>
        <span className="description">Generate Server Report</span>
      </div>
      <div className="action">
        <Button
          disabled={reportMutate.isLoading}
          onClick={handleServerReport}
          compact
        >
          Download Report
        </Button>
      </div>
    </Card>
  );
};

{
  /* <a
  href={API_HOST_URL + `/device_api/getServerReport/${device.device_id}`}
  download="server.txt"
  target="_blank"
  rel="noreferrer noopener"
>
  <Button compact>Server Report</Button>
</a>; */
}
