import React from 'react';
import User from 'api/user';
import Template from 'base/template';
import { Button, CancelButton, SecondaryButton } from 'components/buttons';
import FormProvider from 'providers/form-provider';
import { Redirect } from 'react-router-dom';
import { useMutationWrapper } from 'utils/ajax';
import { useRedirect } from 'hooks/redirect-hook';
import UserForm from 'views/users/form';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PageTitle from 'components/page-title';
import Content from 'base/content';
import { useAuth } from 'providers/auth';
import Breadcrumbs from 'components/breadcrumbs';
import { useParams } from 'react-router-dom';
import { useNotFound } from 'hooks/not-found-hook';
import { useQueryWrapper } from 'utils/ajax';

const Save = (props) => {
  const { id } = useParams();
  const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  const [user, setUser] = React.useState(props?.location?.state?.user);

  const { user: currentUser } = useAuth();

  let userRole, enforceMFA;
  if (currentUser.managing || currentUser.company_id) userRole = 'C';
  else if (currentUser.accessing) userRole = 'I';
  else userRole = currentUser.role;

  enforceMFA = currentUser.mfa_enforce == '1' || user?.company_mfa_enforce == 1;

  // The validation doesnt work if Admin -- myabe limit editing of users when admin?

  const SCHEMA = Yup.object().shape({
    name: Yup.string()
      .min(4, 'Name is too short')
      .max(40, 'Name must be 40 characters max')
      .required('Required'),
    email: Yup.string().email('Invalid email').required('Required').nullable(),
    password: !user
      ? Yup.string().when('active', {
          is: true,
          then: (schema) => schema.required('Required')
        })
      : '',
    sites: Yup.array().nullable().min(1, 'Select at least 1 option'),
    phone: Yup.string()
      .matches(phoneRegex, 'Invalid Phone')
      .required('Required'),
    allowed_installers: Yup.array()
      .nullable()
      .when('permission', {
        is: (permission) =>
          userRole == 'V' &&
          permission[0] != 'fa' &&
          !permission?.includes('allInstallers'),
        then: (schema) =>
          schema.required('Required').min(1, 'Select at least 1 Installer')
      }),
    allowed_companies: Yup.array()
      .nullable()
      .when('permission', {
        is: (permission) =>
          userRole == 'I' &&
          permission[0] != 'fa' &&
          !permission?.includes('allCompanies'),
        then: (schema) =>
          schema.required('Required').min(1, 'Select at least 1 Company')
      }),
    allowed_sites: Yup.array()
      .nullable()
      .when('permission', {
        is: (permission) =>
          userRole == 'C' &&
          permission[0] != 'fa' &&
          !permission?.includes('allSites'),
        then: (schema) =>
          schema.required('Required').min(1, 'Select at least 1 Site')
      })
  });

  const ALLOWED_PERMISSIONS = [
    'fa',
    'writeUsers',
    'allInstallers',
    'writeInstallers',

    'allCompanies',
    'writeCompanies',

    'allSites',

    'dashboard',
    'monitoring',
    'cameraView',
    'personView',
    'personEdit',
    'alView',
    'alEdit',
    'scheduleView',
    'scheduleEdit',
    'guestView',
    'guestEdit'
  ];

  const DEFAULT_PERMISSIONS = [
    'dashboard',
    'monitoring',
    'personView',
    'personEdit',
    'alView',
    'alEdit',
    'scheduleView',
    'scheduleEdit',
    'guestView',
    'guestEdit',
    'cameraView',
    'allSites'
  ];
  const INITIAL_VALUES = {
    formIsDisabled: true,
    enable_2fa: user ? (user.enable_2fa == '1' ? true : false) : false,
    enable_mfa: !enforceMFA
      ? user
        ? user.enable_mfa == '1'
          ? true
          : false
        : false
      : true,

    password: '',
    name: user?.name || '',
    email: user?.email || '',
    phone: user?.phone || '',
    enabled: Boolean(user?.enabled ?? true),
    permission:
      user && user.permission
        ? user.permission.split(',')
        : userRole != 'C'
        ? []
        : currentUser.role == 'C'
        ? currentUser.permission
        : DEFAULT_PERMISSIONS,
    home_path: user?.home_path,
    devices_view: user?.devices_view
  };

  if (userRole == 'I')
    INITIAL_VALUES.allowed_companies =
      user?.allowed_companies.map?.((i) => Number(i)) ?? [];

  if (userRole == 'V')
    INITIAL_VALUES.allowed_installers =
      user?.allowed_installers.map?.((i) => Number(i)) ?? [];

  if (userRole == 'C')
    INITIAL_VALUES.allowed_sites =
      user?.allowed_sites.map?.((i) => Number(i)) ?? [];

  let { isLoading, mutate, isSuccess } = useMutationWrapper(
    ['user', 'save'],
    (input) => User.save(input, user?.id),
    {}
  );

  const query = useQueryWrapper(['user', 'get', id], () => User.get(id), {
    enabled: Boolean(!user && id),
    cacheTime: 0,
    staleTime: Infinity
  });

  const { redirect, setRedirect } = useRedirect(isSuccess);

  useNotFound(id, query, props);

  React.useEffect(() => {
    if (!query?.data) return;
    setUser(query.data);
  }, [query?.data]);

  return (
    <Template {...props}>
      <Content {...props}>
        {redirect && <Redirect to={props.backTo ?? '/accounts'} />}
        <PageTitle>{user ? user?.name : 'Add Account'}</PageTitle>
        <Breadcrumbs crumbs={props.crumbs} />
        {(user || !id) && (
          <Formik
            enableReinitialize={true}
            validationSchema={SCHEMA}
            initialValues={INITIAL_VALUES}
            onSubmit={(values) => {
              // filter permission dont transefer to backend if not in valid permnission array
              if (user?.role == 'C')
                values.permission = values.permission.filter((p) =>
                  ALLOWED_PERMISSIONS.includes(p)
                );

              if (
                !values.permission.includes('cameraView') &&
                !values.permission.includes('fa') &&
                values.home_path == 'cameras'
              )
                values.home_path = 'default';

              if (
                !values.permission.includes('monitoring') &&
                !values.permission.includes('fa') &&
                values.home_path == 'monitoring-views'
              )
                values.home_path = 'default';

              if (
                !values.permission.includes('dashboard') &&
                !values.permission.includes('fa') &&
                values.home_path == 'dashboard'
              )
                values.home_path = 'default';

              delete values.formIsDisabled;
              mutate(values);
            }}
          >
            {({ handleSubmit, handleReset, values }) => (
              <FormProvider
                name="user"
                mutateFunc={handleSubmit}
                disabled={isLoading}
              >
                <UserForm role={userRole} user={user} />
                <footer>
                  <Button disabled={values.formIsDisabled} type="submit">
                    Submit
                  </Button>
                  <CancelButton onClick={() => setRedirect(true)} />
                </footer>
              </FormProvider>
            )}
          </Formik>
        )}
      </Content>
    </Template>
  );
};

export default Save;
