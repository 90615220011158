import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  MenuSeperator,
  MenuItem,
  MenuItemDelete,
  MenuWrapper,
  MenuLabel
} from 'components/action-menu';

import { useQueryClient } from 'react-query';
import { useHeadlessTable } from 'providers/headless-table-provider';
import { useMutationWrapper } from 'utils/ajax';
import AudioClip from 'api/audio-clip';
import useAlertDialog from 'providers/alert-dialog-provider';
export const useDeleteMutate = () => {
  const queryClient = useQueryClient();
  const { queryKey, tableDispatch } = useHeadlessTable();
  return useMutationWrapper(
    ['audio-clip', 'delete'],
    (id) => AudioClip.delete(id),
    {
      successMessage: 'Audio Clip removed',

      onMutate: (id) => {
        let data = queryClient.getQueryData(queryKey);

        const newData = {
          ...data,
          data: data.data.filter((row) => row.id != id)
        };

        queryClient.setQueryData(queryKey, newData);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['audio-clips']);
      }
    }
  );
};

export default function ActionMenu({ data: ac, ...otherProps }) {
  const deleteMutate = useDeleteMutate();
  const { state } = useHeadlessTable();
  const confirm = useAlertDialog();
  const handleDelete = async () => {
    if (
      await confirm({
        description: `Are you sure you want to delete this Audio Clip`
      })
    )
      console.log(deleteMutate);
    deleteMutate.mutate(ac.id);
  };

  return (
    <MenuWrapper {...otherProps}>
      <MenuItemDelete onClick={handleDelete} />
    </MenuWrapper>
  );
}
