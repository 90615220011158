import React from 'react';
import { css } from '@emotion/react';
import Company from 'api/company';
import { useQuery } from 'react-query';
import ReactECharts from 'echarts-for-react';
import Spinner from 'components/spinner';

/** @jsxImportSource @emotion/react */
import { dateTime } from 'utils/time';
import EmptyHolder from 'components/empty-holder';
import getDate from 'date-fns/getDate';
import * as echarts from 'echarts';
import styled from '@emotion/styled';
import Event from 'api/event';
import { useAuth } from 'providers/auth';

const VerticalBarHealthline = ({ company, filters = {}, ...props }) => {
  const { user } = useAuth();
  const [chartSeries, setChartSeries] = React.useState([]);

  const { isLoading, data } = useQuery(
    ['events', 'device-health-events', company?.id, user?.focused_site],
    () =>
      Event.getDeviceHealthEvents({
        ...filters,
        site: user?.focused_site,
        company: company?.id
      }),
    {
      staleTime: 10 * (60 * 1000), // 10 mins
      cacheTime: 15 * (60 * 1000), // 15 mins
      refetchOnMount: false
    }
  );

  const option = {
    animation: false,
    grid: {
      left: 40,
      top: 30,
      right: 40,
      bottom: 40
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    legend: {
      data: ['offline', 'online'],
      icon: 'circle',
      left: 0
    },

    xAxis: [
      {
        axisLabel: {
          show: false
        },
        axisLine: {
          show: false
        },
        // splitLine: {
        //   show: false
        // },
        type: 'value'
      }
    ],
    yAxis: [
      {
        // splitLine: {
        //   show: false
        // },
        axisLabel: {
          show: true,
          fontSize: 11,
          fontWeight: 600,

          textStyle: {
            color: '#59606C'
          }
        },
        axisLine: {
          show: false
        },
        type: 'category',
        axisTick: {
          show: false
        },
        data: data?.map((d) => d.time)
      }
    ],

    series: chartSeries,
    ...props.chartOptions
  };

  React.useEffect(() => {
    setChartSeries([
      {
        name: 'online',
        type: 'bar',

        label: {
          show: false
        },

        data: data?.map((d) => d.onlines) ?? [],
        itemStyle: {
          barBorderRadius: [0, 5, 5, 0], // Specify the border radius
          borderType: 'solid',
          color: '#00a96c'
        }
      },
      {
        name: 'offline',
        type: 'bar',

        label: {
          show: false
        },

        data: data?.map((d) => d.offlines) ?? [],
        itemStyle: {
          barBorderRadius: [0, 5, 5, 0], // Specify the border radius
          borderType: 'solid',
          color: '#ea3737'
        }
      }
    ]);
  }, [data]);

  return (
    <ReactECharts
      showLoading={isLoading}
      loadingOption={{
        showSpinner: false,
        maskColor: 'var(--neutral-09)',
        fontWeight: 'normal',
        text: 'Fetching Data...',

        fontStyle: 'normal',

        fontFamily: 'inherit'
      }}
      opts={{ renderer: 'svg' }}
      option={option}
      style={{ padding: '1rem', height: '400px', width: '100%' }}
    />
  );
};

export default VerticalBarHealthline;
