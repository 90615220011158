import React from 'react';

import { NumberField, TextField } from 'components/form';

import { Field } from 'formik';

const ElevatorForm = (props) => {
  return (
    <section>
      <Field label="Elevator Name" name="name" as={TextField}></Field>
      {/* <Field
        label="Floors access time (seconds)"
        max={60}
        min={0}
        name={`access_time`}
        as={NumberField}
      /> */}
    </section>
  );
};

export default ElevatorForm;
