import { dataFetchQuery } from 'utils/ajax';

import { API_HOST_URL } from './api-url';
const API_URL = API_HOST_URL + '/notification_api';

const Notification = {
  search(input = {}) {
    return dataFetchQuery(`${API_URL}/search`, input);
  },
  getStats(input = {}) {
    return dataFetchQuery(`${API_URL}/getStats`, input);
  },
  save(input = {}, id = '') {
    return dataFetchQuery(`${API_URL}/save/` + id, input);
  },
  enable(id) {
    return dataFetchQuery(`${API_URL}/toggleEnabled/` + id);
  },
  delete(id) {
    return dataFetchQuery(`${API_URL}/delete/` + id);
  }
};

export default Notification;
