import { callAPI } from 'utils/ajax';

const AccessLevelDoor = {
  search(input = {}) {
    return callAPI('GET', `/access_level_door`, input);
  },

  stats(input = {}) {
    return callAPI('GET', `/access_level_door/stats`, input);
  }
};

export default AccessLevelDoor;
