import * as React from 'react';
import { FeatureGroup, useMap } from 'react-leaflet';
import type { FeatureCollection } from 'geojson';
import * as L from 'leaflet';
import { GeomanControls } from 'react-leaflet-geoman-v2';
import Site from 'api/site';
import LayerSearch from './layer-search';
import { useMutationWrapper } from 'utils/ajax';

const LeafIcon = L.Icon.extend({
  options: {}
});
const Geoman = React.forwardRef(
  (
    {
      geojson,
      searchVisible,
      setGeojson,
      selectedSite,
      isEdit,
      setSelectedLayer,
      selectedLayer,
      pathOptions,
      setPathOptions,
      modes,
      setModes
    },
    ref
  ) => {
    const [layers, setLayers] = React.useState();
    const map = useMap();

    function getDoorIcon({ name }) {
      return L.divIcon({
        className: `pin-square`,
        html: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-lock" viewBox="0 0 16 16">
        <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2zM5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1z"/></svg>`
      });
    }

    function getCameraIcon(camera) {
      //let color = camera.online == '1' ? '#00A96C' : '#ea3737';
      let color = '#7f8795';

      return new L.divIcon({
        className: 'custom-icon camera',
        html: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-device-cctv">
            <path stroke="none" d="M0 0h24v24H0z" fill=${color} />
            <path d="M3 3m0 1a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-16a1 1 0 0 1 -1 -1z" />
            <path d="M12 14m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" />
            <path d="M19 7v7a7 7 0 0 1 -14 0v-7" />
            <path d="M12 14l.01 0" />
          </svg>`
      });
    }

    function getAuxIcon(door) {
      return new L.divIcon({
        className: `pin-square`,
        html: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.5577 16.1406H9.06402C8.07623 16.1406 7.27255 15.3442 7.27255 14.3654C7.27255 13.3863 8.07614 12.5898 9.06402 12.5898H14.7949C16.4674 12.5898 17.8279 11.2414 17.8279 9.58389C17.8279 7.92641 16.4674 6.57787 14.7949 6.57787H4.42719C4.14089 5.6637 3.28076 5 2.26425 5C1.0135 5 0 6.00442 0 7.24396C0 8.48349 1.0136 9.48791 2.26434 9.48791C3.318 9.48791 4.20373 8.77467 4.45634 7.80852H14.795C15.7826 7.80852 16.5863 8.605 16.5863 9.58394C16.5863 10.5629 15.7827 11.3594 14.795 11.3594H9.06411C7.39164 11.3594 6.03091 12.7078 6.03091 14.3654C6.03091 16.0229 7.39155 17.3712 9.06411 17.3712H19.5576C19.8274 18.3116 20.7003 19 21.7358 19C22.9865 19 24 17.9956 24 16.756C24 15.5165 22.9865 14.5121 21.7358 14.5121C20.7003 14.5121 19.8275 15.2004 19.5577 16.1406Z" fill="currentColor"/>
        </svg>
        `
      });
    }

    React.useEffect(() => {
      if (
        ref.current?.getLayers().length === 0 &&
        geojson &&
        !geojson.editMode
      ) {
        let _layers = [];
        L.geoJSON(geojson).eachLayer((layer) => {
          _layers.push(layer);

          if (layer._latlng) {
            var latlng = layer.getLatLng();
          } else {
            var latlng = layer.getLatLngs();
          }
          switch (layer.feature.properties.shape) {
            case 'rectangle':
              new L.Rectangle(latlng, layer.feature.options).addTo(ref.current);
              break;
            case 'circle':
              new L.Circle(latlng, layer.feature.options).addTo(ref.current);
              break;
            case 'polygon':
              new L.Polygon(latlng, layer.feature.options).addTo(ref.current);
              break;
            case 'polyline':
              new L.Polyline(latlng, layer.feature.options).addTo(ref.current);
              break;
            case 'marker':
              if (layer.feature.properties.hidden) break;

              if (layer.feature.properties.type == 'auxiliary') {
                let name = layer.feature.properties.name;

                let marker = new L.Marker(latlng, {
                  icon: getAuxIcon({ name })
                }).bindTooltip(name, {
                  permanent: true,
                  direction: 'right'
                });
                marker.properties = layer.feature.properties;
                marker.addTo(ref.current);
              }
              if (layer.feature.properties.type == 'door') {
                let name = layer.feature.properties.name;
                let onlineColor = 'FF0000';
                let marker = new L.Marker(latlng, {
                  icon: getDoorIcon({ name })
                }).bindTooltip(name, {
                  permanent: true,
                  direction: 'right'
                });
                marker.properties = layer.feature.properties;
                marker.addTo(ref.current);
              }

              if (layer.feature.properties.type == 'camera') {
                let name = layer.feature.properties.name;
                let rotation = layer.feature.options.rotation;
                let onlineColor = 'FF0000';
                let marker = new L.Marker(latlng, {
                  icon: getCameraIcon(layer.feature.properties)
                }).bindTooltip(name, {
                  permanent: true,
                  direction: 'right'
                });
                marker.properties = layer.feature.properties;

                marker.options.rotation = rotation;

                marker.addTo(ref.current);

                marker.on('click', (data) => {
                  const isEdit =
                    map.pm.globalDragModeEnabled() ||
                    map.pm.globalEditModeEnabled();
                  if (isEdit) return;
                  marker.options.rotation = (marker.options.rotation ?? 0) + 20;
                  marker.update();
                });
              }
              if (layer.feature?.options?.textMarker) {
                let marker = L.marker(latlng, {
                  textMarker: true,
                  text: layer.feature.options.text
                }).addTo(map);

                marker.properties = layer.feature.properties;
                marker.options = layer.feature.options;
                marker.addTo(ref.current);
              }
              break;
            case 'circlemarker':
              new L.CircleMarker(latlng, layer.feature.options).addTo(map);
              break;
          }
        });
        setLayers(_layers);
      }

      //  map.fitBounds(ref.current.getBounds(), { padding: [60, 60] });
      // map.dragging.disable();
    }, [geojson]);

    const handleChange = () => {
      // const newGeo = {
      //   type: 'FeatureCollection',
      //   features: [],
      //   editMode: true
      // };
      const layers = ref.current?.getLayers();
      if (layers) layers.forEach((layer) => (layer._state = 'changed'));
      //     var geoJson = JSON.parse(JSON.stringify(layer.toGeoJSON()));
      //     if (!geoJson.properties) {
      //       geoJson.properties = {};
      //     }
      //     geoJson.properties = layer.properties || {};
      //     geoJson.options = layer.options;
      //     // sometime the properties are inside options
      //     geoJson.properties = {
      //       ...geoJson.properties,
      //       ...layer.options.properties
      //     };
      //     // geoJson.properties.options = JSON.parse(JSON.stringify(layer.options));
      //     if (layer.options.radius) {
      //       var radius = parseFloat(layer.options.radius);
      //       if (radius % 1 !== 0) {
      //         geoJson.options.radius = radius.toFixed(6);
      //       } else {
      //         geoJson.options.radius = radius.toFixed(0);
      //       }
      //     }
      //     if (layer instanceof L.Rectangle) {
      //       geoJson.properties.shape = 'rectangle';
      //     } else if (layer instanceof L.Circle) {
      //       geoJson.properties.shape = 'circle';
      //     } else if (layer instanceof L.CircleMarker) {
      //       geoJson.properties.shape = 'circlemarker';
      //     } else if (layer instanceof L.Polygon) {
      //       geoJson.properties.shape = 'polygon';
      //     } else if (layer instanceof L.Polyline) {
      //       geoJson.properties.shape = 'polyline';
      //     } else if (layer instanceof L.Marker) {
      //       geoJson.properties.shape = 'marker';
      //     }
      //     newGeo.features.push(geoJson);
      //   });
      // }
      // setGeojson(newGeo);
    };

    // React.useEffect(() => {
    //   // if u want to start in drag mode
    //   setModes((prev) => ({ ...prev, drag: true }));
    //   map.pm.toggleGlobalDragMode();
    // }, []);

    return (
      <FeatureGroup
        ref={ref}
        eventHandlers={{
          // set selected layer
          click: (e) => {
            if (map.pm.globalCutModeEnabled()) return;
            if (map.pm.globalEditModeEnabled()) return;
            if (map.pm.globalDrawModeEnabled()) return;
            if (map.pm.globalDragModeEnabled() && selectedLayer) return;
            if (e.layer instanceof L.Marker && e.layer.options.textMarker) {
              map.pm.disableGlobalEditMode();
              map.pm.disableGlobalDragMode();
              map.pm.disableGlobalRemovalMode();
              map.pm.disableGlobalRotateMode();
              e.layer.pm.enable();
            } else if (!(e.layer instanceof L.Marker)) {
              setSelectedLayer(e.layer);

              map.pm.disableGlobalEditMode();
              map.pm.disableGlobalDragMode();
              map.pm.disableGlobalRemovalMode();
              map.pm.disableGlobalRotateMode();
              e.layer.pm.enable();
            } else setSelectedLayer(null);
          }
        }}
      >
        {/* {searchVisible && (
          <LayerSearch
            layers={layers}
            onChange={(e) => {
              let { id, type, name } = e.value.feature.properties;

              let layerExists = ref.current
                .getLayers()
                ?.filter((l) => l instanceof L.Marker)
                .find(
                  (l) => l?.properties?.id == id && l?.properties?.type == type
                );

              if (layerExists) {
                layerExists.setIcon(
                  getDoorIcon({ name })
                  //L.icon({
                  //iconUrl: `https://chart.googleapis.com/chart?chst=d_bubble_text_small&chld=bb|${name}|FFFF00|000000`
                  //})
                );
              }
              if (!layerExists) {
                // TODO check if there is another way to put properties
                map.pm.enableDraw('Marker', {
                  markerStyle: {
                    icon: getDoorIcon({ name }),
                    properties: { ...e.value.feature.properties, hidden: null }
                  },
                  tooltips: false,
                  continueDrawing: false
                });
              }
            }}
          />
        )} */}

        <GeomanControls
          options={{
            position: 'topleft',
            preventMarkerRemoval: true,
            drawMarker: false,
            drawText: false,
            templineStyle: { color: '#CE0048' },
            hintlineStyle: { color: '#24F262', dashArray: [5, 5] },
            drawControls: false,
            editControls: false,
            optionsControls: false,
            customControls: false,
            oneBlock: false
          }}
          pathOptions={pathOptions}
          globalOptions={{
            continueDrawing: false,
            editable: false,
            preventMarkerRemoval: true
          }}
          editOptions={{ preventMarkerRemoval: true }}
          // onMount={() => L.PM.setOptIn(true)}
          // onUnmount={() => L.PM.setOptIn(false)}
          eventDebugFn={console.log}
          onCreate={handleChange}
          onChange={handleChange}
          onUpdate={handleChange}
          onEdit={handleChange}
          onMapRemove={handleChange}
          onMapCut={handleChange}
          onDragEnd={handleChange}
          onMarkerDragEnd={handleChange}
          onUnmount={() => map.pm.disableGlobalDragMode()}
          onMount={() => {
            // add here all the avaiable patterns
            map.pm.Draw.OutdoorArea.setPathOptions({
              fillColor: 'url(#trees)',
              fillOpacity: 0.4
            });
          }}
          onGlobalDrawModeToggled={(e) => {
            setModes((prev) => ({
              ...prev,
              draw: { [e.shape]: { enabled: e.enabled } }
            }));
          }}
          onGlobalEditModeToggled={(e) =>
            setModes((prev) => ({
              ...prev,
              edit: e.enabled
            }))
          }
          onGlobalDragModeToggled={(e) =>
            setModes((prev) => ({
              ...prev,
              drag: e.enabled
            }))
          }
          onGlobalCutModeToggled={(e) =>
            setModes((prev) => ({
              ...prev,
              cut: e.enabled
            }))
          }
          onGlobalRemovalModeToggled={(e) => {
            setModes((prev) => ({
              ...prev,
              removal: e.enabled
            }));
          }}
        />
      </FeatureGroup>
    );
  }
);

export default Geoman;
