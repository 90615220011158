import React from 'react';
import { Camera2, XLg } from 'react-bootstrap-icons';
import Webcam from 'react-webcam';
import { AuxiliaryButton, SecondaryButton } from './buttons';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { IconCameraCheck } from '@tabler/icons-react';

export const WebcamCapture = ({ img, setIsCamera }) => {
  const webcamRef = React.useRef(null);
  const [imgSrc, setImgSrc] = React.useState(null);
  const videoConstraints = {
    width: 100,
    height: 100,
    facingMode: 'user'
  };

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
    img.src = imageSrc;
    if (setIsCamera) setIsCamera(false);
  }, [webcamRef, setImgSrc]);

  React.useEffect(() => {
    navigator.getMedia =
      navigator.getUserMedia || // use the proper vendor prefix
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia;

    navigator.getMedia(
      { video: true },
      function () {},
      function () {}
    );
  }, [webcamRef]);

  return (
    <article className="webcam">
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        videoConstraints={videoConstraints}
      />
      <section className="controls">
        <AuxiliaryButton compact className="capture" onClick={capture}>
          <IconCameraCheck />
          Capture
        </AuxiliaryButton>

        <AuxiliaryButton
          compact
          className="exit"
          onClick={() => setIsCamera(false)}
        >
          <XLg />
          Back
        </AuxiliaryButton>
      </section>
    </article>
  );
};
