/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import React, { useState } from "react";
import { ThreeDotsVertical } from "react-bootstrap-icons";

const buttonStyle = css`
  display: flex;
  border: 2px solid var(--primary-base);
  border-radius: 32px;
  padding: 0 6px 0 10px;
  gap: 2px;
  font-size: 12px;
  height: 32px;
  color: var(--primary-base);
  font-weight: bold;
  align-items: center;
  background: var(--neutral-09);
  transition: background ease 300ms;

  :hover {
    background: var(--menu-hover-background-color);
  }
`;

const MenuModal = styled("div")`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  z-index: 3;
  cursor: default;
  pointer-events: none;
`;

const openModalCSS = css`
  pointer-events: all;
`;

const MenuItems = styled("aside")`
  pointer-events: none;
  background: var(--neutral-09);
  border-radius: 5px;
  width: 228px;
  padding: 4px 0;
  position: absolute;
  right: 0;
  z-index: 5;
  opacity: 0;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.2);
`;

const openMenuCSS = css`
  pointer-events: all;
  opacity: 1;
`;

const FloatMenu = function (props) {
  const [open, setOpen] = useState(0);

  function openMenu(e) {
    setOpen(true);
    e.stopPropagation();
  }

  function closeMenu(e) {
    setOpen(false);
    e.stopPropagation();
  }

  return (
    <>
      <button css={buttonStyle} onClick={openMenu}>
        {props.text || "Actions"}
        <ThreeDotsVertical size="16" color="var(--primary-base)" />
      </button>
      <MenuModal onClick={closeMenu} css={open ? openModalCSS : null} />
      <MenuItems css={open ? openMenuCSS : null}>{props.children}</MenuItems>
    </>
  );
};

export default FloatMenu;
