import ActionMenu from 'views/intercoms/action-menu';
import { css } from '@emotion/react';
import { getSelectionColumn } from 'components/table-selection-column';
import { Badge } from 'components/badge';
import { TelephoneInboundFill, TelephonePlusFill } from 'react-bootstrap-icons';
import { dateTimeShort } from 'utils/time';
import DeviceAlertsTableIndicator from 'components/device-alerts-table-indicator';
/** @jsxImportSource @emotion/react */

export const columns = [
  getSelectionColumn(),
  {
    Header: 'Name',
    accessor: 'name',
    pickerDisabled: true,
    newSorter: true,
    asc: 'atoz',
    desc: 'ztoa',
    Cell: ({ row: { original } }) => {
      return (
        <div
          css={css`
            display: grid;
            grid-template-columns: auto 1fr;
            column-gap: 0.4rem;
            svg {
              grid-row: span 2;
              place-self: center;
              color: var(--light--others--red-400-base);
            }
            > small {
              grid-column: 1;
            }
          `}
        >
          <DeviceAlertsTableIndicator device={original} />
          <div
            css={css`
              display: flex;
              flex-direction: column;
            `}
          >
            <strong
              css={css`
                display: inline-block;
                margin-bottom: 6px;
              `}
            >
              {original.name}
            </strong>
            <small>{original.site_name}</small>
          </div>
        </div>
      );
    }
  },
  {
    disableSortBy: true,
    Header: 'Status',
    accessor: 'online',
    label: 'Status',
    Cell: ({ row, cell }) => {
      if (!cell.value || cell.value == 0)
        return <Badge color="danger">offline</Badge>;
      else if (cell.value == 1) return <Badge color="green">Online</Badge>;
    }
  },
  {
    disableSortBy: true,
    Header: 'Door Association',
    accessor: 'generic_door_name',
    Cell: ({ row, cell }) => {
      return (
        <span>
          {row.original.generic_door_name ||
            row.original.intercom_relay_door_name}
        </span>
      );
    }
  },
  {
    newSorter: true,
    asc: 'leastRecentCall',
    desc: 'mostRecentCall',
    Header: 'Last Call Time',
    accessor: 'last_call_time',
    Cell: ({ cell: { value } }) => {
      return <time>{value ? dateTimeShort(new Date(value)) : null}</time>;
    }
  },

  {
    disableSortBy: true,
    Header: 'State',
    accessor: 'last_call_state',

    Cell: ({ cell: { value } }) => {
      return (
        <div>
          {value == 'answered' && (
            <TelephoneInboundFill color={`var(--secundary-base)`} />
          )}

          {value == 'missed' && <TelephonePlusFill color={`var(--red-base)`} />}
          <span
            css={css`
              text-transform: capitalize;
              margin-left: 0.5rem;
            `}
          >
            Call {value}
          </span>
        </div>
      );
    }
  }

  // {
  //   // Make an expander cell
  //   Header: () => ntnull, // No header
  //   pickerHide: true,
  //   id: "action", // It needs an ID
  //   style: {
  //     width: "5%",
  //   },
  //   Cell: ({ row }) => <ActionMenu data={row.original} />,
  // },
  /**  {
    // Make an expander cell
    Header: () => null, // No header
    id: "expander", // It needs an ID
    style: {
      width: "5%",
    },
    pickerHide: true,
    Cell: ({ row }) => (
      // Use Cell to render an expander for each row.
      // We can use the getToggleRowExpandedProps prop-getter
      // to build the expander.
      <span {...row.getToggleRowExpandedProps()}>
        {row.isExpanded ? (
          <ChevronUp color="var(--primary-base)" />
        ) : (
          <ChevronDown color="var(--primary-base)" />
        )}
      </span>
    ),
  },**/
];
