import React from 'react';
import ActivityChart from './activity-chart';
import { format, addHours, startOfHour } from 'date-fns';

export default ({ activity, device }) => {
  activity = typeof activity == 'object' ? activity : JSON.parse(activity);
  function past24Hours() {
    const currentDate = new Date();

    // Initialize an array to store the past 24 hours
    const past24Hours = [];

    // Loop through the past 24 hours
    for (let i = 0; i < 24; i++) {
      const hour = new Date(currentDate);
      past24Hours.push(hour);
      // Calculate the time for the current hour

      hour.setHours(currentDate.getHours() - i);

      // Add the formatted date to the array
    }

    // Print the list of hours in the past 24 hours

    let hours = past24Hours.map((now) => {
      now.setMinutes(0);
      now.setSeconds(0);
      now.setMilliseconds(0);
      return now.toISOString();
    });
    let obj = {};

    for (let h of hours) {
      obj[h] = 1;

      if (typeof activity == 'object' && activity)
        if (h in activity) obj[h] = 0;
      //if (new Date(h) > new Date(last_activity_at)) obj[h] = 0;
      if (!device.online) {
        //if continues feed also calucalte by last_activity

        let lastOffline = device.last_offline_at
          ? startOfHour(new Date(device.last_offline_at))
          : null;
        if (new Date(h) >= lastOffline) obj[h] = 0;
      } else if (device.online) {
        let lastOffline = device.last_offline_at
          ? startOfHour(new Date(device.last_offline_at))
          : null;
        let firstOnline = device.last_online_at
          ? startOfHour(new Date(device.last_online_at))
          : null;

        if (
          lastOffline &&
          new Date(h) <= firstOnline &&
          new Date(h) >= lastOffline
        )
          obj[h] = 0;
      }
    }

    return obj;
  }
  let hours = past24Hours();
  return <ActivityChart hours={hours} activity={activity} />;
};
